import { Text, Link } from "@merge-api/merge-javascript-shared";
import { ArrowUpRight } from "lucide-react";

interface ConnectorInputHeaderProps {
  title: string;
  link: string;
  description: string;
}

const ConnectorInputHeader = ({ title, link, description }: ConnectorInputHeaderProps) => {
  return (
    <>
      <div className="flex justify-between">
        <Text as="div" variant="h6">
          {title}
        </Text>
        <Link
          className="font-semibold text-sm flex items-center text-blue-40 hover:text-blue-60"
          target="_blank"
          href={link}
        >
          Docs
          <ArrowUpRight className="ml-1.5" size={12} />
        </Link>
      </div>
      <Text as="div" variant="sm" className="text-gray-70">
        {description}
      </Text>
    </>
  );
};

export default ConnectorInputHeader;
