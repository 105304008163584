import { match } from "react-router-dom";
import IntegrationPublishModuleSubtabWithValidator from "./IntegrationPublishModuleSubtab";
import { MergeFlagFeature, useMergeFlag } from "../../shared/hooks/useMergeFlag";
import IntegrationPublishModuleSubtabWithValidatorV2 from "./IntegrationPublishModuleSubtabWithValidatorV2";

type Props = { integrationID: string; match: match };

const IntegrationPublishRequestTab = ({ integrationID }: Props) => {
  const { enabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_PUBLISH_MODULE_V2,
    isEnabledForUser: true,
  });

  return enabled ? (
    <IntegrationPublishModuleSubtabWithValidatorV2 integrationID={integrationID} />
  ) : (
    <IntegrationPublishModuleSubtabWithValidator integrationID={integrationID} />
  );
};

export default IntegrationPublishRequestTab;
