import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CornerUpLeft,
  CornerUpRight,
  ExternalLink,
  Save,
  GitPullRequest,
  GitPullRequestClosed,
} from "lucide-react";
import { Integration } from "../../../models/Entities";
import { NextComponentVersionState } from "../../integrations/versioned-components/types";
import IntegrationEditorTopControlPanel from "./IntegrationEditorTopControlPanel";
import { FOLDER_NAMES } from "../../../constants";
import { navigateToPublishModule } from "../../../router/RouterUtils";

const AUTO_SAVE_TIME_LIMIT_IN_MINUTES = 5;
type Props = {
  integration: Integration;
  isSaving: boolean;
  mappingTestName: string;
  reducerActions: {
    save: () => void;
    undo: () => void;
    redo: () => void;
    canUndo: boolean;
    canRedo: boolean;
    stage: () => void;
    unstage: () => void;
  };
  mappingTestState: NextComponentVersionState;
  hasUnsavedChanges: boolean;
};

const MappingTestEditorTopControlPanel = ({
  integration,
  isSaving,
  reducerActions,
  mappingTestState,
  mappingTestName,
  hasUnsavedChanges,
}: Props) => {
  const { canUndo, canRedo, save, undo, redo, stage, unstage } = reducerActions;
  const [lastSaveTime, setLastSaveTime] = useState<number>(Date.now());

  const history = useHistory();

  const isStaged = mappingTestState === NextComponentVersionState.STAGED_WITH_CHANGES;
  const isDraft = mappingTestState === NextComponentVersionState.DRAFT_WITH_CHANGES;

  const shouldAutoSave = () => {
    const currentTime = Date.now();
    const elapsedTime = currentTime - lastSaveTime;
    const elapsedMinutes = Math.floor(elapsedTime / (1000 * 60));

    return (
      elapsedMinutes >= AUTO_SAVE_TIME_LIMIT_IN_MINUTES &&
      !isStaged &&
      !(isSaving || !hasUnsavedChanges)
    );
  };

  const saveAndResetAutoSaveTimer = () => {
    setLastSaveTime(Date.now());
    save();
  };

  useEffect(() => {
    const saveInterval = setInterval(() => {
      if (shouldAutoSave()) {
        saveAndResetAutoSaveTimer();
      }
    }, 5 * 60 * 1000); // Check every 5 minutes

    return () => {
      clearInterval(saveInterval); // Clean up the interval on component unmount
    };
  }, []);

  return (
    <IntegrationEditorTopControlPanel
      // @ts-ignore
      actions={[
        { Icon: CornerUpLeft, isDisabled: !canUndo, onClick: undo, text: "Undo" },
        { Icon: CornerUpRight, isDisabled: !canRedo, onClick: redo, text: "Redo" },
        !isStaged && {
          Icon: Save,
          isDisabled: isSaving || !hasUnsavedChanges,
          onClick: saveAndResetAutoSaveTimer,
          text: "Save Draft",
        },
        isStaged && {
          Icon: GitPullRequestClosed,
          isDisabled: isSaving,
          onClick: unstage,
          text: "Unstage",
        },
        isStaged && {
          Icon: ExternalLink,
          isDisabled: isSaving,
          onClick: () => {
            navigateToPublishModule(history, integration.id, true);
          },
          text: "Publish",
        },
        !hasUnsavedChanges &&
          !isStaged &&
          isDraft && {
            Icon: GitPullRequest,
            isDisabled: isSaving,
            onClick: stage,
            text: "Stage",
          },
      ].filter((x) => !!x)}
      integration={integration}
      path={[FOLDER_NAMES.MAPPING_TEST_FOLDER, mappingTestName]}
    />
  );
};

export default MappingTestEditorTopControlPanel;
