import { useEffect } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast } from "../../../shared/Toasts";
import { RateLimitConfigurationIntegrationBuilder } from "../../utils/Entities";

interface LoadRateLimitConfigurationProps {
  integrationID: string;
  setRateLimitConfigurations: React.Dispatch<
    React.SetStateAction<RateLimitConfigurationIntegrationBuilder[] | undefined>
  >;
  setIsLoadingRateLimitConfigurations?: React.Dispatch<React.SetStateAction<boolean>>;
}

const useLoadRateLimitConfigurations = ({
  integrationID,
  setRateLimitConfigurations,
  setIsLoadingRateLimitConfigurations = () => {},
}: LoadRateLimitConfigurationProps) => {
  // effects
  useEffect(() => {
    fetchWithAuth({
      path: `/integrations/${integrationID}/rate-limit-configs`,
      method: "GET",
      onResponse: (data: RateLimitConfigurationIntegrationBuilder[]) => {
        setRateLimitConfigurations(data);
        setIsLoadingRateLimitConfigurations(false);
      },
      onError: () => {
        showErrorToast("Failed to fetch rate limit configurations for integration.");
        setRateLimitConfigurations([]);
      },
    });
  }, [setRateLimitConfigurations]);
};

export default useLoadRateLimitConfigurations;
