import classNames from "classnames";
import { abbreviationForAPICategory } from "../../../dashboard/components/IntegrationsDashboardTableRow/utils";
import { APICategory } from "../../../../../models/Entities";

const TABS: { value: APICategory | "all"; label: string }[] = [
  { value: "all", label: "All" },
  ...Object.values(APICategory).map((category) => ({
    value: category,
    label: abbreviationForAPICategory(category),
  })),
];

type CategoriesTabsProps = {
  category: "all" | APICategory;
  setCategory: React.Dispatch<React.SetStateAction<"all" | APICategory>>;
};

const CategoriesTabs = ({ category, setCategory }: CategoriesTabsProps) => {
  return (
    <div className="flex flex-row overflow-scroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']">
      {TABS.map((tab) => {
        const isActive = category === tab.value;

        return (
          <button
            className={classNames(
              isActive ? "!bg-blue-0 !text-blue-40" : "bg-white text-gray-50",
              "border-0 px-1.5 font-semibold rounded mr-1 text-sm leading-[16px]"
            )}
            onClick={() => setCategory(tab.value)}
          >
            {tab.label}
          </button>
        );
      })}
    </div>
  );
};

export default CategoriesTabs;
