/// /////////////////////////////
/// / Blueprint JSON Parsing ////
/// /////////////////////////////

import { BuilderStatus } from "./components/integration-builder/utils/Entities";
import { BlueprintOperationType } from "./models/Blueprints";
import { APICategory } from "./models/Entities";

export const STEP_TYPES = {
  API_REQUEST: "API_REQUEST",
  ARRAY_LOOP: "ARRAY_LOOP",
  IF_ELSE: "IF_ELSE",
  CREATE_OR_UPDATE: "CREATE_OR_UPDATE",
};

export const STEP_TYPES_TO_BE_DEPRECATED = ["DATA_TRANSFORM"];

export const PUBLISH_STATES = {
  PUBLISHED: "PUBLISHED",
  UNPUBLISHED: "UNPUBLISHED",
  DRAFT: "DRAFT",
};

/// /////////////////////////////

/// /////////////////////////////
/// /// HRIS Common Models //////
/// /////////////////////////////

export const HRIS_COMMON_MODEL_TYPES = {
  EMPLOYEE: "EMPLOYEE",
  LOCATION: "LOCATION",
  COMPANY: "COMPANY",
  EMPLOYMENT: "EMPLOYMENT",
  TEAM: "TEAM",
  TIME_OFF: "TIME_OFF",
  TIME_OFF_BALANCE: "TIME_OFF_BALANCE",
  BENEFIT: "BENEFIT",
  DOCUMENT: "DOCUMENT",
  REPORT: "REPORT",
  PAYROLL_RUN: "PAYROLL_RUN",
  EMPLOYEE_PAYROLL_RUN: "EMPLOYEE_PAYROLL_RUN",
  BANK_INFO: "BANK_INFO",
  PAY_GROUP: "PAY_GROUP",
  GROUP: "GROUP",
  EMPLOYER_BENEFIT: "EMPLOYER_BENEFIT",
  DEPENDENT: "DEPENDENT",
  TIMESHEET_ENTRY: "TIMESHEET_ENTRY",
};

/// /////////////////////////////
/// /// ATS Common Models ///////
/// /////////////////////////////

export const ATS_COMMON_MODEL_TYPES = {
  REMOTE_USER: "REMOTE_USER",
  JOB: "JOB",
  JOB_POSTING: "JOB_POSTING",
  DEPARTMENT: "DEPARTMENT",
  OFFICE: "OFFICE",
  CANDIDATE: "CANDIDATE",
  TAG: "TAG",
  APPLICATION: "APPLICATION",
  JOB_INTERVIEW_STAGE: "JOB_INTERVIEW_STAGE",
  SCHEDULED_INTERVIEW: "SCHEDULED_INTERVIEW",
  SCORECARD: "SCORECARD",
  REJECT_REASON: "REJECT_REASON",
  ATTACHMENT: "ATTACHMENT",
  OFFER: "OFFER",
  EEOC: "EEOC",
  ACTIVITY: "ACTIVITY",
  SCREENING_QUESTION: "SCREENING_QUESTION",
  SCREENING_QUESTION_OPTION: "SCREENING_QUESTION_OPTION",
  SCREENING_QUESTION_ANSWER: "SCREENING_QUESTION_ANSWER",
};

/// ///////////////////////////////////
/// /// ACCOUNTING Common Models //////
/// ///////////////////////////////////

export const ACCOUNTING_COMMON_MODEL_TYPES = {
  ACCOUNT: "ACCOUNT",
  ADDRESS: "ADDRESS",
  ACCOUNTING_ATTACHMENT: "ACCOUNTING_ATTACHMENT",
  BALANCE_SHEET: "BALANCE_SHEET",
  CASH_FLOW_STATEMENT: "CASH_FLOW_STATEMENT",
  COMPANY_INFO: "COMPANY_INFO",
  CONTACT: "CONTACT",
  CREDIT_NOTE: "CREDIT_NOTE",
  EXPENSE: "EXPENSE",
  INCOME_STATEMENT: "INCOME_STATEMENT",
  INVOICE_LINE_ITEM: "INVOICE_LINE_ITEMS",
  INVOICE: "INVOICE",
  ITEM: "ITEM",
  JOURNAL_ENTRY: "JOURNAL_ENTRY",
  JOURNAL_LINE: "JOURNAL_LINE",
  PAYMENT: "PAYMENT",
  PURCHASE_ORDER_LINE_ITEM: "PURCHASE_ORDER_LINE_ITEM",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  REPORT_ITEM: "REPORT_ITEM",
  TAX_RATE: "TAX_RATE",
  TAX_COMPONENT: "TAX_COMPONENT",
  TRACKING_CATEGORY: "TRACKING_CATEGORY",
  VENDOR_CREDIT: "VENDOR_CREDIT",
  CREDIT_NOTE_APPLY_LINE: "CREDIT_NOTE_APPLY_LINE",
  VENDOR_CREDIT_APPLY_LINE: "VENDOR_CREDIT_APPLY_LINE",
  ACCOUNTING_PERIOD: "ACCOUNTING_PERIOD",
  PAYMENT_LINE_ITEM: "PAYMENT_LINE_ITEM",
  GENERAL_LEDGER_TRANSACTION: "GENERAL_LEDGER_TRANSACTION",
  GENERAL_LEDGER_TRANSACTION_ITEM: "GENERAL_LEDGER_TRANSACTION_ITEM",
  BANK_FEED_ACCOUNT: "BANK_FEED_ACCOUNT",
  BANK_FEED_TRANSACTION: "BANK_FEED_TRANSACTION",
  ACCOUNTING_EMPLOYEE: "ACCOUNTING_EMPLOYEE",
};

/// //////////////////////////////////
/// /// TICKETING Common Models //////
/// //////////////////////////////////

export const TICKETING_COMMON_MODEL_TYPES = {
  TICKETING_ACCOUNT: "TICKETING_ACCOUNT",
  TICKETING_ATTACHMENT: "TICKETING_ATTACHMENT",
  TICKETING_CONTACT: "TICKETING_CONTACT",
  COMMENT: "COMMENT",
  PROJECT: "PROJECT",
  TICKETING_TAG: "TICKETING_TAG",
  TICKETING_TEAM: "TICKETING_TEAM",
  TICKET: "TICKET",
  USER: "USER",
  ROLE: "ROLE",
};

/// ////////////////////////////
/// /// CRM Common Models //////
/// ////////////////////////////

export const CRM_COMMON_MODEL_TYPES = {
  CRM_USER: "CRM_USER",
  CRM_ADDRESS: "CRM_ADDRESS",
  CRM_PHONE_NUMBER: "CRM_PHONE_NUMBER",
  CRM_EMAIL_ADDRESS: "CRM_EMAIL_ADDRESS",
  CRM_ACCOUNT: "CRM_ACCOUNT",
  ENGAGEMENT_TYPE: "ENGAGEMENT_TYPE",
  ENGAGEMENT: "ENGAGEMENT",
  STAGE: "STAGE",
  OPPORTUNITY: "OPPORTUNITY",
  CRM_CONTACT: "CRM_CONTACT",
  LEAD: "LEAD",
  NOTE: "NOTE",
  TASK: "TASK",
  CRM_CUSTOM_OBJECT_CLASS: "CRM_CUSTOM_OBJECT_CLASS",
  CRM_CUSTOM_OBJECT: "CRM_CUSTOM_OBJECT",
  CRM_ASSOCIATION_TYPE: "CRM_ASSOCIATION_TYPE",
  CRM_ASSOCIATION: "CRM_ASSOCIATION",
};

/// ////////////////////////////
/// /// MKTG Common Models /////
/// ////////////////////////////

export const MKTG_COMMON_MODEL_TYPES = {
  MKTG_USER: "MKTG_USER",
  MKTG_EVENT: "MKTG_EVENT",
  MKTG_CONTACT: "MKTG_CONTACT",
  MKTG_CAMPAIGN: "MKTG_CAMPAIGN",
  MKTG_LIST: "MKTG_LIST",
  MKTG_MESSAGE: "MKTG_MESSAGE",
  MKTG_TEMPLATE: "MKTG_TEMPLATE",
  MKTG_MARKETING_EMAIL: "MKTG_MARKETING_EMAIL",
  MKTG_ACTION: "MKTG_ACTION",
  MKTG_AUTOMATION: "MKTG_AUTOMATION",
};

/// ///////////////////////////////////
/// /// FILESTORAGE Common Models /////
/// ///////////////////////////////////

export const FILESTORAGE_COMMON_MODEL_TYPES = {
  FILESTORAGE_FILE: "FILESTORAGE_FILE",
  FILESTORAGE_FOLDER: "FILESTORAGE_FOLDER",
  FILESTORAGE_DRIVE: "FILESTORAGE_DRIVE",
  FILESTORAGE_USER: "FILESTORAGE_USER",
  FILESTORAGE_GROUP: "FILESTORAGE_GROUP",
  FILESTORAGE_PERMISSIONS: "FILESTORAGE_PERMISSIONS",
};

/// ///////////////////////////////////
/// /// DATAWAREHOUSE Common Models /////
/// ///////////////////////////////////

export const DATAWAREHOUSE_COMMON_MODEL_TYPES = {
  DATAWAREHOUSE_TABLE: "DATAWAREHOUSE_TABLE",
  DATAWAREHOUSE_COLUMN: "DATAWAREHOUSE_COLUMN",
};

/// ////////////////////////////////////////////
/// / Category - Common Model Types Mapping ////
/// ////////////////////////////////////////////
export const CATEGORY_COMMON_MODEL_TYPES: { [key in APICategory]: Record<string, string> } = {
  hris: HRIS_COMMON_MODEL_TYPES,
  ats: ATS_COMMON_MODEL_TYPES,
  accounting: ACCOUNTING_COMMON_MODEL_TYPES,
  ticketing: TICKETING_COMMON_MODEL_TYPES,
  crm: CRM_COMMON_MODEL_TYPES,
  mktg: MKTG_COMMON_MODEL_TYPES,
  filestorage: FILESTORAGE_COMMON_MODEL_TYPES,
  datawarehouse: DATAWAREHOUSE_COMMON_MODEL_TYPES,
};

/// /////////////////////////////

/// ////////////////////////////////////
/// / Category - Short Name Mapping ////
/// ////////////////////////////////////

export const CATEGORY_SHORT_NAME: { [key in APICategory]: string } = {
  hris: "HRIS",
  ats: "ATS",
  accounting: "ACCT",
  ticketing: "TCKT",
  crm: "CRM",
  mktg: "MKTG",
  filestorage: "FILE",
  datawarehouse: "DATA",
};

/// /////////////////////////////

/// /////////////////////////////
/// /       User Types       ////
/// /////////////////////////////

export const USER_TYPES = {
  ADMIN: "ADMIN",
  MEMBER: "MEMBER",
};

/// /////////////////////////////

/// /////////////////////////////
/// /         Time           ////
/// /////////////////////////////
export const SEC_IN_DAY = 60 * 60 * 24;
export const SEC_IN_YEAR = SEC_IN_DAY * 365;
export const SEC_IN_WEEK = SEC_IN_DAY * 7;
export const SEC_IN_TWO_WEEKS = SEC_IN_DAY * 14;

/// /////////////////////////////

/// /////////////////////////////
/// /      Documentation      ////
/// /////////////////////////////
export const DOCUMENTATION_ORDER = [
  "link-token",
  "account-token",
  "regenerate-key",
  "available-actions",
  "passthrough",
];

/// /////////////////////////////

/// /////////////////////////////
/// /             Logs       ////
/// /////////////////////////////

export const LOG_TYPES = {
  API_REQUEST_LOG_ENTRY_TYPE: "APIRequestLogEntry",
};

export const LOG_STATUSES = {
  REQUEST_STATUS_SUCCEEDED: "SUCCEEDED",
  REQUEST_STATUS_FAILED: "FAILED",
};

/// /////////////////////////////

/// /////////////////////////////
/// /       Issues           ////
/// /////////////////////////////

export const ISSUE_STATUSES = {
  ISSUE_STATUS_ONGOING: "ONGOING",
  ISSUE_STATUS_RESOLVED: "RESOLVED",
};

export const ISSUE_DESCRIPTION = {
  ISSUE_DESCRIPTION_MISSING_PERMISSION: "Missing Permission",
  ISSUE_DESCRIPTION_BAD_API_KEY: "Bad API Key",
  ISSUE_DESCRIPTION_HIT_RATE_LIMIT: "Hit Rate Limit",
};

/// /////////////////////////////

/// /////////////////////////////
/// /     Date Options       ////
/// /////////////////////////////

// export enum DATE_PICKER_OPTIONS = {
//   ALL_TIME: "All time",
//   PAST_24_HOURS: "Past 24 hours",
//   PAST_WEEK: "Past week",
//   PAST_MONTH: "Past month",
//   PAST_YEAR: "Past year",
//   CUSTOM:  "Custom...",
// }

export enum DATE_PICKER_OPTIONS {
  ALL_TIME = "All time",
  PAST_24_HOURS = "Past 24 hours",
  PAST_WEEK = "Past week",
  PAST_MONTH = "Past month",
  PAST_YEAR = "Past year",
  CUSTOM = "Custom...",
}

/// /////////////////////////////

/// /////////////////////////////////////////
/// // Blueprint Async Execution Argument ///
/// /////////////////////////////////////////

export const BLUEPRINT_PRE_RUN_OPTIONS = {
  KEEP_MOCK_DATA: true,
  DONT_KEEP_MOCK_DATA: false,
};

export enum INTEGRATION_CATEGORY_STATUSES {
  ACTIVE = "ACTIVE",
  IN_BETA = "IN_BETA",
  FOR_MARKETING = "FOR_MARKETING",
  INACTIVE = "INACTIVE",
}

export const BLUEPRINT_OPERATION_TYPES_WITH_SCHEDULES = [
  BlueprintOperationType.FETCH,
  BlueprintOperationType.WEBHOOK_SETUP,
];

export const ALLOWED_OPERATION_TYPES_FOR_PAGINATION_TIMESTAMPS = [
  BlueprintOperationType.FETCH,
  BlueprintOperationType.FETCH_FILTER_OPTIONS,
];

export const ALLOWED_OPERATION_TYPES_FOR_IGNORE_AUTH_CONFIG = [BlueprintOperationType.CREATE];

export enum ChecklistStatusValue {
  DRAFT = "DRAFT",
  NOT_SUPPORTED = "NOT_SUPPORTED",
  STAGED = "STAGED",
  IMPLEMENTED = "IMPLEMENTED",
  NOT_IMPLEMENTED = "NOT_IMPLEMENTED",
  PENDING = "PENDING",
  FAILURE = "FAILURE",
}

export type IntegrationChecklistData = {
  has_rate_limit_config: ChecklistStatusValue;
  has_pagination_config: ChecklistStatusValue;
  has_authentication_config: ChecklistStatusValue;
  has_setup_steps: ChecklistStatusValue;
  has_validation_bp: ChecklistStatusValue;
  has_web_address_schema: ChecklistStatusValue;
  has_integration_logo: ChecklistStatusValue;
  has_automatic_webhook_support: ChecklistStatusValue;
  has_manual_webhook_support: ChecklistStatusValue;
  has_api_endpoint: ChecklistStatusValue;
  has_base_api_url: ChecklistStatusValue;
  has_setup_steps_config_fields: ChecklistStatusValue;
  has_api_endpoints_config_fields: ChecklistStatusValue;
  has_notes_for_customers: ChecklistStatusValue;
  has_api_documentation_url: ChecklistStatusValue;
  has_api_endpoint_collection_run: ChecklistStatusValue;
};

// This prevents us from making faulty frontend changes when adding & backfilling new checklist fields to backend
export const supportedIntegrationChecklistData = [
  "has_rate_limit_config",
  "has_pagination_config",
  "has_authentication_config",
  "has_setup_steps",
  "has_validation_bp",
  "has_web_address_schema",
  "has_integration_logo",
  "has_automatic_webhook_support",
  "has_manual_webhook_support",
  "has_api_endpoint",
  "has_base_api_url",
  "has_setup_steps_config_fields",
  "has_api_endpoints_config_fields",
];

type FieldNameToWarningText = {
  [K in keyof IntegrationChecklistData]: string;
};

export const FIELD_NAME_TO_WARNING_TEXT: FieldNameToWarningText = {
  has_rate_limit_config: "Rate limit configuration",
  has_pagination_config: "Pagination configuration",
  has_authentication_config: "Authentication configuration",
  has_setup_steps: "Setup step path",
  has_validation_bp: "Validation blueprint",
  has_web_address_schema: "Web address schema",
  has_integration_logo: "Logo",
  has_automatic_webhook_support: "Automated webhook support",
  has_manual_webhook_support: "Manual webhook support",
  has_api_endpoint: "API endpoint",
  has_base_api_url: "Base API URL",
  has_setup_steps_config_fields: "Required org fields for partner auth",
  has_api_endpoints_config_fields: "Timestamp format",
  has_notes_for_customers: "Notes for customers",
  has_api_documentation_url: "API documentation URL",
  has_api_endpoint_collection_run: "API endpoint collection run",
};

export type ChecklistValueToBuilderStatus = {
  [K in ChecklistStatusValue]: BuilderStatus;
};

export const CHECKLIST_VALUE_TO_BUILDER_STATUS: ChecklistValueToBuilderStatus = {
  [ChecklistStatusValue.DRAFT]: BuilderStatus.NOT_COMPLETED,
  [ChecklistStatusValue.NOT_SUPPORTED]: BuilderStatus.COMPLETED,
  [ChecklistStatusValue.STAGED]: BuilderStatus.NOT_COMPLETED,
  [ChecklistStatusValue.IMPLEMENTED]: BuilderStatus.COMPLETED,
  [ChecklistStatusValue.NOT_IMPLEMENTED]: BuilderStatus.NOT_COMPLETED,
  [ChecklistStatusValue.PENDING]: BuilderStatus.PENDING,
  [ChecklistStatusValue.FAILURE]: BuilderStatus.FAILED,
};

export const FOLDER_NAMES = {
  MAPPING_TEST_FOLDER: " Mapping Tests",
};

export const DEFAULT_ADMIN_TAB_NAME = "Merge Admin";

export enum VALIDATOR_TRIGGER_TYPES {
  LINTER = "LINTER",
  PUBLICATION = "PUBLICATION",
}
