import { Card, Text, Link } from "@merge-api/merge-javascript-shared";
import { convertNameToDisplayName } from "../../../../../shared/diff-view/utils/helpers";
import { ArrowUpRight } from "lucide-react";
import DiffModal from "../../../../../shared/diff-view/DiffModal";
import { useState } from "react";
import { DiffModalTypeEnum, DiffModelTypeEnum } from "../../../../../../models/DiffModels";
import {
  ChangelogModelType,
  ChangelogModelTypeMap,
  ModelTypeMappingToDiffModal,
} from "../../../utils/types";
import { handleModelTypeFieldConversionForDiffModal } from "../../../utils/helpers";

interface FieldsChangedContainerProps {
  updatedFields: string[];
  newFields: Record<any, any>;
  originalFields: Record<any, any>;
  modelType: ChangelogModelType;
}

const FieldsChangedContainer = ({
  updatedFields,
  newFields,
  originalFields,
  modelType,
}: FieldsChangedContainerProps) => {
  // const derived from state
  const numberOfFieldsChanged = updatedFields.length;
  const displayNames = updatedFields.map(convertNameToDisplayName);
  const mappedModelType = ModelTypeMappingToDiffModal[modelType] || DiffModelTypeEnum.DEFAULT;

  // Join display names with commas, except for the last one
  const displayNamesString = displayNames.join(", ").replace(/, ([^,]*)$/, " and $1");
  const [isShowingDiffModal, setIsShowingDiffModal] = useState(false);

  // convert fields if necessary to be format to be displayed by diff modal
  const newDiffModalFields = handleModelTypeFieldConversionForDiffModal(modelType, newFields);
  const originalDiffModalFields = handleModelTypeFieldConversionForDiffModal(
    modelType,
    originalFields
  );

  return (
    <>
      <Card variant="outline" className="py-2 px-3 w-full">
        <div className="flex flex-row justify-between items-center">
          <Text as="span" variant="sm" className="break-words whitespace-pre-wrap truncate">
            <Text as="span" variant="sm" className="font-semibold">
              {numberOfFieldsChanged === 1
                ? "1 field changed:"
                : `${numberOfFieldsChanged} fields changed:`}
            </Text>{" "}
            {displayNamesString}
          </Text>
          <Link
            onClick={() => {
              setIsShowingDiffModal(true);
            }}
            variant="sm"
            target="_blank"
            className="inline-flex flex-row items-center font-semibold text-blue-40 hover:text-blue-60"
          >
            Compare changes <ArrowUpRight className="ml-1.5 flex items-center" size={12} />
          </Link>
        </div>
      </Card>
      {isShowingDiffModal && (
        <DiffModal
          isModalOpen={isShowingDiffModal}
          setIsModalOpen={setIsShowingDiffModal}
          diffModalType={DiffModalTypeEnum.COMPARE}
          modelType={mappedModelType}
          newState={newDiffModalFields}
          title={`${ChangelogModelTypeMap[modelType]} edited`}
          newStateTitle={`Edited version`}
          currentState={originalDiffModalFields ?? {}}
          currentStateTitle={`Previous version`}
        />
      )}
    </>
  );
};

export default FieldsChangedContainer;
