import { Col } from "react-bootstrap";
import styled from "styled-components";
import { palette } from "../../../styles/theme/colors";

export type VerticalLineConfig = "all" | "bottom-half" | "none";

const CenteredVerticalLineCol = styled(Col)<{ $verticalLineConfig: VerticalLineConfig }>`
  background-image: linear-gradient(${palette.border}, ${palette.border});
  background-size: ${({ $verticalLineConfig }) => {
    switch ($verticalLineConfig) {
      case "all":
        return "1px 100%";
      case "bottom-half":
        return "1px 50%";
      case "none":
        return "0px";
    }
  }};
  background-repeat: no-repeat;
  background-position: center top;

  transition: background-size 0.3s ease-out;
`;

export default CenteredVerticalLineCol;
