import { useState, useEffect, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { fetchWithAuth } from "../../../api-client/api_client";
import { Integration, LinkedAccount } from "../../../models/Entities";
import { MappingTestSuiteMeta } from "../../../models/MappingTests";
import { navigateToIndividualTestSuite } from "../../../router/RouterUtils";
import VersionControlSaveModal from "../../blueprint-editor/control-panel/VersionControlSaveModal";
import DropdownFormField from "../../blueprint-editor/right-panel/DropdownFormField";
import MergeModal from "../../shared/MergeModal";
import SpinnerButton from "../../shared/SpinnerButton";
import { showErrorToast } from "../../shared/Toasts";

type Props = {
  integrationMeta: Integration;
  isShown: boolean;
  onHide: () => void;
  integrationID: string;
  fetchMappingTestSuites: () => void;
};

export default function AddNewTestSuiteModal({
  integrationMeta,
  isShown,
  onHide,
  integrationID,
  fetchMappingTestSuites,
}: Props) {
  const categoriesForIntegration = integrationMeta.categories;
  const history = useHistory();

  const [isAdding, setIsAdding] = useState(false);
  const [testLinkedAccounts, setTestLinkedAccounts] = useState<LinkedAccount[]>();
  const [selectedLinkedAccountID, setSelectedLinkedAccountID] = useState<string>();
  const [selectedCategory, setSelectedCategory] = useState<string>(categoriesForIntegration[0]);

  useEffect(() => {
    fetchWithAuth({
      path: `/integrations/linked-accounts?is_test_account=True&is_sandbox_account=True&integration=${integrationID}`,
      method: "GET",
      onResponse: (response: { results: LinkedAccount[] }) => {
        setTestLinkedAccounts(response.results);
      },
      onError: () => showErrorToast("Unable to load linked accounts"),
    });
  }, [integrationID]);

  const filteredTestLinkedAccounts =
    testLinkedAccounts?.filter((linkedAccount) => linkedAccount.category === selectedCategory) ??
    [];

  const addNewTestSuite = useCallback(() => {
    if (selectedLinkedAccountID) {
      setIsAdding(true);
      fetchWithAuth({
        path: `/stallions/integrations/test-suite/create`,
        method: "POST",
        body: {
          linked_account_id: selectedLinkedAccountID,
        },
        onResponse: (testSuite: MappingTestSuiteMeta) => {
          navigateToIndividualTestSuite(history, integrationID, testSuite.id);
          fetchMappingTestSuites();
        },
        onError: () => {
          showErrorToast("Unable to create Mapping Test Suite");
          setIsAdding(false);
        },
      });
    }
  }, [history, integrationID, selectedLinkedAccountID]);

  return (
    <>
      <MergeModal
        show={isShown}
        onHide={onHide}
        title={"Add Test Suite"}
        bodyClassName="overflow-hidden"
      >
        {!testLinkedAccounts ? (
          <VersionControlSaveModal onHide={onHide} />
        ) : (
          <>
            <Row>
              <Col>
                {categoriesForIntegration.length > 1 && (
                  <DropdownFormField
                    placeholder="Select Category"
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                    }}
                    currentValue={selectedCategory}
                    choices={
                      categoriesForIntegration?.map((category) => ({
                        id: category,
                        name: category,
                      })) ?? []
                    }
                  />
                )}
                <DropdownFormField
                  title="Select Linked Account (sandbox accounts only)"
                  placeholder=""
                  disabled={!testLinkedAccounts || !selectedCategory}
                  onChange={(e) => {
                    setSelectedLinkedAccountID(e.target.value);
                  }}
                  currentValue={selectedLinkedAccountID}
                  choices={
                    filteredTestLinkedAccounts?.map(({ id, end_user }) => ({
                      id,
                      name: end_user.organization_name + " - " + id,
                    })) ?? []
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SpinnerButton
                  isLoading={isAdding}
                  disabled={!selectedLinkedAccountID}
                  className="btn btn-primary btn-block"
                  onClick={addNewTestSuite}
                  text="Add Test Suite"
                />
              </Col>
            </Row>
          </>
        )}
      </MergeModal>
    </>
  );
}
