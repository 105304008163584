// useResetReadTest.ts
import { useCallback, useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { navigateToTestSuitesTable } from "../../../../router/RouterUtils";
import { showErrorToast } from "../../../shared/Toasts";
import { useHistory } from "react-router-dom";

interface UseResetReadTestProps {
  integrationID: string;
}

export const useResetReadTest = ({ integrationID }: UseResetReadTestProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();

  const resetReadTest = useCallback(
    (mappingTestSuiteID: string) => {
      setIsLoading(true);
      if (mappingTestSuiteID) {
        fetchWithAuth({
          path: `/stallions/integrations/test-suite/${mappingTestSuiteID}/reset-read-test`,
          method: "PATCH",
          body: {},
          onResponse: () => {
            navigateToTestSuitesTable(history, integrationID);
            setIsLoading(false);
          },
          onError: () => {
            showErrorToast("Unable to delete Mapping Test Suite");
            setIsLoading(false);
          },
        });
      }
    },
    [history, integrationID]
  );

  return {
    isLoading,
    resetReadTest,
  };
};
