import { useContext, useEffect, useState } from "react";
import { PageSizeRequestLocation } from "../../../../models/PaginationConfigurationModels";
import AccordionCard from "../../shared/AccordionCard";
import KeyPathFieldHeader from "../../shared/KeyPathFieldHeader";
import MultiSelectHeader from "../../shared/MultiSelectHeader";
import TextFieldHeader from "../../shared/TextFieldHeader";
import {
  KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER,
  KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER,
  PAGE_SIZE_REQUEST_LOCATION_OPTIONS,
} from "../constants";
import PaginationConfigurationContext from "../context/PaginationConfigurationContext";

interface RequestPageSizeInformationSetupOptionsProps {
  required?: boolean;
}

const RequestPageSizeInformationSetupOptions = ({
  required = false,
}: RequestPageSizeInformationSetupOptionsProps) => {
  const {
    pageSize,
    setPageSize,
    pageSizeRequestLocation,
    setPageSizeRequestLocation,
    pageSizeKeyPath,
    setPageSizeKeyPath,
  } = useContext(PaginationConfigurationContext);

  const [pageSizeText, setPageSizeText] = useState<string>(pageSize?.toString() || "");
  const [isLocationModified, setIsLocationModified] = useState<boolean>(false);

  /* --- START - MULTISELECTBUTTONGROUP LOGIC FOR pageSizeRequestLocation --- */

  const [optionsForPageSizeLocation, setOptionsForPageSizeLocation] = useState<any[]>([]);

  // Update optionsForPageSizeLocation based on changes
  useEffect(() => {
    setOptionsForPageSizeLocation(
      PAGE_SIZE_REQUEST_LOCATION_OPTIONS.map(({ value, text }) => ({
        value,
        text,
        selected: value === pageSizeRequestLocation,
        onClick: () => {
          if (value === pageSizeRequestLocation) {
            setPageSizeRequestLocation(null);
          } else {
            setIsLocationModified(true);
            setPageSizeRequestLocation(value);
          }
        },
      }))
    );
  }, [pageSizeRequestLocation]);

  // Function to clear value of pageSizeRequestLocation
  const clearOptionsForPageSizeLocation = () => {
    setPageSizeRequestLocation(null);
    setIsLocationModified(true);
  };

  // Clear the key path when the location is changed but not on initial render
  useEffect(() => {
    if (isLocationModified) {
      setPageSizeKeyPath([]);
    }
  }, [pageSizeRequestLocation]);

  /* --- END - MULTISELECTBUTTONGROUP LOGIC FOR pageSizeRequestLocation --- */

  // when the page size text changes, parse it to an integer and update the page size or set it to 0 if it's not a number
  useEffect(() => {
    if (pageSizeText === "") {
      setPageSize(null);
    } else {
      setPageSize(parseInt(pageSizeText));
    }
  }, [pageSizeText]);

  return (
    <AccordionCard
      dataTestID="accordion-pagination-config-page-size-information"
      title="Page size information"
      defaultExpanded={required}
      required={required}
      variant="outline"
      nested
    >
      <TextFieldHeader
        dataTestID="field-pagination-config-page-size"
        title="Page size"
        subtitle={
          "The number of items that are returned per request" +
          (required
            ? ". Required for offset pagination, otherwise backend logic may not successfuly paginate through all pages."
            : "")
        }
        placeholder="Value"
        hasSource={false}
        value={pageSizeText}
        onChange={(e) => {
          if (/^\d*$/.test(e.target.value)) {
            setPageSizeText(e.target.value);
          }
        }}
        required={required}
      />
      <MultiSelectHeader
        dataTestID="field-pagination-config-page-size-location"
        className="mt-6"
        title="Location of page size in request"
        subtitle="Where the page size is inserted in the request"
        options={optionsForPageSizeLocation}
        clearOptions={clearOptionsForPageSizeLocation}
      />
      {/** TODO: https://app.asana.com/0/1205644398660644/1206923763851312/f Modify the key path field header component to have a max depth instead of using text field conditionally */}
      {pageSizeRequestLocation === PageSizeRequestLocation.QUERY_PARAM && (
        <div className="border-l-2 pl-8 mt-6 border-gray-10">
          <TextFieldHeader
            dataTestID="field-pagination-config-page-size-key"
            title="Key to page size"
            subtitle="What query param should the pagination value be inserted into?"
            placeholder="Key"
            hasSource={false}
            value={pageSizeKeyPath && pageSizeKeyPath.length > 0 ? pageSizeKeyPath[0] : ""}
            onChange={(e) => setPageSizeKeyPath([e.target.value])}
          />
        </div>
      )}
      {pageSizeRequestLocation === PageSizeRequestLocation.BODY_PARAM && (
        <div className="border-l-2 pl-8 mt-6 border-gray-10">
          <KeyPathFieldHeader
            dataTestID="field-pagination-config-page-size-key-path"
            title="Key path to page size"
            subtitle="Where in the request body should the pagination value be inserted into?"
            placeholder={
              pageSizeKeyPath?.length
                ? KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER
                : KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER
            }
            hasSource={false}
            keyPath={pageSizeKeyPath}
            onKeyPathChange={setPageSizeKeyPath}
          />
        </div>
      )}
    </AccordionCard>
  );
};

export default RequestPageSizeInformationSetupOptions;
