import { useRef, useState } from "react";
import { getJSONSchemaFromExampleJSON } from "../utils/IntegrationsUtils";
import { Button } from "react-bootstrap";
import Editor from "react-simple-code-editor";
import EditableJSONSchemaTree from "../json-schema/EditableJSONSchemaTree";
import styled from "styled-components";
import { Upload } from "lucide-react";
import { fetchWithAuth } from "../../../api-client/api_client";
import { isValidJSON } from "../../../utils";
import DeprecatedH2 from "../../deprecated/DeprecatedH2";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";

const { highlight, languages } = require("prismjs");

interface Props {}

const UploadButton = styled(Button)`
  border-radius: 6px;
  border: 1px solid var(--lm-blue-40, #075ff7);
  background: #fff;
  color: #075ff7;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const ReportTemplateFileSchemaEditor = ({}: Props) => {
  const [sampleSchemaValue, setSampleSchemaValue] = useState("");
  const [JSONSchemaValue, setJSONSchemaValue] = useState("");
  const isJSON = isValidJSON(JSONSchemaValue);
  const [isJSONSchemaShowing, setIsJSONSchemaShowing] = useState(false);

  const isValidInput = (code: string) => {
    return isValidJSON(code);
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      getFileDataAsJSON(event.target.files[0]);
    }
  };

  const getFileDataAsJSON = (file: File) => {
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }

    fetchWithAuth({
      path: `/integrations/reports/sftp/get-test-file-json-data`,
      method: "POST",
      body: { file },
      onResponse: (data) => {
        setSampleSchemaValue(JSON.stringify({ report_rows: data?.report_rows ?? [] }));
        setJSONSchemaValue(
          getJSONSchemaFromExampleJSON(JSON.stringify({ report_rows: data?.report_rows ?? [] }))
        );
        setIsJSONSchemaShowing(true);
      },
    });
  };

  return (
    <div
      className="log-side-panel"
      style={{
        position: "fixed",
        width: "30%",
        height: "100%",
        zIndex: 1000,
        padding: "36px",
      }}
    >
      <>
        <DeprecatedH2 className="mb-4">JSON Schema Converter</DeprecatedH2>
        <p className="text-muted small-text mb-3">
          Param schemas require arguments to be in the form of JSON schema.{" "}
        </p>
        <p className="text-muted small-text mb-3">
          {" "}
          Upload a file to convert into interpreted JSON schema, and then copy and paste the output
          into the relevant schema field{" "}
        </p>
        <p className="text-muted small-text mb-9">
          {" "}
          Consult the schema tree diagram to verify that there are no errors or unknown fields.
          Modify the fields by clicking on the red highlighted text and selecting the correct type.
        </p>

        <div>
          <input
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            ref={fileInputRef}
          />
          <UploadButton className="btn-block" onClick={handleUploadButtonClick}>
            <Upload size={"16px"} className="mr-1" /> <b>Upload File</b>
          </UploadButton>
        </div>

        <DeprecatedH4>Sample JSON</DeprecatedH4>
        <div className="json-text-box">
          <Editor
            highlight={(code) => highlight(code, languages.js)}
            value={sampleSchemaValue ?? ""}
            onValueChange={async (code) => {
              let toConvert = code;
              try {
                setSampleSchemaValue(code);
                setJSONSchemaValue(getJSONSchemaFromExampleJSON(toConvert ?? ""));
              } catch {
                //eslint-disable-next-line no-empty
              }
            }}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
              minHeight: "98px",
              border: 0,
            }}
            padding={10}
          />
        </div>
        <div style={{ marginTop: 24 }}>
          <Button
            className="btn-block btn-primary"
            onClick={() => setIsJSONSchemaShowing(true)}
            disabled={!isValidInput(sampleSchemaValue)}
          >
            <b>Convert</b>
          </Button>
        </div>

        <DeprecatedH4 className="mt-9">JSON Schema</DeprecatedH4>
        <Editor
          highlight={(code) => highlight(code, languages.js)}
          value={isJSONSchemaShowing ? JSONSchemaValue : ""}
          onValueChange={(code) => {
            setJSONSchemaValue(code);
          }}
          padding={10}
          style={{
            backgroundColor: "white",
            border: "1px solid #d2ddec",
            borderRadius: 8,
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
            minHeight: "100px",
            overflow: "auto",
          }}
        />
        <div className="mt-9">
          <DeprecatedH4>Schema Tree Diagram</DeprecatedH4>
          {isJSONSchemaShowing && JSONSchemaValue && isJSON ? (
            <EditableJSONSchemaTree
              jsonSchema={JSON.parse(JSONSchemaValue)}
              setJSONSchema={setJSONSchemaValue}
            />
          ) : (
            <p className="mb-3">
              The Schema tree diagram will render after converting sample schema.
            </p>
          )}
        </div>
      </>
    </div>
  );
};

export default ReportTemplateFileSchemaEditor;
