import { useCallback, useRef } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast } from "../../../shared/Toasts";
import { RateLimitConfigurationIntegrationBuilder } from "../../utils/Entities";

const POLL_INTERVAL_MS = 5000;

interface pollRateLimitConfigurationsSummaryProps {
  setRateLimitConfigurations: React.Dispatch<
    React.SetStateAction<RateLimitConfigurationIntegrationBuilder[] | undefined>
  >;
  setIsLoadingRateLimitConfigurations: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAutogeneratingRateLimitConfiguration: React.Dispatch<React.SetStateAction<boolean>>;
}

interface RateLimitConfigurationSummary {
  rate_limits: RateLimitConfigurationIntegrationBuilder[];
  is_generating_rate_limit: boolean;
}

const usePollRateLimitConfigurationsSummary = ({
  setRateLimitConfigurations: setRateLimitConfigurations,
  setIsLoadingRateLimitConfigurations: setIsLoadingRateLimitConfigurations,
  setIsAutogeneratingRateLimitConfiguration: setIsAutogeneratingRateLimitConfiguration,
}: pollRateLimitConfigurationsSummaryProps) => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const pollRateLimitConfigurations = useCallback(
    (integrationID: string) => {
      const poll = () => {
        fetchWithAuth({
          path: `/integrations/integration-builder/integration/${integrationID}/rate-limits-summary`,
          method: "GET",
          onResponse: (data: RateLimitConfigurationSummary) => {
            setRateLimitConfigurations(data.rate_limits);
            setIsAutogeneratingRateLimitConfiguration(data.is_generating_rate_limit);
            setIsLoadingRateLimitConfigurations(false);
          },
          onError: () => {
            showErrorToast("Failed to fetch rate limits for integration.");
            setRateLimitConfigurations([]);
            setIsLoadingRateLimitConfigurations(false);
          },
        });
      };

      // Initial call to poll
      setIsLoadingRateLimitConfigurations(true);
      poll();

      // Setup to poll every 5 seconds
      intervalRef.current = setInterval(poll, POLL_INTERVAL_MS);
    },
    [setRateLimitConfigurations]
  );

  const stopPollingRateLimitConfigurations = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  return { pollRateLimitConfigurations, stopPollingRateLimitConfigurations };
};

export default usePollRateLimitConfigurationsSummary;
