import { useCallback, useRef } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast } from "../../../shared/Toasts";
import { PaginationConfigurationIntegrationBuilder } from "../../utils/Entities";

const POLL_INTERVAL_MS = 5000;

interface pollPaginationConfigurationsSummaryProps {
  setPaginationConfigurations: React.Dispatch<
    React.SetStateAction<PaginationConfigurationIntegrationBuilder[] | undefined>
  >;
  setIsLoadingPaginationConfigurations: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAutogeneratingPaginationConfiguration: React.Dispatch<React.SetStateAction<boolean>>;
}

interface PaginationConfigurationSummary {
  pagination_configurations: PaginationConfigurationIntegrationBuilder[];
  is_generating_pagination_configuration: boolean;
}

const usePollPaginationConfigurationsSummary = ({
  setPaginationConfigurations,
  setIsLoadingPaginationConfigurations,
  setIsAutogeneratingPaginationConfiguration,
}: pollPaginationConfigurationsSummaryProps) => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const pollPaginationConfigurations = useCallback(
    (integrationID: string) => {
      const poll = () => {
        fetchWithAuth({
          path: `/integrations/integration-builder/integration/${integrationID}/pagination-configurations-summary`,
          method: "GET",
          onResponse: (data: PaginationConfigurationSummary) => {
            setPaginationConfigurations(data.pagination_configurations);
            setIsAutogeneratingPaginationConfiguration(data.is_generating_pagination_configuration);
            setIsLoadingPaginationConfigurations(false);
          },
          onError: () => {
            showErrorToast("Failed to fetch pagination configurations for integration.");
            setPaginationConfigurations([]);
            setIsLoadingPaginationConfigurations(false);
          },
        });
      };

      // Initial call to poll
      setIsLoadingPaginationConfigurations(true);
      poll();

      // Setup to poll every 5 seconds
      intervalRef.current = setInterval(poll, POLL_INTERVAL_MS);
    },
    [setPaginationConfigurations]
  );

  const stopPollingPaginationConfigurations = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  return { pollPaginationConfigurations, stopPollingPaginationConfigurations };
};

export default usePollPaginationConfigurationsSummary;
