import { Spinner, Text } from "@merge-api/merge-javascript-shared";

interface TableRowLoadingProps {
  numCols: number;
  title?: string;
}

const TableRowLoading = ({ numCols, title }: TableRowLoadingProps) => {
  return (
    <tr>
      {Array.from({ length: numCols }).map((_, index) => {
        if (index === 0) {
          return (
            <td key={index}>
              <Text variant="inherit" className="text-gray-60 text-base font-medium">
                {title || "Loading..."}
              </Text>
            </td>
          );
        }
        if (index === numCols - 1) {
          return (
            <td key={index}>
              <div className="flex flex-row justify-end">
                <Spinner size="md" />
              </div>
            </td>
          );
        }
        return <td key={index}></td>;
      })}
    </tr>
  );
};

export default TableRowLoading;
