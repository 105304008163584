import { Link, Text } from "@merge-api/merge-javascript-shared";
import { IntegrationValidatorRuleOverride } from "../types";

interface Props {
  override: IntegrationValidatorRuleOverride | null;
}

const IntegrationValidatorRuleOverrideDetails = ({ override }: Props) => {
  if (!override) {
    return null;
  }

  const {
    comment: overrideComment,
    user_name: overrideUserName,
    user_email: overrideUserEmail,
    asana_url: overrideAsanaUrl,
  } = override;

  if (!overrideComment) {
    return null;
  }

  return (
    <div className="ml-9 flex flex-col">
      {overrideUserName && (
        <Text className="text-gray-70">
          {`Overridden by ${overrideUserName}`}
          {overrideUserEmail && ` (${overrideUserEmail})`}
        </Text>
      )}
      {overrideComment && (
        <Text className="text-gray-70">
          {`Comment: ${overrideComment}`}
          {overrideAsanaUrl && (
            <Text>
              {" ("}
              <Link target="_blank" href={overrideAsanaUrl}>
                Ticket
              </Link>
              {")"}
            </Text>
          )}
        </Text>
      )}
    </div>
  );
};

export default IntegrationValidatorRuleOverrideDetails;
