import { Text } from "@merge-api/merge-javascript-shared";
import StepIOLogsTableCardSectionRow from "./StepIOLogsTableCardSectionRow";

interface Props {
  title: string;
  data: { [key: string]: any } | null;
}

/**
 * Renders a section of a single step I/O log
 * Can be used for Input, Output, and Variable diff
 * @param {string} title - The title of the section (e.g., "Input", "Output")
 * @param {Object|null} data - The data to be displayed in the section
 */
const StepIOLogsTableCardSection = ({ title, data }: Props) => {
  const dataArray = data ? Object.entries(data) : [];

  if (dataArray.length === 0) {
    return (
      <div className="flex flex-col">
        <Text variant="title-sm" className="px-1">
          {title}
        </Text>
        <Text variant="sm-mono" className="text-gray-50 px-1">
          No data
        </Text>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <Text variant="title-sm" className="px-1">
        {title}
      </Text>
      {dataArray.map(([dataKey, value]) => (
        <StepIOLogsTableCardSectionRow dataKey={dataKey} dataValue={value} />
      ))}
    </div>
  );
};

export default StepIOLogsTableCardSection;
