import { BlueprintGenericStepTemplate } from "../../../models/Blueprints";
import JSONSchemaTreeDiagram from "../../shared/JSONSchemaTreeDiagram";
import TypeaheadFormField from "./TypeaheadFormField";
import { HeaderPretitle } from "../../shared/text/MergeText";

interface Props {
  stepID: string;
  stepTemplate: BlueprintGenericStepTemplate;
}

const NON_SORTED_STEP_TEMPLATE_TYPES = [
  "STEP_TYPE_WRITE_GLOBAL_VARIABLE",
  "STEP_TYPE_READ_GLOBAL_VARIABLE",
]; // Don't want to add to the type because I'm afraid it'd mess with generics lol

const BlueprintEditorRightPanelGenericStepForm = ({ stepID, stepTemplate }: Props) => {
  const parameters = Object.entries(stepTemplate.parameter_schema?.properties ?? {});

  return (
    <>
      <form>
        <HeaderPretitle className="my-2">{"Parameter Values"}</HeaderPretitle>
        {(NON_SORTED_STEP_TEMPLATE_TYPES.includes(stepTemplate.step_type)
          ? parameters
          : parameters.sort()
        ).map(([key, parameter]) => {
          return (
            <TypeaheadFormField
              key={key}
              title={parameter.title}
              subtitle={parameter.description}
              valueKey={key}
              parameterType={parameter.type}
              choices_override={parameter?.enum}
              choiceNamesOverride={parameter?.enumNames}
            />
          );
        })}
      </form>
      <JSONSchemaTreeDiagram stepID={stepID} jsonSchema={stepTemplate.return_schema} />
    </>
  );
};

export default BlueprintEditorRightPanelGenericStepForm;
