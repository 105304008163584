import { useState } from "react";
import { useEffect } from "react";
import { fetchWithAuth } from "../../../api-client/api_client";
import { HTTPMethod } from "../../../models/HTTPMethods";
import { showErrorToast } from "../Toasts";

type Props = {
  path: string;
  headers?: { [key: string]: string };
  body?: { [key: string]: any };
  method: HTTPMethod;
};

const useRequest = <Data extends unknown>({
  path,
  method,
  headers,
  body,
}: Props): { data: undefined | Data; hasError: boolean; isLoading: boolean } => {
  const [data, setData] = useState<Data>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    fetchWithAuth({
      path,
      method,
      headers,
      body,
      onResponse: (data: Data) => {
        setIsLoading(false);
        setData(data);
      },
      onError: () => {
        setIsLoading(false);
        setHasError(true);
        showErrorToast("Something went wrong. Please post in #bpe-and-admin-discussions!");
      },
    });
  }, [body, path, headers, method, setIsLoading, setHasError, setData]);

  return { data, hasError, isLoading };
};

export default useRequest;
