import { Badge, Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import { CircleCheck, CircleOff, OctagonX, RefreshCw } from "lucide-react";
import { useEffect, useState } from "react";
import { IntegrationTestSuiteExecution } from "../../../../models/MappingTests";
import { palette } from "../../../../styles/theme/colors";
import { usePublishModuleContext } from "../context/PublishModuleContext";
import PublishModuleOverrideChecksModal from "../IntegrationStagedVersions/PublishModuleOverrideChecksModal";
import { countIntegrationTestSuiteMappingTestExecutionFailures } from "../publishModuleUtils";

const MappingTestAccordionTitleV2 = () => {
  const {
    integrationID,
    onRunTestsForStagedIntegrationVersion,
    integrationTestSuiteExecution,
    isRunningMappingTests,
    setOverrideComment,
    setOverrideTicket,
    areMappingTestsFailing,
    setAreMappingTestsFailing,
  } = usePublishModuleContext();
  const [isOverrideModalOpen, setisOverrideModalOpen] = useState<boolean>(false);
  const [failureCount, setfailureCount] = useState<number>(0);

  useEffect(() => {
    setfailureCount(
      integrationTestSuiteExecution
        ? countIntegrationTestSuiteMappingTestExecutionFailures(integrationTestSuiteExecution)
        : 0
    );
  }, [integrationTestSuiteExecution]);

  const hasMappingTestExecutions = (
    integrationTestSuiteExecution: IntegrationTestSuiteExecution | null
  ): boolean =>
    (integrationTestSuiteExecution?.linked_account_test_suite_executions ?? []).some(
      (testSuiteExecution) => (testSuiteExecution.mapping_test_executions?.length ?? 0) > 0
    );

  return (
    <div className="flex flex-row justify-between w-full">
      <div className="flex flex-row justify-start items-center space-x-3">
        {isRunningMappingTests ? (
          <RefreshCw className="rotating" color={palette.blue} size={16} />
        ) : !hasMappingTestExecutions(integrationTestSuiteExecution) ? (
          <CircleOff size={16} className="text-gray-60" />
        ) : failureCount > 0 ? (
          <OctagonX size={16} className="text-red-70" />
        ) : (
          <CircleCheck size={16} className="text-teal-70" />
        )}
        <Text variant="h5" className="text-black">
          Mapping tests
        </Text>
        {!isRunningMappingTests && failureCount > 0 && (
          <Badge color="red">
            {failureCount.toString() + (failureCount > 1 ? " failures" : " failure")}
          </Badge>
        )}
      </div>
      <div className="mr-6">
        {isRunningMappingTests ? (
          <Button
            size="sm"
            variant={ButtonVariant.TertiaryWhite}
            leftIcon={<RefreshCw className="rotating" size={12} />}
            disabled
          >
            Re-run
          </Button>
        ) : (
          <div className="flex flex-row items-center space-x-3">
            {areMappingTestsFailing && (
              <>
                <PublishModuleOverrideChecksModal
                  open={isOverrideModalOpen}
                  onClose={() => setisOverrideModalOpen(false)}
                  integrationID={integrationID}
                  setOverrideComment={setOverrideComment}
                  setOverrideTicket={setOverrideTicket}
                  setCanPublish={(canPublish: boolean) => setAreMappingTestsFailing(!canPublish)}
                />
                <Button
                  variant={ButtonVariant.TextBlack}
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    setisOverrideModalOpen(true);
                  }}
                >
                  Override
                </Button>
              </>
            )}
            <Button
              size="sm"
              variant={ButtonVariant.TertiaryWhite}
              leftIcon={<RefreshCw size={12} />}
              onClick={(e) => {
                e.stopPropagation();
                onRunTestsForStagedIntegrationVersion();
              }}
            >
              Re-run
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MappingTestAccordionTitleV2;
