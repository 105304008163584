import { useEffect, useState } from "react";
import { fetchWithAuth } from "../../../api-client/api_client";
import { Button, ButtonVariant, Card, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { BlueprintValidatorResultOptions, PublishIntegrationVersionRequestBodyType } from "./types";
import EmptyStateWrapper from "../../shared/layout/EmptyStateWrapper";
import { showErrorToast, showSuccessToast } from "../../shared/Toasts";
import useInterval from "../../mapping-tests/utils/useInterval";

import { IntegrationTestSuiteExecutionStatus } from "../../../models/MappingTests";
import PublishModulePublishModal from "./IntegrationStagedVersions/PublishModulePublishModal";
import PublishModuleStagedVersionsCard from "./IntegrationStagedVersions/PublishModuleStagedVersionsCard";
import PublishModuleChecksCard from "./IntegrationPublishChecks/PublishModuleChecksCard";
import PublishModuleOverrideChecksModal from "./IntegrationStagedVersions/PublishModuleOverrideChecksModal";
import useLoadMappingTestExecutions from "./hooks/useLoadMappingTestExecutions";
import useLoadValidation from "./hooks/useLoadValidation";
import useLoadChecklist from "./hooks/useLoadChecklist";
import useLoadStagedVersions from "./hooks/useLoadStagedVersions";

type Props = {
  integrationID: string;
};

const LoadingSpinnerCard = () => {
  return (
    <Card className="flex justify-center align-center">
      <EmptyStateWrapper isSpinner />
    </Card>
  );
};

const IntegrationPublishModuleSubtabWithValidator = ({ integrationID }: Props) => {
  const [canPublish, setCanPublish] = useState<boolean>(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState<boolean>(false);
  const [isOverrideModalOpen, setisOverrideModalOpen] = useState<boolean>(false);
  const [overrideComment, setOverrideComment] = useState<string | undefined>();
  const [overrideTicket, setOverrideTicket] = useState<string | undefined>();
  const [isSaving, setIsSaving] = useState(false);

  const { publishModuleInfo, fetchStagedVersions } = useLoadStagedVersions({
    integrationID: integrationID,
  });

  const {
    isFetchingLatestTestSuiteExecution,
    hasFailedFetchingLatestExecution,
    integrationTestSuiteExecution,
    isRunningMappingTests,
    fetchLatestIntegrationVersionTestSuiteExecution,
    onRunTestsForStagedIntegrationVersion,
  } = useLoadMappingTestExecutions({ integrationID: integrationID });

  const { blueprintValidatorInfo, isRunningValidator, runAllValidationChecks } = useLoadValidation({
    publishModuleInfo: publishModuleInfo,
  });

  const {
    isRefreshingChecklist,
    integrationChecklist,
    refreshIntegrationChecklist,
    fetchIntegrationChecklist,
  } = useLoadChecklist({ integrationID: integrationID });

  // Triggered by "Unstage" button to execute all checks again, esp when unstaging a version
  const onRefreshAllChecks = () => {
    fetchStagedVersions();
    onRunTestsForStagedIntegrationVersion();
    runAllValidationChecks();
    refreshIntegrationChecklist();
  };

  useEffect(() => {
    if (!isSaving) {
      fetchIntegrationChecklist();
      fetchStagedVersions();
      fetchLatestIntegrationVersionTestSuiteExecution();
    }
  }, [integrationID, isSaving]);

  useEffect(() => {
    // We can now run the validator for staged changes
    runAllValidationChecks();
  }, [publishModuleInfo]);

  useEffect(() => {
    // Checking to see if there are viable changes and they have all passed
    setCanPublish(
      !!(
        publishModuleInfo &&
        publishModuleInfo?.has_changes &&
        publishModuleInfo.can_publish &&
        blueprintValidatorInfo &&
        Object.values(blueprintValidatorInfo).every(
          (result) => result.result != BlueprintValidatorResultOptions.FAILURE
        )
      )
    );
  }, [publishModuleInfo, blueprintValidatorInfo]);

  useInterval(
    () => {
      fetchLatestIntegrationVersionTestSuiteExecution();
      fetchStagedVersions();
    },
    (integrationTestSuiteExecution &&
      integrationTestSuiteExecution.status !== IntegrationTestSuiteExecutionStatus.IN_PROGRESS) ||
      hasFailedFetchingLatestExecution
      ? null
      : 5000
  );

  const onPublish = ({
    ticket,
    description,
    override_comment,
    override_ticket,
    blueprint_advanced_configurations = {},
  }: PublishIntegrationVersionRequestBodyType) => {
    const overriddenResults =
      override_comment || override_ticket
        ? Object.fromEntries(
            Object.keys(blueprintValidatorInfo).map((key) => [key, blueprintValidatorInfo[key].id])
          )
        : {};
    setIsPublishModalOpen(false);
    setIsSaving(true);
    fetchWithAuth({
      path: `/integrations/versioning/${integrationID}/publish-version`,
      method: "POST",
      body: {
        ticket,
        description,
        blueprint_advanced_configurations,
        override_comment,
        override_ticket,
        blueprint_validator_results: overriddenResults,
      },
      onResponse: () => {
        showSuccessToast("Successfully published versions!");
        setIsSaving(false);
        fetchStagedVersions();
      },
      onError: () => {
        showErrorToast("Error publishing all versions.");
        setIsSaving(false);
      },
    });
  };

  return (
    <div className="flex flex-column my-1 mb-5">
      <div className="flex-1">
        <div className="flex flex-row justify-between">
          <PublishModulePublishModal
            open={isPublishModalOpen}
            onPublish={onPublish}
            onClose={() => setIsPublishModalOpen(false)}
            stagedChanges={publishModuleInfo?.staged_changes}
            integrationID={integrationID}
            overrideComment={overrideComment}
            overrideTicket={overrideTicket}
          />
          <PublishModuleOverrideChecksModal
            open={isOverrideModalOpen}
            onClose={() => setisOverrideModalOpen(false)}
            integrationID={integrationID}
            setOverrideComment={setOverrideComment}
            setOverrideTicket={setOverrideTicket}
            setCanPublish={setCanPublish}
          />
        </div>
        {!publishModuleInfo ? (
          <LoadingSpinnerCard />
        ) : (
          <PublishModuleStagedVersionsCard
            publishModuleInfo={publishModuleInfo}
            integrationID={integrationID}
            onRefreshAllChecks={onRefreshAllChecks}
          />
        )}
      </div>
      {publishModuleInfo?.has_changes && (
        <div className="flex-1 space-y-6 min-w-[700px] mb-12">
          <div className="flex flex-row justify-between items-center">
            <Text variant="h3">Checks</Text>
            <Button
              size="sm"
              variant={ButtonVariant.TertiaryWhite}
              className="text-red-50"
              onClick={() => setisOverrideModalOpen(true)}
            >
              Override
            </Button>
          </div>
          {!blueprintValidatorInfo ? (
            <LoadingSpinnerCard />
          ) : (
            <PublishModuleChecksCard
              integrationID={integrationID}
              integrationChecklist={integrationChecklist}
              blueprintValidatorInfo={blueprintValidatorInfo}
              integrationTestSuiteExecution={integrationTestSuiteExecution}
              hasFailedFetchingLatestExecution={hasFailedFetchingLatestExecution}
              onRunTestsForStagedIntegrationVersion={onRunTestsForStagedIntegrationVersion}
              fetchIntegrationChecklist={fetchIntegrationChecklist}
              runAllValidationChecks={runAllValidationChecks}
              isRunningMappingTests={isRunningMappingTests}
              isRunningValidator={isRunningValidator}
              publishModuleInfo={publishModuleInfo}
              isRefreshingChecklist={isRefreshingChecklist}
              refreshIntegrationChecklist={refreshIntegrationChecklist}
              isFetchingLatestTestSuiteExecution={isFetchingLatestTestSuiteExecution}
            />
          )}
        </div>
      )}
      {!canPublish ? (
        <div>
          <Tooltip
            title={
              !publishModuleInfo || !publishModuleInfo?.has_changes
                ? "No staged versions to publish."
                : "Cannot publish due to failed or in-progress test executions. See above for more details."
            }
          >
            <Button disabled>Publish staged versions</Button>
          </Tooltip>
        </div>
      ) : (
        <Button
          loading={isSaving}
          disabled={isSaving || !canPublish}
          onClick={() => setIsPublishModalOpen(true)}
        >
          Publish staged versions
        </Button>
      )}
    </div>
  );
};

export default IntegrationPublishModuleSubtabWithValidator;
