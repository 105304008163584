import { Button, ButtonVariant, Badge, Text } from "@merge-api/merge-javascript-shared";
import {
  BlueprintValidatorResult,
  BlueprintValidatorRuleResultOptions,
  BlueprintValidatorRuleResultType,
} from "../types";
import { CheckCircle, OctagonX, RefreshCw, TriangleAlert } from "lucide-react";
import { palette } from "../../../../styles/theme/colors";
import { useEffect, useState } from "react";

type Props = {
  blueprintValidatorInfo: Record<string, BlueprintValidatorResult>;
  integrationID: string;
  runAllValidationChecks: () => void;
  isRunningValidator: boolean;
};

const BlueprintValidationAccordionTitle = ({
  blueprintValidatorInfo,
  runAllValidationChecks,
  isRunningValidator,
}: Props) => {
  const [failureCount, setFailureCount] = useState<number>(0);
  const [warningCount, setWarningCount] = useState<number>(0);

  useEffect(() => {
    let newFailureCount = 0;
    let newWarningCount = 0;

    Object.values(blueprintValidatorInfo).forEach((result) => {
      result.rule_results.forEach((rule_result) => {
        if (rule_result.result == BlueprintValidatorRuleResultOptions.FAILURE) {
          rule_result.result_type == BlueprintValidatorRuleResultType.WARNING
            ? newWarningCount++
            : newFailureCount++;
        }
      });
    });

    setFailureCount(newFailureCount);
    setWarningCount(newWarningCount);
  }, [blueprintValidatorInfo, isRunningValidator]);

  return (
    <div className="flex flex-row justify-between w-full">
      <div className="flex flex-row justify-start items-center space-x-4">
        {isRunningValidator ? (
          <RefreshCw className="rotating" color={palette.blue} size={24} />
        ) : failureCount > 0 ? (
          <OctagonX color={palette.red} />
        ) : warningCount > 0 ? (
          <TriangleAlert color={palette.yellow} />
        ) : (
          <CheckCircle color={palette.green} />
        )}
        <Text variant="h5" className="text-black">
          Blueprint validation
        </Text>
        <div className="space-x-2">
          {!isRunningValidator && failureCount > 0 && (
            <Badge color="red">
              {failureCount.toString() + (failureCount > 1 ? " failures" : " failure")}
            </Badge>
          )}
          {!isRunningValidator && warningCount > 0 && (
            <Badge color="yellow">
              {warningCount.toString() + (warningCount > 1 ? " warnings" : " warning")}
            </Badge>
          )}
        </div>
      </div>
      <div className="mr-6">
        {isRunningValidator ? (
          <Button
            size="sm"
            variant={ButtonVariant.TertiaryWhite}
            leftIcon={<RefreshCw className="rotating" size={12} />}
            disabled
          >
            Re-run
          </Button>
        ) : (
          <Button
            size="sm"
            variant={ButtonVariant.TertiaryWhite}
            leftIcon={<RefreshCw size={12} />}
            onClick={(e) => {
              e.stopPropagation();
              runAllValidationChecks();
            }}
          >
            Re-run
          </Button>
        )}
      </div>
    </div>
  );
};

export default BlueprintValidationAccordionTitle;
