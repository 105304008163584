import { fetchWithAuth } from "../../../../api-client/api_client";
import { APIEndpointMethod } from "../../../../models/Entities";

interface Props {
  integrationID: string;
}

const useAutogeneratedAPIEndpoints = ({ integrationID }: Props) => {
  const autogenerateAPIEndpoints = async (endpointPreviewIDs: string[]) => {
    fetchWithAuth({
      path: `/integrations/integration-builder/integration/${integrationID}/autogenerated-api-endpoints`,
      method: APIEndpointMethod.POST,
      body: { api_endpoint_preview_ids: endpointPreviewIDs },
      onResponse: () => {},
      onError: () => {
        console.error("Failed to initiate API endpoints generation.");
      },
    });
  };

  return { autogenerateAPIEndpoints };
};

export default useAutogeneratedAPIEndpoints;
