import { ChangeEvent, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import styled from "styled-components";
import { FetchReadmeProps, UpdateReadmeProps } from "../../../api-client/ReadmeAPIClient";
import Readme from "../../../models/Readme";
import { palette } from "../../../styles/theme/colors";
import Spinner from "../Spinner";
import Markdown from "markdown-to-jsx";
import { showErrorToast } from "../Toasts";
import { Prompt } from "react-router";
import { HeaderPretitle } from "../text/MergeText";
import ClickableContainer from "../ClickableContainer";
import Icon from "../Icon";

const TOP_CONTAINER_MAX_HEIGHT = 32;
const TOP_CONTAINER_MIN_HEIGHT = 8;
const TOP_CONTAINER_CONTENT_OVERLAP = TOP_CONTAINER_MAX_HEIGHT - TOP_CONTAINER_MIN_HEIGHT;

export const DEFAULT_TEMPLATE = `# Heading 1
* Sample bullet points
  * Nested bullet point
    * Nested bullet point

**Bold text**

*Italicized text*

## Heading 2
1. First item
2. Second item

## Heading 3

\`\`\`
def hello_world():
  print("Hello World!")
\`\`\`

[Link to docs](https://docs.merge.dev)
`;

const OverviewSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  padding-bottom: 16px;
`;
const TopContainer = styled.header.attrs({ className: "w-100" })`
  position: sticky;
  height: ${TOP_CONTAINER_MAX_HEIGHT}px;
  top: -${TOP_CONTAINER_CONTENT_OVERLAP}px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${palette.border};
  background: ${palette.white};
`;

const TopContent = styled.div.attrs({ className: "w-100" })`
  position: sticky;
  height: ${TOP_CONTAINER_MIN_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledButton = styled(Button).attrs({ size: "sm", variant: "light" })`
  background-color: white;
  border: 1px #e3ebf6 solid;
`;

const BodyContainer = styled.div.attrs({ className: "w-100" })`
  padding: 0px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

interface TryAgainViewProps {
  onClick: () => void;
}

const TryAgainDiv = styled.div.attrs({ className: "w-100" })`
  display: flex;
  justify-content: space-between;
`;

const TryAgainView = ({ onClick }: TryAgainViewProps) => (
  <TryAgainDiv>
    <span>Failed to load readme</span>
    <StyledButton onClick={onClick}>
      <i className="fe fe-refresh-cw mr-1.5" />
      Try Again
    </StyledButton>
  </TryAgainDiv>
);

const Editor = styled(Form.Control).attrs({ as: "textarea", className: "w-100" })`
  height: calc(100vh - 350px - 40px - 1rem);
  box-sizing: border-box;
  resize: none;
`;

const Viewer = styled(Markdown).attrs({ className: "pb-6 w-100 prose prose-sm" })``;

type ReadmePanelProps<T extends Readme> = {
  fetchReadme: (props: Omit<FetchReadmeProps<T>, "path">) => void;
  updateReadme: (props: Omit<UpdateReadmeProps<T>, "path">) => void;
};

function OverviewReadmePanel<T extends Readme>({ fetchReadme, updateReadme }: ReadmePanelProps<T>) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
  const [text, setText] = useState<string | undefined | null>(null);
  const [editedText, setEditedText] = useState<string | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);

  const fetchAndSetText = () => {
    setIsLoading(true);
    setHasErrorOccurred(false);
    fetchReadme({
      onSuccess: (data) => {
        setIsLoading(false);
        setHasErrorOccurred(false);
        setText(data.text);
      },
      onError: (error) => {
        setIsLoading(false);
        if (error?.status === 404) {
          setHasErrorOccurred(false);
          setText(undefined);
        } else {
          setHasErrorOccurred(true);
        }
      },
    });
  };

  const updateText = (text: string) => {
    setIsLoading(true);
    updateReadme({
      onSuccess: (data) => {
        setIsLoading(false);
        setText(data.text);
        setEditedText(undefined);
        setIsEditing(false);
      },
      onError: () => {
        setIsLoading(false);
        showErrorToast("Failed to save readme.");
      },
      text,
    });
  };

  // We want something like componentDidMount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchAndSetText, []);

  return (
    <OverviewSectionContainer className="mb-3">
      <Prompt
        when={isEditing && text !== editedText}
        message={"Are you sure you want to leave the readme editor? You have unsaved changes."}
      />
      <TopContainer>
        <TopContent>
          <HeaderPretitle>Readme</HeaderPretitle>
          {!isEditing && (text || (!isLoading && !hasErrorOccurred)) && (
            <ClickableContainer>
              <Icon
                className="text-muted ml-1.5"
                onClick={() => {
                  setIsEditing(true);
                  setEditedText(text ?? DEFAULT_TEMPLATE);
                }}
                name="edit-2"
              />
            </ClickableContainer>
          )}
          {isEditing && (
            <div>
              <StyledButton
                className="mr-3"
                onClick={() => {
                  setIsEditing(false);
                  setEditedText(undefined);
                }}
              >
                Cancel
              </StyledButton>
              <Button
                size="sm"
                disabled={isLoading || !editedText}
                onClick={() => editedText && updateText(editedText)}
              >
                <i className="fe fe-save mr-1.5" />
                {isLoading ? "Saving..." : "Save Changes"}
              </Button>
            </div>
          )}
        </TopContent>
      </TopContainer>
      <BodyContainer>
        {text === null ? (
          hasErrorOccurred ? (
            <TryAgainView onClick={() => !isLoading && fetchAndSetText()} />
          ) : (
            <Spinner />
          )
        ) : isEditing ? (
          <Editor
            value={editedText}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setEditedText(e.target.value)}
          />
        ) : (
          <Viewer>{text ?? DEFAULT_TEMPLATE}</Viewer>
        )}
      </BodyContainer>
    </OverviewSectionContainer>
  );
}

export default OverviewReadmePanel;
