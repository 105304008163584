import React from "react";

type Props = {
  children: React.ReactNode;
};
function PortalPageContainer(props: Props) {
  return (
    <>
      <div className="main-content" style={{ marginLeft: 64 }}>
        {props.children}
      </div>
    </>
  );
}

export default PortalPageContainer;
