import { Alert, Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import { OctagonX } from "lucide-react";
import {
  MappingTestAndVersions,
  MappingTestExecution,
} from "../../../../../../../models/MappingTests";
import usePublishModuleMappingTestContext from "../../../../../context/usePublishModuleMappingTestContext";

const MappingTestExecutionFailureCardV2 = ({
  mappingTest,
  testExecution,
}: {
  mappingTest: MappingTestAndVersions;
  testExecution: MappingTestExecution;
}) => {
  const {
    setMappingTest,
    setMappingTestExecution,
    setIsShowingMappingTestExecutionModal,
  } = usePublishModuleMappingTestContext();

  return (
    <Alert
      color="red"
      size="sm"
      className="mb-4"
      icon={<OctagonX className="text-black" size={16} />}
      title={
        <div className="flex flex-row justify-between items-center w-full ml-2">
          <Text variant="md">{mappingTest.name}</Text>
          <Button
            size="sm"
            variant={ButtonVariant.TertiaryWhite}
            onClick={() => {
              setMappingTestExecution(testExecution);
              setMappingTest(mappingTest);
              setIsShowingMappingTestExecutionModal(true);
            }}
          >
            View logs
          </Button>
        </div>
      }
    />
  );
};

export default MappingTestExecutionFailureCardV2;
