import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import {
  getIntegrationBuilderAPIEndpointPathForId,
  getIntegrationBuilderCreateNewAPIEndpointPathForId,
  getIntegrationBuilderSingleAPIEndpointPathForId,
} from "../../../router/RouterUtils";
import { APIEndpointIntegrationBuilder } from "../utils/Entities";
import APIEndpointSetup from "./components/APIEndpointSetup";
import APIEndpointsTable from "./components/APIEndpointsTable";
import useLoadAPIEndpoints from "./hooks/useLoadAPIEndpoints";

interface APIEndpointsProps {
  integrationID: string;
}

const APIEndpoints = ({ integrationID }: APIEndpointsProps) => {
  const [apiEndpoints, setAPIEndpoints] = useState<APIEndpointIntegrationBuilder[] | undefined>();
  const [isLoadingAPIEndpoints, setIsLoadingAPIEndpoints] = useState(false);

  const { loadAPIEndpoints } = useLoadAPIEndpoints({ integrationID, setAPIEndpoints });

  useEffect(() => {
    loadAPIEndpoints();
  }, []);

  return (
    <div>
      <Switch>
        <Route
          exact
          path={getIntegrationBuilderAPIEndpointPathForId(integrationID)}
          render={() => (
            <APIEndpointsTable
              integrationID={integrationID}
              apiEndpoints={apiEndpoints}
              setAPIEndpoints={setAPIEndpoints}
              isLoadingAPIEndpoints={isLoadingAPIEndpoints}
              setIsLoadingAPIEndpoints={setIsLoadingAPIEndpoints}
            />
          )}
        />
        <Route
          exact
          path={getIntegrationBuilderCreateNewAPIEndpointPathForId(integrationID)}
          render={() => (
            <APIEndpointSetup
              integrationID={integrationID}
              apiEndpoints={apiEndpoints}
              setAPIEndpoints={setAPIEndpoints}
              isLoadingAPIEndpoints={isLoadingAPIEndpoints}
            />
          )}
        />
        <Route
          exact
          path={getIntegrationBuilderSingleAPIEndpointPathForId(integrationID)}
          render={() => (
            <APIEndpointSetup
              integrationID={integrationID}
              apiEndpoints={apiEndpoints}
              setAPIEndpoints={setAPIEndpoints}
              isLoadingAPIEndpoints={isLoadingAPIEndpoints}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default APIEndpoints;
