import React from "react";

interface DeprecatedH5Props {
  children: React.ReactNode;
  className?: string;
}

const DeprecatedH5: React.FC<DeprecatedH5Props> = ({ className, children }) => {
  return <div className={`deprecatedh5 ${className}`}>{children}</div>;
};

export default DeprecatedH5;
