import { Accordion, Text } from "@merge-api/merge-javascript-shared";
import MappingTestV2RightPanelErrorCard from "../../../../../../../../mapping-tests/right-panel/right-panel-errors/MappingTestV2RightPanelErrorCard";
import { MappingTestBlockExecutionError } from "../../../../../../../../../models/MappingTests";
import { AlertCircle } from "lucide-react";

interface Props {
  errors: Array<MappingTestBlockExecutionError>;
}

const BlockErrorsAccordion = ({ errors }: Props) => {
  return (
    <Accordion
      title={
        <div className="flex flex-row items-center space-x-2 border-b-1 border-gray-20">
          <AlertCircle size={16} className="text-red-70" />
          <Text variant="h6" className="text-red-70">
            Errors
          </Text>
        </div>
      }
      titleClassName="p-4"
      chevronColor="darkGray"
      chevronOrientation="right"
      variant="outline"
      className="w-full"
    >
      <div className="px-4">
        <hr className="text-gray-20 h-[0.5px] my-0 -mx-4" />
        {errors.map((error, index) => {
          return (
            <div key={error.type}>
              <MappingTestV2RightPanelErrorCard error={error} />
              {index + 1 !== errors.length && <hr className="text-gray-50 h-[0.5px] my-0" />}
            </div>
          );
        })}
      </div>
    </Accordion>
  );
};

export default BlockErrorsAccordion;
