import { BlueprintStepTemplate } from "../../../models/Blueprints";
import CreateOrUpdateForm from "./CreateOrUpdateForm";
import useBlueprintContext from "../context/useBlueprintContext";
import { checkNeedsRemoteData } from "../utils/BlueprintEditorUtils";
import NeedsRemoteDataToggle from "./NeedsRemoteDataToggle";

interface Props {
  stepID: string;
  stepTemplate: BlueprintStepTemplate;
}

const BlueprintEditorRightPanelCreateOrUpdateForm = ({ stepID, stepTemplate }: Props) => {
  const { selectedStep, setStepNeedsRemoteData } = useBlueprintContext();

  const updateNeedsRemoteData = () => {
    setStepNeedsRemoteData(stepID, !needsRemoteData);
  };
  const needsRemoteData = checkNeedsRemoteData(selectedStep);

  return (
    <>
      <NeedsRemoteDataToggle
        updateNeedsRemoteData={updateNeedsRemoteData}
        needsRemoteData={needsRemoteData}
      />
      <CreateOrUpdateForm stepID={stepID} stepTemplate={stepTemplate} />;
    </>
  );
};

export default BlueprintEditorRightPanelCreateOrUpdateForm;
