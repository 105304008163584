import { Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import { Check, Plus } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { getLastStringOfUrlPath } from "../utils/helpers";
import transparentCheckboardImage from "../../../assets/img/transparent_checkerboard_background.png";

interface Props {
  title: string;
  subtitle?: string;
  imageUrl: string | undefined;
  setRequestedImageFile: (value: File) => void;
}

const ImageUploadHeader = ({ title, subtitle, imageUrl, setRequestedImageFile }: Props) => {
  // set states to display
  const [imageDisplayName, setImageDisplayName] = useState<string | undefined>();
  const [imageDisplaySource, setImageDisplaySource] = useState<string | undefined>();

  // initialize states
  useEffect(() => {
    if (imageUrl) {
      setImageDisplayName(getLastStringOfUrlPath(imageUrl));
      setImageDisplaySource(imageUrl);
    }
  }, [imageUrl]);

  // opens the file input dialog
  const fileInputRef = useRef<HTMLInputElement>(null);
  const openFilePicker = () => {
    fileInputRef.current?.click();
  };

  // handles the file upload and sets the JSON text in the text area
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      setRequestedImageFile(file);
      setImageDisplaySource(URL.createObjectURL(file));
      setImageDisplayName(file.name);
    }
  };

  return (
    <div className="flex flex-row space-x-6 items-center justify-start">
      <div className="relative w-[72px] h-[72px]">
        {imageDisplaySource ? (
          <>
            <img
              src={transparentCheckboardImage}
              className="object-contain w-[72px] h-[72px] opacity-50"
            />
            <img
              src={imageDisplaySource}
              className="absolute top-0 left-0 object-contain w-[72px] h-[72px] cursor-pointer"
              onClick={() => window.open(imageDisplaySource, "_blank", "noopener,noreferrer")}
            />
          </>
        ) : (
          <div className="w-[72px] h-[72px] bg-gray-0" />
        )}
      </div>
      <div className="flex flex-column items-start space-y-2">
        <div className="flex flex-column items-start">
          <Text variant="h6">{title}</Text>
          {subtitle && (
            <Text variant="sm" className="text-gray-70">
              {subtitle}
            </Text>
          )}
        </div>
        {imageDisplayName ? (
          <div className="flex flex-row space-x-2 justify-start items-center">
            <Check size={16} className="text-green-60" />
            <Text variant="md">{imageDisplayName}</Text>
            <div onClick={openFilePicker}>
              <Text variant="md" className="text-blue-40 cursor-pointer">
                Change
              </Text>
            </div>
          </div>
        ) : (
          <Button
            size="sm"
            leftIcon={<Plus size={16} />}
            variant={ButtonVariant.TertiaryWhite}
            onClick={openFilePicker}
          >
            Choose file
          </Button>
        )}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
          accept=".jpg,.jpeg,.png,.svg"
        />
      </div>
    </div>
  );
};

export default ImageUploadHeader;
