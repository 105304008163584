import React from "react";
import { BlueprintValidatorResult, PublishModuleInfo } from "../publish-requests/types";

export interface PublishModuleValidatorContextState {
  blueprintValidatorInfo: Record<string, BlueprintValidatorResult>;
  integrationID: string | null;
  publishModuleInfo: PublishModuleInfo | undefined;
}

const PublishModuleValidatorContext = React.createContext<PublishModuleValidatorContextState>({
  blueprintValidatorInfo: {},
  integrationID: null,
  publishModuleInfo: undefined,
});

export default PublishModuleValidatorContext;
