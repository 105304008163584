import { AuthConfigIntegrationBuilder } from "../../../utils/Entities";

interface NameCellProps {
  authConfig: AuthConfigIntegrationBuilder;
}

const NameCell = ({ authConfig }: NameCellProps) => {
  return (
    <td>
      <div className="text-gray-90 text-base font-medium">{authConfig.name}</div>
    </td>
  );
};
export default NameCell;
