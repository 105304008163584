import { Checkbox, Dialog, Text, TextField } from "@merge-api/merge-javascript-shared";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useStagedComponentContext } from "../context/StagedComponentContext";
import { IntegrationValidatorRuleResult, OverrideRuleRequestInput } from "../types";

type Props = {
  rule: IntegrationValidatorRuleResult;
  open: boolean;
  onClose: () => void;
};

const StagedComponentOverrideModal = ({ rule, open, onClose }: Props) => {
  const { component, overrideRule } = useStagedComponentContext();
  const { component_id, component_version_id } = component;
  const [comment, setComment] = useState<string>("");
  const [ticketUrl, setTicketUrl] = useState<string>("");
  const [isComponentOverridden, setIsComponentOverridden] = useState<boolean>(false);
  const [overrideBody, setOverrideBody] = useState<OverrideRuleRequestInput>({
    component_id,
    rule_id: rule.rule_id,
    comment,
    asana_url: ticketUrl,
  });

  useEffect(() => {
    setOverrideBody({
      rule_id: rule.rule_id,
      ...(isComponentOverridden ? { component_id } : { component_version_id }),
      comment,
      asana_url: ticketUrl,
    });
  }, [isComponentOverridden, comment, ticketUrl]);

  const handleOnConfirm = useCallback(() => {
    overrideRule(rule, overrideBody);
    onClose();
  }, [overrideRule, overrideBody]);

  const isConfirmDisabled = useMemo(() => !comment || (isComponentOverridden && !ticketUrl), [
    comment,
    isComponentOverridden,
    ticketUrl,
  ]);

  return (
    <Dialog
      title="Are you sure?"
      variant="lg"
      open={open}
      onClose={onClose}
      onSecondaryButtonClick={onClose}
      onPrimaryButtonClick={handleOnConfirm}
      primaryButtonDisabled={isConfirmDisabled}
    >
      <div className="space-y-4">
        <Text>
          Overriding a check without addressing it could introduce issues in the integration. Ensure
          the check isn’t necessary to resolve before publish.
        </Text>
        <div>
          <div className="flex flex-row">
            <Text variant="h6">Comment</Text>
            <Text variant="md" className="text-red-50 ml-1">
              *
            </Text>
          </div>
          <Text className="text-gray-70">Provide a quick explanation on why you’re overriding</Text>
          <TextField
            className="mt-2"
            placeholder="Comment"
            value={comment}
            onChangeCapture={(e: any) => setComment(e.target.value)}
            required
          />
        </div>
        <div>
          <Checkbox
            label="Override for future versions of component"
            labelFontWeight="semibold"
            checked={isComponentOverridden}
            onChange={() => setIsComponentOverridden(!isComponentOverridden)}
          />
          <Text className="text-gray-70">
            You can turn on checks again from the component’s overridden section.
          </Text>
        </div>
        {isComponentOverridden && (
          <div>
            <div className="flex flex-row">
              <Text variant="h6">Asana Ticket</Text>
              <Text variant="md" className="text-red-50 ml-1">
                *
              </Text>
            </div>
            <Text className="text-gray-70 mb-2">
              Link the relevant Asana ticket so that it can be tracked in changelog
            </Text>
            <TextField
              className="mt-2"
              placeholder="Asana Ticket URL"
              value={ticketUrl}
              onChangeCapture={(e: any) => setTicketUrl(e.target.value)}
              required
            />
          </div>
        )}
      </div>
    </Dialog>
  );
};
export default StagedComponentOverrideModal;
