export enum APIRequestContentTypes {
  JSON = "application/json",
  FORM_DATA = "multipart/form-data",
  OCTET_STREAM = "application/octet-stream",
}

export enum APIProtocols {
  REST = "REST_JSON",
  SOAP = "SOAP_XML",
  GRAPHQL = "GRAPHQL_JSON",
}

export enum APIEndpointTypes {
  SINGLE_REQUEST = "SINGLE_REQUEST",
  PAGINATION = "PAGINATION",
  LIVE = "LIVE",
  PROXY = "PROXY",
  WEB_CONNECTOR = "WEB_CONNECTOR",
}

export const APIEndpointTypeKeyValues = {
  [APIEndpointTypes.SINGLE_REQUEST]: "Single Request",
  [APIEndpointTypes.PAGINATION]: "Pagination",
  [APIEndpointTypes.LIVE]: "Live",
  [APIEndpointTypes.PROXY]: "Proxy",
};

export enum APIEndpointResponseTypes {
  JSON = "JSON",
  XML = "XML",
  BYTES = "BYTES",
  IMAGE = "IMAGE",
  NUMBER = "NUMBER",
}

export enum APIEndpointTimestampFilterParamLocations {
  BODY = "BODY",
  QUERY_PARAM = "QUERY_PARAM",
  URL_ENCODED_QUERY_PARAM = "URL_ENCODED_QUERY_PARAM",
}

export enum APIRequestTypes {
  REST = "REST",
  SOAP = "SOAP",
  GRAPHQL = "GRAPHQL",
}

export enum APIEndpointPaginationConfigurationTypes {
  DEFAULT = "DEFAULT",
  SELECTED = "SELECTED",
  NEW = "NEW",
}
