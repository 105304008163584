import { useCallback, useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { APIEndpointMethod } from "../../../../models/Entities";
import { TestRunResponse } from "../../../shared/TestRunResponseBlock";
import { showErrorToast } from "../../../shared/Toasts";
import { APIEndpointIntegrationBuilder } from "../../utils/Entities";
import { APIEndpointParameterValues } from "../components/request_tester/APIRequestTester";

interface loadAPIEndpointProps {
  linkedAccountID: string;
  apiEndpoint: APIEndpointIntegrationBuilder | null | undefined;
  parameterValues: APIEndpointParameterValues;
  setTestRunResponse: (value: React.SetStateAction<TestRunResponse | undefined>) => void;
}

const useRunTestAPIRequest = ({
  linkedAccountID,
  apiEndpoint,
  parameterValues,
  setTestRunResponse,
}: loadAPIEndpointProps) => {
  // state
  const [isLoadingTestAPIRequest, setIsLoadingTestAPIRequest] = useState(false);

  // Method for making an api test request
  const runTestAPIRequest = useCallback(() => {
    if (!apiEndpoint) return;
    setIsLoadingTestAPIRequest(true);
    fetchWithAuth({
      path: "/integrations/integration-builder/test-endpoint",
      method: APIEndpointMethod.POST,
      body: {
        linked_account_id: linkedAccountID,
        api_endpoint: apiEndpoint,
        parameter_values: parameterValues,
      },
      onResponse: (data) => {
        setTestRunResponse(data);
        setIsLoadingTestAPIRequest(false);
      },
      onError: (error) => {
        setIsLoadingTestAPIRequest(false);
        error?.json().then((value: string) => {
          showErrorToast(`Failed to run ${value}`);
        });
      },
    });
  }, [linkedAccountID, parameterValues, apiEndpoint]);

  return { isLoadingTestAPIRequest, runTestAPIRequest };
};

export default useRunTestAPIRequest;
