import AccordionCard from "../../shared/AccordionCard";
import TextFieldHeader from "../../shared/TextFieldHeader";
import { APICategory } from "@merge-api/merge-javascript-shared";
import MultiSelectHeader from "../../shared/MultiSelectHeader";
import { IntegrationForIntegrationBuilder } from "../../utils/Entities";

interface IntegrationCreationAdvancedProps {
  requestBodyForIntegration: IntegrationForIntegrationBuilder | undefined;
  setRequestBodyForIntegration: React.Dispatch<
    React.SetStateAction<IntegrationForIntegrationBuilder | undefined>
  >;
  isUseScrapers: boolean;
  isSoapIntegrationOptions: any[];
  isMultiSubsidiaryOptions: any[];
  isUseScrapersOptions: any[];
  isScraperProxyOptions: any[];
}

const IntegrationCreationAdvanced = ({
  requestBodyForIntegration,
  setRequestBodyForIntegration,
  isSoapIntegrationOptions,
  isMultiSubsidiaryOptions,
  isUseScrapers,
  isUseScrapersOptions,
  isScraperProxyOptions,
}: IntegrationCreationAdvancedProps) => {
  return (
    <AccordionCard title="Advanced" variant="shadow" dataTestID="accordion-advanced">
      <div className="space-y-6">
        <MultiSelectHeader
          dataTestID="field-is-soap-integration"
          title="Is this a SOAP integration?"
          subtitle="Set whether or not an integration uses a SOAP API rather than a more traditional REST API - for the majority of integrations this should remain false. An example of a SOAP integration is Sage Intacct or Salesforce."
          options={isSoapIntegrationOptions}
        />
        {requestBodyForIntegration?.categories &&
          requestBodyForIntegration.categories.includes(APICategory.accounting) && (
            <MultiSelectHeader
              dataTestID="field-is-multisubsidiary"
              title="Is this multi-subsidiary?"
              subtitle="Set true if an integration is a multi-subsidiary, otherwise defaults to false. Not common, usually applies to only certain Accounting integrations."
              options={isMultiSubsidiaryOptions}
            />
          )}
        {requestBodyForIntegration?.categories &&
          requestBodyForIntegration.categories.includes(APICategory.hris) && (
            <>
              <MultiSelectHeader
                dataTestID="field-is-use-scrapers"
                title="Does this integration use scrapers?"
                subtitle="Example integrations that use scrapers are Gusto, Justworks, ADP."
                options={isUseScrapersOptions}
              />
              {isUseScrapers && (
                <div className="border-l-2 pl-8 mt-6 border-gray-10 space-y-6">
                  <MultiSelectHeader
                    dataTestID="field-is-scraper-proxy"
                    title="Should we use scraper proxy?"
                    subtitle="Set true if the scraper should use a proxy, otherwise defaults to false."
                    options={isScraperProxyOptions}
                  />
                  <TextFieldHeader
                    dataTestID="field-extra-scraper-headers"
                    title="Extra scraper headers"
                    subtitle="Extra HTTP headers to add to all requests made by the scraper, if a scraper is used."
                    value={requestBodyForIntegration?.extra_scraper_headers || ""}
                    onChange={(event) =>
                      setRequestBodyForIntegration((body) => {
                        return {
                          ...body,
                          extra_scraper_headers:
                            event.target.value.length > 0 ? event.target.value : null,
                        };
                      })
                    }
                  />
                </div>
              )}
            </>
          )}
      </div>
    </AccordionCard>
  );
};

export default IntegrationCreationAdvanced;
