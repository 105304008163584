import { Accordion, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import MappingTestBlockBlueprintExecutionStatusBadge from "../shared/MappingTestBlockBlueprintExecutionStatusBadge";
import IntegrationEditorStepLogsTable from "../../shared/integration-editor-base/IntegrationEditorStepLogsTable";
import { BlueprintRunnerExitData } from "../../../models/Blueprints";
import { MappingTestExecution } from "../../../models/MappingTests";

interface Props {
  index: number;
  exitData: BlueprintRunnerExitData;
  selectedBlockExecution: MappingTestExecution;
}

const MappingTestV2RightPanelLogCard = ({ index, exitData, selectedBlockExecution }: Props) => {
  return (
    <div>
      <Accordion
        title={
          <div className="flex flex-row items-center py-4 space-x-2 w-[286px]">
            <Tooltip title={exitData.blueprint_meta?.name} className="w-full truncate">
              <Text variant="md" className="text-black truncate text-start">
                {exitData.blueprint_meta?.name}
              </Text>
            </Tooltip>
            <MappingTestBlockBlueprintExecutionStatusBadge blueprintExecutionExitData={exitData} />
          </div>
        }
        chevronOrientation="right"
        chevronSize={16}
        chevronColor="darkGray"
        variant="none"
        className="h-full"
      >
        <IntegrationEditorStepLogsTable
          startTime={selectedBlockExecution?.created_at}
          stepLogs={exitData.step_logs}
        />
      </Accordion>
      {index + 1 !== selectedBlockExecution.exit_data?.length && (
        <hr className="text-gray-50 h-[0.5px] my-0" />
      )}
    </div>
  );
};

export default MappingTestV2RightPanelLogCard;
