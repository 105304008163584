import { Accordion, Badge, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { Info } from "lucide-react";
import IntegrationValidatorRuleAlert from "../IntegrationValidatorRules/IntegrationValidatorRuleAlert";
import { IntegrationValidatorRuleResult } from "../types";
import clsx from "clsx";

type StagedComponentRulesAccordionProps = {
  ruleResults: IntegrationValidatorRuleResult[];
  title: string;
  isCollapsible?: boolean;
  learnMoreText?: string;
  className?: string;
};

const StagedComponentRulesAccordion = ({
  ruleResults,
  title,
  isCollapsible = true,
  learnMoreText,
  className = "",
}: StagedComponentRulesAccordionProps) => {
  if (ruleResults.length === 0) {
    return null;
  }

  const renderTitle = () => (
    <div className="flex flex-row items-center space-x-2">
      <Text>{title}</Text>
      {learnMoreText && (
        <Tooltip title={learnMoreText}>
          <Info size={16} className="text-gray-70" />
        </Tooltip>
      )}
      <Badge>{ruleResults.length}</Badge>
    </div>
  );

  if (!isCollapsible) {
    return (
      <div className={clsx(className, "space-y-2")}>
        {renderTitle()}
        {ruleResults.map((check) => (
          <IntegrationValidatorRuleAlert validatorRuleResult={check} />
        ))}
      </div>
    );
  }

  return (
    <Accordion
      title={renderTitle()}
      className={clsx(className, "rounded-none")}
      defaultExpanded={false}
      chevronOrientation="down"
      variant="none"
    >
      <div className="space-y-2 mt-2">
        {ruleResults.map((check) => (
          <IntegrationValidatorRuleAlert validatorRuleResult={check} />
        ))}
      </div>
    </Accordion>
  );
};

export default StagedComponentRulesAccordion;
