import React from "react";
import {
  ScraperVersion,
  ScraperExecutionLogs,
  ScraperGhostStep,
  ScraperStep,
  ScraperStepType,
  ScraperValue,
} from "../types";
import { AddStepRelation, BlueprintVersionPublishState } from "../../../models/Blueprints";
const noop = () => {};
export interface ScraperContextState {
  addStep: (step: ScraperGhostStep, stepType: ScraperStepType) => void;
  deleteStep: (step: ScraperStep) => void;
  collapseStep: (stepID: string) => void;
  updateStepParameterValue: (step: ScraperStep, key: string, value: ScraperValue | null) => void;
  updateStepNoteText: (step: ScraperStep, stepText: string) => void;
  integrationID: string;
  scraper: ScraperVersion;
  latestPublishedVersionID: string | undefined;
  disableArrows: boolean;
  setDisableArrows: (disableArrows: boolean) => void;
  selectedStep: ScraperStep | ScraperGhostStep | undefined;
  setSelectedStep(step: ScraperStep | ScraperGhostStep | undefined): void;
  saveScraper: () => void;
  copiedStep: ScraperStep | undefined;
  setCopiedStep: (step: ScraperStep | undefined) => void;
  addCopiedStep: (
    step: ScraperStep,
    relatedStepRelation: AddStepRelation,
    relatedStepID: string,
    pathKey?: string
  ) => void;
  addCopiedSteps: (
    steps: ScraperStep[],
    relatedStepRelation: AddStepRelation,
    relatedStepID: string,
    pathKey?: string
  ) => void;
  doesScraperHaveUnsavedChanges: boolean;
  renameStep: (stepID: string, newName: string, onError?: () => void) => void;
  scraperExecutionLogs: ScraperExecutionLogs | undefined;
  scraperVersions: ScraperVersion[];
  setScraperExecutionLogs: (logs: ScraperExecutionLogs | undefined) => void;
  setScraper: (scraper: ScraperVersion) => void;
  stageScraper: () => void;
  unstageScraper: () => void;
}

const ScraperContext = React.createContext<ScraperContextState>({
  addStep: noop,
  updateStepParameterValue: noop,
  updateStepNoteText: noop,
  deleteStep: noop,
  collapseStep: noop,
  disableArrows: false,
  setDisableArrows: noop,
  integrationID: "",
  latestPublishedVersionID: "",
  scraper: {
    id: "id",
    version_id: "version_id",
    name: "",
    data_type: "",
    steps: [],
    max_timeout: "15",
    should_run_in_headless_mode: false,
    should_log_non_errors: false,
    should_solve_captchas: false,
    most_recent_blueprint_version_id: "",
    is_draft: true,
    is_staged: false,
    is_published: false,
    created_at: "",
    publish_state: BlueprintVersionPublishState.Draft,
  },
  doesScraperHaveUnsavedChanges: false,
  selectedStep: undefined,
  saveScraper: noop,
  stageScraper: noop,
  unstageScraper: noop,
  setSelectedStep: noop,
  copiedStep: undefined,
  addCopiedStep: noop,
  addCopiedSteps: noop,
  renameStep: noop,
  setCopiedStep: noop,
  scraperVersions: [],
  scraperExecutionLogs: undefined,
  setScraperExecutionLogs: noop,
  setScraper: noop,
});

export default ScraperContext;
