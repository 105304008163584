import styled from "styled-components";
import classNames from "classnames";
import { formatDistanceToNow, sub } from "date-fns";
import {
  BlueprintParameterValueType,
  BlueprintParameterValue,
  WebConnectorSessionStatus,
} from "../../../models/Blueprints";
import moment from "moment-timezone";
import { DATE_PICKER_OPTIONS } from "../../../constants";
import { BaseColor } from "@merge-api/merge-javascript-shared/dist/designSystem/types";

export const TableTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const TableTitleText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  vertical-align: bottom;
  display: table-cell;
`;

export const getIconStyling = (type: string) => {
  switch (type) {
    case "array":
      return "fe-menu";
    case "integer":
    case "number":
      return "fe-hash";
    case "string":
    case "uuid":
    case "date":
    case "datetime":
    case "email":
    case "url":
      return "fe-italic";
    case "boolean":
      return "fe-toggle-left";
    case "object":
      return "fe-list";
    default:
      return "fe-help-circle";
  }
};

export const getIconForTypeForJSONTreeDiagram = (
  type: string,
  shouldShowAsError: boolean = false
) => {
  return (
    <i
      className={classNames(
        "fe mr-1.5",
        getIconStyling(type),
        shouldShowAsError ? "text-danger" : "text-muted"
      )}
    />
  );
};

export const getBadgeColorForCoveragePercent = (
  coveragePercent: number,
  isLight: boolean = false
) => {
  return coveragePercent == 100
    ? isLight
      ? "blue"
      : "blueStrong"
    : coveragePercent >= 80
    ? isLight
      ? "teal"
      : "tealStrong"
    : coveragePercent >= 40
    ? isLight
      ? "yellow"
      : "yellowStrong"
    : coveragePercent >= 10
    ? isLight
      ? "orange"
      : "orangeStrong"
    : isLight
    ? "red"
    : "redStrong";
};

export const getTimeFromNowUTC = (timeAsString: string) => {
  const timeAgo = formatDistanceToNow(new Date(timeAsString)) + " ago";
  return timeAgo.charAt(0) + timeAgo.slice(1);
};

export const getCapitalizedTimeFromNow = (timeAsString: string) => {
  const timeAgo = formatDistanceToNow(new Date(timeAsString)) + " ago";
  return timeAgo.charAt(0).toUpperCase() + timeAgo.slice(1);
};

export const getShortenedText = (text: string | null, maxLength: number) => {
  let shortened: [string, boolean];
  if (!text) {
    shortened = ["", false];
    return shortened;
  }
  const shortenedText: string | null =
    (text ?? "").length > maxLength ? text.substring(0, maxLength) + "..." : text;
  const isShortened: boolean = text && text.length > maxLength ? true : false;
  shortened = [shortenedText, isShortened];
  return shortened;
};

export const parseParameterValue = (param: BlueprintParameterValue) => {
  switch (param.value_type) {
    case BlueprintParameterValueType.constant:
      return param.constant;
    case BlueprintParameterValueType.globalVariable:
      return param.return_schema_path
        ? `global.${param.key}.${param.return_schema_path.join(".")}`
        : `global.${param.key}`;
    case BlueprintParameterValueType.nestedParameterValues:
      return `nested values: ${param.nested_parameter_values}`;
    case BlueprintParameterValueType.none:
      return "None";
    case BlueprintParameterValueType.procedureArray:
      return `procedure: ${param.procedure_array.join(", ")}`;
    case BlueprintParameterValueType.returnValue:
      return param.return_schema_path.length > 0
        ? `${param.step_id}.${param.return_schema_path
            .filter((word) => word !== "properties")
            .join(".")}`
        : `${param.step_id}`;
    case BlueprintParameterValueType.variable:
      return param.key;
  }
};

export function formatDate(
  dateString: string | Date,
  formatString = "MMM DD, YYYY hh:mm:ss A",
  includeTimezone = true
) {
  const userTimezone = moment.tz.guess();
  const convertedDate = moment.utc(dateString).tz(userTimezone);

  const timezoneStr = moment.tz(userTimezone).zoneAbbr();

  const formattedDate = convertedDate.format(formatString);

  return `${formattedDate}${includeTimezone ? " " + timezoneStr : ""}`;
}

export const formatDateFromDateString = (dateStr: string): string => {
  const monthsAbbreviation = [
    "Jan.",
    "Feb.",
    "Mar.",
    "Apr.",
    "May.",
    "Jun.",
    "Jul.",
    "Aug.",
    "Sep.",
    "Oct.",
    "Nov.",
    "Dec.",
  ];

  const dateObj = new Date(dateStr);
  const month = monthsAbbreviation[dateObj.getUTCMonth()];
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();

  let hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours || 12; // Convert 0 hours to 12
  const minutesStr = minutes < 10 ? "0" + minutes : minutes.toString();

  return `${month} ${day}, ${year} (${hours}:${minutesStr} ${ampm})`;
};

export function calculateDateFromPicker(selectedOption: string): Date | null {
  const today = new Date();
  switch (selectedOption) {
    case DATE_PICKER_OPTIONS.PAST_24_HOURS: {
      return sub(today, { days: 1 });
    }
    case DATE_PICKER_OPTIONS.PAST_WEEK: {
      return sub(today, { weeks: 1 });
    }
    case DATE_PICKER_OPTIONS.PAST_MONTH: {
      return sub(today, { months: 1 });
    }
    case DATE_PICKER_OPTIONS.PAST_YEAR: {
      return sub(today, { years: 1 });
    }
    default: {
      return null;
    }
  }
}

// WEB CONNECTOR BLUEPRINT RESULTS
export const WebConnectorSessionStatusColorMap: Record<WebConnectorSessionStatus, BaseColor> = {
  [WebConnectorSessionStatus.FAILED]: "red",
  [WebConnectorSessionStatus.ABORTED]: "red",
  [WebConnectorSessionStatus.SUCCEEDED]: "teal",
  [WebConnectorSessionStatus.PENDING]: "gray",
  [WebConnectorSessionStatus.IN_PROGRESS]: "yellow",
};

export const WebConnectorSessionStatusTextMap: Record<WebConnectorSessionStatus, string> = {
  [WebConnectorSessionStatus.FAILED]: "Failed",
  [WebConnectorSessionStatus.ABORTED]: "Aborted",
  [WebConnectorSessionStatus.SUCCEEDED]: "Success",
  [WebConnectorSessionStatus.PENDING]: "Pending",
  [WebConnectorSessionStatus.IN_PROGRESS]: "In Progress",
};
