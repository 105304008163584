import { Link, Text } from "@merge-api/merge-javascript-shared";
import { ListTransformationInformation } from "../../../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import TextFieldHeader from "../../../../../shared/TextFieldHeader";
import TransformValuesModalCheckboxes from "./TransformValuesModalCheckboxes";
import SelectHeader from "../../../../../shared/SelectHeader";
import { DatetimeFormatEnum } from "../../../../types";

type Props = {
  isListOperator: boolean;

  formatString: string;
  prefix: string;
  suffix: string;
  listTransformationInformation: ListTransformationInformation;
  datetimeFormat: DatetimeFormatEnum | null;

  setFormatString: (newVal: string) => void;
  setPrefix: (newVal: string) => void;
  setSuffix: (newVal: string) => void;
  setListTransformationInformation: (newVal: ListTransformationInformation) => void;
  setDatetimeFormat: (newVal: DatetimeFormatEnum | null) => void;
};

const PYTHON_FORMAT_STRINGS_DOC_URL = "https://docs.python.org/3/library/string.html#formatstrings";

const TransformValuesModalForm = ({
  isListOperator,
  listTransformationInformation,
  setListTransformationInformation,
  formatString,
  setFormatString,
  datetimeFormat,
  setDatetimeFormat,
  prefix,
  setPrefix,
  suffix,
  setSuffix,
}: Props) => {
  return (
    <div className="flex flex-col">
      <>
        {isListOperator && (
          <TransformValuesModalCheckboxes
            listTransformationInformation={listTransformationInformation}
            setListTransformationInformation={setListTransformationInformation}
          />
        )}
        <TextFieldHeader
          className="mb-6"
          title="Format string"
          subtitleComponent={
            <Text as="span" className="text-gray-7" variant="sm">
              Specify what string transformation (if any) should be applied to the value we input in
              our filter <br /> See{" "}
              <Link target="_blank" href={PYTHON_FORMAT_STRINGS_DOC_URL}>
                here
              </Link>{" "}
              for available transformations.{" "}
            </Text>
          }
          learnMoreText="For example, Jira requires dates to be formatted as {:%Y-%m-%d %H:%M}. So '2022-02-02T00:00:00Z' will be transformed into '2022-02-02 00:00'"
          value={formatString ?? ""}
          onChange={(event) => {
            const value = event.target.value;
            setFormatString(value);
          }}
        />
        <SelectHeader
          className="mb-6"
          title="Format date"
          subtitle="Specify the target datetime format (only applies for Selective Sync filters that are DATE_TIME values, such as 'modified_at')."
          learnMoreText="For example, Zendesk requires SECONDS."
          value={datetimeFormat}
          options={Object.values(DatetimeFormatEnum)}
          onChange={(_: any, selectedOption: DatetimeFormatEnum | null) => {
            setDatetimeFormat(selectedOption);
          }}
        />
        <TextFieldHeader
          className="mb-6"
          title="Filter prefix"
          subtitle="The string prefix that will be prepended to our filter value."
          learnMoreText='For example, in Jira, filtering can look like `api.atlassian.net/v1/search?jql=updated_at>="2022-02-02 00:00"`. In this case, the prefix would be `updated_at>="`, assuming the key of the API endpoint filter is `jql`.'
          value={prefix}
          onChange={(event) => {
            const value = event.target.value;
            setPrefix(value);
          }}
        />
        <TextFieldHeader
          className="mb-6"
          title="Filter suffix"
          subtitle="The string suffix that will be appended after our filter value."
          learnMoreText='For example, in Jira, filtering can look like `api.atlassian.net/v1/search?jql=updated_at>="2022-02-02 00:00"`. In this case, the prefix would be `"`, assuming the key of the API endpoint filter is `jql`.'
          value={suffix}
          onChange={(event) => {
            const value = event.target.value;
            setSuffix(value);
          }}
        />
      </>
    </div>
  );
};

export default TransformValuesModalForm;
