import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { APIEndpointMethod } from "../../../../models/Entities";
import { navigateToIntegrationBuilderPaginationConfigurationTable } from "../../../../router/RouterUtils";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import IntegrationBuilderContext from "../../context/IntegrationBuilderContext";
import { PaginationConfigurationIntegrationBuilder } from "../../utils/Entities";

interface DeletePaginationConfigurationsProps {
  integrationID: string;
  setPaginationConfigurations: React.Dispatch<
    React.SetStateAction<PaginationConfigurationIntegrationBuilder[] | undefined>
  >;
  paginationConfigurationID?: string | null;
}

const useDeletePaginationConfiguration = ({
  integrationID,
  setPaginationConfigurations,
  paginationConfigurationID,
}: DeletePaginationConfigurationsProps) => {
  // state
  const [
    isDeletingPaginationConfigurationLoading,
    setIsDeletingPaginationConfigurationLoading,
  ] = useState(false);

  // context
  const history = useHistory();
  const { setIsPaginationConfigurationComplete } = useContext(IntegrationBuilderContext);

  // methods
  const deletePaginationConfiguration = () => {
    if (!paginationConfigurationID) {
      return;
    }
    setIsDeletingPaginationConfigurationLoading(true);
    fetchWithAuth({
      path: `/integrations/integration-builder/pagination-configuration/${paginationConfigurationID}`,
      method: APIEndpointMethod.DELETE,
      onResponse: () => {
        showSuccessToast("Pagination configuration successfully deleted!");
        setIsDeletingPaginationConfigurationLoading(false);
        // navigate to pagination configurations table page
        navigateToIntegrationBuilderPaginationConfigurationTable(history, integrationID);
        // replaces pagination configuration in paginationConfigurations array with new data
        setPaginationConfigurations((prevPaginationConfiguration) => {
          const updatedPaginationConfigurations = prevPaginationConfiguration?.filter(
            (configuration) => configuration.id !== paginationConfigurationID
          );
          if (updatedPaginationConfigurations?.length === 0) {
            setIsPaginationConfigurationComplete(false);
          }
          return updatedPaginationConfigurations;
        });
      },
      onError: () => {
        showErrorToast("Failed to delete your pagination configuration.");
        setPaginationConfigurations([]);
      },
    });
  };

  return {
    deletePaginationConfiguration,
    isDeletingPaginationConfigurationLoading,
  };
};

export default useDeletePaginationConfiguration;
