import { useHistory } from "react-router-dom";
import { Button, ButtonVariant, Badge, Text } from "@merge-api/merge-javascript-shared";
import { APIEndpointIntegrationBuilder } from "../../utils/Entities";
import { ChevronRight, Copy } from "lucide-react";
import HTTPMethodText from "../../../shared/text/HTTPMethodText";
import { navigateToIntegrationBuilderAPIEndpointForm } from "../../../../router/RouterUtils";
import useDuplicateAPIEndpoint from "../hooks/useDuplicateAPIEndpoint";

type APIEndpointTableRowProps = {
  apiEndpoint: APIEndpointIntegrationBuilder;
  integrationID: string;
  hasButtonHeader?: boolean;
  navigateToNewTab?: boolean;
  setAPIEndpoints?: React.Dispatch<
    React.SetStateAction<APIEndpointIntegrationBuilder[] | undefined>
  >;
  canDuplicate?: boolean;
};

const APIEndpointTableRow = ({
  apiEndpoint,
  integrationID,
  hasButtonHeader = false,
  navigateToNewTab = false,
  setAPIEndpoints = () => {},
  canDuplicate = false,
}: APIEndpointTableRowProps) => {
  // hooks
  const history = useHistory();
  const { isLoadingDuplicateAPIEndpoint, duplicateAPIEndpoint } = useDuplicateAPIEndpoint({
    integrationID: integrationID,
    apiEndpointID: apiEndpoint.id ?? null,
    setAPIEndpoints: setAPIEndpoints,
  });

  // event handlers
  const onAPIEndpointClick = () => {
    if (!apiEndpoint.id) return;
    navigateToIntegrationBuilderAPIEndpointForm(
      history,
      integrationID,
      apiEndpoint.id,
      navigateToNewTab
    );
  };

  return (
    <tr onClick={onAPIEndpointClick} className="cursor-pointer">
      <td>
        <Text className="font-medium">{apiEndpoint.name}</Text>
      </td>
      <td>
        <HTTPMethodText
          method={apiEndpoint.request_configuration.method}
          className={hasButtonHeader ? "ml-3" : ""}
        />
      </td>
      <td>
        <Badge size="sm">{apiEndpoint.request_configuration.path}</Badge>
      </td>
      <td>
        <div className="flex flex-row space-x-6 justify-end items-center">
          {canDuplicate && (
            <Button
              size="sm"
              variant={ButtonVariant.TertiaryWhite}
              leftIcon={
                !isLoadingDuplicateAPIEndpoint ? (
                  <Copy size={12} className="text-black" />
                ) : undefined
              }
              onClick={(event) => {
                event.stopPropagation();
                duplicateAPIEndpoint();
              }}
              loading={isLoadingDuplicateAPIEndpoint}
            >
              Duplicate
            </Button>
          )}

          <ChevronRight size={12} color="black" />
        </div>
      </td>
    </tr>
  );
};

export default APIEndpointTableRow;
