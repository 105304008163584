import resolveConfig from "tailwindcss/resolveConfig";

const tailwindConfig = require("@merge-api/merge-javascript-shared/tailwind.config.js");
const fullConfig = resolveConfig(tailwindConfig);
/**
 * Merge Color Spectrum - SOURCE OF TRUTH FOR HEX CODES
 * Full spectrum of on-brand colors
 *
 * Spectrum should be last resort when picking colors - opt for "palette" instead
 */

export const spectrum = {
  gray0: fullConfig.theme.colors.gray[0],
  gray10: fullConfig.theme.colors.gray[10],
  gray20: fullConfig.theme.colors.gray[20],
  gray30: fullConfig.theme.colors.gray[30],
  gray40: fullConfig.theme.colors.gray[40],
  gray50: fullConfig.theme.colors.gray[50],
  gray60: fullConfig.theme.colors.gray[60],
  gray70: fullConfig.theme.colors.gray[70],
  gray80: fullConfig.theme.colors.gray[80],
  gray90: fullConfig.theme.colors.gray[90],

  slate0: fullConfig.theme.colors.slate[0],
  slate10: fullConfig.theme.colors.slate[10],
  slate20: fullConfig.theme.colors.slate[20],
  slate30: fullConfig.theme.colors.slate[30],
  slate40: fullConfig.theme.colors.slate[40],
  slate50: fullConfig.theme.colors.slate[50],
  slate60: fullConfig.theme.colors.slate[60],
  slate70: fullConfig.theme.colors.slate[70],
  slate80: fullConfig.theme.colors.slate[80],
  slate90: fullConfig.theme.colors.slate[90],

  red0: fullConfig.theme.colors.red[0],
  red10: fullConfig.theme.colors.red[10],
  red20: fullConfig.theme.colors.red[20],
  red30: fullConfig.theme.colors.red[30],
  red40: fullConfig.theme.colors.red[40],
  red50: fullConfig.theme.colors.red[50],
  red60: fullConfig.theme.colors.red[60],
  red70: fullConfig.theme.colors.red[70],
  red80: fullConfig.theme.colors.red[80],
  red90: fullConfig.theme.colors.red[90],

  orange0: fullConfig.theme.colors.orange[0],
  orange10: fullConfig.theme.colors.orange[10],
  orange20: fullConfig.theme.colors.orange[20],
  orange30: fullConfig.theme.colors.orange[30],
  orange40: fullConfig.theme.colors.orange[40],
  orange50: fullConfig.theme.colors.orange[50],
  orange60: fullConfig.theme.colors.orange[60],
  orange70: fullConfig.theme.colors.orange[70],
  orange80: fullConfig.theme.colors.orange[80],
  orange90: fullConfig.theme.colors.orange[90],

  amber0: fullConfig.theme.colors.amber[0],
  amber10: fullConfig.theme.colors.amber[10],
  amber20: fullConfig.theme.colors.amber[20],
  amber30: fullConfig.theme.colors.amber[30],
  amber40: fullConfig.theme.colors.amber[40],
  amber50: fullConfig.theme.colors.amber[50],
  amber60: fullConfig.theme.colors.amber[60],
  amber70: fullConfig.theme.colors.amber[70],
  amber80: fullConfig.theme.colors.amber[80],
  amber90: fullConfig.theme.colors.amber[90],

  yellow0: fullConfig.theme.colors.yellow[0],
  yellow10: fullConfig.theme.colors.yellow[10],
  yellow20: fullConfig.theme.colors.yellow[20],
  yellow30: fullConfig.theme.colors.yellow[30],
  yellow40: fullConfig.theme.colors.yellow[40],
  yellow50: fullConfig.theme.colors.yellow[50],
  yellow60: fullConfig.theme.colors.yellow[60],
  yellow70: fullConfig.theme.colors.yellow[70],
  yellow80: fullConfig.theme.colors.yellow[80],
  yellow90: fullConfig.theme.colors.yellow[90],

  green0: fullConfig.theme.colors.green[0],
  green10: fullConfig.theme.colors.green[10],
  green20: fullConfig.theme.colors.green[20],
  green30: fullConfig.theme.colors.green[30],
  green40: fullConfig.theme.colors.green[40],
  green50: fullConfig.theme.colors.green[50],
  green60: fullConfig.theme.colors.green[60],
  green70: fullConfig.theme.colors.green[70],
  green80: fullConfig.theme.colors.green[80],
  green90: fullConfig.theme.colors.green[90],

  emerald0: fullConfig.theme.colors.emerald[0],
  emerald10: fullConfig.theme.colors.emerald[10],
  emerald20: fullConfig.theme.colors.emerald[20],
  emerald30: fullConfig.theme.colors.emerald[30],
  emerald40: fullConfig.theme.colors.emerald[40],
  emerald50: fullConfig.theme.colors.emerald[50],
  emerald60: fullConfig.theme.colors.emerald[60],
  emerald70: fullConfig.theme.colors.emerald[70],
  emerald80: fullConfig.theme.colors.emerald[80],
  emerald90: fullConfig.theme.colors.emerald[90],

  teal0: fullConfig.theme.colors.teal[0],
  teal10: fullConfig.theme.colors.teal[10],
  teal20: fullConfig.theme.colors.teal[20],
  teal30: fullConfig.theme.colors.teal[30],
  teal40: fullConfig.theme.colors.teal[40],
  teal50: fullConfig.theme.colors.teal[50],
  teal60: fullConfig.theme.colors.teal[60],
  teal70: fullConfig.theme.colors.teal[70],
  teal80: fullConfig.theme.colors.teal[80],
  teal90: fullConfig.theme.colors.teal[90],

  cyan0: fullConfig.theme.colors.cyan[0],
  cyan10: fullConfig.theme.colors.cyan[10],
  cyan20: fullConfig.theme.colors.cyan[20],
  cyan30: fullConfig.theme.colors.cyan[30],
  cyan40: fullConfig.theme.colors.cyan[40],
  cyan50: fullConfig.theme.colors.cyan[50],
  cyan60: fullConfig.theme.colors.cyan[60],
  cyan70: fullConfig.theme.colors.cyan[70],
  cyan80: fullConfig.theme.colors.cyan[80],
  cyan90: fullConfig.theme.colors.cyan[90],

  sky0: fullConfig.theme.colors.sky[0],
  sky10: fullConfig.theme.colors.sky[10],
  sky20: fullConfig.theme.colors.sky[20],
  sky30: fullConfig.theme.colors.sky[30],
  sky40: fullConfig.theme.colors.sky[40],
  sky50: fullConfig.theme.colors.sky[50],
  sky60: fullConfig.theme.colors.sky[60],
  sky70: fullConfig.theme.colors.sky[70],
  sky80: fullConfig.theme.colors.sky[80],
  sky90: fullConfig.theme.colors.sky[90],

  blue0: fullConfig.theme.colors.blue[0],
  blue10: fullConfig.theme.colors.blue[10],
  blue20: fullConfig.theme.colors.blue[20],
  blue30: fullConfig.theme.colors.blue[30],
  blue40: fullConfig.theme.colors.blue[40],
  blue50: fullConfig.theme.colors.blue[50],
  blue60: fullConfig.theme.colors.blue[60],
  blue70: fullConfig.theme.colors.blue[70],
  blue80: fullConfig.theme.colors.blue[80],
  blue90: fullConfig.theme.colors.blue[90],

  indigo0: fullConfig.theme.colors.indigo[0],
  indigo10: fullConfig.theme.colors.indigo[10],
  indigo20: fullConfig.theme.colors.indigo[20],
  indigo30: fullConfig.theme.colors.indigo[30],
  indigo40: fullConfig.theme.colors.indigo[40],
  indigo50: fullConfig.theme.colors.indigo[50],
  indigo60: fullConfig.theme.colors.indigo[60],
  indigo70: fullConfig.theme.colors.indigo[70],
  indigo80: fullConfig.theme.colors.indigo[80],
  indigo90: fullConfig.theme.colors.indigo[90],

  purple0: fullConfig.theme.colors.purple[0],
  purple10: fullConfig.theme.colors.purple[10],
  purple20: fullConfig.theme.colors.purple[20],
  purple30: fullConfig.theme.colors.purple[30],
  purple40: fullConfig.theme.colors.purple[40],
  purple50: fullConfig.theme.colors.purple[50],
  purple60: fullConfig.theme.colors.purple[60],
  purple70: fullConfig.theme.colors.purple[70],
  purple80: fullConfig.theme.colors.purple[80],
  purple90: fullConfig.theme.colors.purple[90],

  pink0: fullConfig.theme.colors.pink[0],
  pink10: fullConfig.theme.colors.pink[10],
  pink20: fullConfig.theme.colors.pink[20],
  pink30: fullConfig.theme.colors.pink[30],
  pink40: fullConfig.theme.colors.pink[40],
  pink50: fullConfig.theme.colors.pink[50],
  pink60: fullConfig.theme.colors.pink[60],
  pink70: fullConfig.theme.colors.pink[70],
  pink80: fullConfig.theme.colors.pink[80],
  pink90: fullConfig.theme.colors.pink[90],
};

/**
 * Merge Color Palette
 * Palette of primary used colors
 *
 * Palette should be first resort when picking colors
 * If a color isn't available in Palette, move a color from Spectrum to Palette before using
 */
export const palette = {
  white: "#ffffff",

  lightGray: spectrum.gray0,
  border: spectrum.gray10,
  empty: spectrum.gray30,
  placeholder: spectrum.gray40,
  gray: spectrum.gray50,
  slate: spectrum.gray60,
  graphite: spectrum.gray70,
  black: spectrum.slate90,

  red: spectrum.red50,
  redHover: spectrum.red70,

  green: spectrum.teal50,
  greenHover: spectrum.teal70,

  blue: spectrum.blue40,
  blueHover: spectrum.blue60,

  yellow: spectrum.yellow50,
  yellowHover: spectrum.yellow70,

  indigo: spectrum.indigo40,
  indigoHover: spectrum.indigo60,

  purple: spectrum.purple50,
  purpleHover: spectrum.purple70,

  pink: spectrum.pink50,
  pinkHover: spectrum.pink70,
};
