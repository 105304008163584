import {
  ChangelogEventType,
  ChangelogModelType,
  ChangelogModelTypeMap,
  ChangelogModelTypeRouteMap,
} from "../../../utils/types";
import { Text, Link } from "@merge-api/merge-javascript-shared";
import { ArrowUpRight } from "lucide-react";
import { useParams } from "react-router-dom";

type ModelCellProps = {
  modelType: ChangelogModelType;
  eventType?: ChangelogEventType;
  modelID?: string;
  githubDeployURL?: string;
};

type RouteParams = {
  integrationID: string;
};

const ModelCell = ({ modelType, eventType, modelID, githubDeployURL }: ModelCellProps) => {
  // router params integration ID
  const { integrationID } = useParams<RouteParams>();
  // const derived from state
  const modelHumanString = ChangelogModelTypeMap[modelType];

  // function to get route path for links
  function getRoutePath(
    modelType: ChangelogModelType,
    integrationID: string,
    modelID?: string
  ): string {
    const routeFunction = ChangelogModelTypeRouteMap[modelType];
    if (!routeFunction) {
      return "";
    }
    return modelID ? routeFunction(integrationID, modelID) : routeFunction(integrationID);
  }

  return (
    <td>
      <Text className="text-black flex-wrap">
        {modelHumanString ? modelHumanString : "Deploy made to merge-backend"}
      </Text>
      {modelID && (
        <div className="flex-wrap">
          {eventType === ChangelogEventType.DELETED ? (
            <Text variant="sm" className="text-gray-60 font-semibold">
              {modelID}
            </Text>
          ) : (
            <Link
              href={getRoutePath(modelType, integrationID, modelID)}
              variant="sm"
              target="_blank"
              className="inline-flex flex-row items-center font-semibold text-blue-40 hover:text-blue-60"
            >
              {modelID} <ArrowUpRight className="ml-1.5 flex items-center" size={12} />
            </Link>
          )}
        </div>
      )}
      {modelType === null && githubDeployURL && (
        <div className="flex-wrap">
          {eventType === ChangelogEventType.DELETED ? (
            <Text variant="sm" className="text-gray-60 font-semibold">
              {modelID}
            </Text>
          ) : (
            <Link
              href={githubDeployURL}
              variant="sm"
              target="_blank"
              className="inline-flex flex-row items-center font-semibold text-blue-40 hover:text-blue-60"
            >
              Github <ArrowUpRight className="ml-1.5 flex items-center" size={12} />
            </Link>
          )}
        </div>
      )}
    </td>
  );
};

export default ModelCell;
