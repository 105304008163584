import { Route, Switch } from "react-router-dom";
import {
  getIntegrationBuilderCreateNewWebAddressSchemaPathForId,
  getIntegrationBuilderSingleWebAddressSchemaPathForId,
  getIntegrationBuilderWebAddressSchemaForId,
} from "../../../router/RouterUtils";
import WebAddressSchemaTable from "./components/WebAddressSchemaTable";
import WebAddressSchemaSetup from "./components/WebAddressSchemaSetup";
import useLoadWebAddressSchemas from "./hooks/useLoadWebAddressSchemas";

interface WebAddressSchemaProps {
  integrationID: string;
}

const WebAddressSchema = ({ integrationID }: WebAddressSchemaProps) => {
  //hooks
  const {
    webAddressSchemas,
    isLoadingWebAddressSchemas,
    setWebAddressSchemas,
  } = useLoadWebAddressSchemas({
    integrationID,
  });

  return (
    <div>
      <Switch>
        <Route
          exact
          path={getIntegrationBuilderWebAddressSchemaForId(integrationID)}
          render={() => (
            <WebAddressSchemaTable
              integrationID={integrationID}
              webAddressSchemas={webAddressSchemas}
              isLoadingWebAddressSchemas={isLoadingWebAddressSchemas}
            />
          )}
        />
        <Route
          exact
          path={getIntegrationBuilderCreateNewWebAddressSchemaPathForId(integrationID)}
          render={() => (
            <WebAddressSchemaSetup
              webAddressSchemas={webAddressSchemas}
              setWebAddressSchemas={setWebAddressSchemas}
              isNewWebAddressSchema={true}
              integrationID={integrationID}
            />
          )}
        />
        <Route
          exact
          path={getIntegrationBuilderSingleWebAddressSchemaPathForId(integrationID)}
          render={() => (
            <WebAddressSchemaSetup
              webAddressSchemas={webAddressSchemas}
              setWebAddressSchemas={setWebAddressSchemas}
              isNewWebAddressSchema={false}
              integrationID={integrationID}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default WebAddressSchema;
