import { Form } from "react-bootstrap";
import { FormFieldCommonProps } from "./FormField";

interface Props extends FormFieldCommonProps {
  onChange: (value: boolean) => void;
  currentValue: boolean;
}

const CheckboxFormField = ({ onChange, currentValue, title }: Props) => {
  return (
    <Form.Group>
      <Form.Check
        type="checkbox"
        label={title}
        defaultChecked={currentValue}
        onChange={(newValue) => onChange(newValue.target.checked)}
      />
    </Form.Group>
  );
};

export default CheckboxFormField;
