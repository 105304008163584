import { useLoadUsers } from "./hooks/useLoadUsers";
import { Typeahead } from "@merge-api/merge-javascript-shared";

type UserPickerProps = {
  userId: string | null;
  onUserIdChange: (userId: string | null) => void;
};

interface Option {
  value: string;
  label: string;
}

const UserPicker = ({ userId, onUserIdChange }: UserPickerProps) => {
  const { users, isLoading, onUserFilterChange } = useLoadUsers();

  const userOptions: Option[] = users.map((user) => ({
    label: user.name,
    value: user.id,
  }));

  const getNameFromUserId = (userId: string): string | null => {
    const userOption = userOptions.find((option) => option.value === userId);
    return userOption ? userOption.label : null;
  };

  const selectedUser: Option | null = userId
    ? { label: getNameFromUserId(userId)!, value: userId }
    : null;

  return (
    <Typeahead
      className="bg-white"
      options={userOptions}
      value={selectedUser}
      onChange={(_, selectedOption: Option | null) =>
        onUserIdChange(selectedOption ? selectedOption.value : null)
      }
      onInputChange={(_, inputValue) => onUserFilterChange(inputValue)}
      loading={isLoading}
      placeholder="All users"
      getOptionLabel={(option) => option.label}
      getOptionKey={(option) => option.value}
    />
  );
};

export default UserPicker;
