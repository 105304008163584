import { AuthConfigMeta } from "../../../models/AuthConfig";
import { useState } from "react";
import InputFormField from "../../blueprint-editor/right-panel/InputFormField";
import { Col, Row, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { showSuccessToast } from "../../shared/Toasts";
import { AuthType } from "../../../models/Entities";
import { navigateToAuthConfigsTable } from "../../../router/RouterUtils";
import { SaveAuthConfigButton, AuthConfigUpdateProps } from "./AuthConfigShared";
import AuthConfigEditFormRightPanel from "./AuthConfigEditFormRightPanel";

type Props = {
  authConfig?: AuthConfigMeta;
  integrationID: string;
  integrationCategory: string | undefined;
  authName: string | undefined;
  isEditingExistingConfig: boolean;
};

const AuthConfigBasicAuthTokenExchangeForm = (props: Props) => {
  const history = useHistory();
  const [tokenExchangeURL, setTokenExchangeURL] = useState<undefined | string>(
    props.authConfig?.basic_auth_key_token_exchange_url
  );

  const [tokenHeaderFormat, setTokenHeaderFormat] = useState<undefined | string>(
    props.authConfig?.basic_auth_key_token_exchange_header_format
  );

  const [requestDataFormat, setRequestDataFormat] = useState<undefined | string>(
    props.authConfig?.basic_auth_key_token_exchange_request_data_format
  );

  const [requestDataConvertToString, setRequestDataConvertToString] = useState<undefined | boolean>(
    props.authConfig?.basic_auth_key_token_exchange_request_data_convert_to_string
  );

  const [tokenExchangeRespAuthKeyPath, setTokenExchangeRespAuthKeyPath] = useState<
    undefined | string
  >(props.authConfig?.basic_auth_key_token_exchange_response_auth_key_path);

  const [tokenExchangeRespLifespanKeyPath, setTokenExchangeResponseKeyPath] = useState<
    undefined | string
  >(props.authConfig?.basic_auth_key_token_access_token_lifespan_key_path);

  const [lifespanInSeconds, setLifespanInSeconds] = useState<undefined | string>(
    props.authConfig?.basic_auth_key_token_exchange_access_token_lifespan_in_seconds
  );
  const [isLoading, setIsLoading] = useState(false);

  const authConfigUpdates: AuthConfigUpdateProps = {
    id: props.authConfig?.id,
    name: props.authName,
    authType: AuthType.BASIC_AUTH_WITH_TOKEN_EXCHANGE,
    tokenExchangeURL: tokenExchangeURL,
    headerFormatForTokenExchange: tokenHeaderFormat,
    requestDataFormat: requestDataFormat,
    pathToAuthKey:
      tokenExchangeRespAuthKeyPath && tokenExchangeRespAuthKeyPath.length !== 0
        ? tokenExchangeRespAuthKeyPath.toString().replace(/\s/g, "").split(",")
        : undefined,
    lifespanKeyPath:
      tokenExchangeRespLifespanKeyPath && tokenExchangeRespLifespanKeyPath.length !== 0
        ? tokenExchangeRespLifespanKeyPath.toString().replace(/\s/g, "").split(",")
        : undefined,
    lifespanOfToken: lifespanInSeconds,
    integrationID: props.integrationID,
    integrationCategory: props.integrationCategory,
    basicAuthKeyTokenExchangeRequestDataConvertToString: requestDataConvertToString,
  };

  return (
    <Row>
      <Col>
        <InputFormField
          currentValue={tokenExchangeURL}
          onChange={(tokenExchangeURL) => setTokenExchangeURL(tokenExchangeURL)}
          placeholder=""
          title={"Token Exchange URL"}
          subtitle={
            "URL for exchanging information (like username & password) for a authentication token."
          }
        />
        <InputFormField
          currentValue={tokenHeaderFormat}
          onChange={(tokenHeaderFormat) => setTokenHeaderFormat(tokenHeaderFormat)}
          placeholder=""
          title={"Token Exchange Header Format"}
          subtitle={"Header format for the token exchnage."}
        />
        <InputFormField
          currentValue={requestDataFormat}
          onChange={(requestDataFormat) => setRequestDataFormat(requestDataFormat)}
          placeholder=""
          title={"Token Exchange Request Data Format"}
          subtitle={
            "Body data format for sending the exchange request. We can use placeholders like {USERNAME} and {PASSWORD}"
          }
        />

        <InputFormField
          currentValue={tokenExchangeRespAuthKeyPath}
          onChange={(tokenExchangeRespAuthKeyPath) =>
            setTokenExchangeRespAuthKeyPath(tokenExchangeRespAuthKeyPath)
          }
          placeholder=""
          title={"Token Exchange Response Authentication Token Key Path"}
          subtitle={"Path in the token exchange response to grab the authentication token."}
        />

        <InputFormField
          currentValue={tokenExchangeRespLifespanKeyPath}
          onChange={(tokenExchangeRespLifespanKeyPath) =>
            setTokenExchangeResponseKeyPath(tokenExchangeRespLifespanKeyPath)
          }
          placeholder=""
          title={"Token Exchange Response Lifespan Key Path"}
          subtitle={
            "If it exists, the path in the token exchange response to grab the lifespan of the auth key."
          }
        />

        <InputFormField
          currentValue={lifespanInSeconds}
          onChange={(lifespanInSeconds) => setLifespanInSeconds(lifespanInSeconds)}
          placeholder=""
          title={"Token Exchange Lifespan in Seconds"}
          subtitle={"Manually set how long the access token's lifespan is."}
        />

        <Form.Group controlId="convert_data_to_string">
          <Form.Check
            type="checkbox"
            label="Select to convert request data to string"
            onChange={() => setRequestDataConvertToString(!requestDataConvertToString)}
            checked={requestDataConvertToString}
          />
        </Form.Group>

        <SaveAuthConfigButton
          text={
            !props.isEditingExistingConfig
              ? "Save configuration"
              : "Save and continue editing auth configuration"
          }
          onSuccess={() => {
            showSuccessToast(
              props.authConfig
                ? "Successfully updated configuration!"
                : "Successfully created configuration!"
            );
            setIsLoading(false);
            if (!props.isEditingExistingConfig) {
              navigateToAuthConfigsTable(history, props.integrationID);
            }
          }}
          setLoading={setIsLoading}
          isLoading={isLoading}
          authConfigUpdateProps={authConfigUpdates}
        />
      </Col>
      <AuthConfigEditFormRightPanel authConfigUpdateProps={authConfigUpdates} />
    </Row>
  );
};

export default AuthConfigBasicAuthTokenExchangeForm;
