import { DiffState } from "../../../../../models/DiffModels";
import { generateDiffForSimpleComponents } from "../helpers-diff";

/**
 * Generates diff state for step's template's API endpoint's pagination configuration, if it has one
 * @param {Object} currentPagConfigData - The current pagination configuration data.
 * @param {Object} newPagConfigData - The new pagination configuration data.
 * @returns {DiffState} - The generated diff state for the pagination configuration.
 */
export const generateDiffStateForStepTemplatePaginationConfiguration = (
  currentPagConfigData: Object,
  newPagConfigData: Object
): DiffState => {
  return generateDiffForSimpleComponents(currentPagConfigData, newPagConfigData);
};
