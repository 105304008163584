import { Alert, Text } from "@merge-api/merge-javascript-shared";
import { Info } from "lucide-react";

interface SchemaExplainProps {
  className?: string;
}
const SchemaExplainAlert = ({ className }: SchemaExplainProps) => {
  return (
    <Alert
      title="What is a web address schema?"
      color="gray"
      icon={<Info size={16} />}
      className={className}
    >
      <Text>
        A web address schema is a URL format Merge whitelists to ensure security. For example, if
        the integration requires API calls to "api.acme.com/api/2/...", then you would add a web
        address schema for "https://api.acme.com".
      </Text>
    </Alert>
  );
};

export default SchemaExplainAlert;
