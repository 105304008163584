import clsx from "clsx";
import { BuilderStatus } from "../utils/Entities";
import { AlertTriangle, CheckCircle2, RefreshCcw } from "lucide-react";
import { ReactNode } from "react";

interface NavBarTabNameAndStatus {
  name: string;
  icon: ReactNode;
  state: BuilderStatus;
  selected: boolean;
  disabled: boolean;
}

const NavBarTabNameAndStatus = ({
  name,
  icon,
  state,
  selected,
  disabled,
}: NavBarTabNameAndStatus) => {
  const pending = state === BuilderStatus.PENDING;
  const completed = state === BuilderStatus.COMPLETED;
  const failed = state === BuilderStatus.FAILED;

  return (
    <div className="flex flex-row items-center justify-between w-full font-semibold">
      <div className="flex flex-row items-center">
        <div
          className={clsx("text-base items-center flex flex-row font-semibold", {
            "text-blue-40": selected && !disabled,
            "text-black": disabled,
          })}
        >
          <div className="flex items-center mr-2">{icon}</div>
          {name}
        </div>
      </div>
      {failed && <AlertTriangle className="text-yellow-80" size={16} />}
      {pending && <RefreshCcw className="rotating-counter ml-2 text-blue-40" size={16} />}
      {completed && <CheckCircle2 className="text-green-50" size={16} />}
    </div>
  );
};

export default NavBarTabNameAndStatus;
