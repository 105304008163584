import { useState } from "react";
import { fetchWithAuth } from "../../../../../api-client/api_client";
import { MappingTestRequestMock } from "../../../../../models/MappingTests";
import { showErrorToast, showSuccessToast } from "../../../../shared/Toasts";

interface UseImportBlueprintExecutionsToMappingTestProps {
  blueprintExecutionID: string;
  testID: string;
  addRequestMock: (requestMock: MappingTestRequestMock) => void;
  handleClose: () => void;
  saveMappingTest: () => void;
}

export const useImportBlueprintExecutionsToMappingTest = ({
  blueprintExecutionID,
  testID,
  addRequestMock,
  handleClose,
  saveMappingTest,
}: UseImportBlueprintExecutionsToMappingTestProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = () => {
    setIsLoading(true);

    fetchWithAuth({
      path: `stallions/tests/${testID}/import-blueprint-execution/${blueprintExecutionID}`,
      method: "POST",
      onResponse: (data: MappingTestRequestMock[]) => {
        setIsLoading(false);
        handleClose();
        data.forEach(addRequestMock);
        showSuccessToast("Successfully imported request mocks");
        saveMappingTest();
      },
      onError: () => {
        setIsLoading(false);
        showErrorToast("Failed to import request mocks");
      },
    });
  };

  return { isLoading, handleConfirm };
};
