import { BlueprintMeta } from "../../../../models/Entities";
import { useParams } from "react-router-dom";
import { NextComponentVersionState } from "../../../integrations/versioned-components/types";
import { useState } from "react";
import { Button, Select, Text } from "@merge-api/merge-javascript-shared";
import {
  BlueprintExecutionPreviewInfo,
  MappingTestRequestMock,
} from "../../../../models/MappingTests";
import { showErrorToast } from "../../../shared/Toasts";
import { fetchWithAuth } from "../../../../api-client/api_client";
import BlueprintExecutionPreviewCard from "./BlueprintExecutionPreviewCard";
import BlueprintExecutionsContainer from "./BlueprintExecutionsContainer";
import HeaderBase from "../../../integration-builder/shared/HeaderBase";

type Props = {
  blueprints: { [key: string]: BlueprintMeta };
  mappingTestState: NextComponentVersionState;
  addRequestMock: (requestMock: MappingTestRequestMock) => void;
  saveMappingTest: () => void;
  isMappingTestNextVersionNull: boolean;
};

const ImportExecutionsContainer = ({
  blueprints,
  mappingTestState,
  addRequestMock,
  saveMappingTest,
  isMappingTestNextVersionNull,
}: Props) => {
  const { testID } = useParams<{ integrationID: string; testID: string }>();

  // states
  const [selectedBlueprint, setSelectedBlueprint] = useState<BlueprintMeta | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const [executions, setExecutions] = useState<BlueprintExecutionPreviewInfo[]>([]);
  const [showAll, setShowAll] = useState(false);

  // const derived from state
  const isStaged = mappingTestState === NextComponentVersionState.STAGED_WITH_CHANGES;
  const noChanges = mappingTestState === NextComponentVersionState.NO_CHANGES;
  const isNewTestAndUnSaved = noChanges && isMappingTestNextVersionNull;
  const isLoadButtonsDisabled = isStaged || isNewTestAndUnSaved;
  const loadAll = () => {
    setShowAll(true);
  };

  const loadExecutions = () => {
    setIsLoading(true);
    fetchWithAuth({
      path: `/stallions/blueprint-versions/${selectedBlueprint?.id}/recent-blueprint-executions`,
      method: "GET",
      onResponse: (data: BlueprintExecutionPreviewInfo[]) => {
        setExecutions(data);
        setIsLoading(false);
        setSearched(true);
        setShowAll(false);
      },
      onError: () => {
        showErrorToast(`Error fetching recent executions for blueprint ${selectedBlueprint?.id}`);
        setIsLoading(false);
        setShowAll(false);
      },
    });
  };

  const blueprintOptions = [...Object.values(blueprints)];

  return (
    <div className="m-4">
      <HeaderBase
        title="Recent executions for blueprint:"
        subtitle='Clicking "Load Blueprint" or "Load All" retrieves the most recent blueprint executions from the staged blueprint version, or from the published version if there is no staged version'
      />
      {
        <div className="flex items-center">
          <Select
            className="min-w-0"
            placeholder="Select blueprint"
            options={blueprintOptions}
            getOptionLabel={(option) => option.human_name || option.name}
            onChange={(_: any, value: BlueprintMeta | null) => {
              setSelectedBlueprint(value ? value : undefined);
            }}
          />
          <Button
            className="ml-2"
            onClick={loadExecutions}
            loading={isLoading}
            disabled={!selectedBlueprint || isLoadButtonsDisabled}
          >
            Load Blueprint
          </Button>
          <Button className="ml-2" onClick={loadAll} disabled={isLoadButtonsDisabled}>
            Load All
          </Button>
        </div>
      }
      {isLoadButtonsDisabled ? (
        <div className="mt-4 text-red">
          {isStaged && (
            <Text className="text-red font-semibold">
              You must unstage the test to import request mocks
            </Text>
          )}
          {isNewTestAndUnSaved && (
            <Text className="text-red font-semibold">
              You must save the test to import request mocks
            </Text>
          )}
        </div>
      ) : showAll ? (
        <div>
          {Object.values(blueprints)
            .filter((blueprintMeta) => blueprintMeta.id)
            .map((blueprintMeta) => {
              return (
                <BlueprintExecutionsContainer
                  saveMappingTest={saveMappingTest}
                  addRequestMock={addRequestMock}
                  blueprintMeta={blueprintMeta}
                  testID={testID}
                />
              );
            })}
        </div>
      ) : (
        <div className="mt-4">
          {executions?.length > 0
            ? executions?.map((execution) => (
                <BlueprintExecutionPreviewCard
                  addRequestMock={addRequestMock}
                  testID={testID}
                  key={execution.id}
                  data={execution}
                  saveMappingTest={saveMappingTest}
                />
              ))
            : searched && (
                <Text variant="caption">
                  No recent executions with API requests were found. Note that it may take a few
                  minutes for API request logs to save and be available to import after a blueprint
                  run.
                </Text>
              )}
        </div>
      )}
    </div>
  );
};

export default ImportExecutionsContainer;
