import { Text } from "@merge-api/merge-javascript-shared";
import PublishModalTextField from "./PublishModalTextField";

type Props = {
  setTicket: React.Dispatch<React.SetStateAction<string | undefined>>;
  setDescription: React.Dispatch<React.SetStateAction<string | undefined>>;
  ticket: string | undefined;
  description: string | undefined;
};

const PublishModalContextScreen = ({ setTicket, setDescription, ticket, description }: Props) => {
  return (
    <div className="flex flex-column h-full justify-between">
      <div className="space-y-4 w-full">
        <Text>Before you publish, add context.</Text>
        <PublishModalTextField
          title="Asana ticket"
          description="Link the relevant Asana ticket, so that it can be tracked in changelog."
          placeholder="https://app.asana.com/your-ticket"
          value={ticket}
          onChange={(e) => setTicket(e.target.value)}
        />
        <PublishModalTextField
          title="Comment"
          description="Enter any comments explaining the change you’re publishing."
          placeholder="I’m making this change because ..."
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
    </div>
  );
};

export default PublishModalContextScreen;
