import TextFieldHeader from "../../../shared/TextFieldHeader";
import PostmanTableHeader from "../shared/PostmanTableHeader";
import { Row } from "../../../shared/postman-table/PostmanTableRow";
import HeadersHelperAccordion from "../shared/HeadersHelperAccordion";
import CheckboxHeader from "../../../shared/CheckboxHeader";

const BODY_DATA_TITLE = "Body data";
const BODY_DATA_DESCRIPTION =
  "The body data to send when requesting a token. You can use {USERNAME}, {PASSWORD}, {DOMAIN}, {PATH}, {CLIENT-ID}, {$GUID}, and {$TIMESTAMP} in this URL where $ denotes a dynamic value.";
const HTML_PLACEHOLDER = `<?xml version="1.0" encoding="UTF-8"?><request></request>`;

interface BasicAuthWithTokenExchangeRequestCardProps {
  basicAuthExchangeURL: string | undefined;
  setBasicAuthExchangeURL: React.Dispatch<React.SetStateAction<string | undefined>>;
  refreshTokenHeaders: Row[];
  setRefreshTokenHeaders: React.Dispatch<React.SetStateAction<Row[]>>;
  isSoapIntegration: boolean;
  refreshTokenBodyParams: Row[];
  setRefreshTokenBodyParams: React.Dispatch<React.SetStateAction<Row[]>>;
  refreshTokenStringBodyParams: string | undefined;
  setRefreshTokenStringBodyParams: React.Dispatch<React.SetStateAction<string | undefined>>;
  shouldConvertBodyDataToString: boolean | undefined;
  setShouldConvertBodyDataToString: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const BasicAuthWithTokenExchangeRequestCard = ({
  basicAuthExchangeURL,
  setBasicAuthExchangeURL,
  refreshTokenHeaders,
  setRefreshTokenHeaders,
  isSoapIntegration,
  refreshTokenBodyParams,
  setRefreshTokenBodyParams,
  refreshTokenStringBodyParams,
  setRefreshTokenStringBodyParams,
  shouldConvertBodyDataToString,
  setShouldConvertBodyDataToString,
}: BasicAuthWithTokenExchangeRequestCardProps) => {
  const showRefreshTokenStringBodyParamInput = isSoapIntegration || shouldConvertBodyDataToString;
  return (
    <>
      <TextFieldHeader
        dataTestID="field-auth-config-token-exchange-url"
        title="Token exchange URL"
        subtitle="If this API requires an exchange of information like username and password to obtain a key, put the URL here that we should hit to retrieve the token. You can use {DOMAIN} and {PATH} in this URL."
        placeholder="example.com"
        prefix="https://"
        prefixVariant="url"
        value={basicAuthExchangeURL}
        onChange={(newEvent) => {
          setBasicAuthExchangeURL(newEvent.target.value);
        }}
        required
      />
      <PostmanTableHeader
        dataTestID="field-auth-config-token-exchange-header-fields"
        className="mt-6"
        title="Header fields"
        subtitle="Auth key header format for token exchange URL only. Example: For UKG Ready, Accept: application/json, Content-Type: application/json, api-key: {api-key}>."
        rows={refreshTokenHeaders}
        setRows={setRefreshTokenHeaders}
      />
      <HeadersHelperAccordion />
      {showRefreshTokenStringBodyParamInput ? (
        <TextFieldHeader
          dataTestID="field-auth-config-token-exchange-soap-body-data"
          className="mt-6"
          title={BODY_DATA_TITLE}
          subtitle={BODY_DATA_DESCRIPTION}
          maxRows={10}
          autosize={true}
          placeholder={"Example: " + HTML_PLACEHOLDER}
          value={refreshTokenStringBodyParams}
          onChange={(event) => setRefreshTokenStringBodyParams(event.target.value)}
        />
      ) : (
        <PostmanTableHeader
          dataTestID="field-auth-config-token-exchange-body-data"
          className="mt-6"
          title={BODY_DATA_TITLE}
          subtitle={BODY_DATA_DESCRIPTION}
          rows={refreshTokenBodyParams}
          setRows={setRefreshTokenBodyParams}
        />
      )}
      <CheckboxHeader
        dataTestID="field-auth-config-token-exchange-convert-body-data-to-string"
        className="mt-6"
        title="Convert body data to string"
        checked={isSoapIntegration ? true : shouldConvertBodyDataToString ?? false}
        disabled={isSoapIntegration}
        onChange={setShouldConvertBodyDataToString}
      />
    </>
  );
};

export default BasicAuthWithTokenExchangeRequestCard;
