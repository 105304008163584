import React, { useState } from "react";
import { ArrowLeft } from "lucide-react";
import { Card, Col, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { fetchWithAuth } from "../../../api-client/api_client";
import { navigateToScraperHistory, navigateToScrapersSubtab } from "../../../router/RouterUtils";
import { getLastSegmentOfURLPath } from "../../../utils";
import InputFormField from "../../blueprint-editor/right-panel/InputFormField";
import { ScraperExecutionPreview } from "../../scraper/types";
import MergeModal from "../../shared/MergeModal";
import DeprecatedPaginationFooter from "../../shared/DeprecatedPaginationFooter";
import { showErrorToast } from "../../shared/Toasts";
import ButtonWithTooltip from "../../shared/ButtonWithTooltip";
import ScraperExitCodeBadge from "./ScraperExitCodeBadge";
import { formatRecentTimestamp } from "../../../models/Helpers";
import DeprecatedH1 from "../../deprecated/DeprecatedH1";
import { Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";

type Props = {
  executions: ScraperExecutionPreview[] | undefined;
  nextPageURL: string | null;
  previousPageURL: string | null;
  fetchExecutions: (cursorURL?: string, linkedAccountID?: string) => void;
  integrationID: string;
  scraperID: string;
  integrationName: string;
  scraperName: string;
};

type ExecutionDataURLs = {
  urls: string[];
};

type ResultsURLs = {
  urls: string[];
};

const ExecutionsTable = styled(Table)`
  font-size: 13px;
`;

const ButtonContainer = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  float: left;
  width: 100%;
  justify-content: end;
`;

const URLList = styled.ul`
  padding-top: 18px;
  overflow-y: auto;
  max-height: 500px;
`;
const URLListItem = styled.li``;

const ModalBody = styled.div`
  overflow-y = auto;
  display: flex;
  flex-direction: column
`;

const CopySpan = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const CodeSmall = styled.code`
  font-size: 12px;
`;

const ScraperHistoryView = ({
  integrationID,
  scraperID,
  executions,
  nextPageURL,
  previousPageURL,
  fetchExecutions,
  integrationName,
  scraperName,
}: Props) => {
  const [showPermissionModal, setShowPermissionModal] = useState<boolean>(false);
  const [showExecutionDataModal, setShowExecutionDataModal] = useState<boolean>(false);
  const [showScreenshotsModal, setShowScreenshotsModal] = useState<boolean>(false);

  const [executionDataURLs, setExecutionDataURLs] = useState<string[]>([]);
  const [modalHelpText, setModalHelpText] = useState<React.ReactNode | undefined>(undefined);
  const [screenshotURLs, setScreenshotURLs] = useState<string[]>([]);
  const [modalOnProceed, setModalOnProceed] = useState<() => () => void>(() => () => {});
  const [linkedAccountFilter, setLinkedAccountFilter] = useState<string>("");
  const [copiedID, setCopiedID] = useState("");
  const history = useHistory();

  const fetchTraceURLs = (executionID: string) => {
    setModalHelpText(
      <p>
        Use <a href="https://playwright.dev/docs/trace-viewer">Playwright Trace Viewer</a> locally
        (not the playwright-hosted version) to view traces.
      </p>
    );
    fetchWithAuth({
      path: `/scrapers/executions/${executionID}/trace-urls`,
      method: "GET",
      onResponse: (response: ExecutionDataURLs) => {
        setExecutionDataURLs(response.urls);
      },
      onError: () => {
        showErrorToast("No traces available for this execution.");
      },
    });
  };

  const fetchScreenshotURLs = (executionID: string) => {
    setModalHelpText(undefined);
    fetchWithAuth({
      path: `/scrapers/executions/${executionID}/screenshot-urls`,
      method: "GET",
      onResponse: (response: ExecutionDataURLs) => {
        setScreenshotURLs(response.urls);
      },
      onError: () => {
        showErrorToast("No screenshots found for this execution.");
      },
    });
  };

  const fetchDownloadURLs = (executionID: string) => {
    setModalHelpText(
      <p>
        These are the reports and other files downloaded by the scraper execution. CSVs and Excel
        sheets can be converted to JSON output by the scraper.
      </p>
    );
    fetchWithAuth({
      path: `/scrapers/executions/${executionID}/download-urls`,
      method: "GET",
      onResponse: (response: ExecutionDataURLs) => {
        setExecutionDataURLs(response.urls);
      },
      onError: () => {
        showErrorToast("No downloads found for this execution.");
      },
    });
  };

  const fetchResultsURL = (executionID: string) => {
    setModalHelpText(
      <p>
        Results files contain JSON as text. For easy viewing, rename the file to end with{" "}
        <code>.json</code> then open with VSCode or another JSON-friendly text editor.
      </p>
    );
    fetchWithAuth({
      path: `/scrapers/executions/${executionID}/results-url`,
      method: "GET",
      onResponse: (response: ResultsURLs) => {
        setExecutionDataURLs(response.urls);
      },
      onError: () => {
        showErrorToast("No results found for this execution.");
      },
    });
  };

  const fetchScreenshotURLsForID = (executionID: string) => {
    fetchScreenshotURLs(executionID);
    setShowPermissionModal(false);
    setShowScreenshotsModal(true);
  };

  const fetchTraceURLsForID = (executionID: string) => {
    fetchTraceURLs(executionID);
    setShowPermissionModal(false);
    setShowExecutionDataModal(true);
  };

  const fetchDownloadURLsForID = (executionID: string) => {
    fetchDownloadURLs(executionID);
    setShowPermissionModal(false);
    setShowExecutionDataModal(true);
  };

  const fetchResultsURLForID = (executionID: string) => {
    fetchResultsURL(executionID);
    setShowPermissionModal(false);
    setShowExecutionDataModal(true);
  };

  const permissionModal = (
    <MergeModal
      show={showPermissionModal ?? false}
      onHide={() => setShowPermissionModal(false)}
      title={"Sensitive Information"}
      bodyClassName="overflow-hidden"
      dialogClassName="permission-modal-width"
    >
      The requested data may contain sensitive customer information. If you proceed, we will record
      your activity for compliance and auditing purposes.
      <ButtonContainer>
        <Button
          className="mr-1.5"
          size="sm"
          variant={ButtonVariant.SecondaryBlue}
          onClick={() => setShowPermissionModal(false)}
        >
          Cancel
        </Button>
        <Button
          className="mr-1.5"
          size="sm"
          variant={ButtonVariant.PrimaryBlue}
          onClick={modalOnProceed}
        >
          Proceed
        </Button>
      </ButtonContainer>
    </MergeModal>
  );

  const executionDataModal = (
    <MergeModal
      show={showExecutionDataModal ?? false}
      onHide={() => setShowExecutionDataModal(false)}
      title={"Sensitive Information"}
      bodyClassName="overflow-hidden"
      dialogClassName="scraper-urls-modal-width"
    >
      {modalHelpText}
      Scraper execution data:
      <URLList>
        {executionDataURLs.map((url) => (
          <URLListItem key={url}>
            <a href={url}>{getLastSegmentOfURLPath(url)}</a>
          </URLListItem>
        ))}
      </URLList>
      <ButtonContainer>
        <Button
          className="mr-1.5"
          size="sm"
          variant={ButtonVariant.PrimaryBlue}
          onClick={() => setShowExecutionDataModal(false)}
        >
          Close
        </Button>
      </ButtonContainer>
    </MergeModal>
  );

  const scraperScreenshotsModal = (
    <MergeModal
      show={showScreenshotsModal ?? false}
      onHide={() => setShowScreenshotsModal(false)}
      title={"Sensitive Information"}
      dialogClassName="scraper-urls-modal-width"
    >
      {modalHelpText}
      Scraper Screenshots:
      <ModalBody>
        {screenshotURLs.map((url) => (
          <img src={url} className="m-6" />
        ))}
      </ModalBody>
      <ButtonContainer>
        <Button
          className="mr-1.5"
          size="sm"
          variant={ButtonVariant.PrimaryBlue}
          onClick={() => setShowScreenshotsModal(false)}
        >
          Close
        </Button>
      </ButtonContainer>
    </MergeModal>
  );

  const openModal = (onProceed: () => void) => {
    setShowPermissionModal(true);
    setModalOnProceed(() => onProceed);
  };

  return (
    <>
      {/* Modals */}
      {permissionModal}
      {executionDataModal}
      {scraperScreenshotsModal}

      <div className="flex-column p-7 space-y-1">
        <Button
          leftIcon={<ArrowLeft strokeWidth={1.3} width={18} height={18} />}
          variant={ButtonVariant.TextBlue}
          size="sm"
          onClick={() => navigateToScrapersSubtab(history, integrationID)}
        >
          Back to scrapers
        </Button>
        <br></br>
        <Text variant="h4">{integrationName}</Text>
        <Text variant="h3">{scraperName}</Text>
      </div>
      <div className="d-inline-flex flex-column pt-0 pr-9 pb-9 pl-9">
        <div className="d-flex flex-row mb-9">
          <div className="w-50">
            <InputFormField
              className="mb-0"
              key={"linked account"}
              title={"Filter by Linked Account"}
              currentValue={linkedAccountFilter}
              onChange={(value) => setLinkedAccountFilter(value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  navigateToScraperHistory(history, integrationID, scraperID, linkedAccountFilter);
                }
              }}
            />
          </div>
          <div className="w-25 d-flex align-items-center">
            <Button
              className="ml-3 mt-7"
              size="md"
              variant={ButtonVariant.PrimaryBlue}
              onClick={() =>
                navigateToScraperHistory(history, integrationID, scraperID, linkedAccountFilter)
              }
            >
              Filter
            </Button>
          </div>
        </div>
        <DeprecatedH1>Scraper Executions</DeprecatedH1>
        <Card>
          <div className="table-responsive" style={{ borderRadius: 8 }}>
            <ExecutionsTable className="table-nowrap table-hover">
              <thead className="table-borderless">
                <tr>
                  <th>Execution ID</th>
                  <th>Linked Account ID</th>
                  <th>Exit Code</th>
                  <th>Created At</th>
                  <th>Started At</th>
                  <th>Finished At</th>
                  <th>Trigger Type</th>
                  <th>Queue Name</th>
                  <th>Browser</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {executions?.map((execution) => {
                  const {
                    end_time,
                    created_at,
                    start_time,
                    exit_code,
                    linked_account,
                    id,
                    trigger_type,
                    queue_name,
                    browser,
                  } = execution;
                  return (
                    <tr key={id}>
                      <td>
                        <CodeSmall>{id}</CodeSmall>
                        <OverlayTrigger
                          overlay={
                            <Tooltip
                              className="step-card-log-icon-tooltip"
                              id={`${id}-scraper-execution-id-copy`}
                            >
                              {copiedID && copiedID === id ? "Copied!" : "Copy ID"}
                            </Tooltip>
                          }
                          placement="top"
                        >
                          <CopySpan
                            onClick={() => {
                              navigator.clipboard.writeText(id);
                              setCopiedID(id);
                            }}
                          >
                            <i className="fe fe-copy mx-1.5" />
                          </CopySpan>
                        </OverlayTrigger>
                      </td>
                      <td>
                        <OverlayTrigger
                          overlay={
                            <Tooltip
                              className="step-card-log-icon-tooltip"
                              id={`${id}-linked-account-id-filter`}
                            >
                              Filter by Linked Account ID
                            </Tooltip>
                          }
                          placement="top"
                        >
                          <a
                            href=""
                            onClick={() => {
                              setLinkedAccountFilter(linked_account);
                              navigateToScraperHistory(
                                history,
                                integrationID,
                                scraperID,
                                linked_account
                              );
                            }}
                          >
                            <CodeSmall>{linked_account}</CodeSmall>
                          </a>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip
                              className="step-card-log-icon-tooltip"
                              id={`${id}-linked-account-id-copy`}
                            >
                              {copiedID && copiedID === linked_account ? "Copied!" : "Copy ID"}
                            </Tooltip>
                          }
                          placement="top"
                        >
                          <CopySpan
                            onClick={() => {
                              navigator.clipboard.writeText(linked_account);
                              setCopiedID(linked_account);
                            }}
                          >
                            <i className="fe fe-copy mx-1.5" />
                          </CopySpan>
                        </OverlayTrigger>
                      </td>
                      <td>{exit_code && <ScraperExitCodeBadge exitCode={exit_code} />}</td>
                      <td>{formatRecentTimestamp(created_at)}</td>
                      <td>{formatRecentTimestamp(start_time)}</td>
                      <td>{formatRecentTimestamp(end_time)}</td>
                      <td>{trigger_type && <CodeSmall>{trigger_type}</CodeSmall>}</td>
                      <td>{queue_name && <CodeSmall>{queue_name}</CodeSmall>}</td>
                      <td>{browser && <CodeSmall>{browser}</CodeSmall>}</td>
                      <td className="text-right">
                        <ButtonWithTooltip
                          className="mr-3"
                          onClick={() => openModal(() => fetchScreenshotURLsForID(execution.id))}
                          tooltipContent="View Screenshots"
                          tooltipId={`${id}-view-screenshots`}
                          tooltipPlacement="top"
                        >
                          <div className="d-flex justify-content-center">
                            <i className="fe fe-image" />
                          </div>
                        </ButtonWithTooltip>
                        <ButtonWithTooltip
                          className="mr-3"
                          onClick={() => openModal(() => fetchTraceURLsForID(execution.id))}
                          tooltipContent="Get Traces"
                          tooltipId={`${id}-get-traces`}
                          tooltipPlacement="top"
                        >
                          <div className="d-flex justify-content-center">
                            <i className="fe fe-monitor" />
                          </div>
                        </ButtonWithTooltip>
                        <ButtonWithTooltip
                          className="mr-3"
                          onClick={() => openModal(() => fetchDownloadURLsForID(execution.id))}
                          tooltipContent="Get Downloads"
                          tooltipId={`${id}-get-downloads`}
                          tooltipPlacement="top"
                        >
                          <div className="d-flex justify-content-center">
                            <i className="fe fe-folder" />
                          </div>
                        </ButtonWithTooltip>
                        <ButtonWithTooltip
                          className="mr-3"
                          onClick={() => openModal(() => fetchResultsURLForID(execution.id))}
                          tooltipContent="Get Results"
                          tooltipId={`${id}-get-results`}
                          tooltipPlacement="top"
                        >
                          <div className="d-flex justify-content-center">
                            <i className="fe fe-file-text" />
                          </div>
                        </ButtonWithTooltip>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </ExecutionsTable>
          </div>
        </Card>

        {(previousPageURL || nextPageURL) && (
          <Row>
            <Col>
              <DeprecatedPaginationFooter
                previousPageURL={previousPageURL}
                nextPageURL={nextPageURL}
                onClickPrevious={() => {
                  fetchExecutions(previousPageURL || undefined);
                }}
                onClickNext={() => {
                  fetchExecutions(nextPageURL || undefined);
                }}
              />
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default ScraperHistoryView;
