import { Badge, Text } from "@merge-api/merge-javascript-shared";
import {
  BlueprintValidatorResult,
  BlueprintValidatorRuleResultOptions,
  BlueprintValidatorRuleResultType,
  StagedComponent,
} from "../types";

type Props = {
  isRunningValidator: boolean;
  stagedBlueprint: StagedComponent;
  stagedBlueprintValidatorInfo: BlueprintValidatorResult | undefined;
};

const PublishModuleValidatorResultTitle = ({
  stagedBlueprintValidatorInfo,
  isRunningValidator,
  stagedBlueprint,
}: Props) => {
  var failureCount = 0;
  var warningCount = 0;

  stagedBlueprintValidatorInfo?.rule_results.forEach((rule_result) => {
    if (rule_result.result == BlueprintValidatorRuleResultOptions.FAILURE) {
      rule_result.result_type == BlueprintValidatorRuleResultType.WARNING
        ? warningCount++
        : failureCount++;
    }
  });

  return (
    <div className="flex flex-row justify-between w-full items-center">
      <div className="flex flex-col">
        <Text variant="h6">{stagedBlueprint.name}</Text>
        <Text variant="md" className="text-gray-600">
          {stagedBlueprint.comment}
        </Text>
      </div>
      <div className="justify-end mr-4 space-x-2">
        {!isRunningValidator && failureCount > 0 && (
          <Badge color="red">
            {failureCount.toString() + (failureCount > 1 ? " failures" : " failure")}
          </Badge>
        )}
        {!isRunningValidator && warningCount > 0 && (
          <Badge color="yellow">
            {warningCount.toString() + (warningCount > 1 ? " warnings" : " warning")}
          </Badge>
        )}
      </div>
    </div>
  );
};

export default PublishModuleValidatorResultTitle;
