import { useEffect, useState } from "react";
import { BlueprintParameterSchema, BlueprintStep } from "../../../../models/Blueprints";
import useBlueprintContext from "../../context/useBlueprintContext";
import { getCurrentStepParameterValue } from "../../utils/BlueprintEditorUtils";
import { isParameterValueCustomJSON } from "./CustomJSONUtils";

export type KeyToggles = Record<string, boolean>;

export type CustomJSONModalContext = {
  expandedNestedKeys: KeyToggles;
  setExpandedNestedKeys: (x: KeyToggles) => void;
  enabledRootKeys: KeyToggles;
  setEnabledRootKeys: (x: KeyToggles) => void;
  isShowing: boolean;
  setIsShowing: (x: boolean) => void;
  parameterSchema: BlueprintParameterSchema<string | null>;
  setParameterSchema: (x: BlueprintParameterSchema<string | null>) => void;
  rootParameterKey: string | undefined;
  setRootParameterKey: (x: string | undefined) => void;
};

function useCustomJSONModalContext(parameterKeys: string[]): CustomJSONModalContext {
  const [expandedNestedKeys, setExpandedNestedKeys] = useState<KeyToggles>({});
  const [enabledRootKeys, setEnabledRootKeys] = useState<KeyToggles>({});
  const [isShowing, setIsShowing] = useState<boolean>(false);
  const [parameterSchema, setParameterSchema] = useState<BlueprintParameterSchema<string | null>>();
  const [rootParameterKey, setRootParameterKey] = useState<string | undefined>();
  const { selectedStep } = useBlueprintContext();
  const step = selectedStep as BlueprintStep;
  const customJSONEntries = Object.fromEntries(
    parameterKeys
      .filter((key) => isParameterValueCustomJSON(getCurrentStepParameterValue(step, key)))
      .map((key) => [key, true])
  );

  useEffect(() => {
    setEnabledRootKeys({
      ...enabledRootKeys,
      ...customJSONEntries,
    });
  }, []);

  return {
    expandedNestedKeys,
    setExpandedNestedKeys,
    enabledRootKeys,
    setEnabledRootKeys,
    isShowing,
    setIsShowing,
    parameterSchema,
    setParameterSchema,
    rootParameterKey,
    setRootParameterKey,
  };
}

export default useCustomJSONModalContext;
