import { Text, Tooltip } from "@merge-api/merge-javascript-shared";

interface Props {
  subtitle: string;
}

const StepCardSubheader = ({ subtitle }: Props) => {
  return (
    <Tooltip
      title={subtitle}
      placement="left"
      className="w-full truncate text-start"
      tooltipClassName="p-2"
    >
      <Text variant="sm" className="text-gray-60 truncate">
        {subtitle}
      </Text>
    </Tooltip>
  );
};

export default StepCardSubheader;
