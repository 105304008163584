import classNames from "classnames";
import { useContext, useState } from "react";
import { Accordion, AccordionContext, useAccordionToggle, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { palette } from "../../../styles/theme/colors";

const FieldStatusStyledRow = styled(Row).attrs({
  className: "semibold pl-3 pr-3",
})`
  font-size: 12px;
  line-height: 18px;
`;

const PaddedAccordion = styled(Accordion)`
  padding-left: 44px;
`;

interface ToggleProps {
  setIsExpanded: (expanded: boolean) => void;
  children: JSX.Element;
  eventKey: string;
}

const ToggleButton = styled.div`
  padding: 8px 12px;

  cursor: pointer;
  background-color: #f3f5f8;
  transition: background-color 0.15s ease-in-out;
  &:hover {
    background-color: ${palette.border};
  }
  &:active {
    background-color: ${palette.empty};
  }
`;

const CollapseFieldsCol = styled(Col).attrs({ className: "d-flex justify-content-end mr-1.5" })`
  color: ${palette.graphite};
`;

const PaddedChevron = styled.i<{ $isUp: boolean }>`
  margin-left: 6px;

  transform: ${({ $isUp }) => ($isUp ? "rotate(0deg)" : "rotate(180deg)")};
  transition: transform 0.3s ease-out;
`;

const ToggleRow = ({ setIsExpanded, children, eventKey }: ToggleProps) => {
  const currentEventKey = useContext(AccordionContext);
  const onClick = useAccordionToggle(eventKey, () => setIsExpanded(currentEventKey !== eventKey));

  return <ToggleButton onClick={onClick}>{children}</ToggleButton>;
};

const FieldStatusRow = ({ isExpanded, fieldKey }: { isExpanded: boolean; fieldKey: string }) => {
  return (
    <FieldStatusStyledRow>
      <CollapseFieldsCol>
        {isExpanded ? `Collapse ${fieldKey} fields` : `Configure ${fieldKey} fields`}
        {<PaddedChevron className={classNames("fe", "fe-chevron-up")} $isUp={isExpanded} />}
      </CollapseFieldsCol>
    </FieldStatusStyledRow>
  );
};

type Props = {
  children: JSX.Element;
  fieldKey: string;
};

const BlueprintValidationEditorCardCollapsableSection = ({ children, fieldKey }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <PaddedAccordion>
      <Accordion.Collapse eventKey={fieldKey}>{children}</Accordion.Collapse>
      <ToggleRow setIsExpanded={setIsExpanded} eventKey={fieldKey}>
        <FieldStatusRow isExpanded={isExpanded} fieldKey={fieldKey} />
      </ToggleRow>
    </PaddedAccordion>
  );
};

export default BlueprintValidationEditorCardCollapsableSection;
