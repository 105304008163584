import { Select, Text, TextField, TextFieldVariant } from "@merge-api/merge-javascript-shared";
import {
  AbstractCondition,
  CONDITION_OPERATORS,
  ConditionOperator,
} from "../../../../../integration-builder/selective-sync-filters/types";
import { UserFacingFilterType } from "../../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import {
  AbstractConditionAction,
  UPDATE_CONDITION,
} from "../../../../reducers/AbstractConditionsForSelectiveSyncReducer";

interface Props {
  index: number;
  abstractConditionsForSelectiveSync: AbstractCondition[];
  dispatchAbstractConditionsForSelectiveSync: React.Dispatch<AbstractConditionAction>;
  filterTypeOptions: UserFacingFilterType[];
}

/**
 * Renders the fields for an individual Selective Sync filter in Blueprint Editor left console
 */
const LeftPanelSelectiveSyncFilterFields = ({
  index,
  abstractConditionsForSelectiveSync,
  dispatchAbstractConditionsForSelectiveSync,
  filterTypeOptions,
}: Props) => {
  const setFilterType = (filterTypeID: string) => {
    const updatedCondition: AbstractCondition = {
      ...abstractConditionsForSelectiveSync[index],
      filter_type_id: filterTypeID,
    };
    dispatchAbstractConditionsForSelectiveSync({
      type: UPDATE_CONDITION,
      payload: { index, updatedCondition },
    });
  };

  const setOperator = (operator: string) => {
    const updatedCondition: AbstractCondition = {
      ...abstractConditionsForSelectiveSync[index],
      operator: operator,
    };
    dispatchAbstractConditionsForSelectiveSync({
      type: UPDATE_CONDITION,
      payload: { index, updatedCondition },
    });
  };

  const setValue = (value: string) => {
    const updatedCondition: AbstractCondition = {
      ...abstractConditionsForSelectiveSync[index],
      value: {
        value: value,
        typed_value: value,
      },
    };
    dispatchAbstractConditionsForSelectiveSync({
      type: UPDATE_CONDITION,
      payload: { index, updatedCondition },
    });
  };

  return (
    <div className="flex flex-col border-gray-30 border rounded-md divide-y divide-gray-30">
      <Select
        placeholder="Select model & field ..."
        options={filterTypeOptions}
        renderOption={(option: UserFacingFilterType) => (
          <Text>
            {option.common_model_class_id.split(".").length > 1
              ? `${option.common_model_class_id.split(".")[1]}.${option.filter_name}`
              : `${option.common_model_class_id}.${option.filter_name}`}
          </Text>
        )}
        renderValue={(option: UserFacingFilterType) => (
          <Text>
            {option.common_model_class_id.split(".").length > 1
              ? `${option.common_model_class_id.split(".")[1]}.${option.filter_name}`
              : `${option.common_model_class_id}.${option.filter_name}`}
          </Text>
        )}
        value={filterTypeOptions.find(
          (option) => option.id === abstractConditionsForSelectiveSync[index].filter_type_id
        )}
        onChange={(_, option) => {
          setFilterType(option?.id ?? "");
        }}
        shadowHidden={true}
        clearable={false}
      />
      <Select
        placeholder="Select operator ..."
        options={Object.entries(CONDITION_OPERATORS).map(([key, _]) => key)}
        renderOption={(option: string) => (
          <Text>{CONDITION_OPERATORS[option as ConditionOperator]}</Text>
        )}
        value={abstractConditionsForSelectiveSync[index]?.operator}
        renderValue={(option: string) => (
          <Text>{CONDITION_OPERATORS[option as ConditionOperator]}</Text>
        )}
        onChange={(_, option) => {
          setOperator(option ?? "");
        }}
        shadowHidden={true}
        clearable={false}
      />
      <TextField
        inputClassName="-ml-1"
        placeholder="Enter filter value ..."
        variant={TextFieldVariant.InlinedRounded}
        value={abstractConditionsForSelectiveSync[index]?.value.value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
      />
    </div>
  );
};

export default LeftPanelSelectiveSyncFilterFields;
