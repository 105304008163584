import { fetchWithAuth } from "../../../api-client/api_client";
import { APIEndpointMethod } from "../../../models/Entities";
import { showErrorToast, showSuccessToast } from "../../shared/Toasts";

interface PatchLinkedAccountAccessRequestProps {
  accessRequestID: string | null;
  fetchLinkedAccountAccessRequest: () => void;
  fetchTestLinkedAccounts: () => void;
  fetchLinkedAccountAccessSession: () => void;
}

const usePatchLinkedAccountAccessRequest = ({
  accessRequestID,
  fetchLinkedAccountAccessRequest,
  fetchTestLinkedAccounts,
  fetchLinkedAccountAccessSession,
}: PatchLinkedAccountAccessRequestProps) => {
  const basePath = `/integrations/linked-accounts/access-request/${accessRequestID}`;
  const headers = {
    "Content-Type": "application/json",
  };

  const withdrawLinkedAccountAccessRequest = () => {
    if (!accessRequestID) {
      return;
    }
    fetchWithAuth({
      path: `${basePath}/withdraw`,
      method: APIEndpointMethod.POST,
      headers,
      onResponse: () => {
        fetchLinkedAccountAccessRequest();
        showSuccessToast("Access request withdrawn successfully");
      },
      onError: (error) => {
        error?.json().then((value: string | Record<string, any>) => {
          let errorMessage = "";
          if (typeof value == "string") {
            errorMessage = value;
          } else {
            errorMessage = value?.non_field_errors ?? JSON.stringify(value);
          }
          showErrorToast(`Failed to withdraw access request. Error message: \n ${errorMessage}`);
        });
      },
    });
  };

  const acknowledgeLinkedAccountAccessRequest = (shouldHideSuccessToast: boolean = false) => {
    if (!accessRequestID) {
      return;
    }
    fetchWithAuth({
      path: `${basePath}/acknowledge`,
      method: APIEndpointMethod.POST,
      headers,
      onResponse: () => {
        fetchLinkedAccountAccessRequest();
        fetchLinkedAccountAccessSession();
        fetchTestLinkedAccounts();
        if (!shouldHideSuccessToast) {
          showSuccessToast("Access request cleared successfully");
        }
      },
      onError: (error) => {
        error
          ?.json()
          .then((value: string | Record<string, any>) => {
            let errorMessage = "";
            if (typeof value == "string") {
              errorMessage = value;
            } else {
              errorMessage = value?.non_field_errors ?? JSON.stringify(value);
            }
            showErrorToast(`Failed to clear access request. Error message: \n ${errorMessage}`);
          })
          .catch(() => {
            showErrorToast("Failed to clear access request");
          });
      },
    });
  };

  return { withdrawLinkedAccountAccessRequest, acknowledgeLinkedAccountAccessRequest };
};

export default usePatchLinkedAccountAccessRequest;
