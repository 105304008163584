import { useCallback } from "react";
import { FormErrorData, fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast } from "../../../shared/Toasts";

export const useAutogenerateReadMappingTest = () => {
  const generateReadTest = useCallback(
    (mappingTestSuiteID: string, onGenerated: (autogeneratedTestRunID: string) => void) => {
      fetchWithAuth({
        path: `/stallions/integrations/test-suite/${mappingTestSuiteID}/autogenerated-read-test-run`,
        method: "POST",
        body: {},
        onResponse: (data) => {
          const autogeneratedTestRunID = data.id;
          onGenerated(autogeneratedTestRunID);
        },
        onError: (err) => {
          err?.json().then((data: FormErrorData) => {
            let fieldNameFound = false;
            for (const field_name in data) {
              if (field_name === "non_field_errors") {
                showErrorToast(data[field_name][0]);
                fieldNameFound = true;
                break;
              }
            }
            if (!fieldNameFound) {
              showErrorToast("An unexpected error occurred. Please reach out to the AI team.");
            }
          });
        },
      });
    },
    []
  );

  return {
    generateReadTest,
  };
};
