import { Badge } from "react-bootstrap";
import { NextComponentVersionState } from "../integrations/versioned-components/types";

const ComponentStatusBadge = ({
  hasPublishedVersion,
  nextComponentVersionState,
}: {
  hasPublishedVersion: boolean;
  nextComponentVersionState: NextComponentVersionState;
}) => {
  switch (nextComponentVersionState) {
    case NextComponentVersionState.DRAFT_WITH_CHANGES:
      if (hasPublishedVersion) {
        return <Badge variant="light">Published with New Draft</Badge>;
      } else {
        return <Badge variant="light">New Component - Draft</Badge>;
      }
    case NextComponentVersionState.STAGED_WITH_CHANGES:
      if (hasPublishedVersion) {
        return <Badge variant="warning">New Staged Version of Published Component</Badge>;
      } else {
        return <Badge variant="warning">New Component - Staged</Badge>;
      }
    case NextComponentVersionState.NO_CHANGES:
      if (hasPublishedVersion) {
        return <Badge variant="dark">Published</Badge>;
      } else {
        return <Badge variant="light">New Component - Draft</Badge>;
      }
  }
};

export default ComponentStatusBadge;
