import classNames from "classnames";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import InputFormField from "../blueprint-editor/right-panel/InputFormField";
import HeaderBar from "../portal/HeaderBar";
import MergeModal from "../shared/MergeModal";
import useScraperContext from "./context/useScraperContext";
import ScraperVersionHistoryModal from "./ScraperVersionHistoryModal";

const ImageButton = (props: {
  isDisabled?: boolean;
  imageClassName: string;
  onClick?: () => void;
  label: string;
  type?: "primary" | "danger";
}) => (
  <button
    type="button"
    className={classNames(
      "btn mr-3",
      props.type === "danger" ? "btn-danger" : "btn-outline-primary"
    )}
    disabled={props.isDisabled}
    onClick={props.onClick}
  >
    <div className="d-flex align-items-center ">
      <i className={props.imageClassName + " mr-1.5"} /> {props.label}
    </div>
  </button>
);

const VersionStatus = (props: { imageClassName: string; label: string }) => (
  <button type="button" className={classNames("btn mr-3", "btn-outline-info")} disabled={true}>
    <div className="d-flex align-items-center ">
      <i className={props.imageClassName + " mr-1.5"} /> {props.label}
    </div>
  </button>
);

const ScraperEditorControlRow = () => {
  const {
    scraper,
    stageScraper,
    unstageScraper,
    setScraper,
    saveScraper,
    doesScraperHaveUnsavedChanges,
  } = useScraperContext();
  const [isShowingVersionControlHistory, setIsShowingVersionControlHistory] = useState(false);
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);

  const versionStatus = scraper.is_draft
    ? "Draft"
    : scraper.is_staged
    ? "Staged"
    : scraper.is_published
    ? "Published"
    : "Historical";

  return (
    <>
      <MergeModal
        show={isConfigModalOpen}
        onHide={() => setIsConfigModalOpen(false)}
        title="Set Scraper Config"
        bodyClassName="overflow-hidden"
      >
        <Row>
          <Col>
            <InputFormField
              onChange={(newValue) => setScraper({ ...scraper, max_timeout: newValue })}
              currentValue={scraper.max_timeout}
              title="Max Timeout"
            />
            <div>
              <Form.Check
                type="checkbox"
                id="headless"
                onChange={() =>
                  setScraper({
                    ...scraper,
                    should_run_in_headless_mode: !scraper.should_run_in_headless_mode,
                  })
                }
                checked={scraper.should_run_in_headless_mode}
                label="Check if this scraper should run in a headless browser (non-visible, no GUI, more likely to trigger bot detection)"
              />
            </div>
            <div className="mt-6">
              <Form.Check
                type="checkbox"
                id="captcha"
                onChange={() =>
                  setScraper({
                    ...scraper,
                    should_solve_captchas: !scraper.should_solve_captchas,
                  })
                }
                checked={scraper.should_solve_captchas}
                label="Check if this scraper should attempt to solve captchas that are visible on the page (this costs money)"
              />
            </div>
            <div className="mt-6">
              <Form.Check
                type="checkbox"
                id="captcha"
                onChange={() =>
                  setScraper({
                    ...scraper,
                    should_log_non_errors: !scraper.should_log_non_errors,
                  })
                }
                checked={scraper.should_log_non_errors}
                label="Check if this scraper should log all info in non-tests."
              />
            </div>
          </Col>
        </Row>
      </MergeModal>
      <HeaderBar
        className="pl-9 pr-9"
        pretitle={<>Scraper Editor </>}
        title={scraper.name}
        rightElement={
          <Row>
            <VersionStatus label={versionStatus} imageClassName={"fe fe-git-merge"} />
            <ImageButton
              onClick={() => setIsConfigModalOpen(true)}
              label={"Modify Scraper Config"}
              imageClassName={"fe fe-settings"}
            />
            {scraper.is_draft ? (
              <ImageButton onClick={stageScraper} label={"Stage"} imageClassName={"fe fe-save"} />
            ) : (
              scraper.is_staged && (
                <ImageButton
                  onClick={unstageScraper}
                  label={"Unstage"}
                  imageClassName={"fe fe-save"}
                />
              )
            )}
            <ImageButton
              onClick={saveScraper}
              label={"Save Draft"}
              imageClassName={"fe fe-save"}
              // Can't save staged versions with changes or historical versions
              isDisabled={scraper.is_staged || !doesScraperHaveUnsavedChanges}
            />
            <ImageButton
              onClick={() => setIsShowingVersionControlHistory(true)}
              label={"Version History"}
              imageClassName={"fe fe-list"}
            />
          </Row>
        }
      />
      <ScraperVersionHistoryModal
        isVisible={isShowingVersionControlHistory}
        onHide={() => setIsShowingVersionControlHistory(false)}
      />
    </>
  );
};

export default ScraperEditorControlRow;
