import {
  BlueprintExtendedOperationType,
  BlueprintStatus,
  BlueprintVersionPublishState,
} from "../Blueprints";
import { APICategory } from "../Entities";

export enum BlueprintSearchFilterTypeEnum {
  CONTAINS_ALL = "CONTAINS_ALL",
  CONTAINS_ANY = "CONTAINS_ANY",
  EQUALS = "EQUALS",
}

export enum BlueprintSearchFilterTypeaheadEnum {
  OPERATION_TYPE = "operation_type",
  CATEGORY = "category",
  PUBLISHED_STATUS = "published_status",
  ARCHIVED_STATUS = "archived_status",
  API_ENDPOINT_ID = "api_endpoint_id",
  FUNCTIONAL_BLUEPRINT_ID = "functional_blueprint_id",
  COMMON_MODEL = "common_model",
  COMMON_MODEL_FIELD = "common_model_field",
}

export const MapBlueprintSearchFilterTypeaheadDisplayName: Record<
  BlueprintSearchFilterTypeaheadEnum,
  string
> = {
  [BlueprintSearchFilterTypeaheadEnum.OPERATION_TYPE]: "Operation type",
  [BlueprintSearchFilterTypeaheadEnum.CATEGORY]: "Category",
  [BlueprintSearchFilterTypeaheadEnum.PUBLISHED_STATUS]: "Published status",
  [BlueprintSearchFilterTypeaheadEnum.ARCHIVED_STATUS]: "Archived status",
  [BlueprintSearchFilterTypeaheadEnum.API_ENDPOINT_ID]: "API Endpoint",
  [BlueprintSearchFilterTypeaheadEnum.FUNCTIONAL_BLUEPRINT_ID]: "Functional Blueprint",
  [BlueprintSearchFilterTypeaheadEnum.COMMON_MODEL]: "Common Model",
  [BlueprintSearchFilterTypeaheadEnum.COMMON_MODEL_FIELD]: "Common Model field",
};

type FilterType = {
  filter_type: string;
};

type ContainsAllFilter<T> = FilterType & {
  filter_type: BlueprintSearchFilterTypeEnum.CONTAINS_ALL;
  values: T[];
};

type ContainsAnyFilter<T> = FilterType & {
  filter_type: BlueprintSearchFilterTypeEnum.CONTAINS_ANY;
  values: T[];
};

type EqualsFilter<T> = FilterType & {
  filter_type: BlueprintSearchFilterTypeEnum.EQUALS;
  value: T;
};

export interface BlueprintSearchSingleCommonModelFilter {
  model: string;
  fields?: string[];
}

export type BlueprintSearchOperationTypeFilter = ContainsAnyFilter<BlueprintExtendedOperationType>;
export type BlueprintSearchCategoryFilter = ContainsAnyFilter<APICategory>;
export type BlueprintSearchPublishedStatusFilter = EqualsFilter<BlueprintVersionPublishState>;
export type BlueprintSearchArchivedStatusFilter = EqualsFilter<BlueprintStatus>;
export type BlueprintSearchAPIEndpointFilter = ContainsAllFilter<string>;
export type BlueprintSearchFunctionalBlueprintFilter = ContainsAllFilter<string>;
export type BlueprintSearchCommonModelFilter = ContainsAllFilter<
  BlueprintSearchSingleCommonModelFilter
>;

/**
 * Filter interface for BlueprintSearch
 *
 * @param operation_type - Filter for blueprint operation types (ie.: FETCH, CREATE)
 * @param category - Filter for integration categories that blueprint belongs to
 * @param published_status - Filter for blueprint publish state
 * @param archived_status - Filter for blueprint archive status
 * @param api_endpoint_id - Filter for API endpoint IDs in blueprint
 * @param functional_blueprint_id - Filter for functional blueprint in blueprint
 * @param common_model - Filter for common models and their fields in blueprint
 */
export interface BlueprintSearchFilterSet {
  operation_type?: BlueprintSearchOperationTypeFilter;
  category?: BlueprintSearchCategoryFilter;
  published_status?: BlueprintSearchPublishedStatusFilter;
  archived_status?: BlueprintSearchArchivedStatusFilter;
  api_endpoint_id?: BlueprintSearchAPIEndpointFilter;
  functional_blueprint_id?: BlueprintSearchFunctionalBlueprintFilter;
  common_model?: BlueprintSearchCommonModelFilter;
}
