import { Button, ButtonVariant, Card, Dialog } from "@merge-api/merge-javascript-shared";
import {
  APIEndpointPreviewIntegrationBuilder,
  AutogenerationRunNotificationStatus,
  AutogenerationRunStatus,
  FAILURE_AUTOGENERATION_RUN_STATUSES,
  FINISHED_AUTOGENERATION_RUN_STATUSES,
  IntegrationBuilderAutogenRun,
} from "../../utils/Entities";
import clsx from "clsx";
import AutogeneratedConfigurationCardTitle from "./AutogeneratedConfigurationCardTitle";
import AutogeneratedConfigurationPreviousRuns from "./AutogeneratedConfigurationPreviousRuns";
import AutogeneratedConfigurationActions from "./AutogeneratedConfigurationActions";
import React, { useEffect, useState } from "react";
import useAbortAutogeneratedModelRun from "../../hooks/useAbortAutogeneratedModelRun";

interface AutogeneratedConfigurationCardProps {
  api_endpoint_preview?: APIEndpointPreviewIntegrationBuilder;
  autogen_runs: IntegrationBuilderAutogenRun[];
  setGeneratingAPIEndpointPreviews?: React.Dispatch<
    React.SetStateAction<APIEndpointPreviewIntegrationBuilder[]>
  >;
  className?: string;
  nested?: boolean;
}

const AutogeneratedConfigurationCard = ({
  api_endpoint_preview,
  autogen_runs,
  setGeneratingAPIEndpointPreviews,
  className,
  nested = false,
}: AutogeneratedConfigurationCardProps) => {
  const [status, setStatus] = useState<string>(AutogenerationRunStatus.RUNNING);
  const [mostRecentRun, setMostRecentRun] = useState<null | IntegrationBuilderAutogenRun>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // hooks
  const { abortAutogeneratedModelRun } = useAbortAutogeneratedModelRun();
  useEffect(() => {
    setMostRecentRun(autogen_runs.length ? autogen_runs[0] : null);
  }, [autogen_runs]);
  useEffect(() => {
    if (
      !mostRecentRun ||
      mostRecentRun.notification_status === AutogenerationRunNotificationStatus.RETRIED
    ) {
      setStatus(AutogenerationRunStatus.RUNNING);
      return;
    }

    setStatus(mostRecentRun.status);
  }, [mostRecentRun]);

  // handlers
  const onAbortSuccess = () => {
    setStatus(AutogenerationRunStatus.ABORTED);
  };

  return (
    <Card className={clsx(className, "w-full")} variant={nested ? "none" : "shadow"}>
      <div
        className={clsx(
          nested ? "px-4 py-3" : "px-6 py-5",
          "flex flex-row items-center justify-between"
        )}
      >
        <AutogeneratedConfigurationCardTitle
          status={status}
          api_endpoint_preview={api_endpoint_preview}
        />
        {FINISHED_AUTOGENERATION_RUN_STATUSES.includes(status) && mostRecentRun && (
          <AutogeneratedConfigurationActions
            autogenRun={mostRecentRun as IntegrationBuilderAutogenRun}
            setAutogenRun={setMostRecentRun}
            apiEndpointPreview={api_endpoint_preview}
            setGeneratingAPIEndpointPreviews={setGeneratingAPIEndpointPreviews}
            setStatus={setStatus}
          />
        )}
        {status === AutogenerationRunStatus.RUNNING && mostRecentRun?.task_id ? (
          <Button
            size="sm"
            variant={ButtonVariant.DangerText}
            onClick={() => setIsDialogOpen(true)}
          >
            Abort
          </Button>
        ) : null}
      </div>
      {FAILURE_AUTOGENERATION_RUN_STATUSES.includes(status) && (
        <AutogeneratedConfigurationPreviousRuns autogen_runs={autogen_runs} />
      )}
      <Dialog
        title="Abort API Endpoint Generation"
        open={isDialogOpen}
        onPrimaryButtonClick={() => {
          mostRecentRun && abortAutogeneratedModelRun(mostRecentRun.id, onAbortSuccess);
          setIsDialogOpen(false);
        }}
        onSecondaryButtonClick={() => setIsDialogOpen(false)}
        onClose={() => setIsDialogOpen(false)}
      >
        Are you sure you want to abort the generation of the API endpoint?
      </Dialog>
    </Card>
  );
};

export default AutogeneratedConfigurationCard;
