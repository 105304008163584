import PublishModuleValidatorContext, {
  PublishModuleValidatorContextState,
} from "./PublishModuleValidatorContext";

interface Props extends PublishModuleValidatorContextState {
  children: JSX.Element;
}

// Wraps around App to be able to pull global state anywhere in tree via useContext
const PublishModuleValidatorContextProvider = ({ children, ...contextProps }: Props) => (
  <PublishModuleValidatorContext.Provider value={contextProps}>
    {children}
  </PublishModuleValidatorContext.Provider>
);

export default PublishModuleValidatorContextProvider;
