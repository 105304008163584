import {
  DiffState,
  DiffStateFieldTypeEnum,
  generateDiffStateFieldInputType,
} from "../../../../models/DiffModels";
import { CONDITION_OPERATORS } from "../../../integration-builder/selective-sync-filters/types";
import { generateDiffState } from "./helpers-diff";

export const generateDiffForSelectiveSyncFilterSchema = (): DiffState => {
  return generateDiffState({
    fields: [
      {
        type: DiffStateFieldTypeEnum.HEADER,
        name: "user_facing_filter_details",
        displayName: "Filter configuration",
        childDiffStateInputFields: [
          {
            name: "common_model_class_id",
            displayName: "Common model class ID",
            keyPathToName: ["user_facing_filter_details", "filter_type_id"],
          },
          {
            name: "remote_key_name",
            displayName: "Remote Key Name",
            keyPathToName: ["user_facing_filter_details"],
          },
          {
            name: "value_type",
            displayName: "Value type",
            keyPathToName: ["user_facing_filter_details", "filter_type_id"],
          },
          {
            name: "is_common_model_field",
            displayName: "Is native Common Model field",
            keyPathToName: ["user_facing_filter_details", "filter_type_id"],
          },
          {
            name: "is_public_facing",
            displayName: "Is public facing",
            keyPathToName: ["user_facing_filter_details"],
          },
        ],
      },
      {
        type: DiffStateFieldTypeEnum.HEADER,
        name: "filter_mapping_details_header",
        displayName: "Operators and endpoint mapping logic",
        childDiffStateInputFields: generateDiffStateInputFieldsForOperators(),
      },
    ],
  });
};

// Generate diff input fields for Operators
const generateDiffStateInputFieldsForOperators = (): generateDiffStateFieldInputType[] => {
  let inputFields: generateDiffStateFieldInputType[] = [];

  Object.entries(CONDITION_OPERATORS).forEach(([name, displayName]) => {
    inputFields.push({
      type: DiffStateFieldTypeEnum.SECTION,
      name: name,
      displayName: displayName,
      keyPathToName: ["filter_mapping_details"],
      isRenderNoValueAsEmpty: true,
      isRenderAsEmpty: true,
      isRenderNewAsEmpty: true,
      isRenderCurrentAsEmpty: true,
      childDiffStateFields: generateDiffState({
        keyPathToSliceOfState: ["filter_mapping_details", name, "api_endpoint_filter_mappings"],
        isStateAnArray: true,
        arrayItemHeaderName: "API endpoint filter mapping",
        arrayItemIdentifier: "id",
        isIgnoreArrayOrder: true,
        fields: generateDiffStateInputFieldsForAPIEndpointFilter(),
      }),
    });
  });

  return inputFields;
};

// Generate diff input fields for API Endpoint Filters
const generateDiffStateInputFieldsForAPIEndpointFilter = (): generateDiffStateFieldInputType[] => {
  let inputFields: generateDiffStateFieldInputType[] = [
    {
      type: DiffStateFieldTypeEnum.SECTION,
      name: "api_endpoint_filter_id_section",
      displayName: "API endpoint filter",
      childDiffStateInputFields: [
        {
          name: "name",
          displayName: "API endpoint",
          keyPathToName: ["api_endpoint_filter_id", "next_version", "api_endpoint_id"],
        },
        {
          name: "request_location",
          displayName: "Request location",
          keyPathToName: ["api_endpoint_filter_id", "next_version"],
        },
        {
          name: "key_path",
          displayName: "Key path",
          keyPathToName: ["api_endpoint_filter_id", "next_version"],
        },
        {
          type: DiffStateFieldTypeEnum.SECTION,
          name: "composite_filter_information_section",
          displayName: "Composite filter",
          childDiffStateInputFields: [
            {
              name: "composite_filter_type",
              displayName: "Composite filter type",
              keyPathToName: [
                "api_endpoint_filter_id",
                "next_version",
                "composite_filter_information",
              ],
            },
          ],
        },
      ],
    },
    {
      type: DiffStateFieldTypeEnum.SECTION,
      name: "value_transformation_section",
      displayName: "Value transformation",
      childDiffStateInputFields: [
        {
          name: "format_string",
          displayName: "Format string",
          keyPathToName: ["value_transformation"],
        },
        {
          name: "prefix",
          displayName: "Prefix",
          keyPathToName: ["value_transformation"],
        },
        {
          name: "suffix",
          displayName: "Suffix",
          keyPathToName: ["value_transformation"],
        },
        {
          name: "datetime_format",
          displayName: "Datetime format",
          keyPathToName: ["value_transformation"],
        },
        {
          type: DiffStateFieldTypeEnum.SECTION,
          name: "list_transformation_information_section",
          displayName: "List transformation information",
          keyPathToName: ["value_transformation"],
          childDiffStateInputFields: [
            {
              name: "should_insert_as_repeated_query_parameters",
              displayName: "Should insert as repeated query parameters",
              keyPathToName: ["value_transformation", "list_transformation_information"],
            },
            {
              name: "is_non_native_or",
              displayName: "Is non-native or",
              keyPathToName: ["value_transformation", "list_transformation_information"],
            },
            {
              name: "list_delimiter",
              displayName: "List delimiter",
              keyPathToName: ["value_transformation", "list_transformation_information"],
            },
          ],
        },
        {
          type: DiffStateFieldTypeEnum.SECTION,
          name: "composite_filter_mapping_information_section",
          displayName: "Composite filter mapping",
          childDiffStateInputFields: [
            {
              name: "sub_filter_key_path",
              displayName: "Key path in composite filter",
              keyPathToName: ["value_transformation", "composite_filter_mapping_information"],
            },
          ],
        },
      ],
    },
  ];

  return inputFields;
};
