import { Text } from "@merge-api/merge-javascript-shared";
import BlueprintCardAPIRequestFooterEditableCodeSection from "../../../shared/blueprint-card/BlueprintCardAPIRequestFooterEditableCodeSection";
import { isValidJSON } from "../../../../utils";

interface Props {
  title: string;
  body: string | null;
  useCodeBlock?: boolean;
}

const MappingTestV2RightPanelErrorDetailsMockRequestParameter = ({
  title,
  body,
  useCodeBlock = false,
}: Props) => {
  return (
    <div className="flex flex-column">
      <Text variant="sm" className="text-gray-90">
        {title}
      </Text>
      {useCodeBlock ? (
        body ? (
          <BlueprintCardAPIRequestFooterEditableCodeSection
            body={
              isValidJSON(body)
                ? JSON.stringify(JSON.parse(body), null, 2)
                : isValidJSON(body.replaceAll("'", '"'))
                ? JSON.stringify(JSON.parse(body.replaceAll("'", '"')), null, 2)
                : body
            }
            alwaysEditable={false}
            editable={false}
          />
        ) : (
          <Text variant="sm" className="text-gray-60">
            No {title.toLowerCase()}
          </Text>
        )
      ) : (
        <Text variant="sm" className="text-gray-60 break-all">
          {body}
        </Text>
      )}
    </div>
  );
};

export default MappingTestV2RightPanelErrorDetailsMockRequestParameter;
