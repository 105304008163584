import { Text } from "@merge-api/merge-javascript-shared";

type LinkedAccountsCellProps = {
  numberOfLinkedAccounts: string | number;
};

const LinkedAccountsCell = ({ numberOfLinkedAccounts }: LinkedAccountsCellProps) => {
  return (
    <td>
      <Text className="text-gray-60">{numberOfLinkedAccounts}</Text>
    </td>
  );
};

export default LinkedAccountsCell;
