import { Text } from "@merge-api/merge-javascript-shared";
import { useState } from "react";
import {
  IntegrationTestSuiteExecution,
  MappingTestAndVersions,
  MappingTestExecution,
} from "../../../../models/MappingTests";
import TestSuiteExecutionContainer from "./TestSuiteExecution/TestSuiteExecutionContainer";
import PublishModuleMappingTestContextProvider from "../../context/PublishModuleMappingTestContextProvider";
import MappingTestExecutionModal from "./TestSuiteExecution/EndpointCollection/MappingTestExecution/MappingTestExecutionModal/MappingTestExecutionModal";
import GradientIcon from "../../../shared/icons/GradientIcon";
import { CloudMoon } from "lucide-react";

const PublishModuleTestExecutionsContainer = ({
  integrationID,
  integrationTestSuiteExecution,
  hasFailedFetchingLatestExecution,
  isRunningMappingTests,
}: {
  integrationID: string;
  integrationTestSuiteExecution: IntegrationTestSuiteExecution | null;
  hasFailedFetchingLatestExecution: boolean;
  isRunningMappingTests: boolean;
}) => {
  // States for context, particularly for MappingTestExecutionModal
  const [mappingTestExecution, setMappingTestExecution] = useState<MappingTestExecution | null>(
    null
  );
  const [mappingTest, setMappingTest] = useState<MappingTestAndVersions | null>(null);
  const [isShowingMappingTestExecutionModal, setIsShowingMappingTestExecutionModal] = useState<
    boolean
  >(false);

  const hasMappingTestExecutions = (
    integrationTestSuiteExecution: IntegrationTestSuiteExecution | null
  ): boolean =>
    (integrationTestSuiteExecution?.linked_account_test_suite_executions ?? []).some(
      (testSuiteExecution) => (testSuiteExecution.mapping_test_executions?.length ?? 0) > 0
    );

  return (
    <PublishModuleMappingTestContextProvider
      mappingTestExecution={mappingTestExecution}
      setMappingTestExecution={setMappingTestExecution}
      mappingTest={mappingTest}
      setMappingTest={setMappingTest}
      isShowingMappingTestExecutionModal={isShowingMappingTestExecutionModal}
      setIsShowingMappingTestExecutionModal={setIsShowingMappingTestExecutionModal}
      integrationID={integrationID}
    >
      <div>
        {hasFailedFetchingLatestExecution ? (
          <div className="p-6">
            <Text className="text-gray-50">Failed to fetch test executions.</Text>
          </div>
        ) : !isRunningMappingTests ? (
          <div className="pb-4 px-6">
            {hasMappingTestExecutions(integrationTestSuiteExecution) ? (
              <div>
                {(integrationTestSuiteExecution?.linked_account_test_suite_executions ?? [])
                  .filter(
                    (test_suite_execution) =>
                      (test_suite_execution?.mapping_test_executions ?? []).length > 0
                  )
                  .map((test_suite_execution) => (
                    <>
                      <TestSuiteExecutionContainer testSuiteExecution={test_suite_execution} />
                    </>
                  ))}
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center px-6 py-8 space-y-4">
                <GradientIcon Icon={CloudMoon} size={32} endColor="#2DB9EE" startColor="#0042E5" />
                <Text variant="h5" className="text-gray-70">
                  No staged or published mapping tests
                </Text>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
        <MappingTestExecutionModal
          open={isShowingMappingTestExecutionModal}
          onClose={() => setIsShowingMappingTestExecutionModal(false)}
          mappingTestExecution={mappingTestExecution}
          mappingTest={mappingTest}
        />
      </div>
    </PublishModuleMappingTestContextProvider>
  );
};

export default PublishModuleTestExecutionsContainer;
