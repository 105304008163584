import React, { useCallback, useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import MergeModal from "./MergeModal";
import useOnClickOutside from "use-onclickoutside";
import DeprecatedH4 from "../deprecated/DeprecatedH4";

interface ContextMenuItem {
  label: string;
  featherIconName?: string;
  onClick: (e?: any) => void;
  confirmationMessage?: string;
  warning?: boolean;
}

interface Props {
  items: Array<ContextMenuItem>;
  position: { x: number; y: number; positionType?: React.CSSProperties["position"] };
  isShown: boolean;
  onClose: () => void;
}

const ContextMenu = ({ items, position, isShown, onClose }: Props) => {
  const onClickItem = useCallback(
    (e, item) => {
      if (item.confirmationMessage) {
        setItemBeingConfirmed(item);
      } else {
        item.onClick(e);
      }
    },
    [items]
  );

  const [itemBeingConfirmed, setItemBeingConfirmed] = useState<ContextMenuItem | null>(null);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    if (itemBeingConfirmed) {
      return;
    }
    onClose();
  });

  return itemBeingConfirmed ? (
    <MergeModal
      show={true}
      title={itemBeingConfirmed.label}
      dialogClassName="modal-narrow delete-blueprint-modal"
      bodyClassName="modal-narrow delete-blueprint-modal"
      onHide={() => setItemBeingConfirmed(null)}
    >
      <p className="pb-3">{itemBeingConfirmed.confirmationMessage}</p>
      <Row>
        <Col>
          {itemBeingConfirmed.warning ? (
            <Button
              className="form-control float-left"
              onClick={itemBeingConfirmed.onClick}
              variant={ButtonVariant.DangerFilled}
            >
              {itemBeingConfirmed.label}
            </Button>
          ) : (
            <Button className="form-control float-left" onClick={itemBeingConfirmed.onClick}>
              {itemBeingConfirmed.label}
            </Button>
          )}
        </Col>
        <Col>
          <Button
            className="form-control float-right"
            onClick={() => setItemBeingConfirmed(null)}
            variant={ButtonVariant.SecondaryGray}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </MergeModal>
  ) : (
    <div
      className="context-menu"
      style={{ left: position.x, top: position.y, position: position.positionType || undefined }}
      hidden={!isShown}
      ref={ref}
    >
      {items.map((item) => {
        return (
          <div
            className="context-menu-item"
            key={item.label}
            onClick={(e) => {
              onClickItem(e, item);
            }}
            style={
              item === items[items.length - 1]
                ? { borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }
                : item === items[0]
                ? { borderTopRightRadius: 8, borderTopLeftRadius: 8 }
                : {}
            }
          >
            <DeprecatedH4 className="m-0 whitespace-nowrap">
              {item.featherIconName && <i className={`fe fe-${item.featherIconName} pr-1`} />}
              {" " + item.label}
            </DeprecatedH4>
          </div>
        );
      })}
    </div>
  );
};

export default ContextMenu;
