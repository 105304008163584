import { useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import styled from "styled-components";
import {
  BlueprintExecutionPreviewInfo,
  MappingTestRequestMock,
} from "../../../../models/MappingTests";
import { formatDateFromDateString } from "../../../shared/utils/SharedComponentUtils";
import ImportBlueprintExecutionRequestsPreviewModal from "../ImportBlueprintExecutionRequestsPreviewModal";

interface Props {
  data: BlueprintExecutionPreviewInfo;
  testID: string;
  addRequestMock: (requestMock: MappingTestRequestMock) => void;
  saveMappingTest: () => void;
}

const InfoText = styled.div`
  font-size: 12px;
`;

const BlueprintExecutionPreviewCard = ({
  data,
  testID,
  addRequestMock,
  saveMappingTest,
}: Props) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <Card className="mb-3">
      <ImportBlueprintExecutionRequestsPreviewModal
        show={showModal}
        handleClose={handleCloseModal}
        blueprintExecutionID={data.id}
        testID={testID}
        addRequestMock={addRequestMock}
        saveMappingTest={saveMappingTest}
      />
      <Card.Body>
        <Row>
          <Col md={5}>
            <div>
              <strong>Linked Account:</strong>
              <br /> <InfoText>{data.linked_account}</InfoText>
            </div>
            <div>
              <strong>Status Code:</strong> <br /> <InfoText>{data.exit_code}</InfoText>
            </div>
            {data?.end_time && (
              <div>
                <strong>End Time:</strong> <br />{" "}
                <InfoText> {formatDateFromDateString(data.end_time)}</InfoText>
              </div>
            )}
          </Col>
          <Col md={5}>
            <div className="mb-3">
              <strong>API Requests:</strong> <br /> {data.api_requests_count}
            </div>
            <div>
              <div className="mb-1">
                <strong>Produced Models:</strong>
              </div>
              {Object.entries(data?.produced_models_count ?? {}).map(([key, value], index) => (
                <div
                  key={index}
                  className="d-flex flex-row"
                  style={{ marginBottom: "10px", fontSize: "10px" }}
                >
                  <strong className="mr-1.5">{key}: </strong>
                  <div>{value}</div>
                </div>
              ))}
            </div>
          </Col>
          <Col className="d-flex align-items-center justify-content-end" md={2}>
            <Button variant="success" size="sm" onClick={() => handleShowModal()}>
              Add <strong>+</strong>
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default BlueprintExecutionPreviewCard;
