import MappingTestContext, { MappingTestContextState } from "./MappingTestContext";

interface Props extends MappingTestContextState {
  children: JSX.Element;
}

// Wraps around App to be able to pull global state anywhere in tree via useContext
const MappingTestContextProvider = ({ children, ...contextProps }: Props) => (
  <MappingTestContext.Provider value={contextProps}>{children}</MappingTestContext.Provider>
);

export default MappingTestContextProvider;
