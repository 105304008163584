import { MappingTestBlockExecution } from "../../../models/MappingTests";
import MappingTestV2RightPanelErrors from "./right-panel-errors/MappingTestV2RightPanelErrors";
import MappingTestV2RightPanelHeader from "./MappingTestV2RightPanelHeader";
import MappingTestV2RightPanelLogs from "./MappingTestV2RightPanelLogs";

interface Props {
  blockName: string;
  selectedBlockExecution: MappingTestBlockExecution | undefined;
  setIsShowingRightPanel: (value: React.SetStateAction<boolean>) => void;
}

const MappingTestV2RightPanel = ({
  blockName,
  selectedBlockExecution,
  setIsShowingRightPanel,
}: Props) => {
  return (
    <div className="flex flex-column h-[calc(100vh-40px)] bg-white">
      <MappingTestV2RightPanelHeader
        blockName={blockName}
        selectedBlockExecution={selectedBlockExecution}
        setIsShowingRightPanel={setIsShowingRightPanel}
      />
      <div className="flex flex-column h-full overflow-y-scroll">
        {selectedBlockExecution && (
          <>
            {selectedBlockExecution.errors && selectedBlockExecution.errors.length > 0 && (
              <div>
                <MappingTestV2RightPanelErrors errors={selectedBlockExecution.errors} />
                <hr className="text-gray-50 h-[0.5px] my-0" />
              </div>
            )}
            {selectedBlockExecution.exit_data && selectedBlockExecution.exit_data.length && (
              <div>
                <MappingTestV2RightPanelLogs selectedBlockExecution={selectedBlockExecution} />
                <hr className="text-gray-50 h-[0.5px] my-0" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MappingTestV2RightPanel;
