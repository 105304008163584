import { useState } from "react";
import {
  BlueprintCanvasViewableStepLogsMap,
  BlueprintSelectedStepLog,
  BlueprintCanvasBaseStepLogList,
} from "../../../../models/Blueprints";
import { showWarningToast } from "../../../shared/Toasts";
import {
  generateViewableStepLogsByTraversingList,
  locateStartingIndexInRawLogs,
} from "../utils/viewableStepLogsUtils";

/**
 * Step logs to show in canvas, given a selected step log
 */
const useBlueprintCanvasViewableStepLogs = () => {
  // Step logs to show in canvas, given a selected step log
  const [viewableStepLogsForCanvas, setViewableStepLogsForCanvas] = useState<
    BlueprintCanvasViewableStepLogsMap | undefined
  >();

  /**
   * Generate viewable step logs, formatted so that <BlueprintStepCard> can display the log next to each step
   */
  const generateViewableStepLogs = (
    baseStepLogsList: BlueprintCanvasBaseStepLogList,
    selectedStepLog: BlueprintSelectedStepLog | undefined
  ) => {
    try {
      // Get starting index
      let startingIndex = locateStartingIndexInRawLogs(baseStepLogsList, selectedStepLog);

      // Think of "baseStepLogsList" as a linked list, where "index_of_previous_log" is the pointer to the next item
      // We follow the pointers until it leads us to the very 1st step log
      const viewableStepLogs = generateViewableStepLogsByTraversingList(
        baseStepLogsList,
        startingIndex
      );

      setViewableStepLogsForCanvas(viewableStepLogs);
    } catch (error) {
      showWarningToast(`Error rendering step I/O in canvas`);
      console.error(error);
    }
  };

  /**
   * Reset base canvas step logs
   * Used when blueprintRunnerExecutionResponse is cleaned up
   */
  const resetViewableStepLogs = () => {
    setViewableStepLogsForCanvas(undefined);
  };

  return {
    viewableStepLogsForCanvas,
    generateViewableStepLogs,
    resetViewableStepLogs,
  };
};

export default useBlueprintCanvasViewableStepLogs;
