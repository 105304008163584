import { fetchWithAuth } from "../../../api-client/api_client";
import { LinkedAccount } from "../../../models/Entities";

interface FetchLinkedAccountProps {
  setLinkedAccount: (linkedAccount: LinkedAccount | null) => void;
  setHasCheckedLinkedAccount: (hasCheckedLinkedAccount: boolean) => void;
  setIsSearchingLinkedAccount: (isSearchingLinkedAccount: boolean) => void;
}

const useFetchLinkedAccount = ({
  setLinkedAccount,
  setHasCheckedLinkedAccount,
  setIsSearchingLinkedAccount,
}: FetchLinkedAccountProps) => {
  const fetchLinkedAccount = (linkedAccountId: string) => {
    fetchWithAuth({
      path: `/integrations/linked-accounts/all/${linkedAccountId}`,
      method: "GET",
      onResponse: (data) => {
        setLinkedAccount(data);
        setIsSearchingLinkedAccount(false);
        setHasCheckedLinkedAccount(true);
      },
      onError: () => {
        setHasCheckedLinkedAccount(true);
        setLinkedAccount(null);
        setIsSearchingLinkedAccount(false);
      },
    });
  };

  return { fetchLinkedAccount };
};

export default useFetchLinkedAccount;
