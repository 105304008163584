import { Badge, Text } from "@merge-api/merge-javascript-shared";
import { ArrowUpRight } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { APIEndpointPaginationConfigurationTypes } from "../../../../models/APIEndpointModels";
import { PaginationType } from "../../../../models/PaginationConfigurationModels";
import { navigateToIntegrationBuilderPaginationConfigurationForm } from "../../../../router/RouterUtils";
import PaginationConfigurationExplain from "../../pagination-configuration-setup/components/PaginationConfigurationExplain";
import PaginationConfigurationSetupOptions from "../../pagination-configuration-setup/components/PaginationConfigurationSetupOptions";
import {
  KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER,
  KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER,
  PAGINATION_TYPE_TO_DISPLAY_NAME,
} from "../../pagination-configuration-setup/constants";
import AccordionCard from "../../shared/AccordionCard";
import KeyPathFieldHeader from "../../shared/KeyPathFieldHeader";
import MultiSelectHeader from "../../shared/MultiSelectHeader";
import SelectHeader from "../../shared/SelectHeader";
import { PaginationConfigurationIntegrationBuilder } from "../../utils/Entities";
import { API_ENDPOINT_PAGINATION_CONFIGURATION_OPTIONS_DATA } from "../constants";
import APIEndpointContext from "../context/APIEndpointContext";

interface APIEndpointPaginationSetupOptionsProps {
  integrationID: string;
  paginationConfigID: string | null;
  setPaginationConfigID: (value: string | null) => void;
  paginationConfigType: APIEndpointPaginationConfigurationTypes;
  setPaginationConfigType: (value: APIEndpointPaginationConfigurationTypes) => void;
  paginationConfigurations: PaginationConfigurationIntegrationBuilder[] | undefined;
  defaultPaginationConfiguration: PaginationConfigurationIntegrationBuilder | null;
  paginationResponseBodyArrayKeyPathOverride: string[] | null;
  setPaginationResponseBodyArrayKeyPathOverride: React.Dispatch<
    React.SetStateAction<string[] | null>
  >;
}

const APIEndpointPaginationSetupOptionsWithProps = React.memo(
  ({
    integrationID,
    paginationConfigID,
    setPaginationConfigID,
    paginationConfigType,
    setPaginationConfigType,
    paginationConfigurations,
    defaultPaginationConfiguration,
    paginationResponseBodyArrayKeyPathOverride,
    setPaginationResponseBodyArrayKeyPathOverride,
  }: APIEndpointPaginationSetupOptionsProps) => {
    const history = useHistory();

    const [selectedPaginationConfiguration, setSelectedPaginationConfiguration] = useState(
      paginationConfigurations?.find((config) => config.id === paginationConfigID)
    );

    useEffect(() => {
      setSelectedPaginationConfiguration(
        paginationConfigurations?.find((config) => config.id === paginationConfigID)
      );
    }, [paginationConfigurations, paginationConfigID]);

    // Check if there are no pagination configurations set up yet or if the user is editing the only existing one
    const isFirstPaginationConfiguration =
      !paginationConfigurations?.length ||
      (paginationConfigurations?.length === 1 &&
        paginationConfigurations[0].id == paginationConfigID);
    const newPaginationConfigurationOption = API_ENDPOINT_PAGINATION_CONFIGURATION_OPTIONS_DATA.filter(
      ({ value }) => value === APIEndpointPaginationConfigurationTypes.NEW
    );
    const optionsData = isFirstPaginationConfiguration
      ? newPaginationConfigurationOption
      : API_ENDPOINT_PAGINATION_CONFIGURATION_OPTIONS_DATA;

    const paginationConfigurationOptions = optionsData.map(({ value, text }) => ({
      value,
      text,
      selected: paginationConfigType === value,
      onClick: () => {
        if (value === APIEndpointPaginationConfigurationTypes.DEFAULT) {
          setPaginationConfigID(null);
        }
        setPaginationConfigType(value);
      },
    }));

    const renderOption = (option: PaginationConfigurationIntegrationBuilder) => {
      return (
        <div className="flex flex-col">
          <Text variant="md">{`${option.name} - ${
            PAGINATION_TYPE_TO_DISPLAY_NAME[option?.pagination_type as PaginationType]
          }`}</Text>
          <Text variant="sm">{option.description}</Text>
        </div>
      );
    };

    const renderValue = (option: PaginationConfigurationIntegrationBuilder) => {
      return (
        <Text variant="md">{`${option.name} - ${
          PAGINATION_TYPE_TO_DISPLAY_NAME[option?.pagination_type as PaginationType]
        }`}</Text>
      );
    };

    const renderLinkToPaginationConfiguration = (
      config: PaginationConfigurationIntegrationBuilder,
      isDefault: boolean = false
    ) => {
      return (
        <div
          className="cursor-pointer text-blue-40 items-center"
          onClick={() => {
            navigateToIntegrationBuilderPaginationConfigurationForm(
              history,
              integrationID,
              config.id ?? "",
              true
            );
          }}
        >
          <Text variant="pre-title">
            {isDefault
              ? "View default pagination configuration"
              : `View ${config.name} configuration`}
          </Text>
          <ArrowUpRight size={12} />
        </div>
      );
    };

    const renderResponseBodyArrayKeyPathOverride = (isDefault: boolean = false) => {
      // Form a placeholder that is the default array key path if it exists.
      const paginationConfiguration = isDefault
        ? defaultPaginationConfiguration
        : selectedPaginationConfiguration;
      const defaultArray =
        paginationConfiguration?.response_information?.paginated_results_array_key_path ?? null;
      const defaultArrayString = defaultArray?.join(" -> ") ?? "";
      const emptyPlaceholder = !!paginationConfiguration
        ? `Default: ${defaultArrayString}`
        : KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER;

      return (
        <KeyPathFieldHeader
          dataTestID="field-api-endpoint-pagination-config-response-body-array-key-path-override"
          title="Response body array key path override"
          subtitle="If the endpoint pagination configuration looks the same as a configuration you have already set up aside from where the response data is located, you can override the key path to that data here."
          keyPath={paginationResponseBodyArrayKeyPathOverride}
          onKeyPathChange={setPaginationResponseBodyArrayKeyPathOverride}
          placeholder={
            paginationResponseBodyArrayKeyPathOverride?.length
              ? KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER
              : emptyPlaceholder
          }
          className="mt-3"
        />
      );
    };

    return (
      <AccordionCard
        dataTestID="accordion-api-endpoint-pagination-config"
        title="Pagination configuration"
      >
        <PaginationConfigurationExplain />
        <MultiSelectHeader
          dataTestID="field-api-endpoint-pagination-config-select-configuration-chips"
          title="Select configuration"
          options={paginationConfigurationOptions}
          className="mt-6"
        />
        {paginationConfigType === APIEndpointPaginationConfigurationTypes.DEFAULT &&
          !!defaultPaginationConfiguration && (
            <div className="border-l-2 pl-6 border-gray-10 mt-6">
              <div className="flex flex-row items-center">
                <Text className="mr-2" variant="h6">
                  {defaultPaginationConfiguration.name}
                </Text>
                <Badge size="md" color="gray">
                  {
                    PAGINATION_TYPE_TO_DISPLAY_NAME[
                      defaultPaginationConfiguration.pagination_type as PaginationType
                    ]
                  }
                </Badge>
              </div>
              <div className="mt-2">
                <Text>{defaultPaginationConfiguration.description}</Text>
              </div>
              <div className="flex mt-2">
                {renderLinkToPaginationConfiguration(defaultPaginationConfiguration, true)}
              </div>
              {renderResponseBodyArrayKeyPathOverride(true)}
            </div>
          )}
        {paginationConfigType === APIEndpointPaginationConfigurationTypes.SELECTED && (
          <div className="border-l-2 pl-6 border-gray-10 mt-6">
            <SelectHeader
              dataTestID="field-api-endpoint-pagination-config-select-configuration-select-header"
              title="Select configuration"
              options={
                paginationConfigurations?.filter((config) => !config.is_default_for_integration) ||
                []
              }
              onChange={(
                _: any,
                selectedOption: PaginationConfigurationIntegrationBuilder | null
              ) => {
                setPaginationConfigID(selectedOption?.id || null);
              }}
              value={selectedPaginationConfiguration}
              renderOption={renderOption}
              renderValue={renderValue}
            />
            <div className="mt-2">
              {selectedPaginationConfiguration &&
                renderLinkToPaginationConfiguration(selectedPaginationConfiguration)}
            </div>
            {renderResponseBodyArrayKeyPathOverride()}
          </div>
        )}
        {paginationConfigType === APIEndpointPaginationConfigurationTypes.NEW && (
          <div className="border-l-2 pl-6 border-gray-10 mt-6">
            <PaginationConfigurationSetupOptions
              isNewPaginationConfiguration={true}
              inlineSetup={true}
              isFirstPaginationConfiguration={!paginationConfigurations?.length}
            />
          </div>
        )}
      </AccordionCard>
    );
  }
);

// We set up these wrappers to pass the props from the context to the component
// Any changes to the context (even if they are not used in the component) will trigger a re-render
const APIEndpointPaginationSetupOptions = () => {
  const {
    integrationID,
    paginationConfigID,
    setPaginationConfigID,
    paginationConfigType,
    setPaginationConfigType,
    paginationConfigurations,
    defaultPaginationConfiguration,
    paginationResponseBodyArrayKeyPathOverride,
    setPaginationResponseBodyArrayKeyPathOverride,
  } = useContext(APIEndpointContext);

  return (
    <APIEndpointPaginationSetupOptionsWithProps
      integrationID={integrationID}
      paginationConfigID={paginationConfigID}
      setPaginationConfigID={setPaginationConfigID}
      paginationConfigType={paginationConfigType}
      setPaginationConfigType={setPaginationConfigType}
      paginationConfigurations={paginationConfigurations}
      defaultPaginationConfiguration={defaultPaginationConfiguration}
      paginationResponseBodyArrayKeyPathOverride={paginationResponseBodyArrayKeyPathOverride}
      setPaginationResponseBodyArrayKeyPathOverride={setPaginationResponseBodyArrayKeyPathOverride}
    />
  );
};

export default APIEndpointPaginationSetupOptions;
