import { Dialog } from "@merge-api/merge-javascript-shared";
import MappingTestV2RequestMock from "./requests/MappingTestV2RequestMock";
import { MappingTestRequestMock } from "../../../models/MappingTests";
import EmptyStateWrapper from "../../shared/layout/EmptyStateWrapper";
import { useGetBlueprintExecutions } from "./requests/hooks/useGetBlueprintExecutions";
import { useImportBlueprintExecutionsToMappingTest } from "./requests/hooks/useImportBlueprintExecutionsToMappingTest";

interface Props {
  blueprintExecutionID: string;
  show: boolean;
  handleClose: () => void;
  testID: string;
  addRequestMock: (requestMock: MappingTestRequestMock) => void;
  saveMappingTest: () => void;
}

const noop = () => {};

const ImportBlueprintExecutionRequestsPreviewModal = ({
  show,
  handleClose,
  blueprintExecutionID,
  testID,
  addRequestMock,
  saveMappingTest,
}: Props) => {
  const { apiRequests, isLoadingAPIRequests } = useGetBlueprintExecutions({
    show,
    blueprintExecutionID,
  });
  const { isLoading, handleConfirm } = useImportBlueprintExecutionsToMappingTest({
    blueprintExecutionID,
    testID,
    addRequestMock,
    handleClose,
    saveMappingTest,
  });

  return (
    <Dialog
      title="Import blueprint execution requests"
      open={show}
      variant="lg"
      onClose={handleClose}
      primaryButtonText="Confirm"
      secondaryButtonText="Cancel"
      primaryButtonLoading={isLoading}
      onSecondaryButtonClick={handleClose}
      onPrimaryButtonClick={handleConfirm}
      children={
        !isLoadingAPIRequests ? (
          <div className="max-h-[500px] overflow-y-scroll overflow-x-hidden">
            {apiRequests.map((request) => (
              <MappingTestV2RequestMock
                key={request.id}
                requestMock={request}
                editable={false}
                editRequestMock={noop}
                deleteRequestMock={noop}
                setCopiedRequestMock={noop}
                saveMappingTest={saveMappingTest}
              />
            ))}
          </div>
        ) : (
          <EmptyStateWrapper isSpinner />
        )
      }
    />
  );
};

export default ImportBlueprintExecutionRequestsPreviewModal;
