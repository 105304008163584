import { Button, ButtonVariant, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import clsx from "clsx";
import { Braces, ChevronDown, Info } from "lucide-react";
import { useState } from "react";
import SampleJSONUpload from "./SampleJSONUpload";
import ViewSourceText from "./ViewSourceText";

interface HeaderBaseProps {
  title?: string;
  subtitle?: string;
  subtitleComponent?: React.ReactNode;
  helpText?: string;
  learnMoreText?: string;
  learnMoreTextComponent?: React.ReactNode;
  required?: boolean;
  hasSource?: boolean;
  hasSampleJSONUpload?: boolean;
  setValue?: (value: string) => void;
  dataTestID?: string;
}

const HeaderBase = ({
  title,
  subtitle,
  subtitleComponent,
  learnMoreText,
  learnMoreTextComponent,
  helpText,
  required,
  hasSource,
  hasSampleJSONUpload,
  setValue,
  dataTestID,
}: HeaderBaseProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isLearnMoreOpen, setIsLearnMoreOpen] = useState<boolean>(false);

  return (
    <>
      <div data-testid={dataTestID} className="flex flex-row items-center justify-between w-full">
        <div
          className={clsx("text-slate-90 text-base font-semibold flex flex-row items-center", {
            "mb-2": (!subtitleComponent && !subtitle) || !title,
          })}
        >
          {title}
          {helpText && (
            <Tooltip title={helpText}>
              <Info size={12} className="ml-2 text-gray-70 flex items-center" />
            </Tooltip>
          )}
          {required && (
            <Text variant="md" className="text-red-50 ml-1">
              *
            </Text>
          )}
        </div>
        <div className={clsx("flex flex-row items-center", { "mb-2": hasSampleJSONUpload })}>
          {hasSampleJSONUpload && setValue && (
            <>
              <Button
                size="sm"
                onClick={() => setIsDialogOpen(true)}
                leftIcon={<Braces size={12} />}
                variant={ButtonVariant.IconOnly}
              >
                Use sample JSON
              </Button>
              <SampleJSONUpload
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                setValue={setValue}
              />
            </>
          )}
          {hasSource && <ViewSourceText />}
        </div>
      </div>
      {(subtitleComponent || subtitle) && (
        <div className="mb-2">
          <div className="inline">
            {subtitleComponent ? (
              subtitleComponent
            ) : (
              <Text as="span" className="text-gray-70" variant="sm">
                {subtitle}{" "}
              </Text>
            )}

            {(learnMoreText || learnMoreTextComponent) && (
              <span
                className="inline-flex items-center space-x-1 cursor-pointer text-blue-40"
                onClick={() => setIsLearnMoreOpen(!isLearnMoreOpen)}
              >
                <Text as="span" variant="sm">
                  Learn more
                </Text>
                <span
                  className={clsx(
                    "transition-transform duration-120 flex items-center",
                    isLearnMoreOpen ? "transform -rotate-180" : "transform rotate-0"
                  )}
                >
                  <ChevronDown size={12} />
                </span>
              </span>
            )}
          </div>
          <div
            className={clsx(
              "grid overflow-hidden transition-[grid-template-rows] duration-300 ease-in-out",
              isLearnMoreOpen ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
            )}
          >
            <div className="flex flex-row justify-start items-stretch space-x-3 pt-1 overflow-hidden">
              <div className="border-l-2 border-gray-10"></div>
              <div>
                <Text className="text-gray-70 text-sm">
                  {learnMoreTextComponent ?? learnMoreText}
                </Text>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default HeaderBase;
