import { useState } from "react";
import EditableJSONSchemaValueMenu from "./EditableJSONSchemaValueMenu";

type Props = {
  children: JSX.Element;
  updateJSONSchemaValue: (updateJSONSchemaValue: string) => void;
};

const EditableJSONSchemaValue = ({ children, updateJSONSchemaValue }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <div className="cursor-pointer text" onClick={() => setIsMenuOpen(true)}>
        {children}
      </div>
      {isMenuOpen && (
        <EditableJSONSchemaValueMenu
          setIsMenuOpen={setIsMenuOpen}
          updateJSONSchemaValue={updateJSONSchemaValue}
        />
      )}
    </>
  );
};

export default EditableJSONSchemaValue;
