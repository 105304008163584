import { Toggle } from "@merge-api/merge-javascript-shared";
import MergeDatePicker from "../../../../shared/MergeDatePicker/MergeDatePicker";
import UserPicker from "../../../../shared/UserPicker/UserPicker";
import { ChangelogEventType, ChangelogModelType } from "../../utils/types";
import EventTypePicker from "./EventTypePicker";
import ModelTypePicker from "./ModelTypePicker";
import { MergeFlagFeature, useMergeFlag } from "../../../../shared/hooks/useMergeFlag";

interface ChangelogFiltersProps {
  startDate: Date | null;
  endDate: Date | null;
  eventType: ChangelogEventType | null;
  modelType: ChangelogModelType | null;
  userId: string | null;
  filterDeploys: boolean;
  onFilterChange: (
    key: "startDate" | "endDate" | "eventType" | "modelType" | "userId" | "filterDeploys"
  ) => (value: any) => void;
}

const ChangelogFilters = ({
  startDate,
  endDate,
  eventType,
  modelType,
  onFilterChange,
  userId,
  filterDeploys,
}: ChangelogFiltersProps) => {
  const { enabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_GITHUB_DEPLOYS_IN_CHANGELOG,
    isEnabledForUser: true,
  });

  return (
    <div className="flex flex-row w-full gap-4 mb-6 items-center">
      <div className="grow">
        <UserPicker userId={userId} onUserIdChange={onFilterChange("userId")} />
      </div>
      <div className="grow">
        <ModelTypePicker modelType={modelType} onModelTypeChange={onFilterChange("modelType")} />
      </div>
      <div className="grow">
        <EventTypePicker eventType={eventType} onEventTypeChange={onFilterChange("eventType")} />
      </div>
      <div className="grow">
        <MergeDatePicker
          selectedStartDate={startDate}
          selectedEndDate={endDate}
          onStartDateSet={onFilterChange("startDate")}
          onEndDateSet={onFilterChange("endDate")}
        />
      </div>
      {enabled && (
        <div className="bg-gray-0 rounded-md p-2 pr-4 pl-3 gap-2">
          <Toggle
            checked={filterDeploys}
            label={<span className="whitespace-nowrap">Show deploys</span>}
            onChange={onFilterChange("filterDeploys")}
          />
        </div>
      )}
    </div>
  );
};

export default ChangelogFilters;
