import { useState } from "react";
import { changeBlueprintStatus } from "../../../../components/blueprint-editor/utils/BlueprintEditorAPIClient";
import { showErrorToast, showSuccessToast } from "../../../../components/shared/Toasts";
import { BlueprintStatus } from "../../../../models/Blueprints";

interface Props {
  onSuccess?: () => void;
  onError?: () => void;
}

/**
 * This hook manages the state and logic for archiving a blueprint.
 *
 * @param {Object} props - The parameters object.
 * @param {Function} [props.onSuccess] - Optional callback function to be called on successful archive.
 * @param {Function} [props.onError] - Optional callback function to be called on archive error.
 * @returns {Object} An object containing:
 *   - isLoadingArchiveBlueprint: Boolean indicating if archiving is in progress
 *   - archiveBlueprint: Function to archive a blueprint
 */
const useArchiveBlueprint = ({ onSuccess, onError }: Props) => {
  const [isLoadingArchiveBlueprint, setIsLoadingArchiveBlueprint] = useState<boolean>(false);

  const archiveBlueprint = (blueprintVersionID: string) => {
    setIsLoadingArchiveBlueprint(true);
    changeBlueprintStatus({
      versionID: blueprintVersionID,
      status: BlueprintStatus.Archived,
      onSuccess: () => {
        showSuccessToast("Successfully archived blueprint!");
        setIsLoadingArchiveBlueprint(false);
        onSuccess && onSuccess();
      },
      onError: () => {
        showErrorToast("Failed to archive blueprint.");
        setIsLoadingArchiveBlueprint(false);
        onError && onError();
      },
    });
  };

  return {
    isLoadingArchiveBlueprint,
    archiveBlueprint,
  };
};

export default useArchiveBlueprint;
