import { IntegrationAvatar, AvatarSize } from "../MergeAvatars";
import { Integration } from "../../../models/Entities";
import { Text } from "@merge-api/merge-javascript-shared";

type Props = {
  integration: Integration;
  header: string;
  subheader: string;
  hasUnsavedChanges?: boolean;
};

const HeaderSection = ({ integration, header, subheader, hasUnsavedChanges }: Props) => {
  return (
    <div>
      <Text variant="h4" className="pb-2">
        {header}
      </Text>
      <div className="space-x-2 d-flex align-items-center">
        <IntegrationAvatar integration={integration} size={AvatarSize.xs} />
        <Text variant="h5">{subheader}</Text>
        {hasUnsavedChanges && (
          <span className="badge badge-soft-danger ml-1.5">Unsaved changes</span>
        )}
      </div>
    </div>
  );
};

export default HeaderSection;
