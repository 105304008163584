import {
  BlueprintParameterValue,
  BlueprintParameterValueProcedureArray,
  BlueprintStepType,
} from "../../../../models/Blueprints";
import { parseParameterValue } from "../../utils/SharedComponentUtils";
import { Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { parseParamValuefromStep, parseProcedureArray } from "./utils/stepCardPreviewUtil";

interface Props {
  step: Record<string, BlueprintParameterValue>;
  stepType?: BlueprintStepType | string;
}

const StepCardPreview = ({ step, stepType }: Props) => {
  const code = parseParamValuefromStep(step, stepType);
  const displayText = () => {
    if (step.array || (step.value && stepType != BlueprintStepType.SetVariable)) {
      return `Params: ${code}`;
    } else if (stepType == BlueprintStepType.IfElse) {
      return `${code}`;
    } else if (stepType === BlueprintStepType.SetVariable && step.key && step.value_type) {
      return `Set ${parseParameterValue(
        step.key as BlueprintParameterValue
      )} to ${parseParameterValue((step.value ?? step.value_type) as BlueprintParameterValue)}`;
    } else if (stepType == BlueprintStepType.DataTransform && step.procedures) {
      return parseProcedureArray(step.procedures as BlueprintParameterValueProcedureArray);
    } else if (stepType == BlueprintStepType.CustomFunction) {
      return parseParameterValue(step.code);
    } else return "";
  };

  return (
    <div className="max-h-[22px]">
      <Tooltip
        title={<Text variant="md-mono">{displayText()}</Text>}
        placement="left"
        className="w-full truncate"
      >
        <div className="flex flex-row bg-gray-10 rounded px-2 truncate">
          <Text variant="sm-mono" className="truncate">
            {displayText()}
          </Text>
        </div>
      </Tooltip>
    </div>
  );
};

export default StepCardPreview;
