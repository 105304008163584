import { Card } from "@merge-api/merge-javascript-shared";
import clsx from "clsx";
import { useState } from "react";
import StagedComponentOverrideModal from "../IntegrationStagedVersions/StagedComponentOverrideModal";
import {
  IntegrationValidatorRuleResult,
  IntegrationValidatorRuleResultType,
  IntegrationValidatorRuleSeverityType,
} from "../types";
import IntegrationValidatorRuleAlertAdditionalInfo from "./IntegrationValidatorRuleAlertAdditionalInfo";
import IntegrationValidatorRuleAlertTitle from "./IntegrationValidatorRuleAlertTitle";

interface Props {
  validatorRuleResult: IntegrationValidatorRuleResult;
}

const getAlertColor = (validatorRuleResult: IntegrationValidatorRuleResult): string => {
  const { severity, result, is_overridden } = validatorRuleResult;

  // If the result is overridden, we want to show a gray alert
  if (is_overridden) {
    return "bg-gray-0";
  }

  // If the result is not overridden and a success, we want to show a green alert
  if (result === IntegrationValidatorRuleResultType.SUCCESS) {
    return "bg-green-0";
  }

  // All other alerts should be displayed by their severity
  switch (severity) {
    case IntegrationValidatorRuleSeverityType.BLOCKING:
      return "bg-red-0";
    case IntegrationValidatorRuleSeverityType.WARNING:
      return "bg-yellow-0";
    default:
      return "bg-gray-0";
  }
};

const IntegrationValidatorRuleAlert = ({ validatorRuleResult }: Props) => {
  const bgColor = getAlertColor(validatorRuleResult);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const shouldShowAdditionalInfo =
    validatorRuleResult.additional_information &&
    Object.keys(validatorRuleResult.additional_information).length > 0 &&
    validatorRuleResult.result !== IntegrationValidatorRuleResultType.SUCCESS;

  return (
    <Card variant="none" className={clsx(bgColor, "rounded-lg p-4")}>
      <StagedComponentOverrideModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        rule={validatorRuleResult}
      />
      <IntegrationValidatorRuleAlertTitle
        validatorRuleResult={validatorRuleResult}
        setIsModalOpen={setIsModalOpen}
      />
      {shouldShowAdditionalInfo && (
        <IntegrationValidatorRuleAlertAdditionalInfo validatorRuleResult={validatorRuleResult} />
      )}
    </Card>
  );
};

export default IntegrationValidatorRuleAlert;
