import React, { useContext } from "react";
import AccordionCard from "../../../shared/AccordionCard";
import JSONSchemaFieldHeader from "../../../shared/JSONSchemaFieldHeader";
import MultiSelectHeader from "../../../shared/MultiSelectHeader";
import { API_ENDPOINT_RESPONSE_TYPE_OPTIONS_DATA } from "../../constants";
import APIEndpointContext from "../../context/APIEndpointContext";
import AdvancedResponseConfigurationSetupOptions from "./AdvancedResponseConfigurationSetupOptions";
import { APIEndpointBodyParsingConfiguration } from "../../../utils/Entities";
import { ResponseBodyAdditionalParsing } from "../../../../../models/Entities";
import { APIEndpointTypes } from "../../../../../models/APIEndpointModels";

interface AdditionalContextProps {
  responseType: string;
  setResponseType: (value: string) => void;
  responseSchema: string;
  setResponseSchema: (value: string) => void;
  allowedErrorCodes: string[] | null;
  setAllowedErrorCodes: React.Dispatch<React.SetStateAction<string[] | null>>;
  setAdditionalResponseBodyParsing: React.Dispatch<APIEndpointBodyParsingConfiguration[] | null>;
  responseBodyParsingRules: ResponseBodyAdditionalParsing;
  setResponseBodyParsingRules: (data: ResponseBodyAdditionalParsing) => void;
  syncCursorKeyPath: string[] | null;
  setSyncCursorKeyPath: (value: string[] | null) => void;
  endpointType: string;
}

const ResponseConfigurationSetupOptionsWithContext = React.memo(
  ({
    responseType,
    setResponseType,
    responseSchema,
    setResponseSchema,
    allowedErrorCodes,
    setAllowedErrorCodes,
    setAdditionalResponseBodyParsing,
    responseBodyParsingRules,
    setResponseBodyParsingRules,
    syncCursorKeyPath,
    setSyncCursorKeyPath,
    endpointType,
  }: AdditionalContextProps) => {
    const responseTypeOptions = API_ENDPOINT_RESPONSE_TYPE_OPTIONS_DATA.map(({ value, text }) => ({
      value,
      text,
      selected: responseType === value,
      onClick: setResponseType,
    }));

    return (
      <AccordionCard title="Response configuration" defaultExpanded={true}>
        <MultiSelectHeader
          dataTestID="field-api-endpoint-response-type"
          title="Response type"
          options={responseTypeOptions}
          className="mb-6"
        />
        <JSONSchemaFieldHeader
          dataTestID="field-api-endpoint-response-schema"
          value={responseSchema}
          setValue={setResponseSchema}
          title="Response schema"
          subtitle={
            endpointType === APIEndpointTypes.PAGINATION
              ? "Since endpoint type is 'Pagination', the response schema should represent the array of results, not the entire response payload (unless the entire payload is the array of results)."
              : ""
          }
          className="mb-6"
          hasSampleJSONUpload={true}
        />
        <AdvancedResponseConfigurationSetupOptions
          allowedErrorCodes={allowedErrorCodes}
          setAllowedErrorCodes={setAllowedErrorCodes}
          setAdditionalResponseBodyParsing={setAdditionalResponseBodyParsing}
          responseBodyParsingRules={responseBodyParsingRules}
          setResponseBodyParsingRules={setResponseBodyParsingRules}
          syncCursorKeyPath={syncCursorKeyPath}
          setSyncCursorKeyPath={setSyncCursorKeyPath}
        />
      </AccordionCard>
    );
  }
);

// We set up these wrappers to pass the props from the context to the component
// Any changes to the context (even if they are not used in the component) will trigger a re-render
const ResponseConfigurationSetupOptions = () => {
  const {
    responseType,
    setResponseType,
    responseSchema,
    setResponseSchema,
    allowedErrorCodes,
    setAllowedErrorCodes,
    setAdditionalResponseBodyParsing,
    responseBodyParsingRules,
    setResponseBodyParsingRules,
    syncCursorKeyPath,
    setSyncCursorKeyPath,
    endpointType,
  } = useContext(APIEndpointContext);

  return (
    <ResponseConfigurationSetupOptionsWithContext
      responseType={responseType}
      setResponseType={setResponseType}
      responseSchema={responseSchema}
      setResponseSchema={setResponseSchema}
      allowedErrorCodes={allowedErrorCodes}
      setAllowedErrorCodes={setAllowedErrorCodes}
      setAdditionalResponseBodyParsing={setAdditionalResponseBodyParsing}
      responseBodyParsingRules={responseBodyParsingRules}
      setResponseBodyParsingRules={setResponseBodyParsingRules}
      syncCursorKeyPath={syncCursorKeyPath}
      setSyncCursorKeyPath={setSyncCursorKeyPath}
      endpointType={endpointType}
    />
  );
};

export default ResponseConfigurationSetupOptions;
