import MergeFlagWrapper from "../../../components/shared/MergeFlagWrapper";
import { Integration } from "../../../models/Entities";
import BlueprintsTable from "./BlueprintsTable";
import BlueprintsTablePageWrapper from "./BlueprintsTablePageWrapper";
import BlueprintsSearch from "../blueprints-search/BlueprintsSearch";
import { MergeFlagFeature } from "../../../components/shared/hooks/useMergeFlag";

type Props = { integrationMeta: Integration };

function BlueprintsOverview({ integrationMeta }: Props) {
  return (
    <MergeFlagWrapper
      isEnabledForUser={true}
      feature={MergeFlagFeature.MERGE_FLAG_BLUEPRINT_SEARCH}
      children={<BlueprintsSearch integration={integrationMeta} />}
      fallback={
        <BlueprintsTablePageWrapper>
          <BlueprintsTable integrationMeta={integrationMeta} />
        </BlueprintsTablePageWrapper>
      }
    />
  );
}

export default BlueprintsOverview;
