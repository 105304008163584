import { useReducer, useCallback, Reducer } from "react";
import { ComponentState } from "../../../versioned-components/types";

// Generic type for the reducer actions
export type ReducerActions<T> = {
  set: (newPresent: VersionedComponentInstance<T>) => void;
  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;
  updateFieldValue: (fieldKeyPath: Array<string | number>, newFieldValue: any) => void;
  updateComponentState: (newComponentState: ComponentState) => void;
};

// Generic enum for action types
enum ReducerActionType {
  UNDO = "UNDO",
  REDO = "REDO",
  SET_VERSIONED_COMPONENT = "SET_VERSIONED_COMPONENT",
  UPDATE_FIELD_VALUE = "UPDATE_FIELD_VALUE",
  UPDATE_COMPONENT_STATE = "UPDATE_COMPONENT_STATE",
}

// Generic type for VersionedComponentInstance
export type VersionedComponentInstance<T> = {
  componentState: ComponentState;
  versionedComponentUnderConstruction: T;
};

// Generic state type
type State<T> = {
  past: Array<VersionedComponentInstance<T>>;
  present: VersionedComponentInstance<T>;
  future: Array<VersionedComponentInstance<T>>;
};

// Generic action types
interface ActionBase<T extends ReducerActionType> {
  type: T;
}

interface UndoAction extends ActionBase<ReducerActionType.UNDO> {}

interface RedoAction extends ActionBase<ReducerActionType.REDO> {}

interface UpdateComponentStateAction extends ActionBase<ReducerActionType.UPDATE_COMPONENT_STATE> {
  newComponentState: ComponentState;
}

interface SetVersionedComponentAction<T>
  extends ActionBase<ReducerActionType.SET_VERSIONED_COMPONENT> {
  newPresent: VersionedComponentInstance<T>;
}

interface UpdateFieldValueAction extends ActionBase<ReducerActionType.UPDATE_FIELD_VALUE> {
  fieldKeyPath: Array<string | number>;
  newFieldValue: any;
}

type Action<T> =
  | SetVersionedComponentAction<T>
  | UpdateFieldValueAction
  | UndoAction
  | RedoAction
  | UpdateComponentStateAction;

type ActionAndDispatch<T extends Action<any>> = T & {
  dispatch?: (props: T) => void;
};

function updateNestedObject(obj: any, keyPath: (string | number)[], value: any): any {
  if (keyPath.length === 0) {
    return value;
  }

  const [currentKey, ...remainingKeys] = keyPath;

  if (typeof currentKey === "number") {
    // If currentKey is a number, treat obj as an array
    const arrayCopy = Array.isArray(obj) ? [...obj] : [];
    arrayCopy[currentKey] = updateNestedObject(arrayCopy[currentKey] ?? {}, remainingKeys, value);
    return arrayCopy;
  } else {
    // If currentKey is a string, treat obj as an object
    return {
      ...obj,
      [currentKey]: updateNestedObject(obj[currentKey] ?? {}, remainingKeys, value),
    };
  }
}

// Generic reducer function
function reducer<T>(state: State<T>, action: ActionAndDispatch<Action<T>>): State<T> {
  const { past, present, future } = state;

  const updateForNewPresent = (newPresent: VersionedComponentInstance<T>) => {
    if (newPresent === present) {
      return state;
    }

    if (present === undefined) {
      return { ...state, past: [], present: newPresent, future: [] };
    }
    return {
      ...state,
      past: [...past, present],
      present: newPresent,
      future: [],
    };
  };

  switch (action.type) {
    case ReducerActionType.UNDO: {
      const previous = past[past.length - 1];
      const newPast = past.slice(0, past.length - 1);

      return {
        past: newPast,
        present: previous,
        future: [present, ...future],
      };
    }

    case ReducerActionType.REDO: {
      const next = future[0];
      const newFuture = future.slice(1);

      return {
        past: [...past, present],
        present: next,
        future: newFuture,
      };
    }

    case ReducerActionType.SET_VERSIONED_COMPONENT: {
      const { newPresent } = action;

      return updateForNewPresent(newPresent);
    }

    // Updating state triggers after DB call. We don't want to
    // support undo/redo actions after we commit to DB, so we reset the
    // past and future states
    case ReducerActionType.UPDATE_COMPONENT_STATE: {
      const { newComponentState } = action;

      const newPresent = { ...present, componentState: newComponentState };

      return {
        past: [],
        present: newPresent,
        future: [],
      };
    }

    case ReducerActionType.UPDATE_FIELD_VALUE: {
      const { fieldKeyPath, newFieldValue } = action;
      if (!fieldKeyPath || fieldKeyPath.length === 0) {
        return state;
      }

      const newPresent = {
        ...present,
        versionedComponentUnderConstruction: updateNestedObject(
          present?.versionedComponentUnderConstruction,
          fieldKeyPath,
          newFieldValue
        ),
      };

      return updateForNewPresent(newPresent);
    }
    default:
      return state;
  }
}

// Custom hook with generic type
function useVersionedComponentReducer<T>(
  initialPresent: VersionedComponentInstance<T>
): [VersionedComponentInstance<T> | undefined, ReducerActions<T>] {
  const [state, dispatch] = useReducer<Reducer<State<T>, ActionAndDispatch<Action<T>>>>(
    reducer,
    {
      past: [],
      future: [],
      present: initialPresent,
    },
    undefined
  );

  const canUndo = state.past.length !== 0;
  const canRedo = state.future.length !== 0;
  const undo = useCallback(() => {
    if (canUndo) {
      dispatch({ type: ReducerActionType.UNDO });
    }
  }, [canUndo]);
  const redo = useCallback(() => {
    if (canRedo) {
      dispatch({ type: ReducerActionType.REDO });
    }
  }, [canRedo]);
  const set = useCallback(
    (newPresent: VersionedComponentInstance<T>) =>
      dispatch({
        type: ReducerActionType.SET_VERSIONED_COMPONENT,
        newPresent,
      }),
    []
  );

  const updateFieldValue = useCallback(
    (fieldKeyPath: (string | number)[], newFieldValue: any) =>
      dispatch({
        type: ReducerActionType.UPDATE_FIELD_VALUE,
        fieldKeyPath,
        newFieldValue,
      }),
    []
  );

  const updateComponentState = useCallback(
    (newComponentState: ComponentState) =>
      dispatch({
        type: ReducerActionType.UPDATE_COMPONENT_STATE,
        newComponentState,
      }),
    []
  );

  return [
    state.present,
    {
      set,
      undo,
      redo,
      canUndo,
      canRedo,
      updateFieldValue,
      updateComponentState,
    },
  ];
}

export default useVersionedComponentReducer;
