import { TextField } from "@merge-api/merge-javascript-shared";
import ConnectorInputHeader from "./ConnectorInputHeader";
import useBlueprintContext from "../../context/useBlueprintContext";

interface QBXMLEditorProps {
  qbXMLQueryRequestFormat: string | undefined;
}
const QBXMLEditor = ({ qbXMLQueryRequestFormat }: QBXMLEditorProps) => {
  // context
  const { setBlueprintQBXMLQueryRequestFormat } = useBlueprintContext();
  // constants
  const NOTION_QBD_QBXML_DOCS_LINK =
    "https://www.notion.so/mergeapi/QBD-Platform-Mapping-e90d233a53f94596bb99ed58c06d1394";
  return (
    <>
      <ConnectorInputHeader
        link={NOTION_QBD_QBXML_DOCS_LINK}
        title="qbXML query request format"
        description="The format of the qbXML request that this blueprint will send to retrieve data from the web connector"
      />
      <TextField
        className="mb-4 mt-2"
        multiline
        value={qbXMLQueryRequestFormat ?? undefined}
        onChange={(e) => setBlueprintQBXMLQueryRequestFormat(e.target.value)}
      />
    </>
  );
};

export default QBXMLEditor;
