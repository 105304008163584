import { useState } from "react";
import { Blueprint, WebConnectorSession } from "../../../../../models/Blueprints";
import { fetchWithAuth } from "../../../../../api-client/api_client";
import { usePollWebConnectorSessionStatus } from "./usePollWebConnectorSessionStatus";
import { showErrorToast } from "../../../../shared/Toasts";
import { isValidJSON } from "../../../../../utils";

interface UseInitiateWebConnectorLiveRunProps {
  blueprint: Blueprint;
  linkedAccountId?: string;
  globalVarsAsString?: string;
  overrideLastRunAt?: string;
  shouldLogStepIO?: boolean;
}

const useInitiateWebConnectorLiveRun = ({
  blueprint,
  linkedAccountId,
  globalVarsAsString,
  overrideLastRunAt,
  shouldLogStepIO,
}: UseInitiateWebConnectorLiveRunProps) => {
  // state
  const [isLoadingLiveRun, setIsLoadingLiveRun] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [
    webConnectorSessionResponse,
    setWebConnectorSessionResponse,
  ] = useState<WebConnectorSession | null>(null);

  // hook used to poll the ID returned from the view in the initiateLiveRun view
  const { pollWebConnectorSessionStatus } = usePollWebConnectorSessionStatus({
    setWebConnectorSessionResponse,
    onFinalStateReached: () => {
      setIsLoadingLiveRun(false);
    },
  });

  const initiateLiveRun = () => {
    setIsLoadingLiveRun(true);
    setError(null);

    if (!linkedAccountId) {
      return;
    }

    const payload: Record<string, any> = {
      version_id: blueprint.version.id,
      linked_account_id: linkedAccountId,
      global_var_test_values:
        globalVarsAsString && isValidJSON(globalVarsAsString)
          ? JSON.parse(globalVarsAsString)
          : null,
      ...(overrideLastRunAt?.trim() && { override_last_run_at: overrideLastRunAt }),
      ...(shouldLogStepIO && {
        debugging_configuration: { should_log_step_io: shouldLogStepIO },
      }),
    };

    fetchWithAuth({
      path: "/blueprints/test-web-connector",
      method: "POST",
      body: payload,
      onResponse: (data) => {
        setWebConnectorSessionResponse(data);
        if (data?.id) {
          pollWebConnectorSessionStatus(data.id);
        }
      },
      onError: () => {
        showErrorToast("A server error occurred. The #bpe-alerts slack channel has been pinged.");
        setIsLoadingLiveRun(false);
      },
    });
  };

  return { initiateLiveRun, isLoadingLiveRun, error, webConnectorSessionResponse };
};

export default useInitiateWebConnectorLiveRun;
