import { Card, TextField, Text } from "@merge-api/merge-javascript-shared";
import CardHeader from "../../shared/CardHeader";
import { WebAddressSchemaIntegrationBuilder } from "../../utils/Entities";

interface EnterURLProps {
  fullURL: string | undefined;
  setFullURL: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedWebAddressSchema: WebAddressSchemaIntegrationBuilder | undefined;
  setSourceOfChange: React.Dispatch<React.SetStateAction<"fullURL" | "parts">>;
}

const EnterURL = ({
  fullURL,
  setFullURL,
  selectedWebAddressSchema,
  setSourceOfChange,
}: EnterURLProps) => {
  return (
    <Card className="pt-4 mt-6 px-5 pb-5 bg-white mb-6 min-w-fit">
      <CardHeader title="URL" />
      <div data-testid="field-web-address-schema-url">
        <Text as="div" variant="sm" className="mb-2 text-gray-70">
          Enter a URL to auto-populate the configuration below, or manually configure
        </Text>
      </div>
      <TextField
        prefix={(selectedWebAddressSchema?.scheme ?? "https") + "://"}
        prefixVariant="url"
        placeholder="api.merge.dev"
        value={fullURL}
        onChange={(newEvent) => {
          setFullURL(newEvent.target.value);
          setSourceOfChange("fullURL");
        }}
      />
    </Card>
  );
};

export default EnterURL;
