import { Accordion, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import {
  BlueprintParameterValue,
  BlueprintParameterValueCustomFunctionType,
  BlueprintParameterValueNestedParameterValues,
  BlueprintParameterValueType,
  BlueprintStep,
} from "../../../../models/Blueprints";
import { SettingsButtonToggle } from "../../../shared/MergeToggles";
import { HeaderPretitle } from "../../../shared/text/MergeText";
import useBlueprintContext from "../../context/useBlueprintContext";
import {
  BlueprintAvailableParameter,
  getUpdatedStepParameterValueForCustomFunctionChange,
  PARAMETER_TYPES,
} from "../../utils/BlueprintEditorUtils";
import Dropdown from "../Dropdown";
import FormField, { FormFieldCommonProps } from "../FormField";
import TypeaheadFormFieldTypeahead from "../TypeaheadFormFieldTypeahead";
import {
  getCurrentStepCustomJSONParameterValue,
  JSONPath,
  updateJSONNestedParameterValue,
} from "./CustomJSONUtils";
import DeprecatedH5 from "../../../deprecated/DeprecatedH5";

interface Props extends FormFieldCommonProps {
  rootParameterKey: string;
  parameterPath: JSONPath;
  parameterType: string | undefined;
}

/**
 * TypeaheadFormField used only for the CustomJSONEditorModal
 *
 * This form field requires a parameter path and the root parameter key
 * of the field we are editing in order to properly update the nested
 * parameter value structure used to represent custom arrays/objects.
 */
const CustomJSONTypeaheadFormField = <T extends BlueprintStep>({
  rootParameterKey,
  parameterPath,
  parameterType,
}: Props) => {
  const { selectedStep, updateStepParameterValue } = useBlueprintContext();
  const step = selectedStep as T;
  const currentParameterValue = getCurrentStepCustomJSONParameterValue(
    step,
    rootParameterKey,
    parameterPath
  );
  const currentCustomFunction =
    currentParameterValue && "custom_function" in currentParameterValue
      ? currentParameterValue?.custom_function
      : undefined;
  const formKey = rootParameterKey + "." + parameterPath.join(".");

  const updateParameterValue = (options: BlueprintAvailableParameter[]) => {
    const newParameterValue: BlueprintParameterValue = options[0]?.customOption
      ? {
          constant: options[0].labelKey,
          value_type: BlueprintParameterValueType.constant,
        }
      : {
          ...options[0]?.parameterValue,
          custom_function: currentCustomFunction,
        };
    const updatedRootParameterValue = updateJSONNestedParameterValue(
      step,
      rootParameterKey,
      parameterPath,
      newParameterValue
    );
    updateStepParameterValue(step, rootParameterKey, updatedRootParameterValue);
  };

  const updateCustomFunctionType = (e: any) => {
    const updatedRootParameterValue = updateJSONNestedParameterValue(
      step,
      rootParameterKey,
      parameterPath,
      getUpdatedStepParameterValueForCustomFunctionChange(
        currentParameterValue,
        e.target.value
      ) as BlueprintParameterValue
    );
    updateStepParameterValue(step, rootParameterKey, updatedRootParameterValue);
  };

  const updateNestedParameterValue = (
    options: BlueprintAvailableParameter[],
    nestedParameterKey: string
  ) => {
    const newNestedParameterValues = {
      ...(currentParameterValue as BlueprintParameterValueNestedParameterValues)
        .nested_parameter_values,
      [nestedParameterKey]:
        (options[0]?.customOption
          ? {
              constant: options[0].labelKey,
              value_type: BlueprintParameterValueType.constant,
            }
          : options[0]?.parameterValue) ?? null,
    };
    const newParameterValue = {
      ...currentParameterValue,
      custom_function: currentCustomFunction,
      nested_parameter_values: newNestedParameterValues,
    } as BlueprintParameterValue;
    const updatedRootParameterValue = updateJSONNestedParameterValue(
      step,
      rootParameterKey,
      parameterPath,
      newParameterValue
    );
    updateStepParameterValue(step, rootParameterKey, updatedRootParameterValue);
  };

  const NestedParameterValueTypeahead = () => {
    return (
      <>
        {Object.entries(
          (currentParameterValue as BlueprintParameterValueNestedParameterValues)
            .nested_parameter_values
        ).map(([nestedParameterKey, nestedParameterValue]) => (
          <>
            <DeprecatedH5 className="mt-1.5 mb-1.5">{nestedParameterKey}</DeprecatedH5>
            <TypeaheadFormFieldTypeahead
              allowConstantValues
              currentParameterValue={nestedParameterValue}
              key={nestedParameterKey}
              onChange={(options) => {
                updateNestedParameterValue(options, nestedParameterKey);
              }}
              parameterType={PARAMETER_TYPES.ANY}
            />
          </>
        ))}
      </>
    );
  };

  return (
    <FormField valueType={parameterType}>
      <Accordion className="row">
        <Col>
          <Row>
            <Col>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  {currentParameterValue?.value_type ===
                  BlueprintParameterValueType.nestedParameterValues ? (
                    <NestedParameterValueTypeahead />
                  ) : (
                    <TypeaheadFormFieldTypeahead
                      allowConstantValues
                      key={formKey}
                      currentParameterValue={currentParameterValue}
                      onChange={updateParameterValue}
                      parameterType={PARAMETER_TYPES.ANY}
                    />
                  )}
                </div>
                <div className="ml-1.5">
                  <SettingsButtonToggle eventKey="0" />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Accordion.Collapse eventKey="0">
                <Row className="mb-9">
                  <Col>
                    <HeaderPretitle className="mt-9">
                      Custom Functions
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 100, hide: 0 }}
                        overlay={
                          <Tooltip id="create-or-update-custom-function-info-tooltip">
                            Combine a create or update step and a custom function by selecting one
                            of the custom functions in the dropdown below.
                          </Tooltip>
                        }
                      >
                        <i className="ml-1.5 text-muted text-right fe fe-info float-right" />
                      </OverlayTrigger>
                    </HeaderPretitle>
                    <Dropdown
                      currentValue={currentCustomFunction?.custom_function_type}
                      onChange={updateCustomFunctionType}
                      placeholder="Select to add a custom function."
                      choices={[
                        ...Object.values(BlueprintParameterValueCustomFunctionType).map((val) => ({
                          name: val,
                          id: val,
                        })),
                        { name: "None", id: "None" },
                      ]}
                    />
                  </Col>
                </Row>
              </Accordion.Collapse>
            </Col>
          </Row>
        </Col>
      </Accordion>
    </FormField>
  );
};

export default CustomJSONTypeaheadFormField;
