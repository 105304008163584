import { HeaderPretitle } from "../../shared/text/MergeText";
import TypeaheadFormField from "./TypeaheadFormField";

const BlueprintEditorRightPanelParseFromRemoteDataForm = () => {
  return (
    <>
      <form>
        <HeaderPretitle className="my-2">Parameters</HeaderPretitle>
        <TypeaheadFormField
          title={"data"}
          subtitle={"Remote data to parse."}
          valueKey={"data"}
          parameterType={"array"}
        />
        <TypeaheadFormField
          title={"endpoint_path"}
          subtitle={"Path for endpoint providing remote_data."}
          valueKey={"endpoint_path"}
          parameterType={"string"}
        />
        <TypeaheadFormField
          title={"key_path"}
          subtitle={"Path for accessing remote data."}
          valueKey={"key_path"}
          parameterType={"string"}
        />
      </form>
    </>
  );
};

export default BlueprintEditorRightPanelParseFromRemoteDataForm;
