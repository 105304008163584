import {
  PublishModalScreenType,
  BlueprintAdvancedConfigurationsType,
  StagedComponent,
} from "../types";
import { formattedModelClassName } from "../../utils/PublishModuleUtils";
import { Text } from "@merge-api/merge-javascript-shared";

type Props = {
  setScreen: React.Dispatch<React.SetStateAction<PublishModalScreenType>>;
  stagedChanges: Record<string, StagedComponent[]> | undefined;
  blueprintAdvancedConfigurations: Record<string, BlueprintAdvancedConfigurationsType> | undefined;
  setCopyOfBlueprintAdvancedConfigurations: React.Dispatch<
    React.SetStateAction<Record<string, BlueprintAdvancedConfigurationsType> | undefined>
  >;
};

const PublishModalConfirmationScreen = ({
  setScreen,
  stagedChanges,
  blueprintAdvancedConfigurations,
  setCopyOfBlueprintAdvancedConfigurations,
}: Props) => {
  return (
    <div className="flex flex-column h-full justify-between">
      <div className="space-y-4 w-full">
        <Text>Confirm publishing these staged versions.</Text>
        <div className="w-full space-y-1">
          <div className="w-full space-y-2 p-3 rounded border border-gray-20 h-64 overflow-scroll">
            {Object.entries(stagedChanges ?? {}).map(([modelClassName, stagedComponents]) => {
              return (
                <>
                  <Text variant="h6">{formattedModelClassName(modelClassName)}</Text>
                  {stagedComponents.map((stagedComponent) => {
                    return (
                      <div className="flex flex-row w-full justify-between space-x-6">
                        <Text className="truncate w-[420px]">{stagedComponent.name}</Text>
                        {blueprintAdvancedConfigurations &&
                          blueprintAdvancedConfigurations?.[stagedComponent.component_version_id]
                            ?.reset_timestamps_on_publish && (
                            <Text className="text-red-50">Timestamps will reset</Text>
                          )}
                      </div>
                    );
                  })}
                </>
              );
            })}
          </div>
          <div
            onClick={() => {
              setCopyOfBlueprintAdvancedConfigurations(blueprintAdvancedConfigurations);
              setScreen(PublishModalScreenType.CONFIGURATION);
            }}
          >
            <Text className="text-blue-40 cursor-pointer">Advanced Configurations</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublishModalConfirmationScreen;
