import { Text } from "@merge-api/merge-javascript-shared";
import { AdditionalInfoDisplayProps } from "./types";

const StaleParamsAdditionalInfoDisplay = ({ data }: AdditionalInfoDisplayProps) => {
  const { stale_parameters } = data;

  return (
    <div>
      {Object.entries(stale_parameters).map(([key, value]) => (
        <div key={key}>
          <Text variant="h6" className="mr-2">
            {`• ${key}`}
          </Text>
          <div>
            {Array.isArray(value) ? (
              value.map((v) => (
                <div>
                  <Text>{JSON.stringify(v)}</Text>
                </div>
              ))
            ) : (
              <Text>{JSON.stringify(value)}</Text>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StaleParamsAdditionalInfoDisplay;
