interface DescriptionCellProps {
  description: string | undefined;
}

const DescriptionCell = ({ description }: DescriptionCellProps) => {
  return (
    <td>
      <div className="text-gray-90 text-base font-normal">{description ?? "No description"}</div>
    </td>
  );
};
export default DescriptionCell;
