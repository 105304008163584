import clsx from "clsx";

import {
  abbreviationForAPICategory,
  AdminAPICategory,
  Badge,
  colorForAPICategory,
} from "@merge-api/merge-javascript-shared";
import useIntegrationBuilderContext from "../context/useIntegrationBuilderContext";

interface CategoryStatusBadgeProps {
  category: AdminAPICategory;
}

const SimpleCategoryCell = ({ category }: CategoryStatusBadgeProps) => {
  const categoryColor = colorForAPICategory(category); // Ensure this function is imported
  const categoryAbbreviation = abbreviationForAPICategory(category); // Ensure this function is imported
  const { markedForDeletion } = useIntegrationBuilderContext();

  return (
    <Badge className={clsx("items-center")} color={markedForDeletion ? "gray" : categoryColor}>
      {categoryAbbreviation}
    </Badge>
  );
};

export default SimpleCategoryCell;
