import { useRef } from "react";
import { FileParameterData } from "../../models/Entities";

import FormField, { FormFieldCommonProps } from "../blueprint-editor/right-panel/FormField";

interface Props extends FormFieldCommonProps {
  getFileData: (fileData: FileParameterData) => void;
  onError: (error: ProgressEvent) => void;
  parameter: string;
}

const FileUploadWithEncodingFormField = ({
  getFileData,
  onError,
  parameter,
  subtitle,
  title,
  example,
}: Props) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const onChange = () => {
    if (fileInput.current?.files && fileInput.current.files.length === 1) {
      const file = fileInput.current.files[0];
      toBase64(file)
        .then((data) =>
          getFileData({
            param_key: parameter,
            filename: file.name,
            base64_data: data,
          })
        )
        .catch((error) => onError(error));
    }
  };

  const toBase64 = (file: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error: ProgressEvent) => reject(error);
    });

  return (
    <FormField title={title} subtitle={subtitle} example={example}>
      <input type="file" ref={fileInput} placeholder={parameter} onChange={onChange} />
    </FormField>
  );
};

export default FileUploadWithEncodingFormField;
