import { Badge, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { StepCardStatusBadgeProps, StepCardStatusBadgeTypeEnum } from "./models/StepCardModels";
import { CircleAlert } from "lucide-react";

interface Props {
  title: string;
  statusBadgeProps?: StepCardStatusBadgeProps | undefined;
}

const StepCardHeader = ({ title, statusBadgeProps }: Props) => {
  return (
    <div className="flex flex-row justify-between items-center w-full">
      <Tooltip
        title={<Text variant="h6">{title}</Text>}
        placement="left"
        tooltipClassName="p-2"
        className="truncate"
      >
        <Text variant="h6" className="truncate">
          {title}
        </Text>
      </Tooltip>
      {statusBadgeProps && (
        <Tooltip title={statusBadgeProps.tooltipTitle} placement="right">
          <Badge
            size="sm"
            color={statusBadgeProps.type === StepCardStatusBadgeTypeEnum.ERROR ? "red" : "yellow"}
            className="w-fit"
          >
            <div className="flex flex-row space-x-1 items-center">
              <CircleAlert size={12} />
              <Text variant="title-sm">{statusBadgeProps.badgeTitle}</Text>
            </div>
          </Badge>
        </Tooltip>
      )}
    </div>
  );
};

export default StepCardHeader;
