import ClickableContainer from "../../shared/ClickableContainer";
import { Text } from "@merge-api/merge-javascript-shared";
import { MinusCircle } from "lucide-react";
import { BlueprintParameterValue, BlueprintParameterValueType } from "../../../models/Blueprints";
import NestedTypeaheadFormField from "./NestedTypeaheadFormField";

interface Props {
  item: BlueprintParameterValue | null;
  itemTitle: string;
  nestedParameterValueSubtitle?: Record<string, string>; // Map of nested parameter key to subtitle string to display for key
  index: number;
  deleteItem: (index: number) => void;
  updateItem: (
    index: number,
    parameterKey: string,
    parameterValue: BlueprintParameterValue | null
  ) => void;
}

const CancellableForm = ({
  item,
  itemTitle,
  index,
  deleteItem,
  updateItem,
  nestedParameterValueSubtitle,
}: Props) => {
  return (
    <>
      <div className="mb-1.5 d-flex align-items-center justify-content-between">
        <Text variant="h5" className="mb-0">
          {itemTitle} {index + 1}
        </Text>
        <div className="ml-1.5">
          <ClickableContainer onClick={() => deleteItem(index)}>
            <MinusCircle className="red" strokeWidth={1.5} />
          </ClickableContainer>
        </div>
      </div>
      {item?.value_type === BlueprintParameterValueType.nestedParameterValues &&
        Object.entries(item?.nested_parameter_values).map(([key, value]) => {
          return (
            <div key={key}>
              <NestedTypeaheadFormField
                title={key}
                subtitle={
                  nestedParameterValueSubtitle && key in nestedParameterValueSubtitle
                    ? nestedParameterValueSubtitle[key]
                    : ""
                }
                onChange={(newValue: BlueprintParameterValue | null) => {
                  updateItem(index, key, newValue);
                }}
                currentParameterValue={value}
              />
            </div>
          );
        })}
      <hr />
    </>
  );
};

export default CancellableForm;
