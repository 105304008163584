import { Card, Text } from "@merge-api/merge-javascript-shared";
import { useContext, useEffect } from "react";
import { PaginationType } from "../../../../models/PaginationConfigurationModels";
import CardHeader from "../../shared/CardHeader";
import CheckboxHeader from "../../shared/CheckboxHeader";
import SelectHeader from "../../shared/SelectHeader";
import TextFieldHeader from "../../shared/TextFieldHeader";
import { SelectOptionsData } from "../../utils/types";
import { PAGINATION_TYPE_SELECT_OPTIONS } from "../constants";
import PaginationConfigurationContext from "../context/PaginationConfigurationContext";

interface BasicPaginationConfigSetupOptionsProps {
  cardVariant?: "outline" | "shadow" | "none";
  isFirstPaginationConfiguration: boolean;
}

const BasicPaginationConfigSetupOptions = ({
  cardVariant,
  isFirstPaginationConfiguration,
}: BasicPaginationConfigSetupOptionsProps) => {
  const {
    name,
    setName,
    description,
    setDescription,
    paginationType,
    setPaginationType,
    isDefaultForIntegration,
    setIsDefaultForIntegration,
  } = useContext(PaginationConfigurationContext);

  const selectedPaginationType = PAGINATION_TYPE_SELECT_OPTIONS.find(
    (option) => option.value === paginationType
  );

  useEffect(() => {
    if (isFirstPaginationConfiguration) {
      setIsDefaultForIntegration(true);
    }
  }, [isFirstPaginationConfiguration, setIsDefaultForIntegration]);

  const renderPaginationTypeOption = (option: SelectOptionsData) => {
    return (
      <div className="flex flex-col">
        <Text variant="md">{option.title}</Text>
        <Text variant="sm">{option.subtitle}</Text>
      </div>
    );
  };

  const renderPaginationTypeValue = (option: SelectOptionsData) => {
    return <Text variant="md">{option.title}</Text>;
  };

  return (
    <Card className="pt-4 mt-6 px-5 pb-5 bg-white mb-6 min-w-fit" variant={cardVariant || "shadow"}>
      <CardHeader title="Pagination setup" />
      <TextFieldHeader
        dataTestID="field-pagination-config-name"
        required
        title="Configuration name"
        placeholder="Configuration name"
        hasSource={false}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextFieldHeader
        dataTestID="field-pagination-config-description"
        className="mt-6"
        title="Description"
        placeholder="Description"
        hasSource={false}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <SelectHeader
        required
        dataTestID="field-pagination-config-pagination-type"
        className="mt-6"
        title="Pagination type"
        subtitle="Select the type of pagination you want to use."
        hasSource={false}
        value={selectedPaginationType}
        renderValue={renderPaginationTypeValue}
        renderOption={renderPaginationTypeOption}
        onChange={(_: any, selectedOption: SelectOptionsData | null) => {
          if (selectedOption) {
            setPaginationType(selectedOption.value as PaginationType);
          } else {
            setPaginationType("");
          }
        }}
        options={PAGINATION_TYPE_SELECT_OPTIONS}
      />
      <CheckboxHeader
        className="mt-6"
        dataTestID="field-pagination-config-is-default-for-integration"
        title="Set this configuration as the default for this integration"
        subtitle="This pagination configuration will apply to all endpoints without individual pagination overrides. Checking this will remove default from all other configurations."
        checked={isDefaultForIntegration || isFirstPaginationConfiguration}
        onChange={setIsDefaultForIntegration}
        disabled={isFirstPaginationConfiguration}
        helpText={
          isFirstPaginationConfiguration
            ? "If no other configurations exist, this will be set as the default."
            : ""
        }
      />
    </Card>
  );
};

export default BasicPaginationConfigSetupOptions;
