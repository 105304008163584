import isEmpty from "lodash/isEmpty";
import {
  JSONObjectSchema,
  JSONSchemaGenericValue,
  JSONSchemaPrimitiveType,
  JSONSchemaValue,
} from "../../../models/Blueprints";
import { APIRequestFormat } from "../../../models/Entities";
const GenerateSchema = require("generate-schema");

const findNonNullType = (jsonSchemaTypeArray: JSONSchemaPrimitiveType[]) => {
  const nonNullType = jsonSchemaTypeArray.find((typ) => typ !== "null");
  if (nonNullType !== undefined) {
    return nonNullType;
  }
  throw new Error("JSON schema type array should always have at least one non-null type.");
};

/***
 * Given a JSONSchema generated by `GenerateSchema`, convert all type arrays
 * into single types. The `GenerateSchema` API will often times create an array
 * to specify the type of a schema value. For instance, when parsing an array
 * of objects, if `GenerateSchema` sees one string value and one null value
 * in two different entries of the array then it will deem the type to be:
 * ["string", "null"]. All of our logic is predicated on the assumption schema
 * types are a single string value, so this function's purpose is to
 * remedy this situation.
 * TODO (https://app.asana.com/0/1202372989048346/1202672794284732/f):
 * Support type arrays for JSON schemas.
 */
const recursivelyReplaceTypeArraysWithSingleNonNullType = (
  jsonObject: JSONSchemaGenericValue
): JSONSchemaValue => {
  let jsonObjectCopy = JSON.parse(JSON.stringify(jsonObject));
  if (jsonObjectCopy.type?.constructor === Array) {
    jsonObjectCopy.type = findNonNullType(jsonObjectCopy.type);
  }
  const type = jsonObjectCopy.type;
  if (type === "object") {
    for (const key in jsonObjectCopy.properties) {
      const nestedJSONValue = jsonObjectCopy.properties[key];
      jsonObjectCopy.properties[key] = recursivelyReplaceTypeArraysWithSingleNonNullType(
        nestedJSONValue
      );
    }
  } else if (type === "array") {
    jsonObjectCopy.items = recursivelyReplaceTypeArraysWithSingleNonNullType(jsonObjectCopy.items);
  }
  if (type?.constructor === Array) {
    jsonObjectCopy.type = findNonNullType(type);
  }
  return jsonObjectCopy;
};

export const getJSONSchemaFromExampleJSON = (json: string): string => {
  const jsonObject: JSONObjectSchema = GenerateSchema.json(JSON.parse(json));
  return JSON.stringify(recursivelyReplaceTypeArraysWithSingleNonNullType(jsonObject));
};

export const getURLParamJSONSchemaFromPath = (
  path: string
): {
  pathParamJSONSchema: string | undefined;
  queryParamJSONSchema: string | undefined;
} => {
  const pathSplitAtQuery = path.split("?");
  const re = /{.*?}/g;
  const pathParamJSONSchema = {
    properties: Object.fromEntries(
      pathSplitAtQuery[0].match(re)?.map((match) => {
        return [match.slice(1, -1), { type: "string" }];
      }) ?? []
    ),
  };

  //TODO: Logic for parsing query params - not high pri for now
  return {
    pathParamJSONSchema: isEmpty(pathParamJSONSchema)
      ? undefined
      : JSON.stringify(pathParamJSONSchema),
    queryParamJSONSchema: undefined,
  };
};

export const getXMLRequestBodyParamSchema = (XMLRequestBodyFormatString: string): string => {
  const re = /{.*?}/g;
  const requestBodyParam = {
    properties: Object.fromEntries(
      XMLRequestBodyFormatString.match(re)?.map((match) => {
        return [match.slice(1, -1), { type: "string" }];
      }) ?? []
    ),
  };

  return JSON.stringify(requestBodyParam);
};

export const nameFromApiRequestFormat = (format: APIRequestFormat) => {
  switch (format) {
    case APIRequestFormat.REQUEST_FORMAT_MULTIPART_FORM:
      return "multipart/form-data";
    case APIRequestFormat.REQUEST_FORMAT_OCTET_STREAM:
      return "application/octet-stream";
    default:
      return "application/json";
  }
};
