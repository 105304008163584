import { ResponseStatusBadge } from "./MergeBadges";
import MergeCodeBlock from "./MergeCodeBlock";
import { isValidJSON } from "../../utils";
import { Link, Text } from "@merge-api/merge-javascript-shared";
import { ArrowUpRight } from "lucide-react";
import { useState } from "react";
import { DiffModalTypeEnum, DiffModelTypeEnum } from "../../models/DiffModels";
import DiffModal from "./diff-view/DiffModal";

export interface TestRunResponse {
  status_code: number;
  body?: string;
  headers?: { [key: string]: string };
  request: {
    url: string;
    body?: string;
    method: string;
    headers: { [key: string]: string };
  };
}

export interface TestSelectiveSyncFilterProps {}

interface Props {
  response: TestRunResponse;
  responseWithoutSelectiveSyncFilters?: TestRunResponse | undefined;
}

const TestRunResponseBlock = ({ response, responseWithoutSelectiveSyncFilters }: Props) => {
  const [isShowingDiffModal, setIsShowingDiffModal] = useState<boolean>(false);
  const requestHeaders = JSON.stringify(response?.request.headers, null, 2);

  let requestBody: string = response?.request.body ?? "{}";
  let stringifiedRequestBody: string;
  try {
    stringifiedRequestBody =
      requestBody !== undefined && isValidJSON(requestBody)
        ? JSON.stringify(JSON.parse(requestBody), null, 2)
        : requestBody || "Empty";
  } catch (error) {
    stringifiedRequestBody = "Error when parsing JSON";
  }

  let responseBody: string = response?.body ?? "{}";
  let stringifiedResponseBody: string;
  try {
    stringifiedResponseBody =
      responseBody !== undefined && isValidJSON(responseBody)
        ? JSON.stringify(JSON.parse(responseBody), null, 2)
        : responseBody || "Empty";
  } catch (error) {
    stringifiedResponseBody = "Error when parsing JSON";
  }

  const responseHeaders = JSON.stringify(response?.headers, null, 2);

  return (
    <>
      {isShowingDiffModal && responseWithoutSelectiveSyncFilters && (
        <DiffModal
          isModalOpen={isShowingDiffModal}
          setIsModalOpen={setIsShowingDiffModal}
          diffModalType={DiffModalTypeEnum.COMPARE}
          modelType={DiffModelTypeEnum.DEFAULT}
          newState={JSON.parse(responseWithoutSelectiveSyncFilters?.body ?? "{}")}
          newStateTitle={`Response without Selective Sync Filter`}
          currentState={JSON.parse(responseBody) ?? {}}
          currentStateTitle={`Response with Selective Sync Filter applied`}
        />
      )}
      <hr />
      <div className="flex flex-col space-y-4">
        <div className="flex flex-row items-center justify-between">
          <Text variant="h4">API request log</Text>
          {responseWithoutSelectiveSyncFilters && (
            <Link>
              <div
                className="flex flex-row items-center space-x-1"
                onClick={() => setIsShowingDiffModal(true)}
              >
                <Text variant="h6">Compare without filter</Text>
                <ArrowUpRight size={16} />
              </div>
            </Link>
          )}
        </div>
        <div className="flex flex-row items-start justify-between space-x-2">
          <div className="flex flex-row items-start justify-start space-x-2 truncate w-full">
            <Text variant="h6" className="uppercase">
              {response.request.method}
            </Text>
            <Text className="text-wrap break-all">{response.request.url}</Text>
          </div>
          <ResponseStatusBadge responseCode={response.status_code} size="lg" />
        </div>
        <MergeCodeBlock
          title="Request headers"
          textToCopy={requestHeaders}
          children={requestHeaders}
        />
        <MergeCodeBlock
          title="Request body"
          textToCopy={stringifiedRequestBody}
          children={stringifiedRequestBody}
        />
        <MergeCodeBlock
          title="Response headers"
          textToCopy={responseHeaders}
          children={responseHeaders}
        />
        <MergeCodeBlock
          title="Response body"
          showLineNumbers={true}
          textToCopy={stringifiedResponseBody}
          children={stringifiedResponseBody}
          customStyle={{
            lineHeight: "4px",
          }}
        />
      </div>
    </>
  );
};

/**
 *  {
        <MergeCodeBlock
          showLineNumbers={true}
          customStyle={{
            lineHeight: "4px",
          }}
          textToCopy={stringifiedResponseBody}
        >
          {stringifiedResponseBody}
        </MergeCodeBlock>
      }
 */

export default TestRunResponseBlock;
