import CardHeader from "../../../shared/CardHeader";
import { Button, ButtonVariant, Card, Text } from "@merge-api/merge-javascript-shared";
import {
  AuthConfigIntegrationBuilder,
  IntegrationSetupChecklistItem,
  LinkChoiceStepOption,
  LinkingFlowStepPathIntegrationBuilder,
} from "../../../utils/Entities";
import Callout from "../../../shared/Callout";
import ConfigureStepCard from "../step/ConfigureStepCard";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import { addStep, deleteStep, onDragEnd, updateStep } from "../../utils/helpers";
import { useCallback, useContext, useState } from "react";
import IntegrationBuilderContext from "../../../context/IntegrationBuilderContext";
import { Plus } from "lucide-react";

interface ConfigureStepsSectionProps {
  authConfigs: AuthConfigIntegrationBuilder[] | undefined;
  integrationSetupChecklistItems: IntegrationSetupChecklistItem[] | undefined;
  setRequestedStepPath: React.Dispatch<
    React.SetStateAction<LinkingFlowStepPathIntegrationBuilder | undefined>
  >;
  requestedStepPath: LinkingFlowStepPathIntegrationBuilder;
  selectedStepPath: LinkingFlowStepPathIntegrationBuilder | undefined;
  requestedLinkChoiceStepOptions: LinkChoiceStepOption[];
  setRequestedLinkChoiceStepOptions: React.Dispatch<React.SetStateAction<LinkChoiceStepOption[]>>;
}

const ConfigureStepsSection = ({
  authConfigs,
  integrationSetupChecklistItems,
  setRequestedStepPath,
  requestedStepPath,
  selectedStepPath,
  requestedLinkChoiceStepOptions,
  setRequestedLinkChoiceStepOptions,
}: ConfigureStepsSectionProps) => {
  // Apply callback on update & delete functions, to prevent unnecessary re-rendering
  const onCallbackUpdateStep = useCallback(
    (index: number, keyValuePairs: Record<string, any>) => {
      updateStep(index, setRequestedStepPath, keyValuePairs);
    },
    [setRequestedStepPath]
  );

  const onCallbackDeleteStep = useCallback(
    (index: number) => {
      deleteStep(index, setRequestedStepPath);
    },
    [setRequestedStepPath]
  );

  // Get integration name for steps
  const { integration } = useContext(IntegrationBuilderContext);

  const [activeStepIndex, setActiveStepIndex] = useState<number | undefined>();

  return (
    <Card className="pt-4 px-5 pb-5 bg-white mb-6 min-w-fit">
      <CardHeader title="Configure steps" />
      <div className="space-y-6 pt-1">
        {(!selectedStepPath?.steps || selectedStepPath?.steps.length == 0) && (
          <Callout
            title="What is a Merge Link step?"
            description="A step represents an individual screen in the linking flow. The order of the steps shown below will determine the order in which an end user will move through the flow."
          />
        )}
        <div className="space-y-3">
          {requestedStepPath.steps.length > 0 && (
            <Text variant="md" className="text-gray-70">
              Drag to reorder steps
            </Text>
          )}
          <div className="space-y-6">
            <DragDropContext
              onDragEnd={(result) => onDragEnd(result, requestedStepPath, setRequestedStepPath)}
            >
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-6">
                    {requestedStepPath &&
                      requestedStepPath.steps.length > 0 &&
                      requestedStepPath.steps.map((requestedStep, index) => {
                        return (
                          <div onClick={() => setActiveStepIndex(index)}>
                            <ConfigureStepCard
                              key={index}
                              index={index}
                              authConfigs={authConfigs}
                              integrationName={integration?.name}
                              integrationID={integration?.id}
                              requestedStepPathAuthConfigID={
                                requestedStepPath.auth_configuration_id
                              }
                              integrationSetupChecklistItems={integrationSetupChecklistItems}
                              requestedLinkChoiceStepOptions={requestedLinkChoiceStepOptions}
                              setRequestedLinkChoiceStepOptions={setRequestedLinkChoiceStepOptions}
                              onCallbackUpdateStep={onCallbackUpdateStep}
                              onCallbackDeleteStep={onCallbackDeleteStep}
                              requestedStep={requestedStep}
                              isExpanded={index === activeStepIndex}
                            />
                          </div>
                        );
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Button
              leftIcon={<Plus size={16} />}
              variant={ButtonVariant.TertiaryWhite}
              onClick={() => addStep(requestedStepPath, setRequestedStepPath)}
            >
              <div data-testid="button-step-path-add-step">Step</div>
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ConfigureStepsSection;
