import { Badge, ButtonVariant, Dropdown, MenuItem, Text } from "@merge-api/merge-javascript-shared";
import clsx from "clsx";
import { Ellipsis } from "lucide-react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  BlueprintVersion,
  BlueprintVersionPublishState,
  BlueprintWithTrigger,
} from "../../../../models/Blueprints";
import { DiffModalTypeEnum, DiffModelTypeEnum } from "../../../../models/DiffModels";
import {
  navigateToBlueprintEditor,
  navigateToIntegrationBuilderSelectiveSyncFilterBuilder,
  navigateToIntegrationBuilderSelectiveSyncFilterBuilderForId,
  navigateToMappingTestEditor,
  navigateToScraperEditor,
} from "../../../../router/RouterUtils";
import {
  fetchBlueprintVersionForIntegration,
  fetchBlueprintVersions,
} from "../../../blueprint-editor/utils/BlueprintEditorAPIClient";
import DiffModal from "../../../shared/diff-view/DiffModal";
import { useStagedComponentContext } from "../context/StagedComponentContext";
import {
  IntegrationComponentType,
  IntegrationComponentTypeToNameMap,
  ValidatedStagedComponent,
} from "../types";
import UnstageModal from "./UnstageModal";
import { usePublishModuleContext } from "../context/PublishModuleContext";

type Props = {
  className?: string;
};

type MenuButtonProps = {
  title: string;
  onView: () => void;
};

const getMenuButtonProps = (
  integrationID: string,
  stagedComponent: ValidatedStagedComponent,
  history: any
): MenuButtonProps | null => {
  switch (stagedComponent.component_type) {
    case IntegrationComponentType.BLUEPRINT:
      return {
        title: "View blueprint",
        onView: () =>
          navigateToBlueprintEditor(
            history,
            integrationID,
            stagedComponent.component_version_id,
            true
          ),
      };
    case IntegrationComponentType.MAPPING_TEST:
      return {
        title: "View mapping test",
        onView: () =>
          navigateToMappingTestEditor(history, integrationID, stagedComponent.component_id, true),
      };
    case IntegrationComponentType.SCRAPER:
      return {
        title: "View scraper",
        onView: () =>
          navigateToScraperEditor(
            history,
            integrationID,
            stagedComponent.component_id,
            stagedComponent.component_version_id,
            true
          ),
      };
    case IntegrationComponentType.SELECTIVE_SYNC_FILTER_SCHEMA:
      return {
        title: "View filter",
        onView: () =>
          navigateToIntegrationBuilderSelectiveSyncFilterBuilderForId(
            history,
            integrationID,
            true,
            stagedComponent.component_version_id
          ),
      };
    case IntegrationComponentType.API_ENDPOINT_PARAMETER:
      return {
        title: "Navigate to Selective Sync filters",
        onView: () =>
          navigateToIntegrationBuilderSelectiveSyncFilterBuilder(history, integrationID, true),
      };
    default:
      return null;
  }
};

const StagedComponentVersionCardTitle = ({ className }: Props) => {
  const { refetchPublishModuleInfo } = usePublishModuleContext();
  const {
    component,
    integrationID,
    newBlockingRules: blockingRules,
    newWarningRules: warningRules,
  } = useStagedComponentContext();
  const history = useHistory();
  const menuButtonProps = getMenuButtonProps(integrationID, component, history);

  // States for diff
  const [isDiffModalOpen, setIsDiffModalOpen] = useState<boolean>(false);
  const [isLoadingCurrentStateForDiff, setIsLoadingCurrentStateForDiff] = useState<boolean>(false);
  const [isLoadingNewStateForDiff, setIsLoadingNewStateForDiff] = useState<boolean>(false);
  const [blueprintVersions, setBlueprintVersions] = useState<BlueprintVersion[] | undefined>();
  const [currentStateForDiff, setCurrentStateForDiff] = useState<{ [key: string]: any }>({});
  const [newStateForDiff, setNewStateForDiff] = useState<{ [key: string]: any }>({});
  const [currentStateTitle, setCurrentStateTitle] = useState<string>();
  const [newStateTitle, setNewStateTitle] = useState<string>();

  // States for unstaging
  const [isUnstageModalOpen, setIsUnstageModalOpen] = useState<boolean>(false);

  // Open diff modal and load staged & published Blueprint version JSON's
  const onOpenBlueprintDiffModal = () => {
    setIsDiffModalOpen(true);
    setIsLoadingCurrentStateForDiff(true);
    setIsLoadingNewStateForDiff(true);
    fetchBlueprintVersions({
      blueprintID: component.component_id,
      onSuccess: (versions: BlueprintVersion[]) => {
        setBlueprintVersions(versions);
      },
    });
  };

  // Loads published & staged Blueprint versions, for diff modal
  useEffect(() => {
    if (
      !(blueprintVersions ?? []).find(
        (version) => version.publish_state === BlueprintVersionPublishState.Published
      )
    ) {
      setIsLoadingCurrentStateForDiff(false);
    }
    (blueprintVersions ?? []).forEach((version) => {
      if (version.publish_state === BlueprintVersionPublishState.Staged) {
        fetchBlueprintVersionForIntegration({
          integrationID: integrationID,
          blueprintVersionID: version.id,
          onSuccess: (response: BlueprintWithTrigger) => {
            const { schedule, ...blueprint } = response;
            setNewStateForDiff(blueprint);
            setNewStateTitle(`Blueprint Version - "${version.comment}"`);
            setIsLoadingNewStateForDiff(false);
          },
        });
      } else if (version.publish_state === BlueprintVersionPublishState.Published) {
        fetchBlueprintVersionForIntegration({
          integrationID: integrationID,
          blueprintVersionID: version.id,
          onSuccess: (response: BlueprintWithTrigger) => {
            const { schedule, ...blueprint } = response;
            setCurrentStateForDiff(blueprint);
            setCurrentStateTitle(`Blueprint Version - "${version.comment}"`);
            setIsLoadingCurrentStateForDiff(false);
          },
        });
      }
    });
  }, [blueprintVersions]);

  return (
    <div className={clsx(className, "flex flex-row items-center w-full justify-between")}>
      <div>
        <div className="flex flex-row items-center space-x-2">
          <Text variant="h5" className="p-0 m-0">
            {component.name}
            {blockingRules.length > 0 && (
              <Badge className="mx-2" color="red">
                {blockingRules.length.toString() +
                  (blockingRules.length > 1 ? " failures" : " failure")}
              </Badge>
            )}
            {warningRules.length > 0 && (
              <Badge className="mx-2" color="yellow">
                {warningRules.length.toString() +
                  (warningRules.length > 1 ? " warnings" : " warning")}
              </Badge>
            )}
          </Text>
        </div>
        <Text className="text-gray-70">{component.comment}</Text>
      </div>
      <div>
        {menuButtonProps && (
          <Dropdown
            ButtonProps={{
              children: <Ellipsis className="text-gray-60" size={16} />,
              variant: ButtonVariant.IconShadowHidden,
            }}
            menuPlacement="bottom-end"
          >
            {component.component_type === IntegrationComponentType.BLUEPRINT && (
              <MenuItem onClick={onOpenBlueprintDiffModal}>Compare versions</MenuItem>
            )}
            <MenuItem onClick={menuButtonProps.onView}>{menuButtonProps.title}</MenuItem>
            <MenuItem onClick={() => setIsUnstageModalOpen(true)}>Unstage</MenuItem>
          </Dropdown>
        )}
      </div>
      {isDiffModalOpen && (
        <DiffModal
          currentState={currentStateForDiff}
          currentStateTitle={currentStateTitle}
          newState={newStateForDiff}
          newStateTitle={newStateTitle}
          modelType={DiffModelTypeEnum.BLUEPRINT}
          isLoadingStates={isLoadingCurrentStateForDiff || isLoadingNewStateForDiff}
          isModalOpen={isDiffModalOpen}
          setIsModalOpen={setIsDiffModalOpen}
          diffModalType={DiffModalTypeEnum.COMPARE}
        />
      )}
      {isUnstageModalOpen && (
        <UnstageModal
          open={isUnstageModalOpen}
          onClose={() => setIsUnstageModalOpen(false)}
          modelClassName={IntegrationComponentTypeToNameMap[component.component_type]}
          stagedComponent={component}
          onRefreshAllChecks={refetchPublishModuleInfo}
          integrationID={integrationID}
        />
      )}
    </div>
  );
};

export default StagedComponentVersionCardTitle;
