import {
  DiffState,
  DiffStateField,
  DiffStateFieldTypeEnum,
} from "../../../../../models/DiffModels";
import { convertValueToString, getAllKeysInBothCurrentAndNew, getDiffStatus } from "../helpers";

/**
 * This function generates the diff state for property details by comparing the current and new property details.
 * Most of the time, the detail will have a "type", and look something like {"employee_id": {"type": "string"}}
 * However, there are malformed ones that will not have a "type", like {"employee_id": "string"}
 * @param {object} currentPropertyDetails - The current property details.
 * @param {object} newPropertyDetails - The new property details.
 * @param {boolean} isRenderCurrentAsEmpty - Flag to render the current state as empty.
 * @param {boolean} isRenderNewAsEmpty - Flag to render the new state as empty.
 *
 * @returns {DiffState[]} - An array of DiffStateField objects representing the differences.
 */
export const generateDiffStateForPropertyDetails = (
  currentPropertyDetails: { [key: string]: string },
  newPropertyDetails: { [key: string]: string },
  isRenderCurrentAsEmpty: boolean = false,
  isRenderNewAsEmpty: boolean = false
): DiffState => {
  // Validate details are objects, otherwise will break
  // ie.: Some malformed properties are defined like {"user_id": "string"} instead of {"user_id": {"type": "string"}}
  if (!isValidPropertyDetails(currentPropertyDetails, newPropertyDetails)) return [];

  let diffStatePropertyDetails: DiffState = [];

  const allDetailKeys = getAllKeysInBothCurrentAndNew(currentPropertyDetails, newPropertyDetails);
  allDetailKeys.forEach((detailKey) => {
    const currentValue =
      detailKey in currentPropertyDetails
        ? convertValueToString(currentPropertyDetails[detailKey])
        : "";
    const newValue =
      detailKey in newPropertyDetails ? convertValueToString(newPropertyDetails[detailKey]) : "";
    const diffStateFieldOfDetail: DiffStateField = {
      type: DiffStateFieldTypeEnum.FIELD,
      name: detailKey,
      displayName: detailKey,
      currentValue: currentValue,
      newValue: newValue,
      diffStatus: getDiffStatus(currentValue, newValue),
      isRenderCurrentAsEmpty: isRenderCurrentAsEmpty,
      isRenderNewAsEmpty: isRenderNewAsEmpty,
    };
    diffStatePropertyDetails.push(diffStateFieldOfDetail);
  });

  return diffStatePropertyDetails;
};

/**
 * Generates currentValue, newValue, and childDiffStateFields for a field's property
 * Especially helpful for handling {"user_id": "string"} vs. {"user_id": {"type": "string"}}
 * @param {object} currentPropertyDetails - The current property details to process.
 * @param {object} newPropertyDetails - The new property details to process.
 * @param {boolean} isRenderCurrentAsEmpty - Flag to render the current state as empty.
 * @param {boolean} isRenderNewAsEmpty - Flag to render the new state as empty.
 * @returns {[string, string, DiffState]} - A tuple containing the current value, new value, and the diff state.
 */
export const processDiffForPropertyDetails = (
  currentPropertyDetails: { [key: string]: any },
  newPropertyDetails: { [key: string]: any },
  isRenderCurrentAsEmpty: boolean = false,
  isRenderNewAsEmpty: boolean = false
): [string, string, DiffState] => {
  let currentValue: string = "";
  let newValue: string = "";
  let childDiffState: DiffState = [];

  if (isValidPropertyDetails(currentPropertyDetails, newPropertyDetails)) {
    childDiffState = generateDiffStateForPropertyDetails(
      currentPropertyDetails,
      newPropertyDetails,
      isRenderCurrentAsEmpty,
      isRenderNewAsEmpty
    );
  } else {
    currentValue = convertValueToString(currentPropertyDetails);
    newValue = convertValueToString(newPropertyDetails);
  }

  return [currentValue, newValue, childDiffState];
};

/**
 * Validates whether a parameter's property schema is valid
 * ie.: {"user_id": "string"} is invalid
 * ie.: {"user_id": {"type": "string"}} is valid
 * @param {object} currentPropertyDetails - The current property details to validate.
 * @param {object} newPropertyDetails - The new property details to validate.
 * @returns {boolean} - Returns true if the property details are valid, otherwise false.
 */
export const isValidPropertyDetails = (
  currentPropertyDetails: { [key: string]: any },
  newPropertyDetails: { [key: string]: any }
) => {
  return typeof currentPropertyDetails === "object" && typeof newPropertyDetails === "object";
};
