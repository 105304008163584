import styled from "styled-components";

const DotImage = styled.div`
  display: inline-block;
  background: #95aac9;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 4px;
`;

const Dot = () => {
  return (
    <span>
      <DotImage />
    </span>
  );
};

export default Dot;
