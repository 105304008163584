import clsx from "clsx";
import { FocusEventHandler } from "react";
import ClickableText from "./ClickableText";
import KeyPathField from "./KeyPathField";
import HeaderBase from "./HeaderBase";

interface KeyPathFieldHeaderProps {
  title?: string;
  subtitle?: string;
  learnMoreText?: string;
  learnMoreTextComponent?: React.ReactNode;
  placeholder?: string;
  className?: string;
  prefix?: string;
  prefixVariant?: "inline" | "url" | undefined;
  keyPath?: string[] | null;
  onKeyPathChange?: (keyPath: string[] | null) => void;
  name?: string;
  ref?: React.Ref<HTMLInputElement>;
  error?: boolean;
  errorText?: string;
  type?: React.HTMLInputTypeAttribute;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  helpText?: string;
  required?: boolean;
  hasSource?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  autosize?: boolean;
  bottomLinkText?: string;
  bottomLinkOnClick?: () => void;
  showArrow?: boolean;
  dataTestID?: string;
}

const KeyPathFieldHeader = ({
  title,
  subtitle,
  learnMoreText,
  learnMoreTextComponent,
  placeholder,
  className,
  prefixVariant = "inline",
  keyPath,
  onKeyPathChange,
  name,
  ref,
  error,
  errorText,
  type,
  onBlur,
  helpText,
  required,
  hasSource = false,
  multiline = false,
  disabled,
  autosize,
  bottomLinkText,
  bottomLinkOnClick,
  showArrow,
  dataTestID,
}: KeyPathFieldHeaderProps) => {
  return (
    <div className={clsx("w-full", className)}>
      <HeaderBase
        title={title}
        subtitle={subtitle}
        learnMoreText={learnMoreText}
        learnMoreTextComponent={learnMoreTextComponent}
        helpText={helpText}
        required={required}
        hasSource={hasSource}
        dataTestID={dataTestID}
      />
      <KeyPathField
        keyPath={keyPath}
        onKeyPathChange={onKeyPathChange}
        prefixVariant={prefixVariant}
        placeholder={placeholder}
        name={name}
        ref={ref}
        error={error}
        errorText={errorText}
        type={type}
        onBlur={onBlur}
        disabled={disabled}
        multiline={multiline}
        autosize={autosize}
        showArrow={showArrow}
      />
      {bottomLinkText && bottomLinkOnClick && (
        <ClickableText text={bottomLinkText} onClick={bottomLinkOnClick} className="mt-2" />
      )}
    </div>
  );
};

export default KeyPathFieldHeader;
