import { useCallback, useRef } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import {
  AsyncAutogeneratedReadMappingTestRun,
  AutogeneratedReadMappingTestRunStatus,
} from "../../../../models/MappingTests";

interface usePollAutogeneratedReadTestProps {
  setGeneratedReadTestRun: React.Dispatch<
    React.SetStateAction<AsyncAutogeneratedReadMappingTestRun | null>
  >;
  setHasPollingBeenTriggered: React.Dispatch<React.SetStateAction<boolean>>;
}

export const usePollAutogeneratedReadTest = ({
  setGeneratedReadTestRun,
  setHasPollingBeenTriggered,
}: usePollAutogeneratedReadTestProps) => {
  const intervalId = useRef<NodeJS.Timeout | null>(null);

  const pollAutogeneratedReadTest = useCallback(
    (mappingTestSuiteID: string, autogeneratedTestRunID: string) => {
      const poll = () => {
        fetchWithAuth({
          path: `/stallions/integrations/test-suite/${mappingTestSuiteID}/autogenerated-read-test-run/${autogeneratedTestRunID}`,
          method: "GET",
          onResponse: (data) => {
            setGeneratedReadTestRun(data);
            setHasPollingBeenTriggered(true);

            // Check if the data status is one of the specified statuses
            if (
              [
                AutogeneratedReadMappingTestRunStatus.FINISHED_WITH_SUCCESS,
                AutogeneratedReadMappingTestRunStatus.FINISHED_WITH_FAILURES,
                AutogeneratedReadMappingTestRunStatus.FINISHED_WITH_EXCEPTIONS,
                AutogeneratedReadMappingTestRunStatus.ABORTED,
              ].includes(data.status)
            ) {
              // If true, stop polling
              if (intervalId.current !== null) {
                clearInterval(intervalId.current);
                intervalId.current = null; // Clear the interval ID
              }
            }
          },
          onError: () => {},
        });
      };

      // Initial call to poll
      poll();
      // Setup to poll every 5 seconds
      intervalId.current = setInterval(poll, 5000);

      return () => {
        if (intervalId.current !== null) {
          clearInterval(intervalId.current);
        }
      };
    },
    [setGeneratedReadTestRun, setHasPollingBeenTriggered]
  );

  return {
    pollAutogeneratedReadTest,
  };
};
