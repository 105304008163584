import { useEffect, useState } from "react";
import IntegrationBuilderHeader from "../shared/IntegrationBuilderHeader";
import { IntegrationForIntegrationBuilder, IntegrationLogos } from "../utils/Entities";
import useIntegrationBuilderContext from "../context/useIntegrationBuilderContext";
import useCreateOrPatchIntegration from "../hooks/useCreateOrPatchIntegration";
import EmptyStateWrapper from "../../shared/layout/EmptyStateWrapper";
import MarketingPageConfigurationSection from "./components/MarketingPageConfigurationSection";
import DocsConfigurationSection from "./components/DocsConfigurationSection";
import DashboardConfigurationSection from "./components/DashboardConfigurationSection";
import { DiffModelTypeEnum } from "../../../models/DiffModels";
import EditorLeavingGuard from "../../shared/unsaved-changes/EditorLeavingGuard";
import LogosSection from "./components/LogosSection";
import { getLastStringOfUrlPath } from "../utils/helpers";

interface Props {
  integrationID: string;
}

const CustomerFacingFields = ({ integrationID }: Props) => {
  // context
  const {
    setOnSubmit,
    integration,
    isLoading,
    currentStateForDiff,
    setCurrentStateForDiff,
    setNewStateForDiff,
    computeHasUnsavedChanges,
  } = useIntegrationBuilderContext();

  const [requestBodyForIntegration, setRequestBodyForIntegration] = useState<
    IntegrationForIntegrationBuilder | undefined
  >();
  const [requestBodyForLogos, setRequestBodyForLogos] = useState<IntegrationLogos>({});
  const { isLoadingCreateOrPatchIntegration, patchIntegration } = useCreateOrPatchIntegration({
    requestBodyForIntegration,
    requestBodyForLogos,
  });

  // sets submitText, canSubmit, isLoadingSubmit, onSubmit
  useIntegrationBuilderContext({
    submitButtonText: "Save integration",
    canSubmit: true,
    isLoadingSubmit: isLoadingCreateOrPatchIntegration,
    shouldRenderSubmitButton: true,
    shouldRenderNavigationButtons: true,
    shouldRenderDeleteButton: false,
    modelTypeForDiff: DiffModelTypeEnum.CUSTOMER_FACING_FIELDS,
    shouldHideDiffModal: false,
    markForDeletion: false,
    shouldRenderStageButton: false,
  });

  /* initialize request body & diff states
   * When adding/removing fields, you must update helpers-customer-facing-fields.ts
   * Otherwise diff modal will display outdated fields
   */
  useEffect(() => {
    const requestBody = {
      color: integration?.color,
      webhook_setup_guide_url: integration?.webhook_setup_guide_url || null,
      notes_for_customers: integration?.notes_for_customers || null,
    };
    setRequestBodyForIntegration(requestBody);
    const initialDiffStateWithLogos = {
      ...requestBody,
      image: integration?.image ? getLastStringOfUrlPath(integration?.image) : "",
      square_image: integration?.square_image
        ? getLastStringOfUrlPath(integration?.square_image)
        : "",
      color_image: integration?.color_image ? getLastStringOfUrlPath(integration?.color_image) : "",
      white_image: integration?.white_image ? getLastStringOfUrlPath(integration?.white_image) : "",
      light_grey_image: integration?.light_grey_image
        ? getLastStringOfUrlPath(integration?.light_grey_image)
        : "",
      dark_grey_image: integration?.dark_grey_image
        ? getLastStringOfUrlPath(integration?.dark_grey_image)
        : "",
      black_image: integration?.black_image ? getLastStringOfUrlPath(integration?.black_image) : "",
      social_banner: integration?.social_banner
        ? getLastStringOfUrlPath(integration?.social_banner)
        : "",
    };
    setCurrentStateForDiff(initialDiffStateWithLogos);
    setNewStateForDiff(initialDiffStateWithLogos);
  }, [integration]);

  // set submit button state based on current values
  useEffect(() => {
    setOnSubmit(() => patchIntegration(integrationID));
  }, [integrationID, requestBodyForIntegration, requestBodyForLogos]);

  // Update diff state
  useEffect(() => {
    const changedDiffState = {
      ...requestBodyForIntegration,
      image: requestBodyForLogos.image
        ? requestBodyForLogos.image.name
        : currentStateForDiff?.image,
      square_image: requestBodyForLogos.square_image
        ? requestBodyForLogos.square_image.name
        : currentStateForDiff?.square_image,
      color_image: requestBodyForLogos.color_image
        ? requestBodyForLogos.color_image.name
        : currentStateForDiff?.color_image,
      white_image: requestBodyForLogos.white_image
        ? requestBodyForLogos.white_image.name
        : currentStateForDiff?.white_image,
      light_grey_image: requestBodyForLogos.light_grey_image
        ? requestBodyForLogos.light_grey_image.name
        : currentStateForDiff?.light_grey_image,
      dark_grey_image: requestBodyForLogos.dark_grey_image
        ? requestBodyForLogos.dark_grey_image.name
        : currentStateForDiff?.dark_grey_image,
      black_image: requestBodyForLogos.black_image
        ? requestBodyForLogos.black_image.name
        : currentStateForDiff?.black_image,
      social_banner: requestBodyForLogos.social_banner
        ? requestBodyForLogos.social_banner.name
        : currentStateForDiff?.social_banner,
    };
    setNewStateForDiff(changedDiffState);
  }, [requestBodyForIntegration, requestBodyForLogos]);

  return (
    <EditorLeavingGuard computeHasUnsavedChanges={computeHasUnsavedChanges}>
      <div>
        <IntegrationBuilderHeader title="Customer-facing fields" showRequired />
        {isLoading ? (
          <EmptyStateWrapper isSpinner />
        ) : (
          <div>
            <MarketingPageConfigurationSection
              requestBodyForIntegration={requestBodyForIntegration}
              setRequestBodyForIntegration={setRequestBodyForIntegration}
            />
            <LogosSection
              integration={integration}
              setRequestBodyForLogos={setRequestBodyForLogos}
            />
            <DocsConfigurationSection
              requestBodyForIntegration={requestBodyForIntegration}
              setRequestBodyForIntegration={setRequestBodyForIntegration}
            />
            <DashboardConfigurationSection
              requestBodyForIntegration={requestBodyForIntegration}
              setRequestBodyForIntegration={setRequestBodyForIntegration}
            />
          </div>
        )}
      </div>
    </EditorLeavingGuard>
  );
};

export default CustomerFacingFields;
