import { ButtonVariant, Dropdown, MenuItem, Text } from "@merge-api/merge-javascript-shared";
import { ChevronDown } from "lucide-react";
import { MapDisplaySortType, SortTypeEnum } from "../utils/constants";

interface Props {
  sortType: SortTypeEnum;
  setSortType: (sortType: SortTypeEnum) => void;
}

const SortButton = ({ sortType, setSortType }: Props) => {
  return (
    <div className="flex flex-row justify-end space-x-2">
      <Text variant="h6" className="text-slate-90">
        Sort by
      </Text>
      <Dropdown
        ButtonProps={{
          variant: ButtonVariant.TextBlue,
          children: MapDisplaySortType[sortType],
          rightIcon: <ChevronDown size={16} className="text-blue-40" />,
        }}
      >
        {Object.values(SortTypeEnum).map((sortTypeOption) => (
          <MenuItem key={sortTypeOption} onClick={() => setSortType(sortTypeOption)}>
            {MapDisplaySortType[sortTypeOption]}
          </MenuItem>
        ))}
      </Dropdown>
    </div>
  );
};

export default SortButton;
