import React, { CSSProperties, useContext } from "react";
import { Card, AccordionContext, useAccordionToggle } from "react-bootstrap";
import ClickableContainer from "./ClickableContainer";
import { OverlayTrigger, Tooltip, Row, Col } from "react-bootstrap";
import classNames from "classnames";
import { HeaderPretitle } from "./text/MergeText";
import DeprecatedH3 from "../deprecated/DeprecatedH3";

type NavBarToggleProps = {
  children: React.ReactNode;
  callback?: (eventKey: string) => void;
  eventKey: string;
};

export const NavBarToggle = ({ children, eventKey, callback }: NavBarToggleProps) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <span
      className={isCurrentEventKey ? "nav-link accordion-show" : "nav-link accordion-collapse"}
      onClick={decoratedOnClick}
    >
      {children}
    </span>
  );
};

type CardHeaderToggleProps = {
  callback?: (eventKey: string) => void;
  eventKey: string;
  category: string;
  enabledIntegrationsCount: number;
  categoryIntegrationsCount: number;
};

export const CardHeaderToggle = ({
  eventKey,
  callback,
  category,
  enabledIntegrationsCount,
  categoryIntegrationsCount,
}: CardHeaderToggleProps) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Card.Header className="clickable-card" onClick={decoratedOnClick}>
      <Row>
        <Col>
          <DeprecatedH3 className="mb-1 ml-1">{category.toUpperCase()}</DeprecatedH3>
        </Col>
        <Col className="text-right text-muted small">
          {enabledIntegrationsCount === categoryIntegrationsCount
            ? "All"
            : enabledIntegrationsCount === 0
            ? "0"
            : enabledIntegrationsCount}{" "}
          enabled
        </Col>
        <Col className="col-auto pl-0 text-muted small">
          <span
            className={classNames(isCurrentEventKey ? "accordion-show" : "accordion-collapse")}
          />
        </Col>
      </Row>
    </Card.Header>
  );
};

type CollapsableToggleBaseProps = {
  children: React.ReactNode;
  callback?: (eventKey: string) => void;
  eventKey: string;
  additionalButton?: JSX.Element | null;
  style?: CSSProperties;
};

export const PlusMinusToggle = (props: CollapsableToggleBaseProps) => (
  <CollapsableToggle {...props} openedLogo={"fe-minus"} closedLogo={"fe-plus"} />
);
export const ChevronToggle = (props: CollapsableToggleBaseProps) => (
  <CollapsableToggle {...props} openedLogo={"fe-chevron-down"} closedLogo={"fe-chevron-right"} />
);

type CollapsableToggleProps = CollapsableToggleBaseProps & {
  openedLogo: string;
  closedLogo: string;
};

export const CollapsableToggle = ({
  children,
  eventKey,
  callback,
  openedLogo,
  closedLogo,
  additionalButton,
}: CollapsableToggleProps) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <ClickableContainer>
      <Row onClick={decoratedOnClick} className={"align-items-center"}>
        <Col>
          <HeaderPretitle>{children}</HeaderPretitle>
        </Col>
        <Col className="col-auto">
          <div className="d-flex">
            {additionalButton}
            {isCurrentEventKey ? (
              <span className={`fe ${openedLogo}`} />
            ) : (
              <span className={`fe ${closedLogo}`} />
            )}
          </div>
        </Col>
      </Row>
    </ClickableContainer>
  );
};

type SettingsButtonToggleProps = {
  callback?: (eventKey: string) => void;
  eventKey: string;
};

export const SettingsButtonToggle = ({ eventKey, callback }: SettingsButtonToggleProps) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <ClickableContainer>
      {isCurrentEventKey ? (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-top">Collapse settings</Tooltip>}
        >
          <span className="fe fe-minus" onClick={decoratedOnClick} />
        </OverlayTrigger>
      ) : (
        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">View settings</Tooltip>}>
          <span className="fe fe-plus" onClick={decoratedOnClick} />
        </OverlayTrigger>
      )}
    </ClickableContainer>
  );
};

type NestedPropertiesButtonToggleProps = {
  callback?: (eventKey: string) => void;
  eventKey: string;
};

export const NestedPropertiesButtonToggle = ({
  eventKey,
  callback,
}: NestedPropertiesButtonToggleProps) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <ClickableContainer>
      {isCurrentEventKey ? (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-top">Collapse nested properties</Tooltip>}
        >
          <span className="fe fe-minus" onClick={decoratedOnClick} />
        </OverlayTrigger>
      ) : (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-top">Expand nested properties</Tooltip>}
        >
          <span className="fe fe-plus" onClick={decoratedOnClick} />
        </OverlayTrigger>
      )}
    </ClickableContainer>
  );
};

type ChevronUpDownToggleWithElementProps = {
  children: React.ReactNode;
  afterChildren: React.ReactNode;
  callback?: (eventKey: string) => void;
  eventKey: string;
  style?: CSSProperties;
};

type CollapsableUpDownToggleWithElementProps = CollapsableToggleProps & {
  afterChildren: React.ReactNode;
};

export const ChevronUpDownToggleWithElement = (props: ChevronUpDownToggleWithElementProps) => (
  <CollapsableUpDownToggleWithElement
    {...props}
    openedLogo={"fe-chevron-down"}
    closedLogo={"fe-chevron-up"}
  />
);

export const CollapsableUpDownToggleWithElement = ({
  children,
  afterChildren,
  eventKey,
  callback,
  openedLogo,
  closedLogo,
  style,
}: CollapsableUpDownToggleWithElementProps) => {
  const currentEventKey = useContext(AccordionContext);
  const containerStyle = style ?? {};

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <ClickableContainer>
      <Row onClick={decoratedOnClick} className={"align-items-center"} style={containerStyle}>
        <Col style={{ paddingLeft: "24px" }}>
          {isCurrentEventKey ? (
            <Row>
              {children}
              <i className={`fe ${openedLogo}`} />
              {afterChildren}
            </Row>
          ) : (
            <Row>
              {children}
              <i className={`fe ${closedLogo}`} />
              {afterChildren}
            </Row>
          )}
        </Col>
      </Row>
    </ClickableContainer>
  );
};
