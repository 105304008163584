import { formatDate } from "../../../../../shared/utils/SharedComponentUtils";

type DateCellProps = {
  date: Date;
};

const DateCell = ({ date }: DateCellProps) => {
  return <td className="text-sm">{formatDate(date)}</td>;
};

export default DateCell;
