import { Text } from "@merge-api/merge-javascript-shared";

interface Props {
  title: string;
  badge?: React.ReactNode;
}

const LeftPanelSectionHeader = ({ title, badge }: Props) => {
  return (
    <div className="flex flex-row justify-between items-center">
      <Text variant="h5">{title}</Text>
      {badge}
    </div>
  );
};

export default LeftPanelSectionHeader;
