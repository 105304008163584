import { Badge, Text } from "@merge-api/merge-javascript-shared";

interface Props {
  title: string;
  badgeNames: string[];
}

/**
 * Renders the title & badge list combo for BlueprintSearchCard
 * Used for rendering Endpoints & Common Models that a Blueprint has
 */
const BlueprintSearchCardBadgeList = ({ title, badgeNames }: Props) => {
  if (badgeNames.length === 0) return null;
  return (
    <div className="flex flex-row space-x-1 justify-start items-start">
      <Text as="div" variant="md" className="text-slate-90 w-fit whitespace-nowrap">
        {title}:
      </Text>
      <div className="flex flex-row space-x-2 flex-wrap">
        {badgeNames.map((name) => (
          <Badge className="mt-[2px] items-center">{name}</Badge>
        ))}
      </div>
    </div>
  );
};

export default BlueprintSearchCardBadgeList;
