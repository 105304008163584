import { BlueprintAdvancedConfigurationsType, StagedComponent } from "../types";
import { useState, useEffect } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { Text, Toggle } from "@merge-api/merge-javascript-shared";
import { Integration } from "../../../../models/Entities";

type Props = {
  setCopyOfBlueprintAdvancedConfigurations: React.Dispatch<
    React.SetStateAction<Record<string, BlueprintAdvancedConfigurationsType> | undefined>
  >;
  copyOfBlueprintAdvancedConfigurations:
    | Record<string, BlueprintAdvancedConfigurationsType>
    | undefined;
  stagedChanges: Record<string, StagedComponent[]> | undefined;
  integrationID: string;
};

const PublishModalConfigurationScreen = ({
  setCopyOfBlueprintAdvancedConfigurations,
  copyOfBlueprintAdvancedConfigurations,
  stagedChanges,
  integrationID,
}: Props) => {
  const [numOfLinkedAccounts, setNumOfLinkedAccounts] = useState<number | undefined>();
  const [anyToggled, setAnyToggled] = useState<boolean>(false);

  useEffect(() => {
    fetchWithAuth({
      path: `/integrations/${integrationID}`,
      method: "GET",
      onResponse: (data: Integration) => {
        setNumOfLinkedAccounts(data.number_of_active_production_linked_accounts);
      },
    });
  }, [integrationID]);

  useEffect(() => {
    if (
      copyOfBlueprintAdvancedConfigurations &&
      Object.values(copyOfBlueprintAdvancedConfigurations).some(
        (stagedComponent) => stagedComponent.reset_timestamps_on_publish === true
      )
    ) {
      setAnyToggled(true);
    } else {
      setAnyToggled(false);
    }
  }, [copyOfBlueprintAdvancedConfigurations]);

  return (
    <div className="flex flex-column h-full justify-between">
      <div className="space-y-4 w-full">
        <Text>Advanced Configurations</Text>
        <div className="w-full space-y-2 py-2 px-3 rounded border border-gray-20 h-64 overflow-scroll">
          <>
            <Text variant="h6">Reset timestamps on publish</Text>
            <Text variant="sm" className="text-gray-50">
              Only reset timestamps if no alternative to implementing your change.
            </Text>
          </>
          {Object.entries(stagedChanges ?? {}).map(([modelClassName, stagedComponents]) => {
            if (modelClassName === "Blueprint") {
              return (
                <>
                  {stagedComponents.map((stagedComponent) => (
                    <div className="flex flex-row w-full justify-between space-x-6">
                      <Text className="truncate w-[420px]">{stagedComponent.name}</Text>
                      <Toggle
                        checked={
                          copyOfBlueprintAdvancedConfigurations &&
                          stagedComponent.component_version_id in
                            copyOfBlueprintAdvancedConfigurations
                            ? copyOfBlueprintAdvancedConfigurations[
                                stagedComponent.component_version_id
                              ].reset_timestamps_on_publish
                            : false
                        }
                        onChange={(e) => {
                          setCopyOfBlueprintAdvancedConfigurations({
                            ...copyOfBlueprintAdvancedConfigurations,
                            [stagedComponent.component_version_id]: {
                              reset_timestamps_on_publish: e,
                            },
                          });
                        }}
                      />
                    </div>
                  ))}
                </>
              );
            } else return null;
          })}
        </div>
        {anyToggled && numOfLinkedAccounts != null && (
          <Text className="text-red-50">
            This will reset timestamps for {numOfLinkedAccounts} active production linked account
            {numOfLinkedAccounts > 1 && "s"}.
          </Text>
        )}
      </div>
    </div>
  );
};

export default PublishModalConfigurationScreen;
