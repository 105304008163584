import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast } from "../../../shared/Toasts";
import {
  IntegrationTestSuiteExecution,
  IntegrationTestSuiteExecutionStatus,
} from "../../../../models/MappingTests";
import { useState } from "react";

interface Props {
  integrationID: string;
}

const useLoadMappingTestExecutions = ({ integrationID }: Props) => {
  const [isFetchingLatestTestSuiteExecution, setIsFetchingLatestTestSuiteExecution] = useState<
    boolean
  >(false);
  const [hasFailedFetchingLatestExecution, setHasFailedFetchingLatestExecution] = useState<boolean>(
    false
  );
  const [
    integrationTestSuiteExecution,
    setIntegrationTestSuiteExecution,
  ] = useState<IntegrationTestSuiteExecution | null>(null);
  const [isRunningMappingTests, setIsRunningMappingTests] = useState<boolean>(false);

  const fetchLatestIntegrationVersionTestSuiteExecution = () => {
    setIsFetchingLatestTestSuiteExecution(true);
    fetchWithAuth({
      path: `/integrations/versioning/${integrationID}/latest-integration-version-test-suite-execution`,
      method: "GET",
      onResponse: (data: IntegrationTestSuiteExecution) => {
        setIntegrationTestSuiteExecution(data);
        setIsRunningMappingTests(
          data && data.status === IntegrationTestSuiteExecutionStatus.IN_PROGRESS
        );
        setHasFailedFetchingLatestExecution(false);
        setIsFetchingLatestTestSuiteExecution(false);
      },
      onError: () => {
        showErrorToast("Error fetching tests.");
        setHasFailedFetchingLatestExecution(true);
        setIsFetchingLatestTestSuiteExecution(false);
      },
    });
  };

  const onRunTestsForStagedIntegrationVersion = () => {
    setIsRunningMappingTests(true);
    fetchWithAuth({
      path: `/integrations/versioning/${integrationID}/run-integration-version-test-suite-execution`,
      method: "POST",
      onResponse: () => {
        fetchLatestIntegrationVersionTestSuiteExecution();
      },
      onError: () => {
        showErrorToast("Error running tests.");
        setIsRunningMappingTests(false);
      },
    });
  };

  return {
    isFetchingLatestTestSuiteExecution,
    hasFailedFetchingLatestExecution,
    integrationTestSuiteExecution,
    isRunningMappingTests,
    fetchLatestIntegrationVersionTestSuiteExecution,
    onRunTestsForStagedIntegrationVersion,
  };
};

export default useLoadMappingTestExecutions;
