import { useEffect, useMemo, useRef, useState } from "react";
import { Integration, User } from "../../models/Entities";

export enum AvatarSize {
  xxl = "avatar-xxl",
  xl = "avatar-xl",
  normal = "",
  sm = "avatar-sm",
  xs = "avatar-xs",
  xxs = "avatar-xxs",
}

interface UserAvatarProps {
  user?: User | undefined;
  email?: string;
  size?: AvatarSize;
  className?: string;
}

export const UserAvatar = ({
  user,
  email,
  size = AvatarSize.normal,
  className = "",
}: UserAvatarProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [avatarLetters, setAvatarLetters] = useState("");

  useEffect(() => {
    setWidth(containerRef.current?.offsetWidth ?? 0);
    setHeight(containerRef.current?.offsetHeight ?? 0);
  }, [containerRef]);

  useMemo(() => {
    if (user) {
      if (user.name) {
        const splitName = user.name.split(" ");
        if (splitName.length > 1) {
          setAvatarLetters(splitName[0].charAt(0) + splitName[splitName.length - 1].charAt(0));
        }
      } else if (user.email) {
        setAvatarLetters(user.email.charAt(0).toUpperCase());
      }
    } else if (email) {
      setAvatarLetters(email.charAt(0).toUpperCase());
    }
  }, [user, email]);

  return user?.avatar ? (
    <div className={"avatar " + size + " " + className}>
      <img alt="avatar" src={user.avatar} className="avatar-img rounded-circle" />
    </div>
  ) : (
    <div className={"avatar " + size + " " + className} ref={containerRef}>
      <span
        className="avatar-title rounded-circle align-middle"
        style={{ fontSize: Math.min(width, height) * 0.5 }}
      >
        {avatarLetters}
      </span>
    </div>
  );
};

interface IntegrationAvatarProps {
  integration?: Integration | undefined;
  size?: AvatarSize;
  className?: string;
}

export const IntegrationAvatar = ({
  integration,
  size,
  className = "",
}: IntegrationAvatarProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [avatarLetters, setAvatarLetters] = useState("");

  useEffect(() => {
    setWidth(containerRef.current?.offsetWidth ?? 0);
    setHeight(containerRef.current?.offsetHeight ?? 0);
  }, [containerRef]);

  useMemo(() => {
    if (integration) {
      if (integration.name) {
        const splitName = integration.name.split(" ");
        if (splitName.length > 1) {
          setAvatarLetters(splitName[0].charAt(0) + splitName[splitName.length - 1].charAt(0));
        } else {
          setAvatarLetters(splitName[0].charAt(0));
        }
      }
    }
  }, [integration]);

  const avatarClass = {
    border: "1px solid #eaeef3",
    borderRadius: "8px",
    fontSize: Math.min(width, height) * 0.5,
    width: size ? undefined : "40px",
    height: size ? undefined : "40px",
  };

  return integration?.square_image ? (
    <div className={"avatar " + size + className}>
      <img alt="avatar" src={integration.square_image} className="avatar-img" style={avatarClass} />
    </div>
  ) : (
    <div className={"avatar " + size + className} ref={containerRef}>
      <span className="avatar-title align-middle" style={avatarClass}>
        {avatarLetters}
      </span>
    </div>
  );
};
