import { Route, Switch } from "react-router-dom";
import LinkingFlowStepPathsTable from "./components/table/LinkingFlowStepPathsTable";
import LinkingFlowStepPathSetup from "./components/setup/LinkingFlowStepPathSetup";
import {
  getIntegrationBuilderLinkingFlowStepsPathForId,
  getIntegrationBuilderNewLinkingFlowStepPath,
  getIntegrationBuilderExistingLinkingFlowStepPath,
} from "../../../router/RouterUtils";
import useLoadLinkChoiceStepOptions from "./hooks/useLoadLinkChoiceStepOptions";
import useLoadIntegrationSetupChecklistItems from "./hooks/useLoadIntegrationSetupChecklistItems";
import useLoadLinkToken from "./hooks/useLoadLinkToken";
import useLoadLinkingFlowStepPathTableRows from "./hooks/useLoadLinkingFlowStepPathTableRows";

interface LinkingFlowStepPathProps {
  integrationID: string;
}

const LinkingFlowStepPaths = ({ integrationID }: LinkingFlowStepPathProps) => {
  // Load LinkingFlowStepPaths, AuthConfigs, LinkChoiceStepOptions, & IntegrationSetupChecklistItems, & LinkToken
  const { linkChoiceStepOptions, setLinkChoiceStepOptions } = useLoadLinkChoiceStepOptions({
    integrationID,
  });
  const { integrationSetupChecklistItems } = useLoadIntegrationSetupChecklistItems();
  const { linkToken, fetchLinkToken } = useLoadLinkToken();

  const {
    stepPathTableRows,
    isLoadingLinkingFlowStepPathsTableRows,
    reloadLinkingFlowStepPathsAndAuthConfigs,
    linkingFlowStepPaths,
    authConfigs,
  } = useLoadLinkingFlowStepPathTableRows({ integrationID });

  return (
    <div>
      <Switch>
        <Route
          exact
          path={getIntegrationBuilderLinkingFlowStepsPathForId(integrationID)}
          render={() => (
            <LinkingFlowStepPathsTable
              integrationID={integrationID}
              linkingFlowStepPaths={linkingFlowStepPaths}
              authConfigs={authConfigs}
              stepPathTableRows={stepPathTableRows}
              isLoadingLinkingFlowStepPathsTableRows={isLoadingLinkingFlowStepPathsTableRows}
              reloadLinkingFlowStepPathsAndAuthConfigs={reloadLinkingFlowStepPathsAndAuthConfigs}
              linkToken={linkToken}
              fetchLinkToken={fetchLinkToken}
            />
          )}
        />
        <Route
          exact
          path={getIntegrationBuilderNewLinkingFlowStepPath(integrationID)}
          render={() => (
            <LinkingFlowStepPathSetup
              authConfigs={authConfigs}
              integrationSetupChecklistItems={integrationSetupChecklistItems}
              linkingFlowStepPaths={linkingFlowStepPaths}
              linkChoiceStepOptions={linkChoiceStepOptions}
              setLinkChoiceStepOptions={setLinkChoiceStepOptions}
              integrationID={integrationID}
              reloadLinkingFlowStepPathsAndAuthConfigs={reloadLinkingFlowStepPathsAndAuthConfigs}
            />
          )}
        />
        <Route
          exact
          path={getIntegrationBuilderExistingLinkingFlowStepPath(integrationID)}
          render={() => (
            <LinkingFlowStepPathSetup
              authConfigs={authConfigs}
              integrationSetupChecklistItems={integrationSetupChecklistItems}
              linkingFlowStepPaths={linkingFlowStepPaths}
              linkChoiceStepOptions={linkChoiceStepOptions}
              setLinkChoiceStepOptions={setLinkChoiceStepOptions}
              integrationID={integrationID}
              reloadLinkingFlowStepPathsAndAuthConfigs={reloadLinkingFlowStepPathsAndAuthConfigs}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default LinkingFlowStepPaths;
