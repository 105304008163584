import React from "react";
import {
  Blueprint,
  BlueprintStep,
  BlueprintStepType,
  BlueprintVersionPublishState,
  BlueprintStatus,
  BlueprintStepTemplate,
  AddStepRelation,
  BlueprintRunnerExecutionResponse,
  BlueprintOperationType,
  BlueprintSwitchStep,
  BlueprintTrigger,
  BlueprintStepOrGhostStepOrTriggerOrScraper,
  JSONObjectSchema,
  JSONSchemaValue,
  StepRelations,
  BlueprintGenericStepTemplate,
  BlueprintVersion,
  ReportFile,
  BlueprintParameterValue,
  BlueprintVersionStaleParametersInfo,
  BlueprintIfElseStep,
  BlueprintSelectedStepLog,
  StepLoggingViewEnum,
  StepParameterTracingTarget,
} from "../../../models/Blueprints";
import { IntegrationCommonModelConfig, UserType } from "../../../models/Entities";
import { ModifySwitchOptionType } from "../utils/BlueprintEditorUtils";
import { JSONSchemaTraversalPath } from "../validator/BlueprintValidationEditorUtils";

const noop = () => {};
export interface BlueprintContextState {
  blueprint: Blueprint;
  setBlueprint: (blueprint: Blueprint) => void;
  blueprintVersions: BlueprintVersion[];
  setOriginalBlueprint: (blueprint: Blueprint) => void;
  selectedStep: undefined | BlueprintStepOrGhostStepOrTriggerOrScraper;
  setSelectedSteps: (steps: Array<BlueprintStepOrGhostStepOrTriggerOrScraper> | undefined) => void;
  setCopiedSteps: (steps: Array<BlueprintStepOrGhostStepOrTriggerOrScraper> | undefined) => void;
  selectedSteps: undefined | Array<BlueprintStepOrGhostStepOrTriggerOrScraper>;
  copiedSteps: undefined | Array<BlueprintStepOrGhostStepOrTriggerOrScraper>;
  setSelectedStep: (step: BlueprintStepOrGhostStepOrTriggerOrScraper) => void;
  updateStepParameterValues: (
    step: BlueprintStep,
    parameterValues: { [key: string]: BlueprintParameterValue | null }
  ) => void;
  updateStepParameterValue: (step: BlueprintStep, valueKey: string, value: any) => void;
  replaceStepTemplate: (step: BlueprintStep, newTemplate: BlueprintStepTemplate) => void;
  addStep: (
    stepTemplate: BlueprintStepTemplate,
    relatedStepRelation: AddStepRelation,
    relatedStepID: string,
    newStepTypePathKey?: string
  ) => void;
  addGenericStep: (
    stepTemplate: BlueprintGenericStepTemplate,
    relatedStepRelation: AddStepRelation,
    relatedStepID: string,
    newStepTypePathKey?: string
  ) => void;
  deleteStep: (step: string) => void;
  deleteSteps: (steps: Array<BlueprintStep>) => void;
  deleteIfElseStepAndMoveChildrenUp: (step: BlueprintIfElseStep) => void;
  collapseSubsteps: (stepID: string) => void;
  blueprintRunnerExecutionResponse: null | BlueprintRunnerExecutionResponse;
  setBlueprintRunnerExecutionResponse: (
    setBlueprintRunnerLogs: BlueprintRunnerExecutionResponse | null
  ) => void;
  stepTemplates: BlueprintStepTemplate[];
  genericStepTemplates: BlueprintGenericStepTemplate[];
  updateSwitchStepOption: (
    switchStep: BlueprintSwitchStep,
    optionKey: string,
    modificationType: ModifySwitchOptionType
  ) => void;
  blueprintTrigger: BlueprintTrigger | undefined;
  setBlueprintTrigger: (blueprintTrigger: BlueprintTrigger | undefined) => void;
  copiedStep: BlueprintStep | undefined;
  setCopiedStep: (step: BlueprintStep | undefined) => void;
  addCopiedStep: (
    step: BlueprintStep,
    relatedStepRelation: AddStepRelation,
    relatedStepID: string,
    newStepTypePathKey?: string
  ) => void;
  addCopiedSteps: (
    steps: BlueprintStep[],
    relatedStepRelation: AddStepRelation,
    relatedStepID: string,
    newStepTypePathKey?: string
  ) => void;
  addCopiedStepsFromClipboard: (
    relatedStepRelation: AddStepRelation,
    relatedStepID: string,
    newStepTypePathKey?: string
  ) => void;
  renameStep: (stepID: string, newName: string, onError?: () => void) => void;
  doesBlueprintHaveUnsavedChanges: boolean;
  updateStepMockResponseBody: (step: BlueprintStep, key: string, value: string) => void;
  undoActions: {
    undo: () => void;
    redo: () => void;
    canUndo: boolean;
    canRedo: boolean;
  };
  setBlueprintParameterSchema: (parameterSchema: JSONObjectSchema) => void;
  setBlueprintQBXMLQueryRequestFormat: (qbXMLQueryRequestFormat: string) => void;
  setBlueprintReturnSchema: (returnSchema: JSONObjectSchema) => void;
  setBlueprintHumanName: (human_name: string) => void;
  setBlueprintCategory: (category: string) => void;
  setRunStepConcurrently: (stepId: string, run_concurrently: boolean) => void;
  setStepUsesPaginationTimestamp: (stepId: string, usesPaginationTimestamp: boolean) => void;
  setStepIgnoreAuthConfig: (stepId: string, ignoreAuthConfig: boolean) => void;
  setStepNeedsRemoteData: (stepId: string, needsRemoteData: boolean) => void;
  setStepClosesPaginationSequence: (stepId: string, closesPaginationSequence: boolean) => void;
  setRequiredFieldsForObject: (
    objectSchemaTraversalPath: JSONSchemaTraversalPath,
    requiredFields: Array<string>
  ) => void;
  setBlueprintParameterSchemaValue: (
    fieldTraversalPath: JSONSchemaTraversalPath,
    fieldValue: JSONSchemaValue | undefined
  ) => void;
  setBlueprintReturnSchemaValue: (
    fieldTraversalPath: JSONSchemaTraversalPath,
    fieldValue: JSONSchemaValue | undefined
  ) => void;
  setBlueprintStepNote: (stepID: string, text: string) => void;
  availableTemplateConfigs: IntegrationCommonModelConfig[];
  setAvailableTemplateConfigs: (templateConfigs: IntegrationCommonModelConfig[]) => void;
  stepRelationMap: Map<string, StepRelations>;
  setStepRelationMap: (stepRelationMap: Map<string, StepRelations>) => void;
  setReportFiles: (reportFiles: Array<ReportFile>) => void;
  reportFiles: Array<ReportFile>;
  backendStaleParameters: BlueprintVersionStaleParametersInfo | null;
  setBackendStaleParameters: (info: BlueprintVersionStaleParametersInfo | null) => void;
  selectedStepLog: BlueprintSelectedStepLog | undefined;
  setSelectedStepLog: (selectedStepLog: BlueprintSelectedStepLog | undefined) => void;
  stepLoggingView: StepLoggingViewEnum;
  setStepLoggingView: (stepLoggingView: StepLoggingViewEnum) => void;
  isTracing: boolean;
  setIsTracing: (value: boolean) => void;
  stepParameterToTrace: StepParameterTracingTarget | undefined;
  setStepParameterToTrace: (value: StepParameterTracingTarget | undefined) => void;
  tracedStepIDs: string[];
  setTracedStepIDs: (value: string[]) => void;
  computeTracedStepIDs: (stepParameterToTrace: StepParameterTracingTarget) => void;
  isFlagEnabledForTracing: boolean;
}

const BlueprintContext = React.createContext<BlueprintContextState>({
  blueprint: {
    id: "",
    integration: {
      action_types: {},
      api_documentation_url: "",
      integration_checklist: undefined,
      base_api_url: "",
      categories: [],
      coverage: "",
      name: "",
      image: "",
      id: "",
      modified_at: "",
      active: true,
      organization_config_status: [],
      timestamp_format: "",
      last_modified_blueprint_at: "",
      number_of_linked_accounts: "",
      number_of_staged_tests: 0,
      has_published_setup_steps: false,
      has_pagination_configurations: false,
      api_request_retry_delay_seconds: "",
      notes_for_customers: "",
      auth_type: "",
      report_templates: [],
      webhook_receiver_event_types: [],
    },
    name: "",
    category: null,
    human_name: null,
    operation_type: BlueprintOperationType.FETCH,
    webhook_receiver_event_type_id: "",
    report_files: [],
    report_template_id: null,
    steps: [],
    owner: {
      id: "string",
      uuid: "string",
      name: "string",
      email: "string",
      avatar: "string",
      organization: {
        id: "",
        name: "",
        logo: "",
        auto_enable_new_integrations: true,
        is_merge_org: true,
      },

      type: UserType.admin,
      is_staff: true,
      is_demo: false,
      is_two_factor_enabled: false,
    },
    version: {
      id: "",
      created_at: "",
      publish_state: BlueprintVersionPublishState.Unpublished,
      comment: "",
    },
    mapped_models: [],
    endpoints: [],
    step_notes: [],
    status: BlueprintStatus.Active,
    trigger_type: undefined,
    is_long_running: false,
  },
  blueprintVersions: [],
  setBlueprint: noop,
  setOriginalBlueprint: noop,
  addStep: noop,
  addGenericStep: noop,
  deleteStep: noop,
  deleteSteps: noop,
  deleteIfElseStepAndMoveChildrenUp: noop,
  collapseSubsteps: noop,
  setSelectedStep: noop,
  setSelectedSteps: noop,
  setCopiedSteps: noop,
  updateStepParameterValues: noop,
  updateStepParameterValue: noop,
  replaceStepTemplate: noop,
  selectedStep: {
    id: "",
    parameter_values: {},
    paths: undefined,
    template: {
      id: "",
      step_type: BlueprintStepType.CreateOrUpdate,
      name: "",
      image: "",
      integration: "",
      parameter_schema: {},
      return_schema: { type: "object", properties: {} },
      metadata: {},
      can_run_concurrently: false,
    },
    run_concurrently: false,
  },
  selectedSteps: [],
  copiedSteps: [],
  blueprintRunnerExecutionResponse: null,
  setBlueprintRunnerExecutionResponse: noop,
  stepTemplates: [],
  genericStepTemplates: [],
  reportFiles: [],
  backendStaleParameters: null,
  setBackendStaleParameters: noop,
  updateSwitchStepOption: noop,
  blueprintTrigger: undefined,
  setBlueprintTrigger: noop,
  copiedStep: undefined,
  setCopiedStep: noop,
  addCopiedStep: noop,
  addCopiedSteps: noop,
  addCopiedStepsFromClipboard: noop,
  renameStep: noop,
  doesBlueprintHaveUnsavedChanges: false,
  updateStepMockResponseBody: () => {},
  undoActions: { undo: noop, redo: noop, canUndo: false, canRedo: false },
  setBlueprintParameterSchema: noop,
  setBlueprintQBXMLQueryRequestFormat: noop,
  setReportFiles: noop,
  setBlueprintReturnSchema: noop,
  setRunStepConcurrently: noop,
  setStepUsesPaginationTimestamp: noop,
  setStepIgnoreAuthConfig: noop,
  setStepNeedsRemoteData: noop,
  setStepClosesPaginationSequence: noop,
  setBlueprintParameterSchemaValue: noop,
  setBlueprintReturnSchemaValue: noop,
  setRequiredFieldsForObject: noop,
  setBlueprintHumanName: noop,
  setBlueprintCategory: noop,
  setBlueprintStepNote: noop,
  availableTemplateConfigs: [],
  setAvailableTemplateConfigs: noop,
  stepRelationMap: new Map(),
  setStepRelationMap: noop,
  selectedStepLog: undefined,
  setSelectedStepLog: noop,
  stepLoggingView: StepLoggingViewEnum.DEFAULT,
  setStepLoggingView: noop,
  isTracing: false,
  setIsTracing: noop,
  tracedStepIDs: [],
  setTracedStepIDs: noop,
  stepParameterToTrace: undefined,
  setStepParameterToTrace: noop,
  computeTracedStepIDs: noop,
  isFlagEnabledForTracing: false,
});

export default BlueprintContext;
