import { Accordion, Badge, Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import {
  BPRStepIOLog,
  BPRStepIOLogTypeEnum,
  BlueprintSelectedStepLog,
  mapDisplayableStepIOLogType,
} from "../../../../../models/Blueprints";
import StepIOLogsTableCardSection from "./StepIOLogsTableCardSection";
import clsx from "clsx";
import { convertVariableDiffLogsToDisplayableDictionary } from "../../../utils/BlueprintStepIOUtils";
import StepIOLogModal from "../../../shared/blueprint-editor-step-io-log-modal/StepIOLogModal";
import { useState } from "react";
import { ArrowUpRight } from "lucide-react";

interface Props {
  index: number;
  stepIOLog: BPRStepIOLog;
  setSelectedStepLog: (selectedStepLog: BlueprintSelectedStepLog | undefined) => void;
  isSelected: boolean;
}

/**
 * Renders a card representing a single step I/O log
 * @param {BPRStepIOLog} stepIOLog - The step I/O log to be rendered
 */
const StepIOLogsTableCard = ({
  index,
  stepIOLog,
  setSelectedStepLog,
  isSelected = false,
}: Props) => {
  // Convert variable diff logs into displayable section
  let variableDiffLogsAsDict: Record<string, any> = convertVariableDiffLogsToDisplayableDictionary(
    stepIOLog.variable_diff_logs
  );

  const [showModal, setShowModal] = useState<boolean>(false);

  const handleFocusOnStepIO = () => {
    setSelectedStepLog({ step_id: stepIOLog.step_id, index_in_raw_logs: index });
  };

  return (
    <>
      <Accordion
        title={
          <div className="flex flex-row items-center w-full pr-2 justify-between space-x-2">
            <Text variant="title-sm" className="break-all">
              {stepIOLog.step_id}
            </Text>
            {stepIOLog.log_type !== BPRStepIOLogTypeEnum.DEFAULT && (
              <Badge size="sm" className="text-nowrap">
                {mapDisplayableStepIOLogType[stepIOLog.log_type]}
              </Badge>
            )}
          </div>
        }
        variant={isSelected ? "none" : "outline"}
        titleClassName="p-3 w-full"
        className={clsx("w-full", isSelected && "border-blue-20 border-4")}
        chevronOrientation="right"
      >
        <>
          <hr className="bg-gray-10 p-0 m-0" />
          <div className="flex flex-col w-full space-y-3 px-2 py-3">
            <div className="flex flex-row w-full justify-between px-1 items-center">
              <Button variant={ButtonVariant.TextBlue} size="sm" onClick={handleFocusOnStepIO}>
                Focus on step I/O
              </Button>
              <Button
                variant={ButtonVariant.TextBlue}
                size="sm"
                onClick={() => setShowModal(true)}
                rightIcon={<ArrowUpRight size={12} className="text-blue-40" />}
              >
                View in modal
              </Button>
            </div>
            <StepIOLogsTableCardSection title="Input" data={stepIOLog.inputs} />
            <StepIOLogsTableCardSection title="Output" data={stepIOLog.outputs} />
            {variableDiffLogsAsDict && Object.entries(variableDiffLogsAsDict).length > 0 && (
              <StepIOLogsTableCardSection title="Variable changes" data={variableDiffLogsAsDict} />
            )}
          </div>
        </>
      </Accordion>
      <StepIOLogModal stepIOLog={stepIOLog} open={showModal} setOpen={setShowModal} />
    </>
  );
};

export default StepIOLogsTableCard;
