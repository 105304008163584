import { usePaginatedRequest } from "../../../../hooks/usePaginatedRequest";
import { Integration, PaginationType } from "../../../../models/Entities";
import PaginationFooter from "../../../shared/layout/PaginationFooter";
import useChangelogFilters from "../hooks/useChangelogFilters";
import { ChangelogEvent } from "../utils/types";
// import useChangelogFilters from "../hooks/useChangelogFilters";
import ChangelogTable from "./ChangelogTable";
import ChangelogFilters from "./Filters/ChangelogFilters";

type Props = {
  integrationMeta: Integration;
};

const IntegrationChangelogTab = ({ integrationMeta }: Props) => {
  // hooks
  const {
    paramsPath,
    startDate,
    endDate,
    eventType,
    modelType,
    onFilterChange,
    userId,
    filterDeploys,
  } = useChangelogFilters();
  const { isLoading, results, hasPrevious, hasNext, onNext, onPrevious } = usePaginatedRequest<
    ChangelogEvent
  >({
    rootPath: `integrations/${integrationMeta.id}/integration-changelog`,
    paginationType: PaginationType.CURSOR,
    paramsPath: paramsPath,
  });

  return (
    <>
      <ChangelogFilters
        startDate={startDate}
        endDate={endDate}
        eventType={eventType}
        modelType={modelType}
        onFilterChange={onFilterChange}
        userId={userId}
        filterDeploys={filterDeploys}
      />
      <ChangelogTable changelogEvents={results} isLoading={isLoading} />
      <PaginationFooter
        hasPrevious={hasPrevious}
        hasNext={hasNext}
        onNextClick={onNext}
        onPreviousClick={onPrevious}
      />
    </>
  );
};

export default IntegrationChangelogTab;
