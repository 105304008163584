import { Plus } from "lucide-react";
import clsx from "clsx";
import { ChevronRight } from "lucide-react";

type Props = {
  doesEntityExist: boolean;
  isFetchMappingTestReset?: boolean;
  onClick: () => void;
  title: string;
  actionButton?: React.ReactNode | null;
};

const EndpointCollectionCardBase = ({
  doesEntityExist,
  title,
  onClick,
  actionButton,
  isFetchMappingTestReset = false,
}: Props) => {
  const grayBackground = !doesEntityExist || isFetchMappingTestReset;
  return (
    <div
      onClick={onClick}
      className={clsx(
        "py-5 px-6 flex items-center justify-between cursor-pointer rounded-md transition duration-150 ease-in-out",
        {
          "bg-gray-0 hover:bg-gray-10": grayBackground,
          "bg-white shadow-md hover:bg-gray-0": !grayBackground,
        }
      )}
    >
      <div className="font-semibold text-black">{title}</div>
      <div className="flex flex-row items-center">
        {doesEntityExist ? (
          <>
            {actionButton}
            {!isFetchMappingTestReset ? (
              <ChevronRight size={12} className="text-gray-50" />
            ) : (
              <Plus size={20} className="text-black" />
            )}
          </>
        ) : (
          <>
            <Plus size={20} className="text-black" />
          </>
        )}
      </div>
    </div>
  );
};

export default EndpointCollectionCardBase;
