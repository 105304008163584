import {
  BlueprintCanvasBaseStepLogList,
  BlueprintCanvasBaseStepLogListItem,
  BlueprintCanvasViewableStepLogsMap,
  BlueprintSelectedStepLog,
} from "../../../../models/Blueprints";

/**
 * Locates the starting index in the raw logs for displaying viewable step logs.
 *
 * This function determines the appropriate starting point in the log list based on:
 * 1. The selected step log (if any)
 * 2. Whether the current log is an iteration or API request
 * 3. The nesting level of the logs
 *
 * It aims to show relevant logs at the same nesting level, while handling special cases
 * for loop iterations and API request pages.
 *
 * @param baseStepLogsList - The complete list of base step logs
 * @param selectedStepLog - The currently selected step log (if any)
 * @returns The index in the raw logs from which to start displaying viewable logs
 */
export const locateStartingIndexInRawLogs = (
  baseStepLogsList: BlueprintCanvasBaseStepLogList,
  selectedStepLog: BlueprintSelectedStepLog | undefined
): number | undefined => {
  // Locate initial startIndex, via selected step log
  let startIndex: number = selectedStepLog
    ? selectedStepLog.index_in_raw_logs
    : baseStepLogsList.length - 1;

  const startingStepLog = baseStepLogsList[startIndex];
  const isAPICallPage =
    startingStepLog.iteration_info.index_in_api_requests !== undefined &&
    startingStepLog.iteration_info.index_in_loop_iterations === undefined;
  const isLoopIteration = startingStepLog.iteration_info.index_in_loop_iterations !== undefined;

  // If this step log is an iteration, return the iteration's last child step log
  if (isLoopIteration) {
    const indexOfLastChildrenStepLog =
      startingStepLog.selected_log_info.index_of_last_children_step_log;
    return indexOfLastChildrenStepLog ? indexOfLastChildrenStepLog : startIndex;
  }
  // If this step log is an API call / page, return the last iteration's step log
  if (isAPICallPage) {
    const indexOfLoopLatestIterationLog =
      startingStepLog.selected_log_info.index_of_loop_latest_iteration_log;
    return indexOfLoopLatestIterationLog ? indexOfLoopLatestIterationLog : startIndex;
  }

  // "Look outside" to show logs on same nesting level
  // Reset initial startIndex to immediate parent's last_children_step_log,
  // However, we DON'T want to show same nesting level if the current log is an iteration or page
  const parentIndex = startingStepLog.selected_log_info.index_of_parent_log;
  const lastChildrenStepLogIndex = parentIndex
    ? baseStepLogsList[parentIndex]?.selected_log_info?.index_of_last_children_step_log
    : undefined;
  // Show logs starting with the index of the last step, in the same nesting level
  if (parentIndex !== undefined && lastChildrenStepLogIndex !== undefined) {
    startIndex = lastChildrenStepLogIndex;
  }
  // Show all logs on same nesting level, if no parent
  else {
    startIndex = baseStepLogsList.length - 1;
  }
  return startIndex;
};

/**
 * Generates a map of viewable step logs by traversing the base step logs list.
 * This function starts from a given index and traverses the list, creating
 * a map of step logs that should be visible in the UI.
 *
 * @param baseStepLogsList - The complete list of base step logs
 * @param startingIndex - The index from which to start traversing the list
 * @returns A map of viewable step logs, keyed by step ID
 */
export const generateViewableStepLogsByTraversingList = (
  baseStepLogsList: BlueprintCanvasBaseStepLogList,
  startingIndex: number | null | undefined
): BlueprintCanvasViewableStepLogsMap => {
  let viewableStepLogs: BlueprintCanvasViewableStepLogsMap = {};
  let visited: Set<string> = new Set();

  while (
    startingIndex !== null &&
    startingIndex !== undefined &&
    startingIndex < baseStepLogsList.length
  ) {
    const stepLog: BlueprintCanvasBaseStepLogListItem = baseStepLogsList[startingIndex];

    // If already visited, skip to next step
    if (visited.has(stepLog.step_id)) {
      startingIndex = stepLog.selected_log_info.index_of_previous_log;
    } else {
      // Add to viewableStepLogs
      viewableStepLogs[stepLog.step_id] = {
        step_io_log: stepLog.step_io_log,
        index_in_raw_logs: startingIndex,
        iteration_info: stepLog.iteration_info,
        loop_initialization_viewable_step_log: undefined,
      };

      // If this is an iteration, add "api_request_page_viewable_step_log"
      // This powers the secondary step log you see in canvas for loop steps
      if (stepLog.selected_log_info.index_of_loop_initialization_log !== undefined) {
        const loop_initialization_log =
          baseStepLogsList[stepLog.selected_log_info.index_of_loop_initialization_log];
        viewableStepLogs[stepLog.step_id].loop_initialization_viewable_step_log = {
          step_io_log: loop_initialization_log.step_io_log,
          index_in_raw_logs: stepLog.selected_log_info.index_of_loop_initialization_log,
          iteration_info: loop_initialization_log.iteration_info,
        };
      }

      visited.add(stepLog.step_id);

      // We can't miss including children step logs to viewableStepLogs,
      // We also can't miss including the latest iteration log to viewableStepLogs, (this applies for loop steps)
      // So we check for those first before traversing up the list
      startingIndex =
        stepLog.selected_log_info.index_of_last_children_step_log !== undefined
          ? stepLog.selected_log_info.index_of_last_children_step_log
          : stepLog.selected_log_info.index_of_loop_latest_iteration_log !== undefined
          ? stepLog.selected_log_info.index_of_loop_latest_iteration_log
          : stepLog.selected_log_info.index_of_previous_log;
    }
  }

  return viewableStepLogs;
};
