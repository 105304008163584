import { Accordion, Text } from "@merge-api/merge-javascript-shared";
import React, { useContext, useEffect, useState } from "react";
import { APIProtocols } from "../../../../../models/APIEndpointModels";
import { HTTPMethod } from "../../../../../models/HTTPMethods";
import { getURLParamJSONSchemaFromPath } from "../../../../integrations/utils/IntegrationsUtils";
import PostmanTableHeader from "../../../authentication/components/shared/PostmanTableHeader";
import useIntegrationBuilderContext from "../../../context/useIntegrationBuilderContext";
import AccordionCard from "../../../shared/AccordionCard";
import BadgeList from "../../../shared/BadgeList";
import SelectHeader from "../../../shared/SelectHeader";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import { parsePostmanTableRowsToPropertiesDict } from "../../../utils/helpers";
import APIEndpointContext from "../../context/APIEndpointContext";
import AdvancedRequestConfigurationSetupOptions from "./AdvancedRequestConfigurationSetupOptions";
import GraphQLRequestConfigurationSetupOptions from "./GraphQLRequestConfigurationSetupOptions";
import RestRequestConfigurationSetupOptions from "./RestRequestConfigurationSetupOptions";
import SoapRequestConfigurationSetupOptions from "./SoapRequestConfigurationSetupOptions";
import TimestampFilterConfigurationSetupOptions from "./TimestampFilterConfigurationSetupOptions";
import { Row } from "../../../shared/postman-table/PostmanSelectTableRow";

const DATA_TYPES = ["string", "number", "boolean", "array", "datetime"];

interface RequestConfigurationSetupOptionsProps {
  protocol: string;
  method: string;
  setMethod: (value: string) => void;
  path: string;
  setPath: (value: string) => void;
  baseURLOverride: string;
  setBaseURLOverride: (value: string) => void;
  pathParamSchema: Object;
  setPathParamSchema: (value: Object) => void;
  setQueryParamSchema: (value: Object) => void;
  queryParamTableRows: Row[];
  setQueryParamTableRows: React.Dispatch<React.SetStateAction<Row[]>>;
  setHeaderSchema: (value: Object) => void;
  headerSchemaTableRows: Row[];
  setHeaderSchemaTableRows: React.Dispatch<React.SetStateAction<Row[]>>;
}

const RequestConfigurationSetupOptionsWithProps = React.memo(
  ({
    protocol,
    method,
    setMethod,
    path,
    setPath,
    baseURLOverride,
    setBaseURLOverride,
    pathParamSchema,
    setPathParamSchema,
    queryParamTableRows: queryParamSchemaTableRows,
    setQueryParamTableRows: setQueryParamSchemaTableRows,
    setQueryParamSchema,
    setHeaderSchema,
    headerSchemaTableRows,
    setHeaderSchemaTableRows,
  }: RequestConfigurationSetupOptionsProps) => {
    const { integration } = useIntegrationBuilderContext();

    const [pathParameters, setPathParameters] = useState<string[]>([]);

    useEffect(() => {
      setPathParameters(Object.keys((pathParamSchema as any)?.properties || {}));
    }, [pathParamSchema]);

    useEffect(() => {
      setHeaderSchema(parsePostmanTableRowsToPropertiesDict(headerSchemaTableRows));
    }, [headerSchemaTableRows]);

    useEffect(() => {
      setQueryParamSchema(parsePostmanTableRowsToPropertiesDict(queryParamSchemaTableRows));
    }, [queryParamSchemaTableRows]);

    const renderProtocolSpecificOptions = () => {
      switch (protocol) {
        case APIProtocols.REST:
          return <RestRequestConfigurationSetupOptions />;
        case APIProtocols.SOAP:
          return <SoapRequestConfigurationSetupOptions />;
        case APIProtocols.GRAPHQL:
          return <GraphQLRequestConfigurationSetupOptions />;
        default:
          return null;
      }
    };

    useEffect(() => {
      if (path !== undefined || path !== null) {
        const schemas = getURLParamJSONSchemaFromPath(path);
        setPathParamSchema(JSON.parse(schemas.pathParamJSONSchema || ""));
      }
    }, [path]);

    return (
      <AccordionCard title="Request configuration" defaultExpanded={true}>
        <SelectHeader
          dataTestID="field-api-endpoint-method"
          required
          title="Method"
          subtitle="Select the HTTP method used when calling this endpoint"
          options={Object.values(HTTPMethod)}
          onChange={(_: any, selectedOption: string | null) => {
            setMethod(selectedOption || HTTPMethod.GET);
          }}
          value={method}
          disabled={false}
          clearable={false}
        />
        <TextFieldHeader
          dataTestID="field-api-endpoint-url-path"
          title="URL path"
          subtitle="Specify the URL path for this endpoint. Use curly braces to denote path parameters (i.e. /users/{userId})"
          placeholder="URL path"
          prefix={
            !!baseURLOverride
              ? baseURLOverride
              : integration?.base_api_url || "https://{domain}/api"
          }
          prefixVariant="url"
          className="mt-6"
          value={path}
          onChange={(e) => setPath(e.target.value)}
          hasSource={false}
        />
        <div data-testid="accordion-override-base-url">
          <Accordion
            title={
              <Text
                className="text-blue-40 hover:text-blue-60 cursor-pointer font-medium"
                variant="sm"
              >
                Override base URL
              </Text>
            }
            chevronSize={0}
            onChange={function noRefCheck() {}}
            defaultExpanded={baseURLOverride !== ""}
            variant="none"
            className="mt-1"
          >
            <div className="border-l-2 pl-8 mt-6 mb-2 border-gray-10">
              <TextFieldHeader
                dataTestID="field-api-endpoint-base-url-override"
                title="Base URL override"
                subtitle="Overrides integration level base URL with one specific to this endpoint"
                placeholder="https://{domain}/api"
                value={baseURLOverride}
                onChange={(e) => setBaseURLOverride(e.target.value)}
                hasSource={false}
              />
            </div>
          </Accordion>
        </div>
        <BadgeList
          className="mt-2"
          title="Path Parameters"
          helpText="Path parameters are variables in the URL path that are replaced with actual values when making a request. The parameters will appear here when specified in the path."
          items={pathParameters}
        />
        <PostmanTableHeader
          dataTestID="field-api-endpoint-query-param-schema"
          title="Query param schema"
          subtitle="Use JSON to autofill the table, or manually enter the schema key types below"
          rows={queryParamSchemaTableRows}
          setRows={setQueryParamSchemaTableRows}
          className="mt-6"
          valueHeader="Type"
          isSelectRow={true}
          selectOptions={DATA_TYPES}
        />
        <PostmanTableHeader
          dataTestID="field-api-endpoint-header-schema"
          title="Header schema"
          subtitle="Use JSON to autofill the table, or manually enter the schema key types below"
          rows={headerSchemaTableRows}
          setRows={setHeaderSchemaTableRows}
          className="mt-6"
          valueHeader="Type"
          isSelectRow={true}
          selectOptions={DATA_TYPES}
        />
        {renderProtocolSpecificOptions()}
        <TimestampFilterConfigurationSetupOptions />
        <AdvancedRequestConfigurationSetupOptions />
      </AccordionCard>
    );
  }
);

// We set up these wrappers to pass the props from the context to the component
// Any changes to the context (even if they are not used in the component) will trigger a re-render
const RequestConfigurationSetupOptions = () => {
  const {
    protocol,
    method,
    setMethod,
    path,
    setPath,
    baseURLOverride,
    setBaseURLOverride,
    pathParamSchema,
    setPathParamSchema,
    setQueryParamSchema,
    queryParamTableRows,
    setQueryParamTableRows,
    setHeaderSchema,
    headerSchemaTableRows,
    setHeaderSchemaTableRows,
  } = useContext(APIEndpointContext);

  return (
    <RequestConfigurationSetupOptionsWithProps
      protocol={protocol}
      method={method}
      setMethod={setMethod}
      path={path}
      setPath={setPath}
      baseURLOverride={baseURLOverride}
      setBaseURLOverride={setBaseURLOverride}
      pathParamSchema={pathParamSchema}
      setPathParamSchema={setPathParamSchema}
      setQueryParamSchema={setQueryParamSchema}
      queryParamTableRows={queryParamTableRows}
      setQueryParamTableRows={setQueryParamTableRows}
      setHeaderSchema={setHeaderSchema}
      headerSchemaTableRows={headerSchemaTableRows}
      setHeaderSchemaTableRows={setHeaderSchemaTableRows}
    />
  );
};

export default RequestConfigurationSetupOptions;
