import { ReactNode, useEffect, useMemo, useState } from "react";
import {
  LinkedAccountAccessRequest,
  LinkedAccountAccessRequestStatus,
  LinkedAccountAccessSession,
} from "../../../models/Entities";
import useFetchLinkedAccountAccessSession from "../hooks/useFetchLinkedAccountAccess";
import useFetchLinkedAccountAccessRequest from "../hooks/useFetchLinkedAccountAccessRequest";
import CustomerLinkedAccountContext from "./CustomerLinkedAccountAccessContext";
import useRevokeLinkedAccountAccess from "../hooks/useRevokeLinkedAccountAccess";
import usePatchLinkedAccountAccessRequest from "../hooks/usePatchLinkedAccountAccessRequest";

interface CustomerLinkedAccountContextProviderProps {
  children: ReactNode;
  integrationID: string;
  fetchTestLinkedAccounts: () => void;
}

const CustomerLinkedAccountContextProvider = ({
  children,
  integrationID,
  fetchTestLinkedAccounts,
}: CustomerLinkedAccountContextProviderProps) => {
  const [showRequestModal, setShowRequestModal] = useState<boolean>(false);
  const [showWithdrawRequestModal, setShowWithdrawRequestModal] = useState<boolean>(false);
  const [showRevokeSessionModal, setShowRevokeSessionModal] = useState<boolean>(false);
  const [
    currentAccessSession,
    setCurrentAccessSession,
  ] = useState<LinkedAccountAccessSession | null>(null);
  const [
    currentAccessRequest,
    setCurrentAccessRequest,
  ] = useState<LinkedAccountAccessRequest | null>(null);
  const [isUnauthorizedUser, setIsUnauthorizedUser] = useState<boolean>(true);
  const isAccessRequestOutsideCurrentIntegration = useMemo<boolean>(
    () =>
      !!currentAccessRequest &&
      currentAccessRequest.integration_id !== integrationID &&
      currentAccessRequest.status !== LinkedAccountAccessRequestStatus.WITHDRAWN &&
      !currentAccessRequest.is_result_acknowledged,
    [currentAccessRequest, integrationID]
  );
  const isAccessSessionOutsideCurrentIntegration = useMemo<boolean>(
    () => !!currentAccessSession && currentAccessSession.integration_id !== integrationID,
    [currentAccessSession, integrationID]
  );

  const { fetchLinkedAccountAccessSession } = useFetchLinkedAccountAccessSession({
    setLinkedAccountAccessSession: setCurrentAccessSession,
    setIsUnauthorizedUser,
  });

  const { fetchLinkedAccountAccessRequest } = useFetchLinkedAccountAccessRequest({
    setLinkedAccountAccessRequest: setCurrentAccessRequest,
    setIsUnauthorizedUser,
  });

  const { revokeLinkedAccountAccess } = useRevokeLinkedAccountAccess({
    accessSessionID: currentAccessSession?.id ?? null,
    setLinkedAccountAccessSession: setCurrentAccessSession,
    fetchTestLinkedAccounts,
  });

  const {
    acknowledgeLinkedAccountAccessRequest,
    withdrawLinkedAccountAccessRequest,
  } = usePatchLinkedAccountAccessRequest({
    accessRequestID: currentAccessRequest?.id ?? null,
    fetchLinkedAccountAccessRequest,
    fetchTestLinkedAccounts,
    fetchLinkedAccountAccessSession,
  });

  // When the current access session exists, fetch the linked account access session every 60 seconds
  useEffect(() => {
    if (currentAccessSession) {
      const interval = setInterval(() => {
        fetchLinkedAccountAccessSession();
      }, 60000);

      return () => clearInterval(interval);
    }

    return () => {};
  }, [currentAccessSession]);

  // When the current access request exists and the status is pending, fetch the linked account access request every 30 seconds
  useEffect(() => {
    if (
      currentAccessRequest &&
      currentAccessRequest.status === LinkedAccountAccessRequestStatus.PENDING
    ) {
      const interval = setInterval(() => {
        fetchLinkedAccountAccessRequest();
      }, 30000);

      return () => clearInterval(interval);
    }

    return () => {};
  }, [currentAccessRequest]);

  // Fetch the linked account access session and request when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetchLinkedAccountAccessSession();
    fetchLinkedAccountAccessRequest();
  };

  // This is the function that is called when the user clicks the request button
  // If the user is on a BP that is not the current integration and the user has an open access request, show the withdraw request modal
  // If the user is on a BP that is not the current integration and the user has an open access session, show the revoke session modal
  // Otherwise show the request modal
  const handleRequestButton = () => {
    if (isAccessRequestOutsideCurrentIntegration) {
      setShowWithdrawRequestModal(true);
    } else if (isAccessSessionOutsideCurrentIntegration) {
      setShowRevokeSessionModal(true);
    } else {
      setShowRequestModal(true);
    }
  };

  return (
    <CustomerLinkedAccountContext.Provider
      value={{
        integrationID,
        showRequestModal,
        setShowRequestModal,
        currentAccessSession,
        setCurrentAccessSession,
        currentAccessRequest,
        setCurrentAccessRequest,
        fetchLinkedAccountAccessSession,
        fetchLinkedAccountAccessRequest,
        handleRequestButton,
        isUnauthorizedUser,
        showWithdrawRequestModal,
        setShowWithdrawRequestModal,
        showRevokeSessionModal,
        setShowRevokeSessionModal,
        isAccessRequestOutsideCurrentIntegration,
        isAccessSessionOutsideCurrentIntegration,
        revokeLinkedAccountAccess,
        withdrawLinkedAccountAccessRequest,
        acknowledgeLinkedAccountAccessRequest,
        fetchTestLinkedAccounts,
      }}
    >
      {children}
    </CustomerLinkedAccountContext.Provider>
  );
};

export default CustomerLinkedAccountContextProvider;
