import { useParams } from "react-router-dom";
import AuthenticationConfigurationSetupOptions from "./AuthenticationConfigurationSetupOptions";
import { useEffect, useState } from "react";
import { AuthConfigIntegrationBuilder } from "../../../utils/Entities";
import AuthenticationConfigurationHeader from "../../AuthenticationConfigurationHeader";
import AuthConfigExplainAlert from "../shared/AuthConfigExplainAlert";

type RouteParams = {
  authConfigID: string | undefined;
};

interface AuthenticationConfigurationSetupProps {
  integrationID: string;
  isNewAuthConfig: boolean;
  authConfigs: AuthConfigIntegrationBuilder[] | undefined;
  setAuthConfigs: React.Dispatch<React.SetStateAction<AuthConfigIntegrationBuilder[] | undefined>>;
}

const AuthenticationConfigurationSetup = ({
  integrationID,
  isNewAuthConfig,
  authConfigs,
  setAuthConfigs,
}: AuthenticationConfigurationSetupProps) => {
  // params
  const { authConfigID } = useParams<RouteParams>();

  // const derived from state
  const selectedAuthConfig = authConfigs?.find((authConfig) => authConfig.id === authConfigID);

  // state
  const [isLoadingAuthConfigs, setIsLoadingAuthConfigs] = useState(true);

  // hook to wait for loading of auth config before mounting component
  useEffect(() => {
    if (authConfigs) {
      setIsLoadingAuthConfigs(false);
    }
  }, [authConfigs]);

  return (
    <div>
      <AuthenticationConfigurationHeader
        isNewAuthConfig={isNewAuthConfig}
        integrationID={integrationID}
      />
      <AuthConfigExplainAlert className="mb-6" />
      {!isLoadingAuthConfigs && (
        <AuthenticationConfigurationSetupOptions
          integrationID={integrationID}
          selectedAuthConfig={selectedAuthConfig}
          setAuthConfigs={setAuthConfigs}
          isNewAuthConfig={isNewAuthConfig}
        />
      )}
    </div>
  );
};

export default AuthenticationConfigurationSetup;
