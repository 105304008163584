import React from "react";

interface DeprecatedH6Props {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const DeprecatedH6: React.FC<DeprecatedH6Props> = ({ className, children, style }) => {
  return (
    <div className={`deprecatedh6 ${className}`} style={style}>
      {children}
    </div>
  );
};

export default DeprecatedH6;
