import { useMemo, useState } from "react";
import {
  BlueprintStep,
  BlueprintStepTemplate,
  BlueprintOperationType,
  BlueprintParameterSchema,
  BlueprintParameterValueType,
  BlueprintParameterValueConstant,
} from "../../../models/Blueprints";
import TypeaheadFormField from "./TypeaheadFormField";
import { PlusMinusToggle } from "../../shared/MergeToggles";
import { Accordion } from "react-bootstrap";
import useBlueprintContext from "../context/useBlueprintContext";
import { HeaderPretitle } from "../../shared/text/MergeText";
import { IntegrationCommonModelConfig } from "../../../models/Entities";
import { getCurrentStepParameterValue } from "../utils/BlueprintEditorUtils";
import DropdownFormField from "./DropdownFormField";

interface Props {
  stepID: string;
  stepTemplate: BlueprintStepTemplate;
}

const BlueprintEditorRightPanelManyToManyOverrideForm = ({ stepID, stepTemplate }: Props) => {
  const UID_TEMPLATE_KEY = "uid_template";
  const {
    blueprint,
    selectedStep,
    updateStepParameterValue,
    availableTemplateConfigs,
  } = useBlueprintContext();
  const step = selectedStep as BlueprintStep;
  const [selectedTemplate, setSelectedTemplate] = useState(() => {
    return getCurrentStepParameterValue(step, UID_TEMPLATE_KEY);
  });
  const [
    templates,
    templateFields,
    remoteDataFields,
    otherIdentifierFields,
    deprecatedFields,
    parameterFields,
  ] = useMemo(() => {
    function filterParametersByKey(filterIncludes: String[], filterExcludes: String[]) {
      return Object.fromEntries(
        Object.entries(stepTemplate.parameter_schema ?? {})
          .filter(([key]) => (filterIncludes.length > 0 ? filterIncludes.includes(key) : {}))
          .filter(([key]) => !filterExcludes.includes(key))
      );
    }

    function filterDeprecatedParameters(
      parameter_schema: BlueprintParameterSchema<string | null>,
      includeDeprecatedFields: boolean
    ) {
      return Object.fromEntries(
        Object.entries(parameter_schema ?? {}).filter(([, parameter]) =>
          includeDeprecatedFields ? parameter.deprecated : !parameter.deprecated
        )
      );
    }

    function getAvailableTemplatesForModel(templateConfigs: IntegrationCommonModelConfig[]) {
      return templateConfigs.find((config) => {
        return (
          config.category === stepTemplate.metadata["common_model"]["category"] &&
          config.model.replace(/\s+/g, "").toLowerCase() ===
            stepTemplate.metadata["common_model"]["name"].toLowerCase()
        );
      })?.unique_id_templates;
    }

    function getCurrentTemplateForStep() {
      return templates?.find((template) => {
        const currentTemplate = getCurrentStepParameterValue(step, UID_TEMPLATE_KEY);
        if (selectedTemplate) {
          return (
            template.id ===
            ((currentTemplate as BlueprintParameterValueConstant).constant["id"] as string)
          );
        } else {
          return null;
        }
      });
    }

    let templates = getAvailableTemplatesForModel(availableTemplateConfigs);

    let tempRemoteDataFields = filterParametersByKey(["remote_data", "remote_data_path"], []);

    let tempOtherIdentifierFields = filterParametersByKey(["id", "unique_identifier"], []);

    let tempDeprecatedFields = filterDeprecatedParameters(stepTemplate.parameter_schema, true);

    let tempParameterFields = filterDeprecatedParameters(
      filterParametersByKey(
        [],
        [
          "remote_data",
          "remote_data_path",
          "id",
          "unique_identifier",
          "remote_was_deleted",
          "ignore_id",
        ]
      ),
      false
    );

    let currentTemplate = getCurrentTemplateForStep();

    function getFieldsForCurrentTemplate() {
      if (!currentTemplate) {
        return [];
      }
      const templateFieldNames = currentTemplate.unique_id_fields.map((field) => {
        return field.field_name;
      });
      const templateFields = Object.fromEntries(
        Object.entries(tempParameterFields).filter(([key]) => templateFieldNames.includes(key))
      );
      tempParameterFields = Object.fromEntries(
        Object.entries(tempParameterFields).filter(([key]) => !templateFieldNames.includes(key))
      );
      return templateFields;
    }

    let templateFields = getFieldsForCurrentTemplate();
    return [
      templates,
      templateFields,
      tempRemoteDataFields,
      tempOtherIdentifierFields,
      tempDeprecatedFields,
      tempParameterFields,
    ];
  }, [stepID, stepTemplate, availableTemplateConfigs, selectedTemplate]);

  return (
    <>
      <form>
        {templates && (
          <>
            <HeaderPretitle className="my-2">Unique ID Parameters</HeaderPretitle>
            <DropdownFormField
              choices={templates.map((template) => {
                return {
                  name: template.template_name,
                  id: template.id,
                };
              })}
              currentValue={(selectedTemplate as BlueprintParameterValueConstant)?.constant}
              placeholder={"Select a UID template"}
              title={"UID Template"}
              onChange={(e) => {
                const thisTemplate = templates.find((template) => template.id === e.target.value);
                setSelectedTemplate({
                  constant: thisTemplate,
                  value_type: BlueprintParameterValueType.constant,
                });
                updateStepParameterValue(step, UID_TEMPLATE_KEY, {
                  constant: thisTemplate,
                  value_type: BlueprintParameterValueType.constant,
                });
              }}
            />
          </>
        )}
        {Object.entries(templateFields ?? {})
          .sort()
          .sort(function (a, b) {
            const keyA = a[0],
              keyB = b[0];

            if (keyA === "remote_id") return -1;
            if (keyB === "remote_id") return -1;
            return 0;
          })
          .map(([key, parameter]) => {
            return (
              <TypeaheadFormField
                key={key}
                title={parameter.title}
                subtitle={parameter.description}
                valueKey={key}
                parameterType={parameter.type}
              />
            );
          })}
        <HeaderPretitle className="my-2">
          {selectedTemplate ? "Other Parameters" : "Parameter Values"}
        </HeaderPretitle>
        {Object.entries(parameterFields ?? {})
          .sort()
          .sort(function (a, b) {
            const keyA = a[0],
              keyB = b[0];

            if (keyA === "remote_id") return -1;
            if (keyB === "remote_id") return -1;
            return 0;
          })
          .map(([key, parameter]) => {
            return (
              <TypeaheadFormField
                key={key}
                title={parameter.title}
                subtitle={parameter.description}
                valueKey={key}
                parameterType={parameter.type}
              />
            );
          })}
      </form>

      {blueprint.operation_type === BlueprintOperationType.FETCH &&
        Object.keys(remoteDataFields ?? {}).length > 0 && (
          <>
            <hr />
            <form>
              <HeaderPretitle className="my-2">Remote Data</HeaderPretitle>
              {Object.entries(remoteDataFields ?? {})
                .sort(function (a, b) {
                  const keyA = a[0],
                    keyB = b[0];

                  if (keyA === "remote_data_path") return -1;
                  if (keyB === "remote_data_path") return -1;
                  return 0;
                })
                .map(([key, parameter]) => {
                  return (
                    <TypeaheadFormField
                      key={key}
                      title={parameter.title}
                      subtitle={parameter.description}
                      valueKey={key}
                      parameterType={parameter.type}
                    />
                  );
                })}
            </form>
          </>
        )}
      <hr />
      <form>
        <HeaderPretitle className="my-2">Other Identifiers</HeaderPretitle>
        {Object.entries(otherIdentifierFields ?? {}).map(([key, parameter]) => {
          return (
            <TypeaheadFormField
              key={key}
              title={parameter.title}
              subtitle={parameter.description}
              valueKey={key}
              parameterType={parameter.type}
            />
          );
        })}
      </form>

      {Object.entries(deprecatedFields ?? {}).length > 0 && (
        <>
          <hr />
          <Accordion>
            <PlusMinusToggle eventKey="0">Deprecated Fields</PlusMinusToggle>
            <Accordion.Collapse eventKey="0" className="mt-3">
              <form>
                {Object.entries(deprecatedFields ?? {}).map(([key, parameter]) => {
                  return (
                    <TypeaheadFormField
                      key={key}
                      title={parameter.title}
                      subtitle={parameter.description}
                      valueKey={key}
                      parameterType={parameter.type}
                    />
                  );
                })}
              </form>
            </Accordion.Collapse>
          </Accordion>
        </>
      )}

      <hr />
    </>
  );
};

export default BlueprintEditorRightPanelManyToManyOverrideForm;
