import { Text } from "@merge-api/merge-javascript-shared";
import { MappingTestBlockExecutionError } from "../../../../models/MappingTests";
import { AlertCircle } from "lucide-react";
import MappingTestV2RightPanelErrorCard from "./MappingTestV2RightPanelErrorCard";

interface Props {
  errors: Array<MappingTestBlockExecutionError>;
}

const MappingTestV2RightPanelErrors = ({ errors }: Props) => {
  return (
    <div className="flex flex-column pt-4 pb-2 px-4">
      <div className="flex flex-row items-center space-x-2">
        <AlertCircle size={16} className="text-red-70" />
        <Text variant="h5" className="text-red-70">
          Errors
        </Text>
      </div>
      {errors.map((error, index) => {
        return (
          <div key={error.type}>
            <MappingTestV2RightPanelErrorCard error={error} />
            {index + 1 !== errors.length && <hr className="text-gray-50 h-[0.5px] my-0" />}
          </div>
        );
      })}
    </div>
  );
};

export default MappingTestV2RightPanelErrors;
