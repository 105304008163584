import { Alert, Text } from "@merge-api/merge-javascript-shared";
import { BlockExecutionSuggestionDict } from "../../../../models/MappingTests";
import { Lightbulb } from "lucide-react";

interface Props {
  errorSuggestions: Array<BlockExecutionSuggestionDict> | null;
}

const MappingTestV2RightPanelErrorSuggestions = ({ errorSuggestions }: Props) => {
  return (
    <div className="space-y-2">
      {errorSuggestions &&
        errorSuggestions.length > 0 &&
        errorSuggestions.map((suggestion) => {
          return (
            <Alert
              icon={<Lightbulb size={16} className="text-blue-50" />}
              title={
                <Text variant="sm" className="text-gray-90">
                  {suggestion.text}
                </Text>
              }
              color="blue"
            />
          );
        })}
    </div>
  );
};

export default MappingTestV2RightPanelErrorSuggestions;
