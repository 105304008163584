import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
  onClick?: () => void;
  tooltipId: string;
  tooltipContent?: React.ReactNode;
  tooltipPlacement?: "auto" | "top" | "right" | "bottom" | "left";
  variant?: string;
  className?: string;
  size?: "sm" | "lg";
  children?: React.ReactNode;
}

const ButtonWithTooltip = ({
  onClick,
  tooltipId,
  tooltipContent,
  tooltipPlacement,
  variant,
  className,
  size,
  children,
}: Props) => {
  const button = (
    <Button
      variant={variant || "white"}
      className={className}
      size={size || "sm"}
      onClick={onClick}
    >
      {children}
    </Button>
  );

  if (!tooltipContent) {
    return button;
  }

  return (
    <OverlayTrigger
      placement={tooltipPlacement || "auto"}
      overlay={
        <Tooltip className="step-card-log-icon-tooltip" id={`${tooltipId}`}>
          {tooltipContent}
        </Tooltip>
      }
    >
      {button}
    </OverlayTrigger>
  );
};

export default ButtonWithTooltip;
