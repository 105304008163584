import { Text } from "@merge-api/merge-javascript-shared";

interface Props {
  dataKey: string;
  dataValue: any;
}

/**
 * Renders a row in the StepIOLogsTableCardSection component.
 *
 * @param {string} dataKey - The key of the data to be displayed.
 * @param {any} dataValue - The value of the data to be displayed.
 */
const StepIOLogsTableCardSectionRow = ({ dataKey, dataValue }: Props) => {
  const displayValue =
    typeof dataValue === "object" && dataValue !== null
      ? JSON.stringify(dataValue, null, 2)
      : dataValue !== null
      ? dataValue.toString()
      : dataValue;

  return (
    <div className="flex flex-row hover:bg-gray-0 border-t-[1px] border-gray-10 align-top space-x-4 px-1">
      <Text variant="sm-mono" className="w-[150px] break-all">
        {dataKey}
      </Text>
      <Text as="pre" variant="sm-mono" className="w-full m-0 max-h-[200px]">
        {displayValue}
      </Text>
    </div>
  );
};

export default StepIOLogsTableCardSectionRow;
