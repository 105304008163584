import React from "react";

import SelectiveSyncFilterBuilderContext, {
  SelectiveSyncFilterBuilderContextState,
} from "./SelectiveSyncFilterBuilderContext";

// Wraps around BlueprintEditor to be able to pull global state anywhere in tree via useContext
const useSelectiveSyncFilterBuilderContext = (): SelectiveSyncFilterBuilderContextState =>
  React.useContext(SelectiveSyncFilterBuilderContext);

export default useSelectiveSyncFilterBuilderContext;
