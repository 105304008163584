import { useCallback, useState } from "react";
import { APIEndpointParameter } from "src/autogenerated-types/AUTOGENERATED_ExpandedPydantic_APIEndpointParameter";
import {
  APIEndpointParameterAPIResponse,
  VersionedComponentAPIActions,
  VersionedComponentAPIComponentKeys,
  VersionedComponentInfo,
  VersionedComponentsAPIRequestData,
} from "../../../../../versioned-components/types";
import { makeVersionedComponentsAPIRequest } from "../../../../VersionedComponentsAPIClient";

interface loadAPIEndpointParameterProps {
  integrationID: string;
  setAvailableAPIEndpointParameters: React.Dispatch<React.SetStateAction<APIEndpointParameter[]>>;
}

const useLoadAPIEndpointParameters = ({
  integrationID,
  setAvailableAPIEndpointParameters,
}: loadAPIEndpointParameterProps) => {
  // State
  const [isLoadingAPIEndpointParameters, setIsLoadingAPIEndpointParameters] = useState<boolean>(
    true
  );

  const getAPIEndpointParameterFromVersionedComponentInfo = (
    componentInfo: VersionedComponentInfo<APIEndpointParameter>
  ) => {
    return (
      componentInfo?.next_version ?? (componentInfo?.published_version as APIEndpointParameter)
    );
  };

  // Fetch all APIEndpointParameters for integration
  const loadAPIEndpointParameters = useCallback(() => {
    setIsLoadingAPIEndpointParameters(true);
    const request_body: VersionedComponentsAPIRequestData = {
      component_key: VersionedComponentAPIComponentKeys.APIEndpointParameter,
    };

    makeVersionedComponentsAPIRequest({
      integrationID,
      versioned_components_api_action:
        VersionedComponentAPIActions.GET_COMPONENTS_FOR_COMPONENT_CLASS,
      request_body,
      onResponse: (data: APIEndpointParameterAPIResponse) => {
        const apiEndpointParameters = Object.values(data).map((apiEndpointParameter) =>
          getAPIEndpointParameterFromVersionedComponentInfo(apiEndpointParameter)
        );
        setAvailableAPIEndpointParameters(apiEndpointParameters);
        setIsLoadingAPIEndpointParameters(false);
      },
    });
  }, [integrationID, setAvailableAPIEndpointParameters]);

  return { isLoadingAPIEndpointParameters, loadAPIEndpointParameters };
};

export default useLoadAPIEndpointParameters;
