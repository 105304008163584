import classNames from "classnames";
import { Text } from "@merge-api/merge-javascript-shared";
import { HTTPMethod } from "../../../models/HTTPMethods";

interface HTTPMethodTextProps {
  method: string;
  className?: string;
}

const HTTPMethodText = ({ method, className }: HTTPMethodTextProps) => {
  let color = "text-blue";
  const formattedMethod = method.toUpperCase();

  switch (formattedMethod) {
    case HTTPMethod.GET:
      color = "text-blue";
      break;
    case HTTPMethod.POST:
      color = "text-green";
      break;
    case HTTPMethod.PUT:
      color = "text-yellow";
      break;
    case HTTPMethod.PATCH:
      color = "text-purple";
      break;
    case HTTPMethod.DELETE:
      color = "text-red";
      break;
    default:
      color = "text-blue";
      break;
  }

  return <Text className={classNames("font-semibold", className, color)}>{method}</Text>;
};

export default HTTPMethodText;
