import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { BlueprintParameterValueType, BlueprintStep } from "../../../../models/Blueprints";
import useBlueprintContext from "../../context/useBlueprintContext";
import { getCurrentStepParameterValue } from "../../utils/BlueprintEditorUtils";
import ParameterSchemaEditorModal from "./ParameterSchemaEditorModal";
import { CustomJSONModalContext } from "./useCustomJSONModalContext";

type Props = CustomJSONModalContext & {
  parameterKey: string;
  parameter?: any;
  enableSchemaEditor?: boolean;
};

const CustomJSONOptions = <T extends BlueprintStep>(props: Props) => {
  const {
    parameter,
    parameterKey,
    setParameterSchema,
    setRootParameterKey,
    setIsShowing,
    enabledRootKeys,
    setEnabledRootKeys,
    enableSchemaEditor,
  } = props;
  const [isShowingSchemaEditor, setIsShowingSchemaEditor] = useState(false);
  const [schema, setSchema] = useState<any>(parameter);
  const { selectedStep, updateStepParameterValue } = useBlueprintContext();
  const step = selectedStep as T;

  useEffect(() => {
    // Load the hidden parameter value storing our custom schema if it exists.
    const currentCustomParameterSchema = getCurrentStepParameterValue(
      step,
      "__customParameterSchema"
    );
    if (
      !!currentCustomParameterSchema &&
      currentCustomParameterSchema.value_type == BlueprintParameterValueType.constant
    ) {
      setSchema(JSON.parse(currentCustomParameterSchema.constant));
    }
  }, []);

  useEffect(() => {
    if (enableSchemaEditor && schema) {
      updateStepParameterValue(step, "__customParameterSchema", {
        constant: JSON.stringify(schema),
        value_type: BlueprintParameterValueType.constant,
      });
    }
  }, [schema]);

  return (
    <>
      <ParameterSchemaEditorModal
        isShowingSchemaEditor={isShowingSchemaEditor}
        setIsShowingSchemaEditor={setIsShowingSchemaEditor}
        setSchema={(schema) => {
          setSchema(schema);
          updateStepParameterValue(step, parameterKey, {
            value_type: BlueprintParameterValueType.none,
          });
        }}
        schema={schema}
      />
      <div className="mb-6 mt-n4">
        <Form.Check
          type="checkbox"
          label="Use custom object"
          defaultChecked={enabledRootKeys[parameterKey]}
          className="mb-1.5"
          onClick={() =>
            setEnabledRootKeys({
              ...enabledRootKeys,
              [parameterKey]: !enabledRootKeys[parameterKey],
            })
          }
        />
        {enabledRootKeys[parameterKey] && (
          <Button
            size="sm"
            className="mb-1.5"
            variant="outline-primary"
            onClick={() => {
              setParameterSchema(schema);
              setRootParameterKey(parameterKey);
              setIsShowing(true);
            }}
          >
            Open custom object editor
          </Button>
        )}
        {enabledRootKeys[parameterKey] && enableSchemaEditor && (
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => {
              setIsShowingSchemaEditor(true);
            }}
          >
            Set custom object schema
          </Button>
        )}
      </div>
    </>
  );
};

export default CustomJSONOptions;
