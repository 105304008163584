import { Asterisk } from "lucide-react";
import { Text } from "@merge-api/merge-javascript-shared";

interface Props {
  size?: number;
  showText?: boolean;
}

const DEFAULT_SIZE = 12;
const RequiredFieldAsterisk = ({ size, showText }: Props) => {
  if (!showText) return <Asterisk color="red" size={size ?? DEFAULT_SIZE} />;

  return (
    <>
      <Asterisk color="red" size={size ?? DEFAULT_SIZE} />
      <Text className="ml-1 text-red-50">Required Field</Text>
    </>
  );
};

export default RequiredFieldAsterisk;
