import {
  BreakValueType,
  EndOfPaginationType,
  MaxItemsValueLocation,
  PageSizeRequestLocation,
  PaginationType,
  PaginationValueRequestLocation,
  PointerForNextRequestResponseLocation,
} from "../../../models/PaginationConfigurationModels";
import { SelectOptionsData, StringOptionsData } from "../utils/types";

export const KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER =
  'Example: "key_1" [Enter] "key_2" [Enter] "key_3" [Enter]';
export const KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER = "Add key name...";

export const PAGINATION_CONFIG_END_OF_PAGINATION_OPTIONS: StringOptionsData[] = [
  { value: EndOfPaginationType.EMPTY_ARRAY, text: "Empty array" },
  { value: EndOfPaginationType.MAX_ITEMS, text: "Total # of items" },
  { value: EndOfPaginationType.BREAK_VALUE, text: "Break value" },
];

export const MAX_ITEMS_VALUE_LOCATION_OPTIONS: StringOptionsData[] = [
  { value: MaxItemsValueLocation.RESPONSE_BODY, text: "Body" },
  { value: MaxItemsValueLocation.RESPONSE_HEADER, text: "Header" },
];

export const POINTER_FOR_NEXT_REQUEST_RESPONSE_LOCATION_OPTIONS: StringOptionsData[] = [
  { value: PointerForNextRequestResponseLocation.RESPONSE_BODY, text: "Body" },
  { value: PointerForNextRequestResponseLocation.RESPONSE_HEADER, text: "Header" },
];

export const PAGE_SIZE_REQUEST_LOCATION_OPTIONS: StringOptionsData[] = [
  { value: PageSizeRequestLocation.QUERY_PARAM, text: "Query param" },
  { value: PageSizeRequestLocation.BODY_PARAM, text: "Body param" },
];

export const BREAK_VALUE_TYPE_OPTIONS: StringOptionsData[] = [
  { value: BreakValueType.BREAK_WHEN_FALSE, text: "Boolean value" },
  { value: BreakValueType.BREAK_WHEN_SPECIFIC_VALUE, text: "Specific value" },
];

export const SORT_FIELD_FILTER_REQUEST_LOCATION_OPTIONS: StringOptionsData[] = [
  { value: "QUERY_PARAM", text: "Query param" },
  { value: "PATH_PARAM", text: "Path param" },
  { value: "HEADER_PARAM", text: "Header param" },
  { value: "BODY_PARAM", text: "Body param" },
];

export const POINTER_PAGINATION_VALUE_REQUEST_LOCATION_OPTIONS: StringOptionsData[] = [
  { value: PaginationValueRequestLocation.PATH, text: "Path" },
  { value: PaginationValueRequestLocation.URL, text: "URL" },
  { value: PaginationValueRequestLocation.PATH_PARAM, text: "Path param" },
  { value: PaginationValueRequestLocation.QUERY_PARAM, text: "Query param" },
  { value: PaginationValueRequestLocation.BODY_PARAM, text: "Body param" },
];

export const PAGINATION_VALUE_REQUEST_LOCATION_OPTIONS: StringOptionsData[] = [
  { value: PaginationValueRequestLocation.URL, text: "URL" },
  { value: PaginationValueRequestLocation.PATH_PARAM, text: "Path param" },
  { value: PaginationValueRequestLocation.QUERY_PARAM, text: "Query param" },
  { value: PaginationValueRequestLocation.BODY_PARAM, text: "Body param" },
];

export const PAGINATION_TYPE_TO_DISPLAY_NAME = {
  [PaginationType.POINTER]: "Pointer",
  [PaginationType.OFFSET]: "Offset",
  [PaginationType.PAGES]: "Pages",
};

export const PAGINATION_TYPE_SELECT_OPTIONS: SelectOptionsData[] = [
  {
    value: PaginationType.POINTER,
    title: PAGINATION_TYPE_TO_DISPLAY_NAME[PaginationType.POINTER],
    subtitle:
      "Select this option if the API returns a specific pointer, ID, or other value to directly pass into the next query",
  },
  {
    value: PaginationType.OFFSET,
    title: PAGINATION_TYPE_TO_DISPLAY_NAME[PaginationType.OFFSET],
    subtitle:
      "Select this option if the API specifies a starting point and number of items to retrieve",
  },
  {
    value: PaginationType.PAGES,
    title: PAGINATION_TYPE_TO_DISPLAY_NAME[PaginationType.PAGES],
    subtitle:
      "Select this option if the API requires you to request a specific page number to access different sections of the dataset",
  },
];
