import { Accordion } from "react-bootstrap";
import MergeTable from "../../../shared/MergeTable";
import TableSkeletonLoader from "../../../shared/TableSkeletonLoader";
import EmptyStateWrapper from "../../../shared/layout/EmptyStateWrapper";
import { ChangelogEvent } from "../utils/types";
import ChangelogTableRow from "./ChangelogTableRow/ChangelogTableRow";

interface ChangelogTableProps {
  changelogEvents?: ChangelogEvent[];
  isLoading: boolean;
  className?: string;
}

const ChangelogTable = ({ className, changelogEvents = [], isLoading }: ChangelogTableProps) => {
  return (
    <div className={className}>
      <Accordion>
        <MergeTable
          hover={false}
          hasMorePaddingOnFirstElement
          header={
            <>
              <th scope="col">User</th>
              <th scope="col">Model</th>
              <th scope="col">Event type</th>
              <th scope="col">Date</th>
              <th scope="col" />
            </>
          }
          content={
            isLoading ? (
              <TableSkeletonLoader cols={4} rows={8} size="large" />
            ) : changelogEvents.length ? (
              changelogEvents.map((changelogEvent) => (
                <ChangelogTableRow key={changelogEvent.id} changelogEvent={changelogEvent} />
              ))
            ) : (
              <tr>
                <td colSpan={5} className="py-9">
                  <EmptyStateWrapper isTable title="No changelog events" />
                </td>
              </tr>
            )
          }
        />
      </Accordion>
    </div>
  );
};

export default ChangelogTable;
