import {
  BlueprintCanvasBaseStepLogsTree,
  StepLogIterationInfo,
} from "../../../../models/Blueprints";
import { showWarningToast } from "../../../shared/Toasts";

/**
 * Finds the index in raw logs for a selected iteration of a step.
 * @param stepID - The ID of the step.
 * @param selectedIterationInfo - Information about the selected iteration.
 * @param baseStepLogsTreeForCanvas - The tree structure of step logs for the canvas.
 * @returns The index in raw logs, or undefined if not found or an error occurs.
 */
export const findIndexInRawLogsFromSelectedIteration = (
  stepID: string,
  selectedIterationInfo: StepLogIterationInfo,
  baseStepLogsTreeForCanvas: BlueprintCanvasBaseStepLogsTree
): number | undefined => {
  try {
    const stepLogsSubTree = findSubTreeForSelectedIterationInfo(
      selectedIterationInfo,
      baseStepLogsTreeForCanvas
    );
    if (!stepLogsSubTree) return undefined;
    return findIndexOfRawLogsForIterationInSubTree(stepID, selectedIterationInfo, stepLogsSubTree);
  } catch (error) {
    showWarningToast("Error rendering selected step I/O iteration in canvas");
    console.error(error);
    return undefined;
  }
};

/**
 * Finds the subtree in the step logs tree that corresponds to the selected iteration.
 * 
 * Example tree
 * {
    "get_api_request_loop": {
      "loop_iterations": [],
      "api_requests": [
          {
          "index_in_raw_logs": 0,
          "loop_iterations": [
              {
              "index_in_raw_logs": 1,
              "children_loop_steps": {
                  "nested_array_loop": {
                      "loop_iterations": [
                          {
                          "index_in_raw_logs": 4,
                          }
                      ]
                  }
                  }
              }
          ]
          }
      }
    }
 * This function can find the 2nd iteration of "get_api_requests_loop" of the "selectedIterationInfo" 
 * By following the path along the tree
 * 
 * @param selectedIterationInfo - Information about the selected iteration.
 * @param baseStepLogsTreeForCanvas - The tree structure of step logs for the canvas.
 * @returns The subtree corresponding to the selected iteration, or undefined if not found.
 */
const findSubTreeForSelectedIterationInfo = (
  selectedIterationInfo: StepLogIterationInfo,
  baseStepLogsTreeForCanvas: BlueprintCanvasBaseStepLogsTree
): BlueprintCanvasBaseStepLogsTree | undefined => {
  let indexOfParentIterationInfo: number = 0;
  let stepLogsTree: BlueprintCanvasBaseStepLogsTree = { ...baseStepLogsTreeForCanvas };
  // Follow path along baseTree, with path defined by "selectedIterationInfo"
  while (indexOfParentIterationInfo < selectedIterationInfo.parent_iteration_info.length) {
    const parentIterationInfo =
      selectedIterationInfo.parent_iteration_info[indexOfParentIterationInfo];
    const parentStepID = parentIterationInfo.step_id;
    const indexInAPIRequests = parentIterationInfo.index_in_api_requests;
    const indexInLoopIterations = parentIterationInfo.index_in_loop_iterations;
    const parentStepLog = stepLogsTree[parentStepID];
    // If lives within paginated API request, navigate to the next level accordingly
    if (indexInAPIRequests !== undefined) {
      const apiRequests = parentStepLog.api_requests;
      if (apiRequests) {
        if (indexInLoopIterations !== undefined) {
          stepLogsTree =
            apiRequests[indexInAPIRequests].loop_iterations[indexInLoopIterations]
              .children_loop_steps;
        }
        // A parent_iteration_info should never contain null "index_in_loop_iterations"
        else return undefined;
      }
    }
    // If lives within loop iteration, navigate to the next level accordingly
    else if (indexInLoopIterations !== undefined) {
      stepLogsTree = parentStepLog.loop_iterations[indexInLoopIterations].children_loop_steps;
    }
    indexOfParentIterationInfo = indexOfParentIterationInfo + 1;
  }
  return stepLogsTree;
};

/**
 * Finds the index of raw logs for a specific iteration in a subtree of step logs.
 * @param stepID - The ID of the step.
 * @param selectedIterationInfo - Information about the selected iteration.
 * @param stepLogsSubTree - The subtree of step logs to search in.
 * @returns The index in raw logs, or undefined if not found.
 */
const findIndexOfRawLogsForIterationInSubTree = (
  stepID: string,
  selectedIterationInfo: StepLogIterationInfo,
  stepLogsSubTree: BlueprintCanvasBaseStepLogsTree
): number | undefined => {
  const indexInAPIRequests = selectedIterationInfo.index_in_api_requests;
  const indexInLoopIterations = selectedIterationInfo.index_in_loop_iterations;
  if (indexInAPIRequests !== undefined) {
    const apiRequests = stepLogsSubTree[stepID].api_requests;
    if (apiRequests) {
      if (indexInLoopIterations !== undefined) {
        return apiRequests[indexInAPIRequests].loop_iterations[indexInLoopIterations]
          .index_in_raw_logs;
      } else {
        return apiRequests[indexInAPIRequests].index_in_raw_logs;
      }
    }
  } else if (indexInLoopIterations !== undefined) {
    return stepLogsSubTree[stepID].loop_iterations[indexInLoopIterations].index_in_raw_logs;
  }
  return undefined;
};
