import { IntegrationCategoryStatusInfo } from "../../../../../../models/Entities";
import CategoryStatusBadge from "./CategoryStatusBadge";

type CategoryCellProps = {
  category_statuses?: IntegrationCategoryStatusInfo[];
};

const CategoryCell = ({ category_statuses }: CategoryCellProps) => {
  return (
    <td>
      <div>
        {category_statuses?.map((category_status) => (
          <CategoryStatusBadge key={category_status.category} category_status={category_status} />
        ))}
      </div>
    </td>
  );
};

export default CategoryCell;
