import TypeaheadFormField from "./TypeaheadFormField";
import { HeaderPretitle } from "../../shared/text/MergeText";

const BlueprintEditorRightPanelGetFirstInListForm = () => {
  return (
    <form>
      <HeaderPretitle>Parameters</HeaderPretitle>
      <TypeaheadFormField
        title="Target List"
        subtitle="Select an array returned by a previous step."
        valueKey="array"
        parameterType={"array"}
      />
      <TypeaheadFormField
        title="Target Key Path"
        subtitle="Optional key path used to filter object list, e.g. name or user.name"
        valueKey="target_key_path"
        parameterType={"string"}
      />
      <TypeaheadFormField
        title="Target Value"
        subtitle="Optional value to stringmatch with optional key."
        valueKey="target_value"
        parameterType={"string"}
      />
    </form>
  );
};

export default BlueprintEditorRightPanelGetFirstInListForm;
