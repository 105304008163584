import { Badge } from "@merge-api/merge-javascript-shared";
import { ComponentState } from "../../versioned-components/types";

interface Props {
  publishState: ComponentState;
}

const VersionedComponentPublishStateDescriptionBadge = ({ publishState }: Props) => {
  const draftBadge = <Badge color="gray">Draft</Badge>;
  const stagedBadge = <Badge color="yellow">Staged</Badge>;
  const publishedBadge = <Badge color="teal">Published</Badge>;

  switch (publishState) {
    case ComponentState.PUBLISHED_WITH_NEW_DRAFT:
      return (
        <>
          {publishedBadge} with {draftBadge}
        </>
      );
    case ComponentState.NEW_COMPONENT_WITH_DRAFT_VERSION:
      return draftBadge;
    case ComponentState.PUBLISHED_WITH_NEW_STAGED_VERSION:
      return (
        <>
          {publishedBadge} with {stagedBadge}
        </>
      );
    case ComponentState.NEW_COMPONENT_WITH_STAGED_VERSION:
      return stagedBadge;
    case ComponentState.PUBLISHED:
      return publishedBadge;
    case ComponentState.MARKED_FOR_DELETION:
      return <Badge color="red">Marked for Deletion</Badge>;
    default:
      return <Badge color="gray">{publishState}</Badge>;
  }
};

export default VersionedComponentPublishStateDescriptionBadge;
