import { APICategory } from "./models/Entities";
import {
  DOCUMENTATION_ORDER,
  ATS_COMMON_MODEL_TYPES,
  HRIS_COMMON_MODEL_TYPES,
  ACCOUNTING_COMMON_MODEL_TYPES,
  TICKETING_COMMON_MODEL_TYPES,
  CRM_COMMON_MODEL_TYPES,
  MKTG_COMMON_MODEL_TYPES,
  FILESTORAGE_COMMON_MODEL_TYPES,
  IntegrationChecklistData,
  ChecklistStatusValue,
  DATAWAREHOUSE_COMMON_MODEL_TYPES,
  supportedIntegrationChecklistData,
} from "./constants";
import queryString from "query-string";
import { AbstractCondition } from "./components/integration-builder/selective-sync-filters/types";

export function firstLetterUpperCase(string: string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  return string;
}

export function snakeCaseToCamelCase(string: string) {
  if (string) {
    return string
      .split("_")
      .map((word) => {
        return firstLetterUpperCase(word);
      })
      .join("");
  }
  return string;
}

export function snakeCaseToFirstLetterUpperCase(string: string) {
  if (string) {
    const camelCaseString = snakeCaseToCamelCase(string);
    return camelCaseString.replace(/([A-Z])/g, " $1").trim();
  }
  return string;
}

export function camelCaseToSentenceCase(string: string): string {
  if (string) {
    const spacedString = string.replace(/([a-z])([A-Z])/g, "$1 $2");
    return spacedString.charAt(0).toUpperCase() + spacedString.slice(1).toLowerCase();
  }
  return string;
}

export function stringRemoveSpaces(string: string) {
  if (string) {
    return string.replaceAll(" ", "");
  }
  return string;
}

export function formatCommonModelId(category: string, commonModelName: string) {
  if (commonModelName === "EEOC") {
    return category + "." + commonModelName;
  }
  return category + "." + stringRemoveSpaces(snakeCaseToFirstLetterUpperCase(commonModelName));
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function getCursorPath(path: string) {
  const start_index = path.indexOf("cursor");
  if (start_index === -1) {
    return "";
  }

  const new_path = path.substring(start_index);
  return new_path;
}

export function getOffsetPath(path: string) {
  const { offset, limit } = queryString.parse(path);
  return `offset=${offset}&limit=${limit}`;
}

export function isValidJSON(str: string) {
  try {
    JSON.parse(str);
  } catch {
    return false;
  }
  return true;
}

export function isValidXML(str: string) {
  const parser = new window.DOMParser();
  const theDom = parser.parseFromString(str, "application/xml");
  if (theDom.getElementsByTagName("parsererror").length > 0) {
    return false;
  } else {
    return true;
  }
}

export function removeValueFromStringArray(array: Array<string>, string: string) {
  const new_array = [...array];
  const index = new_array.indexOf(string);
  if (index !== -1) {
    new_array.splice(index, 1);
  }
  return new_array;
}

// Assumes Capitalization writing
export function getCategoryFromCommonModel(commonModel: string): string | null {
  const reformattedCommonModel = commonModel.toUpperCase().replaceAll(" ", "_");

  if (Object.keys(HRIS_COMMON_MODEL_TYPES).indexOf(reformattedCommonModel) !== -1) {
    return APICategory.hris;
  } else if (Object.keys(ATS_COMMON_MODEL_TYPES).indexOf(reformattedCommonModel) !== -1) {
    return APICategory.ats;
  } else if (Object.keys(ACCOUNTING_COMMON_MODEL_TYPES).indexOf(reformattedCommonModel) !== -1) {
    return APICategory.ticketing;
  } else if (Object.keys(TICKETING_COMMON_MODEL_TYPES).indexOf(reformattedCommonModel) !== -1) {
    return APICategory.accounting;
  } else if (Object.keys(CRM_COMMON_MODEL_TYPES).indexOf(reformattedCommonModel) !== -1) {
    return APICategory.crm;
  } else if (Object.keys(MKTG_COMMON_MODEL_TYPES).indexOf(reformattedCommonModel) !== -1) {
    return APICategory.mktg;
  } else if (Object.keys(FILESTORAGE_COMMON_MODEL_TYPES).indexOf(reformattedCommonModel) !== -1) {
    return APICategory.filestorage;
  } else if (Object.keys(DATAWAREHOUSE_COMMON_MODEL_TYPES).indexOf(reformattedCommonModel) !== -1) {
    return APICategory.datawarehouse;
  } else {
    return null;
  }
}

export function numberWithCommas(x: number | null) {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0";
}

export function sortDocumentation(a: string | any, b: string | any) {
  const aIndex = DOCUMENTATION_ORDER.indexOf(a);
  const bIndex = DOCUMENTATION_ORDER.indexOf(b);
  if (aIndex !== -1 && bIndex !== -1) {
    if (aIndex < bIndex) {
      return -1;
    }
    if (aIndex > bIndex) {
      return 1;
    }
  }
  if (aIndex !== -1) {
    return -1;
  }
  if (bIndex !== -1) {
    return 1;
  }
  return 0;
}

export const getParsedDateInMilliseconds = (timeAsString: string) => {
  if (timeAsString.indexOf("Z") < 0) {
    timeAsString += "Z";
  }
  return Date.parse(timeAsString);
};

export const getDifferenceInDatesInMilliseconds = (start_time: string, end_time: string) => {
  return getParsedDateInMilliseconds(end_time) - getParsedDateInMilliseconds(start_time);
};

export const getLastSegmentOfURLPath = (url: string) => {
  const urlWithoutQueryParams = url.split("?")[0];
  return urlWithoutQueryParams.split("/").pop();
};

export const isAnyConfigNotImplemented = (checklistData: IntegrationChecklistData): boolean => {
  for (const [key, value] of Object.entries(checklistData)) {
    if (
      supportedIntegrationChecklistData.includes(key) &&
      value === ChecklistStatusValue.NOT_IMPLEMENTED
    )
      return true;
  }
  return false;
};

// functions for calculating if frozen time / last modified timestamp is correctly inputted
export function validateTimeInput(value: string): { isValid: boolean; errorMsg?: string } {
  if (value === null || value === undefined || value.trim() === "") {
    return { isValid: true };
  }

  let isValid = true;
  let errorMsg = "";

  if (value && value[value.length - 1].toLowerCase() === "z") {
    value = value.slice(0, -1);
  }

  try {
    const date = new Date(value);
    if (isNaN(date.getTime())) {
      isValid = false;
      errorMsg = "Incorrect format for time input";
    }
  } catch (e) {
    isValid = false;
    errorMsg = "Incorrect format for time input";
  }

  return { isValid, errorMsg };
}

export const isAbstractConditionsForSelectiveSyncValid = (
  abstractConditions: AbstractCondition[]
): boolean => {
  let isValid = true;
  abstractConditions.forEach((abstractCondition) => {
    if (
      abstractCondition.filter_type_id === "" ||
      abstractCondition.integration_id === "" ||
      abstractCondition.integration_id === null ||
      abstractCondition.operator === "" ||
      abstractCondition.value.value === "" ||
      abstractCondition.value.typed_value === ""
    ) {
      isValid = false;
    }
  });
  return isValid;
};
