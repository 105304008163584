import useScraperContext from "./context/useScraperContext";
import ScraperCheckboxField from "./ScraperCheckboxField";
import ScraperElementSelectorField from "./ScraperElementSelectorField";
import ScraperInputField from "./ScraperInputField";
import { ScraperQueryAndEvalStep } from "./types";

const ScraperQueryAndEvalFields = () => {
  const { selectedStep, updateStepParameterValue } = useScraperContext();
  const step = selectedStep as ScraperQueryAndEvalStep;
  const {
    element_selector,
    should_fail_if_not_found,
    should_query_all,
    eval_expression,
  } = step.parameter_values;

  return (
    <div>
      <ScraperElementSelectorField
        updateValue={(newValue) => updateStepParameterValue(step, "element_selector", newValue)}
        value={element_selector}
      />
      <ScraperCheckboxField
        valueKey={"should_query_all"}
        updateValue={(newValue) => updateStepParameterValue(step, "should_query_all", newValue)}
        value={should_query_all}
      />
      <ScraperCheckboxField
        valueKey={"should_fail_if_not_found"}
        updateValue={(newValue) =>
          updateStepParameterValue(step, "should_fail_if_not_found", newValue)
        }
        value={should_fail_if_not_found}
      />

      <ScraperInputField
        valueKey={"eval_expression"}
        updateValue={(newValue) => updateStepParameterValue(step, "eval_expression", newValue)}
        value={eval_expression}
      />
    </div>
  );
};

export default ScraperQueryAndEvalFields;
