import classNames from "classnames";

interface Props {
  className?: string;
}

function Spinner({ className }: Props) {
  return (
    <div className={classNames("text-center w-100", className)}>
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default Spinner;
