import InputFormField from "../blueprint-editor/right-panel/InputFormField";
import { ScraperConstantValue, ScraperGlobalVariableValue, ScraperValueType } from "./types";

type Props = {
  valueKey: string;
  value: ScraperConstantValue | ScraperGlobalVariableValue;
  updateValue: (value: ScraperConstantValue | ScraperGlobalVariableValue) => void;
};

const ScraperInputField = ({ valueKey, value, updateValue }: Props) => {
  const currentValue =
    value.value_type === ScraperValueType.CONSTANT ? value.constant : value.global_variable_key;

  const updateField = (newValue: string) =>
    updateValue({
      ...value,
      [value.value_type === ScraperValueType.CONSTANT
        ? "constant"
        : "global_variable_key"]: newValue,
    });

  return (
    <InputFormField onChange={updateField} currentValue={currentValue as string} title={valueKey} />
  );
};
export default ScraperInputField;
