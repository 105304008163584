import { ArrowUpRight } from "lucide-react";

interface ViewSourceTextProps {
  onClick?: () => void;
}

const ViewSourceText = ({ onClick }: ViewSourceTextProps) => {
  return (
    <div
      onClick={onClick}
      className="ml-2 flex flex-row items-center text-blue-40 hover:text-blue-60 cursor-pointer whitespace-nowrap"
    >
      <div className="text-sm font-medium">View source</div>
      <ArrowUpRight className="ml-1" size={12} />
    </div>
  );
};

export default ViewSourceText;
