import { HeaderPretitle } from "../../shared/text/MergeText";
import TypeaheadFormField from "./TypeaheadFormField";
import useBlueprintContext from "../context/useBlueprintContext";
import { checkNeedsRemoteData } from "../utils/BlueprintEditorUtils";
import NeedsRemoteDataToggle from "./NeedsRemoteDataToggle";

interface Props {
  stepID: string;
}

const BlueprintEditorRightPanelGetOrCreateByRemoteIDForm = ({ stepID }: Props) => {
  const { selectedStep, setStepNeedsRemoteData } = useBlueprintContext();

  const updateNeedsRemoteData = () => {
    setStepNeedsRemoteData(stepID, !needsRemoteData);
  };
  const needsRemoteData = checkNeedsRemoteData(selectedStep);

  return (
    <>
      <NeedsRemoteDataToggle
        updateNeedsRemoteData={updateNeedsRemoteData}
        needsRemoteData={needsRemoteData}
      />
      <HeaderPretitle className="my-2">Parameters</HeaderPretitle>
      <TypeaheadFormField
        key={"remote_id"}
        title={"Remote ID"}
        subtitle={"The third-party API ID of the matching object."}
        valueKey={"remote_id"}
        parameterType={"string"}
      />
    </>
  );
};

export default BlueprintEditorRightPanelGetOrCreateByRemoteIDForm;
