import clsx from "clsx";
import { panelPositionEnum } from "../utils/MappingTestBuildingUtils";

interface Props {
  panelPosition: panelPositionEnum;
  numberOfPanels: number;
  children: React.ReactNode;
}

const MappingTestPanelContainer = ({ children, panelPosition, numberOfPanels = 2 }: Props) => {
  return (
    <div
      className={clsx(
        "flex-auto",
        panelPosition === panelPositionEnum.LEFT && (numberOfPanels === 3 ? "w-4/12" : "w-5/12"),
        panelPosition === panelPositionEnum.CENTER && (numberOfPanels === 3 ? "w-5/12" : "w-7/12"),
        panelPosition === panelPositionEnum.RIGHT && (numberOfPanels === 3 ? "w-3/12" : "w-5/12")
      )}
    >
      {children}
    </div>
  );
};

export default MappingTestPanelContainer;
