import { useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import { useHistory } from "react-router-dom";
import { APIEndpointMethod } from "../../../../models/Entities";
import { navigateToIntegrationBuilderLinkingFlowStepPathTable } from "../../../../router/RouterUtils";

interface UseDeleteLinkingFlowStepPathProps {
  integrationID: string;
  linkingFlowStepPathID?: string;
  reloadLinkingFlowStepPathsAndAuthConfigs: () => void;
}

const useDeleteLinkingFlowStepPath = ({
  integrationID,
  linkingFlowStepPathID,
  reloadLinkingFlowStepPathsAndAuthConfigs,
}: UseDeleteLinkingFlowStepPathProps) => {
  const [isLoadingLinkingFlowStepPathDeletion, setIsLoadingLinkingFlowStepPathDeletion] = useState(
    false
  );

  const history = useHistory();

  const deleteLinkingFlowStepPath = () => {
    setIsLoadingLinkingFlowStepPathDeletion(true);
    fetchWithAuth({
      path: `/integrations/${integrationID}/integration-setup-step-paths/${linkingFlowStepPathID}`,
      method: APIEndpointMethod.DELETE,
      onResponse: () => {
        showSuccessToast("Linking flow step path successfully deleted!");
        reloadLinkingFlowStepPathsAndAuthConfigs();
        setIsLoadingLinkingFlowStepPathDeletion(false);
        navigateToIntegrationBuilderLinkingFlowStepPathTable(history, integrationID);
      },
      onError: () => {
        showErrorToast("Failed to delete the linking flow step path.");
        setIsLoadingLinkingFlowStepPathDeletion(false);
      },
    });
  };

  return { deleteLinkingFlowStepPath, isLoadingLinkingFlowStepPathDeletion };
};

export default useDeleteLinkingFlowStepPath;
