import MergeModal from "../../shared/MergeModal";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";

type Props = {
  show?: boolean;
  title: string;
  mappingTestBlockIssues: Record<number, string[]>;
  confirmText: string;
  rejectText: string;
  onHide: () => void;
  onConfirm: () => void;
};

export default function MappingTestRunWarningModal({
  show,
  title,
  mappingTestBlockIssues,
  confirmText,
  rejectText,
  onHide,
  onConfirm,
}: Props) {
  const message = "We've found some issues in your mapping test blocks:";

  return (
    <>
      <MergeModal
        show={show ?? false}
        onHide={onHide}
        title={title}
        bodyClassName="overflow-hidden"
      >
        <div>
          <b>{message}</b>
          <ul className="m-4 list-disc">
            {Object.entries(mappingTestBlockIssues).map(([order, errors]) => {
              return (
                <li className="my-2">
                  <b>Mapping Test Block {Number(order) + 1}</b>
                  <ul>
                    {errors.map((error) => (
                      <li>{error}</li>
                    ))}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="flex mt-6 flex-row">
          <Button
            className="btn-block m-1 basis-2/4"
            variant={ButtonVariant.DangerFilled}
            onClick={() => {
              onHide();
              onConfirm();
            }}
          >
            {confirmText}
          </Button>
          <Button className="btn-block m-1 basis-2/4" onClick={() => onHide()}>
            {rejectText}
          </Button>
        </div>
      </MergeModal>
    </>
  );
}
