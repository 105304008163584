import AccordionCard from "./AccordionCard";
import PermissionWrapper from "./PermissionWrapper";

import React from "react";

interface Props {
  title: string;
  defaultExpanded?: boolean;
  hasPermissionToEdit?: boolean;
  children: React.ReactNode;
  dataTestID?: string;
  badgeContents?: number | string;
  variant?: "none" | "outline" | "shadow" | undefined;
  className?: string;
  nested?: boolean;
}

const AccordionCardForSection = ({
  title,
  defaultExpanded,
  hasPermissionToEdit,
  children,
  dataTestID,
  badgeContents,
  variant,
  className,
  nested,
}: Props) => {
  // Create an object with only defined props
  const propsToPass = {
    title,
    ...(defaultExpanded && { defaultExpanded }),
    ...(dataTestID && { dataTestID }),
    ...(badgeContents && { badgeContents }),
    ...(variant && { variant }),
    ...(className && { className }),
    ...(nested && { nested }),
  };

  return (
    <AccordionCard {...propsToPass}>
      <PermissionWrapper hasPermissionToEdit={hasPermissionToEdit ?? true}>
        {children}
      </PermissionWrapper>
    </AccordionCard>
  );
};

export default AccordionCardForSection;
