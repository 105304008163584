import {
  Blueprint,
  BlueprintReadme,
  BlueprintTrigger,
  BlueprintVersion,
  BlueprintPublishNote,
  BlueprintWithTrigger,
  ReportFile,
  BlueprintVersionStaleParametersInfo,
} from "../../../models/Blueprints";
import { fetchWithAuth } from "../../../api-client/api_client";
import { History, navigateToBlueprintEditor } from "../../../router/RouterUtils";
import { fetchReadme, updateReadme } from "../../../api-client/ReadmeAPIClient";
import { HTTPMethod } from "@merge-api/merge-javascript-shared";
import { AbstractCondition } from "../../integration-builder/selective-sync-filters/types";

export const saveBlueprintAsDraft = (props: {
  blueprint: Blueprint;
  history: History;
  onSuccess: (response: Blueprint) => void;
  onError: (response: Response | undefined) => void;
  comment?: string;
}) => {
  fetchWithAuth({
    path: `/blueprints/${props.blueprint.id}/versions`,
    method: "POST",
    body: {
      comment: props.comment,
      steps: props.blueprint.steps,
      parameter_schema: props.blueprint.parameter_schema ?? {},
      qbxml_query_request_format: props.blueprint.qbxml_query_request_format ?? "",
      return_schema: props.blueprint.return_schema ?? {},
      step_notes: props.blueprint.step_notes,
    },
    onResponse: (data: Blueprint) => {
      props.onSuccess(data);
      navigateToBlueprintEditor(props.history, data.integration.id, data.version.id);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const saveBlueprintTrigger = (props: {
  blueprintTrigger: BlueprintTrigger;
  blueprintID: string;
  onSuccess: (response: any) => void;
  onError: (response: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/blueprints/${props.blueprintID}/speeds`,
    method: "PATCH",
    body: props.blueprintTrigger,
    onResponse: (data: any) => {
      props.onSuccess(data);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const changeBlueprintStatus = (props: {
  versionID: string;
  status: string;
  onSuccess: (response: any) => void;
  onError: (response: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/blueprints/versions/${props.versionID}/status`,
    body: { status: props.status },
    method: "PATCH",
    onResponse: (data: any) => {
      props.onSuccess(data);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const stageBlueprintVersion = (props: {
  versionID: string;
  onSuccess: (response: any) => void;
  onError: (response: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/blueprints/versions/${props.versionID}/stage`,
    method: "POST",
    onResponse: (data: any) => {
      props.onSuccess(data);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const unstageBlueprintVersion = (props: {
  versionID: string;
  onSuccess: (response: any) => void;
  onError: (response: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/blueprints/versions/${props.versionID}/unstage`,
    method: "POST",
    onResponse: (data: any) => {
      props.onSuccess(data);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const fetchBlueprintChangelog = (props: {
  integrationID: string;
  onSuccess: (data: BlueprintPublishNote[]) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/integrations/${props.integrationID}/changelog`,
    method: "GET",
    onResponse: props.onSuccess,
  });

export const unpublishAllBlueprintVersions = (props: {
  blueprintID: string;
  description: string;
  ticket: string;
  onSuccess: (response: Blueprint) => void;
  onError: (response: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/blueprints/${props.blueprintID}/un-publish`,
    method: "POST",
    body: {
      description: props.description,
      ticket: props.ticket,
    },
    onResponse: (data: Blueprint) => {
      props.onSuccess(data);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const fetchLatestStaleParameters = ({
  blueprintVersionID,
  onResponse,
  onError,
}: {
  blueprintVersionID: string;
  onResponse: (data: BlueprintVersionStaleParametersInfo) => void;
  onError: () => void;
}) =>
  fetchWithAuth({
    path: `/versions/${blueprintVersionID}/stale-parameters`,
    method: HTTPMethod.GET,
    onResponse,
    onError,
  });

export const testBlueprint = (props: {
  blueprint: Blueprint;
  linkedAccountID: string;
  disableFilterByDate: boolean;
  disableSyncCursor: boolean;
  maxLoopIterations: number | undefined;
  maxLoopIterationsPerStep: { [stepID: string]: number };
  maxPageIterations: number | undefined;
  frozenTimeValue: string | undefined;
  overrideLastRunAtValue: string | undefined;
  globalVarTestValues?: { [key: string]: any };
  commonModelObjectID?: string;
  reportFileID?: string;
  shouldUseLastScraperResults: boolean;
  shouldFilterDisabledFields: boolean;
  shouldRunAsAsyncTask: boolean;
  shouldGenerateMappingTest: boolean;
  shouldLogStepIO?: boolean;
  abstractConditionsForSelectiveSync?: AbstractCondition[];
  onResponse: (data: any) => void;
  onError: (data: Response | undefined) => void;
}): void => {
  const bodyParamsSharedBetweenSyncAndAsync = {
    linked_account_id: props.linkedAccountID,
    disable_filter_by_date: props.disableFilterByDate,
    disable_sync_cursor: props.disableSyncCursor,
    max_loop_iterations: props.maxLoopIterations,
    max_loop_iterations_per_step_id: props.maxLoopIterationsPerStep,
    max_page_iterations: props.maxPageIterations,
    ...(props.frozenTimeValue &&
      props.frozenTimeValue.trim() !== "" && { frozen_time: props.frozenTimeValue }),
    ...(props.overrideLastRunAtValue &&
      props.overrideLastRunAtValue.trim() !== "" && {
        override_last_run_at: props.overrideLastRunAtValue,
      }),
    global_var_test_values: props.globalVarTestValues,
    common_model_object_id: props.commonModelObjectID,
    should_filter_disabled_fields: props.shouldFilterDisabledFields,
    should_run_as_async_task: props.shouldRunAsAsyncTask,
    is_mapping_test_generation_run: props.shouldGenerateMappingTest,
    report_file_id: props.reportFileID,
    ...(props.abstractConditionsForSelectiveSync && {
      override_abstract_conditions_for_selective_sync: props.abstractConditionsForSelectiveSync,
    }),
    ...(props.shouldLogStepIO && {
      debugging_configuration: { should_log_step_io: props.shouldLogStepIO },
    }),
  };
  if (props.shouldRunAsAsyncTask) {
    fetchWithAuth({
      path: `/blueprints/test`,
      method: "POST",
      body: {
        version_id: props.blueprint.version.id,
        ...bodyParamsSharedBetweenSyncAndAsync,
      },
      onResponse: props.onResponse,
      onError: (data: any) => {
        props.onError(data);
      },
    });
  } else {
    fetchWithAuth({
      path: `/blueprints/test`,
      method: "POST",
      body: {
        blueprint_json: JSON.stringify(props.blueprint),
        ...bodyParamsSharedBetweenSyncAndAsync,
      },
      onResponse: props.onResponse,
      onError: (data: any) => {
        props.onError(data);
      },
    });
  }
};

export const fetchStepTemplates = (props: {
  integrationID: string;
  blueprintVersionID: string;
  onSuccess: (response: any) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/blueprints/step-templates?integration_id=${props.integrationID}&blueprint_version_id=${props.blueprintVersionID}`,
    method: "GET",
    onResponse: props.onSuccess,
  });

export const fetchGenericStepTemplates = (props: {
  integrationID: string;
  blueprintVersionID: string;
  onSuccess: (response: any) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/blueprints/generic-step-templates?integration_id=${props.integrationID}&blueprint_version_id=${props.blueprintVersionID}`,
    method: "GET",
    onResponse: props.onSuccess,
  });

export const fetchReportFilesByBlueprintVersionID = (props: {
  blueprintVersionID: string;
  onSuccess: (response: any) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/blueprints/test-report-files?blueprint_version_id=${props.blueprintVersionID}`,
    method: "GET",
    onResponse: props.onSuccess,
  });

export const fetchStaleParametersByBlueprintVersionID = (props: {
  blueprintVersionID: string;
  onSuccess: (response: BlueprintVersionStaleParametersInfo) => void;
  onError?: (response: any) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/blueprints/versions/${props.blueprintVersionID}/stale-parameters`,
    method: "GET",
    onResponse: props.onSuccess,
    onError: props.onError,
  });

export const fetchBlueprintVersionForIntegration = (props: {
  integrationID: string;
  blueprintVersionID: string;
  onSuccess: (response: BlueprintWithTrigger) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/integrations/${props.integrationID}/blueprints/versions/${props.blueprintVersionID}`,
    method: "GET",
    onResponse: props.onSuccess,
  });

export const fetchBlueprintVersions = (props: {
  blueprintID: string;
  onSuccess: (data: BlueprintVersion[]) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/blueprints/${props.blueprintID}/versions`,
    method: "GET",
    onResponse: props.onSuccess,
  });

export const fetchBlueprintTestReportFiles = (props: {
  blueprintID: string;
  onSuccess: (reportFiles: Array<ReportFile>) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/blueprints/${props.blueprintID}/test-report-files`,
    method: "GET",
    onResponse: props.onSuccess,
  });

export const createBlueprint = (props: {
  integrationID: string;
  operationType: string;
  writtenCommonModel?: undefined | string;
  proxiedCommonModel?: undefined | string;
  actionType?: undefined | string;
  category?: undefined | string;
  associatedBlueprintIDForMeta?: string;
  webhookReceiverEventID?: string | undefined;
  reportTemplateID?: string | undefined;
  onSuccess: (data: Blueprint) => void;
  onError?: (error: Response | undefined) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `integrations/${props.integrationID}/blueprints`,
    body: {
      name: "Blueprint",
      operation_type: props.operationType,
      associated_blueprint_id_for_meta: props.associatedBlueprintIDForMeta,
      written_common_model: props.writtenCommonModel,
      proxied_common_model: props.proxiedCommonModel,
      action_type: props.actionType,
      category: props.category,
      webhook_receiver_event_type_id: props.webhookReceiverEventID,
      report_template_id: props.reportTemplateID,
      steps: [],
    },
    method: "POST",
    onResponse: props.onSuccess,
    ...(props.onError && { onError: props.onError }),
  });

export const updateBlueprintName = (props: {
  name: string;
  blueprintID: string;
  onSuccess: (data: Blueprint) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/blueprints/${props.blueprintID}`,
    method: "PATCH",
    body: {
      human_name: props.name,
    },
    onResponse: props.onSuccess,
  });

export const fetchBlueprintReadme = ({
  blueprintID,
  ...rest
}: {
  blueprintID: string;
  onSuccess: (data: BlueprintReadme) => void;
  onError: (error: Response | undefined) => void;
}): Promise<void> => fetchReadme({ path: `/blueprints/${blueprintID}/readme`, ...rest });

export const updateBlueprintReadme = ({
  blueprintID,
  ...rest
}: {
  blueprintID: string;
  text: string;
  onSuccess: (data: BlueprintReadme) => void;
  onError: (error: Response | undefined) => void;
}): Promise<void> => updateReadme({ path: `/blueprints/${blueprintID}/readme`, ...rest });

export const getRelations = (props: { model_id: string; onSuccess: (response: any) => void }) => {
  fetchWithAuth({
    path: `/blueprints/models/${props.model_id}/fetch-relations`,
    method: "GET",
    onResponse: (data: any) => {
      props.onSuccess(data);
    },
  });
};

export const toggleBlueprintIsLongRunning = (props: {
  blueprintID: string;
  isLongRunning: boolean;
  onSuccess: (response: any) => void;
  onError: (error: Response | undefined) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/blueprints/${props.blueprintID}/is-long-running`,
    method: "PATCH",
    body: {
      is_long_running: props.isLongRunning,
    },
    onResponse: props.onSuccess,
    onError: props.onError,
  });

export const getLatestVerionIDForBlueprint = (props: {
  blueprintID: string;
  onSuccess: (response: any) => void;
  onError: (error: Response | undefined) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/blueprints/${props.blueprintID}/latest-version-id`,
    method: "GET",
    onResponse: props.onSuccess,
    onError: props.onError,
  });
