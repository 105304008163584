import { DiffStateFieldTypeEnum, DiffState } from "../../../../models/DiffModels";
import { generateDiffState } from "./helpers-diff";

interface GenerateDiffForMergeLinkStepsProps {
  hasMergeLinkSteps: boolean;
  hasLinkChoiceStepOptions: boolean;
}

// Generate diff for Merge Link steps
export const generateDiffForMergeLinkSteps = ({
  hasMergeLinkSteps = true,
  hasLinkChoiceStepOptions = true,
}: GenerateDiffForMergeLinkStepsProps): DiffState => {
  const mergeLinkSteps = [
    {
      type: DiffStateFieldTypeEnum.HEADER,
      name: "configure_step_path_section",
      displayName: "Configure step path",
      childDiffStateInputFields: [
        {
          name: "path_type",
          displayName: "Path type",
          keyPathToName: ["step_path"],
        },
        {
          name: "is_active",
          displayName: "Is active",
          keyPathToName: ["step_path"],
        },
        {
          type: DiffStateFieldTypeEnum.SECTION,
          name: "advanced",
          displayName: "Advanced",
          isRenderNumberOfChildrenInDisplayName: true,
          childDiffStateInputFields: [
            {
              name: "custom_auth_choice_text",
              displayName: "Auth choice name",
              keyPathToName: ["step_path"],
            },
            {
              name: "checklist_title",
              displayName: "Checklist title",
              keyPathToName: ["step_path"],
            },
          ],
        },
      ],
    },
    {
      type: DiffStateFieldTypeEnum.HEADER,
      name: "configure_steps_section",
      displayName: "Configure steps",
      childDiffStateFields: generateDiffState({
        keyPathToSliceOfState: ["step_path", "steps"],
        isStateAnArray: true,
        arrayItemHeaderName: "Step",
        arrayItemIdentifier: "step_identifier",
        arrayItemNameToDisplayAsHeaderValue: "step_type",
        fields: [
          {
            name: "step_type",
            displayName: "Step type",
          },
          {
            name: "title",
            displayName: "Title",
          },
          {
            name: "description",
            displayName: "Description",
          },
          {
            name: "help_guide_url",
            displayName: "Help guide URL",
            isRenderAsPreviewable: true,
          },
          {
            name: "confirm_message",
            displayName: "Confirm message",
          },
          {
            name: "integration_setup_checklist_item_id",
            displayName: "Integration setup checklist item",
          },
          {
            name: "sftp_credential_field_keys",
            displayName: "SFTP credential field keys",
          },
          {
            type: DiffStateFieldTypeEnum.SECTION,
            name: "input_configuration",
            displayName: "Input configuration",
            childDiffStateInputFields: [
              {
                name: "input_prefix",
                displayName: "Input prefix",
              },
              {
                name: "input_suffix",
                displayName: "Input suffix",
              },
              {
                name: "input_placeholder",
                displayName: "Input placeholder",
              },
            ],
          },
          {
            type: DiffStateFieldTypeEnum.SECTION,
            name: "input_validation_configuration",
            displayName: "Input validation configuration",
            childDiffStateInputFields: [
              {
                name: "input_validation_regex",
                displayName: "Input validation regex",
              },
              {
                name: "input_validation_error",
                displayName: "Input validation error",
              },
            ],
          },
          {
            type: DiffStateFieldTypeEnum.SECTION,
            name: "advanced",
            displayName: "Advanced",
            isRenderNumberOfChildrenInDisplayName: true,
            childDiffStateInputFields: [
              {
                name: "additional_auth_display_names",
                displayName: "Additional auth display names",
              },
              {
                name: "step_post_action_type",
                displayName: "Step post action type",
              },
            ],
          },
        ],
      }),
    },
  ];
  const linkChoiceStepOption = {
    type: DiffStateFieldTypeEnum.HEADER,
    name: "options_header",
    displayName: "Options",
    childDiffStateFields: generateDiffState({
      keyPathToSliceOfState: ["options"],
      isStateAnArray: true,
      arrayItemHeaderName: "Option",
      arrayItemIdentifier: "name",
      arrayItemNameToDisplayAsHeaderValue: "name",
      fields: [
        {
          name: "name",
          displayName: "Name",
        },
        {
          name: "auth_configuration_override_id",
          displayName: "Authentication configuration override",
        },
        {
          type: DiffStateFieldTypeEnum.SECTION,
          name: "advanced",
          displayName: "Advanced",
          isRenderNumberOfChildrenInDisplayName: true,
          childDiffStateInputFields: [
            {
              name: "account_type",
              displayName: "Account type",
            },
          ],
        },
        {
          type: DiffStateFieldTypeEnum.SECTION,
          name: "field_overrides_section",
          displayName: "Field overrides",
          generateChildDiffStateInput: {
            keyPathToSliceOfState: ["form_field_values"],
            isStateAnArray: true,
            arrayItemHeaderName: "Field override",
            arrayItemIdentifier: "field_name",
            arrayItemNameToDisplayAsHeaderValue: "field_name",
            fields: [
              {
                name: "field_name",
                displayName: "Override field",
              },
              {
                name: "field_value",
                displayName: "Override value",
              },
            ],
          },
        },
      ],
    }),
  };

  if (hasLinkChoiceStepOptions && hasMergeLinkSteps) {
    mergeLinkSteps.push(linkChoiceStepOption);
    return generateDiffState({ fields: mergeLinkSteps });
  } else if (!hasLinkChoiceStepOptions && hasMergeLinkSteps) {
    return generateDiffState({ fields: mergeLinkSteps });
  } else if (hasLinkChoiceStepOptions && !hasMergeLinkSteps) {
    return generateDiffState({ fields: [linkChoiceStepOption] });
  }
  return generateDiffState({ fields: [] });
};
