import { GrantTypes } from "../../../../integrations/auth-config/AuthConfigShared";
import MultiSelectHeader from "../../../shared/MultiSelectHeader";

interface GrantTypeButtonsProps {
  className?: string;
  grantType: GrantTypes | undefined;
  setGrantType: React.Dispatch<React.SetStateAction<GrantTypes | undefined>>;
}

const GrantTypeButtons = ({ className, grantType, setGrantType }: GrantTypeButtonsProps) => {
  const handleButtonClick = (value: GrantTypes) => {
    setGrantType(value);
  };

  const options = [
    {
      value: GrantTypes.authorization_code,
      text: "Code",
      disabled: false,
      selected: grantType === GrantTypes.authorization_code,
      onClick: handleButtonClick,
    },
    {
      value: GrantTypes.client_credentials,
      text: "Client credentials",
      disabled: false,
      selected: grantType === GrantTypes.client_credentials,
      onClick: handleButtonClick,
    },
    {
      value: GrantTypes.jwt_bearer,
      text: "JWT bearer",
      disabled: false,
      selected: grantType === GrantTypes.jwt_bearer,
      onClick: handleButtonClick,
    },
  ];

  return (
    <MultiSelectHeader
      dataTestID="field-auth-config-grant-type"
      title="Grant type"
      options={options}
      className={className}
      required
    />
  );
};

export default GrantTypeButtons;
