import ScraperEditorLeftPanel from "./ScraperEditorLeftPanel";
import ScraperEditorRightPanel from "./ScraperEditorRightPanel";
import ScraperEditorCenterPanel from "./ScraperEditorCenterPanel";
import { GlobalHotKeys } from "react-hotkeys";
import useScraperContext from "./context/useScraperContext";
import { ScraperGhostStep, ScraperStep } from "./types";
import { showSuccessToast, showErrorToast } from "../shared/Toasts";

const ScraperEditorView = () => {
  const {
    scraper,
    selectedStep,
    setCopiedStep,
    addCopiedStep,
    addCopiedSteps,
    deleteStep,
    copiedStep,
    saveScraper,
  } = useScraperContext();
  const handlers = {
    COPY: () => {
      if (selectedStep && document.activeElement?.className.includes("step-card")) {
        setCopiedStep(selectedStep as ScraperStep);
        showSuccessToast("Step copied.");
      }
    },
    CUT: () => {
      if (selectedStep && document.activeElement?.className.includes("step-card")) {
        setCopiedStep(selectedStep as ScraperStep);
        showSuccessToast("Step cut.");
        deleteStep(selectedStep as ScraperStep);
      }
    },
    PASTE: () => {
      if (
        selectedStep &&
        "template" in selectedStep &&
        selectedStep.template === "ghost" &&
        copiedStep
      ) {
        const { relatedStepID, newStepRelation } = selectedStep as ScraperGhostStep;
        addCopiedStep(copiedStep, newStepRelation, relatedStepID, selectedStep.pathKey);
      }
    },
    COPY_JSON: () => {
      if (selectedStep && document.activeElement?.className.includes("step-card")) {
        navigator.clipboard.writeText(JSON.stringify(selectedStep));
        showSuccessToast("Step copied to clipboard.");
      }
    },
    CUT_JSON: () => {
      if (selectedStep && document.activeElement?.className.includes("step-card")) {
        navigator.clipboard.writeText(JSON.stringify(selectedStep));
        showSuccessToast("Step cut to clipboard.");
        deleteStep(selectedStep as ScraperStep);
      }
    },
    COPY_ALL_JSON: () => {
      navigator.clipboard.writeText(JSON.stringify(scraper.steps));
      showSuccessToast("Step copy ALL to clipboard.");
    },
    PASTE_JSON: () => {
      const { pathKey, relatedStepID, newStepRelation } = selectedStep as ScraperGhostStep;

      navigator.clipboard.readText().then((StepOrStepsJSON) => {
        try {
          const stepOrSteps = JSON.parse(StepOrStepsJSON);
          if (Array.isArray(stepOrSteps)) {
            addCopiedSteps(stepOrSteps as ScraperStep[], newStepRelation, relatedStepID, pathKey);
          } else {
            addCopiedStep(stepOrSteps as ScraperStep, newStepRelation, relatedStepID, pathKey);
          }
        } catch (error) {
          showErrorToast("Invalid step JSON");
        }
      });
    },

    SAVE: (e: any) => {
      e.preventDefault();
      saveScraper();
    },
  };
  return (
    <div className="w-100 vh-100 d-flex">
      <ScraperEditorLeftPanel />
      <GlobalHotKeys handlers={handlers} allowChanges={true}>
        <ScraperEditorCenterPanel />
      </GlobalHotKeys>
      <ScraperEditorRightPanel />
    </div>
  );
};

export default ScraperEditorView;
