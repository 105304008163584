import { Accordion, Badge, Text } from "@merge-api/merge-javascript-shared";
import { useContext, useEffect, useState } from "react";
import PostmanTableHeader from "../../../authentication/components/shared/PostmanTableHeader";
import KeyPathFieldHeader from "../../../shared/KeyPathFieldHeader";
import MultiSelectHeader from "../../../shared/MultiSelectHeader";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import { BOOLEAN_OPTIONS_DATA } from "../../../utils/constants";
import { parseDictToPostmanTableRows, parsePostmanTableRowsToDict } from "../../../utils/helpers";
import {
  KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER,
  KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER,
} from "../../constants";
import PaginationConfigurationContext from "../../context/PaginationConfigurationContext";

interface SessionInformationSetupOptionsProps {
  className?: string;
}

const SessionInformationSetupOptions = ({ className }: SessionInformationSetupOptionsProps) => {
  const {
    isSessionPagination,
    setIsSessionPagination,
    sessionIDParamName,
    setSessionIDParamName,
    readMorePaginatedRequestBodyBaseConfig,
    setReadMorePaginatedRequestBodyBaseConfig,
    readMoreRequestHeaderOverrides,
    setReadMoreRequestHeaderOverrides,
    readMoreResponseBodyArrayKeyPath,
    setReadMoreResponseBodyArrayKeyPath,
    responseSessionIDKeyPath,
    setResponseSessionIDKeyPath,
    readMoreResponseMaxItemsKeyPath,
    setReadMoreResponseMaxItemsKeyPath,
  } = useContext(PaginationConfigurationContext);

  const isSessionPaginationOptions = BOOLEAN_OPTIONS_DATA.map(({ value, text }) => ({
    value,
    text,
    selected: value === isSessionPagination,
    onClick: () => setIsSessionPagination(value),
  }));

  const [overridesTableRows, setOverridesTableRows] = useState(
    parseDictToPostmanTableRows(readMoreRequestHeaderOverrides || {})
  );

  // If the table rows change, update the readMoreRequestHeaderOverrides
  useEffect(() => {
    setReadMoreRequestHeaderOverrides(parsePostmanTableRowsToDict(overridesTableRows));
  }, [overridesTableRows]);

  return (
    <div className={className}>
      <MultiSelectHeader
        dataTestID="field-pagination-config-is-session-pagination"
        title="Is this session pagination?"
        subtitle="Indicates if the given pagination configuration corresponds to a read -> readMore query pattern where the first request fetches a query session id and subsequent queries use a different request body to fetch additional results."
        options={isSessionPaginationOptions}
      />
      {isSessionPagination && (
        <div className="border-l-2 pl-8 mt-4 border-gray-10">
          <TextFieldHeader
            dataTestID="field-pagination-config-session-id-param-name"
            title="What param should the session information be put in?"
            subtitle="If we send the pagination session information in the body itself, put the var name to include with parameter"
            hasSource={false}
            value={sessionIDParamName || ""}
            onChange={(e) => setSessionIDParamName(e.target.value)}
            placeholder="Value"
            required
          />
          <div className="border-gray-20 rounded-lg border-[0.5px] mt-5">
            <Accordion
              chevronSize={12}
              chevronOrientation="right"
              defaultExpanded={true}
              onChange={function noRefCheck() {}}
              title={
                <div className="flex flex-row justify-start space-x-2">
                  <Text variant="h6" className="normal-case">
                    Request
                  </Text>
                  <div className="min-w-fit min-h-fit">
                    <Badge className="align-middle" color="blue">
                      {"2"}
                    </Badge>
                  </div>
                </div>
              }
              titleClassName="px-5 py-4"
              variant="none"
            >
              <div className="px-5 py-4 space-y-6 border-t-[0.5px] border-gray-20">
                <TextFieldHeader
                  dataTestID="field-pagination-config-read-more-paginated-request-body-base-config"
                  title="Read more paginated request body base config"
                  subtitle="The string template that is transformed alongside the request body parameters into the readMore body message separate from the body used for the request that initiates the session."
                  hasSource={false}
                  placeholder="Value"
                  multiline
                  value={readMorePaginatedRequestBodyBaseConfig || ""}
                  onChange={(e) => setReadMorePaginatedRequestBodyBaseConfig(e.target.value)}
                />
                <PostmanTableHeader
                  dataTestID="field-pagination-config-read-more-paginated-request-header-override"
                  title="Read more paginated request header override"
                  subtitle="The string template that is transformed alongside the request body parameters into the readMore body message separate from the body used for the request that initiates the session."
                  rows={overridesTableRows}
                  setRows={setOverridesTableRows}
                  className="mt-4"
                  valueHeader="Value"
                />
              </div>
            </Accordion>
          </div>
          <div className="border-gray-20 rounded-lg border-[0.5px] mt-5">
            <Accordion
              chevronSize={12}
              chevronOrientation="right"
              defaultExpanded={true}
              onChange={function noRefCheck() {}}
              title={
                <div className="flex flex-row justify-start space-x-2">
                  <Text variant="h6" className="normal-case">
                    Response
                  </Text>
                  <div className="min-w-fit min-h-fit">
                    <Badge className="align-middle" color="blue">
                      {"3"}
                    </Badge>
                  </div>
                </div>
              }
              titleClassName="px-5 py-4"
              variant="none"
            >
              <div className="px-5 py-4 space-y-6 border-t-[0.5px] border-gray-20">
                <KeyPathFieldHeader
                  dataTestID="field-pagination-config-read-more-response-body-array-key-path"
                  title="Read more response body array key path"
                  subtitle="The traversal path to the data array that we want to loop through in the readMore response."
                  keyPath={readMoreResponseBodyArrayKeyPath}
                  onKeyPathChange={setReadMoreResponseBodyArrayKeyPath}
                  placeholder={
                    readMoreResponseBodyArrayKeyPath?.length
                      ? KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER
                      : KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER
                  }
                  hasSource={false}
                />
                <KeyPathFieldHeader
                  dataTestID="field-pagination-config-read-more-response-max-items-key-path"
                  title="Read more response total # of items key path"
                  subtitle="The traversal path to the total number of entries (across all pages) in the readMore response."
                  keyPath={readMoreResponseMaxItemsKeyPath}
                  onKeyPathChange={setReadMoreResponseMaxItemsKeyPath}
                  placeholder={
                    readMoreResponseMaxItemsKeyPath?.length
                      ? KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER
                      : KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER
                  }
                  hasSource={false}
                />
                <KeyPathFieldHeader
                  dataTestID="field-pagination-config-response-session-id-key-path"
                  title="Response session ID key path"
                  subtitle="The keys creating a path to the session id information in the API response (e.g. key for response body or title of header)."
                  keyPath={responseSessionIDKeyPath}
                  onKeyPathChange={setResponseSessionIDKeyPath}
                  placeholder={
                    responseSessionIDKeyPath?.length
                      ? KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER
                      : KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER
                  }
                  hasSource={false}
                  required
                />
              </div>
            </Accordion>
          </div>
        </div>
      )}
    </div>
  );
};

export default SessionInformationSetupOptions;
