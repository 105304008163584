import { fetchWithAuth } from "../../../../api-client/api_client";
import { APIEndpointMethod } from "../../../../models/Entities";

interface Props {
  integrationID: string;
}

const useCollectEndpoints = ({ integrationID }: Props) => {
  const collectEndpoints = (
    apiDocumentationURL: string,
    onResponse: () => void,
    onError: () => void
  ) => {
    fetchWithAuth({
      path: `/integrations/integration-builder/integration/${integrationID}/collect-endpoints`,
      method: APIEndpointMethod.POST,
      body: {
        api_documentation_url: apiDocumentationURL,
      },
      onResponse,
      onError,
    });
  };

  return { collectEndpoints };
};

export default useCollectEndpoints;
