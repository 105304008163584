import {
  IntegrationTestSuiteExecution,
  MappingTestExecutionResult,
} from "../../../models/MappingTests";

export const countIntegrationTestSuiteMappingTestExecutionFailures = (
  integrationTestSuiteExecution: IntegrationTestSuiteExecution
): number => {
  let failureCount = 0;

  integrationTestSuiteExecution.linked_account_test_suite_executions.forEach((linkedExec) => {
    linkedExec.mapping_test_executions.forEach((mappingTest) => {
      if (mappingTest.result !== MappingTestExecutionResult.SUCCESS) {
        failureCount++;
      }
    });
  });

  return failureCount;
};
