import { LinkedAccount } from "../../../models/Entities";

export const filterLinkedAccountTypeaheadOptions = (options: LinkedAccount[], state: any) => {
  return options.filter((option: LinkedAccount) => {
    const inputValueToLowerCase = (state.inputValue as string).toLowerCase();
    const linkedAccountName = option?.end_user.organization_name.toLowerCase();
    const linkedAccountID = option?.id;
    return (
      linkedAccountName?.includes(inputValueToLowerCase) ||
      linkedAccountID?.startsWith(inputValueToLowerCase)
    );
  });
};
