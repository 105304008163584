import TypeaheadFormField from "./TypeaheadFormField";

const BlueprintEditorRightPanelBatchAPIResponseForm = () => {
  return (
    <form>
      <TypeaheadFormField
        title="API Response Data"
        subtitle="Response JSON to loop through."
        valueKey="api_response"
        parameterType={"object"}
      />
      <TypeaheadFormField
        title="Object path schema"
        subtitle="Array of keys and indices to target list."
        valueKey="object_path_schema"
        parameterType={"array"}
      />
      <TypeaheadFormField
        title="Target Key"
        subtitle="Optional key used to filter object list."
        valueKey="target_key"
        parameterType={"string"}
      />
      <TypeaheadFormField
        title="Target Value"
        subtitle="Optional value to stringmatch with target key."
        valueKey="target_value"
        parameterType={"string"}
      />
      <TypeaheadFormField
        title="Batch Size"
        subtitle="Number of objects to include in each loop."
        valueKey="batch_size"
        parameterType={"number"}
      />
    </form>
  );
};

export default BlueprintEditorRightPanelBatchAPIResponseForm;
