import { ChangelogModelType, ChangelogModelTypeMap } from "../../utils/types";
import { Typeahead } from "@merge-api/merge-javascript-shared";

type ModelTypePickerProps = {
  modelType: ChangelogModelType | null;
  onModelTypeChange: (modelType: ChangelogModelType | null) => void;
};

type Option = {
  label: string;
  value: ChangelogModelType;
};

const ModelTypePicker = ({ modelType, onModelTypeChange }: ModelTypePickerProps) => {
  const modelTypeOptions: Option[] = Object.values(ChangelogModelType).map((type) => ({
    label: ChangelogModelTypeMap[type],
    value: type,
  }));

  return (
    <Typeahead
      className="bg-white"
      placeholder="All models"
      options={modelTypeOptions}
      value={modelType ? { label: ChangelogModelTypeMap[modelType], value: modelType } : null}
      onChange={(_, selectedOption: Option | null) =>
        onModelTypeChange(selectedOption ? (selectedOption.value as ChangelogModelType) : null)
      }
      getOptionLabel={(option) => option.label}
      getOptionKey={(option) => option.value}
    />
  );
};

export default ModelTypePicker;
