import { Integration } from "../../../models/Entities";
import { getUIDTemplateCategoryPath } from "../../../router/RouterUtils";
import SubLeftNavigationContainer from "../../shared/layout/SubLeftNavigationContainer";

type Props = {
  integrationMeta: Integration;
  children: JSX.Element | JSX.Element[];
};

function UIDTemplatePageWrapper({ integrationMeta, children }: Props) {
  return (
    <SubLeftNavigationContainer
      subtabs={[
        {
          header: true,
          label: "Categories",
        },
        ...integrationMeta.categories.map((category) => ({
          label: category.toUpperCase(),
          destination: getUIDTemplateCategoryPath(integrationMeta.id, category),
        })),
      ]}
    >
      {children}
    </SubLeftNavigationContainer>
  );
}

export default UIDTemplatePageWrapper;
