import { Badge, Tooltip } from "@merge-api/merge-javascript-shared";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  BlueprintParameterValue,
  BlueprintParameterValueType,
  BlueprintStep,
} from "../../../models/Blueprints";
import { DeprecatedBadge } from "../../shared/MergeBadges";
import useBlueprintContext from "../context/useBlueprintContext";
import {
  BlueprintAvailableParameter,
  convertTraversalPathToDotNotation,
  getConstantParametersForChoices,
  getParametersAvailableToStepForParameterType,
  isParameterCurrentlySelectedParameterValue,
  PARAMETER_TYPES,
} from "../utils/BlueprintEditorUtils";
import MergeText, { SmallTextMutedParagraph, TextType } from "../../shared/text/MergeText";
import { parseParameterValue } from "../../shared/utils/SharedComponentUtils";
import { firstLetterUpperCase } from "../../../utils";
import {
  customJSONHumanReadableName,
  isParameterValueCustomJSON,
} from "./custom-json-parameter/CustomJSONUtils";
import PrettifiedStepParameterValue from "./prettified-step-parameter-value/PrettifiedStepParameterValue";

type Props = {
  allowConstantValues: boolean;
  currentParameterValue: null | BlueprintParameterValue;
  onChange: (options: Array<BlueprintAvailableParameter>) => void;
  shouldIncludeProcedureParameters?: boolean;
  procedureIndex?: number;
  parameterType: string;
  disabled?: boolean;
  validator?: (x: BlueprintParameterValue) => boolean;
  choices?: Array<string>;
  choiceNames?: Array<string>;
  parameterKey?: string;
};

const TypeaheadFormFieldTypeahead = ({
  allowConstantValues,
  currentParameterValue,
  onChange,
  procedureIndex,
  parameterType,
  shouldIncludeProcedureParameters,
  disabled,
  validator,
  choices,
  choiceNames,
  parameterKey,
}: Props) => {
  const { blueprint, selectedStep } = useBlueprintContext();

  const availableParameters = [
    ...getConstantParametersForChoices(choices ?? [], choiceNames).sort(),
    ...getParametersAvailableToStepForParameterType({
      blueprint: blueprint,
      step: selectedStep as BlueprintStep,
      parameterType: parameterType,
      currentParameterValue: currentParameterValue,
      procedureIndex: procedureIndex,
      shouldIncludeProcedureParameters: shouldIncludeProcedureParameters,
      parameterKey: parameterKey,
    }).sort(),
  ];

  const selectedParameters =
    currentParameterValue != null
      ? availableParameters.filter((availableParameter) =>
          isParameterCurrentlySelectedParameterValue(
            availableParameter.parameterValue,
            currentParameterValue
          )
        )
      : [];

  const isCurrentParameterCustomJSON = isParameterValueCustomJSON(currentParameterValue);

  const isCurrentlySelectedParameterStale =
    currentParameterValue != null &&
    selectedParameters.length === 0 &&
    !isCurrentParameterCustomJSON;

  const isInvalid = validator && currentParameterValue ? !validator(currentParameterValue) : false;

  return (
    <Tooltip
      placement="left-start"
      className="w-full"
      tooltipClassName="min-w-[350px]"
      title={
        selectedParameters.length > 0 ? (
          <PrettifiedStepParameterValue availableParameterValue={selectedParameters[0]} />
        ) : (
          ""
        )
      }
    >
      <div className="flex flex-col">
        {isCurrentlySelectedParameterStale && (
          <Tooltip title="This step has changed so the param value needs to be updated.">
            <>
              <MergeText isBold type={TextType.DANGER}>
                stale parameter value:
              </MergeText>
              <MergeText type={TextType.MUTED} className="ml-1.5">
                {parseParameterValue(currentParameterValue)}
              </MergeText>
            </>
          </Tooltip>
        )}
        {isCurrentParameterCustomJSON && (
          <Tooltip title="This parameter currently uses a custom object, use the object editor to make changes.">
            <MergeText isBold type={TextType.BLUE}>
              {`Uses custom ${customJSONHumanReadableName(currentParameterValue)}`}
            </MergeText>
          </Tooltip>
        )}
        <Typeahead
          allowNew={allowConstantValues}
          disabled={disabled ?? false}
          selected={selectedParameters}
          options={availableParameters}
          clearButton={true}
          labelKey={"labelKey"}
          id="typeahead"
          isValid={currentParameterValue?.is_unique_identifier && !isInvalid}
          isInvalid={isInvalid}
          inputProps={{ autoComplete: "none" }}
          newSelectionPrefix={"Set as constant: "}
          placeholder={
            parameterType
              ? parameterType === PARAMETER_TYPES.UUID
                ? "UUID"
                : firstLetterUpperCase(parameterType)
              : "Unknown"
          }
          renderMenuItemChildren={(availableParameter, _, index) => {
            const { parameterValue, extraData } = availableParameter;

            let boldedPath;
            let nonBoldedPath;
            if (parameterValue.value_type === BlueprintParameterValueType.returnValue) {
              if (parameterValue.step_id.includes("remote_data_path")) {
                boldedPath = parameterValue.step_id.split("remote_data_path")[0];
                nonBoldedPath = ".remote_data_path";
              } else {
                boldedPath = parameterValue.step_id;
                nonBoldedPath =
                  parameterValue.request_return_value_path.length > 0
                    ? "." +
                      convertTraversalPathToDotNotation(parameterValue.request_return_value_path)
                    : "";
              }
            }
            const valueType = extraData.valueType
              ? extraData?.format === PARAMETER_TYPES.UUID
                ? extraData.format.toUpperCase()
                : firstLetterUpperCase(extraData.valueType)
              : "";

            return (
              <div style={{ overflowY: "scroll" }}>
                {index > 0 && <hr />}
                {extraData.title}
                <Badge className="ml-1">{valueType}</Badge>
                {extraData.deprecated && <DeprecatedBadge />}
                <br />
                {extraData.description && (
                  <>
                    <SmallTextMutedParagraph className="mb-0">
                      {extraData.description}
                    </SmallTextMutedParagraph>{" "}
                    <br />{" "}
                  </>
                )}
                <SmallTextMutedParagraph className="mb-0">
                  {parameterValue.value_type === BlueprintParameterValueType.returnValue && (
                    <>
                      <b>{boldedPath}</b>
                      {nonBoldedPath}
                    </>
                  )}
                </SmallTextMutedParagraph>
              </div>
            );
          }}
          onChange={onChange}
        />
      </div>
    </Tooltip>
  );
};

export default TypeaheadFormFieldTypeahead;
