import { Toggle, Tooltip, Text } from "@merge-api/merge-javascript-shared";
import { Info } from "lucide-react";

export interface NeedsRemoteDataToggleProps {
  updateNeedsRemoteData: () => void;
  needsRemoteData: boolean;
}

const NeedsRemoteDataToggle = ({
  updateNeedsRemoteData,
  needsRemoteData,
}: NeedsRemoteDataToggleProps) => (
  <Toggle
    className="my-3"
    checked={needsRemoteData}
    label={
      <div className="flex">
        <Text className="mr-1" variant="md">
          Needs remote data
        </Text>
        <Tooltip title="Check if you will need to directly access (not just set) .remote_data in a later step for this model">
          <Info size={16} />
        </Tooltip>
      </div>
    }
    labelPlacement="right"
    onChange={updateNeedsRemoteData}
  />
);

export default NeedsRemoteDataToggle;
