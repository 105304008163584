import { HeaderPretitle } from "../../shared/text/MergeText";
import TypeaheadFormField from "./TypeaheadFormField";
import DropdownFormField from "./DropdownFormField";
import { BlueprintGetRelationById, BlueprintParameterValueType } from "../../../models/Blueprints";
import { getRelations } from "../utils/BlueprintEditorAPIClient";
import useBlueprintContext from "../context/useBlueprintContext";
import { useEffect, useState } from "react";

const BlueprintEditorRightPanelGetRelationByIdForm = () => {
  const { selectedStep, updateStepParameterValue } = useBlueprintContext();
  const step = selectedStep as BlueprintGetRelationById;
  const [relations, setRelations] = useState<string[]>();

  const onSuccess = (response: any) => {
    setRelations(response.relations);
  };

  useEffect(() => {
    if (step.template.metadata.common_model.id) {
      getRelations({ model_id: step.template.metadata.common_model.id, onSuccess: onSuccess });
    }
  }, [step.template.metadata]);

  const currentRelation =
    step.parameter_values?.["relation"]?.value_type === BlueprintParameterValueType.constant
      ? step.parameter_values?.["relation"]?.constant
      : null;

  const relationChoices = Object.values(relations ?? {}).map((relation) => ({
    id: relation,
    name: relation,
  }));

  return (
    <>
      <HeaderPretitle className="my-2">Parameters</HeaderPretitle>
      <TypeaheadFormField
        key={"id"}
        title={"ID"}
        subtitle={"The ID of the matching object."}
        valueKey={"id"}
        parameterType={"uuid"}
      />
      <DropdownFormField
        choices={relationChoices}
        currentValue={currentRelation}
        placeholder={"Select a relation"}
        title={"Relation"}
        subtitle={"Select a relation to return UUID's of its instances."}
        onChange={(e) =>
          updateStepParameterValue(step, "relation", {
            constant: e.target.value,
            value_type: BlueprintParameterValueType.constant,
          })
        }
      />
    </>
  );
};

export default BlueprintEditorRightPanelGetRelationByIdForm;
