import { Link, useHistory } from "react-router-dom";
import { Button, Card, Table } from "react-bootstrap";
import EmptyStateWrapper from "../../shared/layout/EmptyStateWrapper";
import { ReportTemplate } from "../../../models/Entities";
import { navigateToIndividualReportTemplate } from "../../../router/RouterUtils";
import { Fragment } from "react";

type Props = { reportTemplates: ReportTemplate[]; integrationID: string };

const ReportTemplateTable = ({ reportTemplates, integrationID }: Props) => {
  const history = useHistory();

  return (
    <Card>
      <Table responsive hover>
        <thead className="table-borderless">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Filename pattern type</th>
            <th scope="col">Is filename pattern regex</th>
            <th scope="col" className="text-right" />
            <th scope="col" style={{ display: "flex", justifyContent: "flex-end" }}>
              <Link to={`/integrations/${integrationID}/report-templates/create`}>
                <Button variant="white" size="sm" className="mr-3">
                  <div className="d-flex justify-content-center">
                    <i className="fe fe-plus mr-1.5" />
                    Create
                  </div>
                </Button>
              </Link>
            </th>
          </tr>
        </thead>
        <tbody>
          {reportTemplates ? (
            reportTemplates.length > 0 ? (
              <>
                {reportTemplates.map((reportTemplate: ReportTemplate) => (
                  <Fragment key={reportTemplate?.id}>
                    <tr
                      onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                        navigateToIndividualReportTemplate(
                          history,
                          integrationID,
                          reportTemplate.id,
                          event.metaKey || event.ctrlKey
                        )
                      }
                      className="table-link"
                    >
                      <td className="w-35">{reportTemplate.name}</td>
                      <td className="w-35">{reportTemplate.filename_pattern}</td>
                      <td className="w-35">{String(reportTemplate.is_filename_pattern_regex)}</td>
                      <td className="w-15" />
                      <td className="text-right">
                        <Button
                          className="mr-3"
                          variant="white"
                          size="sm"
                          onClick={() =>
                            navigateToIndividualReportTemplate(
                              history,
                              integrationID,
                              reportTemplate.id
                            )
                          }
                        >
                          <i className="fe fe-edit-2" /> Edit
                        </Button>
                      </td>
                    </tr>
                  </Fragment>
                ))}
              </>
            ) : (
              <tr className="unmapped-common-models">
                <td colSpan={8} className="p-0">
                  <EmptyStateWrapper isTable title="No Report templates" />
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan={7} className="p-0">
                <EmptyStateWrapper isTable isSpinner />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Card>
  );
};

export default ReportTemplateTable;
