import { useCallback, useEffect, useState } from "react";
import { fetchWithAuth } from "../../../api-client/api_client";
import { JSONSchema, JSONObjectSchema } from "../../../models/Blueprints";
import {
  APIRequestFormat,
  APIRequestType,
  APIResponseType,
  FileParameterData,
  LinkedAccount,
  APIRequestFormatSubtype,
  ResponseBodyAdditionalParsing,
} from "../../../models/Entities";
import DropdownFormField from "../../blueprint-editor/right-panel/DropdownFormField";
import InputFormField from "../../blueprint-editor/right-panel/InputFormField";
import SpinnerButton from "../../shared/SpinnerButton";
import { showErrorToast } from "../../shared/Toasts";
import { Col, Row } from "react-bootstrap";
import { isValidJSON } from "../../../utils";
import FileUploadWithEncodingFormField from "../../shared/FileUploadWithEncodingFormField";
import { HeaderPretitle } from "../../shared/text/MergeText";
import React from "react";
import TestRunResponseBlock, { TestRunResponse } from "../../shared/TestRunResponseBlock";
import DeprecatedH2 from "../../deprecated/DeprecatedH2";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";

interface Props {
  integrationID: string;
  method: string;
  path: string;
  baseAPIURLOverride: string;

  headerSchema: string;
  bodySchema: string;
  requestFormat: APIRequestFormat;
  pathParamSchema: string;
  queryParamSchema: string;
  fileSchema: string;
  requestType: string;
  responseType: APIResponseType;
  responseBodyAdditionalParsing?: ResponseBodyAdditionalParsing;
  requestBodyBaseConfig: string;
  requestBodyParamSchema: string;
  requestBodyFormatSubtype: string;
  soapRequestUseBodyTemplatingEngine: boolean;
  pathTestValues: { [key: string]: any };
  setPathTestValues: (pathTestValues: { [key: string]: any }) => void;
  headerTestValues: { [key: string]: any };
  setHeaderTestValues: (headerTestValues: { [key: string]: any }) => void;
  queryParamTestValues: { [key: string]: any };
  setQueryParamTestValues: (queryParamTestValues: { [key: string]: any }) => void;
  bodyTestValues: { [key: string]: any };
  setBodyTestValues: (bodyTestValues: { [key: string]: any }) => void;
  requestBodyParamValues: { [key: string]: any };
  setRequestBodyParamValues: (requestBodyParamValues: { [key: string]: any }) => void;
  fileTestValues: FileParameterData[];
  setFileTestValues: (fileTestValues: FileParameterData[]) => void;
}

const APIRequestTester = ({
  integrationID,
  method,
  path,
  baseAPIURLOverride,
  headerSchema,
  bodySchema,
  requestFormat,
  pathParamSchema,
  queryParamSchema,
  fileSchema,
  requestType,
  responseType,
  responseBodyAdditionalParsing,
  requestBodyBaseConfig,
  requestBodyParamSchema,
  requestBodyFormatSubtype,
  soapRequestUseBodyTemplatingEngine,
  pathTestValues,
  setPathTestValues,
  headerTestValues,
  setHeaderTestValues,
  queryParamTestValues,
  setQueryParamTestValues,
  bodyTestValues,
  setBodyTestValues,
  requestBodyParamValues,
  setRequestBodyParamValues,
  fileTestValues,
  setFileTestValues,
}: Props) => {
  const [selectedTestLinkedAccount, setSelectedTestLinkedAccount] = useState<undefined | string>();
  const [testLinkedAccounts, setTestLinkedAccounts] = useState<LinkedAccount[]>([]);

  const [requestBaseConfig, setRequestBaseConfig] = useState<string>(requestBodyBaseConfig);
  const [runningTestRequest, setRunningTestRequest] = useState(false);

  const [testRunResponse, setTestRunResponse] = useState<TestRunResponse | undefined>(undefined);

  useEffect(() => {
    fetchWithAuth({
      path: `/integrations/linked-accounts?is_test_account=True&integration=${integrationID}`,
      method: "GET",
      onResponse: (data) => {
        setTestLinkedAccounts(data.results);
        setSelectedTestLinkedAccount(data.results[0].id);
      },
    });
  }, [integrationID]);

  const pathJSONSchema = isValidJSON(pathParamSchema)
    ? (JSON.parse(pathParamSchema) as JSONSchema)
    : null;
  const pathProperties =
    pathJSONSchema && "properties" in pathJSONSchema ? pathJSONSchema.properties : null;

  const queryParamJSONSchema = isValidJSON(queryParamSchema)
    ? (JSON.parse(queryParamSchema) as JSONSchema)
    : null;
  const queryParamProperties =
    queryParamJSONSchema && "properties" in queryParamJSONSchema
      ? queryParamJSONSchema.properties
      : null;

  const headerJSONSchema = isValidJSON(headerSchema)
    ? (JSON.parse(headerSchema) as JSONSchema)
    : null;
  const headerProperties =
    headerJSONSchema && "properties" in headerJSONSchema ? headerJSONSchema.properties : null;

  const bodyJSONSchema = isValidJSON(bodySchema) ? (JSON.parse(bodySchema) as JSONSchema) : null;
  const bodyProperties =
    bodyJSONSchema && "properties" in bodyJSONSchema ? bodyJSONSchema.properties : null;

  const fileJSONSchema = isValidJSON(fileSchema) ? (JSON.parse(fileSchema) as JSONSchema) : null;
  const fileProperties =
    fileJSONSchema && "properties" in fileJSONSchema ? fileJSONSchema.properties : null;

  const requestParamJSONSchema = isValidJSON(requestBodyParamSchema)
    ? (JSON.parse(requestBodyParamSchema) as JSONObjectSchema)
    : null;

  const requestParamProperties = requestParamJSONSchema?.properties;

  const runTestRequest = useCallback(() => {
    setRunningTestRequest(true);
    fetchWithAuth({
      path: "/blueprints/test-endpoint",
      method: "POST",
      body: {
        method: method,
        linked_account_id: selectedTestLinkedAccount,
        path: path,
        base_api_url_override: baseAPIURLOverride,
        path_params: pathTestValues,
        query_params: queryParamTestValues,
        header_params: headerTestValues,
        body_params: bodyTestValues,
        file_params: fileTestValues,
        request_format: requestFormat,
        request_type: requestType,
        response_type: responseType,
        response_body_additional_parsing: responseBodyAdditionalParsing,
        request_body_base_config: requestBaseConfig,
        request_body_param_params: requestBodyParamValues,
        request_body_format_subtype: requestBodyFormatSubtype,
        soap_request_use_body_templating_engine: soapRequestUseBodyTemplatingEngine,
      },
      onResponse: (data) => {
        setRunningTestRequest(false);
        setTestRunResponse(data);
      },
      onError: (response) => {
        setRunningTestRequest(false);
        response?.json().then((value: string) => {
          showErrorToast(`Failed to run: ${JSON.stringify(value)}`);
        });
      },
    });
  }, [
    method,
    selectedTestLinkedAccount,
    path,
    baseAPIURLOverride,
    pathTestValues,
    queryParamTestValues,
    headerTestValues,
    bodyTestValues,
    requestType,
    requestBaseConfig,
    requestBodyParamValues,
  ]);

  return (
    <>
      <Row>
        <Col>
          <DeprecatedH2>Test Endpoint</DeprecatedH2>
          <DropdownFormField
            onChange={(e) => {
              setSelectedTestLinkedAccount(e.target.value);
            }}
            placeholder="Select Test Linked Account"
            subtitle={"Select the Linked Account to run this account on. A real request will run."}
            currentValue={selectedTestLinkedAccount}
            choices={testLinkedAccounts.map(({ id, end_user }) => ({
              id,
              name: end_user.organization_name + " - " + id,
            }))}
          />
          {pathProperties && Object.entries(pathProperties).length > 0 && (
            <>
              <hr />
              <HeaderPretitle className="mt-3">Path Parameters</HeaderPretitle>
            </>
          )}
          {pathProperties &&
            Object.entries(pathProperties).map(([parameter, _]) => {
              return (
                <InputFormField
                  key={`path-${parameter}`}
                  currentValue={pathTestValues[parameter]}
                  onChange={(newValue) =>
                    setPathTestValues({
                      ...pathTestValues,
                      [parameter]: newValue,
                    })
                  }
                  placeholder={parameter}
                  title={parameter}
                />
              );
            })}

          {queryParamProperties && (
            <>
              <hr />
              <DeprecatedH4>Query Parameters</DeprecatedH4>
            </>
          )}
          {queryParamProperties &&
            Object.entries(queryParamProperties).map(([parameter, _]) => {
              return (
                <InputFormField
                  currentValue={queryParamTestValues[parameter]}
                  onChange={(newValue) =>
                    setQueryParamTestValues({
                      ...queryParamTestValues,
                      [parameter]: newValue,
                    })
                  }
                  placeholder={parameter}
                  title={parameter}
                />
              );
            })}
          {headerProperties && (
            <>
              <hr />
              <DeprecatedH4>Header Parameters</DeprecatedH4>
            </>
          )}
          {headerProperties &&
            Object.entries(headerProperties).map(([parameter, _]) => {
              return (
                <InputFormField
                  currentValue={headerTestValues[parameter]}
                  onChange={(newValue) =>
                    setHeaderTestValues({
                      ...headerTestValues,
                      [parameter]: newValue,
                    })
                  }
                  placeholder={parameter}
                  title={parameter}
                />
              );
            })}
          {bodyProperties && !(requestType === APIRequestType.SOAP) && (
            <>
              <hr />
              <HeaderPretitle className="mt-3">Body Parameters</HeaderPretitle>
            </>
          )}
          {bodyProperties &&
            !(requestType === APIRequestType.SOAP) &&
            Object.entries(bodyProperties).map(([parameter, _]) => {
              return (
                <InputFormField
                  key={`body-parameter-${parameter}`}
                  currentValue={bodyTestValues[parameter]}
                  onChange={(newValue) =>
                    setBodyTestValues({
                      ...bodyTestValues,
                      [parameter]: newValue,
                    })
                  }
                  placeholder={parameter}
                  title={parameter}
                />
              );
            })}
          {requestType === APIRequestType.SOAP && (
            <InputFormField
              currentValue={requestBaseConfig}
              onChange={(newValue) => setRequestBaseConfig(newValue)}
              placeholder={"SOAP Body format"}
              title={"SOAP Body format"}
            />
          )}
          {requestBodyFormatSubtype === APIRequestFormatSubtype.GRAPHQL_JSON && (
            <InputFormField
              currentValue={requestBaseConfig}
              onChange={(newValue) => setRequestBaseConfig(newValue)}
              placeholder={"GraphQL Request Body Base Config"}
              title={"GraphQL Request Body Base Config"}
            />
          )}
          {fileProperties && requestFormat === APIRequestFormat.REQUEST_FORMAT_MULTIPART_FORM && (
            <>
              <hr />
              <HeaderPretitle className="mt-3">Request Files</HeaderPretitle>
            </>
          )}
          {fileProperties &&
            requestFormat === APIRequestFormat.REQUEST_FORMAT_MULTIPART_FORM &&
            Object.entries(fileProperties).map(([parameter, _]) => {
              return (
                <FileUploadWithEncodingFormField
                  key={`file-property-${parameter}`}
                  getFileData={(newValue: FileParameterData) => {
                    setFileTestValues([
                      ...fileTestValues.filter((value) => {
                        return value.param_key !== parameter;
                      }),
                      newValue,
                    ]);
                  }}
                  onError={(error) => showErrorToast(`Failed to upload file due to error ${error}`)}
                  parameter={parameter}
                  title={parameter}
                />
              );
            })}
          {requestType === APIRequestType.SOAP && (
            <>
              <hr />
              <DeprecatedH4>SOAP Request Parameters</DeprecatedH4>
            </>
          )}
          {requestParamProperties &&
            requestType === APIRequestType.SOAP &&
            Object.entries(requestParamProperties).map(([parameter, _]) => {
              return (
                <InputFormField
                  currentValue={requestBodyParamValues[parameter]}
                  onChange={(newValue) =>
                    setRequestBodyParamValues({
                      ...requestBodyParamValues,
                      [parameter]: newValue,
                    })
                  }
                  placeholder={parameter}
                  title={parameter}
                />
              );
            })}

          {requestParamProperties && requestType === APIRequestFormatSubtype.GRAPHQL_JSON && (
            <>
              <hr />
              <DeprecatedH4>GraphQL Request Parameters</DeprecatedH4>
            </>
          )}
          {requestParamProperties &&
            requestBodyFormatSubtype === APIRequestFormatSubtype.GRAPHQL_JSON &&
            Object.entries(requestParamProperties).map(([parameter, _]) => {
              return (
                <InputFormField
                  currentValue={requestBodyParamValues[parameter]}
                  onChange={(newValue) =>
                    setRequestBodyParamValues({
                      ...requestBodyParamValues,
                      [parameter]: newValue,
                    })
                  }
                  placeholder={parameter}
                  title={parameter}
                />
              );
            })}
          <SpinnerButton
            text="Test request"
            isLoading={runningTestRequest}
            className="btn-block btn-primary"
            onClick={() => runTestRequest()}
            disabled={!selectedTestLinkedAccount}
          />
        </Col>
      </Row>
      <Row>
        <Col>{testRunResponse && <TestRunResponseBlock response={testRunResponse} />}</Col>
      </Row>
    </>
  );
};

export default React.memo(APIRequestTester);
