import React from "react";
import {
  RateLimitConfigurationIntegrationBuilder,
  RateLimitResetTimestampFormat,
  RateLimitTimePeriod,
  RateLimitType,
} from "../../utils/Entities";

export interface RateLimitConfigurationContextState {
  integrationID: string;
  apiEndpointID: string | undefined;
  setAPIEndpointID: React.Dispatch<React.SetStateAction<string | undefined>>;
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  rateLimitType: RateLimitType;
  setRateLimitType: React.Dispatch<React.SetStateAction<RateLimitType>>;
  rateLimitTimePeriod: RateLimitTimePeriod;
  setRateLimitTimePeriod: React.Dispatch<React.SetStateAction<RateLimitTimePeriod>>;
  rateLimitTimeValue: number | undefined;
  setRateLimitTimeValue: React.Dispatch<React.SetStateAction<number | undefined>>;
  rateLimitMaxCount: number | undefined;
  setRateLimitMaxCount: React.Dispatch<React.SetStateAction<number | undefined>>;
  rateLimitMaxPoints: number | undefined;
  setRateLimitMaxPoints: React.Dispatch<React.SetStateAction<number | undefined>>;
  rateLimitThreshold: number | undefined;
  setRateLimitThreshold: React.Dispatch<React.SetStateAction<number | undefined>>;

  // HEADERS
  shouldSetLimitMaxCountViaHeaders: boolean;
  setShouldSetLimitMaxCountViaHeaders: React.Dispatch<React.SetStateAction<boolean>>;
  responseHeaderMaxCountKeyPath: string[] | null;
  setResponseHeaderMaxCountKeyPath: React.Dispatch<React.SetStateAction<string[] | null>>;
  shouldUpdateRateLimitCurrentCountViaHeaders: boolean;
  setShouldUpdateRateLimitCurrentCountViaHeaders: React.Dispatch<React.SetStateAction<boolean>>;
  responseHeaderRateLimitCurrentCountKeyPath: string[] | null;
  setResponseHeaderRateLimitCurrentCountKeyPath: React.Dispatch<
    React.SetStateAction<string[] | null>
  >;
  shouldUpdateRateLimitCountLeftViaHeaders: boolean;
  setShouldUpdateRateLimitCountLeftViaHeaders: React.Dispatch<React.SetStateAction<boolean>>;
  responseHeaderRateLimitCountLeftKeyPath: string[] | null;
  setResponseHeaderRateLimitCountLeftKeyPath: React.Dispatch<React.SetStateAction<string[] | null>>;
  shouldUpdateResetTimestampKeyPathViaHeaders: boolean;
  setShouldUpdateResetTimestampKeyPathViaHeaders: React.Dispatch<React.SetStateAction<boolean>>;
  responseHeaderResetTimestampKeyPath: string[] | null;
  setResponseHeaderResetTimestampKeyPath: React.Dispatch<React.SetStateAction<string[] | null>>;
  resetExpirationFormat: RateLimitResetTimestampFormat | undefined;
  setResetExpirationFormat: React.Dispatch<
    React.SetStateAction<RateLimitResetTimestampFormat | undefined>
  >;
  // ADVANCED
  aggressiveRateLimitThreshold: number | undefined;
  setAggressiveRateLimitThreshold: React.Dispatch<React.SetStateAction<number | undefined>>;
  defaultIncrement: number | undefined;
  setDefaultIncrement: React.Dispatch<React.SetStateAction<number | undefined>>;
  maximumRetryCount: number | undefined;
  setMaximumRetryCount: React.Dispatch<React.SetStateAction<number | undefined>>;
  retryBackoffFactor: number | undefined;
  setRetryBackoffFactor: React.Dispatch<React.SetStateAction<number | undefined>>;
  formRateLimitConfigurationData: () => RateLimitConfigurationIntegrationBuilder | null;
  canSubmitRateLimitConfiguration: boolean;
}

const RateLimitConfigurationContext = React.createContext<RateLimitConfigurationContextState>({
  integrationID: "",
  apiEndpointID: undefined,
  setAPIEndpointID: () => {},
  isActive: true,
  setIsActive: () => {},
  rateLimitType: RateLimitType.REQUEST_FREQUENCY,
  setRateLimitType: () => {},
  rateLimitTimePeriod: RateLimitTimePeriod.SECONDS,
  setRateLimitTimePeriod: () => {},
  rateLimitTimeValue: 1,
  setRateLimitTimeValue: () => {},
  rateLimitMaxCount: undefined,
  setRateLimitMaxCount: () => {},
  rateLimitMaxPoints: undefined,
  setRateLimitMaxPoints: () => {},
  rateLimitThreshold: 80,
  setRateLimitThreshold: () => {},
  // HEADERS
  shouldSetLimitMaxCountViaHeaders: false,
  setShouldSetLimitMaxCountViaHeaders: () => {},
  responseHeaderMaxCountKeyPath: [],
  setResponseHeaderMaxCountKeyPath: () => {},
  shouldUpdateRateLimitCurrentCountViaHeaders: false,
  setShouldUpdateRateLimitCurrentCountViaHeaders: () => {},
  responseHeaderRateLimitCurrentCountKeyPath: [],
  setResponseHeaderRateLimitCurrentCountKeyPath: () => {},
  shouldUpdateRateLimitCountLeftViaHeaders: false,
  setShouldUpdateRateLimitCountLeftViaHeaders: () => {},
  responseHeaderRateLimitCountLeftKeyPath: [],
  setResponseHeaderRateLimitCountLeftKeyPath: () => {},
  shouldUpdateResetTimestampKeyPathViaHeaders: false,
  setShouldUpdateResetTimestampKeyPathViaHeaders: () => {},
  responseHeaderResetTimestampKeyPath: [],
  setResponseHeaderResetTimestampKeyPath: () => {},
  resetExpirationFormat: undefined,
  setResetExpirationFormat: () => {},
  // ADVANCED
  aggressiveRateLimitThreshold: 90,
  setAggressiveRateLimitThreshold: () => {},
  maximumRetryCount: undefined,
  setMaximumRetryCount: () => {},
  retryBackoffFactor: undefined,
  setRetryBackoffFactor: () => {},
  defaultIncrement: 1,
  setDefaultIncrement: () => {},
  formRateLimitConfigurationData: () => null,
  canSubmitRateLimitConfiguration: false,
});

export default RateLimitConfigurationContext;
