import { useContext, useEffect, useState } from "react";
import {
  AuthConfigIntegrationBuilder,
  LinkingFlowStepPathIntegrationBuilder,
  LinkingFlowStepPathResponseBody,
  LinkingFlowStepPathTableRowsIntegrationBuilder,
} from "../../utils/Entities";
import { generateStepPathTableRows, sortStepPathTableRows } from "../utils/helpers";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast } from "../../../shared/Toasts";
import IntegrationBuilderContext from "../../context/IntegrationBuilderContext";

interface Props {
  integrationID: string;
}

const useLoadLinkingFlowStepPathTableRows = ({ integrationID }: Props) => {
  const [authConfigs, setAuthConfigs] = useState<AuthConfigIntegrationBuilder[] | undefined>();
  const [linkingFlowStepPaths, setLinkingFlowStepPaths] = useState<
    LinkingFlowStepPathIntegrationBuilder[] | undefined
  >();
  const [stepPathTableRows, setStepPathTableRows] = useState<
    LinkingFlowStepPathTableRowsIntegrationBuilder[] | undefined
  >();
  const [
    isLoadingLinkingFlowStepPathsTableRows,
    setIsLoadingLinkingFlowStepPathsTableRows,
  ] = useState<boolean>(true);
  const [isLoadingAuthConfigs, setIsLoadingAuthConfigs] = useState<boolean>(false);
  const [isLoadingStepPaths, setIsLoadingStepPaths] = useState<boolean>(false);
  const { setIsLinkingFlowStepPathComplete } = useContext(IntegrationBuilderContext);

  function reloadLinkingFlowStepPathsAndAuthConfigs() {
    setIsLoadingLinkingFlowStepPathsTableRows(true);
    setIsLoadingAuthConfigs(true);
    setIsLoadingStepPaths(true);

    fetchWithAuth({
      path: `/integrations/${integrationID}/integration-setup-step-paths`,
      method: "GET",
      onResponse: (data: LinkingFlowStepPathResponseBody) => {
        setLinkingFlowStepPaths(data.results);
        fetchWithAuth({
          path: `/integrations/${integrationID}/auth-configs`,
          method: "GET",
          onResponse: (data: AuthConfigIntegrationBuilder[]) => {
            setAuthConfigs(data);
            setIsLoadingAuthConfigs(false);
          },
          onError: () => {
            showErrorToast("Failed to fetch authentication configurations for integration.");
            setIsLoadingAuthConfigs(false);
            setAuthConfigs([]);
          },
        });
        setIsLoadingStepPaths(false);
        setIsLinkingFlowStepPathComplete(data.results.length > 0 ? true : false);
      },
      onError: () => {
        showErrorToast("Failed to fetch linking flow steps for integration.");
        setIsLoadingStepPaths(false);
      },
    });
  }

  useEffect(() => {
    reloadLinkingFlowStepPathsAndAuthConfigs();
  }, [setStepPathTableRows]);

  useEffect(() => {
    setIsLoadingLinkingFlowStepPathsTableRows(true);
    if (!isLoadingAuthConfigs && !isLoadingStepPaths) {
      if (authConfigs && linkingFlowStepPaths) {
        setStepPathTableRows(
          sortStepPathTableRows(generateStepPathTableRows(authConfigs, linkingFlowStepPaths))
        );
      }
      setIsLoadingLinkingFlowStepPathsTableRows(false);
    }
  }, [isLoadingAuthConfigs, isLoadingStepPaths, authConfigs, linkingFlowStepPaths]);

  return {
    stepPathTableRows,
    isLoadingLinkingFlowStepPathsTableRows,
    reloadLinkingFlowStepPathsAndAuthConfigs,
    linkingFlowStepPaths,
    authConfigs,
  };
};

export default useLoadLinkingFlowStepPathTableRows;
