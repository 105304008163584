import { Button, ButtonVariant, Tooltip } from "@merge-api/merge-javascript-shared";
import { BlueprintSearchResultBlueprint } from "../../../../../models/blueprint-search/BlueprintSearchOutputModels";
import { BlueprintStatus } from "../../../../../models/Blueprints";
import { Trash } from "lucide-react";

interface Props {
  blueprintResult: BlueprintSearchResultBlueprint;
  setIsArchiveModalShown: (isArchiveModalShown: boolean) => void;
  setSelectedBlueprint: (selectedBlueprintSearchResult: BlueprintSearchResultBlueprint) => void;
}

const BlueprintSearchCardArchiveButton = ({
  blueprintResult,
  setIsArchiveModalShown,
  setSelectedBlueprint,
}: Props) => {
  const canArchive =
    !blueprintResult.published_blueprint_version &&
    blueprintResult.status !== BlueprintStatus.Archived;

  if (!canArchive) return null;

  return (
    <Tooltip title="Archive this Blueprint">
      <Button
        onClick={(event) => {
          event.stopPropagation();
          setSelectedBlueprint(blueprintResult);
          setIsArchiveModalShown(true);
        }}
        size="md"
        variant={ButtonVariant.IconOnly}
      >
        <Trash size={16} className="text-black" />
      </Button>
    </Tooltip>
  );
};

export default BlueprintSearchCardArchiveButton;
