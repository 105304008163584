import { useState } from "react";
import { Route, Switch } from "react-router-dom";
import {
  getIntegrationBuilderCreateNewRateLimitConfigurationPathForId,
  getIntegrationBuilderRateLimitConfigurationPathForId,
  getIntegrationBuilderSingleRateLimitConfigurationPathForId,
} from "../../../router/RouterUtils";
import { RateLimitConfigurationIntegrationBuilder } from "../utils/Entities";
import RateLimitConfigurationSetup from "./components/RateLimitConfigurationSetup";
import RateLimitConfigurationTable from "./components/table/RateLimitConfigurationTable";

interface RateLimitConfigurationProps {
  integrationID: string;
}

const RateLimitConfiguration = ({ integrationID }: RateLimitConfigurationProps) => {
  const [rateLimitConfigurations, setRateLimitConfigurations] = useState<
    RateLimitConfigurationIntegrationBuilder[] | undefined
  >([]);
  const [isLoadingRateLimitConfigurations, setIsLoadingRateLimitConfigurations] = useState(true);

  return (
    <div>
      <Switch>
        <Route
          exact
          path={getIntegrationBuilderRateLimitConfigurationPathForId(integrationID)}
          render={() => (
            <RateLimitConfigurationTable
              setRateLimitConfigurations={setRateLimitConfigurations}
              rateLimitConfigurations={rateLimitConfigurations}
              setIsLoadingRateLimitConfigurations={setIsLoadingRateLimitConfigurations}
              integrationID={integrationID}
              isLoadingRateLimitConfigurations={isLoadingRateLimitConfigurations}
            />
          )}
        />
        <Route
          exact
          path={getIntegrationBuilderCreateNewRateLimitConfigurationPathForId(integrationID)}
          render={() => (
            <RateLimitConfigurationSetup
              integrationID={integrationID}
              rateLimitConfigurations={rateLimitConfigurations}
              setRateLimitConfigurations={setRateLimitConfigurations}
              isLoadingRateLimitConfigurations={isLoadingRateLimitConfigurations}
              setIsLoadingRateLimitConfigurations={setIsLoadingRateLimitConfigurations}
            />
          )}
        />
        <Route
          exact
          path={getIntegrationBuilderSingleRateLimitConfigurationPathForId(integrationID)}
          render={() => (
            <RateLimitConfigurationSetup
              integrationID={integrationID}
              rateLimitConfigurations={rateLimitConfigurations}
              setRateLimitConfigurations={setRateLimitConfigurations}
              isLoadingRateLimitConfigurations={isLoadingRateLimitConfigurations}
              setIsLoadingRateLimitConfigurations={setIsLoadingRateLimitConfigurations}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default RateLimitConfiguration;
