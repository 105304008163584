import { Modal } from "react-bootstrap";
import classNames from "classnames";
import styled from "styled-components";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";

type MergeModalProps = {
  show: boolean;
  title?: string;
  nav?: JSX.Element;
  onHide?: () => void;
  onShow?: () => void;
  children: React.ReactNode;
  centered?: boolean | null;
  dialogClassName?: string;
  bodyClassName?: string;
  ref?: any;
  fitContent?: boolean;
};

const Header = styled(DeprecatedH4)`
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 20px;
  line-height: 44px;
  color: #b0b7c3;
`;

const StyledModalHeader = styled(Modal.Header)`
  border-bottom: 0px;
  padding: 32px 24px 12px 24px;
`;

function ChangelogModal(props: MergeModalProps) {
  return (
    <Modal
      show={props.show ?? false}
      onHide={props.onHide}
      onShow={props.onShow}
      centered={!!props.centered || true}
      dialogClassName={props.dialogClassName}
      ref={props.ref}
      className={classNames("modal-shadow", props.fitContent ? "fit-content" : "")}
    >
      <StyledModalHeader closeButton>
        <Header className="mb-0">{props.title}</Header>
      </StyledModalHeader>
      <Modal.Body className={props.bodyClassName}>{props.children}</Modal.Body>
    </Modal>
  );
}

export default ChangelogModal;
