import MergeModal from "../../../shared/MergeModal";
import JSONSchemaConverterFormField from "../../../integrations/api-endpoints/JSONSchemaConverterFormField";
import { BlueprintParameterSchema } from "../../../../models/Blueprints";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { showErrorToast } from "../../../shared/Toasts";

interface Props {
  schema: undefined | BlueprintParameterSchema<any>;
  setSchema: (x: undefined | BlueprintParameterSchema<any>) => void;
  isShowingSchemaEditor: boolean;
  setIsShowingSchemaEditor: (x: boolean) => void;
}

const ParameterSchemaEditorModal = ({
  schema,
  setSchema,
  isShowingSchemaEditor,
  setIsShowingSchemaEditor,
}: Props) => {
  const [parameterSchemaString, setParameterSchemaString] = useState<string>("");
  useEffect(() => {
    if (schema) {
      setParameterSchemaString(JSON.stringify(schema, null, 2));
    }
  }, [schema]);

  const parseAndSetSchema = () => {
    try {
      setSchema(JSON.parse(parameterSchemaString));
      return true;
    } catch (error) {
      showErrorToast(`Failed to parse schema: ${error}`);
      return false;
    }
  };

  return (
    <MergeModal
      show={isShowingSchemaEditor}
      onHide={() => {
        setIsShowingSchemaEditor(false);
      }}
      title={"Edit Parameter Schema"}
      bodyClassName="overflow-scroll"
    >
      <JSONSchemaConverterFormField
        currentValue={parameterSchemaString}
        setValue={(newParamaterSchema) => {
          setParameterSchemaString(newParamaterSchema);
        }}
        numRows={5}
      />
      <Button
        size="sm"
        variant="outline-primary"
        onClick={() => {
          const isSuccess = parseAndSetSchema();
          setIsShowingSchemaEditor(!isSuccess);
        }}
      >
        Set schema
      </Button>
    </MergeModal>
  );
};

export default ParameterSchemaEditorModal;
