import React from "react";
import { IntegrationForIntegrationBuilder } from "../utils/Entities";
import { DiffModelTypeEnum } from "../../../models/DiffModels";
import { ChecklistStatusValue } from "../../../constants";

export interface IntegrationBuilderContextState {
  // integration context
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  integration: IntegrationForIntegrationBuilder | undefined;
  setIntegration: (integration: IntegrationForIntegrationBuilder) => void;

  /**
   * Submit context - handles ability to submit form, if the submit button appears, and the loading action itself
   */

  canSubmit: boolean;
  setCanSubmit: (canSubmit: boolean) => void;
  onSubmit: () => void;
  setOnSubmit: (onSubmit: () => void) => void;
  isLoadingSubmit: boolean;
  setIsLoadingSubmit: (isLoadingSubmit: boolean) => void;
  submitButtonText: string;
  setSubmitButtonText: (submitText: string) => void;
  shouldRenderSubmitButton: boolean;
  setShouldRenderSubmitButton: (renderSubmitButton: boolean) => void;
  currentStateForDiff: { [key: string]: any } | undefined;
  setCurrentStateForDiff: (currentState: { [key: string]: any } | undefined) => void;
  newStateForDiff: { [key: string]: any } | undefined;
  setNewStateForDiff: (newState: { [key: string]: any } | undefined) => void;
  modelTypeForDiff: DiffModelTypeEnum | undefined;
  setModelTypeForDiff: (modelTypeForDiff: DiffModelTypeEnum | undefined) => void;
  shouldHideDiffModal: boolean;
  setShouldHideDiffModal: (shouldHideDiffModal: boolean) => void;

  /**
   * Component rendering context
   */
  isRightPanelOpen: boolean;
  setIsRightPanelOpen: (value: boolean) => void;
  rightPanelChildren: React.ReactNode | undefined;
  setRightPanelChildren: (value: React.ReactNode | undefined) => void;

  /**
   * Navigation button context - handles the rendering of our navigation buttons
   */

  shouldRenderNavigationButtons: boolean;
  setShouldRenderNavigationButtons: (renderNavigationButtons: boolean) => void;

  /**
   * Delete button context
   */
  onDelete: () => void;
  setOnDelete: (onDelete: () => void) => void;
  isLoadingDelete: boolean;
  setIsLoadingDelete: (isLoadingDelete: boolean) => void;
  deleteButtonText: string;
  setDeleteButtonText: (deleteButtonText: string) => void;
  shouldRenderDeleteButton: boolean;
  setShouldRenderDeleteButton: (renderDeleteButton: boolean) => void;

  /**
   * Progress context
   */
  completionProgress: number;
  setCompletionProgress: (completionProgress: number) => void;

  // integration initialization
  isIntegrationInitializationComplete: boolean;
  setIsIntegrationInitializationComplete: (isIntegrationInitializationComplete: boolean) => void;

  // ai search
  aiSearchConfigurationStatus: ChecklistStatusValue;
  setAISearchConfigurationStatus: (aiSearchConfigurationStatus: ChecklistStatusValue) => void;

  // web address schema
  isWebAddressSchemaComplete: boolean;
  setIsWebAddressSchemaComplete: (isWebAddressSchemaComplete: boolean) => void;

  // base api url
  isBaseAPIURLComplete: boolean;
  setIsBaseAPIURLComplete: (isBaseAPIURLComplete: boolean) => void;

  // authentication configuration
  isAuthenticationConfigurationComplete: boolean;
  setIsAuthenticationConfigurationComplete: (
    isAuthenticationConfigurationComplete: boolean
  ) => void;

  // pagination configuration
  isPaginationConfigurationComplete: boolean;
  setIsPaginationConfigurationComplete: (isPaginationConfigurationComplete: boolean) => void;

  // linking flow steps
  isLinkingFlowStepPathComplete: boolean;
  setIsLinkingFlowStepPathComplete: (isLinkingFlowStepPathComplete: boolean) => void;

  // linking flow step config fields
  isSetupStepsConfigFieldsComplete: boolean;
  setIsSetupStepsConfigFieldsComplete: (isSetupStepsConfigFieldsComplete: boolean) => void;

  // api endpoints
  isAPIEndpointsComplete: boolean;
  setIsAPIEndpointsComplete: (value: boolean) => void;

  // api endpoint config fields
  isAPIEndpointsConfigFieldsComplete: boolean;
  setIsAPIEndpointsConfigFieldsComplete: (isAPIEndpointsConfigFieldsComplete: boolean) => void;

  // rate limits
  isRateLimitConfigurationComplete: boolean;
  setIsRateLimitConfigurationComplete: (isRateLimitConfigurationComplete: boolean) => void;

  // logo & customer-facing fields
  isCustomerFacingFieldsComplete: boolean;
  setIsCustomerFacingFieldsComplete: (value: boolean) => void;

  // compute if there is unsaved changes
  computeHasUnsavedChanges: () => boolean;
  resetDiffStates: () => void;
  asyncResetDiffStates: () => Promise<void>;

  /**
   * Stage button context
   */
  onStage: () => void;
  setOnStage: (onStage: () => void) => void;
  isLoadingStage: boolean;
  setIsLoadingStage: (isLoadingStage: boolean) => void;
  stageButtonText: string;
  setStageButtonText: (stageButtonText: string) => void;
  shouldRenderStageButton: boolean;
  setShouldRenderStageButton: (renderStageButton: boolean) => void;
  canStage: boolean;
  setCanStage: (canStage: boolean) => void;

  // permissioning
  // "isGatedByPermissioning" indicates whether the model supports permissioning
  // If "isGatedByPermissioning" is true for a model, then we should be checking its permissions
  // If false for a model, then no need to check
  isGatedByPermissioning: boolean;
  setIsGatedByPermissioning: (value: boolean) => void;
  hasPermissionToEdit: boolean;
  setHasPermissionToEdit: (value: boolean) => void;

  /**
   * Whether a component is marked for deletion
   */
  markedForDeletion: boolean;
  setMarkedForDeletion: (value: boolean) => void;
}

const IntegrationBuilderContext = React.createContext<IntegrationBuilderContextState>({
  // integration context
  isLoading: true,
  setIsLoading: () => {},
  integration: undefined,
  setIntegration: () => {},
  // submission button context
  canSubmit: false,
  setCanSubmit: () => {},
  onSubmit: () => {},
  setOnSubmit: () => {},
  isLoadingSubmit: false,
  setIsLoadingSubmit: () => {},
  submitButtonText: "Save",
  setSubmitButtonText: () => {},
  shouldRenderSubmitButton: true,
  setShouldRenderSubmitButton: () => {},
  currentStateForDiff: {},
  setCurrentStateForDiff: () => {},
  newStateForDiff: {},
  setNewStateForDiff: () => {},
  modelTypeForDiff: undefined,
  setModelTypeForDiff: () => {},
  shouldHideDiffModal: false,
  setShouldHideDiffModal: () => {},
  // navigation button context
  shouldRenderNavigationButtons: true,
  setShouldRenderNavigationButtons: () => {},
  // delete button context
  onDelete: () => {},
  setOnDelete: () => {},
  isLoadingDelete: false,
  setIsLoadingDelete: () => {},
  deleteButtonText: "Delete",
  setDeleteButtonText: () => {},
  shouldRenderDeleteButton: false,
  setShouldRenderDeleteButton: () => {},
  // progress context
  completionProgress: 0,
  setCompletionProgress: () => {},
  isIntegrationInitializationComplete: false,
  setIsIntegrationInitializationComplete: () => {},
  isWebAddressSchemaComplete: false,
  setIsWebAddressSchemaComplete: () => {},
  isBaseAPIURLComplete: false,
  setIsBaseAPIURLComplete: () => {},
  isAuthenticationConfigurationComplete: false,
  setIsAuthenticationConfigurationComplete: () => {},
  isPaginationConfigurationComplete: false,
  setIsPaginationConfigurationComplete: () => {},
  isLinkingFlowStepPathComplete: false,
  setIsLinkingFlowStepPathComplete: () => {},
  isSetupStepsConfigFieldsComplete: false,
  setIsSetupStepsConfigFieldsComplete: () => {},
  isAPIEndpointsComplete: false,
  setIsAPIEndpointsComplete: () => {},
  isAPIEndpointsConfigFieldsComplete: false,
  setIsAPIEndpointsConfigFieldsComplete: () => {},
  isRateLimitConfigurationComplete: false,
  setIsRateLimitConfigurationComplete: () => {},
  isCustomerFacingFieldsComplete: false,
  setIsCustomerFacingFieldsComplete: () => {},
  aiSearchConfigurationStatus: ChecklistStatusValue.NOT_IMPLEMENTED,
  setAISearchConfigurationStatus: () => {},
  // Permissioning
  isGatedByPermissioning: false,
  setIsGatedByPermissioning: () => {},
  hasPermissionToEdit: false,
  setHasPermissionToEdit: () => {},
  // Component rendering context
  isRightPanelOpen: false,
  setIsRightPanelOpen: () => {},
  rightPanelChildren: undefined,
  setRightPanelChildren: () => {},
  computeHasUnsavedChanges: () => false,
  resetDiffStates: () => {},
  asyncResetDiffStates: () => Promise.resolve(),
  // Stage button context
  onStage: () => {},
  setOnStage: () => {},
  isLoadingStage: false,
  setIsLoadingStage: () => {},
  stageButtonText: "Stage",
  setStageButtonText: () => {},
  shouldRenderStageButton: false,
  setShouldRenderStageButton: () => {},
  canStage: false,
  setCanStage: () => {},
  markedForDeletion: false,
  setMarkedForDeletion: () => {},
});

export default IntegrationBuilderContext;
