import { Text } from "@merge-api/merge-javascript-shared";
import { ReactNode } from "react";
interface IntegrationBuilderEmptyStateProps {
  title: string;
  subtitle: string | ReactNode;
  numberOfColumns: number;
}

const IntegrationBuilderEmptyState = ({
  title,
  subtitle,
  numberOfColumns,
}: IntegrationBuilderEmptyStateProps) => {
  return (
    <tr>
      <td colSpan={numberOfColumns}>
        <div className="flex flex-col text-center align-middle p-4">
          <Text className="mb-2 text-gray-40" variant="h4">
            {title}
          </Text>
          <Text className="text-gray-40" variant="sm">
            {subtitle}
          </Text>
        </div>
      </td>
    </tr>
  );
};
export default IntegrationBuilderEmptyState;
