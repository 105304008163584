import { Accordion, Text } from "@merge-api/merge-javascript-shared";

interface Props {
  title: string;
  children: React.ReactNode;
  isAccordionWithinAccordion?: boolean;
  defaultExpanded?: boolean;
}

const LeftPanelAccordion = ({
  title,
  children,
  isAccordionWithinAccordion,
  defaultExpanded = false,
}: Props) => {
  return (
    <Accordion
      title={<Text variant="h6">{title}</Text>}
      titleClassName="p-4"
      variant={isAccordionWithinAccordion ? "outline" : "none"}
      className="w-full bg-gray-0"
      defaultExpanded={defaultExpanded}
    >
      <>
        <hr className="bg-gray-20 p-0 m-0" />
        <div className="flex flex-col items-start w-full space-y-4 p-4">{children}</div>
      </>
    </Accordion>
  );
};

export default LeftPanelAccordion;
