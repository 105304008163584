import { fetchWithAuth } from "../../../../api-client/api_client";
import { APIEndpointMethod } from "../../../../models/Entities";

interface AbortEndpointCollectionRunProps {
  collection_run_id: string;
}

const useAbortEndpointCollectionRun = ({ collection_run_id }: AbortEndpointCollectionRunProps) => {
  const abortEndpointCollectionRun = (onResponse = () => {}, onError = () => {}) => {
    fetchWithAuth({
      path: `/integrations/integration-builder/api-endpoint-collection-run/${collection_run_id}/abort`,
      method: APIEndpointMethod.POST,
      body: {},
      onResponse,
      onError: (err) => {
        console.error(err);
        onError();
      },
    });
  };

  return { abortEndpointCollectionRun };
};

export default useAbortEndpointCollectionRun;
