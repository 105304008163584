import { Button, ButtonVariant, Alert, Text } from "@merge-api/merge-javascript-shared";
import { Integration } from "../../../models/Entities";
import useDocumentTitle from "../../shared/useDocumentTitle";
import APIEndpointsTable from "./APIEndpointsTable";
import APIEndpointsTableWrapper from "./APIEndpointsTablePageWrapper";
import { navigateToIntegrationBuilderAPIEndpointTable } from "../../../router/RouterUtils";
import { useHistory } from "react-router-dom";
import { Lightbulb } from "lucide-react";

type Props = { integrationMeta: Integration };

const APIEndpointsOverview = ({ integrationMeta }: Props) => {
  useDocumentTitle(`${integrationMeta.name} - Endpoints`, [integrationMeta.name]);
  const history = useHistory();
  return (
    <div className="flex flex-column w-full space-y-6">
      <Alert color="blue" icon={<Lightbulb size={16} className="text-blue-40" />}>
        <div className="flex w-full flex-row items-center justify-between">
          <Text variant="h6">You can now edit API Endpoints with the Integration Builder!</Text>
          <Button
            size="sm"
            variant={ButtonVariant.PrimaryBlue}
            onClick={() =>
              navigateToIntegrationBuilderAPIEndpointTable(history, integrationMeta.id)
            }
          >
            Navigate to Integration Builder
          </Button>
        </div>
      </Alert>
      <APIEndpointsTableWrapper>
        <APIEndpointsTable />
      </APIEndpointsTableWrapper>
    </div>
  );
};

export default APIEndpointsOverview;
