import { useState } from "react";
import { BlueprintParameterValueCustomArray, BlueprintStep } from "../../../../models/Blueprints";
import useBlueprintContext from "../../context/useBlueprintContext";
import {
  deleteItemFromCustomArrayParameterValue,
  getCurrentStepCustomJSONParameterValue,
  JSONPath,
  parameterKeyForJSONPath,
  updateJSONNestedParameterValue,
} from "./CustomJSONUtils";

export type KeyToggles = Record<string, boolean>;
export type ArrayParameterItemsMap = Record<string, number[]>;

export type CustomJSONModalContext = {
  getArrayParameterItems: (x: JSONPath) => number[];
  canAddItemToArrayParameter: (x: JSONPath) => boolean;
  addItemToArrayParameter: (x: JSONPath) => void;
  deleteItemFromArrayParameter: (x: JSONPath, y: number) => void;
  clearCustomArrayParametersContext: () => void;
  clearCustomArrayParametersContextForParameter: (x: string) => void;
};

/**
 * Given the key to a custom array/object parameter value,
 * this context provides utility methods for managing the
 * state of all of the arrays that are nested inside of that
 * root parameter value. In this context, "parameter items"
 * refers to a simple array of the indices (e.g. [0, 1, 2]).
 * This is because when we render the input fields for these
 * array items, the parameterPath+index will take care of
 * looking up the actual nested parameter values for us.
 */
function useCustomArrayParametersContext(
  rootParameterKey: string | undefined
): CustomJSONModalContext {
  const [arrayParameterItemsMap, setArrayParameterItemsMap] = useState<ArrayParameterItemsMap>({});
  const { selectedStep, updateStepParameterValue } = useBlueprintContext();
  const step = selectedStep as BlueprintStep;
  const failEarly = rootParameterKey === undefined;

  const createItems = (length: number) => {
    const items = [];
    for (let i = 0; i < length; i++) {
      items.push(i);
    }
    return items;
  };

  const getArrayParameterItems = (parameterPath: JSONPath) => {
    if (failEarly) {
      return [];
    }
    const parameterKey = parameterKeyForJSONPath(parameterPath);
    if (parameterKey in arrayParameterItemsMap) {
      return arrayParameterItemsMap[parameterKey];
    }
    const parameterValue = getCurrentStepCustomJSONParameterValue(
      step,
      rootParameterKey,
      parameterPath
    );
    const arrayParameterValue = parameterValue as BlueprintParameterValueCustomArray;
    if (arrayParameterValue && arrayParameterValue.array_values) {
      const parameterItems = createItems(arrayParameterValue.array_values.length);
      setArrayParameterItemsMap({
        ...arrayParameterItemsMap,
        [parameterKey]: parameterItems,
      });
      return parameterItems;
    }
    return [0];
  };

  const canAddItemToArrayParameter = (parameterPath: JSONPath) => {
    const parameterKey = parameterKeyForJSONPath(parameterPath);
    const parameterValue = getCurrentStepCustomJSONParameterValue(
      step,
      rootParameterKey,
      parameterPath
    );
    const arrayParameterValue = parameterValue as BlueprintParameterValueCustomArray;
    if (arrayParameterValue && arrayParameterValue.array_values) {
      const currentLength =
        parameterKey in arrayParameterItemsMap ? arrayParameterItemsMap[parameterKey].length : 0;
      if (currentLength === 0) {
        return false;
      }
      return arrayParameterValue.array_values.length >= currentLength;
    }
    return false;
  };

  const addItemToArrayParameter = (parameterPath: JSONPath) => {
    if (failEarly) {
      return;
    }
    const parameterKey = parameterKeyForJSONPath(parameterPath);
    const parameterItems =
      parameterKey in arrayParameterItemsMap ? arrayParameterItemsMap[parameterKey] : [];
    const newParameterItems = [...parameterItems, parameterItems.length];
    setArrayParameterItemsMap({
      ...arrayParameterItemsMap,
      [parameterKey]: newParameterItems,
    });
  };

  const deleteItemFromArrayParameter = (parameterPath: JSONPath, index: number) => {
    if (failEarly) {
      return;
    }
    const parameterKey = parameterKeyForJSONPath(parameterPath);
    const parameterItems =
      parameterKey in arrayParameterItemsMap ? arrayParameterItemsMap[parameterKey] : [];
    if (index >= parameterItems.length) {
      return;
    }
    const newParameterItems = createItems(parameterItems.length - 1);
    setArrayParameterItemsMap({
      ...arrayParameterItemsMap,
      [parameterKey]: newParameterItems,
    });
    const newCustomArrayParameterValue = deleteItemFromCustomArrayParameterValue(
      step,
      rootParameterKey,
      parameterPath,
      index
    );
    const newRootParameterValue = updateJSONNestedParameterValue(
      step,
      rootParameterKey,
      parameterPath,
      newCustomArrayParameterValue
    );
    updateStepParameterValue(step, rootParameterKey, newRootParameterValue);
  };

  const clearCustomArrayParametersContext = () => {
    setArrayParameterItemsMap({});
  };

  const clearCustomArrayParametersContextForParameter = (parameterKey: string) => {
    const newArrayParameterItemsMap = Object.fromEntries(
      Object.entries(arrayParameterItemsMap).filter(([key, _]) => key !== parameterKey)
    );
    setArrayParameterItemsMap({
      ...newArrayParameterItemsMap,
    });
  };

  return {
    getArrayParameterItems,
    canAddItemToArrayParameter,
    addItemToArrayParameter,
    deleteItemFromArrayParameter,
    clearCustomArrayParametersContext,
    clearCustomArrayParametersContextForParameter,
  };
}

export default useCustomArrayParametersContext;
