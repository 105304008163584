import { ComponentDiffType, IntegrationComponentNames } from "../publish-requests/types";
import { Badge } from "@merge-api/merge-javascript-shared";

export const formattedModelClassName = (modelClassName: string): string => {
  switch (modelClassName) {
    case IntegrationComponentNames.BLUEPRINT:
      return "Blueprints";
    case IntegrationComponentNames.MAPPING_TEST:
      return "Mapping Tests";
    case IntegrationComponentNames.SCRAPER:
      return "Scrapers";
    case IntegrationComponentNames.API_ENDPOINT_FILTER:
      return "API Endpoint Parameters";
    case IntegrationComponentNames.SELECTIVE_SYNC_FILTER_SCHEMA:
      return "Selective Sync Filter Schemas";
    default:
      throw new Error("Operation not supported for this model class.");
  }
};

export const DiffTypeBadge = ({ diffType }: { diffType: ComponentDiffType }) => {
  switch (diffType) {
    case ComponentDiffType.ADDED:
      return <Badge color="teal">Created</Badge>;
    case ComponentDiffType.UPDATED:
      return <Badge color="blue">Edited</Badge>;
    case ComponentDiffType.DELETED:
      return <Badge color="red">Deleted</Badge>;
  }
};
