import { Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import InputFormField from "../../blueprint-editor/right-panel/InputFormField";
import ChangelogModal from "./ChangelogModal";
import { PUBLISH_STATES } from "../../../constants";
import CheckboxFormField from "../../blueprint-editor/right-panel/CheckboxFormField";
import SpinnerButton from "../../shared/SpinnerButton";

type Props = {
  isShown: boolean;
  onHide: () => void;
  publishIntent: string;
  publishAction: () => void;
  asanaTicketField: string;
  setAsanaTicketField: (asanaTicketField: string) => void;
  changelogCommentField: string;
  setChangelogCommentField: (changelogCommentField: string) => void;
  resetTimestampsOnPublish: boolean;
  setResetTimestampsOnPublish: (resetTimestamps: boolean) => void;
  isLoading: boolean;
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  float: left;
  width: 100%;
  justify-content: center;
`;

const PublishButton = styled(SpinnerButton)`
  background: rgb(0, 0, 0);
  font-weight: 500;
  color: #fff;
  &:hover {
    color: #fff;
  }
`;

// const DarkButton = styled(Button)`
//   background: #000000;
//   font-weight: 500;
// `;

const CancelButton = styled(Button)`
  font-weight: 500;
`;

const BottomRow = styled(Row)`
  margin-bottom: 8px;
`;

const TitleText = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const ChangelogPublishModal = ({
  isShown,
  onHide,
  publishIntent,
  publishAction,
  asanaTicketField,
  setAsanaTicketField,
  changelogCommentField,
  setChangelogCommentField,
  resetTimestampsOnPublish,
  setResetTimestampsOnPublish,
  isLoading,
}: Props) => {
  return (
    <ChangelogModal
      show={isShown}
      onHide={onHide}
      title={
        publishIntent === PUBLISH_STATES.PUBLISHED ? "Publish blueprint" : "Unpublish blueprint"
      }
      bodyClassName="overflow-hidden"
    >
      <InputContainer>
        <TitleText>Enter your Asana ticket URL</TitleText>
        <InputFormField
          onChange={(newValue) => setAsanaTicketField(newValue)}
          currentValue={asanaTicketField}
        />
        <TitleText>Enter any comments you have regarding the change you're making</TitleText>
        <InputFormField
          onChange={(newValue) => setChangelogCommentField(newValue)}
          currentValue={changelogCommentField}
        />
        {publishIntent === PUBLISH_STATES.PUBLISHED && (
          <CheckboxFormField
            title="Reset timestamps on publish"
            currentValue={resetTimestampsOnPublish}
            onChange={() => setResetTimestampsOnPublish(!resetTimestampsOnPublish)}
          />
        )}
      </InputContainer>
      <BottomRow className="mt-6">
        <Col>
          <CancelButton className="btn-block" variant="outline-danger" onClick={onHide}>
            Cancel
          </CancelButton>
        </Col>
        <Col>
          <PublishButton
            className="btn-block"
            text={
              publishIntent === PUBLISH_STATES.PUBLISHED
                ? "Publish Blueprint"
                : "Unpublish Blueprint"
            }
            disabled={!asanaTicketField || !changelogCommentField}
            onClick={publishAction}
            isLoading={isLoading}
          />
        </Col>
      </BottomRow>
    </ChangelogModal>
  );
};

export default ChangelogPublishModal;
