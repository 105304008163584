import SelectiveSyncFilterBuilderContext, {
  SelectiveSyncFilterBuilderContextState,
} from "./SelectiveSyncFilterBuilderContext";

interface Props extends SelectiveSyncFilterBuilderContextState {
  children: JSX.Element;
}

// Wraps around App to be able to pull global state anywhere in tree via useContext
const SelectiveSyncFilterBuilderContextProvider = ({ children, ...contextProps }: Props) => (
  <SelectiveSyncFilterBuilderContext.Provider value={contextProps}>
    {children}
  </SelectiveSyncFilterBuilderContext.Provider>
);

export default SelectiveSyncFilterBuilderContextProvider;
