import clsx from "clsx";
import MultiSelectHeader from "../../../shared/MultiSelectHeader";

interface BinaryChoiceButtonsProps {
  binaryChoice: boolean | undefined;
  setBinaryChoice: React.Dispatch<React.SetStateAction<any | boolean | undefined>>;
  title: string;
  subtitle?: string;
  className?: string;
  learnMoreText?: string;
  required?: boolean;
  dataTestID?: string;
}

const BinaryChoiceButtons = ({
  binaryChoice,
  setBinaryChoice,
  title,
  subtitle,
  className,
  learnMoreText,
  required = false,
  dataTestID,
}: BinaryChoiceButtonsProps) => {
  const handleButtonClick = (value: boolean) => {
    setBinaryChoice(value);
  };

  const options = [
    {
      value: true,
      text: "Yes",
      disabled: false,
      selected: binaryChoice === true,
      onClick: handleButtonClick,
    },
    {
      value: false,
      text: "No",
      disabled: false,
      selected: binaryChoice === false,
      onClick: handleButtonClick,
    },
  ];
  return (
    <MultiSelectHeader
      title={title}
      subtitle={subtitle}
      learnMoreText={learnMoreText}
      options={options}
      required={required}
      className={clsx("w-full", className)}
      dataTestID={dataTestID}
    />
  );
};

export default BinaryChoiceButtons;
