import clsx from "clsx";
import PostmanTableRow, { Row } from "./PostmanTableRow";
import PostmanSelectTableRow from "./PostmanSelectTableRow";
import useIntegrationBuilderContext from "../../context/useIntegrationBuilderContext";

export const BLANK_POSTMAN_TABLE_ROW: Row = {
  active: true,
  key: "",
  value: "",
};

type PostmanTableProps = {
  className?: string;
  rows: Row[];
  onChange: (newRows: Row[]) => void;
  keyHeader?: string;
  valueHeader?: string;
  isSelectRow?: boolean;
  selectOptions?: string[];
  keysDisabled?: boolean;
  userInputDisabled?: boolean;
  isUserInput?: boolean;
  addRowDisabled?: boolean;
  pushDefaultValueOnNewRows?: boolean;
};

/**
 * Table for editing contents of an object. Designed to look like Postman.
 */
const PostmanTable = ({
  className,
  rows,
  onChange,
  keyHeader,
  valueHeader,
  isSelectRow,
  selectOptions,
  isUserInput = true,
  keysDisabled = false,
  userInputDisabled = false,
  pushDefaultValueOnNewRows = false,
  addRowDisabled = false,
}: PostmanTableProps) => {
  const { hasPermissionToEdit } = useIntegrationBuilderContext();

  // event handlers

  // creates a new blank row
  const createBlankPostmanTableRow = () => {
    return {
      active: true,
      key: "",
      value: "",
    };
  };

  // createBlankPostmanTableRow with default value
  const createBlankPostmanTableRowDefaultValue = () => {
    return {
      active: true,
      key: "",
      value: "default value",
      isDefault: true,
    };
  };
  // adding a new row
  const onNewLineClick = () => {
    const newBlankRow = pushDefaultValueOnNewRows
      ? createBlankPostmanTableRowDefaultValue()
      : createBlankPostmanTableRow();
    onChange([...rows, newBlankRow]);
  };

  // deleting a row
  const onRowRemove = (index: number) => {
    const newRows = rows.filter((_, i) => i !== index);
    onChange(newRows);
  };

  const onRowChange = (index: number) => (
    key: "key" | "value" | "active",
    value: string | boolean
  ) => {
    let newRows = [...rows];

    // Update the value in the row
    if (key === "active" && typeof value === "boolean") {
      newRows[index][key] = value;
    } else if (typeof value === "string" && key !== "active") {
      newRows[index][key] = value;
    }

    onChange(newRows);
  };

  return (
    <div className={clsx("flex flex-col border-[0.5px] border-gray-20 rounded-md", className)}>
      {/* header */}
      <div className={clsx("flex flex-row", hasPermissionToEdit && !userInputDisabled && "pl-12")}>
        <div
          className={clsx(
            "text-black text-sm font-semibold w-[260.5px] py-2.5 px-3",
            hasPermissionToEdit && !userInputDisabled && "border-l-[0.5px] border-l-gray-20"
          )}
        >
          {keyHeader || "Key"}
        </div>
        <div className="text-black text-sm font-semibold py-2.5 px-3 border-l-[0.5px] border-l-gray-20">
          {valueHeader || "Value"}
        </div>
      </div>

      {/* row */}
      <div className="overflow-auto">
        {rows.map((row, index) =>
          isSelectRow ? (
            <PostmanSelectTableRow
              key={index}
              row={row}
              isLastRow={index === rows.length - 1}
              onNewLineClick={onNewLineClick}
              onRowChange={onRowChange(index)}
              onRowRemove={() => onRowRemove(index)}
              isUserInput={true}
              options={selectOptions || []}
            />
          ) : (
            <PostmanTableRow
              key={index}
              row={row}
              isLastRow={index === rows.length - 1}
              onNewLineClick={onNewLineClick}
              onRowChange={onRowChange(index)}
              onRowRemove={() => onRowRemove(index)}
              keysDisabled={keysDisabled}
              userInputDisabled={userInputDisabled}
              isUserInput={isUserInput}
              disabled={!hasPermissionToEdit}
              addRowDisabled={addRowDisabled}
            />
          )
        )}
      </div>
    </div>
  );
};

export default PostmanTable;
