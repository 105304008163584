import { Badge } from "@merge-api/merge-javascript-shared";
import { MappingTestExecution, MappingTestExecutionResult } from "../../../models/MappingTests";

interface Props {
  testBlockExecution: MappingTestExecution;
}

const MappingTestBlockExecutionStatusBadge = ({ testBlockExecution }: Props) => {
  return (
    <Badge
      color={testBlockExecution.result === MappingTestExecutionResult.SUCCESS ? "teal" : "red"}
    >
      {testBlockExecution.result === MappingTestExecutionResult.SUCCESS
        ? "Success"
        : testBlockExecution.result === MappingTestExecutionResult.EXCEPTION
        ? "Failed run with exceptions"
        : testBlockExecution.exit_data?.every(
            (blueprintExecution) =>
              blueprintExecution.blueprint_meta.blueprint_run_exit_code === 200
          )
        ? "Failed assertions"
        : "Failed run"}
    </Badge>
  );
};

export default MappingTestBlockExecutionStatusBadge;
