import { AuthConfigMeta } from "../../../models/AuthConfig";
import { useState } from "react";
import { Link } from "@merge-api/merge-javascript-shared";
import InputFormField from "../../blueprint-editor/right-panel/InputFormField";
import { Col, Row, Form } from "react-bootstrap";
import { showSuccessToast } from "../../shared/Toasts";
import { AuthType } from "../../../models/Entities";
import { useHistory } from "react-router-dom";
import { navigateToAuthConfigsTable } from "../../../router/RouterUtils";
import {
  SaveAuthConfigButton,
  AuthConfigUpdateProps,
  QUERY_PARAMS_LOCATION_CHOICES,
  GRANT_TYPE_CHOICES,
  CLIENT_CREDENTIAL_SOURCE_CHOICES,
  ClientCredentialSource,
} from "./AuthConfigShared";
import AuthConfigEditFormRightPanel from "./AuthConfigEditFormRightPanel";
import DropdownFormField from "../../blueprint-editor/right-panel/DropdownFormField";
import { getIntegrationBuilderLinkingFlowStepsPathConfigurationForId } from "../../../router/RouterUtils";

type Props = {
  authConfig?: AuthConfigMeta;
  integrationID: string;
  integrationCategory: string | undefined;
  authName: string | undefined;
  isEditingExistingConfig: boolean;
};

const AuthConfigOAuthForm = (props: Props) => {
  const history = useHistory();

  const [oauthAuthorizeURL, setOauthAuthorizeURL] = useState<undefined | string>(
    props.authConfig?.oauth_authorize_url
  );
  const [oauthTokenURL, setOAuthTokenURL] = useState<undefined | string>(
    props.authConfig?.oauth_token_url
  );
  const [oauthRefreshURL, setOAuthRefreshURL] = useState<undefined | string>(
    props.authConfig?.oauth_refresh_url
  );
  const [oauthTokenURLParamsLocation, setOauthTokenURLParamsLocation] = useState<
    undefined | string
  >(props.authConfig?.oauth_token_url_params_location);

  const [oauthResponseTokenKeyPath, setOauthResponseTokenKeyPath] = useState<undefined | string>(
    props.authConfig?.oauth_response_token_key_path
  );

  const [oauthGrantType, setOauthGrantType] = useState<undefined | string>(
    props.authConfig?.oauth_grant_type
  );

  const [oauthClientCredentialSource, setOauthClientCredentialSource] = useState<
    undefined | string
  >(props.authConfig?.oauth_client_credential_source);

  const [oauthAccessTokenLifespan, setOauthAccessTokenLifespan] = useState<undefined | string>(
    props.authConfig?.oauth_access_token_lifespan_in_seconds
  );

  const [oauthAccessTokenExpirationKeyPath, setOauthAccessTokenExpirationKeyPath] = useState<
    undefined | string
  >(props.authConfig?.oauth_access_token_expiration_key_path);

  const [oauthRefreshTokenLifespanSeconds, setOauthRefreshTokenLifespanSeconds] = useState<
    undefined | string
  >(props.authConfig?.oauth_refresh_token_lifespan_in_seconds);

  const [oauthParamsScopes, setOauthParamsScopes] = useState<undefined | string>(
    props.authConfig?.oauth_params_scopes
  );

  const [
    oauthAuthorizeURLAdditionalRedirectURIQueryParamKeys,
    setOauthAuthorizeURLAdditionalRedirectURIQueryParamKeys,
  ] = useState<undefined | string>(
    props.authConfig?.oauth_authorize_url_additional_redirect_uri_query_param_keys
  );

  const [oauthAuthorizeURLParamKeystoExclude, setOauthAuthorizeURLParamKeystoExclude] = useState<
    undefined | string
  >(props.authConfig?.oauth_authorize_url_param_keys_to_exclude);

  const [oauthTokenURLHeaderFormat, setOauthTokenURLHeaderFormat] = useState<undefined | string>(
    props.authConfig?.oauth_token_url_header_format
  );

  const [oauthTokenURLHeaderKeysToExclude, setOauthTokenURLHeaderKeysToExclude] = useState<
    undefined | string
  >(props.authConfig?.oauth_token_url_header_keys_to_exclude);

  const [oauthRequestFieldsToExclude, setOauthRequestFieldsToExclude] = useState<
    undefined | string
  >(props.authConfig?.oauth_request_fields_to_exclude);

  const [oauthRefreshFieldsToExclude, setOauthRefreshFieldsToExclude] = useState<
    undefined | string
  >(props.authConfig?.oauth_refresh_fields_to_exclude);

  const [oauthShouldRefreshAccessToken, setOauthShouldRefreshAccessToken] = useState<
    undefined | boolean
  >(props.authConfig?.oauth_should_refresh_access_token);

  const [oauthShouldFetchAccessToken, setOauthShouldFetchAccessToken] = useState<
    undefined | boolean
  >(props.authConfig?.oauth_should_fetch_access_token);

  const [oauthUsePkce, setOauthUsePkce] = useState<undefined | boolean>(
    props.authConfig?.oauth_use_pkce
  );

  const [isLoading, setIsLoading] = useState(false);

  const clientCredentialSourceSubtitle = (
    <>
      This is used in cases in which Merge cannot set up a direct client connection with the API
      provider or the customer has selected the partnership method.{" "}
      {oauthClientCredentialSource === ClientCredentialSource.customer && (
        <>
          For a customer client credential source to work, we need to fill out the required fields{" "}
          <Link
            target="_blank"
            href={getIntegrationBuilderLinkingFlowStepsPathConfigurationForId(props.integrationID)}
          >
            here
          </Link>
          .
        </>
      )}
    </>
  );
  const authConfigUpdates: AuthConfigUpdateProps = {
    id: props.authConfig?.id,
    name: props.authName,
    authType: props.authConfig ? props.authConfig?.auth_type : AuthType.OAUTH2,
    integrationID: props.integrationID,
    integrationCategory: props.integrationCategory,
    OAuthAuthorizeURL: oauthAuthorizeURL,
    OAuthTokenURL: oauthTokenURL,
    OAuthRefreshURL: oauthRefreshURL,
    OAuthTokenURLParamsLocation: oauthTokenURLParamsLocation,
    OAuthResponseTokenKeyPath:
      oauthResponseTokenKeyPath && oauthResponseTokenKeyPath.length !== 0
        ? oauthResponseTokenKeyPath.toString().replace(/\s/g, "").split(",")
        : undefined,
    OAuthGrantType: oauthGrantType,
    OAuthClientCredentialSource: oauthClientCredentialSource,
    OAuthAccessTokenLifespan: oauthAccessTokenLifespan,
    OAuthRefreshTokenLifespan: oauthRefreshTokenLifespanSeconds,
    OAuthParamsScopes: oauthParamsScopes,
    additionalQueryParameters:
      oauthAuthorizeURLAdditionalRedirectURIQueryParamKeys &&
      oauthAuthorizeURLAdditionalRedirectURIQueryParamKeys.length !== 0
        ? oauthAuthorizeURLAdditionalRedirectURIQueryParamKeys
            .toString()
            .replace(/\s/g, "")
            .split(",")
        : undefined,
    parametersToExclude:
      oauthAuthorizeURLParamKeystoExclude && oauthResponseTokenKeyPath?.length !== 0
        ? oauthAuthorizeURLParamKeystoExclude.toString().replace(/\s/g, "").split(",")
        : undefined,
    OAuthTokenURLFormat: oauthTokenURLHeaderFormat,
    OAuthTokenURLHeaderKeysToExclude:
      oauthTokenURLHeaderKeysToExclude && oauthTokenURLHeaderKeysToExclude.length !== 0
        ? oauthTokenURLHeaderKeysToExclude.toString().replace(/\s/g, "").split(",")
        : undefined,
    OAuthRefreshFieldsToExclude:
      oauthRefreshFieldsToExclude && oauthRefreshFieldsToExclude.length !== 0
        ? oauthRefreshFieldsToExclude.toString().replace(/\s/g, "").split(",")
        : undefined,
    OAuthRequestFieldsToExclude:
      oauthRequestFieldsToExclude && oauthRequestFieldsToExclude.length !== 0
        ? oauthRequestFieldsToExclude.toString().replace(/\s/g, "").split(",")
        : undefined,
    OAuthAccessTokenExpirationKeyPath:
      oauthAccessTokenExpirationKeyPath && oauthAccessTokenExpirationKeyPath.length !== 0
        ? oauthAccessTokenExpirationKeyPath.toString().replace(/\s/g, "").split(",")
        : undefined,
    OAuthShouldFetchAccessToken: oauthShouldFetchAccessToken,
    OAuthShouldRefreshAccessToken: oauthShouldRefreshAccessToken,
    OAuthUsePKCE: oauthUsePkce,
  };

  return (
    <Row>
      <Col>
        <InputFormField
          currentValue={oauthAuthorizeURL}
          onChange={(oauthAuthorizeURL) => setOauthAuthorizeURL(oauthAuthorizeURL)}
          placeholder=""
          title={"OAuth Authorize URL"}
          subtitle={
            "URL for Authorization. You can use {PLACEHOLDER_DOMAIN}, {PLACEHOLDER_API_KEY}, and {PLACEHOLDER_PATH}."
          }
        />

        <InputFormField
          currentValue={oauthTokenURL}
          onChange={(oauthTokenURL) => setOAuthTokenURL(oauthTokenURL)}
          placeholder=""
          title={"OAuth Token URL"}
          subtitle={
            "URL for requesting the access token. You can use {PLACEHOLDER_DOMAIN}, {PLACEHOLDER_API_KEY}, and {PLACEHOLDER_PATH}."
          }
        />

        <InputFormField
          currentValue={oauthRefreshURL}
          onChange={(oauthRefreshURL) => setOAuthRefreshURL(oauthRefreshURL)}
          placeholder=""
          title={"OAuth Refresh URL"}
          subtitle={
            "URL for refreshing the access token. You can use {PLACEHOLDER_DOMAIN}, {PLACEHOLDER_API_KEY}, and {PLACEHOLDER_PATH}. If none, OAuth token URL will be reused."
          }
        />

        <DropdownFormField
          currentValue={oauthTokenURLParamsLocation}
          onChange={(e) => {
            setOauthTokenURLParamsLocation(e.target.value);
          }}
          placeholder=""
          title="Token URL Params Location"
          subtitle={
            "Whether client_id, client_secret, and other params in the oauth token request should be provided in the url path as query params or in the request body."
          }
          choices={QUERY_PARAMS_LOCATION_CHOICES}
        />

        <InputFormField
          currentValue={oauthResponseTokenKeyPath}
          onChange={(oauthResponseTokenKeyPath) =>
            setOauthResponseTokenKeyPath(oauthResponseTokenKeyPath)
          }
          placeholder=""
          title={"Response Token Key Path"}
          subtitle={
            "This key creates a path to the token in the response after token exchange. Standard OAuth will just come back as access_token, if this is true for the integration then leave this blank."
          }
        />

        <DropdownFormField
          currentValue={oauthGrantType}
          onChange={(e) => {
            setOauthGrantType(e.target.value);
          }}
          placeholder=""
          title="Grant Type"
          subtitle={"OAuth 2.0 Grant Type."}
          choices={GRANT_TYPE_CHOICES}
        />

        <DropdownFormField
          currentValue={oauthClientCredentialSource}
          onChange={(e) => {
            setOauthClientCredentialSource(e.target.value);
          }}
          placeholder=" "
          title="Client Credential Source"
          subtitle={clientCredentialSourceSubtitle}
          choices={CLIENT_CREDENTIAL_SOURCE_CHOICES}
        />

        <InputFormField
          currentValue={oauthAccessTokenLifespan}
          onChange={(oauthAccessTokenLifespan) =>
            setOauthAccessTokenLifespan(oauthAccessTokenLifespan)
          }
          placeholder=""
          title={"Access Token Lifespan"}
          subtitle={
            "If expires_in is not provided in the token request response but is a constant specified elsewhere, set manually here."
          }
        />

        <InputFormField
          currentValue={oauthRefreshTokenLifespanSeconds}
          onChange={(oauthRefreshTokenLifespanSeconds) =>
            setOauthRefreshTokenLifespanSeconds(oauthRefreshTokenLifespanSeconds)
          }
          placeholder=""
          title={"Refresh Token Lifespan"}
          subtitle={"For integrations where refresh tokens have an expiration time."}
        />

        <InputFormField
          currentValue={oauthAccessTokenExpirationKeyPath}
          onChange={(oauthAccessTokenExpirationKeyPath) =>
            setOauthAccessTokenExpirationKeyPath(oauthAccessTokenExpirationKeyPath)
          }
          placeholder=""
          title={"Access Token Expiration Key Path"}
          subtitle={
            "A comma separated path of keys that defines where the access token expiration is in the response."
          }
        />

        <InputFormField
          currentValue={oauthParamsScopes}
          onChange={(oauthParamsScopes) => setOauthParamsScopes(oauthParamsScopes)}
          placeholder=""
          title={"Param Scopes"}
          subtitle={"If scopes is required in the params, set manually here."}
        />

        <InputFormField
          currentValue={oauthAuthorizeURLAdditionalRedirectURIQueryParamKeys}
          onChange={(oauthAuthorizeURLAdditionalRedirectURIQueryParamKeys) =>
            setOauthAuthorizeURLAdditionalRedirectURIQueryParamKeys(
              oauthAuthorizeURLAdditionalRedirectURIQueryParamKeys
            )
          }
          placeholder=""
          title={"Authorize URL Additional Redirect Param Keys"}
          subtitle={
            "If an integration includes additional query params in the redirect url after oauth authorization, set keys here - all params matching will be added to oauth_authorize_url_additional_redirect_uri_query_params on the associated linked account."
          }
        />

        <InputFormField
          currentValue={oauthAuthorizeURLParamKeystoExclude}
          onChange={(oauthAuthorizeURLParamKeystoExclude) =>
            setOauthAuthorizeURLParamKeystoExclude(oauthAuthorizeURLParamKeystoExclude)
          }
          placeholder=""
          title={"Authorize URL Additional Param Keys to Exclude"}
          subtitle={
            "If an integration needs to exclude any params for oauth authorization (client_id, redirect_uri, response_type, state), set the keys here."
          }
        />

        <InputFormField
          currentValue={oauthTokenURLHeaderFormat}
          onChange={(oauthTokenURLHeaderFormat) =>
            setOauthTokenURLHeaderFormat(oauthTokenURLHeaderFormat)
          }
          placeholder=""
          title={"Token URL Header Format"}
          subtitle={
            "OAuth Token URL header format. Example: Authorization: Basic {BASE-64}{CLIENT-ID}:{CLIENT-SECRET}{BASE-64}."
          }
        />

        <InputFormField
          currentValue={oauthTokenURLHeaderKeysToExclude}
          onChange={(oauthTokenURLHeaderKeysToExclude) =>
            setOauthTokenURLHeaderKeysToExclude(oauthTokenURLHeaderKeysToExclude)
          }
          placeholder=""
          title={"Token URL Header Keys to Exclude"}
          subtitle={
            "If an integration needs to exclude any header params for oauth authorization, but still keep the params for subsequent API requests, set the keys here."
          }
        />

        <InputFormField
          currentValue={oauthRefreshFieldsToExclude}
          onChange={(oauthRefreshFieldsToExclude) =>
            setOauthRefreshFieldsToExclude(oauthRefreshFieldsToExclude)
          }
          placeholder=""
          title={"Refresh Fields to Exclude"}
          subtitle={
            "If fields should be excluded from the body of JUST token refresh requests, enter the keys here."
          }
        />

        <InputFormField
          currentValue={oauthRequestFieldsToExclude}
          onChange={(oauthRequestFieldsToExclude) =>
            setOauthRequestFieldsToExclude(oauthRequestFieldsToExclude)
          }
          placeholder=""
          title={"Request Keys to Exclude"}
          subtitle={
            "If fields should be excluded from the body of the initial token fetch or token refresh requests (excluded from BOTH requests), enter the keys here."
          }
        />

        <Form.Group controlId="should_refresh">
          <Form.Check
            type="checkbox"
            label="Select if the access token needs to be refreshed after initial linking."
            onChange={() => setOauthShouldRefreshAccessToken(!oauthShouldRefreshAccessToken)}
            checked={oauthShouldRefreshAccessToken}
          />
        </Form.Group>

        <Form.Group controlId="should_fetch">
          <Form.Check
            type="checkbox"
            label="Select if the access token needs to be automatically fetched."
            onChange={() => setOauthShouldFetchAccessToken(!oauthShouldFetchAccessToken)}
            checked={oauthShouldFetchAccessToken}
          />
        </Form.Group>

        <Form.Group controlId="use_pkce">
          <Form.Check
            type="checkbox"
            label="Select if the oauth flow needs to follow the PKCE standard to include a code verifier/code challenge."
            onChange={() => setOauthUsePkce(!oauthUsePkce)}
            checked={oauthUsePkce}
          />
        </Form.Group>

        <SaveAuthConfigButton
          text={
            !props.isEditingExistingConfig
              ? "Save configuration"
              : "Save and continue editing auth configuration"
          }
          onSuccess={() => {
            showSuccessToast(
              props.authConfig
                ? "Successfully updated configuration!"
                : "Successfully created configuration!"
            );
            setIsLoading(false);
            if (!props.isEditingExistingConfig) {
              navigateToAuthConfigsTable(history, props.integrationID);
            }
          }}
          setLoading={setIsLoading}
          isLoading={isLoading}
          authConfigUpdateProps={authConfigUpdates}
        />
      </Col>
      <AuthConfigEditFormRightPanel authConfigUpdateProps={authConfigUpdates} />
    </Row>
  );
};

export default AuthConfigOAuthForm;
