import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  APIEndpointPaginationConfigurationTypes,
  APIEndpointResponseTypes,
  APIEndpointTypes,
  APIProtocols,
  APIRequestContentTypes,
  APIRequestTypes,
} from "../../../../models/APIEndpointModels";
import { HTTPMethod } from "../../../../models/HTTPMethods";
import useIntegrationBuilderContext from "../../context/useIntegrationBuilderContext";
import PaginationConfigurationContext from "../../pagination-configuration-setup/context/PaginationConfigurationContext";
import useLoadPaginationConfigurations from "../../pagination-configuration-setup/hooks/useLoadPaginationConfigurations";
import { Row } from "../../shared/postman-table/PostmanTableRow";
import {
  APIEndpointBodyParsingConfiguration,
  APIEndpointIntegrationBuilder,
  PaginationConfigurationIntegrationBuilder,
  RateLimitConfigurationIntegrationBuilder,
} from "../../utils/Entities";
import {
  parseBodyParsingConfigurationsToParsingRules,
  parsePropertiesDictToPostmanTableRows,
} from "../../utils/helpers";
import APIEndpointContext from "./APIEndpointContext";
import { transformRateLimitConfigurationDataForDiffModal } from "../../rate-limits/utils/helpers";
import { API_ENDPOINT_TYPE_SELECT_OPTIONS } from "../constants";
import { isValidJSON } from "../../../../utils";

interface Props {
  children: JSX.Element;
  selectedAPIEndpoint: APIEndpointIntegrationBuilder | undefined;
  integrationID: string;
}

const APIEndpointContextProvider = ({ children, selectedAPIEndpoint, integrationID }: Props) => {
  const {
    setCurrentStateForDiff,
    setNewStateForDiff,
    modelTypeForDiff,
  } = useIntegrationBuilderContext();
  const [paginationConfigurations, setPaginationConfigurations] = useState<
    PaginationConfigurationIntegrationBuilder[] | undefined
  >([]);
  useLoadPaginationConfigurations({
    integrationID,
    setPaginationConfigurations,
  });

  const { canSubmitPaginationConfiguration } = useContext(PaginationConfigurationContext);

  // Initialize functions
  const initialName = selectedAPIEndpoint?.name ?? "";
  const initialProtocol = selectedAPIEndpoint?.api_protocol ?? APIProtocols.REST;
  const initialEndpointType = selectedAPIEndpoint?.endpoint_type ?? APIEndpointTypes.SINGLE_REQUEST;
  const initialMethod = selectedAPIEndpoint?.request_configuration?.method ?? HTTPMethod.GET;
  const initialPath = selectedAPIEndpoint?.request_configuration?.path ?? "";
  const initialBaseURLOverride =
    selectedAPIEndpoint?.request_configuration?.base_url_override ?? "";
  const initialPathParamSchema =
    selectedAPIEndpoint?.request_configuration?.path_param_schema ?? {};
  const initialQueryParamSchema =
    selectedAPIEndpoint?.request_configuration?.query_param_schema ?? {};
  const initialQueryParamTableRows = parsePropertiesDictToPostmanTableRows(
    selectedAPIEndpoint?.request_configuration?.query_param_schema ?? {}
  );
  const initialHeaderSchema = selectedAPIEndpoint?.request_configuration?.header_schema ?? {};
  const initialHeaderSchemaTableRows = parsePropertiesDictToPostmanTableRows(
    selectedAPIEndpoint?.request_configuration?.header_schema ?? {}
  );
  const timestampConfig =
    selectedAPIEndpoint?.request_configuration?.timestamp_filter_configuration;
  const initialDoesAllowTimestampFiltering = !!timestampConfig ?? false;
  const initialTimestampFilterParamKey = timestampConfig?.param_key ?? "";
  const initialTimestampFilterParamLocation = timestampConfig?.param_location ?? null;
  const initialTimestampFilterFormatOverride = timestampConfig?.format_override ?? "";
  const initialTimestampFilterParamPrefix = timestampConfig?.param_prefix ?? "";
  const initialTimestampFilterParamSuffix = timestampConfig?.param_suffix ?? "";
  const initialTimestampFilterBufferHours = timestampConfig?.buffer_hours ?? null;
  const initialTimestampFilterDefaultOverride = timestampConfig?.default_override ?? "";
  const initialContentType =
    selectedAPIEndpoint?.request_configuration?.rest_request_configuration?.content_type ??
    APIRequestContentTypes.JSON;
  const initialBodySchema =
    selectedAPIEndpoint?.request_configuration?.rest_request_configuration?.body_schema ?? {};
  const initialSoapRequestBodyFormat =
    selectedAPIEndpoint?.request_configuration?.soap_request_configuration?.request_body_format ??
    "";
  const initialSoapRequestBodyParamSchema =
    selectedAPIEndpoint?.request_configuration?.soap_request_configuration
      ?.request_body_param_schema ?? {};
  const initialSoapDoesRequestUseBodyTemplatingEngine =
    selectedAPIEndpoint?.request_configuration?.soap_request_configuration
      ?.does_request_use_body_templating_engine ?? false;
  const initialSoapShouldXmlCharactersBeEscaped =
    selectedAPIEndpoint?.request_configuration?.soap_request_configuration
      ?.should_xml_characters_be_escaped ??
    (selectedAPIEndpoint?.request_configuration?.request_type == APIRequestTypes.SOAP &&
      selectedAPIEndpoint?.request_configuration?.method == HTTPMethod.POST);
  const initialGraphqlRequestBodyBaseConfig =
    selectedAPIEndpoint?.request_configuration?.graphql_request_configuration
      ?.request_body_base_config ?? "";
  const initialGraphqlRequestBodyParamSchema =
    selectedAPIEndpoint?.request_configuration?.graphql_request_configuration
      ?.request_body_param_schema ?? {};
  const initialFileSchema = selectedAPIEndpoint?.request_configuration?.file_schema ?? {};
  const initialUrlEncodedQueryParamsFormat =
    selectedAPIEndpoint?.request_configuration?.url_encoded_query_params_format ?? {};
  const initialUrlEncodedQueryParamSchema =
    selectedAPIEndpoint?.request_configuration?.url_encoded_query_param_schema ?? {};
  const initialRequestType =
    selectedAPIEndpoint?.request_configuration?.request_type ?? APIRequestTypes.REST;
  const initialShouldFilterEmptyValuesFromBodyData =
    selectedAPIEndpoint?.request_configuration?.should_filter_empty_values_from_body_data ?? true;
  const initialShouldAcceptMergeWithJsonRequestBody =
    selectedAPIEndpoint?.request_configuration?.should_accept_merge_with_json_request_body ?? false;
  const initialShouldAcceptRawJsonRequestBody =
    selectedAPIEndpoint?.request_configuration?.should_accept_raw_json_request_body ?? false;
  const initialShouldOverrideIntegrationConvertStringsToNumbers =
    selectedAPIEndpoint?.request_configuration
      ?.should_override_integration_convert_strings_to_numbers ?? false;
  const initialShouldOverrideIntegrationConvertBodyDataToString =
    selectedAPIEndpoint?.request_configuration
      ?.should_override_integration_convert_body_data_to_string ?? false;
  const initialResponseType =
    selectedAPIEndpoint?.response_configuration?.response_type ?? APIEndpointResponseTypes.JSON;
  const initialResponseSchema = JSON.stringify(
    selectedAPIEndpoint?.response_configuration?.response_schema ?? {}
  );
  const initialAllowedErrorCodes =
    selectedAPIEndpoint?.response_configuration?.allowed_error_codes ?? [];
  const initialAdditionalResponseBodyParsing =
    selectedAPIEndpoint?.response_configuration?.additional_response_body_parsing ?? [];
  const initialResponseBodyParsingRules = parseBodyParsingConfigurationsToParsingRules(
    selectedAPIEndpoint?.response_configuration?.additional_response_body_parsing ?? []
  );
  const initialSyncCursorKeyPath =
    selectedAPIEndpoint?.response_configuration?.sync_cursor_key_path ?? null;
  const initialPaginationConfigID =
    selectedAPIEndpoint?.pagination_configuration_information?.pagination_configuration_id ?? null;
  const initialPaginationResponseBodyArrayKeyPathOverride =
    selectedAPIEndpoint?.pagination_configuration_information
      ?.pagination_response_body_array_key_path_override ?? null;
  const defaultPaginationConfiguration = useMemo(
    () => paginationConfigurations?.find((config) => config.is_default_for_integration) ?? null,
    [paginationConfigurations]
  );
  const selectedPaginationConfiguration = useMemo(
    () =>
      paginationConfigurations?.find((config) => config.id === initialPaginationConfigID) ?? null,
    [paginationConfigurations, initialPaginationConfigID]
  );
  const initialPaginationConfigType =
    initialPaginationConfigID === null ||
    initialPaginationConfigID === defaultPaginationConfiguration?.id
      ? APIEndpointPaginationConfigurationTypes.DEFAULT
      : APIEndpointPaginationConfigurationTypes.SELECTED;
  const initialIsEndpointModificationDisabled =
    selectedAPIEndpoint?.is_endpoint_modification_disabled ?? false;
  const initialRateLimitConfigurations = selectedAPIEndpoint?.rate_limit_configurations ?? [];

  // MAIN API ENDPOINT RELATED STATES
  // Basic API Endpoint related states
  const [name, setName] = useState(initialName);
  const [protocol, setProtocol] = useState(initialProtocol);
  const [endpointType, setEndpointType] = useState(initialEndpointType);

  // Request Configuration related states
  const [method, setMethod] = useState(initialMethod);
  const [path, setPath] = useState(initialPath);
  const [baseURLOverride, setBaseURLOverride] = useState(initialBaseURLOverride);
  const [pathParamSchema, setPathParamSchema] = useState(initialPathParamSchema);
  const [queryParamSchema, setQueryParamSchema] = useState(initialQueryParamSchema);
  const [queryParamTableRows, setQueryParamTableRows] = useState<Row[]>(initialQueryParamTableRows);
  const [headerSchema, setHeaderSchema] = useState(initialHeaderSchema);
  const [headerSchemaTableRows, setHeaderSchemaTableRows] = useState<Row[]>(
    initialHeaderSchemaTableRows
  );
  // ---- Timestamp Filter Configuration related states
  const [doesAllowTimestampFiltering, setDoesAllowTimestampFiltering] = useState(
    initialDoesAllowTimestampFiltering
  );
  const [timestampFilterParamKey, setTimestampFilterParamKey] = useState(
    initialTimestampFilterParamKey
  );
  const [timestampFilterParamLocation, setTimestampFilterParamLocation] = useState(
    initialTimestampFilterParamLocation
  );
  const [timestampFilterFormatOverride, setTimestampFilterFormatOverride] = useState(
    initialTimestampFilterFormatOverride
  );
  const [timestampFilterParamPrefix, setTimestampFilterParamPrefix] = useState(
    initialTimestampFilterParamPrefix
  );
  const [timestampFilterParamSuffix, setTimestampFilterParamSuffix] = useState(
    initialTimestampFilterParamSuffix
  );
  const [timestampFilterBufferHours, setTimestampFilterBufferHours] = useState(
    initialTimestampFilterBufferHours
  );
  const [timestampFilterDefaultOverride, setTimestampFilterDefaultOverride] = useState(
    initialTimestampFilterDefaultOverride
  );
  // ---- REST Request Configuration related states
  const [contentType, setContentType] = useState(initialContentType);
  const [bodySchema, setBodySchema] = useState(initialBodySchema);
  // ---- SOAP Request Configuration related states
  const [soapRequestBodyFormat, setSoapRequestBodyFormat] = useState(initialSoapRequestBodyFormat);
  const [soapRequestBodyParamSchema, setSoapRequestBodyParamSchema] = useState(
    initialSoapRequestBodyParamSchema
  );
  const [
    soapDoesRequestUseBodyTemplatingEngine,
    setSoapDoesRequestUseBodyTemplatingEngine,
  ] = useState(initialSoapDoesRequestUseBodyTemplatingEngine);
  const [soapShouldXmlRequestEscapeCharacters, setSoapShouldXmlRequestEscapeCharacters] = useState(
    initialSoapShouldXmlCharactersBeEscaped
  );
  // ---- GraphQL Request Configuration related states
  const [graphqlRequestBodyBaseConfig, setGraphqlRequestBodyBaseConfig] = useState(
    initialGraphqlRequestBodyBaseConfig
  );
  const [graphqlRequestBodyParamSchema, setGraphqlRequestBodyParamSchema] = useState(
    initialGraphqlRequestBodyParamSchema
  );
  // ---- Advanced Request Configuration related states
  const [fileSchema, setFileSchema] = useState(initialFileSchema);
  const [urlEncodedQueryParamsFormat, setUrlEncodedQueryParamsFormat] = useState(
    initialUrlEncodedQueryParamsFormat
  );
  const [urlEncodedQueryParamSchema, setUrlEncodedQueryParamSchema] = useState(
    initialUrlEncodedQueryParamSchema
  );
  const [syncCursorKeyPath, setSyncCursorKeyPath] = useState(initialSyncCursorKeyPath);
  const [requestType, setRequestType] = useState(initialRequestType);
  const [shouldFilterEmptyValuesFromBodyData, setShouldFilterEmptyValuesFromBodyData] = useState<
    boolean
  >(initialShouldFilterEmptyValuesFromBodyData);
  const [shouldAcceptMergeWithJsonRequestBody, setShouldAcceptMergeWithJsonRequestBody] = useState<
    boolean
  >(initialShouldAcceptMergeWithJsonRequestBody);
  const [shouldAcceptRawJsonRequestBody, setShouldAcceptRawJsonRequestBody] = useState<boolean>(
    initialShouldAcceptRawJsonRequestBody
  );
  const [
    shouldOverrideIntegrationConvertStringsToNumbers,
    setShouldOverrideIntegrationConvertStringsToNumbers,
  ] = useState<boolean>(initialShouldOverrideIntegrationConvertStringsToNumbers);
  const [
    shouldOverrideIntegrationConvertBodyDataToString,
    setShouldOverrideIntegrationConvertBodyDataToString,
  ] = useState<boolean>(initialShouldOverrideIntegrationConvertBodyDataToString);

  // Response Configuration related states
  const [responseType, setResponseType] = useState(initialResponseType);
  const [responseSchema, setResponseSchema] = useState(initialResponseSchema);
  // ---- Advanced response configuration related states
  const [allowedErrorCodes, setAllowedErrorCodes] = useState<string[] | null>(
    initialAllowedErrorCodes
  );
  const [additionalResponseBodyParsing, setAdditionalResponseBodyParsing] = useState<
    APIEndpointBodyParsingConfiguration[] | null
  >(initialAdditionalResponseBodyParsing);
  const [responseBodyParsingRules, setResponseBodyParsingRules] = useState(
    initialResponseBodyParsingRules
  );

  // Pagination configuration related states
  const [paginationConfigID, setPaginationConfigID] = useState(initialPaginationConfigID);

  const [
    paginationResponseBodyArrayKeyPathOverride,
    setPaginationResponseBodyArrayKeyPathOverride,
  ] = useState(initialPaginationResponseBodyArrayKeyPathOverride);
  const [paginationConfigType, setPaginationConfigType] = useState<
    APIEndpointPaginationConfigurationTypes
  >(initialPaginationConfigType);

  // Rate limit configuration states
  const [rateLimitConfigurations, setRateLimitConfigurations] = useState<
    RateLimitConfigurationIntegrationBuilder[] | undefined
  >(initialRateLimitConfigurations);
  const [rateLimitConfigurationsToDelete, setRateLimitConfigurationsToDelete] = useState<
    RateLimitConfigurationIntegrationBuilder[]
  >([]);
  const [canSubmitRateLimitConfigurations, setCanSubmitRateLimitConfigurations] = useState<boolean>(
    true
  );

  // Advanced Configuration related states
  const [isEndpointModificationDisabled, setIsEndpointModificationDisabled] = useState(
    initialIsEndpointModificationDisabled
  );

  const canSubmitAPIEndpoint = useMemo(() => {
    const isValidString = (str: string | null) => str && str.trim().length > 0;

    // basics should be covered - valid name, protocol, endpoint type, and method
    if (
      !isValidString(name) ||
      !isValidString(protocol) ||
      !isValidString(method) ||
      !isValidString(endpointType)
    ) {
      return false;
    }

    // If allows filtering by timestamp, make sure key and location are set
    if (doesAllowTimestampFiltering) {
      if (!isValidString(timestampFilterParamKey) || !isValidString(timestampFilterParamLocation)) {
        return false;
      }
    }

    // If timstamp buffer hours is set, make sure it's a number between 1 and 24
    if (
      timestampFilterBufferHours !== null &&
      (timestampFilterBufferHours < 1 || timestampFilterBufferHours > 24)
    ) {
      return false;
    }

    // If building new pagination configuration, check if it can be submitted
    if (
      paginationConfigType === APIEndpointPaginationConfigurationTypes.NEW &&
      !canSubmitPaginationConfiguration
    ) {
      return false;
    }
    if (!canSubmitRateLimitConfigurations) {
      return false;
    }
    return true;
  }, [
    name,
    protocol,
    method,
    endpointType,
    doesAllowTimestampFiltering,
    timestampFilterParamKey,
    timestampFilterParamLocation,
    paginationConfigType,
    timestampFilterBufferHours,
    canSubmitPaginationConfiguration,
    canSubmitRateLimitConfigurations,
  ]);

  // Set states based on selected API endpoint
  // This is required for page reloads
  useEffect(() => {
    setName(initialName);
    setProtocol(initialProtocol);
    setEndpointType(initialEndpointType);
    setMethod(initialMethod);
    setPath(initialPath);
    setBaseURLOverride(initialBaseURLOverride);
    setPathParamSchema(initialPathParamSchema);
    setQueryParamSchema(initialQueryParamSchema);
    setQueryParamTableRows(initialQueryParamTableRows);
    setHeaderSchema(initialHeaderSchema);
    setHeaderSchemaTableRows(initialHeaderSchemaTableRows);
    setDoesAllowTimestampFiltering(initialDoesAllowTimestampFiltering);
    setTimestampFilterParamKey(initialTimestampFilterParamKey);
    setTimestampFilterParamLocation(initialTimestampFilterParamLocation);
    setTimestampFilterFormatOverride(initialTimestampFilterFormatOverride);
    setTimestampFilterParamPrefix(initialTimestampFilterParamPrefix);
    setTimestampFilterParamSuffix(initialTimestampFilterParamSuffix);
    setTimestampFilterBufferHours(initialTimestampFilterBufferHours);
    setTimestampFilterDefaultOverride(initialTimestampFilterDefaultOverride);
    setContentType(initialContentType);
    setBodySchema(initialBodySchema);
    setSoapRequestBodyFormat(initialSoapRequestBodyFormat);
    setSoapRequestBodyParamSchema(initialSoapRequestBodyParamSchema);
    setSoapDoesRequestUseBodyTemplatingEngine(initialSoapDoesRequestUseBodyTemplatingEngine);
    setSoapShouldXmlRequestEscapeCharacters(initialSoapShouldXmlCharactersBeEscaped);
    setGraphqlRequestBodyBaseConfig(initialGraphqlRequestBodyBaseConfig);
    setGraphqlRequestBodyParamSchema(initialGraphqlRequestBodyParamSchema);
    setRequestType(initialRequestType);
    setFileSchema(initialFileSchema);
    setUrlEncodedQueryParamsFormat(initialUrlEncodedQueryParamsFormat);
    setUrlEncodedQueryParamSchema(initialUrlEncodedQueryParamSchema);
    setSyncCursorKeyPath(initialSyncCursorKeyPath);
    setShouldFilterEmptyValuesFromBodyData(initialShouldFilterEmptyValuesFromBodyData);
    setShouldAcceptMergeWithJsonRequestBody(initialShouldAcceptMergeWithJsonRequestBody);
    setShouldAcceptRawJsonRequestBody(initialShouldAcceptRawJsonRequestBody);
    setShouldOverrideIntegrationConvertStringsToNumbers(
      initialShouldOverrideIntegrationConvertStringsToNumbers
    );
    setShouldOverrideIntegrationConvertBodyDataToString(
      initialShouldOverrideIntegrationConvertBodyDataToString
    );
    setResponseType(initialResponseType);
    setResponseSchema(initialResponseSchema);
    setAllowedErrorCodes(initialAllowedErrorCodes);
    setAdditionalResponseBodyParsing(initialAdditionalResponseBodyParsing);
    setResponseBodyParsingRules(initialResponseBodyParsingRules);

    // Rate limit configuration states
    setRateLimitConfigurations(initialRateLimitConfigurations);

    // pagination configuration related states=
    setPaginationConfigID(initialPaginationConfigID);
    setPaginationResponseBodyArrayKeyPathOverride(
      initialPaginationResponseBodyArrayKeyPathOverride
    );
    setPaginationConfigType(initialPaginationConfigType);
    setIsEndpointModificationDisabled(initialIsEndpointModificationDisabled);
  }, [selectedAPIEndpoint, defaultPaginationConfiguration, selectedPaginationConfiguration]);

  /* This useEffect initializes the model state to be used in the diff modal.
   * When adding/removing fields, you must update helpers-api-endpoint-diff.ts
   * to ensure that these state changes get effectively captured by diff modal.
   */
  useEffect(() => {
    const initialAPIEndpointDiffData: APIEndpointIntegrationBuilder = {
      id: selectedAPIEndpoint?.id,
      integration_id: integrationID,
      name: initialName,
      api_protocol: initialProtocol,
      endpoint_type:
        API_ENDPOINT_TYPE_SELECT_OPTIONS.find((option) => option.value === initialEndpointType)
          ?.title ?? "",
      request_configuration: {
        method: initialMethod,
        path: initialPath,
        base_url_override: initialBaseURLOverride,
        path_param_schema: initialPathParamSchema,
        query_param_schema: initialQueryParamSchema,
        header_schema: initialHeaderSchema,
        timestamp_filter_configuration: initialDoesAllowTimestampFiltering
          ? {
              param_key: initialTimestampFilterParamKey,
              param_location: initialTimestampFilterParamLocation || null,
              format_override: initialTimestampFilterFormatOverride || null,
              param_prefix: initialTimestampFilterParamPrefix || null,
              param_suffix: initialTimestampFilterParamSuffix || null,
              buffer_hours: initialTimestampFilterBufferHours ?? null,
              default_override: initialTimestampFilterDefaultOverride || null,
            }
          : null,
        rest_request_configuration:
          initialProtocol === APIProtocols.REST
            ? { content_type: initialContentType, body_schema: initialBodySchema }
            : null,
        soap_request_configuration:
          initialProtocol === APIProtocols.SOAP
            ? {
                request_body_format: initialSoapRequestBodyFormat,
                request_body_param_schema: initialSoapRequestBodyParamSchema,
                does_request_use_body_templating_engine: initialSoapDoesRequestUseBodyTemplatingEngine,
                should_xml_characters_be_escaped: initialSoapShouldXmlCharactersBeEscaped,
              }
            : null,
        graphql_request_configuration:
          initialProtocol === APIProtocols.GRAPHQL
            ? {
                request_body_base_config: initialGraphqlRequestBodyBaseConfig,
                request_body_param_schema: initialGraphqlRequestBodyParamSchema,
              }
            : null,
        file_schema: initialFileSchema,
        url_encoded_query_params_format: initialUrlEncodedQueryParamsFormat,
        url_encoded_query_param_schema: initialUrlEncodedQueryParamSchema,
        request_type: initialRequestType,
        should_filter_empty_values_from_body_data: initialShouldFilterEmptyValuesFromBodyData,
        should_accept_merge_with_json_request_body: initialShouldAcceptMergeWithJsonRequestBody,
        should_accept_raw_json_request_body: initialShouldAcceptRawJsonRequestBody,
        should_override_integration_convert_strings_to_numbers: initialShouldOverrideIntegrationConvertStringsToNumbers,
        should_override_integration_convert_body_data_to_string: initialShouldOverrideIntegrationConvertBodyDataToString,
      },
      response_configuration: {
        response_type: initialResponseType,
        response_schema: isValidJSON(initialResponseSchema)
          ? JSON.parse(initialResponseSchema)
          : initialResponseSchema,
        allowed_error_codes: initialAllowedErrorCodes,
        additional_response_body_parsing: initialAdditionalResponseBodyParsing,
        sync_cursor_key_path: initialSyncCursorKeyPath,
      },
      pagination_configuration_information: {
        pagination_configuration_id: selectedPaginationConfiguration?.name ?? "Use default",
        pagination_response_body_array_key_path_override: initialPaginationResponseBodyArrayKeyPathOverride,
      },
      is_endpoint_modification_disabled: initialIsEndpointModificationDisabled,
    };

    const initialAPIEndpointDiffDataWithHelperFields = {
      ...initialAPIEndpointDiffData,
      request_configuration: {
        ...initialAPIEndpointDiffData.request_configuration,
        timestamp_filter_configuration: {
          ...initialAPIEndpointDiffData.request_configuration.timestamp_filter_configuration,
          does_allow_timestamp_filtering: initialDoesAllowTimestampFiltering,
        },
      },
    };

    const initialRateLimitConfigurationsData = (initialRateLimitConfigurations ?? []).map(
      (config) => {
        const transformedConfig = transformRateLimitConfigurationDataForDiffModal(config);
        return {
          ...transformedConfig,
          rate_limit_identifier: config.id,
        };
      }
    );

    setCurrentStateForDiff({
      api_endpoint: initialAPIEndpointDiffDataWithHelperFields,
      rate_limit_configurations: initialRateLimitConfigurationsData,
    });
    setNewStateForDiff({
      api_endpoint: initialAPIEndpointDiffDataWithHelperFields,
      rate_limit_configurations: initialRateLimitConfigurationsData,
    });
  }, [
    modelTypeForDiff,
    selectedAPIEndpoint,
    defaultPaginationConfiguration,
    selectedPaginationConfiguration,
  ]);

  useEffect(() => {
    switch (protocol) {
      case APIProtocols.REST:
        setRequestType(APIRequestTypes.REST);
        break;
      case APIProtocols.SOAP:
        setRequestType(APIRequestTypes.SOAP);
        break;
      case APIProtocols.GRAPHQL:
        setRequestType(APIRequestTypes.GRAPHQL);
        break;
      default:
        break;
    }
  }, [protocol]);

  const formAPIEndpointData = useCallback(() => {
    const data: APIEndpointIntegrationBuilder = {
      id: selectedAPIEndpoint?.id,
      integration_id: integrationID,
      name,
      api_protocol: protocol,
      endpoint_type: endpointType !== APIEndpointTypes.SINGLE_REQUEST ? endpointType : null, // if single request, don't send endpoint type
      request_configuration: {
        method,
        path,
        base_url_override: baseURLOverride,
        path_param_schema: pathParamSchema,
        query_param_schema: queryParamSchema,
        header_schema: headerSchema,
        timestamp_filter_configuration: doesAllowTimestampFiltering
          ? {
              param_key: timestampFilterParamKey,
              param_location: timestampFilterParamLocation || null,
              format_override: timestampFilterFormatOverride || null,
              param_prefix: timestampFilterParamPrefix || null,
              param_suffix: timestampFilterParamSuffix || null,
              buffer_hours: timestampFilterBufferHours ?? null,
              default_override: timestampFilterDefaultOverride || null,
            }
          : null,
        rest_request_configuration:
          protocol === APIProtocols.REST
            ? { content_type: contentType, body_schema: bodySchema }
            : null,
        soap_request_configuration:
          protocol === APIProtocols.SOAP
            ? {
                request_body_format: soapRequestBodyFormat,
                request_body_param_schema: soapRequestBodyParamSchema,
                does_request_use_body_templating_engine: soapDoesRequestUseBodyTemplatingEngine,
                should_xml_characters_be_escaped: soapShouldXmlRequestEscapeCharacters,
              }
            : null,
        graphql_request_configuration:
          protocol === APIProtocols.GRAPHQL
            ? {
                request_body_base_config: graphqlRequestBodyBaseConfig,
                request_body_param_schema: graphqlRequestBodyParamSchema,
              }
            : null,
        file_schema: fileSchema,
        url_encoded_query_params_format: urlEncodedQueryParamsFormat,
        url_encoded_query_param_schema: urlEncodedQueryParamSchema,
        request_type: requestType,
        should_filter_empty_values_from_body_data: shouldFilterEmptyValuesFromBodyData,
        should_accept_merge_with_json_request_body: shouldAcceptMergeWithJsonRequestBody,
        should_accept_raw_json_request_body: shouldAcceptRawJsonRequestBody,
        should_override_integration_convert_strings_to_numbers: shouldOverrideIntegrationConvertStringsToNumbers,
        should_override_integration_convert_body_data_to_string: shouldOverrideIntegrationConvertBodyDataToString,
      },
      response_configuration: {
        response_type: responseType,
        response_schema: isValidJSON(responseSchema) ? JSON.parse(responseSchema) : responseSchema,
        allowed_error_codes: allowedErrorCodes,
        additional_response_body_parsing: additionalResponseBodyParsing,
        sync_cursor_key_path: syncCursorKeyPath,
      },
      pagination_configuration_information: {
        pagination_configuration_id: paginationConfigID,
        pagination_response_body_array_key_path_override: paginationResponseBodyArrayKeyPathOverride,
      },
      is_endpoint_modification_disabled: isEndpointModificationDisabled,
    };
    return data;
  }, [
    name,
    protocol,
    endpointType,
    method,
    path,
    baseURLOverride,
    pathParamSchema,
    queryParamSchema,
    headerSchema,
    doesAllowTimestampFiltering,
    timestampFilterParamKey,
    timestampFilterParamLocation,
    timestampFilterFormatOverride,
    timestampFilterParamPrefix,
    timestampFilterParamSuffix,
    timestampFilterBufferHours,
    timestampFilterDefaultOverride,
    contentType,
    bodySchema,
    soapRequestBodyFormat,
    soapRequestBodyParamSchema,
    soapDoesRequestUseBodyTemplatingEngine,
    soapShouldXmlRequestEscapeCharacters,
    graphqlRequestBodyBaseConfig,
    graphqlRequestBodyParamSchema,
    fileSchema,
    urlEncodedQueryParamsFormat,
    urlEncodedQueryParamSchema,
    syncCursorKeyPath,
    requestType,
    shouldFilterEmptyValuesFromBodyData,
    shouldAcceptMergeWithJsonRequestBody,
    shouldAcceptRawJsonRequestBody,
    shouldOverrideIntegrationConvertStringsToNumbers,
    shouldOverrideIntegrationConvertBodyDataToString,
    responseType,
    responseSchema,
    allowedErrorCodes,
    additionalResponseBodyParsing,
    responseBodyParsingRules,
    paginationConfigID,
    paginationResponseBodyArrayKeyPathOverride,
    isEndpointModificationDisabled,
  ]);

  return (
    <APIEndpointContext.Provider
      value={{
        integrationID,
        name,
        setName,
        protocol,
        setProtocol,
        endpointType,
        setEndpointType,
        method,
        setMethod,
        path,
        setPath,
        baseURLOverride,
        setBaseURLOverride,
        pathParamSchema,
        setPathParamSchema,
        queryParamSchema,
        setQueryParamSchema,
        queryParamTableRows,
        setQueryParamTableRows,
        doesAllowTimestampFiltering,
        setDoesAllowTimestampFiltering,
        timestampFilterParamKey,
        setTimestampFilterParamKey,
        timestampFilterParamLocation,
        setTimestampFilterParamLocation,
        timestampFilterFormatOverride,
        setTimestampFilterFormatOverride,
        timestampFilterParamPrefix,
        setTimestampFilterParamPrefix,
        timestampFilterParamSuffix,
        setTimestampFilterParamSuffix,
        timestampFilterBufferHours,
        setTimestampFilterBufferHours,
        timestampFilterDefaultOverride,
        setTimestampFilterDefaultOverride,
        headerSchema,
        setHeaderSchema,
        headerSchemaTableRows,
        setHeaderSchemaTableRows,
        contentType,
        setContentType,
        bodySchema,
        setBodySchema,
        soapRequestBodyFormat,
        setSoapRequestBodyFormat,
        soapRequestBodyParamSchema,
        setSoapRequestBodyParamSchema,
        soapDoesRequestUseBodyTemplatingEngine,
        setSoapDoesRequestUseBodyTemplatingEngine,
        soapShouldXmlRequestEscapeCharacters,
        setSoapShouldXmlRequestEscapeCharacters,
        graphqlRequestBodyBaseConfig,
        setGraphqlRequestBodyBaseConfig,
        graphqlRequestBodyParamSchema,
        setGraphqlRequestBodyParamSchema,
        requestType,
        setRequestType,
        fileSchema,
        setFileSchema,
        urlEncodedQueryParamsFormat,
        setUrlEncodedQueryParamsFormat,
        urlEncodedQueryParamSchema,
        setUrlEncodedQueryParamSchema,
        syncCursorKeyPath,
        setSyncCursorKeyPath,
        shouldFilterEmptyValuesFromBodyData,
        setShouldFilterEmptyValuesFromBodyData,
        shouldAcceptMergeWithJsonRequestBody,
        setShouldAcceptMergeWithJsonRequestBody,
        shouldAcceptRawJsonRequestBody,
        setShouldAcceptRawJsonRequestBody,
        shouldOverrideIntegrationConvertStringsToNumbers,
        setShouldOverrideIntegrationConvertStringsToNumbers,
        shouldOverrideIntegrationConvertBodyDataToString,
        setShouldOverrideIntegrationConvertBodyDataToString,
        responseType,
        setResponseType,
        responseSchema,
        setResponseSchema,
        allowedErrorCodes,
        setAllowedErrorCodes,
        additionalResponseBodyParsing,
        setAdditionalResponseBodyParsing,
        responseBodyParsingRules,
        setResponseBodyParsingRules,
        isEndpointModificationDisabled,
        setIsEndpointModificationDisabled,
        paginationConfigID,
        setPaginationConfigID,
        paginationResponseBodyArrayKeyPathOverride,
        setPaginationResponseBodyArrayKeyPathOverride,
        formAPIEndpointData,
        paginationConfigType,
        setPaginationConfigType,
        paginationConfigurations,
        setPaginationConfigurations,
        rateLimitConfigurations,
        setRateLimitConfigurations,
        rateLimitConfigurationsToDelete,
        setRateLimitConfigurationsToDelete,
        canSubmitRateLimitConfigurations,
        setCanSubmitRateLimitConfigurations,
        defaultPaginationConfiguration,
        canSubmitAPIEndpoint,
      }}
    >
      {children}
    </APIEndpointContext.Provider>
  );
};

export default APIEndpointContextProvider;
