import { ChevronRight } from "lucide-react";
import { AuthConfigIntegrationBuilder } from "../../../utils/Entities";

interface AuthTypeCellProps {
  authConfig: AuthConfigIntegrationBuilder;
}

const AuthTypeCell = ({ authConfig }: AuthTypeCellProps) => {
  return (
    <td>
      <div className="flex items-center justify-between text-gray-90 text-base">
        {authConfig.auth_type}
        <ChevronRight size={12} className="text-black" />
      </div>
    </td>
  );
};
export default AuthTypeCell;
