import { BlueprintParameterSchemaValue } from "../../../models/Blueprints";
import { MappingTestBodyParameters } from "../../../models/MappingTests";
import MappingTestEditorCommonModelExpectedMappingField from "../mapping-test-block-assertions/MappingTestEditorCommonModelExpectedMappingField";
import MappingTestEditorCommonModelExpectedMappingSectionHeader from "../mapping-test-block-assertions/MappingTestEditorCommonModelExpectedMappingSectionHeader";
import {
  getEmptyValueForField,
  getBodyparameterValueByPath,
} from "../utils/MappingTestBuildingUtils";

const BodyParameterField = (props: {
  bodyParameters: MappingTestBodyParameters;
  availableRelationLookupDict: { [commonModelID: string]: Array<string> };
  fieldSchema: BlueprintParameterSchemaValue;
  fieldKey: string;
  fieldKeyPath: Array<string>;
  isRequired: boolean;
  index: number;
  root?: string;
  shouldManualInputSubObject?: boolean;
  onUpdate: (args: { fieldKeyPath: Array<string>; newFieldValue: any }) => void;
  isNestedObject?: boolean;
  isNestedWriteField?: boolean;
  nestedWritesMapForMappingTestModelArray?: string[];
  commonModelName?: string;
}) => {
  const {
    bodyParameters,
    availableRelationLookupDict,
    fieldSchema,
    fieldKey,
    index,
    isRequired,
    fieldKeyPath,
    root,
    shouldManualInputSubObject,
    onUpdate,
    isNestedObject = false,
    isNestedWriteField = false,
    nestedWritesMapForMappingTestModelArray = [],
    commonModelName,
  } = props;
  const relationalModelClass = fieldSchema?.relation?.model ?? fieldSchema?.items?.relation?.model;

  const availableRelationNames = relationalModelClass
    ? availableRelationLookupDict?.[relationalModelClass] ?? []
    : undefined;

  // If we're rendering inputs for an object parameter, recursively render
  // inputs for all of its key/value pairs
  const objectRenderingCondition =
    shouldManualInputSubObject == null
      ? //@ts-ignore
        [undefined, []].includes(availableRelationNames)
      : shouldManualInputSubObject;

  if (fieldSchema.type == "object" && objectRenderingCondition) {
    const items = Object.entries(fieldSchema?.properties ?? {});

    // because of how these components are structured, this state is to re-calculate the required fields for nested objects
    const nestedObjectRequiredFields = fieldSchema?.required ?? [];

    return items.length == 0 ? null : (
      <>
        <MappingTestEditorCommonModelExpectedMappingSectionHeader commonModelName={fieldKey} />
        {items.map(([fieldKey, fieldSchema], index) => (
          <BodyParameterField
            commonModelName={commonModelName}
            nestedWritesMapForMappingTestModelArray={nestedWritesMapForMappingTestModelArray}
            bodyParameters={bodyParameters}
            availableRelationLookupDict={availableRelationLookupDict}
            key={fieldKey}
            fieldKey={fieldKey}
            fieldSchema={fieldSchema}
            index={index}
            fieldKeyPath={[...fieldKeyPath, fieldKey]}
            root={"model"}
            onUpdate={onUpdate}
            isRequired={
              isNestedObject ? nestedObjectRequiredFields?.includes(fieldKey) : isRequired
            }
          />
        ))}
      </>
    );
  }

  return (
    <MappingTestEditorCommonModelExpectedMappingField
      key={fieldKey}
      bodyParameters={bodyParameters}
      text={fieldKey}
      isRowShaded={index % 2 === 1}
      hasMappingMisses={false}
      fieldKeyPath={fieldKeyPath}
      isUniqueIdentifier={false}
      fieldSchema={fieldSchema}
      fieldType={fieldSchema.type ?? "unknown"}
      // @ts-ignore choices is deprecated but still used
      enumChoices={fieldSchema?.enum ?? fieldSchema?.choices}
      value={
        // @ts-ignore choices is deprecated but still used
        getBodyparameterValueByPath(
          root ? [root, ...fieldKeyPath] : fieldKeyPath,
          bodyParameters as any
        ) ?? getEmptyValueForField(fieldSchema)
      }
      setValue={(newFieldValue) => onUpdate({ fieldKeyPath, newFieldValue })}
      availableRelationNames={availableRelationNames}
      availableRelationLookupDict={availableRelationLookupDict}
      isRequired={isRequired}
      onUpdate={onUpdate}
      isNestedWriteField={isNestedWriteField}
      nestedWritesMapForMappingTestModelArray={nestedWritesMapForMappingTestModelArray}
      commonModelName={commonModelName}
    />
  );
};

export default BodyParameterField;
