import { MoreHorizontal } from "lucide-react";
import {
  rateLimitTimeValueTabletDisplayStrings,
  rateLimitTypeRequestDisplayStrings,
  rateLimitTypeTableRequestDisplayStrings,
} from "../../../rate-limits/utils/constants";
import { RateLimitType } from "../../../utils/Entities";
import {
  ButtonVariant,
  Dropdown,
  MenuItem,
  Text,
  Toggle,
} from "@merge-api/merge-javascript-shared";
import RateLimitConfigurationContext from "../../../rate-limits/context/RateLimitConfigurationContext";
import { useContext } from "react";

interface APIEndpointRateLimitAccordionTitleProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  cardNumber: number;
  setIsDeletionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const APIEndpointRateLimitAccordionTitle = ({
  isModalOpen,
  setIsModalOpen,
  cardNumber,
  setIsDeletionModalOpen,
}: APIEndpointRateLimitAccordionTitleProps) => {
  // context
  const {
    isActive,
    rateLimitType,
    rateLimitTimePeriod,
    rateLimitMaxCount,
    rateLimitMaxPoints,
    rateLimitTimeValue,
  } = useContext(RateLimitConfigurationContext);

  // consts derived from state to display rate limit configuration in title
  const requestDisplayString = rateLimitType && rateLimitTypeRequestDisplayStrings[rateLimitType];
  const timeDisplayString =
    rateLimitTimePeriod && rateLimitTimeValueTabletDisplayStrings[rateLimitTimePeriod];
  const countDisplay =
    rateLimitType !== RateLimitType.COMPLEXITY_COUNT ? rateLimitMaxCount : rateLimitMaxPoints;
  const typeDisplayString = rateLimitType && rateLimitTypeTableRequestDisplayStrings[rateLimitType];
  const countDisplayBoolean =
    (rateLimitType !== RateLimitType.COMPLEXITY_COUNT && rateLimitMaxCount) ||
    (rateLimitType === RateLimitType.COMPLEXITY_COUNT && rateLimitMaxPoints);
  const displayTitle =
    rateLimitType && rateLimitTimePeriod && rateLimitTimeValue && countDisplayBoolean;

  return (
    <div className="flex flex-row items-center justify-between w-full">
      {displayTitle ? (
        <div className="flex flex-row items-center">
          <Text className="mr-2" as="div" variant="h6">
            {typeDisplayString}
          </Text>
          <Text as="div" className="text-gray-90">
            {countDisplay} {requestDisplayString} / {rateLimitTimeValue} {timeDisplayString}
          </Text>
        </div>
      ) : (
        <div className="flex flex-row items-center">
          <Text className="mr-2" as="div" variant="h6">
            Rate Limit {cardNumber}
          </Text>
        </div>
      )}
      <div
        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
          event.stopPropagation();
        }}
        className="flex flex-row-items-center space-x-6 mr-6"
      >
        <div
          className="flex items-center"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.stopPropagation();
            setIsModalOpen(!isModalOpen);
          }}
        >
          <Toggle
            className="pointer-events-none"
            checked={isActive}
            label={isActive ? "Active" : "Inactive"}
          />
        </div>
        <Dropdown
          ButtonProps={{
            children: <MoreHorizontal size={16} className="text-gray-50" />,
            variant: ButtonVariant.IconShadowHidden,
          }}
          menuPlacement="bottom-end"
        >
          <MenuItem
            onClick={() => {
              setIsDeletionModalOpen(true);
            }}
          >
            <Text className="text-red-50">Delete configuration</Text>
          </MenuItem>
        </Dropdown>
      </div>
    </div>
  );
};

export default APIEndpointRateLimitAccordionTitle;
