import { useParams } from "react-router-dom";
import { Integration } from "../../../models/Entities";
import { MappingTestExpectedResult } from "../../../models/MappingTests";
import { getEndpointCollectionPath } from "../../../router/RouterUtils";
import SubLeftNavigationContainer from "../../shared/layout/SubLeftNavigationContainer";
import IndividualEndpointCollectionTestCaseTable from "./IndividualEndpointCollectionTestCaseTable";
import { getEndpointCollectionTitle } from "../utils/MappingTestUtils";
import { useGetEndpointCollection } from "./hooks/useGetEndpointCollection";

type RouteParams = {
  mappingTestSuiteID: string;
  endpointCollectionID: string;
};

type Props = {
  integrationMeta: Integration;
  endUserOrganizationName: string;
};

const IndividualEndpointCollectionOverview = ({
  integrationMeta,
  endUserOrganizationName,
}: Props) => {
  const { mappingTestSuiteID, endpointCollectionID } = useParams<RouteParams>();
  const { endpointCollection } = useGetEndpointCollection({
    endpointCollectionID,
  }); // setEndpointCollection

  const basePath = getEndpointCollectionPath(
    integrationMeta.id,
    mappingTestSuiteID,
    endpointCollectionID
  );

  return (
    <SubLeftNavigationContainer
      subtabs={[
        {
          header: true,
          label: "Filter By",
        },
        {
          label: "All",
          destination: { pathname: basePath, params: "" },
        },
        {
          label: "Success",
          divider: true,
          destination: {
            pathname: basePath,
            params: `test_case_type=${MappingTestExpectedResult.SUCCESS}`,
          },
        },
        {
          label: "Success with Warnings",
          destination: {
            pathname: basePath,
            params: `test_case_type=${MappingTestExpectedResult.SUCCESS_WITH_WARNINGS}`,
          },
        },
        {
          label: "Failure",
          destination: {
            pathname: basePath,
            params: `test_case_type=${MappingTestExpectedResult.ERROR}`,
          },
        },
      ]}
    >
      {endpointCollection && (
        <IndividualEndpointCollectionTestCaseTable
          title={getEndpointCollectionTitle(endpointCollection)}
          testCases={endpointCollection.test_cases}
          integrationMeta={integrationMeta}
          endpointCollectionID={endpointCollectionID}
          mappingTestSuiteID={mappingTestSuiteID}
          endUserOrganizationName={endUserOrganizationName}
        />
      )}
    </SubLeftNavigationContainer>
  );
};

export default IndividualEndpointCollectionOverview;
