import { useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { PublishModuleInfo } from "../types";

interface Props {
  integrationID: string;
}

const useLoadStagedVersions = ({ integrationID }: Props) => {
  const [publishModuleInfo, setPublishModuleInfo] = useState<PublishModuleInfo>();

  const fetchStagedVersions = () => {
    fetchWithAuth({
      path: `/integrations/versioning/${integrationID}/staged-version`,
      method: "GET",
      onResponse: (data: PublishModuleInfo) => {
        setPublishModuleInfo(data);
      },
    });
  };

  return {
    publishModuleInfo,
    fetchStagedVersions,
  };
};

export default useLoadStagedVersions;
