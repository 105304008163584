import { useHistory } from "react-router-dom";
import { BlueprintOperationType, Blueprint } from "../../../models/Blueprints";
import { createBlueprint } from "../../blueprint-editor/utils/BlueprintEditorAPIClient";
import { fetchIntegrationCommonModelContentTypes } from "../../integrations/utils/IntegrationsAPIClient";
import { Button, Col, Row } from "react-bootstrap";
import { navigateToBlueprintEditor } from "../../../router/RouterUtils";
import MergeModal from "../../shared/MergeModal";
import {
  getCategoryChoices,
  getCommonModelChoicesForCategories,
  getOperationNameFromOperationType,
  isBlueprintWriteOperation,
  isBlueprintProxyOperation,
} from "../../blueprint-editor/utils/BlueprintEditorUtils";
import { useCallback, useEffect, useState } from "react";
import DropdownFormField from "../../blueprint-editor/right-panel/DropdownFormField";
import {
  Integration,
  IntegrationCommonModelContentType,
  ReportTemplate,
} from "../../../models/Entities";
import { showErrorToast } from "../../shared/Toasts";
import { fetchWithAuth, FormErrorData } from "../../../api-client/api_client";

type Props = {
  categories: Array<string>;
  integrationMeta: Integration;
  isShown: boolean;
  onHide: () => void;
};

const CreateBlueprintModal = ({ categories, isShown, onHide, integrationMeta }: Props) => {
  const [operationType, setOperationType] = useState<BlueprintOperationType>(
    BlueprintOperationType.FETCH
  );
  const [writtenCommonModel, setWrittenCommonModel] = useState<string>();
  const [proxiedCommonModel, setProxiedCommonModel] = useState<string>();
  const [reportTemplateID, setReportTemplateID] = useState<string | undefined>();
  const [webhookReceiverEventID, setWebhookReceiverEventID] = useState<string | undefined>();
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [actionType, setActionType] = useState<string>();
  const [reportTemplates, setReportTemplates] = useState<ReportTemplate[]>([]);
  const [availableCommonModelContentTypes, setAvailableCommonModelContentTypes] = useState<
    IntegrationCommonModelContentType[]
  >();

  const integrationID = integrationMeta.id;
  const availableActionTypes = integrationMeta.action_types?.[operationType] ?? [];
  const { webhook_receiver_event_types } = integrationMeta;

  useEffect(() => {
    if (actionType) {
      setWrittenCommonModel(undefined);
      setProxiedCommonModel(undefined);
    }
  }, [actionType]);

  const fetchReportTemplates = useCallback(() => {
    fetchWithAuth({
      path: `/integrations/${integrationID}/report-templates`,
      method: "GET",
      onResponse: (data: ReportTemplate[]) => {
        setReportTemplates(data);
      },
    });
  }, [integrationID]);

  useEffect(() => {
    fetchReportTemplates();
  }, []);

  useEffect(() => {
    if (actionType && !availableActionTypes.includes(actionType)) {
      setActionType(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionType, operationType]);

  useEffect(() => {
    if (writtenCommonModel) {
      setActionType(undefined);
    }
  }, [writtenCommonModel]);

  useEffect(() => {
    if (proxiedCommonModel) {
      setActionType(undefined);
    }
  }, [proxiedCommonModel]);

  useEffect(() => {
    if (isBlueprintProxyOperation(operationType))
      fetchIntegrationCommonModelContentTypes({
        integrationID: integrationID,
        onSuccess: (data) => {
          setAvailableCommonModelContentTypes(data);
        },
        onError: () => {
          showErrorToast("Failed to return available common models for proxy.");
        },
      });
  }, [operationType]);

  const history = useHistory();

  const isWrite = operationType && isBlueprintWriteOperation(operationType);

  const isProxy = operationType && isBlueprintProxyOperation(operationType);

  const onCloseModal = () => {
    setWebhookReceiverEventID(undefined);
    setReportTemplateID(undefined);
    onHide();
  };

  const addBlueprintAndNavigate = () => {
    if (operationType) {
      createBlueprint({
        integrationID,
        operationType,
        writtenCommonModel,
        proxiedCommonModel,
        actionType,
        reportTemplateID,
        webhookReceiverEventID,
        category: selectedCategory,
        onSuccess: (data: Blueprint) =>
          navigateToBlueprintEditor(history, integrationID, data.version.id),
        onError: (err) => {
          err?.json().then((data: FormErrorData) => {
            let fieldNameFound = false;
            for (const field_name in data) {
              if (field_name === "non_field_errors") {
                showErrorToast(data[field_name][0]);
                fieldNameFound = true;
                break;
              }
            }
            if (!fieldNameFound) {
              showErrorToast("Failed to create blueprint.");
            }
          });
        },
      });
    }
  };

  const getCommonModelContentTypesForProxy = () => {
    return (
      availableCommonModelContentTypes?.map(({ id, category, common_model_name }) => ({
        id: id.toString(),
        name: category + "." + common_model_name,
      })) ?? []
    );
  };

  const isSavingDisabled = !operationType;

  return (
    <MergeModal
      show={isShown}
      onHide={onCloseModal}
      title={"Create Blueprint"}
      bodyClassName="overflow-hidden"
    >
      <DropdownFormField
        title="Operation Type"
        subtitle="The operation type of the blueprint."
        placeholder="Select Operation Type"
        onChange={(e) => {
          setOperationType(e.target.value);
        }}
        currentValue={operationType}
        choices={(Object.keys(BlueprintOperationType) as Array<BlueprintOperationType>)
          .filter((operation_type) => operation_type !== BlueprintOperationType.META)
          .map((operationType) => ({
            name: getOperationNameFromOperationType(operationType),
            id: operationType,
          }))}
      />
      {isWrite && (
        <DropdownFormField
          title="Written Common Model"
          subtitle="The written common model of the write blueprint."
          placeholder="Select Common Model"
          onChange={(e) => setWrittenCommonModel(e.target.value)}
          currentValue={writtenCommonModel}
          choices={getCommonModelChoicesForCategories(categories)}
          disabled={!!actionType}
          showEmptyOption={true}
        />
      )}
      {isProxy && (
        <DropdownFormField
          title="Proxied Common Model"
          subtitle="The proxied common model of the proxy blueprint."
          placeholder="Select Common Model"
          onChange={(e) => setProxiedCommonModel(e.target.value)}
          currentValue={proxiedCommonModel}
          choices={getCommonModelContentTypesForProxy()}
          disabled={!!actionType}
          showEmptyOption={true}
        />
      )}
      {categories.length > 1 && (
        <DropdownFormField
          title="Category"
          subtitle="For integrations covering multiple categories, select the specific category for this blueprint."
          placeholder="Select Category"
          onChange={(e) => setSelectedCategory(e.target.value)}
          currentValue={selectedCategory}
          choices={getCategoryChoices(categories)}
          showEmptyOption={true}
        />
      )}
      {availableActionTypes.length > 0 && (
        <DropdownFormField
          title="Action Type"
          subtitle="The action type of the blueprint, if applicable."
          placeholder="Select Action Type"
          onChange={(e) => setActionType(e.target.value)}
          currentValue={actionType}
          choices={availableActionTypes.map((actionType) => ({ name: actionType, id: actionType }))}
          disabled={!!writtenCommonModel}
          showEmptyOption={true}
        />
      )}
      {(reportTemplates ?? []).length > 0 && operationType == BlueprintOperationType.FETCH && (
        <DropdownFormField
          title="Report Template"
          subtitle="If this is a Report Blueprint, choose the associated Report Template"
          placeholder="Select Report Template"
          onChange={(e) => setReportTemplateID(e.target.value)}
          currentValue={reportTemplateID}
          choices={reportTemplates!.map((reportTemplate) => ({
            name: reportTemplate.name,
            id: reportTemplate.id,
          }))}
          disabled={!!webhookReceiverEventID}
          showEmptyOption={true}
        />
      )}
      {(webhook_receiver_event_types ?? []).length > 0 &&
        operationType == BlueprintOperationType.FETCH && (
          <DropdownFormField
            title="Action Type"
            subtitle="If this is a Webhook Receiever Blueprint, choose the webhook receiver event type to be listening for"
            placeholder="Select Webhook Receiver Event Type"
            onChange={(e) => setWebhookReceiverEventID(e.target.value)}
            currentValue={webhookReceiverEventID}
            choices={webhook_receiver_event_types!.map((webhookEvent) => ({
              name: webhookEvent.event,
              id: webhookEvent.id,
            }))}
            disabled={!!reportTemplateID}
            showEmptyOption={true}
          />
        )}
      <Row className="mt-6">
        <Col>
          <Button className="btn-block" variant="outline-danger" onClick={onCloseModal}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            className="btn-block"
            disabled={isSavingDisabled}
            onClick={addBlueprintAndNavigate}
          >
            Create Blueprint
          </Button>
        </Col>
      </Row>
    </MergeModal>
  );
};

export default CreateBlueprintModal;
