export enum DiffModelTypeEnum {
  INTEGRATION_INITIALIZATION = "INTEGRATION_INITIALIZATION",
  BASE_API_URL = "BASE_API_URL",
  WEB_ADDRESS_SCHEMA = "WEB_ADDRESS_SCHEMA",
  AUTHENTICATION_CONFIGURATION = "AUTHENTICATION_CONFIGURATION",
  MERGE_LINK_STEPS_CONFIGURATION = "MERGE_LINK_STEPS_CONFIGURATION",
  MERGE_LINK_STEPS = "MERGE_LINK_STEPS",
  MERGE_LINK_STEPS_CHANGELOG = "MERGE_LINK_STEPS_CHANGELOG",
  LINK_CHOICE_STEP_OPTION_CHANGELOG = "LINK_CHOICE_STEP_OPTION_CHANGELOG",
  PAGINATION_CONFIGURATION = "PAGINATION_CONFIGURATION",
  RATE_LIMIT_CONFIGURATION = "RATE_LIMIT_CONFIGURATION",
  API_ENDPOINT_CONFIGURATION = "API_ENDPOINT_CONFIGURATION",
  API_ENDPOINT = "API_ENDPOINT",
  API_ENDPOINT_CHANGELOG = "API_ENDPOINT_CHANGELOG",
  CUSTOMER_FACING_FIELDS = "CUSTOMER_FACING_FIELDS",
  BLUEPRINT = "BLUEPRINT",
  BLUEPRINT_STEP_TEMPLATE = "BLUEPRINT_STEP_TEMPLATE",
  DEFAULT = "DEFAULT",
  SELECTIVE_SYNC_FILTER_SCHEMA = "SELECTIVE_SYNC_FILTER_SCHEMA",
  SCRAPER = "SCRAPER",
}

export const MapDiffModelTypeToName: { [key in DiffModelTypeEnum]: string } = {
  [DiffModelTypeEnum.INTEGRATION_INITIALIZATION]: "integration initialization",
  [DiffModelTypeEnum.BASE_API_URL]: "base API URL",
  [DiffModelTypeEnum.WEB_ADDRESS_SCHEMA]: "web address schema",
  [DiffModelTypeEnum.AUTHENTICATION_CONFIGURATION]: "authentication configuration",
  [DiffModelTypeEnum.MERGE_LINK_STEPS_CONFIGURATION]: "Merge link steps configuration",
  [DiffModelTypeEnum.MERGE_LINK_STEPS]: "Merge Link steps",
  [DiffModelTypeEnum.MERGE_LINK_STEPS_CHANGELOG]: "Merge Link steps",
  [DiffModelTypeEnum.LINK_CHOICE_STEP_OPTION_CHANGELOG]: "Link choice step option",
  [DiffModelTypeEnum.RATE_LIMIT_CONFIGURATION]: "rate limit configuration",
  [DiffModelTypeEnum.PAGINATION_CONFIGURATION]: "pagination configuration",
  [DiffModelTypeEnum.API_ENDPOINT_CONFIGURATION]: "API Endpoints configuration",
  [DiffModelTypeEnum.API_ENDPOINT]: "API Endpoint",
  [DiffModelTypeEnum.API_ENDPOINT_CHANGELOG]: "API Endpoint",
  [DiffModelTypeEnum.CUSTOMER_FACING_FIELDS]: "customer facing fields",
  [DiffModelTypeEnum.BLUEPRINT]: "Blueprint Versions",
  [DiffModelTypeEnum.BLUEPRINT_STEP_TEMPLATE]: "step template",
  [DiffModelTypeEnum.DEFAULT]: "",
  [DiffModelTypeEnum.SELECTIVE_SYNC_FILTER_SCHEMA]: "Selective sync filter schema",
  [DiffModelTypeEnum.SCRAPER]: "Scraper",
};

export const VERSIONED_MODEL_TYPES = [DiffModelTypeEnum.SELECTIVE_SYNC_FILTER_SCHEMA];

export enum DiffModalTypeEnum {
  PRESAVE = "PRESAVE",
  COMPARE = "COMPARE",
}

export enum DiffStateFieldTypeEnum {
  HEADER = "HEADER",
  SECTION = "SECTION",
  FIELD = "FIELD",
  EMPTY = "EMPTY",
}

export enum DiffStatusEnum {
  NO_CHANGES = "NO_CHANGES",
  DELETED = "DELETED",
  ADDED = "ADDED",
  UPDATED = "UPDATED",
}

export const MapDiffStatusEnumToColor: { [key in DiffStatusEnum]: string } = {
  [DiffStatusEnum.NO_CHANGES]: "bg-white",
  [DiffStatusEnum.DELETED]: "bg-red-0",
  [DiffStatusEnum.ADDED]: "bg-green-0",
  [DiffStatusEnum.UPDATED]: "bg-yellow-0",
};

export interface DiffStateField {
  type: DiffStateFieldTypeEnum; // Denotes whether it's a header, section, or field
  name: string; // The key name to value in raw JSON
  displayName: string; // The name to display in Diff modal
  currentValue: string; // Value to display for field in "Current version" column
  newValue: string; // Value to display for field in "Your changes" column
  diffStatus: DiffStatusEnum; // Denotes whether field has no changes/updated/deleted/added
  displayNameCurrent?: string; // Helpful to display array fields, esp when current number != new number
  displayNameNew?: string; // Helpful to display array fields, esp when current number != new number
  childDiffStateFields?: DiffState; // The children fields
  childDiffStateNestedFields?: DiffState; // Used for BP steps, where there can be both nested & section child fields.
  isRenderChildrenAsNested?: boolean; // Renders gray arc, to the left of child fields
  isRenderAsPreviewable?: boolean; // Renders "Preview" button
  isRenderCurrentAsEmpty?: boolean; // Renders "Current version" column as empty for this field
  isRenderNewAsEmpty?: boolean; // Renders "Your changes" column as empty for this field
  isRenderAsSecret?: boolean; // Renders it as a password
  isRenderAsExpanded?: boolean; // Renders it as expanded by default
}

export type DiffState = DiffStateField[];

/* FUNCTION TYPES */

export type generateDiffStateInputType = {
  fields: generateDiffStateFieldInputType[];
  // Fields that denote how to process an array. See Merge Link steps as example.
  isStateAnArray?: boolean;
  arrayItemHeaderName?: string;
  isArrayOrdered?: boolean;
  arrayItemIdentifier?: string;
  arrayItemNameToDisplayAsHeaderValue?: string;
  isIgnoreArrayOrder?: boolean;
  keyPathToSliceOfState?: string[];
  overrideCurrentState?: { [key: string]: string };
  overrideNewState?: { [key: string]: string };
};

export type generateConditionType = {
  name: string;
  keyPathToName?: string[];
  valuesToMatch: any[];
};

export type generateDiffStateFieldInputType = {
  /* --- Base fields --- */
  name: string; // The key name in the raw JSON
  keyPathToName?: string[]; // The key path to the key name in the raw JSON, so that we can parse the key's value
  displayName: string; // The name to display in Diff modal
  displayNameCurrent?: string; // Helpful to display array fields, esp when current number != new number
  displayNameNew?: string; // Helpful to display array fields, esp when current number != new number
  type?: DiffStateFieldTypeEnum; // Denotes whether it's a header, section, or field
  /* --- Boolean fields that denote how to process input --- */
  isRenderChildrenAsNested?: boolean; // Renders gray arc, to the left of child fields
  isRenderAsPreviewable?: boolean; // Renders "Preview" button
  isRenderCurrentAsEmpty?: boolean; // Renders "Current version" column as empty for this field
  isRenderNewAsEmpty?: boolean; // Renders "Your changes" column as empty for this field
  isRenderAsEmpty?: boolean; // Doesn't render the value, but still shows the field. Especially helpful for Selective Sync's Operators
  isRenderAsSecret?: boolean; // Renders it as a password
  isRenderNumberOfChildrenInDisplayName?: boolean; // Renders (6) in "Advanced (6)", for example
  isRenderAsExpanded?: boolean; // Renders it as expanded by default
  /* --- Fields that determine if we should even generate a diffStateField for a given input field --- */
  isRenderNoValueAsEmpty?: boolean; // If true, does not generate a diffStateField for input field whose currentState & newState is empty. Particularly helpful for Selective Sync's Operators.
  generateCondition?: generateConditionType; // Only generate a diffStateField for input field if this condition passes
  /* --- Fields that represent child fields --- */
  childDiffStateInputFields?: generateDiffStateFieldInputType[]; // Most commonly used
  childDiffStateFields?: DiffState; // Used if need to call generateDiffState() to generate child fields. Useful when you have array of child fields.
  childDiffStateNestedFields?: DiffState; // Used for BP steps, where there can be both nested & section-children fields
  generateChildDiffStateInput?: generateDiffStateInputType; // Same as childDiffStateFields. This input will be fed into generateDiffState(). Useful for array within array.
  isChildBlueprintSteps?: boolean; // If true, diffState will be generated using custom helpers-blueprint-diff logic instead of standard diff logic.
};
