import Dropdown from "./Dropdown";

import FormField, { FormFieldCommonProps } from "./FormField";
interface Props extends FormFieldCommonProps {
  choices: Array<{ name: string; id: string }>;
  currentValue: any;
  placeholder: string;
  className?: string;
  onChange: (e: any) => void;
  disabled?: boolean;
  showEmptyOption?: boolean;
}

const DropdownFormField = ({ subtitle, title, className, ...rest }: Props) => (
  <FormField subtitle={subtitle} title={title} className={className}>
    <Dropdown {...rest} />
  </FormField>
);

export default DropdownFormField;
