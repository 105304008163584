import { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { List, MinusCircle } from "lucide-react";
import styled from "styled-components";
import MergeText from "../../shared/text/MergeText";

type MappingMissProps = {
  hasMappingMisses?: boolean;
  isMappedCorrectly?: boolean;
  canEdit?: boolean;
};

const SectionHeaderContainer = styled.div<MappingMissProps>`
  display: flex;
  justify-content: between;
  width: 100%;
  align-items: stretch;

  > * {
    padding-left: 4px;
  }

  border-top: ${({ hasMappingMisses, isMappedCorrectly }) =>
    hasMappingMisses ? "1px solid #FF8696" : isMappedCorrectly ? "1px solid #97EDCE" : ""}};
  border-bottom: ${({ hasMappingMisses, isMappedCorrectly }) =>
    hasMappingMisses ? "1px solid #FF8696" : isMappedCorrectly ? "1px solid #97EDCE" : ""}};
`;

const TitleSection = styled.div<MappingMissProps>`
  height: inherit;
  display: flex;
  ${({ canEdit }) => !canEdit && `flex: 1;`}
  align-items: center;
  background: ${({ hasMappingMisses, isMappedCorrectly }) =>
    hasMappingMisses ? "#FFE9EC" : isMappedCorrectly ? "#D2FFEF" : "#06297d"};
  padding: 8px;
  gap: 4px;
`;

const EditSection = styled.div<MappingMissProps>`
  background: ${({ hasMappingMisses, isMappedCorrectly }) =>
    hasMappingMisses ? "#FFFAFA" : isMappedCorrectly ? "#F5FDFA" : "#dce7fa"};
  display: flex;
  justify-content: between;
  align-items: center;
  flex: 1;
`;

const NameEdit = styled.input<MappingMissProps>`
  background: ${({ hasMappingMisses, isMappedCorrectly }) =>
    hasMappingMisses ? "#FFFAFA" : isMappedCorrectly ? "#F5FDFA" : "#dce7fa"};
  color: #121314;
  font-size: 13px;
  line-height: 20px;
  border: 0;
  outline: 0;
  flex: 1;

  &::placeholder {
    color: #a2b9fa;
  }
`;

const ActionButtons = styled.div`
  gap: 8px;
  flex: 0;
  padding-right: 12px;
  padding-left: 8px;
`;

export type ActionButtonProps = {
  isDisabled?: boolean;
};

const ActionButton = styled.div<ActionButtonProps>`
  display: flex;
  align-items: center;
  color: ${({ isDisabled }) => (isDisabled ? "#c9d0da" : "#121314")};
  cursor: ${({ isDisabled }) => (isDisabled ? "auto" : "pointer")};
  &:hover {
    stroke-width: 3;
    opacity: 0.7;
  }
`;

type Props = {
  commonModelName: string;
  hasMappingMisses?: boolean;
  isMappedCorrectly?: boolean;
  individualMappingID?: string;
  isIDAlreadyUsed?: (individualMappingID: string) => boolean;
  onAdd?: (individualMappingID: string) => void;
  onChangeName?: (oldIndividualMappingID: string, newIndividualMappingID: string) => void;
  onDelete?: (oldIndividualMappingID: string) => void;
  updateRelationName?: (commonModelID: string, oldName: string, newName: string) => void;
  commonModelID?: string;
  isMissingInProducedModels?: boolean;
};

const MappingTestEditorCommonModelExpectedMappingSectionHeader = ({
  commonModelName,
  hasMappingMisses,
  isMappedCorrectly,
  individualMappingID,
  isMissingInProducedModels,
  isIDAlreadyUsed,
  updateRelationName,
  onAdd,
  onChangeName,
  onDelete,
  commonModelID,
}: Props) => {
  const [unsavedNewMappingID, setUnsavedNewMappingID] = useState<string>(individualMappingID ?? "");
  const inputRef = useRef<HTMLInputElement>(null);

  const hasDraftName =
    unsavedNewMappingID !== null &&
    unsavedNewMappingID !== "" &&
    !(isIDAlreadyUsed && isIDAlreadyUsed(unsavedNewMappingID));

  const handleBlur = () => {
    if (individualMappingID && individualMappingID !== unsavedNewMappingID && onChangeName) {
      onChangeName(individualMappingID, unsavedNewMappingID);
    }
  };

  const canEdit = onAdd !== undefined || onChangeName !== undefined;

  return (
    <SectionHeaderContainer
      hasMappingMisses={hasMappingMisses}
      isMappedCorrectly={isMappedCorrectly}
    >
      <TitleSection
        hasMappingMisses={hasMappingMisses}
        isMappedCorrectly={isMappedCorrectly}
        canEdit={canEdit}
      >
        <List
          color={hasMappingMisses ? "#F00424" : isMappedCorrectly ? "#00B187" : "white"}
          size={"12px"}
        />
        <MergeText
          isBold
          size={"12px"}
          color={hasMappingMisses ? "#F00424" : isMappedCorrectly ? "#00B187" : "white"}
        >
          {commonModelName}
        </MergeText>
      </TitleSection>
      {canEdit && (
        <EditSection hasMappingMisses={hasMappingMisses} isMappedCorrectly={isMappedCorrectly}>
          <NameEdit
            autoFocus={true}
            hasMappingMisses={hasMappingMisses}
            isMappedCorrectly={isMappedCorrectly}
            value={unsavedNewMappingID}
            onChange={(e) => {
              const oldName = unsavedNewMappingID;
              setUnsavedNewMappingID(e.target.value.trim());
              if (updateRelationName && commonModelID) {
                updateRelationName(commonModelID, oldName, e.target.value.trim());
              }
            }}
            ref={inputRef}
            onBlur={handleBlur}
            placeholder="Add name..."
          />
          {isMissingInProducedModels ? <MergeText color="#F00424">UUID not found</MergeText> : null}
          <ActionButtons>
            {onDelete && typeof individualMappingID === "string" && (
              <ActionButton>
                <MinusCircle
                  color={hasMappingMisses ? "#F00424" : isMappedCorrectly ? "#00B187" : "#121314"}
                  onClick={() => onDelete(individualMappingID)}
                  size={16}
                />
              </ActionButton>
            )}
            {onAdd && (
              <Button
                disabled={!hasDraftName}
                size="sm"
                onClick={() => {
                  if (hasDraftName) {
                    onAdd(unsavedNewMappingID);
                    setUnsavedNewMappingID("");
                  }
                }}
              >
                Create
              </Button>
            )}
          </ActionButtons>
        </EditSection>
      )}
    </SectionHeaderContainer>
  );
};

export default MappingTestEditorCommonModelExpectedMappingSectionHeader;
