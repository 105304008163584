import {
  BlueprintParameterValue,
  BlueprintParameterValueCustomArray,
  BlueprintParameterValueCustomObject,
  BlueprintParameterValueType,
  BlueprintStep,
} from "../../../../models/Blueprints";

type ParameterType = "array" | "object";

export type ArrayIndexPathComponent = {
  type: "array";
  index: number;
};
export type ObjectPropertyPathComponent = {
  type: "object";
  key: string;
};
type JSONPathComponent = ArrayIndexPathComponent | ObjectPropertyPathComponent;

/**
 * A `JSONPath` is a list of path components that are used to index into a nested
 * parameter value structure. A parameter value may be arbitrarily nested with
 * custom arrays and custom objects, so each component of this path represents
 * either a key used to index into an object, or a number used to index into an array.
 */
export type JSONPath = JSONPathComponent[];

type NestedTypeAnnotation = {
  nestedType: ParameterType;
};
type AnnotatedObjectPropertyPathComponent = ObjectPropertyPathComponent & NestedTypeAnnotation;
type AnnotatedArrayIndexPathComponent = ArrayIndexPathComponent & NestedTypeAnnotation;
type AnnotatedJSONPathComponent =
  | AnnotatedArrayIndexPathComponent
  | AnnotatedObjectPropertyPathComponent;

/**
 * An `AnnotatedJSONPath` is similar to a `JSONPath`, but each path component
 * is annotated with the nested type that we expect after we have indexed into
 * the given array/object. This annotated path will always have 1 less component
 * than the corresponding JSONPath. The reason for this is that we only care about
 * the nestedTypes for arrays/objects that contain another array/object, because we
 * use this information to fill out the nested JSON structure. This means we do not
 * care about an annotated path component for the leaf nodes of the JSONPath.
 * For example...
 *
 * Given the following object:
 * OBJECT = { data: [ { value: 10 }, { value: 20 } ] }
 *
 * We might have the JSONPath:
 * [
 *   {key: "data"},
 *   {index: 0},
 *   {key: "value"},
 * ]
 * which corresponds to OBJECT["data"][0]["value"].
 *
 * Our desired annotated path would look like this:
 * [
 *   {key: "data", nestedType: "array"},
 *   {index: 0, nestedType: "object"},
 * ]
 *
 * Which tells us we have an expected traversal pattern of object->array->object.
 *
 * You may ask: why did you make this so complicated?
 * Well this becomes useful when we fill out an empty nested parameter value.
 * For example, say OBJECT = {}, but we want to set OBJECT["data"][0]["value"] = 100
 *
 * Here's some pseudo-code for how we do this using the path and annotatedPath:
 *
 * annotatedPath = annotate(path)
 * pointer = OBJECT
 * for component in annotatedPath:
 *   { keyOrIndex, nestedType } = component
 *   pointer[keyOrIndex] = keepExistingOrCreateEmpty(nestedType)
 *   pointer = pointer[keyOrIndex]
 *
 * { lastKeyOrIndex, value } = path[-1]
 * pointer[lastKeyOrIndex] = value
 *
 */
type AnnotatedJSONPath = AnnotatedJSONPathComponent[];

const createEmptyCustomArray = (): BlueprintParameterValueCustomArray => {
  return {
    value_type: BlueprintParameterValueType.customArray,
    array_values: [],
  };
};

const createEmptyCustomObject = (): BlueprintParameterValueCustomObject => {
  return {
    value_type: BlueprintParameterValueType.customObject,
    object_value: {},
  };
};

const createEmptyParameterValue = (componentType: ParameterType) => {
  return componentType === "array" ? createEmptyCustomArray() : createEmptyCustomObject();
};

const paramMatchesType = (param: BlueprintParameterValue, pathComponentType: ParameterType) => {
  const paramType = param.value_type;
  switch (pathComponentType) {
    case "array":
      return paramType === BlueprintParameterValueType.customArray;
    case "object":
      return paramType === BlueprintParameterValueType.customObject;
    default:
      return false;
  }
};

/**
 * Given a raw JSONPath, traverse the indices as tuples of (i, i+1)
 * and then annotate each path component at `i` with the value type of the
 * path component at `i+1`. These annotated path components come in handy
 * when filling out an empty JSON structure with the correct nested types
 * while traversing a path.
 */
const getAnnotatedPath = (path: JSONPath): AnnotatedJSONPath => {
  let annotatedPathComponents = [];
  for (let i = 0; i < path.length - 1; i++) {
    const annotatedComponent = {
      ...path[i],
      nestedType: path[i + 1].type,
    };
    annotatedPathComponents.push(annotatedComponent);
  }
  return annotatedPathComponents;
};

/**
 * Given a custom object parameter value and a path component, initialize
 * the nested value inside the object with the expected type.
 */
const fillObjectParameterKey = (
  param: BlueprintParameterValueCustomObject,
  propertyComponent: AnnotatedObjectPropertyPathComponent
) => {
  const { key, nestedType } = propertyComponent;
  const emptyValue = nestedType === "array" ? createEmptyCustomArray() : createEmptyCustomObject();
  if (key in param.object_value) {
    const nestedValue = param.object_value[key];
    if (!nestedValue || !paramMatchesType(nestedValue, nestedType)) {
      param.object_value[key] = emptyValue;
    }
    return;
  }
  param.object_value = {
    ...param.object_value,
    [key]: emptyValue,
  };
};

/**
 * Given a custom array parameter value and a path component, initialize
 * the nested value inside the array with the expected type.
 */
const fillArrayParameterIndex = (
  param: BlueprintParameterValueCustomArray,
  arrayComponent: AnnotatedArrayIndexPathComponent
) => {
  const { index, nestedType } = arrayComponent;
  const emptyValue = nestedType === "array" ? createEmptyCustomArray() : createEmptyCustomObject();
  if (param.array_values.length > index) {
    const nestedValue = param.array_values[index];
    if (!nestedValue || !paramMatchesType(nestedValue, nestedType)) {
      param.array_values[index] = emptyValue;
    }
    return;
  } else if (param.array_values.length < index) {
    throw Error("Can't add multiple empty array items at once");
  }
  param.array_values = [...param.array_values, emptyValue];
};

/**
 * Utility function to initialize the the root parameter value
 * with the expected parameter value type. If we already have the
 * expected type, just return the original parameter value.
 */
const initializeRootParameter = (
  rootParameter: BlueprintParameterValue | undefined,
  path: JSONPath
) => {
  const rootParameterType = path[0].type;
  if (rootParameter && paramMatchesType(rootParameter, rootParameterType)) {
    return rootParameter;
  }
  return createEmptyParameterValue(path[0].type);
};

/**
 * Utility function to traverse a blueprint parameter value down a given path,
 * filling out any empty/undefined nested parameter values along the way.
 * If we encounter an unexpected parameter value type along the way, it gets
 * nulled out and replaced with an empty parameter value of the expected type.
 * This returns a deep copy of the newly filled out parameter value, and should
 * not mutate the existing parameter value.
 *
 * Example (param already has the correct structure):
 *
 * PARAMETERS:
 *
 * param = {
 *   value_type: CUSTOM_OBJECT
 *   object_value: {
 *     data: {
 *       value_type: CUSTOM_ARRAY
 *       array_values: [
 *         { <some_parameter_value> },
 *         { <some_parameter_value> },
 *       ]
 *     }
 *   }
 * }
 * path = [
 *   { key: "data" },
 *   { index: 1 },
 * ]
 * newParam = { <some_new_parameter_value> }
 *
 * RETURN VALUE:
 * {
 *   value_type: CUSTOM_OBJECT
 *   object_value: {
 *     data: {
 *       value_type: CUSTOM_ARRAY
 *       array_values: [
 *         { <some_parameter_value> },
 *         { <some_new_parameter_value> }, <----- Replaced this item with newParam
 *       ]
 *     }
 *   }
 * }
 *
 * Example (overwriting param's structure):
 *
 * param = {
 *   value_type: CUSTOM_OBJECT
 *   object_value: {
 *     data: { <some_parameter_value> }
 *     more_data: { <some_other_parameter_value> }
 *   }
 * }
 * path = [
 *   { key: "more_data" },
 *   { index: 0 },
 * ]
 * newParam = { <some_new_parameter_value> }
 *
 * RETURN VALUE:
 * {
 *   value_type: CUSTOM_OBJECT
 *   object_value: {
 *     data: { <some_parameter_value> }    <----- left alone
 *     more_data: {                        <----- Replaced the entire parameter value
 *       value_type: CUSTOM_ARRAY
 *       array_values: [
 *         { <some_new_parameter_value> }, <----- newParam
 *       ]
 *     }
 *   }
 * }
 */
const fillComponentPath = (
  param: BlueprintParameterValue | undefined,
  path: JSONPath,
  newParam: BlueprintParameterValue
) => {
  const rootParam = initializeRootParameter(param, path);
  const clonedRootParam = JSON.parse(JSON.stringify(rootParam));
  let paramPointer: BlueprintParameterValue = clonedRootParam;
  const annotatedPath = getAnnotatedPath(path);
  for (const pathComponent of annotatedPath) {
    switch (pathComponent.type) {
      case "array":
        const arrayParam = paramPointer as BlueprintParameterValueCustomArray;
        fillArrayParameterIndex(arrayParam, pathComponent);
        paramPointer = arrayParam.array_values[pathComponent.index];
        break;
      case "object":
        const objectParam = paramPointer as BlueprintParameterValueCustomObject;
        fillObjectParameterKey(objectParam, pathComponent);
        paramPointer = objectParam.object_value[pathComponent.key];
    }
  }

  const finalPathComponent = path[path.length - 1];
  switch (finalPathComponent.type) {
    case "array":
      const arrayParamValue = (paramPointer as BlueprintParameterValueCustomArray).array_values;
      const index = finalPathComponent.index;
      if (arrayParamValue.length < index) {
        throw Error("Index out of range when adding new parameter value to array!");
      } else if (arrayParamValue.length > index) {
        arrayParamValue[index] = newParam;
      } else {
        arrayParamValue.push(newParam);
      }
      break;
    case "object":
      const objectParamValue = (paramPointer as BlueprintParameterValueCustomObject).object_value;
      objectParamValue[finalPathComponent.key] = newParam;
      break;
  }

  return clonedRootParam;
};

export const parameterKeyForJSONPath = (path: JSONPath) => {
  return path
    .map((component) => {
      return component.type === "array" ? `${component.index}` : component.key;
    })
    .join(".");
};

/**
 *
 * @param step The current blueprint step, used to fetch to correct root parameter value
 * @param rootParameterKey The key for the root parameter value that we want to retrieve
 * @param path The path from the root parameter value to the nested array parameter value we are retrieving
 * @returns The blueprint parameter value at `path` if it exists, otherwise null
 */
export const getCurrentStepCustomJSONParameterValue = (
  step: BlueprintStep,
  rootParameterKey: string | undefined,
  path: JSONPath
): null | BlueprintParameterValue => {
  if (!rootParameterKey) {
    return null;
  }
  const rootParameterValue = step.parameter_values[rootParameterKey];
  if (!rootParameterValue) {
    return null;
  }
  let paramPointer: BlueprintParameterValue = rootParameterValue;
  for (const pathComponent of path) {
    if (!paramPointer) {
      return null;
    }
    switch (pathComponent.type) {
      case "array":
        const arrayParam = paramPointer as BlueprintParameterValueCustomArray;
        if (!arrayParam.array_values) {
          return null;
        }
        if (arrayParam.array_values.length <= pathComponent.index) {
          return null;
        }
        paramPointer = arrayParam.array_values[pathComponent.index];
        break;
      case "object":
        const objectParam = paramPointer as BlueprintParameterValueCustomObject;
        if (!objectParam.object_value) {
          return null;
        }
        if (!(pathComponent.key in objectParam.object_value)) {
          return null;
        }
        paramPointer = objectParam.object_value[pathComponent.key];
    }
  }
  return paramPointer;
};

/**
 * When deleting an item from a custom array via the UI, we need to fetch the array nested
 * in the root parameter value, remove the item at the desired index, and then update the root
 * parameter value to reflect the new state.
 *
 * @param step The current blueprint step, used to fetch to correct root parameter value
 * @param rootParameterKey The key for the root parameter value that we want to update
 * @param path The path from the root parameter value to the nested array parameter value we are deleting from
 * @param index The index of the item we want to remove from the array
 * @returns An updated version of the parameter value for `rootValueKey` with the item at `index` in
 *          the nested array parameter value removed.
 */
export const deleteItemFromCustomArrayParameterValue = (
  step: BlueprintStep,
  rootParameterKey: string,
  path: JSONPath,
  index: number
) => {
  const parameterValue = getCurrentStepCustomJSONParameterValue(step, rootParameterKey, path);
  if (!parameterValue || parameterValue.value_type !== BlueprintParameterValueType.customArray) {
    return;
  }
  const clonedParameterValue = JSON.parse(JSON.stringify(parameterValue));
  const arrayParameterValue = clonedParameterValue as BlueprintParameterValueCustomArray;
  const arrayValues = arrayParameterValue.array_values;
  arrayValues.splice(index, 1);
  return clonedParameterValue;
};

/**
 * When setting a parameter value that exists inside of a custom JSON object parameter value,
 * we need to first lookup that parameter value via some key path and then replace the
 * corresponding nested parameter value and update the root custom JSON parameter value.
 * This function will create a copy of that root parameter value with the nested value
 * updated, which we can use to update `step`'s parameter_values dictionary.
 *
 * @param step The current blueprint step, used to fetch to correct root parameter value
 * @param rootParameterKey The key for the root parameter value that we want to update
 * @param path The path from the root parameter value to the nested parameter value we are replacing
 * @param newParameterValue The new parameter value to replace the nested parameter at `path`
 * @returns An updated version of the parameter value for `rootValueKey` with the nested parameter value
 *          at `path` replaced with `newParameterValue`.
 */
export const updateJSONNestedParameterValue = (
  step: BlueprintStep,
  rootParameterKey: string,
  path: JSONPath,
  newParameterValue: BlueprintParameterValue
) => {
  return fillComponentPath(step.parameter_values[rootParameterKey], path, newParameterValue);
};

export const isParameterValueCustomJSON = (param: BlueprintParameterValue | null) => {
  if (!param) {
    return false;
  }
  return (
    param.value_type === BlueprintParameterValueType.customArray ||
    param.value_type === BlueprintParameterValueType.customObject
  );
};

export const customJSONHumanReadableName = (param: BlueprintParameterValue | null) => {
  if (!param || !isParameterValueCustomJSON(param)) {
    return "any";
  }
  return param.value_type === BlueprintParameterValueType.customArray ? "array" : "object";
};

export const schemaSupportsNestedEntries = (schema: any) => {
  return schema.type && (schema.type === "object" || schema.type === "array");
};
