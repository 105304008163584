import { Card } from "@merge-api/merge-javascript-shared";
import { useHistory } from "react-router-dom";
import { BlueprintSearchResultBlueprint } from "../../../../models/blueprint-search/BlueprintSearchOutputModels";
import { navigateToBlueprintEditor } from "../../../../router/RouterUtils";
import BlueprintSearchCardStatusBadges from "./blueprint-search-card-status-badges/BlueprintSearchCardStatusBadges";
import BlueprintSearchCardTitle from "./blueprint-search-card-title/BlueprintSearchCardTitle";
import BlueprintSearchCardEndpointsAndCommonModels from "./blueprint-search-card-endpoints-and-common-models/BlueprintSearchCardEndpointsAndCommonModels";
import { ChevronRight } from "lucide-react";
import BlueprintSearchCardMatchedSteps from "./blueprint-search-card-matched-steps/BlueprintSearchCardMatchedSteps";
import BlueprintSearchCardArchiveButton from "./blueprint-search-card-buttons/BlueprintSearchCardArchiveButton";
import BlueprintSearchCardMetaBlueprintButton from "./blueprint-search-card-buttons/BlueprintSearchCardMetaBlueprintButton";

interface Props {
  blueprintResult: BlueprintSearchResultBlueprint;
  integrationID: string;
  setIsArchiveModalShown: (isArchiveModalShown: boolean) => void;
  setSelectedBlueprint: (selectedBlueprintSearchResult: BlueprintSearchResultBlueprint) => void;
}

const BlueprintsSearchTableCard = ({
  blueprintResult,
  integrationID,
  setIsArchiveModalShown,
  setSelectedBlueprint,
}: Props) => {
  const history = useHistory();

  return (
    <Card
      className="w-full px-6 py-5 cursor-pointer hover:bg-slate-0"
      type="button"
      onClick={(event) => {
        navigateToBlueprintEditor(
          history,
          integrationID,
          blueprintResult.latest_blueprint_version.id,
          event.metaKey || event.ctrlKey
        );
      }}
    >
      <div className="flex flex-col w-full space-y-4">
        <div className="flex flex-row w-full justify-between items-center">
          <div className="flex flex-col items-start justify-start text-start space-y-4">
            <BlueprintSearchCardStatusBadges blueprintResult={blueprintResult} />
            <BlueprintSearchCardTitle blueprintResult={blueprintResult} />
            <BlueprintSearchCardEndpointsAndCommonModels blueprintResult={blueprintResult} />
          </div>
          <div className="flex flex-row items-center space-x-6">
            <BlueprintSearchCardMetaBlueprintButton
              blueprintResult={blueprintResult}
              integrationID={integrationID}
            />
            <BlueprintSearchCardArchiveButton
              blueprintResult={blueprintResult}
              setIsArchiveModalShown={setIsArchiveModalShown}
              setSelectedBlueprint={setSelectedBlueprint}
            />
            <ChevronRight size={16} className="text-gray-70" />
          </div>
        </div>
        <BlueprintSearchCardMatchedSteps
          blueprintResult={blueprintResult}
          integrationID={integrationID}
        />
      </div>
    </Card>
  );
};

export default BlueprintsSearchTableCard;
