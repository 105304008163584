import { Row } from "../../../shared/postman-table/PostmanTableRow";
import PostmanTableHeader from "../shared/PostmanTableHeader";
import HeadersHelperAccordion from "./HeadersHelperAccordion";

interface AdditionalHeaderFieldsProps {
  additionalHeaderFields: Row[];
  setAdditionalHeaderFields: React.Dispatch<React.SetStateAction<Row[]>>;
  className?: string;
}

const AdditionalHeaderFields = ({
  additionalHeaderFields,
  setAdditionalHeaderFields,
  className,
}: AdditionalHeaderFieldsProps) => {
  return (
    <>
      <PostmanTableHeader
        dataTestID="field-auth-config-additional-header-fields"
        className={className ?? ""}
        title="Additional header fields"
        subtitle="If an integration requires additional header fields for every endpoint, enter them below"
        rows={additionalHeaderFields}
        setRows={setAdditionalHeaderFields}
      />
      <HeadersHelperAccordion />
    </>
  );
};
export default AdditionalHeaderFields;
