import { fetchWithAuth } from "../../../api-client/api_client";
import { showErrorToast, showSuccessToast } from "../../shared/Toasts";

interface RequestLinkedAccountAccessProps {
  setShowRequestModal: (show: boolean) => void;
  setIsRequesting: (isRequesting: boolean) => void;
  fetchLinkedAccountAccessRequest: () => void;
}

const useRequestLinkedAccountAccess = ({
  setShowRequestModal,
  setIsRequesting,
  fetchLinkedAccountAccessRequest,
}: RequestLinkedAccountAccessProps) => {
  const requestLinkedAccountAccess = (
    linkedAccountId: string,
    description: string,
    ticketUrl: string
  ) => {
    fetchWithAuth({
      path: `/integrations/linked-accounts/access-request`,
      method: "POST",
      body: {
        linked_account_id: linkedAccountId,
        description,
        ticket_url: ticketUrl,
      },
      onResponse: () => {
        setShowRequestModal(false);
        setIsRequesting(false);
        fetchLinkedAccountAccessRequest();
        showSuccessToast("Successfully requested customer Linked Account access");
      },
      onError: (error) => {
        setIsRequesting(false);
        error
          ?.json()
          .then((value: string | Record<string, any>) => {
            let errorMessage = "";
            if (typeof value == "string") {
              errorMessage = value;
            } else {
              errorMessage = value?.non_field_errors ?? JSON.stringify(value);
            }
            showErrorToast(
              `Failed to request customer Linked Account access. Error message: \n ${errorMessage}`
            );
          })
          .catch(() => {
            showErrorToast("Failed to request customer Linked Account access");
          });
      },
    });
  };

  return { requestLinkedAccountAccess };
};

export default useRequestLinkedAccountAccess;
