import SpinnerButton from "../../shared/SpinnerButton";
import { useState } from "react";
import { useMergeLink } from "@mergeapi/react-merge-link";
import { AuthConfigUpdateProps, callCreateTestAuthConfig } from "./AuthConfigShared";
import DeprecatedH2 from "../../deprecated/DeprecatedH2";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";

interface Props {
  authConfigID: string;
  linkToken: string;
  category: string;
  authConfigUpdateProps: AuthConfigUpdateProps;
  onMergeLinkExit: (testAuthConfigID: string, category: string) => void;
}

const AuthConfigMergeLinkButton = ({
  authConfigID,
  linkToken,
  category,
  authConfigUpdateProps,
  onMergeLinkExit,
}: Props) => {
  const [testAuthConfigID, setTestAuthConfigID] = useState<undefined | string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { open, isReady } = useMergeLink({
    linkToken,
    onSuccess: () => {},
    onExit: () => {
      onMergeLinkExit(testAuthConfigID ?? "", category);
    },
  });

  const connectWithMergeLink = () => {
    // Call the endpoint to create a test auth config with the existing values
    setIsLoading(true);
    callCreateTestAuthConfig(
      authConfigID,
      authConfigUpdateProps,
      linkToken,
      (data: any) => {
        setTestAuthConfigID(data);
        open();
      },
      () => {}
    );
  };

  return (
    <div>
      <DeprecatedH2>Test Authentication Config</DeprecatedH2>
      <div className="form-group mb-5">
        <DeprecatedH4 className="mb-0 text-muted">
          Note: Right now this test will only work for authentication configurations that have
          Integration setup step paths set up for Merge Link (via admin).
        </DeprecatedH4>
      </div>
      <SpinnerButton
        text="Launch Merge Link"
        isLoading={isLoading}
        className="btn-block btn-primary"
        onClick={() => connectWithMergeLink()}
        disabled={!isReady}
      />
    </div>
  );
};

export default AuthConfigMergeLinkButton;
