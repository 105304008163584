import { useState } from "react";
import { duplicateEndpoint } from "../../../integrations/utils/IntegrationsAPIClient";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import useLoadAPIEndpoints from "./useLoadAPIEndpoints";
import { APIEndpointIntegrationBuilder } from "../../utils/Entities";

interface Props {
  integrationID: string;
  apiEndpointID?: string | null;
  setAPIEndpoints: React.Dispatch<
    React.SetStateAction<APIEndpointIntegrationBuilder[] | undefined>
  >;
}

const useDuplicateAPIEndpoint = ({ integrationID, apiEndpointID, setAPIEndpoints }: Props) => {
  const [isLoadingDuplicateAPIEndpoint, setIsLoadingDuplicateAPIEndpoint] = useState<boolean>(
    false
  );

  const { loadAPIEndpoints } = useLoadAPIEndpoints({
    integrationID: integrationID,
    setAPIEndpoints: setAPIEndpoints,
  });

  const duplicateAPIEndpoint = () => {
    if (!apiEndpointID) {
      showErrorToast("No API endpoint ID provided.");
      return;
    }
    setIsLoadingDuplicateAPIEndpoint(true);
    duplicateEndpoint({
      apiEndpointID: apiEndpointID,
      integrationID,
      onSuccess: () => {
        loadAPIEndpoints();
        showSuccessToast("API endpoint successfully duplicated!");
        setIsLoadingDuplicateAPIEndpoint(false);
      },
      onError: () => {
        showErrorToast("Duplicating failed. API endpoint with duplicate name already exists.");
        setIsLoadingDuplicateAPIEndpoint(false);
      },
    });
  };

  return { isLoadingDuplicateAPIEndpoint, duplicateAPIEndpoint };
};

export default useDuplicateAPIEndpoint;
