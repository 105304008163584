import { getIntegrationBuilderPaginationConfigurationsForId } from "../../../../router/RouterUtils";
import IntegrationBuilderHeader from "../../shared/IntegrationBuilderHeader";

interface PaginationConfigurationHeaderProps {
  integrationID: string;
  isNewPaginationConfiguration: boolean;
}

const PaginationConfigurationHeader = ({
  integrationID,
  isNewPaginationConfiguration,
}: PaginationConfigurationHeaderProps) => {
  return (
    <div>
      <IntegrationBuilderHeader
        title={
          isNewPaginationConfiguration
            ? "Add pagination configuration"
            : "Edit pagination configuration"
        }
        navButtonLink={getIntegrationBuilderPaginationConfigurationsForId(integrationID)}
        navButtonText="Pagination configurations"
        showRequired
      />
    </div>
  );
};

export default PaginationConfigurationHeader;
