import { Spinner, Text } from "@merge-api/merge-javascript-shared";
import clsx from "clsx";

interface Props {
  text: string;
  className?: string;
}

const LoadingText = ({ text, className }: Props) => {
  return (
    <div className={clsx("flex flex-row space-x-2 items-center justify-start", className)}>
      <Spinner size="md" />
      <Text variant="h6" className="text-gray-70">
        {text}
      </Text>
    </div>
  );
};

export default LoadingText;
