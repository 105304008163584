import KeyPathFieldHeader from "../../../shared/KeyPathFieldHeader";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import BinaryChoiceButtons from "../shared/BinaryChoiceButtons";
import LineIndent from "../shared/LineIndent";

interface NonOAuthResponseCard {
  basicAuthKeyTokenExchangeResponseAuthKeyPath: string[] | null;
  setBasicAuthKeyTokenExchangeResponseAuthKeyPath: React.Dispatch<
    React.SetStateAction<string[] | null>
  >;
  isTokenLifespanInResponse: boolean | undefined;
  setIsTokenLifespanInResponse: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  basicAuthKeyTokenExchangeAccessTokenLifespanInSeconds: number | undefined;
  setBasicAuthKeyTokenExchangeAccessTokenLifespanInSeconds: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  basicAuthKeyTokenAccessTokenLifespanKeyPath: string[] | null;
  setBasicAuthKeyTokenAccessTokenLifespanKeyPath: React.Dispatch<
    React.SetStateAction<string[] | null>
  >;
}

const BasicAuthWithTokenExchangeResponseCard = ({
  basicAuthKeyTokenExchangeResponseAuthKeyPath,
  setBasicAuthKeyTokenExchangeResponseAuthKeyPath,
  isTokenLifespanInResponse,
  setIsTokenLifespanInResponse,
  basicAuthKeyTokenExchangeAccessTokenLifespanInSeconds,
  setBasicAuthKeyTokenExchangeAccessTokenLifespanInSeconds,
  basicAuthKeyTokenAccessTokenLifespanKeyPath,
  setBasicAuthKeyTokenAccessTokenLifespanKeyPath,
}: NonOAuthResponseCard) => {
  return (
    <div className="mt-6">
      <KeyPathFieldHeader
        dataTestID="field-auth-config-token-exchange-response-key-path"
        title="Token exchange response key path"
        subtitle="Enter the keys creating a path to the auth key in the response after a token exchange. Type the name of the key below and press Enter."
        placeholder="Add key name..."
        learnMoreText="For the majority of integrations that require this, the key is simply 'access_token'. For nested paths, such as 'results' > 'access_token', you would type 'results', press Enter, and type `access_token` and press Enter."
        keyPath={basicAuthKeyTokenExchangeResponseAuthKeyPath}
        onKeyPathChange={setBasicAuthKeyTokenExchangeResponseAuthKeyPath}
        hasSource={false}
      />
      <BinaryChoiceButtons
        dataTestID="field-auth-config-token-exchange-response-is-token-lifespan-in-response"
        className="mt-6"
        title="Is token lifespan in the response?"
        binaryChoice={isTokenLifespanInResponse}
        setBinaryChoice={setIsTokenLifespanInResponse}
      />
      {isTokenLifespanInResponse !== undefined && (
        <LineIndent className="mt-6">
          {isTokenLifespanInResponse === true && (
            <KeyPathFieldHeader
              dataTestID="field-auth-config-token-exchange-response-token-lifespan-key-path"
              title="Token lifespan key path"
              subtitle="Enter the keys creating a path to the auth key lifespan in the response after a token exchange. Type the name of the key below and press Enter."
              placeholder="Add key name..."
              required
              learnMoreText="For nested paths, such as 'key_name' > 'expires_value', you would type 'key_name', press Enter, and type `expires_value` and press Enter."
              keyPath={basicAuthKeyTokenAccessTokenLifespanKeyPath}
              onKeyPathChange={setBasicAuthKeyTokenAccessTokenLifespanKeyPath}
              hasSource={false}
            />
          )}
          {isTokenLifespanInResponse === false && (
            <TextFieldHeader
              dataTestID="field-auth-config-token-exchange-response-token-lifespan"
              title="Token lifespan"
              subtitle="Enter token lifespan in seconds"
              placeholder="123"
              required
              type="number"
              value={basicAuthKeyTokenExchangeAccessTokenLifespanInSeconds}
              onChange={(newEvent) => {
                const value = newEvent.target.value;
                if (value === "") {
                  setBasicAuthKeyTokenExchangeAccessTokenLifespanInSeconds(undefined);
                } else {
                  const numericValue = parseInt(value, 10);
                  if (!isNaN(numericValue)) {
                    setBasicAuthKeyTokenExchangeAccessTokenLifespanInSeconds(numericValue);
                  }
                }
              }}
            />
          )}
        </LineIndent>
      )}
    </div>
  );
};

export default BasicAuthWithTokenExchangeResponseCard;
