import React from "react";

interface DeprecatedH3Props {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const DeprecatedH3: React.FC<DeprecatedH3Props> = ({ className, children, style }) => {
  return (
    <div className={`deprecatedh3 ${className}`} style={style}>
      {children}
    </div>
  );
};

export default DeprecatedH3;
