import { singular } from "pluralize";

// HELPER FUNCTION SECTION FOR CORE MAPPING TEST ROOT FUNCTIONS

import { MappingTestBlock } from "../../../models/MappingTests";

// We want to take the most recently established relation across multiple mapping test blocks and assert those.
// All additional relations not in the last mapping test block but declared in earlier blocks are added to the schema
export const buildMappingTestBlockSchema = (
  mappingTestBlocks: MappingTestBlock[],
  prioritizedBlockIndex?: number
): Record<string, string[]> => {
  if (prioritizedBlockIndex === undefined) {
    return mappingTestBlocks.reduce((acc, block, index, array) => {
      const isLastBlock = index === array.length - 1;

      Object.entries(block.common_model_mappings).forEach(([modelID, mappings]) => {
        const modelName = modelID.split(".")[1];
        const individualMappings = Object.keys(mappings.individual_mappings);

        if (!acc[modelName]) {
          acc[modelName] = [];
        }

        individualMappings.forEach((identifier) => {
          if (isLastBlock || !acc[modelName].includes(identifier)) {
            if (!isLastBlock && acc[modelName].includes(identifier)) {
              const index = acc[modelName].indexOf(identifier);
              if (index > -1) {
                acc[modelName].splice(index, 1);
              }
            }
            acc[modelName].push(identifier);
          }
        });
      });

      return acc;
    }, {} as Record<string, string[]>);
  } else {
    const prioritizedBlock = mappingTestBlocks[prioritizedBlockIndex];
    const acc: Record<string, string[]> = {};

    Object.entries(prioritizedBlock?.common_model_mappings).forEach(([modelID, mappings]) => {
      const modelName = modelID.split(".")[1];
      const individualMappings = Object.keys(mappings.individual_mappings);

      if (!acc[modelName]) {
        acc[modelName] = [];
      }

      individualMappings.forEach((identifier) => {
        acc[modelName].push(identifier);
      });
    });

    return acc;
  }
};

// Transform from model to field name and vice versa

export function fieldToModel(fieldName: string): string {
  return singular(fieldName)
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
}

// Main function to check if a field inside of nested write is the parent - this is to avoid having the user have the option add Applications whilst doing POST Applications with a nested candidate
export function isParentedNestedWrite(child_field: string, parent_common_model: string): boolean {
  return fieldToModel(child_field) === parent_common_model;
}

// Function to determine whether to display suboject schema as input or dropdown for user input
type InputValue = string | string[] | object | object[] | null | undefined | boolean;

export function shouldDisplayManualInputSubObject(fieldType: string, value: InputValue): boolean {
  // if its any of these values, we'll default to showing a dropdown for relational values
  if (value === null || value === undefined || typeof value === "boolean") {
    return false;
  }

  if (fieldType === "object") {
    // If the value is a string, return false (should not display manual input because the value is a string templated unique identifier)
    if (typeof value === "string") {
      return false;
    }
    // if the value is an object, and it's not empty, return true to show the user the parameter schema input for subojects
    if (typeof value === "object" && value != null) {
      return true;
    }
  } else if (Array.isArray(value)) {
    // If the value is an array with at least one elemnt
    if (value.length >= 1) {
      // if that element is a string, then return false because it's a relational field
      if (typeof value[0] === "string") {
        return false;
      }
      // if it's an object that isn't empty, return true because the user should be able to input the parameter schema values of the suboject
      if (typeof value[0] === "object" && value[0] != null) {
        return true;
      }
    }
  }
  // show relational field dropdown by default - minimizes user input error when constructing their own objects and payloads
  return false;
}
