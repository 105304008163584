import { useContext, useEffect, useState } from "react";
import {
  PaginationType,
  PaginationValueRequestLocation,
} from "../../../../models/PaginationConfigurationModels";
import AccordionCard from "../../shared/AccordionCard";
import KeyPathFieldHeader from "../../shared/KeyPathFieldHeader";
import MultiSelectHeader from "../../shared/MultiSelectHeader";
import TextFieldHeader from "../../shared/TextFieldHeader";
import { StringOptionsData } from "../../utils/types";
import {
  KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER,
  KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER,
  PAGINATION_VALUE_REQUEST_LOCATION_OPTIONS,
  POINTER_PAGINATION_VALUE_REQUEST_LOCATION_OPTIONS,
} from "../constants";
import PaginationConfigurationContext from "../context/PaginationConfigurationContext";
import RequestPageSizeInformationSetupOptions from "./RequestPageSizeInformationSetupOptions";

interface RequestInformationSetupOptionsProps {
  cardVariant?: "outline" | "shadow" | "none";
}

const RequestInformationSetupOptions = ({ cardVariant }: RequestInformationSetupOptionsProps) => {
  const {
    isSessionPagination,
    paginationType,
    paginationValueRequestLocation,
    setPaginationValueRequestLocation,
    initialPaginationValue,
    setInitialPaginationValue,
    paginationValueRequestLocationKeyPath,
    setPaginationValueRequestLocationKeyPath,
  } = useContext(PaginationConfigurationContext);

  const [paginationValueKey, setPaginationValueKey] = useState<string>(
    paginationValueRequestLocationKeyPath?.length ? paginationValueRequestLocationKeyPath[0] : ""
  );
  const [initialOffsetValueText, setInitialOffsetValueText] = useState<string>(
    initialPaginationValue?.toString() || ""
  );

  // when changing the location of the pagination value in the request, clear the key path
  useEffect(() => {
    if (
      paginationValueRequestLocation === PaginationValueRequestLocation.QUERY_PARAM ||
      paginationValueRequestLocation === PaginationValueRequestLocation.PATH_PARAM
    ) {
      if (paginationValueKey) {
        setPaginationValueRequestLocationKeyPath([paginationValueKey]);
      } else {
        setPaginationValueRequestLocationKeyPath([]);
      }
    }
  }, [
    paginationValueKey,
    setPaginationValueRequestLocationKeyPath,
    paginationValueRequestLocation,
  ]);

  const [locationOptions, setLocationOptions] = useState<StringOptionsData[]>([]);
  const [isLocationModified, setIsLocationModified] = useState<boolean>(false);

  // The options for the location of the pagination value in the request are different depending on the pagination type.
  useEffect(() => {
    const options =
      paginationType === PaginationType.POINTER
        ? POINTER_PAGINATION_VALUE_REQUEST_LOCATION_OPTIONS
        : PAGINATION_VALUE_REQUEST_LOCATION_OPTIONS;
    setLocationOptions(
      options.map(({ value, text }) => ({
        value,
        text,
        selected: value === paginationValueRequestLocation,
        onClick: () => {
          setIsLocationModified(true);
          setPaginationValueKey("");
          setPaginationValueRequestLocation(value);
        },
      }))
    );
    if (
      paginationType !== PaginationType.POINTER &&
      paginationValueRequestLocation === PaginationValueRequestLocation.PATH
    ) {
      setPaginationValueRequestLocation("");
    }
  }, [
    paginationType,
    paginationValueRequestLocation,
    setIsLocationModified,
    setPaginationValueRequestLocation,
  ]);

  // Clear the key path when the location is changed but not on initial render
  useEffect(() => {
    if (isLocationModified) {
      setPaginationValueRequestLocationKeyPath([]);
    }
  }, [paginationValueRequestLocation]);

  // when the initial offset value text changes, parse as int or set pagination value to 0 if empty
  useEffect(() => {
    if (initialOffsetValueText === "") {
      setInitialPaginationValue(0);
    } else {
      setInitialPaginationValue(parseInt(initialOffsetValueText));
    }
  }, [initialOffsetValueText]);

  const shouldDisplayInitialOffsetValue =
    paginationType === PaginationType.OFFSET || paginationType === PaginationType.PAGES;
  const shouldDisplayPageSizeInformation = paginationType === PaginationType.OFFSET;
  const shouldDisplayKeyPath =
    paginationValueRequestLocation === PaginationValueRequestLocation.BODY_PARAM;
  const shouldDisplayKey =
    paginationValueRequestLocation === PaginationValueRequestLocation.QUERY_PARAM ||
    paginationValueRequestLocation === PaginationValueRequestLocation.PATH_PARAM;

  const getPaginationValueKeySubtitle = () => {
    let paramType = "param";
    switch (paginationValueRequestLocation) {
      case PaginationValueRequestLocation.QUERY_PARAM:
        paramType = "query param";
        break;
      case PaginationValueRequestLocation.PATH_PARAM:
        paramType = "path param";
        break;
      default:
        break;
    }
    return `The ${paramType} that stores the ${paginationType.toLowerCase()} value.`;
  };

  return (
    <AccordionCard title="Request information" defaultExpanded={true} variant={cardVariant}>
      <MultiSelectHeader
        dataTestID="field-pagination-config-pagination-value-request-location"
        title="Location of pagination value in request"
        subtitle="Select the location of the pagination value in the request."
        options={locationOptions}
        required={!isSessionPagination}
      />
      {/** Specifying the key for the pagination value is done in the case of query params and path params */}
      {shouldDisplayKey ? (
        <div className="border-l-2 pl-8 mt-4 border-gray-10">
          <TextFieldHeader
            dataTestID="field-pagination-config-pagination-value-key"
            className="mt-6"
            title="Pagination value key"
            subtitle={getPaginationValueKeySubtitle()}
            value={paginationValueKey}
            onChange={(e) => setPaginationValueKey(e.target.value)}
            placeholder="Key"
            hasSource={false}
            required={!isSessionPagination}
          />
        </div>
      ) : null}

      {/** Specifying the key path for the pagination value is done in the case of body params */}
      {shouldDisplayKeyPath ? (
        <div className="border-l-2 pl-8 mt-4 border-gray-10">
          <KeyPathFieldHeader
            dataTestID="field-pagination-config-pagination-value-key-path"
            className="mt-6"
            title="Pagination value key path"
            subtitle='The key path to the offset value in the request body. For example, if the key path is key 1 > key 2 > key 3, you would type "key 1", press Enter, type "key 2", press Enter, type "key 3", and press Enter.'
            placeholder={
              paginationValueRequestLocationKeyPath?.length
                ? KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER
                : KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER
            }
            keyPath={paginationValueRequestLocationKeyPath}
            onKeyPathChange={setPaginationValueRequestLocationKeyPath}
            hasSource={false}
            required={!isSessionPagination}
          />
        </div>
      ) : null}
      {shouldDisplayInitialOffsetValue ? (
        <TextFieldHeader
          dataTestID="field-pagination-config-initial-offset-value"
          className="mt-6"
          title="Initial offset value"
          subtitle="If not specified, this should generally start at 0."
          placeholder="Value"
          hasSource={false}
          value={initialOffsetValueText}
          onChange={(e) => {
            // only allow characters 0-9 and empty string
            if (/^\d*$/.test(e.target.value)) {
              setInitialOffsetValueText(e.target.value);
            }
          }}
        />
      ) : null}
      <RequestPageSizeInformationSetupOptions required={shouldDisplayPageSizeInformation} />
    </AccordionCard>
  );
};

export default RequestInformationSetupOptions;
