import { useEffect, useState } from "react";
import { TypeaheadComparatorClusterOption } from "@merge-api/merge-javascript-shared/dist/designSystem/molecules/Typeahead/types/types";
import { Integration } from "../../../models/Entities";
import CreateBlueprintModal from "../blueprints/CreateBlueprintModal";
import BlueprintsSearchTableHeader from "./BlueprintsSearchHeader";
import BlueprintsSearchFilters from "./blueprints-search-filters/BlueprintsSearchFilters";
import BlueprintsSearchTableResults from "./BlueprintsSearchTableResults";
import useBlueprintSearchResults from "./hooks/useBlueprintSearchResults";
import useBlueprintSearchAvailableFilters from "./hooks/useBlueprintSearchAvailableFilters";
import { DEFAULT_TYPEAHEAD_COMPARATOR_CLUSTER_OPTIONS, SortTypeEnum } from "./utils/constants";
import ArchiveConfirmationModal from "../../../components/shared/ArchiveConfirmationModal";
import { BlueprintSearchResultBlueprint } from "../../../models/blueprint-search/BlueprintSearchOutputModels";
import useArchiveBlueprint from "./hooks/useArchiveBlueprint";

interface Props {
  integration: Integration;
}

const BlueprintsSearch = ({ integration }: Props) => {
  /* ---- STATES ---- */
  const [isCreateModalShown, setIsCreateModalShown] = useState<boolean>(false);
  const [isArchiveModalShown, setIsArchiveModalShown] = useState<boolean>(false);
  const [selectedBlueprint, setSelectedBlueprint] = useState<BlueprintSearchResultBlueprint>();
  const [selectedFilters, setSelectedFilters] = useState<TypeaheadComparatorClusterOption[]>(
    DEFAULT_TYPEAHEAD_COMPARATOR_CLUSTER_OPTIONS
  );
  const [sortType, setSortType] = useState<SortTypeEnum>(SortTypeEnum.ALPHA);

  /* ---- HOOKS & STATES ---- */
  // Load available filters for search bar
  const {
    availableFilters,
    initializeAvailableFilters,
    isLoadingAvailableFilters,
  } = useBlueprintSearchAvailableFilters();

  // Load results
  const {
    blueprintSearchResults,
    fetchBlueprintSearchResults,
    sortBlueprintSearchResults,
    isLoadingBlueprintSearchResults,
  } = useBlueprintSearchResults();

  // Load ability to archive blueprint
  const { isLoadingArchiveBlueprint, archiveBlueprint } = useArchiveBlueprint({
    onSuccess: () => {
      setIsArchiveModalShown(false);
      fetchBlueprintSearchResults(integration, selectedFilters, sortType);
    },
    onError: () => {
      setIsArchiveModalShown(false);
      fetchBlueprintSearchResults(integration, selectedFilters, sortType);
    },
  });

  /* ---- USE EFFECTS ---- */
  // Load initial available filter options (ie.: Func BP's, API endpoints, Categories)
  useEffect(() => {
    setSelectedFilters(DEFAULT_TYPEAHEAD_COMPARATOR_CLUSTER_OPTIONS);
    initializeAvailableFilters(integration);
  }, [integration]);

  // Load results when selected filters updated
  // Integration is also a dependency to ensure safe updates when switching from integration to integration
  useEffect(() => {
    fetchBlueprintSearchResults(integration, selectedFilters, sortType);
  }, [integration, selectedFilters]);

  // Sort results when sort type is updated
  useEffect(() => {
    sortBlueprintSearchResults(sortType);
  }, [sortType]);

  return (
    <>
      <div className="flex flex-col space-y-6 h-full pb-8">
        <BlueprintsSearchTableHeader onCreateBlueprint={() => setIsCreateModalShown(true)} />
        <BlueprintsSearchFilters
          isLoadingAvailableFilters={isLoadingAvailableFilters}
          availableFilters={availableFilters}
          selectedFilters={selectedFilters ?? []}
          setSelectedFilters={setSelectedFilters}
          sortType={sortType}
          setSortType={setSortType}
        />
        <BlueprintsSearchTableResults
          blueprintSearchResults={blueprintSearchResults}
          integrationID={integration.id}
          isLoading={isLoadingBlueprintSearchResults}
          setIsArchiveModalShown={setIsArchiveModalShown}
          setSelectedBlueprint={setSelectedBlueprint}
        />
      </div>
      <CreateBlueprintModal
        categories={integration.categories}
        integrationMeta={integration}
        isShown={isCreateModalShown}
        onHide={() => setIsCreateModalShown(false)}
      />
      <ArchiveConfirmationModal
        selectedObjectType="Blueprint"
        show={isArchiveModalShown}
        onHide={() => setIsArchiveModalShown(false)}
        isLoading={isLoadingArchiveBlueprint}
        onConfirm={() => {
          if (selectedBlueprint) {
            archiveBlueprint(selectedBlueprint.latest_blueprint_version.id);
          }
        }}
        objectName={selectedBlueprint ? selectedBlueprint.human_name || selectedBlueprint.name : ""}
      />
    </>
  );
};

export default BlueprintsSearch;
