import { Box, Wrench, Database, AlertTriangle, HardDrive } from "lucide-react";
import { BlueprintOperationType } from "../../../models/Blueprints";
import { BlueprintMeta, Integration } from "../../../models/Entities";
import {
  MappingTestBodyParameters,
  MappingTestBodyParameterSchema,
  MappingTestErrorInfo,
  MappingTestExistingCommonModels,
  MappingTestExpectedResult,
  MappingTestBlueprintsMetadata,
  MappingTestRequestMock,
  MappingTestBlock,
} from "../../../models/MappingTests";
import { NextComponentVersionState } from "../../integrations/versioned-components/types";
import IntegrationEditorLeftPanel from "../../shared/integration-editor-base/IntegrationEditorLeftPanel";
import useMappingTestContext from "../context/useMappingTestContext";
import ImportExecutionsContainer from "./import-blueprint-execution-requests/ImportExecutionsContainer";
import MappingTestConstructionErrors from "./MappingTestConstructionErrors";
import MappingTestV2EditorLeftPanelOverviewSubtab from "./MappingTestV2EditorLeftPanelOverviewSubtab";
import MappingTestV2RequestsContainer from "./requests/MappingTestV2RequestsContainer";
import MappingTestEditorLeftPanelUtilsSubtab from "./MappingTestEditorLeftPanelUtilsSubtab";

type Props = {
  leftPanelAvailableRelationLookupDict: { [commonModelID: string]: Array<string> };
  blueprints: BlueprintMeta[];
  bodyParameters: MappingTestBodyParameters;
  setBodyParameters: (bodyParameters: MappingTestBodyParameters) => void;
  bodyParameterSchema: MappingTestBodyParameterSchema | null;
  existingCommonModels: MappingTestExistingCommonModels | undefined;
  setExistingCommonModels: (existingCommonModels: MappingTestExistingCommonModels) => void;
  existingCommonModelName: string | undefined;
  setExistingCommonModelName: (existingCommonModelName: string) => void;
  integration: Integration;
  maxLoopIterations: undefined | number;
  setMaxLoopIterations: (val: number | undefined) => void;
  maxPageIterations: undefined | number;
  setMaxPageIterations: (val: number | undefined) => void;
  shouldMatchOnRequestHeaders: boolean;
  setShouldMatchOnRequestHeaders: (val: boolean) => void;
  navigateToFetchTest: undefined | (() => void);
  mappingTestID: string;
  linkedAccountEndUserName: string;
  linkedAccountID: string;
  expectedResult: MappingTestExpectedResult | undefined;
  isMappingTestInitialized: boolean;
  operationType: BlueprintOperationType;
  writtenOrUpdatedCommonModel: string | undefined | null;
  webhookReceiverEventTypeID: string | null;
  blueprintsMetadata: MappingTestBlueprintsMetadata;
  updateMappingTestBPMetadata: (blueprintID: string, newMetaData: any) => void;
  requests: MappingTestRequestMock[];
  saveMappingTest: () => void;
  addRequestMock: (requestMock: MappingTestRequestMock) => void;
  editRequestMock: (requestMock: MappingTestRequestMock) => void;
  pasteRequestMock: (requestMock: MappingTestRequestMock) => void;
  deleteRequestMock: (requestMock: MappingTestRequestMock) => void;
  constructionErrors: MappingTestErrorInfo;
  mappingTestState: NextComponentVersionState;
  mappingTestFreezeTime: undefined | string;
  setMappingTestFreezeTime: (val: string | undefined) => void;
  mappingTestBlocks: Array<MappingTestBlock>;
  isMappingTestNextVersionNull: boolean;
  isReadTestCollection: boolean;
  nestedWritesMapForMappingTestModelArray: string[];
  nestedWritesMap?: Record<string, string[]>;
  commonModelName?: string;
};
const MappingTestV2EditorLeftPanel = ({
  leftPanelAvailableRelationLookupDict,
  nestedWritesMapForMappingTestModelArray,
  blueprints,
  bodyParameters,
  setBodyParameters,
  bodyParameterSchema,
  existingCommonModels,
  setExistingCommonModels,
  existingCommonModelName,
  setExistingCommonModelName,
  integration,
  isMappingTestInitialized,
  navigateToFetchTest,
  mappingTestID,
  linkedAccountEndUserName,
  linkedAccountID,
  expectedResult,
  operationType,
  writtenOrUpdatedCommonModel,
  webhookReceiverEventTypeID,
  blueprintsMetadata,
  mappingTestState,
  updateMappingTestBPMetadata,
  addRequestMock,
  editRequestMock,
  pasteRequestMock,
  deleteRequestMock,
  saveMappingTest,
  requests,
  maxLoopIterations,
  setMaxLoopIterations,
  maxPageIterations,
  setMaxPageIterations,
  constructionErrors,
  mappingTestFreezeTime,
  setMappingTestFreezeTime,
  mappingTestBlocks,
  isMappingTestNextVersionNull,
  isReadTestCollection,
  commonModelName,
  shouldMatchOnRequestHeaders,
  setShouldMatchOnRequestHeaders,
}: Props) => {
  const { mappingTestName } = useMappingTestContext();
  const OverviewSubtab = (
    <MappingTestV2EditorLeftPanelOverviewSubtab
      nestedWritesMapForMappingTestModelArray={nestedWritesMapForMappingTestModelArray}
      isReadTestCollection={isReadTestCollection}
      leftPanelAvailableRelationLookupDict={leftPanelAvailableRelationLookupDict}
      blueprints={blueprints}
      integration={integration}
      linkedAccountEndUserName={linkedAccountEndUserName}
      navigateToFetchTest={navigateToFetchTest}
      linkedAccountID={linkedAccountID}
      expectedResult={expectedResult}
      isMappingTestInitialized={isMappingTestInitialized}
      bodyParameterSchema={bodyParameterSchema}
      bodyParameters={bodyParameters}
      setBodyParameters={setBodyParameters}
      existingCommonModels={existingCommonModels}
      setExistingCommonModels={setExistingCommonModels}
      existingCommonModelName={existingCommonModelName}
      setExistingCommonModelName={setExistingCommonModelName}
      operationType={operationType}
      writtenOrUpdatedCommonModel={writtenOrUpdatedCommonModel}
      webhookReceiverEventTypeID={webhookReceiverEventTypeID}
      blueprintsMetadata={blueprintsMetadata}
      updateMappingTestBPMetadata={updateMappingTestBPMetadata}
      mappingTestID={mappingTestID}
      maxLoopIterations={maxLoopIterations}
      setMaxLoopIterations={setMaxLoopIterations}
      maxPageIterations={maxPageIterations}
      setMaxPageIterations={setMaxPageIterations}
      mappingTestFreezeTime={mappingTestFreezeTime}
      setMappingTestFreezeTime={setMappingTestFreezeTime}
      commonModelName={commonModelName}
      shouldMatchOnRequestHeaders={shouldMatchOnRequestHeaders}
      setShouldMatchOnRequestHeaders={setShouldMatchOnRequestHeaders}
    />
  );

  const blueprintsMeta = blueprints.reduce((acc, item) => {
    //@ts-ignore
    acc[item.id] = item;
    return acc;
  }, {});

  const tabs = [
    {
      key: "overview",
      navbarText: "Overview",
      Icon: Box,
      content: OverviewSubtab,
    },
    {
      key: "requests",
      navbarText: "Requests",
      Icon: Database,
      content: (
        <MappingTestV2RequestsContainer
          editRequestMock={editRequestMock}
          addRequestMock={addRequestMock}
          pasteRequestMock={pasteRequestMock}
          deleteRequestMock={deleteRequestMock}
          requests={requests}
          mappingTestBlocks={mappingTestBlocks}
          saveMappingTest={saveMappingTest}
        />
      ),
    },
    {
      key: "utils",
      navbarText: "Utils",
      Icon: Wrench,
      content: <MappingTestEditorLeftPanelUtilsSubtab />,
    },
    {
      key: "errors",
      navbarText: "Errors",
      Icon: AlertTriangle,
      content: <MappingTestConstructionErrors constructionErrors={constructionErrors} />,
    },
    {
      key: "importData",
      navbarText: "Import Data",
      Icon: HardDrive,
      content: (
        <ImportExecutionsContainer
          isMappingTestNextVersionNull={isMappingTestNextVersionNull}
          mappingTestState={mappingTestState}
          blueprints={blueprintsMeta}
          addRequestMock={addRequestMock}
          saveMappingTest={saveMappingTest}
        />
      ),
    },
  ];

  return (
    <div className="h-full bg-white">
      <IntegrationEditorLeftPanel
        header={mappingTestName}
        subheader={integration.name}
        integration={integration}
        tabs={tabs}
      />
    </div>
  );
};

export default MappingTestV2EditorLeftPanel;
