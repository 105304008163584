import { Text } from "@merge-api/merge-javascript-shared";
import MergeCopyToClipboard from "../MergeCopyToClipboard";

interface Props {
  includeCopyToClipboard: boolean;
  textToCopy: string | undefined;
  title: string | undefined;
}

/**
 * MergeCodeBlockHeader component
 *
 * This component renders the header for a code block, which can include:
 * - A title (if provided)
 * - A copy to clipboard button (if includeCopyToClipboard is true)
 *
 * The component's appearance changes based on whether a title is provided:
 * - With title: Displays a full-width header with the title on the left and copy button on the right
 * - Without title: Only shows the copy button aligned to the right
 *
 * If neither title nor copy button are needed, the component renders null.
 */

const MergeCodeBlockHeader = ({ includeCopyToClipboard, textToCopy, title }: Props) => {
  if (title) {
    return (
      <div className="flex flex-row items-center justify-between bg-navy-50 text-gray-50 rounded-t-lg pl-3 py-2">
        <Text variant="h5">{title}</Text>
        {includeCopyToClipboard && (
          <div className="flex items-center text-gray-50">
            <MergeCopyToClipboard textToCopy={textToCopy} />
          </div>
        )}
      </div>
    );
  } else {
    if (includeCopyToClipboard) {
      return (
        <div className="align-items-center justify-content-end">
          <div className="flex items-center float-right text-gray-50">
            <MergeCopyToClipboard textToCopy={textToCopy} />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
};

export default MergeCodeBlockHeader;
