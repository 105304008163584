import Editor from "react-simple-code-editor";
import clsx from "clsx";
import { FocusEventHandler } from "react";
import ClickableText from "./ClickableText";
import HeaderBase from "./HeaderBase";
import { isValidJSON } from "../../../utils";
import useIntegrationBuilderContext from "../context/useIntegrationBuilderContext";

const { highlight, languages } = require("prismjs");

interface JSONSchemaFieldHeaderProps {
  title?: string;
  subtitle?: string;
  learnMoreText?: string;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  textFieldClassName?: string;
  prefix?: string;
  prefixVariant?: "inline" | "url" | undefined;
  postfix?: React.ReactNode | undefined;
  value?: string;
  setValue?: (value: string) => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  name?: string;
  ref?: React.Ref<HTMLInputElement>;
  error?: boolean;
  errorText?: string;
  type?: React.HTMLInputTypeAttribute;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  helpText?: string;
  required?: boolean;
  hasSource?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  autosize?: boolean;
  bottomLinkText?: string;
  bottomLinkOnClick?: () => void;
  inputWrapperClassName?: string;
  hasSampleJSONUpload?: boolean;
  dataTestID?: string;
}

const JSONSchemaFieldHeader = ({
  title,
  subtitle,
  learnMoreText,
  className,
  textFieldClassName,
  value,
  setValue,
  helpText,
  required,
  hasSource = false,
  hasSampleJSONUpload = false,
  disabled,
  bottomLinkText,
  bottomLinkOnClick,
  dataTestID,
}: JSONSchemaFieldHeaderProps) => {
  const { hasPermissionToEdit } = useIntegrationBuilderContext();
  const isValueValidJSON = value && isValidJSON(value);
  const editDisabled = !hasPermissionToEdit || disabled;

  return (
    <div className={clsx("w-full", className)}>
      <HeaderBase
        title={title}
        subtitle={subtitle}
        learnMoreText={learnMoreText}
        helpText={helpText}
        required={required}
        hasSource={hasSource}
        hasSampleJSONUpload={hasSampleJSONUpload}
        setValue={setValue}
        dataTestID={dataTestID}
      />
      <div className={textFieldClassName}>
        <Editor
          disabled={editDisabled}
          highlight={(code) => highlight(code, languages.js)}
          value={value ?? ""}
          onValueChange={(code) => setValue && setValue(code)}
          onBlur={() => {
            if (isValueValidJSON) {
              setValue && setValue(JSON.stringify(JSON.parse(value), null, 2));
            }
          }}
          padding={10}
          style={{
            backgroundColor: editDisabled ? "rgb(249 250 251)" : "white",
            border: !value || isValueValidJSON ? "1px solid #d2ddec" : "1px solid red",
            borderRadius: 8,
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
          }}
        />
      </div>
      {bottomLinkText && bottomLinkOnClick && (
        <ClickableText text={bottomLinkText} onClick={bottomLinkOnClick} className="mt-2" />
      )}
    </div>
  );
};

export default JSONSchemaFieldHeader;
