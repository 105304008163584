import { Alert, Badge, Spinner, Text } from "@merge-api/merge-javascript-shared";
import { X } from "lucide-react";
import {
  LinkedAccount,
  mapDisplayableLinkedAccountStatus,
  mapLinkedAccountStatusToBadgeColor,
} from "../../../models/Entities";
import { formatShorthandDate } from "../../integration-builder/utils/helpers";

interface LinkedAccountSearchResultsProps {
  integrationID: string;
  linkedAccount: LinkedAccount | null;
  isSearchingLinkedAccount: boolean;
  hasCheckedLinkedAccount: boolean;
}

const LinkedAccountSearchResults = ({
  integrationID,
  linkedAccount,
  isSearchingLinkedAccount,
  hasCheckedLinkedAccount,
}: LinkedAccountSearchResultsProps) => {
  if (!hasCheckedLinkedAccount && !isSearchingLinkedAccount) {
    return null;
  }

  if (isSearchingLinkedAccount) {
    return (
      <div className="bg-gray-0 rounded-lg p-3 flex flex-row space-x-2 items-center">
        <Spinner size="md" />
        <Text className="text-gray-70">Searching...</Text>
      </div>
    );
  }

  return (
    <>
      <div className="bg-gray-0 rounded-lg p-3">
        {linkedAccount ? (
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col">
              <Text className="text-gray-70">{`Created on ${formatShorthandDate(
                linkedAccount["created_at"]
              )}`}</Text>
              <Text>{linkedAccount["end_user"]["organization_name"]}</Text>
              <Text className="text-gray-70">{`ID: ${linkedAccount["id"]}`}</Text>
              <Text className="text-gray-70">{linkedAccount["organization"]["name"]}</Text>
            </div>
            <div className="flex flex-row space-x-2">
              {linkedAccount.status && (
                <Badge size="sm" color={mapLinkedAccountStatusToBadgeColor[linkedAccount.status]}>
                  {mapDisplayableLinkedAccountStatus[linkedAccount.status]}
                </Badge>
              )}
              {linkedAccount.is_sandbox_account && <Badge size="sm">Sandbox</Badge>}
            </div>
          </div>
        ) : (
          <div className="flex flex-row items-center space-x-2">
            <X size={16} className="text-red-70" />
            <Text className="text-gray-70">Linked Account not found</Text>
          </div>
        )}
      </div>
      {linkedAccount && linkedAccount?.integration.id !== integrationID && (
        <Alert showWarningIcon size="sm">
          This Linked Account is not associated with the current integration
        </Alert>
      )}
    </>
  );
};

export default LinkedAccountSearchResults;
