import { Drawer } from "@merge-api/merge-javascript-shared";
import {
  MappingTestAndVersions,
  MappingTestExecution,
} from "../../../../../../../../models/MappingTests";
import MappingTestExecutionModalBlockCard from "./MappingTestExecutionModalBlockCard/MappingTestExecutionModalBlockCard";
import MappingTestBlockExecutionStatusBadge from "../../../../../../../mapping-tests/shared/MappingTestBlockExecutionStatusBadge";

interface Props {
  open: boolean;
  onClose: () => void;
  mappingTestExecution: MappingTestExecution | null;
  mappingTest: MappingTestAndVersions | null;
}

const MappingTestExecutionModal = ({ open, onClose, mappingTestExecution, mappingTest }: Props) => {
  if (!mappingTestExecution) {
    return <></>;
  }

  if (!mappingTest) {
    return <></>;
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={mappingTest.name}
      titlePostfix={
        <MappingTestBlockExecutionStatusBadge testBlockExecution={mappingTestExecution} />
      }
      truncateLongTitle={true}
    >
      <div className="p-4">
        {mappingTestExecution.block_executions?.map((blockExecution, index) => {
          return (
            <MappingTestExecutionModalBlockCard
              blockNumber={index + 1}
              blockExecution={blockExecution}
            />
          );
        })}
      </div>
    </Drawer>
  );
};

export default MappingTestExecutionModal;
