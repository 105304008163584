import { Route, match } from "react-router-dom";

import BlueprintEditorLeftPanel from "./left-panel/BlueprintEditorLeftPanel";
import BlueprintEditorRightPanel from "./right-panel/BlueprintEditorRightPanel";
import BlueprintCanvas from "./canvas/BlueprintCanvas";

import { JSONObjectSchema, SchemaTypes } from "../../models/Blueprints";
import BlueprintEditorTopControlPanel from "./control-panel/BlueprintEditorTopControlPanel";
import styled from "styled-components";
import {
  BLUEPRINT_EDITOR_MAIN_TAB_PATH,
  BLUEPRINT_EDITOR_README_TAB_PATH,
  BLUEPRINT_EDITOR_VALIDATION_TAB_PATH,
  BLUEPRINT_EDITOR_RETURN_SCHEMA_TAB_PATH,
} from "../../router/RouterUtils";
import BlueprintValidationEditor from "./validator/BlueprintValidationEditor";
import BlueprintEditorReadmePanel from "./center-panel/BlueprintEditorReadmePanel";

type Props = {
  integrationID: string;
  match: match;
  parameterSchema: undefined | JSONObjectSchema;
  returnSchema: undefined | JSONObjectSchema;
  operationType: undefined | string;
  hasUnsavedChanges: boolean;
};

const BlueprintEditorMainPanelsContainer = styled.div`
  height: calc(100vh - 40px);
  flex-grow: 1;
  display: flex;
`;

const BlueprintEditorView = ({
  parameterSchema,
  returnSchema,
  operationType,
  hasUnsavedChanges,
}: Props) => {
  return (
    <div>
      <BlueprintEditorTopControlPanel hasUnsavedChanges={hasUnsavedChanges} />
      <BlueprintEditorMainPanelsContainer>
        <BlueprintEditorLeftPanel />
        <Route path={BLUEPRINT_EDITOR_VALIDATION_TAB_PATH}>
          {parameterSchema && (
            <BlueprintValidationEditor
              schema={parameterSchema}
              schemaType={SchemaTypes.parameter_schema}
              operationType={operationType}
            />
          )}
        </Route>
        <Route path={BLUEPRINT_EDITOR_RETURN_SCHEMA_TAB_PATH}>
          {returnSchema && (
            <BlueprintValidationEditor
              schema={returnSchema}
              schemaType={SchemaTypes.return_schema}
              operationType={operationType}
            />
          )}
        </Route>
        <Route path={BLUEPRINT_EDITOR_MAIN_TAB_PATH}>
          <BlueprintCanvas />
          <BlueprintEditorRightPanel />
        </Route>
        <Route path={BLUEPRINT_EDITOR_README_TAB_PATH}>
          <BlueprintEditorReadmePanel />
        </Route>
      </BlueprintEditorMainPanelsContainer>
    </div>
  );
};

export default BlueprintEditorView;
