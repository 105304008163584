import { useState } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import MappingTestEditorCommonModelExpectedMappingSectionHeader from "./MappingTestEditorCommonModelExpectedMappingSectionHeader";

const AddContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

type Props = {
  commonModelID: string;
  commonModelName: string;
  isIDAlreadyUsed: (individualMappingID: string) => boolean;
  onAdd: (individualMappingID: string) => void;
};

const MappingTestNewMappingRow = ({
  commonModelID,
  onAdd,
  isIDAlreadyUsed,
  commonModelName,
}: Props) => {
  const [hasStartedCreating, setHasStartedCreating] = useState(false);
  return hasStartedCreating ? (
    <MappingTestEditorCommonModelExpectedMappingSectionHeader
      key={commonModelID + "new"}
      commonModelName={commonModelName}
      isIDAlreadyUsed={isIDAlreadyUsed}
      isMissingInProducedModels={false}
      onAdd={(individualMappingID) => {
        onAdd(individualMappingID);
        setHasStartedCreating(false);
      }}
    />
  ) : (
    <AddContainer>
      <Button size="sm" variant="secondary" onClick={() => setHasStartedCreating(true)}>
        Add {commonModelName}
      </Button>
    </AddContainer>
  );
};

export default MappingTestNewMappingRow;
