import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import {
  APIEndpointPreviewIntegrationBuilder,
  AutogenerationRunNotificationStatus,
  AutogenerationRunStatus,
  AutogenerationRunType,
  IntegrationBuilderAutogenRun,
} from "../../utils/Entities";
import usePatchAutogeneratedModelRun from "../../hooks/usePatchAutogeneratedModelRun";
import useAutogenerateRateLimit from "../../rate-limits/hooks/useAutogenerateRateLimit";
import { useHistory } from "react-router-dom";
import {
  navigateToIntegrationBuilderAPIEndpointForm,
  navigateToIntegrationBuilderPaginationConfigurationForm,
  navigateToIntegrationBuilderRateLimitConfigurationForm,
} from "../../../../router/RouterUtils";
import useAutogeneratedAPIEndpoints from "../../api-endpoint-setup/hooks/useAutogenerateAPIEndpoints";

interface AutogeneratedConfigurationActionsProps {
  autogenRun: IntegrationBuilderAutogenRun;
  setAutogenRun: React.Dispatch<React.SetStateAction<IntegrationBuilderAutogenRun | null>>;
  apiEndpointPreview?: APIEndpointPreviewIntegrationBuilder;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  setGeneratingAPIEndpointPreviews?: React.Dispatch<
    React.SetStateAction<APIEndpointPreviewIntegrationBuilder[]>
  >;
}

const AutogeneratedConfigurationActions = ({
  autogenRun,
  setAutogenRun,
  apiEndpointPreview,
  setGeneratingAPIEndpointPreviews,
  setStatus,
}: AutogeneratedConfigurationActionsProps) => {
  const history = useHistory();

  const { patchAutogeneratedModelRun } = usePatchAutogeneratedModelRun({
    autogen_run_id: autogenRun.id,
  });
  const { autogenerateRateLimit } = useAutogenerateRateLimit({
    integrationID: autogenRun.integration_id,
  });
  const { autogenerateAPIEndpoints } = useAutogeneratedAPIEndpoints({
    integrationID: autogenRun.integration_id,
  });

  const viewModel = () => {
    if (autogenRun.run_type === AutogenerationRunType.RATE_LIMIT) {
      navigateToIntegrationBuilderRateLimitConfigurationForm(
        history,
        autogenRun.integration_id,
        autogenRun.model_id || ""
      );
    } else if (autogenRun.run_type === AutogenerationRunType.API_ENDPOINT) {
      navigateToIntegrationBuilderAPIEndpointForm(
        history,
        autogenRun.integration_id,
        autogenRun.model_id || "",
        true
      );
    } else if (autogenRun.run_type === AutogenerationRunType.PAGINATION_CONFIGURATION) {
      navigateToIntegrationBuilderPaginationConfigurationForm(
        history,
        autogenRun.integration_id,
        autogenRun.model_id || ""
      );
    }
  };

  const retryAutogeneration = () => {
    setStatus(AutogenerationRunStatus.PENDING);
    setAutogenRun(null);
    patchAutogeneratedModelRun(AutogenerationRunNotificationStatus.RETRIED);
    if (autogenRun.run_type === AutogenerationRunType.RATE_LIMIT) {
      autogenerateRateLimit();
    } else if (autogenRun.run_type === AutogenerationRunType.API_ENDPOINT) {
      autogenerateAPIEndpoints([apiEndpointPreview?.id || ""]);
    } else if (autogenRun.run_type === AutogenerationRunType.PAGINATION_CONFIGURATION) {
      // TODO: implement
    }
  };

  const onPrimaryButtonClick = () => {
    if (autogenRun.status === AutogenerationRunStatus.SUCCESS_MODEL_CREATED) {
      viewModel();
    } else {
      retryAutogeneration();
    }
  };

  const removeAPIEndpointPreviewFromGeneratingList = () => {
    if (apiEndpointPreview && setGeneratingAPIEndpointPreviews) {
      setGeneratingAPIEndpointPreviews((prev) =>
        prev?.filter((preview) => preview.id !== apiEndpointPreview.id)
      );
    }
  };

  const onSecondaryButtonClick = () => {
    removeAPIEndpointPreviewFromGeneratingList();
    patchAutogeneratedModelRun(AutogenerationRunNotificationStatus.DISMISSED);
  };

  return (
    <div className="flex flex-row items-center just space-x-3">
      <Button onClick={onSecondaryButtonClick} size="sm" variant={ButtonVariant.TextBlue}>
        Dismiss
      </Button>
      <Button onClick={onPrimaryButtonClick} size="sm">
        {autogenRun.status === AutogenerationRunStatus.SUCCESS_MODEL_CREATED ? "View" : "Try again"}
      </Button>
    </div>
  );
};

export default AutogeneratedConfigurationActions;
