import { MinusCircle } from "lucide-react";
import { BlueprintParameterValue, BlueprintParameterValueType } from "../../../models/Blueprints";
import ClickableContainer from "../../shared/ClickableContainer";
import FormField, { FormFieldCommonProps } from "./FormField";
import TypeaheadFormFieldTypeahead from "./TypeaheadFormFieldTypeahead";

interface Props extends FormFieldCommonProps {
  onChange: (option: null | BlueprintParameterValue) => void;
  currentParameterValue: null | BlueprintParameterValue;
  onDelete: () => void;
}

const CancellableTypeaheadFormField = ({
  currentParameterValue,
  onChange,
  subtitle,
  title,
  onDelete,
}: Props) => {
  return (
    <FormField
      subtitle={subtitle}
      title={title}
      valueType={"string"}
      titleChildren={
        <div className="d-flex align-items-center justify-content-between">
          <div className="ml-1.5">
            <ClickableContainer onClick={onDelete}>
              <MinusCircle className="red" strokeWidth={1.5} />
            </ClickableContainer>
          </div>
        </div>
      }
    >
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">
          <TypeaheadFormFieldTypeahead
            allowConstantValues
            currentParameterValue={currentParameterValue}
            onChange={(options) =>
              onChange(
                options[0]?.customOption
                  ? {
                      constant: options[0].labelKey,
                      value_type: BlueprintParameterValueType.constant,
                    }
                  : options[0]?.parameterValue
              )
            }
            parameterType={"any"}
          />
        </div>
      </div>
    </FormField>
  );
};

export default CancellableTypeaheadFormField;
