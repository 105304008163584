import React from "react";
import clsx from "clsx";
import MergeCodeBlockHeader from "./merge-code-block/MergeCodeBlockHeader";
import MergeCodeBlockTextWithSyntaxHighlight from "./merge-code-block/MergeCodeBlockTextWithSyntaxHighlight";
import MergeCodeBlockText from "./merge-code-block/MergeCodeBlockText";

interface Props {
  children?: string | null;
  title?: string;
  language?: string | null;
  showLineNumbers?: boolean | null;
  customStyle?: any;
  textToCopy?: string;
  shouldExcludeCopyOption?: boolean | null;
  shouldWrapLongLines?: boolean | null;
}

const MergeCodeBlock = ({
  children,
  title,
  language,
  showLineNumbers,
  customStyle,
  textToCopy,
  shouldExcludeCopyOption = false,
  shouldWrapLongLines = false,
}: Props) => {
  // react-syntax-highlighter freezes UI if text is too large
  const childrenLength = children?.length ?? 0;
  return (
    <div className={clsx("rounded-lg bg-navy-90", !title && "px-4 pt-4")}>
      <MergeCodeBlockHeader
        includeCopyToClipboard={!shouldExcludeCopyOption}
        textToCopy={textToCopy}
        title={title}
      />
      {childrenLength > 200000 ? (
        <MergeCodeBlockText children={children} />
      ) : (
        <MergeCodeBlockTextWithSyntaxHighlight
          language={language}
          showLineNumbers={showLineNumbers}
          customStyle={customStyle}
          shouldExcludeCopyOption={shouldExcludeCopyOption}
          shouldWrapLongLines={shouldWrapLongLines}
          children={children}
        />
      )}
    </div>
  );
};

export default React.memo(MergeCodeBlock);
