import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { APIEndpointMethod } from "../../../../models/Entities";
import { navigateToIntegrationBuilderAPIEndpointTable } from "../../../../router/RouterUtils";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import { APIEndpointIntegrationBuilder } from "../../utils/Entities";
import IntegrationBuilderContext from "../../context/IntegrationBuilderContext";

interface DeleteAPIEndpointProps {
  integrationID: string;
  setAPIEndpoints: React.Dispatch<
    React.SetStateAction<APIEndpointIntegrationBuilder[] | undefined>
  >;
  apiEndpointID?: string | null;
}

const useDeleteAPIEndpoint = ({
  integrationID,
  setAPIEndpoints,
  apiEndpointID,
}: DeleteAPIEndpointProps) => {
  // state
  const [isLoadingDeleteAPIEndpoint, setIsLoadingDeleteAPIEndpoint] = useState(false);
  // context
  const history = useHistory();
  const { setIsAPIEndpointsComplete } = useContext(IntegrationBuilderContext);
  // effects
  const deleteAPIEndpoint = () => {
    if (!apiEndpointID) {
      return;
    }
    setIsLoadingDeleteAPIEndpoint(true);
    fetchWithAuth({
      path: `/integrations/integration-builder/api-endpoint/${apiEndpointID}`,
      method: APIEndpointMethod.DELETE,
      onResponse: () => {
        showSuccessToast("API endpoint successfully deleted!");
        setIsLoadingDeleteAPIEndpoint(false);
        // navigate to api endpoints table page
        navigateToIntegrationBuilderAPIEndpointTable(history, integrationID);
        // replaces api endpoint in apiEndpoints array with new data
        setAPIEndpoints((prevAPIEndpoint) => {
          const updatedAPIEndpoints = prevAPIEndpoint?.filter(
            (endpoint) => endpoint.id !== apiEndpointID
          );
          if (updatedAPIEndpoints?.length === 0) {
            setIsAPIEndpointsComplete(false);
          }
          return updatedAPIEndpoints;
        });
      },
      onError: () => {
        showErrorToast("Failed to delete your API endpoint.");
        setIsLoadingDeleteAPIEndpoint(false);
      },
    });
  };

  return { deleteAPIEndpoint, isLoadingDeleteAPIEndpoint };
};

export default useDeleteAPIEndpoint;
