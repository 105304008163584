import { createRef, useEffect, useRef } from "react";
import { BPRStepIOLog } from "../../../../../models/Blueprints";
import useBlueprintContext from "../../../context/useBlueprintContext";
import StepIOLogsTableCard from "./StepIOLogsTableCard";

interface Props {
  stepIOLogs: BPRStepIOLog[];
}

/**
 * Renders table of step I/O logs
 * @param {BPRStepIOLog[]} stepIOLogs - An array of step I/O logs to be rendered
 */
const StepIOLogsTable = ({ stepIOLogs }: Props) => {
  const { setSelectedStepLog, selectedStepLog } = useBlueprintContext();

  // Initialize step refs, to enable auto-scrolling to a step
  const stepIOLogRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});
  const formStepIOLogRefKey = (stepID: string, index: number) => {
    return `${stepID}-${index}`;
  };

  // Set step refs on initial page load
  useEffect(() => {
    stepIOLogs.forEach((stepIOLog, index) => {
      const refKey = formStepIOLogRefKey(stepIOLog.step_id, index);
      stepIOLogRefs.current[refKey] = stepIOLogRefs.current[refKey] || createRef();
    });
  }, []);

  // Listen for changes in selectedStepLog, so that we can auto-scroll to it
  useEffect(() => {
    if (selectedStepLog) {
      const selectedLogRef =
        stepIOLogRefs?.current?.[
          formStepIOLogRefKey(selectedStepLog.step_id, selectedStepLog.index_in_raw_logs)
        ]?.current;
      // Slight delay, since Chrome browsers can't handle multiple smooth "scrollIntoView" at once
      if (!!selectedLogRef) {
        setTimeout(() => {
          selectedLogRef.scrollIntoView({ behavior: "smooth" });
        }, 800);
      }
    }
  }, [selectedStepLog]);

  return (
    <div className="flex flex-col space-y-2">
      {stepIOLogs.map((stepIOLog, index) => {
        const isSelected = selectedStepLog?.index_in_raw_logs === index;
        return (
          <div ref={stepIOLogRefs.current[formStepIOLogRefKey(stepIOLog.step_id, index)]}>
            <StepIOLogsTableCard
              index={index}
              stepIOLog={stepIOLog}
              setSelectedStepLog={setSelectedStepLog}
              isSelected={isSelected}
            />
          </div>
        );
      })}
    </div>
  );
};

export default StepIOLogsTable;
