import { useState, useCallback, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { Integration } from "../../../models/Entities";
import { fetchWithAuth } from "../../../api-client/api_client";
import AuthConfigOverview from "./AuthConfigOverview";
import { AuthConfigMeta } from "../../../models/AuthConfig";

type Props = {
  integrationMeta: Integration;
};

const AuthConfigTab = ({ integrationMeta }: Props) => {
  const [authConfigs, setAuthConfigs] = useState<AuthConfigMeta[] | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const integrationID = integrationMeta.id;

  const fetchAuthConfigs = useCallback(() => {
    setIsLoading(true);
    fetchWithAuth({
      path: `/integrations/${integrationID}/auth-configs`,
      method: "GET",
      onResponse: (data: AuthConfigMeta[]) => {
        setAuthConfigs(data);
        setIsLoading(false);
      },
    });
  }, [integrationID]);

  useEffect(() => {
    fetchAuthConfigs();
  }, [integrationID, fetchAuthConfigs]);

  return (
    <Switch>
      <Route
        path={"/integrations/:integrationID/auth-configs"}
        children={() => <AuthConfigOverview authConfigs={authConfigs} isLoading={isLoading} />}
      />
    </Switch>
  );
};

export default AuthConfigTab;
