import React from "react";
import { MappingTestAndVersions, MappingTestExecution } from "../../../models/MappingTests";

export interface PublishModuleMappingTestContextState {
  mappingTestExecution: MappingTestExecution | null;
  setMappingTestExecution: (testExecution: MappingTestExecution | null) => void;
  mappingTest: MappingTestAndVersions | null;
  setMappingTest: (mappingTest: MappingTestAndVersions | null) => void;
  isShowingMappingTestExecutionModal: boolean;
  setIsShowingMappingTestExecutionModal: (value: boolean) => void;
  integrationID: string | null;
}

const PublishModuleMappingTestContext = React.createContext<PublishModuleMappingTestContextState>({
  mappingTestExecution: null,
  setMappingTestExecution: () => {},
  mappingTest: null,
  setMappingTest: () => {},
  isShowingMappingTestExecutionModal: false,
  setIsShowingMappingTestExecutionModal: () => {},
  integrationID: null,
});

export default PublishModuleMappingTestContext;
