import clsx from "clsx";
import PostmanTable from "../../../shared/postman-table/PostmanTable";
import { Row } from "../../../shared/postman-table/PostmanTableRow";
import HeaderBase from "../../../shared/HeaderBase";

interface PostmanTableHeaderProps {
  rows: Row[];
  setRows: React.Dispatch<React.SetStateAction<Row[]>>;
  title: string;
  subtitle?: string;
  className?: string;
  keyHeader?: string;
  valueHeader?: string;
  isSelectRow?: boolean;
  selectOptions?: string[];
  hasSource?: boolean;
  keysDisabled?: boolean;
  valuesDisabled?: boolean;
  userInputDisabled?: boolean;
  isUserInput?: boolean;
  addRowDisabled?: boolean;
  required?: boolean;
  learnMoreText?: string;
  pushDefaultValueOnNewRows?: boolean;
  dataTestID?: string;
}

const PostmanTableHeader = ({
  rows,
  setRows,
  title,
  subtitle,
  className,
  keyHeader,
  valueHeader,
  isSelectRow,
  selectOptions,
  learnMoreText,
  dataTestID,
  isUserInput = true,
  hasSource = false,
  keysDisabled = false,
  userInputDisabled = false,
  addRowDisabled = false,
  pushDefaultValueOnNewRows = false,
  required = false,
}: PostmanTableHeaderProps) => {
  return (
    <div className={clsx("w-full", className)}>
      <HeaderBase
        title={title}
        subtitle={subtitle}
        learnMoreText={learnMoreText}
        required={required}
        hasSource={hasSource}
        dataTestID={dataTestID}
      />
      <PostmanTable
        rows={rows}
        onChange={(headers) => setRows(headers)}
        keyHeader={keyHeader}
        valueHeader={valueHeader}
        isSelectRow={isSelectRow}
        isUserInput={isUserInput}
        selectOptions={selectOptions}
        keysDisabled={keysDisabled}
        userInputDisabled={userInputDisabled}
        pushDefaultValueOnNewRows={pushDefaultValueOnNewRows}
        addRowDisabled={addRowDisabled}
      />
    </div>
  );
};
export default PostmanTableHeader;
