import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { ChevronsLeftRight } from "lucide-react";
import { getIntegrationBuilderAPIEndpointPathForId } from "../../../../router/RouterUtils";
import useIntegrationBuilderContext from "../../context/useIntegrationBuilderContext";
import IntegrationBuilderHeader from "../../shared/IntegrationBuilderHeader";
import { useEffect } from "react";
import APIRequestTester from "./request_tester/APIRequestTester";
import { APIEndpointIntegrationBuilder } from "../../utils/Entities";
import APIEndpointContextProvider from "../context/APIEndpointContextProvider";

interface APIEndpointHeaderProps {
  integrationID: string;
  isNewAPIEndpoint: boolean;
  formAPIEndpointData: () => APIEndpointIntegrationBuilder | null;
}

const APIEndpointHeader = ({
  integrationID,
  isNewAPIEndpoint,
  formAPIEndpointData,
}: APIEndpointHeaderProps) => {
  const {
    isRightPanelOpen,
    setIsRightPanelOpen,
    setRightPanelChildren,
  } = useIntegrationBuilderContext();

  // Controls API request tester opening in right panel
  useEffect(() => {
    if (isRightPanelOpen) {
      setRightPanelChildren(
        <APIEndpointContextProvider
          integrationID={integrationID}
          selectedAPIEndpoint={formAPIEndpointData() ?? undefined}
        >
          <APIRequestTester
            integrationID={integrationID}
            formAPIEndpointData={formAPIEndpointData}
          />
        </APIEndpointContextProvider>
      );
    } else {
      setRightPanelChildren(undefined);
    }
  }, [isRightPanelOpen, formAPIEndpointData]);

  // Controls API request tester closing if exit page
  useEffect(() => {
    return () => {
      setIsRightPanelOpen(false);
      setRightPanelChildren(undefined);
    };
  }, []);

  return (
    <IntegrationBuilderHeader
      title={isNewAPIEndpoint ? "Add endpoint" : "Edit endpoint"}
      navButtonLink={getIntegrationBuilderAPIEndpointPathForId(integrationID)}
      navButtonText="API endpoints"
      showRequired
      button={
        <div className="flex flex-row items-center flex-nowrap ml-2 space-x-3">
          <Button
            variant={ButtonVariant.SecondaryBlue}
            size="md"
            leftIcon={<ChevronsLeftRight size={16} />}
            onClick={() => setIsRightPanelOpen(true)}
          >
            API tester
          </Button>
        </div>
      }
    />
  );
};

export default APIEndpointHeader;
