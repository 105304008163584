import classNames from "classnames";
import { Form, InputGroup } from "react-bootstrap";
import FormField, { FormFieldCommonProps } from "./FormField";

interface Props extends FormFieldCommonProps {
  onChange: (value: string) => void;
  currentValue: undefined | string;
  placeholder?: string;
  children?: JSX.Element;
  hasEdited?: boolean;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  prepend?: string;
  readOnly?: boolean;
  className?: string;
}

const InputFormField = ({
  children,
  onChange,
  currentValue,
  placeholder,
  subtitle,
  title,
  valueType,
  example,
  hasEdited,
  onBlur,
  onKeyDown,
  prepend,
  readOnly,
  className,
}: Props) => {
  const styleProps = className ? { className } : {};
  return (
    <FormField
      title={title}
      subtitle={subtitle}
      valueType={valueType}
      example={example}
      onBlur={onBlur}
      {...styleProps}
    >
      <>
        {children}
        <InputGroup>
          {prepend && (
            <InputGroup.Prepend className="base-api-url-prepend">
              <InputGroup.Text className="base-api-url-text overflow-hidden">
                <b>{prepend}</b>
              </InputGroup.Text>
            </InputGroup.Prepend>
          )}
          <Form.Control
            as="input"
            className={classNames("form-control", hasEdited ? "form-control-is-editing" : "")}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            value={currentValue}
            autoComplete="no"
            onKeyDown={onKeyDown}
            readOnly={readOnly}
          />
        </InputGroup>
      </>
    </FormField>
  );
};

export default InputFormField;
