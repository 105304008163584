import clsx from "clsx";
import { Badge, Card, Text } from "@merge-api/merge-javascript-shared";
import { CircleMinus, Ellipsis } from "lucide-react";
import { useState } from "react";
import { APIEndpointParameter } from "src/autogenerated-types/AUTOGENERATED_ExpandedPydantic_APIEndpointParameter";
import {
  APIEndpointParameterMapping,
  UserFacingFilterDetails,
  ValueTransformation,
} from "../../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import { ConditionOperator } from "../../../types";
import TransformValuesModal from "./transform-values/TransformValuesModal";
import CompositeFilterMapping from "./composite-filter-mapping/CompositeFilterMapping";

type Props = {
  operator: ConditionOperator;
  apiEndpointParameterMapping: APIEndpointParameterMapping;
  userFacingDetails: UserFacingFilterDetails | null;
  removeParameterMappingDetails: (parameterMappingDetailsID: string, operator: string) => void;
  updateValueTransformation: (newValueTransformation: ValueTransformation | null) => void;
  handleEditExistingParameterMapping: (parameterMappingDetails: APIEndpointParameter) => void;
  isDisabled?: boolean;
};

const APIEndpointParameterMappingPreviewRow = ({
  operator,
  apiEndpointParameterMapping,
  userFacingDetails,
  removeParameterMappingDetails,
  handleEditExistingParameterMapping,
  updateValueTransformation,
  isDisabled,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const apiEndpointParameter =
    apiEndpointParameterMapping?.api_endpoint_filter_id?.next_version ??
    apiEndpointParameterMapping?.api_endpoint_filter_id?.published_version;

  return (
    <div className="flex flex-row mb-5 items-center w-100">
      <TransformValuesModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        valueTransformation={apiEndpointParameterMapping?.value_transformation}
        apiEndpointParameterMapping={apiEndpointParameterMapping}
        userFacingDetails={userFacingDetails}
        operator={operator}
        updateValueTransformation={updateValueTransformation}
        isDisabled={!!isDisabled}
      />
      <Card className="p-3 w-100" variant="outline" disabled={isDisabled}>
        <div className="flex-col">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center space-x-2">
              <Text variant="h6">{apiEndpointParameter?.api_endpoint_id?.name}</Text>
              <Badge className="items-center h-5">
                <Text variant="sm-mono" className="h-5">
                  {apiEndpointParameter?.api_endpoint_id?.path}
                </Text>
              </Badge>
              {apiEndpointParameter?.composite_filter_information && (
                <Badge color="indigo" className="flex items-center h-5">
                  <Text variant="title-sm" className="flex items-center h-5">
                    Composite
                  </Text>
                </Badge>
              )}
              <Ellipsis
                className="cursor-pointer"
                onClick={() =>
                  handleEditExistingParameterMapping(apiEndpointParameter as APIEndpointParameter)
                }
                size={16}
              >
                {apiEndpointParameter?.api_endpoint_id?.path}
              </Ellipsis>
            </div>
            {apiEndpointParameterMapping?.value_transformation ? (
              <div className="px-3 py-2 flex flex-row bg-gray-0 rounded items-center justify-between min-w-[340px]">
                <div>
                  <Text variant="title-sm">Transform values</Text>
                  <Text className="ml-2" variant="md-mono">
                    {apiEndpointParameterMapping?.value_transformation?.format_string}
                  </Text>
                </div>
                <div onClick={() => setIsModalOpen(true)}>
                  <Text
                    className={clsx(
                      "cursor-pointer",
                      !!isDisabled ? "text-gray-70" : "text-blue-40"
                    )}
                    variant="title-sm"
                  >
                    Edit
                  </Text>
                </div>
              </div>
            ) : (
              <div onClick={() => setIsModalOpen(true)}>
                <Text
                  className={clsx(
                    "cursor-pointer",
                    !!isDisabled && "text-gray-70",
                    !isDisabled && "text-blue-40"
                  )}
                  variant="title-sm"
                >
                  Transform values
                </Text>
              </div>
            )}
          </div>
          <div className="flex flex-row items-center">
            <Text variant="title-sm" className="mr-2">
              Key name
            </Text>
            <Text variant="md-mono">{apiEndpointParameter?.key_path?.[0]}</Text>
          </div>
          {apiEndpointParameter?.composite_filter_information && (
            <CompositeFilterMapping
              apiEndpointParameterMapping={apiEndpointParameterMapping}
              updateValueTransformation={updateValueTransformation}
              isDisabled={isDisabled}
            />
          )}
        </div>
      </Card>
      {!isDisabled && (
        <CircleMinus
          className="ml-5 cursor-pointer"
          size={16}
          onClick={() => removeParameterMappingDetails(apiEndpointParameterMapping.id, operator)}
        />
      )}
    </div>
  );
};

export default APIEndpointParameterMappingPreviewRow;
