import { useEffect, useState } from "react";
import { MappingTestSuiteEndpointCollection } from "../../../../models/MappingTests";
import { showErrorToast } from "../../../shared/Toasts";
import { fetchWithAuth } from "../../../../api-client/api_client";

interface UseGetEndpointCollectionProps {
  endpointCollectionID: string;
}

export const useGetEndpointCollection = ({
  endpointCollectionID,
}: UseGetEndpointCollectionProps) => {
  const [
    endpointCollection,
    setEndpointCollection,
  ] = useState<MappingTestSuiteEndpointCollection | null>(null);

  useEffect(() => {
    const fetchData = () => {
      if (!endpointCollectionID) {
        return;
      }
      fetchWithAuth({
        path: `stallions/endpoint-collections/${endpointCollectionID}`,
        method: "GET",
        onResponse: (data: MappingTestSuiteEndpointCollection) => {
          setEndpointCollection(data);
        },
        onError: () => {
          showErrorToast("Error querying endpoint collection.");
        },
      });
    };

    fetchData();
  }, [endpointCollectionID]);

  return {
    endpointCollection,
    setEndpointCollection,
  };
};
