import { Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import { Plus, WandSparkles } from "lucide-react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MergeFlagWrapper from "../../../../components/shared/MergeFlagWrapper";
import { MergeFlagFeature } from "../../../../components/shared/hooks/useMergeFlag";
import { navigateToIntegrationBuilderCreatePaginationConfiguration } from "../../../../router/RouterUtils";
import MergeTable from "../../../shared/MergeTable";
import TableSkeletonLoader from "../../../shared/TableSkeletonLoader";
import useIntegrationBuilderContext from "../../context/useIntegrationBuilderContext";
import IntegrationBuilderEmptyState from "../../shared/IntegrationBuilderEmptyState";
import IntegrationBuilderHeader from "../../shared/IntegrationBuilderHeader";
import { PaginationConfigurationIntegrationBuilder } from "../../utils/Entities";
import PaginationConfigurationExplain from "./PaginationConfigurationExplain";
import PaginationConfigurationTableRow from "./PaginationConfigurationTableRow";
import usePollPaginationConfigurationsSummary from "../hooks/usePollPaginationConfigurationsSummary";
// import AutogeneratedConfigurationCard from "../../shared/autogenerated-configuration-card/AutogeneratedConfigurationCard";

interface PaginationConfigurationsTableProps {
  integrationID: string;
  paginationConfigurations: PaginationConfigurationIntegrationBuilder[] | undefined;
  setPaginationConfigurations: React.Dispatch<
    React.SetStateAction<PaginationConfigurationIntegrationBuilder[] | undefined>
  >;
  isLoadingPaginationConfigurations: boolean;
  setIsLoadingPaginationConfigurations: React.Dispatch<React.SetStateAction<boolean>>;
}

const PaginationConfigurationsTable = ({
  integrationID,
  paginationConfigurations,
  setPaginationConfigurations,
  isLoadingPaginationConfigurations,
  setIsLoadingPaginationConfigurations,
}: PaginationConfigurationsTableProps) => {
  const [
    isAutogeneratingPaginationConfiguration,
    setIsAutogeneratingPaginationConfiguration,
  ] = useState(false);

  //hooks
  const history = useHistory();

  const {
    pollPaginationConfigurations,
    stopPollingPaginationConfigurations,
  } = usePollPaginationConfigurationsSummary({
    setPaginationConfigurations,
    setIsLoadingPaginationConfigurations,
    setIsAutogeneratingPaginationConfiguration,
  });

  // poll for pagination configurations when component mounts
  // and stop polling when component unmounts
  useEffect(() => {
    pollPaginationConfigurations(integrationID);
    return () => {
      stopPollingPaginationConfigurations();
    };
  }, []);

  // context
  useIntegrationBuilderContext({
    canSubmit: false,
    shouldRenderSubmitButton: false,
    shouldRenderNavigationButtons: true,
    shouldRenderDeleteButton: false,
    shouldRenderStageButton: false,
    markForDeletion: false,
  });

  return (
    <div>
      <IntegrationBuilderHeader
        title="Pagination configurations"
        button={
          <Button
            size="md"
            leftIcon={<Plus size={16} />}
            onClick={() => {
              navigateToIntegrationBuilderCreatePaginationConfiguration(history, integrationID);
            }}
          >
            Configuration
          </Button>
        }
        secondaryButton={
          <MergeFlagWrapper
            integrationId={integrationID}
            feature={MergeFlagFeature.MERGE_FLAG_PAGINATION_CONFIGURATION_GENERATOR}
          >
            <Button
              variant={
                isAutogeneratingPaginationConfiguration
                  ? ButtonVariant.SecondaryGray
                  : ButtonVariant.SecondaryBlue
              }
              size="md"
              loading={isAutogeneratingPaginationConfiguration}
              leftIcon={
                !isAutogeneratingPaginationConfiguration ? <WandSparkles size={16} /> : undefined
              }
              onClick={() => setIsAutogeneratingPaginationConfiguration((prev) => !prev)}
            >
              {isAutogeneratingPaginationConfiguration
                ? "Generating default configuration"
                : "Generate default configuration"}
            </Button>
          </MergeFlagWrapper>
        }
      />
      <PaginationConfigurationExplain className="mb-6" />
      {/* <AutogeneratedConfigurationCard
        className="my-6"
        autogen_runs={[]}
        setShouldHideCard={() => {}}
      /> */}
      <MergeTable
        hover={paginationConfigurations && paginationConfigurations.length > 0}
        hasMarginBottom={false}
        hasMorePaddingOnFirstElement
        header={
          <>
            <th scope="col">Name</th>
            <th scope="col">Type</th>
            <th scope="col">Description</th>
            <th scope="col">Endpoints</th>
          </>
        }
        content={
          isLoadingPaginationConfigurations ? (
            <TableSkeletonLoader cols={4} rows={3} size="large" />
          ) : paginationConfigurations && paginationConfigurations.length > 0 ? (
            paginationConfigurations?.map((config) => (
              <PaginationConfigurationTableRow
                key={config.id}
                paginationConfiguration={config}
                integrationID={integrationID}
              />
            ))
          ) : (
            <IntegrationBuilderEmptyState
              title="No pagination configurations"
              subtitle={
                <div>
                  Click <Text variant="title-sm">+ Configuration</Text> in the top right to manually
                  add one
                </div>
              }
              numberOfColumns={4}
            />
          )
        }
      />
    </div>
  );
};

export default PaginationConfigurationsTable;
