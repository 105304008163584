import { useContext, useEffect, useState } from "react";
import MultiSelectHeader from "../../../shared/MultiSelectHeader";
import { API_ENDPOINT_CONTENT_TYPE_OPTIONS_DATA } from "../../constants";
import APIEndpointContext from "../../context/APIEndpointContext";
import JSONSchemaFieldHeader from "../../../shared/JSONSchemaFieldHeader";

const RestRequestConfigurationSetupOptions = () => {
  const { contentType, setContentType, bodySchema, setBodySchema } = useContext(APIEndpointContext);
  const contentTypeOptions = API_ENDPOINT_CONTENT_TYPE_OPTIONS_DATA.map(({ value, text }) => ({
    value,
    text,
    selected: contentType === value,
    onClick: setContentType,
  }));

  const [schemaConverterText, setSchemaConverterText] = useState(JSON.stringify(bodySchema));

  useEffect(() => {
    setSchemaConverterText(JSON.stringify(bodySchema));
  }, [bodySchema]);

  // This useEffect sets converts our displayed schema text to a body schema object
  // If schema text is non-empty, we try to parse to JSON. If it fails to parse, set empty object.
  useEffect(() => {
    if (schemaConverterText) {
      try {
        const parsedValue = JSON.parse(schemaConverterText);
        setBodySchema(parsedValue);
      } catch {
        // If we fail to parse the current text, do not update the body schema
      }
    }
  }, [schemaConverterText]);

  return (
    <>
      <MultiSelectHeader
        title="Content type"
        options={contentTypeOptions}
        className="mt-6"
        dataTestID="field-api-endpoint-content-type"
      />
      <JSONSchemaFieldHeader
        dataTestID="field-api-endpoint-body-schema"
        value={schemaConverterText}
        setValue={(schema) => setSchemaConverterText(schema)}
        title="Body schema"
        subtitle="The JSON schema of the API request body."
        className="mt-6"
        hasSampleJSONUpload={true}
      />
    </>
  );
};

export default RestRequestConfigurationSetupOptions;
