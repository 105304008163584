import { Badge } from "@merge-api/merge-javascript-shared";
import { getBlueprintSearchStatusBadgeColor } from "../../../../shared/MergeBadges";
import { getBadgeColorForCoveragePercent } from "../../../../shared/utils/SharedComponentUtils";
import {
  BlueprintVersionPublishState,
  MapDisplayNameOfBlueprintVersionPublishState,
} from "../../../../../models/Blueprints";
import {
  BlueprintSearchResultBlueprint,
  BlueprintSearchResultBlueprintVersion,
} from "../../../../../models/blueprint-search/BlueprintSearchOutputModels";
import BlueprintSearchCardPublishStatusBadgeIcon from "./BlueprintSearchCardPublishStatusBadgeIcon";

interface Props {
  blueprintResult: BlueprintSearchResultBlueprint;
}

const BlueprintSearchCardStatusBadges = ({ blueprintResult }: Props) => {
  const relevantBlueprintVersion: BlueprintSearchResultBlueprintVersion = blueprintResult.published_blueprint_version
    ? blueprintResult.published_blueprint_version
    : blueprintResult.latest_blueprint_version;

  // Very rarely will there be a Blueprint with no "latest_blueprint_version"
  // But if there isn't, we don't render this component
  if (!relevantBlueprintVersion) {
    return null;
  }

  const testCoveragePercent: number | undefined = relevantBlueprintVersion?.test_coverage
    ? relevantBlueprintVersion?.test_coverage?.coverage_percent
    : undefined;
  const publishedStatus: BlueprintVersionPublishState = blueprintResult.published_blueprint_version
    ? BlueprintVersionPublishState.Published
    : blueprintResult.latest_version_publish_state;
  const latestPublishedStatus: BlueprintVersionPublishState | null | undefined =
    publishedStatus === BlueprintVersionPublishState.Published &&
    blueprintResult.latest_version_publish_state !== BlueprintVersionPublishState.Published
      ? blueprintResult.latest_version_publish_state
      : undefined;

  return (
    <div className="flex flex-row items-center space-x-2">
      <Badge
        color={getBlueprintSearchStatusBadgeColor(publishedStatus)}
        className="flex flex-row items-center space-x-1"
      >
        <BlueprintSearchCardPublishStatusBadgeIcon publishedStatus={publishedStatus} />
        <div>{MapDisplayNameOfBlueprintVersionPublishState[publishedStatus]}</div>
      </Badge>
      {latestPublishedStatus && (
        <Badge
          color={getBlueprintSearchStatusBadgeColor(latestPublishedStatus)}
          className="flex flex-row items-center space-x-1"
        >
          <BlueprintSearchCardPublishStatusBadgeIcon publishedStatus={latestPublishedStatus} />
          <div>
            Latest version: {MapDisplayNameOfBlueprintVersionPublishState[latestPublishedStatus]}
          </div>
        </Badge>
      )}
      {testCoveragePercent !== undefined && (
        <Badge color={getBadgeColorForCoveragePercent(testCoveragePercent, true)}>
          {Math.round(testCoveragePercent)}% test coverage
        </Badge>
      )}
    </div>
  );
};

export default BlueprintSearchCardStatusBadges;
