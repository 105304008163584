import { Button, ButtonVariant, Card, Text } from "@merge-api/merge-javascript-shared";
import { LinkedAccountAccessSession } from "../../../models/Entities";
import { formatShorthandDate } from "../../integration-builder/utils/helpers";
import { useCustomerLinkedAccountContext } from "../context/CustomerLinkedAccountAccessContext";

interface CustomerLinkedAccountAccessProps {
  accessSession: LinkedAccountAccessSession;
}

const CustomerLinkedAccountAccess = ({ accessSession }: CustomerLinkedAccountAccessProps) => {
  const { revokeLinkedAccountAccess } = useCustomerLinkedAccountContext();

  return (
    <Card variant="outline" className="p-3 space-y-2">
      <Text>{`Customer Linked Account now available in dropdown. Access will expire on ${formatShorthandDate(
        accessSession.access_end
      )}`}</Text>
      <div className="flex flex-col">
        <Text
          variant="sm"
          className="text-gray-70"
        >{`${accessSession.organization_name} - ${accessSession.end_user_name}`}</Text>
        <Text
          variant="sm"
          className="text-gray-70"
        >{`ID: ${accessSession.linked_account_id}`}</Text>
      </div>
      <Button
        className="w-full"
        variant={ButtonVariant.DangerText}
        size="sm"
        onClick={revokeLinkedAccountAccess}
      >
        End session
      </Button>
    </Card>
  );
};

export default CustomerLinkedAccountAccess;
