import { transformRateLimitConfigurationDataForDiffModal } from "../../../integration-builder/rate-limits/utils/helpers";
import {
  LinkChoiceStepOption,
  LinkingFlowStepPathIntegrationBuilder,
  RateLimitConfigurationIntegrationBuilder,
} from "../../../integration-builder/utils/Entities";
import { convertToDisplayableDiffState } from "../../../shared/diff-view/utils/helpers";
import { ChangelogModelType } from "./types";

export function handleModelTypeFieldConversionForDiffModal(
  modelType: ChangelogModelType,
  fields: Record<any, any>
): Record<any, any> {
  switch (modelType) {
    case ChangelogModelType.MERGE_LINK_STEPS:
      return convertToDisplayableDiffState(
        fields as LinkingFlowStepPathIntegrationBuilder,
        [],
        undefined,
        undefined
      );
    case ChangelogModelType.LINK_CHOICE_STEP_OPTION:
      return convertToDisplayableDiffState(
        undefined,
        [fields] as LinkChoiceStepOption[],
        undefined,
        undefined
      );
    case ChangelogModelType.RATE_LIMIT_CONFIGURATION:
      return transformRateLimitConfigurationDataForDiffModal(
        fields as RateLimitConfigurationIntegrationBuilder
      );
    case ChangelogModelType.API_ENDPOINT:
      return { api_endpoint: fields, rate_limit_configurations: [] };

    default:
      return fields;
  }
}
