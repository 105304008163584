import { ChevronRight } from "lucide-react";

interface Props {
  onClick: React.MouseEventHandler<HTMLTableCellElement> | undefined;
}

const ChevronCell = ({ onClick }: Props) => {
  return (
    <td className="py-5 pr-6" onClick={onClick}>
      <div className="flex flex-row justify-end">
        <ChevronRight size={12} className="text-black" />
      </div>
    </td>
  );
};

export default ChevronCell;
