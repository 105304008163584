import { Container } from "react-bootstrap";
import AuthMergeLinkTester from "./AuthMergeLinkTester";
import { AuthConfigUpdateProps } from "./AuthConfigShared";

interface Props {
  authConfigUpdateProps: AuthConfigUpdateProps;
}

const AuthConfigEditFormRightPanel = (props: Props) => {
  return (
    <div
      className="log-side-panel"
      style={{
        position: "fixed",
        width: "30%",
        height: "100%",
        zIndex: 1039,
      }}
    >
      {props.authConfigUpdateProps.id ? (
        <Container fluid>
          <AuthMergeLinkTester
            authConfigUpdateProps={props.authConfigUpdateProps}
            authConfigID={props.authConfigUpdateProps.id}
          />
        </Container>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AuthConfigEditFormRightPanel;
