import { useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { PublishModuleInfoV2 } from "../types";

interface Props {
  integrationID: string;
}

const useLoadValidatedStagedVersions = ({ integrationID }: Props) => {
  const [publishModuleInfoV2, setPublishModuleInfoV2] = useState<PublishModuleInfoV2>();

  const fetchValidatedStagedVersions = () => {
    fetchWithAuth({
      path: `/integrations/versioning/${integrationID}/staged-version-with-validator-results`,
      method: "GET",
      onResponse: (data: PublishModuleInfoV2) => {
        setPublishModuleInfoV2(data);
      },
      onError: (error) => {
        console.error("Error fetching validated staged versions", error);
      },
    });
  };

  return {
    publishModuleInfoV2,
    setPublishModuleInfoV2,
    fetchValidatedStagedVersions,
  };
};

export default useLoadValidatedStagedVersions;
