import React, { ChangeEvent, memo, Suspense, useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import styled from "styled-components";
import MergeText from "../text/MergeText";
import { getTimeFromNowUTC } from "../../shared/utils/SharedComponentUtils";
import { SmallTextMutedParagraph } from "../../shared/text/MergeText";
import { isEqual } from "lodash-es";
import { isValidXML } from "../../../utils";
import beautify from "xml-beautifier";

const LazyCodeBlock = React.lazy(() => import("../MergeCodeBlock"));

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledButton = styled(Button).attrs({ size: "sm", variant: "light" })`
  background-color: white;
  border: 1px #e3ebf6 solid;
`;

const CodeBlockEditor = styled(Form.Control).attrs({
  as: "textarea",
  className: "w-100",
  style: { minHeight: "500px" },
})`
  box-sizing: border-box;
  resize: vertical;
`;

type EditResponseFunction = (apiRequestID: string, apiRequestBody: string) => void;
type EditBlueprintInputPayloadFunction = (blueprintID: string, newMetaData: any) => void;

type Props = {
  title?: string;
  bodyType?: string;
  body: string;
  targetID?: string;
  editedAt?: null | string;
  editCodeFunction?: null | EditResponseFunction | EditBlueprintInputPayloadFunction;
  resetApiRequest?: (apiRequestID: string) => void;
  isInPortal?: boolean;
  JSONOnly?: boolean;
  alwaysEditable?: boolean;
  editable?: boolean;
};

const getDisplayedBody = (bodyType: string, body: string): string => {
  if (bodyType === "SHA256") {
    return [...atob(body)]
      .map((c) => c.charCodeAt(0).toString(16).padStart(2, "0"))
      .join("")
      .toUpperCase();
  }
  try {
    const json = JSON.parse(body);
    return JSON.stringify(json, null, 2);
  } catch (_) {
    return isValidXML(body) ? beautify(body) : body;
  }
};

const deWhiteSpaceBody = (code: string): string => {
  try {
    const json = JSON.parse(code);
    return JSON.stringify(json, null);
  } catch (_) {
    return code;
  }
};

const BlueprintCardAPIRequestFooterEditableCodeSection = ({
  title,
  bodyType,
  body,
  targetID,
  editedAt,
  editCodeFunction,
  resetApiRequest,
  isInPortal,
  JSONOnly,
  alwaysEditable = false,
  editable = true,
}: Props) => {
  const [isEditing, setIsEditing] = useState(alwaysEditable);
  const [text, setText] = useState<string | null>(getDisplayedBody(bodyType ?? "", body));
  const [editedText, setEditedText] = useState<string | undefined>(body);

  useEffect(() => setText(getDisplayedBody(bodyType ?? "", body)), [body]);
  return (
    <div>
      {editable && (
        <HeaderRow>
          {title && (
            <MergeText isBold size="15px">
              {title}
            </MergeText>
          )}
          {editedAt && (
            <SmallTextMutedParagraph className="mb-0">
              <span className="fe fe-clock mr-1.5" />
              edited {getTimeFromNowUTC(editedAt)}
            </SmallTextMutedParagraph>
          )}
          <div>
            {!isEditing && (
              <StyledButton
                onClick={() => {
                  setIsEditing(true);
                  setEditedText(text ?? "");
                }}
              >
                <i className="fe fe-edit-3 mr-1.5" />
                Edit
              </StyledButton>
            )}
            {isEditing && editCodeFunction && (
              <StyledButton
                onClick={() => {
                  const newText = deWhiteSpaceBody(editedText!);
                  editCodeFunction(
                    targetID ?? "",
                    JSONOnly === true ? JSON.parse(newText) : newText
                  );

                  if (!alwaysEditable) setIsEditing(false);
                  setText(getDisplayedBody(bodyType ?? "", newText));
                }}
              >
                <i className="fe fe-edit-3 mr-1.5" />
                Save
              </StyledButton>
            )}
            {!isEditing && editedAt && resetApiRequest && (
              <StyledButton
                onClick={() => {
                  resetApiRequest(targetID!);
                }}
              >
                <i className="fe fe-edit-3 mr-1.5" />
                Reset
              </StyledButton>
            )}
          </div>
        </HeaderRow>
      )}
      <Suspense fallback={<div />}>
        {!isEditing ? (
          <LazyCodeBlock
            customStyle={isInPortal === true ? { maxHeight: 800 } : {}}
            textToCopy={text ?? ""}
            language={isValidXML(body) ? "xml" : undefined}
          >
            {text}
          </LazyCodeBlock>
        ) : (
          <CodeBlockEditor
            value={editedText}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setEditedText(e.target.value)}
          />
        )}
      </Suspense>
    </div>
  );
};

export default memo(BlueprintCardAPIRequestFooterEditableCodeSection, isEqual);
