import { useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";

interface useConvertQBXMLToJsonProps {
  qbxmlString: string;
  blueprintID?: string;
}

const useConvertQBXMLToJson = () => {
  const [isLoadingConversion, setIsLoadingConversion] = useState(false);
  const [conversionResult, setConversionResult] = useState<string | null>(null);

  const convertQbxmlToJson = ({ qbxmlString, blueprintID }: useConvertQBXMLToJsonProps) => {
    setIsLoadingConversion(true);
    fetchWithAuth({
      path: `integrations/convert-qbxml-to-json`,
      method: "POST",
      body: {
        qbxml_string: qbxmlString,
        ...(blueprintID != null && { blueprint_id: blueprintID }),
      },
      headers: {
        "Content-Type": "application/json",
      },
      onResponse: (response: any) => {
        showSuccessToast("qbXML successfully converted to JSON!");
        setConversionResult(JSON.stringify(response, null, 2));
        setIsLoadingConversion(false);
      },
      onError: () => {
        showErrorToast("Failed to convert qbXML to JSON.");
        setIsLoadingConversion(false);
      },
    });
  };

  return { convertQbxmlToJson, isLoadingConversion, conversionResult };
};

export default useConvertQBXMLToJson;
