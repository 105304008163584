import { Form } from "react-bootstrap";
import { BlueprintStepTemplate } from "../../../models/Blueprints";
import useBlueprintContext from "../context/useBlueprintContext";
import {
  isPaginationTimestampAvailable,
  checkPaginationTimestampUsed,
} from "../utils/BlueprintEditorUtils";
import BlueprintEditorRightPanelAPIRequestForm from "./BlueprintEditorRightPanelAPIRequestForm";

interface Props {
  stepID: string;
  stepTemplate: BlueprintStepTemplate;
}

const BlueprintEditorRightPanelAPIRequestLoopForm = (props: Props) => {
  const { blueprint, selectedStep, setStepUsesPaginationTimestamp } = useBlueprintContext();

  const canTogglePaginationTimestamps = isPaginationTimestampAvailable(blueprint.operation_type);
  const paginationTimestampUsed = checkPaginationTimestampUsed(selectedStep);

  const updateUsePaginationTimeStamps = () => {
    setStepUsesPaginationTimestamp(props.stepID, !paginationTimestampUsed);
  };

  return (
    <>
      {canTogglePaginationTimestamps && (
        <Form.Group className="mb-9">
          <Form.Check
            key={`use-pagination-timestamp-toggle-${props.stepID}-key`}
            id={`use-pagination-timestamp-toggle-${props.stepID}-id`}
            type="switch"
            label="Enable Pagination Timestamps"
            defaultChecked={paginationTimestampUsed}
            onChange={updateUsePaginationTimeStamps}
          />
        </Form.Group>
      )}
      <BlueprintEditorRightPanelAPIRequestForm {...props} />
    </>
  );
};

export default BlueprintEditorRightPanelAPIRequestLoopForm;
