import BinaryChoiceButtons from "../shared/BinaryChoiceButtons";
import { DateTimeFormatEnums, TokenExpiresInType } from "../../../utils/Entities";
import TokenURLResponseRenderedComponenets from "./TokenURLResponseRenderedComponents";

interface TokenURLCardProps {
  doesResponseIncludeExpiresInParam: boolean | undefined;
  setDoesResponseIncludeExpiresInParam: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  tokenExpiresInType: TokenExpiresInType | undefined;
  setTokenExpiresInType: React.Dispatch<React.SetStateAction<TokenExpiresInType | undefined>>;
  dateTimeFormat: DateTimeFormatEnums | undefined;
  setDateTimeFormat: React.Dispatch<React.SetStateAction<DateTimeFormatEnums | undefined>>;
  oAuthResponseTokenLifespanInSeconds: number | undefined;
  setOAuthResponseTokenLifespanInSeconds: React.Dispatch<React.SetStateAction<number | undefined>>;
  oAuthResponseCreatedAtKeyPath: string[] | null;
  setOAuthResponseCreatedAtKeyPath: React.Dispatch<React.SetStateAction<string[] | null>>;
}

const RefreshConfigurationTokenURLResponseCard = ({
  doesResponseIncludeExpiresInParam,
  setDoesResponseIncludeExpiresInParam,
  tokenExpiresInType,
  setTokenExpiresInType,
  dateTimeFormat,
  setDateTimeFormat,
  oAuthResponseCreatedAtKeyPath,
  setOAuthResponseCreatedAtKeyPath,
  oAuthResponseTokenLifespanInSeconds,
  setOAuthResponseTokenLifespanInSeconds,
}: TokenURLCardProps) => {
  return (
    <>
      <BinaryChoiceButtons
        dataTestID="field-auth-config-refresh-token-includes-expires-in"
        title="Does the token URL response include an `expires_in` parameter?"
        binaryChoice={doesResponseIncludeExpiresInParam}
        setBinaryChoice={setDoesResponseIncludeExpiresInParam}
      />
      {doesResponseIncludeExpiresInParam !== undefined && (
        <TokenURLResponseRenderedComponenets
          doesResponseIncludeExpiresInParam={doesResponseIncludeExpiresInParam}
          tokenExpiresInType={tokenExpiresInType}
          setTokenExpiresInType={setTokenExpiresInType}
          dateTimeFormat={dateTimeFormat}
          setDateTimeFormat={setDateTimeFormat}
          oAuthResponseTokenLifespanInSeconds={oAuthResponseTokenLifespanInSeconds}
          setOAuthResponseTokenLifespanInSeconds={setOAuthResponseTokenLifespanInSeconds}
          oAuthResponseCreatedAtKeyPath={oAuthResponseCreatedAtKeyPath}
          setOAuthResponseCreatedAtKeyPath={setOAuthResponseCreatedAtKeyPath}
        />
      )}
    </>
  );
};
export default RefreshConfigurationTokenURLResponseCard;
