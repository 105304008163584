import styled from "styled-components";

type RowProps = {
  isRowShaded: boolean;
};

const Row = styled.div<RowProps>`
  display: flex;
  justify-content: space-between;
  background: ${({ isRowShaded }) => (isRowShaded ? "#F7F8F9" : "#FFFFFF")};
`;

type Props = {
  isRowShaded: boolean;
  children: React.ReactNode;
};

const MappingTestEditorCommonModelExpectedMappingRow = ({ isRowShaded, children }: Props) => {
  return <Row isRowShaded={isRowShaded}>{children}</Row>;
};

export default MappingTestEditorCommonModelExpectedMappingRow;
