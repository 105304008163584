import { useState, useEffect } from "react";
import { Prompt, useHistory } from "react-router-dom";
import PreExitUnsavedChangesModal from "./PreExitUnsavedChangesModal";

type Props = {
  currentLocation?: string;
  hasUnsavedChanges?: boolean;
  computeHasUnsavedChanges?: () => boolean;
  children: JSX.Element;
};

function EditorLeavingGuard({
  children,
  currentLocation,
  hasUnsavedChanges,
  computeHasUnsavedChanges,
}: Props): JSX.Element {
  const [isShowingOnExitModal, setIsShowingOnExitModal] = useState<boolean>();
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const history = useHistory();

  useEffect(() => {
    function showAlertOnCloseTab(e: any) {
      e.preventDefault();
      return (e.returnValue = "Are you sure you want to close this tab?");
    }

    if (hasUnsavedChanges) {
      window.addEventListener("beforeunload", showAlertOnCloseTab);
    }
    return () => {
      window.removeEventListener("beforeunload", showAlertOnCloseTab);
    };
  }, [hasUnsavedChanges]);

  const shouldBlockNavigation = (location: any) => {
    // Compute whether there is unsaved changes, if the compute method is passed in
    let hasUnsavedChangesComputed = false;
    if (computeHasUnsavedChanges) {
      hasUnsavedChangesComputed = computeHasUnsavedChanges();
    }
    // Block navigation if there are unsaved changes
    if (
      (hasUnsavedChanges !== undefined && !hasUnsavedChanges) ||
      (computeHasUnsavedChanges !== undefined && !hasUnsavedChangesComputed) ||
      (currentLocation && location.pathname.indexOf(currentLocation) > -1)
    ) {
      return false;
    }
    return true;
  };

  const handleBlockedNavigation = (location: any) => {
    const shouldBlock = shouldBlockNavigation(location);
    if (!confirmedNavigation && shouldBlock) {
      setIsShowingOnExitModal(true);
      setLastLocation(location);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setIsShowingOnExitModal(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push({
        pathname: lastLocation.pathname,
      });
    }
  }, [confirmedNavigation, lastLocation, history]);

  return (
    <>
      <Prompt message={handleBlockedNavigation} />
      <PreExitUnsavedChangesModal
        show={isShowingOnExitModal}
        onHide={() => {
          setIsShowingOnExitModal(false);
        }}
        onConfirm={() => handleConfirmNavigationClick()}
      />
      {children}
    </>
  );
}

export default EditorLeavingGuard;
