import { OverlayTrigger, Tooltip } from "react-bootstrap";

type LoadingProps = {
  className: string;
  onClick?: (e?: any) => void;
  text: JSX.Element | string;
  type?: "button" | "submit" | "reset";
};
function LoadingButton(props: LoadingProps) {
  return (
    <button
      type={props.type ? props.type : "submit"}
      className={`btn ${props.className}`}
      disabled
      onClick={props.onClick}
    >
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
      <span className="ml-1.5">{props.text}</span>
    </button>
  );
}

type NormalProps = {
  className: string;
  onClick?: (e?: any) => void;
  text: JSX.Element | string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  tooltipText?: string;
  showTooltip?: boolean;
};

function NormalButton(props: NormalProps) {
  return (
    <button
      className={`btn ${props.className}`}
      type={props.type ? props.type : "submit"}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.text}
    </button>
  );
}

type SpinnerProps = {
  isLoading: boolean;
  className: string;
  onClick?: (e?: any) => void;
  text: JSX.Element | string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  disabledTooltipText?: string;
};

const SpinnerButton = (props: SpinnerProps) => {
  const button = props.isLoading ? <LoadingButton {...props} /> : <NormalButton {...props} />;

  return props.disabled && props.disabledTooltipText ? (
    <OverlayTrigger
      overlay={<Tooltip id={`tooltip-${props.className}`}>{props.disabledTooltipText}</Tooltip>}
      placement="top"
    >
      <div>{button}</div>
    </OverlayTrigger>
  ) : (
    button
  );
};

export default SpinnerButton;
