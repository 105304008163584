import KeyPathFieldHeader from "../../../shared/KeyPathFieldHeader";

interface Props {
  className?: string;
  basicAuthAdditionalAuthFieldKeys: string[] | null;
  setBasicAuthAdditionalAuthFieldKeys: React.Dispatch<React.SetStateAction<string[] | null>>;
}

const AdditionalAuthFieldKeysFields = ({
  className,
  basicAuthAdditionalAuthFieldKeys,
  setBasicAuthAdditionalAuthFieldKeys,
}: Props) => {
  return (
    <KeyPathFieldHeader
      dataTestID="field-auth-config-additional-auth-field-keys"
      className={className ?? ""}
      title="Additional auth field keys"
      subtitle="Enter any additional keys required for the integration's auth field setup. Type the name of the key below and press Enter."
      placeholder="Add key name..."
      learnMoreText="For example, the Salesforce integration requires fields 'SECURITY_TOKEN' and 'ORGANIZATION_ID'"
      keyPath={basicAuthAdditionalAuthFieldKeys}
      onKeyPathChange={setBasicAuthAdditionalAuthFieldKeys}
      hasSource={false}
      showArrow={false}
    />
  );
};

export default AdditionalAuthFieldKeysFields;
