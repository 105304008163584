import { Activity } from "lucide-react";
import { Text } from "@merge-api/merge-javascript-shared";
import { MappingTestExecution } from "../../../models/MappingTests";
import MappingTestV2RightPanelLogCard from "./MappingTestV2RightPanelLogCard";

interface Props {
  selectedBlockExecution: MappingTestExecution;
}

const MappingTestV2RightPanelLogs = ({ selectedBlockExecution }: Props) => {
  return (
    <div className="flex flex-column pt-4 px-4">
      <div className="flex flex-row items-center justify-start space-x-2">
        <Activity size={16} className="text-black" />
        <Text variant="h5">Logs</Text>
      </div>
      {selectedBlockExecution.exit_data &&
        selectedBlockExecution.exit_data.map((exitData, index) => {
          return (
            <div>
              <MappingTestV2RightPanelLogCard
                index={index}
                exitData={exitData}
                selectedBlockExecution={selectedBlockExecution}
              />
            </div>
          );
        })}
    </div>
  );
};

export default MappingTestV2RightPanelLogs;
