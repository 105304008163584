import { useState } from "react";
import { RefreshCw } from "lucide-react";
import { Button, ButtonVariant, Dialog } from "@merge-api/merge-javascript-shared";
import { useResetReadTest } from "./hooks/useResetReadTest";

interface ResetFetchMappingTestButtonProps {
  integrationID: string;
  mappingTestSuiteID: string;
}

const ResetFetchMappingTestButton = ({
  integrationID,
  mappingTestSuiteID,
}: ResetFetchMappingTestButtonProps) => {
  // state
  const [dialogOpen, setDialogOpen] = useState(false);

  // hooks
  const { isLoading, resetReadTest } = useResetReadTest({ integrationID });

  // functions
  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };
  return (
    <>
      <Dialog
        title="Reset fetch mapping test"
        primaryButtonText="Reset"
        onPrimaryButtonClick={() => {
          resetReadTest(mappingTestSuiteID);
          closeDialog();
        }}
        primaryButtonLoading={isLoading}
        open={dialogOpen}
        onClose={closeDialog}
        variant="sm"
        disableCloseOnBackdropClick={false}
      >
        <p>Are you sure you want to reset the Fetch mapping test?</p>
        <p>This will remove all saved work in the mapping test.</p>
      </Dialog>
      <Button
        variant={ButtonVariant.TertiaryWhite}
        size="sm"
        leftIcon={<RefreshCw size={12} />}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          openDialog();
        }}
      >
        Reset
      </Button>
    </>
  );
};

export default ResetFetchMappingTestButton;
