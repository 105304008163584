import { Text } from "@merge-api/merge-javascript-shared";
import {
  BLOCK_EXECUTION_ERROR_TYPE_TO_TEXT,
  BlockExecutionErrorType,
} from "../../../../models/MappingTests";

interface Props {
  type: BlockExecutionErrorType;
  summary: string;
}

const MappingTestV2RightPanelErrorSummary = ({ type, summary }: Props) => {
  return (
    <div className="flex flex-column">
      <Text variant="md" className="text-gray-90">
        {BLOCK_EXECUTION_ERROR_TYPE_TO_TEXT[type]}
      </Text>
      <Text variant="sm" className="text-gray-60">
        {summary}
      </Text>
    </div>
  );
};

export default MappingTestV2RightPanelErrorSummary;
