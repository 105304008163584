import { Text } from "@merge-api/merge-javascript-shared";
import { getCapitalizedTimeFromNow } from "../../../../../shared/utils/SharedComponentUtils";

type LastModifiedCellProps = {
  lastModifiedBlueprintAt: string;
};

const LastModifiedCell = ({ lastModifiedBlueprintAt }: LastModifiedCellProps) => {
  return (
    <td>
      <Text className="text-gray-60">
        {lastModifiedBlueprintAt ? getCapitalizedTimeFromNow(lastModifiedBlueprintAt) : "-"}
      </Text>
    </td>
  );
};

export default LastModifiedCell;
