import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { APIEndpointMethod } from "../../../../models/Entities";
import {
  navigateToIntegrationBuilderAPIEndpointForm,
  navigateToIntegrationBuilderAPIEndpointTable,
} from "../../../../router/RouterUtils";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import IntegrationBuilderContext from "../../context/IntegrationBuilderContext";
import {
  APIEndpointIntegrationBuilder,
  PaginationConfigurationIntegrationBuilder,
  RateLimitConfigurationIntegrationBuilder,
} from "../../utils/Entities";
import useCreateOrPatchPaginationConfiguration from "../../pagination-configuration-setup/hooks/useCreateOrPatchPaginationConfiguration";
import useCreateOrPatchRateLimitConfiguration from "../../rate-limits/hooks/useCreateOrPatchRateLimitConfiguration";
import useDeleteRateLimitConfiguration from "../../rate-limits/hooks/useDeleteRateLimitConfiguration";

interface CreateOrPatchAPIEndpointProps {
  setAPIEndpoints: React.Dispatch<
    React.SetStateAction<APIEndpointIntegrationBuilder[] | undefined>
  >;
  integrationID: string;
  setSelectedAPIEndpoint?: React.Dispatch<
    React.SetStateAction<APIEndpointIntegrationBuilder | undefined>
  >;
  setPaginationConfigurations: React.Dispatch<
    React.SetStateAction<PaginationConfigurationIntegrationBuilder[] | undefined>
  >;
  setRateLimitConfigurations: React.Dispatch<
    React.SetStateAction<RateLimitConfigurationIntegrationBuilder[] | undefined>
  >;
}

const useCreateOrPatchAPIEndpoint = ({
  setAPIEndpoints,
  integrationID,
  setSelectedAPIEndpoint,
  setPaginationConfigurations,
  setRateLimitConfigurations,
}: CreateOrPatchAPIEndpointProps) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  // context
  const history = useHistory();
  const { setIsAPIEndpointsComplete, resetDiffStates, asyncResetDiffStates } = useContext(
    IntegrationBuilderContext
  );
  const { createPaginationConfiguration } = useCreateOrPatchPaginationConfiguration({
    setPaginationConfigurations,
    integrationID,
  });
  const {
    createRateLimitConfiguration,
    patchRateLimitConfiguration,
  } = useCreateOrPatchRateLimitConfiguration({
    integrationID: integrationID,
    setRateLimitConfigurations: setRateLimitConfigurations,
    isSubmittingAPIEndpoint: true,
  });
  const { deleteRateLimitConfiguration } = useDeleteRateLimitConfiguration({
    integrationID: integrationID,
    setRateLimitConfigurations: setRateLimitConfigurations,
    isSubmittingAPIEndpoint: true,
  });

  // function to create a new api endpoint
  // can optionally pass in pagination configuration data to create a pagination configuration alongside the api endpoint
  const createAPIEndpoint = async (
    data: APIEndpointIntegrationBuilder,
    paginationConfigData?: PaginationConfigurationIntegrationBuilder
  ) => {
    setIsLoadingSubmit(true);

    // Create pagination configuration if data is passed for it
    if (paginationConfigData) {
      const paginationConfig = await createPaginationConfiguration(paginationConfigData, false);

      // Update the api endpoint data with the pagination configuration ID
      data.pagination_configuration_information = {
        pagination_configuration_id: paginationConfig.is_default_for_integration
          ? null
          : paginationConfig.id || null,
        pagination_response_body_array_key_path_override: null,
      };
    }

    fetchWithAuth({
      path: `/integrations/integration-builder/integration/${integrationID}/api-endpoints`,
      method: APIEndpointMethod.POST,
      body: data,
      onResponse: async (responseData: APIEndpointIntegrationBuilder) => {
        await asyncResetDiffStates();

        showSuccessToast("API endpoint successfully created!");
        setSelectedAPIEndpoint && setSelectedAPIEndpoint(responseData);
        // reset diff states before navigating, otherwise "unsaved changes" modal will pop up
        // navigates to url for api endpoint with id
        if (responseData.id) {
          navigateToIntegrationBuilderAPIEndpointForm(history, integrationID, responseData.id);
        } else {
          navigateToIntegrationBuilderAPIEndpointTable(history, integrationID);
        }
        // add new api endpoint to apiEndpoints array
        setAPIEndpoints((prevAPIEndpoints) => {
          return prevAPIEndpoints ? [...prevAPIEndpoints, responseData] : [responseData];
        });
        setIsLoadingSubmit(false);
        setIsAPIEndpointsComplete(true);
      },
      onError: () => {
        showErrorToast("Failed to create your API endpoint.");
        setIsLoadingSubmit(false);
      },
    });
  };

  // function to update an existing api endpoint
  // can optionally pass in pagination configuration data to create a pagination configuration alongside the api endpoint
  const patchAPIEndpoint = async (
    data: APIEndpointIntegrationBuilder,
    paginationConfigData?: PaginationConfigurationIntegrationBuilder,
    rateLimitConfigurationFormData?: (RateLimitConfigurationIntegrationBuilder | null)[],
    rateLimitConfigurationsToDelete?: RateLimitConfigurationIntegrationBuilder[]
  ) => {
    setIsLoadingSubmit(true);
    // Create pagination configuration if data is passed for it
    if (paginationConfigData) {
      const paginationConfig = await createPaginationConfiguration(paginationConfigData, false);

      // Update the api endpoint data with the pagination configuration ID
      data.pagination_configuration_information = {
        pagination_configuration_id: paginationConfig.id || null,
        pagination_response_body_array_key_path_override: null,
      };
    }

    //##### RATE LIMIT CONFIGURATION CREATION, PATCH, AND DELETION #####//
    if (rateLimitConfigurationFormData) {
      const updatePromises = rateLimitConfigurationFormData
        .filter(
          (configData): configData is RateLimitConfigurationIntegrationBuilder =>
            configData !== null
        )
        .map(async (configData) => {
          if (configData.id === undefined) {
            return createRateLimitConfiguration(configData);
          } else {
            return patchRateLimitConfiguration(configData);
          }
        });
      await Promise.all(updatePromises);
    }

    if (rateLimitConfigurationsToDelete && rateLimitConfigurationsToDelete.length > 0) {
      const deletePromises = rateLimitConfigurationsToDelete.map(async (config) => {
        if (config.id) {
          return deleteRateLimitConfiguration(config.id);
        }
      });
      await Promise.all(deletePromises);
    }

    fetchWithAuth({
      path: `/integrations/integration-builder/api-endpoint/${data.id}`,
      method: APIEndpointMethod.PATCH,
      body: data,
      onResponse: (responseData: APIEndpointIntegrationBuilder) => {
        showSuccessToast("API endpoint successfully updated!");
        // reset diff states before navigating, otherwise "unsaved changes" modal will pop up
        resetDiffStates();
        setSelectedAPIEndpoint && setSelectedAPIEndpoint(responseData);
        setAPIEndpoints((prevAPIEndpoints) => {
          return prevAPIEndpoints?.map((endpoint) => {
            if (endpoint.id === responseData.id) {
              return responseData;
            }
            return endpoint;
          });
        });
        if (responseData.id) {
          navigateToIntegrationBuilderAPIEndpointForm(history, integrationID, responseData.id);
        } else {
          navigateToIntegrationBuilderAPIEndpointTable(history, integrationID);
        }
        setIsLoadingSubmit(false);
      },
      onError: () => {
        showErrorToast("Failed to update your API endpoint.");
        setIsLoadingSubmit(false);
      },
    });
  };

  return { createAPIEndpoint, patchAPIEndpoint, isLoadingSubmit };
};

export default useCreateOrPatchAPIEndpoint;
