import React from "react";
import styled from "styled-components";

interface Props {
  /**
   * The name of this icon in feather-icons (leave off the fe- prefix!)
   */
  name: string;

  /**
   * If specified, the icon color, defaulting to currentcolor.
   */
  color?: string;

  /**
   * If specified, the icon size. Defaults to 20x20px
   */
  size?: string;

  /**
   * If passed, applies the color only if non-mobile
   */
  applyColorOnlyOnDesktop?: true;

  /*
   * If passed, sets an onclick function to trigger on click
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

// Adds defaults from the props
const SpannedIcon = styled.span<{ $applyColor?: true; $color?: string; $size?: string }>`
  color: ${({ $color, $applyColor }) => (!$applyColor && $color) || "currentcolor"};
  @media (min-width: 768px) {
    color: ${({ $color }) => $color ?? "currentcolor"};
  }
  &::before {
    font-size: ${({ $size }) => $size ?? "16px"};
  }
`;

/**
 * Creates a feather icon with a given name and perhaps a color.
 */
const Icon = ({
  name,
  color,
  size,
  applyColorOnlyOnDesktop,
  className,
  onClick,
}: Props & Pick<React.ComponentProps<"span">, "className">) => (
  <SpannedIcon
    onClick={onClick}
    $color={color}
    $size={size}
    className={`${className ?? ""} fe fe-${name}`}
    $applyColor={applyColorOnlyOnDesktop}
  />
);

export default Icon;
