import { fetchWithAuth } from "../../../../api-client/api_client";
import { VALIDATOR_TRIGGER_TYPES } from "../../../../constants";
import { showErrorToast } from "../../../shared/Toasts";
import { useState } from "react";
import { BlueprintValidatorResult, PublishModuleInfo } from "../types";
import { delay } from "../utils";

interface Props {
  publishModuleInfo: PublishModuleInfo | undefined;
}

const useLoadValidation = ({ publishModuleInfo }: Props) => {
  const [blueprintValidatorInfo, setBlueprintValidatorInfo] = useState<
    Record<string, BlueprintValidatorResult>
  >({});
  const [isRunningValidator, setIsRunningValidator] = useState<boolean>(false);

  const runValidationCheck = (blueprintVersionID: string) => {
    return fetchWithAuth({
      path: `/blueprints/validator/run`,
      method: "POST",
      body: {
        validator_trigger: VALIDATOR_TRIGGER_TYPES.PUBLICATION,
        blueprint_version_id: blueprintVersionID,
      },
      onResponse: (result: BlueprintValidatorResult) => {
        // Appending validator results onto Record
        setBlueprintValidatorInfo((prevResultInfo) => ({
          ...prevResultInfo,
          [blueprintVersionID]: result,
        }));
      },
      onError: () => {
        showErrorToast(
          `Error running validation check for blueprint version ${blueprintVersionID}.`
        );
      },
    });
  };

  const runAllValidationChecks = () => {
    setIsRunningValidator(true);
    let validationPromises: any[] = [];

    if (publishModuleInfo?.staged_changes?.Blueprint?.length ?? 0) {
      validationPromises = (
        publishModuleInfo?.staged_changes?.Blueprint ?? []
      ).map((staged_blueprint) => runValidationCheck(staged_blueprint.component_version_id));
    }

    Promise.all(validationPromises)
      .then(() => delay(500))
      .then(() => {
        setIsRunningValidator(false);
      })
      .catch((error) => {
        showErrorToast(`Error running validation check: ${error}.`);
        setIsRunningValidator(false);
      });
  };

  return {
    blueprintValidatorInfo,
    isRunningValidator,
    runAllValidationChecks,
  };
};

export default useLoadValidation;
