import FullPageSpinner from "../../shared/layout/FullPageSpinner";
import { useParams } from "react-router-dom";
import { TableTitleContainer, TableTitleText } from "../../shared/utils/SharedComponentUtils";
import { ReportTemplate } from "../../../models/Entities";
import ReportTemplateTable from "./ReportTemplateTable";

type RouteParams = {
  integrationID: string;
  operationType: string;
};

type Props = {
  reportTemplates: ReportTemplate[] | undefined;
  isLoading: boolean;
};

const ReportTemplatesOverview = ({ reportTemplates, isLoading }: Props) => {
  const { integrationID } = useParams<RouteParams>();

  return (
    <>
      <TableTitleContainer>
        <TableTitleText>Report Templates</TableTitleText>
      </TableTitleContainer>
      {isLoading || !reportTemplates ? (
        <FullPageSpinner />
      ) : (
        <ReportTemplateTable reportTemplates={reportTemplates} integrationID={integrationID} />
      )}
    </>
  );
};

export default ReportTemplatesOverview;
