import { JSONObjectSchema, BlueprintOperationType } from "../../../models/Blueprints";
import { JSONSchemaTypeOption } from "../utils/BlueprintDataTransformUtils";
import BlueprintValidationEditorField from "./BlueprintValidationEditorField";
import BlueprintValidationEditorNewField from "./BlueprintValidationEditorNewField";
import { BlueprintValidationActions } from "./BlueprintValidationEditorUtils";
import { useState } from "react";
import { DeleteFieldModal } from "./BlueprintValidationEdtiorFieldDeleteModal";

type Props = {
  fieldKey: string;
  objectJSONSchema: JSONObjectSchema;
  traversalPath: Array<string>;
  validationActions: BlueprintValidationActions;
  level: number;
  operationType: undefined | string;
};

const KEYS_WITH_EDITABLE_FIELDS = ["integration_params"];

const BlueprintValidationEditorObjectFieldSet = ({
  fieldKey,
  objectJSONSchema,
  traversalPath,
  validationActions,
  level,
  operationType,
}: Props) => {
  const requiredFields = objectJSONSchema.required ?? [];
  const shouldAllowEditableFields =
    KEYS_WITH_EDITABLE_FIELDS.includes(fieldKey) ||
    operationType === BlueprintOperationType.FUNCTIONAL;

  const [isShowingDeleteFieldModal, setIsShowingDeleteFieldModal] = useState<boolean>(false);
  const [selectedFieldKey, setSelectedFieldKey] = useState<string>("");

  return (
    <>
      {Object.entries(objectJSONSchema.properties).map((k, index, arr) => {
        const [childFieldKey, childFieldValue] = k;
        const isFieldRequired = requiredFields.includes(childFieldKey);

        return (
          <BlueprintValidationEditorField
            level={level}
            key={childFieldKey}
            fieldKey={childFieldKey}
            fieldValue={childFieldValue}
            traversalPath={[...traversalPath, "properties", childFieldKey]}
            toggleIsFieldRequired={() => {
              if (isFieldRequired) {
                validationActions.setNotRequiredForField(
                  objectJSONSchema,
                  traversalPath,
                  childFieldKey
                );
              } else {
                validationActions.setRequiredForField(
                  objectJSONSchema,
                  traversalPath,
                  childFieldKey
                );
              }
            }}
            removeField={
              shouldAllowEditableFields
                ? () => {
                    if (fieldKey === "integration_params") {
                      setIsShowingDeleteFieldModal(true);
                      setSelectedFieldKey(childFieldKey);
                    } else {
                      validationActions.removeFieldFromObject(traversalPath, childFieldKey);
                    }
                  }
                : undefined
            }
            operationType={operationType}
            isFieldRequired={isFieldRequired}
            validationActions={validationActions}
            showBorderAboveIcon={index === 0}
            showBorderBelowContent={index < arr.length - 1}
            verticalLineConfig={index === arr.length - 1 ? "bottom-half" : "all"}
          />
        );
      })}
      {shouldAllowEditableFields && (
        <BlueprintValidationEditorNewField
          addField={(k: string, t: JSONSchemaTypeOption) =>
            validationActions.addFieldToObject(traversalPath, k, t)
          }
          level={level}
        />
      )}
      <DeleteFieldModal
        show={isShowingDeleteFieldModal}
        onHide={() => {
          setIsShowingDeleteFieldModal(false);
        }}
        onConfirm={() => {
          validationActions.removeFieldFromObject(traversalPath, selectedFieldKey);
          setIsShowingDeleteFieldModal(false);
        }}
        field={selectedFieldKey}
      />
    </>
  );
};

export default BlueprintValidationEditorObjectFieldSet;
