import { DiffState } from "../../../../models/DiffModels";
import { generateDiffState } from "./helpers-diff";

// Generate diff for Merge Link steps configurations
export const generateDiffForMergeLinkStepsConfiguration = (): DiffState => {
  return generateDiffState({
    fields: [
      {
        name: "required_organization_config_fields",
        displayName: "Required customer credential fields",
      },
      {
        name: "optional_organization_config_fields",
        displayName: "Optional customer credential fields",
      },
      {
        name: "show_admin_needed_screen",
        displayName: "Should show admin needed Link screen?",
      },
    ],
  });
};
