import { Alert, Badge, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import {
  LinkedAccount,
  mapDisplayableLinkedAccountStatus,
  mapLinkedAccountStatusToBadgeColor,
} from "../../../models/Entities";
import { Info } from "lucide-react";

interface Props {
  option: LinkedAccount;
}

const LinkedAccountTypeaheadRenderOption = ({ option }: Props) => {
  const isCustomerLinkedAccount = option?.organization?.is_merge_org == false;

  return (
    <div className="flex flex-col w-full space-y-2">
      {isCustomerLinkedAccount && (
        <Alert
          color="blue"
          size="sm"
          icon={<Info size={12} />}
          title={
            <Text className="mt-[1px]" variant="sm">
              Customer Linked Account. Use with caution.
            </Text>
          }
        />
      )}
      <div className="flex flex-row items-center justify-between w-full">
        <div className="flex flex-col items-start w-2/3 truncate">
          <Text variant="sm" className="text-gray-70 w-full truncate">
            Created on {new Date(option.created_at).toLocaleDateString()}
          </Text>
          <Tooltip title={option.end_user.organization_name} className="w-full truncate">
            <Text className="w-full truncate">{option.end_user.organization_name}</Text>
          </Tooltip>
          <Text variant="sm" className="text-gray-70 w-full truncate">
            {`ID: ${option.id}`}
          </Text>
          {isCustomerLinkedAccount && (
            <Text variant="sm" className="text-gray-70 w-full truncate">
              {option.organization.name}
            </Text>
          )}
        </div>
        <div className="flex flex-row space-x-2">
          {option.status && (
            <Badge size="sm" color={mapLinkedAccountStatusToBadgeColor[option.status]}>
              {mapDisplayableLinkedAccountStatus[option.status]}
            </Badge>
          )}
          {option.is_sandbox_account && <Badge size="sm">Sandbox</Badge>}
          {isCustomerLinkedAccount && <Badge size="sm">Customer</Badge>}
        </div>
      </div>
    </div>
  );
};

export default LinkedAccountTypeaheadRenderOption;
