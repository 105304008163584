import classNames from "classnames";
import { AddStepRelation, BlueprintStep } from "../../../models/Blueprints";
import useBlueprintContext from "../context/useBlueprintContext";
import { getGhostStepID } from "../utils/BlueprintCanvasUtils";

interface Props {
  step: BlueprintStep;
  relation: AddStepRelation;
}

const AddStepArrow = ({ step, relation }: Props) => {
  const { setSelectedStep } = useBlueprintContext();

  const ghostStepID = getGhostStepID(step.id, relation);
  const arrowClass = classNames(
    "card-arrow-box",
    relation === AddStepRelation.SIBLING_AFTER ? "card-arrow-bottom" : undefined
  );

  return (
    <div
      className="cursor-pointer"
      onClick={() =>
        setSelectedStep({
          id: ghostStepID,
          relatedStepID: step.id,
          newStepRelation: relation,
          template: "ghost",
        })
      }
    >
      <div className={arrowClass}>
        <div className="arrow-padding">
          <button className="arrow-button fe fe-plus"></button>
        </div>
      </div>
    </div>
  );
};

export default AddStepArrow;
