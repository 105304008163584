import { Text } from "@merge-api/merge-javascript-shared";
import {
  ListDelimiter,
  ListTransformationInformation,
} from "../../../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import LineIndent from "../../../../../authentication/components/shared/LineIndent";
import CheckboxHeader from "../../../../../shared/CheckboxHeader";
import MultiSelectHeader from "../../../../../shared/MultiSelectHeader";
import RequiredFieldAsterisk from "../../../../../shared/RequiredFieldAsterisk";
import { ListDelimiterEnum } from "../../../../types";

type Props = {
  listTransformationInformation: ListTransformationInformation;
  setListTransformationInformation: (newVal: ListTransformationInformation) => void;
};

const FALSEY_LIST_TRANSFORMATION: ListTransformationInformation = {
  should_insert_as_repeated_query_parameters: false,
  is_non_native_or: false,
  list_delimiter: null,
};

const hasTruthyValue = (obj: ListTransformationInformation): boolean => {
  return Object.values(obj).some((value) => Boolean(value));
};

const TransformValuesModalCheckboxes = ({
  listTransformationInformation,
  setListTransformationInformation,
}: Props) => {
  const handleListDelimiterButtonClick = (value: ListDelimiter) => {
    const newListTransformation = {
      ...FALSEY_LIST_TRANSFORMATION,
      list_delimiter: value,
    };

    setListTransformationInformation(newListTransformation);
  };

  const listDelimiterOptions = Object.entries(ListDelimiterEnum).map(([option, val]) => {
    return {
      value: val,
      text: option,
      disabled: false,
      selected: listTransformationInformation?.list_delimiter === val,
      onClick: handleListDelimiterButtonClick,
    };
  });

  return (
    <div className="flex flex-col mb-6">
      <div className="flex flex-row items-center mb-2">
        <Text variant="h6">Select an option </Text>
        {!hasTruthyValue(listTransformationInformation) && (
          <RequiredFieldAsterisk showText={false} />
        )}
      </div>
      <CheckboxHeader
        key="1"
        className="mb-2"
        title="Make a separate request for each value in list. (non native ors)."
        subtitle="Description lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
        variant="secondary"
        checked={listTransformationInformation.is_non_native_or}
        onChange={(newVal) => {
          const newListTransformation = {
            ...FALSEY_LIST_TRANSFORMATION,
            is_non_native_or: newVal,
          };

          setListTransformationInformation(newListTransformation);
        }}
      />
      <CheckboxHeader
        key="2"
        className="mb-2"
        title="Include list in one request, as repeat usages of the same query parameter."
        subtitle="Description lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
        variant="secondary"
        checked={listTransformationInformation.should_insert_as_repeated_query_parameters}
        onChange={(newVal) => {
          const newListTransformation = {
            ...FALSEY_LIST_TRANSFORMATION,
            should_insert_as_repeated_query_parameters: newVal,
          };

          setListTransformationInformation(newListTransformation);
        }}
      />
      <CheckboxHeader
        key="3"
        title="Include entire list in a single query parameter."
        subtitle="Description lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
        variant="secondary"
        checked={!!listTransformationInformation?.list_delimiter}
        onChange={(newVal) => {
          const newListTransformation = {
            ...FALSEY_LIST_TRANSFORMATION,
            list_delimiter: newVal ? ListDelimiterEnum.Comma : null,
          };

          setListTransformationInformation(newListTransformation);
        }}
      />
      {listTransformationInformation?.list_delimiter && (
        <LineIndent className="mt-2 ml-7">
          <MultiSelectHeader
            title="List delimiter"
            subtitle="Description lorem ipsum dolor sit amet, consectetur adipiscing elit"
            options={listDelimiterOptions}
          />
        </LineIndent>
      )}
    </div>
  );
};

export default TransformValuesModalCheckboxes;
