import { AdminAPICategory, Text, Typeahead } from "@merge-api/merge-javascript-shared";
import React from "react";
import { LinkedAccount } from "../../../models/Entities";
import useLoadLinkedAccounts from "../ai-search/hooks/useLoadLinkedAccounts";
import LinkedAccountTypeaheadRenderOption from "../../shared/linked-account-typeahead/LinkedAccountTypeaheadRenderOption";
import { filterLinkedAccountTypeaheadOptions } from "../../shared/linked-account-typeahead/LinkedAccountTypeaheadFilter";

interface Props {
  integrationID: string;
  category: AdminAPICategory;
  linkedAccountInfo: LinkedAccount | null;
  className?: string;
  setLinkedAccountInfo: React.Dispatch<React.SetStateAction<LinkedAccount | null>>;
}

const LinkedAccountsTypeahead = ({
  integrationID,
  category,
  linkedAccountInfo,
  setLinkedAccountInfo,
  className = "",
}: Props) => {
  const { linkedAccounts } = useLoadLinkedAccounts({
    integrationID: integrationID,
    category,
  });

  const filteredLinkedAccounts = linkedAccounts.filter(
    (account) => account.id !== linkedAccountInfo?.id
  );

  return (
    <div className="flex flex-col space-y-2">
      <Text variant="h6">Linked account</Text>
      <Typeahead
        className={className}
        onChange={(_: any, option: LinkedAccount | null) => {
          setLinkedAccountInfo(option ? option : null);
        }}
        options={filteredLinkedAccounts}
        value={linkedAccountInfo}
        getOptionLabel={(option: LinkedAccount) => {
          return option.end_user?.organization_name;
        }}
        renderOption={(option: LinkedAccount) => (
          <LinkedAccountTypeaheadRenderOption option={option} />
        )}
        filterOptions={(options: LinkedAccount[], state: any) => {
          return filterLinkedAccountTypeaheadOptions(options, state);
        }}
      />
    </div>
  );
};

export default LinkedAccountsTypeahead;
