import isEqual from "lodash/isEqual";
import { memo, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { BlueprintMeta } from "../../../models/Entities";
import { MappingTestBlock, MappingTestRequestMock } from "../../../models/MappingTests";
import styled from "styled-components";
import clsx from "clsx";
import { Text, Tooltip } from "@merge-api/merge-javascript-shared";

const ExistingRequestMock = styled.div`
  height: 40px;
  color: #000000;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 0.5px solid #dce2ea;
  border-top: none;
  background: #ffffff;
`;

const DeleteButton = styled(Button)`
  z-index: 1000;
  bottom: 24px;
  position: relative;
  height: 30px;
  align-content: center;
  line-height: 0px;
  font-size: 14px;
`;

type Props = {
  /** The fallback name of the request mock, in the case the request mock was deleted and not removed from the UI */
  deletedRequestMockFallbackName: string;
  mappingTestBlock: MappingTestBlock;
  blueprint: BlueprintMeta;
  requestMock: MappingTestRequestMock;
  removeRequestMockFromMappingTestBlock: (
    mappingTestBlockID: string,
    blueprintID: string,
    requestMock: MappingTestRequestMock,
    deletedRequestMockName?: string
  ) => void;
};

const MappingTestBlockRequestMockRow = ({
  deletedRequestMockFallbackName,
  requestMock,
  mappingTestBlock,
  blueprint,
  removeRequestMockFromMappingTestBlock,
}: Props) => {
  // state
  const [showOptions, setShowOptions] = useState(false);
  const deleteOptionRef = useRef<HTMLDivElement>(null);
  // const derived from state

  const isRequestMockDeleted = !requestMock;
  // functions
  const handleOptionsClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setShowOptions((prevShowDeleteOption) => !prevShowDeleteOption);
  };

  const removeRequestFromBlueprint = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    removeRequestMockFromMappingTestBlock(
      mappingTestBlock.id,
      blueprint.blueprint_id,
      requestMock,
      deletedRequestMockFallbackName
    );
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (deleteOptionRef.current && !deleteOptionRef.current.contains(event.target as Node)) {
      setShowOptions(false);
    }
  };

  // hooks
  useEffect(() => {
    const handleOutsideClickEvent = (event: MouseEvent) => {
      handleOutsideClick(event);
    };

    document.addEventListener("click", handleOutsideClickEvent);

    return () => {
      document.removeEventListener("click", handleOutsideClickEvent);
    };
  }, []);

  const optionsTooltip = () => (
    <div className="d-flex flex-column">
      <div className={`fe fe-more-horizontal`} onClick={handleOptionsClick} />
      {showOptions && (
        <div ref={deleteOptionRef} className="">
          <DeleteButton
            onClick={(event: React.MouseEvent<HTMLDivElement>) => removeRequestFromBlueprint(event)}
            variant="white"
          >
            Delete
          </DeleteButton>
        </div>
      )}
    </div>
  );

  return (
    <ExistingRequestMock
      className={clsx(
        blueprint.blueprint_overview_publish_state === "DRAFT" &&
          "border border-b-red-50 border-l-red-50 border-r-red-50 bg-red-0",
        isRequestMockDeleted && "bg-red-0"
      )}
    >
      {isRequestMockDeleted ? (
        <Tooltip title="This request mock has been deleted. Please remove it from this blueprint or you will encounter an exception when runnning the mapping test">
          <Text className="font-semibold">{deletedRequestMockFallbackName}</Text>
        </Tooltip>
      ) : (
        <Text className="font-semibold">{requestMock?.name}</Text>
      )}
      {optionsTooltip()}
    </ExistingRequestMock>
  );
};

export default memo(MappingTestBlockRequestMockRow, isEqual);
