import { Text } from "@merge-api/merge-javascript-shared";
import { Integration } from "../../../../../../models/Entities";
import { IntegrationAvatar } from "../../../../../shared/MergeAvatars";

type NameCellProps = {
  integration: Integration;
};

const NameCell = ({ integration }: NameCellProps) => {
  return (
    <td className="w-1/3">
      <div className="flex flex-row items-center">
        <IntegrationAvatar integration={integration} />
        <Text variant="h6" className="ml-4 text-base">
          {integration.name}
        </Text>
      </div>
    </td>
  );
};

export default NameCell;
