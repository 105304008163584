import { RateLimitTimePeriod, RateLimitType } from "../../utils/Entities";
import { SelectOptionsData, StringOptionsData } from "../../utils/types";

export const RATE_LIMIT_TYPE_TO_DISPLAY_NAME = {
  [RateLimitType.REQUEST_FREQUENCY]: "Request frequency rate limit",
  [RateLimitType.MODEL_COUNT]: "Fetched model count rate limit",
  [RateLimitType.COMPLEXITY_COUNT]: "Complexity rate limit",
  [RateLimitType.CONNECTION_COUNT]: "Concurrent session rate limit",
  [RateLimitType.FAILED_REQUEST_COUNT]: "Failed request rate limit",
};

export const RATE_LIMIT_TYPE_SELECT_OPTIONS: SelectOptionsData[] = [
  {
    value: RateLimitType.REQUEST_FREQUENCY,
    title: RATE_LIMIT_TYPE_TO_DISPLAY_NAME[RateLimitType.REQUEST_FREQUENCY],
    subtitle: "This limit is based on the number of requests in a defined time range",
  },
  {
    value: RateLimitType.MODEL_COUNT,
    title: RATE_LIMIT_TYPE_TO_DISPLAY_NAME[RateLimitType.MODEL_COUNT],
    subtitle: `This limit is based on the amount of data fetched in a defined time range and is often configured as "entities" or "models" returned in response payloads from the third-party`,
  },
  {
    value: RateLimitType.COMPLEXITY_COUNT,
    title: RATE_LIMIT_TYPE_TO_DISPLAY_NAME[RateLimitType.COMPLEXITY_COUNT],
    subtitle:
      "This rate limit is used uniquely for GraphQL integrations to support float based complexity scores",
  },
  {
    value: RateLimitType.CONNECTION_COUNT,
    title: RATE_LIMIT_TYPE_TO_DISPLAY_NAME[RateLimitType.CONNECTION_COUNT],
    subtitle:
      "This limit is based on the number of active client/server sessions established with the 3rd party service",
  },
  {
    value: RateLimitType.FAILED_REQUEST_COUNT,
    title: RATE_LIMIT_TYPE_TO_DISPLAY_NAME[RateLimitType.FAILED_REQUEST_COUNT],
    subtitle:
      "This limit will start restricting access if too many failed requests are made in a row",
  },
];

export const RATE_LIMIT_TIME_PERIOD_OPTIONS: StringOptionsData[] = [
  { value: RateLimitTimePeriod.SECONDS, text: "seconds" },
  { value: RateLimitTimePeriod.MINUTES, text: "minutes" },
  { value: RateLimitTimePeriod.HOURS, text: "hours" },
  { value: RateLimitTimePeriod.DAYS, text: "days" },
  { value: RateLimitTimePeriod.MONTHS, text: "months" },
  { value: RateLimitTimePeriod.END_OF_MONTH, text: "end of month" },
];

export const rateLimitTypeRequestDisplayStrings: Record<RateLimitType, string> = {
  [RateLimitType.REQUEST_FREQUENCY]: "requests",
  [RateLimitType.MODEL_COUNT]: "models",
  [RateLimitType.COMPLEXITY_COUNT]: "points",
  [RateLimitType.CONNECTION_COUNT]: "sessions",
  [RateLimitType.FAILED_REQUEST_COUNT]: "requests",
};

export const rateLimitTypeTableRequestDisplayStrings: Record<RateLimitType, string> = {
  [RateLimitType.REQUEST_FREQUENCY]: "Request frequency",
  [RateLimitType.MODEL_COUNT]: "Fetched model count",
  [RateLimitType.COMPLEXITY_COUNT]: "Complexity",
  [RateLimitType.CONNECTION_COUNT]: "Concurrent session",
  [RateLimitType.FAILED_REQUEST_COUNT]: "Failed request",
};

export const rateLimitTimeValueTabletDisplayStrings: Record<RateLimitTimePeriod, string> = {
  [RateLimitTimePeriod.END_OF_MONTH]: "EOM",
  [RateLimitTimePeriod.MONTHS]: "month",
  [RateLimitTimePeriod.DAYS]: "day",
  [RateLimitTimePeriod.HOURS]: "hr",
  [RateLimitTimePeriod.MINUTES]: "min",
  [RateLimitTimePeriod.SECONDS]: "sec",
};

export function getRateLimitTimeValueTabletDisplayStrings(
  timePeriod: RateLimitTimePeriod | undefined
): string {
  if (timePeriod) {
    return rateLimitTimeValueTabletDisplayStrings[timePeriod];
  } else {
    return "";
  }
}
