import { Accordion, Badge, Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import FieldOverrideFieldsCard from "./FieldOverrideFieldsCard";
import { LinkChoiceStepOption } from "../../../utils/Entities";
import { addFormFieldValue } from "../../utils/helpers";
import { Plus } from "lucide-react";

interface Props {
  indexStepOption: number;
  requestedLinkChoiceStepOption: LinkChoiceStepOption;
  onCallbackUpdateLinkChoiceStepOption: (index: number, keyValuePairs: Record<string, any>) => void;
}

const FieldOverridesFields = ({
  indexStepOption,
  requestedLinkChoiceStepOption,
  onCallbackUpdateLinkChoiceStepOption,
}: Props) => {
  return (
    <Accordion
      chevronSize={16}
      chevronOrientation="right"
      onChange={function noRefCheck() {}}
      title={
        <div
          data-testid={`accordion-option-card-field-overrides-${indexStepOption}`}
          className="flex flex-row items-center justify-start space-x-2"
        >
          <div>
            <Text variant="h6">Field overrides</Text>
          </div>
          <div>
            <Text variant="sm" className="text-gray-70">
              Optional
            </Text>
          </div>
          <Badge color="blue">{requestedLinkChoiceStepOption.form_field_values?.length || 0}</Badge>
        </div>
      }
      titleClassName="px-5 py-4"
      variant="outline"
    >
      <div>
        {(requestedLinkChoiceStepOption.form_field_values || []).map(
          (formFieldValue, indexFormFieldValue) => {
            return (
              <FieldOverrideFieldsCard
                key={indexFormFieldValue}
                indexFormFieldValue={indexFormFieldValue}
                formFieldValue={formFieldValue}
                requestedLinkChoiceStepOption={requestedLinkChoiceStepOption}
                onCallbackUpdateLinkChoiceStepOption={onCallbackUpdateLinkChoiceStepOption}
                indexStepOption={indexStepOption}
              />
            );
          }
        )}
        <div className="border-t-[0.5px] border-gray-20 p-5">
          <Button
            leftIcon={<Plus size={16} />}
            variant={ButtonVariant.TertiaryWhite}
            size="sm"
            onClick={() =>
              addFormFieldValue(
                onCallbackUpdateLinkChoiceStepOption,
                indexStepOption,
                requestedLinkChoiceStepOption
              )
            }
          >
            <div data-testid={`button-option-card-add-field-name-override-${indexStepOption}`}>
              Field name override
            </div>
          </Button>
        </div>
      </div>
    </Accordion>
  );
};

export default FieldOverridesFields;
