import IntegrationBuilderContext, {
  IntegrationBuilderContextState,
} from "./IntegrationBuilderContext";

interface Props extends IntegrationBuilderContextState {
  children: JSX.Element;
}

const IntegrationBuilderContextProvider = ({ children, ...contextProps }: Props) => (
  <IntegrationBuilderContext.Provider value={contextProps}>
    {children}
  </IntegrationBuilderContext.Provider>
);

export default IntegrationBuilderContextProvider;
