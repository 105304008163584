import { Alert, Text } from "@merge-api/merge-javascript-shared";
import { Info } from "lucide-react";

interface AuthConfigExplainAlertProps {
  className?: string;
}
const AuthConfigExplainAlert = ({ className }: AuthConfigExplainAlertProps) => {
  return (
    <Alert
      title="What is an authentication configuration?"
      color="gray"
      icon={<Info size={16} />}
      className={className}
    >
      <Text>
        An authentication configuration is the process end users follow to link their account to a
        customer's platform through Merge and authenticate with the third party.
      </Text>
    </Alert>
  );
};

export default AuthConfigExplainAlert;
