import { useContext, useState } from "react";
import AccordionCard from "../../shared/AccordionCard";
import RateLimitConfigurationContext from "../context/RateLimitConfigurationContext";
import TextFieldHeader from "../../shared/TextFieldHeader";
import { isValidNumber } from "../utils/helpers";
import { RateLimitType } from "../../utils/Entities";
import { Link, Text } from "@merge-api/merge-javascript-shared";

const SPREADSHEET_RETRY_TABLE_URL =
  "https://docs.google.com/spreadsheets/d/1X3ELTnwMBKiLMujLqyCItjRe7OMhdjNU2tsq8GrlBRw/edit?usp=sharing";

interface RateLimitAdvancedProps {
  inlineSetup: boolean;
}

const RateLimitAdvanced = ({ inlineSetup }: RateLimitAdvancedProps) => {
  // context
  const {
    rateLimitType,
    defaultIncrement,
    setDefaultIncrement,
    aggressiveRateLimitThreshold,
    setAggressiveRateLimitThreshold,
    maximumRetryCount,
    setMaximumRetryCount,
    retryBackoffFactor,
    setRetryBackoffFactor,
  } = useContext(RateLimitConfigurationContext);

  // state for error message when inputting numbers larger than 90 or smaller than 0
  const [aggressiveRateLimitError, setAggressiveRateLimitError] = useState(false);

  // calculate total badge
  const badgeNumber = rateLimitType === RateLimitType.REQUEST_FREQUENCY ? 4 : 2;
  return (
    <AccordionCard
      dataTestID="accordion-advanced-rate-limit-options"
      title="Advanced"
      className={inlineSetup ? "mt-6" : "my-6"}
      defaultExpanded={false}
      variant={inlineSetup ? "outline" : "shadow"}
      titleVariant={inlineSetup ? "h6" : "h5"}
      badgeContents={inlineSetup ? badgeNumber : undefined}
    >
      <TextFieldHeader
        dataTestID="field-rate-limit-aggressive-rate-limit-threshold"
        title="Aggressive rate limit threshold"
        subtitle="The percentage towards the rate limit that requests will be kept below during initial syncs. This is typically higher than the default_rate_limit_threshold, but should never exceed 80%."
        inputWrapperClassName="w-[120px]"
        error={aggressiveRateLimitError}
        placeholder="Value"
        errorText="The maximum entered percentage value should never exceed 90%"
        value={aggressiveRateLimitThreshold}
        postfix={
          <div className="flex h-full items-center rounded-br-md rounded-tr-md bg-gray-0 px-3 text-black">
            %
          </div>
        }
        type="number"
        onChange={(newEvent) => {
          const value = newEvent.target.value;
          setAggressiveRateLimitError(false);
          if (value === "") {
            setAggressiveRateLimitThreshold(undefined);
          } else {
            const numericValue = parseInt(value, 10);
            if (!isNaN(numericValue)) {
              setAggressiveRateLimitThreshold(numericValue);
              if (!isValidNumber(numericValue, 90)) {
                setAggressiveRateLimitError(true);
              }
            }
          }
        }}
      />
      <TextFieldHeader
        className="mt-6"
        dataTestID="field-rate-limit-default-increment"
        required
        title="Default increment"
        subtitle="This denotes the default increment by which the count is increased for a single API request if the rate limit configuration is obtained during that request. See Oracle Taleo as an example on usage."
        placeholder="Increment value"
        value={defaultIncrement}
        type="number"
        onChange={(newEvent) => {
          const value = newEvent.target.value;
          if (value === "") {
            setDefaultIncrement(undefined);
          } else {
            const numericValue = parseInt(value, 10);
            if (!isNaN(numericValue)) {
              setDefaultIncrement(numericValue);
            }
          }
        }}
      />
      {rateLimitType === RateLimitType.REQUEST_FREQUENCY && (
        <>
          <TextFieldHeader
            dataTestID="field-rate-limit-maximum-retry-count"
            className="mt-6"
            title="Maximum retry count"
            subtitleComponent={
              <Text as="span" className="text-gray-70" variant="sm">
                Maximum number of times to retry when rate limit reached. Only relevant to request
                frequency rate limit type. View spreadsheet{" "}
                <Link href={SPREADSHEET_RETRY_TABLE_URL} target="_blank">
                  here
                </Link>{" "}
                to calculate backoff in seconds.{" "}
              </Text>
            }
            placeholder="Maximum retry value"
            learnMoreText="In the runner, the default value for maximum retry count is set to 7. If you are setting this, use this value as a starting point and iterate based on the rate limits of the third-party."
            value={maximumRetryCount}
            type="number"
            onChange={(newEvent) => {
              const value = newEvent.target.value;
              if (value === "") {
                setMaximumRetryCount(undefined);
              } else {
                const numericValue = parseInt(value, 10);
                if (!isNaN(numericValue)) {
                  setMaximumRetryCount(numericValue);
                }
              }
            }}
          />
          <TextFieldHeader
            className="mt-6"
            dataTestID="field-rate-limit-retry-backoff-factor"
            title="Retry backoff factor"
            subtitle="Relevant only to request frequency rate limit types, this is the backoff factor used in our exponential backoff retry logic when a blueprint is hitting a frequency rate limit."
            subtitleComponent={
              <Text as="span" className="text-gray-70" variant="sm">
                Backoff factor used in exponential backoff retry logic when rate limit reached. Only
                relevant to request frequency rate limit type. View spreadsheet{" "}
                <Link href={SPREADSHEET_RETRY_TABLE_URL} target="_blank">
                  here
                </Link>{" "}
                to calculate backoff in seconds.{" "}
              </Text>
            }
            placeholder="Retry value"
            learnMoreText="In the runner, the default value for retry backoff factor is set to 1. If you are setting this, use this value as a starting point and iterate based on the rate limits of the third-party."
            value={retryBackoffFactor}
            type="number"
            onChange={(newEvent) => {
              const value = newEvent.target.value;
              if (value === "") {
                setRetryBackoffFactor(undefined);
              } else {
                const numericValue = parseInt(value, 10);
                if (!isNaN(numericValue)) {
                  setRetryBackoffFactor(numericValue);
                }
              }
            }}
          />
        </>
      )}
    </AccordionCard>
  );
};
export default RateLimitAdvanced;
