import { WebAddressSchemaIntegrationBuilder } from "../../utils/Entities";

interface URLCellProps {
  webAddressSchema: WebAddressSchemaIntegrationBuilder;
}

const URLCell = ({ webAddressSchema }: URLCellProps) => {
  // const derived from state
  const constructedURL = webAddressSchema?.subdomain
    ? `${webAddressSchema?.scheme}://${webAddressSchema?.subdomain}.${webAddressSchema?.second_level_domain}.${webAddressSchema?.top_level_domain}`
    : `${webAddressSchema?.scheme}://${webAddressSchema?.second_level_domain}.${webAddressSchema?.top_level_domain}`;
  return (
    <td>
      <div className="text-gray-90 text-base font-medium">{constructedURL}</div>
    </td>
  );
};
export default URLCell;
