import useIntegrationBuilderContext from "../../../context/useIntegrationBuilderContext";
import HelpAccordionCard from "../../../shared/HelpAccordionCard";
import HelpAccordionBlock from "../../../shared/HelpAccordionBlock";

const DescriptionHelpAccordionCard = () => {
  const { hasPermissionToEdit } = useIntegrationBuilderContext();

  if (!hasPermissionToEdit) {
    return <></>;
  }

  return (
    <HelpAccordionCard
      title="Guidelines for description"
      children={
        <div className="flex flex-col space-y-4">
          <HelpAccordionBlock
            title="General guidelines"
            body={
              <div>
                - Do NOT mention “Merge”, link should feel like a white-labeled customer experience
                <br />
                - Do NOT repeat the integration name unless needed
                <br />
                - Use sentence casing, aka start with a capital word and the rest are lowercase
                <br />
                - Use ordered list if there is more than 1 step, otherwise use paragraph (Use your
                best discretion)
                <br />
                - Use bold words instead of using quotations if it is something the user has to
                type, search, or click
                <br />- Bulleted lists should not end in periods
              </div>
            }
          />
          <HelpAccordionBlock
            title="Guidelines for URL steps"
            body={
              <div>
                If this step requires users to enter a URL, description should contain:
                <br />
                - Instructions on how to find subdomain (ie.: "Your subdomain can be found [in the
                URL of your account after signing in].")
                <br />
                - An example subdomain (ie.: "For example, if you sign in at “acme.zendesk.com”
                enter **“acme”**.")
                <br />- Counter example (ie.: "Do not enter the full domain “acme.zendesk.com”.")
              </div>
            }
          />
        </div>
      }
    />
  );
};

export default DescriptionHelpAccordionCard;
