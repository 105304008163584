import { useMemo, useCallback, useState, useEffect } from "react";
import classNames from "classnames";
import {
  AddStepRelation,
  BlueprintCanvasViewableStepLog,
  BlueprintIfElseStep,
  BlueprintRunnerStepLogEntryType,
  BlueprintStep,
  BlueprintStepType,
  StepCoverageLevel,
  StepLogIterationInfo,
  StepLoggingViewEnum,
} from "../../../models/Blueprints";
import { STEP_TYPES_TO_BE_DEPRECATED } from "../../../constants";
import {
  isStepTypeWithPaths,
  isStepTypeAPIRequest,
  ExpandStepsButton,
  isStepTypeFunctionalBP,
  calculateCoverageLevelForStep,
} from "../../blueprint-editor/utils/BlueprintEditorUtils";
import { useParams, useHistory } from "react-router-dom";
import { navigateHelper, navigateToBlueprintEditor } from "../../../router/RouterUtils";

import useBlueprintContext from "../context/useBlueprintContext";
import AddStepArrow from "./AddStepArrow";
import isEqual from "lodash/isEqual";
import ContextMenu from "../../shared/ContextMenu";
import StepCard from "../../shared/StepCard";
import { showSuccessToast } from "../../shared/Toasts";
import StepNotePreview from "./StepNotePreview";
import { getLatestVerionIDForBlueprint } from "../utils/BlueprintEditorAPIClient";
import {
  StepCardStatusBadgeProps,
  StepCardStatusBadgeTypeEnum,
} from "../../shared/stepcard/models/StepCardModels";
import StepCardStepLog from "../../shared/stepcard/StepCardStepLog";

type RouteParams = {
  integrationID: string;
};

interface Props {
  isHighlighted: boolean;
  isDescendantofSelectedStep: boolean;
  step: BlueprintStep;
  stepNote: string | null;
  stepLog?: BlueprintCanvasViewableStepLog;
  handleSelectStepLogFromIteration?: (
    stepID: string,
    selectedIterationInfo: StepLogIterationInfo
  ) => void;
}

const BlueprintStepCard = ({
  isHighlighted,
  isDescendantofSelectedStep,
  step,
  stepNote,
  stepLog,
  handleSelectStepLogFromIteration,
}: Props) => {
  const {
    blueprintRunnerExecutionResponse,
    stepTemplates,
    genericStepTemplates,
    setSelectedStep,
    setSelectedSteps,
    deleteStep,
    collapseSubsteps,
    setCopiedStep,
    setCopiedSteps,
    selectedSteps,
    deleteSteps,
    deleteIfElseStepAndMoveChildrenUp,
    stepLoggingView,
    isTracing,
    tracedStepIDs,
  } = useBlueprintContext();
  const history = useHistory();

  const { integrationID } = useParams<RouteParams>();

  const [functionalBlueprintVersionId, setFunctionalBlueprintVersionId] = useState(null);
  const [lastFetchTime, setLastFetchTime] = useState(0);
  const CACHE_DURATION = 300000; // 5 minute timer
  const isFunctionalBpStep = isStepTypeFunctionalBP(step);
  const additionalCardInfo: Record<string, string | [string]> = {};

  // TODO: Paul S - Currently we store a blueprint ID, not a blueprint version ID
  // in our step json for functional BP steps. We need access to the version
  // ID in order to navigate to the blueprint, so we fetch it
  // Once we store the functional blueprint version ID in the step, we will not
  // need to fetch the version ID any more
  // With this implementation we won't spam our latest version ID endpoint
  // with every step render

  // https://app.asana.com/0/1204663397868682/1206049177181091/f
  useEffect(() => {
    // Check if conditions are met
    if (isFunctionalBpStep && integrationID) {
      const blueprintID = step?.template?.metadata?.blueprint_id;
      const currentTime = Date.now();

      // Check if a new API call is needed
      if (!functionalBlueprintVersionId || currentTime - lastFetchTime > CACHE_DURATION) {
        getLatestVerionIDForBlueprint({
          blueprintID,
          onSuccess: (response: any) => {
            setLastFetchTime(Date.now());
            setFunctionalBlueprintVersionId(response.blueprint_version_id);
          },
          onError: (_: any) => {},
        });
      }
    }
  }, [integrationID, step, lastFetchTime]);

  const logsForStep =
    blueprintRunnerExecutionResponse?.exit_data?.step_logs?.filter(
      (log) => log.step_id === step.id
    ) ?? [];

  const stepHasWarning = logsForStep.some(
    (log) => log.entry_type === BlueprintRunnerStepLogEntryType.Warning
  );
  const stepHasError = logsForStep.some(
    (log) => log.entry_type === BlueprintRunnerStepLogEntryType.Error
  );

  const coverageLevel = useMemo(() => {
    return calculateCoverageLevelForStep(
      blueprintRunnerExecutionResponse?.exit_data?.coverage?.step_coverage?.[step.id],
      false
    ).coverageLevel;
  }, [blueprintRunnerExecutionResponse?.exit_data?.coverage?.step_coverage?.[step.id]]);

  const pulledMatchingTemplate = useMemo(() => {
    if ((!stepTemplates && !genericStepTemplates) || !step.template) {
      return null;
    }
    const stepTemplate = stepTemplates
      ? stepTemplates.find((template) => template.id === step.template.id)
      : undefined;
    const genericStepTemplate = genericStepTemplates
      ? genericStepTemplates.find((template) => template.id === step.template.id)
      : undefined;
    return stepTemplate || genericStepTemplate;
  }, [stepTemplates, genericStepTemplates, step.template]);

  const newStepTemplate = useMemo(() => {
    if (pulledMatchingTemplate && !isEqual(pulledMatchingTemplate, step.template)) {
      return pulledMatchingTemplate;
    }
    return null;
  }, [pulledMatchingTemplate, step.template]);

  const isMissingMatchingTemplate = useMemo(
    () => !!stepTemplates.length && !!genericStepTemplates.length && !pulledMatchingTemplate,
    [stepTemplates.length, pulledMatchingTemplate]
  );

  const willBeDeprecated = step.template
    ? STEP_TYPES_TO_BE_DEPRECATED.includes(step.template.step_type)
    : false;

  const shouldHighlightAsTracedStep = useMemo(() => isTracing && tracedStepIDs.includes(step.id), [
    isTracing,
    tracedStepIDs,
  ]);

  const shouldDisplayStepLogInCanvas =
    stepLoggingView === StepLoggingViewEnum.STEP_IO_IN_CANVAS && stepLog;

  const shouldDisplayStepCoverageInCanvas =
    stepLoggingView === StepLoggingViewEnum.STEP_COVERAGE && coverageLevel;

  const cardClass = classNames(
    !shouldDisplayStepCoverageInCanvas
      ? ""
      : coverageLevel === StepCoverageLevel.INCOMPLETE
      ? "bg-amber-0 border border-yellow-50"
      : coverageLevel === StepCoverageLevel.MISSING
      ? "bg-red-0 border border-red-50"
      : "bg-teal-0 border border-teal-50",
    isHighlighted
      ? "shadow-[0px_0px_0px_2px] shadow-blue-40"
      : isDescendantofSelectedStep
      ? "shadow-[0px_0px_0px_2px] shadow-blue-10"
      : null,
    shouldHighlightAsTracedStep && "bg-purple-0",
    !shouldDisplayStepCoverageInCanvas && !shouldHighlightAsTracedStep && "bg-white"
  );

  const [isShowingContextMenu, setIsShowingContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });

  const onRightClickStep = useCallback(
    (e) => {
      e.preventDefault();
      setIsShowingContextMenu(true);
      setContextMenuPosition({ x: e.pageX, y: e.pageY });
    },
    [step, setContextMenuPosition, setIsShowingContextMenu]
  );

  const getContextMenuOptions = () => {
    const isStepInSelection = selectedSteps && selectedSteps.includes(step);
    const selectedStepsLength = isStepInSelection ? selectedSteps.length : -1;
    let baseOptions = [
      {
        label: isStepInSelection ? "Copy " + selectedStepsLength + " steps" : "Copy Step",
        featherIconName: "copy",
        onClick: () => {
          setIsShowingContextMenu(false);
          if (isStepInSelection) {
            setCopiedSteps(selectedSteps.slice());
            navigator.clipboard.writeText(JSON.stringify(selectedSteps.slice()));
            showSuccessToast(selectedStepsLength + " Steps copied.");
          } else {
            setCopiedStep(step as BlueprintStep);
            navigator.clipboard.writeText(JSON.stringify(step));
            showSuccessToast("Step copied.");
          }
        },
      },
      {
        label: isStepInSelection ? "Cut " + selectedStepsLength + " steps" : "Cut Step",
        featherIconName: "scissors",
        onClick: () => {
          setIsShowingContextMenu(false);
          if (isStepInSelection) {
            setCopiedSteps(selectedSteps.slice());
            deleteSteps(selectedSteps as BlueprintStep[]);
            showSuccessToast(selectedStepsLength + " Steps cut.");
          } else {
            setCopiedStep(step as BlueprintStep);
            deleteStep(step.id);
            showSuccessToast("Step cut.");
          }
        },
      },
      {
        label: isStepInSelection ? "Delete " + selectedStepsLength + " steps" : "Delete Step",
        featherIconName: "trash",
        onClick: () => {
          setIsShowingContextMenu(false);
          if (isStepInSelection) {
            deleteSteps(selectedSteps as BlueprintStep[]);
          } else {
            deleteStep(step.id);
          }
        },
        confirmationMessage: `Are you sure you want to delete steps ${
          isStepInSelection ? selectedSteps.map((step) => step.id).join(", ") : step.id
        }?`,
      },
    ];

    const stepHasCollapsableChildren = isStepTypeWithPaths(step);
    if (stepHasCollapsableChildren) {
      baseOptions.push({
        label: step.hasCollapsedSubsteps ? "Expand Substeps" : "Collapse Substeps",
        featherIconName: step.hasCollapsedSubsteps ? "arrow-right" : "arrow-left",
        onClick: () => {
          setIsShowingContextMenu(false);
          collapseSubsteps(step.id);
        },
      });
    }

    const isIfElseStep = step.template.step_type === BlueprintStepType.IfElse;
    if ((isIfElseStep && step.paths?.["true"]?.length) || step.paths?.["false"]?.length) {
      baseOptions.push({
        label: "Delete Step + Move Substeps Up",
        featherIconName: "trash",
        onClick: () => {
          setIsShowingContextMenu(false);
          deleteIfElseStepAndMoveChildrenUp(step as BlueprintIfElseStep);
        },
        confirmationMessage: `Are you sure you want to delete the If/Else step ${step.id}? This will move the substeps up a level, with steps in the 'true' branch coming before steps in the 'false' branch.`,
      });
    }

    const isAPIRequestStep = isStepTypeAPIRequest(step);
    if (isAPIRequestStep && integrationID && step.template?.endpoint) {
      baseOptions.push({
        label: "Navigate to API Endpoint",
        featherIconName: "edit",
        onClick: () => {
          setIsShowingContextMenu(false);
          navigateHelper(
            history,
            `/integration-builder/${integrationID}/api-endpoints/${step.template?.endpoint}`,
            true
          );
        },
      });
    }
    if (isFunctionalBpStep && integrationID) {
      baseOptions.push({
        label: "Navigate to Functional Blueprint",
        featherIconName: "edit",
        onClick: () => {
          setIsShowingContextMenu(false);
          navigateToBlueprintEditor(history, integrationID, functionalBlueprintVersionId!, true);
        },
      });
    }
    return baseOptions;
  };

  // We want to display API Request error codes in the canvas for visibility.
  if (isStepTypeAPIRequest(step)) {
    additionalCardInfo.allowedErrorCodes = step.template?.metadata?.allowed_error_codes ?? [];
  }

  const getStepCardStatusBadge = (): StepCardStatusBadgeProps | undefined => {
    let badgeType: StepCardStatusBadgeTypeEnum = StepCardStatusBadgeTypeEnum.WARNING;
    let tooltipTitle: string = "";
    let badgeTitle: string = "";
    if (isMissingMatchingTemplate) {
      tooltipTitle = "The template ID for this step cannot be found";
      badgeTitle = "Not found";
      badgeType = StepCardStatusBadgeTypeEnum.ERROR;
    } else if (willBeDeprecated) {
      tooltipTitle = "This step will be deprecated";
      badgeTitle = "Deprecated";
    } else if (newStepTemplate) {
      tooltipTitle = "This step has a new step template";
      badgeTitle = "Old version";
    } else if (stepHasWarning) {
      tooltipTitle = "This step ran into a warning. Check step logs.";
      badgeTitle = "Run warning";
    } else if (stepHasError) {
      tooltipTitle = "This step ran into an error. Check step logs.";
      badgeTitle = "Run error";
      badgeType = StepCardStatusBadgeTypeEnum.ERROR;
    } else {
      return undefined;
    }
    return { type: badgeType, tooltipTitle: tooltipTitle, badgeTitle: badgeTitle };
  };

  return (
    <div className="d-flex align-items-center">
      <div>
        <AddStepArrow step={step} relation={AddStepRelation.SIBLING_BEFORE} />
        <ContextMenu
          items={getContextMenuOptions()}
          isShown={isShowingContextMenu}
          position={contextMenuPosition}
          onClose={() => setIsShowingContextMenu(false)}
        />
        <StepCard
          cardClassName={cardClass}
          title={step.template.name}
          stepType={step.template.step_type}
          subtitle={step.id}
          id={`${step.id}-${step.template.name}`}
          stepParameterValues={step.parameter_values}
          stepImg={step.template.image}
          onClick={() => {
            setSelectedStep(step);
            setSelectedSteps(undefined);
            setIsShowingContextMenu(false);
          }}
          onContextMenu={onRightClickStep}
          additionalCardInfo={additionalCardInfo}
          statusBadgeProps={getStepCardStatusBadge()}
        />
        <AddStepArrow step={step} relation={AddStepRelation.SIBLING_AFTER} />
      </div>
      {step.hasCollapsedSubsteps && <ExpandStepsButton onClick={() => collapseSubsteps(step.id)} />}
      {shouldDisplayStepLogInCanvas ? (
        <StepCardStepLog
          stepLog={stepLog}
          handleSelectStepLogFromIteration={handleSelectStepLogFromIteration}
        />
      ) : (
        <StepNotePreview stepNoteText={stepNote} />
      )}
    </div>
  );
};

export default BlueprintStepCard;
