import PublishModuleStagedComponentCard from "./PublishModuleStagedComponentCard";
import { IntegrationComponentNames, PublishModuleInfo } from "../types";
import { Text } from "@merge-api/merge-javascript-shared";
import { formattedModelClassName } from "../../utils/PublishModuleUtils";

type Props = {
  publishModuleInfo: PublishModuleInfo;
  integrationID: string;
  onRefreshAllChecks: () => void;
};

const PublishModuleStagedVersionsCard = ({
  publishModuleInfo,
  integrationID,
  onRefreshAllChecks,
}: Props) => {
  return (
    <div>
      <Text variant="h3" className="mb-6">{`Staged components`}</Text>
      {Object.values(IntegrationComponentNames)
        .sort((a, b) => a.localeCompare(b))
        .map((integrationComponentName) => (
          <div>
            <Text variant="h4" className="mb-6">{`${formattedModelClassName(
              integrationComponentName
            )}`}</Text>
            {
              <PublishModuleStagedComponentCard
                modelClassName={integrationComponentName}
                stagedComponents={publishModuleInfo.staged_changes?.[integrationComponentName]}
                integrationID={integrationID}
                onRefreshAllChecks={onRefreshAllChecks}
              />
            }
          </div>
        ))}
    </div>
  );
};

export default PublishModuleStagedVersionsCard;
