import { Text } from "@merge-api/merge-javascript-shared";

interface Props {
  title: string;
  value: string;
}

/**
 * Renders a row in the StepCardStepLog component.
 * This component displays a title and a corresponding value. (ie.: "Input: {...}")
 *
 * @param title - The title or label for the row
 * @param value - The value to be displayed, typically a stringified JSON object
 */
const StepCardStepLogRow = ({ title, value }: Props) => {
  return (
    <div className="flex flex-row space-x-1">
      <Text variant="title-sm" className="w-20">
        {title}
      </Text>
      <Text variant="sm-mono" className="w-full truncate">
        {value}
      </Text>
    </div>
  );
};

export default StepCardStepLogRow;
