import { SetStateAction, useEffect, useState } from "react";
import { UserFacingFilterType } from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import LineIndent from "../../../authentication/components/shared/LineIndent";
import TypeaheadHeader from "../../../shared/TypeaheadHeader";
import CreateNewNormalizedKeyNameInfo from "./CreateNewNormalizedKeyNameInfo";

type AvailableFilterTypes = {
  [id: string]: UserFacingFilterType;
};

type Props = {
  integrationFilterTypes: AvailableFilterTypes;
  isExistingCommonModelField: boolean;
  currentCommonModelID: string;
  filterCommonModelField: string | null;
  isEditingExistingS2FilterSchema: boolean;
  setFilterTypeID: (filterTypeID: string) => void;
};

const CREATE_NEW_NORMALIZED_KEY_NAME = "Create new normalized key +";

const CREATE_NEW_NORMALIZED_KEY_OPTION: UserFacingFilterType = {
  id: "",
  common_model_class_id: "",
  filter_name: CREATE_NEW_NORMALIZED_KEY_NAME,
  value_type: null,
  is_common_model_field: true,
};

const SelectiveSyncFilterFilterTypeCommonModelFieldKeyContainer = ({
  integrationFilterTypes,
  isExistingCommonModelField,
  currentCommonModelID,
  filterCommonModelField,
  isEditingExistingS2FilterSchema,
  setFilterTypeID,
}: Props) => {
  const [commonModelField, setCommonModelField] = useState<string | null>(
    filterCommonModelField ?? null
  );

  // Need to reset the common model field if we change the value indicating if it's an existing
  // model field or not
  useEffect(() => {
    setCommonModelField(filterCommonModelField ?? null);
  }, [isExistingCommonModelField]);

  const updateCommonModelField = (_: any, value: any | null) => {
    setFilterTypeID(value?.id ?? "");
    setCommonModelField(value?.filter_name as SetStateAction<string | null>);
  };

  return (
    <div className="flex flex-row mb-2 h-full">
      <div className="flex flex-col justify-between w-100">
        <LineIndent>
          <div className="flex flex-col">
            {/* S2TODO: update placholder text - https://app.asana.com/0/1205644398660644/1207534868357516 */}
            <TypeaheadHeader
              title={
                isExistingCommonModelField ? "Select Common Model field/key" : "Normalized key name"
              }
              subtitle="The specific field/key that this filter will be applied to"
              options={(isExistingCommonModelField
                ? []
                : [CREATE_NEW_NORMALIZED_KEY_OPTION]
              ).concat(
                Object.values(integrationFilterTypes)
                  .filter((filterType) => filterType.common_model_class_id == currentCommonModelID)
                  .filter(
                    (filterType) => filterType.is_common_model_field == isExistingCommonModelField
                  )
                  .map((filterType) => filterType)
              )}
              value={commonModelField ?? ("" as any)}
              disabled={isEditingExistingS2FilterSchema}
              onChange={updateCommonModelField}
              required={!commonModelField}
              renderOption={(option) => <div>{option.filter_name}</div>}
              filterOptions={(options: any, { inputValue }: any) => {
                return options.filter((option: UserFacingFilterType) => {
                  const fieldName = option?.filter_name;
                  return fieldName.includes(inputValue);
                });
              }}
            />
            {commonModelField == CREATE_NEW_NORMALIZED_KEY_NAME && (
              <CreateNewNormalizedKeyNameInfo
                showComponent={commonModelField == CREATE_NEW_NORMALIZED_KEY_NAME}
              />
            )}
          </div>
        </LineIndent>
      </div>
    </div>
  );
};

export default SelectiveSyncFilterFilterTypeCommonModelFieldKeyContainer;
