/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { BlueprintOperationType } from "../../../models/Blueprints";
import {
  MappingTestAndVersions,
  MappingTestSuite,
  MappingTestSuiteEndpointCollectionInfo,
} from "../../../models/MappingTests";
import { navigateToTestSuitesTable } from "../../../router/RouterUtils";
import EmptyStateWrapper from "../../shared/layout/EmptyStateWrapper";
import EndpointCollectionCard from "./EndpointCollectionCard";
import FetchMappingTestCard from "./FetchMappingTestCard";
import MappingTestTableViewLinkRow from "./MappingTestTableViewLinkRow";

type Props = {
  endpointCollections: MappingTestSuiteEndpointCollectionInfo[];
  readTest: null | MappingTestAndVersions;
  endUserOrganizationName: string;
  fetchMappingTestSuite: () => void;
  mappingTestSuite: MappingTestSuite | undefined;
};

type RouteParams = {
  integrationID: string;
  mappingTestSuiteID: string;
};

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 600;
`;

const IndividualMappingTestSuiteEndpointCollectionOverview = ({
  endpointCollections,
  readTest,
  endUserOrganizationName,
  fetchMappingTestSuite,
}: Props) => {
  //@ts-ignore
  const { params } = useLocation();
  const history = useHistory();
  const [isCreatingNewEndpointCollection, setIsCreatingNewEndpointCollection] = useState(false);
  const { integrationID, mappingTestSuiteID } = useParams<RouteParams>();

  const key = params ? params.split("=")[0] : null;
  const val = params ? params.split("=")[1] : null;

  const filteredOperationType = key === "operation_type" ? val : null;
  const filteredModelName = key === "model_name" ? val : null;
  const filterOnWebhooks = key === "webhooks_only" ? val : null;

  // pull read test collection ID
  const readTestCollectionID = endpointCollections.find(
    (collection) => collection.is_read_test_collection
  )?.id;

  return (
    <div>
      <MappingTestTableViewLinkRow
        links={[
          {
            onClick: () => navigateToTestSuitesTable(history, integrationID),
            title: "Mapping Test Suites",
          },
          {
            title: endUserOrganizationName,
          },
        ]}
      />
      <Title>Endpoint Test Collections</Title>
      {!isCreatingNewEndpointCollection ? (
        <div className="space-y-6">
          {(!filteredOperationType || BlueprintOperationType.FETCH === filteredOperationType) &&
            readTest && (
              <FetchMappingTestCard
                fetchMappingTestSuite={fetchMappingTestSuite}
                readTestCollectionID={readTestCollectionID}
                integrationID={integrationID}
                mappingTest={readTest}
              />
            )}
          {!readTest && (
            <EndpointCollectionCard
              endpointCollection={undefined}
              setIsCreatingNewEndpointCollection={setIsCreatingNewEndpointCollection}
              isCreatingNewEndpointCollection={isCreatingNewEndpointCollection}
              fetchMappingTestSuite={fetchMappingTestSuite}
              testSuiteID={mappingTestSuiteID}
              integrationID={integrationID}
            />
          )}
          {endpointCollections.map(
            (endpointCollection) =>
              !endpointCollection.is_read_test_collection &&
              (!filteredModelName || endpointCollection.common_model_name === filteredModelName) &&
              (!filteredOperationType ||
                endpointCollection.operation_type === filteredOperationType) &&
              (!filterOnWebhooks || !!endpointCollection?.webhook_receiver_event_type_id) && (
                <EndpointCollectionCard
                  endpointCollection={endpointCollection}
                  integrationID={integrationID}
                  setIsCreatingNewEndpointCollection={setIsCreatingNewEndpointCollection}
                  isCreatingNewEndpointCollection={isCreatingNewEndpointCollection}
                  testSuiteID={mappingTestSuiteID}
                  fetchMappingTestSuite={fetchMappingTestSuite}
                />
              )
          )}
        </div>
      ) : (
        <EmptyStateWrapper isTable isSpinner />
      )}
    </div>
  );
};

export default IndividualMappingTestSuiteEndpointCollectionOverview;
