import { useHistory, Link } from "react-router-dom";
import { Table, Card, Button } from "react-bootstrap";
import EmptyStateWrapper from "../../shared/layout/EmptyStateWrapper";
import { navigatetoIndividualAuthConfig } from "../../../router/RouterUtils";
import { AuthConfigMeta } from "../../../models/AuthConfig";
import { ButtonVariant } from "@merge-api/merge-javascript-shared";

type Props = { authConfigs: AuthConfigMeta[]; integrationID: string };

const AuthConfigTable = ({ authConfigs, integrationID }: Props) => {
  const history = useHistory();

  return (
    <Card>
      <Table responsive hover>
        <thead className="table-borderless">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Auth Type</th>
            <th scope="col" className="text-right" />
            <th scope="col" style={{ display: "flex", justifyContent: "flex-end" }}>
              <Link to={`/integrations/${integrationID}/auth-configs/create`}>
                <Button variant={ButtonVariant.TertiaryWhite} size="sm" className="mr-3">
                  <div className="d-flex justify-content-center">
                    <i className="fe fe-plus mr-1.5" />
                    Create configuration
                  </div>
                </Button>
              </Link>
            </th>
          </tr>
        </thead>
        <tbody>
          {authConfigs ? (
            authConfigs.length > 0 ? (
              <>
                {authConfigs.map((authConfig: AuthConfigMeta) => (
                  <>
                    <tr
                      key={authConfig.id}
                      onClick={() =>
                        navigatetoIndividualAuthConfig(history, integrationID, authConfig.id)
                      }
                      className="table-link"
                    >
                      <td className="w-35">{authConfig.name}</td>
                      <td className="w-35">{authConfig.auth_type}</td>
                      <td className="w-15" />
                      <td className="text-right">
                        <Button
                          className="mr-3"
                          variant={ButtonVariant.TertiaryWhite}
                          size="sm"
                          onClick={() =>
                            navigatetoIndividualAuthConfig(history, integrationID, authConfig.id)
                          }
                        >
                          <i className="fe fe-edit-2" /> Edit
                        </Button>
                      </td>
                    </tr>
                  </>
                ))}
              </>
            ) : (
              <tr className="unmapped-common-models">
                <td colSpan={8} className="p-0">
                  <EmptyStateWrapper isTable title="No Authentication Configurations" />
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan={7} className="p-0">
                <EmptyStateWrapper isTable isSpinner />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Card>
  );
};

export default AuthConfigTable;
