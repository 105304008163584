import { Badge, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { ChevronRight, Minus } from "lucide-react";
import { useHistory } from "react-router-dom";
import { getIntegrationBuilderSinglePaginationConfigurationPathForId } from "../../../../router/RouterUtils";
import { PaginationConfigurationIntegrationBuilder } from "../../utils/Entities";

const MAX_ENDPOINTS_TO_DISPLAY = 2;

const PAGINATION_TYPE_TO_DISPLAY_NAME: Record<string, string> = {
  POINTER: "Pointer",
  OFFSET: "Offset",
  PAGES: "Pages",
};

type PaginationConfigurationTableRowProps = {
  paginationConfiguration: PaginationConfigurationIntegrationBuilder;
  integrationID: string;
};

const PaginationConfigurationTableRow = ({
  paginationConfiguration,
  integrationID,
}: PaginationConfigurationTableRowProps) => {
  // hooks
  const history = useHistory();

  // event handlers
  const onAPIEndpointClick = () => {
    if (!paginationConfiguration.id) return;
    history.push({
      pathname: getIntegrationBuilderSinglePaginationConfigurationPathForId(
        integrationID,
        paginationConfiguration.id
      ),
    });
  };

  const renderAPIEndpointNames = () => {
    const apiEndpoints = paginationConfiguration.api_endpoints;

    if (paginationConfiguration.is_default_for_integration) {
      return (
        <Badge size="md" color="blue">
          {"Default"}
        </Badge>
      );
    } else if (apiEndpoints && apiEndpoints.length) {
      return (
        <Text>
          {apiEndpoints.slice(0, MAX_ENDPOINTS_TO_DISPLAY).map((endpoint, index) => (
            <Text className="font-medium">{index > 0 ? `, ${endpoint.name}` : endpoint.name}</Text>
          ))}
          {apiEndpoints.length > MAX_ENDPOINTS_TO_DISPLAY && (
            <>
              <Text className="font-medium">{", "}</Text>
              <Badge size="md" color="gray">
                {`${apiEndpoints.length - MAX_ENDPOINTS_TO_DISPLAY} more`}
              </Badge>
            </>
          )}
        </Text>
      );
    } else {
      return <Minus size={16} color="#939AA5" />;
    }
  };

  return (
    <tr onClick={onAPIEndpointClick} className="cursor-pointer">
      <td>
        <div className="overflow-hidden whitespace-nowrap">
          <Tooltip title={paginationConfiguration.name}>
            <div className="max-w-[500px] truncate text-ellipsis">
              <Text variant="inherit" className="text-base font-medium">
                {paginationConfiguration.name}
              </Text>
            </div>
          </Tooltip>
        </div>
      </td>
      <td>
        <Text variant="inherit" className="text-base font-medium">
          {PAGINATION_TYPE_TO_DISPLAY_NAME[paginationConfiguration.pagination_type]}
        </Text>
      </td>
      <td>
        <div className="max-w-[400px] truncate overflow-hidden text-ellipsis whitespace-nowrap">
          <Text variant="md">{paginationConfiguration.description}</Text>
        </div>
      </td>
      <td>
        <div className="flex items-center justify-between">
          <div className="pr-2">{renderAPIEndpointNames()}</div>
          <ChevronRight size={12} color="black" />
        </div>
      </td>
    </tr>
  );
};

export default PaginationConfigurationTableRow;
