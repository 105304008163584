import { useState } from "react";
import { Button, OverlayTrigger, Tooltip as BootstrapTooltip } from "react-bootstrap";
import { Aperture, HelpCircle } from "lucide-react";
import styled from "styled-components";
import { BlueprintParameterSchemaValue } from "../../../models/Blueprints";
import {
  CommonModelMappingMissInfo,
  MappingTestBodyParameters,
} from "../../../models/MappingTests";
import { getIconForTypeForJSONTreeDiagram } from "../../shared/utils/SharedComponentUtils";
import useMappingTestContext from "../context/useMappingTestContext";
import { getEmptyValueForField } from "../utils/MappingTestBuildingUtils";
import MappingTestEditorCommonModelExpectedMappingFieldInputList from "./MappingTestEditorCommonModelExpectedMappingFieldInputList";
import MappingTestEditorCommonModelExpectedMappingRow from "./MappingTestEditorCommonModelExpectedMappingRow";
import { Tooltip } from "@merge-api/merge-javascript-shared";
import React from "react";
import MappingTestEditorCommonModelExpectedMappingFieldInputObject from "./MappingTestEditorCommonModelExpectedMappingFieldInputObject";
import { shouldDisplayManualInputSubObject } from "../utils/helpers";

const VISIBLE_INPUT_LENGTH = 17;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  width: 100%;
  justify-content: space-between;
`;

type MappingMissProps = {
  hasMappingMisses?: boolean;
  isMappedCorrectly?: boolean;
  hasIcon?: boolean;
};

const WeirdText = styled.div<MappingMissProps>`
  font-family: Menlo;
  font-size: 13px;
  color: ${({ hasMappingMisses, isMappedCorrectly }) =>
    hasMappingMisses ? "#F00424" : isMappedCorrectly ? "#00B187" : "#8695b1"};
`;

const FieldInput = styled.input<MappingMissProps>`
  background: ${({ hasMappingMisses, isMappedCorrectly }) =>
    hasMappingMisses ? "#FFE9EC" : isMappedCorrectly ? "#F5FDFA" : "#eff2f4"}};
  color: ${({ hasMappingMisses, isMappedCorrectly }) =>
    hasMappingMisses ? "#F00424" : isMappedCorrectly ? "#00B187" : ""}}; 
  border: 0;
  right: ${({ hasIcon }) => (hasIcon ? "0px" : "18px")}};
  border-bottom: ${({ hasMappingMisses, isMappedCorrectly }) =>
    hasMappingMisses ? "1.5px solid #FFA2A2" : isMappedCorrectly ? "" : "1.5px solid #dce4f0"}};
  width: ${({ hasIcon }) => (hasIcon ? "144px" : "")}};
`;

const FieldDropdown = styled.select<MappingMissProps>`
  width: ${({ hasIcon }) => (hasIcon ? "152px" : "")}};
  background: ${({ hasMappingMisses }) => (hasMappingMisses ? "#FFE9EC" : "#eff2f4")}};
  color: ${({ hasMappingMisses }) => (hasMappingMisses ? "#F00424" : "")}}; 
  border: 0;
  border-bottom: ${({ hasMappingMisses }) =>
    hasMappingMisses ? "1.5px solid #FFA2A2" : "1.5px solid #dce4f0"}};
`;

const EnumInputTooltipIcon = styled(HelpCircle)<MappingMissProps>`
  backgroundcolor: #eff2f4;
  border-bottom: ${({ hasMappingMisses }) =>
    hasMappingMisses ? "1.5px solid #FFA2A2" : "1.5px solid #dce4f0"}};
  height: 100%;
  padding-right: 5px;
  background: ${({ hasMappingMisses }) => (hasMappingMisses ? "#FFE9EC" : "#eff2f4")}};
`;

const ProducedValueContainer = styled.div`
  overflow: hidden;
  word-break: break-all;
  margin-left: 20px;
  margin-right: 5px;
`;

const RequiredField = styled.div`
  margin-left: 4px;
  font-weight: 700;
  font-size: 14px;
`;

const ManualInputButton = styled(Button)`
  height: 10px;
  vertical-align: baseline;
  font-size: 9px;
  line-height: 0px;
  padding: 7px;
`;

type Props<ValueType extends string | Array<string>> = {
  enumChoices?: Array<string>;
  hasMappingMisses?: boolean;
  missedMappingInfo?: CommonModelMappingMissInfo;
  isMappedCorrectly?: boolean;
  isRowShaded: boolean;
  isUniqueIdentifier: boolean;
  isRequired?: boolean;
  text: string;
  fieldType: string;
  fieldSchema: undefined | BlueprintParameterSchemaValue;
  value: ValueType;
  setValue: (value: ValueType | Array<Record<string, any>>) => void;
  availableRelationLookupDict?: { [commonModelID: string]: Array<string> };
  availableRelationNames: undefined | Array<string>;
  bodyParameters?: MappingTestBodyParameters;
  fieldKeyPath: Array<string>;
  onUpdate: (args: { fieldKeyPath: Array<string>; newFieldValue: any }) => void;
  isNestedWriteField?: boolean;
  nestedWritesMapForMappingTestModelArray?: string[];
  commonModelName?: string;
};

const MappingTestEditorCommonModelExpectedMappingField = <
  ValueType extends string | Array<string>
>({
  enumChoices,
  hasMappingMisses,
  bodyParameters,
  missedMappingInfo,
  isMappedCorrectly,
  isRowShaded,
  isUniqueIdentifier,
  isRequired,
  availableRelationLookupDict,
  text,
  fieldSchema,
  fieldType,
  fieldKeyPath,
  value,
  setValue,
  onUpdate,
  availableRelationNames,
  isNestedWriteField = false,
  nestedWritesMapForMappingTestModelArray = [],
  commonModelName,
}: Props<ValueType>) => {
  const [isFocused, setIsFocused] = useState(false);
  const [shouldManualInputSubObject, setShouldManualInputSubObject] = useState<boolean>(
    shouldDisplayManualInputSubObject(fieldType, value)
  );

  const { isDebugMode } = useMappingTestContext();

  const ProducedValue = () => {
    const value = missedMappingInfo?.produced_value ?? "Null";
    return missedMappingInfo?.produced_value !== missedMappingInfo?.expected_value ? (
      <ProducedValueContainer>{value}</ProducedValueContainer>
    ) : null;
  };
  const isFieldTypeObject = fieldType === "object";
  const isFieldKeyNestedWriteObject = nestedWritesMapForMappingTestModelArray.includes(text);
  const isNestedWriteObject = isFieldKeyNestedWriteObject && isFieldTypeObject;

  return (
    <>
      <MappingTestEditorCommonModelExpectedMappingRow isRowShaded={isRowShaded}>
        <LeftSection>
          <div className="d-flex align-items-center">
            {getIconForTypeForJSONTreeDiagram(fieldType, hasMappingMisses)}
            <WeirdText isMappedCorrectly={isMappedCorrectly} hasMappingMisses={hasMappingMisses}>
              {text}
            </WeirdText>
            {isUniqueIdentifier && <Aperture />}
            {isRequired && <RequiredField>*</RequiredField>}
          </div>
          {isDebugMode && fieldType !== "array" && <ProducedValue />}
          {(fieldType == "array" || isNestedWriteObject) && !isNestedWriteField && (
            <ManualInputButton
              onClick={() => {
                setShouldManualInputSubObject(!shouldManualInputSubObject);
              }}
            >
              Toggle
            </ManualInputButton>
          )}
        </LeftSection>
        {enumChoices && enumChoices.length > 0 ? (
          <div className="d-flex">
            <FieldInput
              hasMappingMisses={hasMappingMisses}
              isMappedCorrectly={isMappedCorrectly}
              value={value}
              onChange={(e) => (setValue as (value: string) => void)(e.target.value)}
              hasIcon={true}
            />
            <Tooltip
              title={
                <>
                  This is an Enum field, with the following options:
                  <br />
                  {(enumChoices ?? []).map((enumVal, index) => (
                    <React.Fragment key={index}>
                      {enumVal} <br />
                    </React.Fragment>
                  ))}
                </>
              }
            >
              <EnumInputTooltipIcon size={20} hasMappingMisses={hasMappingMisses} />
            </Tooltip>
          </div>
        ) : fieldType === "boolean" ? (
          <FieldDropdown
            hasMappingMisses={hasMappingMisses}
            onChange={(e) => (setValue as (value: string) => void)(e.target.value)}
            value={value}
          >
            <>
              <option key={"True"} value={"True"}>
                {"True"}
              </option>
              <option key={"False"} value={"False"}>
                {"False"}
              </option>
              <option key={"None"} value={undefined}>
                {"None"}
              </option>
            </>
          </FieldDropdown>
        ) : fieldType !== "array" && !isNestedWriteObject ? (
          availableRelationNames !== undefined ? (
            <FieldDropdown
              hasMappingMisses={hasMappingMisses}
              onChange={(e) => (setValue as (value: string) => void)(e.target.value)}
              value={value}
            >
              <>
                <option key={"None"} value={getEmptyValueForField(fieldSchema)}>
                  {"None"}
                </option>
                {availableRelationNames.map((name) => (
                  <option key={name} value={`$\{${name}}`}>
                    {name}
                  </option>
                ))}
              </>
            </FieldDropdown>
          ) : (
            // Cant componentize this input with a tooltip outside the rendering logic
            // since it keeps remounting
            <OverlayTrigger
              show={(value ?? "").length > VISIBLE_INPUT_LENGTH && isFocused}
              trigger="hover"
              defaultShow={false}
              overlay={
                <BootstrapTooltip
                  className={`step-card-log-icon-tooltip`}
                  id="tooltip"
                  onClick={() => {
                    navigator.clipboard.writeText(String(value));
                  }}
                >
                  {value}
                </BootstrapTooltip>
              }
              placement="top"
            >
              <FieldInput
                onBlur={() => setIsFocused(false)}
                onFocus={() => setIsFocused(true)}
                hasMappingMisses={hasMappingMisses}
                isMappedCorrectly={isMappedCorrectly}
                value={
                  fieldType == "object" && typeof value == "object" ? JSON.stringify(value) : value
                }
                onChange={(e) => {
                  // TODO: Remove logic that parses json objects from string in input fields
                  // https://app.asana.com/0/1200046105855900/1204054688108602
                  if (fieldType == "object") {
                    try {
                      const parsedValue = JSON.parse(e.target.value);
                      (setValue as (value: string | any) => void)(parsedValue);
                    } catch {
                      (setValue as (value: string | any) => void)(e.target.value);
                    }
                  } else {
                    (setValue as (value: string) => void)(e.target.value);
                  }
                }}
                hasIcon={true}
              />
            </OverlayTrigger>
          )
        ) : undefined}
      </MappingTestEditorCommonModelExpectedMappingRow>
      {isNestedWriteObject && fieldSchema && commonModelName && (
        <MappingTestEditorCommonModelExpectedMappingFieldInputObject
          commonModelName={commonModelName}
          fieldName={text}
          fieldSchema={fieldSchema}
          bodyParameters={bodyParameters}
          fieldKeyPath={fieldKeyPath}
          value={value as string}
          availableRelationLookupDict={availableRelationLookupDict ?? {}}
          setValue={setValue as (value: string | Record<string, any>) => void}
          itemSchema={fieldSchema.items!}
          onUpdate={onUpdate}
          missedMappingInfo={missedMappingInfo}
          isDebugMode={isDebugMode}
          availableRelationNames={availableRelationNames}
          shouldManualInputSubObject={shouldManualInputSubObject}
        />
      )}
      {fieldType === "array" && fieldSchema && (
        <MappingTestEditorCommonModelExpectedMappingFieldInputList
          hasMappingMisses={hasMappingMisses}
          fieldName={text}
          fieldSchema={fieldSchema}
          bodyParameters={bodyParameters}
          fieldKeyPath={fieldKeyPath}
          value={value as Array<string>}
          availableRelationLookupDict={availableRelationLookupDict}
          setValue={setValue as (array: Array<string | Record<string, any>>) => void}
          itemSchema={fieldSchema.items!}
          onUpdate={onUpdate}
          missedMappingInfo={missedMappingInfo}
          isDebugMode={isDebugMode}
          availableRelationNames={availableRelationNames}
          shouldManualInputSubObject={shouldManualInputSubObject}
        />
      )}
    </>
  );
};

export default MappingTestEditorCommonModelExpectedMappingField;
