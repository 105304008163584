import { useEffect, useState, useRef } from "react";
import { showSuccessToast } from "../shared/Toasts";
import ScraperContextProvider from "./context/ScraperContextProvider";
import ScraperEditorView from "./ScraperEditorView";

import {
  ScraperVersion,
  ScraperExecutionLogs,
  ScraperGhostStep,
  ScraperStep,
  ScraperStepType,
  ScraperValue,
} from "./types";
import { GlobalHotKeys } from "react-hotkeys";
import {
  fetchScraperVersion,
  fetchScraperVersions,
  saveScraper,
  stageScraper,
  unstageScraper,
} from "./utils/ScraperAPIClient";
import {
  addNewStepFromType,
  deleteScraperStep,
  editExistingStep,
  addNewCopiedStep,
  renameScraperStep,
  collapseScraperSubsteps,
} from "./utils/ScraperUtils";
import { showErrorToast } from "../shared/Toasts";
import { AddStepRelation, BlueprintVersionPublishState } from "../../models/Blueprints";
import EditorLeavingGuard from "../shared/unsaved-changes/EditorLeavingGuard";
import isEqual from "lodash/isEqual";
import MergeModal from "../shared/MergeModal";
import { Button, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { getScraperEditorPath } from "../../router/RouterUtils";
import { ParentIntegrationComponentModelAndVersions } from "../integrations/versioned-components/types";

type Props = {
  integrationID: string;
  scraper: ScraperVersion;
  setScraper: (scraper: ScraperVersion) => void;
};
const keyMaps = {
  COPY: "command+c",
  CUT: "command+x",
  PASTE: "command+v",
  SAVE: "command+s",
  COPY_JSON: "ctrl+c",
  PASTE_JSON: "ctrl+v",
  COPY_ALL_JSON: "ctrl+a",
  CUT_JSON: "ctrl+x",
};

const ScraperEditor = ({ integrationID, scraper, setScraper }: Props) => {
  const history = useHistory();
  const [selectedStep, setSelectedStep] = useState<ScraperStep | ScraperGhostStep | undefined>(
    scraper.steps?.[0]
  );
  const [copiedStep, setCopiedStep] = useState<ScraperStep>();
  const [scraperExecutionLogs, setScraperExecutionLogs] = useState<
    ScraperExecutionLogs | undefined
  >();
  const [scraperVersions, setScraperVersions] = useState<ScraperVersion[]>();
  const [disableArrows, setDisableArrows] = useState<boolean>(true);
  const [showSaveConfirmationModal, setShowSaveConfirmationModal] = useState<boolean>(false);
  const [originalScraper, setOriginalScraper] = useState<ScraperVersion>();
  const saveButtonRef = useRef(null);
  const renameStep = (stepID: string, newName: string, onError?: () => void) => {
    if (scraper != null) {
      const updatedScraperAndStep: {
        scraper: ScraperVersion;
        step: ScraperStep;
      } | null = renameScraperStep(scraper, stepID, newName);

      if (updatedScraperAndStep) {
        setScraper(updatedScraperAndStep.scraper);
        setSelectedStep(updatedScraperAndStep.step);
      } else if (onError) {
        showErrorToast("Error saving step! Step name already exists.");
        onError();
      }
    }
  };
  const updateScraperHelper = (
    updateScraperMethod: () => {
      scraper: ScraperVersion;
      step: ScraperStep | undefined;
    }
  ) => {
    const { scraper: newScraper, step: newStep } = updateScraperMethod();

    setScraper(newScraper);
    setSelectedStep(newStep);
  };

  const addStep = (newStep: ScraperGhostStep, stepType: ScraperStepType): void =>
    updateScraperHelper(() => addNewStepFromType(scraper, newStep, stepType));

  const editStep = (step: ScraperStep): void =>
    updateScraperHelper(() => editExistingStep(scraper, step));

  const deleteStep = (step: ScraperStep): void =>
    updateScraperHelper(() => ({
      scraper: deleteScraperStep(scraper, step),
      step: undefined,
    }));

  const collapseStep = (stepID: string): void =>
    updateScraperHelper(() => collapseScraperSubsteps(scraper, stepID));

  const save = (): void => {
    saveScraper({
      scraper,
      onSuccess: (newScraper) => {
        setScraper(newScraper);
        setOriginalScraper(JSON.parse(JSON.stringify(newScraper)));
        showSuccessToast("Saved scraper!");
        if (scraper.version_id !== newScraper.version_id) {
          history.push(getScraperEditorPath(integrationID, scraper.id, newScraper.version_id));
        }
      },
    });
  };

  const refetchScraperVersion = (versionID: string) => {
    fetchScraperVersion({
      scraperID: scraper.id,
      scraperVersionID: versionID,
      onSuccess: setScraper,
    });
  };

  const refreshNewVersion = (
    action: (props: {
      scraper: ScraperVersion;
      onSuccess: (response: ParentIntegrationComponentModelAndVersions) => void;
    }) => void
  ) => {
    action({
      scraper: scraper,
      onSuccess: (response) => {
        const nextScraperVersionID = response.next_component_version?.model_id;
        const publishedScraperVersionID = response.published_component_version?.model_id;
        if (nextScraperVersionID) {
          refetchScraperVersion(nextScraperVersionID);
        } else if (publishedScraperVersionID) {
          refetchScraperVersion(publishedScraperVersionID);
        } else {
          showErrorToast("Failed to fetch staged or published version.");
        }
      },
    });
  };

  const stage = (): void => {
    refreshNewVersion(stageScraper);
  };

  const unstage = (): void => {
    refreshNewVersion(({ scraper, onSuccess }) =>
      unstageScraper({ scraperID: scraper.id, onSuccess })
    );
  };

  const openSaveConfirmationModal = (): void => {
    setShowSaveConfirmationModal(true);
    // @ts-ignore For some reason Typescript thinks ref.current will be 'never'
    saveButtonRef.current?.focus();
  };

  function addCopiedStep(
    step: ScraperStep,
    newStepRelation: AddStepRelation,
    relatedStepID: string,
    pathKey?: string
  ) {
    if (scraper) {
      const response = addNewCopiedStep(scraper, step, relatedStepID, newStepRelation, pathKey);
      setScraper(response.scraper);
      setSelectedStep(response.step);
      setCopiedStep(response.step);
    }
  }
  function addCopiedSteps(
    steps: ScraperStep[],
    newStepRelation: AddStepRelation,
    relatedStepID: string,
    pathKey?: string
  ) {
    if (scraper && steps.length > 0) {
      let response = null;
      for (const [index, step] of steps.entries()) {
        if (index === 0) {
          response = addNewCopiedStep(scraper, step, relatedStepID, newStepRelation, pathKey);
        } else if (response) {
          response = addNewCopiedStep(
            response.scraper,
            step,
            response.step.id,
            AddStepRelation.SIBLING_AFTER
          );
        }
      }
      if (response) {
        setScraper(response.scraper);
        setSelectedStep(response.step);
        setCopiedStep(response.step);
      }
    }
  }

  const updateStepParameterValue = (
    step: ScraperStep,
    key: string,
    newValue: ScraperValue | null
  ): void =>
    editStep({
      ...step,
      parameter_values: { ...step.parameter_values, [key]: newValue },
    } as ScraperStep);

  const updateStepNoteText = (step: ScraperStep, stepNoteText: string): void =>
    editStep({
      ...step,
      step_note: stepNoteText,
    } as ScraperStep);

  const scraperIsEqual = (
    currentScraper: ScraperVersion | undefined,
    previousScraper: ScraperVersion | undefined
  ) => {
    // Compare the JSON values without the version ID
    if (currentScraper !== undefined && previousScraper !== undefined) {
      const { version_id: a, ...currentContent } = currentScraper;
      const { version_id: b, ...previousContent } = previousScraper;
      return isEqual(currentContent, previousContent);
    }
    return false;
  };

  useEffect(() => {
    if (!scraperVersions) {
      fetchScraperVersions({
        scraperID: scraper.id,
        onSuccess: setScraperVersions,
      });
    }
  }, [scraperVersions, scraper]);

  useEffect(
    () => {
      setOriginalScraper(JSON.parse(JSON.stringify(scraper)));
    },
    [scraper.version_id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const latestPublishedVersion = scraperVersions?.find(
    (scraperVersion) => scraperVersion.publish_state === BlueprintVersionPublishState.Published
  );

  const doesScraperHaveUnsavedChanges = !scraperIsEqual(scraper, originalScraper);

  return (
    <ScraperContextProvider
      disableArrows={disableArrows}
      setDisableArrows={setDisableArrows}
      addStep={addStep}
      updateStepParameterValue={updateStepParameterValue}
      updateStepNoteText={updateStepNoteText}
      deleteStep={deleteStep}
      collapseStep={collapseStep}
      copiedStep={copiedStep}
      setCopiedStep={setCopiedStep}
      addCopiedStep={addCopiedStep}
      addCopiedSteps={addCopiedSteps}
      renameStep={renameStep}
      integrationID={integrationID}
      saveScraper={openSaveConfirmationModal}
      stageScraper={stage}
      unstageScraper={unstage}
      scraper={scraper}
      setSelectedStep={setSelectedStep}
      selectedStep={selectedStep}
      scraperExecutionLogs={scraperExecutionLogs}
      setScraperExecutionLogs={setScraperExecutionLogs}
      setScraper={setScraper}
      latestPublishedVersionID={latestPublishedVersion?.version_id}
      scraperVersions={scraperVersions ?? []}
      doesScraperHaveUnsavedChanges={doesScraperHaveUnsavedChanges}
    >
      <GlobalHotKeys keyMap={keyMaps} allowChanges={true}>
        <EditorLeavingGuard
          currentLocation={getScraperEditorPath(integrationID, scraper.id, scraper.version_id)}
          hasUnsavedChanges={doesScraperHaveUnsavedChanges}
        >
          <ScraperEditorView />
        </EditorLeavingGuard>
        <MergeModal
          show={showSaveConfirmationModal}
          title={"Save Confirmation"}
          bodyClassName="overflow-hidden"
        >
          <>
            <Row>
              <Col>
                <b>Are you sure you want to save?</b>
              </Col>
            </Row>
            <Row className="mt-6">
              <Col>
                <Button
                  className="btn-block"
                  variant="outline-danger"
                  ref={saveButtonRef}
                  onClick={() => {
                    save();
                    setShowSaveConfirmationModal(false);
                  }}
                >
                  Save changes
                </Button>
              </Col>
              <Col>
                <Button className="btn-block" onClick={() => setShowSaveConfirmationModal(false)}>
                  Return to Editor
                </Button>
              </Col>
            </Row>
          </>
        </MergeModal>
      </GlobalHotKeys>
    </ScraperContextProvider>
  );
};

export default ScraperEditor;
