import { Table } from "react-bootstrap";
import styled from "styled-components";
import { palette } from "../../styles/theme/colors";
import classNames from "classnames";

type StyledTableProps = {
  hasMorePaddingOnFirstElement?: boolean;
  isOverviewPage?: boolean;
  hasMarginBottom?: boolean;
  hover?: boolean;
  hoverColor?: string;
  borderBottom?: string;
};

/*
 * Generally we'll want to keep td at font-size 12px and line-height 22px, but this is table specific. There are certain rows
 * that should be different, and if so, simply override this.
 *
 * For our Issues and Logs table, we'll want 24px of margin on the first element on the left-hand side
 * For our Data Sync table, we'll want to keep 16px of margin on the left-hand side
 */

const StyledTable = styled.div<StyledTableProps>`
  box-shadow:${(props) => (props.isOverviewPage ? "" : "0px 4px 20px -4px rgba(0, 0, 0, 0.08);")} 
  background: ${palette.white};
  max-width: 100%;
  overflow-x: auto;
  && th:first-child,
  && td:first-child {
    padding-left: ${({ hasMorePaddingOnFirstElement }) =>
      hasMorePaddingOnFirstElement ? "24px" : "16px"};
  }

  && th:last-child {
    margin: -2px;
  }
  && td:last-child {
    margin: -0.5px;
    border: none
  }

  && th {
    background: ${palette.white};
    color: ${palette.black};
    font-size: 14px;
    line-height: 24px;
    border-bottom-width: 0.5px;
    padding: 16px 24px;
  }
  && td {
    padding: 20px 24px;
    font-size: 12px;
    line-height: 22px;
  }

  // Specifying multi-line-text as a class on a td will allow the text to wrap
  && th, && td.multi-line-text {
    max-width: 200px;
    white-space: normal;
    word-wrap: break-word;
  }

  // Add hover effect color
  ${(props) =>
    props.hover && props.hoverColor && `&& tbody tr:hover { background: ${props.hoverColor}; }`}

  // Optionally add border-bottom to the elements
  ${(props) =>
    props.borderBottom && `&& th, && tr, && td { border-bottom: ${props.borderBottom}; }`}
`;

type Props = {
  className?: string;
  header?: JSX.Element;
  content: JSX.Element | JSX.Element[];
  hasMorePaddingOnFirstElement?: boolean;
  isOverviewPage?: boolean;
  hasMarginBottom?: boolean;
  hover?: boolean;
  hoverColor?: string;
  borderBottom?: string;
};

const MergeTable = ({
  className,
  header,
  content,
  hasMorePaddingOnFirstElement = false,
  isOverviewPage = false,
  hasMarginBottom = true,
  hover = true,
  hoverColor,
  borderBottom,
}: Props) => {
  return (
    <StyledTable
      className={classNames(
        "table-responsive rounded-table-corner rounded-lg fs-mask",
        hasMarginBottom && "mb-5",
        hover && "[&>tr]:cursor-pointer",
        className
      )}
      hasMorePaddingOnFirstElement={hasMorePaddingOnFirstElement}
      isOverviewPage={isOverviewPage}
      hoverColor={hoverColor}
      hover={hover}
      borderBottom={borderBottom}
    >
      <Table size="sm" hover={hover} className="table-nowrap mb-0">
        {header && (
          <thead className="table-borderless">
            <tr>{header}</tr>
          </thead>
        )}
        <tbody>{content}</tbody>
      </Table>
    </StyledTable>
  );
};

export default MergeTable;
