import isEqual from "lodash/isEqual";
import { memo, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { BlueprintMeta } from "../../../models/Entities";
import {
  MappingTestBlock,
  MappingTestBlockExecution,
  MappingTestCommonModelExpectedMappings,
  MappingTestExistingCommonModels,
  MappingTestReadTestInformation,
  MappingTestRequestMock,
} from "../../../models/MappingTests";
import IntegrationEditorProducedModelsModal from "../../shared/integration-editor-base/IntegrationEditorProducedModelsModal";
import MappingTestEditorExpectedMappings from "../mapping-test-block-assertions/MappingTestEditorExpectedMappings";
import { MappingTestAssertionsProps } from "../root/MappingTestV2Editor";
import ContextMenu from "../../shared/ContextMenu";
import { showSuccessToast } from "../../shared/Toasts";
import { Accordion, Card, Text } from "@merge-api/merge-javascript-shared";
import MappingTestBlockBlueprintsHeader from "./MappingTestBlockBlueprintsHeader";
import MappingTestBlockBlueprints from "./MappingTestBlockBlueprints";
import { buildMappingTestBlockSchema } from "../utils/helpers";

type Props = {
  index: number;
  readTestInformation: undefined | MappingTestReadTestInformation;
  existingCommonModels: MappingTestExistingCommonModels | undefined;
  mappingTestBlocks: Array<MappingTestBlock>;
  mappingTestID: string;
  mappingTestBlock: MappingTestBlock;
  requestsMap: { [id: string]: MappingTestRequestMock };
  blueprintsMap: { [id: string]: BlueprintMeta };
  draftBlueprintsMap: { [id: string]: BlueprintMeta };
  isRunningMappingTest: boolean;
  testBlockExecution: MappingTestBlockExecution | undefined;
  removeMappingTestBlock: (mappingTestID: string) => void;
  addBlueprintToMappingTestBlock: (mappingTestBlockID: string, blueprintID: string) => void;
  removeBlueprintFromMappingTestBlock: (mappingTestBlockID: string, blueprintID: string) => void;
  addRequestMockToMappingTestBlock: (
    mappingTestBlockID: string,
    blueprintID: string,
    requestMock: MappingTestRequestMock
  ) => void;
  removeRequestMockFromMappingTestBlock: (
    mappingTestBlockID: string,
    blueprintID: string,
    requestMock: MappingTestRequestMock
  ) => void;
  setOverrideLastRunAtValue: (
    mappingTestBlockID: string,
    blueprintID: string,
    overrideLastRunAtValue?: string | null
  ) => void;
  setDisableFilterByDateValue: (
    mappingTestBlockID: string,
    blueprintID: string,
    disableFilterByDateValue?: boolean
  ) => void;
  setCopiedBlock: (mappingTestBlock: MappingTestBlock) => void;
  updateRelationName: (
    commonModelID: string,
    mappingTestBlockID: string,
    oldName: string,
    newName: string
  ) => void;
  setIsShowingRightPanel: React.Dispatch<React.SetStateAction<boolean>>;
  selectedBlockExecution: MappingTestBlockExecution | undefined;
  setSelectedBlockExecution: React.Dispatch<
    React.SetStateAction<MappingTestBlockExecution | undefined>
  >;
  setSelectedBlockName: React.Dispatch<React.SetStateAction<string | undefined>>;
  isExecutionFinished: boolean;
} & MappingTestAssertionsProps;

const BlockContainer = styled.div`
  width: 100%;
  box-shadow: 0px 0px 0px 0.5px rgba(220, 226, 234, 0.2), 0px 3px 12px -3px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-bottom: 40px;
  background: #ffffff;
`;

const MappingTestBlockContainer = ({
  index,
  readTestInformation,
  existingCommonModels,
  mappingTestBlocks,
  mappingTestBlock,
  blueprintsMap,
  draftBlueprintsMap,
  requestsMap,
  addBlueprintToMappingTestBlock,
  removeBlueprintFromMappingTestBlock,
  addRequestMockToMappingTestBlock,
  removeMappingTestBlock,
  removeRequestMockFromMappingTestBlock,
  setCommonModelCountAssertionForBlock,
  updateCommonModelExpectedMappingsForBlock,
  updateRelationName,
  testBlockExecution,
  operationType,
  setOverrideLastRunAtValue,
  setDisableFilterByDateValue,
  setCopiedBlock,
  setIsShowingRightPanel,
  selectedBlockExecution,
  setSelectedBlockExecution,
  setSelectedBlockName,
  isExecutionFinished,
  ...props
}: Props) => {
  const [isShowingProducedModelsModal, setIsShowingProducedModelsModal] = useState<boolean>(false);
  const [isShowingContextMenu, setIsShowingContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });

  const onRightClickBlock = useCallback(
    (e) => {
      e.preventDefault();
      setIsShowingContextMenu(true);
      setContextMenuPosition({ x: e.pageX, y: e.pageY });
    },
    [mappingTestBlock, setContextMenuPosition, setIsShowingContextMenu]
  );

  const baseOptions = [
    {
      label: "Copy Block",
      featherIconName: "copy",
      onClick: () => {
        setIsShowingContextMenu(false);
        setCopiedBlock(mappingTestBlock as MappingTestBlock);
        navigator.clipboard.writeText(JSON.stringify(mappingTestBlock));
        showSuccessToast("Block copied.");
      },
    },
    {
      label: "Cut Block",
      featherIconName: "scissors",
      onClick: () => {
        setIsShowingContextMenu(false);
        setCopiedBlock(mappingTestBlock as MappingTestBlock);
        navigator.clipboard.writeText(JSON.stringify(mappingTestBlock));
        removeMappingTestBlock(mappingTestBlock.id);
        showSuccessToast("Block cut.");
      },
    },
    {
      label: "Delete Block",
      featherIconName: "trash",
      onClick: () => {
        setIsShowingContextMenu(false);
        removeMappingTestBlock(mappingTestBlock.id);
      },
      confirmationMessage: `Are you sure you want to delete this block?`,
    },
  ];

  // function for rendering relational values in fields on dropdown
  const availableRelationLookupDict = useMemo(() => {
    // pulled from available_relation_lookup_dict on backend
    const readTestSchema = readTestInformation?.available_relation_lookup_dict ?? {};

    // derived and rendered based on mapping test block index
    const mappingTestBlockSchema = buildMappingTestBlockSchema(mappingTestBlocks, index);
    const existingCommonModelSchema = Object.fromEntries(
      Object.entries(existingCommonModels ?? {}).map(([modelID, value]) => [
        modelID.split(".")[1],
        Object.keys(value),
      ])
    );

    let concatenatedSchema: { [key: string]: Array<string> } = {};
    let keys = new Set<string>();

    if (existingCommonModels && Object.keys(existingCommonModels).length > 0) {
      keys = new Set([
        ...Object.keys(existingCommonModelSchema),
        ...Object.keys(mappingTestBlockSchema),
      ]);
      for (let key of keys) {
        let valuesB = mappingTestBlockSchema?.[key] || [];
        let valuesC = existingCommonModelSchema?.[key] || [];
        concatenatedSchema[key] = [...new Set([...valuesB, ...valuesC])];
      }
    } else {
      keys = new Set([...Object.keys(readTestSchema), ...Object.keys(mappingTestBlockSchema)]);
      for (let key of keys) {
        let valuesA = readTestSchema?.[key] || [];
        let valuesB = mappingTestBlockSchema?.[key] || [];
        concatenatedSchema[key] = [...new Set([...valuesA, ...valuesB])];
      }
    }

    return concatenatedSchema;
  }, [
    readTestInformation?.available_relation_lookup_dict,
    mappingTestBlocks,
    existingCommonModels,
  ]);

  return (
    <BlockContainer>
      <ContextMenu
        items={baseOptions}
        isShown={isShowingContextMenu}
        position={contextMenuPosition}
        onClose={() => setIsShowingContextMenu(false)}
      />
      {selectedBlockExecution?.exit_data && isShowingProducedModelsModal && (
        <IntegrationEditorProducedModelsModal
          show={isShowingProducedModelsModal}
          onHide={() => setIsShowingProducedModelsModal(false)}
          title={"Mapping Test Run Models Produced"}
          producedModelsTabs={{
            "Mapping Test Actual Values": selectedBlockExecution.produced_models,
            ...Object.fromEntries(
              selectedBlockExecution.exit_data.flatMap((exitData, index) => [
                [`Final Model Changes (Run ${index + 1})`, exitData.final_model_changes],
                [`Model Logs (Run ${index + 1})`, exitData.model_logs],
              ])
            ),
          }}
        />
      )}
      <Card>
        <div onContextMenu={onRightClickBlock}>
          <Accordion
            title={
              <MappingTestBlockBlueprintsHeader
                isExecutionFinished={isExecutionFinished}
                mappingTestBlock={mappingTestBlock}
                testBlockExecution={testBlockExecution}
                setIsShowingProducedModelsModal={setIsShowingProducedModelsModal}
                setIsShowingRightPanel={setIsShowingRightPanel}
                setSelectedBlockExecution={setSelectedBlockExecution}
                setSelectedBlockName={setSelectedBlockName}
              />
            }
            titleClassName="px-5 py-4"
            chevronOrientation="right"
            chevronSize={16}
            chevronColor="black"
            variant="none"
          >
            <MappingTestBlockBlueprints
              mappingTestBlock={mappingTestBlock}
              blueprintsMap={blueprintsMap}
              requestsMap={requestsMap}
              draftBlueprintsMap={draftBlueprintsMap}
              addBlueprintToMappingTestBlock={addBlueprintToMappingTestBlock}
              removeBlueprintFromMappingTestBlock={removeBlueprintFromMappingTestBlock}
              addRequestMockToMappingTestBlock={addRequestMockToMappingTestBlock}
              removeRequestMockFromMappingTestBlock={removeRequestMockFromMappingTestBlock}
              setOverrideLastRunAtValue={setOverrideLastRunAtValue}
              setDisableFilterByDateValue={setDisableFilterByDateValue}
            />
          </Accordion>
          <hr className="text-gray-50 h-[0.5px] my-0" />
          <Accordion
            title={
              <Text variant="h5" className="text-black">
                Assertions
              </Text>
            }
            titleClassName="px-5 py-4"
            chevronOrientation="right"
            chevronSize={16}
            chevronColor="black"
            variant="none"
          >
            <MappingTestEditorExpectedMappings
              {...props}
              availableRelationLookupDict={availableRelationLookupDict}
              operationType={operationType}
              setCommonModelCountAssertion={(commonModelID: string, count: number) =>
                setCommonModelCountAssertionForBlock(mappingTestBlock.id, commonModelID, count)
              }
              testExecutionResults={testBlockExecution}
              updateCommonModelExpectedMappings={(
                commonModelID: string,
                commonModelExpectedMappings: undefined | MappingTestCommonModelExpectedMappings
              ) =>
                updateCommonModelExpectedMappingsForBlock(
                  mappingTestBlock.id,
                  commonModelID,
                  commonModelExpectedMappings
                )
              }
              updateRelationName={(commonModelID: string, oldName: string, newName: string) =>
                updateRelationName(commonModelID, mappingTestBlock.id, oldName, newName)
              }
              mappings={mappingTestBlock.common_model_mappings}
              commonModelCountAssertions={mappingTestBlock.common_model_count_assertions}
              isRunningMappingTest={props.isRunningMappingTest}
            />
          </Accordion>
        </div>
      </Card>
    </BlockContainer>
  );
};

export default memo(MappingTestBlockContainer, isEqual);
