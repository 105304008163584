import { Button, Table } from "react-bootstrap";
import styled from "styled-components";

import MergeModal from "../../shared/MergeModal";
import {
  ASYNC_TASK_EXECUTION_QUEUED_STATUSES,
  ASYNC_TASK_EXECUTION_RUNNING_STATUSES,
  AsyncTaskExecutionPreview,
} from "../../../models/Blueprints";
import Spinner from "../../shared/Spinner";
import { BlueprintRunnerTestRunStatusBadge } from "../../shared/MergeBadges";
import BlueprintExecutionExitCodeBadge from "../../shared/BlueprintExecutionExitCodeBadge";
import { formatRecentTimestamp } from "../../../models/Helpers";

const ExceptionMessage = styled.div`
  max-width: 350px;
`;

interface Props {
  show: boolean;
  onHide: () => void;
  asyncTaskExecutionPreviews: AsyncTaskExecutionPreview[];
  isLoading: boolean;
  onLoadTestRun: (testRunId: string) => void;
}

function AsyncTestRunsModal({
  show,
  onHide,
  asyncTaskExecutionPreviews,
  isLoading,
  onLoadTestRun,
}: Props) {
  return (
    <MergeModal
      title={"Recent Test Runs"}
      dialogClassName="modal-xl"
      show={show}
      onHide={onHide}
      bodyClassName={"p-0"}
    >
      {isLoading ? (
        <Spinner className="py-[72px]" />
      ) : (
        <Table responsive>
          <Table size="sm" className="low-padding-table">
            <thead className="table-borderless">
              <th scope="col">
                <div className="ml-3">Actions</div>
              </th>
              <th scope="col">Status</th>
              <th scope="col">Created At</th>
              <th scope="col">Finished At</th>
              <th scope="col">Exit Code</th>
              <th scope="col">
                <div className="mr-3">Exception Message</div>
              </th>
            </thead>
            <tbody>
              {asyncTaskExecutionPreviews.map(({ created_at, status, blueprint_execution, id }) => {
                const exitCode = blueprint_execution?.exit_code;
                const finishedAt = blueprint_execution?.end_time;
                return (
                  <tr key={`async-test-run-row-${id}`}>
                    <td>
                      <div className="ml-3">
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => {
                            onLoadTestRun(id);
                            onHide();
                          }}
                        >
                          Load
                        </Button>
                      </div>
                    </td>
                    <td>
                      <BlueprintRunnerTestRunStatusBadge
                        isQueued={ASYNC_TASK_EXECUTION_QUEUED_STATUSES.includes(status)}
                        isRunning={ASYNC_TASK_EXECUTION_RUNNING_STATUSES.includes(status)}
                        exitCode={exitCode}
                      />
                    </td>
                    <td>{formatRecentTimestamp(created_at)}</td>
                    <td>{formatRecentTimestamp(finishedAt)}</td>
                    <td>{exitCode && <BlueprintExecutionExitCodeBadge exitCode={exitCode} />}</td>
                    <td>
                      <ExceptionMessage className="mr-3">
                        {blueprint_execution?.exception_message}
                      </ExceptionMessage>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Table>
      )}
    </MergeModal>
  );
}

export default AsyncTestRunsModal;
