import { Checkbox, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { Info } from "lucide-react";

interface Props {
  tooltipTitle?: string;
  label: string;
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
}

const LeftPanelCheckbox = ({ tooltipTitle = "", label, checked, onChange, disabled }: Props) => {
  return (
    <Tooltip tooltipClassName="p-1" placement="right" title={tooltipTitle}>
      <Checkbox
        label={
          tooltipTitle ? (
            <div className="flex flex-row space-x-2 items-center">
              <Text>{label}</Text>
              <Info size={12} className="text-gray-70" />
            </div>
          ) : (
            <Text>{label}</Text>
          )
        }
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
    </Tooltip>
  );
};

export default LeftPanelCheckbox;
