import { firstLetterUpperCase } from "../../../../utils";

export function getReadableCommonModelName(modelName: string, delimiter: string) {
  return modelName
    .split(delimiter)
    .map((str) => {
      return firstLetterUpperCase(str);
    })
    .join(" ");
}
