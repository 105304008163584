import classNames from "classnames";
import { AddStepRelation } from "../../models/Blueprints";
import { getGhostStepID } from "../blueprint-editor/utils/BlueprintCanvasUtils";
import useScraperContext from "./context/useScraperContext";
import { ScraperStep } from "./types";

interface Props {
  step: ScraperStep;
  relation: AddStepRelation;
}

const ScraperAddStepArrow = ({ step, relation }: Props) => {
  const { setSelectedStep } = useScraperContext();

  const ghostStepID = getGhostStepID(step.id, relation);
  const arrowClass = classNames(
    "card-arrow-box",
    relation === AddStepRelation.SIBLING_AFTER ? "card-arrow-bottom" : undefined
  );

  return (
    <div
      className="cursor-pointer"
      onClick={() =>
        setSelectedStep({
          id: ghostStepID,
          relatedStepID: step.id,
          newStepRelation: relation,
          template: "ghost",
        })
      }
    >
      <div className={arrowClass}>
        <div className="card-arrow">
          <svg
            width="12"
            height="40"
            viewBox="0 0 12 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M6 0L0.226497 10H11.7735L6 0ZM7 40L7 9H5L5 40H7Z" fill="#95AAC9" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ScraperAddStepArrow;
