import { Badge, Text } from "@merge-api/merge-javascript-shared";
import { APIEndpoint } from "../../../../integrations/versioned-components/types";

type Props = {
  apiEndpoint: APIEndpoint;
};

const APIEndpointChoiceRow = ({ apiEndpoint }: Props) => {
  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row items-center">
        <Text variant="sm" className="mr-2">
          {apiEndpoint?.name}
        </Text>
        <Badge className="mr-3">
          <Text variant="sm-mono">{apiEndpoint?.path}</Text>
        </Badge>
      </div>
    </div>
  );
};

export default APIEndpointChoiceRow;
