import { Text, Accordion, Badge } from "@merge-api/merge-javascript-shared";
import StatusIcon from "../../StatusIcon";
import {
  IntegrationTestSuiteExecutionStatus,
  LinkedAccountTestSuiteExecutionStatus,
  MappingTestExecution,
  MappingTestExecutionResult,
  MappingTestSuiteEndpointCollectionInfo,
} from "../../../../../../models/MappingTests";
import { NextComponentVersionState } from "../../../../versioned-components/types";
import { getEndpointCollectionTitle } from "../../../../utils/MappingTestUtils";
import MappingTestExecutionFailureCardV2 from "./MappingTestExecution/MappingTestExecutionFailureCardV2";

const EndpointCollectionContainerV2 = ({
  endpointCollection,
  mappingTestExecutions,
  testExecutionStatus,
  isGrouped,
}: {
  endpointCollection: MappingTestSuiteEndpointCollectionInfo;
  mappingTestExecutions: Array<MappingTestExecution>;
  testExecutionStatus: LinkedAccountTestSuiteExecutionStatus;
  isGrouped?: boolean;
}) => {
  const hasStagedTestCases = (
    endpointCollection: MappingTestSuiteEndpointCollectionInfo
  ): boolean => {
    return endpointCollection.test_cases.some(
      (testCase) => testCase.next_version_state === NextComponentVersionState.STAGED_WITH_CHANGES
    );
  };

  const endpointCollectionHasFailedExecution = () =>
    mappingTestExecutions.some(
      (execution) => execution.result == MappingTestExecutionResult.FAILURE
    );

  const renderTitle = () => (
    <div className={"flex flex-row items-center justify-between w-full py-4"}>
      <div className="flex flex-row items-center space-x-2">
        <div className="w-fit h-fit">
          <StatusIcon
            status={
              testExecutionStatus === LinkedAccountTestSuiteExecutionStatus.IN_PROGRESS
                ? LinkedAccountTestSuiteExecutionStatus.IN_PROGRESS
                : mappingTestExecutions.length === 0
                ? IntegrationTestSuiteExecutionStatus.NOT_RUN
                : mappingTestExecutions.some(
                    (execution) => execution.result !== MappingTestExecutionResult.SUCCESS
                  )
                ? MappingTestExecutionResult.FAILURE
                : MappingTestExecutionResult.SUCCESS
            }
          />
        </div>
        <Text className="text-black normal-case">
          {getEndpointCollectionTitle(endpointCollection)}
        </Text>
      </div>
      <div>
        {hasStagedTestCases(endpointCollection) && !endpointCollectionHasFailedExecution() && (
          <Badge className="mr-1" color="gray">
            Staged
          </Badge>
        )}
      </div>
    </div>
  );

  // If there are no test cases, we don't want to render an accordion
  if (!endpointCollectionHasFailedExecution()) {
    return renderTitle();
  }

  return (
    <div className={`${!isGrouped && "mb-5"}`}>
      <Accordion
        chevronSize={12}
        chevronOrientation="right"
        variant={isGrouped ? "none" : "outline"}
        onChange={function noRefCheck() {}}
        title={renderTitle()}
      >
        {/* We only want to show individual test cases if one of them failed */}
        {endpointCollectionHasFailedExecution() ? (
          <>
            {(endpointCollection?.test_cases ?? []).map((mappingTest) => {
              const testExecution = mappingTestExecutions.find(
                (execution) => execution?.mapping_test_id == mappingTest?.id
              );
              return testExecution ? (
                <MappingTestExecutionFailureCardV2
                  mappingTest={mappingTest}
                  testExecution={testExecution}
                />
              ) : null;
            })}
          </>
        ) : (
          <></>
        )}
      </Accordion>
    </div>
  );
};

export default EndpointCollectionContainerV2;
