import { createContext, useContext } from "react";
import { LinkedAccountAccessRequest, LinkedAccountAccessSession } from "../../../models/Entities";

interface CustomerLinkedAccountContextType {
  integrationID: string;
  showRequestModal: boolean;
  setShowRequestModal: (value: boolean) => void;
  currentAccessSession: LinkedAccountAccessSession | null;
  setCurrentAccessSession: (session: LinkedAccountAccessSession | null) => void;
  currentAccessRequest: LinkedAccountAccessRequest | null;
  setCurrentAccessRequest: (request: LinkedAccountAccessRequest | null) => void;
  fetchLinkedAccountAccessSession: () => void;
  fetchLinkedAccountAccessRequest: () => void;
  handleRequestButton: () => void;
  isUnauthorizedUser: boolean;
  isAccessRequestOutsideCurrentIntegration: boolean;
  isAccessSessionOutsideCurrentIntegration: boolean;
  showWithdrawRequestModal: boolean;
  setShowWithdrawRequestModal: (value: boolean) => void;
  showRevokeSessionModal: boolean;
  setShowRevokeSessionModal: (value: boolean) => void;
  revokeLinkedAccountAccess: () => void;
  withdrawLinkedAccountAccessRequest: () => void;
  acknowledgeLinkedAccountAccessRequest: (shouldHideSuccessToast?: boolean) => void;
  fetchTestLinkedAccounts: () => void;
}

const CustomerLinkedAccountContext = createContext<CustomerLinkedAccountContextType | undefined>(
  undefined
);

export const useCustomerLinkedAccountContext = (): CustomerLinkedAccountContextType => {
  const context = useContext(CustomerLinkedAccountContext);
  if (!context) {
    throw new Error(
      "useCustomerLinkedAccountContext must be used within a CustomerLinkedAccountContextProvider"
    );
  }
  return context;
};

export default CustomerLinkedAccountContext;
