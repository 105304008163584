import clsx from "clsx";
import {
  Button,
  ButtonVariant,
  Checkbox,
  Select,
  TextField,
  TextFieldVariant,
} from "@merge-api/merge-javascript-shared";
import { Minus, Plus } from "lucide-react";

export type Row = {
  active: boolean;
  key: string;
  value: string;
};

type PostmanSelectTableRowProps = {
  options: string[];
  className?: string;
  row?: Row;
  onNewLineClick?: () => void;
  onRowRemove?: () => void;
  onRowChange?: (key: "key" | "value" | "active", value: string | boolean) => void;
  disabled?: boolean;

  // if last row, will render a plus icon to insert a new row below
  isLastRow?: boolean;
  // if isUserInput, will render a minus icon to remove a row
  isUserInput?: boolean;
};

const PostmanSelectTableRow = ({
  className,
  row,
  isLastRow,
  onNewLineClick,
  onRowRemove,
  onRowChange,
  disabled,
  isUserInput,
  options,
}: PostmanSelectTableRowProps) => {
  return (
    <div
      className={clsx(
        className,
        "flex flex-row border-t-[0.5px] border-t-gray-20 border-solid min-w-[600px]"
      )}
    >
      {/* Checkbox/Plus Icon */}
      <div className="w-12 flex items-center justify-center shrink-0">
        {isLastRow ? (
          <Button size="sm" variant={ButtonVariant.TextBlack} onClick={onNewLineClick}>
            <Plus size={16} />
          </Button>
        ) : isUserInput ? (
          <Button size="sm" variant={ButtonVariant.TextBlack} onClick={onRowRemove}>
            <Minus size={16} />
          </Button>
        ) : (
          <Checkbox
            variant="primary"
            disabled={disabled}
            checked={row?.active}
            onChange={(checked) => onRowChange && onRowChange("active", checked)}
            className="my-2.5 mx-4"
          />
        )}
      </div>

      {/* Key */}
      <div
        className={clsx(
          "border-l-[0.5px] border-l-gray-20",
          isLastRow ? "pt-0.5 px-0.5 pb-[3px]" : "p-0.5"
        )}
      >
        <div className="w-64 flex h-full">
          <TextField
            variant={TextFieldVariant.InlinedCode}
            placeholder={"Enter key..."}
            value={row?.key}
            onChange={(e) => onRowChange && onRowChange("key", e.target.value)}
            className={clsx("min-w-64 w-64 grow-0 pr-[1px]", disabled && "pointer-events-none")}
            inputClassName={clsx(
              !row?.active && "text-black text-opacity-[.35]",
              disabled && "pointer-events-none"
            )}
            disabled={disabled}
          />
        </div>
      </div>

      {/* Value */}
      <div
        className={clsx(
          "border-l-[0.5px] border-l-gray-20 w-full",
          isLastRow ? "pt-0.5 px-0.5 pb-[3px]" : "p-0.5"
        )}
      >
        <div className="flex w-full h-full">
          <Select
            textVariant="md-mono"
            shadowHidden={true}
            options={options}
            onChange={(_: any, selectedOption: string | null) => {
              onRowChange && onRowChange("value", selectedOption || "");
            }}
            value={row?.value}
            clearable={true}
            className={clsx("w-full shadow-none", disabled && "pointer-events-none")}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default PostmanSelectTableRow;
