import useScraperContext from "./context/useScraperContext";
import { ScraperExceptionType, ScraperRaiseExceptionStep, ScraperValueType } from "./types";
import ScraperNestedParameterValueField from "./ScraperNestedParameterValueField";
import ScraperTextField from "./ScraperTextField";
import DropdownFormField from "../blueprint-editor/right-panel/DropdownFormField";
import ScraperCheckboxField from "./ScraperCheckboxField";

const ScraperRaiseExceptionFields = () => {
  const { selectedStep, updateStepParameterValue } = useScraperContext();
  const step = selectedStep as ScraperRaiseExceptionStep;

  // Exception types from enum ScraperExceptionType, sorted alphabetically,
  // for the dropdown menu.
  const EXCEPTION_TYPES = Object.values(ScraperExceptionType)
    .map((v) => {
      return { name: `${v}`, id: `${v}` };
    })
    .sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });

  return (
    <div key={`${step.id}-raise-exception-div`}>
      <DropdownFormField
        choices={EXCEPTION_TYPES}
        currentValue={step.parameter_values.exception_type.constant}
        placeholder={"Select exception type"}
        title={"exception_type"}
        subtitle={"The exception type to raise."}
        onChange={(e) => {
          updateStepParameterValue(step, "exception_type", {
            constant: e.target.value,
            value_type: ScraperValueType.CONSTANT,
          });
        }}
      />
      <ScraperNestedParameterValueField
        value={step.parameter_values.arguments}
        valueKey={"arguments"}
        updateValue={(newValue) => updateStepParameterValue(step, "arguments", newValue)}
      />
      <ScraperTextField
        value={step.parameter_values.exception_text}
        valueKey={"exception_text"}
        updateValue={(newValue) => updateStepParameterValue(step, "exception_text", newValue)}
        description={
          'Customer-facing exception message. Enter a string below. To show the value of x1 in the string, type "{x1}"'
        }
      />
      <ScraperCheckboxField
        valueKey={"should_retry"}
        updateValue={(newValue) => updateStepParameterValue(step, "should_retry", newValue)}
        value={step.parameter_values.should_retry}
      />
    </div>
  );
};

export default ScraperRaiseExceptionFields;
