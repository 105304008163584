import { useCallback, useEffect, useRef, useState } from "react";
import { fetchWithAuth } from "../api-client/api_client";
import { getCursorPath, getOffsetPath } from "../utils";
import { PaginationType } from "../models/Entities";

type UsePaginatedRequestProps = {
  rootPath: string;
  paramsPath?: string;
  isInfiniteScroll?: boolean;
  paginationType?: PaginationType;
};

/**
 * Hook for handling paginated request results. Loads initial value and provideds
 * next and previous values
 */
export function usePaginatedRequest<T>({
  rootPath,
  paramsPath = "",
  isInfiniteScroll,
  paginationType = PaginationType.CURSOR,
}: UsePaginatedRequestProps) {
  // hooks
  const [previousURL, setPreviousURL] = useState<string>();
  const [nextURL, setNextURL] = useState<string>();
  const [results, setResults] = useState<T[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasInitialized, setHasInitialized] = useState(false);
  const currentPath = useRef("");

  // event handlers
  const fetchWithCursor = useCallback(
    (next = "") => {
      setIsLoading(true);

      let paginationPath = "";
      if (paginationType === PaginationType.CURSOR && getCursorPath(next)) {
        paginationPath = `${getCursorPath(next)}&`;
      }
      if (paginationType === PaginationType.OFFSET) {
        paginationPath = `${getOffsetPath(next)}&`;
      }

      const path = `${rootPath}?${paginationPath}${paramsPath}`;
      currentPath.current = path;

      fetchWithAuth({
        path,
        method: "GET",
        onResponse: (data: { next: string; previous: string; results: T[] }) => {
          if (path !== currentPath.current) return; // prevent race condition

          setNextURL(data.next);
          setPreviousURL(data.previous);

          setResults((results = []) => {
            return isInfiniteScroll ? [...results, ...data.results] : data.results;
          });

          setIsLoading(false);
        },
        onError: (response) => {
          console.error(response);
          setResults([]);
          setIsLoading(false);
        },
      });
    },
    [isInfiniteScroll, paginationType, paramsPath, rootPath]
  );

  const onPrevious = () => fetchWithCursor(previousURL);
  const onNext = () => fetchWithCursor(nextURL);

  /**
   * Allows removing an item from data w/o having to refetch. Used when deleting an item.
   */
  const onRemove = (index: number) => {
    if (!results) return;
    setResults([...results.slice(0, index), ...results.slice(index + 1)]);
  };

  // effects
  // initialize
  useEffect(() => {
    if (!hasInitialized) {
      fetchWithCursor();
      setHasInitialized(true);
    }
  }, [fetchWithCursor, hasInitialized]);

  // refetch on params path change
  useEffect(() => {
    setHasInitialized(false);
    setResults([]);
  }, [paramsPath]);

  return {
    results,
    isLoading,
    onPrevious,
    onNext,
    onRemove,
    hasInitialized,
    onRefetch: fetchWithCursor,
    hasPrevious: !!previousURL,
    hasNext: !!nextURL,
  };
}
