import {
  Accordion,
  ButtonVariant,
  Dropdown,
  MenuItem,
  Text,
} from "@merge-api/merge-javascript-shared";
import { ClipboardEdit, FileText, Wifi, GripVertical, MoreHorizontal } from "lucide-react";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import SelectHeader from "../../../shared/SelectHeader";
import React, { useEffect, useState } from "react";
import HtmlPreviewModal from "./HtmlPreviewModal";
import {
  AuthConfigIntegrationBuilder,
  IntegrationSetupChecklistItem,
  IntegrationSetupChecklistItemIconEnums,
  LinkChoiceStepOption,
  LinkingFlowStepIntegrationBuilder,
  LinkingFlowStepTypeDisplayNameMap,
  LinkingFlowStepTypeDisplayNameReverseMap,
  LinkingFlowStepTypeEnums,
  ValidStepTypesForConfirmMessageField,
  ValidStepTypesForInputConfigurationFields,
  ValidStepTypesForIntegrationSetupChecklistItems,
} from "../../../utils/Entities";
import InputConfigurationFields from "./InputConfigurationFields";
import AdvancedConfigurationFields from "./AdvancedConfigurationFields";
import LinkChoiceStepOptionConfigurationFields from "../option/LinkChoiceStepOptionConfigurationFields";
import { Draggable } from "@hello-pangea/dnd";
import Callout from "../../../shared/Callout";
import { LinkingFlowStepTitlePlaceholder } from "../../utils/helpers";
import TypeaheadHeader from "../../../shared/TypeaheadHeader";
import KeyPathFieldHeader from "../../../shared/KeyPathFieldHeader";
import InputValidationConfigurationFields from "./InputValidationConfigurationFields";
import DescriptionHelpAccordionCard from "./DescriptionHelpAccordionCard";

const HTML_PLACEHOLDER =
  "<ol><li>Instruction 1</li><li>Instruction 2</li><ol><br><br><p>Only use paragraphs when sharing longer instructions for a <b>single step</b>.</p>";

interface Props {
  index: number;
  authConfigs: AuthConfigIntegrationBuilder[] | undefined;
  integrationName: string | undefined;
  integrationID: string | undefined;
  integrationSetupChecklistItems: IntegrationSetupChecklistItem[] | undefined;
  requestedStep: LinkingFlowStepIntegrationBuilder;
  requestedStepPathAuthConfigID: string;
  requestedLinkChoiceStepOptions: LinkChoiceStepOption[];
  setRequestedLinkChoiceStepOptions: React.Dispatch<React.SetStateAction<LinkChoiceStepOption[]>>;
  onCallbackUpdateStep: (index: number, keyValuePairs: Record<string, any>) => void;
  onCallbackDeleteStep: (index: number) => void;
  isExpanded: boolean;
}

const ConfigureStepCard = ({
  index,
  authConfigs,
  integrationName,
  integrationID,
  integrationSetupChecklistItems,
  requestedStep,
  requestedStepPathAuthConfigID,
  requestedLinkChoiceStepOptions,
  setRequestedLinkChoiceStepOptions,
  onCallbackUpdateStep,
  onCallbackDeleteStep,
  isExpanded = false,
}: Props) => {
  const [isModalHTMLPreviewOpen, setIsModalHTMLPreviewOpen] = useState<boolean>(false);
  const [isModalConfirmMessagePreviewOpen, setIsModalConfirmMessagePreviewOpen] = useState<boolean>(
    false
  );
  const [isDragDisabled, setIsDragDisabled] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(isExpanded);

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  return (
    <Draggable
      key={requestedStep.id || requestedStep.step_number}
      draggableId={requestedStep.id || requestedStep.step_number.toString()}
      isDragDisabled={isDragDisabled}
      index={index}
    >
      {(provided) => (
        <div
          data-testid={`accordion-step-card-${index}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Accordion
            chevronSize={16}
            chevronOrientation="right"
            onChange={(_, expanded) => setIsDragDisabled(expanded)}
            expanded={expanded}
            setExpanded={setExpanded}
            title={
              <div className="flex flex-row items-center justify-between w-full pr-6">
                <div className="flex flex-row items-center justify-start space-x-2">
                  <GripVertical size={16} />
                  <div>
                    <Text variant="h5" className="text-black normal-case">
                      Step {(index + 1).toString()}
                    </Text>
                  </div>
                  <div>
                    <Text variant="lg">
                      {LinkingFlowStepTypeDisplayNameMap[requestedStep.step_type]}
                    </Text>
                  </div>
                </div>
                <div
                  data-testid={`button-step-card-more-options-${index}`}
                  onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    event.stopPropagation();
                  }}
                >
                  <Dropdown
                    ButtonProps={{
                      children: <MoreHorizontal size={16} className="text-gray-50" />,
                      variant: ButtonVariant.IconShadowHidden,
                    }}
                    menuPlacement="bottom-end"
                  >
                    <MenuItem onClick={() => onCallbackDeleteStep(index)}>
                      <div data-testid={`button-step-card-delete-step-${index}`}>
                        <Text className="text-red-50">Delete step</Text>
                      </div>
                    </MenuItem>
                  </Dropdown>
                </div>
              </div>
            }
            titleClassName="px-5 py-4"
          >
            <div className="pt-4 pb-5 border-t-[0.5px] border-gray-20">
              <div className="px-5 space-y-6">
                <TypeaheadHeader
                  dataTestID={`field-step-card-step-type-${index}`}
                  title="Step type"
                  subtitle="Choose a 'step type' to define the specific fields displayed in the linking flow screen, offering customizable options to override the default fields in the sections below."
                  options={Object.values(LinkingFlowStepTypeDisplayNameMap)}
                  onChange={(_: any, selectedOption: string | string[] | null) => {
                    if (typeof selectedOption === "string") {
                      onCallbackUpdateStep(index, {
                        step_type: selectedOption
                          ? LinkingFlowStepTypeDisplayNameReverseMap[selectedOption]
                          : requestedStep.step_type,
                      });
                    }
                  }}
                  value={LinkingFlowStepTypeDisplayNameMap[requestedStep.step_type]}
                />
                {requestedStep.step_type === LinkingFlowStepTypeEnums.STEP_TYPE_CHOICE && (
                  <Callout
                    title="Choice step type"
                    description="Sometimes certain integration configuration fields depend on the account type or the account’s location. Choice steps enable you to specify these account types and the corresponding configuration that should be applied if the end user selects it."
                  />
                )}
                <TextFieldHeader
                  dataTestID={`field-step-card-title-${index}`}
                  title="Title"
                  subtitle="This will override the default title."
                  learnMoreTextComponent={
                    <ul>
                      <li>This is normally an action like “Enter API Key” or a question</li>
                      <li>No need to define title unless absolutely necessary</li>
                      <li>Do NOT end title with colon</li>
                      <li>Do NOT end title with period</li>
                      <li>Keep title to 1 line</li>
                    </ul>
                  }
                  placeholder={
                    "Default: " +
                    LinkingFlowStepTitlePlaceholder(requestedStep.step_type, integrationName)
                  }
                  value={requestedStep.title}
                  onChange={(event) => onCallbackUpdateStep(index, { title: event.target.value })}
                />
                <TextFieldHeader
                  dataTestID={`field-step-card-description-${index}`}
                  title="Description"
                  subtitle="Enter detailed instructions to guide the end user on how to authenticate, as a string or with HTML."
                  learnMoreText="Personalize your step instructions using placeholders: {ORGANIZATION_NAME} dynamically inserts your organization's name, and {ORGANIZATION_CONFIG:} embeds specific configuration details, like {ORGANIZATION_CONFIG:CONSENT_MANAGEMENT_LINK}, directly into your content."
                  autosize={true}
                  placeholder={"Example: " + HTML_PLACEHOLDER}
                  bottomLinkText={requestedStep.description && "Preview HTML"}
                  bottomLinkOnClick={() => setIsModalHTMLPreviewOpen(true)}
                  value={requestedStep.description}
                  onChange={(event) =>
                    onCallbackUpdateStep(index, { description: event.target.value })
                  }
                />
                <DescriptionHelpAccordionCard />
                <TextFieldHeader
                  dataTestID={`field-step-card-help-guide-url-${index}`}
                  title="Help guide URL"
                  subtitle="Enter an external link to help.merge.dev to provide detailed instructions for the end user to authenticate."
                  prefix="https://"
                  prefixVariant="url"
                  placeholder="Example: help.merge.dev/en/an-example-for-you"
                  error={
                    requestedStep?.help_guide_url
                      ? !/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
                          requestedStep.help_guide_url
                        ) || requestedStep.help_guide_url.substring(8).includes("https://")
                      : false
                  }
                  errorText="Must be a valid URL."
                  value={requestedStep.help_guide_url?.substring(8)}
                  onChange={(event) =>
                    onCallbackUpdateStep(index, {
                      help_guide_url: event.target.value ? "https://" + event.target.value : null,
                    })
                  }
                />
                <InputValidationConfigurationFields
                  index={index}
                  requestedStep={requestedStep}
                  onCallbackUpdateStep={onCallbackUpdateStep}
                />
                {ValidStepTypesForConfirmMessageField.includes(requestedStep.step_type) && (
                  <TextFieldHeader
                    dataTestID={`field-step-card-confirm-message-${index}`}
                    title="Confirm message"
                    subtitle="Enter a confirmation message as text or with HTML."
                    learnMoreText="Personalize your step instructions using placeholders: {ORGANIZATION_NAME} dynamically inserts your organization's name, and {ORGANIZATION_CONFIG:} embeds specific configuration details, like {ORGANIZATION_CONFIG:CONSENT_MANAGEMENT_LINK}, directly into your content."
                    multiline={true}
                    autosize={true}
                    placeholder={"Example: " + HTML_PLACEHOLDER}
                    bottomLinkText={requestedStep.confirm_message && "Preview HTML"}
                    bottomLinkOnClick={() => setIsModalConfirmMessagePreviewOpen(true)}
                    value={requestedStep.confirm_message}
                    onChange={(event) =>
                      onCallbackUpdateStep(index, { confirm_message: event.target.value })
                    }
                  />
                )}
                {ValidStepTypesForInputConfigurationFields.includes(requestedStep.step_type) && (
                  <InputConfigurationFields
                    index={index}
                    requestedStep={requestedStep}
                    onCallbackUpdateStep={onCallbackUpdateStep}
                  />
                )}
                {ValidStepTypesForIntegrationSetupChecklistItems.includes(
                  requestedStep.step_type
                ) && (
                  <SelectHeader
                    dataTestID={`field-step-card-integration-setup-checklist-item-${index}`}
                    title="Integration setup checklist item"
                    subtitle="If selected, this step will appear when the selected checklist item is clicked in Merge Link. This is mostly useful for SFTP auth configs."
                    options={integrationSetupChecklistItems || []}
                    renderOption={(option: IntegrationSetupChecklistItem) => {
                      return (
                        <div className="flex flex-row items-center space-x-2">
                          <div className="flex flex-row items-center">
                            {option.icon ===
                            IntegrationSetupChecklistItemIconEnums.INTEGRATION_SETUP_CHECKLIST_ITEM_ICON_WIFI ? (
                              <Wifi size={16} />
                            ) : option.icon ===
                              IntegrationSetupChecklistItemIconEnums.INTEGRATION_SETUP_CHECKLIST_ITEM_ICON_CLIPBOARD_EDIT ? (
                              <ClipboardEdit size={16} />
                            ) : (
                              <FileText size={16} />
                            )}
                          </div>
                          <div>
                            <Text>{option.title}</Text>
                          </div>
                        </div>
                      );
                    }}
                    onChange={(_: any, selectedOption: IntegrationSetupChecklistItem | null) =>
                      onCallbackUpdateStep(index, {
                        integration_setup_checklist_item_id: selectedOption?.id || null,
                      })
                    }
                    value={
                      integrationSetupChecklistItems?.find(
                        (checklistItem) =>
                          checklistItem.id === requestedStep.integration_setup_checklist_item_id
                      ) || null
                    }
                    clearable={true}
                  />
                )}
                {requestedStep.step_type ===
                  LinkingFlowStepTypeEnums.STEP_TYPE_SFTP_CREDENTIALS_V2 && (
                  <KeyPathFieldHeader
                    dataTestID={`field-step-card-sftp-credential-field-keys-${index}`}
                    className="mb-6"
                    title="SFTP credential field keys"
                    subtitle="If an integration requires SFTP credential setup, enter the keys of the SFTP credentials in the order you'd like them to be to displayed to an end user during the linking flow. Type the name of the key below and press Enter."
                    learnMoreText="Supported field keys are 'Username', 'Host', 'Port' ,'Path',' File encryption','Public key', and 'Private key'. In addition, you can alias the name of these supported fields with a colon, changing how the field is displayed in the linking flow. For example, 'Host: H' would disaply as 'H' in the linking flow."
                    placeholder="Add key name..."
                    onKeyPathChange={(value) => {
                      onCallbackUpdateStep(index, {
                        sftp_credential_field_keys: value,
                      });
                    }}
                    keyPath={requestedStep.sftp_credential_field_keys}
                    hasSource={false}
                    showArrow={false}
                  />
                )}
                <AdvancedConfigurationFields
                  index={index}
                  integrationID={integrationID}
                  authConfigs={authConfigs}
                  requestedStepPathAuthConfigID={requestedStepPathAuthConfigID}
                  requestedStep={requestedStep}
                  onCallbackUpdateStep={onCallbackUpdateStep}
                />
              </div>
              {requestedStep.step_type === LinkingFlowStepTypeEnums.STEP_TYPE_CHOICE && (
                <LinkChoiceStepOptionConfigurationFields
                  indexStep={index}
                  authConfigs={authConfigs}
                  requestedLinkChoiceStepOptions={requestedLinkChoiceStepOptions}
                  setRequestedLinkChoiceStepOptions={setRequestedLinkChoiceStepOptions}
                />
              )}
            </div>

            {isModalHTMLPreviewOpen && (
              <HtmlPreviewModal
                open={isModalHTMLPreviewOpen}
                onClose={() => setIsModalHTMLPreviewOpen(false)}
                description={requestedStep.description || ""}
              />
            )}
            {isModalConfirmMessagePreviewOpen && (
              <HtmlPreviewModal
                open={isModalConfirmMessagePreviewOpen}
                onClose={() => setIsModalConfirmMessagePreviewOpen(false)}
                description={requestedStep.confirm_message || ""}
              />
            )}
          </Accordion>
        </div>
      )}
    </Draggable>
  );
};

export default React.memo(ConfigureStepCard);
