import React from "react";
import { UserFacingFilterDetails } from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import { Badge, Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import CommonModelDisplay from "../shared/CommonModelDisplay";
import { ConditionType } from "../../types";
import { getHumanReadableConditionType } from "../../utils";
import useIntegrationBuilderContext from "../../../context/useIntegrationBuilderContext";

interface Props {
  filterDetails: UserFacingFilterDetails | null;
  updatePublicFacingStatus: (newStatus: boolean) => void;
}

const ReadOnlyUserFacingFilterDetailsField = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col">
      <Text variant="pre-title" className="text-gray-60">
        {title}
      </Text>
      {subtitle}
    </div>
  );
};

const ReadOnlyUserFacingFilterDetails = ({ filterDetails, updatePublicFacingStatus }: Props) => {
  const commonModelID = filterDetails?.filter_type_id?.common_model_class_id ?? "";
  const fieldKey = filterDetails?.filter_type_id?.filter_name ?? "";
  const remoteKey = filterDetails?.remote_key_name;
  const fieldKeyType = getHumanReadableConditionType(
    filterDetails?.filter_type_id?.value_type as ConditionType
  );
  const { markedForDeletion } = useIntegrationBuilderContext();
  const isPublicFacing = filterDetails?.is_public_facing ?? true;

  const InternalOnlyBadge = () =>
    isPublicFacing ? (
      <>
        <Badge className="ml-2" color="gray">
          Public Facing
        </Badge>
        <Button
          onClick={() => updatePublicFacingStatus(false)}
          className="ml-2"
          size="sm"
          variant={ButtonVariant.TextBlue}
          disabled={markedForDeletion}
        >
          Make internal only on publish
        </Button>
      </>
    ) : (
      <>
        <Badge className="ml-2" color="gray">
          Internal-only
        </Badge>
        <Button
          onClick={() => updatePublicFacingStatus(true)}
          className="ml-2"
          size="sm"
          variant={ButtonVariant.TextBlue}
          disabled={markedForDeletion}
        >
          Make public facing on publish
        </Button>
      </>
    );

  const NonCommonModelBadge = () =>
    !!filterDetails?.filter_type_id?.is_common_model_field ? (
      <></>
    ) : (
      <Badge className="ml-2" color={markedForDeletion ? "gray" : "yellow"}>
        Non-Common model
      </Badge>
    );

  return (
    <div className="flex flex-col">
      <ReadOnlyUserFacingFilterDetailsField
        title="Common Model"
        subtitle={<CommonModelDisplay isBold commonModelID={commonModelID} />}
      />
      <div className="mb-4" />
      <ReadOnlyUserFacingFilterDetailsField
        title="Field or key"
        subtitle={
          <div className="flex flex-row items-center">
            <Text variant="h6">{fieldKey}</Text>
            <Text variant="pre-title" className="text-gray-60 ml-2">
              {fieldKeyType}
            </Text>
            <InternalOnlyBadge />
            <NonCommonModelBadge />
          </div>
        }
      />
      <div className="mb-4" />
      <ReadOnlyUserFacingFilterDetailsField
        title="Remote key"
        subtitle={<Text variant="h6">{remoteKey}</Text>}
      />
    </div>
  );
};

export default ReadOnlyUserFacingFilterDetails;
