import {
  LinkingFlowStepPathIntegrationBuilder,
  LinkingFlowStepPathTableRowsIntegrationBuilder,
} from "../../utils/Entities";
import { useContext, useState } from "react";
import IntegrationBuilderContext from "../../context/IntegrationBuilderContext";
import { APIEndpointMethod } from "../../../../models/Entities";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { navigateToIntegrationBuilderExistingLinkingFlowStepPathSetup } from "../../../../router/RouterUtils";
import { useHistory } from "react-router-dom";

interface LinkingFlowStepPathParams {
  isPublishing?: boolean;
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  integrationID: string;
  linkingFlowStepPathID?: string;
  requestedLinkingFlowStepPath: LinkingFlowStepPathIntegrationBuilder | undefined;
  setStepPathTableRows?: React.Dispatch<
    React.SetStateAction<LinkingFlowStepPathTableRowsIntegrationBuilder[] | undefined>
  >;
  reloadLinkingFlowStepPathsAndAuthConfigs: () => void;
}

const useCreateOrPatchLinkingFlowStepPath = ({
  isPublishing,
  setIsModalOpen,
  integrationID,
  linkingFlowStepPathID,
  requestedLinkingFlowStepPath,
  reloadLinkingFlowStepPathsAndAuthConfigs,
}: LinkingFlowStepPathParams) => {
  const history = useHistory();

  const [isLoadingStepPathUpdate, setIsLoadingStepPathUpdate] = useState<boolean>(false);
  const {
    isLinkingFlowStepPathComplete,
    setIsLinkingFlowStepPathComplete,
    resetDiffStates,
  } = useContext(IntegrationBuilderContext);

  const requestBody = requestedLinkingFlowStepPath;

  const createLinkingFlowStepPath = () => {
    setIsLoadingStepPathUpdate(true);
    fetchWithAuth({
      path: `/integrations/${integrationID}/integration-setup-step-paths`,
      method: APIEndpointMethod.POST,
      body: requestBody,
      onResponse: (responseData: LinkingFlowStepPathIntegrationBuilder) => {
        showSuccessToast("Merge Link steps successfully created.");
        setIsLoadingStepPathUpdate(false);
        if (setIsModalOpen) setIsModalOpen(false);

        if (isLinkingFlowStepPathComplete === false) {
          setIsLinkingFlowStepPathComplete(true);
        }

        reloadLinkingFlowStepPathsAndAuthConfigs();

        if (responseData.id) {
          // reset diff states, so that unsaved modal doesn't pop up
          resetDiffStates();
          navigateToIntegrationBuilderExistingLinkingFlowStepPathSetup(
            history,
            integrationID,
            responseData.id
          );
        }
      },
      onError: () => {
        showErrorToast("Failed to create Merge Link steps.");
        setIsLoadingStepPathUpdate(false);
        if (setIsModalOpen) setIsModalOpen(false);
      },
    });
  };

  const patchLinkingFlowStepPath = () => {
    setIsLoadingStepPathUpdate(true);
    fetchWithAuth({
      path: `/integrations/${integrationID}/integration-setup-step-paths/${linkingFlowStepPathID}`,
      method: APIEndpointMethod.PATCH,
      body: requestBody,
      onResponse: () => {
        showSuccessToast(
          isPublishing && isPublishing === true
            ? "Successfully published Merge Link steps."
            : isPublishing && isPublishing === false
            ? "Successfully unpublished Merge Link steps."
            : "Successfully updated Merge Link steps."
        );

        reloadLinkingFlowStepPathsAndAuthConfigs();

        setIsLoadingStepPathUpdate(false);
        if (setIsModalOpen) setIsModalOpen(false);
      },
      onError: () => {
        showErrorToast("Failed to update Merge Link steps.");
        setIsLoadingStepPathUpdate(false);
        if (setIsModalOpen) setIsModalOpen(false);
      },
    });
  };

  return { createLinkingFlowStepPath, patchLinkingFlowStepPath, isLoadingStepPathUpdate };
};

export default useCreateOrPatchLinkingFlowStepPath;
