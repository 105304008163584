import { useEffect, useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast } from "../../../shared/Toasts";
import { PaginationConfigurationIntegrationBuilder } from "../../utils/Entities";

interface LoadPaginationConfigurationsProps {
  integrationID: string;
  setPaginationConfigurations: React.Dispatch<
    React.SetStateAction<PaginationConfigurationIntegrationBuilder[] | undefined>
  >;
  setIsLoadingPaginationConfigurations?: React.Dispatch<React.SetStateAction<boolean>>;
}

const useLoadPaginationConfigurations = ({
  integrationID,
  setPaginationConfigurations,
  setIsLoadingPaginationConfigurations = () => {},
}: LoadPaginationConfigurationsProps) => {
  const [defaultPaginationConfiguration, setDefaultPaginationConfiguration] = useState<
    PaginationConfigurationIntegrationBuilder | undefined
  >();

  // effects
  useEffect(() => {
    fetchWithAuth({
      path: `/integrations/integration-builder/integration/${integrationID}/pagination-configurations`,
      method: "GET",
      onResponse: (data: PaginationConfigurationIntegrationBuilder[]) => {
        setPaginationConfigurations(data);
        const defaultPaginationConfig = data.find((config) => config.is_default_for_integration);
        setDefaultPaginationConfiguration(defaultPaginationConfig);
        setIsLoadingPaginationConfigurations(false);
      },
      onError: () => {
        showErrorToast("Failed to fetch pagination configurations for integration.");
        setDefaultPaginationConfiguration(undefined);
        setIsLoadingPaginationConfigurations(false);
        setPaginationConfigurations([]);
      },
    });
  }, [setPaginationConfigurations]);

  return {
    defaultPaginationConfiguration,
  };
};

export default useLoadPaginationConfigurations;
