import { BlueprintParameterSchemaValue } from "../../../models/Blueprints";
import {
  MappingTestBodyParameters,
  MappingTestExpectedResult,
  MappingTestSuiteEndpointCollection,
  MappingTestCommonModel,
  MappingTestCommonModelExpectedMappings,
  MappingTestExpectedMapping,
} from "../../../models/MappingTests";

export enum panelPositionEnum {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  CENTER = "CENTER",
}

const omittedKeys = new Set([
  "id",
  "created_at",
  "modified_at",
  "encrypted_remote_data",
  "remote_was_deleted",
  "unique_identifier",
  "linked_account",
  "linked_account_params",
  "integration_params",
]);

export const getEmptyValueForField = (
  commonModelField: undefined | BlueprintParameterSchemaValue
) =>
  !commonModelField
    ? ""
    : commonModelField.type === "array" ||
      (commonModelField.relation && commonModelField.relation?.is_many_to_many)
    ? []
    : //@ts-ignore choices is deprecated but old step templates rely on it
    commonModelField.type === "boolean" || commonModelField.enum || commonModelField.choices
    ? "None"
    : "";

export const getBodyparameterValueByPath = (
  fieldKeyPath: Array<string>,
  bodyParameters: Record<string, any>
): any => {
  const currVal = bodyParameters?.[fieldKeyPath[0]];

  if (fieldKeyPath.length == 1) return currVal;
  return getBodyparameterValueByPath(fieldKeyPath.slice(1), currVal);
};

const getPermittedFieldEntries = (fields: MappingTestCommonModel["fields"]) => {
  return Object.entries(fields).filter(([fieldKey, _]) => !omittedKeys.has(fieldKey));
};

export const getEmptyCommonModelExpectedMappingForCommonModel = (
  commonModel: MappingTestCommonModel
): MappingTestExpectedMapping => {
  const fieldMappings = Object.fromEntries(
    getPermittedFieldEntries(commonModel.fields).map(([key, commonModelField]) => {
      return [key, getEmptyValueForField(commonModelField)];
    })
  );

  return {
    id: undefined,
    common_model: commonModel.id,
    unique_identifier: "",
    field_mappings: fieldMappings,
  };
};

export const addNewCommonModelExpectedMapping = ({
  expectedMappings,
  commonModel,
  newExpectedMappingName,
}: {
  expectedMappings: MappingTestCommonModelExpectedMappings;
  commonModel: MappingTestCommonModel;
  newExpectedMappingName: string;
}): MappingTestCommonModelExpectedMappings => {
  return {
    ...expectedMappings,
    [newExpectedMappingName]: getEmptyCommonModelExpectedMappingForCommonModel(commonModel),
  };
};

export const changeCommonModelExpectedMappingName = ({
  expectedMappings,
  oldExpectedMappingName,
  newExpectedMappingName,
}: {
  expectedMappings: MappingTestCommonModelExpectedMappings;
  oldExpectedMappingName: string;
  newExpectedMappingName: string;
}): MappingTestCommonModelExpectedMappings => {
  const { [oldExpectedMappingName]: _, ...commonModelMappingsWithoutOldName } = expectedMappings;
  return {
    ...commonModelMappingsWithoutOldName,
    [newExpectedMappingName]: expectedMappings[oldExpectedMappingName],
  };
};

export const deleteCommonModelExpectedMapping = ({
  expectedMappings,
  oldExpectedMappingName,
}: {
  expectedMappings: MappingTestCommonModelExpectedMappings;
  oldExpectedMappingName: string;
}): undefined | MappingTestCommonModelExpectedMappings => {
  const { [oldExpectedMappingName]: _, ...commonModelMappingsWithoutOldName } = expectedMappings;
  return Object.keys(commonModelMappingsWithoutOldName).length === 0
    ? undefined
    : commonModelMappingsWithoutOldName;
};

export const getReadableNameForExpectedResult = (
  expectedResult: MappingTestExpectedResult
): string => {
  switch (expectedResult) {
    case MappingTestExpectedResult.SUCCESS:
      return "Success";
    case MappingTestExpectedResult.SUCCESS_WITH_WARNINGS:
      return "Success with Warnings";
    case MappingTestExpectedResult.ERROR:
      return "Error";
  }
};

export const initializeBodyParametersForMappingTest = (
  bodyParameters: MappingTestBodyParameters,
  endpointTestCollection: undefined | MappingTestSuiteEndpointCollection
): MappingTestBodyParameters => {
  // const operationType = endpointTestCollection?.operation_type;

  const modelProperties =
    endpointTestCollection?.body_parameter_schema?.properties?.model?.properties ?? {};
  const modelValues = bodyParameters?.model;

  const model = Object.fromEntries(
    Object.entries(modelProperties).map(([fieldKey, _]) => [
      fieldKey,
      modelValues?.[fieldKey] ?? undefined,
    ])
  );

  return {
    ...bodyParameters,
    //@ts-ignore
    model: model,
  };
};
