import { useEffect } from "react";
import { BlueprintStepTemplate, BlueprintParameterSchema } from "../../../models/Blueprints";
import TypeaheadFormField from "./TypeaheadFormField";

interface Props {
  stepID: string;
  stepTemplate: BlueprintStepTemplate;
}

const BlueprintEditorRightPanelSetRemoteDeletedForm = ({ stepID, stepTemplate }: Props) => {
  useEffect(() => {
    const tempOtherIdentifierFields: BlueprintParameterSchema<string> = {};
    const tempParameterFields: BlueprintParameterSchema<string> = {};

    Object.entries(stepTemplate.parameter_schema ?? {}).forEach(([key, parameter]) => {
      if (key === "id") {
        tempOtherIdentifierFields[key] = parameter;
      } else {
        tempParameterFields[key] = parameter;
      }
    });
  }, [stepID, stepTemplate]);

  return (
    <>
      <form>
        <TypeaheadFormField
          key={"id"}
          title={"id"}
          subtitle={"The ID of the matching object."}
          valueKey={"id"}
          parameterType={"uuid"}
        />
        <TypeaheadFormField
          key={"should_cascade"}
          title={"should_cascade"}
          subtitle={"True if deletion should cascade to all relations, else false."}
          valueKey={"should_cascade"}
          parameterType={"boolean"}
        />
      </form>
    </>
  );
};

export default BlueprintEditorRightPanelSetRemoteDeletedForm;
