import { match, useParams } from "react-router-dom";
import { HTTPMethod } from "../../../models/HTTPMethods";
import useRequest from "../../shared/hooks/useRequest";
import { TestsResponse } from "../../../models/MappingTests";
import FullPageSpinner from "../../shared/layout/FullPageSpinner";
import { initializeBodyParametersForMappingTest } from "../utils/MappingTestBuildingUtils";
import MappingTestContextProvider from "../context/MappingTestContextProvider";
import { useEffect, useState } from "react";
import MappingTestV2EditorView from "./MappingTestV2Editor";
import useDocumentTitle from "../../shared/useDocumentTitle";

type Props = {
  match: match;
};

const MappingTestEditorRoot = ({}: Props) => {
  const { testID } = useParams<{ integrationID: string; testID: string }>();
  const { data, isLoading, hasError } = useRequest<TestsResponse>({
    path: `/stallions/tests/${testID}`,
    method: HTTPMethod.GET,
  });

  const [isDebugMode, setIsDebugMode] = useState<boolean>(false);
  const [mappingTestName, setMappingTestName] = useState<string>("");
  const [writtenCommonModel, setWrittenCommonModel] = useState<string>("");
  const [isMappingTestNextVersionNull, _] = useState<boolean>(
    data?.mapping_test_and_versions?.next_version == null
  );
  const isReadTestCollection = data?.endpoint_test_collection?.is_read_test_collection ?? false;
  const [
    nestedWritesMapForMappingTestModelArray,
    setNestedWritesMapForMappingTestModelArray,
  ] = useState<string[]>([]);

  const commonModelName = data?.endpoint_test_collection?.common_model_name ?? undefined;
  const nestedWritesMap = data?.endpoint_test_collection?.nested_writes_map ?? undefined;

  // This useEffect sets the value of the array of the endpoint test collection's common model, if it exists, and returns its associated nested writes map
  useEffect(() => {
    if (commonModelName && nestedWritesMap) {
      if (commonModelName in nestedWritesMap) {
        setNestedWritesMapForMappingTestModelArray(nestedWritesMap[commonModelName]);
      }
    }
  }, [commonModelName, nestedWritesMap]);

  const getDocumentTitle = (integrationName: string) => `${integrationName} - Mapping Test`;
  useDocumentTitle(getDocumentTitle(data?.integration?.name ?? ""), [data?.integration?.name]);

  useEffect(() => {
    setMappingTestName(data?.mapping_test_and_versions?.name ?? "");
    setWrittenCommonModel(data?.blueprints?.[0]?.written_common_model ?? "");
  }, [data?.mapping_test_and_versions?.name, data?.blueprints]);

  const currentMappingTestVersion =
    data?.mapping_test_and_versions.next_version ??
    data?.mapping_test_and_versions.published_version;

  const mappingTestVersionUnderConstruction = {
    ...currentMappingTestVersion,
    requests: currentMappingTestVersion?.requests ?? [],
    should_check_auth_headers: currentMappingTestVersion?.should_check_auth_headers ?? false,
    common_model_count_assertions: currentMappingTestVersion?.common_model_count_assertions ?? {},
    common_model_mappings: currentMappingTestVersion?.common_model_mappings ?? {},
    existing_common_models: currentMappingTestVersion?.existing_common_models,
    blueprint_metadata: currentMappingTestVersion?.blueprint_metadata ?? {},
    max_page_iterations: currentMappingTestVersion?.max_page_iterations ?? 2,
    frozen_time: currentMappingTestVersion?.frozen_time,
    max_loop_iterations: currentMappingTestVersion?.max_loop_iterations ?? 2,
    body_parameters: initializeBodyParametersForMappingTest(
      currentMappingTestVersion?.body_parameters,
      data?.endpoint_test_collection
    ),
    existing_common_model_name: currentMappingTestVersion?.existing_common_model_name,
    mapping_test_blocks: currentMappingTestVersion?.mapping_test_blocks ?? [],
    construction_errors: currentMappingTestVersion?.construction_errors ?? [],
  };

  if (!data || !mappingTestVersionUnderConstruction || isLoading || hasError) {
    return <FullPageSpinner />;
  }

  return (
    <MappingTestContextProvider
      isDebugMode={isDebugMode}
      setIsDebugMode={setIsDebugMode}
      mappingTestName={mappingTestName}
      setMappingTestName={setMappingTestName}
      writtenCommonModel={writtenCommonModel}
      integrationID={data.integration.id}
    >
      <MappingTestV2EditorView
        isReadTestCollection={isReadTestCollection}
        nestedWritesMapForMappingTestModelArray={nestedWritesMapForMappingTestModelArray}
        isMappingTestNextVersionNull={isMappingTestNextVersionNull}
        readTestInformation={data.read_test_information}
        bodyParameterSchema={data.endpoint_test_collection?.body_parameter_schema}
        writtenOrUpdatedCommonModel={data.endpoint_test_collection?.common_model_name}
        integration={data.integration}
        blueprints={data.blueprints}
        draftBlueprints={data.ineligible_draft_blueprint_versions}
        common_models={data.common_models}
        mappingTestVersionUnderConstruction={mappingTestVersionUnderConstruction}
        mappingTestID={testID}
        mappingTestOperationType={data.mapping_test_and_versions.operation_type}
        webhookReceiverEventTypeID={data.mapping_test_and_versions?.webhook_receiver_event_type_id}
        mappingTestName={data.mapping_test_and_versions.name}
        mappingTestState={data.mapping_test_and_versions.next_version_state}
        linkedAccount={data.linked_account}
        expectedResult={data.mapping_test_and_versions.expected_result}
        commonModelName={commonModelName}
      />
    </MappingTestContextProvider>
  );
};

export default MappingTestEditorRoot;
