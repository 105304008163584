import { Alert, Card, Text } from "@merge-api/merge-javascript-shared";
import {
  DiffModalTypeEnum,
  DiffModelTypeEnum,
  DiffState,
  VERSIONED_MODEL_TYPES,
} from "../../../../models/DiffModels";
import SplitDiffFieldRow from "./SplitDiffFieldRow";
import clsx from "clsx";

interface Props {
  diffState: DiffState;
  currentStateTitle?: string | undefined;
  newStateTitle?: string | undefined;
  diffModalType?: DiffModalTypeEnum;
  modelType?: DiffModelTypeEnum;
}

const SplitDiffView = ({
  diffState,
  currentStateTitle,
  newStateTitle,
  diffModalType,
  modelType,
}: Props) => {
  // className constant derived from state
  const modalClass = clsx("flex flex-col overflow-y-auto", {
    "max-h-[536px]": diffModalType === "PRESAVE",
    "max-h-[603px]": diffModalType !== "PRESAVE",
  });

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-row w-full">
        <div className="truncate w-1/2 pr-6">
          <Text variant="h6" className="truncate">
            {currentStateTitle || "Current version"}
          </Text>
        </div>
        <div className="truncate w-1/2 pr-6">
          <Text variant="h6" className="truncate">
            {newStateTitle || "Your changes"}
          </Text>
        </div>
      </div>
      <Card variant="outline" className="overflow-hidden">
        <div className={modalClass}>
          {diffState.map((field) => {
            return <SplitDiffFieldRow key={field.name} field={field} />;
          })}
          <div className="flex flex-1 flex-row">
            <div className="w-1/2 border-r-[0.5px] border-gray-20"></div>
            <div className="w-1/2"></div>
          </div>
        </div>
      </Card>
      {diffModalType === DiffModalTypeEnum.PRESAVE &&
        modelType &&
        !VERSIONED_MODEL_TYPES.includes(modelType) && (
          <Alert className="mt-2" showWarningIcon>
            <Text variant="md">
              This component is unversioned, so saved changes will be immediately reflected in
              production
            </Text>
          </Alert>
        )}
    </div>
  );
};

export default SplitDiffView;
