import { NavLink, useHistory } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../assets/svg/merge-black-white.png";
import { onLogout } from "../../api-client/api_client";
import { ADMIN_ROOT_PATH, LOGIN_PATH, INTEGRATION_DASHBOARD_PATH } from "../../router/RouterUtils";
import { Globe, Home, Layers, Layout, LogOut } from "lucide-react";
import { palette } from "../../styles/theme/colors";

const Nav = styled.nav.attrs({
  className: "navbar navbar-vertical fixed-left navbar-expand-md d-flex flex-column p-0",
  id: "sidebar",
})`
  && {
    background: #000240;
    max-width: 64px;
  }
`;

const LogoLink = styled(NavLink).attrs({
  className:
    "nav-link sub-nav-link d-flex flex-column align-items-center justify-content-center p-0",
})`
  && {
    width: 64px;
    height: 64px;
  }
`;

interface NavItemInterface {
  disabled?: boolean;
}

const NavItem = styled.li.attrs({
  className: "nav-item",
})<NavItemInterface>`
  && {
    ${(props) =>
      props.disabled &&
      `
    pointer-events: none;
    opacity: 0.35;
    cursor: auto;
    `}
  }
`;

const StyledNavLink = styled(NavLink).attrs({
  className:
    "nav-link sub-nav-link d-flex flex-column align-items-center justify-content-center p-0",
})`
  && {
    width: 64px;
    height: 64px;

    &:hover {
      background-color: rgba(244, 248, 255, 0.15);
    }
  }
`;

const StyledExternalLink = styled.a.attrs({
  className:
    "nav-link sub-nav-link d-flex flex-column align-items-center justify-content-center p-0",
})`
  && {
    width: 64px;
    height: 64px;
    &:hover {
      background-color: rgba(244, 248, 255, 0.15);
    }
  }
`;

const NavLinkText = styled.p`
  && {
    font-size: 8.5px;
    font-weight: 700;
    color: #ffffff;
    margin: 0;
    line-height: 12px;
    padding-top: 6px;
  }
`;

function LeftNavigationBar() {
  const history = useHistory();
  const logout = () =>
    onLogout(history, { onError: () => console.error("Failed to logout. Please try again.") });

  return (
    <Nav>
      <LogoLink to={ADMIN_ROOT_PATH}>
        <img src={Logo} style={{ height: 28 }} />
      </LogoLink>
      <ul className="sub-navbar-nav-side m-0 p-0 flex-fill d-flex flex-column justify-content-between">
        <div>
          <NavItem>
            <StyledNavLink to={INTEGRATION_DASHBOARD_PATH}>
              <Globe color={palette.white} />
              <NavLinkText>Integrations</NavLinkText>
            </StyledNavLink>
          </NavItem>
        </div>
        <div>
          <NavItem>
            <StyledExternalLink href="https://www.merge.dev" target="_blank">
              <Home color={palette.white} />
              <NavLinkText>Landing</NavLinkText>
            </StyledExternalLink>
          </NavItem>
          <NavItem>
            <StyledExternalLink href="https://app.merge.dev" target="_blank">
              <Layout color={palette.white} />
              <NavLinkText>Dashboard</NavLinkText>
            </StyledExternalLink>
          </NavItem>
          <NavItem>
            <StyledExternalLink href="https://docs.merge.dev" target="_blank">
              <Layers color={palette.white} />
              <NavLinkText>Docs</NavLinkText>
            </StyledExternalLink>
          </NavItem>
          <NavItem>
            <StyledNavLink to={LOGIN_PATH} onClick={logout}>
              <LogOut color={palette.white} />
              <NavLinkText>Log out</NavLinkText>
            </StyledNavLink>
          </NavItem>
        </div>
      </ul>
    </Nav>
  );
}

export default LeftNavigationBar;
