import { Integration } from "../../../../../models/Entities";
import {
  NameCell,
  CategoryCell,
  LastModifiedCell,
  LinkedAccountsCell,
  MissingConfigurationsCell,
  MappingTestCoverageCell,
} from "./components";
import { ChevronRight } from "lucide-react";
import { spectrum } from "../../../../../styles/theme/colors";
import { useHistory } from "react-router-dom";
import {
  getBlueprintsPath,
  navigateHelper,
  navigateToIntegrationBuilderPostIntegrationInitialization,
} from "../../../../../router/RouterUtils";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";

type IntegrationsDashboardTableRowProps = {
  integration: Integration;
};

const IntegrationsDashboardTableRow = ({ integration }: IntegrationsDashboardTableRowProps) => {
  // hooks
  const history = useHistory();

  // event handlers
  const onIntegrationClick = (event: React.MouseEvent<HTMLDivElement>) => {
    navigateHelper(history, getBlueprintsPath(integration.id), event.metaKey || event.ctrlKey);
  };

  return (
    <tr onClick={onIntegrationClick} className="cursor-pointer">
      <NameCell integration={integration} />
      <CategoryCell category_statuses={integration?.categories_status_info} />
      <LastModifiedCell lastModifiedBlueprintAt={integration.last_modified_blueprint_at} />
      <LinkedAccountsCell numberOfLinkedAccounts={integration.number_of_linked_accounts} />
      <MissingConfigurationsCell integrationChecklist={integration.integration_checklist} />
      <MappingTestCoverageCell
        integrationTestCoverage={integration.test_coverage}
        numberOfStagedTests={integration.number_of_staged_tests}
      />
      <td>
        <div className="flex flex-row items-center justify-center">
          <Button
            variant={ButtonVariant.TertiaryWhite}
            size="sm"
            className="mr-2"
            onClick={(event) => {
              event.stopPropagation();
              navigateToIntegrationBuilderPostIntegrationInitialization(
                history,
                integration.id,
                true
              );
            }}
          >
            Edit integration
          </Button>
          <ChevronRight size={16} color={spectrum.gray60} />
        </div>
      </td>
    </tr>
  );
};

export default IntegrationsDashboardTableRow;
