export enum SelectSyncFilterOperators {
  GREATER_THAN = "Greater than",
  LESS_THAN = "Less than",
  EQUAL_TO = "Equal to",
}

export enum ConditionType {
  BOOLEAN = "BOOLEAN",
  DATE = "DATE",
  DATE_TIME = "DATE_TIME",
  INTEGER = "INTEGER",
  FLOAT = "FLOAT",
  STRING = "STRING",
}

export enum DatetimeFormatEnum {
  ISO_FORMAT = "ISO_FORMAT",
  ISO_FORMAT_SECONDS = "ISO_FORMAT_SECONDS",
  MILLISECONDS = "MILLISECONDS",
  SECONDS = "SECONDS",
}

// Currently the condition operators we support are spread across 4 different enums,
// ConditionListOperator, ConditionStringOperator, ConditionComparableOperator and ConditionBooleanOperator
export enum ConditionListOperator {
  IS_ONE_OF = "IS_ONE_OF",
  HAS_ALL_OF = "HAS_ALL_OF",
  HAS_NONE_OF = "HAS_NONE_OF",
}

export type ConditionStringOperator = "CONTAINS" | "EQUALS" | "NOT_EQUALS";

export type ConditionComparableOperator =
  | "GREATER_THAN"
  | "GREATER_THAN_OR_EQUAL_TO"
  | "LESS_THAN"
  | "LESS_THAN_OR_EQUAL_TO"
  | "EQUALS"
  | "NOT_EQUALS"
  | "CONTAINS";

export type ConditionBooleanOperator = "EQUALS" | "NOT_EQUALS";

// Combine all condition operators into a single type
export type ConditionOperator =
  | ConditionListOperator
  | ConditionStringOperator
  | ConditionComparableOperator
  | ConditionBooleanOperator;

export const CONDITION_OPERATORS: Record<ConditionOperator, string> = {
  IS_ONE_OF: "Is one of",
  HAS_ALL_OF: "Has all of",
  HAS_NONE_OF: "Has none of",
  CONTAINS: "Contains",
  EQUALS: "Equals",
  NOT_EQUALS: "Not equals",
  GREATER_THAN: "Greater than",
  GREATER_THAN_OR_EQUAL_TO: "Greater than or equal to",
  LESS_THAN: "Less than",
  LESS_THAN_OR_EQUAL_TO: "Less than or equal to",
};

// NOTE: Derive from RequestLocation in AUTOGENERATED types
export enum FilterLocationEnum {
  BODY = "BODY",
  PATH = "PATH",
  QUERY = "QUERY",
  HEADER = "HEADER",
}

export enum CompositeFilterEnum {
  ODATA = "ODATA",
  INTERCOM_QUERY_LANGUAGE = "INTERCOM_QUERY_LANGUAGE",
  HUBSPOT_FILTER_GROUPS = "HUBSPOT_FILTER_GROUPS",
}

export enum ListDelimiterEnum {
  Comma = ",",
  "Custom delimiter" = "Custom delimiter",
}

export interface AbstractConditionValue {
  value: string | string[];
  typed_value: string | string[];
}

export interface AbstractCondition {
  operator: string;
  value: AbstractConditionValue;
  integration_id: string | null;
  filter_type_id: string;
}
