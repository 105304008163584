import { useEffect, useState } from "react";
import IntegrationBuilderHeader from "../shared/IntegrationBuilderHeader";
import useIntegrationBuilderContext from "../context/useIntegrationBuilderContext";
import AIInitializiation from "../endpoint-collection/components/AIInitialization";

interface AISearchProps {
  integrationID: string;
}

const AISearch = ({ integrationID }: AISearchProps) => {
  // state
  const [apiDocumentationURL, setApiDocumentationURL] = useState<string>("");

  // hooks
  const {
    integration,
    aiSearchConfigurationStatus,
    setAISearchConfigurationStatus,
  } = useIntegrationBuilderContext();

  // update api documentation url when integration loads
  useEffect(() => {
    // The api documentation url got backfilled with "API Documentation URL not provided." for existing integrations, so we need to check for that
    if (integration?.api_documentation_url) {
      setApiDocumentationURL(
        integration.api_documentation_url == "API Documentation URL not provided."
          ? ""
          : integration.api_documentation_url
      );
    } else {
      setApiDocumentationURL("");
    }
  }, [integration]);

  /*
   * TODO - update submission context for when we have backend endpoint/models
   * sets canSubmit, onSubmit, isLoadingSubmit, and submitText states in context
   **/
  useIntegrationBuilderContext({
    canSubmit: true,
    shouldRenderSubmitButton: false,
    shouldRenderStageButton: false,
    shouldRenderNavigationButtons: true,
    shouldRenderDeleteButton: false,
    markForDeletion: false,
  });

  return (
    <div>
      <IntegrationBuilderHeader title="AI search" />
      <AIInitializiation
        integrationID={integrationID}
        apiDocumentationURL={apiDocumentationURL}
        setApiDocumentationURL={setApiDocumentationURL}
        aiSearchConfigurationStatus={aiSearchConfigurationStatus}
        setAISearchConfigurationStatus={setAISearchConfigurationStatus}
      />
    </div>
  );
};

export default AISearch;
