import {
  DiffState,
  DiffStateField,
  DiffStateFieldTypeEnum,
  DiffStatusEnum,
} from "../../../../../models/DiffModels";
import { getDiffStatus } from "../helpers";
import {
  generateDiffState,
  generateEmptyDiffState,
  globalCurrentState,
  globalNewState,
} from "../helpers-diff";
import { generateDiffStateForStepParams } from "./blueprint-step-params-diff";
import { generateDiffStateForStepTemplate } from "./blueprint-step-template-diff";
import { isSequenceOfIDs, retrieveMapToNextID, retrieveStepIDs, retrieveSteps } from "./helpers";

/**
 * The functions in this file generates DiffState for a Blueprint
 * 
 * EXAMPLE
 * 
 * INPUT ORDER OF STEPS (SIMPLIFIED)
    current = [
        "get_linked_account",
        "custom_function_1",
        "create_or_update",
        "if_else",
    ]
    new = [
        "set_variable"
        "custom_function_1",
        "get_linked_account",
        "if_else",
    ]
  * OUTPUT DIFF STATE (SIMPLIFIED)
    [
        ("", "set_variable"),                       -> ADDED
        ("get_linked_account", ""),                 -> TREAT AS DELETED
        ("custom_function_1", "custom_function_1"), -> SAME
        ("", "get_linked_account"),                 -> TREAT AS NEW
        ("", "create_or_update"),                   -> NEW
        ("if_else", "if_else"),                     -> SAME
    ]
  *
  */

export const generateDiffForBlueprint = (): DiffState => {
  return generateDiffState({
    fields: [
      {
        type: DiffStateFieldTypeEnum.HEADER,
        name: "steps",
        displayName: "Steps",
        isChildBlueprintSteps: true,
      },
    ],
  });
};

interface GeneratedDiffStateForBlueprintStepsProps {
  overrideCurrentSteps?: { [key: string]: any }[];
  overrideNewSteps?: { [key: string]: any }[];
}

export const generateDiffStateForBlueprintSteps = ({
  overrideCurrentSteps,
  overrideNewSteps,
}: GeneratedDiffStateForBlueprintStepsProps): DiffState => {
  let diffStateFields: DiffState = [];

  const currentSteps: { [key: string]: any }[] = retrieveSteps(
    overrideCurrentSteps,
    globalCurrentState
  );
  const newSteps: { [key: string]: any }[] = retrieveSteps(overrideNewSteps, globalNewState);

  const currentIDs: string[] = retrieveStepIDs(currentSteps);
  const newIDs: string[] = retrieveStepIDs(newSteps);

  const currentMapToNextStepIDs: { [key: string]: string | null } = retrieveMapToNextID(currentIDs);
  const newMapToNextStepIDs: { [key: string]: string | null } = retrieveMapToNextID(newIDs);

  let indexCurrent = 0;
  let indexNew = 0;
  let isPivotCurrentElseNew = true;

  while (indexCurrent < currentIDs.length || indexNew < newIDs.length) {
    // If DELETED (aka current step id doesn't exist in new state)
    if (
      indexCurrent < currentIDs.length &&
      (indexNew === newIDs.length || !newIDs.includes(currentIDs[indexCurrent]))
    ) {
      diffStateFields = addDeletedField(diffStateFields, currentSteps[indexCurrent]);
      indexCurrent += 1;
    }
    // If ADDED (aka new step id doesn't exist in current state)
    else if (
      indexNew < newIDs.length &&
      (indexCurrent === currentIDs.length || !currentIDs.includes(newIDs[indexNew]))
    ) {
      diffStateFields = addAddedField(diffStateFields, newSteps[indexNew]);
      indexNew += 1;
    }
    // If UPDATED or MOVED
    else {
      if (indexCurrent < currentIDs.length && indexNew < newIDs.length) {
        // If UPDATED
        // If equal step id
        if (currentIDs[indexCurrent] === newIDs[indexNew]) {
          diffStateFields = addExistingField(
            diffStateFields,
            currentSteps[indexCurrent],
            newSteps[indexNew]
          );
          indexNew += 1;
          indexCurrent += 1;
        } else {
          // If MOVED
          // If unequal step id, either treat current as DELETED or treat new as ADDED
          // For now, we'll use a pivot, which helps us optimmize for minimal diff view
          if (isPivotCurrentElseNew) {
            diffStateFields = addDeletedField(diffStateFields, currentSteps[indexCurrent]);
            // We pivot when we're done processing a continuous sequence of MOVED steps

            isPivotCurrentElseNew = isSequenceOfIDs(
              currentMapToNextStepIDs,
              newMapToNextStepIDs,
              currentIDs[indexCurrent]
            )
              ? isPivotCurrentElseNew
              : !isPivotCurrentElseNew;
            indexCurrent += 1;
          }
          // Treat as ADDED
          else {
            diffStateFields = addAddedField(diffStateFields, newSteps[indexNew]);
            // We pivot when we're done processing a continuous sequence of MOVED steps
            isPivotCurrentElseNew = isSequenceOfIDs(
              currentMapToNextStepIDs,
              newMapToNextStepIDs,
              newIDs[indexNew]
            )
              ? isPivotCurrentElseNew
              : !isPivotCurrentElseNew;
            indexNew += 1;
          }
        }
      }
      // Should never happen
      else {
        indexCurrent = currentIDs.length;
        indexNew = newIDs.length;
      }
    }
  }

  return diffStateFields;
};

const addDeletedField = (
  diffStateFields: DiffStateField[],
  currentStep: { [key: string]: any }
): DiffStateField[] => {
  const field = generateDiffStateFieldForBlueprintStep({
    currentStep: currentStep,
    newStep: {},
    stepName: currentStep["id"],
    overrideDiffStatus: DiffStatusEnum.DELETED,
    isRenderCurrentAsEmpty: false,
    isRenderNewAsEmpty: true,
  });
  if (field) {
    diffStateFields.push(field);
  }
  return diffStateFields;
};

const addAddedField = (
  diffStateFields: DiffStateField[],
  newStep: { [key: string]: any }
): DiffStateField[] => {
  const field = generateDiffStateFieldForBlueprintStep({
    currentStep: {},
    newStep: newStep,
    stepName: newStep["id"],
    overrideDiffStatus: DiffStatusEnum.ADDED,
    isRenderCurrentAsEmpty: true,
    isRenderNewAsEmpty: false,
  });
  if (field) {
    diffStateFields.push(field);
  }
  return diffStateFields;
};

const addExistingField = (
  diffStateFields: DiffStateField[],
  currentStep: { [key: string]: any },
  newStep: { [key: string]: any }
): DiffStateField[] => {
  const field = generateDiffStateFieldForBlueprintStep({
    currentStep: currentStep,
    newStep: newStep,
    stepName: newStep["id"],
  });
  if (field) {
    diffStateFields.push(field);
  }
  return diffStateFields;
};

const generateDiffStateForChildSteps = (
  currentStep: { [key: string]: any },
  newStep: { [key: string]: any },
  isRenderCurrentAsEmpty: boolean,
  isRenderNewAsEmpty: boolean
) => {
  const isCurrentHasPaths = currentStep.hasOwnProperty("paths");
  const isNewHasPaths = newStep.hasOwnProperty("paths");
  if (!isCurrentHasPaths && !isNewHasPaths) {
    return [];
  }
  currentStep = isCurrentHasPaths ? currentStep["paths"] : {};
  newStep = isNewHasPaths ? newStep["paths"] : {};
  const isCurrentHasTrue = currentStep.hasOwnProperty("true");
  const isCurrentHasFalse = currentStep.hasOwnProperty("false");
  const isNewHasTrue = newStep.hasOwnProperty("true");
  const isNewHasFalse = newStep.hasOwnProperty("false");
  const hasTrue = isCurrentHasTrue || isNewHasTrue;
  const hasFalse = isCurrentHasFalse || isNewHasFalse;

  return hasTrue && hasFalse
    ? generateDiffState({
        fields: [
          {
            name: "true",
            displayName: "True",
            isRenderChildrenAsNested: true,
            isRenderCurrentAsEmpty: isRenderCurrentAsEmpty,
            isRenderNewAsEmpty: isRenderNewAsEmpty,
            childDiffStateFields: generateDiffStateForBlueprintSteps({
              overrideCurrentSteps: isCurrentHasTrue ? currentStep["true"] : [],
              overrideNewSteps: isNewHasTrue ? newStep["true"] : [],
            }),
          },
          {
            name: "false",
            displayName: "False",
            isRenderChildrenAsNested: true,
            isRenderCurrentAsEmpty: isRenderCurrentAsEmpty,
            isRenderNewAsEmpty: isRenderNewAsEmpty,
            childDiffStateFields: generateDiffStateForBlueprintSteps({
              overrideCurrentSteps: isCurrentHasFalse ? currentStep["false"] : [],
              overrideNewSteps: isNewHasFalse ? newStep["false"] : [],
            }),
          },
        ],
      })
    : generateDiffStateForBlueprintSteps({
        overrideCurrentSteps: isCurrentHasTrue ? currentStep["true"] : [],
        overrideNewSteps: isNewHasTrue ? newStep["true"] : [],
      });
};

interface GeneratedDiffStateFieldForBlueprintStepProps {
  currentStep: { [key: string]: string };
  newStep: { [key: string]: string };
  stepName: string;
  overrideDiffStatus?: DiffStatusEnum;
  isRenderCurrentAsEmpty?: boolean;
  isRenderNewAsEmpty?: boolean;
}

const generateDiffStateFieldForBlueprintStep = ({
  currentStep,
  newStep,
  stepName,
  overrideDiffStatus,
  isRenderCurrentAsEmpty = false,
  isRenderNewAsEmpty = false,
}: GeneratedDiffStateFieldForBlueprintStepProps): DiffStateField | null => {
  const childDiffStateNestedFields: DiffStateField[] = generateDiffStateForChildSteps(
    currentStep,
    newStep,
    isRenderCurrentAsEmpty,
    isRenderNewAsEmpty
  );
  let diffStateFieldsForParams = generateDiffStateForStepParams(
    currentStep,
    newStep,
    overrideDiffStatus
  );
  diffStateFieldsForParams =
    diffStateFieldsForParams.length > 0
      ? diffStateFieldsForParams
      : generateEmptyDiffState("no-parameters-text", "No parameters");
  const diffStateFieldsForTemplate = generateDiffStateForStepTemplate(
    currentStep,
    newStep,
    isRenderNewAsEmpty,
    isRenderCurrentAsEmpty
  );
  let childDiffStateFields: DiffStateField[] = [
    {
      type: DiffStateFieldTypeEnum.FIELD,
      name: "params-section",
      displayName: "Parameters",
      currentValue: "",
      newValue: "",
      diffStatus: overrideDiffStatus
        ? overrideDiffStatus
        : getDiffStatus("", "", diffStateFieldsForParams),
      childDiffStateFields: diffStateFieldsForParams,
      isRenderCurrentAsEmpty: isRenderCurrentAsEmpty,
      isRenderNewAsEmpty: isRenderNewAsEmpty,
      isRenderAsExpanded: true,
    },
    {
      type: DiffStateFieldTypeEnum.FIELD,
      name: "template-section",
      displayName: "Template",
      currentValue: "",
      newValue: "",
      diffStatus: overrideDiffStatus
        ? overrideDiffStatus
        : getDiffStatus("", "", diffStateFieldsForTemplate),
      childDiffStateFields: diffStateFieldsForTemplate,
      isRenderCurrentAsEmpty: isRenderCurrentAsEmpty,
      isRenderNewAsEmpty: isRenderNewAsEmpty,
      isRenderAsExpanded: true,
    },
  ];
  const diffStateField: DiffStateField = {
    type: DiffStateFieldTypeEnum.SECTION,
    name: stepName,
    displayName: stepName,
    currentValue: "",
    newValue: "",
    diffStatus: overrideDiffStatus
      ? overrideDiffStatus
      : getDiffStatus("", "", childDiffStateFields),
    isRenderNewAsEmpty: isRenderNewAsEmpty,
    isRenderCurrentAsEmpty: isRenderCurrentAsEmpty,
    childDiffStateNestedFields: childDiffStateNestedFields,
    childDiffStateFields: childDiffStateFields,
  };
  return diffStateField;
};
