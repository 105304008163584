import { DiffStateField } from "../../../../../models/DiffModels";
import { generateDiffStateField } from "../helpers-diff";

export const retrieveSteps = (
  overrideSteps: { [key: string]: any }[] | undefined,
  globalState: { [key: string]: any }
): { [key: string]: any }[] => {
  return Array.isArray(overrideSteps)
    ? overrideSteps
    : globalState.hasOwnProperty("steps")
    ? globalState["steps"]
    : [];
};

export const retrieveStepIDs = (steps: { [key: string]: any }[]): string[] => {
  return steps
    .filter((step) => "id" in step && step["id"] !== null && step["id"] !== undefined)
    .map((step) => step["id"]);
};

export const retrieveMapToNextID = (ids: string[]): { [key: string]: string | null } => {
  let map: { [key: string]: string | null } = {};
  ids.forEach((id, index) => {
    if (index !== ids.length - 1) {
      map[id] = ids[index + 1];
    } else {
      map[id] = null;
    }
  });
  return map;
};

export const isSequenceOfIDs = (
  currentMapToNextID: { [key: string]: string | null },
  newMapToNextID: { [key: string]: string | null },
  id: string
) => {
  return currentMapToNextID[id] === newMapToNextID[id];
};

export const getValue = (dict: { [key: string]: any }, key: string): any => {
  return dict.hasOwnProperty(key) ? dict[key] : "";
};

export interface TemplateParentProps {
  diffStateFields: DiffStateField[];
  currentState: { [key: string]: any };
  newState: { [key: string]: any };
  isRenderNewAsEmpty: boolean;
  isRenderCurrentAsEmpty: boolean;
}

export const addTemplateField = (
  key: string,
  parentProps: TemplateParentProps,
  childDiffStateFields: DiffStateField[] = []
): DiffStateField[] => {
  const field = generateDiffStateField(
    {
      name: key,
      displayName: key,
      isRenderNewAsEmpty: parentProps.isRenderNewAsEmpty ?? false,
      isRenderCurrentAsEmpty: parentProps.isRenderCurrentAsEmpty ?? false,
      childDiffStateFields: childDiffStateFields,
      isRenderAsExpanded: childDiffStateFields?.length > 0,
    },
    parentProps.currentState,
    parentProps.newState
  );
  if (field) {
    parentProps.diffStateFields.push(field);
  }
  return parentProps.diffStateFields;
};
