import { Badge } from "@merge-api/merge-javascript-shared";
import { BlueprintRunnerExitData } from "../../../models/Blueprints";

interface Props {
  blueprintExecutionExitData: BlueprintRunnerExitData;
}

const MappingTestBlockBlueprintExecutionStatusBadge = ({ blueprintExecutionExitData }: Props) => {
  return (
    <Badge
      color={
        blueprintExecutionExitData.blueprint_meta.blueprint_run_exit_code === 200 ? "teal" : "red"
      }
    >
      {blueprintExecutionExitData.blueprint_meta.blueprint_run_exit_code === 200
        ? "Success"
        : "Failed"}
    </Badge>
  );
};

export default MappingTestBlockBlueprintExecutionStatusBadge;
