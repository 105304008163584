import { Section } from "../../utils/Entities";
import NavBarTabNameAndStatus from "../../shared/NavBarTabNameAndStatus";
import { NavigationBarButton } from "@merge-api/merge-javascript-shared/labs";
import IntegrationBuilderLeftNavBarSubTab from "./IntegrationBuilderLeftNavSubTab";

interface Props {
  item: Section;
  itemIndex: number;
  relativePathOfSelectedPage: string;
  onNavigate: (path: string) => void;
}

const IntegrationBuilderLeftNavBarTab = ({
  item,
  itemIndex,
  relativePathOfSelectedPage,
  onNavigate,
}: Props) => {
  // See if we are on active tab or sub-tab
  const relativePathIdentifiers = item.subSections?.map((subItem) => subItem.sectionIdentifier) || [
    item.sectionIdentifier,
  ];
  const isContainsRelativePath = relativePathIdentifiers.includes(relativePathOfSelectedPage);

  // consts derived from state for animation calculation
  const height = item.subSections ? item.subSections.length * 42 : 0;
  const displaySubNav = !item.disabled && !!item.subSections && isContainsRelativePath;
  const containerHeight = displaySubNav ? `${height}px` : "0px";
  const containerOpacity = displaySubNav ? 1 : 0;

  return (
    <div>
      <NavigationBarButton
        className="hover:text-blue-40"
        key={itemIndex}
        onClick={() => onNavigate(item.path)}
        selected={isContainsRelativePath}
        disabled={item.disabled}
      >
        <NavBarTabNameAndStatus
          selected={isContainsRelativePath}
          name={item.name}
          icon={item.icon}
          state={item.state}
          disabled={item.disabled}
        />
      </NavigationBarButton>
      <div
        className="transition-all duration-300 ease-in-out overflow-hidden"
        style={{ height: containerHeight, opacity: containerOpacity }}
      >
        {displaySubNav && (
          <div className="border-l-2 ml-5 mt-3 border-gray-10 space-y-3 py-2">
            {item.subSections?.map((subItem, subItemIndex) => {
              const isMatchingPath = subItem.sectionIdentifier === relativePathOfSelectedPage;
              return (
                <IntegrationBuilderLeftNavBarSubTab
                  key={subItemIndex}
                  subItem={subItem}
                  onNavigate={onNavigate}
                  isMatchingPath={isMatchingPath}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default IntegrationBuilderLeftNavBarTab;
