import { useMemo, useState } from "react";
import queryString from "query-string";
import pickBy from "lodash-es/pickBy";
import { ChangelogEventType, ChangelogModelType } from "../utils/types";

const useChangelogFilters = () => {
  // state
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [eventType, setEventType] = useState<ChangelogEventType | null>(null);
  const [modelType, setModelType] = useState<ChangelogModelType | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [filterDeploys, setFilterDeploys] = useState<boolean>(false);

  // event handlers
  const onFilterChange = (
    key: "startDate" | "endDate" | "eventType" | "modelType" | "userId" | "filterDeploys"
  ) => (value: any) => {
    if (key === "startDate") setStartDate(value);
    if (key === "endDate") setEndDate(value);
    if (key === "eventType") setEventType(value);
    if (key === "modelType") setModelType(value);
    if (key === "userId") setUserId(value);
    if (key == "filterDeploys") setFilterDeploys(value);
  };

  const paramsPath = useMemo(() => {
    return queryString.stringify(
      pickBy({
        start_date: startDate && new Date(startDate).toISOString(),
        end_date: endDate && new Date(endDate).toISOString(),
        event_type: eventType,
        model_type: modelType,
        user_id: userId,
        filter_deploy: filterDeploys,
      })
    );
  }, [endDate, eventType, modelType, startDate, userId, filterDeploys]);

  return {
    startDate,
    endDate,
    eventType,
    modelType,
    userId,
    filterDeploys,
    onFilterChange,
    paramsPath,
  };
};

export default useChangelogFilters;
