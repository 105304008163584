import { useEffect, useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast } from "../../../shared/Toasts";
import { LinkChoiceStepOption, LinkChoiceStepOptionResponseBody } from "../../utils/Entities";

interface loadLinkingFlowStepPathsProps {
  integrationID: string;
}

const useLoadLinkChoiceStepOptions = ({ integrationID }: loadLinkingFlowStepPathsProps) => {
  const [linkChoiceStepOptions, setLinkChoiceStepOptions] = useState<
    LinkChoiceStepOption[] | undefined
  >();

  useEffect(() => {
    fetchWithAuth({
      path: `/integrations/${integrationID}/link-choice-step-options`,
      method: "GET",
      onResponse: (data: LinkChoiceStepOptionResponseBody) => {
        setLinkChoiceStepOptions(data.results);
      },
      onError: () => {
        showErrorToast("Failed to fetch link choice step options.");
      },
    });
  }, [setLinkChoiceStepOptions]);

  return { linkChoiceStepOptions, setLinkChoiceStepOptions };
};

export default useLoadLinkChoiceStepOptions;
