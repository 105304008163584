import { useState } from "react";
import { Button } from "react-bootstrap";
import { useParams, match } from "react-router-dom";
import { Integration } from "../../../models/Entities";
import { MappingTestSuiteMeta } from "../../../models/MappingTests";
import FullPageSpinner from "../../shared/layout/FullPageSpinner";
import AddNewTestSuiteModal from "./AddNewTestSuiteModal";
import MappingTestSuitesTable from "./MappingTestSuitesTable";
import { TableTitleContainer, TableTitleText } from "../../shared/utils/SharedComponentUtils";
import useDocumentTitle from "../../shared/useDocumentTitle";

type RouteParams = {
  integrationID: string;
  operationType: string;
};

type Props = {
  integrationMeta: Integration;
  match: match;
  isLoading: boolean;
  testSuites: MappingTestSuiteMeta[] | undefined;
  fetchMappingTestSuites: () => void;
};

const MappingTestSuitesOverview = ({
  integrationMeta,
  isLoading,
  testSuites,
  fetchMappingTestSuites,
}: Props) => {
  const { integrationID } = useParams<RouteParams>();

  useDocumentTitle(`${integrationMeta.name} - Mapping Tests`, [integrationMeta.name]);
  const [isAddNewTestSuiteModalOpen, setIsAddNewTestSuiteModalOpen] = useState(false);

  return (
    <>
      <AddNewTestSuiteModal
        integrationMeta={integrationMeta}
        integrationID={integrationID}
        onHide={() => setIsAddNewTestSuiteModalOpen(false)}
        isShown={isAddNewTestSuiteModalOpen}
        fetchMappingTestSuites={fetchMappingTestSuites}
      />
      <TableTitleContainer>
        <TableTitleText>Mapping Test Suites</TableTitleText>
        <Button variant="primary" onClick={() => setIsAddNewTestSuiteModalOpen(true)}>
          + Add New Test Suite{" "}
        </Button>
      </TableTitleContainer>
      {isLoading || !testSuites ? (
        <FullPageSpinner />
      ) : (
        <MappingTestSuitesTable testSuites={testSuites} integrationID={integrationID} />
      )}
    </>
  );
};

export default MappingTestSuitesOverview;
