import React from "react";

interface DeprecatedH1Props {
  children: React.ReactNode;
  className?: string;
}

const DeprecatedH1: React.FC<DeprecatedH1Props> = ({ children, className }) => {
  return <div className={`deprecatedh1 ${className}`}>{children}</div>;
};

export default DeprecatedH1;
