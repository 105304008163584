import classNames from "classnames";
import MergeModal from "../../shared/MergeModal";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import SpinnerButton from "../../shared/SpinnerButton";
import useBlueprintContext from "../context/useBlueprintContext";
import { fetchWithAuth } from "../../../api-client/api_client";
import { BlueprintTestPayload } from "../../../models/Blueprints";
import { showErrorToast, showSuccessToast } from "../../shared/Toasts";

interface Props {
  show: boolean;
  onHide: () => void;
  blueprintTestPayloads: BlueprintTestPayload[];
  setBlueprintTestPayloads: (x: BlueprintTestPayload[]) => void;
  generatedBlueprintTestPayloads: BlueprintTestPayload[];
  setGeneratedBlueprintTestPayloads: (x: BlueprintTestPayload[]) => void;
  selectedTestPayload: BlueprintTestPayload | undefined;
  setSelectedTestPayload: (x: BlueprintTestPayload) => void;
  isSelectedTestPayloadGenerated: boolean;
  globalVarsAsString: string | undefined;
  selectedTestLinkedAccount: undefined | string;
  selectedTestCommonModel: undefined | string;
}

function BlueprintEditorLeftPanelSavePayloadModal({
  show,
  onHide,
  blueprintTestPayloads,
  setBlueprintTestPayloads,
  generatedBlueprintTestPayloads,
  selectedTestPayload,
  setSelectedTestPayload,
  globalVarsAsString,
  selectedTestLinkedAccount,
  selectedTestCommonModel,
  isSelectedTestPayloadGenerated,
  setGeneratedBlueprintTestPayloads,
}: Props) {
  const { register, handleSubmit, errors } = useForm();
  const { blueprint } = useBlueprintContext();
  const [isLoading, setIsLoading] = useState(false);

  const getPayloadForName = (name: string) =>
    blueprintTestPayloads.find((payload) => payload.name === name);

  let updateOrAddPayload = (
    existing: BlueprintTestPayload | undefined,
    response: BlueprintTestPayload
  ) => {
    if (!existing) {
      // If we're adding a new payload, just append it to the array.
      setBlueprintTestPayloads([...blueprintTestPayloads, response]);
      setSelectedTestPayload(response);
    } else {
      // Update the text field for the existing payload and re-insert it
      // into the payload array.
      let updatedExisting = {
        ...existing,
        text: response.text,
        common_model_object_id: response.common_model_object_id,
      };
      let updatedTestPayloads = blueprintTestPayloads.map((entry) =>
        entry.id === existing.id ? updatedExisting : entry
      );
      setBlueprintTestPayloads(updatedTestPayloads);
      setSelectedTestPayload(updatedExisting);
    }
  };

  const onSubmit = (data: { name: string }) => {
    setIsLoading(true);
    let existingPayload = getPayloadForName(data.name);
    let method = existingPayload && !isSelectedTestPayloadGenerated ? "PATCH" : "POST";
    let body = {
      name: data.name,
      text: globalVarsAsString,
      linked_account_id: selectedTestLinkedAccount,
      common_model_object_id: selectedTestCommonModel,
    };
    fetchWithAuth({
      path: `/blueprints/${blueprint.id}/test-payloads`,
      body: body,
      method: method,
      onResponse: (response: BlueprintTestPayload) => {
        showSuccessToast("Payload saved successfully!");
        updateOrAddPayload(existingPayload, response);
        const newPayloads = generatedBlueprintTestPayloads.filter(
          (payload) => payload.id !== selectedTestPayload?.id
        );
        setGeneratedBlueprintTestPayloads(newPayloads);

        setIsLoading(false);
        onHide();
      },
      onError: (err) => {
        if (err) {
          err.text().then((errorMessage) => {
            showErrorToast(errorMessage);
          });
        } else {
          showErrorToast("Failed to save payload.");
        }
        setIsLoading(false);
      },
    });
  };

  return (
    <MergeModal
      title="Enter name for your payload"
      dialogClassName="permission-modal-width"
      show={show}
      onHide={onHide}
    >
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Form.Group>
          <Form.Label>Payload name</Form.Label>
          <Form.Control
            name="name"
            type="text"
            className={classNames({ "is-invalid": errors.name })}
            ref={register({ required: true, minLength: 1, maxLength: 100 })}
            defaultValue={selectedTestPayload?.name}
          />
          <Form.Control.Feedback type="invalid">
            Enter a name between 1 and 100 characters long.
          </Form.Control.Feedback>
        </Form.Group>
        <SpinnerButton text="Save" isLoading={isLoading} className="btn btn-primary" />
      </Form>
    </MergeModal>
  );
}

export default BlueprintEditorLeftPanelSavePayloadModal;
