import { DiffState, DiffStateFieldTypeEnum } from "../../../models/DiffModels";
import { generateDiffState } from "./utils/helpers-diff";

export const generateDiffForScraper = (): DiffState => {
  return generateDiffState({
    fields: [
      {
        type: DiffStateFieldTypeEnum.HEADER,
        name: "steps",
        displayName: "Steps",
        isChildBlueprintSteps: true,
      },
    ],
  });
};
