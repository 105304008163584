import { Card } from "@merge-api/merge-javascript-shared";
import CardHeader from "../../shared/CardHeader";
import TextFieldHeader from "../../shared/TextFieldHeader";

interface SchemaConfigurationProps {
  //scheme
  scheme: string | undefined;
  //subdomain
  subdomain: string | undefined;
  setSubdomain: React.Dispatch<React.SetStateAction<string | undefined>>;
  //second level domain
  secondLevelDomain: string | undefined;
  setSecondLevelDomain: React.Dispatch<React.SetStateAction<string | undefined>>;
  //top level domain
  topLevelDomain: string | undefined;
  setTopLevelDomain: React.Dispatch<React.SetStateAction<string | undefined>>;
  //description
  description: string | undefined;
  setDescription: React.Dispatch<React.SetStateAction<string | undefined>>;
  // source of change
  setSourceOfChange: React.Dispatch<React.SetStateAction<"fullURL" | "parts">>;
}

const SchemaConfiguration = ({
  scheme,
  subdomain,
  setSubdomain,
  secondLevelDomain,
  setSecondLevelDomain,
  topLevelDomain,
  setTopLevelDomain,
  description,
  setDescription,
  setSourceOfChange,
}: SchemaConfigurationProps) => {
  return (
    <Card className="pt-4 mt-6 px-5 pb-5 bg-white mb-6 min-w-fit">
      <CardHeader title="Schema configuration" />
      <TextFieldHeader
        dataTestID="field-web-address-schema-scheme"
        title="Scheme"
        subtitle="Represents the protocol used to access the web address. It is set to 'https' for secure, encrypted communication and should only be changed to 'http' in exceptional cases with a clear understanding of the security implications."
        value={scheme}
        disabled={true}
        hasSource={false}
        required
      />
      <TextFieldHeader
        dataTestID="field-web-address-schema-subdomain"
        className="mt-6"
        title="Subdomain"
        subtitle="Part of the web address that precedes the main domain. It can be used to designate a particular service or section of the site, like 'app' in api.merge.dev'. Leave this blank if not applicable."
        placeholder="api"
        value={subdomain}
        onChange={(newEvent) => {
          setSubdomain(newEvent.target.value);
          setSourceOfChange("parts");
        }}
      />
      <TextFieldHeader
        dataTestID="field-web-address-schema-second-level-domain"
        className="mt-6"
        title="Second level domain"
        subtitle="Core part of the web address that represents your brand or service, like 'merge' in 'app.merge.dev'. This field typically reflects the name of your organization or service."
        placeholder="merge"
        required
        value={secondLevelDomain}
        onChange={(newEvent) => {
          setSecondLevelDomain(newEvent.target.value);
          setSourceOfChange("parts");
        }}
      />
      <TextFieldHeader
        dataTestID="field-web-address-schema-top-level-domain"
        className="mt-6"
        title="Top level domain"
        subtitle="The last segment of the domain name, like 'com' or 'org'. It often represents the purpose or geographic area of the website, like 'dev' in 'app.merge.dev'."
        placeholder="dev"
        required
        value={topLevelDomain}
        onChange={(newEvent) => {
          setTopLevelDomain(newEvent.target.value);
          setSourceOfChange("parts");
        }}
      />
      <TextFieldHeader
        dataTestID="field-web-address-schema-description"
        className="mt-6"
        title="Description"
        placeholder="Describe this schema"
        value={description}
        onChange={(newEvent) => {
          setDescription(newEvent.target.value);
        }}
      />
    </Card>
  );
};

export default SchemaConfiguration;
