import clsx from "clsx";
import { BuilderStatus, Section } from "../../utils/Entities";
import { Text } from "@merge-api/merge-javascript-shared";
import { useState } from "react";
import { Check, RefreshCcw } from "lucide-react";

interface Props {
  subItem: Section;
  isMatchingPath: boolean;
  onNavigate: (path: string) => void;
}

const IntegrationBuilderLeftNavBarSubTab = ({ subItem, isMatchingPath, onNavigate }: Props) => {
  const pending = subItem.state === BuilderStatus.PENDING;
  const completed = subItem.state === BuilderStatus.COMPLETED;
  const [isHovering, setIsHovering] = useState<boolean>(false);

  return (
    <div
      className={clsx(
        "pl-5 pr-3 flex flex-row items-center justify-between cursor-pointer",
        isMatchingPath && "border-l-2 border-blue-40 -ml-0.5"
      )}
      onClick={() => onNavigate(subItem.path)}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Text variant="title-sm" className={clsx((isMatchingPath || isHovering) && "text-blue-40")}>
        {subItem.name}
      </Text>
      {pending && <RefreshCcw className="rotating-counter ml-2 text-blue-40" size={16} />}
      {completed && <Check className="text-green-50" size={16} />}
    </div>
  );
};

export default IntegrationBuilderLeftNavBarSubTab;
