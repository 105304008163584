import { RateLimitConfigurationIntegrationBuilder } from "../../../../utils/Entities";
import { Text } from "@merge-api/merge-javascript-shared";

interface APIEndpointCellProps {
  rateLimitConfiguration: RateLimitConfigurationIntegrationBuilder;
  onClick: React.MouseEventHandler<HTMLTableCellElement> | undefined;
}

const APIEndpointCell = ({ rateLimitConfiguration, onClick }: APIEndpointCellProps) => {
  const hasAPIEndpoint = !!rateLimitConfiguration?.api_endpoint_id;
  return (
    <td onClick={onClick}>
      <Text as="div" className="text-gray-90 text-base font-medium">
        {hasAPIEndpoint ? rateLimitConfiguration?.api_endpoint_name : "All endpoints"}
      </Text>
    </td>
  );
};
export default APIEndpointCell;
