import { BPRStepIOVariableDiffLog } from "../../../models/Blueprints";

/**
 * Convert step I/O variable_diff_logs into displayable section
 */
export const convertVariableDiffLogsToDisplayableDictionary = (
  variableDiffLogs: BPRStepIOVariableDiffLog[] | null
): Record<string, any> => {
  // Convert variable diff logs into displayable section
  let variableDiffLogsAsDict: Record<string, any> = {};
  if (variableDiffLogs && variableDiffLogs.length > 0) {
    variableDiffLogs.forEach((diffLog) => {
      variableDiffLogsAsDict[diffLog.change_event + " " + diffLog.variable_key] =
        diffLog.change_data;
    });
  }
  return variableDiffLogsAsDict;
};
