import React from "react";

import PublishModuleMappingTestContext, {
  PublishModuleMappingTestContextState,
} from "./PublishModuleMappingTestContext";

// Wraps around BlueprintEditor to be able to pull global state anywhere in tree via useContext
const usePublishModuleMappingTestContext = (): PublishModuleMappingTestContextState =>
  React.useContext(PublishModuleMappingTestContext);

export default usePublishModuleMappingTestContext;
