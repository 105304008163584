import { DiffState } from "../../../../../models/DiffModels";
import { generateDiffStateForProperty } from "./step-templates-property-diff";

/**
 * Generate diff state for a Blueprint step's template's return_schema
 * @param {Object} currentReturnSchemaData - The current return schema data.
 * @param {Object} newReturnSchemaData - The new return schema data.
 * @returns {DiffState} - The generated diff state for the return schema.
 */
export const generateDiffStateForStepTemplateReturnSchema = (
  currentReturnSchemaData: { [key: string]: any },
  newReturnSchemaData: { [key: string]: any }
): DiffState => {
  // return schema data is always starts with a single property
  return generateDiffStateForProperty(
    "return_schema",
    currentReturnSchemaData,
    newReturnSchemaData
  );
};
