import { useContext, useEffect, useState } from "react";
import JSONSchemaConverterFormField from "../../../../integrations/api-endpoints/JSONSchemaConverterFormField";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import APIEndpointContext from "../../context/APIEndpointContext";

const GraphQLRequestConfigurationSetupOptions = () => {
  const {
    graphqlRequestBodyBaseConfig: requestBodyFormat,
    setGraphqlRequestBodyBaseConfig: setRequestBodyFormat,
    graphqlRequestBodyParamSchema: requestBodyParamSchema,
    setGraphqlRequestBodyParamSchema: setRequestBodyParamSchema,
  } = useContext(APIEndpointContext);

  const [schemaConverterText, setSchemaConverterText] = useState(
    JSON.stringify(requestBodyParamSchema)
  );

  useEffect(() => {
    try {
      const parsedValue = JSON.parse(schemaConverterText);
      setRequestBodyParamSchema(parsedValue);
    } catch {
      setRequestBodyParamSchema({});
    }
  }, [schemaConverterText]);

  return (
    <>
      <TextFieldHeader
        dataTestID="field-api-endpoint-request-body-base-config"
        title="Request body base config"
        subtitle="Base configuration for the request body. This is the GraphQL query or mutation string."
        placeholder="Example: mutation IssueCreate ($title: String!) { issueCreate(input: { title: $title }) { issue { id } } }"
        value={requestBodyFormat}
        onChange={(e) => setRequestBodyFormat(e.target.value)}
        hasSource={false}
        autosize={true}
        className="mt-6"
      />
      <JSONSchemaConverterFormField
        dataTestID="field-api-endpoint-request-body-param-schema"
        title="Request body param schema"
        subtitle="Schema for the request body parameters. This is the JSON schema for the variables in the query or mutation string."
        currentValue={schemaConverterText}
        setValue={(schema) => setSchemaConverterText(schema)}
        numRows={5}
        className="my-4"
      />
    </>
  );
};

export default GraphQLRequestConfigurationSetupOptions;
