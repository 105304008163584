import ReadmePanel from "../../shared/readme/ReadmePanel";
import useBlueprintContext from "../context/useBlueprintContext";
import { fetchBlueprintReadme, updateBlueprintReadme } from "../utils/BlueprintEditorAPIClient";

const BlueprintEditorReadmePanel = () => {
  const { blueprint } = useBlueprintContext();

  return blueprint.id ? (
    <ReadmePanel
      fetchReadme={(props) => fetchBlueprintReadme({ blueprintID: blueprint.id, ...props })}
      updateReadme={(props) => updateBlueprintReadme({ blueprintID: blueprint.id, ...props })}
    />
  ) : (
    <></>
  );
};

export default BlueprintEditorReadmePanel;
