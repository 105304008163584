import { useEffect, useState } from "react";
import { Button } from "@merge-api/merge-javascript-shared";
import {
  BlueprintAddToDictionaryStep,
  BlueprintParameterValue,
  BlueprintParameterValueType,
} from "../../../models/Blueprints";
import useBlueprintContext from "../context/useBlueprintContext";
import TypeaheadFormField from "./TypeaheadFormField";
import CancellableForm from "./CancellableForm";
import { Plus } from "lucide-react";

const BlueprintEditorRightPanelAddToDictionaryForm = () => {
  const { selectedStep, updateStepParameterValue } = useBlueprintContext();
  const step = selectedStep as BlueprintAddToDictionaryStep;
  const [currentItems, setCurrentItems] = useState<Array<BlueprintParameterValue | null>>([]);

  useEffect(() => {
    const items = step.parameter_values.items ?? {};
    if (items.value_type === BlueprintParameterValueType.customArray) {
      const array_values = items.array_values ?? [];
      setCurrentItems(array_values);
    } else {
      setCurrentItems([]);
    }
  }, []);

  const updateItems = (newItems: Array<BlueprintParameterValue | null>) => {
    setCurrentItems(newItems);
    updateStepParameterValue(step, "items", {
      value_type: BlueprintParameterValueType.customArray,
      array_values: newItems.filter((param) => !!param),
    });
  };

  const addItem = () => {
    updateItems([
      ...currentItems,
      {
        value_type: BlueprintParameterValueType.nestedParameterValues,
        nested_parameter_values: {
          key: {
            value_type: BlueprintParameterValueType.constant,
            constant: "",
          },
          value: {
            value_type: BlueprintParameterValueType.constant,
            constant: "",
          },
        },
      },
    ]);
  };

  const deleteItem = (index: number) => {
    updateItems([...currentItems.slice(0, index), ...currentItems.slice(index + 1)]);
  };

  const updateItem = (
    index: number,
    nestedParameterKey: string,
    nestedParameterValue: BlueprintParameterValue | null
  ) => {
    let updatedItem = currentItems[index];
    if (updatedItem?.value_type == BlueprintParameterValueType.nestedParameterValues) {
      updatedItem.nested_parameter_values[nestedParameterKey] =
        nestedParameterValue === null
          ? { value_type: BlueprintParameterValueType.none }
          : nestedParameterValue;
      updateItems([...currentItems.slice(0, index), updatedItem, ...currentItems.slice(index + 1)]);
    }
  };

  return (
    <>
      <TypeaheadFormField
        key="dictionary"
        title="dictionary"
        valueKey="dictionary"
        parameterType="object"
      />
      <hr />
      {currentItems.map((item, index) => (
        <div key={index}>
          <CancellableForm
            item={item}
            itemTitle="Key-Value Pair"
            index={index}
            deleteItem={deleteItem}
            updateItem={updateItem}
            nestedParameterValueSubtitle={{
              key: "The key to upsert into dictionary",
              value:
                "The value of the key to upsert into dictionary. Currently added as reference, but will soon be added as deep-copy. You should map as if deep-copy.",
            }}
          />
        </div>
      ))}
      <Button leftIcon={<Plus size={16} />} className="w-full" onClick={addItem}>
        Add Key-Value Pair
      </Button>
    </>
  );
};

export default BlueprintEditorRightPanelAddToDictionaryForm;
