interface Props {
  hasPermissionToEdit: boolean;
  children: React.ReactNode;
}

const PermissionWrapper = ({ hasPermissionToEdit, children }: Props) => {
  return (
    <div className={hasPermissionToEdit ? "" : "pointer-events-none cursor-not-allowed"}>
      {children}
    </div>
  );
};

export default PermissionWrapper;
