import { memo } from "react";
import AccordionCard from "../../../shared/AccordionCard";
import SessionInformationSetupOptions from "./SessionInformationSetupOptions";
import SortInformationSetupOptions from "./SortInformationSetupOptions";

interface AdvancedSetupOptionsProps {
  cardVariant?: "none" | "outline" | "shadow" | undefined;
}

const AdvancedSetupOptions = ({ cardVariant }: AdvancedSetupOptionsProps) => {
  return (
    <div data-testid="accordion-advanced-pagination-configuration-options">
      <AccordionCard title="Advanced" variant={cardVariant}>
        <SessionInformationSetupOptions />
        <SortInformationSetupOptions className="mt-6" />
      </AccordionCard>
    </div>
  );
};

export default memo(AdvancedSetupOptions);
