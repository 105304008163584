import {
  BlueprintAddValidationProblemStep,
  BlueprintParameterValueConstant,
  BlueprintParameterValueType,
  BlueprintStepTemplate,
} from "../../../models/Blueprints";
import useBlueprintContext from "../context/useBlueprintContext";
import DropdownFormField from "./DropdownFormField";
import TypeaheadFormField from "./TypeaheadFormField";

type ValidationProblemTypes = Record<
  string,
  {
    problem_type: string;
    title: string;
  }
>;
interface Props {
  stepTemplate: BlueprintStepTemplate;
}

const BlueprintEditorRightPanelAddValidationProblemForm = ({ stepTemplate }: Props) => {
  const { selectedStep, updateStepParameterValue } = useBlueprintContext();
  const step = selectedStep as BlueprintAddValidationProblemStep;
  const problemTypes = stepTemplate.metadata?.validation_problem_types as ValidationProblemTypes;
  const problemTypeChoices = Object.values(problemTypes ?? {}).map((choice) => ({
    id: choice.problem_type,
    name: choice.title,
  }));

  return (
    <>
      <DropdownFormField
        currentValue={
          (step.parameter_values.problem_type as BlueprintParameterValueConstant)?.constant
        }
        choices={problemTypeChoices}
        onChange={(e) =>
          updateStepParameterValue(step, "problem_type", {
            constant: e.target.value,
            value_type: BlueprintParameterValueType.constant,
          })
        }
        key={"problem_type"}
        title={"problem_type"}
        placeholder={""}
        subtitle={"The short description of the problem type."}
      />
      <DropdownFormField
        currentValue={(step.parameter_values.severity as BlueprintParameterValueConstant)?.constant}
        choices={[
          { id: "WARNING", name: "Warning" },
          { id: "ERROR", name: "Error" },
        ]}
        onChange={(e) =>
          updateStepParameterValue(step, "severity", {
            constant: e.target.value,
            value_type: BlueprintParameterValueType.constant,
          })
        }
        placeholder={""}
        key={"severity"}
        title={"severity"}
        subtitle={
          "The severity of the problem. An error will be included in the 'errors' array in the response body of the request, and will halt the request. A warning will be included in the 'warnings' array in the response body of the request, but will not halt the request."
        }
      />
      <TypeaheadFormField
        key={"detail"}
        title={"detail"}
        subtitle={"A longer description of the specific error."}
        valueKey={"detail"}
        parameterType={"string"}
      />
      <TypeaheadFormField
        title={"Field Location"}
        subtitle={"If applicable, the location of the field that this problem applies to."}
        includeUniqueIdentifierCheck={false}
        valueKey={"field_location"}
        parameterType={"field_reference"}
      />
    </>
  );
};

export default BlueprintEditorRightPanelAddValidationProblemForm;
