import { Form } from "react-bootstrap";
import FormField from "../blueprint-editor/right-panel/FormField";
import { ScraperConstantValue, ScraperValueType } from "./types";

type Props = {
  updateValue: (newValue: ScraperConstantValue) => void;
  value: ScraperConstantValue;
  valueKey: string;
};
const ScraperCheckboxField = ({ updateValue, value, valueKey }: Props) => {
  const isChecked = (value?.constant ?? false) as boolean;
  return (
    <FormField title={valueKey} valueType={"any"}>
      <div className="flex-grow-1">
        <Form.Group>
          <Form.Check
            type="checkbox"
            onChange={() =>
              updateValue({
                ...value,
                value_type: ScraperValueType.CONSTANT,
                constant: !isChecked,
              })
            }
            checked={isChecked}
          />
        </Form.Group>
      </div>
    </FormField>
  );
};

export default ScraperCheckboxField;
