import { useState } from "react";
import { Button } from "react-bootstrap";
import DropdownFormField from "../blueprint-editor/right-panel/DropdownFormField";
import useScraperContext from "./context/useScraperContext";
import { ScraperGhostStep, ScraperStepType } from "./types";

type Props = {
  step: ScraperGhostStep;
};

const ScraperEditorNewStepForm = ({ step }: Props) => {
  const [stepType, setStepType] = useState<null | ScraperStepType>(null);
  const { addStep, addCopiedStep, copiedStep } = useScraperContext();

  return (
    <div className="p-3">
      <DropdownFormField
        currentValue={stepType}
        onChange={(e) => {
          setStepType(e.target.value);
        }}
        placeholder="Select a step type"
        title="Step Type"
        choices={Object.values(ScraperStepType).map((stepType) => {
          switch (stepType) {
            case ScraperStepType.CLICK:
              return { name: "Click", id: stepType };
            case ScraperStepType.GOTO:
              return { name: "Go to", id: stepType };
            case ScraperStepType.WAIT:
              return { name: "Wait", id: stepType };
            case ScraperStepType.INPUT:
              return { name: "Input", id: stepType };
            case ScraperStepType.READ_AND_SAVE:
              return { name: "Read and Save", id: stepType };
            case ScraperStepType.READ_FROM_CLIPBOARD:
              return { name: "Read from Clipboard", id: stepType };
            case ScraperStepType.SAVE_CURRENT_URL:
              return { name: "Save Current URL", id: stepType };
            case ScraperStepType.TAKE_SCREENSHOT:
              return { name: "Take Screenshot", id: stepType };
            case ScraperStepType.CLICK_AND_DOWNLOAD:
              return { name: "Click And Download", id: stepType };
            case ScraperStepType.CLICK_AND_DOWNLOAD_CSV:
              return { name: "Click And Download CSV", id: stepType };
            case ScraperStepType.EXPECT_DOWNLOAD_CSV:
              return { name: "Expect Download CSV", id: stepType };
            case ScraperStepType.PRESS_KEY:
              return { name: "Press Key", id: stepType };
            case ScraperStepType.READ_STORAGE_STATE:
              return { name: "Read Storage State", id: stepType };
            case ScraperStepType.CONDITIONAL_ACTION:
              return { name: "Conditional Action", id: stepType };
            case ScraperStepType.RAISE_EXCEPTION:
              return { name: "Raise Exception", id: stepType };
            case ScraperStepType.GET_USER_INPUT:
              return { name: "Get User Input", id: stepType };
            case ScraperStepType.ARRAY_LOOP:
              return { name: "Array Loop", id: stepType };
            case ScraperStepType.QUERY_AND_EVAL:
              return { name: "Query and Evaluate", id: stepType };
            case ScraperStepType.SET_VARIABLE:
              return { name: "Set Variable", id: stepType };
            case ScraperStepType.SET_KEY_VALUE:
              return { name: "Set Object Key-Value Pair", id: stepType };
            case ScraperStepType.APPEND_TO_ARRAY:
              return { name: "Append to Array", id: stepType };
            case ScraperStepType.CONCATENATE_STRINGS:
              return { name: "Concatenate Strings", id: stepType };
            case ScraperStepType.QUERY_SELECTOR_LOOP:
              return { name: "Query Selector Loop", id: stepType };
            case ScraperStepType.REGEX:
              return { name: "Regex Search", id: stepType };
            case ScraperStepType.SAVE_NETWORK_RESPONSE:
              return { name: "Save Network Response", id: stepType };
            case ScraperStepType.SELECT_OPTION:
              return { name: "Select Option", id: stepType };
            case ScraperStepType.WAIT_FOR_SELECTOR:
              return { name: "Wait for Selector", id: stepType };
            case ScraperStepType.IF_ELSE:
              return { name: "If/Else", id: stepType };
            case ScraperStepType.CUSTOM_FUNCTION:
              return { name: "Custom Function", id: stepType };
            case ScraperStepType.SAVE_MFA_SECRET_KEY:
              return { name: "Save MFA Secret Key", id: stepType };
            case ScraperStepType.GENERATE_MFA_CODE_FROM_SECRET_KEY:
              return { name: "Generate MFA Code From Secret Key", id: stepType };
          }
        })}
      />
      <Button
        className="btn btn-primary btn-block"
        disabled={stepType == null}
        onClick={() => {
          if (stepType) {
            addStep(step, stepType);
          }
        }}
      >
        Add
      </Button>
      {copiedStep && (
        <button
          className="btn btn-primary btn-block"
          value="Add Copied Step"
          onClick={() => {
            addCopiedStep(copiedStep, step.newStepRelation, step.relatedStepID, step.pathKey);
          }}
        >
          Add copy of {copiedStep.id}
        </button>
      )}
    </div>
  );
};

export default ScraperEditorNewStepForm;
