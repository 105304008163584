import { ChangelogEventType, ChangelogEventTypeTextMap } from "../../utils/types";
import { Typeahead } from "@merge-api/merge-javascript-shared";

type EventTypePickerProps = {
  eventType: ChangelogEventType | null;
  onEventTypeChange: (eventType: ChangelogEventType | null) => void;
};

type Option = {
  label: string;
  value: ChangelogEventType;
};

const EventTypePicker = ({ eventType, onEventTypeChange }: EventTypePickerProps) => {
  const eventTypeOptions: Option[] = Object.values(ChangelogEventType)
    .filter((type) => type !== ChangelogEventType.BACKEND_DEPLOY)
    .map((type) => ({
      label: ChangelogEventTypeTextMap[type],
      value: type,
    }));

  return (
    <Typeahead
      className="bg-white"
      placeholder="All events"
      options={eventTypeOptions}
      value={eventType ? { label: ChangelogEventTypeTextMap[eventType], value: eventType } : null}
      onChange={(_, selectedOption: Option | null) =>
        onEventTypeChange(selectedOption ? (selectedOption.value as ChangelogEventType) : null)
      }
      getOptionLabel={(option) => option.label}
      getOptionKey={(option) => option.value}
    />
  );
};

export default EventTypePicker;
