import { Button } from "@merge-api/merge-javascript-shared";
import { useHistory } from "react-router-dom";
import { navigateToIntegrationBuilderSelectiveSyncFilterBuilderCreate } from "../../../../../router/RouterUtils";
import IntegrationBuilderHeader from "../../../shared/IntegrationBuilderHeader";
import { Plus } from "lucide-react";

interface Props {
  integrationID: string;
}

const SelectiveSyncFiltersTableHeader = ({ integrationID }: Props) => {
  const history = useHistory();
  const navigateToCreateNewSelectiveSyncFilterSchema = () => {
    navigateToIntegrationBuilderSelectiveSyncFilterBuilderCreate(history, integrationID);
  };
  const AddNewSelectiveSyncFilterButton = () => {
    return (
      <Button
        size="md"
        leftIcon={<Plus size={16} />}
        onClick={navigateToCreateNewSelectiveSyncFilterSchema}
      >
        Filter
      </Button>
    );
  };
  return (
    <IntegrationBuilderHeader
      title="Selective Sync filters"
      button={<AddNewSelectiveSyncFilterButton />}
    />
  );
};

export default SelectiveSyncFiltersTableHeader;
