import DeprecatedH3 from "../../deprecated/DeprecatedH3";
import MergeModal from "../../shared/MergeModal";
import { Button, Row, Col } from "react-bootstrap";

interface Props {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  field: string;
}

export const DeleteFieldModal = ({ show, onHide, onConfirm, field }: Props) => {
  return (
    <MergeModal show={show ?? false} centered onHide={onHide} bodyClassName="overflow-hidden">
      <>
        <Row>
          <Col>
            <DeprecatedH3>Make sure no customers are using this field!</DeprecatedH3>
            Deleting a field can break customer's code. Communicate to customer before deleting.
          </Col>
        </Row>
        <Row className="mt-6">
          <Col>
            <Button className="btn-block" variant="outline-secondary" onClick={onHide}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button className="btn-block" variant="outline-danger" onClick={onConfirm}>
              Delete "{field}"
            </Button>
          </Col>
        </Row>
      </>
    </MergeModal>
  );
};
