import { Typeahead } from "@merge-api/merge-javascript-shared";
import { useState } from "react";
import { APIEndpointParameter } from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_APIEndpointParameter";
import {
  UserFacingFilterDetails,
  APIEndpointParameterMapping,
} from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import EmptyStateWrapper from "../../../../shared/layout/EmptyStateWrapper";
import { VersionedComponentInfo } from "../../../../versioned-components/types";
import APIEndpointParameterChoiceRow from "./APIEndpointParameterChoiceRow";
import APIEndpointParameterForm from "./APIEndpointParameterForm";

interface Props {
  userFacingDetails: UserFacingFilterDetails;
  parameterMappingDetails: APIEndpointParameterMapping;
  existingAPIEndpointParameter: APIEndpointParameter | null | undefined;
  setOnSubmit: () => void;
  addOrUpdateAPIEndpointParameter: (
    newAPIEndpointParameter: VersionedComponentInfo<APIEndpointParameter>
  ) => void;
  setIsModalOpen: (value: boolean) => void;
  availableAPIEndpointParameters: APIEndpointParameter[];
  isLoadingAPIEndpointParameters: boolean;
  canSubmit: boolean;
  setCanSubmit: (value: boolean) => void;
}

const EditAPIEndpointParameterContainer = ({
  userFacingDetails,
  existingAPIEndpointParameter,
  setOnSubmit,
  setIsModalOpen,
  addOrUpdateAPIEndpointParameter,
  availableAPIEndpointParameters,
  isLoadingAPIEndpointParameters,
  canSubmit,
  setCanSubmit,
}: Props) => {
  const [apiEndpointParameter, setApiEndpointParameter] = useState<APIEndpointParameter | null>(
    existingAPIEndpointParameter ?? null
  );

  if (isLoadingAPIEndpointParameters) {
    return <EmptyStateWrapper isSpinner />;
  }

  return (
    <>
      <Typeahead
        className="mb-2"
        options={availableAPIEndpointParameters}
        value={apiEndpointParameter?.api_endpoint_id?.name ?? ("" as any)}
        onChange={(_: any, val: any) => setApiEndpointParameter(val)}
        renderOption={(apiEndpointParameter) => (
          <APIEndpointParameterChoiceRow apiEndpointParameter={apiEndpointParameter} />
        )}
        filterOptions={(options: any, { inputValue }: any) => {
          return options.filter((option: APIEndpointParameter) => {
            const inputValueToLowerCase = inputValue.toLowerCase();
            const endpointName = (option.api_endpoint_id?.name ?? "").toLowerCase();
            const path = (option?.api_endpoint_id?.path ?? "").toLowerCase();
            return (
              endpointName.includes(inputValueToLowerCase) || path.startsWith(inputValueToLowerCase)
            );
          });
        }}
      />
      {apiEndpointParameter && (
        <APIEndpointParameterForm
          key={apiEndpointParameter?.id}
          userFacingDetails={userFacingDetails}
          apiEndpointParameter={apiEndpointParameter}
          setOnSubmit={setOnSubmit}
          isEditingExistingAPIEndpointParameter={true}
          setIsModalOpen={setIsModalOpen}
          addOrUpdateAPIEndpointParameter={addOrUpdateAPIEndpointParameter}
          availableAPIEndpointParameters={availableAPIEndpointParameters}
          canSubmit={canSubmit}
          setCanSubmit={setCanSubmit}
        />
      )}
    </>
  );
};

export default EditAPIEndpointParameterContainer;
