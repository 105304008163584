import { useContext } from "react";
import AccordionCard from "../../shared/AccordionCard";
import CheckboxHeader from "../../shared/CheckboxHeader";
import APIEndpointContext from "../context/APIEndpointContext";

const APIEndpointAdvancedSetupOptions = () => {
  const { isEndpointModificationDisabled, setIsEndpointModificationDisabled } = useContext(
    APIEndpointContext
  );
  return (
    <AccordionCard
      title={"Advanced"}
      badgeContents={1}
      dataTestID="accordion-advanced-api-endpoint-options"
    >
      <CheckboxHeader
        dataTestID="field-api-endpoint-disable-endpoint-modifications"
        title="Disable Endpoint Modifications"
        subtitle="Enabling this runs Endpoint Modification Blueprint when making this API call during a Blueprint run, if Endpoint Modification Blueprint exists. Enabled by default."
        onChange={setIsEndpointModificationDisabled}
        checked={isEndpointModificationDisabled}
      />
    </AccordionCard>
  );
};

export default APIEndpointAdvancedSetupOptions;
