import { useMemo } from "react";
import styled from "styled-components";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { JSONObjectSchema, SchemaTypes, BlueprintOperationType } from "../../../models/Blueprints";
import MergeText from "../../shared/text/MergeText";
import useBlueprintContext from "../context/useBlueprintContext";
import BlueprintValidationEditorCard from "./BlueprintValidationEditorCard";
import { buildValidationActions } from "./BlueprintValidationEditorUtils";
import { Text } from "@merge-api/merge-javascript-shared";

const ValidationEditorContainer = styled.div`
  padding: 72px;
  flex-grow: 1;
  overflow: scroll;
`;

type Props = {
  schema: JSONObjectSchema;
  schemaType: SchemaTypes;
  operationType: undefined | string;
};

const CenteredContainer = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-align: center;
  flex-grow: 1;
`;

const TopContainer = styled.header.attrs({ className: "w-100" })`
  position: sticky;
  height: 40px;
  display: flex;
  align-items: center;
`;

const TopContent = styled.div.attrs({ className: "w-100" })`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledButton = styled(Button).attrs({ size: "sm", variant: "light" })`
  background-color: white;
  border: 1px #e3ebf6 solid;
`;

const BlueprintValidationEditor = ({ schema, schemaType, operationType }: Props) => {
  const {
    setBlueprintParameterSchemaValue,
    setBlueprintReturnSchemaValue,
    setRequiredFieldsForObject,
  } = useBlueprintContext();
  const schemaSetter =
    schemaType === SchemaTypes.parameter_schema
      ? setBlueprintParameterSchemaValue
      : setBlueprintReturnSchemaValue;
  const validationActions = useMemo(
    () => buildValidationActions({ setRequiredFieldsForObject, schemaSetter }),
    [schemaSetter, setRequiredFieldsForObject]
  );
  const title =
    operationType !== BlueprintOperationType.FUNCTIONAL
      ? "Field Validation (WIP)"
      : schemaType === SchemaTypes.parameter_schema
      ? "Parameter Schema"
      : "Return Schema";
  return (
    <ValidationEditorContainer>
      <TopContainer>
        <TopContent>
          <MergeText size="24px" isBold>
            {title}
          </MergeText>
          <OverlayTrigger
            placement="top"
            delay={{ show: 100, hide: 0 }}
            overlay={
              <Tooltip className="step-card-log-icon-tooltip" id="tooltip">
                {
                  <>
                    For now, edit the BP's parameter schema in CMS to add custom requirements.
                    <br />
                    <b>Make sure to follow proper JSON schema.</b>
                    <br />
                    See Freshdesk Ticket for example.
                  </>
                }
              </Tooltip>
            }
          >
            <StyledButton className="mr-3">Add Custom Requirements</StyledButton>
          </OverlayTrigger>
        </TopContent>
      </TopContainer>
      <CenteredContainer>
        {schema && Object.entries(schema).length > 0 ? (
          <BlueprintValidationEditorCard
            schema={schema}
            schemaType={schemaType}
            validationActions={validationActions}
            operationType={operationType}
          />
        ) : (
          <Text variant="h6" className="text-gray-70">
            No parameter schema defined for this Blueprint
          </Text>
        )}
      </CenteredContainer>
    </ValidationEditorContainer>
  );
};

export default BlueprintValidationEditor;
