import { Accordion } from "@merge-api/merge-javascript-shared";
import {
  MappingTestBlockExecutionError,
  isAssertionFailureDict,
  isMockRequestNotFoundDict,
} from "../../../../models/MappingTests";
import MappingTestV2RightPanelErrorSummary from "./MappingTestV2RightPanelErrorSummary";
import MappingTestV2RightPanelErrorDetailsAssertions from "./MappingTestV2RightPanelErrorDetailsAssertions";
import MappingTestV2RightPanelErrorDetailsMockRequest from "./MappingTestV2RightPanelErrorDetailsMockRequest";
import MappingTestV2RightPanelErrorSuggestions from "./MappingTestV2RightPanelErrorSuggestions";

interface Props {
  error: MappingTestBlockExecutionError;
}

const MappingTestV2RightPanelErrorCard = ({ error }: Props) => {
  return error.details || error.suggestions ? (
    <Accordion
      title={<MappingTestV2RightPanelErrorSummary type={error.type} summary={error.summary} />}
      chevronOrientation="right"
      chevronSize={16}
      chevronColor="darkGray"
      variant="none"
      className="py-4"
    >
      <div className="pt-2">
        {error.details &&
          (isMockRequestNotFoundDict(error.details) ? (
            <MappingTestV2RightPanelErrorDetailsMockRequest errorDetails={error.details} />
          ) : isAssertionFailureDict(error.details) ? (
            <MappingTestV2RightPanelErrorDetailsAssertions errorDetails={error.details} />
          ) : null)}
        {error.suggestions && (
          <MappingTestV2RightPanelErrorSuggestions errorSuggestions={error.suggestions} />
        )}
      </div>
    </Accordion>
  ) : (
    <div className="py-4">
      <MappingTestV2RightPanelErrorSummary type={error.type} summary={error.summary} />
    </div>
  );
};

export default MappingTestV2RightPanelErrorCard;
