import classNames from "classnames";
import { Plus } from "lucide-react";
import useScraperContext from "./context/useScraperContext";
import { ScraperGhostStep } from "./types";

interface Props {
  ghostStep: ScraperGhostStep;
}

const ScraperGhostStepCard = ({ ghostStep }: Props) => {
  const { selectedStep, setSelectedStep } = useScraperContext();
  const cardClass = classNames(
    "card",
    "m-0",
    "step-card",
    "ghost-step",
    selectedStep?.id === ghostStep.id ? "step-card-highlighted" : "step-card-not-highlighted"
  );

  return (
    <>
      <button
        className={cardClass}
        onClick={() => {
          setSelectedStep(ghostStep);
        }}
      >
        <Plus size={"36"} strokeWidth={2} />
      </button>
    </>
  );
};

export default ScraperGhostStepCard;
