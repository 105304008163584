import useIntegrationBuilderContext from "../../../context/useIntegrationBuilderContext";
import HelpAccordionBlock from "../../../shared/HelpAccordionBlock";
import HelpAccordionCard from "../../../shared/HelpAccordionCard";

interface HelperAccordionProps {
  className?: string;
}

const HeadersHelperAccordion = ({ className }: HelperAccordionProps) => {
  const { hasPermissionToEdit } = useIntegrationBuilderContext();

  if (!hasPermissionToEdit) {
    return <></>;
  }

  return (
    <HelpAccordionCard
      title="Additional information for using variables in header fields"
      className={className}
      children={
        <div className="flex flex-col space-y-4">
          <HelpAccordionBlock
            title="Auth key header format"
            body={
              <div>
                The auth key header format is what comes after -H in curl commands for API requests.
                Default auth headers are appended to every request.
                <br />
                Examples: For “Authorization: Token aBc8Jss-d2s”, enter “Authorization: Token
                &#123;API-KEY&#125;”. For “API-Key: aBc8Jss-d2s”, enter “API-Key:
                &#123;API-KEY&#125;”.
                <br />
                Always use &#123;API-KEY&#125; for anything resembling an API Key, even if it isn’t
                called that.
              </div>
            }
          />
          <HelpAccordionBlock
            title="Placeholders"
            body={
              <div>
                &#123;DOMAIN&#125;: Represents the domain for authentication.
                <br />
                &#123;API-KEY&#125;: Represents the API key for authentication.
                <br />
                &#123;BASE_URL&#125;: Represents the base URL or domain of the API. Replace it with
                the actual base URL when making requests.
                <br />
                &#123;PATH&#125;: Represents the specific path or endpoint of the API.
                <br />
                &#123;USERNAME&#125;: Represents the username for authentication.
                <br />
                &#123;PASSWORD&#125;: Represents the password for authentication.
                <br />
                &#123;ACCESS-TOKEN&#125;: Represents the OAuth access token.
                <br />
                &#123;CLIENT-ID&#125;: Represents the OAuth client ID.
                <br />
                &#123;CLIENT-SECRET&#125;: Represents the OAuth client secret.
                <br />
                &#123;OAUTH-REFRESH-TOKEN&#125;: Represents the OAuth refresh token.
                <br />
                &#123;$GUID&#125;: Represents a globally unique identifier.
                <br />
                &#123;$TIMESTAMP&#125;: Represents a timestamp value.
                <br />
                &#123;ORGANIZATION_NAME&#125;: Represents the name of an organization.
                <br />
                &#123;ORGANIZATION_CONFIG:your_custom_field_name&#125;: Represents a custom
                organization config field. The actual field value is typically defined in a
                Blueprint, via the "Set Organization Config Field" step. An example is Sage
                Intacct's basic authentication configuration.
              </div>
            }
          />
          <HelpAccordionBlock
            title="Base64 encoding"
            body={
              <div>
                For base64 encoding, mark the encrypted value with &#123;BASE-64&#125; at the
                beginning and end.
                <br />
                Example: For an authorization header that takes a base64-encoded SOME_KEY as a
                bearer token, use “Authorization: Bearer
                &#123;BASE-64&#125;&#123;SOME_KEY&#125;&#123;BASE-64&#125;”.
              </div>
            }
          />
        </div>
      }
    />
  );
};

export default HeadersHelperAccordion;
