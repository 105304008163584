import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { BlueprintOperationType } from "../../../models/Blueprints";
import { ScraperVersion } from "../../scraper/types";
import { EditableText } from "../../shared/EditableText";
import useBlueprintContext from "../context/useBlueprintContext";
import BlueprintEditorRightPanelExistingStepForm from "./BlueprintEditorRightPanelExistingStepForm";
import BlueprintEditorRightPanelNewStepForm from "./BlueprintEditorRightPanelNewStepForm";
import BlueprintEditorRightPanelScraperForm from "./BlueprintEditorRightPanelScraperForm";
import BlueprintEditorRightPanelTriggerForm from "./BlueprintEditorRightPanelTriggerForm";
import { showErrorToast } from "../../shared/Toasts";
import DeprecatedH2 from "../../deprecated/DeprecatedH2";

const MAX_NAME_LENGTH = 33;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  width: 350px;
  height: 100%;
  box-shadow: -1px 0px 0px #edf2f9;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const MainContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const BlueprintEditorRightPanel = () => {
  const { renameStep, selectedStep, blueprint } = useBlueprintContext();

  if (!selectedStep) {
    return <></>;
  }
  const stepID = selectedStep?.id ?? "";
  const stepTemplate = selectedStep?.template;

  const getConfig = () => {
    if (stepTemplate === undefined) {
      return {
        title: "Trigger",
        subtitle: "Control when this Blueprint executes",
      };
    } else if (stepTemplate === "ghost") {
      return {
        title: "New Step",
        subtitle: undefined,
      };
    } else {
      return {
        title: stepTemplate.name,
        subtitle: stepID,
      };
    }
  };

  const { title, subtitle } = getConfig();

  const truncatedTitle =
    title.length > MAX_NAME_LENGTH ? title.substring(0, MAX_NAME_LENGTH) + "..." : title;

  const onSaveStepID = (newName: string, onError?: () => void) => {
    if (newName) {
      renameStep(stepID, newName, onError);
    } else {
      showErrorToast("Step name cannot be empty.");
    }
  };

  const showTriggerForm = stepTemplate === undefined && !(selectedStep && "steps" in selectedStep);

  const isReportBlueprint = !!blueprint.report_template_id;
  const isWebhookBlueprint = !!blueprint.webhook_receiver_event_type_id;
  const isScheduledBlueprint = [
    BlueprintOperationType.FETCH,
    BlueprintOperationType.WEBHOOK_SETUP,
  ].includes(blueprint.operation_type);

  // Webhook/Report blueprints don't run at scheduled intervals, but rather whenever we receive a webhook/report file
  const blueprintRunsOnScheduledIntervals =
    !(isReportBlueprint || isWebhookBlueprint) && isScheduledBlueprint;

  if (showTriggerForm && !blueprintRunsOnScheduledIntervals) {
    return <></>;
  }

  return (
    <RightPanel>
      <Row className="mt-6 mx-3">
        <Col className="text-center">
          <DeprecatedH2 className="mb-3">{truncatedTitle}</DeprecatedH2>
          {subtitle && stepTemplate && stepTemplate !== "ghost" && (
            <EditableText
              originalText={stepID}
              onSave={onSaveStepID}
              textClassName="text-muted"
              hasEditShortcut={true}
            />
          )}
        </Col>
      </Row>
      <hr />
      <MainContent>
        {stepTemplate === undefined ? (
          selectedStep && "steps" in selectedStep ? (
            <BlueprintEditorRightPanelScraperForm scraper={selectedStep as ScraperVersion} />
          ) : (
            <BlueprintEditorRightPanelTriggerForm />
          )
        ) : stepTemplate === "ghost" ? (
          <BlueprintEditorRightPanelNewStepForm />
        ) : (
          <BlueprintEditorRightPanelExistingStepForm
            key={stepID}
            stepID={stepID}
            stepTemplate={stepTemplate}
          />
        )}
      </MainContent>
    </RightPanel>
  );
};

export default BlueprintEditorRightPanel;
