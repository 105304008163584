import { Card } from "react-bootstrap";
import styled from "styled-components";
import { MappingTestErrorInfo } from "../../../models/MappingTests";

const ConsoleSubtabContainer = styled.div`
  margin-top: 12px;
`;

type Props = {
  constructionErrors: MappingTestErrorInfo;
};

const ErrorCardTitle = styled(Card.Title)`
  font-size: 18px;
`;

const ErrorCard = styled(Card)`
  border: 1px solid #dc3545;
`;

const ErrorCardBody = styled(Card)`
  padding: 14px;
  margin: 0px;
`;

const MappingTestConstructionErrors = ({ constructionErrors }: Props) => {
  if ((constructionErrors ?? []).length === 0 || !constructionErrors)
    return <ConsoleSubtabContainer> No Errors!</ConsoleSubtabContainer>;

  return (
    <ConsoleSubtabContainer>
      {(constructionErrors ?? []).map((errorInfo, index) => (
        <ErrorCard key={index}>
          <ErrorCardBody>
            <ErrorCardTitle className="text-danger">Error</ErrorCardTitle>
            <Card.Text>
              <strong>Component:</strong> {errorInfo.path}
              {errorInfo?.field ? (
                <>
                  <br /> <strong>Field:</strong> {errorInfo?.field}
                </>
              ) : (
                <></>
              )}
              <br />
              <strong>Message:</strong> {errorInfo.message}
            </Card.Text>
          </ErrorCardBody>
        </ErrorCard>
      ))}
    </ConsoleSubtabContainer>
  );
};

export default MappingTestConstructionErrors;
