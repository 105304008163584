import { Text } from "@merge-api/merge-javascript-shared";
import { BlueprintMeta } from "../../../models/Entities";
import { MappingTestBlock, MappingTestRequestMock } from "../../../models/MappingTests";
import MappingTestBlockBlueprintCard from "./MappingTestBlockBlueprintCard";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

const BlueprintDropdownContainer = styled(Dropdown)``;

const BlueprintDropdownToggle = styled(Dropdown.Toggle)`
  background: #f2f4f7;
  border-radius: 8px;
  width: 100%;
  padding: 12px 16px;
  width: 100%;
  height: 36px;
  color: #737982;

  /* LM/Gray/0 */

  background: #f2f4f7;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 0.5px rgba(220, 226, 234, 0.2), 0px 3px 12px -3px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  border-color: #d7d9de;
  padding-top: 5px;

  &&& {
    ::after {
      content: "+";
      font-weight: bold;
      margin-bottom: 6px;
      margin-left: 6px;
    }
  }
`;

interface BlueprintDropdownProps {
  blueprintsMeta: BlueprintMeta[];
  blockID: string;
}

interface Props {
  mappingTestBlock: MappingTestBlock;
  blueprintsMap: { [id: string]: BlueprintMeta };
  requestsMap: { [id: string]: MappingTestRequestMock };
  draftBlueprintsMap: { [id: string]: BlueprintMeta };
  addBlueprintToMappingTestBlock: (mappingTestBlockID: string, blueprintID: string) => void;
  removeBlueprintFromMappingTestBlock: (mappingTestBlockID: string, blueprintID: string) => void;
  addRequestMockToMappingTestBlock: (
    mappingTestBlockID: string,
    blueprintID: string,
    requestMock: MappingTestRequestMock
  ) => void;
  removeRequestMockFromMappingTestBlock: (
    mappingTestBlockID: string,
    blueprintID: string,
    requestMock: MappingTestRequestMock
  ) => void;
  setOverrideLastRunAtValue: (
    mappingTestBlockID: string,
    blueprintID: string,
    overrideLastRunAtValue?: string | null
  ) => void;
  setDisableFilterByDateValue: (
    mappingTestBlockID: string,
    blueprintID: string,
    disableFilterByDateValue?: boolean
  ) => void;
}

const MappingTestBlockBlueprints = ({
  mappingTestBlock,
  blueprintsMap,
  requestsMap,
  draftBlueprintsMap,
  addBlueprintToMappingTestBlock,
  removeBlueprintFromMappingTestBlock,
  addRequestMockToMappingTestBlock,
  removeRequestMockFromMappingTestBlock,
  setOverrideLastRunAtValue,
  setDisableFilterByDateValue,
}: Props) => {
  const BlueprintDropdown: React.FC<BlueprintDropdownProps> = ({ blueprintsMeta, blockID }) => {
    return (
      <BlueprintDropdownContainer>
        <BlueprintDropdownToggle variant="light">Add Blueprint</BlueprintDropdownToggle>
        <BlueprintDropdownContainer.Menu>
          {blueprintsMeta
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter(
              (blueprintMeta) =>
                !(mappingTestBlock.ordered_blueprints_meta ?? []).some((orderedMeta) =>
                  orderedMeta.hasOwnProperty(blueprintMeta.blueprint_id)
                )
            )
            .map((blueprintMeta: BlueprintMeta) => (
              <BlueprintDropdownContainer.Item
                key={blueprintMeta.id}
                onClick={() => {
                  addBlueprintToMappingTestBlock(blockID, blueprintMeta.blueprint_id);
                }}
              >
                <div>
                  <Text variant="h5">
                    {blueprintMeta?.human_name ?? "" !== ""
                      ? blueprintMeta?.human_name
                      : blueprintMeta.name}
                  </Text>
                </div>
              </BlueprintDropdownContainer.Item>
            ))}
        </BlueprintDropdownContainer.Menu>
      </BlueprintDropdownContainer>
    );
  };

  return (
    <div className="px-5 pb-5">
      {(mappingTestBlock.ordered_blueprints_meta ?? []).map((blueprintMetaDict) => {
        const [bp_id, blockBlueprintMeta] = Object.entries(blueprintMetaDict)[0];
        return (
          <MappingTestBlockBlueprintCard
            blueprint={bp_id in blueprintsMap ? blueprintsMap[bp_id] : draftBlueprintsMap[bp_id]}
            requestsMap={requestsMap}
            blockBlueprintMeta={blockBlueprintMeta}
            mappingTestBlock={mappingTestBlock}
            removeBlueprintFromMappingTestBlock={removeBlueprintFromMappingTestBlock}
            addRequestMockToMappingTestBlock={addRequestMockToMappingTestBlock}
            removeRequestMockFromMappingTestBlock={removeRequestMockFromMappingTestBlock}
            setOverrideLastRunAtValue={setOverrideLastRunAtValue}
            setDisableFilterByDateValue={setDisableFilterByDateValue}
          />
        );
      })}
      <BlueprintDropdown
        blockID={mappingTestBlock.id}
        blueprintsMeta={Object.values(blueprintsMap)}
      />
    </div>
  );
};

export default MappingTestBlockBlueprints;
