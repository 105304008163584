import { Card, Text } from "@merge-api/merge-javascript-shared";
import { ChecklistStatusValue, IntegrationChecklistData } from "../../../../constants";
import IntegrationChecklistIssue from "./IntegrationChecklistIssue";

type Props = {
  integrationChecklist: IntegrationChecklistData | undefined;
  integrationID: string;
  fetchIntegrationChecklist: () => void;
};

const IntegrationChecklistResultsContainer = ({
  integrationChecklist,
  integrationID,
  fetchIntegrationChecklist,
}: Props) => {
  const integrationChecklistIssues = integrationChecklist
    ? Object.entries(integrationChecklist).filter(
        ([_, value]) =>
          value === ChecklistStatusValue.NOT_IMPLEMENTED ||
          value === ChecklistStatusValue.NOT_SUPPORTED
      )
    : undefined;

  return (
    <Card variant="none">
      {integrationChecklist ? (
        integrationChecklistIssues ? (
          <div className="m-5 mt-0">
            {Object.values(integrationChecklistIssues).map(([key, value]) => (
              <IntegrationChecklistIssue
                checklistItem={key}
                checklistValue={value}
                integrationID={integrationID}
                fetchIntegrationChecklist={fetchIntegrationChecklist}
              />
            ))}
          </div>
        ) : (
          <Card>
            <Text>All checks passed.</Text>
          </Card>
        )
      ) : (
        <Card>
          <Text>No checklist information available.</Text>
        </Card>
      )}
    </Card>
  );
};

export default IntegrationChecklistResultsContainer;
