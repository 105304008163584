import { Toggle, Badge } from "@merge-api/merge-javascript-shared";

interface Props {
  onClick: () => void;
  checked: boolean;
}

const ToggleCell = ({ onClick, checked }: Props) => {
  return (
    <td className="py-5">
      <div className="flex flex-row space-x-3 justify-start items-center w-[86px]">
        <div onClick={onClick}>
          <Toggle className="pointer-events-none" checked={checked} />
        </div>
        <Badge color={checked ? "teal" : "gray"}>{checked ? "Active" : "Inactive"}</Badge>
      </div>
    </td>
  );
};

export default ToggleCell;
