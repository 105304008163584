import { CommonModelInfo, LinkedAccount } from "../../../../models/Entities";
import LeftPanelTypeahead from "../shared/LeftPanelTypeahead";
import LinkedAccountTypeaheadRenderOption from "../../../shared/linked-account-typeahead/LinkedAccountTypeaheadRenderOption";
import { filterLinkedAccountTypeaheadOptions } from "../../../shared/linked-account-typeahead/LinkedAccountTypeaheadFilter";

interface Props {
  selectedTestLinkedAccount: undefined | string;
  setSelectedTestLinkedAccount: (s: string | undefined) => void;
  setReportFileID: (x: string | undefined) => void;
  isLoadingTestLinkedAccounts: boolean;
  testLinkedAccounts: LinkedAccount[];
  selectedTestCommonModel: undefined | string;
  setSelectedTestCommonModel: (s: string | undefined) => void;
  testCommonModels: undefined | CommonModelInfo[];
}

/**
 * Renders Select fields for configuring a Blueprint test run
 */
const BlueprintLeftPanelConsoleSelect = ({
  selectedTestLinkedAccount,
  setSelectedTestLinkedAccount,
  setReportFileID,
  isLoadingTestLinkedAccounts,
  testLinkedAccounts,
  selectedTestCommonModel,
  setSelectedTestCommonModel,
  testCommonModels,
}: Props) => {
  return (
    <div className="flex flex-col space-y-4">
      <LeftPanelTypeahead
        loading={isLoadingTestLinkedAccounts}
        title="Linked Account"
        placeholder="Select Linked Account"
        onChange={(_, option: LinkedAccount | undefined) => {
          setSelectedTestLinkedAccount(option?.id ?? undefined);
          setReportFileID(undefined);
        }}
        value={testLinkedAccounts.find(
          (linkedAccount) => linkedAccount?.id === selectedTestLinkedAccount
        )}
        getOptionLabel={(option: LinkedAccount) => {
          return option.end_user?.organization_name;
        }}
        renderOption={(option: LinkedAccount) => (
          <LinkedAccountTypeaheadRenderOption option={option} />
        )}
        filterOptions={(options: LinkedAccount[], state: any) => {
          return filterLinkedAccountTypeaheadOptions(options, state);
        }}
        options={testLinkedAccounts}
      />
      {testCommonModels !== undefined && (
        <LeftPanelTypeahead
          title={`Common Model ${testCommonModels?.[0]?.name}`}
          placeholder={`Select ${testCommonModels?.[0]?.name ?? "Common Model"}`}
          onChange={(_, option) => {
            setSelectedTestCommonModel(option ?? undefined);
          }}
          value={selectedTestCommonModel}
          options={testCommonModels.map(({ id }) => id)}
        />
      )}
    </div>
  );
};

export default BlueprintLeftPanelConsoleSelect;
