import clsx from "clsx";
import { Card, Spinner } from "@merge-api/merge-javascript-shared";
import { useEffect, useState } from "react";
import {
  UserFacingFilterDetails,
  UserFacingFilterType,
} from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import useSelectiveSyncFilterBuilderContext from "../../context/useSelectiveSyncFilterBuilderContext";
import ReadOnlyUserFacingFilterDetails from "./ReadOnlyUserFacingFilterDetails";
import UserFacingFilterForm from "./UserFacingFilterForm";
import useLoadFilterTypes from "./hooks/useLoadFilterTypes";
import useIntegrationBuilderContext from "../../../../integration-builder/context/useIntegrationBuilderContext";

interface Props {
  filterDetails: UserFacingFilterDetails | null;
  setFilterTypeID: (filterTypeID: string) => void;
  setRemoteKeyName: (remoteKeyName: string) => void;
  isEditingExistingS2FilterSchema: boolean;
  setIsMissingRequiredFields: React.Dispatch<React.SetStateAction<boolean>>;
  updatePublicFacingStatus: (newStatus: boolean) => void;
}

type AvailableFilterTypes = {
  [id: string]: UserFacingFilterType;
};

const SelectiveSyncFilterUserFacingFilterDetails = ({
  filterDetails,
  setFilterTypeID,
  setRemoteKeyName,
  setIsMissingRequiredFields,
  isEditingExistingS2FilterSchema,
  updatePublicFacingStatus,
}: Props) => {
  const isExistingUserFacingDetails = !!filterDetails?.filter_type_id?.id;
  const { integrationID } = useSelectiveSyncFilterBuilderContext();
  const [currentCommonModelID, setCurrentCommonModelID] = useState<string>(
    filterDetails?.filter_type_id?.common_model_class_id ?? ""
  );
  const [isExistingCommonModelField, setIsExistingCommonModelField] = useState<boolean | null>(
    filterDetails?.filter_type_id?.is_common_model_field ?? true
  );
  const [integrationFilterTypes, setIntegrationFilterTypes] = useState<AvailableFilterTypes>({});
  const [commonModelField, setCommonModelField] = useState<string | null>(
    filterDetails?.filter_type_id?.filter_name ?? null
  );
  const { markedForDeletion } = useIntegrationBuilderContext();

  const { loadFilterTypes, isLoadingFilterTypes } = useLoadFilterTypes({
    integrationID,
    setIntegrationFilterTypes,
  });

  // This useEffect is used to fetch available filter types from our backend
  // so that users can choose which filter type to associate a new S2 filter
  // schema to. If we already have a filterType connected to the S2 filter type schema,
  // we block them from changing it (for now)
  useEffect(() => {
    loadFilterTypes();
  }, []);

  const Header = () => (
    <>
      <div className="text-lg font-semibold items-center flex flex-row">
        <div>Filter Configuration</div>
      </div>
      <hr className="text-gray-50 h-[0.5px] -mx-4" />
    </>
  );

  return (
    <Card className={clsx("flex flex-col p-4 mb-6", markedForDeletion && "bg-gray-0")}>
      <Header />
      {isLoadingFilterTypes && !(Object.entries(integrationFilterTypes).length > 0) ? (
        <Spinner />
      ) : isExistingUserFacingDetails && isEditingExistingS2FilterSchema ? (
        <ReadOnlyUserFacingFilterDetails
          filterDetails={filterDetails}
          updatePublicFacingStatus={updatePublicFacingStatus}
        />
      ) : (
        <UserFacingFilterForm
          integrationFilterTypes={integrationFilterTypes}
          currentCommonModelID={currentCommonModelID}
          setCurrentCommonModelID={setCurrentCommonModelID}
          isExistingCommonModelField={isExistingCommonModelField}
          setIsExistingCommonModelField={setIsExistingCommonModelField}
          isEditingExistingS2FilterSchema={isEditingExistingS2FilterSchema}
          commonModelField={commonModelField}
          setCommonModelField={setCommonModelField}
          setFilterTypeID={setFilterTypeID}
          setRemoteKeyName={setRemoteKeyName}
          filterDetails={filterDetails}
          setIsMissingRequiredFields={setIsMissingRequiredFields}
        />
      )}
    </Card>
  );
};

export default SelectiveSyncFilterUserFacingFilterDetails;
