import { Dialog } from "@merge-api/merge-javascript-shared";
import DOMPurify from "dompurify";

interface Props {
  open: boolean;
  onClose: () => void;
  htmlText: string;
}

const NotesForCustomersPreviewModal = ({ open, onClose, htmlText }: Props) => {
  return (
    <Dialog
      variant="md"
      footerButtonsHidden
      open={open}
      onClose={onClose}
      title="Preview notes for customers"
    >
      <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlText) }} />
    </Dialog>
  );
};

export default NotesForCustomersPreviewModal;
