import classNames from "classnames";
import { differenceInMilliseconds } from "date-fns";
import { useEffect, useState } from "react";
import { Accordion, Col, Container, Form, Row, Table } from "react-bootstrap";
import { ArrowLeft } from "lucide-react";
import { useHistory } from "react-router-dom";
import Editor from "react-simple-code-editor";

import {
  BlueprintRunnerStepLogEntries,
  BlueprintRunnerStepLogEntryType,
} from "../../models/Blueprints";
import { LinkedAccount } from "../../models/Entities";
import { isValidJSON } from "../../utils";
import { navigateToScraperEditor, navigateToScrapersSubtab } from "../../router/RouterUtils";
import DropdownFormField from "../blueprint-editor/right-panel/DropdownFormField";
import FormField from "../blueprint-editor/right-panel/FormField";
import InputFormField from "../blueprint-editor/right-panel/InputFormField";
import ClickableContainer from "../shared/ClickableContainer";
import JSONSchemaTreeDiagram from "../shared/JSONSchemaTreeDiagram";
import MergeCodeBlock from "../shared/MergeCodeBlock";
import MergeModal from "../shared/MergeModal";
import { PlusMinusToggle } from "../shared/MergeToggles";
import SpinnerButton from "../shared/SpinnerButton";
import { showErrorToast, showSuccessToast } from "../shared/Toasts";
import useScraperContext from "./context/useScraperContext";
import {
  ScraperExecutionStatus,
  ScraperExecutionWithIO,
  ScraperGetUserInputIntegrationSetupStepType,
  ScraperTestResults,
} from "./types";
import {
  fetchScraperExecutionUpdates,
  fetchTestLinkedAccounts,
  postScraperExecutionInput,
  testScraper,
} from "./utils/ScraperAPIClient";
import { getReturnSchemaForScraper } from "./utils/ScraperUtils";
import useFetchAsyncResult from "../shared/hooks/useFetchAsyncResult";
import { HeaderPretitle } from "../shared/text/MergeText";
import DeprecatedH2 from "../deprecated/DeprecatedH2";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";

const { highlight, languages } = require("prismjs");

const EventTypeRectangle = (props: { event_type: BlueprintRunnerStepLogEntryType }) => {
  const colorClass =
    props.event_type === BlueprintRunnerStepLogEntryType.Error
      ? "console-error"
      : props.event_type === BlueprintRunnerStepLogEntryType.Warning
      ? "console-warning"
      : "console-info";
  const rectClasses = classNames("event-type-rectangle", colorClass);
  return <div className={rectClasses}></div>;
};

const ScraperEditorLeftPanel = () => {
  const {
    integrationID,
    scraper,
    scraperExecutionLogs,
    setScraperExecutionLogs,
    setDisableArrows,
    disableArrows,
    latestPublishedVersionID,
  } = useScraperContext();
  const [linkedAccountID, setLinkedAccountID] = useState<string | undefined>();
  const [scraperExecutionID, setScraperExecutionID] = useState<string | undefined>();
  const [testLinkedAccounts, setTestLinkedAccounts] = useState<LinkedAccount[]>([]);
  const [isShowingRunScraperJSONModal, setIsShowingRunScraperJSONModal] = useState(false);
  const [shouldTriggerBlueprint, setShouldTriggerBlueprint] = useState(false);
  const [mfaCode, setMFACode] = useState<string | undefined>();
  const [globalVars, setGlobalVars] = useState<string>();
  const [isRunningTest, setIsRunningTest] = useState(false);
  const [scraperInputLabel, setScraperInputLabel] = useState<string | undefined>();
  const [scraperInput, setScraperInput] = useState<string | undefined>();
  const [isPostingScraperInput, setIsPostingScraperInput] = useState(false);
  const [runScraperJSON, setRunScraperJSON] = useState<string | undefined>();
  const history = useHistory();

  useEffect(() => {
    fetchTestLinkedAccounts({
      integrationID,
      onSuccess: setTestLinkedAccounts,
    });
  }, [integrationID]);

  const [
    isRunningScraperWithIO,
    scraperWithIOExecutionResults,
    clearScraperWithIOExecutionResults,
    pollScraperExecutionWithIO,
  ] = useFetchAsyncResult<ScraperExecutionWithIO>(
    (props) => {
      if (!scraperExecutionID) {
        return;
      }
      fetchScraperExecutionUpdates({
        asyncExecutionID: scraperExecutionID,
        ...props,
      });
    },
    fetchScraperExecutionUpdates,
    "poll scraper execution updates"
  );

  useEffect(() => {
    if (scraperExecutionID) {
      pollScraperExecutionWithIO();
    }
  }, [scraperExecutionID]);

  const step_logs = scraperExecutionLogs?.step_logs
    ? scraperExecutionLogs?.step_logs
    : scraperWithIOExecutionResults?.results?.scraper_results?.exit_data?.step_logs;

  if (scraperWithIOExecutionResults?.status === ScraperExecutionStatus.WAITING_ON_INPUT) {
    switch (scraperWithIOExecutionResults.integration_setup_step_type) {
      case ScraperGetUserInputIntegrationSetupStepType.STEP_TYPE_MFA_CODE:
        const mfaInputLabel = scraperWithIOExecutionResults.prompt || "Enter your MFA code";
        if (scraperInputLabel !== mfaInputLabel) {
          setScraperInputLabel(mfaInputLabel);
        }
        break;
      case ScraperGetUserInputIntegrationSetupStepType.STEP_TYPE_SECURITY_QUESTION:
        const inputLabel = scraperWithIOExecutionResults.prompt;
        if (scraperInputLabel !== inputLabel) {
          setScraperInputLabel(inputLabel);
        }
        break;
      default:
        break;
    }
  } else {
    if (scraperInputLabel !== undefined) {
      setScraperInputLabel(undefined);
    }
  }

  return (
    <div className="left-panel justify-content-center">
      <Container fluid>
        <Row className="mt-6">
          <Col className="text-center">
            <ClickableContainer onClick={() => navigateToScrapersSubtab(history, integrationID)}>
              <ArrowLeft strokeWidth={1.5} className="float-left" />
            </ClickableContainer>
            <DeprecatedH2 className="mb-3">Console</DeprecatedH2>
            <p className="text-muted">Check for scraper errors by running a test.</p>
          </Col>
        </Row>
        <hr />
        <Row className="mb-n3">
          <Col>
            <HeaderPretitle className="mt-3">Linked Account</HeaderPretitle>
            <DropdownFormField
              placeholder="Select Linked Account"
              onChange={(e) => {
                setLinkedAccountID(e.target.value);
              }}
              currentValue={linkedAccountID}
              choices={testLinkedAccounts.map(({ id }) => ({ id, name: id }))}
            />
          </Col>
        </Row>
        {latestPublishedVersionID && scraper.version_id !== latestPublishedVersionID && (
          <Row>
            <Button
              fullWidth
              variant={ButtonVariant.SecondaryBlue}
              onClick={() =>
                navigateToScraperEditor(
                  history,
                  integrationID,
                  scraper.id,
                  latestPublishedVersionID
                )
              }
            >
              Go to Latest Published Version
            </Button>
          </Row>
        )}

        <Row>
          <Col>
            <Accordion>
              <PlusMinusToggle eventKey="0">Settings</PlusMinusToggle>
              <Accordion.Collapse eventKey="0" className="mt-3">
                <>
                  <Form.Group controlId="should-trigger-blueprint">
                    <Form.Check
                      type="checkbox"
                      label="Trigger blueprint after scrape finishes"
                      onChange={() => setShouldTriggerBlueprint(!shouldTriggerBlueprint)}
                    />
                  </Form.Group>
                  <Form.Group controlId="disable-scraper-arrows">
                    <Form.Check
                      type="checkbox"
                      label="Disable scraper arrows"
                      onChange={() => setDisableArrows(!disableArrows)}
                      checked={disableArrows}
                    />
                  </Form.Group>
                  <InputFormField
                    currentValue={mfaCode}
                    onChange={setMFACode}
                    placeholder={"Set MFA Code"}
                    title={"MFA Code"}
                  />
                  <FormField
                    title={"Global Vars"}
                    subtitle={"For writes, pass in valid JSON representing nested common model."}
                  >
                    <Editor
                      highlight={(code) => highlight(code, languages.js)}
                      value={globalVars ?? ""}
                      onValueChange={(code) => {
                        setGlobalVars(code);
                      }}
                      padding={10}
                      style={{
                        backgroundColor: "white",
                        border: "1px solid #d2ddec",
                        borderRadius: 8,
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 12,
                        minHeight: "100px",
                        overflow: "auto",
                      }}
                    />
                  </FormField>
                </>
              </Accordion.Collapse>
            </Accordion>
          </Col>
        </Row>
        {isRunningScraperWithIO && scraperWithIOExecutionResults && (
          <Row>
            <Col>
              <Accordion defaultActiveKey="scraper-io-toggle">
                <PlusMinusToggle eventKey="scraper-io-toggle">Scraper IO</PlusMinusToggle>
                <Accordion.Collapse eventKey="scraper-io-toggle" className="mt-3">
                  <>
                    <p>Status: {scraperWithIOExecutionResults.status}</p>
                    {scraperInputLabel && !isPostingScraperInput && (
                      <>
                        <InputFormField
                          currentValue={scraperInput}
                          onChange={setScraperInput}
                          placeholder={"Enter input here"}
                          title={scraperInputLabel}
                        />
                        <SpinnerButton
                          isLoading={false}
                          className="btn btn-primary btn-block mt-9"
                          onClick={() => {
                            clearScraperWithIOExecutionResults();
                            setIsPostingScraperInput(true);
                            postScraperExecutionInput({
                              scraperExecutionID: scraperWithIOExecutionResults.id,
                              input: scraperInput!,
                              onSuccess: () => {
                                clearScraperWithIOExecutionResults();
                                setIsPostingScraperInput(false);
                                showSuccessToast("Successfully posted input to scraper");
                              },
                              onError: () => {
                                clearScraperWithIOExecutionResults();
                                setIsPostingScraperInput(false);
                                showErrorToast("Failed to post input to scraper");
                              },
                            });
                          }}
                          disabled={!scraperInput}
                          text="Submit"
                        />
                      </>
                    )}
                  </>
                </Accordion.Collapse>
              </Accordion>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <SpinnerButton
              isLoading={isRunningTest || isRunningScraperWithIO}
              className="btn btn-primary btn-block mt-9"
              onClick={() => {
                if (linkedAccountID) {
                  setIsRunningTest(true);
                  testScraper({
                    scraper,
                    shouldTriggerBlueprint,
                    isIOAvailable: true,
                    mfaCode,
                    linkedAccountID,
                    globalVars:
                      globalVars && isValidJSON(globalVars) ? JSON.parse(globalVars) : undefined,
                    onSuccess: (response) => {
                      if (response.run_scraper_json) {
                        setRunScraperJSON(JSON.stringify(response.run_scraper_json, null, 2));
                      }
                      if (response.is_io_enabled) {
                        const scraperExecutionResponse = response as ScraperExecutionWithIO;
                        setScraperExecutionID(scraperExecutionResponse.id);
                        setIsRunningTest(false);
                        return;
                      }
                      setScraperExecutionLogs(
                        (response as ScraperTestResults).scraper_results?.exit_data
                      );
                      showSuccessToast("Successfully tested scraper!");
                      setIsRunningTest(false);
                    },
                    onError: () => {
                      showErrorToast("Something went wrong.");
                      setIsRunningTest(false);
                    },
                  });
                }
              }}
              disabled={!linkedAccountID}
              text="Test Scraper"
            />
            {runScraperJSON && (
              <button
                className="btn btn-secondary btn-block mt-1.5"
                onClick={() => setIsShowingRunScraperJSONModal(true)}
                disabled={!runScraperJSON}
              >
                View Test Scraper JSON
              </button>
            )}
          </Col>
        </Row>
        {step_logs ? (
          <Row className="mt-6">
            <Col>
              <Table size="sm" className="low-padding-table" style={{ height: "100%" }}>
                <thead className="table-borderless">
                  <tr>
                    <th>Time</th>
                    <th>Message</th>
                  </tr>
                </thead>
                <tbody>
                  {" "}
                  {step_logs?.map((log: BlueprintRunnerStepLogEntries) => (
                    <tr>
                      <td className="console-table-message align-middle" style={{ height: "100%" }}>
                        <EventTypeRectangle event_type={log.entry_type} />
                        {differenceInMilliseconds(new Date(log.time), new Date(step_logs[0].time)) /
                          1000 +
                          "s"}
                      </td>
                      <td className="text-break align-middle p-0">
                        {(log.step_id ? log.step_id + ": " : "") + log.message}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        ) : (
          <Row className="mt-9">
            <Col>
              <JSONSchemaTreeDiagram
                jsonSchema={getReturnSchemaForScraper(scraper)}
                stepID={"return_schema"}
              />
            </Col>
          </Row>
        )}
      </Container>
      <MergeModal
        show={isShowingRunScraperJSONModal}
        onHide={() => setIsShowingRunScraperJSONModal(false)}
        title="/run-scraper JSON"
        fitContent
      >
        <Row>
          <Col>
            <p>Use the following JSON to rapidly create a test case for merge-scraper.</p>
            <MergeCodeBlock textToCopy={runScraperJSON}>{runScraperJSON}</MergeCodeBlock>
          </Col>
        </Row>
      </MergeModal>
    </div>
  );
};

export default ScraperEditorLeftPanel;
