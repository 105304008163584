import { Accordion, Card } from "@merge-api/merge-javascript-shared";
import { IntegrationTestSuiteExecution } from "../../../../models/MappingTests";
import { BlueprintValidatorResult, PublishModuleInfo } from "../types";
import PublishModuleTestExecutionsContainer from "../MappingTestResults/MappingTestResults";
import IntegrationChecklistAccordionTitle from "./IntegrationChecklistAccordionTitle";
import MappingTestAccordionTitle from "./MappingTestAccordionTitle";
import { IntegrationChecklistData } from "../../../../constants";
import PublishModuleValidatorResultsContainer from "../BlueprintValidatorResults/PublishModuleValidatorResultsContainer";
import IntegrationChecklistResultsContainer from "../IntegrationChecklistResults/IntegrationChecklistResultsContainer";
import BlueprintValidationAccordionTitle from "./BlueprintValidationAccordionTile";

type Props = {
  blueprintValidatorInfo: Record<string, BlueprintValidatorResult>;
  integrationTestSuiteExecution: IntegrationTestSuiteExecution | null;
  hasFailedFetchingLatestExecution: boolean;
  integrationID: string;
  integrationChecklist: IntegrationChecklistData | undefined;
  onRunTestsForStagedIntegrationVersion: () => void;
  fetchIntegrationChecklist: () => void;
  runAllValidationChecks: () => void;
  refreshIntegrationChecklist: () => void;
  isRunningMappingTests: boolean;
  isRunningValidator: boolean;
  publishModuleInfo: PublishModuleInfo | undefined;
  isRefreshingChecklist: boolean;
  isFetchingLatestTestSuiteExecution: boolean;
};

const PublishModuleChecksCard = ({
  blueprintValidatorInfo,
  integrationID,
  hasFailedFetchingLatestExecution,
  integrationTestSuiteExecution,
  integrationChecklist,
  onRunTestsForStagedIntegrationVersion,
  fetchIntegrationChecklist,
  runAllValidationChecks,
  refreshIntegrationChecklist,
  isRunningMappingTests,
  isRunningValidator,
  publishModuleInfo,
  isRefreshingChecklist,
  isFetchingLatestTestSuiteExecution,
}: Props) => {
  return (
    <div>
      <Card>
        <Accordion
          chevronSize={16}
          chevronOrientation="right"
          onChange={function noRefCheck() {}}
          title={
            <IntegrationChecklistAccordionTitle
              integrationID={integrationID}
              integrationChecklist={integrationChecklist}
              refreshIntegrationChecklist={refreshIntegrationChecklist}
              isRefreshingChecklist={isRefreshingChecklist}
            />
          }
          titleClassName="px-6 py-6"
          variant="none"
        >
          <IntegrationChecklistResultsContainer
            integrationID={integrationID}
            integrationChecklist={integrationChecklist}
            fetchIntegrationChecklist={fetchIntegrationChecklist}
          />
        </Accordion>
        <hr className="m-0 text-gray-50" />
        <Accordion
          className=""
          chevronSize={16}
          chevronOrientation="right"
          onChange={function noRefCheck() {}}
          title={
            <BlueprintValidationAccordionTitle
              blueprintValidatorInfo={blueprintValidatorInfo}
              integrationID={integrationID}
              runAllValidationChecks={runAllValidationChecks}
              isRunningValidator={isRunningValidator}
            />
          }
          titleClassName="px-6 py-6"
          variant="none"
        >
          <PublishModuleValidatorResultsContainer
            blueprintValidatorInfo={blueprintValidatorInfo}
            integrationID={integrationID}
            publishModuleInfo={publishModuleInfo}
            isRunningValidator={isRunningValidator}
          />
        </Accordion>
        <hr className="m-0 text-gray-50" />
        <Accordion
          chevronSize={16}
          chevronOrientation="right"
          onChange={function noRefCheck() {}}
          defaultExpanded={!isRunningMappingTests}
          title={
            <MappingTestAccordionTitle
              integrationTestSuiteExecution={integrationTestSuiteExecution}
              hasFailedFetchingLatestExecution={hasFailedFetchingLatestExecution}
              onRunTestsForStagedIntegrationVersion={onRunTestsForStagedIntegrationVersion}
              isRunningMappingTests={isRunningMappingTests || isFetchingLatestTestSuiteExecution}
            />
          }
          titleClassName="px-6 py-6"
          variant="none"
        >
          <PublishModuleTestExecutionsContainer
            integrationID={integrationID}
            integrationTestSuiteExecution={integrationTestSuiteExecution}
            hasFailedFetchingLatestExecution={hasFailedFetchingLatestExecution}
            isRunningMappingTests={isRunningMappingTests || isFetchingLatestTestSuiteExecution}
          />
        </Accordion>
      </Card>
    </div>
  );
};

export default PublishModuleChecksCard;
