import { useState } from "react";
import {
  DiffStateField,
  DiffStateFieldTypeEnum,
  DiffStatusEnum,
  MapDiffStatusEnumToColor,
} from "../../../../models/DiffModels";
import SplitDiffFieldCell from "./SplitDiffFieldCell";
import clsx from "clsx";

interface Props {
  field: DiffStateField;
  childLevel?: number;
  isNested?: boolean;
  isParentFieldDeleted?: boolean;
}

const SplitDiffFieldRow = ({
  field,
  childLevel = 0,
  isNested = false,
  isParentFieldDeleted = false,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(
    childLevel == 0 && field.diffStatus !== DiffStatusEnum.NO_CHANGES ? true : false
  );
  const [isTruncated, setIsTruncated] = useState<boolean>(true);
  const isFieldDeleted = isParentFieldDeleted
    ? isParentFieldDeleted
    : field.diffStatus === DiffStatusEnum.DELETED;

  return (
    <div className="flex flex-col">
      <div className="flex flex-row hover:brightness-95">
        <div className="w-1/2 border-r-[0.5px] border-gray-20">
          <SplitDiffFieldCell
            isCurrent={true}
            isNested={isNested}
            childLevel={childLevel}
            field={field}
            isParentFieldDeleted={isParentFieldDeleted}
            setIsExpanded={setIsExpanded}
            isExpanded={isExpanded}
            setIsTruncated={setIsTruncated}
            isTruncated={isTruncated}
          />
        </div>
        <div className="w-1/2">
          <SplitDiffFieldCell
            isCurrent={false}
            isNested={isNested}
            childLevel={childLevel}
            field={field}
            isParentFieldDeleted={isParentFieldDeleted}
            setIsExpanded={setIsExpanded}
            isExpanded={isExpanded}
            setIsTruncated={setIsTruncated}
            isTruncated={isTruncated}
          />
        </div>
      </div>
      {(isExpanded || field.isRenderChildrenAsNested || field.isRenderAsExpanded) &&
        field.childDiffStateFields?.map((childField) => {
          return (
            <div
              className={clsx(
                MapDiffStatusEnumToColor[childField.diffStatus],
                "transition-all duration-300 ease-in-out"
              )}
            >
              <SplitDiffFieldRow
                childLevel={childLevel + 1}
                isNested={field.isRenderChildrenAsNested}
                field={childField}
                isParentFieldDeleted={isFieldDeleted}
              />
            </div>
          );
        })}
      {field.childDiffStateNestedFields?.map((childField) => {
        return (
          <div
            className={clsx(
              MapDiffStatusEnumToColor[childField.diffStatus],
              "transition-all duration-300 ease-in-out"
            )}
          >
            <SplitDiffFieldRow
              childLevel={childLevel + 1}
              isNested={true}
              field={childField}
              isParentFieldDeleted={isFieldDeleted}
            />
          </div>
        );
      })}
      {field.type === DiffStateFieldTypeEnum.HEADER && <div className="h-[0.5px] bg-gray-20"></div>}
    </div>
  );
};

export default SplitDiffFieldRow;
