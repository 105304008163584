import { useEffect } from "react";

type Props = {
  setIsMenuOpen: (val: boolean) => void;
  updateJSONSchemaValue: (updateJSONSchemaValue: string) => void;
};
const EditableJSONSchemaValueMenu = ({
  setIsMenuOpen,
  updateJSONSchemaValue,
}: Props): JSX.Element => {
  useEffect(() => {
    document.addEventListener("click", () => setIsMenuOpen(false));

    return () => {
      document.addEventListener("click", () => setIsMenuOpen(false));
    };
  }, [setIsMenuOpen]);

  return (
    <div className="card shadow" style={{ position: "absolute", zIndex: 1000 }}>
      {["string", "number", "boolean", "array", "object"].map((choice) => (
        <button
          className="dropdown-item"
          onClick={() => {
            updateJSONSchemaValue(choice);
          }}
        >
          {choice}
        </button>
      ))}
    </div>
  );
};
export default EditableJSONSchemaValueMenu;
