import { useParams } from "react-router-dom";
import { APIEndpointMethod } from "../../../models/Entities";
import { getAPIEndpointsMethodTypePath } from "../../../router/RouterUtils";
import SubLeftNavigationContainer from "../../shared/layout/SubLeftNavigationContainer";

type Props = { children: JSX.Element | JSX.Element[] };

type RouteParams = {
  integrationID: string;
};

function APIEndpointsTablePageWrapper(props: Props) {
  const { integrationID } = useParams<RouteParams>();

  return (
    <SubLeftNavigationContainer
      subtabs={[
        {
          header: true,
          label: "Filter By",
        },
        ...Object.values(APIEndpointMethod).map((method_type: string) => ({
          label: method_type,
          destination: getAPIEndpointsMethodTypePath(integrationID, method_type),
        })),
        {
          divider: true,
          label: "All",
          destination: getAPIEndpointsMethodTypePath(integrationID, "all"),
        },
      ]}
    >
      {props.children}
    </SubLeftNavigationContainer>
  );
}
// APIEndpointMethod

export default APIEndpointsTablePageWrapper;
