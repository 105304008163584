import {
  RateLimitConfigurationIntegrationBuilder,
  RateLimitTimePeriod,
  RateLimitType,
} from "../utils/Entities";
import { v4 as uuidv4 } from "uuid";

// ADDS A RATE LIMIT CONFIGURATION TO API ENDPOINTS PAGE
export function addRateLimitConfiguration(
  rateLimitConfigurations: RateLimitConfigurationIntegrationBuilder[] | undefined,
  setRateLimitConfigurations: React.Dispatch<
    React.SetStateAction<RateLimitConfigurationIntegrationBuilder[] | undefined>
  >,
  apiEndpointID: string
) {
  const newTempID = uuidv4();
  let newConfiguration: RateLimitConfigurationIntegrationBuilder = {
    temp_id: newTempID,
    rate_limit_identifier: newTempID,
    api_endpoint: apiEndpointID,
    rate_limit_type: RateLimitType.REQUEST_FREQUENCY,
    is_active: true,
    rate_limit_time_period: RateLimitTimePeriod.SECONDS,
    rate_limit_time_value: undefined,
    default_rate_limit_threshold: undefined,
    should_set_limit_max_count_via_headers: false,
    should_update_rate_limit_current_count_via_headers: false,
    should_update_rate_limit_count_left_via_headers: false,
    default_increment: 1,
  };
  if (rateLimitConfigurations) {
    setRateLimitConfigurations([...rateLimitConfigurations, newConfiguration]);
  } else {
    setRateLimitConfigurations([newConfiguration]);
  }
}

// DELETES A RATE LIMIT CONFIGURATION FROM THE API ENDPOINTS PAGE
export function deleteRateLimitConfiguration(
  rateLimitConfigurationToDelete: RateLimitConfigurationIntegrationBuilder,
  rateLimitConfigurations: RateLimitConfigurationIntegrationBuilder[] | undefined,
  setRateLimitConfigurations: React.Dispatch<
    React.SetStateAction<RateLimitConfigurationIntegrationBuilder[] | undefined>
  >,
  setRateLimitConfigurationsToDelete: React.Dispatch<
    React.SetStateAction<RateLimitConfigurationIntegrationBuilder[]>
  >
) {
  if (rateLimitConfigurations && rateLimitConfigurations.length > 0) {
    // adds config to delete list if it has an id
    if (rateLimitConfigurationToDelete.id) {
      setRateLimitConfigurationsToDelete((prev) => [...prev, rateLimitConfigurationToDelete]);
    }
    const updatedRateLimitConfigurations = rateLimitConfigurations.filter((configuration) => {
      const shouldRemoveById =
        rateLimitConfigurationToDelete.id && configuration.id === rateLimitConfigurationToDelete.id;
      const shouldRemoveByTempId =
        rateLimitConfigurationToDelete.temp_id &&
        configuration.temp_id === rateLimitConfigurationToDelete.temp_id;
      // only remove the configuration if it explicitly matches by id or temp_id
      return !shouldRemoveById && !shouldRemoveByTempId;
    });

    setRateLimitConfigurations(updatedRateLimitConfigurations);
  }
}
