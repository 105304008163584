import BinaryChoiceButtons from "../shared/BinaryChoiceButtons";
import RefreshURLAdvanced from "./RefreshURLAdvanced";
import { DateTimeFormatEnums, TokenExpiresInType } from "../../../utils/Entities";
import RefreshConfigurationTokenURLResponseCard from "./RefreshConfigurationTokenURLResponseCard";
import AccordionCardForSection from "../../../shared/AccordionCardForSection";
import LineIndent from "../shared/LineIndent";

interface RefreshURLCardProps {
  // token url refresh response states
  doesResponseIncludeExpiresInParam: boolean | undefined;
  setDoesResponseIncludeExpiresInParam: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  tokenExpiresInType: TokenExpiresInType | undefined;
  setTokenExpiresInType: React.Dispatch<React.SetStateAction<TokenExpiresInType | undefined>>;
  dateTimeFormat: DateTimeFormatEnums | undefined;
  setDateTimeFormat: React.Dispatch<React.SetStateAction<DateTimeFormatEnums | undefined>>;
  oAuthResponseTokenLifespanInSeconds: number | undefined;
  setOAuthResponseTokenLifespanInSeconds: React.Dispatch<React.SetStateAction<number | undefined>>;
  oAuthResponseCreatedAtKeyPath: string[] | null;
  setOAuthResponseCreatedAtKeyPath: React.Dispatch<React.SetStateAction<string[] | null>>;

  // refresh url response states
  shouldRefreshAfterInitialLink: boolean | undefined;
  setShouldRefreshAfterInitialLink: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  shouldUseTokenURLForRefresh: boolean | undefined;
  setShouldUseTokenURLForRefresh: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  doesRefreshIncludeExpiresIn: boolean | undefined;
  setDoesRefreshIncludeExpiresIn: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  oAuthRefreshURL: string | undefined;
  setOAuthRefreshURL: React.Dispatch<React.SetStateAction<string | undefined>>;
  oAuthRefreshTokenLifespanInSeconds: number | undefined;
  setOAuthRefreshTokenLifespanInSeconds: React.Dispatch<React.SetStateAction<number | undefined>>;
  refreshURLFieldsToExclude: string[] | null;
  setRefreshURLFieldsToExclude: React.Dispatch<React.SetStateAction<string[] | null>>;

  // permissioning
  hasPermissionToEdit: boolean;
}

const RefreshConfigurationCard = ({
  // token url refresh response states
  doesResponseIncludeExpiresInParam,
  setDoesResponseIncludeExpiresInParam,
  tokenExpiresInType,
  setTokenExpiresInType,
  dateTimeFormat,
  setDateTimeFormat,
  oAuthResponseCreatedAtKeyPath,
  setOAuthResponseCreatedAtKeyPath,
  oAuthResponseTokenLifespanInSeconds,
  setOAuthResponseTokenLifespanInSeconds,

  // refresh url configuration states
  shouldRefreshAfterInitialLink,
  setShouldRefreshAfterInitialLink,
  shouldUseTokenURLForRefresh,
  setShouldUseTokenURLForRefresh,
  doesRefreshIncludeExpiresIn,
  setDoesRefreshIncludeExpiresIn,
  oAuthRefreshURL,
  setOAuthRefreshURL,
  oAuthRefreshTokenLifespanInSeconds,
  setOAuthRefreshTokenLifespanInSeconds,
  refreshURLFieldsToExclude,
  setRefreshURLFieldsToExclude,

  // permissioning
  hasPermissionToEdit,
}: RefreshURLCardProps) => {
  return (
    <AccordionCardForSection
      dataTestID="accordion-auth-config-refresh-configuration"
      title="Refresh configuration"
      defaultExpanded
      hasPermissionToEdit={hasPermissionToEdit}
    >
      <BinaryChoiceButtons
        dataTestID="field-auth-config-refresh-should-refresh-periodically"
        title="Does the access token need to be refreshed periodically?"
        binaryChoice={shouldRefreshAfterInitialLink}
        setBinaryChoice={setShouldRefreshAfterInitialLink}
      />
      {shouldRefreshAfterInitialLink && (
        <LineIndent className="mt-6">
          <RefreshConfigurationTokenURLResponseCard
            doesResponseIncludeExpiresInParam={doesResponseIncludeExpiresInParam}
            setDoesResponseIncludeExpiresInParam={setDoesResponseIncludeExpiresInParam}
            tokenExpiresInType={tokenExpiresInType}
            setTokenExpiresInType={setTokenExpiresInType}
            oAuthResponseCreatedAtKeyPath={oAuthResponseCreatedAtKeyPath}
            setOAuthResponseCreatedAtKeyPath={setOAuthResponseCreatedAtKeyPath}
            dateTimeFormat={dateTimeFormat}
            setDateTimeFormat={setDateTimeFormat}
            oAuthResponseTokenLifespanInSeconds={oAuthResponseTokenLifespanInSeconds}
            setOAuthResponseTokenLifespanInSeconds={setOAuthResponseTokenLifespanInSeconds}
          />
          <RefreshURLAdvanced
            shouldUseTokenURLForRefresh={shouldUseTokenURLForRefresh}
            setShouldUseTokenURLForRefresh={setShouldUseTokenURLForRefresh}
            doesRefreshIncludeExpiresIn={doesRefreshIncludeExpiresIn}
            setDoesRefreshIncludeExpiresIn={setDoesRefreshIncludeExpiresIn}
            oAuthRefreshURL={oAuthRefreshURL}
            setOAuthRefreshURL={setOAuthRefreshURL}
            oAuthRefreshTokenLifespanInSeconds={oAuthRefreshTokenLifespanInSeconds}
            setOAuthRefreshTokenLifespanInSeconds={setOAuthRefreshTokenLifespanInSeconds}
            refreshURLFieldsToExclude={refreshURLFieldsToExclude}
            setRefreshURLFieldsToExclude={setRefreshURLFieldsToExclude}
            hasPermissionToEdit={hasPermissionToEdit}
          />
        </LineIndent>
      )}
    </AccordionCardForSection>
  );
};

export default RefreshConfigurationCard;
