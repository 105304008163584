import { Text } from "@merge-api/merge-javascript-shared";
import { Asterisk, ChevronLeft } from "lucide-react";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { ComponentState } from "../../versioned-components/types";
import VersionedComponentPublishStateBadge from "./VersionedComponentPublishStateBadge";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { useHistory } from "react-router-dom";
import { getIntegrationBuilderSingleAuthConfigPathForId } from "../../../router/RouterUtils";

interface HeaderProps {
  title: string;
  integrationId?: string;
  authConfigName?: string;
  authConfigId?: string;
  button?: ReactNode;
  secondaryButton?: ReactNode;
  navButtonLink?: string;
  navButtonText?: string;
  showRequired?: boolean;
  componentState?: ComponentState;
}

const IntegrationBuilderHeader = ({
  title,
  integrationId,
  authConfigName,
  authConfigId,
  navButtonLink,
  navButtonText,
  button,
  secondaryButton,
  componentState,
  showRequired = false,
}: HeaderProps) => {
  const history = useHistory();
  const navigateToAuthConfig = (integrationId: string, authConfigId: string) => {
    history.push({
      pathname: getIntegrationBuilderSingleAuthConfigPathForId(integrationId, authConfigId),
    });
  };

  return (
    <div className={showRequired ? "mb-3" : "mb-6"}>
      {navButtonLink && navButtonText && (
        <div className="flex items-center text-blue-40 hover:text-blue-60">
          <Link to={navButtonLink} className="flex flex-row items-center">
            <ChevronLeft size={12} />
            <div className="text-sm font-semibold ml-1">{navButtonText}</div>
          </Link>
        </div>
      )}
      <div className="w-full flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <div className="flex flex-column">
            <h2 className="whitespace-nowrap mr-2">{title}</h2>
            {integrationId && authConfigId && authConfigName && (
              <Button
                size="md"
                variant={ButtonVariant.TextBlue}
                onClick={() => navigateToAuthConfig(integrationId, authConfigId)}
              >
                {authConfigName}
              </Button>
            )}
          </div>
          <div>
            {componentState && (
              <VersionedComponentPublishStateBadge className="ml-1" publishState={componentState} />
            )}
          </div>
        </div>
        <div className="flex flex-row space-x-4 items-center">
          {secondaryButton}
          {button}
        </div>
      </div>
      <hr className="text-gray-50 h-[0.5px] mt-6 mb-0" />
      {showRequired && (
        <div className="w-full flex flex-row items-center space-x-1 mt-3">
          <Asterisk size={12} className="text-red-50" />
          <Text variant="md" className="text-red-50">
            Required
          </Text>
        </div>
      )}
    </div>
  );
};

export default IntegrationBuilderHeader;
