import { useState } from "react";
import { Button } from "react-bootstrap";
import { Integration } from "../../../models/Entities";
import CreateBlueprintModal from "./CreateBlueprintModal";

type Props = { integrationMeta: Integration };

const BlueprintsTableCreateButton = ({ integrationMeta }: Props) => {
  const [isModalShown, setIsModalShown] = useState(false);

  return (
    <div className="float-right">
      <Button variant="white" size="sm" onClick={() => setIsModalShown(true)}>
        <i className="fe fe-plus mr-1.5" />
        Create blueprint
      </Button>
      <CreateBlueprintModal
        categories={integrationMeta.categories}
        integrationMeta={integrationMeta}
        isShown={isModalShown}
        onHide={() => setIsModalShown(false)}
      />
    </div>
  );
};

export default BlueprintsTableCreateButton;
