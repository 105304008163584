import React, { useEffect } from "react";
import { ResponseBodyAdditionalParsing } from "../../../../../models/Entities";
import FormField from "../../../../blueprint-editor/right-panel/FormField";
import ResponseBodyAdditionalParsingForm from "../../../../integrations/api-endpoints/ResponseBodyAdditionalParsingForm";
import AccordionCard from "../../../shared/AccordionCard";
import KeyPathFieldHeader from "../../../shared/KeyPathFieldHeader";
import { APIEndpointBodyParsingConfiguration } from "../../../utils/Entities";
import { parseParsingRulesToBodyParsingConfigurations } from "../../../utils/helpers";
import {
  KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER,
  KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER,
} from "../../../pagination-configuration-setup/constants";

interface AdvancedResponseConfigurationSetupOptionsProps {
  allowedErrorCodes: string[] | null;
  setAllowedErrorCodes: React.Dispatch<React.SetStateAction<string[] | null>>;
  setAdditionalResponseBodyParsing: React.Dispatch<APIEndpointBodyParsingConfiguration[] | null>;
  responseBodyParsingRules: ResponseBodyAdditionalParsing;
  setResponseBodyParsingRules: (data: ResponseBodyAdditionalParsing) => void;
  syncCursorKeyPath: string[] | null;
  setSyncCursorKeyPath: (value: string[] | null) => void;
}

const AdvancedResponseConfigurationSetupOptions = React.memo(
  ({
    allowedErrorCodes,
    setAllowedErrorCodes,
    responseBodyParsingRules,
    setAdditionalResponseBodyParsing,
    setResponseBodyParsingRules,
    syncCursorKeyPath,
    setSyncCursorKeyPath,
  }: AdvancedResponseConfigurationSetupOptionsProps) => {
    // When the parsing rules change on the frontend, we want to update the additionalResponseBodyParsing state
    //   that gets passed as part of our API Endpoint pydantic model.
    useEffect(() => {
      if (responseBodyParsingRules) {
        setAdditionalResponseBodyParsing(
          parseParsingRulesToBodyParsingConfigurations(responseBodyParsingRules)
        );
      } else {
        setAdditionalResponseBodyParsing(null);
      }
    }, [responseBodyParsingRules]);

    return (
      <AccordionCard
        dataTestID="accordion-advanced-response-configuration-options"
        chevronSize={12}
        title="Advanced"
        variant="outline"
        nested
        titleClassName="px-5 py-3"
        badgeContents={3}
      >
        <KeyPathFieldHeader
          dataTestID="field-api-endpoint-allowed-error-codes"
          title="Allowed error codes"
          subtitle="To allow certain error codes in the API response, type your error code followed by pressing enter. You can specify multiple error codes."
          placeholder={
            allowedErrorCodes?.length ? "Add error code..." : "Example: 403 [Enter] 401 [Enter]"
          }
          keyPath={allowedErrorCodes}
          onKeyPathChange={setAllowedErrorCodes}
          hasSource={false}
          showArrow={false}
        />
        <FormField
          dataTestID="field-api-endpoint-response-body-additional-parsing"
          className="mt-6"
          title="Response body additional parsing"
          subtitle={
            "Optionally add parsing to run on the response body after initial parsing, specifying path + parser. Remember to use a fully parsed example response as input to json schema creation for Return Schema."
          }
        >
          <ResponseBodyAdditionalParsingForm
            responseBodyAdditionalParsing={responseBodyParsingRules}
            setResponseBodyAdditionalParsing={setResponseBodyParsingRules}
            showTitle={false}
          />
        </FormField>
        <KeyPathFieldHeader
          dataTestID="field-api-endpoint-sync-cursor-key-path"
          title="Sync cursor key path"
          className="mt-3" // the form field above has a bottom margin of 3 so we only need to add a top margin of 3 here
          subtitle="Some integrations such as Ashby implement pagination through a synchronization cursor in the response. You can specify the key path to the cursor here."
          placeholder={
            syncCursorKeyPath?.length
              ? KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER
              : KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER
          }
          keyPath={syncCursorKeyPath}
          onKeyPathChange={setSyncCursorKeyPath}
          hasSource={false}
        />
      </AccordionCard>
    );
  }
);

export default AdvancedResponseConfigurationSetupOptions;
