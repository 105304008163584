import clsx from "clsx";
import { ClientCredentialSource } from "../../../../integrations/auth-config/AuthConfigShared";
import MultiSelectHeader from "../../../shared/MultiSelectHeader";

interface ClientCredentialSourceButtonsProps {
  clientCredentialSourceChoice: ClientCredentialSource | undefined;
  setClientCredentialSourceChoice: React.Dispatch<
    React.SetStateAction<ClientCredentialSource | undefined>
  >;
  className?: string;
}

const ClientCredentialSourceButtons = ({
  clientCredentialSourceChoice,
  setClientCredentialSourceChoice,
  className,
}: ClientCredentialSourceButtonsProps) => {
  const handleButtonClick = (value: ClientCredentialSource) => {
    setClientCredentialSourceChoice(value);
  };

  const options = [
    {
      value: ClientCredentialSource.merge,
      text: "Merge",
      disabled: false,
      selected: clientCredentialSourceChoice === ClientCredentialSource.merge,
      onClick: handleButtonClick,
    },
    {
      value: ClientCredentialSource.customer,
      text: "Customer",
      disabled: false,
      selected: clientCredentialSourceChoice === ClientCredentialSource.customer,
      onClick: handleButtonClick,
    },
  ];
  return (
    <MultiSelectHeader
      dataTestID="field-auth-config-client-credential-source"
      title="Client credential source"
      subtitle="The owner of the client credentials."
      learnMoreText="If the grant type 'Code' is selected, an integration with 'Customer' selected as the client credential source will pull the client ID and secret from configuration parameters inputted by the customer in https://app.merge.dev/integrations, and will navigate to Merge's customer's OAuth flow. This is used in cases where Merge cannot set up a direct client connection with the API provider or the customer has a partnership with the third-party integration. Selecting 'Merge' as the client credential source will pull the client ID and secret from the inputs below, and will use Merge's client connection with the provider. You should use Merge's OAuth callback URK as the redirect URL when setting up the client connection. For integrations that require grant type 'Client credentials', the integration will always default to use 'Merge' as the source to fetch organization credentials."
      options={options}
      className={clsx("w-full", className)}
    />
  );
};

export default ClientCredentialSourceButtons;
