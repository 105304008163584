import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CommonModelBadge } from "../../shared/MergeBadges";
import Dropdown from "../../blueprint-editor/right-panel/Dropdown";
import { APICategory, BlueprintMeta, Integration } from "../../../models/Entities";
import { fetchWithAuth } from "../../../api-client/api_client";
import { Card, Col, Row } from "react-bootstrap";
import {
  BlueprintOperationType,
  BlueprintTriggerType,
  BlueprintVersionPublishState,
  BlueprintStatus,
  BlueprintVersion,
} from "../../../models/Blueprints";

import { showErrorToast, showSuccessToast } from "../../shared/Toasts";
import BlueprintsTableCreateButton from "./BlueprintsTableCreateButton";
import {
  fetchBlueprintVersions,
  changeBlueprintStatus,
} from "../../blueprint-editor/utils/BlueprintEditorAPIClient";
import ArchiveConfirmationModal from "../../shared/ArchiveConfirmationModal";
import MergeText from "../../shared/text/MergeText";
import BlueprintTableCard from "./BlueprintTableCard";
import classNames from "classnames";
import useDocumentTitle from "../../shared/useDocumentTitle";

enum SortType {
  ALPHA = "alpha",
  REVERSE_ALPHA = "reverse-alpha",
  PUBLISHED_FIRST = "published",
  UNPUBLISHED_FIRST = "unpublished",
  MODIFIED = "modified",
}

type RouteParams = {
  integrationID: string;
  operationType: string;
};

type Props = { integrationMeta: Integration };

const BlueprintsTable = ({ integrationMeta }: Props) => {
  const { integrationID, operationType } = useParams<RouteParams>();
  const { categories } = integrationMeta;
  const [blueprints, setBlueprints] = useState<BlueprintMeta[] | undefined>();
  const [unmappedCommonModels, setUnmappedCommonModels] = useState<string[] | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [
    isShowingConfirmArchiveBlueprintModal,
    setIsShowingConfirmArchiveBlueprintModal,
  ] = useState<boolean>(false);
  const [selectedBlueprintName, setSelectedBlueprintName] = useState<string | undefined>();
  const [selectedBlueprintVersionID, setSelectedBlueprintVersionID] = useState<
    string | undefined
  >();
  const [sortBy, setSortBy] = useState<SortType>(SortType.ALPHA);
  const [selectedCategory, setSelectedCategory] = useState<APICategory | null>(null);

  useDocumentTitle(`${integrationMeta.name} - Blueprints`, [integrationMeta.name]);

  const fetchBlueprints = useCallback(() => {
    fetchWithAuth({
      path: `/integrations/${integrationID}/blueprints`,
      method: "GET",
      onResponse: (data) => {
        setBlueprints(data.blueprints);
        setUnmappedCommonModels(data.unmapped_models);
      },
    });
  }, [integrationID]);

  useEffect(() => {
    fetchBlueprints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationID]);

  const fetchBlueprintVersion = (blueprintID: string) => {
    fetchBlueprintVersions({
      blueprintID: blueprintID,
      onSuccess: (versions: BlueprintVersion[]) => {
        setSelectedBlueprintVersionID(versions[0]["id"]);
      },
    });
  };

  const archiveBlueprint = (versionID: string) => {
    setIsLoading(true);
    changeBlueprintStatus({
      versionID: versionID,
      status: BlueprintStatus.Archived,
      onSuccess: () => {
        showSuccessToast("Successfully archived blueprint!");
        setIsShowingConfirmArchiveBlueprintModal(false);
        fetchBlueprints();
        setIsLoading(false);
      },
      onError: () => {
        showErrorToast("Failed to archive blueprint.");
        setIsLoading(false);
      },
    });
  };

  const BlueprintCategoriesTabs = ({ categories }: { categories?: Array<string> }) => (
    <ul className="navbar-nav sub-navbar-nav flex-row">
      {
        <li key={"all-tab"} className="nav-item">
          <div
            className={classNames("nav-link", !selectedCategory && "disabled")}
            onClick={() => setSelectedCategory(null)}
          >
            All
          </div>
        </li>
      }
      {categories &&
        categories.map((category) => {
          return (
            <>
              <li key={category} className="nav-item">
                {category && (
                  <div
                    className={classNames("nav-link", selectedCategory == category && "disabled")}
                    onClick={() => setSelectedCategory(category as APICategory)}
                  >
                    {category}
                  </div>
                )}
              </li>
            </>
          );
        })}
    </ul>
  );

  const filterBlueprintByCategory = (blueprint: BlueprintMeta) => {
    const bpMappedModel = blueprint?.mapping_statuses?.[0]?.common_model;
    const blueprintCategory = bpMappedModel?.split(".")[0];

    return !selectedCategory || selectedCategory == blueprintCategory;
  };

  return (
    <>
      <ArchiveConfirmationModal
        selectedObjectType="Blueprint"
        show={isShowingConfirmArchiveBlueprintModal}
        onHide={() => setIsShowingConfirmArchiveBlueprintModal(false)}
        isLoading={isLoading}
        onConfirm={() => {
          if (selectedBlueprintVersionID) {
            archiveBlueprint(selectedBlueprintVersionID);
          }
        }}
        objectName={selectedBlueprintName}
      />
      <Row className="mb-6">
        <Col>
          <MergeText isBold size="20px">
            Blueprints
          </MergeText>
        </Col>
        <Col className="col-3">
          <Dropdown
            placeholder="Select Sort Type"
            currentValue={sortBy}
            onChange={(e) => {
              setSortBy(e.target.value);
            }}
            choices={[
              { name: "A → Z", id: SortType.ALPHA },
              { name: "Z → A", id: SortType.REVERSE_ALPHA },
              { name: "Published First", id: SortType.PUBLISHED_FIRST },
              { name: "Unpublished First", id: SortType.UNPUBLISHED_FIRST },
              { name: "Last Modified", id: SortType.MODIFIED },
            ]}
            className="custom-select custom-select-sm float-right w-50"
          />
        </Col>
        <Col className="col-auto">
          <BlueprintsTableCreateButton integrationMeta={integrationMeta} />
        </Col>
      </Row>
      {categories.length > 1 && <BlueprintCategoriesTabs categories={categories} />}
      {blueprints
        ?.filter(
          (blueprint: BlueprintMeta) =>
            operationType === "all" ||
            (operationType === "archived" && blueprint.status === BlueprintStatus.Archived) ||
            (blueprint.status !== BlueprintStatus.Archived &&
              blueprint.operation_type === operationType.toUpperCase() &&
              blueprint.trigger_type !== BlueprintTriggerType.WEBHOOK &&
              blueprint.trigger_type !== BlueprintTriggerType.REPORT_RECEIVED) ||
            (operationType === "webhook" &&
              blueprint.trigger_type === BlueprintTriggerType.WEBHOOK) ||
            (operationType === "report-received" &&
              blueprint.trigger_type === BlueprintTriggerType.REPORT_RECEIVED) ||
            (operationType === "published" &&
              blueprint.publish_state === BlueprintVersionPublishState.Published) ||
            (operationType === "staged" &&
              blueprint.publish_state === BlueprintVersionPublishState.Staged) ||
            (operationType === "draft" &&
              blueprint.publish_state === BlueprintVersionPublishState.Draft) ||
            (operationType === "unpublished" &&
              blueprint.publish_state === BlueprintVersionPublishState.Unpublished)
        )
        .filter((blueprint: BlueprintMeta) => filterBlueprintByCategory(blueprint))
        .sort(
          (sortBy === SortType.MODIFIED &&
            ((a, b) =>
              (a.modified_at || a.created_at) > (b.modified_at || b.created_at) ? -1 : 1)) ||
            (sortBy === SortType.PUBLISHED_FIRST &&
              ((a, _) => (a.publish_state === BlueprintVersionPublishState.Published ? -1 : 1))) ||
            (sortBy === SortType.UNPUBLISHED_FIRST &&
              ((a, _) =>
                a.publish_state === BlueprintVersionPublishState.Unpublished ? -1 : 1)) ||
            (sortBy === SortType.ALPHA &&
              ((a, b) => ((a.human_name || a.name) > (b.human_name || b.name) ? 1 : -1))) ||
            ((a, b) => ((a.human_name || a.name) > (b.human_name || b.name) ? -1 : 1))
        )
        .map((blueprint: BlueprintMeta) => (
          <BlueprintTableCard
            blueprint={blueprint}
            integrationID={integrationID}
            setSelectedBlueprintName={setSelectedBlueprintName}
            setIsShowingConfirmArchiveBlueprintModal={setIsShowingConfirmArchiveBlueprintModal}
            fetchBlueprintVersion={fetchBlueprintVersion}
          />
        ))}
      <UnmappedCommonModelsRow
        operationType={operationType}
        unmappedCommonModels={unmappedCommonModels}
      />
    </>
  );
};

type UnmappedCommonModelsRowProps = {
  operationType: string;
  unmappedCommonModels: string[] | undefined;
};

const UnmappedCommonModelsRow = ({
  operationType,
  unmappedCommonModels,
}: UnmappedCommonModelsRowProps) => {
  return (
    <>
      {operationType.toUpperCase() == BlueprintOperationType.FETCH && (
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <MergeText isBold size="16px">
                  Unmapped Common Models
                </MergeText>
              </Col>
            </Row>
            <Row>
              <Col>
                {unmappedCommonModels?.map((commonModel: string) => (
                  <CommonModelBadge commonModel={commonModel} />
                ))}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default BlueprintsTable;
