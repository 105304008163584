import {
  APICategory,
  AdminAPICategory,
  UnreleasedAPICategory,
} from "@merge-api/merge-javascript-shared";
import IntegrationCategoryCard from "./IntegrationCategoryCard";
import { IntegrationForIntegrationBuilder } from "../../utils/Entities";

interface IntegrationCategorySelectionProps {
  requestBodyForIntegration: IntegrationForIntegrationBuilder | undefined;
  setRequestBodyForIntegration: React.Dispatch<
    React.SetStateAction<IntegrationForIntegrationBuilder | undefined>
  >;
}

const IntegrationCategorySelection = ({
  requestBodyForIntegration,
  setRequestBodyForIntegration,
}: IntegrationCategorySelectionProps) => {
  const selectedCategories = requestBodyForIntegration?.categories
    ? requestBodyForIntegration.categories
    : [];

  // combine all enum values into an array
  const allCategories: AdminAPICategory[] = [
    ...Object.values(APICategory),
    ...Object.values(UnreleasedAPICategory),
  ];

  const handleCategoryClick = (category: AdminAPICategory) => {
    if (selectedCategories.includes(category)) {
      setRequestBodyForIntegration((body) => {
        return { ...body, categories: selectedCategories.filter((c) => c !== category) };
      });
    } else {
      setRequestBodyForIntegration((body) => {
        return { ...body, categories: [...selectedCategories, category] };
      });
    }
  };

  return (
    <div className="grid grid-cols-[repeat(auto-fit,_minmax(297px,_1fr))] gap-4">
      {allCategories.map((category) => (
        <IntegrationCategoryCard
          key={category}
          category={category}
          selected={selectedCategories.includes(category)}
          onClick={() => handleCategoryClick(category)}
        />
      ))}
    </div>
  );
};

export default IntegrationCategorySelection;
