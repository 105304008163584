import {
  MappingTestSuiteEndpointCollection,
  MappingTestSuiteEndpointCollectionInfo,
} from "../../../models/MappingTests";

export const getEndpointCollectionTitle = (
  endpointCollection: MappingTestSuiteEndpointCollectionInfo | MappingTestSuiteEndpointCollection
): string =>
  endpointCollection?.webhook_receiver_event_type_id !== null
    ? `Webhook - ${endpointCollection?.webhook_event ?? ""}`
    : !endpointCollection?.operation_type
    ? "Fetch Test"
    : endpointCollection?.operation_type === "WEBHOOK_SETUP"
    ? `${endpointCollection.blueprint_name}`
    : `${endpointCollection?.operation_type} - ${endpointCollection?.common_model_name}`;
