import { Chips } from "@merge-api/merge-javascript-shared";
import HeaderBase from "./HeaderBase";
import useIntegrationBuilderContext from "../context/useIntegrationBuilderContext";
import { Text } from "@merge-api/merge-javascript-shared";

interface MultiSelectHeaderProps {
  title: string;
  subtitle?: string;
  options: any[];
  className?: string;
  learnMoreText?: string;
  required?: boolean;
  clearOptions?: null | (() => void);
  dataTestID?: string;
}

const MultiSelectHeader = ({
  title,
  subtitle,
  options,
  className,
  learnMoreText,
  required = false,
  clearOptions,
  dataTestID,
}: MultiSelectHeaderProps) => {
  const { hasPermissionToEdit } = useIntegrationBuilderContext();

  return (
    <div className={className}>
      <HeaderBase
        title={title}
        subtitle={subtitle}
        learnMoreText={learnMoreText}
        required={required}
        dataTestID={dataTestID}
      />
      <Chips
        options={options.map((option) => {
          return { ...option, disabled: !hasPermissionToEdit };
        })}
      />
      {clearOptions && (
        <div className="mt-2 cursor-pointer" onClick={clearOptions}>
          <Text
            variant="title-sm"
            className="text-blue-40 hover:text-blue-20 transition duration-150 ease-in-out"
          >
            Clear selection
          </Text>
        </div>
      )}
    </div>
  );
};

export default MultiSelectHeader;
