import { useEffect, useState } from "react";
import { Redirect, Route, match, useParams } from "react-router-dom";
import ScraperEditor from "./ScraperEditor";
import { ScraperVersion } from "./types";
import { fetchScraperVersion } from "./utils/ScraperAPIClient";
import { SCRAPER_VERSION_EDITOR_PATH, getScraperEditorPath } from "../../router/RouterUtils";

type RouteParams = {
  integrationID: string;
  scraperID: string;
  scraperVersionID: string;
};

type Props = {
  match: match;
};

const ScraperEditorRoot = (_: Props) => {
  const { integrationID, scraperID, scraperVersionID } = useParams<RouteParams>();
  const [scraperVersion, setScraperVersion] = useState<ScraperVersion | undefined>();

  useEffect(() => {
    if (scraperVersion?.version_id !== scraperVersionID) {
      fetchScraperVersion({
        scraperID,
        scraperVersionID,
        onSuccess: setScraperVersion,
      });
    }
  }, [scraperID, scraperVersionID]);

  return scraperVersion ? (
    <>
      {scraperVersion.version_id !== scraperVersionID ? (
        <Route exact path={SCRAPER_VERSION_EDITOR_PATH}>
          <Redirect
            to={getScraperEditorPath(integrationID, scraperID, scraperVersion.version_id)}
          />
        </Route>
      ) : (
        <ScraperEditor
          integrationID={integrationID}
          scraper={scraperVersion}
          setScraper={setScraperVersion}
        />
      )}
    </>
  ) : null;
};

export default ScraperEditorRoot;
