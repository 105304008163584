import { useState } from "react";
import CreateMappingTestCaseModal from "./CreateMappingTestCaseModal";

type Props = { integrationID: string; endpointCollectionID: string; categories: Array<string> };

const MappingTestCaseCreateButton = ({
  integrationID,
  endpointCollectionID,
  categories,
}: Props) => {
  const [isModalShown, setIsModalShown] = useState(false);

  return (
    <>
      <button className="btn btn-primary " onClick={() => setIsModalShown(true)}>
        <i className="fe fe-plus mr-1.5" />
        Add Test Case
      </button>
      <CreateMappingTestCaseModal
        integrationID={integrationID}
        endpointCollectionID={endpointCollectionID}
        categories={categories}
        isShown={isModalShown}
        onHide={() => setIsModalShown(false)}
      />
    </>
  );
};

export default MappingTestCaseCreateButton;
