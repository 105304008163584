import { Badge, Button, Checkbox, Text } from "@merge-api/merge-javascript-shared";
import { MappingTestExecution } from "../../../models/MappingTests";
import useMappingTestContext from "../context/useMappingTestContext";
import { Boxes } from "lucide-react";

interface Props {
  testExecutionResults: MappingTestExecution | undefined;
  runMappingTestWrapper: () => void;
  setIsShowingRightPanel: React.Dispatch<React.SetStateAction<boolean>>;
  isRunningMappingTest: boolean;
  isTestRunningDisabled: boolean;
  isExecutionFinished: boolean;
}

const MappingTestV2CentralPanelHeader = ({
  testExecutionResults,
  runMappingTestWrapper,
  setIsShowingRightPanel,
  isRunningMappingTest,
  isTestRunningDisabled,
  isExecutionFinished,
}: Props) => {
  const { isDebugMode, setIsDebugMode } = useMappingTestContext();

  let isSuccess =
    testExecutionResults?.result === "SUCCESS" &&
    testExecutionResults?.block_executions?.every(
      (execution) =>
        execution.result === "SUCCESS" &&
        execution.exit_data?.every(
          (blueprintExecution) => blueprintExecution.blueprint_meta.blueprint_run_exit_code === 200
        )
    );

  return (
    <div className="flex flex-column m-0">
      <div className="flex flex-row justify-between p-4 bg-white">
        <div className="flex flex-row justify-start items-center space-x-2">
          <Boxes size="16px" />
          <Text variant="h5" className="text-black">
            Mapping Test Blocks
          </Text>
          {isExecutionFinished && (
            <Badge color={isSuccess ? "teal" : "red"}>{isSuccess ? "Success" : "Failed"}</Badge>
          )}
        </div>
        <div className="flex flex-row space-x-4">
          <Checkbox label="Debug mode" onChange={() => setIsDebugMode(!isDebugMode)} />
          <Button
            className="btn-primary"
            loading={isRunningMappingTest}
            disabled={isRunningMappingTest || isTestRunningDisabled}
            onClick={() => {
              runMappingTestWrapper();
              setIsShowingRightPanel(false);
            }}
          >
            Run test
          </Button>
        </div>
      </div>
      <hr className="text-gray-50 h-[0.5px] m-0" />
    </div>
  );
};

export default MappingTestV2CentralPanelHeader;
