import { useHistory } from "react-router-dom";
import { getIntegrationBuilderSingleAuthConfigPathForId } from "../../../../../router/RouterUtils";
import AuthTypeCell from "./AuthTypeCell";
import NameCell from "./NameCell";
import { AuthConfigIntegrationBuilder } from "../../../utils/Entities";

type AuthenticationConfigurationTableRowProps = {
  authConfig: AuthConfigIntegrationBuilder;
  integrationID: string;
};

const AuthenticationConfigurationTableRow = ({
  authConfig,
  integrationID,
}: AuthenticationConfigurationTableRowProps) => {
  // hooks
  const history = useHistory();

  // event handlers
  const onAuthConfigClick = () => {
    history.push({
      pathname: getIntegrationBuilderSingleAuthConfigPathForId(integrationID, authConfig.id),
    });
  };

  return (
    <tr onClick={onAuthConfigClick} className="cursor-pointer">
      <NameCell authConfig={authConfig} />
      <AuthTypeCell authConfig={authConfig} />
    </tr>
  );
};

export default AuthenticationConfigurationTableRow;
