import LineIndent from "../shared/LineIndent";
import BinaryChoiceButtons from "../shared/BinaryChoiceButtons";

interface SFTPConfigurationProps {
  shouldEndUserProvidePublicKey: boolean | undefined;
  setShouldEndUserProvidePublicKey: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  shouldEndUserProvidePgpPublicKey: boolean | undefined;
  setShouldEndUserProvidePgpPublicKey: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  isManualUploadOnlySftp: boolean | undefined;
  setIsManualUploadOnlySftp: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const SFTPConfiguration = ({
  shouldEndUserProvidePublicKey,
  setShouldEndUserProvidePublicKey,
  shouldEndUserProvidePgpPublicKey,
  setShouldEndUserProvidePgpPublicKey,
  isManualUploadOnlySftp,
  setIsManualUploadOnlySftp,
}: SFTPConfigurationProps) => {
  return (
    <LineIndent className="mt-6">
      <BinaryChoiceButtons
        dataTestID="field-auth-config-should-end-user-provide-public-key"
        title="Should the end user provide a public key?"
        binaryChoice={shouldEndUserProvidePublicKey}
        setBinaryChoice={setShouldEndUserProvidePublicKey}
        required
      />
      <BinaryChoiceButtons
        className="mt-6"
        dataTestID="field-auth-config-should-end-user-provide-pgp-public-key"
        title="Should the end user provide a pgp public key?"
        binaryChoice={shouldEndUserProvidePgpPublicKey}
        setBinaryChoice={setShouldEndUserProvidePgpPublicKey}
        required
      />
      <BinaryChoiceButtons
        className="mt-6"
        dataTestID="field-auth-config-is-manual-upload-only-sftp"
        title="Is the auth config only for manual CSV upload?"
        binaryChoice={isManualUploadOnlySftp}
        setBinaryChoice={setIsManualUploadOnlySftp}
        required
      />
    </LineIndent>
  );
};

export default SFTPConfiguration;
