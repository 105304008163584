import { Alert, Text, Link } from "@merge-api/merge-javascript-shared";
import { Info } from "lucide-react";
import {
  navigateToIntegrationBuilderAPIEndpointTable,
  navigateToIntegrationBuilderRateLimitConfigurationTable,
} from "../../../../../router/RouterUtils";
import { useHistory } from "react-router-dom";

interface RateLimitExplainAlertProps {
  className?: string;
  integrationID: string;
  isOnAPIEndpointsPage?: boolean;
}
const RateLimitExplainAlert = ({
  className,
  integrationID,
  isOnAPIEndpointsPage = false,
}: RateLimitExplainAlertProps) => {
  //hooks
  const history = useHistory();

  return (
    <Alert
      title="What is a rate limit configuration?"
      color="gray"
      icon={<Info size={16} />}
      className={className}
    >
      {isOnAPIEndpointsPage ? (
        <Text>
          A rate limit configuration sets integration-wide request limits to the third-party
          platform, ensuring efficient operations and preventing server overload. It manages request
          frequency, data volume, and session counts. Adding configurations here will apply in
          addition to any{" "}
          <Link
            onClick={() => {
              navigateToIntegrationBuilderRateLimitConfigurationTable(history, integrationID);
            }}
            className="cursor-pointer text-blue hover:text-blue-60"
            as="span"
          >
            integration-wide rate limit configurations
          </Link>{" "}
          for this API endpoint.
        </Text>
      ) : (
        <Text>
          A rate limit configuration sets integration-wide request limits to the third-party
          platform, ensuring efficient operations and preventing server overload. It manages request
          frequency, data volume, and session counts. Rate limits added here will be applied to all
          API endpoints for this integration. For individual API endpoint-specific limits,
          configurations can be applied in the{" "}
          <Link
            onClick={() => {
              navigateToIntegrationBuilderAPIEndpointTable(history, integrationID);
            }}
            className="cursor-pointer text-blue hover:text-blue-60"
            as="span"
          >
            API endpoints
          </Link>{" "}
          section of the builder.
        </Text>
      )}
    </Alert>
  );
};

export default RateLimitExplainAlert;
