import {
  MappingTestSuiteEndpointCollection,
  MappingTestSuiteEndpointCollectionInfo,
} from "../../../models/MappingTests";
import {
  navigateToEndpointCollection,
  navigateToMappingTestEditor,
} from "../../../router/RouterUtils";
import { useHistory } from "react-router-dom";
import { fetchWithAuth } from "../../../api-client/api_client";
import { showErrorToast } from "../../shared/Toasts";
import EndpointCollectionCardBase from "./EndpointCollectionCardBase";
import { getEndpointCollectionTitle } from "../utils/MappingTestUtils";

type Props = {
  endpointCollection: MappingTestSuiteEndpointCollectionInfo | undefined;
  setIsCreatingNewEndpointCollection: (isCreating: boolean) => void;
  isCreatingNewEndpointCollection: boolean;
  integrationID: string;
  testSuiteID: string;
  fetchMappingTestSuite: () => void;
};

const EndpointCollectionCard = ({
  endpointCollection,
  integrationID,
  testSuiteID,
  setIsCreatingNewEndpointCollection,
  isCreatingNewEndpointCollection,
  fetchMappingTestSuite,
}: Props) => {
  const history = useHistory();
  const hasCollection = endpointCollection?.id != null;

  const body = endpointCollection
    ? {
        common_model_name: endpointCollection?.common_model_name,
        operation_type: endpointCollection?.operation_type,
        event: endpointCollection?.webhook_event,
        blueprint_id: endpointCollection?.blueprint_id,
      }
    : { is_read_test_collection: true };

  const onAddEndpointCollection = () => {
    if (!isCreatingNewEndpointCollection) {
      setIsCreatingNewEndpointCollection(true);
      fetchWithAuth({
        path: `/stallions/test-suites/${testSuiteID}/endpoint-collections`,
        body: body,
        method: "POST",
        onResponse: (response: {
          endpoint_test_collection: MappingTestSuiteEndpointCollection;
          mapping_test_id?: string;
        }) => {
          const { endpoint_test_collection, mapping_test_id } = response;
          if (endpointCollection) {
            navigateToEndpointCollection(
              history,
              integrationID,
              testSuiteID,
              endpoint_test_collection.id
            );
          } else {
            navigateToMappingTestEditor(
              history,
              integrationID,
              mapping_test_id || endpoint_test_collection.id
            );
          }
          setIsCreatingNewEndpointCollection(false);
          fetchMappingTestSuite();
        },
        onError: (err: Response | undefined) => {
          err?.json().then((errorData: any) => {
            if (errorData) {
              showErrorToast(errorData);
            } else {
              showErrorToast("Failed to create endpoint collection.");
            }
          });
          setIsCreatingNewEndpointCollection(false);
        },
      });
    }
  };

  const title = endpointCollection ? getEndpointCollectionTitle(endpointCollection) : "Fetch Test";
  return (
    <EndpointCollectionCardBase
      doesEntityExist={hasCollection}
      onClick={() => {
        hasCollection
          ? navigateToEndpointCollection(
              history,
              integrationID,
              testSuiteID,
              endpointCollection?.id!
            )
          : onAddEndpointCollection();
      }}
      title={title}
    />
  );
};

export default EndpointCollectionCard;
