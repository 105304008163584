import { useParams } from "react-router-dom";
import { PaginationConfigurationIntegrationBuilder } from "../../utils/Entities";
import PaginationConfigurationHeader from "./PaginationConfigurationHeader";
import PaginationConfigurationContextProvider from "../context/PaginationConfigurationContextProvider";
import PaginationConfigurationExplain from "./PaginationConfigurationExplain";
import PaginationConfigurationSetupOptions from "./PaginationConfigurationSetupOptions";
import { useContext, useEffect } from "react";
import useIntegrationBuilderContext from "../../context/useIntegrationBuilderContext";
import PaginationConfigurationContext from "../context/PaginationConfigurationContext";
import useDeletePaginationConfiguration from "../hooks/useDeletePaginationConfiguration";
import useCreateOrPatchPaginationConfiguration from "../hooks/useCreateOrPatchPaginationConfiguration";
import { DiffModelTypeEnum } from "../../../../models/DiffModels";
import EditorLeavingGuard from "../../../shared/unsaved-changes/EditorLeavingGuard";
import useLoadPaginationConfigurations from "../hooks/useLoadPaginationConfigurations";

const PAGINATION_CONFIG_SAVE_TEXT = "Save pagination configuration";
const PAGINATION_CONFIG_CREATE_TEXT = "Create pagination configuration";
const PAGINATION_CONFIG_DELETE_TEXT = "Delete pagination configuration";

type RouteParams = {
  paginationConfigurationID: string | undefined;
};

interface PaginationConfigurationSetupProps {
  integrationID: string;
  setPaginationConfigurations: React.Dispatch<
    React.SetStateAction<PaginationConfigurationIntegrationBuilder[] | undefined>
  >;
  isLoadingPaginationConfigurations: boolean;
  setIsLoadingPaginationConfigurations: React.Dispatch<React.SetStateAction<boolean>>;
  paginationConfigurations?: PaginationConfigurationIntegrationBuilder[] | undefined;
  paginationConfigurationID?: string | undefined;
  selectedPaginationConfiguration?: PaginationConfigurationIntegrationBuilder | undefined;
}

const BasePaginationConfigurationSetup = ({
  integrationID,
  paginationConfigurations,
  setPaginationConfigurations,
  isLoadingPaginationConfigurations,
  paginationConfigurationID,
  selectedPaginationConfiguration,
}: PaginationConfigurationSetupProps) => {
  const isNewPaginationConfiguration = !!!selectedPaginationConfiguration;
  const paginationConfigSubmitText = isNewPaginationConfiguration
    ? PAGINATION_CONFIG_CREATE_TEXT
    : PAGINATION_CONFIG_SAVE_TEXT;

  const {
    createPaginationConfiguration,
    patchPaginationConfiguration,
    isLoadingSubmit,
  } = useCreateOrPatchPaginationConfiguration({
    integrationID,
    setPaginationConfigurations,
  });

  const {
    deletePaginationConfiguration,
    isDeletingPaginationConfigurationLoading,
  } = useDeletePaginationConfiguration({
    integrationID,
    setPaginationConfigurations,
    paginationConfigurationID: selectedPaginationConfiguration?.id,
  });

  const { formPaginationConfigurationData, canSubmitPaginationConfiguration } = useContext(
    PaginationConfigurationContext
  );

  const {
    setOnSubmit,
    setOnDelete,
    setCanSubmit,
    computeHasUnsavedChanges,
  } = useIntegrationBuilderContext({
    submitButtonText: paginationConfigSubmitText,
    canSubmit: canSubmitPaginationConfiguration,
    isLoadingSubmit: isLoadingSubmit,
    isLoadingDelete: isDeletingPaginationConfigurationLoading,
    shouldRenderSubmitButton: true,
    shouldRenderNavigationButtons: false,
    shouldRenderDeleteButton: !isNewPaginationConfiguration,
    deleteButtonText: PAGINATION_CONFIG_DELETE_TEXT,
    modelTypeForDiff: DiffModelTypeEnum.PAGINATION_CONFIGURATION,
    shouldHideDiffModal: isNewPaginationConfiguration,
    shouldRenderStageButton: false,
    markForDeletion: false,
  });
  const paginationConfigOnSubmit = () => {
    const paginationConfigData = formPaginationConfigurationData();
    if (!paginationConfigData) {
      return;
    }

    isNewPaginationConfiguration
      ? createPaginationConfiguration(paginationConfigData)
      : patchPaginationConfiguration(paginationConfigData);
  };

  const isFirstPaginationConfiguration =
    !paginationConfigurations?.length ||
    (paginationConfigurations?.length == 1 &&
      paginationConfigurations[0].id == selectedPaginationConfiguration?.id);

  // Sets up the onSubmit function
  useEffect(() => {
    setOnSubmit(paginationConfigOnSubmit);
  }, [setOnSubmit, formPaginationConfigurationData]);

  // Specifies whether we can submit the form
  useEffect(() => {
    setCanSubmit(canSubmitPaginationConfiguration);
  }, [setCanSubmit, canSubmitPaginationConfiguration]);

  // Sets up the onDelete function
  useEffect(() => {
    setOnDelete(deletePaginationConfiguration);
  }, [setOnDelete, selectedPaginationConfiguration]);

  return (
    <EditorLeavingGuard computeHasUnsavedChanges={computeHasUnsavedChanges}>
      <div>
        <PaginationConfigurationHeader
          integrationID={integrationID}
          isNewPaginationConfiguration={!paginationConfigurationID}
        />
        <PaginationConfigurationExplain />
        {!isLoadingPaginationConfigurations && (
          <PaginationConfigurationSetupOptions
            isNewPaginationConfiguration={isNewPaginationConfiguration}
            isFirstPaginationConfiguration={isFirstPaginationConfiguration}
          />
        )}
      </div>
    </EditorLeavingGuard>
  );
};

const PaginationConfigurationSetup = ({
  integrationID,
  paginationConfigurations,
  setPaginationConfigurations,
  isLoadingPaginationConfigurations,
  setIsLoadingPaginationConfigurations,
}: PaginationConfigurationSetupProps) => {
  // Need to load the pagination configurations in the case of loading a specific pagination configuration page directly
  useLoadPaginationConfigurations({
    integrationID,
    setPaginationConfigurations,
    setIsLoadingPaginationConfigurations,
  });

  const { paginationConfigurationID } = useParams<RouteParams>();

  const selectedPaginationConfiguration = paginationConfigurations?.find(
    (paginationConfiguration) => paginationConfiguration.id === paginationConfigurationID
  );

  return (
    <PaginationConfigurationContextProvider
      selectedPaginationConfiguration={selectedPaginationConfiguration}
      integrationID={integrationID}
    >
      <BasePaginationConfigurationSetup
        integrationID={integrationID}
        paginationConfigurations={paginationConfigurations}
        setPaginationConfigurations={setPaginationConfigurations}
        isLoadingPaginationConfigurations={isLoadingPaginationConfigurations}
        paginationConfigurationID={paginationConfigurationID}
        selectedPaginationConfiguration={selectedPaginationConfiguration}
        setIsLoadingPaginationConfigurations={setIsLoadingPaginationConfigurations}
      />
    </PaginationConfigurationContextProvider>
  );
};

export default PaginationConfigurationSetup;
