import { useEffect, useState } from "react";
import { UserFacingFilterType } from "../../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import { fetchMappedFilterTypesForIntegration } from "../../../../../integration-builder/selective-sync-filters/components/shared/SelectiveSyncFilterBuilderAPIClient";
import { showWarningToast } from "../../../../../shared/Toasts";

interface Props {
  integrationID: string;
}
/**
 * Custom hook to fetch and manage user-facing filter type options for selective sync.
 * This hook fetches the available filter types for a given integration and provides
 * them as an array of UserFacingFilterType objects.
 *
 * @param {Object} props - The props object.
 * @param {string} props.integrationID - The ID of the integration to fetch filter types for.
 * @returns {Object} An object containing the filterTypeOptions array.
 */

const useFetchUserFacingFilterTypeOptions = ({ integrationID }: Props) => {
  const [filterTypeOptions, setFilterTypeOptions] = useState<UserFacingFilterType[]>([]);

  // Function to fetch available filter types and convert them to map
  const fetchAvailabeFilterTypes = () => {
    fetchMappedFilterTypesForIntegration({
      integrationID: integrationID,
      onSuccess: (response: Record<string, UserFacingFilterType>) => {
        setFilterTypeOptions(Object.values(response));
      },
      onError: () => {
        showWarningToast("Failed to load available selective sync filters");
      },
    });
  };

  // Fetch filter types during component load
  useEffect(() => {
    fetchAvailabeFilterTypes();
  }, []);

  return {
    filterTypeOptions,
  };
};

export default useFetchUserFacingFilterTypeOptions;
