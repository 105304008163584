import {
  BlueprintDataTransformStep,
  BlueprintParameterValue,
  BlueprintParameterValueProcedureArray,
  BlueprintParameterValueType,
  BlueprintProcedure,
  BlueprintProcedureCategoryType,
  BlueprintProcedureType,
  BlueprintStep,
  JSONArraySchema,
  JSONObjectSchema,
  JSONSchemaNonNestedValue,
  JSONSchemaValue,
} from "../../../models/Blueprints";
import {
  BlueprintAvailableParameter,
  convertTraversalPathToDotNotation,
  recursivelyAppendParameters,
} from "./BlueprintEditorUtils";

export enum JSONSchemaTypeOption {
  array = "array",
  object = "object",
  string = "string",
  boolean = "boolean",
  number = "number",
}

export const getEmptyObjectJSONSchema = (): JSONObjectSchema => ({
  type: "object",
  properties: {},
});

export const getEmptyJSONSchemaValue = (
  type: "string" | "number" | "boolean"
): JSONSchemaNonNestedValue => ({
  type,
});

export const getGenericJSONSchemaValue = (): JSONSchemaNonNestedValue => ({
  type: "any",
});

export const getEmptyArrayJSONSchema = (): JSONArraySchema => ({
  type: "array",
  items: getGenericJSONSchemaValue(),
});

export const getEmptyJSONSchemaForType = (type: JSONSchemaTypeOption): JSONSchemaValue => {
  switch (type) {
    case JSONSchemaTypeOption.object:
      return getEmptyObjectJSONSchema();
    case JSONSchemaTypeOption.array:
      return getEmptyArrayJSONSchema();
    default:
      return getEmptyJSONSchemaValue(type);
  }
};

export const initializeProcedureForProcedureType = (
  procedureType: BlueprintProcedureType,
  procedureIndex: number
): BlueprintProcedure => {
  const procedureConfig = (): {
    procedure_category_type: BlueprintProcedureCategoryType;
    parameter_schema: JSONObjectSchema;
    parameter_values: { [key: string]: null | BlueprintParameterValue };
    return_schema?: JSONObjectSchema;
    arguments?: object;
  } => {
    switch (procedureType) {
      case BlueprintProcedureType.ADD_KEY_VALUE_PAIR_TO_OBJECT:
        return {
          procedure_category_type: BlueprintProcedureCategoryType.MODIFY,
          parameter_schema: {
            type: "object",
            properties: {
              object: getEmptyObjectJSONSchema(),
              key: getGenericJSONSchemaValue(),
              value: getGenericJSONSchemaValue(),
            },
          },
          parameter_values: { object: null, key: null, value: null },
        };
      case BlueprintProcedureType.INSERT_INTO_ARRAY:
        return {
          procedure_category_type: BlueprintProcedureCategoryType.MODIFY,
          parameter_schema: {
            type: "object",
            properties: {
              array: getEmptyArrayJSONSchema(),
              value: getGenericJSONSchemaValue(),
            },
          },
          parameter_values: { array: null, value: null },
        };
    }
  };

  return {
    ...procedureConfig(),
    id: "procedure" + procedureIndex,
    procedure_type: procedureType,
  };
};

export const generateReturnSchemaFromProcedureArray = (
  procedureArray: Array<BlueprintProcedure>
): JSONObjectSchema => {
  const returnSchemaProperties: {
    [parameter: string]: JSONSchemaValue;
  } = {};

  procedureArray.forEach((procedure) => {
    if (procedure.return_schema) {
      returnSchemaProperties[procedure.id] = procedure.return_schema;
    }
  });

  return { type: "object", properties: returnSchemaProperties };
};

export const appendProcedureParameters = (
  step: BlueprintStep,
  parameterType: string,
  resultArray: Array<BlueprintAvailableParameter>,
  procedureIndex: number,
  shouldIncludeProcedureParameters?: boolean
) => {
  const procedureArray = ((step as BlueprintDataTransformStep).parameter_values
    .procedures as BlueprintParameterValueProcedureArray).procedure_array;

  const previousProcedures = procedureArray.slice(0, procedureIndex);

  previousProcedures.forEach((procedure) => {
    Object.keys(procedure.return_schema?.properties ?? {}).forEach((key) => {
      resultArray.push({
        labelKey: convertTraversalPathToDotNotation([step.id, procedure.id, key]),
        parameterValue: {
          step_id: step.id,
          return_schema_path: [],
          request_return_value_path: [procedure.id, key],
          value_type: BlueprintParameterValueType.returnValue,
        },
        extraData: {
          title: key,
          description: `The return value ${key} from ${procedure.id}`,
          valueType: parameterType,
          deprecated: false,
        },
      });
    });
  });

  if (shouldIncludeProcedureParameters) {
    const procedure = procedureArray[procedureIndex];

    Object.entries(procedureArray[procedureIndex].parameter_schema.properties).forEach(
      ([key, value]) => {
        const isAddingArrayLoopItem = value.type === "array";
        recursivelyAppendParameters(
          resultArray,
          parameterType,
          step.id,
          value,
          [key],
          isAddingArrayLoopItem ? [procedure.id, key, "item"] : [procedure.id, key],
          key,
          isAddingArrayLoopItem
        );
      }
    );
  }
};
