import { HeaderPretitle } from "../../shared/text/MergeText";
import TypeaheadFormField from "./TypeaheadFormField";

const BlueprintEditorRightPanelUpdateLinkedAccountForm = () => {
  return (
    <>
      <form>
        <HeaderPretitle className="my-2">Parameters</HeaderPretitle>
        <TypeaheadFormField
          title={"basic_auth_key"}
          subtitle={
            "Authentication key (generally just referred to as API key) for the end user's linked account."
          }
          valueKey={"basic_auth_key"}
          parameterType={"string"}
        />
        <TypeaheadFormField
          title={"basic_auth_password"}
          subtitle={"Authentication password for the end user's linked account."}
          valueKey={"basic_auth_password"}
          parameterType={"string"}
        />
        <TypeaheadFormField
          title={"api_url_subdomain"}
          subtitle={"Subdomain of the end user."}
          valueKey={"api_url_subdomain"}
          parameterType={"string"}
        />
        <TypeaheadFormField
          title={"oauth_client_id"}
          subtitle={"Client ID for OAuth."}
          valueKey={"oauth_client_id"}
          parameterType={"string"}
        />
        <TypeaheadFormField
          title={"oauth_client_secret"}
          subtitle={"Client secret for OAuth."}
          valueKey={"oauth_client_secret"}
          parameterType={"string"}
        />
        <TypeaheadFormField
          title={"scraper_auth_storage_state"}
          subtitle={"Cookies and local storage used to authenticate scrapers to bypass login. "}
          valueKey={"scraper_auth_storage_state"}
          parameterType={"string"}
        />
        <TypeaheadFormField
          title={"additional_auth_fields"}
          subtitle={"JSON object of additional auth fields for the linked account."}
          valueKey={"additional_auth_fields"}
          parameterType={"object"}
        />
        <TypeaheadFormField
          title={"service_account_fields"}
          subtitle={"JSON object of service account fields for the linked account."}
          valueKey={"service_account_fields"}
          parameterType={"object"}
        />
        <TypeaheadFormField
          title={"security_questions"}
          subtitle={'Array of security questions as { "question": "answer" }'}
          valueKey={"security_questions"}
          parameterType={"array"}
        />
      </form>
    </>
  );
};

export default BlueprintEditorRightPanelUpdateLinkedAccountForm;
