import { Badge, Tooltip } from "@merge-api/merge-javascript-shared";
import { IntegrationTestCoverage } from "../../../../../../models/Entities";
import { getBadgeColorForCoveragePercent } from "../../../../../shared/utils/SharedComponentUtils";
import { Info } from "lucide-react";

type MappingTestCoverageCellProps = {
  integrationTestCoverage?: IntegrationTestCoverage | null;
  numberOfStagedTests?: number;
};

const MappingTestCoverageCell = ({
  integrationTestCoverage,
  numberOfStagedTests,
}: MappingTestCoverageCellProps) => {
  const coverage_percent = integrationTestCoverage?.total_coverage_percent
    ? integrationTestCoverage.total_coverage_percent
    : 0;

  return (
    <td>
      <div className="flex items-center justify-start">
        <Badge color={getBadgeColorForCoveragePercent(coverage_percent)}>
          {Math.round(coverage_percent)}
        </Badge>
        <div className="flex items-center ml-1.5">
          {!!numberOfStagedTests && (
            <Tooltip
              title={
                numberOfStagedTests.toString() +
                " staged test" +
                (numberOfStagedTests > 1 ? "s" : "")
              }
            >
              <Info size={12} className="text-black" />
            </Tooltip>
          )}
        </div>
      </div>
    </td>
  );
};

export default MappingTestCoverageCell;
