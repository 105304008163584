import ContentLoader from "react-content-loader";
import { spectrum } from "../../styles/theme/colors";
import classNames from "classnames";

type TableSkeletonLoaderProps = {
  cols?: number;
  rows?: number;
  variant?: "static" | "loading";
  size?: "small" | "large";
};
const TableSkeletonLoader = ({
  cols = 7,
  rows = 5,
  variant = "loading",
  size = "small",
}: TableSkeletonLoaderProps) => {
  const skeletonRows = Array.from({ length: rows }).map((_, i) => (
    <tr key={`skel-row-${i}`} className="w-3">
      {Array.from({ length: cols }).map((_, j) => (
        <td
          className={classNames("pr-8", size === "large" ? "h-[68px]" : "h-[55px]")}
          key={`skel-cell-${j}`}
        >
          <ContentLoader
            speed={variant === "loading" ? 1.4 : 0}
            width="auto"
            height={20}
            backgroundColor={variant === "loading" ? spectrum.gray0 : spectrum.gray20}
            foregroundColor={spectrum.gray10}
            title={variant === "loading" ? "Loading..." : ""}
          >
            <rect x="0" y="3" rx="3" ry="3" width="100%" height="14" />
          </ContentLoader>
        </td>
      ))}
    </tr>
  ));

  return <>{skeletonRows}</>;
};
export default TableSkeletonLoader;
