import { useState, useCallback, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { Integration, ReportTemplate } from "../../../models/Entities";
import { fetchWithAuth } from "../../../api-client/api_client";
import ReportTemplatesOverview from "./ReportTemplatesOverview";

type Props = {
  integrationMeta: Integration;
};

const ReportTemplatesTab = ({ integrationMeta }: Props) => {
  const [reportTemplates, setReportTemplates] = useState<ReportTemplate[] | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const integrationID = integrationMeta.id;

  const fetchReportTemplates = useCallback(() => {
    setIsLoading(true);
    fetchWithAuth({
      path: `/integrations/${integrationID}/report-templates`,
      method: "GET",
      onResponse: (data: ReportTemplate[]) => {
        setReportTemplates(data);
        setIsLoading(false);
      },
    });
  }, [integrationID]);

  useEffect(() => {
    fetchReportTemplates();
  }, [integrationID, fetchReportTemplates]);

  return (
    <Switch>
      <Route
        path={"/integrations/:integrationID/report-templates/"}
        children={() => (
          <ReportTemplatesOverview reportTemplates={reportTemplates} isLoading={isLoading} />
        )}
      />
    </Switch>
  );
};

export default ReportTemplatesTab;
