import { fetchWithAuth } from "./api_client";
import Readme from "../models/Readme";

export interface FetchReadmeProps<T extends Readme> {
  path: string;
  onSuccess: (data: T) => void;
  onError: (error: Response | undefined) => void;
}

export const fetchReadme = <T extends Readme>(props: FetchReadmeProps<T>): Promise<void> =>
  fetchWithAuth({
    path: props.path,
    method: "GET",
    onResponse: props.onSuccess,
    onError: props.onError,
  });

export interface UpdateReadmeProps<T extends Readme> {
  path: string;
  text: string;
  onSuccess: (data: T) => void;
  onError: (error: Response | undefined) => void;
}

export const updateReadme = <T extends Readme>(props: UpdateReadmeProps<T>): Promise<void> =>
  fetchWithAuth({
    path: props.path,
    method: "POST",
    body: { text: props.text },
    onResponse: props.onSuccess,
    onError: props.onError,
  });
