import { useEffect, useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast } from "../../../shared/Toasts";
import { WebAddressSchemaIntegrationBuilder } from "../../utils/Entities";

interface loadWebAddressSchemaProps {
  integrationID: string;
}

const useLoadWebAddressSchemas = ({ integrationID }: loadWebAddressSchemaProps) => {
  // state
  const [webAddressSchemas, setWebAddressSchemas] = useState<
    WebAddressSchemaIntegrationBuilder[] | undefined
  >();
  const [isLoadingWebAddressSchemas, setIsLoadingWebAddressSchemas] = useState(true);

  // effects
  useEffect(() => {
    fetchWithAuth({
      path: `/integrations/${integrationID}/web-address-schemas`,
      method: "GET",
      onResponse: (data: WebAddressSchemaIntegrationBuilder[]) => {
        setWebAddressSchemas(data);
        setIsLoadingWebAddressSchemas(false);
      },
      onError: () => {
        showErrorToast("Failed to fetch web address schemas for integration.");
        setWebAddressSchemas([]);
      },
    });
  }, [setWebAddressSchemas]);

  return { webAddressSchemas, isLoadingWebAddressSchemas, setWebAddressSchemas };
};

export default useLoadWebAddressSchemas;
