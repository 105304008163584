import styled from "styled-components";
import { Accordion, Button, Card, Form, Table } from "react-bootstrap";
import MergeText, { SmallTextMutedParagraph } from "../../shared/text/MergeText";
import ClickableContainer from "../../shared/ClickableContainer";
import { OverflowSmallTextMutedParagraph } from "./UIDTemplateCards";
import { ChevronUpDownToggleWithElement } from "../../shared/MergeToggles";
import {
  IntegrationCommonModelTemplate,
  IntegrationCommonModelTemplateField,
} from "../../../models/Entities";
import { firstLetterUpperCase } from "../../../utils";

const FieldSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 14px;
  padding-left: 20px;
`;

const WhiteOverflowSmallTextMutedParagraph = styled(OverflowSmallTextMutedParagraph)`
  color: white !important;
  padding-left: 22px;
  margin-bottom: 0;
  min-height: 50px;
  padding-top: 16px;
`;

const PaddedSmallTextMutedParagraph = styled(OverflowSmallTextMutedParagraph)`
  padding-left: 8px;
  padding-top: 1px;
`;

const NonCommonModelFieldsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin-left: 30px;
`;

const CustomSwitch = styled(Form.Check)`
  && {
    .custom-control-input:checked ~ .custom-control-label:before {
      background-color: #111317;
    }
  }
`;

const RowCustomSwitch = styled(CustomSwitch)`
  padding-left: 0px;
  padding-right: 0px;
`;

const TrashButton = styled(Button).attrs({ size: "sm", variant: "light" })`
  background-color: white;
  border: none;
  font-size: 14px;
  border: 1px solid #d4def2;
  width: 35px;
  margin-right: 15px;
`;

interface Props {
  template: IntegrationCommonModelTemplate;
  setTemplate: (template: IntegrationCommonModelTemplate) => void;
  setShowAddNonCommonModelField: (showAddNonCommonModelField: boolean) => void;
  templateIsActive: boolean;
}

function UIDTemplateFieldTable({
  template,
  setTemplate,
  setShowAddNonCommonModelField,
  templateIsActive,
}: Props) {
  const isFieldUsedInTemplate = (
    template: IntegrationCommonModelTemplate,
    eligibleField: IntegrationCommonModelTemplateField
  ) => {
    return (
      template.unique_id_fields.findIndex(
        (field) => field.field_name === eligibleField.field_name
      ) > -1
    );
  };

  const addOrRemoveTemplateField = (
    template: IntegrationCommonModelTemplate,
    fieldToAddOrRemove: IntegrationCommonModelTemplateField
  ) => {
    const index = template.unique_id_fields.findIndex(
      (field) => field.field_name === fieldToAddOrRemove.field_name
    );
    if (index > -1) {
      template.unique_id_fields.splice(index, 1);
      setTemplate({ ...template, unique_id_fields: template.unique_id_fields });
    } else {
      template.unique_id_fields.push(fieldToAddOrRemove);
      setTemplate({ ...template, unique_id_fields: template.unique_id_fields });
    }
  };
  return (
    <Card
      style={{
        marginLeft: "5px",
        marginRight: "5px",
        marginTop: "10px",
        borderRadius: "14px",
      }}
    >
      <Table className="table-nowrap table-layout: fixed margin-bottom: 0px">
        <thead className="height: 50px">
          <tr>
            <th
              colSpan={3}
              style={{
                backgroundColor: "#111317",
                borderRadius: "14px 14px 0 0",
                textTransform: "none",
                width: "100%",
              }}
            >
              <WhiteOverflowSmallTextMutedParagraph>
                {template.unique_id_fields
                  .map((field) => {
                    return field.field_name.toLowerCase();
                  })
                  .sort()
                  .join(" - ")}
              </WhiteOverflowSmallTextMutedParagraph>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={3} style={{ backgroundColor: "#F7F9FB", paddingLeft: "35px" }}>
              <MergeText isBold>Common Model Fields</MergeText>
            </td>
          </tr>
          {template.eligible_fields.map((field) => {
            return (
              <tr key={field.id}>
                <td>
                  <FieldSection>
                    <Accordion>
                      <ChevronUpDownToggleWithElement
                        eventKey="0"
                        afterChildren={
                          <PaddedSmallTextMutedParagraph>
                            {firstLetterUpperCase(field.field_type)}
                          </PaddedSmallTextMutedParagraph>
                        }
                      >
                        <MergeText
                          isBold
                          style={{
                            fontSize: "15px",
                            paddingRight: "10px",
                            textTransform: "none",
                          }}
                        >
                          {field.field_name}
                        </MergeText>
                      </ChevronUpDownToggleWithElement>
                      <Accordion.Collapse eventKey="0" className="mt-0">
                        <SmallTextMutedParagraph>{field.description}</SmallTextMutedParagraph>
                      </Accordion.Collapse>
                    </Accordion>
                  </FieldSection>
                </td>
                <td className="text-right">
                  <RowCustomSwitch
                    id={`is-field-enabled-${field.field_name}`}
                    type="switch"
                    checked={isFieldUsedInTemplate(template, field)}
                    onChange={() => {
                      addOrRemoveTemplateField(template, field);
                    }}
                  />
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan={3} style={{ backgroundColor: "#F7F9FB", paddingLeft: "35px" }}>
              <MergeText isBold>Non-Common Model Fields</MergeText>
            </td>
          </tr>
          {template.unique_id_fields.map((field) => {
            const allowNulls = field.should_allow_nulls ? "Nulls allowed" : "No nulls";
            const parsed = field.use_unparsed_value ? "Parsed" : "Unparsed";
            if (!field.is_common_model_field) {
              return (
                <tr>
                  <td>
                    <FieldSection>
                      <div style={{ width: "20%" }}>
                        <Accordion>
                          <ChevronUpDownToggleWithElement
                            eventKey="0"
                            afterChildren={
                              <PaddedSmallTextMutedParagraph>
                                {firstLetterUpperCase(field.field_type)}
                              </PaddedSmallTextMutedParagraph>
                            }
                          >
                            <MergeText
                              isBold
                              style={{
                                fontSize: "15px",
                                paddingRight: "10px",
                                textTransform: "none",
                              }}
                            >
                              {field.field_name}
                            </MergeText>
                          </ChevronUpDownToggleWithElement>
                          <Accordion.Collapse eventKey="0" className="mt-3">
                            <SmallTextMutedParagraph>{field.description}</SmallTextMutedParagraph>
                          </Accordion.Collapse>
                        </Accordion>
                      </div>

                      <NonCommonModelFieldsContainer>
                        <SmallTextMutedParagraph>{allowNulls}</SmallTextMutedParagraph>
                        <SmallTextMutedParagraph>{parsed}</SmallTextMutedParagraph>
                        <SmallTextMutedParagraph>
                          {field.transformation || "Untransformed"}
                        </SmallTextMutedParagraph>
                      </NonCommonModelFieldsContainer>
                    </FieldSection>
                  </td>
                  <td className="text-right">
                    {!templateIsActive && (
                      <TrashButton
                        onClick={() => {
                          addOrRemoveTemplateField(template, field);
                        }}
                      >
                        <i className="fe fe-trash-2" />
                      </TrashButton>
                    )}
                  </td>
                </tr>
              );
            } else {
              return <></>;
            }
          })}
          <tr>
            <td colSpan={3} style={{ paddingLeft: "25px" }}>
              <ClickableContainer
                onClick={() => {
                  setShowAddNonCommonModelField(true);
                }}
              >
                <MergeText style={{ fontSize: "12px", color: "#075FF7" }}>
                  + Add Non-Common Model Field
                </MergeText>
              </ClickableContainer>
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
}

export default UIDTemplateFieldTable;
