import { Text, Link } from "@merge-api/merge-javascript-shared";
import { INTEGRATION_ORDER_BY_OPTIONS } from "../hooks/useLoadPaginatedIntegrations";
import { Dropdown } from "react-bootstrap";

// constants
const readableIntegrationOrderBy = {
  [INTEGRATION_ORDER_BY_OPTIONS.NAME]: "Name",
  [INTEGRATION_ORDER_BY_OPTIONS.LAST_MODIFIED]: "Last modified",
  [INTEGRATION_ORDER_BY_OPTIONS.LINKED_ACCOUNTS]: "Linked Accounts",
};

type IntegrationsDashboardOrderByProps = {
  orderBy: INTEGRATION_ORDER_BY_OPTIONS;
  setOrderBy: React.Dispatch<React.SetStateAction<INTEGRATION_ORDER_BY_OPTIONS>>;
};

const IntegrationsDashboardOrderBy = ({
  orderBy,
  setOrderBy,
}: IntegrationsDashboardOrderByProps) => {
  return (
    <div className="flex flex-row">
      <Text className="mr-1.5">Sort by:</Text>
      <div className="cursor-pointer">
        <Dropdown>
          <Dropdown.Toggle
            variant="hidden"
            className="p-0 text-blue-40 hover:text-blue-60 focus:text-blue-60 text-base"
          >
            <Link as="a">{readableIntegrationOrderBy[orderBy]}</Link>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              className="hover:bg-gray-0 text-base font-normal text-black"
              onClick={() => setOrderBy(INTEGRATION_ORDER_BY_OPTIONS.NAME)}
            >
              {readableIntegrationOrderBy[INTEGRATION_ORDER_BY_OPTIONS.NAME]}
            </Dropdown.Item>
            <Dropdown.Item
              className="hover:bg-gray-0 text-base font-normal text-black"
              onClick={() => setOrderBy(INTEGRATION_ORDER_BY_OPTIONS.LAST_MODIFIED)}
            >
              {readableIntegrationOrderBy[INTEGRATION_ORDER_BY_OPTIONS.LAST_MODIFIED]}
            </Dropdown.Item>
            <Dropdown.Item
              className="hover:bg-gray-0 text-base font-normal text-black"
              onClick={() => setOrderBy(INTEGRATION_ORDER_BY_OPTIONS.LINKED_ACCOUNTS)}
            >
              {readableIntegrationOrderBy[INTEGRATION_ORDER_BY_OPTIONS.LINKED_ACCOUNTS]}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default IntegrationsDashboardOrderBy;
