import { fetchWithAuth } from "../../../../api-client/api_client";
import { BlueprintSearchFilterSet } from "../../../../models/blueprint-search/BlueprintSearchInputModels";
import { BlueprintSearchResults } from "../../../../models/blueprint-search/BlueprintSearchOutputModels";

interface fetchBlueprintSearchProps {
  integrationID: string;
  requestBody: BlueprintSearchFilterSet;
  onSuccess: (response: BlueprintSearchResults) => void;
  onError: (response: Response | undefined) => void;
  setIsLoading: (isLoading: boolean) => void;
}

/**
 * Props for fetchBlueprintSearch function
 *
 * @param integrationID - The ID of the integration to search blueprints for
 * @param requestBody - The filter set to apply to the blueprint search
 * @param onSuccess - Callback function to handle successful search results
 * @param onError - Callback function to handle errors during the search
 * @param setIsLoading - Function to update the loading state of the search
 */

export const fetchBlueprintSearch = ({
  integrationID,
  requestBody,
  onSuccess,
  onError,
  setIsLoading,
}: fetchBlueprintSearchProps) => {
  setIsLoading(true);
  fetchWithAuth({
    path: `/integrations/${integrationID}/blueprints/search`,
    method: "POST",
    body: requestBody,
    onResponse: (data: BlueprintSearchResults) => {
      onSuccess(data);
      setIsLoading(false);
    },
    onError: (error: Response | undefined) => {
      onError(error);
      setIsLoading(false);
    },
  });
};
