import { Button, Form } from "react-bootstrap";
import { MoreHorizontal } from "lucide-react";
import styled from "styled-components";

import ContextMenu from "../../shared/ContextMenu";
import MergeText from "../../shared/text/MergeText";
import { StyledButton } from "../../shared/readme/ReadmePanel";
import Markdown from "markdown-to-jsx";
import { ChangeEvent, useRef, useState } from "react";
import { palette } from "../../../styles/theme/colors";

const NotesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MoreHorizontalButton = styled(MoreHorizontal)`
  cursor: pointer;
`;

// Someone please unhardcode the height one day
const Editor = styled(Form.Control).attrs({ as: "textarea", className: "w-100" })`
  height: calc(100vh - 275px - 40px - 1rem);
  box-sizing: border-box;
  resize: none;
`;

const Viewer = styled(Markdown).attrs({ className: "pb-6 prose prose-sm" })``;

interface Props {
  stepNoteText: string;
  setStepNoteText: (x: string) => void;
}
const StepNoteEditorAndViewer = ({ stepNoteText, setStepNoteText }: Props) => {
  const [editedText, setEditedText] = useState<string>("");
  const [isEditing, setIsEditing] = useState(false);
  const [isShowingContextMenu, setIsShowingContextMenu] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const contextMenuOptions = [
    {
      label: "Edit",
      featherIconName: "edit-3",
      onClick: () => {
        setIsEditing(true);
        setEditedText(stepNoteText);
        setIsShowingContextMenu(false);
      },
    },
  ];
  return (
    <>
      <NotesHeader ref={headerRef}>
        <MergeText isBold={true} size="15px">
          Notes
        </MergeText>
        <div style={{ position: "relative" }}>
          <MoreHorizontalButton
            size="16"
            color={palette.gray}
            onClick={() => setIsShowingContextMenu(true)}
          />
          {isShowingContextMenu && (
            <ContextMenu
              items={contextMenuOptions}
              isShown={isShowingContextMenu}
              position={{
                x: -54,
                y: 28,
                positionType: "absolute",
              }}
              onClose={() => setIsShowingContextMenu(false)}
            />
          )}
        </div>
      </NotesHeader>
      <hr />
      {isEditing ? (
        <>
          <Editor
            value={editedText}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setEditedText(e.target.value)}
          />
          <div className="d-flex justify-content-end">
            <StyledButton
              className="mr-3"
              onClick={() => {
                setIsEditing(false);
              }}
            >
              Cancel
            </StyledButton>
            <Button
              size="sm"
              disabled={!editedText}
              onClick={() => {
                setStepNoteText(editedText);
                setIsEditing(false);
              }}
            >
              <i className="fe fe-save mr-1.5" />
              Save Changes
            </Button>
          </div>
        </>
      ) : (
        <Viewer>{stepNoteText || "No Notes"}</Viewer>
      )}
    </>
  );
};

export default StepNoteEditorAndViewer;
