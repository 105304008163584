import useScraperContext from "./context/useScraperContext";
import { ScraperPressKeyStep, ScraperValueType } from "./types";
import ScraperTypeaheadFormField from "./ScraperTypeaheadFormField";
import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const ScraperPressKeyField = () => {
  const { selectedStep, updateStepParameterValue } = useScraperContext();
  const step = selectedStep as ScraperPressKeyStep;
  const { key } = step.parameter_values;
  const [isInputting, setIsInputting] = useState<boolean>(false);
  const [pressed, setPressed] = useState<Set<string>>(new Set());
  const handleKeyDown = useCallback(
    (event) => {
      if (!isInputting) {
        return;
      }
      event.preventDefault();
      if (pressed.has(event.key)) return;
      setPressed(new Set([...pressed, event.key]));
    },
    [isInputting, pressed]
  );

  const handleKeyUp = useCallback(
    (event) => {
      if (!isInputting) {
        return;
      }

      if (pressed.has(event.key)) {
        updateStepParameterValue(step, "key", {
          value_type: ScraperValueType.CONSTANT,
          constant: Array.from(pressed).join("+"),
        });
        setPressed(new Set());
        setIsInputting(false);
      }
    },
    [isInputting, pressed]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown, true);
    return () => window.removeEventListener("keydown", handleKeyDown, true);
  }, [handleKeyDown]);
  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp, true);
    return () => window.removeEventListener("keyup", handleKeyUp, true);
  }, [handleKeyUp]);

  return (
    <>
      <ScraperTypeaheadFormField
        key={"key"}
        valueKey={"key"}
        updateValue={(newValue) => updateStepParameterValue(step, "key", newValue)}
        value={key}
      />
      <Button
        className="btn btn-primary btn-block"
        disabled={isInputting}
        onClick={() => {
          setIsInputting(true);
        }}
      >
        Record Keys
      </Button>
    </>
  );
};

export default ScraperPressKeyField;
