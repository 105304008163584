import ScraperContext, { ScraperContextState } from "./ScraperContext";

interface Props extends ScraperContextState {
  children: JSX.Element;
}

// Wraps around ScraperEditorView to be able to pull global state anywhere in tree via useContext
const ScraperContextProvider = ({ children, ...contextProps }: Props) => (
  <ScraperContext.Provider value={contextProps}>{children}</ScraperContext.Provider>
);

export default ScraperContextProvider;
