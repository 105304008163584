import classNames from "classnames";
import styled from "styled-components";
import { HeaderPretitle } from "../text/MergeText";
import { Link } from "@merge-api/merge-javascript-shared";
import { ExternalLink } from "lucide-react";
import { useState } from "react";

interface SectionProps {
  title: string;
  linkName?: string;
  onClick?: () => void;
  content: React.ReactNode | string;
  overviewBodyTextClassName?: string;
  collapsible?: boolean;
}

const OverviewBodyText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

const OverviewSection = (props: SectionProps) => {
  const { title, linkName, onClick, content, overviewBodyTextClassName, collapsible } = props;
  const [showContent, setShowContent] = useState<boolean>(true);

  const toggleShowcontent = () => {
    setShowContent(!showContent);
  };

  return (
    <div className="flex flex-column items-start mb-4">
      <div className="flex flex-row items-center w-full justify-between mb-1">
        <HeaderPretitle>
          <div className="flex flex-row" onClick={() => collapsible && toggleShowcontent()}>
            {title}{" "}
            {collapsible && (
              <span className={`fe ${showContent ? "fe-chevron-down" : "fe-chevron-up"}  ml-2`} />
            )}{" "}
          </div>
        </HeaderPretitle>
        {linkName != undefined && (
          <div className="flex flex-row items-center">
            <Link variant="title-sm" onClick={onClick}>
              {linkName + " "}
              <ExternalLink size={12} className="text-blue-40 mb-1" />
            </Link>
          </div>
        )}
      </div>
      {(!collapsible || showContent) && (
        <OverviewBodyText className={classNames(overviewBodyTextClassName)}>
          {content}
        </OverviewBodyText>
      )}
    </div>
  );
};

export default OverviewSection;
