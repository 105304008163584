import { BlueprintStep, BlueprintStepTemplate } from "../../../models/Blueprints";
import JSONSchemaTreeDiagram from "../../shared/JSONSchemaTreeDiagram";
import isEmpty from "lodash/isEmpty";
import InputFormField from "./InputFormField";
import useBlueprintContext from "../context/useBlueprintContext";
import { HTTPMethod } from "../../../models/HTTPMethods";
import { HeaderPretitle } from "../../shared/text/MergeText";
import CustomJSONEditorModal from "./custom-json-parameter/CustomJSONEditorModal";
import TypeaheadFormField from "./TypeaheadFormField";
import useCustomJSONModalContext from "./custom-json-parameter/useCustomJSONModalContext";
import CustomJSONOptions from "./custom-json-parameter/CustomJSONOptions";
import { schemaSupportsNestedEntries } from "./custom-json-parameter/CustomJSONUtils";
import {
  checkIgnoreAuthConfigUsed,
  isIgnoreAuthConfigAvailable,
} from "../utils/BlueprintEditorUtils";
import { Text, Toggle, Tooltip } from "@merge-api/merge-javascript-shared";
import { Info } from "lucide-react";

interface Props {
  stepID: string;
  stepTemplate: BlueprintStepTemplate;
}

const BlueprintEditorRightPanelAPIRequestForm = ({ stepID, stepTemplate }: Props) => {
  const {
    blueprint,
    selectedStep,
    updateStepMockResponseBody,
    setStepIgnoreAuthConfig,
  } = useBlueprintContext();
  const stepParameterSchema = stepTemplate.parameter_schema;
  const stepParameterSchemaProperties = stepParameterSchema?.properties ?? {};
  const customObjectModalContext = useCustomJSONModalContext(
    Object.keys(stepParameterSchemaProperties)
  );
  const { enabledRootKeys } = customObjectModalContext;
  const step = selectedStep as BlueprintStep;

  const canToggleAuthConfig = isIgnoreAuthConfigAvailable(blueprint);
  const ignoreAuthConfigUsed = checkIgnoreAuthConfigUsed(selectedStep);

  const updateIgnoreAuthConfig = () => {
    setStepIgnoreAuthConfig(stepID, !ignoreAuthConfigUsed);
  };

  return (
    <>
      {canToggleAuthConfig && (
        <Toggle
          className="my-3"
          checked={ignoreAuthConfigUsed}
          label={
            <div className="flex">
              <Text className="mr-1" variant="md">
                Ignore Auth Configuration
              </Text>
              <Tooltip title="Built for for Sage Intacct POSTs.">
                <Info size={16} />
              </Tooltip>
            </div>
          }
          labelPlacement="right"
          onChange={updateIgnoreAuthConfig}
        />
      )}
      {!isEmpty(stepTemplate.parameter_schema) &&
        !isEmpty(stepTemplate.parameter_schema?.properties) && (
          <>
            <HeaderPretitle className="my-2">Parameters</HeaderPretitle>
            <CustomJSONEditorModal {...customObjectModalContext} />
            {Object.entries(stepTemplate.parameter_schema?.properties ?? {}).map(
              ([key, parameter]) => {
                return (
                  <>
                    <TypeaheadFormField
                      key={key}
                      title={parameter.title ?? key}
                      valueKey={key}
                      parameterType={parameter.type}
                      disabled={enabledRootKeys[key]}
                    />
                    {schemaSupportsNestedEntries(parameter) && (
                      <CustomJSONOptions
                        parameterKey={key}
                        parameter={parameter}
                        {...customObjectModalContext}
                      />
                    )}
                  </>
                );
              }
            )}
            <hr />
          </>
        )}

      <HeaderPretitle className="mt-3">Response</HeaderPretitle>
      <JSONSchemaTreeDiagram stepID={stepID} jsonSchema={stepTemplate.return_schema} />
      {!isEmpty(stepTemplate.return_schema) &&
        stepTemplate.return_schema?.type === "object" &&
        stepTemplate.metadata["method"] !== HTTPMethod.GET && (
          <>
            <HeaderPretitle className="mt-3">Mock Response Fields For Test Runs</HeaderPretitle>
            {Object.entries(stepTemplate.return_schema?.properties ?? {}).map(([field, _]) => {
              return (
                <InputFormField
                  key={field}
                  title={field}
                  currentValue={
                    step.mock_response_body !== undefined ? step.mock_response_body[field] : ""
                  }
                  onChange={(value) => updateStepMockResponseBody(step, field, value)}
                />
              );
            })}
            <hr />
          </>
        )}
    </>
  );
};

export default BlueprintEditorRightPanelAPIRequestForm;
