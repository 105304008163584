import { useEffect, useMemo, useState } from "react";
import { LinkedAccount } from "../../../../models/Entities";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast } from "../../../shared/Toasts";
import { AdminAPICategory } from "@merge-api/merge-javascript-shared";

interface loadLinkedAccountProps {
  integrationID: string | undefined;
  category: AdminAPICategory;
  newLinkedAccount?: LinkedAccount;
}

const useLoadLinkedAccounts = ({ integrationID, category }: loadLinkedAccountProps) => {
  // state
  const [sandboxLinkedAccounts, setSandboxLinkedAccounts] = useState<LinkedAccount[]>();

  // derived state, if we want to get more complex down the line we can
  const linkedAccounts = useMemo(() => {
    if (!sandboxLinkedAccounts) return [];

    return [...sandboxLinkedAccounts].sort((a, b) =>
      a.end_user.organization_name.localeCompare(b.end_user.organization_name)
    );
  }, [sandboxLinkedAccounts]);

  // effects
  useEffect(() => {
    if (!integrationID) {
      setSandboxLinkedAccounts([]);
      return;
    }

    fetchWithAuth({
      path: `/integrations/linked-accounts?is_test_account=True&is_sandbox_account=True&integration=${integrationID}&category=${category}`,
      method: "GET",
      onResponse: (data: { results: LinkedAccount[] }) => {
        setSandboxLinkedAccounts(data.results);
      },
      onError: () => {
        showErrorToast("Failed to fetch sandbox Linked Accounts");
        setSandboxLinkedAccounts([]);
      },
    });
  }, [setSandboxLinkedAccounts]);

  return { linkedAccounts, setSandboxLinkedAccounts };
};

export default useLoadLinkedAccounts;
