import { Button, Collapse } from "react-bootstrap";
import { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import DeprecatedH5 from "../../deprecated/DeprecatedH5";

const EMPTY_STRING = "Empty String";

type Props = {
  choices: Array<string>;
  choiceMapping: { [value: string]: string };
  updateChoiceMapping: (choiceMapping: { [value: string]: string }) => void;
};

const CollapsibleChoiceMappingForm = ({ choices, choiceMapping, updateChoiceMapping }: Props) => {
  const [isEnumMappingShown, setIsEnumMappingShown] = useState(false);

  return (
    <div>
      <Collapse in={isEnumMappingShown}>
        <div className="pl-9 mt-6 vertical-line">
          {choices.map((choice) => (
            <div className="mb-3" key={choice}>
              <DeprecatedH5>{choice}</DeprecatedH5>
              <Typeahead
                allowNew
                multiple
                selected={Object.keys(choiceMapping ?? {})
                  .filter((val) => choiceMapping?.[val] === choice)
                  .map((val) => ({
                    customOption: true,
                    label: val === "" ? EMPTY_STRING : val,
                  }))}
                options={[
                  {
                    customOption: true,
                    label: EMPTY_STRING,
                  },
                ]}
                id="typeahead"
                inputProps={{ autoComplete: "none" }}
                newSelectionPrefix={"Assign new value: "}
                onChange={(newValues) => {
                  const newChoiceMapping = Object.assign({}, choiceMapping) ?? {};
                  Object.keys(newChoiceMapping ?? {})
                    .filter((val) => newChoiceMapping?.[val] === choice)
                    .map((val) => delete newChoiceMapping?.[val]);
                  newValues.forEach((newValue) => {
                    const val = newValue.label === EMPTY_STRING ? "" : newValue.label;
                    newChoiceMapping[val] = choice;
                  });
                  updateChoiceMapping(newChoiceMapping);
                }}
              />
            </div>
          ))}
        </div>
      </Collapse>
      <Button
        variant="link"
        className="pl-0"
        onClick={() => setIsEnumMappingShown(!isEnumMappingShown)}
      >
        <b>
          {isEnumMappingShown ? (
            <>
              <i className="fe fe-arrow-up mr-1.5" />
              {"Collapse Enum Mapping"}
            </>
          ) : (
            <>
              <i className="fe fe-arrow-down mr-1.5" />
              {"Show Enum Mapping"}
            </>
          )}
        </b>
      </Button>
    </div>
  );
};

export default CollapsibleChoiceMappingForm;
