import classNames from "classnames";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  BlueprintParameterSchemaValue,
  JSONArraySchema,
  JSONObjectSchema,
  JSONSchema,
  JSONSchemaValue,
} from "../../models/Blueprints";
import { getIconForTypeForJSONTreeDiagram } from "./utils/SharedComponentUtils";

const NestedSchemaContainer = styled(Row)`
  flex-wrap: nowrap;
`;

const OuterSchemaContainer = styled.div`
  overflow-x: scroll;
`;

interface Props {
  stepID: string;
  jsonSchema: JSONSchema | BlueprintParameterSchemaValue;
}

const JSONSchemaTreeDiagram = ({ jsonSchema, stepID }: Props) => {
  const getHTMLFromJSONSchema = (
    schema: JSONSchemaValue | BlueprintParameterSchemaValue,
    key: string
  ): JSX.Element[] => {
    const currentHTML: JSX.Element[] = [];
    const valueType = schema.type;
    const reactKey = `${stepID}.${key}`;
    const propertyName = key.includes(".") ? key.split(".").slice(-1)[0] : key;

    currentHTML.push(
      <div
        className={classNames(
          "text-monospace var-label d-flex align-items-center text-align-center",
          !valueType && "text-danger"
        )}
        key={`${reactKey}-icon-div`}
      >
        {getIconForTypeForJSONTreeDiagram(valueType)}
        {valueType === "array" &&
          (schema as JSONArraySchema).items?.type !== "object" &&
          getIconForTypeForJSONTreeDiagram((schema as JSONArraySchema).items?.type)}
        <span>{propertyName}</span>
      </div>
    );

    if (valueType === "object") {
      currentHTML.push(
        <NestedSchemaContainer key={`${reactKey}-row`}>
          <Col className="text-center json-schema--vertical-line" />
          <Col className="collapse show" id={`${reactKey}-collapse`}>
            {Object.entries((schema as JSONObjectSchema).properties ?? {}).map(
              ([itemKey, value]) => {
                const fullItemKey = `${key}.${itemKey}`;
                return getHTMLFromJSONSchema(value, fullItemKey);
              }
            )}
          </Col>
        </NestedSchemaContainer>
      );
    }

    if (valueType === "array") {
      const items = (schema as JSONArraySchema).items;

      switch (items?.type) {
        case "array":
          currentHTML.push(
            <Row className="row" key={`${reactKey}-array-array-row`}>
              <Col className="text-center json-schema--vertical-line" />
              <Col className="collapse show" id={`${reactKey}-array-array-collapse`}>
                <>
                  {"properties" in items &&
                    getHTMLFromJSONSchema(
                      (items as JSONArraySchema).items,
                      `${reactKey}.arrayitems`
                    )}
                </>
              </Col>
            </Row>
          );
          break;
        case "object":
          currentHTML.push(
            <Row key={`${reactKey}-array-object-row`}>
              <Col className="text-center json-schema--vertical-line" />
              <Col className="collapse show" id={`${reactKey}-array-object-collapse`}>
                {Object.entries((items as JSONObjectSchema).properties ?? {}).map(
                  ([itemKey, value]) => {
                    const fullItemKey = `${key}.${itemKey}`;
                    return getHTMLFromJSONSchema(value, fullItemKey);
                  }
                )}
              </Col>
            </Row>
          );
          break;
        default:
          break;
      }
    }
    return currentHTML;
  };

  return (
    <OuterSchemaContainer style={{ overflowX: "scroll" }}>
      {getHTMLFromJSONSchema(jsonSchema, stepID)}
    </OuterSchemaContainer>
  );
};

export default JSONSchemaTreeDiagram;
