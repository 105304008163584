import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { StatusBadge } from "../../shared/MergeBadges";
import { APIEndpoint } from "../../../models/Entities";
import { fetchWithAuth } from "../../../api-client/api_client";
import EmptyStateWrapper from "../../shared/layout/EmptyStateWrapper";
import { OverlayTrigger, Tooltip, Table, Button, Card } from "react-bootstrap";
import { showSuccessToast, showErrorToast } from "../../shared/Toasts";
import DeletionConfirmationModal from "../../shared/DeletionConfirmationModal";
import { duplicateEndpoint } from "../utils/IntegrationsAPIClient";
import { BlueprintStatus } from "../../../models/Blueprints";

type RouteParams = {
  integrationID: string;
  methodType: string;
};

function APIEndpointsTable() {
  const history = useHistory();
  const { integrationID, methodType } = useParams<RouteParams>();
  const [APIEndpoints, setAPIEndpoints] = useState<APIEndpoint[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [
    isShowingConfirmDeleteAPIEndpointModal,
    setIsShowingConfirmDeleteAPIEndpointModal,
  ] = useState<boolean>(false);
  const [selectedAPIEndpointID, setSelectedAPIEndpointID] = useState<string | undefined>();
  const [selectedAPIEndpointName, setSelectedAPIEndpointName] = useState<string | undefined>();
  const [activeEndpoints, setActiveEndpoints] = useState<string[]>([]);

  const viewEditEndpoint = (APIEndpointID: string, event: React.MouseEvent<HTMLDivElement>) => {
    const path = `/integrations/${integrationID}/api-endpoints/${APIEndpointID}/edit/converter`;

    if (event.metaKey || event.ctrlKey) {
      window.open(path, "_blank");
    } else {
      history.push({
        pathname: `/integrations/${integrationID}/api-endpoints/${APIEndpointID}/edit/converter`,
      });
    }
  };

  useEffect(() => {
    fetchAPIEndpoints();
    fetchActiveEndpoints();
  }, [integrationID]);

  const fetchAPIEndpoints = () => {
    fetchWithAuth({
      path: `/integrations/${integrationID}/api-endpoints`,
      method: "GET",
      onResponse: (data) => {
        setAPIEndpoints(data);
      },
    });
  };

  const deleteAPIEndpoint = (APIEndpointID: string | undefined) => {
    setIsLoading(true);
    fetchWithAuth({
      path: `/integrations/${integrationID}/api-endpoints/${APIEndpointID}`,
      method: "DELETE",
      onResponse: () => {
        showSuccessToast(`Successfully deleted API endpoint!`);
        setIsShowingConfirmDeleteAPIEndpointModal(false);
        fetchAPIEndpoints();
        setIsLoading(false);
      },
      onError: () => {
        showErrorToast(`Failed to delete API endpoint.`);
        setIsLoading(false);
      },
    });
  };

  const fetchActiveEndpoints = () => {
    fetchWithAuth({
      path: `/integrations/${integrationID}/blueprints`,
      method: "GET",
      onResponse: (data) => {
        let arrEndpoints = [];
        for (var blueprint of data.blueprints) {
          if (blueprint.status !== BlueprintStatus.Archived) {
            for (var endpoint of blueprint.endpoints) {
              if (endpoint?.id) {
                arrEndpoints.push(endpoint.id);
              }
            }
          }
        }
        setActiveEndpoints(arrEndpoints);
      },
    });
  };

  return (
    <>
      <DeletionConfirmationModal
        selectedObjectType="API Endpoint"
        show={isShowingConfirmDeleteAPIEndpointModal}
        onHide={() => setIsShowingConfirmDeleteAPIEndpointModal(false)}
        isLoading={isLoading}
        onConfirm={() => deleteAPIEndpoint(selectedAPIEndpointID)}
        objectName={selectedAPIEndpointName}
      />

      <Card>
        <Table responsive hover={APIEndpoints && APIEndpoints.length > 0} className="table-nowrap">
          <thead className="table-borderless">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Method</th>
              <th scope="col">Path</th>
              <th scope="col" style={{ display: "flex", justifyContent: "flex-end" }}>
                <Link to={`/integration-builder/${integrationID}/api-endpoints/create`}>
                  <Button variant="white" size="sm" className="mr-3">
                    <div className="d-flex justify-content-center">
                      <i className="fe fe-plus mr-1.5" />
                      Create endpoint
                    </div>
                  </Button>
                </Link>
              </th>
            </tr>
          </thead>
          <tbody>
            {APIEndpoints ? (
              APIEndpoints.length > 0 ? (
                APIEndpoints.filter(
                  (apiEndpoint: APIEndpoint) =>
                    methodType === "all" || apiEndpoint.method === methodType.toUpperCase()
                )
                  .sort()
                  .map((APIEndpoint: APIEndpoint) => (
                    <tr className="table-link" key={APIEndpoint.id}>
                      <td
                        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                          viewEditEndpoint(APIEndpoint.id, event)
                        }
                      >
                        {APIEndpoint.name}
                      </td>
                      <td
                        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                          viewEditEndpoint(APIEndpoint.id, event)
                        }
                      >
                        {APIEndpoint.method}
                      </td>
                      <td
                        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                          viewEditEndpoint(APIEndpoint.id, event)
                        }
                      >
                        <StatusBadge status={APIEndpoint.path} />
                      </td>
                      <td className="text-right">
                        <Button
                          className="mr-3"
                          variant="white"
                          size="sm"
                          onClick={() =>
                            duplicateEndpoint({
                              apiEndpointID: APIEndpoint.id,
                              integrationID,
                              onSuccess: () => {
                                showSuccessToast("Successfully duplicated endpoint!");
                                fetchAPIEndpoints();
                                setIsLoading(false);
                              },
                              onError: () => {
                                showErrorToast("Save failed. Duplicate may already exist.");
                                setIsLoading(false);
                              },
                            })
                          }
                        >
                          <i className="fe fe-copy" /> Duplicate
                        </Button>
                        <Button
                          className="mr-3"
                          variant="white"
                          size="sm"
                          onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                            viewEditEndpoint(APIEndpoint.id, event)
                          }
                        >
                          <i className="fe fe-edit-2" /> Edit
                        </Button>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="custom-function-info-tooltip">
                              {activeEndpoints.includes(APIEndpoint.id)
                                ? "An active blueprint is using this endpoint."
                                : "Available to delete, as no active blueprints are using this endpoint."}
                            </Tooltip>
                          }
                        >
                          <Button
                            disabled={activeEndpoints.includes(APIEndpoint.id)}
                            className=""
                            variant="danger"
                            size="sm"
                            onClick={() => {
                              setIsShowingConfirmDeleteAPIEndpointModal(true);
                              setSelectedAPIEndpointID(APIEndpoint.id);
                              setSelectedAPIEndpointName(APIEndpoint.name);
                            }}
                          >
                            <i className="fe fe-trash-2 mr-1" /> Delete
                          </Button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={4} className="p-0">
                    <EmptyStateWrapper isTable title="No API endpoints" />
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan={4} className="p-0">
                  <EmptyStateWrapper isTable isSpinner />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
    </>
  );
}

export default APIEndpointsTable;
