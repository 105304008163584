import { Alert, Text, Toggle } from "@merge-api/merge-javascript-shared";
import { ChecklistStatusValue, FIELD_NAME_TO_WARNING_TEXT } from "../../../../constants";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { AlertTriangle, Info } from "lucide-react";
import { palette } from "../../../../styles/theme/colors";
import { useState } from "react";

type Props = {
  checklistItem: string;
  checklistValue: ChecklistStatusValue;
  integrationID: string;
  fetchIntegrationChecklist: () => void;
};

const IntegrationChecklistIssue = ({
  checklistItem,
  checklistValue,
  integrationID,
  fetchIntegrationChecklist,
}: Props) => {
  const [isToggled, setIsToggled] = useState<boolean>(
    checklistValue !== ChecklistStatusValue.NOT_SUPPORTED
  );

  const changeSupportStatus = (status: boolean) => {
    if (!status) {
      fetchWithAuth({
        path: `/integrations/${integrationID}/integration-checklist/mark-unsupported`,
        method: "POST",
        body: {
          field_name: checklistItem,
        },
        onResponse: () => {
          fetchIntegrationChecklist();
        },
        onError: () => {},
      });
    } else {
      fetchWithAuth({
        path: `/integrations/${integrationID}/integration-checklist/mark-supported`,
        method: "POST",
        body: {
          field_name: checklistItem,
        },
        onResponse: () => {
          fetchIntegrationChecklist();
        },
        onError: () => {},
      });
    }
  };

  return (
    //TODO: @ken add links to integration builder pages
    <Alert
      className="mb-4"
      color={checklistValue === ChecklistStatusValue.NOT_SUPPORTED ? "gray" : "yellow"}
      size="sm"
      icon={
        checklistValue === ChecklistStatusValue.NOT_SUPPORTED ? (
          <Info color={palette.gray} size={16} />
        ) : (
          <AlertTriangle color={palette.yellow} size={16} />
        )
      }
      title={
        <div className=" flex w-full flex-row items-center justify-between">
          <Text variant="sm">
            {/* @ts-ignore it does not understand I am mapping keys of IntegrationChecklistData for some reason */}
            {"Missing " + FIELD_NAME_TO_WARNING_TEXT[checklistItem] ?? checklistItem}
          </Text>
          <div className="flex flex-row items-center">
            <Text className="mr-2" variant="sm">
              {checklistValue === ChecklistStatusValue.NOT_SUPPORTED
                ? "Not supported"
                : "Supported"}
            </Text>
            <Toggle
              checked={isToggled}
              variant="secondary"
              onChange={(value) => {
                changeSupportStatus(value);
                setIsToggled(value);
              }}
            ></Toggle>
          </div>
        </div>
      }
    />
  );
};

export default IntegrationChecklistIssue;
