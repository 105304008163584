import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";

type Props = {
  textToCopy: string | undefined;
};

const MergeCopyToClipboard = ({ textToCopy }: Props) => {
  const [hasCopiedToClipboard, setHasCopiedToClipboard] = useState<boolean>(false);

  useEffect(() => {
    setHasCopiedToClipboard(false);
  }, [textToCopy]);

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 0, hide: 0 }}
      overlay={<Tooltip id={"tooltip-copy-"}>{hasCopiedToClipboard ? "Copied!" : "Copy"}</Tooltip>}
    >
      <CopyToClipboard
        text={textToCopy ?? ""}
        onCopy={() => {
          setHasCopiedToClipboard(true);
        }}
      >
        <i className="fe fe-copy mr-3 cursor-pointer" />
      </CopyToClipboard>
    </OverlayTrigger>
  );
};

export default MergeCopyToClipboard;
