import { useState } from "react";
import {
  AsyncTaskExecution,
  BlueprintRunnerExecutionResponse,
  WebConnectorSession,
  WebConnectorSessionTestRunRequests,
} from "../../../../models/Blueprints";
import BlueprintLeftPanelConsoleResultLogs from "../left-panel-console/BlueprintLeftPanelConsoleResultLogs";
import BlueprintLeftPanelConsoleResultSummary from "../left-panel-console/BlueprintLeftPanelConsoleResultSummary";
import LeftPanelSectionHeader from "../shared/LeftPanelSectionHeader";
import { useFetchBlueprintRunnerExecution } from "./hooks/useFetchBlueprintRunnerExecution";
import WebConnectorLiveRunStatusBadge from "./WebConnectorLiveRunStatusBadge";
import { Accordion, Button, ButtonVariant, Card, Text } from "@merge-api/merge-javascript-shared";
import { ArrowLeft, ChevronRight } from "lucide-react";

interface WebConnectorLiveRunResultsProps {
  webConnectorSessionResponse: WebConnectorSession | null;
  isTestingBlueprintAsync: boolean;
  isLoadingLiveRun: boolean;
  stopWaitingOnAsyncTaskExecution: () => void;
  asyncTaskExecutionResult?: AsyncTaskExecution;
  blueprintRunnerExecutionResponse: BlueprintRunnerExecutionResponse | null;
  isTestingBlueprint: boolean;
  isShowingModelsModal: boolean;
  setIsShowingModelsModal: (x: boolean) => void;
}
const WebConnectorLiveRunResults = ({
  webConnectorSessionResponse,
  isTestingBlueprintAsync,
  stopWaitingOnAsyncTaskExecution,
  asyncTaskExecutionResult,
  blueprintRunnerExecutionResponse,
  isTestingBlueprint,
  isShowingModelsModal,
  setIsShowingModelsModal,
}: WebConnectorLiveRunResultsProps) => {
  // State to control visibility of execution details
  const [showExecution, setShowExecution] = useState<boolean>(false);

  // State to track selected request's details
  const [selectedRequest, setSelectedRequest] = useState<WebConnectorSessionTestRunRequests | null>(
    null
  );

  // hooks
  const { fetchBlueprintRunnerExecution } = useFetchBlueprintRunnerExecution();

  // Handler for clicking on a specific `test_run_request` card
  const handleRequestClick = (request: WebConnectorSessionTestRunRequests) => {
    setShowExecution(true);
    setSelectedRequest(request);

    if (request.blueprint_execution_id) {
      fetchBlueprintRunnerExecution(request.blueprint_execution_id);
    }
  };

  return (
    <>
      {webConnectorSessionResponse && (
        <>
          {!showExecution ? (
            <LeftPanelSectionHeader
              title="Results"
              badge={
                <WebConnectorLiveRunStatusBadge
                  webConnectorSessionResponse={webConnectorSessionResponse}
                />
              }
            />
          ) : (
            <Button
              leftIcon={<ArrowLeft size={16} />}
              variant={ButtonVariant.TextBlue}
              className="-mb-4"
              onClick={() => {
                setShowExecution(false);
                setSelectedRequest(null);
              }}
            >
              Back to results
            </Button>
          )}
        </>
      )}
      {webConnectorSessionResponse?.test_run_requests && (
        <div className="space-y-4 my-4">
          {webConnectorSessionResponse.test_run_requests.map((request, index) => (
            <>
              {!showExecution && (
                <Card
                  variant="outline"
                  key={index}
                  type="button"
                  className="p-4 flex items-center justify-between"
                  onClick={() => handleRequestClick(request)}
                >
                  <Text as="div" variant="h6">
                    Blueprint execution {index + 1}
                  </Text>
                  <ChevronRight className="text-gray-60" size={16} />
                </Card>
              )}
            </>
          ))}
        </div>
      )}
      {showExecution && selectedRequest && (
        <>
          {/* Display selected hresult and message */}
          {selectedRequest.hresult && <Text as="div">{selectedRequest.hresult}</Text>}
          {selectedRequest.message && (
            <Text className="mb-4" as="div">
              {selectedRequest.message}
            </Text>
          )}

          {/* Accordion and detailed view */}
          <Accordion
            chevronOrientation="right"
            defaultExpanded={true}
            chevronPlacement="end"
            chevronColor="gray"
            chevronSize={16}
            className="mb-4"
            title={
              <Text as="div" variant="h6" className="mb-2">
                sendRequestXML query
              </Text>
            }
            variant="none"
          >
            <Text as="div" variant="md-mono">
              {selectedRequest.request}
            </Text>
          </Accordion>

          {/* Console Summary and Logs */}
          <div>
            <BlueprintLeftPanelConsoleResultSummary
              isTestingBlueprintAsync={isTestingBlueprintAsync}
              stopWaitingOnAsyncTaskExecution={stopWaitingOnAsyncTaskExecution}
              asyncTaskExecutionResult={asyncTaskExecutionResult}
              blueprintRunnerExecutionResponse={blueprintRunnerExecutionResponse}
              isTestingBlueprint={isTestingBlueprint}
              isShowingModelsModal={isShowingModelsModal}
              setIsShowingModelsModal={setIsShowingModelsModal}
            />
          </div>
          <div>
            <BlueprintLeftPanelConsoleResultLogs
              blueprintRunnerExecutionResponse={blueprintRunnerExecutionResponse}
            />
          </div>
        </>
      )}
    </>
  );
};

export default WebConnectorLiveRunResults;
