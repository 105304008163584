import { Button, Text } from "@merge-api/merge-javascript-shared";
import { Plus } from "lucide-react";

interface Props {
  onCreateBlueprint: () => void;
}

const BlueprintsSearchHeader = ({ onCreateBlueprint }: Props) => {
  return (
    <div className="flex flex-row justify-between items-center">
      <Text variant="h3">Blueprints</Text>
      <Button onClick={onCreateBlueprint} leftIcon={<Plus size={16} />}>
        Blueprint
      </Button>
    </div>
  );
};

export default BlueprintsSearchHeader;
