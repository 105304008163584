import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { APIEndpointMethod } from "../../../../models/Entities";
import {
  navigateToIntegrationBuilderPaginationConfigurationForm,
  navigateToIntegrationBuilderPaginationConfigurationTable,
} from "../../../../router/RouterUtils";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import IntegrationBuilderContext from "../../context/IntegrationBuilderContext";
import { PaginationConfigurationIntegrationBuilder } from "../../utils/Entities";

interface CreateOrPatchPaginationConfiguration {
  setPaginationConfigurations: React.Dispatch<
    React.SetStateAction<PaginationConfigurationIntegrationBuilder[] | undefined>
  >;
  integrationID: string;
}

const useCreateOrPatchPaginationConfiguration = ({
  setPaginationConfigurations,
  integrationID,
}: CreateOrPatchPaginationConfiguration) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  // context
  const history = useHistory();
  const { setIsPaginationConfigurationComplete, resetDiffStates } = useContext(
    IntegrationBuilderContext
  );

  // function to create a new pagination configuration
  // returns a promise that resolves with the new pagination configuration data
  const createPaginationConfiguration = (
    data: PaginationConfigurationIntegrationBuilder,
    shouldNavigate: boolean = true
  ): Promise<PaginationConfigurationIntegrationBuilder> => {
    setIsLoadingSubmit(true);
    return new Promise((resolve, reject) =>
      fetchWithAuth({
        path: `/integrations/integration-builder/integration/${integrationID}/pagination-configurations`,
        method: APIEndpointMethod.POST,
        body: data,
        onResponse: (responseData: PaginationConfigurationIntegrationBuilder) => {
          showSuccessToast("Pagination configuration successfully created!");
          // Reset diff state before navigating, so that Unsaved modal doesn't pop up
          resetDiffStates();
          // navigates to url for pagination configuration with id
          if (responseData.id) {
            resolve(responseData); // Resolve the promise with the pagination configuration data
            shouldNavigate &&
              navigateToIntegrationBuilderPaginationConfigurationForm(
                history,
                integrationID,
                responseData.id
              );
          } else {
            shouldNavigate &&
              navigateToIntegrationBuilderPaginationConfigurationTable(history, integrationID);
            reject(new Error("Pagination configuration created without an ID."));
          }
          // add new pagination configuration to paginationConfigurations array
          // if we updated the endpoint to be the default, we need to update the previous default to be false
          setPaginationConfigurations((prevPaginationConfigs) => {
            const updatedPrevPaginationConfigs = prevPaginationConfigs?.map((endpoint) => {
              if (responseData.is_default_for_integration) {
                return { ...endpoint, is_default_for_integration: false };
              }
              return endpoint;
            });
            return updatedPrevPaginationConfigs
              ? [...updatedPrevPaginationConfigs, responseData]
              : [responseData];
          });
          setIsPaginationConfigurationComplete(true);
          setIsLoadingSubmit(false);
        },
        onError: () => {
          showErrorToast("Failed to create your pagination configuration.");
          setIsLoadingSubmit(false);
          reject(new Error("Failed to create pagination configuration."));
        },
      })
    );
  };

  const patchPaginationConfiguration = (data: PaginationConfigurationIntegrationBuilder) => {
    setIsLoadingSubmit(true);
    fetchWithAuth({
      path: `/integrations/integration-builder/pagination-configuration/${data.id}`,
      method: APIEndpointMethod.PATCH,
      body: data,
      onResponse: (responseData: PaginationConfigurationIntegrationBuilder) => {
        showSuccessToast("Pagination configuration successfully updated!");
        setPaginationConfigurations((prevPaginationConfigs) => {
          return prevPaginationConfigs?.map((endpoint) => {
            if (endpoint.id === responseData.id) {
              return responseData;
            }
            if (responseData.is_default_for_integration) {
              return { ...endpoint, is_default_for_integration: false };
            }
            return endpoint;
          });
        });
        // Reset diff state before navigating, so that Unsaved modal doesn't pop up
        resetDiffStates();
        // Navigate
        if (responseData.id) {
          navigateToIntegrationBuilderPaginationConfigurationForm(
            history,
            integrationID,
            responseData.id
          );
        } else {
          navigateToIntegrationBuilderPaginationConfigurationTable(history, integrationID);
        }
        setIsPaginationConfigurationComplete(true);
        setIsLoadingSubmit(false);
      },
      onError: () => {
        showErrorToast("Failed to update your pagination configuration.");
        setIsLoadingSubmit(false);
      },
    });
  };

  return { createPaginationConfiguration, patchPaginationConfiguration, isLoadingSubmit };
};

export default useCreateOrPatchPaginationConfiguration;
