import { useEffect, useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast } from "../../../shared/Toasts";
import { IntegrationSetupChecklistItem } from "../../utils/Entities";

const useLoadIntegrationSetupChecklistItems = () => {
  const [integrationSetupChecklistItems, setIntegrationSetupChecklistItems] = useState<
    IntegrationSetupChecklistItem[] | undefined
  >();

  useEffect(() => {
    fetchWithAuth({
      path: `/integrations/integration-setup-checklist-items/`,
      method: "GET",
      onResponse: (data: IntegrationSetupChecklistItem[]) => {
        setIntegrationSetupChecklistItems(data);
      },
      onError: () => {
        showErrorToast("Failed to fetch integration setup checklist items.");
      },
    });
  }, [setIntegrationSetupChecklistItems]);

  return { integrationSetupChecklistItems };
};

export default useLoadIntegrationSetupChecklistItems;
