import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import {
  BlueprintCanvasViewableStepLog,
  BlueprintCanvasViewableStepLogBase,
} from "../../../models/Blueprints";
import { ArrowUpRight, Eye, EyeOff } from "lucide-react";
import StepLogLoopDropdown from "./step-log-buttons-header/StepLogLoopDropdown";
import StepIOLogModal from "../../blueprint-editor/shared/blueprint-editor-step-io-log-modal/StepIOLogModal";
import { useState } from "react";
import useBlueprintContext from "../../blueprint-editor/context/useBlueprintContext";
import StepCardStepLogParentInfo from "./StepCardStepLogParentInfo";
import StepLogIconButton from "./step-log-buttons-header/StepLogIconButton";

interface itemProps {
  title: string;
  itemIndex: number | undefined;
  totalItems: number | undefined;
  handleSelectItem: (selectedItemIndex: number) => void;
}

interface Props {
  stepLog: BlueprintCanvasViewableStepLog | BlueprintCanvasViewableStepLogBase;
  isHighlightedStepLog: boolean;
  itemInfo: itemProps | undefined;
}

/**
 * Renders the button headers for step log iterations and API request pages.
 * This component generates dropdown menus for selecting specific iterations or pages
 * when the step log represents a loop or paginated API call.
 *
 * @param stepLog - The viewable step log data for the current step
 * @param title - The title to display in the dropdown menu
 * @param itemIndex - The current index of the item (iteration or page)
 * @param totalItems - The total number of items (iterations or pages), if known
 * @param handleSelectItem - Callback function to handle selection of a specific item (iteration or page)
 */
const StepCardStepLogButtonsHeader = ({ stepLog, isHighlightedStepLog, itemInfo }: Props) => {
  const { setSelectedStepLog } = useBlueprintContext();
  const handleSelectStepLog = () => {
    setSelectedStepLog({
      step_id: stepLog.step_io_log.step_id,
      index_in_raw_logs: stepLog.index_in_raw_logs,
    });
  };
  const handleUnselectStepLog = () => {
    setSelectedStepLog(undefined);
  };

  const stepID = stepLog.step_io_log.step_id;
  const showParentInfoInSecondLine = !!itemInfo;
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <div className="flex flex-col space-y-1">
      <div className="flex flex-row space-x-2 justify-between items-center h-[22px]">
        {itemInfo ? (
          itemInfo.itemIndex !== undefined ? (
            <StepLogLoopDropdown
              stepID={stepID}
              title={itemInfo.title}
              itemIndex={itemInfo.itemIndex}
              totalItems={itemInfo.totalItems}
              handleSelectItem={itemInfo.handleSelectItem}
            />
          ) : (
            <Button
              size="sm"
              variant={ButtonVariant.TextBlue}
              onClick={(event: any) => {
                if (event) event.stopPropagation();
                handleSelectStepLog();
              }}
            >
              {itemInfo.title}
            </Button>
          )
        ) : (
          <StepCardStepLogParentInfo stepLog={stepLog} />
        )}
        <div className="flex flex-row space-x-2 justify-end ml-auto">
          {isHighlightedStepLog ? (
            <StepLogIconButton
              icon={<EyeOff size={12} className="text-blue-40" />}
              tooltipTitle="Un-focus from step I/O"
              onClick={handleUnselectStepLog}
            />
          ) : (
            <StepLogIconButton
              icon={<Eye size={12} className="text-blue-40" />}
              tooltipTitle="Focus on step I/O"
              onClick={handleSelectStepLog}
            />
          )}
          <StepLogIconButton
            icon={<ArrowUpRight size={12} className="text-blue-40" />}
            tooltipTitle="View in modal"
            onClick={() => setShowModal(true)}
          />
        </div>
        <StepIOLogModal stepIOLog={stepLog.step_io_log} open={showModal} setOpen={setShowModal} />
      </div>
      {showParentInfoInSecondLine && <StepCardStepLogParentInfo stepLog={stepLog} />}
    </div>
  );
};

export default StepCardStepLogButtonsHeader;
