import { HeaderPretitle } from "../../shared/text/MergeText";
import { BlueprintStepTemplate } from "../../../models/Blueprints";
import useBlueprintContext from "../context/useBlueprintContext";
import { Form } from "react-bootstrap";

interface Props {
  stepID: string;
  stepTemplate: BlueprintStepTemplate;
}

const BlueprintEditorRightPanelEndBlueprintForm = (props: Props) => {
  const { selectedStep, setStepClosesPaginationSequence } = useBlueprintContext();

  const closesPaginationSequence =
    selectedStep && selectedStep.template && selectedStep.template !== "ghost"
      ? selectedStep.pagination_complete_on_end
      : false;

  const updateClosesPaginationSequence = () => {
    setStepClosesPaginationSequence(props.stepID, !closesPaginationSequence);
  };

  return (
    <>
      <Form.Group className="mb-9">
        <Form.Check
          key={`close_pagination-loop-toggle-${props.stepID}-key`}
          id={`close-pagination-loop-toggle-${props.stepID}-id`}
          type="switch"
          label="Close Pagination Loop"
          defaultChecked={closesPaginationSequence}
          onChange={updateClosesPaginationSequence}
        />
      </Form.Group>
      <HeaderPretitle className="mt-3">End Blueprint with 200 status code</HeaderPretitle>
    </>
  );
};

export default BlueprintEditorRightPanelEndBlueprintForm;
