import { Button, ButtonVariant, Tooltip } from "@merge-api/merge-javascript-shared";

interface Props {
  icon: React.ReactNode;
  tooltipTitle: string;
  onClick: () => void;
}

const StepLogIconButton = ({ icon, tooltipTitle, onClick }: Props) => {
  return (
    <Tooltip title={tooltipTitle}>
      <Button
        variant={ButtonVariant.TextBlue}
        onClick={(event: any) => {
          // Prevent registering "click" on step log card itself
          if (event) event.stopPropagation();
          onClick();
        }}
      >
        {icon}
      </Button>
    </Tooltip>
  );
};

export default StepLogIconButton;
