import { Alert, Text } from "@merge-api/merge-javascript-shared";

interface Props {
  children?: string | null;
}

const MergeCodeBlockText = ({ children }: Props) => {
  return (
    <div className="flex flex-col space-y-2 p-3">
      <Alert
        color="gray"
        showWarningIcon
        title="Syntax highlighting not rendered, due to large text size"
      />
      <Text as="pre" variant="md-mono" className="text-white">
        {children}
      </Text>
    </div>
  );
};

export default MergeCodeBlockText;
