import { BaseColor } from "@merge-api/merge-javascript-shared/dist/designSystem/types";
import { APICategory, IntegrationCategoryStatusInfo } from "../../../../../models/Entities";
import { INTEGRATION_CATEGORY_STATUSES } from "../../../../../constants";

export const abbreviationForAPICategory = (category: APICategory): string => {
  switch (category) {
    case APICategory.ats:
      return "ATS";
    case APICategory.hris:
      return "HRIS";
    case APICategory.accounting:
      return "ACCT";
    case APICategory.ticketing:
      return "TCKT";
    case APICategory.crm:
      return "CRM";
    case APICategory.mktg:
      return "MKTG";
    case APICategory.filestorage:
      return "FILE";
    case APICategory.datawarehouse:
      return "DATA";
  }
};

export const colorForAPICategory = (category: APICategory): BaseColor => {
  switch (category) {
    case APICategory.ats:
      return "purple";
    case APICategory.hris:
      return "red";
    case APICategory.accounting:
      return "teal";
    case APICategory.ticketing:
      return "amber";
    case APICategory.crm:
      return "blue";
    case APICategory.mktg:
      return "yellow";
    case APICategory.filestorage:
      return "pink";
    case APICategory.datawarehouse:
      return "gray";
  }
};

export const getCategoryStatusTextInfo = (statusInfo: IntegrationCategoryStatusInfo) => {
  switch (statusInfo.status) {
    case INTEGRATION_CATEGORY_STATUSES.ACTIVE:
      return {
        statusText: "Active",
      };
    case INTEGRATION_CATEGORY_STATUSES.IN_BETA:
      return {
        statusText: "Beta",
      };
    default:
      return {
        statusText: "Inactive",
      };
  }
};

export const isCategoryStatusBeta = (statusInfo: IntegrationCategoryStatusInfo): boolean => {
  return statusInfo.status === INTEGRATION_CATEGORY_STATUSES.IN_BETA;
};

export const isCategoryStatusInactive = (statusInfo: IntegrationCategoryStatusInfo): boolean => {
  return (
    statusInfo.status !== INTEGRATION_CATEGORY_STATUSES.ACTIVE &&
    statusInfo.status !== INTEGRATION_CATEGORY_STATUSES.IN_BETA
  );
};

export const isCategoryStatusActiveOrBeta = (
  statusInfo: IntegrationCategoryStatusInfo
): boolean => {
  return (
    statusInfo.status === INTEGRATION_CATEGORY_STATUSES.IN_BETA ||
    statusInfo.status === INTEGRATION_CATEGORY_STATUSES.ACTIVE
  );
};
