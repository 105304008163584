import { Dialog, Text, TextField } from "@merge-api/merge-javascript-shared";

interface Props {
  open: boolean;
  onClose: () => void;
  inputPrefix?: string;
  inputSuffix?: string;
  inputPlaceholder?: string;
}

const InputPreviewModal = ({
  open,
  onClose,
  inputPrefix,
  inputSuffix,
  inputPlaceholder,
}: Props) => {
  return (
    <Dialog variant="md" footerButtonsHidden open={open} onClose={onClose} title="Preview input">
      <div className="flex flex-column space-y-3">
        <TextField
          prefixVariant="url"
          prefix={inputPrefix}
          postfix={inputSuffix && <Text className="px-3 py-2.5">{inputSuffix}</Text>}
          placeholder={inputPlaceholder}
        />
      </div>
    </Dialog>
  );
};

export default InputPreviewModal;
