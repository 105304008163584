import { Select, Text } from "@merge-api/merge-javascript-shared";

interface Props {
  title: string;
  placeholder?: string;
  onChange:
    | ((
        event:
          | React.MouseEvent<Element, MouseEvent>
          | React.KeyboardEvent<Element>
          | React.FocusEvent<Element, Element>
          | null,
        value: any
      ) => void)
    | undefined;
  value: any;
  options: any[];
  clearable?: boolean;
}

const LeftPanelSelect = ({
  title,
  placeholder,
  onChange,
  value,
  options,
  clearable = true,
}: Props) => {
  return (
    <div className="flex flex-col space-y-2 w-full">
      <Text variant="h6">{title}</Text>
      <Select
        className="w-full"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        options={options}
        clearable={clearable}
      />
    </div>
  );
};

export default LeftPanelSelect;
