import { useCallback, useState } from "react";

import { AddStepRelation } from "../../models/Blueprints";
import ContextMenu from "../shared/ContextMenu";
import StepCard from "../shared/StepCard";
import useScraperContext from "./context/useScraperContext";
import ScraperAddStepArrow from "./ScraperAddStepArrow";
import { ScraperStep } from "./types";
import { getStepConfigForStep } from "./utils/ScraperUtils";
import { showSuccessToast } from "../shared/Toasts";
import {
  ExpandStepsButton,
  isStepTypeWithPaths,
} from "../blueprint-editor/utils/BlueprintEditorUtils";
import StepNotePreview from "../blueprint-editor/canvas/StepNotePreview";

type Props = {
  step: ScraperStep;
};

const ScraperStepCard = ({ step }: Props) => {
  const {
    deleteStep,
    collapseStep,
    setSelectedStep,
    selectedStep,
    setCopiedStep,
  } = useScraperContext();

  const [isShowingContextMenu, setIsShowingContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });

  const onRightClickStep = useCallback((e) => {
    e.preventDefault();
    setIsShowingContextMenu(true);
    setContextMenuPosition({ x: e.pageX, y: e.pageY });
  }, []);

  const config = getStepConfigForStep(step);

  const getContextMenuOptions = () => {
    let baseOptions = [
      {
        label: "Copy Step",
        featherIconName: "copy",
        onClick: () => {
          setIsShowingContextMenu(false);
          navigator.clipboard.writeText(JSON.stringify(selectedStep));
          setCopiedStep(step as ScraperStep);
        },
      },
      {
        label: "Copy Step to Clipboard",
        featherIconName: "copy",
        onClick: () => {
          setIsShowingContextMenu(false);
          navigator.clipboard.writeText(JSON.stringify(selectedStep));
          showSuccessToast("Step copied to clipboard.");
        },
      },
      {
        label: "Cut Step",
        featherIconName: "scissors",
        onClick: () => {
          setIsShowingContextMenu(false);
          setCopiedStep(step as ScraperStep);
          deleteStep(step);
        },
      },
      {
        label: "Delete Step",
        featherIconName: "trash",
        onClick: () => {
          setIsShowingContextMenu(false);
          deleteStep(step);
        },
        confirmationMessage: `Are you sure you want to delete step ${step.id}?`,
      },
    ];

    const stepHasCollapsableChildren = isStepTypeWithPaths(step);
    if (stepHasCollapsableChildren) {
      baseOptions.push({
        label: step.hasCollapsedSubsteps ? "Expand Substeps" : "Collapse Substeps",
        featherIconName: step.hasCollapsedSubsteps ? "arrow-right" : "arrow-left",
        onClick: () => {
          setIsShowingContextMenu(false);
          collapseStep(step.id);
        },
      });
    }

    return baseOptions;
  };

  return (
    <div className="d-flex align-items-center">
      <div>
        <ScraperAddStepArrow step={step} relation={AddStepRelation.SIBLING_BEFORE} />
        <ContextMenu
          items={getContextMenuOptions()}
          isShown={isShowingContextMenu}
          position={contextMenuPosition}
          onClose={() => setIsShowingContextMenu(false)}
        />

        <StepCard
          cardClassName={
            selectedStep?.id === step.id ? "step-card-highlighted" : "step-card-not-highlighted"
          }
          title={config?.description}
          subtitle={step.id}
          stepImg="https://merge-api-production.s3.amazonaws.com/media/Function_8MMT4pH.jpg"
          onClick={() => setSelectedStep(step)}
          onContextMenu={onRightClickStep}
        />

        {step.hasCollapsedSubsteps && <ExpandStepsButton onClick={() => collapseStep(step.id)} />}

        <ScraperAddStepArrow step={step} relation={AddStepRelation.SIBLING_AFTER} />
      </div>
      <StepNotePreview stepNoteText={step.step_note !== undefined ? step.step_note : null} />
    </div>
  );
};

export default ScraperStepCard;
