import React, { useContext, useEffect, useMemo, useRef } from "react";
import { APIEndpointTypes } from "../../../../models/APIEndpointModels";
import { APIEndpointIntegrationBuilder } from "../../utils/Entities";
import APIEndpointContext from "../context/APIEndpointContext";
import APIEndpointAdvancedSetupOptions from "./APIEndpointAdvancedSetupOptions";
import APIEndpointPaginationSetupOptions from "./APIEndpointPaginationSetupOptions";
import { RateLimitFormCallback } from "./APIEndpointSetup";
import BasicAPIEndpointSetupOptions from "./BasicAPIEndpointSetupOptions";
import APIEndpointRateLimitConfigurationSetupOptions from "./rate-limits/APIEndpointRateLimitConfigurationsSetupOptions";
import RequestConfigurationSetupOptions from "./request_configuration_setup/RequestConfigurationSetupOptions";
import ResponseConfigurationSetupOptions from "./response_configuration_setup/ResponseConfigurationSetupOptions";

interface APIEndpointSetupOptionsProps {
  selectedAPIEndpoint?: APIEndpointIntegrationBuilder | undefined;
  registerForm: (formID: string, formFunction: RateLimitFormCallback) => void;
  unregisterForm: (formID: string) => void;
  isAutoScrollToRateLimits: boolean;
}

interface AdditionalContextProps {
  endpointType: string;
}

type APIEndpointSetupOptionsPropsWithContext = APIEndpointSetupOptionsProps &
  AdditionalContextProps;

const APIEndpointSetupOptionsWithContext = React.memo(
  ({
    endpointType,
    selectedAPIEndpoint,
    registerForm,
    unregisterForm,
    isAutoScrollToRateLimits,
  }: APIEndpointSetupOptionsPropsWithContext) => {
    const shouldRenderPaginationOptions = useMemo(
      () => endpointType === APIEndpointTypes.PAGINATION || endpointType === APIEndpointTypes.LIVE,
      [endpointType]
    );

    // Auto-scrolls to "Rate Limit Configuration" section if `isAutoScrollToRateLimits` is true
    const targetRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (isAutoScrollToRateLimits && targetRef.current) {
        targetRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, [isAutoScrollToRateLimits]);

    return (
      <>
        <BasicAPIEndpointSetupOptions />
        <RequestConfigurationSetupOptions />
        <ResponseConfigurationSetupOptions />
        {shouldRenderPaginationOptions && <APIEndpointPaginationSetupOptions />}
        <div ref={targetRef}>
          <APIEndpointRateLimitConfigurationSetupOptions
            selectedAPIEndpoint={selectedAPIEndpoint}
            registerForm={registerForm}
            unregisterForm={unregisterForm}
            isDefaultExpanded={isAutoScrollToRateLimits}
          />
        </div>
        <APIEndpointAdvancedSetupOptions />
      </>
    );
  }
);

// We set up these wrappers to pass the props from the context to the component
// Any changes to the context (even if they are not used in the component) will trigger a re-render
const APIEndpointSetupOptions = ({
  selectedAPIEndpoint,
  registerForm,
  unregisterForm,
  isAutoScrollToRateLimits,
}: APIEndpointSetupOptionsProps) => {
  const { endpointType } = useContext(APIEndpointContext);

  return (
    <APIEndpointSetupOptionsWithContext
      endpointType={endpointType}
      selectedAPIEndpoint={selectedAPIEndpoint}
      registerForm={registerForm}
      unregisterForm={unregisterForm}
      isAutoScrollToRateLimits={isAutoScrollToRateLimits}
    />
  );
};

export default APIEndpointSetupOptions;
