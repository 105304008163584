import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import ClickableContainer from "./ClickableContainer";
import Icon from "./Icon";

type EditableTextProps = {
  originalText: string;
  onSave: (newText: string, onError?: () => void) => void;
  textClassName?: string;
  hasEditShortcut?: boolean;
};

export const EditableText = ({
  originalText,
  onSave,
  textClassName,
  hasEditShortcut,
}: EditableTextProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [text, setText] = useState<string>(originalText);
  const ref = useRef(null);

  const handleKeyDown = useCallback((event) => {
    if (!isEditing && hasEditShortcut) {
      if (event.metaKey && event.key == "e") {
        setIsEditing(true);
        // @ts-ignore For some reason Typescript thinks ref.current will be 'never'
        // because it's initialized with null
        ref.current.focus();
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  // Update text if originalText changes
  useEffect(() => {
    if (text != originalText) {
      setText(originalText);
      setIsEditing(false);
    }
  }, [originalText]);

  const resetTextEdit = () => {
    setIsEditing(false);
    setText(originalText);
  };

  const onSaveNewText = () => {
    onSave(text, resetTextEdit);
    setIsEditing(false);
  };

  return (
    <>
      {isEditing ? (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">
            <input
              tabIndex={0}
              ref={ref}
              className="form-control"
              onChange={(e) => setText(e.target.value)}
              value={text}
              autoComplete="no"
              onKeyDown={(e) => {
                if (e.key == "Escape") {
                  resetTextEdit();
                }
                if (e.key == "Enter") {
                  onSaveNewText();
                }
              }}
              onBlur={() => onSaveNewText()}
            ></input>
          </div>
          <div className="ml-1.5  align-items-center">
            <ClickableContainer>
              <Icon className="text-muted" onClick={() => resetTextEdit()} name="x" />
            </ClickableContainer>
          </div>
        </div>
      ) : (
        <>
          <span className={classNames(textClassName)} onClick={() => setIsEditing(true)}>
            {text}
          </span>
          <ClickableContainer>
            <Icon className="text-muted ml-1.5" onClick={() => setIsEditing(true)} name="edit-2" />
          </ClickableContainer>
        </>
      )}
    </>
  );
};
