import { AuthConfigMeta } from "../../../models/AuthConfig";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import InputFormField from "../../blueprint-editor/right-panel/InputFormField";
import { Col, Row } from "react-bootstrap";
import { showSuccessToast } from "../../shared/Toasts";
import { AuthType } from "../../../models/Entities";
import { navigateToAuthConfigsTable } from "../../../router/RouterUtils";
import { SaveAuthConfigButton, AuthConfigUpdateProps } from "./AuthConfigShared";
import AuthConfigEditFormRightPanel from "./AuthConfigEditFormRightPanel";

type Props = {
  authConfig?: AuthConfigMeta;
  integrationID: string;
  integrationCategory: string | undefined;
  authName: string | undefined;
  isEditingExistingConfig: boolean;
};

const AuthConfigBasicAuthForm = (props: Props) => {
  const history = useHistory();
  const [headerFormat, setHeaderFormat] = useState<undefined | string>(
    props.authConfig?.basic_auth_key_header_format
  );

  const [additionalHeaderFields, setAdditionalHeaderFields] = useState<undefined | string>(
    props.authConfig?.additional_header_fields
  );

  const [additionalAuthFieldKeys, setAdditionalAuthFieldKeys] = useState<undefined | string>(
    props.authConfig?.additional_auth_field_keys
  );

  const [serviceAccountFieldKeys, setServiceAccountFieldKeys] = useState<undefined | string>(
    props.authConfig?.service_account_field_keys
  );

  const [isLoading, setIsLoading] = useState(false);

  const authConfigUpdates: AuthConfigUpdateProps = {
    id: props.authConfig?.id,
    authType: AuthType.BASIC_AUTH,
    name: props.authName,
    headerFormatForBasicAuth: headerFormat,
    additionalHeaderFields: additionalHeaderFields,
    additionalAuthFields:
      additionalAuthFieldKeys && additionalAuthFieldKeys.length !== 0
        ? additionalAuthFieldKeys.toString().replace(/\s/g, "").split(",")
        : undefined,
    integrationID: props.integrationID,
    integrationCategory: props.integrationCategory,
    serviceAccountFieldKeys:
      serviceAccountFieldKeys && serviceAccountFieldKeys.length !== 0
        ? serviceAccountFieldKeys.toString().replace(/\s/g, "").split(",")
        : undefined,
  };

  return (
    <Row>
      <Col>
        <InputFormField
          currentValue={headerFormat}
          onChange={(headerFormat) => setHeaderFormat(headerFormat)}
          placeholder=""
          title={"Header Format"}
          subtitle={
            "Header format for basic authentication appended to every request. We can use dynamic fields such as {USERNAME} as well as encoding by wrapping in {BASE_64}"
          }
        />

        <InputFormField
          currentValue={additionalHeaderFields}
          onChange={(additionalHeaderFields) => setAdditionalHeaderFields(additionalHeaderFields)}
          placeholder=""
          title={"Additional Header Fields"}
          subtitle={
            "If integration requires additional auth header fields for every endpoint. Do not add brackets outside of the keys."
          }
        />

        <InputFormField
          currentValue={additionalAuthFieldKeys}
          onChange={(additionalAuthFieldKeys) =>
            setAdditionalAuthFieldKeys(additionalAuthFieldKeys)
          }
          placeholder=""
          title={"Additional Auth Field Keys"}
          subtitle={"Additional field needed for auth setup"}
        />

        <InputFormField
          currentValue={serviceAccountFieldKeys}
          onChange={(serviceAccountFieldKeys) =>
            setServiceAccountFieldKeys(serviceAccountFieldKeys)
          }
          placeholder=""
          title={"Service Account Field Keys"}
          subtitle={
            "If integration requires service account setup, gives a list of keys of the service account to display to the end user during account setup in the linking flow."
          }
        />
      </Col>
      <SaveAuthConfigButton
        text={
          !props.isEditingExistingConfig
            ? "Save configuration"
            : "Save and continue editing auth configuration"
        }
        onSuccess={() => {
          showSuccessToast(
            props.authConfig
              ? "Successfully updated configuration!"
              : "Successfully created configuration!"
          );
          setIsLoading(false);
          if (!props.isEditingExistingConfig == true) {
            navigateToAuthConfigsTable(history, props.integrationID);
          }
        }}
        setLoading={setIsLoading}
        isLoading={isLoading}
        authConfigUpdateProps={authConfigUpdates}
      />

      <AuthConfigEditFormRightPanel authConfigUpdateProps={authConfigUpdates} />
    </Row>
  );
};

export default AuthConfigBasicAuthForm;
