import { fetchWithAuth } from "../../../api-client/api_client";
import { BlueprintOperationType } from "../../../models/Blueprints";
import { LinkedAccount } from "../../../models/Entities";
import { ParentIntegrationComponentModelAndVersions } from "../../integrations/versioned-components/types";
import {
  ScraperVersion,
  ScraperDataType,
  ScraperExecutionWithIO,
  ScraperStepType,
  ScraperValueType,
  Scraper,
  ScraperGotoStep,
  ScraperStep,
} from "../types";

// API

export const fetchScraper = (props: {
  scraperID: string;
  onSuccess: (response: Scraper) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/scrapers/${props.scraperID}`,
    method: "GET",
    onResponse: props.onSuccess,
  });

export const fetchScraperVersion = (props: {
  scraperID: string;
  scraperVersionID: string;
  onSuccess: (response: ScraperVersion) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/scrapers/${props.scraperID}/versions/${props.scraperVersionID}`,
    method: "GET",
    onResponse: props.onSuccess,
  });

export const fetchScraperVersions = (props: {
  scraperID: string;
  onSuccess: (response: Array<ScraperVersion>) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/scrapers/${props.scraperID}/versions`,
    method: "GET",
    onResponse: props.onSuccess,
  });

export const saveScraper = (props: {
  scraper: ScraperVersion;
  onSuccess: (response: ScraperVersion) => void;
}): void => {
  const {
    steps,
    max_timeout,
    should_run_in_headless_mode,
    should_solve_captchas,
    should_log_non_errors,
  } = props.scraper;
  fetchWithAuth({
    path: `/scrapers/${props.scraper.id}/versions`,
    body: {
      steps,
      max_timeout,
      should_run_in_headless_mode,
      should_log_non_errors,
      should_solve_captchas,
    },
    method: "POST",
    onResponse: props.onSuccess,
  });
};

export const unstageScraper = (props: {
  scraperID: string;
  onSuccess: (response: ParentIntegrationComponentModelAndVersions) => void;
}): void => {
  fetchWithAuth({
    path: `/integrations/versioning/components/${props.scraperID}/unstage`,
    body: {
      app_label: "scrapers",
      model_name: "Scraper",
    },
    method: "POST",
    onResponse: props.onSuccess,
  });
};

export const stageScraper = (props: {
  scraper: ScraperVersion;
  onSuccess: (response: ParentIntegrationComponentModelAndVersions) => void;
}): void => {
  fetchWithAuth({
    path: `/integrations/versioning/components/${props.scraper.id}/stage`,
    body: {
      app_label: "scrapers",
      model_name: "Scraper",
    },
    method: "POST",
    onResponse: props.onSuccess,
  });
};

export const testScraper = (props: {
  scraper: ScraperVersion;
  shouldTriggerBlueprint: boolean;
  isIOAvailable: boolean;
  mfaCode?: string | undefined;
  linkedAccountID: string;
  onSuccess: (response: any) => void;
  onError: (response: Response | undefined) => void;
  blueprintVersionID?: string;
  globalVars?: { [key: string]: any };
}): Promise<void> =>
  fetchWithAuth({
    path: `/scrapers/test`,
    body: {
      scraper_json: JSON.stringify(props.scraper),
      should_trigger_blueprint: props.shouldTriggerBlueprint,
      is_io_available: props.isIOAvailable,
      mfa_code: props.mfaCode,
      linked_account_id: props.linkedAccountID,
      blueprint_version_id: props.blueprintVersionID,
      global_vars: props.globalVars,
    },
    method: "POST",
    onResponse: (data) => {
      props.onSuccess(data);
    },
    onError: (e) => {
      props.onError(e);
    },
  });

export const fetchScraperExecutionUpdates = ({
  asyncExecutionID,
  onResponse,
  onError,
}: {
  asyncExecutionID: string;
  onResponse: (data: ScraperExecutionWithIO) => void;
  onError: () => void;
}): void => {
  fetchWithAuth({
    path: `/scrapers/executions/${asyncExecutionID}/results`,
    method: "GET",
    onResponse,
    onError,
  });
};

export const postScraperExecutionInput = ({
  scraperExecutionID,
  input,
  onSuccess,
  onError,
}: {
  scraperExecutionID: string;
  input: string;
  onSuccess: (response: any) => void;
  onError: (response: Response | undefined) => void;
}): void => {
  fetchWithAuth({
    path: `/scrapers/executions/${scraperExecutionID}/input`,
    method: "POST",
    body: { input },
    onResponse: onSuccess,
    onError: onError,
  });
};

export const fetchTestLinkedAccounts = (props: {
  integrationID: string;
  onSuccess: (results: Array<LinkedAccount>) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/linked-accounts?is_test_account=True&integration=${props.integrationID}`,
    method: "GET",
    onResponse: (data) => {
      props.onSuccess(data.results);
    },
  });
};

export const fetchScrapers = (props: {
  integrationID: string;
  onSuccess: (results: Array<Scraper>) => void;
}) =>
  fetchWithAuth({
    path: `/integrations/${props.integrationID}/scrapers`,
    method: "GET",
    onResponse: (data) => {
      props.onSuccess(data);
    },
  });

export const createScraper = (props: {
  name: string;
  integrationID: string;
  operationType: BlueprintOperationType;
  scraperDataType: ScraperDataType;
  onSuccess: (results: ScraperVersion) => void;
}) =>
  fetchWithAuth({
    path: `/integrations/${props.integrationID}/scrapers`,
    body: {
      name: props.name,
      steps: [
        {
          id: "goto1",
          step_type: ScraperStepType.GOTO,
          parameter_values: {
            url: { value_type: ScraperValueType.CONSTANT, constant: "" },
          },
          step_note: null,
        } as ScraperGotoStep,
      ] as ScraperStep[],
      operation_type: props.operationType,
      data_type: props.scraperDataType,
    },
    method: "POST",
    onResponse: (data) => {
      props.onSuccess(data);
    },
  });

export const deleteScraper = (props: {
  onSuccess: () => void;
  onError: () => void;
  scraperID: string;
}) => {
  fetchWithAuth({
    path: `/scrapers/${props.scraperID}`,
    method: "DELETE",
    onResponse: props.onSuccess,
    onError: props.onError,
  });
};
