import {
  BlueprintGenericStepTemplate,
  BlueprintStep,
  BlueprintStepTemplate,
} from "../../../../models/Blueprints";
import { Alert, Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import { useState } from "react";
import DiffModal from "../../../../components/shared/diff-view/DiffModal";
import { DiffModelTypeEnum } from "../../../../models/DiffModels";

interface Props {
  step: BlueprintStep;
  newStepTemplate: BlueprintStepTemplate;
  currentStepTemplate: BlueprintStepTemplate | BlueprintGenericStepTemplate;
  replaceStepTemplate: (step: BlueprintStep, newTemplate: BlueprintStepTemplate) => void;
}

const AlertNewStepTemplate = ({
  step,
  newStepTemplate,
  currentStepTemplate,
  replaceStepTemplate,
}: Props) => {
  const [isDiffModalOpen, setIsDiffModalOpen] = useState<boolean>(false);

  return (
    <div>
      <Alert showWarningIcon>
        <div className="flex w-full flex-row items-center justify-between">
          <Text>New version available</Text>
          <Button
            size="sm"
            variant={ButtonVariant.TertiaryWhite}
            onClick={() => setIsDiffModalOpen(true)}
          >
            Review
          </Button>
        </div>
      </Alert>
      {isDiffModalOpen && (
        <DiffModal
          title={`Review latest step template for ${step.id}`}
          currentState={currentStepTemplate}
          currentStateTitle="Current step template"
          newState={newStepTemplate}
          newStateTitle="New step template"
          modelType={DiffModelTypeEnum.BLUEPRINT_STEP_TEMPLATE}
          isModalOpen={isDiffModalOpen}
          setIsModalOpen={setIsDiffModalOpen}
          onPrimaryButtonClick={() => replaceStepTemplate(step, newStepTemplate)}
          primaryButtonText="Update to latest template"
        />
      )}
    </div>
  );
};

export default AlertNewStepTemplate;
