import { MappingTestBlock, MappingTestBlockExecution } from "../../../models/MappingTests";
import { Text, Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { Activity, Box } from "lucide-react";
import MappingTestBlockExecutionStatusBadge from "../shared/MappingTestBlockExecutionStatusBadge";

interface Props {
  mappingTestBlock: MappingTestBlock;
  testBlockExecution: MappingTestBlockExecution | undefined;
  setIsShowingProducedModelsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsShowingRightPanel: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedBlockExecution: React.Dispatch<
    React.SetStateAction<MappingTestBlockExecution | undefined>
  >;
  setSelectedBlockName: React.Dispatch<React.SetStateAction<string | undefined>>;
  isExecutionFinished: boolean;
}

const MappingTestBlockBlueprintsHeader = ({
  mappingTestBlock,
  testBlockExecution,
  setIsShowingProducedModelsModal,
  setIsShowingRightPanel,
  setSelectedBlockExecution,
  setSelectedBlockName,
  isExecutionFinished,
}: Props) => {
  let mappingTestBlockName = `Mapping test block - ${mappingTestBlock.order + 1}`;

  return (
    <div className="flex flex-row justify-between w-full items-center normal-case pr-2">
      <div className="flex flex-row items-center space-x-2">
        <Text variant="h5" className="text-black">
          {mappingTestBlockName}
        </Text>
        {testBlockExecution && isExecutionFinished && (
          <div className="flex flex-row items-center">
            <MappingTestBlockExecutionStatusBadge testBlockExecution={testBlockExecution} />
          </div>
        )}
      </div>
      {testBlockExecution && (
        <div className="flex flex-row space-x-2">
          <Button
            size="sm"
            variant={ButtonVariant.TertiaryWhite}
            leftIcon={<Activity size={12} />}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              setSelectedBlockExecution(testBlockExecution);
              setSelectedBlockName(mappingTestBlockName);
              setIsShowingRightPanel(true);
            }}
          >
            View results
          </Button>
          {testBlockExecution &&
            testBlockExecution.produced_models &&
            testBlockExecution.exit_data?.every(
              (blueprintExecution) =>
                blueprintExecution.blueprint_meta.blueprint_run_exit_code === 200
            ) && (
              <Button
                size="sm"
                variant={ButtonVariant.TertiaryWhite}
                leftIcon={<Box size={12} />}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  setSelectedBlockExecution(testBlockExecution);
                  setIsShowingProducedModelsModal(true);
                }}
                disabled={!testBlockExecution?.produced_models}
              >
                Produced models
              </Button>
            )}
        </div>
      )}
    </div>
  );
};

export default MappingTestBlockBlueprintsHeader;
