import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import DeprecatedH6 from "../deprecated/DeprecatedH6";
import DeprecatedH1 from "../deprecated/DeprecatedH1";
import DeprecatedH3 from "../deprecated/DeprecatedH3";

interface Props {
  title: string | JSX.Element;
  pretitle?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  warningComponent?: string | JSX.Element;
  rightElement?: JSX.Element;
  subtabs?: Array<
    | {
        label: JSX.Element;
        destination: undefined;
        disabled?: boolean;
        disabled_tip_message?: string;
      }
    | {
        label: string;
        destination: string;
        disabled?: boolean;
        disabled_tip_message?: string;
      }
  >;
  className?: string;
}

function HeaderBar(props: Props) {
  const { className, pretitle, subtabs, subtitle, title, rightElement, warningComponent } = props;

  const SubtabLink = ({ subtab }: { subtab: any }) => {
    // Takes a subtab and returns proper html based on disabled
    // and tooltip options
    return (
      <li key={(subtab.destination ?? subtab.label) as string} className="nav-item">
        {subtab.destination ? (
          <NavLink
            to={subtab.destination}
            className={classNames(
              "nav-link",
              subtab.disabled !== undefined && subtab.disabled ? "disabled" : ""
            )}
          >
            {subtab.label}
          </NavLink>
        ) : (
          <div
            className={classNames(
              "nav-link",
              subtab.disabled !== undefined && subtab.disabled ? "disabled" : ""
            )}
          >
            {subtab.label}
          </div>
        )}
      </li>
    );
  };

  return (
    <div className={classNames("mt-4", className)}>
      <div>
        <div>
          <div>
            <DeprecatedH6 className="uppercase text-gray-50 mb-2">
              {pretitle ?? "\u00A0"}
            </DeprecatedH6>
            <DeprecatedH1>{title}</DeprecatedH1>
            {subtitle && <DeprecatedH3 className="mt-3">{subtitle}</DeprecatedH3>}
          </div>
          <div className="col-auto">{rightElement}</div>
        </div>
        {warningComponent}
        {subtabs && (
          <ul className="nav nav-tabs border-bottom-0">
            {subtabs.map((subtab) =>
              subtab.disabled_tip_message !== undefined ? (
                <OverlayTrigger
                  overlay={
                    <Tooltip id={`tooltip-${subtab.label}`}>{subtab.disabled_tip_message}</Tooltip>
                  }
                >
                  <SubtabLink key={subtab.destination} subtab={subtab} />
                </OverlayTrigger>
              ) : (
                <SubtabLink key={subtab.destination} subtab={subtab} />
              )
            )}
          </ul>
        )}
      </div>

      {/* divider bar */}
      <div className="my-6 bg-gray-20 h-[0.5px]" />
    </div>
  );
}

export default HeaderBar;
