import { CircleSlash2, CheckCircle, OctagonX } from "lucide-react";
import { Tooltip, Spinner } from "@merge-api/merge-javascript-shared";
import {
  MappingTestExecutionResult,
  LinkedAccountTestSuiteExecutionStatus,
  IntegrationTestSuiteExecutionStatus,
} from "../../../../models/MappingTests";

type TestExecutionStatus =
  | MappingTestExecutionResult
  | LinkedAccountTestSuiteExecutionStatus
  | IntegrationTestSuiteExecutionStatus;

const StatusIcon = ({ status }: { status: TestExecutionStatus }) => {
  switch (status) {
    case "SUCCESS":
    case "FINISHED_WITH_SUCCESS":
      return <CheckCircle size={16} className="text-teal-70" />;
    case "FAILURE":
    case "FINISHED_WITH_FAILURES":
    case "FINISHED_WITH_EXCEPTIONS":
    case "ABORTED":
      return <OctagonX size={16} className="text-red-50" />;
    case "NOT_RUN":
      return (
        <Tooltip title="No test executions found.">
          <CircleSlash2 size={16} className="text-gray-50" />
        </Tooltip>
      );
    case "IN_PROGRESS":
      return (
        <Tooltip title="Tests running." className="flex items-center justify-center">
          <Spinner size="md" />
        </Tooltip>
      );
    default:
      return null;
  }
};

export default StatusIcon;
