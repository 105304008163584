import clsx from "clsx";
import { TokenExpiresInType } from "../../../utils/Entities";
import MultiSelectHeader from "../../../shared/MultiSelectHeader";

interface TokenExpiresInTypeButtonsProps {
  tokenExpiresInType: TokenExpiresInType | undefined;
  setTokenExpiresInType: React.Dispatch<React.SetStateAction<TokenExpiresInType | undefined>>;
  className?: string;
}

const TokenExpiresInTypeButtons = ({
  tokenExpiresInType,
  setTokenExpiresInType,
  className,
}: TokenExpiresInTypeButtonsProps) => {
  const handleButtonClick = (value: TokenExpiresInType) => {
    setTokenExpiresInType(value);
  };

  const options = [
    {
      value: TokenExpiresInType.FETCHED,
      text: "Time fetched",
      disabled: false,
      selected: tokenExpiresInType === TokenExpiresInType.FETCHED,
      onClick: handleButtonClick,
    },
    {
      value: TokenExpiresInType.CREATED,
      text: "Time created",
      disabled: false,
      selected: tokenExpiresInType === TokenExpiresInType.CREATED,
      onClick: handleButtonClick,
    },
  ];
  return (
    <MultiSelectHeader
      dataTestID="field-auth-config-refresh-token-expires-in-type"
      title="Does `expires_in` refer to to the time the token is fetched, or the time the token is created?"
      options={options}
      className={clsx("w-full", className)}
    />
  );
};

export default TokenExpiresInTypeButtons;
