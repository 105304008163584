import { APIEndpointPreviewIntegrationBuilder } from "../../utils/Entities";
import AutogeneratedConfigurationCard from "../../shared/autogenerated-configuration-card/AutogeneratedConfigurationCard";

type APIEndpointPreviewGeneratingTableRowProps = {
  apiEndpointPreview: APIEndpointPreviewIntegrationBuilder;
  setGeneratingAPIEndpointPreviews?: React.Dispatch<
    React.SetStateAction<APIEndpointPreviewIntegrationBuilder[]>
  >;
};

const APIEndpointPreviewGeneratingTableRow = ({
  apiEndpointPreview,
  setGeneratingAPIEndpointPreviews,
}: APIEndpointPreviewGeneratingTableRowProps) => {
  return (
    <tr className="flex justify-between items-center rounded-lg ">
      <AutogeneratedConfigurationCard
        autogen_runs={apiEndpointPreview.autogen_runs ?? []}
        api_endpoint_preview={apiEndpointPreview}
        setGeneratingAPIEndpointPreviews={setGeneratingAPIEndpointPreviews}
        className="bg-gray-0"
        nested
      />
    </tr>
  );
};

export default APIEndpointPreviewGeneratingTableRow;
