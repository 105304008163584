import {
  BPRStepIOLog,
  BPRStepIOLogTypeEnum,
  BlueprintCanvasBaseStepLogListItem,
  BlueprintCanvasBaseStepLogsTree,
  ParentStepLogIterationInfo,
} from "../../../../models/Blueprints";
import { BlueprintCanvasConfigurationForLogging } from "../../utils/BlueprintCanvasUtils";

/**
 * Generates step log for base list of step logs in the blueprint canvas.
 *
 * @param stepIOLog - The step IO log containing information about the step execution.
 * @param canvasConfigurationForLogging - Configuration for logging in the blueprint canvas.
 * @param latestLogIndexForStepIDMap - Map of step IDs to their latest log index.
 * @param latestIterationIndexForStepIDMap - Map of step IDs to their latest iteration index.
 * @param latestAPIRequestIndexForStepIDMap - Map of step IDs to their latest API request index.
 * @param stepLogsTree - Tree structure of step logs.
 * @returns A BlueprintCanvasBaseStepLogListItem containing information about the step log.
 */
export const generateBaseStepLogForList = (
  stepIOLog: BPRStepIOLog,
  canvasConfigurationForLogging: BlueprintCanvasConfigurationForLogging,
  latestLogIndexForStepIDMap: Record<string, number>,
  latestIterationIndexForStepIDMap: Record<string, number | undefined>,
  latestAPIRequestIndexForStepIDMap: Record<string, number>,
  latestLoopInitializationIndexForStepIDMap: Record<string, number>,
  stepLogsTree: BlueprintCanvasBaseStepLogsTree
): BlueprintCanvasBaseStepLogListItem => {
  const stepID = stepIOLog.step_id;
  const previousStepID: string | undefined =
    canvasConfigurationForLogging.nodeIDToPreviousNodeIDMap[stepID];
  const parentStepIDs: string[] =
    canvasConfigurationForLogging.nodeIDtoListOfParentNodeIDsMap[stepID];
  const immediateParentStepID: string | undefined =
    canvasConfigurationForLogging.nodeIDtoParentNodeIDMap[stepID];
  const parentIterationInfo: ParentStepLogIterationInfo[] = (parentStepIDs ?? []).map(
    (parentStepID) => {
      const parentStepLogIterationInfo: ParentStepLogIterationInfo = {
        step_id: parentStepID,
        index_in_loop_iterations: latestIterationIndexForStepIDMap[parentStepID],
        index_in_api_requests: latestAPIRequestIndexForStepIDMap[parentStepID],
      };
      return parentStepLogIterationInfo;
    }
  );
  const indexInLoopIterations = latestIterationIndexForStepIDMap[stepID];
  const indexInAPIRequests = latestAPIRequestIndexForStepIDMap[stepID];
  // Computing total_iterations and total_api_requests requires searching the stepLogsTree
  const [totalIterations, totalAPIRequests] = computeTotalIterationsForStepLog(
    stepID,
    indexInLoopIterations,
    indexInAPIRequests,
    parentIterationInfo,
    stepLogsTree
  );

  return {
    step_id: stepID,
    step_io_log: stepIOLog,
    iteration_info: {
      index_in_loop_iterations: indexInLoopIterations,
      total_iterations: totalIterations,
      index_in_api_requests: indexInAPIRequests,
      total_api_requests: totalAPIRequests,
      parent_iteration_info: parentIterationInfo,
    },
    selected_log_info: {
      index_of_previous_log: previousStepID
        ? latestLogIndexForStepIDMap[previousStepID]
        : undefined,
      index_of_parent_log: immediateParentStepID
        ? latestLogIndexForStepIDMap[immediateParentStepID]
        : undefined,
      index_of_loop_initialization_log: latestLoopInitializationIndexForStepIDMap[stepID],
      // To be filled in by downstream logic
      index_of_last_children_step_log: undefined,
      index_of_loop_latest_iteration_log: undefined,
    },
  };
};

/**
 * Computes the total number of iterations and API requests for a given step log.
 *
 * @param stepID - The ID of the step.
 * @param parentIterationInfo - Array of parent step iteration information.
 * @param stepLogsTree - Tree structure of step logs.
 * @returns A tuple containing the total number of iterations and API requests, both possibly undefined.
 */
const computeTotalIterationsForStepLog = (
  stepID: string,
  stepIndexInLoopIterations: number | undefined,
  stepIndexInAPIRequests: number | undefined,
  parentIterationInfo: ParentStepLogIterationInfo[],
  stepLogsTree: BlueprintCanvasBaseStepLogsTree
): [number | undefined, number | undefined] => {
  // Base case
  if (parentIterationInfo.length === 0) {
    let totalAPIRequests = undefined;
    let totalIterations = undefined;
    const stepLog = stepLogsTree[stepID];
    if (stepLog) {
      // If lives within loop iteration of paginated API request, compute results accordingly
      if (stepIndexInAPIRequests !== undefined) {
        const apiRequests = stepLog.api_requests;
        if (apiRequests) {
          totalAPIRequests = apiRequests.length;
          totalIterations = apiRequests[stepIndexInAPIRequests].loop_iterations.length;
        }
      }
      // If lives within loop iteration, compute results accordingly
      else if (stepIndexInLoopIterations !== undefined) {
        totalIterations = stepLog.loop_iterations.length;
      }
    }

    return [totalIterations, totalAPIRequests];
  }

  // Recursive case
  const highestParentStepIterationInfo = parentIterationInfo[0];
  const indexInAPIRequests = highestParentStepIterationInfo.index_in_api_requests;
  const indexInLoopIterations = highestParentStepIterationInfo.index_in_loop_iterations;
  const highestParentStepLog = stepLogsTree[highestParentStepIterationInfo.step_id];
  if (highestParentStepLog) {
    // If lives within paginated API request, retrieve the next level accordingly
    if (indexInAPIRequests !== undefined) {
      if (indexInLoopIterations !== undefined) {
        const apiRequests = highestParentStepLog.api_requests;
        if (apiRequests) {
          return computeTotalIterationsForStepLog(
            stepID,
            stepIndexInLoopIterations,
            stepIndexInAPIRequests,
            parentIterationInfo.slice(1),
            apiRequests[indexInAPIRequests].loop_iterations[indexInLoopIterations]
              .children_loop_steps
          );
        }
      }
    }
    // If lives within loop iteration, retrieve the next level accordingly
    else if (indexInLoopIterations !== undefined) {
      return computeTotalIterationsForStepLog(
        stepID,
        stepIndexInLoopIterations,
        stepIndexInAPIRequests,
        parentIterationInfo.slice(0, -1),
        highestParentStepLog.loop_iterations[indexInLoopIterations].children_loop_steps
      );
    }
  }

  return [undefined, undefined];
};

/**
 * Updates the tracking indexes for a given step ID in the blueprint execution process.
 * This function is responsible for maintaining the latest iteration and API request indexes
 * for each step in the blueprint.
 *
 * @param stepIOLog - The current step I/O log being processed
 * @param latestIterationIndexForStepIDMap - A map of the latest iteration index for each step ID
 * @param latestAPIRequestIndexForStepIDMap - A map of the latest API request index for each step ID
 * @param canvasConfiguration - The canvas configuration containing step relationships
 * @returns A tuple of updated latestIterationIndexForStepIDMap and latestAPIRequestIndexForStepIDMap
 */
export const updateCountsForStepIDMap = (
  stepIOLog: BPRStepIOLog,
  latestIterationCountForStepIDMap: Record<string, number | undefined>,
  latestAPIRequestCountForStepIDMap: Record<string, number>,
  canvasConfiguration: BlueprintCanvasConfigurationForLogging
): [Record<string, number | undefined>, Record<string, number>] => {
  const stepID = stepIOLog.step_id;
  if (stepIOLog.log_type === BPRStepIOLogTypeEnum.LOOP_ITERATION) {
    const currentIterationIndexForStepID = latestIterationCountForStepIDMap[stepID];
    latestIterationCountForStepIDMap[stepID] =
      currentIterationIndexForStepID !== undefined ? currentIterationIndexForStepID + 1 : 0;
  } else if (stepIOLog.log_type === BPRStepIOLogTypeEnum.API_REQUEST) {
    latestAPIRequestCountForStepIDMap[stepID] =
      latestAPIRequestCountForStepIDMap[stepID] !== undefined
        ? latestAPIRequestCountForStepIDMap[stepIOLog.step_id] + 1
        : 0;
    latestIterationCountForStepIDMap[stepID] = undefined;
  }
  // Process steps with paths, like if-else steps
  else if (
    !canvasConfiguration.listOfLoopNodeIDs.includes(stepID) &&
    canvasConfiguration.nodeIDtoListOfChildNodeIDsMap[stepID].size > 0
  ) {
    latestIterationCountForStepIDMap[stepID] = 0;
  }

  // Reset tracking of all descendant steps of this step
  [
    latestIterationCountForStepIDMap,
    latestAPIRequestCountForStepIDMap,
  ] = resetCountsOfDescendantSteps(
    stepID,
    latestIterationCountForStepIDMap,
    latestAPIRequestCountForStepIDMap,
    canvasConfiguration
  );
  return [latestIterationCountForStepIDMap, latestAPIRequestCountForStepIDMap];
};

/**
 * Resets the tracking indexes for all descendant steps of a given step ID.
 * Used by "updateTrackingIndexesForStepIDMap"
 *
 * @param stepID - The ID of the step whose descendants need to be reset
 * @param latestIterationIndexForStepIDMap - A map of the latest iteration index for each step ID
 * @param latestAPIRequestIndexForStepIDMap - A map of the latest API request index for each step ID
 * @param canvasConfiguration - The canvas configuration containing step relationships
 * @returns A tuple of updated latestIterationIndexForStepIDMap and latestAPIRequestIndexForStepIDMap
 */
const resetCountsOfDescendantSteps = (
  stepID: string,
  latestIterationCountForStepIDMap: Record<string, number | undefined>,
  latestAPIRequestCountForStepIDMap: Record<string, number>,
  canvasConfiguration: BlueprintCanvasConfigurationForLogging
): [Record<string, number | undefined>, Record<string, number>] => {
  const stack = Array.from(canvasConfiguration.nodeIDtoListOfChildNodeIDsMap[stepID]);
  while (stack.length > 0) {
    const childStepID = stack.pop();
    if (childStepID) {
      latestIterationCountForStepIDMap = removeKeyFromMap(
        childStepID,
        latestIterationCountForStepIDMap
      );
      latestAPIRequestCountForStepIDMap = removeKeyFromMap(
        childStepID,
        latestAPIRequestCountForStepIDMap
      );
    }
  }
  return [latestIterationCountForStepIDMap, latestAPIRequestCountForStepIDMap];
};

/**
 * Removes a key from a given map if it exists.
 *
 * @param key - The key to be removed from the map
 * @param map - The map from which the key should be removed
 * @returns The updated map with the key removed (if it existed)
 */
const removeKeyFromMap = (key: string, map: Record<string, any>): Record<string, any> => {
  if (key !== undefined && key in map) {
    delete map[key];
  }
  return map;
};

export const updateIndexesForStepIDMap = (
  stepID: string,
  stepIOLog: BPRStepIOLog,
  index: number,
  canvasConfiguration: BlueprintCanvasConfigurationForLogging,
  latestLogIndexForStepIDMap: Record<string, number>,
  latestLoopInitializationIndexForStepIDMap: Record<string, number>
): [Record<string, number>, Record<string, number>] => {
  // Update latest log index for step ID
  latestLogIndexForStepIDMap[stepID] = index;

  // Update loop initialization tracking for step ID
  // We want to apply this for non-iteration logs of loop steps (aka the initialization log)
  if (
    canvasConfiguration.listOfLoopNodeIDs.includes(stepID) &&
    stepIOLog.log_type !== BPRStepIOLogTypeEnum.LOOP_ITERATION
  ) {
    latestLoopInitializationIndexForStepIDMap[stepID] = index;
  }

  return [latestLogIndexForStepIDMap, latestLoopInitializationIndexForStepIDMap];
};
