import { TypeaheadComparatorClusterOption } from "@merge-api/merge-javascript-shared/dist/designSystem/molecules/Typeahead/types/types";
import { useState } from "react";
import { BlueprintSearchResults } from "../../../../models/blueprint-search/BlueprintSearchOutputModels";
import { fetchBlueprintSearch } from "../utils/BlueprintSearchAPIClient";
import {
  convertTypeaheadFiltersToRequestBody,
  sortBlueprintResults,
} from "../utils/BlueprintSearchUtils";
import { showErrorToast } from "../../../../components/shared/Toasts";
import { SortTypeEnum } from "../utils/constants";
import { Integration } from "../../../../models/Entities";

/**
 * This hook manages the state and logic for Blueprint search results.
 *
 * It uses the following state variables:
 * - blueprintSearchResults: Stores the search results
 * - isLoadingBlueprintSearchResults: Indicates whether the results are currently being loaded
 *
 * It provides the following functions:
 * - fetchBlueprintSearchResults: Fetches search results based on filters and sort type
 * - sortBlueprintSearchResults: Sorts the existing search results
 *
 * @returns {Object} An object containing:
 *   - blueprintSearchResults: The current search results
 *   - fetchBlueprintSearchResults: Function to fetch new search results
 *   - sortBlueprintSearchResults: Function to sort existing results
 *   - isLoadingBlueprintSearchResults: Boolean indicating if results are being loaded
 */
const useBlueprintSearchResults = () => {
  const [blueprintSearchResults, setBlueprintSearchResults] = useState<BlueprintSearchResults>({
    blueprints: [],
  });
  const [isLoadingBlueprintSearchResults, setIsLoadingBlueprintSearchResults] = useState<boolean>(
    false
  );

  const fetchBlueprintSearchResults = (
    integration: Integration,
    typeaheadFilters: TypeaheadComparatorClusterOption[],
    sortType: SortTypeEnum
  ) => {
    fetchBlueprintSearch({
      integrationID: integration.id,
      requestBody: convertTypeaheadFiltersToRequestBody(typeaheadFilters),
      onSuccess: (response) =>
        setBlueprintSearchResults({
          ...response,
          blueprints: sortBlueprintResults(response.blueprints, sortType),
        }),
      onError: (_) => showErrorToast("Error loading results"),
      setIsLoading: setIsLoadingBlueprintSearchResults,
    });
  };

  const sortBlueprintSearchResults = (sortType: SortTypeEnum) => {
    setBlueprintSearchResults({
      ...blueprintSearchResults,
      blueprints: sortBlueprintResults(blueprintSearchResults.blueprints, sortType),
    });
  };

  return {
    blueprintSearchResults,
    fetchBlueprintSearchResults,
    sortBlueprintSearchResults,
    isLoadingBlueprintSearchResults,
  };
};

export default useBlueprintSearchResults;
