import { useEffect, useState } from "react";
import { Button } from "@merge-api/merge-javascript-shared";
import { HeaderPretitle } from "../../shared/text/MergeText";
import {
  BlueprintAddToArrayStep,
  BlueprintParameterValue,
  BlueprintParameterValueType,
} from "../../../models/Blueprints";
import useBlueprintContext from "../context/useBlueprintContext";
import TypeaheadFormField from "./TypeaheadFormField";
import CancellableTypeaheadFormField from "./CancellableTypeaheadFormField";
import { Plus } from "lucide-react";

const BlueprintEditorRightPanelMergeObjectsForm = () => {
  const { selectedStep, updateStepParameterValue } = useBlueprintContext();
  const step = selectedStep as BlueprintAddToArrayStep;
  const [currentValues, setCurrentValues] = useState<Array<BlueprintParameterValue | null>>([]);
  useEffect(() => {
    const values = step.parameter_values.values ?? {};
    if (values.value_type === BlueprintParameterValueType.customArray) {
      const array_values = values.array_values ?? [];
      setCurrentValues(array_values);
    } else {
      setCurrentValues([]);
    }
  }, []);

  const updateValues = (newValues: Array<BlueprintParameterValue | null>) => {
    setCurrentValues(newValues);
    updateStepParameterValue(step, "values", {
      value_type: BlueprintParameterValueType.customArray,
      array_values: newValues.filter((param) => !!param),
    });
  };

  const addValue = () => {
    updateValues([...currentValues, null]);
  };

  const deleteValue = (index: number) => {
    updateValues([...currentValues.slice(0, index), ...currentValues.slice(index + 1)]);
  };

  const updateValue = (index: number, newParameterValue: BlueprintParameterValue | null) => {
    updateValues([
      ...currentValues.slice(0, index),
      newParameterValue,
      ...currentValues.slice(index + 1),
    ]);
  };

  return (
    <>
      <TypeaheadFormField key="array" title="array" valueKey="array" parameterType="array" />
      <HeaderPretitle className="mt-3">Values</HeaderPretitle>
      {currentValues.map((key, index) => (
        <div key={index}>
          <CancellableTypeaheadFormField
            onChange={(newValue: BlueprintParameterValue | null) => {
              updateValue(index, newValue);
            }}
            currentParameterValue={key}
            title={`value ${index + 1}`}
            subtitle="The value to insert into array as a deep copy, not a reference"
            onDelete={() => deleteValue(index)}
          />
          <hr />
        </div>
      ))}
      <Button leftIcon={<Plus size={16} />} fullWidth onClick={addValue}>
        Add Value
      </Button>
    </>
  );
};

export default BlueprintEditorRightPanelMergeObjectsForm;
