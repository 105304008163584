import React from "react";
import { Text } from "@merge-api/merge-javascript-shared";
import { AdditionalInfoDisplayProps } from "./types";

const PaginationSetupAdditionalInfoDisplay: React.FC<AdditionalInfoDisplayProps> = ({ data }) => {
  const { step_ids } = data;

  return (
    <ul>
      {step_ids.map((value: string) => (
        <li key={value}>
          <Text variant="h6" className="mr-2">
            {`• ${value}`}
          </Text>
        </li>
      ))}
    </ul>
  );
};

export default PaginationSetupAdditionalInfoDisplay;
