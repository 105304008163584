import React from "react";

interface DeprecatedH2Props {
  children: React.ReactNode;
  className?: string;
}

const DeprecatedH2: React.FC<DeprecatedH2Props> = ({ className, children }) => {
  return <div className={`deprecatedh2 ${className}`}>{children}</div>;
};

export default DeprecatedH2;
