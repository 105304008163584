import { Button } from "react-bootstrap";
import useScraperContext from "./context/useScraperContext";
import ScraperTypeaheadFormField from "./ScraperTypeaheadFormField";
import { ScraperConcatenateStringsStep, ScraperValueType } from "./types";
import max from "lodash/max";

const ScraperConcatenateStringsFields = () => {
  const { selectedStep, updateStepParameterValue } = useScraperContext();
  const step = selectedStep as ScraperConcatenateStringsStep;
  const parameterValues = step.parameter_values;

  const maxArgVal = max([
    ...Object.keys(parameterValues).map((key) => Number(key.slice(1))),
    0,
  ]) as number;

  const addArg = () =>
    updateStepParameterValue(step, "x" + (maxArgVal + 1), {
      value_type: ScraperValueType.CONSTANT,
      constant: "",
    });

  return (
    <div>
      {Object.entries(parameterValues).map(([key, value]) => (
        <ScraperTypeaheadFormField
          key={key}
          valueKey={key}
          updateValue={(newValue) => updateStepParameterValue(step, key, newValue)}
          value={value}
        />
      ))}
      <Button className="mt-0 mb-3 btn-block" onClick={addArg}>
        Add New String
      </Button>
    </div>
  );
};

export default ScraperConcatenateStringsFields;
