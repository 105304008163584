import styled from "styled-components";
import AccordionCard from "../../shared/AccordionCard";
import TextFieldHeader from "../../shared/TextFieldHeader";
import { IntegrationForIntegrationBuilder } from "../../utils/Entities";

interface Props {
  requestBodyForIntegration: IntegrationForIntegrationBuilder | undefined;
  setRequestBodyForIntegration: React.Dispatch<
    React.SetStateAction<IntegrationForIntegrationBuilder | undefined>
  >;
}

// Cant use tailwind for this because it only supports certain custom hex values
const ColorBlock = styled.div<{
  $color: string;
}>`
  ${({ $color }) => "background-color: #" + $color};
`;

const MarketingPageConfigurationSection = ({
  requestBodyForIntegration,
  setRequestBodyForIntegration,
}: Props) => {
  return (
    <AccordionCard title="Marketing pages" badgeContents={1} className="my-0">
      {requestBodyForIntegration && (
        <div className="space-y-6">
          <TextFieldHeader
            title="Color"
            subtitle="The color of this integration used for buttons and text throughout the app and landing pages. Choose a darker, saturated color."
            prefix="#"
            prefixVariant="url"
            postfix={
              <ColorBlock
                className="w-[26px] h-[26px] rounded-br-md rounded-tr-md border-l border-gray-10"
                $color={requestBodyForIntegration.color?.slice(1) || "080808"}
              />
            }
            placeholder="080808"
            value={requestBodyForIntegration.color?.slice(1)}
            onChange={(event) => {
              let value = event.target.value.replace("#", "");
              if (value?.length <= 6) {
                setRequestBodyForIntegration((body) => {
                  return {
                    ...body,
                    color: "#" + value,
                  };
                });
              }
            }}
            inputClassName="max-h-[26px]"
            textFieldClassName="w-36"
          />
        </div>
      )}
    </AccordionCard>
  );
};

export default MarketingPageConfigurationSection;
