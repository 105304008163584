import { useCallback, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import styled from "styled-components";
import { HTTPMethod } from "../../../../models/HTTPMethods";
import { MappingTestRequestMock } from "../../../../models/MappingTests";
import BlueprintCardAPIRequestFooterEditableCodeSection from "../../../shared/blueprint-card/BlueprintCardAPIRequestFooterEditableCodeSection";
import { HTTPMethodBadgeUnfilled, ResponseStatusBadge } from "../../../shared/MergeBadges";
import { ChevronToggle } from "../../../shared/MergeToggles";
import AddMappingTestRequestMockModal from "./AddMappingTestRequestMockModal";
import ContextMenu from "../../../shared/ContextMenu";
import { showSuccessToast } from "../../../shared/Toasts";

const RequestMockContainer = styled.div`
  border: 0.5px solid #dce2ea;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 16px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.5px solid #dce2ea;
  padding: 16px;
`;

const MockName = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

const URLText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
`;

const BadgeContainer = styled.div`
  height: 20px;
  margin-right: 6px;
`;

const RequestMockRowAccordion = styled(Accordion)`
  padding: 8px;
  border-bottom: 0.5px solid #dce2ea;
`;

type Props = {
  requestMock: MappingTestRequestMock;
  editable: boolean;
  editRequestMock: (requestMock: MappingTestRequestMock) => void;
  deleteRequestMock: (requestMock: MappingTestRequestMock) => void;
  setCopiedRequestMock: (requestMock: MappingTestRequestMock) => void;
  saveMappingTest: () => void;
};

const MappingTestV2RequestMock = ({
  requestMock,
  editable,
  editRequestMock,
  deleteRequestMock,
  setCopiedRequestMock,
  saveMappingTest,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showAddRequestMockModal, setShowAddRequestMockModal] = useState<Boolean>(false);
  const [isShowingContextMenu, setIsShowingContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });

  const previewMessage = requestMock.file_size
    ? `Download full response as file (${(requestMock.file_size / 1000000).toFixed(1)} MB)`
    : null;

  const downloadInfo = {
    downloadLink: requestMock.download_link || undefined,
    previewMessage: previewMessage,
  };

  const handleDelete = () => {
    setIsEditing(false);
    deleteRequestMock(requestMock);
  };

  const handleToggle = (eventKey: string) => {
    setIsExpanded(eventKey === "response-body-toggle");
  };

  const onRightClickBlock = useCallback(
    (e) => {
      e.preventDefault();
      setIsShowingContextMenu(true);
      setContextMenuPosition({ x: e.pageX, y: e.pageY });
    },
    [requestMock, setContextMenuPosition, setIsShowingContextMenu]
  );

  const baseOptions = [
    {
      label: "Copy request",
      featherIconName: "copy",
      onClick: () => {
        setIsShowingContextMenu(false);
        setCopiedRequestMock(requestMock as MappingTestRequestMock);
        navigator.clipboard.writeText(JSON.stringify(requestMock));
        showSuccessToast("Mock request copied.");
      },
    },
    {
      label: "Delete request",
      featherIconName: "trash",
      onClick: () => {
        setIsShowingContextMenu(false);
        deleteRequestMock(requestMock);
        saveMappingTest();
      },
      confirmationMessage: `Are you sure you want to delete this request? Deleting this request will automatically save your mapping test version.`,
      warning: true,
    },
  ];

  return (
    <RequestMockContainer>
      {showAddRequestMockModal && (
        <AddMappingTestRequestMockModal
          setShowAddRequestMockModal={setShowAddRequestMockModal}
          onHide={() => setShowAddRequestMockModal(false)}
          onSubmit={editRequestMock}
          requestMock={requestMock}
        />
      )}
      <ContextMenu
        items={baseOptions}
        isShown={isShowingContextMenu}
        position={contextMenuPosition}
        onClose={() => setIsShowingContextMenu(false)}
      />
      <HeaderContainer onContextMenu={onRightClickBlock}>
        <div>
          <MockName>{requestMock?.name ?? ""}</MockName>
          <div className="d-flex">
            <BadgeContainer>
              <ResponseStatusBadge responseCode={requestMock.response_code} />
            </BadgeContainer>
            <BadgeContainer>
              <HTTPMethodBadgeUnfilled method={requestMock.method} />
            </BadgeContainer>
            <URLText className="break-all">{requestMock.url}</URLText>
          </div>
        </div>
        {editable && (
          <div className="flex flex-col justify-center">
            <Button
              className="w-full"
              size="sm"
              onClick={() => {
                setShowAddRequestMockModal(true);
              }}
            >
              <div>{"Edit"}</div>
            </Button>
            {isEditing && (
              <Button
                className="mt-1"
                variant={ButtonVariant.DangerFilled}
                size="sm"
                onClick={() => {
                  handleDelete();
                }}
              >
                <div>{"Delete"}</div>
              </Button>
            )}
          </div>
        )}
      </HeaderContainer>
      <RequestMockRowAccordion className="mt-1" defaultActiveKey={""}>
        <ChevronToggle eventKey="headers-toggle">Request Headers</ChevronToggle>
        <RequestMockRowAccordion.Collapse eventKey="headers-toggle" className="mt-3">
          <BlueprintCardAPIRequestFooterEditableCodeSection
            body={JSON.stringify(requestMock?.request_headers ?? "")}
            editedAt={requestMock.edited_at}
            editCodeFunction={(_: string, newRequestHeaders: string) => {
              const newRequestMock = {
                ...requestMock,
                request_headers: !newRequestHeaders ? {} : JSON.parse(newRequestHeaders),
              };
              editRequestMock(newRequestMock);
            }}
            alwaysEditable={false}
            editable={false}
          />
        </RequestMockRowAccordion.Collapse>
      </RequestMockRowAccordion>
      <RequestMockRowAccordion className="mt-1" defaultActiveKey={""}>
        <ChevronToggle eventKey="request-body-toggle">Request Body</ChevronToggle>
        <RequestMockRowAccordion.Collapse eventKey="request-body-toggle" className="mt-3">
          <BlueprintCardAPIRequestFooterEditableCodeSection
            body={requestMock?.request_body ?? ""}
            editedAt={requestMock.edited_at}
            editCodeFunction={(_: string, newRequestBody: string) => {
              const newRequestMock = {
                ...requestMock,
                request_body: !newRequestBody || newRequestBody == "" ? null : newRequestBody,
                request_body_type:
                  requestMock.method == HTTPMethod.GET ? null : requestMock.request_body_type,
              };
              editRequestMock(newRequestMock);
            }}
            alwaysEditable={false}
            editable={false}
          />
        </RequestMockRowAccordion.Collapse>
      </RequestMockRowAccordion>
      <RequestMockRowAccordion className="mt-1" defaultActiveKey={""}>
        <ChevronToggle eventKey="headers-toggle">Response Headers</ChevronToggle>
        <RequestMockRowAccordion.Collapse eventKey="headers-toggle" className="mt-3">
          <BlueprintCardAPIRequestFooterEditableCodeSection
            body={JSON.stringify(requestMock?.response_headers ?? "")}
            editedAt={requestMock.edited_at}
            editCodeFunction={(_: string, newResponseHeaders: string) => {
              const newRequestMock = {
                ...requestMock,
                response_headers: !newResponseHeaders ? {} : JSON.parse(newResponseHeaders),
              };
              editRequestMock(newRequestMock);
            }}
            alwaysEditable={false}
            editable={false}
          />
        </RequestMockRowAccordion.Collapse>
      </RequestMockRowAccordion>
      <RequestMockRowAccordion
        activeKey={isExpanded ? "response-body-toggle" : ""}
        onSelect={handleToggle}
      >
        <ChevronToggle eventKey="response-body-toggle">Response Body</ChevronToggle>
        <RequestMockRowAccordion.Collapse eventKey="response-body-toggle" className="mt-3">
          {isExpanded ? (
            <>
              <BlueprintCardAPIRequestFooterEditableCodeSection
                body={requestMock?.response_body ?? ""}
                editedAt={requestMock.edited_at}
                editCodeFunction={(_: string, newResponseBody: string) => {
                  const newRequestMock = {
                    ...requestMock,
                    response_body: newResponseBody,
                  };
                  editRequestMock(newRequestMock);
                }}
                alwaysEditable={false}
                editable={false}
              />
              {downloadInfo.downloadLink && (
                <a
                  className="flex justify-end p-2 w-full"
                  href={downloadInfo?.downloadLink}
                  download
                >
                  <Button variant={ButtonVariant.TertiaryWhite} className="w-full">
                    {downloadInfo.previewMessage}
                  </Button>
                </a>
              )}
            </>
          ) : (
            <></>
          )}
        </RequestMockRowAccordion.Collapse>
      </RequestMockRowAccordion>
    </RequestMockContainer>
  );
};

export default MappingTestV2RequestMock;
