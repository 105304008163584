import { DiffStateFieldTypeEnum, DiffState } from "../../../../models/DiffModels";
import { generateDiffState } from "./helpers-diff";

// Generate diff for API endpoint configs
export const generateDiffForAPIEndpointsConfiguration = (): DiffState => {
  return generateDiffState({
    fields: [
      {
        name: "timestamp_format",
        displayName: "Timestamp format",
      },
      {
        type: DiffStateFieldTypeEnum.SECTION,
        name: "advanced",
        displayName: "Advanced",
        isRenderNumberOfChildrenInDisplayName: true,
        childDiffStateInputFields: [
          {
            name: "override_request_timeout_seconds",
            displayName: "Override request timeout",
          },
          {
            name: "api_request_retry_delay_seconds",
            displayName: "API request retry delay",
          },
          {
            name: "retain_authorization_header_on_redirect",
            displayName: "Should we retain authorization header on redirect?",
          },
          {
            name: "require_ssl_certificates",
            displayName: "Is SSL certificate required?",
          },
          {
            name: "convert_body_data_to_string",
            displayName: "Should we convert API request body data to string?",
          },
          {
            name: "convert_strings_to_numbers",
            displayName: "Should we convert string to numbers?",
          },
        ],
      },
    ],
  });
};
