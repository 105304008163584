import { Badge } from "@merge-api/merge-javascript-shared";
import { ComponentState } from "../../versioned-components/types";

interface Props {
  publishState: ComponentState;
  className?: string;
}

const VersionedComponentPublishStateBadge = ({ publishState, className }: Props) => {
  const draftBadge = (
    <Badge color="gray" className={`${className ?? ""}`}>
      Draft
    </Badge>
  );
  const stagedBadge = (
    <Badge color="yellow" className={`${className ?? ""}`}>
      Staged
    </Badge>
  );
  const publishedBadge = (
    <Badge color="teal" className={`${className ?? ""}`}>
      Published
    </Badge>
  );

  switch (publishState) {
    case ComponentState.PUBLISHED_WITH_NEW_DRAFT:
      return publishedBadge;
    case ComponentState.NEW_COMPONENT_WITH_DRAFT_VERSION:
      return draftBadge;
    case ComponentState.NEW_COMPONENT_WITH_STAGED_VERSION:
    case ComponentState.PUBLISHED_WITH_NEW_STAGED_VERSION:
      return stagedBadge;
    case ComponentState.PUBLISHED:
      return publishedBadge;
    case ComponentState.MARKED_FOR_DELETION:
      return <Badge color="red">Marked for Deletion</Badge>;
    default:
      return <Badge color="gray">{publishState}</Badge>;
  }
};

export default VersionedComponentPublishStateBadge;
