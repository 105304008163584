import { HTTPMethod } from "@merge-api/merge-javascript-shared";
import { useMemo } from "react";
import { fetchWithAuth } from "../../api-client/api_client";
import useRequest from "../shared/hooks/useRequest";
import { showErrorToast, showSuccessToast } from "../shared/Toasts";
import {
  VersionedComponentAPIActions,
  VersionedComponentsAPIRequestData,
} from "../versioned-components/types";

const VERSIONED_COMPONENTS_PATH = "/integrations/versioning/v2";

type VersionedComponentsAPIProps = {
  integrationID: string;
  versioned_components_api_action: VersionedComponentAPIActions;
  request_body: VersionedComponentsAPIRequestData;
  successToastMessage?: string;
  onResponse: (data: any) => void;
  onError?: (data: any) => void;
};

const useVersionedComponentsAPI = <Data extends unknown>({
  integrationID,
  versioned_components_api_action,
  request_body,
}: VersionedComponentsAPIProps): {
  data: undefined | Data;
  hasError: boolean;
  isLoading: boolean;
} => {
  const path = `${VERSIONED_COMPONENTS_PATH}/${integrationID}/${versioned_components_api_action}`;

  // Memoize the request body to prevent continuously hitting the API
  const memoizedRequestBody = useMemo(() => request_body, [JSON.stringify(request_body)]);

  const { data, hasError, isLoading } = useRequest<Data>({
    path,
    method: HTTPMethod.POST,
    body: memoizedRequestBody,
  });

  return { data, hasError, isLoading };
};

export const makeVersionedComponentsAPIRequest = ({
  integrationID,
  versioned_components_api_action,
  request_body,
  successToastMessage,
  onResponse,
  onError,
}: VersionedComponentsAPIProps): void => {
  const path = `${VERSIONED_COMPONENTS_PATH}/${integrationID}/${versioned_components_api_action}`;
  const method = HTTPMethod.POST;

  fetchWithAuth({
    path,
    method,
    body: request_body,
    onResponse: (data: any) => {
      onResponse(data);
      if (successToastMessage) showSuccessToast(successToastMessage);
    },
    onError: (error: any) => {
      (onError ?? (() => {}))(error);
      showErrorToast("Something went wrong");
    },
  });
};

export default useVersionedComponentsAPI;
