import { Accordion, Text } from "@merge-api/merge-javascript-shared";
import clsx from "clsx";
import { IntegrationValidatorRuleResult } from "../types";
import StaleParamsAdditionalInfoDisplay from "./additional-info-displays/StaleParamsAdditionalInfoDisplay";
import UniqueIdentifierAdditionalInfoDisplay from "./additional-info-displays/UniqueIdentifierAdditionalInfoDisplay";
import PaginationSetupAdditionalInfoDisplay from "./additional-info-displays/PaginationSetupAdditionalInfoDisplay";

interface Props {
  validatorRuleResult: IntegrationValidatorRuleResult;
  className?: string;
}

const IntegrationValidatorRuleAlertAdditionalInfo = ({ validatorRuleResult, className }: Props) => {
  const formatDefaultAdditionalInformation = () => {
    if (!validatorRuleResult.additional_information) {
      return null;
    }

    return (
      <div>
        {Object.entries(validatorRuleResult.additional_information).map(([key, value]) => (
          <div className="flex flex-row items-center" key={key}>
            <Text variant="h6" className="mr-2">
              {key}:
            </Text>
            <Text>{JSON.stringify(value)}</Text>
          </div>
        ))}
      </div>
    );
  };

  const getAdditionalInformationProps = () => {
    if (!validatorRuleResult.additional_information) {
      return null;
    }

    try {
      if (validatorRuleResult.rule_id === "unique_identifier_changed") {
        return {
          title: "Show common model",
          info: (
            <UniqueIdentifierAdditionalInfoDisplay
              data={validatorRuleResult.additional_information}
            />
          ),
        };
      } else if (validatorRuleResult.rule_id === "stale_parameters") {
        return {
          title: "Show parameters",
          info: (
            <StaleParamsAdditionalInfoDisplay data={validatorRuleResult.additional_information} />
          ),
        };
      } else if (validatorRuleResult.rule_id === "pagination_setup_check") {
        return {
          title: "Show steps",
          info: (
            <PaginationSetupAdditionalInfoDisplay
              data={validatorRuleResult.additional_information}
            />
          ),
        };
      }
      return {
        title: "Show additional info",
        info: formatDefaultAdditionalInformation(),
      };
    } catch {
      return {
        title: "Show additional info",
        info: formatDefaultAdditionalInformation(),
      };
    }
  };

  const additionalInfoProps = getAdditionalInformationProps();

  if (!additionalInfoProps) {
    return null;
  }

  return (
    <Accordion
      title={
        <Text variant="h6" className="mr-2">
          {additionalInfoProps.title}
        </Text>
      }
      variant="none"
      chevronPlacement="right"
      className={clsx("px-9 bg-transparent", className)}
      titleClassName={"bg-transparent"}
    >
      <div className={"bg-transparent"}>{additionalInfoProps.info}</div>
    </Accordion>
  );
};

export default IntegrationValidatorRuleAlertAdditionalInfo;
