import BlueprintContext, { BlueprintContextState } from "./BlueprintContext";

interface Props extends BlueprintContextState {
  children: JSX.Element;
}

// Wraps around App to be able to pull global state anywhere in tree via useContext
const BlueprintContextProvider = ({ children, ...contextProps }: Props) => (
  <BlueprintContext.Provider value={contextProps}>{children}</BlueprintContext.Provider>
);

export default BlueprintContextProvider;
