import { Text, Tooltip } from "@merge-api/merge-javascript-shared";
import {
  BlueprintCanvasViewableStepLog,
  BlueprintCanvasViewableStepLogBase,
} from "../../../models/Blueprints";

interface Props {
  stepLog: BlueprintCanvasViewableStepLog | BlueprintCanvasViewableStepLogBase;
}

/**
 * Renders the header for a step log in the StepCard component.
 * This component displays information about the parent iteration if the step is within one.
 *
 * @param stepLog - The viewable step log data for the current step
 */
const StepCardStepLogParentInfo = ({ stepLog }: Props) => {
  const parentIterationInfo = stepLog.iteration_info?.parent_iteration_info;

  // We don't show parent info, if there's no parent info in the first place
  if (!parentIterationInfo || parentIterationInfo.length === 0) {
    return null;
  }

  const lastParentIterationInfo = parentIterationInfo[parentIterationInfo.length - 1];
  const parentStepID = lastParentIterationInfo.step_id;
  const parentStepIterationIndex =
    lastParentIterationInfo.index_in_loop_iterations !== undefined
      ? lastParentIterationInfo.index_in_loop_iterations + 1
      : -1;
  const parentStepAPIRequestIndex =
    lastParentIterationInfo.index_in_api_requests !== undefined
      ? lastParentIterationInfo.index_in_api_requests + 1
      : undefined;
  return (
    <Tooltip
      title={`In iteration ${parentStepIterationIndex} ${
        parentStepAPIRequestIndex ? "of page " + parentStepAPIRequestIndex : ""
      } of ${parentStepID}`}
      className="w-full truncate"
    >
      <div className="flex flex-row space-x-1 items-center truncate">
        <Text variant="sm" className="">
          In iteration {parentStepIterationIndex}{" "}
          {parentStepAPIRequestIndex && `of page ${parentStepAPIRequestIndex}`} of
        </Text>
        <Text variant="title-sm" className="truncate">
          {parentStepID}
        </Text>
      </div>
    </Tooltip>
  );
};

export default StepCardStepLogParentInfo;
