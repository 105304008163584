import { DiffState, DiffStateFieldTypeEnum } from "../../../../models/DiffModels";
import { generateDiffState } from "./helpers-diff";

export const generateDiffForIntegrationInitialization = (): DiffState => {
  return generateDiffState({
    fields: [
      { name: "name", displayName: "Name" },
      { name: "categories", displayName: "Categories" },
      { name: "abbreviated_name", displayName: "Abbreviated name" },
      {
        type: DiffStateFieldTypeEnum.SECTION,
        name: "advanced",
        displayName: "Advanced",
        isRenderNumberOfChildrenInDisplayName: true,
        childDiffStateInputFields: [
          {
            name: "is_soap_integration",
            displayName: "Is this a SOAP integration?",
          },
          {
            name: "is_multi_subsidiary",
            displayName: "Is multi subsidiary?",
          },
          {
            name: "does_integration_use_scrapers",
            displayName: "Does this integration use scrapers?",
            isRenderChildrenAsNested: true,
            childDiffStateInputFields: [
              {
                name: "should_use_scraper_proxy",
                displayName: "Should we use scraper proxy?",
              },
              {
                name: "extra_scraper_headers",
                displayName: "Extra scraper headers",
              },
            ],
          },
        ],
      },
    ],
  });
};
