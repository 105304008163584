import { Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { useEffect, useMemo, useState } from "react";

interface Props {
  tracedStepIDs: string[];
  selectedStepID: string | undefined;
  scrollToStep: (stepID: string) => void;
}

const BlueprintCanvasTopBannerStepNavigator = ({
  tracedStepIDs,
  selectedStepID,
  scrollToStep,
}: Props) => {
  /* -- START - Perform memo-ized calculations -- */
  /**
   * > Total number of traced steps, for display
   * > Index of current step, for display and whether to display ArrowLeft and ArrowRight
   */

  const numberOfTracedSteps: number = useMemo(() => tracedStepIDs.length, [tracedStepIDs]);

  const [selectedStepIndex, setSelectedStepIndex] = useState<number>(numberOfTracedSteps);

  // Update the new step index, found from reversedTracedStepIDs
  // If the new selected step doesn't exist in reversedTracedStepIDs, we keep the index as is
  useEffect(() => {
    if (selectedStepID) {
      const index = tracedStepIDs.findIndex((stepID) => stepID === selectedStepID);
      if (index !== -1) setSelectedStepIndex(index);
    }
  }, [selectedStepID, tracedStepIDs]);
  /* -- END - Perform memo-ized calculations -- */

  /* -- START - Handlers -- */
  const handleClickLeftArrow = () => {
    scrollToStep(tracedStepIDs[selectedStepIndex - 1]);
  };
  const handleClickRightArrow = () => {
    scrollToStep(tracedStepIDs[selectedStepIndex + 1]);
  };
  /* -- END - Handlers -- */

  return (
    <div className="flex flex-row items-center justify-between px-4 py-3 bg-white rounded-md border border-gray-10">
      {selectedStepIndex > 0 ? (
        <Button size="sm" variant={ButtonVariant.IconShadowHidden}>
          <ArrowLeft
            className="text-black cursor-pointer"
            size={16}
            onClick={handleClickLeftArrow}
          />
        </Button>
      ) : (
        <div className="w-1"></div>
      )}
      <div className="flex flex-row items-center space-x-2 truncate">
        <Text>
          Step {selectedStepIndex + 1} of {numberOfTracedSteps}
        </Text>
        <Text variant="h6">{tracedStepIDs[selectedStepIndex]}</Text>
      </div>
      {selectedStepIndex < numberOfTracedSteps - 1 ? (
        <Button size="sm" variant={ButtonVariant.IconShadowHidden}>
          <ArrowRight
            className="text-black cursor-pointer"
            size={16}
            onClick={handleClickRightArrow}
          />
        </Button>
      ) : (
        <div className="w-1"></div>
      )}
    </div>
  );
};

export default BlueprintCanvasTopBannerStepNavigator;
