import React from "react";

interface DeprecatedH4Props {
  children: React.ReactNode;
  className?: string;
}

const DeprecatedH4: React.FC<DeprecatedH4Props> = ({ className, children }) => {
  return <div className={`deprecatedh4 ${className}`}>{children}</div>;
};

export default DeprecatedH4;
