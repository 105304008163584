import { ChevronRight, OctagonX, TriangleAlert } from "lucide-react";
import { palette } from "../../../../styles/theme/colors";
import {
  BlueprintValidatorResult,
  BlueprintValidatorResultOptions,
  BlueprintValidatorRuleResult,
  BlueprintValidatorRuleResultOptions,
  BlueprintValidatorRuleResultType,
  ValidatorRules,
  StagedComponent,
} from "../types";
import { Accordion, Alert, Card, Text } from "@merge-api/merge-javascript-shared";
import {
  History,
  getTestSuitesTablePath,
  navigateHelper,
  navigateToBlueprintEditor,
} from "../../../../router/RouterUtils";
import { useHistory } from "react-router-dom";
import PublishModuleValidatorResultTitle from "./PublishModuleValidatorResultsTitle";
import { hasKeys, RenderUniqueIdentifierRuleAdditionalInformation } from "../utils";

type Props = {
  stagedBlueprint: StagedComponent;
  stagedBlueprintValidatorInfo: BlueprintValidatorResult | undefined;
  integrationID: string;
  isRunningValidator: boolean;
};

const RULES_WITH_DETAILED_ERROR_MESSAGES = [
  ValidatorRules.BPV_RULE_UNIQUE_IDENTIFIERS_CHANGED,
  ValidatorRules.BPV_RULE_STALE_PARAMETERS,
];

const getReadableRuleErrorMessage = (ruleResult: BlueprintValidatorRuleResult) => {
  switch (ruleResult.rule) {
    case ValidatorRules.BPV_RULE_UNIQUE_IDENTIFIERS_CHANGED: {
      const commonModelsWithConflicts = Object.entries(
        ruleResult?.additional_information
          ?.unique_identifier_formats_by_blueprint_version_common_model_step_id ?? {}
        // @ts-ignore
      ).filter(([_, info]) => Object.values(info).some(hasKeys)).length;
      return (
        <div className="flex flex-row">
          {"Errors in Unique ID construction for "}
          <span>&nbsp;</span>
          <b>
            {` ${commonModelsWithConflicts.toString()}`} common model
            {`${commonModelsWithConflicts > 1 ? "s" : ""}.`}
          </b>
        </div>
      );
    }
    default: {
      return ruleResult.exception_message
        ? `${ruleResult.rule}: ${ruleResult.exception_message}`
        : ruleResult.error_message
        ? ruleResult.error_message
        : `Rule ${ruleResult.rule} does not have error message.`;
    }
  }
};

const getReadableRuleErrorMessageDetails = (ruleResult: BlueprintValidatorRuleResult) => {
  switch (ruleResult.rule) {
    case "BPV_RULE_STALE_PARAMETERS": {
      return (
        <div className="d-flex flex-column">
          {Object.entries(ruleResult?.additional_information?.stale_parameters ?? {}).map(
            ([scopedStepID, staleParamInfo]) => {
              const stepID = scopedStepID.split("::").slice(-1);
              return (
                <Text variant="sm">
                  {/* @ts-ignore */}
                  <b>{stepID}:</b> {(staleParamInfo?.[0]?.key_path ?? []).join(".")}
                </Text>
              );
            }
          )}
        </div>
      );
    }
    case "BPV_RULE_UNIQUE_IDENTIFIERS_CHANGED": {
      return (
        <div className="d-flex flex-column">
          {RenderUniqueIdentifierRuleAdditionalInformation(ruleResult?.additional_information)}
        </div>
      );
    }
    default: {
      return null;
    }
  }
};

const redirectLink = (
  history: History,
  ruleResult: BlueprintValidatorRuleResult,
  integrationID: string,
  stagedBlueprint: StagedComponent
) => {
  switch (ruleResult.rule) {
    case "BPV_RULE_MAPPING_TEST_COVERAGE": {
      return navigateHelper(history, getTestSuitesTablePath(integrationID), true);
    }
    case "BPV_RULE_UNIQUE_IDENTIFIERS_CHANGED":
    case "BPV_RULE_PAGINATION_SETUP":
    case "BPV_RULE_STALE_PARAMETERS": {
      return navigateToBlueprintEditor(
        history,
        integrationID,
        stagedBlueprint.component_version_id,
        true
      );
    }

    default: {
      return;
    }
  }
};

const PublishModuleValidatorStagedBlueprint = ({
  integrationID,
  stagedBlueprint,
  stagedBlueprintValidatorInfo,
  isRunningValidator,
}: Props) => {
  const history = useHistory();
  return (
    <Card className="m-5 mt-0" variant="outline">
      <Accordion
        chevronSize={16}
        chevronOrientation="right"
        onChange={function noRefCheck() {}}
        title={
          <PublishModuleValidatorResultTitle
            stagedBlueprint={stagedBlueprint}
            stagedBlueprintValidatorInfo={stagedBlueprintValidatorInfo}
            isRunningValidator={isRunningValidator}
          />
        }
        titleClassName="p-4"
        variant="none"
      >
        <hr className="m-0 text-gray-50" />
        {stagedBlueprintValidatorInfo?.result === BlueprintValidatorResultOptions.SUCCESS ? (
          <Card className="m-6" variant="none">
            <Text className="text-gray-50">All checks passed.</Text>
          </Card>
        ) : (
          <div className="m-5">
            <div>
              {stagedBlueprintValidatorInfo?.rule_results
                .filter(
                  (ruleResult) =>
                    ruleResult.result === BlueprintValidatorRuleResultOptions.FAILURE &&
                    ruleResult.result_type === BlueprintValidatorRuleResultType.BLOCKER
                )
                .map((blockingResult) => (
                  <Alert
                    className="mb-4"
                    size="sm"
                    color="red"
                    icon={<OctagonX color={palette.red} size={16} />}
                    title={
                      <div
                        className="flex w-full flex-row items-center justify-between cursor-pointer"
                        onClick={() =>
                          redirectLink(history, blockingResult, integrationID, stagedBlueprint)
                        }
                      >
                        <Text variant="sm">{getReadableRuleErrorMessage(blockingResult)}</Text>
                        <ChevronRight size={12} color={palette.gray} />
                      </div>
                    }
                  >
                    {RULES_WITH_DETAILED_ERROR_MESSAGES.includes(
                      blockingResult.rule as ValidatorRules
                    ) && (
                      <div
                        className="ml-6 mt-0"
                        onClick={() =>
                          redirectLink(history, blockingResult, integrationID, stagedBlueprint)
                        }
                      >
                        {getReadableRuleErrorMessageDetails(blockingResult)}
                      </div>
                    )}
                  </Alert>
                ))}
            </div>
            <div>
              {stagedBlueprintValidatorInfo?.rule_results
                .filter(
                  (ruleResult) =>
                    ruleResult.result === BlueprintValidatorRuleResultOptions.FAILURE &&
                    ruleResult.result_type === BlueprintValidatorRuleResultType.WARNING
                )
                .map((warningResult) => (
                  <Alert
                    className="mb-4"
                    color="yellow"
                    size="sm"
                    icon={<TriangleAlert color={palette.yellow} size={16} />}
                    title={
                      <div
                        className="flex w-full flex-row items-center justify-between cursor-pointer"
                        onClick={() =>
                          redirectLink(history, warningResult, integrationID, stagedBlueprint)
                        }
                      >
                        <Text variant="sm">
                          <div className="d-flex flex-column">
                            {getReadableRuleErrorMessage(warningResult)}
                          </div>
                        </Text>
                        <ChevronRight size={12} color={palette.gray} />
                      </div>
                    }
                  >
                    {RULES_WITH_DETAILED_ERROR_MESSAGES.includes(
                      warningResult.rule as ValidatorRules
                    ) && (
                      <div
                        className="ml-6 mt-0"
                        onClick={() =>
                          redirectLink(history, warningResult, integrationID, stagedBlueprint)
                        }
                      >
                        {getReadableRuleErrorMessageDetails(warningResult)}
                      </div>
                    )}
                  </Alert>
                ))}
            </div>
          </div>
        )}
      </Accordion>
    </Card>
  );
};

export default PublishModuleValidatorStagedBlueprint;
