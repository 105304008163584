import { Col, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import { palette } from "../../../styles/theme/colors";
import { useState } from "react";
import { JSONSchemaTypeOption } from "../utils/BlueprintDataTransformUtils";
import Dropdown from "../right-panel/Dropdown";
import { Button } from "react-bootstrap";

const BottomBorder = css`
  border-bottom: 1px solid ${palette.border};
`;

const BorderedFieldRowContent = styled(Row)`
  padding-left: 88px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: end;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
`;

const StyledButton = styled(Button)<{ isSecondary: boolean }>`
  font-size: 12px;
  line-height: 16px;
  margin-left: 8px;
  padding: 8px 16px;
  ${({ isSecondary }) => isSecondary && BottomBorder}
`;

interface Props {
  setSubItemType: (fieldType: JSONSchemaTypeOption) => void;
  subItemType: JSONSchemaTypeOption | undefined;
  level: number;
}

const BlueprintValidationEditorArrayFieldSubItem = ({ setSubItemType, subItemType }: Props) => {
  const [newSubItemType, setNewSubItemType] = useState<JSONSchemaTypeOption | undefined>(
    subItemType
  );

  return (
    <BorderedFieldRowContent>
      {
        <>
          <Col>
            <Dropdown
              choices={Object.values(JSONSchemaTypeOption).map((option) => ({
                name: option,
                id: option,
              }))}
              currentValue={newSubItemType}
              placeholder={"Field Type"}
              onChange={(e) => {
                setNewSubItemType(e.target.value);
              }}
            />
          </Col>
          <Col>
            <StyledButton
              disabled={subItemType === newSubItemType}
              onClick={() => {
                if (newSubItemType) {
                  setSubItemType(newSubItemType);
                }
              }}
            >
              <b>Save Item Type</b>
            </StyledButton>
          </Col>
        </>
      }
    </BorderedFieldRowContent>
  );
};

export default BlueprintValidationEditorArrayFieldSubItem;
