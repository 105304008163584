import TextFieldHeader from "../../../shared/TextFieldHeader";
import PostmanTableHeader from "../shared/PostmanTableHeader";
import { Row } from "../../../shared/postman-table/PostmanTableRow";
import QueryBodyParamButtons from "../buttons/QueryBodyParamButtons";
import { ParamsLocation } from "../../../../integrations/auth-config/AuthConfigShared";
import HeadersHelperAccordion from "../shared/HeadersHelperAccordion";

interface TokenURLRequestCardProps {
  tokenRequestURL: string | undefined;
  setTokenRequestURL: React.Dispatch<React.SetStateAction<string | undefined>>;
  tokenURLRequestHeaders: Row[];
  setTokenURLRequestHeaders: React.Dispatch<React.SetStateAction<Row[]>>;
  requestParamType: ParamsLocation | undefined;
  setRequestParamType: React.Dispatch<React.SetStateAction<ParamsLocation | undefined>>;
}

const TokenURLRequestCard = ({
  tokenRequestURL,
  setTokenRequestURL,
  tokenURLRequestHeaders,
  setTokenURLRequestHeaders,
  requestParamType,
  setRequestParamType,
}: TokenURLRequestCardProps) => {
  return (
    <>
      <TextFieldHeader
        dataTestID="field-auth-config-token-url"
        title="Token URL"
        subtitle="Enter the URL for requesting the access token. You can use {DOMAIN}, {PLACEHOLDER_API_KEY}, and {PLACEHOLDER_PATH} in this URL."
        placeholder="example.com"
        learnMoreText="For example, for the Salesforce integration, which requires a custom domain, you would input 'https://{DOMAIN}.my.salesforce.com/services/oauth2/token'."
        prefix="https://"
        required
        prefixVariant="url"
        value={tokenRequestURL}
        onChange={(newEvent) => {
          setTokenRequestURL(newEvent.target.value);
        }}
      />
      <PostmanTableHeader
        dataTestID="field-auth-config-token-url-headers"
        className="mt-6"
        title="Header fields"
        subtitle="Enter the OAuth token URL header format. Values should be formatted with {VARIABLE} formatting."
        learnMoreText="For example, if the header format is 'Authorization: Basic {BASE-64}{CLIENT-ID}:{CLIENT-SECRET}{BASE-64}', enter 'Authorization' as the key and 'Basic {BASE-64}{CLIENT-ID}:{CLIENT-SECRET}{BASE-64}' as the value."
        rows={tokenURLRequestHeaders}
        setRows={setTokenURLRequestHeaders}
      />
      <HeadersHelperAccordion />
      <QueryBodyParamButtons
        className="mt-6"
        requestParamType={requestParamType}
        setRequestParamType={setRequestParamType}
      />
    </>
  );
};
export default TokenURLRequestCard;
