import { Button } from "react-bootstrap";
import Editor from "react-simple-code-editor";
import { isValidJSON, isValidXML } from "../../../utils";
import EditableJSONSchemaTree from "./EditableJSONSchemaTree";
import { getJSONSchemaFromExampleJSON } from "../utils/IntegrationsUtils";
import { APIResponseType } from "../../../models/Entities";
import { parseStringPromise } from "xml2js";
import DeprecatedH2 from "../../deprecated/DeprecatedH2";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";

const { highlight, languages } = require("prismjs");

interface Props {
  sampleSchemaValue: string;
  setSampleSchemaValue: (sampleSchemaValue: string) => void;
  JSONSchemaValue: string;
  setJSONSchemaValue: (JSONSchemaValue: string) => void;
  responseType?: APIResponseType;
  isJSONSchemaShowing: boolean;
  setIsJSONSchemaShowing: (isJSONSchemaShowing: boolean) => void;
}

const JSONSchemaConverterCard = (props: Props) => {
  const isJSON = isValidJSON(props.JSONSchemaValue);

  const isValidInput = (code: string) => {
    if (props.responseType === APIResponseType.JSON) {
      return isValidJSON(code);
    } else if (props.responseType === APIResponseType.XML) {
      return isValidXML(code);
    }
    return false;
  };

  return (
    <>
      <DeprecatedH2 className="mb-4">JSON Schema Converter</DeprecatedH2>
      <p className="text-muted small-text mb-3">
        Param schemas require arguments to be in the form of JSON schema.{" "}
      </p>
      <p className="text-muted small-text mb-3">
        {" "}
        Copy and paste sample JSON/XML to convert into interpreted JSON Schema, and then copy and
        paste the output into the relevant schema field.{" "}
      </p>
      <p className="text-muted small-text mb-9">
        {" "}
        Consult the Schema tree diagram to verify that there are no errors or unknown fields. Modify
        the fields by clicking on the red highlighted text, and selecting the correct type.
      </p>

      {props.responseType === APIResponseType.JSON ||
      props.responseType === APIResponseType.NUMBER ? (
        <DeprecatedH4>Sample JSON</DeprecatedH4>
      ) : (
        <DeprecatedH4>Sample XML</DeprecatedH4>
      )}
      <div className="json-text-box">
        <Editor
          highlight={(code) => highlight(code, languages.js)}
          value={props.sampleSchemaValue ?? ""}
          onValueChange={async (code) => {
            let toConvert = code;
            try {
              if (props.responseType === APIResponseType.XML) {
                // convert xml code -> json
                toConvert = await parseStringPromise(code, { explicitArray: false })
                  .then((result: string) => {
                    return JSON.stringify(result);
                  })
                  .catch((_) => {
                    return code;
                  });
              }
              props.setSampleSchemaValue(code);
              props.setJSONSchemaValue(getJSONSchemaFromExampleJSON(toConvert ?? ""));
            } catch {
              //eslint-disable-next-line no-empty
            }
          }}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
            minHeight: "98px",
            border: 0,
          }}
          padding={10}
        />
      </div>
      <div style={{ paddingTop: 20 }}>
        <Button
          className="btn-block btn-primary"
          onClick={() => props.setIsJSONSchemaShowing(true)}
          disabled={!isValidInput(props.sampleSchemaValue)}
        >
          Convert
        </Button>
      </div>

      <DeprecatedH4 className="mt-9">JSON Schema</DeprecatedH4>
      <Editor
        highlight={(code) => highlight(code, languages.js)}
        value={props.isJSONSchemaShowing ? props.JSONSchemaValue : ""}
        onValueChange={(code) => {
          props.setJSONSchemaValue(code);
        }}
        padding={10}
        style={{
          backgroundColor: "white",
          border: "1px solid #d2ddec",
          borderRadius: 8,
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 12,
          minHeight: "100px",
          overflow: "auto",
        }}
      />
      <div className="mt-9">
        <DeprecatedH4>Schema Tree Diagram</DeprecatedH4>
        {props.isJSONSchemaShowing && props.JSONSchemaValue && isJSON ? (
          <EditableJSONSchemaTree
            jsonSchema={JSON.parse(props.JSONSchemaValue)}
            setJSONSchema={props.setJSONSchemaValue}
          />
        ) : (
          <p className="mb-3">
            {" "}
            The Schema tree diagram will render after converting sample schema.
          </p>
        )}
      </div>
    </>
  );
};

export default JSONSchemaConverterCard;
