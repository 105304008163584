import { Text } from "@merge-api/merge-javascript-shared";

interface Props {
  dataKey: string;
  dataValue: any;
}

const StepIOLogModalSectionRow = ({ dataKey, dataValue }: Props) => {
  const displayValue =
    typeof dataValue === "object" && dataValue !== null
      ? JSON.stringify(dataValue, null, 2)
      : dataValue !== null
      ? dataValue.toString()
      : dataValue;

  return (
    <div className="flex flex-col pt-2">
      <hr className="bg-gray-10 w-full m-0" />
      <div className="flex flex-row space-x-4 align-top pt-2">
        <Text variant="md-mono" className="w-[300px] break-all">
          {dataKey}
        </Text>
        <Text as="pre" variant="md-mono" className="w-full m-0 max-h-[400px]">
          {displayValue}
        </Text>
      </div>
    </div>
  );
};

export default StepIOLogModalSectionRow;
