import { fetchWithAuth } from "../../../api-client/api_client";
import { BlueprintOperationType } from "../../../models/Blueprints";
import { APICategory } from "../../../models/Entities";
import { HTTPMethod } from "../../../models/HTTPMethods";
import {
  LatestScraperExecutionData,
  MappingTestAndVersions,
  MappingTestExpectedResult,
  MappingTestVersion,
  MappingTestMeta,
  MappingTestExecution,
} from "../../../models/MappingTests";
import { ParentIntegrationComponentModelAndVersions } from "../../integrations/versioned-components/types";

export const testMappingTest = ({
  mappingTestID,
  mappingTestVersion,
  onResponse,
  onError,
}: {
  mappingTestID: string;
  mappingTestVersion: MappingTestVersion;
  onResponse: (testExecution: MappingTestExecution) => void;
  onError: () => void;
}) =>
  fetchWithAuth({
    path: `/stallions/tests/${mappingTestID}/start-run`,
    method: HTTPMethod.POST,
    body: mappingTestVersion,
    onResponse,
    onError,
  });

export const patchMappingTestName = ({
  mappingTestID,
  newName,
  onResponse,
  onError,
}: {
  mappingTestID: string;
  newName: string;
  onResponse: (updatedName: { name: string }) => void;
  onError: () => void;
}) =>
  fetchWithAuth({
    path: `/stallions/tests/${mappingTestID}/name`,
    method: HTTPMethod.PATCH,
    body: { name: newName },
    onResponse,
    onError,
  });

export const saveMappingTestDraft = ({
  mappingTestID,
  mappingTestVersion,
  onResponse,
  onError,
}: {
  mappingTestID: string;
  mappingTestVersion: MappingTestVersion;
  onResponse: (mappingTestAndVersions: MappingTestAndVersions) => void;
  onError: (error: any) => void;
}) =>
  fetchWithAuth({
    path: `/stallions/tests/${mappingTestID}`,
    method: HTTPMethod.PATCH,
    body: mappingTestVersion,
    onResponse,
    onError,
  });

export const stageMappingTest = ({
  mappingTestID,
  onResponse,
  onError,
}: {
  mappingTestID: string;
  onResponse: (parentComponentModel: ParentIntegrationComponentModelAndVersions) => void;
  onError: () => void;
}) =>
  fetchWithAuth({
    path: `/integrations/versioning/components/${mappingTestID}/stage`,
    method: "POST",
    body: {
      app_label: "stallions",
      model_name: "MappingTest",
    },
    onResponse,
    onError,
  });

export const unstageMappingTest = ({
  mappingTestID,
  onResponse,
  onError,
}: {
  mappingTestID: string;
  onResponse: (parentComponentModel: ParentIntegrationComponentModelAndVersions) => void;
  onError: () => void;
}) =>
  fetchWithAuth({
    path: `/integrations/versioning/components/${mappingTestID}/unstage`,
    method: "POST",
    body: {
      app_label: "stallions",
      model_name: "MappingTest",
    },
    onResponse,
    onError,
  });

export const fetchMappingTestResults = ({
  asyncExecutionID,
  onResponse,
}: {
  asyncExecutionID: string;
  onResponse: (data: MappingTestExecution) => void;
}) =>
  fetchWithAuth({
    path: `/stallions/run-results/${asyncExecutionID}`,
    method: HTTPMethod.GET,
    onResponse,
  });

export const fetchScraperBlueprintLatestExecution = ({
  linkedAccountID,
  blueprintID,
  onResponse,
  onError,
}: {
  linkedAccountID: string;
  blueprintID: string;
  onResponse: (data: LatestScraperExecutionData) => void;
  onError: () => void;
}) =>
  fetchWithAuth({
    path: `/blueprints/${blueprintID}/${linkedAccountID}/latest-scraper-execution`,
    method: HTTPMethod.GET,
    onResponse,
    onError,
  });

export const createMappingTest = ({
  integrationID,
  category,
  operationType: operation_type,
  name,
  onResponse,
  onError,
}: {
  integrationID: string;
  category: APICategory;
  operationType: BlueprintOperationType;
  name: string;
  onResponse: (data: MappingTestMeta) => void;
  onError: () => void;
}) =>
  fetchWithAuth({
    path: `/stallions/integrations/${integrationID}/tests`,
    method: HTTPMethod.POST,
    body: {
      category,
      operation_type,
      name,
    },
    onResponse,
    onError,
  });

export const createMappingTestCase = ({
  endpointCollectionID,
  name,
  expectedResult,
  onResponse,
  onError,
}: {
  endpointCollectionID: string;
  name: string;
  expectedResult: MappingTestExpectedResult;
  onResponse: (data: MappingTestMeta) => void;
  onError: () => void;
}) =>
  fetchWithAuth({
    path: `/stallions/endpoint-collections/${endpointCollectionID}/test-case`,
    method: HTTPMethod.POST,
    body: {
      name,
      expected_result: expectedResult,
    },
    onResponse,
    onError,
  });

export const deleteRequestMock = ({
  mockRequestID,
  onResponse,
  onError,
}: {
  mockRequestID: string | null;
  onResponse: () => void;
  onError: (error: any) => void;
}) =>
  fetchWithAuth({
    path: `/stallions/tests/request-mock/${mockRequestID}`,
    method: HTTPMethod.DELETE,
    onResponse,
    onError,
  });
