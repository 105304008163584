import { Badge, Text, TextField } from "@merge-api/merge-javascript-shared";
import clsx from "clsx";
import { ArrowRight, X } from "lucide-react";
import { FocusEventHandler, useState } from "react";
import useIntegrationBuilderContext from "../context/useIntegrationBuilderContext";

interface KeyPathFieldProps {
  placeholder?: string;
  prefixVariant?: "inline" | "url" | undefined;
  keyPath?: string[] | null;
  onKeyPathChange?: (keyPath: string[] | null) => void;
  name?: string;
  ref?: React.Ref<HTMLInputElement>;
  error?: boolean;
  errorText?: string;
  type?: React.HTMLInputTypeAttribute;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  disabled?: boolean;
  multiline?: boolean;
  autosize?: boolean;
  showArrow?: boolean;
}

const KeyPathField = ({
  placeholder,
  prefixVariant = "inline",
  keyPath,
  onKeyPathChange,
  name,
  ref,
  error,
  errorText,
  type,
  onBlur,
  multiline = false,
  disabled,
  autosize,
  showArrow = true,
}: KeyPathFieldProps) => {
  const { hasPermissionToEdit } = useIntegrationBuilderContext();

  const [currentText, setCurrentText] = useState<string>("");

  const onSubmit = (key: string) => {
    const newArray = keyPath ? [...keyPath, key] : [key];
    onKeyPathChange && onKeyPathChange(newArray);
    setCurrentText("");
  };

  const isDisabled = !hasPermissionToEdit || disabled;

  const renderKeyBadge = (key: string, index: number) => (
    <div className={clsx("flex flex-row items-center", showArrow && index > 0 ? "ml-2" : "")}>
      <Badge
        color="gray"
        size="sm"
        className={clsx(
          !showArrow && index > 0 && "ml-2",
          "flex flex-row items-center justify-center flex-nowrap text-nowrap"
        )}
      >
        <Text variant="title-sm" className="mr-1">
          {key}
        </Text>
        <div
          className="cursor-pointer flex items-center justify-center"
          onClick={() => {
            if (!onKeyPathChange) return;

            if (!keyPath) return onKeyPathChange(null);
            const newArray = [...keyPath];
            newArray.splice(index, 1);
            onKeyPathChange(newArray);
          }}
        >
          <X size={12} className="text-gray-50 hover:text-gray-90 transition-colors duration-100" />
        </div>
      </Badge>
      {showArrow && <ArrowRight size={12} className="ml-2" />}
    </div>
  );

  // If the user presses enter, add the current text to the key path
  // If the user presses backspace and the current text is empty, remove the last key from the key path
  const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSubmit(e.currentTarget.value);
    } else if (e.key === "Backspace" && e.currentTarget.value === "") {
      if (!onKeyPathChange) return;
      if (!keyPath) return onKeyPathChange(null);
      const newArray = [...keyPath];
      newArray.pop();
      onKeyPathChange(newArray);
    }
  };

  // If the user leaves input field while unsaved text exists, it will auto-save it.
  const blurHandler = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (onBlur) {
      onBlur(event);
    } else {
      if (event.currentTarget.value) {
        onSubmit(event.currentTarget.value);
      }
    }
  };

  return (
    <div className={isDisabled ? "bg-gray-0 rounded-md" : ""}>
      <TextField
        placeholder={placeholder}
        prefix={
          keyPath && keyPath.length > 0 && keyPath[0] !== "" ? (
            <div className="flex flex-row pl-3">
              {keyPath?.map((key, index) => renderKeyBadge(key, index))}
            </div>
          ) : null
        }
        prefixVariant={prefixVariant}
        value={currentText}
        onChange={(e) => setCurrentText(e.target.value)}
        onKeyDown={keyDownHandler}
        name={name}
        ref={ref}
        error={error}
        errorText={errorText}
        type={type}
        onBlur={blurHandler}
        disabled={isDisabled}
        multiline={multiline}
        autosize={autosize}
      />
    </div>
  );
};

export default KeyPathField;
