import { TextField } from "@merge-api/merge-javascript-shared";
import clsx from "clsx";
import { FocusEventHandler } from "react";
import ClickableText from "./ClickableText";
import HeaderBase from "./HeaderBase";
import useIntegrationBuilderContext from "../context/useIntegrationBuilderContext";

interface TextFieldHeaderProps {
  title?: string;
  subtitle?: string;
  subtitleComponent?: React.ReactNode;
  learnMoreText?: string;
  learnMoreTextComponent?: React.ReactNode;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  textFieldClassName?: string;
  prefix?: string;
  prefixVariant?: "inline" | "url" | undefined;
  postfix?: React.ReactNode | undefined;
  value?: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  name?: string;
  ref?: React.Ref<HTMLInputElement>;
  error?: boolean;
  errorText?: string;
  type?: React.HTMLInputTypeAttribute;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  helpText?: string;
  required?: boolean;
  hasSource?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  autosize?: boolean;
  bottomLinkText?: string;
  bottomLinkOnClick?: () => void;
  inputWrapperClassName?: string;
  maxRows?: number;
  dataTestID?: string;
}

const TextFieldHeader = ({
  title,
  subtitle,
  subtitleComponent,
  learnMoreText,
  learnMoreTextComponent,
  placeholder,
  className,
  inputClassName,
  textFieldClassName,
  prefix,
  prefixVariant = "inline",
  value,
  rows,
  onChange,
  name,
  postfix,
  ref,
  error,
  errorText,
  inputWrapperClassName,
  type,
  onBlur,
  onFocus,
  helpText,
  required,
  hasSource = false,
  multiline = false,
  disabled,
  autosize,
  bottomLinkText,
  bottomLinkOnClick,
  maxRows,
  dataTestID,
}: TextFieldHeaderProps) => {
  const { hasPermissionToEdit } = useIntegrationBuilderContext();

  return (
    <div className={clsx("w-full", className)}>
      <HeaderBase
        title={title}
        subtitle={subtitle}
        subtitleComponent={subtitleComponent}
        learnMoreText={learnMoreText}
        learnMoreTextComponent={learnMoreTextComponent}
        helpText={helpText}
        required={required}
        hasSource={hasSource}
        dataTestID={dataTestID}
      />
      <div className={textFieldClassName}>
        <TextField
          inputWrapperClassName={inputWrapperClassName}
          placeholder={placeholder}
          prefix={prefix}
          prefixVariant={prefixVariant}
          postfix={postfix}
          inputClassName={inputClassName}
          value={value}
          onChange={onChange}
          name={name}
          ref={ref}
          rows={hasPermissionToEdit ? rows : value?.toString().split(/\r\n|\r|\n/).length}
          error={error}
          errorText={errorText}
          type={type}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={!hasPermissionToEdit || disabled}
          multiline={multiline}
          autosize={autosize}
          maxRows={maxRows}
        />
      </div>
      {bottomLinkText && bottomLinkOnClick && (
        <ClickableText text={bottomLinkText} onClick={bottomLinkOnClick} className="mt-2" />
      )}
    </div>
  );
};

export default TextFieldHeader;
