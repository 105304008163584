import { LinkChoiceStepOption } from "../../utils/Entities";
import { useState } from "react";
import { APIEndpointMethod } from "../../../../models/Entities";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";

interface Params {
  integrationID: string;
  requestedLinkChoiceStepOptions: LinkChoiceStepOption[] | undefined;
  setLinkChoiceStepOptions: React.Dispatch<
    React.SetStateAction<LinkChoiceStepOption[] | undefined>
  >;
}

// Bulk edits LinkChoiceStepOptions for an integration
const useCreateOrPatchLinkChoiceStepOptions = ({
  integrationID,
  requestedLinkChoiceStepOptions,
  setLinkChoiceStepOptions,
}: Params) => {
  const [isLoadingStepOptionsUpdate, setIsLoadingStepOptionsUpdate] = useState<boolean>(false);

  const createOrPatchLinkChoiceStepOptions = () => {
    setIsLoadingStepOptionsUpdate(true);
    fetchWithAuth({
      path: `/integrations/${integrationID}/link-choice-step-options/bulk-edit`,
      method: APIEndpointMethod.POST,
      body: requestedLinkChoiceStepOptions,
      onResponse: (data: LinkChoiceStepOption[]) => {
        showSuccessToast("Successfully updated link choice step options.");
        setIsLoadingStepOptionsUpdate(false);
        setLinkChoiceStepOptions(data);
      },
      onError: () => {
        showErrorToast("Failed to update link choice step options.");
        setIsLoadingStepOptionsUpdate(false);
      },
    });
  };

  return { createOrPatchLinkChoiceStepOptions, isLoadingStepOptionsUpdate };
};

export default useCreateOrPatchLinkChoiceStepOptions;
