import { Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import { Plus } from "lucide-react";
import { UserFacingFilterType } from "../../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import HeaderBase from "../../../../../../components/integration-builder/shared/HeaderBase";
import { AbstractCondition } from "../../../types";
import APIRequestTesterAppliedCondition from "./APIRequestTesterAppliedCondition";

interface Props {
  integrationID: string;
  filterType: UserFacingFilterType | null;
  conditions: Array<AbstractCondition>;
  className?: string;
  updateOrRemoveAbstractConditionAtIndex: (
    index: number,
    updatedCondition: AbstractCondition,
    removeBlock?: boolean
  ) => void;
  setConditions: React.Dispatch<React.SetStateAction<AbstractCondition[]>>;
}

const APIRequestTesterAppliedConditionsContainer = ({
  conditions,
  integrationID,
  filterType,
  className,
  updateOrRemoveAbstractConditionAtIndex,
  setConditions,
}: Props) => {
  const getEmptyAbstractConditiion = (): AbstractCondition => {
    return {
      operator: "",
      value: {
        value: "",
        typed_value: "",
      },
      integration_id: integrationID,
      filter_type_id: filterType?.id ?? "",
    };
  };

  const addCondiition = () => {
    const newConditiions = [...conditions, getEmptyAbstractConditiion()];
    setConditions(newConditiions);
  };

  return (
    <div className={`${className ?? ""}`}>
      <HeaderBase title={"Linked Account condition(s)"} />
      {conditions.map((condition, index) => (
        <div className="flex flex-col">
          <APIRequestTesterAppliedCondition
            condition={condition}
            filterType={filterType}
            index={index}
            updateOrRemoveAbstractConditionAtIndex={updateOrRemoveAbstractConditionAtIndex}
          />
        </div>
      ))}
      <Text variant="caption" className="text-gray-60">
        AND
      </Text>
      <Button
        onClick={addCondiition}
        leftIcon={<Plus size={16} />}
        variant={ButtonVariant.SecondaryGray}
        size="sm"
      >
        Filter
      </Button>
    </div>
  );
};

export default APIRequestTesterAppliedConditionsContainer;
