import { BlockExecutionErrorDetailsMockRequestNotFoundDict } from "../../../../models/MappingTests";
import MappingTestV2RightPanelErrorDetailsMockRequestParameter from "./MappingTestV2RightPanelErrorDetailsMockRequestParameter";

interface Props {
  errorDetails: BlockExecutionErrorDetailsMockRequestNotFoundDict;
}

const MappingTestV2RightPanelErrorDetailsMockRequest = ({ errorDetails }: Props) => {
  return (
    <div className="space-y-2">
      <MappingTestV2RightPanelErrorDetailsMockRequestParameter
        title="Method"
        body={errorDetails.method}
      />
      <MappingTestV2RightPanelErrorDetailsMockRequestParameter
        title="URL"
        body={errorDetails.url}
      />
      <MappingTestV2RightPanelErrorDetailsMockRequestParameter
        title="Request body"
        body={errorDetails.request_body}
        useCodeBlock={true}
      />
    </div>
  );
};

export default MappingTestV2RightPanelErrorDetailsMockRequest;
