import { Card, Text } from "@merge-api/merge-javascript-shared";
import { MappingTestBlockExecution } from "../../../../../../../../../models/MappingTests";
import MappingTestBlockExecutionStatusBadge from "../../../../../../../../mapping-tests/shared/MappingTestBlockExecutionStatusBadge";
import BlockErrorsAccordion from "./BlockErrorsAccordion";
import BlockLogsAccordion from "./BlockLogsAccordion";

interface Props {
  blockNumber: number;
  blockExecution: MappingTestBlockExecution;
}

const MappingTestExecutionModalBlockCard = ({ blockNumber, blockExecution }: Props) => {
  return (
    <Card variant="outline" className="p-4 mb-4">
      <div className="flex flex-column justify-center items-center space-y-4 w-full">
        <div className="flex flex-row justify-between items-center w-full">
          <Text variant="h6">Mapping test block - {blockNumber.toString()}</Text>
          <MappingTestBlockExecutionStatusBadge testBlockExecution={blockExecution} />
        </div>
        <BlockErrorsAccordion errors={blockExecution.errors ?? []} />
        <BlockLogsAccordion selectedBlockExecution={blockExecution} />
      </div>
    </Card>
  );
};

export default MappingTestExecutionModalBlockCard;
