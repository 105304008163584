import { Container } from "react-bootstrap";
import Spinner from "../Spinner";

const FullPageSpinner = () => {
  return (
    <Container fluid className="align-items-center justify-content-center">
      <Spinner />
    </Container>
  );
};

export default FullPageSpinner;
