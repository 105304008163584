import {
  BlueprintAssertStep,
  BlueprintParameterValueConstant,
  BlueprintParameterValueNestedParameterValues,
  BlueprintParameterValueReturnValue,
  BlueprintParameterValueType,
} from "../../../models/Blueprints";
import { HeaderPretitle } from "../../shared/text/MergeText";
import useBlueprintContext from "../context/useBlueprintContext";
import { BlueprintAvailableParameter } from "../utils/BlueprintEditorUtils";
import { COMPARATOR_CHOICES } from "./BlueprintEditorRightPanelIfElseForm";
import DropdownFormField from "./DropdownFormField";
import FormField from "./FormField";
import TypeaheadFormFieldTypeahead from "./TypeaheadFormFieldTypeahead";

const BlueprintEditorRightPanelAssertForm = () => {
  const { selectedStep, updateStepParameterValue } = useBlueprintContext();
  const step = selectedStep as BlueprintAssertStep;

  const currentComparator = (step.parameter_values.comparator as BlueprintParameterValueConstant)
    ?.constant;
  const parameters = (step.parameter_values
    ?.parameters as BlueprintParameterValueNestedParameterValues).nested_parameter_values;

  const updateParameter = (key: string, newValue: any) => {
    updateStepParameterValue(step, "parameters", {
      nested_parameter_values: { ...parameters, [key]: newValue },
      value_type: BlueprintParameterValueType.nestedParameterValues,
    });
  };

  return (
    <>
      <form>
        <HeaderPretitle className="my-2">Parameters</HeaderPretitle>
        <DropdownFormField
          choices={COMPARATOR_CHOICES}
          currentValue={currentComparator}
          placeholder={"Select an assert type"}
          title={"Assert Type"}
          subtitle={"The type of assertion. Validation will fail if the assertion fails."}
          onChange={(e) =>
            updateStepParameterValue(step, "comparator", {
              constant: e.target.value,
              value_type: BlueprintParameterValueType.constant,
            })
          }
        />
        {Object.entries(parameters).map(([key, value]) => (
          <div key={key}>
            <FormField title={key} subtitle={"A value to be compared."}>
              <TypeaheadFormFieldTypeahead
                allowConstantValues
                onChange={(options: BlueprintAvailableParameter[]) =>
                  updateParameter(
                    key,
                    options[0]?.customOption
                      ? {
                          constant: options[0].labelKey,
                          value_type: BlueprintParameterValueType.constant,
                        }
                      : (options[0]?.parameterValue as BlueprintParameterValueReturnValue | null)
                  )
                }
                currentParameterValue={value}
                parameterType={"any"}
                parameterKey={key}
              />
            </FormField>
          </div>
        ))}
      </form>
    </>
  );
};

export default BlueprintEditorRightPanelAssertForm;
