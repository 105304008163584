import { showErrorToast } from "../../../../components/shared/Toasts";
import { fetchWithAuth } from "../../../../api-client/api_client";

interface AutogenerateRateLimitProps {
  integrationID: string;
}

const useAutogenerateRateLimit = (params: AutogenerateRateLimitProps) => {
  const autogenerateRateLimit = () => {
    fetchWithAuth({
      path: `/integrations/integration-builder/integration/${params.integrationID}/autogenerate-rate-limit`,
      method: "POST",
      onResponse: () => {},
      onError: () => {
        showErrorToast("Failed to initiate rate limit generation.");
      },
    });
  };

  return { autogenerateRateLimit };
};

export default useAutogenerateRateLimit;
