import {
  ScraperVersion,
  ScraperAppendToArrayStep,
  ScraperConditionalAction,
  ScraperConstantValue,
  ScraperCustomFunctionStep,
  ScraperElementSelectorValue,
  ScraperExceptionType,
  ScraperGetUserInputIntegrationSetupStepType,
  ScraperGhostStep,
  ScraperNestedParameterValues,
  ScraperRequestEventType,
  ScraperSetKeyValueStep,
  ScraperStep,
  ScraperStepPaths,
  ScraperStepType,
  ScraperUserInputFailedAction,
  ScraperValue,
  ScraperValueType,
} from "../types";
import * as immutable from "object-path-immutable";
import {
  AddStepRelation,
  JSONArraySchema,
  JSONObjectSchema,
  JSONSchemaValue,
  SetVariableValueType,
} from "../../../models/Blueprints";
import cloneDeep from "lodash/cloneDeep";
import {
  areParametersForBlueprintOrScraperStepAvailableToCurrentStep,
  convertTraversalPathToDotNotation,
  getAllStepsForBlueprintOrScraper,
  getEnforceStepTraversalPathForStepID,
  getForTraversalPath,
  getStepForStepID,
  getStepTraversalPathForStepID,
  isStepTypeALoop,
  recursivelyAppendAvailableParametersFromGlobalVarJSONSchema,
} from "../../blueprint-editor/utils/BlueprintEditorUtils";
import {
  getCanvasConfigurationBase,
  StepPlacementType,
} from "../../blueprint-editor/utils/BlueprintCanvasUtils";
import {
  getEmptyArrayJSONSchema,
  getGenericJSONSchemaValue,
} from "../../blueprint-editor/utils/BlueprintDataTransformUtils";
import { isValidJSON } from "../../../utils";

export type TraversalPath = Array<string | number>;

export const addNewStep = (
  scraper: ScraperVersion,
  step: ScraperStep
): { scraper: ScraperVersion; step: ScraperStep } => {
  return { scraper: { ...scraper, steps: [...scraper.steps, step] }, step };
};

export const editExistingStep = (
  scraper: ScraperVersion,
  step: ScraperStep
): { scraper: ScraperVersion; step: ScraperStep } => {
  const path = getEnforceStepTraversalPathForStepID(scraper, step.id);

  const newScraper = immutable.set(scraper, path, step);

  return { scraper: newScraper, step };
};

export const getAllStepIDsInBranch = (step?: ScraperStep): Array<string> => {
  if (!step) {
    return [];
  }
  let IDs: Array<string> = [];

  const stepTraversalHelper = (step: ScraperStep) => {
    IDs.push(step.id);
    if (step.paths != null) {
      for (const [_, substeps] of Object.entries(step.paths)) {
        substeps.forEach((substep) => stepTraversalHelper(substep));
      }
    }
  };

  stepTraversalHelper(step);

  return IDs;
};

const getAllStepIDsInScraper = (scraper: ScraperVersion) => {
  let IDs: Array<string> = [];
  const stepTraversalHelper = (step: ScraperStep) => {
    IDs.push(step.id);
    if (step.paths != null) {
      for (const [_, substeps] of Object.entries(step.paths)) {
        substeps.forEach((substep) => stepTraversalHelper(substep));
      }
    }
  };
  for (const [_, step] of Object.entries(scraper.steps)) {
    if (!step) {
      return [];
    }
    stepTraversalHelper(step as ScraperStep);
  }

  return IDs;
};

export const collapseScraperSubsteps = (
  scraper: ScraperVersion,
  stepID: string
): { scraper: ScraperVersion; step: ScraperStep } => {
  const step = getStepForStepID(scraper, stepID) as ScraperStep;
  const path = getStepTraversalPathForStepID(scraper, stepID);
  if (!path) {
    return { scraper: scraper, step: step };
  }

  if (!step) {
    return { scraper: scraper, step: step };
  }

  const updatedStep = { ...step };
  updatedStep.hasCollapsedSubsteps = !step.hasCollapsedSubsteps;

  return { scraper: immutable.set(scraper, path, updatedStep), step: updatedStep };
};

export const renameScraperStep = (
  scraper: ScraperVersion,
  stepID: string,
  newID: string
): { scraper: ScraperVersion; step: ScraperStep } | null => {
  const stepTraversalHelper = (
    steps: Array<ScraperStep>,
    stepID: string,
    newID: string
  ): boolean => {
    for (const step of steps.values()) {
      if (step.id === newID) {
        return false;
      } else if (step.id === stepID) {
        step.id = newID;
      }

      const paths = { ...step.paths };

      if (paths != null) {
        for (const [_, substeps] of Object.entries(paths)) {
          if (!stepTraversalHelper(substeps as Array<ScraperStep>, stepID, newID)) {
            return false;
          }
        }
      }
    }

    return true;
  };

  const newScraper = cloneDeep(scraper);

  const successfulTraversal = stepTraversalHelper(newScraper.steps, stepID, newID);

  if (!successfulTraversal) {
    return null;
  }

  const newStep = getStepForStepID(newScraper, newID) as ScraperStep;
  return { scraper: newScraper, step: newStep };
};

export const addNewCopiedStep = (
  scraper: ScraperVersion,
  copiedStep: ScraperStep,
  relatedStepID: string,
  newStepRelation: AddStepRelation,
  pathKey?: string
): { scraper: ScraperVersion; step: ScraperStep } => {
  // increment step ids, clear out param values

  const newStep = cloneDeep(copiedStep);
  const siblingTraversalPath = getStepTraversalPathForStepID(scraper, relatedStepID) as Array<
    string | number
  >;
  const newBranchTraversalPath = [
    ...siblingTraversalPath.slice(0, -1),
    (siblingTraversalPath[siblingTraversalPath.length - 1] as number) +
      (newStepRelation === AddStepRelation.SIBLING_AFTER ? 1 : 0),
  ];
  newBranchTraversalPath.shift();
  const createdIDs = new Set();
  const allStepIDs = getAllStepIDsInScraper(scraper);
  const stepTraversalHelper = (steps: Array<ScraperStep>): void => {
    for (const [_, step] of steps.entries()) {
      while (allStepIDs.includes(step.id) || createdIDs.has(step.id)) {
        step.id = step.id + "x";
      }
      createdIDs.add(step.id);
      const paths = { ...step.paths };

      const copiedParamValues = JSON.parse(JSON.stringify(step.parameter_values));

      step.parameter_values = copiedParamValues;
      if (paths != null) {
        for (const [_, substeps] of Object.entries(paths)) {
          stepTraversalHelper(substeps as Array<ScraperStep>);
        }
      }
    }
  };

  stepTraversalHelper([newStep]);
  if (newStepRelation === AddStepRelation.CHILD) {
    return addChildStep(scraper, newStep, relatedStepID, pathKey);
  } else {
    return addSiblingStep(scraper, newStep, relatedStepID, newStepRelation);
  }
};

export const deleteScraperStep = (scraper: ScraperVersion, step: ScraperStep): ScraperVersion => {
  const path = getEnforceStepTraversalPathForStepID(scraper, step.id);

  const childIndex = path[path.length - 1] as number;
  const parentPath = path.slice(0, -1);
  const childrenArray = (immutable.get(scraper, parentPath) as unknown) as Array<ScraperStep>;
  childrenArray.splice(childIndex, 1);

  return immutable.set(scraper, parentPath, childrenArray);
};

export const getNextIDNumberForStepPrefix = (scraper: ScraperVersion, prefix: string): number => {
  let maxID = 0;

  const stepTraversalHelper = (steps: Array<ScraperStep>): void => {
    for (const step of steps.values()) {
      if (step.id.startsWith(prefix)) {
        const IDVal = parseInt(step.id.replace(prefix, "").replace(/\D/g, ""));
        maxID = Math.max(maxID, IDVal);
      }
      const paths = step.paths;
      if (paths !== null && paths !== undefined) {
        for (const substeps of Object.values(paths)) {
          stepTraversalHelper(substeps);
        }
      }
    }
  };

  stepTraversalHelper(scraper.steps);
  return maxID + 1;
};

export const getStepTypeForEventType = (eventType: ScraperRequestEventType): ScraperStepType => {
  switch (eventType) {
    case ScraperRequestEventType.CLICK:
      return ScraperStepType.CLICK;
    case ScraperRequestEventType.CHANGE:
      return ScraperStepType.INPUT;
    case ScraperRequestEventType.KEYPRESS:
      return ScraperStepType.PRESS_KEY;
  }
};

export const getIDPrefixForStepType = (stepType: ScraperStepType): string => {
  switch (stepType) {
    case ScraperStepType.CLICK:
      return "click";
    case ScraperStepType.INPUT:
      return "input";
    case ScraperStepType.WAIT:
      return "wait";
    case ScraperStepType.READ_AND_SAVE:
      return "readandsave";
    case ScraperStepType.READ_FROM_CLIPBOARD:
      return "readfromclipboard";
    case ScraperStepType.SAVE_CURRENT_URL:
      return "savecurrenturl";
    case ScraperStepType.TAKE_SCREENSHOT:
      return "takescreenshot";
    case ScraperStepType.CLICK_AND_DOWNLOAD:
      return "clickanddownload";
    case ScraperStepType.CLICK_AND_DOWNLOAD_CSV:
      return "clickanddownloadcsv";
    case ScraperStepType.EXPECT_DOWNLOAD_CSV:
      return "expectdownloadcsv";
    case ScraperStepType.GOTO:
      return "goto";
    case ScraperStepType.PRESS_KEY:
      return "presskey";
    case ScraperStepType.READ_STORAGE_STATE:
      return "readstoragestate";
    case ScraperStepType.CONDITIONAL_ACTION:
      return "conditionalaction";
    case ScraperStepType.RAISE_EXCEPTION:
      return "raiseexception";
    case ScraperStepType.GET_USER_INPUT:
      return "getuserinput";
    case ScraperStepType.ARRAY_LOOP:
      return "arrayloop";
    case ScraperStepType.QUERY_AND_EVAL:
      return "queryandeval";
    case ScraperStepType.SET_VARIABLE:
      return "setvariable";
    case ScraperStepType.SET_KEY_VALUE:
      return "setkeyvalue";
    case ScraperStepType.APPEND_TO_ARRAY:
      return "appendtoarray";
    case ScraperStepType.CONCATENATE_STRINGS:
      return "concatenatestrings";
    case ScraperStepType.QUERY_SELECTOR_LOOP:
      return "queryselectorloop";
    case ScraperStepType.REGEX:
      return "regex";
    case ScraperStepType.SAVE_NETWORK_RESPONSE:
      return "savenetworkresponse";
    case ScraperStepType.SELECT_OPTION:
      return "selectoption";
    case ScraperStepType.WAIT_FOR_SELECTOR:
      return "waitforselector";
    case ScraperStepType.IF_ELSE:
      return "ifelse";
    case ScraperStepType.CUSTOM_FUNCTION:
      return "customfunction";
    case ScraperStepType.SAVE_MFA_SECRET_KEY:
      return "savemfasecretkey";
    case ScraperStepType.GENERATE_MFA_CODE_FROM_SECRET_KEY:
      return "generatemfacodefromsecretkey";
  }
};

export const getStepIDForNewStep = (scraper: ScraperVersion, stepType: ScraperStepType): string => {
  const prefix = getIDPrefixForStepType(stepType);
  const number = getNextIDNumberForStepPrefix(scraper, prefix);
  return `${prefix}${number}`;
};

export const isTextNotEmpty = (text: string): boolean =>
  text !== null && text !== "" && text !== " ";

export const addSiblingStep = (
  scraper: ScraperVersion,
  newStep: ScraperStep,
  siblingStepID: string,
  siblingStepRelation: AddStepRelation
): { scraper: ScraperVersion; step: ScraperStep } => {
  const path = getEnforceStepTraversalPathForStepID(scraper, siblingStepID);

  const childIndex = path[path.length - 1] as number;
  const parentPath = path.slice(0, -1);
  const childrenArray = (immutable.get(scraper, parentPath) as unknown) as Array<ScraperStep>;
  childrenArray.splice(
    childIndex + (siblingStepRelation === AddStepRelation.SIBLING_AFTER ? 1 : 0),
    0,
    newStep
  );

  const newScraper = immutable.set(scraper, parentPath, childrenArray);

  return { scraper: newScraper, step: newStep };
};

export const addChildStep = (
  scraper: ScraperVersion,
  newStep: ScraperStep,
  parentStepID: string,
  pathKey?: string
): { scraper: ScraperVersion; step: ScraperStep } => {
  const traversalPath = getEnforceStepTraversalPathForStepID(scraper, parentStepID);
  const parentPath = [...traversalPath, "paths", pathKey ?? ""];

  const currentPathArray: Array<ScraperStep> = getForTraversalPath(scraper, parentPath) ?? [];
  currentPathArray.unshift(newStep);

  return {
    scraper: immutable.set(scraper, parentPath, currentPathArray),
    step: newStep,
  };
};

export const addNewStepFromType = (
  scraper: ScraperVersion,
  step: ScraperGhostStep,
  stepType: ScraperStepType
): { scraper: ScraperVersion; step: ScraperStep } => {
  const parameterValues = (() => {
    switch (stepType) {
      case ScraperStepType.CLICK:
        return {
          element_selector: {
            value_type: ScraperValueType.ELEMENT_SELECTOR,
            selector: "",
            tag: "",
          } as ScraperElementSelectorValue,
          should_click_all: {
            value_type: ScraperValueType.CONSTANT,
            constant: false,
          },
        };
      case ScraperStepType.GOTO:
        return {
          url: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
        };
      case ScraperStepType.INPUT:
        return {
          input_value: { value_type: ScraperValueType.CONSTANT, constant: "" },
          element_selector: {
            value_type: ScraperValueType.ELEMENT_SELECTOR,
            selector: "",
            tag: "",
          } as ScraperElementSelectorValue,
          should_clear_before_inputting: {
            value_type: ScraperValueType.CONSTANT,
            constant: false,
          } as ScraperConstantValue,
          should_input_immediately: {
            value_type: ScraperValueType.CONSTANT,
            constant: false,
          },
        };
      case ScraperStepType.READ_AND_SAVE:
      case ScraperStepType.CLICK_AND_DOWNLOAD:
        return {
          element_selector: {
            value_type: ScraperValueType.ELEMENT_SELECTOR,
            selector: "",
            tag: "",
          } as ScraperElementSelectorValue,
        };
      case ScraperStepType.CLICK_AND_DOWNLOAD_CSV:
        return {
          element_selector: {
            value_type: ScraperValueType.ELEMENT_SELECTOR,
            selector: "",
            tag: "",
          } as ScraperElementSelectorValue,
          response_json_schema: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
        };
      case ScraperStepType.EXPECT_DOWNLOAD_CSV:
        return {
          response_json_schema: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
          timeout_override: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
        };
      case ScraperStepType.SAVE_NETWORK_RESPONSE:
        return {
          url_pattern: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
          should_save_all_matches_as_list: {
            value_type: ScraperValueType.CONSTANT,
            constant: false,
          } as ScraperConstantValue,
          response_json_schema: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
          should_log_sentry_error_if_no_match: {
            value_type: ScraperValueType.CONSTANT,
            constant: false,
          } as ScraperConstantValue,
        };
      case ScraperStepType.WAIT:
        return {
          timeout: {
            value_type: ScraperValueType.CONSTANT,
            constant: "1000",
          } as ScraperConstantValue,
        };
      case ScraperStepType.PRESS_KEY:
        return {
          key: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
        };
      case ScraperStepType.CONDITIONAL_ACTION:
        return {
          action: {
            value_type: ScraperValueType.CONSTANT,
            constant: ScraperConditionalAction.GET_MFA_CODE,
          },
          should_store_session_state: {
            value_type: ScraperValueType.CONSTANT,
            constant: false,
          },
          should_execute_if_not_visible: {
            value_type: ScraperValueType.CONSTANT,
            constant: false,
          },
          element_selector: {
            value_type: ScraperValueType.ELEMENT_SELECTOR,
            selector: "",
            tag: "",
          } as ScraperElementSelectorValue,
        };
      case ScraperStepType.RAISE_EXCEPTION:
        return {
          exception_type: {
            value_type: ScraperValueType.CONSTANT,
            constant: ScraperExceptionType.BAD_REQUEST,
          },
          exception_text: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          },
          should_retry: {
            value_type: ScraperValueType.CONSTANT,
            constant: false,
          },
          arguments: {
            value_type: ScraperValueType.NESTED_PARAMETER_VALUES,
            nested_parameter_values: {},
          } as ScraperNestedParameterValues,
        };
      case ScraperStepType.GET_USER_INPUT:
        return {
          integration_setup_step_type: {
            value_type: ScraperValueType.CONSTANT,
            constant: ScraperGetUserInputIntegrationSetupStepType.STEP_TYPE_MFA_CODE,
          } as ScraperConstantValue,
          prompt: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          },
          should_store_session_state: {
            value_type: ScraperValueType.CONSTANT,
            constant: false,
          } as ScraperConstantValue,
          timeout_ms: {
            value_type: ScraperValueType.CONSTANT,
            constant: "120000",
          } as ScraperConstantValue,
          failure_action: {
            value_type: ScraperValueType.CONSTANT,
            constant: ScraperUserInputFailedAction.RAISE_MFA_CODE_EXCEPTION,
          } as ScraperConstantValue,
          max_retries: {
            value_type: ScraperValueType.CONSTANT,
            constant: "5",
          } as ScraperConstantValue,
          condition_for_success_value1: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
          condition_for_success_comparator: {
            value_type: ScraperValueType.CONSTANT,
            constant: "=",
          } as ScraperConstantValue,
          condition_for_success_value2: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
          store_input_as_var: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          },
        };
      case ScraperStepType.QUERY_AND_EVAL:
        return {
          element_selector: {
            value_type: ScraperValueType.ELEMENT_SELECTOR,
            selector: "",
            tag: "",
          } as ScraperElementSelectorValue,
          should_query_all: {
            value_type: ScraperValueType.CONSTANT,
            constant: false,
          },
          should_fail_if_not_found: {
            value_type: ScraperValueType.CONSTANT,
            constant: false,
          },
          eval_expression: {
            value_type: ScraperValueType.CONSTANT,
            constant: "(element) => element.href",
          },
        };
      case ScraperStepType.ARRAY_LOOP:
        return {
          array: {
            value_type: ScraperValueType.CONSTANT,
            constant: undefined,
          },
          should_filter_by_unique: {
            value_type: ScraperValueType.CONSTANT,
            constant: false,
          },
        };
      case ScraperStepType.SET_VARIABLE:
        return {
          key: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
          value_type: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
        };
      case ScraperStepType.SET_KEY_VALUE:
        return {
          object: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
          value_key: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
          value: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
        };
      case ScraperStepType.APPEND_TO_ARRAY:
        return {
          array: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
          value: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
        };
      case ScraperStepType.CONCATENATE_STRINGS:
        return {
          x1: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
          x2: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
        };
      case ScraperStepType.QUERY_SELECTOR_LOOP:
        return {
          element_selector: {
            value_type: ScraperValueType.ELEMENT_SELECTOR,
            selector: "",
            tag: "",
          } as ScraperElementSelectorValue,
          action: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
        };
      case ScraperStepType.REGEX:
        return {
          string: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
          pattern: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
        };
      case ScraperStepType.READ_STORAGE_STATE:
        return {
          should_save_storage_state_to_linked_account: {
            value_type: ScraperValueType.CONSTANT,
            constant: false,
          },
        };
      case ScraperStepType.SELECT_OPTION:
        return {
          select_element_selector: {
            value_type: ScraperValueType.ELEMENT_SELECTOR,
            selector: "",
            tag: "",
          } as ScraperElementSelectorValue,
          option: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
        };
      case ScraperStepType.WAIT_FOR_SELECTOR:
        return {
          element_selector: {
            value_type: ScraperValueType.ELEMENT_SELECTOR,
            selector: "",
            tag: "",
          } as ScraperElementSelectorValue,
          should_continue_after_timeout_if_not_found: {
            value_type: ScraperValueType.CONSTANT,
            constant: false,
          },
        };
      case ScraperStepType.IF_ELSE:
        return {
          value1: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
          comparator: {
            value_type: ScraperValueType.CONSTANT,
            constant: "NOT_EQUAL",
          } as ScraperConstantValue,
          value2: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
        };
      case ScraperStepType.CUSTOM_FUNCTION:
        return {
          code: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
          arguments: {
            value_type: ScraperValueType.NESTED_PARAMETER_VALUES,
            nested_parameter_values: {},
          } as ScraperNestedParameterValues,
        } as ScraperCustomFunctionStep["parameter_values"];
      case ScraperStepType.SAVE_MFA_SECRET_KEY:
        return {
          mfa_secret_key: {
            value_type: ScraperValueType.CONSTANT,
            constant: "",
          } as ScraperConstantValue,
        };
      case ScraperStepType.READ_FROM_CLIPBOARD:
      case ScraperStepType.SAVE_CURRENT_URL:
      case ScraperStepType.GENERATE_MFA_CODE_FROM_SECRET_KEY:
      case ScraperStepType.TAKE_SCREENSHOT:
        return {};
    }
  })();

  const paths: ScraperStepPaths<"true"> | undefined = (() => {
    switch (stepType) {
      case ScraperStepType.CONDITIONAL_ACTION:
      case ScraperStepType.GET_USER_INPUT:
      case ScraperStepType.ARRAY_LOOP:
      case ScraperStepType.QUERY_SELECTOR_LOOP:
      case ScraperStepType.EXPECT_DOWNLOAD_CSV:
        return { true: [] };
      case ScraperStepType.IF_ELSE:
        return { true: [], false: [] };
      default:
        return undefined;
    }
  })();

  const newStep = {
    id: getStepIDForNewStep(scraper, stepType),
    step_type: stepType,
    parameter_values: parameterValues,
    paths,
  } as ScraperStep;

  switch (step.newStepRelation) {
    case AddStepRelation.SIBLING_BEFORE:
    case AddStepRelation.SIBLING_AFTER:
      return addSiblingStep(scraper, newStep, step.relatedStepID, step.newStepRelation);
    case AddStepRelation.CHILD:
      return addChildStep(scraper, newStep, step.relatedStepID, step.pathKey);
    default:
      throw new Error("not implemented yet");
  }
};

export const getStepConfigForStep = (step: ScraperStep | ScraperGhostStep) => {
  if ("template" in step && step.template === "ghost") {
    return { image: "", description: "New Step" };
  }

  const getDescriptionForScraperValue = (value: ScraperValue): string => {
    switch (value?.value_type) {
      case ScraperValueType.CONSTANT:
        return value.constant?.toString();
      case ScraperValueType.NONE:
        return "None";
      case ScraperValueType.GLOBAL_VARIABLE:
        return `global variable ${value.global_variable_key}`;
      case ScraperValueType.VARIABLE:
        return `Variable set at key ${value.key}`;
      case ScraperValueType.RETURN_VALUE:
        return `return value from step ${value.step_id} with traversal path ${value.traversal_path}`;
      case ScraperValueType.NESTED_PARAMETER_VALUES:
        return "nested parameter values";
      case ScraperValueType.ELEMENT_SELECTOR:
        const { tag, text, selector } = value;
        return `${tag} "${text ?? selector}"`;
      case null:
      case undefined:
        return "";
    }
  };

  step = step as ScraperStep;

  switch (step.step_type) {
    case ScraperStepType.CLICK:
      return {
        image: "",
        description: `Click on ${getDescriptionForScraperValue(
          step.parameter_values.element_selector
        )}`,
      };
    case ScraperStepType.INPUT:
      return {
        image: "",
        description: `Input ${getDescriptionForScraperValue(
          step.parameter_values.input_value
        )} in ${getDescriptionForScraperValue(step.parameter_values.element_selector)}`,
      };
    case ScraperStepType.GOTO:
      return {
        image: "",
        description: `Goto ${getDescriptionForScraperValue(step.parameter_values.url)}`,
      };
    case ScraperStepType.READ_AND_SAVE:
      return {
        image: "",
        description: `Read value at  ${getDescriptionForScraperValue(
          step.parameter_values.element_selector
        )} and save `,
      };
    case ScraperStepType.READ_FROM_CLIPBOARD:
      return {
        image: "",
        description: `Read from clipboard`,
      };
    case ScraperStepType.SAVE_CURRENT_URL:
      return {
        image: "",
        description: `Save current url`,
      };
    case ScraperStepType.TAKE_SCREENSHOT:
      return {
        image: "",
        description: `Take screenshot`,
      };
    case ScraperStepType.CLICK_AND_DOWNLOAD:
      return {
        image: "",
        description: `Click and download`,
      };
    case ScraperStepType.CLICK_AND_DOWNLOAD_CSV:
      return {
        image: "",
        description: `Click and download csv`,
      };
    case ScraperStepType.EXPECT_DOWNLOAD_CSV:
      return {
        image: "",
        description: `Expect download csv`,
      };
    case ScraperStepType.SAVE_NETWORK_RESPONSE:
      return {
        image: "",
        description: `Save network response`,
      };
    case ScraperStepType.WAIT:
      return {
        image: "",
        description: `Wait for ${getDescriptionForScraperValue(step.parameter_values.timeout)} ms`,
      };
    case ScraperStepType.PRESS_KEY:
      return {
        image: "",
        description: `Press ${getDescriptionForScraperValue(step.parameter_values.key)} key`,
      };
    case ScraperStepType.READ_STORAGE_STATE:
      return {
        image: "",
        description: `Read storage state`,
      };
    case ScraperStepType.CONDITIONAL_ACTION:
      return {
        image: "",
        description: `Conditional action`,
      };
    case ScraperStepType.RAISE_EXCEPTION:
      return {
        image: "",
        description: `Raise ${getDescriptionForScraperValue(step.parameter_values.exception_type)}`,
      };
    case ScraperStepType.GET_USER_INPUT:
      return {
        image: "",
        description: `Get user input ${
          step.parameter_values.integration_setup_step_type?.constant || ""
        }`,
      };
    case ScraperStepType.QUERY_AND_EVAL:
      return {
        image: "",
        description: `Query ${getDescriptionForScraperValue(
          step.parameter_values.element_selector
        )} and evaluate`,
      };
    case ScraperStepType.ARRAY_LOOP:
      return {
        image: "",
        description: `Array loop`,
      };
    case ScraperStepType.SET_VARIABLE:
      return {
        image: "",
        description: `Set variable with key ${step.parameter_values.key.constant}`,
      };
    case ScraperStepType.SET_KEY_VALUE:
      return {
        image: "",
        description: `Set ${getDescriptionForScraperValue(
          step.parameter_values.object
        )} key: ${getDescriptionForScraperValue(step.parameter_values.value_key)}`,
      };
    case ScraperStepType.APPEND_TO_ARRAY:
      return {
        image: "",
        description: `Append to ${getDescriptionForScraperValue(step.parameter_values.array)}`,
      };
    case ScraperStepType.CONCATENATE_STRINGS:
      return {
        image: "",
        description: `Concatenate strings`,
      };
    case ScraperStepType.QUERY_SELECTOR_LOOP:
      return {
        image: "",
        description: `Query Selector Loop`,
      };
    case ScraperStepType.REGEX:
      return {
        image: "",
        description: `Regex search`,
      };
    case ScraperStepType.SELECT_OPTION:
      return {
        image: "",
        description: `Select option`,
      };
    case ScraperStepType.WAIT_FOR_SELECTOR:
      return {
        image: "",
        description: `Wait for selector`,
      };
    case ScraperStepType.IF_ELSE:
      return {
        image: "",
        description: `If/else`,
      };
    case ScraperStepType.CUSTOM_FUNCTION:
      return {
        image: "",
        description: "Custom function",
      };
    case ScraperStepType.SAVE_MFA_SECRET_KEY:
      return {
        image: "",
        description: "Save MFA secret key",
      };
    case ScraperStepType.GENERATE_MFA_CODE_FROM_SECRET_KEY:
      return {
        image: "",
        description: "Generate MFA code from secret key",
      };
  }
};

// CANVAS

export interface CanvasExistingStepPlacement extends CanvasStepPlacementBase {
  step: ScraperStep;
  stepPlacementType: StepPlacementType.EXISTING;
}

export interface CanvasGhostStepPlacement extends CanvasStepPlacementBase {
  step: ScraperGhostStep;
  stepPlacementType: StepPlacementType.GHOST;
}

export interface CanvasStepPlacementBase {
  xIndex: number;
  yIndex: number;
}

export type CanvasStepPlacement = CanvasExistingStepPlacement | CanvasGhostStepPlacement;

export interface CanvasConfigurationBase {
  totalX: number;
  stepPlacements: { [stepID: string]: CanvasStepPlacement };
}

export const getJSONSchemaForScraperValue = (
  scraper: ScraperVersion,
  value: ScraperValue | undefined
): JSONSchemaValue => {
  switch (value?.value_type) {
    case ScraperValueType.RETURN_VALUE:
      const returnValueStep = getStepForStepID(scraper, value.step_id) as ScraperStep;
      return immutable.get(
        getReturnSchemaForStep(scraper, returnValueStep)?.value,
        value.return_schema_path
      ) as JSONSchemaValue;
    case ScraperValueType.VARIABLE:
      return getReturnSchemaForStep(
        scraper,
        getAllStepsForScraper(scraper).find(
          (step) =>
            step.step_type === ScraperStepType.SET_VARIABLE &&
            step.parameter_values.key.constant === value.key
        )
      )?.value as JSONObjectSchema;
    default:
      return getGenericJSONSchemaValue();
  }
};

export const getReturnSchemaForStep = (
  scraper: ScraperVersion,
  step: ScraperStep | undefined
): undefined | { key: string; value: JSONSchemaValue | undefined } => {
  if (!step) {
    return undefined;
  }
  switch (step.step_type) {
    case ScraperStepType.SET_VARIABLE:
      const returnValue = (() => {
        switch (step.parameter_values.value_type.constant) {
          case SetVariableValueType.EMPTY_ARRAY:
            const value = (getAllStepsForScraper(scraper).find(
              (s) =>
                s.step_type === ScraperStepType.APPEND_TO_ARRAY &&
                s.parameter_values.array &&
                step.parameter_values.key &&
                s.parameter_values.array?.key === step.parameter_values.key?.constant
            ) as ScraperAppendToArrayStep | undefined)?.parameter_values?.value;
            return {
              type: "array",
              items: getJSONSchemaForScraperValue(scraper, value),
            } as JSONArraySchema;
          case SetVariableValueType.EMPTY_OBJECT:
            return {
              type: "object",
              properties: Object.fromEntries(
                (getAllStepsForScraper(scraper).filter(
                  (s) =>
                    s.step_type === ScraperStepType.SET_KEY_VALUE &&
                    s.parameter_values.value_key &&
                    s.parameter_values.object?.key === step.parameter_values.key.constant
                ) as Array<ScraperSetKeyValueStep>).map((step) => [
                  step.parameter_values.value_key.constant,
                  getJSONSchemaForScraperValue(scraper, step.parameter_values.value),
                ])
              ),
            } as JSONObjectSchema;
          case SetVariableValueType.OTHER_VALUE:
          default:
            return getGenericJSONSchemaValue();
        }
      })();

      if (!returnValue) {
        return undefined;
      }

      return {
        key: step.parameter_values.key.constant as string,
        value: returnValue,
      };
    case ScraperStepType.CLICK_AND_DOWNLOAD_CSV:
      const CSVJSONSchemaAsString = (step.parameter_values.response_json_schema?.constant ??
        "") as string;

      if (!isValidJSON(CSVJSONSchemaAsString)) {
        return undefined;
      }

      return {
        key: step.id,
        value: {
          type: "object",
          properties: { return_value: JSON.parse(CSVJSONSchemaAsString) },
        },
      };
    case ScraperStepType.EXPECT_DOWNLOAD_CSV:
      const expectDownloadCSVJSONSchemaAsString = (step.parameter_values.response_json_schema
        ?.constant ?? "") as string;

      if (!isValidJSON(expectDownloadCSVJSONSchemaAsString)) {
        return undefined;
      }

      return {
        key: step.id,
        value: {
          type: "object",
          properties: { return_value: JSON.parse(expectDownloadCSVJSONSchemaAsString) },
        },
      };
    case ScraperStepType.SAVE_NETWORK_RESPONSE:
      const responseJSONSchemaAsString = (step.parameter_values.response_json_schema?.constant ??
        "") as string;

      if (!isValidJSON(responseJSONSchemaAsString)) {
        return undefined;
      }

      const isList = step.parameter_values?.should_save_all_matches_as_list?.constant ?? false;

      const individualResponseJSONSchema: JSONObjectSchema = {
        type: "object",
        properties: {
          url: { type: "string" },
          response: JSON.parse(responseJSONSchemaAsString) as JSONSchemaValue,
        },
      };

      const returnValueJSONSchema: JSONArraySchema | JSONObjectSchema = isList
        ? {
            type: "array",
            items: individualResponseJSONSchema,
          }
        : individualResponseJSONSchema;

      return {
        key: step.id,
        value: {
          type: "object",
          properties: { return_value: returnValueJSONSchema },
        },
      };
    case ScraperStepType.READ_AND_SAVE:
    case ScraperStepType.READ_FROM_CLIPBOARD:
    case ScraperStepType.SAVE_CURRENT_URL:
    case ScraperStepType.CLICK_AND_DOWNLOAD:
    case ScraperStepType.READ_STORAGE_STATE:
    case ScraperStepType.CONCATENATE_STRINGS:
    case ScraperStepType.QUERY_AND_EVAL:
    case ScraperStepType.REGEX:
      return {
        key: step.id,
        value: {
          type: "object",
          properties: { return_value: { type: "string" } },
        },
      };
    default:
      return undefined;
  }
};

export const getReturnSchemaForScraper = (scraper: ScraperVersion): JSONObjectSchema => {
  const properties: { [key: string]: JSONSchemaValue } = Object.fromEntries(
    getAllStepsForScraper(scraper)
      .map((step) => {
        const returnSchema = getReturnSchemaForStep(scraper, step);
        return [returnSchema?.key, returnSchema?.value];
      })
      .filter(([_, value]) => !!value)
  );
  return {
    type: "object",
    properties,
  };
};

export interface ScraperAvailableParameter {
  labelKey: string;
  parameterValue: ScraperValue;
  customOption?: boolean;
}

export const getAllStepsForScraper = (scraper: ScraperVersion): ScraperStep[] =>
  getAllStepsForBlueprintOrScraper(scraper) as ScraperStep[];

export const recursivelyAppendParameters = (
  resultArray: Array<ScraperAvailableParameter>,
  stepID: string,
  jsonSchema: JSONSchemaValue | null,
  shouldRecurseIntoArray = false,
  traversalPath: Array<string>,
  returnSchemaPath: Array<string>
): void => {
  if (!jsonSchema) {
    return;
  }

  resultArray.push({
    labelKey: convertTraversalPathToDotNotation([stepID, ...traversalPath]),
    parameterValue: {
      step_id: stepID,
      traversal_path: traversalPath,
      return_schema_path: returnSchemaPath,
      value_type: ScraperValueType.RETURN_VALUE,
    },
  });

  if (jsonSchema.type === "array" && !shouldRecurseIntoArray) {
    return;
  }

  const properties =
    jsonSchema.type === "array"
      ? //@ts-ignore
        jsonSchema.items?.properties
      : jsonSchema.type === "object" || jsonSchema.type === undefined
      ? jsonSchema.properties
      : {};

  const newReturnSchemaPath =
    jsonSchema.type === "array"
      ? [...returnSchemaPath, "items", "properties"]
      : [...returnSchemaPath, "properties"];

  for (const [key, jsonSchemaValue] of Object.entries(properties ?? {})) {
    recursivelyAppendParameters(
      resultArray,
      stepID,
      //@ts-ignore
      jsonSchemaValue,
      false,
      [...traversalPath, key],
      [...newReturnSchemaPath, key]
    );
  }
};

export const areParametersForScraperStepAvailableToCurrentStep = (
  scraper: ScraperVersion,
  currentStepTraversalPath: TraversalPath,
  stepToCheck: ScraperStep
): boolean =>
  areParametersForBlueprintOrScraperStepAvailableToCurrentStep(
    scraper,
    currentStepTraversalPath,
    stepToCheck
  );

// Filter
const appendAvailableParametersForStepToResultArray = (
  scraper: ScraperVersion,
  step: ScraperStep,
  resultArray: Array<ScraperAvailableParameter>
): void => {
  const getAvailableParamSchemaForStepAndReturnSchemaPath = (
    step: ScraperStep,
    traversalPath: TraversalPath = []
  ): JSONSchemaValue => {
    const getParamSchema = () => {
      switch (step?.step_type) {
        case ScraperStepType.ARRAY_LOOP:
          if (step.parameter_values.array === null) {
            return undefined;
          }

          //@ts-ignore
          const { step_id, return_schema_path, value_type } = step.parameter_values.array;

          if (value_type === "SCRAPER_VALUE_TYPE_VARIABLE") {
            return getEmptyArrayJSONSchema();
          } else if (step_id !== null) {
            const returnValueStep = getStepForStepID(scraper, step_id) as ScraperStep;
            return getAvailableParamSchemaForStepAndReturnSchemaPath(
              returnValueStep,
              return_schema_path
            );
          } else if (value_type === ScraperValueType.GLOBAL_VARIABLE && return_schema_path) {
            return immutable.get(scraper.global_var_json_schema, return_schema_path);
          } else {
            return getEmptyArrayJSONSchema();
          }
        case ScraperStepType.QUERY_AND_EVAL:
          return {
            type: "object",
            properties: {
              return_value: step.parameter_values.should_query_all
                ? getEmptyArrayJSONSchema()
                : getGenericJSONSchemaValue(),
            },
          };
        case ScraperStepType.SAVE_NETWORK_RESPONSE:
          const responseJSONSchemaAsString = (step.parameter_values.response_json_schema
            ?.constant ?? "") as string;

          if (!isValidJSON(responseJSONSchemaAsString)) {
            return undefined;
          }

          const isList = step.parameter_values?.should_save_all_matches_as_list?.constant ?? false;

          const individualResponseJSONSchema: JSONObjectSchema = {
            type: "object",
            properties: {
              url: { type: "string" },
              response: JSON.parse(responseJSONSchemaAsString) as JSONSchemaValue,
            },
          };

          const returnValueJSONSchema: JSONArraySchema | JSONObjectSchema = isList
            ? {
                type: "array",
                items: individualResponseJSONSchema,
              }
            : individualResponseJSONSchema;

          return {
            type: "object",
            properties: { return_value: returnValueJSONSchema },
          };
        case ScraperStepType.REGEX:
        case ScraperStepType.CONCATENATE_STRINGS:
        case ScraperStepType.SAVE_CURRENT_URL:
          return {
            type: "object",
            properties: {
              return_value: getGenericJSONSchemaValue(),
            },
          };
        case ScraperStepType.EXPECT_DOWNLOAD_CSV:
        case ScraperStepType.CLICK_AND_DOWNLOAD_CSV:
          const csvResponseJSONSchemaAsString = (step.parameter_values.response_json_schema
            ?.constant ?? "") as string;

          if (!isValidJSON(csvResponseJSONSchemaAsString)) {
            return undefined;
          }

          const csvReturnValueJSONSchema: JSONArraySchema | JSONObjectSchema = JSON.parse(
            csvResponseJSONSchemaAsString
          );

          return {
            type: "object",
            properties: {
              return_value: csvReturnValueJSONSchema,
            },
          };
        case ScraperStepType.CUSTOM_FUNCTION:
          return {
            type: "object",
            properties: {
              return_value: getGenericJSONSchemaValue(),
            },
          };
        case ScraperStepType.GENERATE_MFA_CODE_FROM_SECRET_KEY:
          return {
            type: "object",
            properties: {
              return_value: getGenericJSONSchemaValue(),
            },
          };
        default:
          return undefined;
      }
    };

    return immutable.get(getParamSchema(), traversalPath) as JSONSchemaValue;
  };

  const availableParamSchema = getAvailableParamSchemaForStepAndReturnSchemaPath(step, []);

  const isAddingArrayLoopItem = isStepTypeALoop(step.step_type);

  recursivelyAppendParameters(
    resultArray,
    step.id,
    availableParamSchema,
    isAddingArrayLoopItem,
    isAddingArrayLoopItem ? ["item"] : [],
    []
  );

  if (step.step_type === ScraperStepType.SET_VARIABLE) {
    const key = (step.parameter_values.key as ScraperConstantValue)?.constant?.toString();
    if (key) {
      resultArray.push({
        labelKey: key,
        parameterValue: {
          key: key,
          value_type: ScraperValueType.VARIABLE,
        },
      });
    }
  }
  if (step.step_type === ScraperStepType.GET_USER_INPUT) {
    const key = (step.parameter_values
      .store_input_as_var as ScraperConstantValue)?.constant?.toString();
    if (key) {
      resultArray.push({
        labelKey: key,
        parameterValue: {
          key: key,
          value_type: ScraperValueType.VARIABLE,
        },
      });
    }
  }
};

export const getAvailableReturnValuesForScraperTypeahead = (
  scraper: ScraperVersion,
  currentStep: ScraperStep
): ScraperAvailableParameter[] => {
  const resultArray: ScraperAvailableParameter[] = [];
  const stepTraversalPath = getStepTraversalPathForStepID(scraper, currentStep.id);
  if (!stepTraversalPath) {
    return [];
  }
  stepTraversalPath.shift();

  // 1. Get all steps.
  const allSteps = getAllStepsForScraper(scraper);

  allSteps.forEach((stepToCheckForParameters) => {
    if (stepToCheckForParameters.id === currentStep.id) {
      return;
    }

    if (
      areParametersForScraperStepAvailableToCurrentStep(
        scraper,
        [...stepTraversalPath],
        stepToCheckForParameters
      )
    ) {
      appendAvailableParametersForStepToResultArray(scraper, stepToCheckForParameters, resultArray);
    }
  });

  return resultArray;
};

export const getAvailableParametersForScraperTypeahead = (
  scraper: ScraperVersion,
  currentStep: ScraperStep,
  currentParameterValue: ScraperValue | undefined
): ScraperAvailableParameter[] => {
  const availableGlobalVarKeys = [
    "basic_auth_username",
    "basic_auth_password",
    "needs_password_reset",
    "is_relink_needed",
    "is_complete",
    "trigger_type",
    "api_url_subdomain",
    "override_scraper_domain_base_url",
    "organization_name",
    "mfa_code",
    "additional_auth_fields",
    "service_account_fields",
    "security_questions",
  ];

  const returnValues = getAvailableReturnValuesForScraperTypeahead(scraper, currentStep);

  const globalVarParameterValues: ScraperAvailableParameter[] = availableGlobalVarKeys.map(
    (globalVar) => ({
      labelKey: globalVar,
      parameterValue: {
        value_type: ScraperValueType.GLOBAL_VARIABLE,
        global_variable_key: globalVar,
      },
    })
  );

  const commonModelGlobalVarParameterValues: ScraperAvailableParameter[] = recursivelyAppendAvailableParametersFromGlobalVarJSONSchema(
    {
      jsonSchema: scraper.global_var_json_schema,
      returnSchemaPath: [],
      requestReturnValuePath: [],
    }
  ).map((param) => ({
    labelKey: convertTraversalPathToDotNotation(param.requestReturnValuePath),
    parameterValue: {
      value_type: ScraperValueType.GLOBAL_VARIABLE,
      return_schema_path: param.returnSchemaPath,
      request_return_value_path: param.requestReturnValuePath,
    },
  }));

  const constantParameterValue: ScraperAvailableParameter[] =
    currentParameterValue?.value_type === ScraperValueType.CONSTANT
      ? [
          {
            labelKey: currentParameterValue?.constant?.toString() ?? "",
            parameterValue: currentParameterValue,
          },
        ]
      : [];

  return [
    ...globalVarParameterValues,
    ...constantParameterValue,
    ...returnValues,
    ...commonModelGlobalVarParameterValues,
    {
      labelKey: "None",
      parameterValue: { value_type: ScraperValueType.NONE },
    },
  ];
};

export const getNameForScraperValueType = (valueType: ScraperValueType): string => {
  switch (valueType) {
    case ScraperValueType.CONSTANT:
      return "Constant";
    case ScraperValueType.GLOBAL_VARIABLE:
      return "Global Variable";
    case ScraperValueType.RETURN_VALUE:
      return "Return Value";
    case ScraperValueType.VARIABLE:
      return "Variable";
    case ScraperValueType.ELEMENT_SELECTOR:
      return "Element Selector";
    case ScraperValueType.NESTED_PARAMETER_VALUES:
      return "Nested Parameter Values";
    case ScraperValueType.NONE:
      return "None";
  }
};

export const getScraperCanvasConfiguration = (
  scraper: ScraperVersion,
  selectedStep: undefined | ScraperStep | ScraperGhostStep
): CanvasConfigurationBase =>
  getCanvasConfigurationBase(scraper, selectedStep, undefined) as CanvasConfigurationBase;
