import { Alert, Text } from "@merge-api/merge-javascript-shared";
import { useState } from "react";

import LineIndent from "../../../authentication/components/shared/LineIndent";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import TypeaheadHeader from "../../../shared/TypeaheadHeader";
import { ConditionType } from "../../types";

type Props = {
  showComponent: boolean;
};

const renderValueTypeOption = (input: string) => {
  // Replace underscores with spaces
  const transformed = input.replace(/_/g, " ");
  // Capitalize the first letter and make the rest lowercase
  return <div> {transformed.charAt(0).toUpperCase() + transformed.slice(1).toLowerCase()} </div>;
};

const CreateNewNormalizedKeyNameInfo = ({ showComponent }: Props) => {
  const [fieldName, setFieldName] = useState<string>("");
  const [normalizedFieldValueType, setNormalizedFieldValueType] = useState<ConditionType | null>(
    null
  );

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFieldName(event.target.value);

  const handleValueTypeChange = (_: any, value: ConditionType | ConditionType[] | null) =>
    setNormalizedFieldValueType(value as ConditionType);

  if (!showComponent) {
    return <></>;
  }

  return (
    <div className="flex flex-row h-full mt-6">
      <LineIndent>
        <div className="flex flex-col">
          <Alert showWarningIcon color="yellow" className="mb-6">
            <Text className="text-gray-90" variant="sm">
              {/* S2TODO: update placholder text - https://app.asana.com/0/1205644398660644/1207534868357516 */}{" "}
              Are you sure that none of the existing Normalized Key Names in the dropdown would fit
              this filter type? If so continue, but [.....] implications of creating a new
              normalized key name.{" "}
            </Text>
          </Alert>
          <TextFieldHeader
            title="New normalized key name"
            subtitle="This name must be normalized, not integration-specific"
            className="mb-6"
            value={fieldName}
            onChange={handleNameChange}
            required={!fieldName}
          />
          {/* S2TODO: update placholder text - https://app.asana.com/0/1205644398660644/1207534868357516 */}
          <TypeaheadHeader
            title="New normalized key type"
            subtitle="The value type of this key"
            value={normalizedFieldValueType}
            options={Object.values(ConditionType)}
            onChange={handleValueTypeChange}
            renderOption={renderValueTypeOption}
            required={!normalizedFieldValueType}
          />
        </div>
      </LineIndent>
    </div>
  );
};

export default CreateNewNormalizedKeyNameInfo;
