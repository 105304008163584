import { DiffStateFieldTypeEnum, DiffState } from "../../../../models/DiffModels";
import { generateDiffState } from "./helpers-diff";

interface Props {
  isForAPIEndpoint?: boolean;
}

// Generate diff for Pagination Configurations
export const generateDiffForPaginationConfiguration = ({
  isForAPIEndpoint = false,
}: Props): DiffState => {
  // TODO - Clean up how keyPathToName works in diff logic, so that we can have cleaner API here and across other components
  // todo task here: https://app.asana.com/0/1206943673736593/1207529340632143
  // parent path
  const keyPath = isForAPIEndpoint ? ["pagination_configuration"] : [];
  // request paths
  const keyPathToRequestInformation = isForAPIEndpoint
    ? keyPath.concat(["request_information"])
    : ["request_information"];
  const keyPathToRequestPaginationValueInformation = isForAPIEndpoint
    ? keyPathToRequestInformation.concat(["pagination_value_information"])
    : ["pagination_value_information"];
  const keyPathToRequestPaginationValuePageSizeInformation = isForAPIEndpoint
    ? keyPathToRequestInformation.concat(["page_size_information"])
    : ["page_size_information"];
  // response paths
  const keyPathToResponseInformation = isForAPIEndpoint
    ? keyPath.concat(["response_information"])
    : ["response_information"];
  const keyPathToResponseResultsArrayKeyPath = isForAPIEndpoint ? keyPathToResponseInformation : [];
  const keyPathToResponseNextPaginationLocationInformation = isForAPIEndpoint
    ? keyPathToResponseInformation.concat(["pointer_for_next_request_information"])
    : ["pointer_for_next_request_information"];
  const keyPathToResponseEndOfPaginationInformation = isForAPIEndpoint
    ? keyPathToResponseInformation.concat(["end_of_pagination_information"])
    : ["end_of_pagination_information"];
  const keyPathToResponseMaxItems = isForAPIEndpoint
    ? keyPathToResponseEndOfPaginationInformation.concat(["max_items_information"])
    : ["max_items_information"];
  const keyPathToResponseBreakValue = isForAPIEndpoint
    ? keyPathToResponseEndOfPaginationInformation.concat(["break_value_information"])
    : ["break_value_information"];
  const keyPathToResponseOverrideInfiniteLoopPath = isForAPIEndpoint
    ? keyPathToResponseBreakValue
    : ["end_of_pagination_information", "break_value_information"];

  return generateDiffState({
    fields: [
      {
        type: isForAPIEndpoint ? DiffStateFieldTypeEnum.SECTION : DiffStateFieldTypeEnum.HEADER,
        name: "pag_config_setup_section",
        displayName: "Pagination setup",
        childDiffStateInputFields: [
          {
            name: "name",
            displayName: "Configuration name",
            keyPathToName: keyPath,
          },
          {
            name: "description",
            displayName: "Description",
            keyPathToName: keyPath,
          },
          {
            name: "pagination_type",
            displayName: "Pagination type",
            keyPathToName: keyPath,
          },
          {
            name: "is_default_for_integration",
            displayName: "Is default for integration?",
            keyPathToName: keyPath,
          },
        ],
      },
      {
        type: isForAPIEndpoint ? DiffStateFieldTypeEnum.SECTION : DiffStateFieldTypeEnum.HEADER,
        name: "request_information_header",
        displayName: "Request configuration",
        childDiffStateFields: generateDiffState({
          keyPathToSliceOfState: keyPathToRequestInformation,
          fields: [
            {
              name: "pagination_value_request_location",
              displayName: "Location of pagination value in request",
              keyPathToName: keyPathToRequestPaginationValueInformation,
              isRenderChildrenAsNested: true,
              childDiffStateInputFields: [
                {
                  name: "pagination_value_request_location_key_path",
                  displayName: "Pagination value key path",
                  keyPathToName: keyPathToRequestPaginationValueInformation,
                },
              ],
            },
            {
              name: "initial_pagination_value",
              displayName: "Initial offset value",
              keyPathToName: keyPathToRequestPaginationValueInformation,
            },
            {
              type: DiffStateFieldTypeEnum.SECTION,
              name: "page_size_information_section",
              displayName: "Page size information",
              childDiffStateInputFields: [
                {
                  name: "page_size",
                  displayName: "Page size",
                  keyPathToName: keyPathToRequestPaginationValuePageSizeInformation,
                },
                {
                  name: "page_size_request_location",
                  displayName: "Location of page size in request",
                  keyPathToName: keyPathToRequestPaginationValuePageSizeInformation,
                  isRenderChildrenAsNested: true,
                  childDiffStateInputFields: [
                    {
                      name: "page_size_key_path",
                      displayName: "Key path to page size",
                      keyPathToName: keyPathToRequestPaginationValuePageSizeInformation,
                    },
                  ],
                },
              ],
            },
          ],
        }),
      },
      {
        type: isForAPIEndpoint ? DiffStateFieldTypeEnum.SECTION : DiffStateFieldTypeEnum.HEADER,
        name: "response_configuration_header",
        displayName: "Response configuration",
        childDiffStateFields: generateDiffState({
          keyPathToSliceOfState: keyPathToResponseInformation,
          fields: [
            {
              name: "paginated_results_array_key_path",
              displayName: "Key path to array of results",
              keyPathToName: keyPathToResponseResultsArrayKeyPath,
            },
            {
              name: "response_location",
              displayName: "Location of next pagination value in response",
              keyPathToName: keyPathToResponseNextPaginationLocationInformation,
              isRenderChildrenAsNested: true,
              childDiffStateInputFields: [
                {
                  name: "response_body_key_path",
                  displayName: "Key path to next page",
                  keyPathToName: keyPathToResponseNextPaginationLocationInformation,
                },
              ],
            },
            {
              name: "end_of_pagination_type",
              displayName: "When do we stop paginating?",
              keyPathToName: keyPathToResponseEndOfPaginationInformation,
              isRenderChildrenAsNested: true,
              childDiffStateInputFields: [
                {
                  type: DiffStateFieldTypeEnum.SECTION,
                  name: "total_number_of_items_section",
                  displayName: "Total # of items",
                  childDiffStateInputFields: [
                    {
                      name: "response_max_items_value_location",
                      displayName: "Location in response",
                      keyPathToName: keyPathToResponseMaxItems,
                      isRenderChildrenAsNested: true,
                      childDiffStateInputFields: [
                        {
                          name: "response_max_items_key_path",
                          displayName: "Key path in response",
                          keyPathToName: keyPathToResponseMaxItems,
                        },
                      ],
                    },
                  ],
                },
                {
                  type: DiffStateFieldTypeEnum.SECTION,
                  name: "break_value_section",
                  displayName: "Break value",
                  childDiffStateInputFields: [
                    {
                      name: "break_value_type",
                      displayName: "Type of break value",
                      keyPathToName: keyPathToResponseBreakValue,
                      isRenderChildrenAsNested: true,
                      childDiffStateInputFields: [
                        {
                          name: "break_value_response_body_key_path",
                          displayName: "Key path in response",
                          keyPathToName: keyPathToResponseBreakValue,
                        },
                        {
                          name: "specific_value_to_break_on",
                          displayName: "Value to indicate last page",
                          keyPathToName: keyPathToResponseBreakValue,
                        },
                        {
                          type: DiffStateFieldTypeEnum.SECTION,
                          name: "advanced",
                          displayName: "Advanced",
                          isRenderNumberOfChildrenInDisplayName: true,
                          childDiffStateInputFields: [
                            {
                              name: "override_infinite_loop_condition_key_path",
                              displayName: "Key path to override infinite loop condition",
                              keyPathToName: keyPathToResponseOverrideInfiniteLoopPath,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        }),
      },
      {
        type: isForAPIEndpoint ? DiffStateFieldTypeEnum.SECTION : DiffStateFieldTypeEnum.HEADER,
        name: "advanced",
        displayName: "Advanced",
        isRenderNumberOfChildrenInDisplayName: true,
        childDiffStateInputFields: [
          {
            type: DiffStateFieldTypeEnum.SECTION,
            name: "session_pagination_section",
            displayName: "Session pagination",
            childDiffStateInputFields: [
              {
                name: "is_session_pagination",
                displayName: "Is this session pagination?",
                keyPathToName: keyPath.concat(["session_information"]),
                isRenderChildrenAsNested: true,
                childDiffStateInputFields: [
                  {
                    name: "session_id_param_name",
                    displayName: "Session ID param",
                    keyPathToName: keyPath.concat(["session_information"]),
                  },
                  {
                    type: DiffStateFieldTypeEnum.SECTION,
                    name: "session_request_section",
                    displayName: "Request",
                    childDiffStateInputFields: [
                      {
                        name: "read_more_request_header_overrides",
                        displayName: "Read more paginated request header override",
                        keyPathToName: keyPath.concat(["session_information"]),
                      },
                      {
                        name: "read_more_paginated_request_body_base_config",
                        displayName: "Read more paginated request body base config",
                        keyPathToName: keyPath.concat(["session_information"]),
                      },
                    ],
                  },
                  {
                    type: DiffStateFieldTypeEnum.SECTION,
                    name: "session_response_section",
                    displayName: "Response",
                    childDiffStateInputFields: [
                      {
                        name: "read_more_response_body_array_key_path",
                        displayName: "Read more response body array key path",
                        keyPathToName: keyPath.concat(["session_information"]),
                      },
                      {
                        name: "read_more_response_max_items_key_path",
                        displayName: "Read more response max items key path",
                        keyPathToName: keyPath.concat(["session_information"]),
                      },
                      {
                        name: "response_session_id_key_path",
                        displayName: "Response session ID key path",
                        keyPathToName: keyPath.concat(["session_information"]),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: DiffStateFieldTypeEnum.SECTION,
            name: "sort_pagination_section",
            displayName: "Sort-assisted pagination",
            childDiffStateInputFields: [
              {
                name: "is_sort_assisted_pagination",
                displayName: "Should pagination be assisted by sorting?",
                keyPathToName: keyPath.concat(["sort_information"]),
                isRenderChildrenAsNested: true,
                childDiffStateInputFields: [
                  {
                    name: "sort_field_filter_request_location",
                    displayName: "Location of field in request used for sorting",
                    keyPathToName: keyPath.concat(["sort_information"]),
                  },
                  {
                    name: "sort_field_filter_request_key_path",
                    displayName: "Key path to field used for sorting",
                    keyPathToName: keyPath.concat(["sort_information"]),
                  },
                  {
                    name: "sort_field_filter_request_format",
                    displayName: "Format of value in request used for sorting",
                    keyPathToName: keyPath.concat(["sort_information"]),
                  },
                  {
                    name: "sort_field_filter_response_key_path",
                    displayName: "Key path to field in response used for sorting",
                    keyPathToName: keyPath.concat(["sort_information"]),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  });
};
