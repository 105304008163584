import { useHistory } from "react-router-dom";
import useIntegrationBuilderContext from "../../context/useIntegrationBuilderContext";
import IntegrationBuilderHeader from "../../shared/IntegrationBuilderHeader";
import { Button, Text } from "@merge-api/merge-javascript-shared";
import MergeTable from "../../../shared/MergeTable";
import TableSkeletonLoader from "../../../shared/TableSkeletonLoader";
import { WebAddressSchemaIntegrationBuilder } from "../../utils/Entities";
import WebAddressSchemaTableRow from "./WebAddressSchemaTableRow";
import { navigateToIntegrationBuilderCreateWebAddressSchema } from "../../../../router/RouterUtils";
import SchemaExplainAlert from "../shared/SchemaExplainAlert";
import IntegrationBuilderEmptyState from "../../shared/IntegrationBuilderEmptyState";
import { Plus } from "lucide-react";

interface WebAddressSchemaTableProps {
  webAddressSchemas: WebAddressSchemaIntegrationBuilder[] | undefined;
  isLoadingWebAddressSchemas: boolean;
  integrationID: string;
}

const WEB_ADDRESS_SCHEMA_SUBMIT_TEXT = "Save schema";

const AuthenticationConfigurationTable = ({
  webAddressSchemas,
  isLoadingWebAddressSchemas,
  integrationID,
}: WebAddressSchemaTableProps) => {
  //hooks
  const history = useHistory();
  // context
  useIntegrationBuilderContext({
    submitButtonText: WEB_ADDRESS_SCHEMA_SUBMIT_TEXT,
    canSubmit: false,
    shouldRenderSubmitButton: false,
    shouldRenderNavigationButtons: true,
    shouldRenderDeleteButton: false,
    shouldRenderStageButton: false,
    markForDeletion: false,
  });

  return (
    <div>
      <IntegrationBuilderHeader
        title="Web address schemas"
        button={
          <Button
            size="md"
            leftIcon={<Plus size={16} />}
            onClick={() => {
              navigateToIntegrationBuilderCreateWebAddressSchema(history, integrationID);
            }}
          >
            Schema
          </Button>
        }
      />

      <>
        <SchemaExplainAlert className="mb-6" />
        <MergeTable
          hover={webAddressSchemas && webAddressSchemas.length > 0}
          hasMarginBottom={false}
          hasMorePaddingOnFirstElement
          header={
            <>
              <th scope="col">URL</th>
              <th scope="col">Description</th>
            </>
          }
          content={
            isLoadingWebAddressSchemas ? (
              <TableSkeletonLoader cols={2} rows={3} size="large" />
            ) : (
              <>
                {webAddressSchemas && webAddressSchemas.length > 0 ? (
                  <>
                    {webAddressSchemas.map((schema) => (
                      <WebAddressSchemaTableRow
                        key={schema.id}
                        webAddressSchema={schema}
                        integrationID={integrationID}
                      />
                    ))}
                  </>
                ) : (
                  <IntegrationBuilderEmptyState
                    title="No web address schemas"
                    subtitle={
                      <div>
                        Click <Text variant="title-sm">+ Schema</Text> in the top right to manually
                        add one
                      </div>
                    }
                    numberOfColumns={2}
                  />
                )}
              </>
            )
          }
        />
      </>
    </div>
  );
};

export default AuthenticationConfigurationTable;
