import { useEffect, useMemo, useReducer, useState } from "react";
import useLoadValidatedStagedVersions from "../hooks/useLoadValidatedStagedVersion";
import {
  ADD_BLOCKING_RULE,
  BlockingRuleAction,
  blockingRulesReducer,
  CLEAR_BLOCKING_RULES,
  generateRuleKey,
  REMOVE_BLOCKING_RULE,
} from "../reducers/blockingRulesReducer";
import {
  IntegrationComponentNames,
  IntegrationValidatorRuleResult,
  IntegrationValidatorRuleResultType,
  IntegrationValidatorRuleSeverityType,
  PublishIntegrationVersionRequestBodyType,
  ValidatedStagedComponent,
} from "../types";
import { PublishModuleContext } from "./PublishModuleContext";
import useLoadMappingTestExecutions from "../hooks/useLoadMappingTestExecutions";
import useInterval from "../../../mapping-tests/utils/useInterval";
import { IntegrationTestSuiteExecutionStatus } from "../../../../models/MappingTests";
import useLoadChecklist from "../hooks/useLoadChecklist";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";

// Context provider component
const PublishModuleContextProvider = ({
  children,
  integrationID,
}: {
  children: JSX.Element;
  integrationID: string;
}) => {
  // PUBLISHING RELATED STATE
  const [isPublishModalOpen, setIsPublishModalOpen] = useState<boolean>(false);
  const [overrideComment, setOverrideComment] = useState<string | undefined>();
  const [overrideTicket, setOverrideTicket] = useState<string | undefined>();
  const [isSaving, setIsSaving] = useState(false);

  const onPublish = ({
    ticket,
    description,
    override_comment,
    override_ticket,
    blueprint_advanced_configurations = {},
  }: PublishIntegrationVersionRequestBodyType) => {
    setIsPublishModalOpen(false);
    setIsSaving(true);
    fetchWithAuth({
      path: `/integrations/versioning/${integrationID}/publish-version`,
      method: "POST",
      body: {
        ticket,
        description,
        blueprint_advanced_configurations,
        override_comment,
        override_ticket,
        blueprint_validator_results: [],
      },
      onResponse: () => {
        showSuccessToast("Successfully published versions!");
        setIsSaving(false);
        refetchPublishModuleInfo();
      },
      onError: () => {
        showErrorToast("Error publishing all versions.");
        setIsSaving(false);
      },
    });
  };

  useEffect(() => {
    if (!isSaving) {
      fetchIntegrationChecklist();
      fetchLatestIntegrationVersionTestSuiteExecution();
    }
  }, [integrationID, isSaving]);

  // STAGED COMPONENTS - Hook for loading validated staged versions
  const {
    publishModuleInfoV2: publishModuleInfo,
    setPublishModuleInfoV2: setPublishModuleInfo,
    fetchValidatedStagedVersions,
  } = useLoadValidatedStagedVersions({
    integrationID: integrationID,
  });

  // MAPPING TESTS - Hook for loading mapping test executions
  const {
    isFetchingLatestTestSuiteExecution,
    hasFailedFetchingLatestExecution,
    integrationTestSuiteExecution,
    isRunningMappingTests,
    fetchLatestIntegrationVersionTestSuiteExecution,
    onRunTestsForStagedIntegrationVersion,
  } = useLoadMappingTestExecutions({ integrationID: integrationID });

  // INTEGRATION CHECKLIST - Hook for loading integration checklist
  const { integrationChecklist, fetchIntegrationChecklist } = useLoadChecklist({
    integrationID: integrationID,
  });

  // Clear publishModuleInfo when integrationID and refetch the validated staged versions changes since this component doesn't unmount when switching integrations and staying on the same publish requests tab
  useEffect(() => {
    refetchPublishModuleInfo();
  }, [integrationID]);

  // Fetch the latest test suite execution every 5 seconds if the test suite is not in progress or if fetching the latest execution has failed
  useInterval(
    () => {
      fetchLatestIntegrationVersionTestSuiteExecution();
    },
    (integrationTestSuiteExecution &&
      integrationTestSuiteExecution.status !== IntegrationTestSuiteExecutionStatus.IN_PROGRESS) ||
      hasFailedFetchingLatestExecution
      ? null
      : 5000
  );

  // Reducer for managing state of blocking rules that are failing and not overridden
  const [blockingRules, dispatchBlockingRules] = useReducer<
    (state: Set<string>, action: BlockingRuleAction) => Set<string>
  >(blockingRulesReducer, new Set<string>());

  // Helper method to add a blocking rule to the blockingRules state
  const addBlockingRule = (rule: IntegrationValidatorRuleResult) => {
    if (
      rule.result === IntegrationValidatorRuleResultType.SUCCESS ||
      rule.is_overridden ||
      !rule.is_new_failure
    ) {
      return;
    }
    const ruleKey = generateRuleKey(rule);
    dispatchBlockingRules({ type: ADD_BLOCKING_RULE, payload: ruleKey });
  };

  // Helper method to remove a blocking rule from the blockingRules state
  const removeBlockingRule = (rule: IntegrationValidatorRuleResult) => {
    if (rule.result === IntegrationValidatorRuleResultType.SUCCESS) {
      return;
    }
    const ruleKey = generateRuleKey(rule);
    dispatchBlockingRules({ type: REMOVE_BLOCKING_RULE, payload: ruleKey });
  };

  // Adds all the failed blocking rules to the blockingRules state
  useEffect(() => {
    if (publishModuleInfo) {
      Object.values(IntegrationComponentNames).forEach((integrationComponentName) => {
        publishModuleInfo.staged_changes?.[integrationComponentName]?.forEach(
          (component: ValidatedStagedComponent) => {
            component.validator_results.forEach((rule: IntegrationValidatorRuleResult) => {
              if (
                rule.result === IntegrationValidatorRuleResultType.FAILURE &&
                rule.severity === IntegrationValidatorRuleSeverityType.BLOCKING &&
                !rule.is_overridden &&
                rule.is_new_failure
              ) {
                addBlockingRule(rule);
              }
            });
          }
        );
      });
    } else {
      dispatchBlockingRules({ type: CLEAR_BLOCKING_RULES, payload: "" });
    }
  }, [publishModuleInfo]);

  // Check if there are any staged changes by looking at if any of the integration components have staged changes
  const hasChanges = useMemo(() => {
    return !!(
      publishModuleInfo &&
      Object.values(IntegrationComponentNames).some(
        (integrationComponentName) =>
          publishModuleInfo.staged_changes?.[integrationComponentName]?.length > 0
      )
    );
  }, [publishModuleInfo]);

  const [areMappingTestsFailing, setAreMappingTestsFailing] = useState(true);
  // Set areMappingTestsFailing to true if the latest test suite execution is not successful or if there are no linked account test suite executions
  useEffect(() => {
    setAreMappingTestsFailing(
      !integrationTestSuiteExecution ||
        integrationTestSuiteExecution.status !==
          IntegrationTestSuiteExecutionStatus.FINISHED_WITH_SUCCESS ||
        integrationTestSuiteExecution.linked_account_test_suite_executions.length === 0
    );
  }, [integrationTestSuiteExecution]);

  const overrideFailingMappingTest = () => {
    setAreMappingTestsFailing(false);
  };

  const refetchPublishModuleInfo = () => {
    setPublishModuleInfo(undefined);
    fetchLatestIntegrationVersionTestSuiteExecution();
    fetchValidatedStagedVersions();
  };

  // Main check to see if the publish button should be disabled
  // If any of the following are true, the publish button should be disabled:
  //  1. If there are any blocking rules that are failing
  //  2. If there are no changes
  //  3. If mapping tests are running
  //  4. If fetching the latest test suite execution
  //  5. If mapping tests are failing
  const isPublishBlocked = useMemo(() => {
    return (
      !!blockingRules.size ||
      !hasChanges ||
      isRunningMappingTests ||
      isFetchingLatestTestSuiteExecution ||
      areMappingTestsFailing
    );
  }, [
    blockingRules,
    hasChanges,
    isRunningMappingTests,
    isFetchingLatestTestSuiteExecution,
    areMappingTestsFailing,
  ]);

  return (
    <PublishModuleContext.Provider
      value={{
        integrationID,
        isPublishModalOpen,
        setIsPublishModalOpen,
        overrideComment,
        setOverrideComment,
        overrideTicket,
        setOverrideTicket,
        isSaving,
        setIsSaving,
        onPublish,
        areMappingTestsFailing,
        setAreMappingTestsFailing,
        publishModuleInfo,
        hasChanges,
        isPublishBlocked,
        addBlockingRule,
        removeBlockingRule,
        isFetchingLatestTestSuiteExecution,
        hasFailedFetchingLatestExecution,
        integrationTestSuiteExecution,
        isRunningMappingTests,
        overrideFailingMappingTest,
        fetchLatestIntegrationVersionTestSuiteExecution,
        onRunTestsForStagedIntegrationVersion,
        integrationChecklist,
        fetchIntegrationChecklist,
        refetchPublishModuleInfo,
      }}
    >
      {children}
    </PublishModuleContext.Provider>
  );
};

export default PublishModuleContextProvider;
