import { Check, Pencil } from "lucide-react";
import { BlueprintVersionPublishState } from "../../../../../models/Blueprints";

interface Props {
  publishedStatus: BlueprintVersionPublishState;
}

const BlueprintSearchCardPublishStatusBadgeIcon = ({ publishedStatus }: Props) => {
  return publishedStatus === BlueprintVersionPublishState.Published ? (
    <Check size={12} />
  ) : (
    <Pencil size={12} />
  );
};

export default BlueprintSearchCardPublishStatusBadgeIcon;
