import { DiffState, DiffStatusEnum } from "../../../../models/DiffModels";
import {
  AuthConfigIntegrationBuilder,
  IntegrationSetupChecklistItem,
  LinkChoiceStepOption,
  LinkingFlowStepPathIntegrationBuilder,
  LinkingFlowStepTypeDisplayNameMap,
} from "../../../integration-builder/utils/Entities";
import { v4 as uuidv4 } from "uuid";

// Needed cause Tailwind doesn't support dynamic classnames
export const mapChildLevelToLeftPadding: { [key: number]: string } = {
  0: "pl-0",
  1: "pl-[24px]",
  2: "pl-[48px]",
  3: "pl-[72px]",
  4: "pl-[96px]",
  5: "pl-[120px]",
  6: "pl-[144px]",
  7: "pl-[168px]",
  8: "pl-[192px]",
  9: "pl-[216px]",
  10: "pl-[240px]",
  11: "pl-[264px]",
  12: "pl-[288px]",
  13: "pl-[312px]",
  14: "pl-[336px]",
};

// Function to convert Merge Link step to displayable diff state
export const convertToDisplayableDiffState = (
  stepPath: LinkingFlowStepPathIntegrationBuilder | undefined,
  options: LinkChoiceStepOption[],
  integrationSetupChecklistItems: IntegrationSetupChecklistItem[] | undefined,
  authConfigs: AuthConfigIntegrationBuilder[] | undefined
): { [key: string]: any } => {
  const displayableSteps = stepPath?.steps.map((step) => {
    return {
      ...step,
      step_identifier: step.id
        ? step.id
        : "step_identifier" in step
        ? step.step_identifier
        : uuidv4(),
      step_type: LinkingFlowStepTypeDisplayNameMap[step["step_type"]],
      integration_setup_checklist_item_id:
        integrationSetupChecklistItems?.find(
          (checklistItem) => checklistItem.id === step.integration_setup_checklist_item_id
        )?.title || null,
    };
  });
  const displayableOptions = options.map((option) => {
    return {
      ...option,
      auth_configuration_override_id: authConfigs?.find(
        (authConfig) => authConfig.id === option.auth_configuration_override_id
      )?.name,
    };
  });
  return {
    step_path: { ...stepPath, steps: displayableSteps },
    options: displayableOptions,
  };
};

export const getSliceOfState = (
  state: { [key: string]: any },
  keyPath: string[]
): { [key: string]: any } | null => {
  let partOfState = state ?? null;
  const doesKeyPathExist = keyPath.every((key) => {
    const doesKeyExist = partOfState.hasOwnProperty(key);
    partOfState = doesKeyExist && partOfState[key] ? partOfState[key] : {};
    return doesKeyExist;
  });
  return doesKeyPathExist ? partOfState : null;
};

export function notNull<T>(val: T | null): val is T {
  return val !== null;
}

/* ------- HELPER FUNCTIONS TO CLEAN UP VALUES ------ */

export const convertNameToDisplayName = (name: string) => {
  return name
    .split("_")
    .map((word, index) => {
      let newWord = word.toLowerCase();
      return index === 0 ? newWord.charAt(0).toUpperCase() + newWord.slice(1) : newWord;
    })
    .join(" ");
};

export const convertDictValuesToString = (dictionary: { [key: string]: any }) => {
  return Object.keys(dictionary).reduce((newDict, key) => {
    newDict[key] = convertValueToString(dictionary[key]);
    return newDict;
  }, {} as { [key: string]: string });
};

export const convertValueToString = (
  value: string | null | undefined | number | boolean | { [key: string]: any },
  isRenderAsSecret: boolean = false
): string => {
  // Sort keys if object
  if (typeof value === "object" && value !== null && !Array.isArray(value)) {
    const sortedKeys = Object.keys(value).sort();
    let sortedObj: { [key: string]: any } = {};
    sortedKeys.forEach((key) => {
      sortedObj[key] = (value as { [key: string]: any })[key];
    });
    value = sortedObj;
  }

  let convertedValue =
    value === null || value === undefined
      ? ""
      : typeof value === "boolean"
      ? value === true
        ? "Yes"
        : "No"
      : typeof value === "object"
      ? JSON.stringify(value)
      : value.toString();
  return isRenderAsSecret ? renderValueAsSecret(convertedValue) : convertedValue;
};

/* ------- GET DIFF STATUS BY COMPARING VALUES ------- */

export const getDiffStatus = (
  currentValue: string,
  newValue: string,
  childrenFields?: DiffState
): DiffStatusEnum => {
  let isEqual: boolean = currentValue === newValue;
  let isNowNull: boolean = currentValue !== "" && newValue === "";
  let isPreviouslyNull: boolean = currentValue === "" && newValue !== "";
  if (isEqual) {
    return childrenFields && childrenFields.length > 0
      ? getDiffStatusFromChildren(childrenFields)
      : DiffStatusEnum.NO_CHANGES;
  }
  if (isNowNull) {
    return DiffStatusEnum.DELETED;
  }
  if (isPreviouslyNull) {
    return DiffStatusEnum.ADDED;
  }
  return DiffStatusEnum.UPDATED;
};

export const getDiffStatusFromChildren = (childrenFields: DiffState): DiffStatusEnum => {
  return childrenFields.every((field) => field.diffStatus === DiffStatusEnum.ADDED)
    ? DiffStatusEnum.ADDED
    : childrenFields.find((field) => field.diffStatus !== DiffStatusEnum.NO_CHANGES)
    ? DiffStatusEnum.UPDATED
    : DiffStatusEnum.NO_CHANGES;
};

export const renderValueAsSecret = (value: string) => {
  return "•".repeat(value.length);
};

export const findKeys = (
  keys: string[],
  keysToCompareAgainst: string[],
  isMatching: boolean
): string[] => {
  return keys
    .map((key) =>
      (!isMatching && !keysToCompareAgainst.includes(key)) ||
      (isMatching && keysToCompareAgainst.includes(key))
        ? key
        : null
    )
    .filter(notNull);
};

export const getAllKeysInBothCurrentAndNew = (
  currentRawState: { [key: string]: any },
  newRawState: { [key: string]: any }
): string[] => {
  // Detect new & deleted fields.
  // Compare existing fields.
  // We don't care about order here

  const currentKeys: string[] = Object.keys(currentRawState);
  const newKeys: string[] = Object.keys(newRawState);

  const existingKeys: string[] = findKeys(currentKeys, newKeys, true);
  const addedKeys: string[] = findKeys(newKeys, currentKeys, false);
  const deletedKeys: string[] = findKeys(currentKeys, newKeys, false);
  return existingKeys.concat(addedKeys, deletedKeys);
};
