import { Card } from "@merge-api/merge-javascript-shared";
import { AbstractCondition } from "../../../../integration-builder/selective-sync-filters/types";
import LeftPanelSelectiveSyncFilterTitle from "./components/LeftPanelSelectiveSyncFilterTitle";
import { UserFacingFilterType } from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import LeftPanelSelectiveSyncFilterFields from "./components/LeftPanelSelectiveSyncFilterFields";
import {
  AbstractConditionAction,
  DELETE_CONDITION,
} from "../../../reducers/AbstractConditionsForSelectiveSyncReducer";

interface Props {
  index: number;
  abstractConditionsForSelectiveSync: AbstractCondition[];
  dispatchAbstractConditionsForSelectiveSync: React.Dispatch<AbstractConditionAction>;
  filterTypeOptions: UserFacingFilterType[];
}

/**
 * Renders the card for an individual Selective Sync filter in Blueprint Editor left console
 */
const BlueprintLeftPanelSelectiveSyncFilterCard = ({
  index,
  abstractConditionsForSelectiveSync,
  dispatchAbstractConditionsForSelectiveSync,
  filterTypeOptions,
}: Props) => {
  return (
    <Card variant="none" className="flex flex-col w-full p-3 rounded-lg space-y-2">
      <LeftPanelSelectiveSyncFilterTitle
        index={index}
        onDelete={() =>
          dispatchAbstractConditionsForSelectiveSync({
            type: DELETE_CONDITION,
            payload: { index: index },
          })
        }
      />
      <LeftPanelSelectiveSyncFilterFields
        index={index}
        abstractConditionsForSelectiveSync={abstractConditionsForSelectiveSync}
        dispatchAbstractConditionsForSelectiveSync={dispatchAbstractConditionsForSelectiveSync}
        filterTypeOptions={filterTypeOptions}
      />
    </Card>
  );
};

export default BlueprintLeftPanelSelectiveSyncFilterCard;
