import {
  BlueprintSearchResultBlueprint,
  BlueprintSearchResultCommonModelMappingStatus,
} from "../../../../../models/blueprint-search/BlueprintSearchOutputModels";
import BlueprintSearchCardBadgeList from "./BlueprintSearchCardBadgeList";

interface Props {
  blueprintResult: BlueprintSearchResultBlueprint;
}

const BlueprintSearchCardEndpointsAndCommonModels = ({ blueprintResult }: Props) => {
  // Determine the relevant blueprint version to use
  // Use the published version if available, otherwise use the latest version
  const relevantBlueprintVersion =
    blueprintResult.published_blueprint_version ?? blueprintResult.latest_blueprint_version;

  // Very rarely will there be a Blueprint with no "latest_blueprint_version"
  // But if there isn't, we don't render this component
  if (!relevantBlueprintVersion) {
    return null;
  }

  // Create array of API endpoint display names
  // For each endpoint, use its name if available, otherwise use "METHOD PATH"
  const apiEndpointDisplayNames = relevantBlueprintVersion?.api_endpoints.map(
    (apiEndpoint) => apiEndpoint.name ?? `${apiEndpoint.method} ${apiEndpoint.path ?? ""}`
  );

  // Create array of common model names
  // Sort the common models alphabetically, then extract and join their names
  const commonModelDisplayNames = relevantBlueprintVersion?.common_model_mapping_statuses
    .sort((a, b) => (a.common_model > b.common_model ? 1 : -1))
    .map((commonModel: BlueprintSearchResultCommonModelMappingStatus) => commonModel.common_model);

  return (
    <div className="flex flex-col space-y-1 items-start justify-start">
      <BlueprintSearchCardBadgeList title="Endpoints" badgeNames={apiEndpointDisplayNames} />
      <BlueprintSearchCardBadgeList title="Common Models" badgeNames={commonModelDisplayNames} />
    </div>
  );
};

export default BlueprintSearchCardEndpointsAndCommonModels;
