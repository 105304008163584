import { DiffStateFieldTypeEnum, DiffState } from "../../../../models/DiffModels";
import { generateDiffState } from "./helpers-diff";

interface Props {
  isForAPIEndpoint?: boolean;
}

// Generate diff for rate limit config
export const generateDiffForRateLimitConfiguration = ({ isForAPIEndpoint }: Props): DiffState => {
  let paramsWhenIsForAPIEndpoint = {};
  if (isForAPIEndpoint) {
    paramsWhenIsForAPIEndpoint = {
      keyPathToSliceOfState: ["rate_limit_configurations"],
      isStateAnArray: true,
      arrayItemHeaderName: "Rate limit configuration",
      arrayItemIdentifier: "rate_limit_identifier",
      arrayItemNameToDisplayAsHeaderValue: "rate_limit_summary",
      isIgnoreArrayOrder: true,
    };
  }

  return generateDiffState({
    ...paramsWhenIsForAPIEndpoint,
    fields: [
      {
        type: isForAPIEndpoint ? DiffStateFieldTypeEnum.SECTION : DiffStateFieldTypeEnum.HEADER,
        name: "configure_rate_limit_header",
        displayName: "Configure rate limit",
        childDiffStateInputFields: [
          {
            name: "rate_limit_type",
            displayName: "Rate limit type",
          },
          {
            name: "rate_limit_summary",
            displayName: "Rate limit",
          },
          {
            name: "constructed_default_rate_limit_threshold",
            displayName: "Rate limit threshold",
          },
          {
            name: "is_active",
            displayName: "Is active?",
          },
        ],
      },
      {
        type: isForAPIEndpoint ? DiffStateFieldTypeEnum.SECTION : DiffStateFieldTypeEnum.HEADER,
        name: "updated_via_headers_header",
        displayName: "Update via headers",
        childDiffStateInputFields: [
          {
            name: "should_set_limit_max_count_via_headers",
            displayName: "Should the max count be pulled from response headers?",
            isRenderChildrenAsNested: true,
            childDiffStateInputFields: [
              {
                name: "response_header_max_count_key_path",
                displayName: "Response header max count key path",
              },
            ],
          },
          {
            name: "should_update_rate_limit_current_count_via_headers",
            displayName: "Should the current count be pulled from response headers?",
            isRenderChildrenAsNested: true,
            childDiffStateInputFields: [
              {
                name: "response_header_rate_limit_current_count_key_path",
                displayName: "Response header rate limit current count key",
              },
            ],
          },
          {
            name: "should_update_rate_limit_count_left_via_headers",
            displayName: "Should the remaining count be pulled from response headers?",
            isRenderChildrenAsNested: true,
            childDiffStateInputFields: [
              {
                name: "response_header_rate_limit_count_left_key_path",
                displayName: "Response header rate limit remaining count key",
              },
            ],
          },
          {
            name: "reset_timestamp_nested_section",
            displayName: "Reset timestamp",
            isRenderChildrenAsNested: true,
            childDiffStateInputFields: [
              {
                name: "response_header_rate_limit_reset_timestamp_key_path",
                displayName: "Response header rate limit reset timestamp key path",
              },
              {
                name: "reset_expiration_format",
                displayName: "Reset expiration format",
              },
            ],
          },
        ],
      },
      {
        type: isForAPIEndpoint ? DiffStateFieldTypeEnum.SECTION : DiffStateFieldTypeEnum.HEADER,
        name: "advanced_header",
        displayName: "Advanced",
        isRenderNumberOfChildrenInDisplayName: true,
        childDiffStateInputFields: [
          {
            name: "aggressive_rate_limit_threshold",
            displayName: "Agressive rate limit threshold",
          },
          {
            name: "default_increment",
            displayName: "Default increment",
          },
          {
            name: "maximum_retry_count",
            displayName: "Maximum retry count",
          },
          {
            name: "retry_backoff_factor",
            displayName: "Retry backoff factor",
          },
        ],
      },
    ],
  });
};
