import "react-bootstrap-typeahead/css/Typeahead.css";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DeprecatedBadge } from "../../shared/MergeBadges";
import { SmallTextMutedParagraph } from "../../shared/text/MergeText";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";

export interface FormFieldCommonProps {
  subtitle?: string | JSX.Element;
  title?: string;
  example?: string;
  valueType?: string;
  deprecated?: boolean;
  choices?: any;
  className?: string;
  onBlur?: () => void;
  relationModel?: string;
  titleChildren?: string | JSX.Element;
  dataTestID?: string;
  titleRightChildren?: JSX.Element;
}

interface Props extends FormFieldCommonProps {
  children: JSX.Element | (JSX.Element | false | undefined)[];
}

function FormField({
  children,
  dataTestID,
  example,
  subtitle,
  title,
  valueType,
  deprecated,
  choices,
  className,
  onBlur,
  titleChildren,
  titleRightChildren,
}: Props) {
  return (
    <div className="mb-9">
      <div className={className || "form-group"} onBlur={onBlur}>
        <div className="flex flex-row items-center justify-between" data-testid={dataTestID}>
          <div className="d-flex align-items-center text-align-center mb-1.5">
            {title && <DeprecatedH4 className="mb-0">{title}</DeprecatedH4>}
            {valueType && (
              <Badge variant="soft-secondary" className="ml-1.5">
                {valueType}
              </Badge>
            )}
            {deprecated && <DeprecatedBadge />}
            {titleChildren}
          </div>
          {titleRightChildren}
        </div>
        {subtitle && (
          <SmallTextMutedParagraph>
            {subtitle}
            {choices && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 100, hide: 0 }}
                overlay={
                  <Tooltip id="choices-mapping-tooltip">
                    If you are hard-coding a value, include one of the following:
                    {choices.map((choice: string) => " " + choice)}
                  </Tooltip>
                }
              >
                <i className="ml-1.5 text-muted text-right fe fe-info float-right" />
              </OverlayTrigger>
            )}
          </SmallTextMutedParagraph>
        )}
        {example && <SmallTextMutedParagraph>{example}</SmallTextMutedParagraph>}
        {children}
      </div>
    </div>
  );
}

export default FormField;
