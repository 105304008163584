import JSONSchemaTreeDiagram from "../../shared/JSONSchemaTreeDiagram";
import { BlueprintStep } from "../../../models/Blueprints";
import useBlueprintContext from "../context/useBlueprintContext";
import { getAllStepsForBlueprint } from "../../blueprint-editor/utils/BlueprintEditorUtils";
import { ChevronToggle } from "../../shared/MergeToggles";
import { Accordion } from "react-bootstrap";
import styled from "styled-components";

const JSONContainer = styled.div`
  padding-bottom: 26px;
`;
const BlueprintEditorLeftPanelSchemasSubtab = () => {
  const { blueprint } = useBlueprintContext();

  return (
    <>
      {getAllStepsForBlueprint(blueprint).map((step: BlueprintStep) => (
        <Accordion>
          <ChevronToggle eventKey="0">{step.id}</ChevronToggle>
          <Accordion.Collapse eventKey="0" className="mt-3">
            <JSONContainer>
              <JSONSchemaTreeDiagram stepID={step.id} jsonSchema={step.template.return_schema} />
            </JSONContainer>
          </Accordion.Collapse>
        </Accordion>
      ))}
    </>
  );
};

export default BlueprintEditorLeftPanelSchemasSubtab;
