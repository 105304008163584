import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { useEffect, useState } from "react";
import { GlobalHotKeys } from "react-hotkeys";
import { PaginatedAPIResponse, fetchWithAuth } from "../../../api-client/api_client";
import {
  ASYNC_TASK_EXECUTION_QUEUED_STATUSES,
  ASYNC_TASK_EXECUTION_RUNNING_STATUSES,
  AsyncTaskExecution,
  AsyncTaskExecutionPreview,
  BlueprintOperationType,
  BlueprintTestPayload,
  DISABLE_FILTER_BY_DATE_OPERATION_TYPES,
  JSONObjectSchema,
  ValidationProblem,
} from "../../../models/Blueprints";
import { CommonModelInfo, LinkedAccount } from "../../../models/Entities";
import {
  isAbstractConditionsForSelectiveSyncValid,
  isValidJSON,
  validateTimeInput,
} from "../../../utils";
import { initializeInputParams } from "../../blueprint-editor/utils/BlueprintEditorUtils";
import useFetchAsyncResult from "../../shared/hooks/useFetchAsyncResult";
import { showErrorToast, showSuccessToast, showWarningToast } from "../../shared/Toasts";
import useBlueprintContext from "../context/useBlueprintContext";
import { testBlueprint } from "../utils/BlueprintEditorAPIClient";
import AsyncTestRunsModal from "./AsyncTestRunsModal";
import AsyncTestRunUnsavedChangesModal from "./AsyncTestRunUnsavedChangesModal";

import { AbstractCondition } from "../../integration-builder/selective-sync-filters/types";
import { ScraperVersion } from "../../scraper/types";
import DeletionConfirmationModal from "../../shared/DeletionConfirmationModal";
import { AbstractConditionAction } from "../reducers/AbstractConditionsForSelectiveSyncReducer";
import CustomerLinkedAccountSection from "./CustomerLinkedAccountSection";
import BlueprintLeftPanelConsolePayload from "./left-panel-console/BlueprintLeftPanelConsolePayload";
import BlueprintLeftPanelConsoleResultLogs from "./left-panel-console/BlueprintLeftPanelConsoleResultLogs";
import BlueprintLeftPanelConsoleResultSummary from "./left-panel-console/BlueprintLeftPanelConsoleResultSummary";
import BlueprintLeftPanelConsoleSelect from "./left-panel-console/BlueprintLeftPanelConsoleSelect";
import BlueprintLeftPanelConsoleSettings from "./left-panel-console/BlueprintLeftPanelConsoleSettings";
import ReportFileUploadModal from "./ReportFileUploadModal";
import LeftPanelSectionHeader from "./shared/LeftPanelSectionHeader";
import useInitiateWebConnectorLiveRun from "./web-connector/hooks/useInitiateWebConnectorLiveRun";
import LiveRunChips from "./web-connector/LiveRunChips";
import WebConnectorLiveRunResults from "./web-connector/WebConnectorLiveRunResults";
import { MergeFlagFeature, useMergeFlag } from "../../shared/hooks/useMergeFlag";
import { prepareAbstractConditionsForTestRun } from "../../integration-builder/selective-sync-filters/utils";

interface Props {
  integrationID: string;
  updatedParameterSchema: JSONObjectSchema | undefined;
  hasInputParameters: boolean | ScraperVersion | JSONObjectSchema | undefined;
  selectedTestPayload: undefined | BlueprintTestPayload;
  setSelectedTestPayload: (s: undefined | BlueprintTestPayload) => void;
  blueprintTestPayloads: BlueprintTestPayload[];
  setBlueprintTestPayloads: React.Dispatch<React.SetStateAction<BlueprintTestPayload[]>>;
  generatedBlueprintTestPayloads: BlueprintTestPayload[];
  canAutogenerateMappingTestForBlueprint: boolean;
  setGeneratedBlueprintTestPayloads: React.Dispatch<React.SetStateAction<BlueprintTestPayload[]>>;
  selectedTestLinkedAccount: undefined | string;
  setSelectedTestLinkedAccount: (s: string | undefined) => void;
  selectedTestCommonModel: undefined | string;
  setSelectedTestCommonModel: (s: string | undefined) => void;
  isTestingBlueprint: boolean;
  setIsTestingBlueprint: (x: boolean) => void;
  isLoading: boolean;
  setIsLoading: (x: boolean) => void;
  isLoadingTestLinkedAccounts: boolean;
  fetchTestLinkedAccounts: () => void;
  testLinkedAccounts: LinkedAccount[];
  testCommonModels: undefined | CommonModelInfo[];
  disableFilterByDate: boolean;
  setDisableFilterByDate: (x: boolean) => void;
  disableSyncCursor: boolean;
  setDisableSyncCursor: (x: boolean) => void;
  shouldFilterDisabledFields: boolean;
  setShouldFilterDisabledFields: (x: boolean) => void;
  shouldRunAsAsyncTask: boolean;
  setShouldRunAsAsyncTask: (x: boolean) => void;
  shouldGenerateMappingTest: boolean;
  setShouldGenerateMappingTest: (x: boolean) => void;
  asyncTaskExecutionID: string | undefined;
  setAsyncTaskExecutionID: (taskID: string | undefined) => void;
  asyncTaskExecutionResult?: AsyncTaskExecution;
  setAsyncTaskExecutionResult: (result?: AsyncTaskExecution) => void;
  shouldUseLastScraperResults: boolean;
  setShouldUseLastScraperResults: (x: boolean) => void;
  maxLoopIterations: number | undefined;
  setMaxLoopIterations: (x: number) => void;
  maxLoopIterationsPerStep: { [stepID: string]: number };
  setMaxLoopIterationsPerStep: (x: { [stepID: string]: number }) => void;
  maxPageIterations: number | undefined;
  setMaxPageIterations: (x: number) => void;
  frozenTimeValue: string | undefined;
  setFrozenTimeValue: (x: string) => void;
  overrideLastRunAtValue: string | undefined;
  setOverrideLastRunAtValue: (x: string) => void;
  shouldIncludeNestedParams: boolean;
  setShouldIncludeNestedParams: (x: boolean) => void;
  globalVarsAsString: string | undefined;
  initializedSchemaForDeletePayload: string;
  setGlobalVarsAsString: (x: string) => void;
  isShowingModelsModal: boolean;
  setIsShowingModelsModal: (x: boolean) => void;
  recentTestRuns: AsyncTaskExecutionPreview[];
  setRecentTestRuns: (x: AsyncTaskExecutionPreview[]) => void;
  isLoadingRecentTestRuns: boolean;
  setIsLoadingRecentTestRuns: (x: boolean) => void;
  isShowingRecentTestRunsModal: boolean;
  setIsShowingRecentTestRunsModal: (x: boolean) => void;
  doesBlueprintHaveUnsavedChanges: boolean;
  abstractConditionsForSelectiveSync: AbstractCondition[];
  dispatchAbstractConditionsForSelectiveSync: React.Dispatch<AbstractConditionAction>;
  isQBDIntegration: boolean;
}

const BlueprintEditorLeftPanelConsoleSubtab = ({
  integrationID,
  updatedParameterSchema,
  hasInputParameters,
  selectedTestPayload,
  setSelectedTestPayload,
  selectedTestLinkedAccount,
  setSelectedTestLinkedAccount,
  selectedTestCommonModel: selectedTestCommonModel,
  setSelectedTestCommonModel: setSelectedTestCommonModel,
  isTestingBlueprint,
  setIsTestingBlueprint,
  isLoading,
  setIsLoading,
  isLoadingTestLinkedAccounts,
  fetchTestLinkedAccounts,
  testLinkedAccounts,
  testCommonModels: testCommonModels,
  canAutogenerateMappingTestForBlueprint,
  disableFilterByDate,
  setDisableFilterByDate,
  disableSyncCursor,
  setDisableSyncCursor,
  shouldFilterDisabledFields,
  setShouldFilterDisabledFields,
  shouldRunAsAsyncTask,
  setShouldRunAsAsyncTask,
  shouldGenerateMappingTest,
  setShouldGenerateMappingTest,
  asyncTaskExecutionID,
  setAsyncTaskExecutionID,
  asyncTaskExecutionResult,
  setAsyncTaskExecutionResult,
  shouldUseLastScraperResults,
  setShouldUseLastScraperResults,
  maxLoopIterations,
  setMaxLoopIterations,
  maxLoopIterationsPerStep,
  setMaxLoopIterationsPerStep,
  maxPageIterations,
  setMaxPageIterations,
  frozenTimeValue,
  setFrozenTimeValue,
  overrideLastRunAtValue,
  setOverrideLastRunAtValue,
  shouldIncludeNestedParams,
  setShouldIncludeNestedParams,
  initializedSchemaForDeletePayload,
  globalVarsAsString,
  setGlobalVarsAsString,
  isShowingModelsModal,
  setIsShowingModelsModal,
  recentTestRuns,
  setRecentTestRuns,
  isLoadingRecentTestRuns,
  setIsLoadingRecentTestRuns,
  isShowingRecentTestRunsModal,
  setIsShowingRecentTestRunsModal,
  doesBlueprintHaveUnsavedChanges,
  blueprintTestPayloads,
  setBlueprintTestPayloads,
  generatedBlueprintTestPayloads,
  setGeneratedBlueprintTestPayloads,
  abstractConditionsForSelectiveSync,
  dispatchAbstractConditionsForSelectiveSync,
  isQBDIntegration,
}: Props) => {
  const [isQBDLiveRunTab, setIsQBDLiveRunTab] = useState<boolean>(false);

  const [isShowingConfirmDeletePayloadModal, setIsShowingConfirmDeletePayloadModal] = useState<
    boolean
  >(false);
  const [isLoadingDeletion, setIsLoadingDeletion] = useState(false);
  const [newGeneratedPayloadId, setNewGeneratedPayloadId] = useState<string | null>(null);

  const [isShowingSavePayloadModal, setIsShowingSavePayloadModal] = useState<boolean>(false);
  const [reportFileID, setReportFileID] = useState<string | undefined>();
  const [showReportUploadModal, setShowReportUploadModal] = useState<boolean>(false);
  const [isLoadingGeneratedPayload, setIsLoadingGeneratedPayload] = useState<boolean>(false);
  const [shouldLogStepIO, setShouldLogStepIO] = useState<boolean>(true);

  const [
    isShowingAsyncTestRunUnsavedChangesModal,
    setIsShowingAsyncTestRunUnsavedChangesModal,
  ] = useState<boolean>(false);
  const {
    blueprint,
    blueprintRunnerExecutionResponse,
    setBlueprintRunnerExecutionResponse,
    setBlueprintParameterSchema,
  } = useBlueprintContext();

  // Validation for frozen time / last modified timestamp input
  const [isFrozenTimeError, setIsFrozenTimeError] = useState<boolean>(false);
  const [frozenTimeErrorText, setFrozenTimeErrorText] = useState<string | undefined>(undefined);
  const [isOverrideLastRunAtError, setIsOverrideLastRunAtError] = useState<boolean>(false);
  const [overrideLastRunAtErrorText, setOverrideLastRunAtErrorText] = useState<string | undefined>(
    undefined
  );

  const handleFrozenTimeInputChange = (value: string) => {
    setFrozenTimeValue(value);
    const { isValid, errorMsg } = validateTimeInput(value);
    setIsFrozenTimeError(!isValid);
    setFrozenTimeErrorText(isValid ? undefined : errorMsg);
  };

  const handleOverrideLastRunAtChange = (value: string) => {
    setOverrideLastRunAtValue(value);
    const { isValid, errorMsg } = validateTimeInput(value);
    setIsOverrideLastRunAtError(!isValid);
    setOverrideLastRunAtErrorText(isValid ? undefined : errorMsg);
  };

  const areInputParametersValid =
    !globalVarsAsString || (globalVarsAsString && isValidJSON(globalVarsAsString));

  const isTestBlueprintDisabled =
    isTestingBlueprint || !selectedTestLinkedAccount || !areInputParametersValid;

  const keyMaps = {
    TEST: "ctrl+r",
  };

  const handlers = {
    TEST: (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      if (!isTestBlueprintDisabled) {
        runTestBlueprint({});
      }
    },
  };

  function runTestBlueprint({
    clearAsyncTaskExecutionResults,
  }: {
    clearAsyncTaskExecutionResults?: () => void;
  }) {
    if (
      abstractConditionsForSelectiveSync.length > 0 &&
      isAbstractConditionsForSelectiveSyncValid(abstractConditionsForSelectiveSync) === false
    ) {
      showErrorToast("Failed to run, check for incomplete selective sync filters");
      return;
    }
    if (shouldRunAsAsyncTask && doesBlueprintHaveUnsavedChanges) {
      setIsShowingAsyncTestRunUnsavedChangesModal(true);
      return;
    }
    setBlueprintRunnerExecutionResponse(null);
    setAsyncTaskExecutionID(undefined);
    clearAsyncTaskExecutionResults && clearAsyncTaskExecutionResults();
    setIsTestingBlueprint(true);
    setIsLoading(true);
    if (selectedTestLinkedAccount) {
      const shouldOverrideDisableByFilterDateToTrue = DISABLE_FILTER_BY_DATE_OPERATION_TYPES.includes(
        blueprint.operation_type
      );
      const shouldUseScraperResultsInsteadOfInputParams =
        blueprint.scraper && shouldUseLastScraperResults;
      // Convert Selective Sync values belonging to list operators, from string to array
      const convertedAbstractConditionsForSelectiveSync = prepareAbstractConditionsForTestRun(
        abstractConditionsForSelectiveSync
      );
      testBlueprint({
        blueprint,
        linkedAccountID: selectedTestLinkedAccount,
        disableFilterByDate: shouldOverrideDisableByFilterDateToTrue || disableFilterByDate,
        disableSyncCursor: shouldOverrideDisableByFilterDateToTrue || disableSyncCursor,
        maxLoopIterations: maxLoopIterations,
        maxLoopIterationsPerStep: maxLoopIterationsPerStep,
        maxPageIterations: maxPageIterations,
        frozenTimeValue: frozenTimeValue,
        overrideLastRunAtValue: overrideLastRunAtValue,
        reportFileID,
        globalVarTestValues:
          globalVarsAsString &&
          isValidJSON(globalVarsAsString) &&
          !shouldUseScraperResultsInsteadOfInputParams
            ? JSON.parse(globalVarsAsString)
            : null,
        commonModelObjectID: selectedTestCommonModel,
        shouldUseLastScraperResults,
        shouldFilterDisabledFields,
        shouldRunAsAsyncTask,
        shouldGenerateMappingTest,
        shouldLogStepIO,
        abstractConditionsForSelectiveSync: convertedAbstractConditionsForSelectiveSync,
        onResponse: (response) => {
          if (response?.errors) {
            const errorMessage = (
              response?.errors?.map(
                (error: ValidationProblem) => `${error.title}: ${error.detail}`
              ) ?? []
            ).join("\n");
            showErrorToast(errorMessage);
            setIsTestingBlueprint(false);
          } else if (response?.scraper_results) {
            if (response.exit_code !== 200) {
              showErrorToast(`Scraper exited with code ${response.exit_code}`);
            }
            setBlueprintRunnerExecutionResponse(response.blueprint_results);
            setIsTestingBlueprint(false);
          } else if (response?.async_task_execution_id) {
            // Test run is running as async task.
            // Note: isTestingBlueprint is staying set to true, will be set to false only after
            // the async task execution concludes.
            setAsyncTaskExecutionID(response!.async_task_execution_id);
          } else {
            setBlueprintRunnerExecutionResponse(response);
            setIsTestingBlueprint(false);
            if (response?.exit_data?.debugging_info?.step_io_logging_failed === true) {
              showWarningToast(
                "Step I/O logging encountered an error, the Automation team has been contacted"
              );
            }
          }
          setIsLoading(false);
        },
        onError: () => {
          showErrorToast("A server error occurred. The #bpe-alerts slack channel has been pinged.");
          setIsTestingBlueprint(false);
          setIsLoading(false);
        },
      });
    }
  }

  // Whenever a new payload is generated, load it
  useEffect(() => {
    if (newGeneratedPayloadId) {
      loadSelectedTestPayload(newGeneratedPayloadId);
    }
  }, [newGeneratedPayloadId]);

  function generateWritePayload(
    common_model_object_id: string | null,
    linked_account_id: string | null
  ) {
    fetchWithAuth({
      path: `/blueprints/${blueprint.id}/generate-write-payload`,
      method: "POST",
      body: {
        common_model_object_id: common_model_object_id,
        linked_account_id: linked_account_id,
        blueprint_parameter_schema: blueprint.parameter_schema,
        blueprint_version_id: blueprint.version.id,
      },
      onResponse: (response: any) => {
        // parses response text and converts back to JSON object with proper formatting
        const parsedResponse = JSON.parse(response.text);

        const formattedText = JSON.stringify(parsedResponse, null, 2);

        const newPayload: BlueprintTestPayload = {
          id: response.id,
          name: response.name,
          text: formattedText,
          common_model_object_id: response.common_model_object_id,
        };

        // Update the state with the new payload and load the selected test payload
        setBlueprintTestPayloads((prevPayloads) => [...prevPayloads, newPayload]);
        setGeneratedBlueprintTestPayloads((prevPayloads) => [...prevPayloads, newPayload]);
        setNewGeneratedPayloadId(newPayload.id);

        setIsLoadingGeneratedPayload(false);
      },
      onError: () => {
        showErrorToast("Failed to fetch test payloads.");
        setIsLoadingGeneratedPayload(false);
      },
    });
  }

  const isSelectedTestPayloadGenerated = generatedBlueprintTestPayloads.some(
    (payload) => payload.id === selectedTestPayload?.id
  );

  function deleteWritePayload(selectedTestPayload: BlueprintTestPayload) {
    fetchWithAuth({
      path: `/blueprints/${blueprint.id}/test-payloads`,
      method: "DELETE",
      body: {
        name: selectedTestPayload.name,
      },
      onResponse: () => {
        setIsLoadingDeletion(false);
        showSuccessToast("Payload deleted successfully!");
        // remove payload from blueprints array
        setBlueprintTestPayloads((prevPayloads) =>
          prevPayloads.filter((payload) => payload.id !== selectedTestPayload.id)
        );
        setSelectedTestPayload(undefined);
        setGlobalVarsAsString(initializedSchemaForDeletePayload);
        setIsShowingConfirmDeletePayloadModal(false);
      },
      onError: () => {
        setIsLoadingDeletion(false);
        showErrorToast("Failed to delete test payload.");
        setIsShowingConfirmDeletePayloadModal(false);
      },
    });
  }

  const fetchRecentTestRuns = () => {
    setRecentTestRuns([]);
    setIsLoadingRecentTestRuns(true);
    setIsShowingRecentTestRunsModal(true);
    fetchWithAuth({
      path: `/blueprints/versions/${blueprint.version.id}/test-runs?linked_account_id=${selectedTestLinkedAccount}`,
      method: "GET",
      onResponse: (data: PaginatedAPIResponse<AsyncTaskExecutionPreview>) => {
        setRecentTestRuns(data.results);
        setIsLoadingRecentTestRuns(false);
      },
      onError: () => {
        showErrorToast("Failed to fetch recent test runs.");
        setIsShowingRecentTestRunsModal(false);
        setIsLoadingRecentTestRuns(false);
      },
    });
  };

  const loadSelectedTestPayload = (id: string) => {
    let payload = findTestPayloadForID(id);
    setSelectedTestPayload(payload);
    if (payload) {
      setGlobalVarsAsString(payload.text);
      setSelectedTestCommonModel(payload.common_model_object_id);
    }
  };

  const findTestPayloadForID = (id: string) =>
    blueprintTestPayloads.find((payload) => payload.id === id);

  const fetchAsyncTaskExecutionDetails = ({
    asyncExecutionID,
    onResponse,
    onError,
  }: {
    asyncExecutionID: string;
    onResponse: (data: AsyncTaskExecution) => void;
    onError: () => void;
  }) => {
    fetchWithAuth({
      path: `/blueprints/async-test-run/${asyncExecutionID}`,
      method: "GET",
      onResponse,
      onError,
    });
  };

  const isAsyncTaskExecutionCompletedPredicate = (execution: AsyncTaskExecution) =>
    !ASYNC_TASK_EXECUTION_RUNNING_STATUSES.includes(execution.status) &&
    !ASYNC_TASK_EXECUTION_QUEUED_STATUSES.includes(execution.status);

  // Periodically fetch results of async task execution, if an async bp test run is running.
  const [
    isTestingBlueprintAsync,
    asyncTaskExecutionResultFromUseFetchAsyncResult,
    clearAsyncTaskExecutionResults,
    pollAsyncTaskExecutionResults,
    stopPollingAsyncTaskExecutionResults,
  ] = useFetchAsyncResult<AsyncTaskExecution>(
    (props) => {
      if (!asyncTaskExecutionID) {
        return;
      }
      fetchAsyncTaskExecutionDetails({ asyncExecutionID: asyncTaskExecutionID, ...props });
    },
    fetchAsyncTaskExecutionDetails,
    "poll async task execution updates",
    isAsyncTaskExecutionCompletedPredicate
  );

  useEffect(() => {
    if (asyncTaskExecutionID) {
      pollAsyncTaskExecutionResults();
    }
  }, [asyncTaskExecutionID]);

  useEffect(() => {
    if (asyncTaskExecutionResult?.id !== asyncTaskExecutionResultFromUseFetchAsyncResult?.id) {
      setAsyncTaskExecutionResult(asyncTaskExecutionResultFromUseFetchAsyncResult);
    }
    if (
      isTestingBlueprint &&
      asyncTaskExecutionResultFromUseFetchAsyncResult?.blueprint_execution?.exit_code
    ) {
      setBlueprintRunnerExecutionResponse(
        asyncTaskExecutionResultFromUseFetchAsyncResult.blueprint_execution
      );
      setIsTestingBlueprint(false);
    }
  }, [
    asyncTaskExecutionID,
    asyncTaskExecutionResultFromUseFetchAsyncResult?.status,
    asyncTaskExecutionResultFromUseFetchAsyncResult?.blueprint_execution?.exit_code,
    isTestingBlueprint,
  ]);

  const stopWaitingOnAsyncTaskExecution = () => {
    stopPollingAsyncTaskExecutionResults();
    setAsyncTaskExecutionID(undefined);
    setAsyncTaskExecutionResult(undefined);
    setIsTestingBlueprint(false);
  };

  const onLoadTestRun = (testRunId: string) => {
    if (testRunId === asyncTaskExecutionID) {
      // This test run is already loaded.
      return;
    }
    stopWaitingOnAsyncTaskExecution();
    setAsyncTaskExecutionID(testRunId);
    setAsyncTaskExecutionResult(undefined);
    setBlueprintRunnerExecutionResponse(null);
    setIsTestingBlueprint(true);
  };

  const { reportFiles } = useBlueprintContext();

  const updateToLatestInputParameterSchema = () => {
    if (updatedParameterSchema) {
      setBlueprintParameterSchema(updatedParameterSchema);
      const inputParams = initializeInputParams(updatedParameterSchema, shouldIncludeNestedParams);
      setGlobalVarsAsString(JSON.stringify(inputParams, null, 2));
    }
  };

  const shouldDisableGeneratePayload =
    !selectedTestLinkedAccount || doesBlueprintHaveUnsavedChanges;

  // QBD SECTION
  const {
    initiateLiveRun,
    isLoadingLiveRun,
    webConnectorSessionResponse,
  } = useInitiateWebConnectorLiveRun({
    blueprint,
    linkedAccountId: selectedTestLinkedAccount,
    globalVarsAsString: globalVarsAsString,
    overrideLastRunAt: overrideLastRunAtValue,
    shouldLogStepIO: shouldLogStepIO,
  });

  const displayForQBDFetchBlueprints =
    isQBDLiveRunTab && blueprint.operation_type === BlueprintOperationType.FETCH;

  const { enabled: isCustomerLinkedAccountEnabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_CUSTOMER_LA_ACCESS,
    isEnabledForUser: true,
  });

  return (
    <>
      <GlobalHotKeys allowChanges={true} keyMap={keyMaps} handlers={handlers} />
      <DeletionConfirmationModal
        selectedObjectType="Payload"
        associatedObjectsName={null}
        show={isShowingConfirmDeletePayloadModal}
        onHide={() => setIsShowingConfirmDeletePayloadModal(false)}
        isLoading={isLoadingDeletion}
        onConfirm={() => {
          setIsLoadingDeletion(true);
          if (selectedTestPayload) {
            deleteWritePayload(selectedTestPayload);
          }
        }}
      />
      <AsyncTestRunsModal
        show={isShowingRecentTestRunsModal}
        onHide={() => setIsShowingRecentTestRunsModal(false)}
        asyncTaskExecutionPreviews={recentTestRuns}
        isLoading={isLoadingRecentTestRuns}
        onLoadTestRun={onLoadTestRun}
      />
      <AsyncTestRunUnsavedChangesModal
        show={isShowingAsyncTestRunUnsavedChangesModal}
        onHide={() => setIsShowingAsyncTestRunUnsavedChangesModal(false)}
      />
      {showReportUploadModal && (
        <ReportFileUploadModal
          onHide={() => setShowReportUploadModal(false)}
          isVisible={showReportUploadModal}
          testLinkedAccounts={testLinkedAccounts}
          reportTemplateID={blueprint.report_template_id!}
          setReportFileID={setReportFileID}
          alreadySelectedLinkedAccountID={selectedTestLinkedAccount}
        />
      )}
      <div className="flex flex-col">
        <div className="flex flex-col space-y-4 pb-4">
          <LeftPanelSectionHeader title="Configure test run" />
          {isQBDIntegration && (
            <LiveRunChips
              isQBDLiveRunTab={isQBDLiveRunTab}
              setIsQBDLiveRunTab={setIsQBDLiveRunTab}
            />
          )}
          <BlueprintLeftPanelConsoleSelect
            selectedTestLinkedAccount={selectedTestLinkedAccount}
            setSelectedTestLinkedAccount={setSelectedTestLinkedAccount}
            setReportFileID={setReportFileID}
            isLoadingTestLinkedAccounts={isLoadingTestLinkedAccounts}
            testLinkedAccounts={testLinkedAccounts}
            selectedTestCommonModel={selectedTestCommonModel}
            setSelectedTestCommonModel={setSelectedTestCommonModel}
            testCommonModels={testCommonModels}
          />
          {isCustomerLinkedAccountEnabled && (
            <CustomerLinkedAccountSection
              integrationID={integrationID}
              fetchTestLinkedAccounts={fetchTestLinkedAccounts}
            />
          )}
          {!displayForQBDFetchBlueprints && (
            <BlueprintLeftPanelConsolePayload
              blueprint={blueprint}
              hasInputParameters={!!hasInputParameters}
              isShowingSavePayloadModal={isShowingSavePayloadModal}
              setIsShowingSavePayloadModal={setIsShowingSavePayloadModal}
              blueprintTestPayloads={blueprintTestPayloads}
              setBlueprintTestPayloads={setBlueprintTestPayloads}
              generatedBlueprintTestPayloads={generatedBlueprintTestPayloads}
              setGeneratedBlueprintTestPayloads={setGeneratedBlueprintTestPayloads}
              isSelectedTestPayloadGenerated={isSelectedTestPayloadGenerated}
              selectedTestPayload={selectedTestPayload}
              setSelectedTestPayload={setSelectedTestPayload}
              globalVarsAsString={globalVarsAsString}
              setGlobalVarsAsString={setGlobalVarsAsString}
              selectedTestLinkedAccount={selectedTestLinkedAccount}
              selectedTestCommonModel={selectedTestCommonModel}
              updatedParameterSchema={updatedParameterSchema}
              updateToLatestInputParameterSchema={updateToLatestInputParameterSchema}
              shouldDisableGeneratePayload={shouldDisableGeneratePayload}
              isLoadingGeneratedPayload={isLoadingGeneratedPayload}
              setIsLoadingGeneratedPayload={setIsLoadingGeneratedPayload}
              generateWritePayload={generateWritePayload}
              loadSelectedTestPayload={loadSelectedTestPayload}
              setIsShowingConfirmDeletePayloadModal={setIsShowingConfirmDeletePayloadModal}
              areInputParametersValid={!!areInputParametersValid}
              reportFileID={reportFileID}
              setReportFileID={setReportFileID}
              reportFiles={reportFiles}
            />
          )}
          <BlueprintLeftPanelConsoleSettings
            blueprint={blueprint}
            maxLoopIterations={maxLoopIterations}
            setMaxLoopIterations={setMaxLoopIterations}
            maxLoopIterationsPerStep={maxLoopIterationsPerStep}
            setMaxLoopIterationsPerStep={setMaxLoopIterationsPerStep}
            maxPageIterations={maxPageIterations}
            setMaxPageIterations={setMaxPageIterations}
            frozenTimeValue={frozenTimeValue}
            handleFrozenTimeInputChange={handleFrozenTimeInputChange}
            overrideLastRunAtValue={overrideLastRunAtValue}
            handleOverrideLastRunAtChange={handleOverrideLastRunAtChange}
            isFrozenTimeError={isFrozenTimeError}
            frozenTimeErrorText={frozenTimeErrorText}
            isOverrideLastRunAtError={isOverrideLastRunAtError}
            overrideLastRunAtErrorText={overrideLastRunAtErrorText}
            disableFilterByDate={disableFilterByDate}
            setDisableFilterByDate={setDisableFilterByDate}
            disableSyncCursor={disableSyncCursor}
            setDisableSyncCursor={setDisableSyncCursor}
            shouldFilterDisabledFields={shouldFilterDisabledFields}
            setShouldFilterDisabledFields={setShouldFilterDisabledFields}
            shouldUseLastScraperResults={shouldUseLastScraperResults}
            setShouldUseLastScraperResults={setShouldUseLastScraperResults}
            shouldRunAsAsyncTask={shouldRunAsAsyncTask}
            setShouldRunAsAsyncTask={setShouldRunAsAsyncTask}
            shouldIncludeNestedParams={shouldIncludeNestedParams}
            setShouldIncludeNestedParams={setShouldIncludeNestedParams}
            shouldGenerateMappingTest={shouldGenerateMappingTest}
            setShouldGenerateMappingTest={setShouldGenerateMappingTest}
            canAutogenerateMappingTestForBlueprint={canAutogenerateMappingTestForBlueprint}
            fetchRecentTestRuns={fetchRecentTestRuns}
            selectedTestLinkedAccount={selectedTestLinkedAccount}
            shouldLogStepIO={shouldLogStepIO}
            setShouldLogStepIO={setShouldLogStepIO}
            abstractConditionsForSelectiveSync={abstractConditionsForSelectiveSync}
            dispatchAbstractConditionsForSelectiveSync={dispatchAbstractConditionsForSelectiveSync}
            isQBDLiveRunTab={isQBDLiveRunTab}
          />
          {shouldRunAsAsyncTask && (
            <div className="text-center">
              <Button
                variant={ButtonVariant.SecondaryBlue}
                onClick={fetchRecentTestRuns}
                disabled={!selectedTestLinkedAccount}
                fullWidth={true}
              >
                Show recent async runs
              </Button>
            </div>
          )}
          {blueprint.report_template_id && (
            <Button
              variant={ButtonVariant.SecondaryBlue}
              fullWidth={true}
              onClick={() => setShowReportUploadModal(true)}
            >
              <b style={{ fontSize: "14px" }}>Upload new Report</b>
            </Button>
          )}
          <Button
            loading={isQBDLiveRunTab ? isLoadingLiveRun : isLoading}
            variant={ButtonVariant.PrimaryBlue}
            disabled={isQBDLiveRunTab ? isLoadingLiveRun : isTestBlueprintDisabled}
            onClick={() => {
              isQBDLiveRunTab
                ? initiateLiveRun()
                : runTestBlueprint({ clearAsyncTaskExecutionResults });
            }}
            fullWidth={true}
          >
            Run Blueprint
          </Button>
        </div>
        {isQBDLiveRunTab ? (
          <WebConnectorLiveRunResults
            isLoadingLiveRun={isLoadingLiveRun}
            webConnectorSessionResponse={webConnectorSessionResponse}
            isTestingBlueprintAsync={isTestingBlueprintAsync}
            stopWaitingOnAsyncTaskExecution={stopWaitingOnAsyncTaskExecution}
            asyncTaskExecutionResult={asyncTaskExecutionResult}
            blueprintRunnerExecutionResponse={blueprintRunnerExecutionResponse}
            isTestingBlueprint={isTestingBlueprint}
            isShowingModelsModal={isShowingModelsModal}
            setIsShowingModelsModal={setIsShowingModelsModal}
          />
        ) : (
          <>
            <div>
              <BlueprintLeftPanelConsoleResultSummary
                isTestingBlueprintAsync={isTestingBlueprintAsync}
                stopWaitingOnAsyncTaskExecution={stopWaitingOnAsyncTaskExecution}
                asyncTaskExecutionResult={asyncTaskExecutionResult}
                blueprintRunnerExecutionResponse={blueprintRunnerExecutionResponse}
                isTestingBlueprint={isTestingBlueprint}
                isShowingModelsModal={isShowingModelsModal}
                setIsShowingModelsModal={setIsShowingModelsModal}
              />
            </div>
            <div>
              <BlueprintLeftPanelConsoleResultLogs
                blueprintRunnerExecutionResponse={blueprintRunnerExecutionResponse}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BlueprintEditorLeftPanelConsoleSubtab;
