import assert from "assert";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { fetchWithAuth } from "../../api-client/api_client";
import InputFormField from "../blueprint-editor/right-panel/InputFormField";
import HeaderBar from "../portal/HeaderBar";
import SpinnerButton from "../shared/SpinnerButton";
import { showErrorToast } from "../shared/Toasts";
import queryString from "query-string";

const ExportPage = () => {
  const [totp, setTotp] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState<string>("");
  const [hasRequestBeenMade, setHasRequestBeenMade] = useState(false);
  const isFormComplete = totp?.length === 6 && !hasRequestBeenMade;

  return (
    <div className="ml-6">
      <Row>
        <Col>
          <HeaderBar title="Export Models" />
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="w-65">
            <Col className="col-12 col-lg-12 col-xl-12">
              <InputFormField
                currentValue={totp}
                onChange={(totp) => setTotp(totp)}
                placeholder=""
                title={"Two-factor code"}
              />
              <SpinnerButton
                text="Export"
                className="btn-block btn-primary mb-9"
                disabled={!isFormComplete}
                isLoading={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  assert(totp?.length === 6);
                  fetchWithAuth({
                    path: "/tenants/sync/models?" + queryString.stringify({ totp }),
                    method: "GET",
                    expectJSON: false,
                    onResponse: (response) => {
                      setHasRequestBeenMade(true);
                      setIsLoading(false);
                      response.blob().then((blob: Blob) => {
                        blob
                          .slice(0, 16)
                          .arrayBuffer()
                          .then((arrayBuffer) =>
                            setKey(btoa(String.fromCharCode(...new Uint8Array(arrayBuffer))))
                          );
                        const url = window.URL.createObjectURL(
                          new Blob([blob.slice(16)], { type: "application/octet-stream" })
                        );
                        const a = document.createElement("a");
                        a.style.display = "none";
                        a.href = url;
                        a.download = "exported-models";
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                      });
                    },
                    onError: (response: Response | undefined) => {
                      if (response?.status === 401) {
                        showErrorToast("Invalid two-factor code.");
                      } else {
                        showErrorToast("Save failed.");
                      }
                      setIsLoading(false);
                    },
                  });
                }}
              />
              <InputFormField
                currentValue={key}
                onChange={() => {}}
                placeholder=""
                title={"Key"}
                readOnly
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ExportPage;
