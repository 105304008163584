import { Text, TextField } from "@merge-api/merge-javascript-shared";

interface Props {
  type: string;
  title: string;
  subtitle?: string;
  placeholder?: string;
  value: any;
  onChange: (e: any) => void;
  error?: boolean;
  errorText?: string;
  defaultValue?: any;
}

const LeftPanelTextField = ({
  type,
  title,
  subtitle,
  placeholder,
  value,
  onChange,
  error,
  errorText,
  defaultValue,
}: Props) => {
  return (
    <TextField
      type={type}
      inputClassName="bg-white"
      inputWrapperClassName="bg-white rounded-md"
      className="w-full"
      label={
        <div>
          <Text as="div" className="text-black font-semibold">
            {title}
          </Text>
          <Text as="div" variant="sm" className="text-gray-70 mb-2">
            {subtitle}
          </Text>
        </div>
      }
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      error={error}
      errorText={errorText}
      defaultValue={defaultValue}
    />
  );
};

export default LeftPanelTextField;
