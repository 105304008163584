import { Text, TextField } from "@merge-api/merge-javascript-shared";

type Props = {
  title: string;
  description: string;
  placeholder: string;
  value: string | undefined;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

const PublishModalTextField = ({ title, description, placeholder, value, onChange }: Props) => {
  return (
    <div className="flex flex-column space-y-2">
      <div>
        <Text variant="h6">{title}</Text>
        <Text variant="sm" className="text-gray-50">
          {description}
        </Text>
      </div>
      <TextField value={value} onChange={onChange} placeholder={placeholder} />
    </div>
  );
};

export default PublishModalTextField;
