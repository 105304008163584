import { useCallback, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { fetchWithAuth } from "../../../api-client/api_client";
import { Integration } from "../../../models/Entities";
import { MappingTestSuiteMeta } from "../../../models/MappingTests";
import IndividualMappingTestSuiteRouter from "./IndividualMappingTestSuiteRouter";
import MappingTestSuitesOverview from "./MappingTestSuitesOverview";

type Props = {
  integrationMeta: Integration;
};
const MappingTestsTab = ({ integrationMeta }: Props) => {
  const [testSuites, setTestSuites] = useState<MappingTestSuiteMeta[] | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const integrationID = integrationMeta.id;

  const fetchMappingTestSuites = useCallback(() => {
    setIsLoading(true);
    fetchWithAuth({
      path: `/stallions/integrations/${integrationID}/test-suites`,
      method: "GET",
      onResponse: (data: MappingTestSuiteMeta[]) => {
        setTestSuites(data);
        setIsLoading(false);
      },
    });
  }, [integrationID]);

  useEffect(() => {
    fetchMappingTestSuites();
  }, [integrationID, fetchMappingTestSuites]);

  return (
    <Switch>
      <Route
        path={"/integrations/:integrationID/test-suites/:mappingTestSuiteID"}
        children={() => <IndividualMappingTestSuiteRouter integrationMeta={integrationMeta} />}
      />
      <Route
        path={"/integrations/:integrationID/test-suites"}
        children={({ match }: { match: any }) => (
          <MappingTestSuitesOverview
            integrationMeta={integrationMeta}
            match={match}
            testSuites={testSuites}
            fetchMappingTestSuites={fetchMappingTestSuites}
            isLoading={isLoading}
          />
        )}
      />
    </Switch>
  );
};

export default MappingTestsTab;
