import { Button } from "@merge-api/merge-javascript-shared";
import { Plus } from "lucide-react";

interface Props {
  onClick: () => void;
  buttonText: string;
}

const ButtonCell = ({ onClick, buttonText }: Props) => {
  return (
    <td className="py-5">
      <Button onClick={onClick} leftIcon={<Plus size={16} />} size="sm">
        {buttonText}
      </Button>
    </td>
  );
};

export default ButtonCell;
