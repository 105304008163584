import { DiffState, DiffStateFieldTypeEnum } from "../../../../models/DiffModels";
import { generateDiffState } from "./helpers-diff";

export const generateDiffForCustomerFacingFields = (): DiffState => {
  return generateDiffState({
    fields: [
      {
        type: DiffStateFieldTypeEnum.HEADER,
        name: "marketing-pages-header",
        displayName: "Marketing pages",
        childDiffStateInputFields: [
          {
            name: "color",
            displayName: "Color",
          },
        ],
      },
      {
        type: DiffStateFieldTypeEnum.HEADER,
        name: "logos-header",
        displayName: "Logos",
        childDiffStateInputFields: [
          {
            name: "image",
            displayName: " Rectangular image",
          },
          {
            name: "square_image",
            displayName: " Square image",
          },
          {
            name: "color_image",
            displayName: " Color image",
          },
          {
            name: "white_image",
            displayName: " White image",
          },
          {
            name: "light_grey_image",
            displayName: " Light grey image",
          },
          {
            name: "dark_grey_image",
            displayName: "Dark grey image",
          },
          {
            name: "black_image",
            displayName: " Black image",
          },
          {
            name: "social_banner",
            displayName: " Social banner",
          },
        ],
      },
      {
        type: DiffStateFieldTypeEnum.HEADER,
        name: "docs-header",
        displayName: "Docs",
        childDiffStateInputFields: [
          {
            name: "webhook_setup_guide_url",
            displayName: "Webhook setup guide URL",
          },
        ],
      },
      {
        type: DiffStateFieldTypeEnum.HEADER,
        name: "dashboard-header",
        displayName: "Dashboard",
        childDiffStateInputFields: [
          {
            name: "notes_for_customers",
            displayName: "Helpful notes for customers",
          },
        ],
      },
    ],
  });
};
