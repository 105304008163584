import {
  BadgeError,
  BadgeSuccess,
  BadgeWarning,
} from "../../shared/BlueprintExecutionExitCodeBadge";

interface Props {
  exitCode: string | number;
}

const EXIT_CODE_MESSAGES: { [key: number]: string } = {
  100: "Continue",
  101: "Switching Protocols",
  200: "OK",
  201: "Created",
  202: "Accepted",
  203: "Non-Authoritative Information",
  204: "No Content",
  205: "Reset Content",
  206: "Partial Content",
  300: "Multiple Choices",
  301: "Moved Permanently",
  302: "Found",
  303: "See Other",
  304: "Not Modified",
  305: "Use Proxy",
  307: "Temporary Redirect",
  400: "Bad Request",
  401: "Unauthorized",
  402: "Payment Required",
  403: "Forbidden",
  404: "Not Found",
  405: "Method Not Allowed",
  406: "Not Acceptable",
  407: "Proxy Authentication Required",
  408: "Request Timeout",
  409: "Conflict",
  410: "Gone",
  411: "Length Required",
  412: "Precondition Failed",
  413: "Payload Too Large",
  414: "URI Too Long",
  415: "Unsupported Media Type",
  416: "Range Not Satisfiable",
  417: "Expectation Failed",
  418: "MFA Code Needed", // Overridden
  419: "Missing Permissions", // Overridden
  426: "Upgrade Required",
  500: "Internal Server Error",
  501: "Not Implemented",
  502: "Bad Gateway",
  503: "Service Unavailable",
  504: "Gateway Timeout",
  505: "HTTP Version Not Supported",
  700: "Server Error",
  701: "Scraper Timeout",
  710: "Retrying",
};

const ScraperExitCodeBadge = ({ exitCode }: Props) => {
  if (!exitCode) {
    return null;
  }
  const exitCodeInt = typeof exitCode !== "number" ? parseInt(exitCode) : exitCode;
  const exitCodeMessage =
    exitCodeInt in EXIT_CODE_MESSAGES ? EXIT_CODE_MESSAGES[exitCodeInt] : "Unknown Exit Code";

  let BadgeComponent = BadgeSuccess;

  if ([418, 419, 710].includes(exitCodeInt)) {
    BadgeComponent = BadgeWarning;
  } else if (exitCodeInt >= 300) {
    BadgeComponent = BadgeError;
  }
  return (
    <BadgeComponent>
      <b>{`${exitCode} ${exitCodeMessage}`}</b>
    </BadgeComponent>
  );
};

export default ScraperExitCodeBadge;
