import { useEffect, useState } from "react";
import {
  AsyncAutogeneratedReadMappingTestRun,
  AutogeneratedReadMappingTestRunStatus,
} from "../../../models/MappingTests";
import AutogenerateFetchMappingTestButton from "./AutogenerateFetchMappingTestButton";
import ResetFetchMappingTestButton from "./ResetFetchMappingTestButton";
import ViewAutogeneratedRunButton from "./ViewAutogeneratedRunButton";
import ViewAutoGeneratedRunModal from "./ViewAutoGeneratedRunModal";
import { navigateToMappingTestEditor } from "../../../router/RouterUtils";
import { useHistory } from "react-router-dom";

interface FetchMappingTestButtonProps {
  integrationID: string;
  mappingTestSuiteID: string;
  isMappingTestReset: boolean;
  generatedReadTestRun: AsyncAutogeneratedReadMappingTestRun | null;
  setGeneratedReadTestRun: React.Dispatch<
    React.SetStateAction<AsyncAutogeneratedReadMappingTestRun | null>
  >;
  inProgressExecution: boolean;
  finishedExecution: boolean;
  mappingTestID: string;
  fetchMappingTestSuite: () => void;
}

const FetchMappingTestButtons = ({
  integrationID,
  mappingTestSuiteID,
  isMappingTestReset,
  generatedReadTestRun,
  inProgressExecution,
  setGeneratedReadTestRun,
  finishedExecution,
  mappingTestID,
  fetchMappingTestSuite,
}: FetchMappingTestButtonProps) => {
  // state
  const [dialogOpen, setDialogOpen] = useState(false);
  const [hasPollingBeenTriggered, setHasPollingBeenTriggered] = useState<boolean>(false);
  const [sendUserToGeneratedMappingTest, setSendUserToGeneratedMappingTest] = useState<boolean>(
    false
  );

  // consts derived from state
  const excludedStatuses = [
    AutogeneratedReadMappingTestRunStatus.FINISHED_WITH_SUCCESS,
    AutogeneratedReadMappingTestRunStatus.FINISHED_WITH_FAILURES_THEN_RAN_SUCCESSFULLY,
  ];

  const showViewExecutionButton =
    generatedReadTestRun && !excludedStatuses.includes(generatedReadTestRun?.status);

  // functions
  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  /*
   * hooks
   */
  const history = useHistory();

  // hook to set new endpoint collection data for suite
  useEffect(() => {
    if (hasPollingBeenTriggered && finishedExecution) {
      setSendUserToGeneratedMappingTest(true);
      fetchMappingTestSuite();
    }
  }, [finishedExecution, hasPollingBeenTriggered]);

  // hook to send the user to the newly generated mapping test
  useEffect(() => {
    if (
      sendUserToGeneratedMappingTest &&
      generatedReadTestRun?.status === AutogeneratedReadMappingTestRunStatus.FINISHED_WITH_SUCCESS
    ) {
      setSendUserToGeneratedMappingTest(false);
      navigateToMappingTestEditor(history, integrationID, mappingTestID);
    }
  }, [mappingTestID]);

  return (
    <>
      {generatedReadTestRun && (
        <ViewAutoGeneratedRunModal
          inProgressExecution={inProgressExecution}
          dialogOpen={dialogOpen}
          closeDialog={closeDialog}
          generatedReadTestRun={generatedReadTestRun}
        />
      )}
      <div className="flex flex-row items-center">
        {showViewExecutionButton && (
          <div className="mr-3">
            <ViewAutogeneratedRunButton
              generatedReadTestRun={generatedReadTestRun}
              mappingTestSuiteID={mappingTestSuiteID}
              hasPollingBeenTriggered={hasPollingBeenTriggered}
              setHasPollingBeenTriggered={setHasPollingBeenTriggered}
              setGeneratedReadTestRun={setGeneratedReadTestRun}
              openDialog={openDialog}
              inProgressExecution={inProgressExecution}
            />
          </div>
        )}
        <div className="mr-3">
          {isMappingTestReset || inProgressExecution ? (
            <AutogenerateFetchMappingTestButton
              generatedReadTestRun={generatedReadTestRun}
              openDialog={openDialog}
              inProgressExecution={inProgressExecution}
              mappingTestSuiteID={mappingTestSuiteID}
              setGeneratedReadTestRun={setGeneratedReadTestRun}
              setHasPollingBeenTriggered={setHasPollingBeenTriggered}
            />
          ) : (
            <ResetFetchMappingTestButton
              integrationID={integrationID}
              mappingTestSuiteID={mappingTestSuiteID}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FetchMappingTestButtons;
