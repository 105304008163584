import { fetchWithAuth } from "../../../api-client/api_client";
import { APIEndpointMethod, LinkedAccountAccessSession } from "../../../models/Entities";
import { showErrorToast, showSuccessToast } from "../../shared/Toasts";

interface RevokeLinkedAccountAccessProps {
  accessSessionID: string | null;
  setLinkedAccountAccessSession: (
    linkedAccountAccessSession: LinkedAccountAccessSession | null
  ) => void;
  fetchTestLinkedAccounts: () => void;
}

const useRevokeLinkedAccountAccess = ({
  accessSessionID,
  setLinkedAccountAccessSession,
  fetchTestLinkedAccounts,
}: RevokeLinkedAccountAccessProps) => {
  const revokeLinkedAccountAccess = () => {
    if (!accessSessionID) {
      return;
    }
    fetchWithAuth({
      path: `/integrations/linked-accounts/access-session/${accessSessionID}/revoke`,
      method: APIEndpointMethod.POST,
      headers: {
        "Content-Type": "application/json",
      },
      body: {},
      onResponse: () => {
        setLinkedAccountAccessSession(null);
        fetchTestLinkedAccounts();
        showSuccessToast("Successfully revoked access to Linked Account");
      },
      onError: (error) => {
        error
          ?.json()
          .then((value: string | Record<string, any>) => {
            let errorMessage = "";
            if (typeof value == "string") {
              errorMessage = value;
            } else {
              errorMessage = value?.non_field_errors ?? JSON.stringify(value);
            }
            showErrorToast(
              `Failed to revoke access to Linked Account. Error message: \n ${errorMessage}`
            );
          })
          .catch((error) => {
            showErrorToast(`Failed to revoke access to Linked Account. Error message: \n ${error}`);
          });
      },
    });
  };

  return { revokeLinkedAccountAccess };
};

export default useRevokeLinkedAccountAccess;
