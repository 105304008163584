import { useEffect } from "react";
import { DEFAULT_ADMIN_TAB_NAME } from "../../constants";

/**
 * useDocumentTitle - A custom hook to set the document title
 * @param title - The title you want to set for the document
 * @param deps - Optional dependency array for useEffect
 */
const useDocumentTitle = (title: string | null | undefined, deps: React.DependencyList = []) => {
  useEffect(() => {
    if (title) document.title = title;

    return () => {
      document.title = DEFAULT_ADMIN_TAB_NAME;
    };
  }, deps);
};

export default useDocumentTitle;
