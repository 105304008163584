import EmptyStateWrapper from "../../../../shared/layout/EmptyStateWrapper";
import {
  SelectiveSyncFilterSchemaAPIResponse,
  VersionedComponentAPIActions,
  VersionedComponentAPIComponentKeys,
} from "../../../../versioned-components/types";
import useVersionedComponentsAPI from "../../../VersionedComponentsAPIClient";
import SelectiveSyncFiltersTable from "./SelectiveSyncFiltersTable";
import SelectiveSyncFiltersTableHeader from "./SelectiveSyncFiltersTableHeader";

interface Props {
  integrationID: string;
}

const SelectiveSyncFiltersTableContainer = ({ integrationID }: Props) => {
  const request_body = {
    component_key: VersionedComponentAPIComponentKeys.SelectiveSyncFilterSchema,
  };

  // Fetch all of the relevant selective sync filter schemas for the integration,
  // then pass the data into our S2 Filter table component
  const { data, isLoading } = useVersionedComponentsAPI<SelectiveSyncFilterSchemaAPIResponse>({
    integrationID,
    versioned_components_api_action:
      VersionedComponentAPIActions.GET_COMPONENTS_FOR_COMPONENT_CLASS,
    request_body,
    onResponse: (_: any) => {},
  });

  if (isLoading) {
    return <EmptyStateWrapper isSpinner />;
  }

  return (
    <>
      <SelectiveSyncFiltersTableHeader integrationID={integrationID} />
      <SelectiveSyncFiltersTable
        integrationID={integrationID}
        selectiveSyncFilterSchemas={data ?? {}}
      />
    </>
  );
};

export default SelectiveSyncFiltersTableContainer;
