import tlds from "tlds";

// parses URL into subdomain, second level domain, and top level domain
export const parseUrl = (urlString: string | undefined) => {
  if (!urlString) {
    // If urlString is undefined or empty, return empty strings for each part
    return {
      subdomain: "",
      secondLevelDomain: "",
      topLevelDomain: "",
    };
  }

  const url = new URL(urlString.startsWith("https://") ? urlString : `https://${urlString}`);
  const hostnameParts = url.hostname.split(".").filter((part) => part !== "");

  let subdomain, secondLevelDomain, topLevelDomain;

  // ensure there are at least 2 parts for a valid second level and top level domain at all times
  if (hostnameParts.length >= 2) {
    [secondLevelDomain, topLevelDomain] = hostnameParts.slice(-2);

    if (hostnameParts.length > 2) {
      // This if statement handles cases such as www.blank.com.uk, where "com.uk" is the combined top level domain
      const twoPartTopLevelDomain = hostnameParts.slice(-2);
      if (tlds.includes(twoPartTopLevelDomain[0]) && tlds.includes(twoPartTopLevelDomain[1])) {
        topLevelDomain = hostnameParts.slice(-2).join(".");
        secondLevelDomain = hostnameParts.slice(-3)[0];
        subdomain = hostnameParts.slice(0, -3).join(".");
      } else {
        subdomain = hostnameParts.slice(0, -2).join(".");
      }
    } else {
      subdomain = "";
    }
  } else {
    subdomain = "";
    secondLevelDomain = "";
    topLevelDomain = "";
  }

  return {
    subdomain: subdomain,
    secondLevelDomain: secondLevelDomain,
    topLevelDomain: topLevelDomain,
  };
};
