import isEqual from "lodash/isEqual";
import { Fragment, memo, useState } from "react";
import { ChevronDown, ChevronRight } from "lucide-react";
import styled from "styled-components";
import {
  CommonModelMappingMissInfo,
  MappingTestCommonModel,
  MappingTestCommonModelExpectedMappings,
  MappingTestCommonModelObject,
  MappingTestExpectedMapping,
  MappingTestExecutionIndividualCommonModelCountMetadata,
  MappingTestExecutionMappingMissesForCommonModel,
} from "../../../models/MappingTests";
import {
  addNewCommonModelExpectedMapping,
  changeCommonModelExpectedMappingName,
  deleteCommonModelExpectedMapping,
  getEmptyValueForField,
} from "../utils/MappingTestBuildingUtils";
import { palette } from "../../../styles/theme/colors";
import MappingTestEditorCommonModelExpectedMappingField from "./MappingTestEditorCommonModelExpectedMappingField";
import MappingTestEditorCommonModelExpectedMappingSectionHeader from "./MappingTestEditorCommonModelExpectedMappingSectionHeader";
import MappingTestNewMappingRow from "./MappingTestNewMappingRow";
import { BlueprintOperationType } from "../../../models/Blueprints";
import { blueprintWriteOperations } from "../../../models/Helpers";

interface IncorrectFieldsInfo {
  [key: string]: CommonModelMappingMissInfo;
}

type CommonModelDividerProps = {
  hasMappingMisses: boolean;
  isMappedCorrectly: boolean;
};

const Divider = styled.div.attrs({ className: "m-0 px-1.5 py-3 font-semibold" })<
  CommonModelDividerProps
>`
  background: ${({ hasMappingMisses, isMappedCorrectly }) =>
    hasMappingMisses ? "#FFFAFA" : isMappedCorrectly ? "#F5FDFA" : palette.border};
    border-top: ${({ hasMappingMisses, isMappedCorrectly }) =>
      hasMappingMisses ? "1px solid #FF8696" : isMappedCorrectly ? "1px solid #97EDCE" : ""}};
  border-bottom: ${({ hasMappingMisses, isMappedCorrectly }) =>
    hasMappingMisses ? "1px solid #FF8696" : isMappedCorrectly ? "1px solid #97EDCE" : ""}};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;
const ExpectedMappingsContainer = styled.div`
  margin-bottom: 20px;
`;

type Props = {
  commonModel: MappingTestCommonModel;
  mappingMissesForCommonModel: undefined | MappingTestExecutionMappingMissesForCommonModel;
  mappingTestCommonModelObject: MappingTestCommonModelObject;
  commonModelID: string;
  commonModelCountMetadata: undefined | MappingTestExecutionIndividualCommonModelCountMetadata;
  commonModelCountAssertion: undefined | number;
  operationType: BlueprintOperationType;
  setCommonModelCountAssertion: (commonModelID: string, commonModelCountAssertion: number) => void;
  updateCommonModelExpectedMappings: (
    commonModelID: string,
    commonModelExpectedMappings: undefined | MappingTestCommonModelExpectedMappings
  ) => void;
  availableRelationLookupDict: { [commonModelID: string]: Array<string> };
  updateRelationName: (commonModelID: string, oldName: string, newName: string) => void;
  isRunningMappingTest: boolean;
};

const MappingTestEditorCommonModelExpectedMappings = ({
  commonModel,
  commonModelID,
  mappingMissesForCommonModel,
  mappingTestCommonModelObject,
  commonModelCountMetadata,
  commonModelCountAssertion,
  setCommonModelCountAssertion,
  updateCommonModelExpectedMappings,
  availableRelationLookupDict,
  updateRelationName,
  isRunningMappingTest,
  operationType,
}: Props) => {
  const mappings = mappingTestCommonModelObject?.individual_mappings ?? {};
  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState<boolean>(true);
  const toggleHeaderIsCollapsed = () => setIsHeaderCollapsed(!isHeaderCollapsed);
  const ToggleChevron = () => {
    const toggleClass = "d float-right";
    return isHeaderCollapsed ? (
      <ChevronRight className={toggleClass} />
    ) : (
      <ChevronDown className={toggleClass} />
    );
  };

  const updateCommonModelExpectedMappingsForCommonModel = (
    commonModelExpectedMappings: undefined | MappingTestCommonModelExpectedMappings
  ) => {
    updateCommonModelExpectedMappings(commonModelID, commonModelExpectedMappings);
  };

  const updateIndividualMapping = ({
    individualMappingID,
    newMapping,
  }: {
    individualMappingID: string;
    newMapping: MappingTestExpectedMapping;
  }) =>
    updateCommonModelExpectedMappings(commonModelID, {
      ...mappings,
      [individualMappingID]: newMapping,
    });

  const getFieldTypeForKey = (key: string): string => commonModel.fields[key]?.type ?? "unknown";

  const getEnumFieldChoicesForKey = (key: string): undefined | Array<string> =>
    // @ts-ignore choices is deprecated but still used
    commonModel.fields[key]?.enum ?? commonModel.fields[key]?.choices;

  const commonModelsProduced = commonModelCountMetadata?.total_common_model_count ?? undefined;

  const isMissingWriteMappings = commonModelsProduced && Object.keys(mappings).length == 0;

  const hasCorrectNumberForWriteTest =
    (!commonModelsProduced ||
      !Object.values(mappingMissesForCommonModel ?? {}).some(
        (mapping) => mapping.incorrect_fields.length || mapping.is_model_missing
      )) &&
    !isMissingWriteMappings;

  // Only consider expected vs actual number of models produced if we've run the test
  const isAssertedNumberCorrect = blueprintWriteOperations.includes(operationType)
    ? hasCorrectNumberForWriteTest
    : commonModelCountAssertion !== undefined &&
      mappingMissesForCommonModel !== undefined &&
      commonModelCountMetadata !== undefined
    ? commonModelsProduced === commonModelCountAssertion
    : commonModelsProduced == undefined
    ? true
    : false;

  const hasMissingMappedModel = Object.values(mappingMissesForCommonModel ?? {})
    .map((missInfo) => missInfo.is_model_missing)
    .some((isModelMissing) => isModelMissing);

  const doesModelClassHaveMappingTestMisses =
    hasMissingMappedModel ||
    !isAssertedNumberCorrect ||
    Object.values(mappingMissesForCommonModel ?? {}).some(
      (mapping) => mapping.incorrect_fields.length || mapping.is_model_missing
    );

  return (
    <ExpectedMappingsContainer>
      <Divider
        hasMappingMisses={doesModelClassHaveMappingTestMisses}
        isMappedCorrectly={
          mappingMissesForCommonModel !== undefined && !doesModelClassHaveMappingTestMisses
        }
        onClick={() => toggleHeaderIsCollapsed()}
      >
        {commonModel.name}s {<ToggleChevron />}
      </Divider>

      {!isHeaderCollapsed ? (
        <>
          {!blueprintWriteOperations.includes(operationType) && (
            <MappingTestEditorCommonModelExpectedMappingField
              isRowShaded={false}
              isUniqueIdentifier={false}
              hasMappingMisses={!isAssertedNumberCorrect}
              isMappedCorrectly={isAssertedNumberCorrect}
              fieldSchema={undefined}
              key={"total_count"}
              text={
                isRunningMappingTest
                  ? "Test Running..."
                  : `Assert the total count of ${commonModel.name} models created`
              }
              fieldType={"number"}
              fieldKeyPath={[]}
              value={isRunningMappingTest ? "" : commonModelCountAssertion?.toString() ?? "0"}
              setValue={(countAsString) => {
                setCommonModelCountAssertion(
                  commonModelID,
                  Math.round(Math.abs(Number(countAsString))) || 0
                );
              }}
              onUpdate={() => {}}
              availableRelationNames={undefined}
            />
          )}
          {Object.entries(mappings).map(([individualMappingID, individualMapping]) => {
            const { unique_identifier, field_mappings } = individualMapping;
            const isKeyUniqueIdentifier = (key: string) => key === unique_identifier;
            const mappingMissesForExpectedMapping =
              mappingMissesForCommonModel?.[individualMappingID];
            const incorrectFields: IncorrectFieldsInfo = (
              mappingMissesForExpectedMapping?.incorrect_fields ?? []
            ).reduce((acc, missedInfo) => {
              acc[missedInfo.name as keyof IncorrectFieldsInfo] = { ...missedInfo };
              return acc;
            }, {} as IncorrectFieldsInfo);
            const hasMappingMissesForExpectedMapping =
              (Object.keys(incorrectFields) ?? []).length > 0 ||
              mappingMissesForExpectedMapping?.is_model_missing;
            const isExpectedMappingCorrect =
              mappingMissesForExpectedMapping && !hasMappingMissesForExpectedMapping;

            const updateUniqueIdentifier = (newUniqueIdentifier: string) => {
              updateIndividualMapping({
                individualMappingID,
                newMapping: {
                  ...individualMapping,
                  field_mappings: { ...field_mappings, unique_identifier: newUniqueIdentifier },
                  unique_identifier: newUniqueIdentifier,
                },
              });
            };

            const updateField = ({
              fieldKey,
              newValue,
            }: {
              fieldKey: string;
              newValue: string | Array<string>;
            }) =>
              updateIndividualMapping({
                individualMappingID,
                newMapping: {
                  ...individualMapping,
                  field_mappings: { ...field_mappings, [fieldKey]: newValue },
                },
              });

            return (
              <Fragment key={individualMappingID}>
                <MappingTestEditorCommonModelExpectedMappingSectionHeader
                  commonModelName={commonModel.name}
                  hasMappingMisses={hasMappingMissesForExpectedMapping}
                  isMissingInProducedModels={
                    mappingMissesForCommonModel?.[individualMappingID]?.is_model_missing === true
                  }
                  isMappedCorrectly={isExpectedMappingCorrect}
                  key={individualMappingID + "header"}
                  individualMappingID={individualMappingID}
                  onChangeName={(oldExpectedMappingName: string, newExpectedMappingName: string) =>
                    updateCommonModelExpectedMappingsForCommonModel(
                      changeCommonModelExpectedMappingName({
                        expectedMappings: mappings,
                        oldExpectedMappingName,
                        newExpectedMappingName,
                      })
                    )
                  }
                  onDelete={(oldExpectedMappingName: string) =>
                    updateCommonModelExpectedMappingsForCommonModel(
                      deleteCommonModelExpectedMapping({
                        expectedMappings: mappings,
                        oldExpectedMappingName,
                      })
                    )
                  }
                  updateRelationName={updateRelationName}
                  commonModelID={commonModelID}
                />
                <MappingTestEditorCommonModelExpectedMappingField
                  isRowShaded={false}
                  isUniqueIdentifier={false}
                  key={individualMappingID + "unique_identifier"}
                  text={"unique_identifier"}
                  fieldType={"unique_identifier"}
                  fieldSchema={undefined}
                  value={unique_identifier}
                  fieldKeyPath={[]}
                  //@ts-ignore
                  setValue={updateUniqueIdentifier as (array: string) => void}
                  onUpdate={() => {}}
                  availableRelationNames={undefined}
                />
                {Object.entries(commonModel.fields)
                  // TODO: https://app.asana.com/0/1204464925313817/1205067530231570
                  // Currently we dont correctly generate the schema for remote fields. It always
                  // show up as string, which makes the mappings crash. Once we correctly generate
                  // the schema in the backend get_mapping_test_common_models() function we can
                  // add this back in
                  .filter(([fieldKey, _commonModelField], _) => fieldKey != "remote_fields")
                  .map(([fieldKey, commonModelField], index) => {
                    const relationalModelClass =
                      commonModel.fields[fieldKey]?.relation?.model ??
                      commonModel.fields[fieldKey]?.items?.relation?.model;

                    const fieldValue =
                      field_mappings[fieldKey] ?? getEmptyValueForField(commonModelField);

                    const availableRelationNames = relationalModelClass
                      ? availableRelationLookupDict?.[relationalModelClass] ?? []
                      : undefined;

                    return (
                      <MappingTestEditorCommonModelExpectedMappingField
                        key={individualMappingID + fieldKey}
                        text={fieldKey}
                        isRowShaded={index % 2 === 1}
                        hasMappingMisses={(Object.keys(incorrectFields) ?? []).includes(fieldKey)}
                        isUniqueIdentifier={isKeyUniqueIdentifier(fieldKey)}
                        fieldSchema={commonModel.fields[fieldKey]}
                        fieldType={getFieldTypeForKey(fieldKey)}
                        fieldKeyPath={[]}
                        enumChoices={getEnumFieldChoicesForKey(fieldKey)}
                        value={fieldValue}
                        missedMappingInfo={incorrectFields?.[fieldKey] ?? {}}
                        //@ts-ignore
                        setValue={(newValue) => updateField({ fieldKey, newValue })}
                        onUpdate={() => {}}
                        availableRelationNames={availableRelationNames}
                      />
                    );
                  })}
              </Fragment>
            );
          })}
          <MappingTestNewMappingRow
            commonModelID={commonModel.id}
            commonModelName={commonModel.name}
            isIDAlreadyUsed={(individualMappingID) =>
              Object.keys(mappings).includes(individualMappingID)
            }
            onAdd={(newExpectedMappingName: string) =>
              updateCommonModelExpectedMappingsForCommonModel(
                addNewCommonModelExpectedMapping({
                  expectedMappings: mappings,
                  commonModel,
                  newExpectedMappingName,
                })
              )
            }
          />
        </>
      ) : null}
    </ExpectedMappingsContainer>
  );
};

export default memo(MappingTestEditorCommonModelExpectedMappings, isEqual);
