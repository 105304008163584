import MappingTestEditorCommonModelExpectedMappingField from "../mapping-test-block-assertions/MappingTestEditorCommonModelExpectedMappingField";
import MappingTestEditorCommonModelExpectedMappingSectionHeader from "../mapping-test-block-assertions/MappingTestEditorCommonModelExpectedMappingSectionHeader";

type Props = {
  availableModelNames: Array<string>;
  existingCommonModelName: string | undefined;
  setExistingCommonModelName: (existingCommonModelName: string) => void;
};
const MappingTestExistingCommonModelNameSection = ({
  availableModelNames,
  existingCommonModelName,
  setExistingCommonModelName,
}: Props) => {
  return (
    <div>
      <MappingTestEditorCommonModelExpectedMappingSectionHeader
        commonModelName={"Existing Common Model"}
      />

      <MappingTestEditorCommonModelExpectedMappingField
        key={"existing-common-model"}
        text={"Existing Common Model"}
        isRowShaded={false}
        hasMappingMisses={false}
        isUniqueIdentifier={false}
        fieldType={"model"}
        fieldSchema={undefined}
        enumChoices={undefined}
        fieldKeyPath={[]}
        value={existingCommonModelName ?? ""}
        setValue={(newValue) => setExistingCommonModelName(newValue as string)}
        onUpdate={() => {}}
        availableRelationNames={availableModelNames}
        isRequired={true}
      />
    </div>
  );
};

export default MappingTestExistingCommonModelNameSection;
