import { getIntegrationBuilderRateLimitConfigurationPathForId } from "../../../../../router/RouterUtils";
import IntegrationBuilderHeader from "../../../shared/IntegrationBuilderHeader";

interface PaginationConfigurationHeaderProps {
  integrationID: string;
  isNewRateLimitConfiguration: boolean;
}

const RateLimitConfigurationHeader = ({
  integrationID,
  isNewRateLimitConfiguration,
}: PaginationConfigurationHeaderProps) => {
  return (
    <div>
      <IntegrationBuilderHeader
        title={isNewRateLimitConfiguration ? "Add rate limit" : "Edit rate limit"}
        navButtonLink={getIntegrationBuilderRateLimitConfigurationPathForId(integrationID)}
        navButtonText="Rate limits"
        showRequired
      />
    </div>
  );
};

export default RateLimitConfigurationHeader;
