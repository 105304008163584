import React from "react";

export interface MappingTestContextState {
  mappingTestName: string;
  setMappingTestName: (newName: string) => void;
  isDebugMode: boolean;
  setIsDebugMode: (newVal: boolean) => void;
  integrationID: string;
  writtenCommonModel: string;
}

const MappingTestContext = React.createContext<MappingTestContextState>({
  mappingTestName: "",
  setMappingTestName: () => {},
  writtenCommonModel: "",
  isDebugMode: false,
  integrationID: "",
  setIsDebugMode: () => {},
});

export default MappingTestContext;
