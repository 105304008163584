import {
  BPRStepIOLog,
  BPRStepIOLogTypeEnum,
  BlueprintCanvasBaseStepLogsTree,
  StepLogsTreeStepLogAPICall,
} from "../../../../models/Blueprints";

/**
 * Helper function that upserts a step I/O log into tree
 * Uses recursion to insert into nested steps in tree
 * 
 * This tree is only used for interacting with the "Iteration #" dropdown on each step I/O log in canvas
 * 
 * Handles API paginated calls and item loops separately
 * Example
 * {
    "get_api_request_loop": {
    "loop_iterations": [],
    "api_requests": [
        {
        "index_in_raw_logs": 0,
        "loop_iterations": [
            {
            "index_in_raw_logs": 1,
            "children_loop_steps": {
                "nested_array_loop": {
                    "loop_iterations": [
                        {
                        "index_in_raw_logs": 4,
                        }
                    ]
                }
                }
            }
        ]
        }
    }
    }
*/
export const upsertLogIntoBaseStepLogsTree = (
  stepLogsTree: BlueprintCanvasBaseStepLogsTree,
  listOfParentStepIDs: string[],
  stepIOLog: BPRStepIOLog,
  indexInRawLogs: number
): BlueprintCanvasBaseStepLogsTree => {
  // Base case - If no parents, create & append log
  if (listOfParentStepIDs.length === 0) {
    // If log is a LOOP_ITERATION, add to "loop_iterations"
    if (stepIOLog.log_type === BPRStepIOLogTypeEnum.LOOP_ITERATION) {
      // If this is an API request loop step, upsert log to latest paginated API call, otherwise latest iteration
      const apiRequests = stepLogsTree[stepIOLog.step_id].api_requests;
      if (apiRequests) {
        return upsertLoopIterationIntoAPIRequestInTree(
          stepLogsTree,
          apiRequests,
          stepIOLog.step_id,
          indexInRawLogs
        );
      } else {
        return upsertLoopIterationIntoLoopIterationInTree(
          stepLogsTree,
          stepIOLog.step_id,
          indexInRawLogs
        );
      }
    }
    // If log is API_REQUEST, add to "api_requests"
    else if (stepIOLog.log_type === BPRStepIOLogTypeEnum.API_REQUEST) {
      return upsertAPIRequestIntoAPIRequestInTree(stepLogsTree, stepIOLog.step_id, indexInRawLogs);
    }
    // If neither API_REQUEST nor LOOP_ITERATION, add new log to tree
    else {
      return upsertNewLogIntoTree(stepLogsTree, stepIOLog.step_id, indexInRawLogs);
    }
  }

  // Recursive case - Recurse to next level of step hierarchy
  const parentStepID = listOfParentStepIDs[0];
  const apiRequests = stepLogsTree[parentStepID].api_requests;
  if (apiRequests) {
    return upsertLogIntoChildTreeOfAPIRequestLoopStep(
      stepLogsTree,
      listOfParentStepIDs,
      stepIOLog,
      indexInRawLogs,
      apiRequests,
      parentStepID
    );
  } else {
    return upsertLogIntoChildTreeOfParentStep(
      stepLogsTree,
      listOfParentStepIDs,
      stepIOLog,
      indexInRawLogs,
      parentStepID
    );
  }
};

/**
 * Upserts a loop iteration into the API request tree.
 * This function is used when dealing with API request loop steps.
 * It adds the new loop iteration to the latest API request's loop iterations.
 *
 * @param stepLogsTree - The current state of the step logs tree
 * @param apiRequests - Array of API requests for the current step
 * @param stepID - ID of the current step
 * @param indexInRawLogs - Index of the current log in the raw logs array
 * @returns Updated BlueprintCanvasBaseStepLogsTree
 */
const upsertLoopIterationIntoAPIRequestInTree = (
  stepLogsTree: BlueprintCanvasBaseStepLogsTree,
  apiRequests: StepLogsTreeStepLogAPICall[],
  stepID: string,
  indexInRawLogs: number
): BlueprintCanvasBaseStepLogsTree => {
  const latestAPIRequestIndex: number = apiRequests.length - 1;
  apiRequests[latestAPIRequestIndex].loop_iterations = [
    ...apiRequests[latestAPIRequestIndex].loop_iterations,
    {
      index_in_raw_logs: indexInRawLogs,
      children_loop_steps: {},
    },
  ];
  stepLogsTree[stepID].api_requests = apiRequests;
  return stepLogsTree;
};

/**
 * Upserts a loop iteration into the loop iteration tree.
 * This function is used for regular loop steps (non-API request loops).
 * It adds the new loop iteration to the step's loop iterations.
 *
 * @param stepLogsTree - The current state of the step logs tree
 * @param stepID - ID of the current step
 * @param indexInRawLogs - Index of the current log in the raw logs array
 * @returns Updated BlueprintCanvasBaseStepLogsTree
 */
const upsertLoopIterationIntoLoopIterationInTree = (
  stepLogsTree: BlueprintCanvasBaseStepLogsTree,
  stepID: string,
  indexInRawLogs: number
): BlueprintCanvasBaseStepLogsTree => {
  stepLogsTree[stepID].loop_iterations = [
    ...stepLogsTree[stepID].loop_iterations,
    {
      index_in_raw_logs: indexInRawLogs,
      children_loop_steps: {},
    },
  ];
  return stepLogsTree;
};

/**
 * Upserts an API request into the API request tree.
 * This function adds a new API request to the step's api_requests array.
 *
 * @param stepLogsTree - The current state of the step logs tree
 * @param stepID - ID of the current step
 * @param indexInRawLogs - Index of the current log in the raw logs array
 * @returns Updated BlueprintCanvasBaseStepLogsTree
 */
const upsertAPIRequestIntoAPIRequestInTree = (
  stepLogsTree: BlueprintCanvasBaseStepLogsTree,
  stepID: string,
  indexInRawLogs: number
): BlueprintCanvasBaseStepLogsTree => {
  if (stepLogsTree[stepID]) {
    stepLogsTree[stepID].api_requests = [
      ...(stepLogsTree[stepID].api_requests ?? []),
      {
        index_in_raw_logs: indexInRawLogs,
        loop_iterations: [],
      },
    ];
  } else {
    stepLogsTree[stepID] = {
      primary_log: {
        index_in_raw_logs: indexInRawLogs,
      },
      api_requests: [
        {
          index_in_raw_logs: indexInRawLogs,
          loop_iterations: [],
        },
      ],
      loop_iterations: [],
    };
  }
  return stepLogsTree;
};

/**
 * Adds a new log to the tree.
 * This function is used for logs that are neither API requests nor loop iterations.
 * It creates a new entry in the step logs tree for the given step ID.
 *
 * @param stepLogsTree - The current state of the step logs tree
 * @param stepID - ID of the current step
 * @param indexInRawLogs - Index of the current log in the raw logs array
 * @returns Updated BlueprintCanvasBaseStepLogsTree
 */
const upsertNewLogIntoTree = (
  stepLogsTree: BlueprintCanvasBaseStepLogsTree,
  stepID: string,
  indexInRawLogs: number
): BlueprintCanvasBaseStepLogsTree => {
  stepLogsTree[stepID] = {
    primary_log: {
      index_in_raw_logs: indexInRawLogs,
    },
    loop_iterations: [],
  };
  return stepLogsTree;
};

/**
 * Upserts a log into the child tree of an API request loop step.
 * This function handles the recursive case for API request loop steps.
 * It processes the next level of steps in the hierarchy.
 *
 * @param stepLogsTree - The current state of the step logs tree
 * @param listOfParentStepIDs - List of parent step IDs
 * @param stepIOLog - The step I/O log to be inserted
 * @param indexInRawLogs - Index of the current log in the raw logs array
 * @param apiRequests - Array of API requests for the current step
 * @param parentStepID - ID of the parent step
 * @returns Updated BlueprintCanvasBaseStepLogsTree
 */
const upsertLogIntoChildTreeOfAPIRequestLoopStep = (
  stepLogsTree: BlueprintCanvasBaseStepLogsTree,
  listOfParentStepIDs: string[],
  stepIOLog: BPRStepIOLog,
  indexInRawLogs: number,
  apiRequests: StepLogsTreeStepLogAPICall[],
  parentStepID: string
): BlueprintCanvasBaseStepLogsTree => {
  // Get the latest loop iteration of parent step
  const latestAPIRequestIndex = apiRequests.length - 1;
  const latestLoopIterationIndex = apiRequests[latestAPIRequestIndex].loop_iterations.length - 1;
  const latestLoopIteration =
    apiRequests[latestAPIRequestIndex].loop_iterations[latestLoopIterationIndex];
  // Recursively process next level of steps
  latestLoopIteration.children_loop_steps = upsertLogIntoBaseStepLogsTree(
    latestLoopIteration.children_loop_steps,
    listOfParentStepIDs.slice(1),
    stepIOLog,
    indexInRawLogs
  );
  // Add changes back to stepLogsTree
  apiRequests[latestAPIRequestIndex].loop_iterations[
    latestLoopIterationIndex
  ] = latestLoopIteration;
  stepLogsTree[parentStepID].api_requests = apiRequests;
  return stepLogsTree;
};

/**
 * Upserts a log into the child tree of a parent step.
 * This function handles the recursive case for regular (non-API request) steps.
 * It processes the next level of steps in the hierarchy.
 *
 * @param stepLogsTree - The current state of the step logs tree
 * @param listOfParentStepIDs - List of parent step IDs
 * @param stepIOLog - The step I/O log to be inserted
 * @param indexInRawLogs - Index of the current log in the raw logs array
 * @param parentStepID - ID of the parent step
 * @returns Updated BlueprintCanvasBaseStepLogsTree
 */
const upsertLogIntoChildTreeOfParentStep = (
  stepLogsTree: BlueprintCanvasBaseStepLogsTree,
  listOfParentStepIDs: string[],
  stepIOLog: BPRStepIOLog,
  indexInRawLogs: number,
  parentStepID: string
): BlueprintCanvasBaseStepLogsTree => {
  // Prep parent steps with child steps but no loop iterations (ie: If-Else steps)
  if (stepLogsTree[parentStepID].loop_iterations.length === 0) {
    stepLogsTree[parentStepID].loop_iterations.push({
      index_in_raw_logs: stepLogsTree[parentStepID].primary_log.index_in_raw_logs,
      children_loop_steps: {},
    });
  }
  // Get the latest loop iteration of parent step
  const latestLoopIterationIndex = stepLogsTree[parentStepID].loop_iterations.length - 1;
  const latestLoopIteration = stepLogsTree[parentStepID].loop_iterations[latestLoopIterationIndex];
  // Recursively process next level of steps
  latestLoopIteration.children_loop_steps = upsertLogIntoBaseStepLogsTree(
    latestLoopIteration.children_loop_steps,
    listOfParentStepIDs.slice(1),
    stepIOLog,
    indexInRawLogs
  );
  // Add changes back to stepLogsTree
  stepLogsTree[parentStepID].loop_iterations[latestLoopIterationIndex] = latestLoopIteration;
  return stepLogsTree;
};
