import { Blueprint } from "../../../models/Blueprints";
import {
  BlueprintCanvasConfiguration,
  getMaxPathYIndexHeightForSubstepsOfStep,
  getXIndexForStep,
  getYIndexForStep,
} from "../utils/BlueprintCanvasUtils";
import {
  canStepHaveMoreThanTwoPaths,
  checkConditionForTraversalPath,
  getAllTerminalStepsFromStep,
  getEnforceStepTraversalPathForStepID,
  getNumberOfPathsForStep,
  getPathKeyForStep,
  getPredecessorStepForStep,
} from "../utils/BlueprintEditorUtils";
import { CANVAS_Y_OFFSET, CARD_X_STEP, CARD_Y_STEP } from "./utils/constants";

interface Props {
  blueprint: Blueprint;
  canvasConfiguration: BlueprintCanvasConfiguration;
  cardXStart: number;
  canvasRef: React.RefObject<HTMLDivElement>;
  leftAlignSteps: boolean | undefined;
}

const BlueprintCanvasArrows = ({
  blueprint,
  canvasConfiguration,
  cardXStart,
  canvasRef,
  leftAlignSteps,
}: Props) => {
  return (
    <div>
      {Object.entries(canvasConfiguration.stepPlacements).map(([stepID, stepPlacement]) => {
        // DRAW ARROWS
        //@ts-ignore This is a step except for triggers and scrapers but TS does not know this
        const currentStep = stepPlacement?.step;
        const XArrowStart = cardXStart + stepPlacement.xIndex * CARD_X_STEP + 175;
        const YArrowStart = CANVAS_Y_OFFSET + stepPlacement.yIndex * CARD_Y_STEP + 14;
        const YArrowArcStart = YArrowStart - 25;
        const controlPointX = XArrowStart;
        const controlPointY = YArrowStart - 35;
        const YArrowArcEnd = YArrowStart - 45;
        const YArrowTipOffset = CANVAS_Y_OFFSET + stepPlacement.yIndex * CARD_Y_STEP - 35;
        const YPathKeyOffset = CANVAS_Y_OFFSET + stepPlacement.yIndex * CARD_Y_STEP;

        // No arrows needed for undefined or ghost steps
        if (
          currentStep === undefined ||
          currentStep?.id === null ||
          currentStep?.template == "ghost"
        ) {
          return <></>;
        }

        const parentID = canvasConfiguration.nodeIDtoParentNodeIDMap[stepID];
        //@ts-ignore This is a step except for triggers and scrapers but TS does not know this
        const parentStep = parentID ? canvasConfiguration.stepPlacements[parentID].step : null;
        // TODO: Dont search the whole tree again, just grab id + step from nodeIDtoPredecessorNodeIDMap
        const predecessorStep = getPredecessorStepForStep(blueprint, currentStep);

        const parentPath = (
          getEnforceStepTraversalPathForStepID(blueprint, currentStep.id) ?? [""]
        ).slice(0, -1);

        if (
          checkConditionForTraversalPath(blueprint, parentPath, (step) => {
            return Boolean(step.hasCollapsedSubsteps);
          })
        ) {
          return <></>;
        }

        if (predecessorStep === null && parentStep !== null && parentID) {
          // child
          const XOffSetFromParent =
            stepPlacement.xIndex - canvasConfiguration.stepPlacements[parentID].xIndex;

          const XArrowArcEnd = XArrowStart + (XOffSetFromParent > 0 ? -10 : 10);
          const XArrowArcStart =
            XArrowStart - XOffSetFromParent * 180 + (XOffSetFromParent > 0 ? 10 : -10);
          const controlPointXX = XArrowStart - XOffSetFromParent * 180;

          const numberOfPathsForParent = getNumberOfPathsForStep(parentStep);

          return (
            <div style={{ position: "absolute" }} key={stepID}>
              <div
                style={{
                  position: "absolute",
                  left: `${cardXStart + stepPlacement.xIndex * CARD_X_STEP}px`,
                  top: `${YArrowTipOffset}px`,
                  transition: "all 0.5s",
                }}
              >
                <svg width="360" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    className="card-bottom"
                    d="M 175 50 L 180.773503 40 L 169.226497 40 L 175 50"
                    fill="#C9D0DA"
                  ></path>
                </svg>
              </div>
              {XOffSetFromParent === 0 ? (
                <svg
                  width={canvasRef.current?.scrollWidth}
                  height={canvasRef.current?.scrollHeight}
                >
                  <line // vertical line
                    x1={XArrowStart}
                    y1={YArrowStart}
                    x2={XArrowStart}
                    y2={YArrowStart - 73}
                    stroke="#C9D0DA"
                    strokeWidth="2"
                  ></line>
                </svg>
              ) : (
                <svg
                  width={canvasRef.current?.scrollWidth}
                  height={canvasRef.current?.scrollHeight}
                >
                  <line // small vertical line from currentStep to horizontal line
                    x1={XArrowStart}
                    y1={YArrowStart}
                    x2={XArrowStart}
                    y2={YArrowArcStart}
                    stroke="#C9D0DA"
                    strokeWidth="2"
                    style={{ zIndex: 0 }}
                  ></line>
                  <path
                    d={
                      "M" +
                      XArrowStart +
                      " " +
                      YArrowArcStart +
                      " " +
                      "Q" +
                      " " +
                      controlPointX +
                      " " +
                      controlPointY +
                      " " +
                      XArrowArcEnd +
                      " " +
                      controlPointY
                    }
                    fill="none"
                    stroke="#C9D0DA"
                    strokeWidth="2"
                  ></path>
                  <line // horizontal line
                    x1={XArrowArcEnd}
                    y1={controlPointY}
                    x2={XArrowArcStart}
                    y2={controlPointY}
                    stroke="#C9D0DA"
                    strokeWidth="2"
                    style={{ zIndex: 0 }}
                  />
                  <path
                    d={
                      "M" +
                      XArrowArcStart +
                      " " +
                      controlPointY +
                      " " +
                      "Q" +
                      controlPointXX +
                      " " +
                      controlPointY +
                      " " +
                      controlPointXX +
                      " " +
                      YArrowArcEnd
                    }
                    fill="none"
                    stroke="#C9D0DA"
                    strokeWidth="2"
                  ></path>
                  <line // small vertical line from parentStep to horizonal line
                    x1={controlPointXX}
                    y1={YArrowArcEnd}
                    x2={controlPointXX}
                    y2={YArrowStart - 73}
                    stroke="#C9D0DA"
                    strokeWidth="2"
                    style={{ zIndex: 0 }}
                  />
                  {leftAlignSteps && (
                    <line // Center align dotted lines along the path of children
                      x1={XArrowStart - CARD_X_STEP * 1.5}
                      y1={(getYIndexForStep(blueprint, parentStep) + 2) * CARD_Y_STEP - 18}
                      x2={XArrowStart - CARD_X_STEP * 1.5}
                      y2={
                        (getYIndexForStep(blueprint, parentStep) + 2) * CARD_Y_STEP +
                        getMaxPathYIndexHeightForSubstepsOfStep(parentStep) * CARD_Y_STEP -
                        18
                      }
                      stroke="#dde2e8"
                      strokeWidth="2px"
                      strokeDasharray={"4,8"}
                      strokeLinecap="round"
                    ></line>
                  )}
                </svg>
              )}

              {numberOfPathsForParent > 2 ||
              (numberOfPathsForParent > 0 && canStepHaveMoreThanTwoPaths(parentStep)) ? (
                <div
                  style={{
                    position: "absolute",
                    left: `${cardXStart + stepPlacement.xIndex * CARD_X_STEP + 141}px`,
                    top: `${YPathKeyOffset - 17}px`,
                    transition: "all 0.5s",
                  }}
                >
                  <button className="pathkey-button">
                    {getPathKeyForStep(parentStep, currentStep)}
                  </button>
                </div>
              ) : numberOfPathsForParent > 1 ? (
                <div
                  style={{
                    position: "absolute",
                    left:
                      XOffSetFromParent < 0
                        ? `${cardXStart + stepPlacement.xIndex * CARD_X_STEP + 283}px`
                        : `${cardXStart + stepPlacement.xIndex * CARD_X_STEP}px`,
                    top: `${YPathKeyOffset - 32}px`,
                    transition: "all 0.5s",
                  }}
                >
                  <button className="pathkey-button">
                    <b>{getPathKeyForStep(parentStep, currentStep)}</b>
                  </button>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          );
        }

        if (predecessorStep !== null && predecessorStep.paths !== undefined) {
          const terminalSteps = getAllTerminalStepsFromStep(blueprint, predecessorStep);
          let XOffSet;
          let YOffSet;

          return Object.entries(terminalSteps ?? {}).map(([_, terminalStep]) => {
            XOffSet =
              getXIndexForStep(blueprint, currentStep) - getXIndexForStep(blueprint, terminalStep);
            YOffSet =
              getYIndexForStep(blueprint, currentStep) - getYIndexForStep(blueprint, terminalStep);

            const XArrowArcEnd = XArrowStart + (XOffSet > 0 ? -10 : 10);
            const XArrowArcStart = XArrowStart - XOffSet * 180 + (XOffSet > 0 ? 10 : -10);
            const controlPointXX = XArrowStart - XOffSet * 180;

            return (
              <div
                style={{ position: "absolute" }}
                key={predecessorStep.id + stepID + terminalStep.id}
              >
                <div
                  style={{
                    position: "absolute",
                    left: `${cardXStart + stepPlacement.xIndex * CARD_X_STEP}px`,
                    top: `${YArrowTipOffset}px`,
                    transition: "all 0.5s",
                  }}
                >
                  <svg width="360" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      className="card-bottom"
                      d="M 175 50 L 180.773503 40 L 169.226497 40 L 175 50"
                      fill="#C9D0DA"
                    ></path>
                  </svg>
                </div>
                {XOffSet === 0 ? (
                  <svg
                    width={canvasRef.current?.scrollWidth}
                    height={canvasRef.current?.scrollHeight}
                  >
                    <line // vertical line
                      x1={XArrowStart}
                      y1={YArrowStart}
                      x2={XArrowStart}
                      y2={YArrowStart - 73}
                      stroke="#C9D0DA"
                      strokeWidth="2"
                    ></line>
                  </svg>
                ) : (
                  <svg
                    width={canvasRef.current?.scrollWidth}
                    height={canvasRef.current?.scrollHeight}
                  >
                    <line // small vertical line from currentStep to horizontal line
                      x1={XArrowStart}
                      y1={YArrowStart}
                      x2={XArrowStart}
                      y2={YArrowArcStart}
                      stroke="#C9D0DA"
                      strokeWidth="2"
                    ></line>
                    <path
                      d={
                        "M" +
                        XArrowStart +
                        " " +
                        YArrowArcStart +
                        " " +
                        "Q" +
                        controlPointX +
                        " " +
                        controlPointY +
                        " " +
                        XArrowArcEnd +
                        " " +
                        controlPointY
                      }
                      fill="none"
                      stroke="#C9D0DA"
                      strokeWidth="2"
                    ></path>
                    <line // horizontal line
                      x1={XArrowArcEnd}
                      y1={controlPointY}
                      x2={XArrowArcStart}
                      y2={controlPointY}
                      stroke="#C9D0DA"
                      strokeWidth="2"
                    />
                    <path
                      d={
                        "M" +
                        XArrowArcStart +
                        " " +
                        controlPointY +
                        " " +
                        "Q" +
                        controlPointXX +
                        " " +
                        controlPointY +
                        " " +
                        controlPointXX +
                        " " +
                        YArrowArcEnd
                      }
                      fill="none"
                      stroke="#C9D0DA"
                      strokeWidth="2"
                    ></path>
                    <line // vertical line from parentStep to horizonal line
                      x1={controlPointXX}
                      y1={YArrowArcEnd}
                      x2={controlPointXX}
                      y2={YArrowStart - YOffSet * 73 - (YOffSet - 1) * 77}
                      stroke="#C9D0DA"
                      strokeWidth="2"
                    />
                  </svg>
                )}
              </div>
            );
          });
        }

        if (predecessorStep != null || (predecessorStep == null && parentStep == null)) {
          return (
            <div style={{ position: "absolute" }} key={stepID}>
              <div
                style={{
                  position: "absolute",
                  left: `${cardXStart + stepPlacement.xIndex * CARD_X_STEP}px`,
                  top: `${YArrowTipOffset}px`,
                  transition: "all 0.5s",
                }}
              >
                <svg width="360" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    className="card-bottom"
                    d="M 175 50 L 180.773503 40 L 169.226497 40 L 175 50"
                    fill="#C9D0DA"
                  ></path>
                </svg>
              </div>
              <svg width={canvasRef.current?.scrollWidth} height={canvasRef.current?.scrollHeight}>
                <line // vertical line
                  x1={XArrowStart}
                  y1={YArrowStart}
                  x2={XArrowStart}
                  y2={YArrowStart - 73}
                  stroke="#C9D0DA"
                  strokeWidth="2"
                ></line>
              </svg>
            </div>
          );
        }
        return <></>;
      })}
    </div>
  );
};

export default BlueprintCanvasArrows;
