import { useContext, useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import { WebAddressSchemaIntegrationBuilder } from "../../utils/Entities";
import { useHistory } from "react-router-dom";
import { APIEndpointMethod } from "../../../../models/Entities";
import { navigateToIntegrationBuilderWebAddressSchemaTable } from "../../../../router/RouterUtils";
import IntegrationBuilderContext from "../../context/IntegrationBuilderContext";

interface DeleteWebAddressSchemaProps {
  integrationID: string;
  setWebAddressSchemas: React.Dispatch<
    React.SetStateAction<WebAddressSchemaIntegrationBuilder[] | undefined>
  >;
  webAddressSchemaID?: string;
}

const useDeleteWebAddressSchemas = (params: DeleteWebAddressSchemaProps) => {
  // state
  const [isLoadingDeleteWebAddressSchema, setIsLoadingDeleteWebAddressSchema] = useState(false);
  // context
  const history = useHistory();
  const { setIsWebAddressSchemaComplete } = useContext(IntegrationBuilderContext);
  // effects
  const deleteWebAddressSchema = () => {
    setIsLoadingDeleteWebAddressSchema(true);
    fetchWithAuth({
      path: `/integrations/${params.integrationID}/web-address-schemas/${params.webAddressSchemaID}`,
      method: APIEndpointMethod.DELETE,
      onResponse: () => {
        showSuccessToast("Web addresss schema successfully deleted!");
        setIsLoadingDeleteWebAddressSchema(false);
        // navigate to web address schema table page
        navigateToIntegrationBuilderWebAddressSchemaTable(history, params.integrationID);
        // replaces web address schema in webAddressSchemas array with new data
        params.setWebAddressSchemas((prevWebAddressSchemas) => {
          const updatedSchemas = prevWebAddressSchemas?.filter(
            (schema) => schema.id !== params.webAddressSchemaID
          );
          if (updatedSchemas?.length === 0) {
            setIsWebAddressSchemaComplete(false);
          }
          return updatedSchemas;
        });
      },
      onError: () => {
        showErrorToast("Failed to delete your web address schema.");
        setIsLoadingDeleteWebAddressSchema(false);
      },
    });
  };

  return { deleteWebAddressSchema, isLoadingDeleteWebAddressSchema };
};

export default useDeleteWebAddressSchemas;
