import { AbstractCondition } from "../../integration-builder/selective-sync-filters/types";

// Define action types
export type AbstractConditionAction =
  | { type: typeof ADD_CONDITION; payload: { integrationID: string } }
  | { type: typeof DELETE_CONDITION; payload: { index: number } }
  | {
      type: typeof UPDATE_CONDITION;
      payload: { index: number; updatedCondition: AbstractCondition };
    };

// Action Types
export const ADD_CONDITION = "ADD_CONDITION";
export const DELETE_CONDITION = "DELETE_CONDITION";
export const UPDATE_CONDITION = "UPDATE_CONDITION";

// Define the state type
export type AbstractConditionReducerState = AbstractCondition[];

// Helper function to create an empty AbstractCondition
const getEmptyAbstractCondition = (integrationID: string): AbstractCondition => ({
  operator: "",
  value: {
    value: "",
    typed_value: "",
  },
  integration_id: integrationID,
  filter_type_id: "",
});

/**
 * Reducer function for managing the state of Abstract Conditions for Selective Sync in Blueprint Editor
 *
 * @param state - The current AbstractConditionReducerState of abstract conditions
 * @param action - The action to be performed on the state
 * @returns The new AbstractConditionReducerState after applying the action
 */
export const abstractConditionsForSelectiveSyncReducer = (
  state: AbstractConditionReducerState,
  action: AbstractConditionAction
): AbstractConditionReducerState => {
  switch (action.type) {
    case "ADD_CONDITION":
      return [...state, getEmptyAbstractCondition(action.payload.integrationID)];
    case "DELETE_CONDITION":
      return state.filter((_, index) => index !== action.payload.index);
    case "UPDATE_CONDITION":
      return state.map((condition, index) =>
        index === action.payload.index ? action.payload.updatedCondition : condition
      );
    default:
      return state;
  }
};

// Action creators
export const addEmptyAbstractConditionForSelectiveSync = (
  integrationID: string
): AbstractConditionAction => ({
  type: "ADD_CONDITION",
  payload: { integrationID },
});

export const deleteAbstractConditionForSelectiveSync = (
  index: number
): AbstractConditionAction => ({
  type: "DELETE_CONDITION",
  payload: { index },
});

export const updateAbstractConditionForSelectiveSync = (
  index: number,
  updatedCondition: AbstractCondition
): AbstractConditionAction => ({
  type: "UPDATE_CONDITION",
  payload: { index, updatedCondition },
});
