import { ButtonVariant, Dialog, Text } from "@merge-api/merge-javascript-shared";

type Props = {
  show?: boolean;
  onHide: () => void;
  onConfirm?: () => void;
};

export default function PreExitUnsavedChangesModal({ show, onHide, onConfirm }: Props) {
  return (
    <Dialog
      open={show ?? false}
      onClose={onHide}
      title="Unsaved changes"
      variant="md"
      primaryButtonText="Discard changes"
      primaryButtonVariant={ButtonVariant.DangerFilled}
      onPrimaryButtonClick={() => {
        onHide();
        if (onConfirm) {
          onConfirm();
        }
      }}
      secondaryButtonText="Go back"
      onSecondaryButtonClick={onHide}
    >
      <Text>You have unsaved changes. Are you sure you don't want to save?</Text>
    </Dialog>
  );
}
