import { useState } from "react";
import {
  BPRStepIOLogTypeEnum,
  Blueprint,
  BlueprintCanvasBaseStepLogList,
  BlueprintCanvasBaseStepLogsTree,
  BlueprintRunnerExecutionResponse,
} from "../../../../models/Blueprints";
import {
  BlueprintCanvasConfiguration,
  getCanvasConfigurationForLogging,
} from "../../utils/BlueprintCanvasUtils";
import { showWarningToast } from "../../../shared/Toasts";
import { upsertLogIntoBaseStepLogsTree } from "../utils/baseStepLogTreeUtils";
import {
  generateBaseStepLogForList,
  updateCountsForStepIDMap,
  updateIndexesForStepIDMap,
} from "../utils/baseStepLogsListUtils";

/**
 * Hook for managing states used to render step I/O logs in canvas
 * 
 * Example of baseStepLogsTreeForCanvas
 * {
    "get_api_request_loop": {
    "loop_iterations": [],
    "api_requests": [
        {
        "index_in_raw_logs": 0,
        "loop_iterations": [
            {
            "index_in_raw_logs": 1,
            "children_loop_steps": {
                "nested_array_loop": {
                    "loop_iterations": [
                        {
                        "index_in_raw_logs": 4,
                        }
                    ]
                }
                }
            }
        ]
        }
    }
    }
  * Example of baseStepLogsListForCanvas
    [
      {
        "step_id": "get_api_request",
        "step_io_log": {...},
        "iteration_info": {
          "index_in_loop_iterations": null,
          "total_iterations": null,
          "index_in_api_requests": 0,
          "total_api_requests": 1,
          "parent_steps_iteration": []
        },
        "selected_log_info": {
          "index_of_previous_log": null,
          "index_of_parent_log": null,
          "index_of_last_children_step_log": null,
        },
      },
      {
        "step_id": "get_api_request",
        "step_io_log": {...},
        "iteration_info": {
          "index_in_loop_iterations": 0,
          "total_iterations": 1,
          "index_in_api_requests": 0,
          "total_api_requests": 1,
          "parent_steps_iteration": []
        },
        "selected_log_info": {
          "index_of_previous_log": 0,
          "index_of_parent_log": null,
          "index_of_last_children_step_log": 3,
        }
      },
      {
        "step_id": "c_u_employee",
        "step_io_log": {...},
        "iteration_info": {
          "index_in_loop_iterations": undefined,
          "total_iterations": undefined,
          "index_in_api_requests": undefined,
          "total_api_requests": undefined,
          "parent_steps_iteration": [
            {
              "step_id": "get_api_request",
              "index_in_api_requests": 0,
              "index_in_loop_iterations": 0
            }
          ]
        },
      },
      ...
    ]
 */
const useBlueprintCanvasBaseStepLogs = () => {
  // Dictionary of {step log iteration hierarchy : index_in_raw_logs}
  // Helpful for finding loop iteration to navigate to
  const [baseStepLogsTreeForCanvas, setBaseStepLogsTreeForCanvas] = useState<
    BlueprintCanvasBaseStepLogsTree | undefined
  >();
  // List of step logs, appended with hierarchy & iteration info
  const [baseStepLogsListForCanvas, setBaseStepLogsListForCanvas] = useState<
    BlueprintCanvasBaseStepLogList | undefined
  >();

  /**
   * Convert raw step I/O logs to base step log list and tree for canvas
   */
  const generateBaseStepLogsForCanvas = (
    blueprintExecutionResponse: BlueprintRunnerExecutionResponse,
    canvasConfiguration: BlueprintCanvasConfiguration,
    blueprint: Blueprint
  ) => {
    // Try/catch so any errors in computation does not break Blueprint
    try {
      if (
        !blueprintExecutionResponse.exit_data?.debugging_info?.step_io_logging_failed &&
        blueprintExecutionResponse.exit_data?.debugging_info?.step_io_logs
      ) {
        // Compute new canvas info for logging
        const canvasConfigurationForLogging = getCanvasConfigurationForLogging(
          canvasConfiguration,
          blueprint
        );
        // Construct tree
        let stepLogsTree: BlueprintCanvasBaseStepLogsTree = {};
        (blueprintExecutionResponse.exit_data?.debugging_info?.step_io_logs ?? []).forEach(
          (stepIOLog, index) => {
            stepLogsTree = upsertLogIntoBaseStepLogsTree(
              stepLogsTree,
              canvasConfigurationForLogging.nodeIDtoListOfParentNodeIDsMap[stepIOLog.step_id],
              stepIOLog,
              index
            );
          }
        );
        // Construct list
        let stepLogsList: BlueprintCanvasBaseStepLogList = [];
        // Initialize maps. These help the logic fill in certain fields
        let latestLogIndexForStepIDMap: Record<string, number> = {};
        let latestLoopInitializationIndexForStepIDMap: Record<string, number> = {};
        let latestIterationCountForStepIDMap: Record<string, number | undefined> = {};
        let latestAPIRequestCountForStepIDMap: Record<string, number> = {};
        (blueprintExecutionResponse.exit_data?.debugging_info?.step_io_logs ?? []).forEach(
          (stepIOLog, index) => {
            // Update maps
            const stepID = stepIOLog.step_id;
            [
              latestLogIndexForStepIDMap,
              latestLoopInitializationIndexForStepIDMap,
            ] = updateIndexesForStepIDMap(
              stepID,
              stepIOLog,
              index,
              canvasConfigurationForLogging,
              latestLogIndexForStepIDMap,
              latestLoopInitializationIndexForStepIDMap
            );
            [
              latestIterationCountForStepIDMap,
              latestAPIRequestCountForStepIDMap,
            ] = updateCountsForStepIDMap(
              stepIOLog,
              latestIterationCountForStepIDMap,
              latestAPIRequestCountForStepIDMap,
              canvasConfigurationForLogging
            );
            // Construct initial list
            stepLogsList.push(
              generateBaseStepLogForList(
                stepIOLog,
                canvasConfigurationForLogging,
                latestLogIndexForStepIDMap,
                latestIterationCountForStepIDMap,
                latestAPIRequestCountForStepIDMap,
                latestLoopInitializationIndexForStepIDMap,
                stepLogsTree
              )
            );
            // Make incremental updates to certain fields
            const parentStepID = canvasConfigurationForLogging.nodeIDtoParentNodeIDMap[stepID];
            if (parentStepID) {
              // Incrementally update "index_of_last_children_step_log" for log of parent step
              stepLogsList[
                latestLogIndexForStepIDMap[parentStepID]
              ].selected_log_info.index_of_last_children_step_log = stepLogsList.length - 1;
            }
            // Incrementally update "index_of_loop_latest_iteration_log" for loop initialization log
            if (
              stepIOLog.log_type == BPRStepIOLogTypeEnum.LOOP_ITERATION &&
              latestLoopInitializationIndexForStepIDMap[stepID] !== undefined
            ) {
              stepLogsList[
                latestLoopInitializationIndexForStepIDMap[stepID]
              ].selected_log_info.index_of_loop_latest_iteration_log = stepLogsList.length - 1;
            }
          }
        );

        setBaseStepLogsTreeForCanvas(stepLogsTree);
        setBaseStepLogsListForCanvas(stepLogsList);
      }
    } catch (error) {
      showWarningToast(`Error rendering step I/O in canvas`);
      console.error(error);
    }
  };

  /**
   * Reset base canvas step logs
   * Used when blueprintRunnerExecutionResponse is cleaned up
   */
  const resetBaseStepLogsForCanvas = () => {
    setBaseStepLogsTreeForCanvas(undefined);
    setBaseStepLogsListForCanvas(undefined);
  };

  return {
    baseStepLogsTreeForCanvas,
    baseStepLogsListForCanvas,
    generateBaseStepLogsForCanvas,
    resetBaseStepLogsForCanvas,
  };
};

export default useBlueprintCanvasBaseStepLogs;
