import { Accordion, Button, Text, TextField, Typeahead } from "@merge-api/merge-javascript-shared";
import { Search, WandSparkles } from "lucide-react";
import { useEffect, useState } from "react";
import {
  APIEndpointPreviewIntegrationBuilder,
  APIEndpointPreviewStatus,
  AutogenerationRunNotificationStatus,
} from "../../utils/Entities";
import { fetchWithAuth } from "../../../../api-client/api_client";
import MergeTable from "../../../shared/MergeTable";
import { showErrorToast } from "../../../shared/Toasts";
import APIEndpointsGeneratorTableRow from "./APIEndpointsGeneratorTableRow";
import { APIEndpointMethod } from "../../../../models/Entities";

interface APIEndpointsGeneratorProps {
  pendingAPIEndpointPreviews: APIEndpointPreviewIntegrationBuilder[] | undefined;
  setAPIEndpointPreviews: React.Dispatch<
    React.SetStateAction<APIEndpointPreviewIntegrationBuilder[] | undefined>
  >;
  integrationID: string;
  fetchAPIEndpointPreviews: (integrationID: string) => void;
  setIsGeneratingListExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const APIEndpointsGenerator = ({
  pendingAPIEndpointPreviews,
  setAPIEndpointPreviews,
  integrationID,
  setIsGeneratingListExpanded,
}: APIEndpointsGeneratorProps) => {
  const [filteredAPIEndpointPreviews, setFilteredAPIEndpointPreviews] = useState<
    APIEndpointPreviewIntegrationBuilder[] | undefined
  >(pendingAPIEndpointPreviews);
  const [selectedPreviewIDs, setSelectedPreviewIDs] = useState<string[]>([]);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [selectedMethods, setSelectedMethods] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  const handleGenerate = () => {
    // Set status to processing of the api endpoint preview to make sure it gets sent to the generating table
    // We also want to check for any existing autogen runs and set the notification status to RETRIED to make sure it gets displayed as generating
    setAPIEndpointPreviews((prev: APIEndpointPreviewIntegrationBuilder[] | undefined) => {
      return prev?.map((preview) => {
        if (preview.id && selectedPreviewIDs.includes(preview.id)) {
          preview.status = APIEndpointPreviewStatus.PROCESSING;
          if (preview.autogen_runs && preview.autogen_runs.length) {
            preview.autogen_runs[0].notification_status =
              AutogenerationRunNotificationStatus.RETRIED;
          }
        }
        return preview;
      });
    });
    setSelectedPreviewIDs([]);
    setIsExpanded(false);
    setIsGeneratingListExpanded(true);
    fetchWithAuth({
      path: `/integrations/integration-builder/integration/${integrationID}/autogenerated-api-endpoints`,
      method: "POST",
      body: { api_endpoint_preview_ids: selectedPreviewIDs },
      onResponse: () => {},
      onError: (error) => {
        error
          ?.json()
          .then((value: string | Record<string, any>) => {
            let errorMessage = "";
            if (typeof value == "string") {
              errorMessage = value;
            } else {
              errorMessage = value?.non_field_errors;
            }
            showErrorToast(
              `Failed initiate API Endpoints generation. Error message: \n ${errorMessage}`
            );
          })
          .catch(() => {
            showErrorToast("Failed initiate API Endpoints generation.");
          });
      },
    });
  };

  /**
   * This effect is responsible for filtering the API endpoint previews based on the selected methods and search value
   *
   * The search value will filter the API endpoint previews based on the name of the endpoint and the path
   *
   * If no filters are applied, it will display all the API endpoint previews
   */
  useEffect(() => {
    if (!selectedMethods.length && searchValue === "") {
      setFilteredAPIEndpointPreviews(pendingAPIEndpointPreviews);
    }

    let filteredPreviews = pendingAPIEndpointPreviews;

    if (selectedMethods.length) {
      filteredPreviews = filteredPreviews?.filter((preview) =>
        selectedMethods.includes(preview.method)
      );
    }

    if (searchValue) {
      filteredPreviews = filteredPreviews?.filter(
        (preview) =>
          preview.path.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
          preview.name.toLowerCase().includes(searchValue.toLocaleLowerCase())
      );
    }

    setFilteredAPIEndpointPreviews(filteredPreviews);
  }, [selectedMethods, searchValue, pendingAPIEndpointPreviews]);

  return (
    <Accordion
      title={
        <div className="flex items-center justify-between w-[100%]">
          <div className="flex items-center flex-row">
            <WandSparkles size={16} className="mr-3 text-black" />
            <Text variant="h5" className="flex items-center text-black">
              Generate endpoints with AI
            </Text>
          </div>
          <div className="flex items-center flex-row">
            <Text className="text-black">{`${
              selectedPreviewIDs?.length ?? 0
            } endpoints selected`}</Text>
            <Button
              size="sm"
              className="mx-6"
              leftIcon={<WandSparkles size={12} />}
              onClick={(e) => {
                e.stopPropagation();
                handleGenerate();
              }}
              disabled={selectedPreviewIDs.length === 0}
            >
              {"Generate"}
            </Button>
          </div>
        </div>
      }
      variant="shadow"
      defaultExpanded={false}
      expanded={isExpanded}
      setExpanded={setIsExpanded}
      titlePosition="bottom"
      chevronOrientation="up"
      className="rounded-lg min-w-[600px] overflow-auto border-[1px] border-gray-10 bg-white"
      titleClassName="px-5 py-4 text-white h-12"
    >
      <div className="relative h-[60vh]">
        <div className="absolute top-0 left-0 right-0 z-10 pt-5 pb-4 px-5 bg-white">
          <div className="flex flex-row items-center space-x-4">
            <div className="w-[400px]">
              <Typeahead
                options={Object.values(APIEndpointMethod)}
                placeholder="Method"
                onChange={(_, selectedOption: string[] | null) =>
                  setSelectedMethods(selectedOption ?? [])
                }
                multiple
                hideExcessTagsOnFocus
                hidePlaceholderWithTags
                showPlusFormat
                renderBadge={(option) => (
                  <Text variant="sm">{`${option.tag}${
                    option.dataTagIndex < selectedMethods.length - 1 ? "," : ""
                  }`}</Text>
                )}
                disableCloseOnSelect
                tagsLimit={2}
              />
            </div>
            <TextField
              prefix={<Search className="ml-2" size={16} />}
              placeholder="Search for an endpoint..."
              className="text-black w-full"
              inputWrapperClassName="rounded-md"
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="overflow-y-auto h-full bg-white">
          <MergeTable
            className="text-white w-full relative mt-[76px] px-5"
            hover={filteredAPIEndpointPreviews?.length ? true : false}
            borderBottom="1px solid rgba(255, 255, 255, 0.15)"
            isOverviewPage
            header={
              <>
                <th scope="col">
                  <Text variant="h6">Name</Text>
                </th>
                <th scope="col">
                  <Text variant="h6">Method</Text>
                </th>
                <th scope="col">
                  <Text variant="h6">Path</Text>
                </th>
                <th scope="col">
                  <Text variant="h6">Description</Text>
                </th>
              </>
            }
            content={
              filteredAPIEndpointPreviews?.length ? (
                filteredAPIEndpointPreviews?.map((apiEndpointPreview) => (
                  <APIEndpointsGeneratorTableRow
                    key={apiEndpointPreview.id}
                    setSelectedPreviewIDs={setSelectedPreviewIDs}
                    endpointPreview={apiEndpointPreview}
                  />
                ))
              ) : (
                <tr className="border-none">
                  <td colSpan={4} className="border-none">
                    <div className="flex items-center justify-center h-64">
                      <Text variant="h4">No results</Text>
                    </div>
                  </td>
                </tr>
              )
            }
          />
        </div>
      </div>
    </Accordion>
  );
};

export default APIEndpointsGenerator;
