import { Row } from "../../../shared/postman-table/PostmanTableRow";
import BasicAuthWithTokenExchangeRequestCard from "./BasicAuthWithTokenExchangeRequestCard";
import BasicAuthWithTokenExchangeResponseCard from "./BasicAuthWithTokenExchangeResponseCard";
import AccordionCardForSection from "../../../shared/AccordionCardForSection";

interface BasicAuthWithTokenExchangeProps {
  // request
  basicAuthExchangeURL: string | undefined;
  setBasicAuthExchangeURL: React.Dispatch<React.SetStateAction<string | undefined>>;
  refreshTokenHeaders: Row[];
  setRefreshTokenHeaders: React.Dispatch<React.SetStateAction<Row[]>>;
  isSoapIntegration: boolean;
  refreshTokenBodyParams: Row[];
  setRefreshTokenBodyParams: React.Dispatch<React.SetStateAction<Row[]>>;
  refreshTokenStringBodyParams: string | undefined;
  setRefreshTokenStringBodyParams: React.Dispatch<React.SetStateAction<string | undefined>>;
  shouldConvertBodyDataToString: boolean | undefined;
  setShouldConvertBodyDataToString: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  // response
  basicAuthKeyTokenExchangeResponseAuthKeyPath: string[] | null;
  setBasicAuthKeyTokenExchangeResponseAuthKeyPath: React.Dispatch<
    React.SetStateAction<string[] | null>
  >;
  isTokenLifespanInResponse: boolean | undefined;
  setIsTokenLifespanInResponse: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  basicAuthKeyTokenExchangeAccessTokenLifespanInSeconds: number | undefined;
  setBasicAuthKeyTokenExchangeAccessTokenLifespanInSeconds: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  basicAuthKeyTokenAccessTokenLifespanKeyPath: string[] | null;
  setBasicAuthKeyTokenAccessTokenLifespanKeyPath: React.Dispatch<
    React.SetStateAction<string[] | null>
  >;
  hasPermissionToEdit: boolean;
}

const BasicAuthWithTokenExchange = ({
  //request
  basicAuthExchangeURL,
  setBasicAuthExchangeURL,
  refreshTokenHeaders,
  setRefreshTokenHeaders,
  isSoapIntegration,
  refreshTokenBodyParams,
  setRefreshTokenBodyParams,
  refreshTokenStringBodyParams,
  setRefreshTokenStringBodyParams,
  shouldConvertBodyDataToString,
  setShouldConvertBodyDataToString,
  //response
  basicAuthKeyTokenExchangeResponseAuthKeyPath,
  setBasicAuthKeyTokenExchangeResponseAuthKeyPath,
  isTokenLifespanInResponse,
  setIsTokenLifespanInResponse,
  basicAuthKeyTokenExchangeAccessTokenLifespanInSeconds,
  setBasicAuthKeyTokenExchangeAccessTokenLifespanInSeconds,
  basicAuthKeyTokenAccessTokenLifespanKeyPath,
  setBasicAuthKeyTokenAccessTokenLifespanKeyPath,
  hasPermissionToEdit,
}: BasicAuthWithTokenExchangeProps) => {
  return (
    <AccordionCardForSection
      dataTestID="accordion-auth-config-basic-auth-with-token-exchange"
      title="Token exchange configuration"
      defaultExpanded
      hasPermissionToEdit={hasPermissionToEdit}
    >
      <BasicAuthWithTokenExchangeRequestCard
        basicAuthExchangeURL={basicAuthExchangeURL}
        setBasicAuthExchangeURL={setBasicAuthExchangeURL}
        refreshTokenHeaders={refreshTokenHeaders}
        setRefreshTokenHeaders={setRefreshTokenHeaders}
        isSoapIntegration={isSoapIntegration}
        refreshTokenBodyParams={refreshTokenBodyParams}
        setRefreshTokenBodyParams={setRefreshTokenBodyParams}
        refreshTokenStringBodyParams={refreshTokenStringBodyParams}
        setRefreshTokenStringBodyParams={setRefreshTokenStringBodyParams}
        shouldConvertBodyDataToString={shouldConvertBodyDataToString}
        setShouldConvertBodyDataToString={setShouldConvertBodyDataToString}
      />
      <BasicAuthWithTokenExchangeResponseCard
        basicAuthKeyTokenExchangeResponseAuthKeyPath={basicAuthKeyTokenExchangeResponseAuthKeyPath}
        setBasicAuthKeyTokenExchangeResponseAuthKeyPath={
          setBasicAuthKeyTokenExchangeResponseAuthKeyPath
        }
        isTokenLifespanInResponse={isTokenLifespanInResponse}
        setIsTokenLifespanInResponse={setIsTokenLifespanInResponse}
        basicAuthKeyTokenExchangeAccessTokenLifespanInSeconds={
          basicAuthKeyTokenExchangeAccessTokenLifespanInSeconds
        }
        setBasicAuthKeyTokenExchangeAccessTokenLifespanInSeconds={
          setBasicAuthKeyTokenExchangeAccessTokenLifespanInSeconds
        }
        basicAuthKeyTokenAccessTokenLifespanKeyPath={basicAuthKeyTokenAccessTokenLifespanKeyPath}
        setBasicAuthKeyTokenAccessTokenLifespanKeyPath={
          setBasicAuthKeyTokenAccessTokenLifespanKeyPath
        }
      />
    </AccordionCardForSection>
  );
};

export default BasicAuthWithTokenExchange;
