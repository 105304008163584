import { ButtonVariant, Dialog, Link, Text } from "@merge-api/merge-javascript-shared";
import { useCustomerLinkedAccountContext } from "../context/CustomerLinkedAccountAccessContext";
import { useHistory } from "react-router-dom";
import { navigateToIntegration } from "../../../router/RouterUtils";

const WithdrawAccessRequestDialog = () => {
  const history = useHistory();
  const {
    currentAccessRequest,
    showWithdrawRequestModal,
    setShowWithdrawRequestModal,
    withdrawLinkedAccountAccessRequest,
  } = useCustomerLinkedAccountContext();

  // Render a link to the associated integration if it exists
  const renderIntegrationName = () => {
    if (currentAccessRequest?.integration_name && currentAccessRequest?.integration_id) {
      return (
        <Link onClick={() => navigateToIntegration(history, currentAccessRequest.integration_id)}>
          {currentAccessRequest.integration_name}
        </Link>
      );
    } else {
      return "another integration";
    }
  };

  const handlePrimaryButtonClick = () => {
    withdrawLinkedAccountAccessRequest();
    setShowWithdrawRequestModal(false);
  };

  return (
    <Dialog
      title="Outstanding Request"
      open={showWithdrawRequestModal}
      onClose={() => setShowWithdrawRequestModal(false)}
      variant="sm"
      primaryButtonVariant={ButtonVariant.DangerFilled}
      onPrimaryButtonClick={handlePrimaryButtonClick}
      primaryButtonText="Clear request"
    >
      <Text>
        You already requested a customer Linked Account for {renderIntegrationName()}. You can clear
        your request to start a new one or wait until the session is over.
      </Text>
    </Dialog>
  );
};

export default WithdrawAccessRequestDialog;
