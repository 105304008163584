import { Select } from "@merge-api/merge-javascript-shared";
import { JSXElementConstructor, ReactElement } from "react";
import HeaderBase from "./HeaderBase";
import useIntegrationBuilderContext from "../context/useIntegrationBuilderContext";

interface Props<TValue> {
  title: string;
  subtitle?: string;
  learnMoreText?: string;
  options: TValue[];
  onChange: (
    event:
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
      | React.FocusEvent<Element, Element>
      | null,
    value: TValue | null
  ) => void;
  value?: TValue | null;
  disabled?: boolean;
  clearable?: boolean;
  className?: string;
  renderOption?: (option: TValue) => ReactElement<unknown, string | JSXElementConstructor<unknown>>;
  renderValue?: (option: TValue) => ReactElement<unknown, string | JSXElementConstructor<unknown>>;
  hasSource?: boolean;
  required?: boolean;
  dataTestID?: string;
}

function SelectHeader<TValue>({
  title,
  subtitle,
  learnMoreText,
  options,
  renderOption,
  renderValue,
  onChange,
  value,
  className,
  disabled = false,
  clearable = true,
  hasSource = false,
  required = false,
  dataTestID,
}: Props<TValue>) {
  const { hasPermissionToEdit } = useIntegrationBuilderContext();

  return (
    <div className={className}>
      <HeaderBase
        title={title}
        subtitle={subtitle}
        hasSource={hasSource}
        required={required}
        learnMoreText={learnMoreText}
        dataTestID={dataTestID}
      />
      <Select
        options={options}
        renderOption={renderOption}
        renderValue={renderValue}
        onChange={onChange}
        value={value}
        disabled={!hasPermissionToEdit || disabled}
        clearable={clearable}
      />
    </div>
  );
}

export default SelectHeader;
