import { useParams } from "react-router-dom";
import { WebAddressSchemaIntegrationBuilder } from "../../utils/Entities";
import { useEffect, useState } from "react";
import WebAddressSchemaSetupOptions from "./WebAddresSchemaSetupOptions";
import WebAddressSchemaHeader from "./WebAddressSchemaHeader";
import SchemaExplainAlert from "../shared/SchemaExplainAlert";

type RouteParams = {
  webAddressSchemaID: string | undefined;
};

interface WebAddressSchemaSetupProps {
  integrationID: string;
  isNewWebAddressSchema: boolean;
  webAddressSchemas: WebAddressSchemaIntegrationBuilder[] | undefined;
  setWebAddressSchemas: React.Dispatch<
    React.SetStateAction<WebAddressSchemaIntegrationBuilder[] | undefined>
  >;
}

const WebAddressSchemaSetup = ({
  integrationID,
  isNewWebAddressSchema,
  webAddressSchemas,
  setWebAddressSchemas,
}: WebAddressSchemaSetupProps) => {
  // params
  const { webAddressSchemaID } = useParams<RouteParams>();

  // const derived from state
  const selectedWebAddressSchema = webAddressSchemas?.find(
    (schema) => schema.id === webAddressSchemaID
  );

  // state
  const [isLoadingWebAddressSchemas, setIsLoadingWebAddressSchemas] = useState(true);

  // hook to wait for loading of auth config before mounting component
  useEffect(() => {
    if (webAddressSchemas) {
      setIsLoadingWebAddressSchemas(false);
    }
  }, [webAddressSchemas]);

  return (
    <div>
      <WebAddressSchemaHeader
        isNewWebAddressSchema={isNewWebAddressSchema}
        integrationID={integrationID}
        setWebAddressSchemas={setWebAddressSchemas}
        selectedWebAddressSchema={selectedWebAddressSchema}
      />
      <SchemaExplainAlert />
      {!isLoadingWebAddressSchemas && (
        <WebAddressSchemaSetupOptions
          integrationID={integrationID}
          isNewWebAddressSchema={isNewWebAddressSchema}
          selectedWebAddressSchema={selectedWebAddressSchema}
          setWebAddressSchemas={setWebAddressSchemas}
        />
      )}
    </div>
  );
};

export default WebAddressSchemaSetup;
