import { useCallback, useRef } from "react";
import { fetchWithAuth } from "../../../../../api-client/api_client";
import { WebConnectorSession, WebConnectorSessionStatus } from "../../../../../models/Blueprints";

interface UsePollWebConnectorSessionStatusProps {
  setWebConnectorSessionResponse: React.Dispatch<React.SetStateAction<WebConnectorSession | null>>;
  onFinalStateReached: (data: WebConnectorSession) => void;
}

export const usePollWebConnectorSessionStatus = ({
  setWebConnectorSessionResponse,
  onFinalStateReached,
}: UsePollWebConnectorSessionStatusProps) => {
  const intervalId = useRef<NodeJS.Timeout | null>(null);

  const WebConnectorFinalState = [
    WebConnectorSessionStatus.SUCCEEDED,
    WebConnectorSessionStatus.FAILED,
    WebConnectorSessionStatus.ABORTED,
  ];

  const pollWebConnectorSessionStatus = useCallback(
    (webConnectorSessionId: string) => {
      const poll = () => {
        fetchWithAuth({
          path: `/blueprints/web-connector-session/${webConnectorSessionId}`,
          method: "GET",
          onResponse: (data: WebConnectorSession) => {
            setWebConnectorSessionResponse(data);

            // check if the session status is in one of the final states, e.g. succeeded, failed or aborted. if so stop polling
            if (data.status && WebConnectorFinalState.includes(data.status)) {
              // stop polling
              if (intervalId.current !== null) {
                clearInterval(intervalId.current);
                intervalId.current = null;
              }
              // callback for final state reached
              onFinalStateReached(data);
            }
          },
          onError: () => {},
        });
      };

      // initial call to poll, and poll every 5 seconds
      poll();
      intervalId.current = setInterval(poll, 5000);

      return () => {
        if (intervalId.current !== null) {
          clearInterval(intervalId.current);
        }
      };
    },
    [setWebConnectorSessionResponse, onFinalStateReached]
  );

  return {
    pollWebConnectorSessionStatus,
  };
};
