import InputFormField from "../blueprint-editor/right-panel/InputFormField";
import DeprecatedH3 from "../deprecated/DeprecatedH3";
import { ScraperElementSelectorValue } from "./types";

type Props = {
  value: ScraperElementSelectorValue;
  updateValue: (value: ScraperElementSelectorValue) => void;
};
const ScraperElementSelectorField = ({ value, updateValue }: Props) => {
  const { tag, selector, outerHTML, text } = value;

  const updateField = (key: string, newValue: any) => {
    updateValue({ ...value, [key]: newValue });
  };

  return (
    <div>
      <DeprecatedH3> Element Selector</DeprecatedH3>
      <hr />
      {outerHTML}
      <InputFormField
        onChange={(newValue) => updateField("tag", newValue)}
        currentValue={tag}
        title="Tag"
        subtitle="The HTML tag type of the element to select."
      />
      <InputFormField
        onChange={(newValue) => updateField("text", newValue)}
        currentValue={text}
        title="Text"
        subtitle={`The text to match. `}
      />
      <InputFormField
        onChange={(newValue) => updateField("selector", newValue)}
        currentValue={selector}
        title="Selector"
        subtitle={`The selector to match. `}
      />
      <hr />
    </div>
  );
};

export default ScraperElementSelectorField;
