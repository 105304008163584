import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { Clock } from "lucide-react";
import {
  BlueprintTrigger,
  BlueprintTriggerIntervalUnit,
  BlueprintTriggerSchedule,
  BlueprintTriggerSpeed,
} from "../../../models/Blueprints";
import { firstLetterUpperCase } from "../../../utils";
import useBlueprintContext from "../context/useBlueprintContext";
import Dropdown from "./Dropdown";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";

const BlueprintEditorRightPanelTriggerForm = () => {
  const { blueprintTrigger, setBlueprintTrigger } = useBlueprintContext();
  const {
    slow_trigger_schedule,
    intermediate_trigger_schedule,
    fast_trigger_schedule,
  } = blueprintTrigger as BlueprintTrigger;

  const [slowSchedule, setSlowSchedule] = useState<BlueprintTriggerSchedule>(slow_trigger_schedule);
  const [intermediateSchedule, setIntermediateSchedule] = useState<BlueprintTriggerSchedule>(
    intermediate_trigger_schedule
  );
  const [fastSchedule, setFastSchedule] = useState<BlueprintTriggerSchedule>(fast_trigger_schedule);

  useEffect(() => {
    setBlueprintTrigger({
      ...(blueprintTrigger as BlueprintTrigger),
      slow_trigger_schedule: slowSchedule,
      intermediate_trigger_schedule: intermediateSchedule,
      fast_trigger_schedule: fastSchedule,
    });
  }, [slowSchedule, intermediateSchedule, fastSchedule]);

  return (
    <div className="px-3 flex-grow-1">
      <BlueprintTriggerSpeedForm
        speed={BlueprintTriggerSpeed.BLUEPRINT_SPEED_SLOW}
        schedule={slowSchedule}
        setSchedule={setSlowSchedule}
      />
      <BlueprintTriggerSpeedForm
        speed={BlueprintTriggerSpeed.BLUEPRINT_SPEED_INTERMEDIATE}
        schedule={intermediateSchedule}
        setSchedule={setIntermediateSchedule}
      />
      <BlueprintTriggerSpeedForm
        speed={BlueprintTriggerSpeed.BLUEPRINT_SPEED_FAST}
        schedule={fastSchedule}
        setSchedule={setFastSchedule}
      />
    </div>
  );
};

interface speedFormProps {
  speed: BlueprintTriggerSpeed;
  schedule: BlueprintTriggerSchedule | undefined;
  setSchedule: (schedule: BlueprintTriggerSchedule) => void;
}

const BlueprintTriggerSpeedForm = (props: speedFormProps): JSX.Element => {
  const {
    schedule_interval_value,
    schedule_interval_unit,
  } = props.schedule as BlueprintTriggerSchedule;
  const [unsavedValue, setUnsavedValue] = useState(schedule_interval_value?.toString());

  return (
    <div className="p-3">
      <Row className="align-items-center">
        <Clock size={16} />
        <DeprecatedH4 className="ml-1.5 mb-0"> {firstLetterUpperCase(props.speed)}</DeprecatedH4>
      </Row>
      <Row className="mt-6 align-items-center">
        <DeprecatedH4 className="mb-0 text-muted"> Every </DeprecatedH4>
        <input
          className="form-control ml-3 trigger-input-value"
          onChange={(e) => setUnsavedValue(e.target.value)}
          onBlur={() => {
            const amt = Number(unsavedValue);
            if (!isNaN(amt)) {
              props.setSchedule({
                ...(props.schedule as BlueprintTriggerSchedule),
                schedule_interval_value: amt,
              });
            }
          }}
          value={unsavedValue ?? undefined}
        />
        <div className="ml-3 trigger-dropdown-unit">
          <Dropdown
            currentValue={schedule_interval_unit}
            onChange={(e) => {
              props.setSchedule({
                ...(props.schedule as BlueprintTriggerSchedule),
                schedule_interval_unit: e.target.value,
              });
            }}
            placeholder=""
            choices={Object.values(BlueprintTriggerIntervalUnit).map((unit) => ({
              name: unit,
              id: unit,
            }))}
          />
        </div>
      </Row>
    </div>
  );
};

export default BlueprintEditorRightPanelTriggerForm;
