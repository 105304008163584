import isEqual from "lodash/isEqual";
import { Fragment, memo } from "react";
import { BlueprintOperationType } from "../../../models/Blueprints";
import {
  MappingTestCommonModel,
  MappingTestCommonModelExpectedMappings,
  MappingTestExpectedMappings,
  MappingTestExecution,
} from "../../../models/MappingTests";
import MappingTestEditorCommonModelExpectedMappings from "./MappingTestEditorCommonModelExpectedMappings";

type Props = {
  availableRelationLookupDict: { [commonModelID: string]: Array<string> };
  commonModels: { [commonModelID: string]: MappingTestCommonModel };
  mappings: MappingTestExpectedMappings;
  testExecutionResults: undefined | MappingTestExecution;
  commonModelCountAssertions: { [commonModelID: string]: number };
  operationType: BlueprintOperationType;
  setCommonModelCountAssertion: (commonModelID: string, count: number) => void;
  updateCommonModelExpectedMappings: (
    commonModelID: string,
    commonModelExpectedMappings: undefined | MappingTestCommonModelExpectedMappings
  ) => void;
  updateRelationName: (commonModelID: string, oldName: string, newName: string) => void;
  isRunningMappingTest: boolean;
};

const MappingTestEditorExpectedMappings = ({
  availableRelationLookupDict,
  commonModels,
  mappings,
  commonModelCountAssertions,
  operationType,
  setCommonModelCountAssertion,
  updateCommonModelExpectedMappings,
  updateRelationName,
  testExecutionResults,
  isRunningMappingTest,
}: Props) => {
  return (
    <div className="px-5 pb-5">
      {Object.entries(commonModels)
        .sort(function ([_modelID1, model1], [_modelID2, model2]) {
          return model1?.name.localeCompare(model2?.name);
        })
        .map(([commonModelID, commonModel]) => {
          const mappingMissesForCommonModel = testExecutionResults?.mapping_misses?.[commonModelID];

          return (
            <Fragment key={commonModel.id}>
              <MappingTestEditorCommonModelExpectedMappings
                commonModel={commonModel}
                mappingMissesForCommonModel={mappingMissesForCommonModel}
                mappingTestCommonModelObject={mappings[commonModelID]}
                commonModelID={commonModelID}
                commonModelCountMetadata={
                  testExecutionResults?.common_model_count_metadata?.[commonModelID]
                }
                commonModelCountAssertion={commonModelCountAssertions?.[commonModelID]}
                operationType={operationType}
                setCommonModelCountAssertion={setCommonModelCountAssertion}
                updateCommonModelExpectedMappings={updateCommonModelExpectedMappings}
                availableRelationLookupDict={availableRelationLookupDict}
                updateRelationName={updateRelationName}
                isRunningMappingTest={isRunningMappingTest}
              />
            </Fragment>
          );
        })}
    </div>
  );
};

export default memo(MappingTestEditorExpectedMappings, isEqual);
