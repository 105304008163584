import { Alert, Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import { ChecklistStatusValue, FIELD_NAME_TO_WARNING_TEXT } from "../../../../constants";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { useState } from "react";
import { CHECKLIST_VALUE_DISPLAY_DETAILS } from "../constants";

type Props = {
  checklistItem: string;
  checklistValue: ChecklistStatusValue;
  integrationID: string;
  fetchIntegrationChecklist: () => void;
};

const IntegrationChecklistIssueV2 = ({
  checklistItem,
  checklistValue,
  integrationID,
  fetchIntegrationChecklist,
}: Props) => {
  const [isSupported, setIsSupported] = useState<boolean>(
    checklistValue !== ChecklistStatusValue.NOT_SUPPORTED
  );

  const changeSupportStatus = (status: boolean) => {
    if (!status) {
      fetchWithAuth({
        path: `/integrations/${integrationID}/integration-checklist/mark-unsupported`,
        method: "POST",
        body: {
          field_name: checklistItem,
        },
        onResponse: () => {
          fetchIntegrationChecklist();
        },
        onError: () => {},
      });
    } else {
      fetchWithAuth({
        path: `/integrations/${integrationID}/integration-checklist/mark-supported`,
        method: "POST",
        body: {
          field_name: checklistItem,
        },
        onResponse: () => {
          fetchIntegrationChecklist();
        },
        onError: () => {},
      });
    }
  };

  const checklistDetails = CHECKLIST_VALUE_DISPLAY_DETAILS[checklistValue];

  return (
    //TODO: @ken add links to integration builder pages
    <Alert
      className="py-4 px-5"
      color={checklistDetails?.alertColor}
      size="sm"
      icon={checklistDetails?.icon}
      title={
        <div className="flex w-full flex-row items-center justify-between">
          <Text className="text-black">
            {/* @ts-ignore it does not understand I am mapping keys of IntegrationChecklistData for some reason */}
            {FIELD_NAME_TO_WARNING_TEXT[checklistItem] ?? checklistItem}
          </Text>
          {checklistValue === ChecklistStatusValue.NOT_SUPPORTED ||
          checklistValue === ChecklistStatusValue.NOT_IMPLEMENTED ? (
            <div className="flex flex-row items-center">
              <Button
                size="sm"
                variant={ButtonVariant.TertiaryWhite}
                onClick={() => {
                  changeSupportStatus(!isSupported);
                  setIsSupported(!isSupported);
                }}
              >
                {checklistValue === ChecklistStatusValue.NOT_SUPPORTED
                  ? "Mark as supported"
                  : "Mark as unsupported"}
              </Button>
            </div>
          ) : null}
        </div>
      }
    />
  );
};

export default IntegrationChecklistIssueV2;
