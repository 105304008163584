import { Text, Accordion, Badge } from "@merge-api/merge-javascript-shared";
import StatusIcon from "../../StatusIcon";
import {
  IntegrationTestSuiteExecutionStatus,
  LinkedAccountTestSuiteExecutionStatus,
  MappingTestExecution,
  MappingTestExecutionResult,
  MappingTestSuiteEndpointCollectionInfo,
} from "../../../../../../models/MappingTests";
import { NextComponentVersionState } from "../../../../versioned-components/types";
import { getEndpointCollectionTitle } from "../../../../utils/MappingTestUtils";
import MappingTestExecutionFailureCard from "./MappingTestExecution/MappingTestExecutionFailureCard";

const EndpointCollectionContainer = ({
  endpointCollection,
  mappingTestExecutions,
  testExecutionStatus,
  isGrouped,
  isLastElement,
}: {
  endpointCollection: MappingTestSuiteEndpointCollectionInfo;
  mappingTestExecutions: Array<MappingTestExecution>;
  testExecutionStatus: LinkedAccountTestSuiteExecutionStatus;
  isGrouped?: boolean;
  isLastElement?: boolean;
}) => {
  const hasStagedTestCases = (
    endpointCollection: MappingTestSuiteEndpointCollectionInfo
  ): boolean => {
    return endpointCollection.test_cases.some(
      (testCase) => testCase.next_version_state === NextComponentVersionState.STAGED_WITH_CHANGES
    );
  };

  const endpointCollectionHasFailedExecution = () =>
    mappingTestExecutions.some(
      (execution) => execution.result == MappingTestExecutionResult.FAILURE
    );

  return (
    <div className={`${!isGrouped && "mb-5"}`}>
      <Accordion
        chevronSize={12}
        chevronOrientation="right"
        variant={isGrouped ? "none" : "outline"}
        onChange={function noRefCheck() {}}
        title={
          <div className="flex flex-row items-center justify-between w-full">
            <div className="flex flex-row items-center space-x-3">
              <div className="w-fit h-fit">
                <StatusIcon
                  status={
                    testExecutionStatus === LinkedAccountTestSuiteExecutionStatus.IN_PROGRESS
                      ? LinkedAccountTestSuiteExecutionStatus.IN_PROGRESS
                      : mappingTestExecutions.length === 0
                      ? IntegrationTestSuiteExecutionStatus.NOT_RUN
                      : mappingTestExecutions.some(
                          (execution) => execution.result !== MappingTestExecutionResult.SUCCESS
                        )
                      ? MappingTestExecutionResult.FAILURE
                      : MappingTestExecutionResult.SUCCESS
                  }
                />
              </div>
              <Text variant="h6" className="text-black normal-case">
                {getEndpointCollectionTitle(endpointCollection)}
              </Text>
            </div>
            <div>
              {hasStagedTestCases(endpointCollection) && !endpointCollectionHasFailedExecution() && (
                <Badge className="mr-1" color="gray">
                  Staged
                </Badge>
              )}
            </div>
          </div>
        }
        titleClassName="px-5 py-4"
      >
        {" "}
        {/* We only want to show individual test cases if one of them failed */}
        {endpointCollectionHasFailedExecution() ? (
          <>
            <hr className="m-0 text-gray-50" />
            {(endpointCollection?.test_cases ?? []).map((mappingTest) => {
              const testExecution = mappingTestExecutions.find(
                (execution) => execution?.mapping_test_id == mappingTest?.id
              );
              return testExecution ? (
                <MappingTestExecutionFailureCard
                  mappingTest={mappingTest}
                  testExecution={testExecution}
                />
              ) : null;
            })}
          </>
        ) : (
          <></>
        )}
      </Accordion>
      {isGrouped && isLastElement === false && <hr className="m-0 text-gray-50" />}
    </div>
  );
};

export default EndpointCollectionContainer;
