import { Button, Form } from "react-bootstrap";
import MergeModal from "./MergeModal";
import SpinnerButton from "./SpinnerButton";

interface Props {
  selectedObjectType: string;
  associatedObjectsName?: string | null;
  show: boolean;
  onHide: () => void;
  isLoading: boolean;
  onConfirm: () => void;
  objectName?: string | null;
}

function ArchiveConfirmationModal({
  selectedObjectType,
  show,
  onHide,
  isLoading,
  onConfirm,
  objectName = null,
}: Props) {
  return (
    <MergeModal
      title={"Confirm Archive " + selectedObjectType}
      centered
      bodyClassName="modal-extra-narrow overflow-hidden"
      dialogClassName="modal-extra-narrow"
      show={show}
      onHide={onHide}
    >
      {objectName && <b>{objectName}</b>}
      <p>Are you sure you want to archive this {selectedObjectType}?</p>
      <Form>
        <SpinnerButton
          disabled={false}
          isLoading={isLoading}
          text="Confirm archive"
          className="btn-danger btn-block mt-1.5"
          onClick={onConfirm}
        />
        <Button variant="outline-dark" className="btn-block mt-1.5 border-0" onClick={onHide}>
          Cancel
        </Button>
      </Form>
    </MergeModal>
  );
}

export default ArchiveConfirmationModal;
