import { BlueprintStepTemplate } from "../../../models/Blueprints";
import { HeaderPretitle } from "../../shared/text/MergeText";
import TypeaheadFormField from "./TypeaheadFormField";

interface Props {
  stepTemplate: BlueprintStepTemplate;
}

const BlueprintEditorRightPanelFileToUrlForm = ({ stepTemplate }: Props) => {
  return (
    <>
      <HeaderPretitle className="my-2">Parameters</HeaderPretitle>
      {Object.entries(stepTemplate.parameter_schema ?? {}).map(([key, parameter]) => {
        return (
          <TypeaheadFormField
            key={key}
            title={parameter.title ?? key}
            subtitle={parameter.description}
            valueKey={key}
            parameterType={parameter.type}
          />
        );
      })}
    </>
  );
};

export default BlueprintEditorRightPanelFileToUrlForm;
