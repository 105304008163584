import styled from "styled-components";
import { palette } from "../../../styles/theme/colors";
import DeprecatedH6 from "../../deprecated/DeprecatedH6";

type TextSize = string;

export enum TextType {
  BLACK,
  BLUE,
  BLUE2,
  SUCCESS,
  WARNING,
  DANGER,
  WHITE,
  MUTED,
  MUTED2,
}

type Props = {
  color?: string;
  size?: TextSize;
  lineHeight?: string;
  type?: TextType;
  isBold?: boolean;
};

const getFontSize = (size: string | undefined) => {
  if (size) {
    return `${size}`;
  } else {
    return "12px";
  }
};

const getLineHeight = (height: string | undefined) => {
  if (height) {
    return `${height}`;
  } else {
    return "20px";
  }
};

const getFontColor = (type: TextType | undefined) => {
  switch (type) {
    case TextType.SUCCESS:
      return "#00BB8E";
    case TextType.WHITE:
      return "#FFFFFF";
    case TextType.MUTED:
      return "#8492A6";
    case TextType.MUTED2:
      return "#C5C7CD";
    case TextType.WARNING:
      return "#f6c343";
    case TextType.DANGER:
      return "#F00424";
    case TextType.BLUE:
      return "#2c7be5";
    case TextType.BLUE2:
      return palette.blue;
    case TextType.BLACK:
    default:
      return "#080808";
  }
};

const MergeText = styled.span<Props>`
  font-weight: ${(props) => (props.isBold ? "600" : "normal")};
  font-size: ${(props) => getFontSize(props.size)};
  line-height: ${(props) => getLineHeight(props.lineHeight)};
  color: ${(props) => props.color ?? getFontColor(props.type)};
`;

export const HeaderPretitle = styled(DeprecatedH6)`
  text-transform: uppercase;
  letter-spacing: 0;
  color: #95aac9;
  font-weight: 600;
`;

export const SmallTextMutedParagraph = styled.p`
  font-size: 0.8125rem;
  font-weight: 400;
  color: #939aa5 !important;
`;

export default MergeText;
