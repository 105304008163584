import useLoadAuthConfigs from "./hooks/useLoadAuthConfigs";
import { Route, Switch } from "react-router-dom";
import AuthenticationConfigurationTable from "./components/table/AuthenticationConfigurationTable";
import {
  getIntegrationBuilderAuthenticationConfigurationPathForId,
  getIntegrationBuilderCreateNewAuthConfigPathForId,
  getIntegrationBuilderSingleAuthConfigPathForId,
} from "../../../router/RouterUtils";
import AuthenticationConfigurationSetup from "./components/setup/AuthenticationConfigurationSetup";

interface AuthenticationConfigurationProps {
  integrationID: string;
}

const AuthenticationConfiguration = ({ integrationID }: AuthenticationConfigurationProps) => {
  const { authConfigs, isLoadingAuthConfig, setAuthConfigs } = useLoadAuthConfigs({
    integrationID,
  });

  return (
    <div>
      <Switch>
        <Route
          exact
          path={getIntegrationBuilderAuthenticationConfigurationPathForId(integrationID)}
          render={() => (
            <AuthenticationConfigurationTable
              integrationID={integrationID}
              authConfigs={authConfigs}
              isLoadingAuthConfig={isLoadingAuthConfig}
            />
          )}
        />
        <Route
          exact
          path={getIntegrationBuilderCreateNewAuthConfigPathForId(integrationID)}
          render={() => (
            <AuthenticationConfigurationSetup
              authConfigs={authConfigs}
              setAuthConfigs={setAuthConfigs}
              isNewAuthConfig={true}
              integrationID={integrationID}
            />
          )}
        />
        <Route
          exact
          path={getIntegrationBuilderSingleAuthConfigPathForId(integrationID)}
          render={() => (
            <AuthenticationConfigurationSetup
              authConfigs={authConfigs}
              setAuthConfigs={setAuthConfigs}
              isNewAuthConfig={false}
              integrationID={integrationID}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default AuthenticationConfiguration;
