import { RateLimitConfigurationIntegrationBuilder } from "../../../../utils/Entities";
import { rateLimitTypeTableRequestDisplayStrings } from "../../../utils/constants";

interface TypeCellProps {
  rateLimitConfiguration: RateLimitConfigurationIntegrationBuilder;
  onClick: React.MouseEventHandler<HTMLTableCellElement> | undefined;
}

const TypeCell = ({ rateLimitConfiguration, onClick }: TypeCellProps) => {
  const displayString =
    rateLimitTypeTableRequestDisplayStrings[rateLimitConfiguration.rate_limit_type];

  return (
    <td onClick={onClick}>
      <div className="text-gray-90 text-base font-medium">{displayString}</div>
    </td>
  );
};
export default TypeCell;
