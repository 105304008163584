import { ButtonVariant, Dropdown, MenuItem } from "@merge-api/merge-javascript-shared";
import { ChevronDown } from "lucide-react";

interface Props {
  stepID: string;
  title: string;
  itemIndex: number | undefined;
  totalItems: number | undefined;
  handleSelectItem: (selectedItemIndex: number) => void;
}

const StepLogLoopDropdown = ({ stepID, title, itemIndex, totalItems, handleSelectItem }: Props) => {
  if (itemIndex === undefined) {
    return null;
  }

  return (
    <div
      onClick={(event: any) => {
        if (event) event.stopPropagation();
      }}
    >
      <Dropdown
        ButtonProps={{
          children: `${title} ${itemIndex + 1}`,
          rightIcon: <ChevronDown size={12} className="text-blue-40" />,
          size: "sm",
          variant: ButtonVariant.TextBlue,
        }}
      >
        {totalItems !== undefined
          ? Array(totalItems)
              .fill(null)
              .map((_, index) => (
                <MenuItem
                  key={`${stepID}-${title}-${index}`}
                  onClick={() => handleSelectItem(index)}
                >
                  {title} {index + 1}
                </MenuItem>
              ))
          : null}
      </Dropdown>
    </div>
  );
};

export default StepLogLoopDropdown;
