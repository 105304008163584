import { useCallback } from "react";
import useBlueprintContext from "../../../context/useBlueprintContext";
import { fetchWithAuth } from "../../../../../api-client/api_client";
import { BlueprintRunnerExecutionResponse } from "../../../../../models/Blueprints";
import { showErrorToast } from "../../../../shared/Toasts";

export const useFetchBlueprintRunnerExecution = () => {
  const {
    blueprintRunnerExecutionResponse,
    setBlueprintRunnerExecutionResponse,
  } = useBlueprintContext();

  const fetchBlueprintRunnerExecution = useCallback(
    (blueprintExecutionId: string) => {
      fetchWithAuth({
        path: `/blueprints/web-connector-session-execution/${blueprintExecutionId}`,
        method: "GET",
        onResponse: (data: BlueprintRunnerExecutionResponse) => {
          setBlueprintRunnerExecutionResponse(data);
        },
        onError: () => {
          showErrorToast("Failed to fetch blueprint runner execution data.");
        },
      });
    },
    [setBlueprintRunnerExecutionResponse]
  );

  return { fetchBlueprintRunnerExecution, blueprintRunnerExecutionResponse };
};
