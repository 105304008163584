import { useState } from "react";
import { Code, File } from "lucide-react";
import styled from "styled-components";

import JSONSchemaConverterCard from "../integrations/json-schema/JSONSchemaConverterCard";
import useScraperContext from "./context/useScraperContext";
import ScraperCheckboxField from "./ScraperCheckboxField";
import ScraperClickFields from "./ScraperClickFields";
import ScraperIfElseFields from "./ScraperIfElseFields";
import ScraperPressKeyField from "./ScraperPressKeyField";
import ScraperConcatenateStringsFields from "./ScraperConcatenateStringsFields";
import ScraperCustomFunctionFields from "./ScraperCustomFunctionFields";
import ScraperElementSelectorField from "./ScraperElementSelectorField";
import ScraperQueryAndEvalFields from "./ScraperQueryAndEvalFields";
import ScraperSetVariableFields from "./ScraperSetVariableFields";
import ScraperTypeaheadFormField from "./ScraperTypeaheadFormField";
import { ScraperConstantValue, ScraperStep, ScraperStepType, ScraperValueType } from "./types";
import ScraperNestedParameterValueField from "./ScraperNestedParameterValueField";
import ScraperRaiseExceptionFields from "./ScraperRaiseExceptionFields";
import IntegrationEditorNavbar from "../shared/integration-editor-base/IntegrationEditorNavbar";
import StepNoteEditorAndViewer from "../blueprint-editor/right-panel/StepNoteEditorAndViewer";

const StyledIntegrationEditorNavbar = styled(IntegrationEditorNavbar).attrs({ className: "px-3" })`
  margin-top: -16px;
`;

const ContentSection = styled.div`
  flex-grow: 1;
  padding: 16px 12px;
  overflow-x: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  & hr {
    margin-left: -12px;
    margin-right: -12px;
  }
`;

type Props = {
  step: ScraperStep;
};

const checkboxFields = [
  "should_clear_before_inputting",
  "should_input_immediately",
  "should_filter_by_unique",
  "should_save_all_matches_as_list",
  "should_log_sentry_error_if_no_match",
  "should_save_storage_state_to_linked_account",
  "should_continue_after_timeout_if_not_found",
  "should_store_session_state",
  "should_retry",
  "should_execute_if_not_visible",
];

const STEPS_WITH_JSON_RESPONSE = [
  ScraperStepType.CLICK_AND_DOWNLOAD_CSV,
  ScraperStepType.EXPECT_DOWNLOAD_CSV,
];

const ScraperEditorExistingForm = ({ step }: Props) => {
  const { updateStepParameterValue, updateStepNoteText } = useScraperContext();
  const [sampleSchemaValue, setSampleSchemaValue] = useState("");
  const [JSONSchemaValue, setJSONSchemaValue] = useState("");
  const [isJSONSchemaShowing, setIsJSONSchemaShowing] = useState(false);

  const logicTabContent = () => {
    switch (step.step_type) {
      case ScraperStepType.SET_VARIABLE:
        return <ScraperSetVariableFields />;
      case ScraperStepType.QUERY_AND_EVAL:
        return <ScraperQueryAndEvalFields />;
      case ScraperStepType.CONCATENATE_STRINGS:
        return <ScraperConcatenateStringsFields />;
      case ScraperStepType.CUSTOM_FUNCTION:
        return <ScraperCustomFunctionFields />;
      case ScraperStepType.CLICK:
        return <ScraperClickFields />;
      case ScraperStepType.IF_ELSE:
        return <ScraperIfElseFields />;
      case ScraperStepType.RAISE_EXCEPTION:
        return <ScraperRaiseExceptionFields />;
      case ScraperStepType.PRESS_KEY:
        return <ScraperPressKeyField />;
      default:
        return (
          <div>
            {Object.entries(step.parameter_values).map(([valueKey, value]) => {
              const reactKey = `${step.id}.${valueKey}`;
              switch (value?.value_type) {
                case ScraperValueType.ELEMENT_SELECTOR:
                  return (
                    <ScraperElementSelectorField
                      key={reactKey}
                      updateValue={(newValue) => updateStepParameterValue(step, valueKey, newValue)}
                      value={value}
                    />
                  );
                case ScraperValueType.NESTED_PARAMETER_VALUES:
                  return (
                    <ScraperNestedParameterValueField
                      key={reactKey}
                      valueKey={valueKey}
                      value={value}
                      updateValue={(newValue) => updateStepParameterValue(step, valueKey, newValue)}
                    />
                  );
                default:
                  if (checkboxFields.includes(valueKey)) {
                    return (
                      <ScraperCheckboxField
                        key={reactKey}
                        valueKey={valueKey}
                        updateValue={(newValue) =>
                          updateStepParameterValue(step, valueKey, newValue)
                        }
                        value={value as ScraperConstantValue}
                      />
                    );
                  }
                  return (
                    <ScraperTypeaheadFormField
                      key={reactKey}
                      valueKey={valueKey}
                      updateValue={(newValue) => updateStepParameterValue(step, valueKey, newValue)}
                      value={value}
                    />
                  );
              }
            })}
            {STEPS_WITH_JSON_RESPONSE.includes(step.step_type) && (
              <JSONSchemaConverterCard
                sampleSchemaValue={sampleSchemaValue}
                setSampleSchemaValue={setSampleSchemaValue}
                JSONSchemaValue={JSONSchemaValue}
                setJSONSchemaValue={setJSONSchemaValue}
                isJSONSchemaShowing={isJSONSchemaShowing}
                setIsJSONSchemaShowing={setIsJSONSchemaShowing}
              />
            )}
          </div>
        );
    }
  };

  const stepNoteText = step.step_note || "";
  const setStepNoteText = (newText: string) => updateStepNoteText(step, newText);
  const notesTabContent = () => (
    <StepNoteEditorAndViewer stepNoteText={stepNoteText} setStepNoteText={setStepNoteText} />
  );

  const tabs = [
    { key: "logic", navbarText: "Logic", Icon: Code, content: logicTabContent },
    {
      key: "notes",
      navbarText: step.step_note ? "Notes" : "Add Notes",
      Icon: File,
      content: notesTabContent,
    },
  ];
  const [selectedTabKey, setSelectedTabKey] = useState(tabs[0].key);

  return (
    <>
      <StyledIntegrationEditorNavbar
        tabs={tabs}
        selectedTabKey={selectedTabKey}
        setSelectedTabKey={setSelectedTabKey}
      />
      <ContentSection>{tabs.find((tab) => tab.key === selectedTabKey)?.content()}</ContentSection>
    </>
  );
  // return <div className="p-3">{logicTabContent}</div>;
};

export default ScraperEditorExistingForm;
