import { getIntegrationBuilderWebAddressSchemaForId } from "../../../../router/RouterUtils";
import IntegrationBuilderHeader from "../../shared/IntegrationBuilderHeader";
import { WebAddressSchemaIntegrationBuilder } from "../../utils/Entities";

interface WebAddressSchemaHeaderProps {
  isNewWebAddressSchema: boolean;
  integrationID: string;
  setWebAddressSchemas: React.Dispatch<
    React.SetStateAction<WebAddressSchemaIntegrationBuilder[] | undefined>
  >;
  selectedWebAddressSchema: WebAddressSchemaIntegrationBuilder | undefined;
}

const WebAddressSchemaHeader = ({
  isNewWebAddressSchema,
  integrationID,
}: WebAddressSchemaHeaderProps) => {
  return (
    <IntegrationBuilderHeader
      title={isNewWebAddressSchema ? "Add schema" : "Edit schema"}
      navButtonLink={getIntegrationBuilderWebAddressSchemaForId(integrationID)}
      navButtonText="Web address schemas"
      showRequired
    />
  );
};

export default WebAddressSchemaHeader;
