import { Text } from "@merge-api/merge-javascript-shared";
import RateLimitConfigurationContext from "../context/RateLimitConfigurationContext";
import { useContext, useEffect, useState } from "react";
import { SelectOptionsData } from "../../utils/types";
import SelectHeader from "../../shared/SelectHeader";
import { RateLimitType } from "../../utils/Entities";
import { RATE_LIMIT_TYPE_SELECT_OPTIONS } from "../utils/constants";
import TextFieldHeader from "../../shared/TextFieldHeader";
import RateLimitValue from "./RateLimitValue";
import { isValidNumber } from "../utils/helpers";

const BasicRateLimitConfiguration = () => {
  // context
  const { rateLimitType, setRateLimitType, rateLimitThreshold, setRateLimitThreshold } = useContext(
    RateLimitConfigurationContext
  );

  // functions for rendering rate limit type title and options
  const selectedRateLimitType = RATE_LIMIT_TYPE_SELECT_OPTIONS.find(
    (option) => option.value === rateLimitType
  );

  const renderRateLimitTypeOption = (option: SelectOptionsData) => {
    return (
      <div className="flex flex-col">
        <Text variant="md">{option.title}</Text>
        <Text variant="sm">{option.subtitle}</Text>
      </div>
    );
  };
  const renderRateLimitTypeValue = (option: SelectOptionsData) => {
    return <Text variant="md">{option.title}</Text>;
  };

  // state for error message when inputting numbers larger than 80 or smaller than 0
  const [isInvalidThreshold, setIsInvalidThreshold] = useState(false);

  const updateIsInvalidThreshold = (value: string) => {
    setIsInvalidThreshold(false);
    if (value === "") {
      setRateLimitThreshold(undefined);
    } else {
      const numericValue = parseInt(value, 10);
      if (!isNaN(numericValue)) {
        setRateLimitThreshold(numericValue);
        if (!isValidNumber(numericValue, 80)) {
          setIsInvalidThreshold(true);
        }
      }
    }
  };

  useEffect(() => {
    if (rateLimitThreshold) updateIsInvalidThreshold(rateLimitThreshold.toString());
  }, [rateLimitThreshold]);

  return (
    <>
      <SelectHeader
        dataTestID="field-rate-limit-config-rate-limit-type"
        required
        className="mt-6"
        title="Rate limit type"
        subtitle="Indicates the type of rate limit to handle"
        clearable={false}
        hasSource={false}
        value={selectedRateLimitType}
        renderValue={renderRateLimitTypeValue}
        renderOption={renderRateLimitTypeOption}
        onChange={(_: any, selectedOption: SelectOptionsData | null) => {
          if (selectedOption) {
            setRateLimitType(selectedOption.value as RateLimitType);
          } else {
            setRateLimitType(RateLimitType.REQUEST_FREQUENCY);
          }
        }}
        options={RATE_LIMIT_TYPE_SELECT_OPTIONS}
      />
      <RateLimitValue />
      <TextFieldHeader
        dataTestID="field-rate-limit-config-rate-limit-threshold"
        className="mt-6"
        inputWrapperClassName="w-[120px]"
        title="Rate limit threshold"
        subtitle="This is the threshold where the runner is stopped or slowed if we reach this count. It is the percentage of the limit max count value that we don't want to surpass."
        error={isInvalidThreshold}
        placeholder="Value"
        errorText="The maximum entered percentage value should never exceed 80%"
        value={rateLimitThreshold}
        postfix={
          <div className="flex h-full items-center rounded-br-md rounded-tr-md bg-gray-0 px-3 text-black">
            %
          </div>
        }
        type="number"
        required
        onChange={(newEvent) => {
          updateIsInvalidThreshold(newEvent.target.value);
        }}
      />
    </>
  );
};

export default BasicRateLimitConfiguration;
