export enum PointerForNextRequestResponseLocation {
  RESPONSE_BODY = "RESPONSE_BODY",
  RESPONSE_HEADER = "RESPONSE_HEADER",
}

export enum EndOfPaginationType {
  EMPTY_ARRAY = "EMPTY_ARRAY",
  MAX_ITEMS = "MAX_ITEMS",
  BREAK_VALUE = "BREAK_VALUE",
}

export enum MaxItemsValueLocation {
  RESPONSE_BODY = "RESPONSE_BODY",
  RESPONSE_HEADER = "RESPONSE_HEADER",
}

export enum PaginationValueRequestLocation {
  PATH = "PATH",
  URL = "URL",
  PATH_PARAM = "PATH_PARAM",
  QUERY_PARAM = "QUERY_PARAM",
  BODY_PARAM = "BODY_PARAM",
}

export enum PaginationType {
  POINTER = "POINTER",
  OFFSET = "OFFSET",
  PAGES = "PAGES",
}

export enum BreakValueType {
  BREAK_WHEN_FALSE = "BREAK_WHEN_FALSE",
  BREAK_WHEN_SPECIFIC_VALUE = "BREAK_WHEN_SPECIFIC_VALUE",
}

export enum SortFieldFilterRequestLocation {
  QUERY_PARAM = "QUERY_PARAM",
  PATH_PARAM = "PATH_PARAM",
  HEADER_PARAM = "HEADER_PARAM",
  BODY_PARAM = "BODY_PARAM",
}

export enum PageSizeRequestLocation {
  QUERY_PARAM = "QUERY_PARAM",
  BODY_PARAM = "BODY_PARAM",
}
