import { Accordion, Text } from "@merge-api/merge-javascript-shared";
import {
  DiffStateField,
  DiffStateFieldTypeEnum,
  DiffStatusEnum,
  MapDiffStatusEnumToColor,
} from "../../../../models/DiffModels";
import clsx from "clsx";
import { mapChildLevelToLeftPadding } from "../utils/helpers";

interface Props {
  isCurrent: boolean;
  isNested?: boolean;
  childLevel: number;
  field: DiffStateField;
  setIsExpanded: (value: boolean) => void;
  isExpanded: boolean;
  isParentFieldDeleted: boolean;
  setIsTruncated: (value: boolean) => void;
  isTruncated: boolean;
}

const SplitDiffFieldCell = ({
  isCurrent,
  isNested = false,
  childLevel,
  field,
  setIsExpanded,
  isExpanded,
  isParentFieldDeleted,
  setIsTruncated,
  isTruncated,
}: Props) => {
  // Add more left padding to children, except if child is nested
  const calculateLeftPadding = isNested
    ? mapChildLevelToLeftPadding[childLevel - 1]
    : mapChildLevelToLeftPadding[childLevel];

  let fieldValue = isCurrent ? field.currentValue : field.newValue;
  const displayName =
    field.displayName === ""
      ? isCurrent
        ? field.displayNameCurrent
        : field.displayNameNew
      : field.displayName;
  const shouldRender = isCurrent ? !field.isRenderCurrentAsEmpty : !field.isRenderNewAsEmpty;

  const diffStatusColor = isParentFieldDeleted
    ? MapDiffStatusEnumToColor[DiffStatusEnum.DELETED]
    : MapDiffStatusEnumToColor[field.diffStatus];

  return (
    <div
      className={clsx(
        field.type === DiffStateFieldTypeEnum.HEADER ? "py-2" : "py-1",
        diffStatusColor,
        "h-full w-full",
        calculateLeftPadding
      )}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      {shouldRender && (
        <div className="flex flex-row items-center px-3.5 space-x-2 h-full w-full cursor-pointer">
          {isNested && (
            <div className="flex flex-row items-start h-full">
              <div className="w-4 h-4 rounded-bl border-l-[1px] border-b-[1px] border-gray-30"></div>
            </div>
          )}
          {field.type === DiffStateFieldTypeEnum.HEADER ? (
            <Accordion
              className={clsx(diffStatusColor)}
              titleClassName={diffStatusColor}
              title={
                <Text variant="h6" className="text-black pl-2 py-0 m-0">
                  {displayName}
                </Text>
              }
              variant="none"
              chevronPlacement="left"
              chevronOrientation="right"
              chevronColor="black"
              chevronSize={16}
              expanded={isExpanded}
              setExpanded={() => setIsExpanded(!isExpanded)}
              onChange={(_, expanded) => setIsExpanded(expanded)}
              children={null}
            />
          ) : field.type === DiffStateFieldTypeEnum.SECTION ? (
            <Accordion
              className={diffStatusColor}
              titleClassName={diffStatusColor}
              title={
                <div className="flex flex-row space-x-2">
                  <Text variant="title-sm" className="text-black pl-2">
                    {displayName}
                  </Text>
                  {<Text variant="sm">{fieldValue}</Text>}
                </div>
              }
              variant="none"
              chevronPlacement="left"
              chevronOrientation="right"
              chevronColor="black"
              chevronSize={16}
              expanded={isExpanded}
              setExpanded={() => setIsExpanded(!isExpanded)}
              onChange={(_, expanded) => setIsExpanded(expanded)}
              children={null}
            />
          ) : field.type === DiffStateFieldTypeEnum.FIELD ? (
            <div className="flex flex-row justify-stretch space-x-2 w-full cursor-default">
              <div className="text-pretty max-w-[300px]">
                <Text variant="title-sm">{displayName}:</Text>
              </div>
              <div
                className={clsx(isTruncated ? "truncate" : "text-wrap break-all", "cursor-pointer")}
                onClick={() => setIsTruncated(!isTruncated)}
              >
                <Text variant={field.isRenderAsPreviewable ? "sm-mono" : "sm"}>{fieldValue}</Text>
              </div>
            </div>
          ) : (
            <div className="flex flex-row justify-stretch space-x-2 w-full cursor-default">
              <div className="text-pretty max-w-[300px]">
                <Text variant="sm" className="text-gray-70">
                  {displayName}
                </Text>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SplitDiffFieldCell;
