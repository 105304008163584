import { Link } from "@merge-api/merge-javascript-shared";
import HelpAccordionBlock from "../../../shared/HelpAccordionBlock";
import HelpAccordionCard from "../../../shared/HelpAccordionCard";
import MergeCodeBlock from "../../../../shared/MergeCodeBlock";
import LineIndent from "../../../authentication/components/shared/LineIndent";

interface HelperAccordionProps {
  className?: string;
}

const GenshiTemplatingHelperAccordion = ({ className }: HelperAccordionProps) => {
  const genshiExample = `<APBILLITEMS>
  <APBILLITEM py:for="item in line_items">
      <ACCOUNTNO>\${item["glaccountno"]}</ACCOUNTNO>
      <TRX_AMOUNT>\${item["amount"]}</TRX_AMOUNT>
      <TAXENTRIES py:if="item.get('tax_id')">
          <TAXENTRY>
              <DETAILID>\${item["tax_id"]}</DETAILID>
          </TAXENTRY>
      </TAXENTRIES>
  </APBILLITEM>
</APBILLITEMS>
`;

  const genshiWithCustomFunctionExample = `<?xml version="1.0" encoding="UTF-8"?>
    <request xmlns:py="http://genshi.edgewall.org/">
     <py:for each="remote_field in remote_fields">
          <py:if test="remote_field.get('parent_tag') is None">
            \${insert_key_value_pair(remote_field["remote_field_class"], remote_field["value"])}
          </py:if>
      </py:for>
      <standard_static_field>\${static_value}</standard_static_field>
    </request>
  `;

  const requestBodyExample = `<?xml version="1.0" encoding="UTF-8"?>
    <request>
      <tag_1>value_1</tag_1>
      <tag_2>value_2</tag_2>
      <standard_static_field>omg</standard_static_field>
    </request>
  `;

  return (
    <HelpAccordionCard
      title="Additional information for using the Genshi templating engine for generating and processing XML request body formats"
      className={className}
      children={
        <div className="flex flex-col space-y-4">
          <HelpAccordionBlock
            title="What is Genshi?"
            body={
              <div>
                Genshi is a powerful Python-based templating engine designed specifically for
                generating and processing XML documents, including XHTML. It allows you to create
                templates that combine static XML structures with dynamic content generated from
                Python expressions and logic. You can learn more by reading the documentation{" "}
                <Link target="_blank" href="https://genshi.readthedocs.io/en/latest/xml-templates/">
                  here
                </Link>
                .
              </div>
            }
          />
          <HelpAccordionBlock
            title="How Does Genshi Work?"
            body={
              <div>
                Genshi works by combining an XML template with a context object containing your
                data. The template includes placeholders that are replaced with actual values from
                the context during rendering. Additionally, Genshi supports conditional statements
                and loops directly within the XML structure, allowing you to include or exclude
                elements based on your data.
                <br />
                <br />
                For example, you can use <code>py:if</code> and <code>py:for</code> together to
                create a dynamic list of items with optional elements:
                <br />
                <br />
                <MergeCodeBlock
                  title="Example request body format with Genshi templating"
                  textToCopy={genshiExample}
                  children={genshiExample}
                  language={"xml"}
                />
                In this example, the <code>py:for</code> loop generates an{" "}
                <code>&lt;APBILLITEM&gt;</code> element for each item in the <code>line_items</code>{" "}
                list. If an item has a <code>tax_id</code>, the <code>&lt;TAXENTRIES&gt;</code>{" "}
                block is included; otherwise, it is omitted.
              </div>
            }
          />
          <HelpAccordionBlock
            title="Custom functions in Genshi"
            body={
              <div>
                We created custom functions that you can use in your XML template. These are custom
                functions we created on top of Genshi templating, so you can perform more complex
                operations.
                <br />
                Here's a list of the available custom functions for Genshi templating:
                <br />
                <br />
                <code>insert_key_value_pair(key, value)</code> — Render a key-value pair as XML
                tag-value pair
                <LineIndent className="mt-2" borderClassName="border-gray-30">
                  <MergeCodeBlock
                    title="Example request body format with insert_key_value_pair"
                    textToCopy={genshiWithCustomFunctionExample}
                    children={genshiWithCustomFunctionExample}
                    language={"xml"}
                  />
                  <MergeCodeBlock
                    title="How the resulting request body could look like"
                    textToCopy={requestBodyExample}
                    children={requestBodyExample}
                    language={"xml"}
                  />
                </LineIndent>
              </div>
            }
          />
        </div>
      }
    />
  );
};

export default GenshiTemplatingHelperAccordion;
