import { Badge, Text } from "@merge-api/merge-javascript-shared";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import { LinkingFlowStepIntegrationBuilder } from "../../../utils/Entities";

interface Props {
  index: number;
  requestedStep: LinkingFlowStepIntegrationBuilder;
  onCallbackUpdateStep: (index: number, keyValuePairs: Record<string, any>) => void;
}

const InputValidationConfigurationFields = ({
  index,
  requestedStep,
  onCallbackUpdateStep,
}: Props) => {
  return (
    <div className="border-gray-20 rounded-lg border-[0.5px]">
      <div className="px-5 py-4 flex flex-row justify-between items-center">
        <div className="flex flex-row items-center justify-start space-x-2">
          <Text variant="h6">Input validation configuration</Text>
          <Badge color="blue">2</Badge>
        </div>
      </div>
      <div className="px-5 py-4 space-y-6 border-t-[0.5px] border-gray-20">
        <TextFieldHeader
          dataTestID={`field-step-card-input-validation-regex-${index}`}
          title="Input validation regex"
          subtitle="The entered regex value will be used to check whether the input is valid."
          placeholder={"Example: ^|^&<>|+$"}
          value={requestedStep.input_validation_regex}
          onChange={(event) =>
            onCallbackUpdateStep(index, { input_validation_regex: event.target.value })
          }
        />
        <TextFieldHeader
          dataTestID={`field-step-card-input-validation-error-${index}`}
          title="Input validation error"
          subtitle="This will be shown to the end user if regex validation fails."
          placeholder={
            'Example: Invalid: "&", "<", or ">" characters cannot be included in the password.'
          }
          value={requestedStep.input_validation_error}
          onChange={(event) =>
            onCallbackUpdateStep(index, { input_validation_error: event.target.value })
          }
        />
      </div>
    </div>
  );
};

export default InputValidationConfigurationFields;
