import TypeaheadFormField from "./TypeaheadFormField";

const BlueprintEditorRightPanelWriteFileForm = () => {
  return (
    <form>
      <TypeaheadFormField
        title="File"
        subtitle="The actual file/data to write to be uploaded"
        valueKey="file"
        parameterType={"any"}
      />
      <TypeaheadFormField
        title="File Name"
        subtitle="The name of the file to write."
        valueKey="filename"
        parameterType={"string"}
      />
      <TypeaheadFormField
        title="Encoding Type"
        subtitle="The encoding type, if any, of the file/data passed in"
        valueKey="encoding_type"
        parameterType={"string"}
      />
      <TypeaheadFormField
        title="Is Public"
        subtitle="Whether the file to be uploaded should be publically available"
        valueKey="is_public"
        parameterType={"boolean"}
      />
    </form>
  );
};

export default BlueprintEditorRightPanelWriteFileForm;
