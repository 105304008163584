import {
  BlueprintParameterValueConstant,
  BlueprintParameterValueType,
  BlueprintSetVariableStep,
  SetVariableValueType,
} from "../../../models/Blueprints";
import { HeaderPretitle } from "../../shared/text/MergeText";
import useBlueprintContext from "../context/useBlueprintContext";
import CustomJSONEditorModal from "./custom-json-parameter/CustomJSONEditorModal";
import CustomJSONOptions from "./custom-json-parameter/CustomJSONOptions";
import useCustomJSONModalContext from "./custom-json-parameter/useCustomJSONModalContext";
import DropdownFormField from "./DropdownFormField";
import TypeaheadFormField from "./TypeaheadFormField";

export const VARIABLE_TYPES = [
  { name: "Empty Object", id: SetVariableValueType.EMPTY_OBJECT },
  { name: "Empty Array", id: SetVariableValueType.EMPTY_ARRAY },
  { name: "Other Value", id: SetVariableValueType.OTHER_VALUE },
];

const BlueprintEditorRightPanelSetVariableForm = () => {
  const parameterKey = "value";

  const { selectedStep, updateStepParameterValue } = useBlueprintContext();
  const step = selectedStep as BlueprintSetVariableStep;
  const customObjectModalContext = useCustomJSONModalContext([parameterKey]);
  const { enabledRootKeys } = customObjectModalContext;
  const currentComparatorValue = (step.parameter_values?.[
    "value_type"
  ] as BlueprintParameterValueConstant)?.constant;

  return (
    <>
      <CustomJSONEditorModal {...customObjectModalContext} />
      <form>
        <HeaderPretitle className="my-2">Parameters</HeaderPretitle>
        <TypeaheadFormField
          title={"key"}
          subtitle={"The key to access the variable with."}
          valueKey={"key"}
          parameterType={"string"}
        />
        <DropdownFormField
          choices={VARIABLE_TYPES}
          currentValue={currentComparatorValue}
          placeholder={"Select a value type"}
          title={"Value Type"}
          subtitle={
            "The type of value to set to. If you don't need to set a default value, select 'Other Value'."
          }
          onChange={(e) =>
            updateStepParameterValue(step, "value_type", {
              constant: e.target.value,
              value_type: BlueprintParameterValueType.constant,
            })
          }
        />
        {currentComparatorValue === SetVariableValueType.OTHER_VALUE && (
          <>
            <TypeaheadFormField
              title={"value"}
              subtitle={"The value to set the variable to."}
              valueKey={parameterKey}
              parameterType={"any"}
              disabled={enabledRootKeys[parameterKey]}
            />
            <CustomJSONOptions
              parameterKey={parameterKey}
              {...customObjectModalContext}
              enableSchemaEditor
            />
          </>
        )}
      </form>
    </>
  );
};

export default BlueprintEditorRightPanelSetVariableForm;
