import { useParams } from "react-router-dom";
import { RateLimitConfigurationIntegrationBuilder } from "../../utils/Entities";
import RateLimitConfigurationContextProvider from "../context/RateLimitConfigurationContextProvider";
import BaseRateLimitConfigurationSetup from "./BaseRateLimitConfigurationSetup";
import useLoadRateLimitConfigurations from "../hooks/useLoadRateLimitConfigurations";

type RouteParams = {
  rateLimitConfigurationID: string | undefined;
};

interface RateLimitConfigurationSetupProps {
  integrationID: string;
  rateLimitConfigurations: RateLimitConfigurationIntegrationBuilder[] | undefined;
  setRateLimitConfigurations: React.Dispatch<
    React.SetStateAction<RateLimitConfigurationIntegrationBuilder[] | undefined>
  >;
  isLoadingRateLimitConfigurations: boolean;
  setIsLoadingRateLimitConfigurations: React.Dispatch<React.SetStateAction<boolean>>;
}

const RateLimitConfigurationSetup = ({
  integrationID,
  rateLimitConfigurations,
  setRateLimitConfigurations,
  isLoadingRateLimitConfigurations,
  setIsLoadingRateLimitConfigurations,
}: RateLimitConfigurationSetupProps) => {
  const { rateLimitConfigurationID } = useParams<RouteParams>();

  // Need to load rate limit configurations in the case of loading a specific rate limit configuration page directly
  useLoadRateLimitConfigurations({
    integrationID,
    setRateLimitConfigurations,
    setIsLoadingRateLimitConfigurations,
  });

  const selectedRateLimitConfiguration = rateLimitConfigurations?.find(
    (rateLimitConfiguration) => rateLimitConfiguration.id === rateLimitConfigurationID
  );

  return (
    <RateLimitConfigurationContextProvider
      selectedRateLimitConfiguration={selectedRateLimitConfiguration}
      integrationID={integrationID}
    >
      <BaseRateLimitConfigurationSetup
        integrationID={integrationID}
        selectedRateLimitConfiguration={selectedRateLimitConfiguration}
        setRateLimitConfigurations={setRateLimitConfigurations}
        isLoadingRateLimitConfigurations={isLoadingRateLimitConfigurations}
      />
    </RateLimitConfigurationContextProvider>
  );
};

export default RateLimitConfigurationSetup;
