import { Text } from "@merge-api/merge-javascript-shared";
import { BlockExecutionErrorDetailsAssertionsDict } from "../../../../models/MappingTests";

interface Props {
  errorDetails: BlockExecutionErrorDetailsAssertionsDict;
}

const MappingTestV2RightPanelErrorDetailsAssertions = ({ errorDetails }: Props) => {
  return (
    <div className="pl-4">
      <ul className="list-disc text-gray-60">
        {errorDetails.failing_assertions.map((failingAssertion) => {
          return (
            <li>
              <Text variant="sm" className="text-gray-60">
                {failingAssertion.model || failingAssertion.model_instance}
              </Text>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MappingTestV2RightPanelErrorDetailsAssertions;
