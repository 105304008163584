import { useHistory, useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import { Button, Col, Row, Form, Popover, OverlayTrigger } from "react-bootstrap";
import { ChevronLeft } from "lucide-react";
import { fetchWithAuth } from "../../../api-client/api_client";
import styled from "styled-components";
import FullPageSpinner from "../../shared/layout/FullPageSpinner";
import { showErrorToast } from "../../shared/Toasts";
import ClickableContainer from "../../shared/ClickableContainer";
import { navigateToUIDTemplateEditor } from "../../../router/RouterUtils";
import { CategoryBadge } from "../../shared/MergeBadges";
import MergeText, { SmallTextMutedParagraph } from "../../shared/text/MergeText";

import { getReadableCommonModelName } from "./utils/UIDUtils";

import {
  IntegrationCommonModelTemplate,
  IntegrationCommonModelTemplateStatus,
} from "../../../models/Entities";
import UIDTemplateFieldTable from "./UIDTemplateFieldTable";
import {
  AddNonCommonModelFieldModal,
  DeleteNonCommonModelFieldModal,
} from "./UIDTemplateEditModals";

type RouteParams = {
  integrationID: string;
  templateID: string;
};

export const StyledButton = styled(Button).attrs({ size: "sm", variant: "light" })`
  background-color: white;
  border: none;
  margin-bottom: 12px;
  margin-left: 3px;
  font-size: 20px;
`;

const CancelChangesButton = styled(Button).attrs({ size: "sm", variant: "light" })`
  background-color: white;
  margin-bottom: 12px;
  margin-left: 24px;
  font-size: 14px;
  box-shadow: 0px 1px 2px 0px #1f2d3d0d;
  border: 1px solid #d4def2;
`;

const ConfirmChangesButton = styled(Button).attrs({ size: "sm", variant: "light" })`
  background-color: #111317;
  color: white;
  border: none;
  margin-bottom: 12px;
  margin-left: 10px;
  font-size: 14px;
`;

const DeleteTemplateButton = styled(Button).attrs({ size: "sm", variant: "light" })`
  background-color: white;
  border: none;
`;

const EllipseButton = styled(Button).attrs({ size: "sm", variant: "light" })`
  background-color: white;
  border: none;
  margin-left: 10px;
  font-size: 14px;
  border: 1px solid #d4def2;
  width: 35px;
`;

const Editor = styled(Form.Control).attrs({ as: "textarea", className: "w-75 padding-top: 20px" })`
  height: 30px;
  box-sizing: border-box;
  resize: auto;
  max-width: 20%;
`;

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 5%;
`;

const Section = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 14px;
`;

const EditorContainer = styled.div`
  padding-left: 10px;
`;

const ShadedDisclaimerContainer = styled.div`
  background-color: #f5f8ff;
  margin-left: 20px;
  width: 100%;
  height: 20%;
  border-radius: 14px;
`;

const ShadedDisclaimerBody = styled.div`
  margin-left: 2%;
  margin-top: 2%;
  margin-bottom: 2%;
  max-height: 30px;
`;

const ButtonSection = styled.div`
  align-self: right;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const GoBackText = styled.p`
  && {
    font-size: 14px;
    font-weight: 400;
    color: #075ff7;
    padding-top: 2px;
  }
`;

const StyledChevronLeft = styled(ChevronLeft)`
  && {
    color: #075ff7;
  }
`;

const CustomSwitch = styled(Form.Check)`
  && {
    .custom-control-input:checked ~ .custom-control-label:before {
      background-color: #111317;
    }
  }
`;

function UIDTemplateEditView() {
  const [template, setTemplate] = useState<IntegrationCommonModelTemplate | undefined>(undefined);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedText, setEditedText] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showAddNonCommonModelFieldModal, setShowAddNonCommonModelFieldModal] = useState<boolean>(
    false
  );
  const [nonCommonModelFieldTitle, setNonCommonModelFieldTitle] = useState<string>("");
  const [nonCommonModelFieldDescr, setNonCommonModelFieldDescr] = useState<string>("");
  const [nonCommonModelFieldParsed, setNonCommonModelFieldParsed] = useState<string>("true");
  const [nonCommonModelFieldNull, setNonCommonModelFieldNull] = useState<string>("true");
  const [nonCommonModelFieldTransformation, setNonCommonModelFieldTransformation] = useState<
    string
  >("null");

  const templateIsActive = template?.status === IntegrationCommonModelTemplateStatus.ACTIVE;

  const { integrationID, templateID } = useParams<RouteParams>();

  const history = useHistory();

  const saveTemplate = (template: IntegrationCommonModelTemplate) => {
    setIsSaving(true);
    fetchWithAuth({
      path: `/integrations/${integrationID}/uid/templates/${templateID}`,
      method: "PATCH",
      body: {
        unique_id_fields: template.unique_id_fields,
        is_default: template.is_default,
        template_name: template.template_name,
      },
      onResponse: (data: IntegrationCommonModelTemplate) => {
        navigateToUIDTemplateEditor(history, integrationID, data.id);
      },
      onError: () => {
        showErrorToast("Failed to save template.");
        setIsSaving(false);
      },
    });
  };

  const deleteTemplate = () => {
    setIsLoading(true);
    setShowDeleteModal(false);
    fetchWithAuth({
      path: `/integrations/${integrationID}/uid/templates/${templateID}`,
      method: "DELETE",
      onResponse: () => {
        history.goBack();
      },
      onError: () => {
        showErrorToast("Failed to delete template.");
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    const fetchTemplate = () => {
      setIsLoading(true);
      fetchWithAuth({
        path: `/integrations/${integrationID}/uid/templates/${templateID}`,
        method: "GET",
        onResponse: (data: IntegrationCommonModelTemplate) => {
          setTemplate(data);
          setIsLoading(false);
        },
        onError: () => {
          showErrorToast("Failed to fetch templates.");
          setIsLoading(false);
        },
      });
    };

    fetchTemplate();
  }, []);

  if (isLoading || isSaving) {
    return <FullPageSpinner />;
  }

  const deletePopover = (
    <Popover id="delete-popover">
      <DeleteTemplateButton
        className=""
        size="sm"
        onClick={() => {
          setShowDeleteModal(true);
        }}
      >
        <i className="fe fe-trash-2" /> Delete
      </DeleteTemplateButton>
    </Popover>
  );

  return (
    <>
      {template && (
        <div>
          <AddNonCommonModelFieldModal
            template={template}
            showAddNonCommonModelFieldModal={showAddNonCommonModelFieldModal}
            setShowAddNonCommonModelFieldModal={setShowAddNonCommonModelFieldModal}
            nonCommonModelFieldTitle={nonCommonModelFieldTitle}
            setNonCommonModelFieldTitle={setNonCommonModelFieldTitle}
            nonCommonModelFieldDescr={nonCommonModelFieldDescr}
            setNonCommonModelFieldDescr={setNonCommonModelFieldDescr}
            nonCommonModelFieldNull={nonCommonModelFieldNull}
            setNonCommonModelFieldNull={setNonCommonModelFieldNull}
            nonCommonModelFieldParsed={nonCommonModelFieldParsed}
            setNonCommonModelFieldParsed={setNonCommonModelFieldParsed}
            nonCommonModelFieldTransformation={nonCommonModelFieldTransformation}
            setNonCommonModelFieldTransformation={setNonCommonModelFieldTransformation}
          />
          <DeleteNonCommonModelFieldModal
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            deleteTemplate={deleteTemplate}
          />
          <CenteredContainer>
            <Row className="w-100 justify-content-md-center align-items-center">
              <Col>
                {isEditing && (
                  <EditorContainer>
                    <Editor
                      value={editedText}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                        setEditedText(e.target.value)
                      }
                    />
                    <StyledButton
                      style={{ marginBottom: "20px" }}
                      onClick={() => {
                        setTemplate({ ...template, template_name: editedText });
                        setIsEditing(false);
                      }}
                    >
                      <i className="fe fe-save mr-1.5" />
                    </StyledButton>
                  </EditorContainer>
                )}
                {!isEditing && (
                  <EditorContainer>
                    <MergeText isBold size="28px">
                      {template.template_name}
                    </MergeText>
                    <StyledButton
                      onClick={() => {
                        setIsEditing(true);
                        setEditedText(template.template_name ?? "");
                      }}
                    >
                      <i className="fe fe-edit-2 mr-1.5" />
                    </StyledButton>
                  </EditorContainer>
                )}
              </Col>
              <Col className="col-4 align-self-right">
                <ButtonSection>
                  <div style={{ marginRight: "10px", paddingLeft: "30px" }}>
                    {template.is_default && (
                      <MergeText isBold size="15px">
                        Default
                      </MergeText>
                    )}
                    {!template.is_default && <MergeText size="15px">Not Default</MergeText>}
                  </div>

                  <Form.Group controlId={"is-default"} className="mb-0">
                    <CustomSwitch
                      id={"is-default"}
                      type="switch"
                      checked={template.is_default}
                      onChange={() => {
                        setTemplate({ ...template, is_default: !template.is_default });
                      }}
                    />
                  </Form.Group>
                  {!templateIsActive && (
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      overlay={deletePopover}
                      rootClose
                    >
                      <EllipseButton>
                        <div style={{ alignItems: "center", paddingBottom: "5px", width: "18px" }}>
                          ...
                        </div>
                      </EllipseButton>
                    </OverlayTrigger>
                  )}
                </ButtonSection>
              </Col>
            </Row>
            <Row>
              <Col>
                <Section>
                  <ClickableContainer onClick={() => history.goBack()}>
                    <div style={{ display: "flex", paddingTop: "2px" }}>
                      <StyledChevronLeft strokeWidth={0.5} className="float-left" />
                      <GoBackText>Back</GoBackText>
                    </div>
                  </ClickableContainer>
                  <div style={{ paddingLeft: "20px" }}>
                    <MergeText style={{ fontSize: "20px" }}>.</MergeText>
                  </div>
                  <div style={{ paddingLeft: "20px", paddingTop: "3px" }}>
                    <MergeText style={{ fontSize: "14px" }}>
                      Unique ID Template for {getReadableCommonModelName(template.model, " ")}
                    </MergeText>
                  </div>
                  <div style={{ paddingLeft: "10px", paddingTop: "2px" }}>
                    <CategoryBadge category={template.category} />
                  </div>
                </Section>
              </Col>
            </Row>
            <Row className="w-100 ">
              <ShadedDisclaimerContainer>
                <ShadedDisclaimerBody>
                  <MergeText style={{ fontSize: "14px" }}>Select</MergeText>
                  <MergeText isBold style={{ fontSize: "14px" }}>
                    {" "}
                    {getReadableCommonModelName(template.model, " ")} fields{" "}
                  </MergeText>{" "}
                  <MergeText style={{ fontSize: "14px" }}>
                    to be part of the Unique Identifier template
                  </MergeText>
                </ShadedDisclaimerBody>
              </ShadedDisclaimerContainer>
            </Row>
            <UIDTemplateFieldTable
              template={template}
              setTemplate={setTemplate}
              setShowAddNonCommonModelField={setShowAddNonCommonModelFieldModal}
              templateIsActive={templateIsActive}
            />
            <Row
              style={{
                marginLeft: "1%",
                marginRight: "1%",
                width: "100",
              }}
            >
              <Col>
                <SmallTextMutedParagraph>
                  Fields listed in alphabetical order
                </SmallTextMutedParagraph>
              </Col>
              <Col className="col-3 align-self-right">
                {!isSaving && !templateIsActive && (
                  <ButtonSection>
                    <CancelChangesButton onClick={() => history.goBack()}>
                      Cancel
                    </CancelChangesButton>
                    <ConfirmChangesButton onClick={() => saveTemplate(template)}>
                      Save Changes
                    </ConfirmChangesButton>
                  </ButtonSection>
                )}
              </Col>
            </Row>
          </CenteredContainer>
        </div>
      )}
    </>
  );
}

export default UIDTemplateEditView;
