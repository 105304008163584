import isEqual from "lodash/isEqual";
import { memo, useMemo, useState } from "react";
import { BlueprintOperationType } from "../../../models/Blueprints";
import { BlueprintMeta, Integration } from "../../../models/Entities";
import {
  MappingTestBlock,
  MappingTestBlockExecution,
  MappingTestBlueprintsMetadata,
  MappingTestExistingCommonModels,
  MappingTestReadTestInformation,
  MappingTestRequestMock,
} from "../../../models/MappingTests";
import { NextComponentVersionState } from "../../integrations/versioned-components/types";
import { MappingTestExecution } from "../../../models/MappingTests";

import MappingTestV2BlocksContainer from "./MappingTestV2BlocksContainer";
import { MappingTestAssertionsProps } from "../root/MappingTestV2Editor";
import MappingTestRunWarningModal from "./MappingTestRunWarningModal";
import MappingTestV2CentralPanelHeader from "./MappingTestV2CentralPanelHeader";

type Props = {
  readTestInformation: undefined | MappingTestReadTestInformation;
  existingCommonModels: MappingTestExistingCommonModels | undefined;
  operationType: BlueprintOperationType;
  blueprints: BlueprintMeta[];
  draftBlueprints: BlueprintMeta[] | undefined;
  integration: Integration;
  isInitializingDisabled: boolean;
  mappingTestState: NextComponentVersionState;
  testExecutionResults: MappingTestExecution | undefined;
  mappingTestID: string;
  requests: MappingTestRequestMock[];
  blueprintsMetadata: MappingTestBlueprintsMetadata;
  linkedAccountID: string;
  updateMappingTestBPMetadata: (blueprintID: string, newMetaData: any) => void;
  editResponseMock: (apiRequestID: string, apiRequestBody: string) => void;
  resetApiRequest: (apiRequestID: string) => void;
  addMappingTestBlock: (mappingTestID: string) => void;
  pasteMappingTestBlock: (mappingTestID: string, mappingTestBlock: MappingTestBlock) => void;
  removeMappingTestBlock: (mappingTestID: string) => void;
  addBlueprintToMappingTestBlock: (mappingTestBlockID: string, blueprintID: string) => void;
  removeBlueprintFromMappingTestBlock: (mappingTestBlockID: string, blueprintID: string) => void;
  addRequestMockToMappingTestBlock: (
    mappingTestBlockID: string,
    blueprintID: string,
    requestMock: MappingTestRequestMock
  ) => void;
  removeRequestMockFromMappingTestBlock: (
    mappingTestBlockID: string,
    blueprintID: string,
    requestMock: MappingTestRequestMock
  ) => void;
  setOverrideLastRunAtValue: (
    mappingTestBlockID: string,
    blueprintID: string,
    overrideLastRunAtValue?: string | null
  ) => void;
  setDisableFilterByDateValue: (
    mappingTestBlockID: string,
    blueprintID: string,
    disableFilterByDateValue?: boolean
  ) => void;
  mappingTestBlocks: Array<MappingTestBlock>;
  runMappingTest: () => void;
  isRunningMappingTest: boolean;
  isTestRunningDisabled: boolean;
  setIsShowingRightPanel: React.Dispatch<React.SetStateAction<boolean>>;
  selectedBlockExecution: MappingTestBlockExecution | undefined;
  setSelectedBlockExecution: React.Dispatch<
    React.SetStateAction<MappingTestBlockExecution | undefined>
  >;
  setSelectedBlockName: React.Dispatch<React.SetStateAction<string | undefined>>;
  isExecutionFinished: boolean;
} & MappingTestAssertionsProps;

const MappingTestV2CentralPanel = ({
  readTestInformation,
  existingCommonModels,
  addMappingTestBlock,
  mappingTestBlocks,
  blueprints,
  draftBlueprints,
  mappingTestID,
  requests,
  addBlueprintToMappingTestBlock,
  removeMappingTestBlock,
  removeBlueprintFromMappingTestBlock,
  addRequestMockToMappingTestBlock,
  removeRequestMockFromMappingTestBlock,
  runMappingTest,
  isRunningMappingTest,
  isTestRunningDisabled,
  setOverrideLastRunAtValue,
  setDisableFilterByDateValue,
  pasteMappingTestBlock,
  setIsShowingRightPanel,
  selectedBlockExecution,
  setSelectedBlockExecution,
  setSelectedBlockName,
  isExecutionFinished,
  ...props
}: Props) => {
  const [showTestRunWarningModal, setShowTestRunWarningModal] = useState(false);
  const [mappingTestBlockIssues, setMappingTestBlockIssues] = useState<Record<number, string[]>>(
    {}
  );

  const blueprintsMap = useMemo(() => {
    const dictionary: { [id: string]: BlueprintMeta } = {};
    blueprints.forEach((blueprint) => {
      dictionary[blueprint.blueprint_id] = blueprint;
    });
    return dictionary;
  }, [blueprints]);

  const draftBlueprintsMap = useMemo(() => {
    const dictionary: { [id: string]: BlueprintMeta } = {};
    draftBlueprints?.forEach((blueprint) => {
      dictionary[blueprint.blueprint_id] = blueprint;
    });
    return dictionary;
  }, [draftBlueprints]);

  const requestsMap = useMemo(() => {
    const dictionary: { [id: string]: MappingTestRequestMock } = {};
    requests.forEach((request) => {
      dictionary[request.name!] = request;
    });
    return dictionary;
  }, [requests]);

  const checkMissingMockErrors = (currentMappingTestBlockIssues: Record<number, string[]>) => {
    mappingTestBlocks
      .filter(
        (block) =>
          Object.entries(block.blueprints_meta).filter(
            ([_, mappingTestBlockBlueprintsMeta]) =>
              mappingTestBlockBlueprintsMeta.request_mocks.length == 0
          ).length > 0
      )
      .map((block) => {
        const errorList = currentMappingTestBlockIssues[block.order] || [];
        errorList.push("- Missing request mocks");
        currentMappingTestBlockIssues[block.order] = errorList;
      });

    return currentMappingTestBlockIssues;
  };

  const checkMissingUniqueIdentifierErrors = (
    currentMappingTestBlockIssues: Record<number, string[]>
  ) => {
    mappingTestBlocks
      .filter(
        (block) =>
          Object.entries(block.common_model_mappings).filter(
            ([_, mappingTestCommonModelObject]) =>
              Object.entries(mappingTestCommonModelObject.individual_mappings).filter(
                ([_, mappingTestExpectedMapping]) => !mappingTestExpectedMapping.unique_identifier
              ).length > 0
          ).length > 0
      )
      .map((block) => {
        const errorList = currentMappingTestBlockIssues[block.order] || [];
        let error = "- Missing unique identifier in common model assertions for models: ";

        Object.entries(block.common_model_mappings).map(([_, mappingTestCommonModelObject]) => {
          error += Object.entries(mappingTestCommonModelObject.individual_mappings)
            .filter(
              ([_, mappingTestExpectedMapping]) => !mappingTestExpectedMapping.unique_identifier
            )
            .map(([name, _]) => name)
            .toString();
        });

        errorList.push(error);
        currentMappingTestBlockIssues[block.order] = errorList;
      });

    return currentMappingTestBlockIssues;
  };

  const runMappingTestWrapper = () => {
    let currentMappingTestBlockIssues: Record<number, string[]> = {};

    // Check for missing mock requests
    currentMappingTestBlockIssues = checkMissingMockErrors(currentMappingTestBlockIssues);

    // Check for missing unique identifiers
    currentMappingTestBlockIssues = checkMissingUniqueIdentifierErrors(
      currentMappingTestBlockIssues
    );

    setMappingTestBlockIssues(currentMappingTestBlockIssues);

    if (Object.entries(currentMappingTestBlockIssues).length > 0) {
      setShowTestRunWarningModal(true);
    } else {
      runMappingTest();
    }
  };

  return (
    <div className="bg-gray-0 h-full flex flex-column border p-0 m-0">
      <MappingTestRunWarningModal
        show={showTestRunWarningModal}
        title="Test Run Warning"
        mappingTestBlockIssues={mappingTestBlockIssues}
        confirmText="Run anyway"
        rejectText="Go back"
        onHide={() => {
          setShowTestRunWarningModal(false);
        }}
        onConfirm={() => {
          runMappingTest();
          setShowTestRunWarningModal(false);
        }}
      />
      <MappingTestV2CentralPanelHeader
        isExecutionFinished={isExecutionFinished}
        testExecutionResults={props.testExecutionResults}
        runMappingTestWrapper={runMappingTestWrapper}
        setIsShowingRightPanel={setIsShowingRightPanel}
        isRunningMappingTest={isRunningMappingTest}
        isTestRunningDisabled={isTestRunningDisabled}
      />
      <MappingTestV2BlocksContainer
        readTestInformation={readTestInformation}
        existingCommonModels={existingCommonModels}
        isExecutionFinished={isExecutionFinished}
        addMappingTestBlock={addMappingTestBlock}
        pasteMappingTestBlock={pasteMappingTestBlock}
        mappingTestBlocks={mappingTestBlocks}
        blueprintsMap={blueprintsMap}
        draftBlueprintsMap={draftBlueprintsMap}
        requestsMap={requestsMap}
        mappingTestID={mappingTestID}
        addBlueprintToMappingTestBlock={addBlueprintToMappingTestBlock}
        removeMappingTestBlock={removeMappingTestBlock}
        removeBlueprintFromMappingTestBlock={removeBlueprintFromMappingTestBlock}
        addRequestMockToMappingTestBlock={addRequestMockToMappingTestBlock}
        removeRequestMockFromMappingTestBlock={removeRequestMockFromMappingTestBlock}
        setOverrideLastRunAtValue={setOverrideLastRunAtValue}
        setDisableFilterByDateValue={setDisableFilterByDateValue}
        isRunningMappingTest={isRunningMappingTest}
        setIsShowingRightPanel={setIsShowingRightPanel}
        selectedBlockExecution={selectedBlockExecution}
        setSelectedBlockExecution={setSelectedBlockExecution}
        setSelectedBlockName={setSelectedBlockName}
        {...props}
      />
    </div>
  );
};

export default memo(MappingTestV2CentralPanel, isEqual);
