import SpinnerButton from "../../shared/SpinnerButton";
import { useState } from "react";
import { fetchWithAuth } from "../../../api-client/api_client";
import { showErrorToast, showSuccessToast } from "../../shared/Toasts";
import { callDeleteTestAuthConfig } from "./AuthConfigShared";
import { TestRunResponse } from "../../shared/TestRunResponseBlock";

interface Props {
  testAuthConfigID: string;
  testLinkedAccountID: string | undefined;
  integrationID: string;
  setTestRunResponses: (testRunResponses: TestRunResponse[] | undefined) => void;
  setLinkedAccountID: (linkedAccountID: string | undefined) => void;
  setTestAuthConfigID: (testAuthConfigID: string | undefined) => void;
  setAreLogsLoading: (areLogsLoading: boolean) => void;
  setTestOAuthAuthorizeURL: (testOAuthAuthorizeURL: string | undefined) => void;
}

const AuthConfigTestCleanupButton = ({
  testAuthConfigID,
  testLinkedAccountID,
  integrationID,
  setTestRunResponses,
  setLinkedAccountID,
  setTestAuthConfigID,
  setAreLogsLoading,
  setTestOAuthAuthorizeURL,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const _deleteLinkedAccount = (linkedAccountID: string) => {
    fetchWithAuth({
      path: `/integrations/linked-accounts/${linkedAccountID}`,
      method: "DELETE",
      onResponse: () => {
        showSuccessToast(`Deleted linked account!`);
      },
      onError: () => {
        showErrorToast("Failed to delete linked account. Please manually delete this account.");
      },
    });
  };

  const _deleteTestAuthConfig = () => {
    if (testAuthConfigID) {
      callDeleteTestAuthConfig(
        integrationID,
        testAuthConfigID,
        () => {
          showSuccessToast("Successfully deleted the test Auth Config.");

          setIsLoading(false);
          setTestAuthConfigID(undefined);
          setTestRunResponses(undefined);
          setLinkedAccountID(undefined);
          setTestOAuthAuthorizeURL(undefined);
          setAreLogsLoading(false);
        },
        () => {
          showErrorToast(
            `Failed to delete test auth config, please delete ID ${testAuthConfigID}.`
          );

          setIsLoading(false);
          setTestRunResponses(undefined);
          setLinkedAccountID(undefined);
          setTestOAuthAuthorizeURL(undefined);
          setAreLogsLoading(false);
        }
      );
    }
  };

  const cleanupTestAuthConfigData = () => {
    // Call the endpoint to create a test auth config with the existing values
    setIsLoading(true);
    if (testLinkedAccountID) {
      _deleteLinkedAccount(testLinkedAccountID);
    }
    _deleteTestAuthConfig();
  };

  return (
    <SpinnerButton
      text="Cleanup Test Data"
      isLoading={isLoading}
      className="btn-block btn-secondary mt-1.5"
      onClick={() => cleanupTestAuthConfigData()}
      type="reset"
    />
  );
};

export default AuthConfigTestCleanupButton;
