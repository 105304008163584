import { AlertTriangle, CircleCheck } from "lucide-react";
import HeaderBase from "../../shared/HeaderBase";
import {
  EndpointCollectionRun,
  EndpointCollectionRunNotificationStatus,
  EndpointCollectionRunStatus,
} from "../../utils/Entities";
import { Button, ButtonVariant, Dialog, Text } from "@merge-api/merge-javascript-shared";
import usePatchEndpointCollectionRun from "../hooks/usePatchEndpointCollectionRun";
import { useCallback, useState } from "react";
import { getIntegrationBuilderAPIEndpointsForId } from "../../../../router/RouterUtils";
import { useHistory } from "react-router-dom";
import useAbortEndpointCollectionRun from "../hooks/useAbortEndpointCollectionRun";
import { showErrorToast } from "../../../shared/Toasts";

interface EndpointCollectionRunCardProps {
  integrationID: string;
  endpointCollectionRun: EndpointCollectionRun;
  setEndpointCollectionRun: React.Dispatch<React.SetStateAction<EndpointCollectionRun | undefined>>;
  fetchEndpointCollectionRun: () => void;
}

const EndpointCollectionRunCard = ({
  integrationID,
  endpointCollectionRun,
  setEndpointCollectionRun,
  fetchEndpointCollectionRun,
}: EndpointCollectionRunCardProps) => {
  // state
  const [isAbortModalOpen, setIsAbortModalOpen] = useState(false);

  // hooks
  const history = useHistory();
  const { patchEndpointCollectionRun } = usePatchEndpointCollectionRun({
    endpointCollectionRunID: endpointCollectionRun.id,
  });
  const { abortEndpointCollectionRun } = useAbortEndpointCollectionRun({
    collection_run_id: endpointCollectionRun.id,
  });

  // handlers
  const onViewEndpoints = () => {
    history.push({
      pathname: getIntegrationBuilderAPIEndpointsForId(integrationID),
    });
  };
  const onAbortResponse = () => {
    setIsAbortModalOpen(false);
    fetchEndpointCollectionRun();
  };
  const onAbortError = () => {
    setIsAbortModalOpen(false);
    showErrorToast("Failed to abort the parsing process.");
  };

  const onAbort = () => {
    abortEndpointCollectionRun(onAbortResponse, onAbortError);
  };

  // change the notification status of the endpoint collection run to dismissed and clear the endpoint collection run
  const onDismiss = useCallback(() => {
    patchEndpointCollectionRun(EndpointCollectionRunNotificationStatus.DISMISSED);
    setEndpointCollectionRun(undefined);
  }, [patchEndpointCollectionRun, setEndpointCollectionRun]);

  // component rendering
  const renderSuccess = useCallback(() => {
    return (
      <div className="space-y-2">
        <div className="bg-gray-0 rounded-md flex flex-row items-center justify-between p-2">
          <div className="flex flex-row items-center">
            <CircleCheck className="mr-2 text-teal-70" size={16} />
            <Text>{`${endpointCollectionRun.endpoint_preview_count ?? 0} endpoints found`}</Text>
          </div>
          <Button
            variant={ButtonVariant.TextBlue}
            className="mr-1"
            size="sm"
            onClick={onViewEndpoints}
          >
            View
          </Button>
        </div>
        {endpointCollectionRun.visited_urls && endpointCollectionRun.visited_urls.length > 0 && (
          <div className="bg-gray-0 rounded-md">
            <div className="flex flex-row items-center p-2">
              <CircleCheck className="mr-2 text-teal-70" size={16} />
              <Text>{`${endpointCollectionRun.visited_urls.length} URLs crawled`}</Text>
            </div>
            <div className="flex flex-col max-h-[100px] overflow-auto space-y-2 p-2">
              {endpointCollectionRun.visited_urls.map((url: string) => (
                <Text key={url}>{url}</Text>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }, [endpointCollectionRun]);

  const renderSearchingForEndpoints = () => {
    return (
      <div className="p-4 bg-gray-0 rounded-md flex flex-row items-center justify-between">
        <Text className="text-gray-70">Searching for endpoints...</Text>
        <Button
          size="sm"
          variant={ButtonVariant.DangerText}
          onClick={() => setIsAbortModalOpen(true)}
        >
          Abort
        </Button>
      </div>
    );
  };

  const renderError = useCallback(() => {
    return (
      <div className="p-4 bg-red-0 rounded-md flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <AlertTriangle className="mr-4 text-red-50" size={16} />
          <Text>{`Unable to parse through URL ${
            endpointCollectionRun.api_documentation_url
          }. Check the link to ensure it’s correct and try again. Exception Message: ${
            endpointCollectionRun.exception_message ?? "None"
          }`}</Text>
        </div>
        <Button variant={ButtonVariant.TertiaryWhite} size="sm" onClick={onDismiss}>
          Dismiss
        </Button>
      </div>
    );
  }, [endpointCollectionRun, onDismiss]);

  const renderCard = useCallback(() => {
    switch (endpointCollectionRun.status) {
      case EndpointCollectionRunStatus.SUCCESS:
        return renderSuccess();
      case EndpointCollectionRunStatus.PENDING:
      case EndpointCollectionRunStatus.RUNNING:
        return renderSearchingForEndpoints();
      case EndpointCollectionRunStatus.FAILURE:
      case EndpointCollectionRunStatus.ABORTED:
        return renderError();
      default:
        return null;
    }
  }, [endpointCollectionRun, renderError, renderSearchingForEndpoints, renderSuccess]);

  return (
    <div>
      <HeaderBase title="Parsing History" />
      {renderCard()}
      <Dialog
        title="Abort parsing"
        open={isAbortModalOpen}
        onPrimaryButtonClick={onAbort}
        onSecondaryButtonClick={() => setIsAbortModalOpen(false)}
        onClose={() => setIsAbortModalOpen(false)}
      >
        You are about to abort the parsing process. Are you sure you want to continue?
      </Dialog>
    </div>
  );
};

export default EndpointCollectionRunCard;
