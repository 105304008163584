export enum StepCardStatusBadgeTypeEnum {
  WARNING = "WARNING",
  ERROR = "ERROR",
}

export interface StepCardStatusBadgeProps {
  type: StepCardStatusBadgeTypeEnum;
  tooltipTitle: string;
  badgeTitle: string;
}
