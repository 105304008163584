import styled from "styled-components";
import { BlueprintParameterSchemaValue } from "../../../models/Blueprints";
import {
  CommonModelMappingMissInfo,
  MappingTestBodyParameters,
} from "../../../models/MappingTests";
import BodyParameterField from "../left-panel/BodyParameterField";
import { getEmptyValueForField } from "../utils/MappingTestBuildingUtils";
import MappingTestEditorCommonModelExpectedMappingRow from "./MappingTestEditorCommonModelExpectedMappingRow";
import { useCallback } from "react";
import MappingTestEditorCommonModelExpectedMappingSectionHeader from "./MappingTestEditorCommonModelExpectedMappingSectionHeader";
import { isParentedNestedWrite } from "../utils/helpers";

type Props = {
  fieldName: string;
  value: string | Record<string, any>;
  bodyParameters: MappingTestBodyParameters;
  missedMappingInfo?: CommonModelMappingMissInfo;
  availableRelationLookupDict: { [commonModelID: string]: Array<string> };
  setValue: (value: string | Record<string, any>) => void;
  isDebugMode: boolean;
  availableRelationNames: undefined | Array<string>;
  itemSchema: BlueprintParameterSchemaValue;
  fieldSchema: undefined | BlueprintParameterSchemaValue;
  fieldKeyPath: Array<string>;
  shouldManualInputSubObject: boolean;
  onUpdate: (args: { fieldKeyPath: Array<string>; newFieldValue: any }) => void;
  commonModelName: string;
};

type MappingMissProps = {
  hasMappingMisses?: boolean;
};

const FieldDropdown = styled.select<MappingMissProps>`
  background: ${({ hasMappingMisses }) => (hasMappingMisses ? "#FFE9EC" : "#eff2f4")}};
  color: ${({ hasMappingMisses }) => (hasMappingMisses ? "#F00424" : "")}}; 
  border: 0;
  border-bottom: ${({ hasMappingMisses }) =>
    hasMappingMisses ? "1.5px solid #FFA2A2" : "1.5px solid #dce4f0"}};
`;

const MappingTestEditorCommonModelExpectedMappingFieldInputObject = ({
  fieldName,
  fieldSchema,
  itemSchema,
  value,
  bodyParameters,
  availableRelationLookupDict,
  fieldKeyPath,
  onUpdate,
  setValue,
  shouldManualInputSubObject,
  availableRelationNames,
  commonModelName,
}: Props) => {
  const updateValue = useCallback((newValue: string) => setValue(newValue), [setValue]);

  const items = Object.entries(fieldSchema?.properties ?? {});

  // calculate nested object's required fields
  const nestedObjectRequiredFields = fieldSchema?.required ?? [];

  return (
    <>
      <MappingTestEditorCommonModelExpectedMappingRow key={"input"} isRowShaded={true}>
        {availableRelationNames && !shouldManualInputSubObject ? (
          <div className="ml-auto">
            <FieldDropdown onChange={(e) => updateValue(e.target.value)} value={value as string}>
              <>
                <option key={"None"} value={getEmptyValueForField(itemSchema)}>
                  {"None"}
                </option>
                {availableRelationNames.map((name) => (
                  <option key={name} value={`$\{${name}}`}>
                    {name}
                  </option>
                ))}
              </>
            </FieldDropdown>
          </div>
        ) : (
          <div className="flex flex-col justify-end w-3/4 ml-auto">
            <MappingTestEditorCommonModelExpectedMappingSectionHeader commonModelName={fieldName} />
            {items.map(
              ([fieldKey, fieldSchema], index) =>
                !isParentedNestedWrite(fieldKey, commonModelName) && (
                  <BodyParameterField
                    commonModelName={commonModelName}
                    bodyParameters={bodyParameters}
                    availableRelationLookupDict={availableRelationLookupDict}
                    key={fieldKey}
                    fieldKey={fieldKey}
                    fieldSchema={fieldSchema}
                    index={index}
                    fieldKeyPath={[...fieldKeyPath, fieldKey]}
                    root={"model"}
                    onUpdate={onUpdate}
                    isRequired={nestedObjectRequiredFields?.includes(fieldKey)}
                    isNestedWriteField={true}
                  />
                )
            )}
          </div>
        )}
      </MappingTestEditorCommonModelExpectedMappingRow>
    </>
  );
};

export default MappingTestEditorCommonModelExpectedMappingFieldInputObject;
