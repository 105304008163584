import { useCallback, useEffect, useMemo, useState } from "react";
import {
  RateLimitConfigurationIntegrationBuilder,
  RateLimitResetTimestampFormat,
  RateLimitTimePeriod,
  RateLimitType,
} from "../../utils/Entities";
import RateLimitConfigurationContext from "./RateLimitConfigurationContext";
import {
  isValidNumber,
  roundPercentValue,
  transformRateLimitConfigurationDataForDiffModal,
  validateHeadersSection,
  validateRateLimitConfigurationInput,
} from "../utils/helpers";
import useIntegrationBuilderContext from "../../context/useIntegrationBuilderContext";
import { DiffModelTypeEnum } from "../../../../models/DiffModels";

interface RateLimitConfigurationContextProviderProps {
  children: JSX.Element;
  selectedRateLimitConfiguration: RateLimitConfigurationIntegrationBuilder | undefined;
  integrationID: string;
}

const RateLimitConfigurationContextProvider = ({
  children,
  selectedRateLimitConfiguration,
  integrationID,
}: RateLimitConfigurationContextProviderProps) => {
  const {
    setCurrentStateForDiff,
    setNewStateForDiff,
    modelTypeForDiff,
  } = useIntegrationBuilderContext();

  // Initialize states
  const initializeApiEndpointID = selectedRateLimitConfiguration?.api_endpoint ?? undefined;
  const initializeIsActive = selectedRateLimitConfiguration?.is_active ?? true;
  const initializeRateLimitType =
    selectedRateLimitConfiguration?.rate_limit_type ?? RateLimitType.REQUEST_FREQUENCY;
  const initializeRateLimitTimePeriod =
    selectedRateLimitConfiguration?.rate_limit_time_period ?? RateLimitTimePeriod.SECONDS;
  const initializeRateLimitTimeValue =
    selectedRateLimitConfiguration?.rate_limit_time_value ?? undefined;
  const initializeRateLimitMaxCount =
    selectedRateLimitConfiguration?.default_rate_limit_max_count ?? undefined;
  const initializeRateLimitMaxPoints =
    selectedRateLimitConfiguration?.default_rate_limit_max_points ?? undefined;
  const initializeRateLimitThreshold =
    selectedRateLimitConfiguration?.default_rate_limit_threshold != null
      ? roundPercentValue(selectedRateLimitConfiguration.default_rate_limit_threshold)
      : undefined;
  const initializeShouldSetLimitMaxCountViaHeaders =
    selectedRateLimitConfiguration?.should_set_limit_max_count_via_headers ?? false;
  const initializeResponseHeaderMaxCountKeyPath =
    selectedRateLimitConfiguration?.response_header_max_count_key_path ?? [];
  const initializeShouldUpdateRateLimitCurrentCountViaHeaders =
    selectedRateLimitConfiguration?.should_update_rate_limit_current_count_via_headers ?? false;
  const initializeResponseHeaderRateLimitCurrentCountKeyPath =
    selectedRateLimitConfiguration?.response_header_rate_limit_current_count_key_path ?? [];
  const initializeShouldUpdateRateLimitCountLeftViaHeaders =
    selectedRateLimitConfiguration?.should_update_rate_limit_count_left_via_headers ?? false;
  const initializeResponseHeaderRateLimitCountLeftKeyPath =
    selectedRateLimitConfiguration?.response_header_rate_limit_count_left_key_path ?? [];
  const initializeShouldUpdateResetTimestampKeyPathViaHeaders =
    !!selectedRateLimitConfiguration?.response_header_rate_limit_reset_timestamp_key_path &&
    selectedRateLimitConfiguration?.response_header_rate_limit_reset_timestamp_key_path.length > 0;
  const initializeResponseHeaderResetTimestampKeyPath =
    selectedRateLimitConfiguration?.response_header_rate_limit_reset_timestamp_key_path ?? [];
  const initializeResetExpirationFormat =
    selectedRateLimitConfiguration?.reset_expiration_format ?? undefined;
  const initializeAggressiveRateLimitThreshold =
    selectedRateLimitConfiguration?.aggressive_rate_limit_threshold != null
      ? roundPercentValue(selectedRateLimitConfiguration.aggressive_rate_limit_threshold)
      : undefined;
  const initializeDefaultIncrement = selectedRateLimitConfiguration?.default_increment ?? 1;
  const initializeMaximumRetryCount =
    selectedRateLimitConfiguration?.maximum_retry_count ?? undefined;
  const initializeRetryBackoffFactor =
    selectedRateLimitConfiguration?.retry_backoff_factor ?? undefined;

  // TO-DO api endpoints
  const [apiEndpointID, setAPIEndpointID] = useState<string | undefined>(initializeApiEndpointID);

  // table view
  const [isActive, setIsActive] = useState<boolean>(initializeIsActive);

  // core configuration
  const [rateLimitType, setRateLimitType] = useState<RateLimitType>(initializeRateLimitType);

  const [rateLimitTimePeriod, setRateLimitTimePeriod] = useState<RateLimitTimePeriod>(
    initializeRateLimitTimePeriod
  );

  const [rateLimitTimeValue, setRateLimitTimeValue] = useState<number | undefined>(
    initializeRateLimitTimeValue
  );

  const [rateLimitMaxCount, setRateLimitMaxCount] = useState<number | undefined>(
    initializeRateLimitMaxCount
  );

  const [rateLimitMaxPoints, setRateLimitMaxPoints] = useState<number | undefined>(
    initializeRateLimitMaxPoints
  );

  const [rateLimitThreshold, setRateLimitThreshold] = useState<number | undefined>(
    initializeRateLimitThreshold
  );

  // updated via headers
  // max count from headers, max count key path
  const [shouldSetLimitMaxCountViaHeaders, setShouldSetLimitMaxCountViaHeaders] = useState<boolean>(
    initializeShouldSetLimitMaxCountViaHeaders
  );
  const [responseHeaderMaxCountKeyPath, setResponseHeaderMaxCountKeyPath] = useState<
    string[] | null
  >(initializeResponseHeaderMaxCountKeyPath);

  // current count from headers, current count key path
  const [
    shouldUpdateRateLimitCurrentCountViaHeaders,
    setShouldUpdateRateLimitCurrentCountViaHeaders,
  ] = useState<boolean>(initializeShouldUpdateRateLimitCurrentCountViaHeaders);
  const [
    responseHeaderRateLimitCurrentCountKeyPath,
    setResponseHeaderRateLimitCurrentCountKeyPath,
  ] = useState<string[] | null>(initializeResponseHeaderRateLimitCurrentCountKeyPath);
  // current count left from headers, current count left key path
  const [
    shouldUpdateRateLimitCountLeftViaHeaders,
    setShouldUpdateRateLimitCountLeftViaHeaders,
  ] = useState<boolean>(initializeShouldUpdateRateLimitCountLeftViaHeaders);
  const [
    responseHeaderRateLimitCountLeftKeyPath,
    setResponseHeaderRateLimitCountLeftKeyPath,
  ] = useState<string[] | null>(initializeResponseHeaderRateLimitCountLeftKeyPath);

  // there is no "should" equivalent for this section in the backend, so we have this boolean to render it instead to maintain consistency with the rest of the section
  const [
    shouldUpdateResetTimestampKeyPathViaHeaders,
    setShouldUpdateResetTimestampKeyPathViaHeaders,
  ] = useState<boolean>(initializeShouldUpdateResetTimestampKeyPathViaHeaders);

  const [responseHeaderResetTimestampKeyPath, setResponseHeaderResetTimestampKeyPath] = useState<
    string[] | null
  >(initializeResponseHeaderResetTimestampKeyPath);

  const [resetExpirationFormat, setResetExpirationFormat] = useState<
    RateLimitResetTimestampFormat | undefined
  >(initializeResetExpirationFormat);

  // advanced
  const [aggressiveRateLimitThreshold, setAggressiveRateLimitThreshold] = useState<
    number | undefined
  >(initializeAggressiveRateLimitThreshold);

  const [defaultIncrement, setDefaultIncrement] = useState<number | undefined>(
    initializeDefaultIncrement
  );
  const [maximumRetryCount, setMaximumRetryCount] = useState<number | undefined>(
    initializeMaximumRetryCount
  );

  const [retryBackoffFactor, setRetryBackoffFactor] = useState<number | undefined>(
    initializeRetryBackoffFactor
  );

  // Set states based on selected rate limit configuration - this is required for reloading the page
  useEffect(() => {
    setAPIEndpointID(initializeApiEndpointID);
    setIsActive(initializeIsActive);
    setRateLimitType(initializeRateLimitType);
    setRateLimitTimePeriod(initializeRateLimitTimePeriod);
    setRateLimitTimeValue(initializeRateLimitTimeValue);
    setRateLimitMaxCount(initializeRateLimitMaxCount);
    setRateLimitMaxPoints(initializeRateLimitMaxPoints);
    setRateLimitThreshold(initializeRateLimitThreshold);
    setMaximumRetryCount(initializeMaximumRetryCount);
    setRetryBackoffFactor(initializeRetryBackoffFactor);
    setShouldSetLimitMaxCountViaHeaders(initializeShouldSetLimitMaxCountViaHeaders);
    setResponseHeaderMaxCountKeyPath(initializeResponseHeaderMaxCountKeyPath);
    setShouldUpdateRateLimitCurrentCountViaHeaders(
      initializeShouldUpdateRateLimitCurrentCountViaHeaders
    );
    setResponseHeaderRateLimitCurrentCountKeyPath(
      initializeResponseHeaderRateLimitCurrentCountKeyPath
    );
    setShouldUpdateRateLimitCountLeftViaHeaders(initializeShouldUpdateRateLimitCountLeftViaHeaders);
    setResponseHeaderRateLimitCountLeftKeyPath(initializeResponseHeaderRateLimitCountLeftKeyPath);
    setShouldUpdateResetTimestampKeyPathViaHeaders(
      initializeShouldUpdateResetTimestampKeyPathViaHeaders
    );
    setResponseHeaderResetTimestampKeyPath(initializeResponseHeaderResetTimestampKeyPath);
    setResetExpirationFormat(initializeResetExpirationFormat);
    setAggressiveRateLimitThreshold(initializeAggressiveRateLimitThreshold);
    setDefaultIncrement(initializeDefaultIncrement);
  }, [selectedRateLimitConfiguration]);

  /* This useEffect initializes the model state to be used in the diff modal.
   * When adding/removing fields, you must update helpers-rate-limit-config-diff.ts
   * to ensure that these state changes get effectively captured by diff modal.
   */
  useEffect(() => {
    if (modelTypeForDiff !== DiffModelTypeEnum.API_ENDPOINT) {
      const data: RateLimitConfigurationIntegrationBuilder = {
        id: selectedRateLimitConfiguration?.id,
        api_endpoint: initializeApiEndpointID,
        is_active: initializeIsActive,
        rate_limit_type: initializeRateLimitType,
        rate_limit_time_period: initializeRateLimitTimePeriod,
        rate_limit_time_value: initializeRateLimitTimeValue,
        default_rate_limit_max_count: initializeRateLimitMaxCount ?? null,
        default_rate_limit_max_points: initializeRateLimitMaxPoints ?? null,
        default_rate_limit_threshold: initializeRateLimitThreshold
          ? initializeRateLimitThreshold / 100
          : undefined,

        // HEADERS SECTION
        should_set_limit_max_count_via_headers: initializeShouldSetLimitMaxCountViaHeaders,
        response_header_max_count_key_path: initializeShouldSetLimitMaxCountViaHeaders
          ? initializeResponseHeaderMaxCountKeyPath
          : [],
        should_update_rate_limit_current_count_via_headers: initializeShouldUpdateRateLimitCurrentCountViaHeaders,
        response_header_rate_limit_current_count_key_path: initializeShouldUpdateRateLimitCurrentCountViaHeaders
          ? initializeResponseHeaderRateLimitCurrentCountKeyPath
          : [],
        should_update_rate_limit_count_left_via_headers: initializeShouldUpdateRateLimitCountLeftViaHeaders,
        response_header_rate_limit_count_left_key_path: initializeShouldUpdateRateLimitCountLeftViaHeaders
          ? initializeResponseHeaderRateLimitCountLeftKeyPath
          : [],
        reset_expiration_format: initializeShouldUpdateResetTimestampKeyPathViaHeaders
          ? initializeResetExpirationFormat
          : null,
        response_header_rate_limit_reset_timestamp_key_path: initializeShouldUpdateResetTimestampKeyPathViaHeaders
          ? initializeResponseHeaderResetTimestampKeyPath ?? []
          : [],

        // ADVANCED SECTION
        aggressive_rate_limit_threshold: initializeAggressiveRateLimitThreshold
          ? initializeAggressiveRateLimitThreshold / 100
          : null,
        default_increment: initializeDefaultIncrement ?? 1,
        maximum_retry_count: initializeMaximumRetryCount ?? null,
        retry_backoff_factor: initializeRetryBackoffFactor ?? null,
      };
      const dataWithHelperFields = transformRateLimitConfigurationDataForDiffModal(data);
      setCurrentStateForDiff(dataWithHelperFields);
      setNewStateForDiff(dataWithHelperFields);
    }
  }, [modelTypeForDiff, selectedRateLimitConfiguration]);

  // form data for submission - all values should be in dependency array to ensure that the data is updated when the values change
  const formRateLimitConfigurationData = useCallback(() => {
    const data: RateLimitConfigurationIntegrationBuilder = {
      id: selectedRateLimitConfiguration?.id,
      api_endpoint: apiEndpointID,
      is_active: isActive,
      rate_limit_type: rateLimitType,
      rate_limit_time_period:
        rateLimitType === RateLimitType.CONNECTION_COUNT
          ? RateLimitTimePeriod.MINUTES
          : rateLimitTimePeriod,
      rate_limit_time_value:
        rateLimitType === RateLimitType.CONNECTION_COUNT ? 1 : rateLimitTimeValue,
      default_rate_limit_max_count: rateLimitMaxCount ?? null,
      default_rate_limit_max_points: rateLimitMaxPoints ?? null,
      default_rate_limit_threshold: rateLimitThreshold ? rateLimitThreshold / 100 : undefined,

      // HEADERS SECTION
      should_set_limit_max_count_via_headers: shouldSetLimitMaxCountViaHeaders,
      response_header_max_count_key_path: shouldSetLimitMaxCountViaHeaders
        ? responseHeaderMaxCountKeyPath
        : [],
      should_update_rate_limit_current_count_via_headers: shouldUpdateRateLimitCurrentCountViaHeaders,
      response_header_rate_limit_current_count_key_path: shouldUpdateRateLimitCurrentCountViaHeaders
        ? responseHeaderRateLimitCurrentCountKeyPath
        : [],
      should_update_rate_limit_count_left_via_headers: shouldUpdateRateLimitCountLeftViaHeaders,
      response_header_rate_limit_count_left_key_path: shouldUpdateRateLimitCountLeftViaHeaders
        ? responseHeaderRateLimitCountLeftKeyPath
        : [],
      reset_expiration_format: shouldUpdateResetTimestampKeyPathViaHeaders
        ? resetExpirationFormat
        : null,
      response_header_rate_limit_reset_timestamp_key_path: shouldUpdateResetTimestampKeyPathViaHeaders
        ? responseHeaderResetTimestampKeyPath ?? []
        : [],

      // ADVANCED SECTION
      aggressive_rate_limit_threshold: aggressiveRateLimitThreshold
        ? aggressiveRateLimitThreshold / 100
        : null,
      default_increment: defaultIncrement ?? 1,
      maximum_retry_count: maximumRetryCount ?? null,
      retry_backoff_factor: retryBackoffFactor ?? null,
    };
    return data;
  }, [
    isActive,
    apiEndpointID,
    rateLimitType,
    rateLimitTimePeriod,
    rateLimitTimeValue,
    rateLimitMaxCount,
    rateLimitMaxPoints,
    rateLimitThreshold,
    maximumRetryCount,
    retryBackoffFactor,
    shouldSetLimitMaxCountViaHeaders,
    responseHeaderMaxCountKeyPath,
    shouldUpdateRateLimitCurrentCountViaHeaders,
    responseHeaderRateLimitCurrentCountKeyPath,
    shouldUpdateRateLimitCountLeftViaHeaders,
    responseHeaderRateLimitCountLeftKeyPath,
    shouldUpdateResetTimestampKeyPathViaHeaders,
    resetExpirationFormat,
    responseHeaderResetTimestampKeyPath,
    aggressiveRateLimitThreshold,
    defaultIncrement,
    resetExpirationFormat,
  ]);

  // validation for submission for rate limit threshold forms
  const canSubmitRateLimitConfiguration = useMemo(() => {
    // BASIC RATE LIMIT FORM SECTION
    const isRateLimitPointsValid =
      rateLimitType === RateLimitType.COMPLEXITY_COUNT &&
      validateRateLimitConfigurationInput(rateLimitMaxPoints);
    const isRateLimitCountValid =
      rateLimitType !== RateLimitType.COMPLEXITY_COUNT &&
      validateRateLimitConfigurationInput(rateLimitType) &&
      validateRateLimitConfigurationInput(rateLimitMaxCount);

    const isConfigureRateLimitSectionValid =
      (isRateLimitPointsValid || isRateLimitCountValid) &&
      (rateLimitType === RateLimitType.CONNECTION_COUNT ||
        (validateRateLimitConfigurationInput(rateLimitTimePeriod) &&
          validateRateLimitConfigurationInput(rateLimitTimeValue))) &&
      rateLimitThreshold &&
      isValidNumber(rateLimitThreshold, 80);

    if (!isConfigureRateLimitSectionValid) {
      return false;
    }

    // HEADERS SECTION
    const isHeadersSectionValid = validateHeadersSection(
      shouldSetLimitMaxCountViaHeaders,
      shouldUpdateRateLimitCurrentCountViaHeaders,
      shouldUpdateRateLimitCountLeftViaHeaders,
      shouldUpdateResetTimestampKeyPathViaHeaders,
      responseHeaderMaxCountKeyPath,
      responseHeaderRateLimitCurrentCountKeyPath,
      responseHeaderRateLimitCountLeftKeyPath,
      responseHeaderResetTimestampKeyPath,
      resetExpirationFormat
    );
    if (!isHeadersSectionValid) {
      return false;
    }
    // ADVANCED SECTION
    const isAdvancedSectionValid =
      validateRateLimitConfigurationInput(defaultIncrement) &&
      (aggressiveRateLimitThreshold === undefined ||
        isValidNumber(aggressiveRateLimitThreshold, 90));
    if (!isAdvancedSectionValid) {
      return false;
    }
    return true;
  }, [
    isActive,
    apiEndpointID,
    rateLimitType,
    rateLimitTimePeriod,
    rateLimitTimeValue,
    rateLimitMaxCount,
    rateLimitMaxPoints,
    rateLimitThreshold,
    maximumRetryCount,
    retryBackoffFactor,
    shouldSetLimitMaxCountViaHeaders,
    responseHeaderMaxCountKeyPath,
    shouldUpdateRateLimitCurrentCountViaHeaders,
    responseHeaderRateLimitCurrentCountKeyPath,
    shouldUpdateRateLimitCountLeftViaHeaders,
    responseHeaderRateLimitCountLeftKeyPath,
    shouldUpdateResetTimestampKeyPathViaHeaders,
    resetExpirationFormat,
    responseHeaderResetTimestampKeyPath,
    aggressiveRateLimitThreshold,
    defaultIncrement,
  ]);
  return (
    <RateLimitConfigurationContext.Provider
      value={{
        integrationID,
        apiEndpointID,
        setAPIEndpointID,
        isActive,
        setIsActive,
        rateLimitType,
        setRateLimitType,
        rateLimitTimePeriod,
        setRateLimitTimePeriod,
        rateLimitTimeValue,
        setRateLimitTimeValue,
        rateLimitMaxCount,
        setRateLimitMaxCount,
        rateLimitMaxPoints,
        setRateLimitMaxPoints,
        rateLimitThreshold,
        setRateLimitThreshold,
        maximumRetryCount,
        setMaximumRetryCount,
        retryBackoffFactor,
        setRetryBackoffFactor,
        shouldSetLimitMaxCountViaHeaders,
        setShouldSetLimitMaxCountViaHeaders,
        responseHeaderMaxCountKeyPath,
        setResponseHeaderMaxCountKeyPath,
        shouldUpdateRateLimitCurrentCountViaHeaders,
        setShouldUpdateRateLimitCurrentCountViaHeaders,
        responseHeaderRateLimitCurrentCountKeyPath,
        setResponseHeaderRateLimitCurrentCountKeyPath,
        shouldUpdateRateLimitCountLeftViaHeaders,
        setShouldUpdateRateLimitCountLeftViaHeaders,
        responseHeaderRateLimitCountLeftKeyPath,
        setResponseHeaderRateLimitCountLeftKeyPath,
        shouldUpdateResetTimestampKeyPathViaHeaders,
        setShouldUpdateResetTimestampKeyPathViaHeaders,
        responseHeaderResetTimestampKeyPath,
        setResponseHeaderResetTimestampKeyPath,
        resetExpirationFormat,
        setResetExpirationFormat,
        aggressiveRateLimitThreshold,
        setAggressiveRateLimitThreshold,
        defaultIncrement,
        setDefaultIncrement,
        formRateLimitConfigurationData,
        canSubmitRateLimitConfiguration,
      }}
    >
      {children}
    </RateLimitConfigurationContext.Provider>
  );
};
export default RateLimitConfigurationContextProvider;
