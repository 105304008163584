import clsx from "clsx";
import {
  BlueprintCanvasViewableStepLogBase,
  StepLogIterationInfo,
} from "../../../../models/Blueprints";
import useBlueprintContext from "../../../blueprint-editor/context/useBlueprintContext";
import StepCardStepLogButtonsHeader from "../StepCardStepLogButtonsHeader";
import StepCardStepLogRow from "../StepCardStepLogRow";

interface Props {
  stepLog: BlueprintCanvasViewableStepLogBase;
  handleSelectStepLogFromIteration?: (
    stepID: string,
    selectedIterationInfo: StepLogIterationInfo
  ) => void;
}

/**
 * Secondary step I/O log card for rendering in canvas
 * Used to show I/O of loop initializations (ie.: API call for an API_REQUEST_LOOP step)
 */
const StepCardStepLogLoopInitialization = ({
  stepLog,
  handleSelectStepLogFromIteration,
}: Props) => {
  const { selectedStepLog, setSelectedStepLog } = useBlueprintContext();

  const isHighlightedStepLog = selectedStepLog?.index_in_raw_logs === stepLog.index_in_raw_logs;

  const stepID = stepLog.step_io_log.step_id;
  const iterationInfo = stepLog.iteration_info;
  const apiRequestIndex = iterationInfo.index_in_api_requests;
  const totalAPIRequests = iterationInfo.total_api_requests;

  // Handles when API request page is selected
  // Note - Not all loop initializations are API request page (ie.: Array loop initialization)
  const handleSelectAPIRequestPage = (selectedAPIRequestIndex: number) => {
    if (handleSelectStepLogFromIteration) {
      const newIterationInfo: StepLogIterationInfo = {
        ...iterationInfo,
        index_in_api_requests: selectedAPIRequestIndex,
        index_in_loop_iterations: undefined,
      };
      handleSelectStepLogFromIteration(stepID, newIterationInfo);
    }
  };

  // Handles when this step log card is clicked on
  const handleSelectStepLog = () => {
    setSelectedStepLog({
      step_id: stepLog.step_io_log.step_id,
      index_in_raw_logs: stepLog.index_in_raw_logs,
    });
  };

  return (
    <div className="flex flex-row items-center mr-8">
      <hr className="m-0 border-dashed border border-gray-30 w-[24px]" />
      <div
        className={clsx(
          "flex flex-col bg-gray-0 w-[240px] rounded-md p-2 cursor-pointer",
          isHighlightedStepLog ? "border-[3px] border-blue-20" : "border border-gray-10"
        )}
        onClick={() => handleSelectStepLog()}
      >
        <StepCardStepLogButtonsHeader
          stepLog={stepLog}
          isHighlightedStepLog={isHighlightedStepLog}
          itemInfo={{
            title: apiRequestIndex !== undefined ? "Page" : "Loop initialization",
            itemIndex: apiRequestIndex,
            totalItems: totalAPIRequests,
            handleSelectItem: handleSelectAPIRequestPage,
          }}
        />
        <StepCardStepLogRow title="Input" value={JSON.stringify(stepLog.step_io_log.inputs)} />
        <StepCardStepLogRow title="Output" value={JSON.stringify(stepLog.step_io_log.outputs)} />
      </div>
    </div>
  );
};

export default StepCardStepLogLoopInitialization;
