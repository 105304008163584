import { BlueprintStepTemplate } from "../../../models/Blueprints";
import JSONSchemaTreeDiagram from "../../shared/JSONSchemaTreeDiagram";
import { HeaderPretitle } from "../../shared/text/MergeText";

interface Props {
  stepID: string;
  stepTemplate: BlueprintStepTemplate;
}

const BlueprintEditorRightPanelMetaAccessParentBPParamNamesForm = ({
  stepID,
  stepTemplate,
}: Props) => {
  return (
    <form>
      <HeaderPretitle className="mt-3">Parent BP Input Parameters</HeaderPretitle>
      <JSONSchemaTreeDiagram stepID={stepID} jsonSchema={stepTemplate.return_schema} />
    </form>
  );
};

export default BlueprintEditorRightPanelMetaAccessParentBPParamNamesForm;
