import { Checkbox, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import clsx from "clsx";
import { Info } from "lucide-react";
import useIntegrationBuilderContext from "../context/useIntegrationBuilderContext";

interface Props {
  onChange: (value: boolean) => void;
  title: string;
  subtitle?: string;
  helpText?: string;
  checked: boolean;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  variant?: "primary" | "secondary";
  dataTestID?: string;
}

const CheckboxHeader = ({
  onChange,
  title,
  subtitle,
  helpText,
  checked,
  className,
  required = false,
  disabled = false,
  variant = "primary",
  dataTestID,
}: Props) => {
  const { hasPermissionToEdit } = useIntegrationBuilderContext();

  const editDisabled = !hasPermissionToEdit || disabled;

  return (
    <div className={clsx("flex flex-column", className)}>
      <div data-testid={dataTestID} className="flex flex-row items-center">
        <Checkbox
          variant={variant}
          checked={checked}
          onChange={onChange}
          className="mt-1 flex flex-row justify-start items-start"
          label={
            <div className="flex flex-row items-center">
              <Text>{title}</Text>
              {helpText && (
                <Tooltip title={helpText}>
                  <Info size={12} className="ml-2 text-gray-70 flex items-center" />
                </Tooltip>
              )}
            </div>
          }
          disabled={editDisabled}
        />
        {required && (
          <Text variant="md" className="text-red-50 ml-1">
            *
          </Text>
        )}
      </div>
      <div className="pl-7">
        {subtitle && (
          <Text className="text-gray-70" variant="sm">
            {subtitle}
          </Text>
        )}
      </div>
    </div>
  );
};

export default CheckboxHeader;
