import { useParams } from "react-router-dom";
import { BlueprintOperationType } from "../../../models/Blueprints";
import { MappingTestSuite } from "../../../models/MappingTests";
import { getIndividualTestSuitePath } from "../../../router/RouterUtils";
import { getOperationNameFromOperationType } from "../../blueprint-editor/utils/BlueprintEditorUtils";
import SubLeftNavigationContainer from "../../shared/layout/SubLeftNavigationContainer";
import IndividualMappingTestSuiteEndpointCollectionOverview from "./IndividualMappingTestSuiteEndpointCollectionOverview";

type RouteParams = {
  integrationID: string;
  mappingTestSuiteID: string;
};

type Props = {
  mappingTestSuite: MappingTestSuite | undefined;
  fetchMappingTestSuite: () => void;
};

const IndividualMappingTestSuiteOverview = ({ mappingTestSuite, fetchMappingTestSuite }: Props) => {
  const { integrationID, mappingTestSuiteID } = useParams<RouteParams>();

  const basePath = getIndividualTestSuitePath(integrationID, mappingTestSuiteID);

  if (!mappingTestSuite) {
    return null;
  }

  const endpointCollections = mappingTestSuite?.endpoint_collections ?? [];

  const modelNames = [
    ...new Set(
      endpointCollections.map((collection) => collection.common_model_name).filter((x) => !!x)
    ),
  ];
  const operationTypes = [
    ...new Set(
      endpointCollections
        .map((collection) => collection.operation_type)
        .filter((operation_type) => operation_type !== null)
    ),
  ];

  const integrationHasWebhook = endpointCollections
    .map((collection) => collection.webhook_receiver_event_type_id)
    .some((id) => !!id);

  const webhookSubtab = integrationHasWebhook
    ? [
        {
          divider: true,
          label: "Webhook",
          destination: { pathname: basePath, params: `webhooks_only=true` },
        },
      ]
    : [];

  return (
    <SubLeftNavigationContainer
      subtabs={[
        {
          header: true,
          label: "Filter By",
        },
        {
          label: "All",
          destination: { pathname: basePath, params: "" },
        },
        // @ts-ignore Typescript complaining that 'unknown' is not assignable to Subtab even though the return type is the same
        ...operationTypes.map((operation_type: BlueprintOperationType, index) => ({
          divider: index === 0,
          label: getOperationNameFromOperationType(operation_type),
          destination: { pathname: basePath, params: `operation_type=${operation_type}` },
        })),
        // @ts-ignore
        ...webhookSubtab,
        // @ts-ignore
        ...modelNames.map((model_name: string, index) => ({
          divider: index === 0,
          label: model_name,
          destination: { pathname: basePath, params: `model_name=${model_name}` },
        })),
      ]}
    >
      <IndividualMappingTestSuiteEndpointCollectionOverview
        mappingTestSuite={mappingTestSuite}
        endpointCollections={endpointCollections}
        endUserOrganizationName={mappingTestSuite.end_user_organization_name}
        readTest={mappingTestSuite.read_test}
        fetchMappingTestSuite={fetchMappingTestSuite}
      />
    </SubLeftNavigationContainer>
  );
};

export default IndividualMappingTestSuiteOverview;
