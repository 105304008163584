import clsx from "clsx";
import {
  abbreviationForAPICategory,
  colorForAPICategory,
  getCategoryStatusTextInfo,
  isCategoryStatusBeta,
  isCategoryStatusInactive,
} from "../utils";
import { Badge, Tooltip } from "@merge-api/merge-javascript-shared";
import { IntegrationCategoryStatusInfo } from "../../../../../../models/Entities";

interface CategoryStatusBadgeProps {
  category_status: IntegrationCategoryStatusInfo;
}

const CategoryStatusBadge = ({ category_status }: CategoryStatusBadgeProps) => {
  const statusText = getCategoryStatusTextInfo(category_status).statusText;
  const categoryColor = colorForAPICategory(category_status?.category); // Ensure this function is imported
  const categoryAbbreviation = abbreviationForAPICategory(category_status?.category); // Ensure this function is imported
  const isBeta = isCategoryStatusBeta(category_status);
  const isInactive = isCategoryStatusInactive(category_status);

  return (
    <Tooltip title={statusText} key={category_status.category}>
      <Badge
        className={clsx("mr-1.5 flex flex-row items-center", {
          "opacity-35": isInactive,
        })}
        color={categoryColor}
      >
        {categoryAbbreviation} {isBeta && "- BETA"}
      </Badge>
    </Tooltip>
  );
};

export default CategoryStatusBadge;
