import {
  BlueprintParameterValue,
  BlueprintParameterValueType,
  BlueprintParameterValueProcedureArray,
  BlueprintStepType,
  BlueprintComparator,
} from "../../../../../models/Blueprints";
import { COMPARATOR_CHOICES } from "../../../../blueprint-editor/right-panel/BlueprintEditorRightPanelIfElseForm";
import { parseParameterValue } from "../../../utils/SharedComponentUtils";

// Checks if an If-Else step is completely filled out
const isIfElseComplete = (step: Record<string, BlueprintParameterValue>): boolean => {
  if (
    step.statements &&
    step.statements.value_type === BlueprintParameterValueType.statementArray &&
    step.statements.statement_array.length > 0
  ) {
    // New Template
    if (!step.logical_operator) {
      return false;
    }
    for (let i = 0; i < step.statements.statement_array.length; i++) {
      let statement = step.statements.statement_array[i];
      if (!(statement.val1 && statement.comparator)) {
        return false;
      }
    }
    // All statements are fully populated
    return true;
  } else if (step.value1 && step.value2 && step.comparator) {
    // Old Template backwards compatability
    return true;
  }
  return false;
};

// Parses parameter value from a step based on its type
export const parseParamValuefromStep = (
  step: Record<string, BlueprintParameterValue>,
  stepType: BlueprintStepType | string | undefined
) => {
  if (step.array) return parseParameterValue(step.array as BlueprintParameterValue);
  if (step.value) return parseParameterValue(step.value as BlueprintParameterValue);
  if (stepType === BlueprintStepType.IfElse && isIfElseComplete(step)) return parseIfElseText(step);
  else return null;
};

// Generates a text representation of an If-Else step
const parseIfElseText = (step: Record<string, BlueprintParameterValue>) => {
  var code = `if `;

  if (
    step.statements &&
    step.statements.value_type === BlueprintParameterValueType.statementArray
  ) {
    code += `${parseParameterValue(step.logical_operator as BlueprintParameterValue)} of: `;
    step.statements.statement_array.forEach((statement) => {
      const comparatorObj = COMPARATOR_CHOICES.find(
        (x) => x.id === parseParameterValue(statement.comparator as BlueprintParameterValue)
      );
      const isNullOrEmpty = comparatorObj?.id === BlueprintComparator.IS_NULL_OR_EMPTY;
      const compSymbol = comparatorObj ? comparatorObj.name : "?";
      code += `${parseParameterValue(statement.val1 as BlueprintParameterValue)} ${compSymbol}${
        !isNullOrEmpty && statement.val2
          ? " " + parseParameterValue(statement.val2 as BlueprintParameterValue)
          : ""
      }, `;
    });

    return code;
  }
  const comparatorObj = COMPARATOR_CHOICES.find(
    (x) => x.id === parseParameterValue(step.comparator as BlueprintParameterValue)
  );
  const compSymbol = comparatorObj ? comparatorObj.name : "?";
  code += `${parseParameterValue(
    step.value1 as BlueprintParameterValue
  )} ${compSymbol} ${parseParameterValue(step.value2 as BlueprintParameterValue)}`;

  return code;
};

// Parses a procedure array and returns a string representation
export const parseProcedureArray = (array: BlueprintParameterValueProcedureArray) => {
  const a = array.procedure_array[0];

  if (a && a.parameter_values.key && a.parameter_values.object) {
    const key = parseParameterValue(a.parameter_values.key as BlueprintParameterValue);
    const value = a.parameter_values.value
      ? parseParameterValue(a.parameter_values.value as BlueprintParameterValue)
      : null;
    const obj = parseParameterValue(a.parameter_values.object as BlueprintParameterValue);
    return `Add \{${key}: ${value ? ", " + value : null}\} to ${obj}`;
  } else if (a && a.parameter_values.array && a.parameter_values.value) {
    const array = parseParameterValue(a.parameter_values.array as BlueprintParameterValue);
    const value = a.parameter_values.value
      ? parseParameterValue(a.parameter_values.value as BlueprintParameterValue)
      : null;
    return `Add ${value} to ${array}`;
  }
  return "";
};
