import { useState } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { BlueprintMeta } from "../../../models/Entities";
import { LatestScraperExecutionData } from "../../../models/MappingTests";
import { fetchScraperBlueprintLatestExecution } from "../../mapping-tests/utils/MappingTestFetchUtils";
import { showErrorToast } from "../Toasts";
import BlueprintCardAPIRequestFooterEditableCodeSection from "./BlueprintCardAPIRequestFooterEditableCodeSection";

const BodyContainer = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: column;
`;

const LoadScraperResultButton = styled(Button)`
  align-self: flex-end;
  margin-bottom: 14px;
  height: 34px;
  line-height: 0;
  font-size: 14px;
`;

type Props = {
  blueprint: BlueprintMeta;
  integrationID: string;
  linkedAccountID: string;
  blueprintMetadata: { input_payload: any };
  updateMappingTestBPMetadata: (blueprintID: string, newMetaData: any) => void;
  isScraper?: boolean;
};

const BlueprintInputPayloadBody = (props: Props) => {
  const {
    blueprintMetadata,
    blueprint,
    updateMappingTestBPMetadata,
    linkedAccountID,
    isScraper,
  } = props;

  const blueprintID = blueprint?.blueprint_id;

  const [inputMetaData, setInputMetadata] = useState(blueprintMetadata);

  const onResponse = (data: LatestScraperExecutionData) => {
    updateMappingTestBPMetadata(blueprint?.blueprint_id, data);
    setInputMetadata({ input_payload: data ?? {} });
  };

  return (
    <BodyContainer>
      {isScraper && (
        <LoadScraperResultButton
          variant="outline-secondary"
          onClick={() => {
            fetchScraperBlueprintLatestExecution({
              blueprintID,
              linkedAccountID,
              onResponse,
              onError: () =>
                showErrorToast("Something went wrong fetching latest scraper exeuction"),
            });
          }}
        >
          Load latest Scraper result
        </LoadScraperResultButton>
      )}
      <BlueprintCardAPIRequestFooterEditableCodeSection
        title="BP Input Payload"
        bodyType="JSON"
        body={JSON.stringify(inputMetaData?.input_payload ?? {})}
        editCodeFunction={updateMappingTestBPMetadata}
        targetID={blueprintID}
        JSONOnly={true}
      />
    </BodyContainer>
  );
};

export default BlueprintInputPayloadBody;
