import { formattedModelClassName } from "../../utils/PublishModuleUtils";
import { IntegrationComponentNames, StagedComponent } from "../types";
import PublishModuleStagedComponentVersionCard from "./PublishModuleStagedComponentVersionCard";
import { Card, Text } from "@merge-api/merge-javascript-shared";

type Props = {
  modelClassName: IntegrationComponentNames;
  stagedComponents: StagedComponent[];
  integrationID: string;
  onRefreshAllChecks: () => void;
};

const PublishModuleStagedComponentCard = ({
  modelClassName,
  stagedComponents,
  integrationID,
  onRefreshAllChecks,
}: Props) => {
  return (
    <div className="mb-12">
      <Card>
        {(stagedComponents?.length ?? []) > 0 ? (
          stagedComponents.map((stagedComponent, index) => (
            <>
              <PublishModuleStagedComponentVersionCard
                modelClassName={modelClassName}
                stagedComponent={stagedComponent}
                integrationID={integrationID}
                onRefreshAllChecks={onRefreshAllChecks}
              />
              {index + 1 != stagedComponents.length && <hr className="p-0 m-0" />}
            </>
          ))
        ) : (
          <div className="flex justify-center items-center px-6 py-5 cursor-pointer pt-6 pb-6">
            <Text variant="h5" className="text-gray-40">
              No staged {formattedModelClassName(modelClassName)}
            </Text>
          </div>
        )}
      </Card>
    </div>
  );
};

export default PublishModuleStagedComponentCard;
