import useBlueprintContext from "../../context/useBlueprintContext";
import { getAllLoopStepsForBlueprint } from "../../utils/BlueprintEditorUtils";
import { BlueprintStep } from "../../../../models/Blueprints";
import { updateMaxLoopIterationPerStep } from "../BlueprintEditorLeftPanel";
import LeftPanelAccordion from "../shared/LeftPanelAccordion";
import LeftPanelTextField from "../shared/LeftPanelTextField";

interface Props {
  defaultValue: number | undefined;
  maxLoopIterationsPerStep: { [stepID: string]: number };
  setMaxLoopIterationsPerStep: (x: { [stepID: string]: number }) => void;
}

/**
 * Renders the "Max loop iterations per step" accordion within "Settings" accordion
 * Enables users to define max loops more granularly
 */
const BlueprintMaxLoopIterationsPerStepCollapsableSection = ({
  maxLoopIterationsPerStep,
  defaultValue,
  setMaxLoopIterationsPerStep,
}: Props) => {
  const { blueprint } = useBlueprintContext();

  return (
    <LeftPanelAccordion title="Max loop iterations per step" isAccordionWithinAccordion={true}>
      <div className="flex flex-col space-y-2 w-full">
        {getAllLoopStepsForBlueprint(blueprint).map((step: BlueprintStep) => (
          <div key={step.id} className="w-full">
            <LeftPanelTextField
              type="number"
              title={step.id}
              subtitle={`Overrides default max # of loops for ${step.id}`}
              placeholder="Enter max # of loops"
              defaultValue={defaultValue}
              value={maxLoopIterationsPerStep[step.id]}
              onChange={(e: any) =>
                updateMaxLoopIterationPerStep(
                  maxLoopIterationsPerStep,
                  step.id,
                  parseInt(e.target.value, 10),
                  setMaxLoopIterationsPerStep
                )
              }
            />
          </div>
        ))}
      </div>
    </LeftPanelAccordion>
  );
};

export default BlueprintMaxLoopIterationsPerStepCollapsableSection;
