import { useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { Integration } from "../../../models/Entities";
import {
  navigateToBlueprintEditor,
  navigateToScraperEditor,
  navigateToScraperHistory,
} from "../../../router/RouterUtils";

import { ScraperDataType, Scraper } from "../../scraper/types";
import { deleteScraper, fetchScrapers } from "../../scraper/utils/ScraperAPIClient";
import DeletionConfirmationModal from "../../shared/DeletionConfirmationModal";
import EmptyStateWrapper from "../../shared/layout/EmptyStateWrapper";
import { BlueprintTypeBadge } from "../../shared/MergeBadges";
import { showSuccessToast, showErrorToast } from "../../shared/Toasts";
import useDocumentTitle from "../../shared/useDocumentTitle";
import ScrapersTableCreateButton from "./ScrapersTableCreateButton";

type Props = { integrationMeta: Integration };

type RouteParams = {
  integrationID: string;
};

const ScrapersTable = ({ integrationMeta }: Props) => {
  const history = useHistory();
  const { integrationID } = useParams<RouteParams>();
  const [scrapers, setScrapers] = useState<Scraper[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowingConfirmDeleteScraperModal, setIsShowingConfirmDeleteScraperModal] = useState<
    boolean
  >(false);
  const [selectedScraperID, setSelectedScraperID] = useState<string | undefined>();
  const [selectedScraperName, setSelectedScraperName] = useState<string | undefined>();

  useDocumentTitle(`${integrationMeta.name} - Scrapers`, [integrationMeta.name]);

  useEffect(() => {
    fetchScrapers({ integrationID, onSuccess: setScrapers });
  }, [integrationID]);

  const viewEditor = (integrationID: string, blueprintID: string) => {
    navigateToBlueprintEditor(history, integrationID, blueprintID);
  };

  const viewScraperHistory = (
    integrationID: string,
    scraperID: string,
    navigateToNewTab: boolean
  ) => {
    navigateToScraperHistory(history, integrationID, scraperID, undefined, navigateToNewTab);
  };

  return (
    <>
      <DeletionConfirmationModal
        selectedObjectType="Scraper"
        show={isShowingConfirmDeleteScraperModal}
        onHide={() => setIsShowingConfirmDeleteScraperModal(false)}
        isLoading={isLoading}
        onConfirm={() => {
          setIsLoading(true);
          if (selectedScraperID) {
            deleteScraper({
              scraperID: selectedScraperID,
              onSuccess: () => {
                showSuccessToast(`Successfully deleted Scraper!`);
                setIsShowingConfirmDeleteScraperModal(false);
                fetchScrapers({ integrationID, onSuccess: setScrapers });
                setIsLoading(false);
              },
              onError: () => {
                showErrorToast(`Failed to delete Scraper.`);
                setIsLoading(false);
              },
            });
          }
        }}
        objectName={selectedScraperName}
      />

      <Card>
        <div className="table-responsive" style={{ borderRadius: 8 }}>
          <Table className="table-nowrap table-hover">
            <thead className="table-borderless">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Data Source</th>
                <th scope="col" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <ScrapersTableCreateButton integrationID={integrationID} />
                </th>
              </tr>
            </thead>
            <tbody>
              {scrapers ? (
                scrapers.length > 0 ? (
                  scrapers.map((scraper) => (
                    <tr className="table-link" key={scraper.id}>
                      <td>{scraper.name}</td>
                      <td>
                        <BlueprintTypeBadge
                          blueprintType={
                            scraper.data_type === ScraperDataType.AUTH_DATA
                              ? "Auth Scraper"
                              : "Common Model Scraper"
                          }
                        />
                      </td>
                      <td className="text-right">
                        <Button
                          className="mr-3"
                          id={scraper.most_recent_blueprint_version_id}
                          variant="info"
                          size="sm"
                          onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                            viewScraperHistory(
                              integrationID,
                              scraper.id,
                              event.metaKey || event.ctrlKey
                            )
                          }
                        >
                          <div className="d-flex justify-content-center">
                            <i className="fe fe-list mr-1.5" />
                            Execution history
                          </div>
                        </Button>
                        <Button
                          className="mr-3"
                          id={scraper.most_recent_blueprint_version_id}
                          variant="white"
                          size="sm"
                          onClick={() =>
                            viewEditor(integrationID, scraper.most_recent_blueprint_version_id)
                          }
                        >
                          <div className="d-flex justify-content-center">
                            <i className="fe fe-clipboard mr-1.5" />
                            View blueprint
                          </div>
                        </Button>
                        <Button
                          className="mr-3"
                          variant="white"
                          size="sm"
                          onClick={() =>
                            navigateToScraperEditor(
                              history,
                              integrationID,
                              scraper.id,
                              scraper.most_recent_scraper_version_id
                            )
                          }
                        >
                          <div className="d-flex justify-content-center">
                            <i className="fe fe-edit-2 mr-1.5" />
                            Edit scraper
                          </div>
                        </Button>
                        <Button
                          className=""
                          variant="danger"
                          size="sm"
                          onClick={() => {
                            setIsShowingConfirmDeleteScraperModal(true);
                            setSelectedScraperID(scraper.id);
                            setSelectedScraperName(scraper.name);
                          }}
                        >
                          <div className="d-flex justify-content-center">
                            <i className="fe fe-trash-2 mr-1.5" /> Delete scraper
                          </div>
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="p-0">
                      <EmptyStateWrapper isTable title="No Scrapers" />
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan={4} className="p-0">
                    <EmptyStateWrapper isTable isSpinner />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Card>
    </>
  );
};

export default ScrapersTable;
