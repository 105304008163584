import { useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { BlueprintMeta } from "../../../../models/Entities";
import {
  BlueprintExecutionPreviewInfo,
  MappingTestRequestMock,
} from "../../../../models/MappingTests";
import Spinner from "../../../shared/Spinner";
import { showErrorToast } from "../../../shared/Toasts";
import BlueprintExecutionPreviewCard from "./BlueprintExecutionPreviewCard";

interface Props {
  blueprintMeta: BlueprintMeta;
  testID: string;
  addRequestMock: (requestMock: MappingTestRequestMock) => void;
  saveMappingTest: () => void;
}

const BlueprintExecutionsContainer = ({
  blueprintMeta,
  testID,
  addRequestMock,
  saveMappingTest,
}: Props) => {
  const [executions, setExecutions] = useState<BlueprintExecutionPreviewInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    fetchWithAuth({
      path: `/stallions/blueprint-versions/${blueprintMeta.id}/recent-blueprint-executions`,
      method: "GET",
      onResponse: (data: BlueprintExecutionPreviewInfo[]) => {
        setExecutions(data);
        setIsLoading(false);
      },
      onError: (error: Response | undefined) => {
        showErrorToast(
          `Error fetching recent executions for blueprint ${blueprintMeta.id}: ${error}`
        );
        setIsLoading(false);
      },
    });
  }, []);

  return (
    <div className="mb-4">
      {
        <div key={blueprintMeta.id}>
          <Accordion defaultActiveKey={blueprintMeta.id.toString()}>
            <Card.Header
              style={{
                padding: 0,
              }}
            >
              <Accordion.Toggle
                eventKey={blueprintMeta.id.toString()}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  padding: 0,
                  textAlign: "left",
                }}
              >
                <strong>{blueprintMeta.human_name || blueprintMeta.name}</strong>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={blueprintMeta.id.toString()}>
              <div>
                {isLoading ? (
                  <Spinner className="mt-4" />
                ) : executions.length > 0 ? (
                  executions.map((execution) => (
                    <div className="max-h-[450px] overflow-y-auto">
                      <BlueprintExecutionPreviewCard
                        testID={testID}
                        key={execution.id}
                        data={execution}
                        addRequestMock={addRequestMock}
                        saveMappingTest={saveMappingTest}
                      />
                    </div>
                  ))
                ) : (
                  <div className="m-3">No recent executions found for this blueprint version</div>
                )}
              </div>
            </Accordion.Collapse>
          </Accordion>
        </div>
      }
    </div>
  );
};

export default BlueprintExecutionsContainer;
