import { Badge, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import clsx from "clsx";
import { Info } from "lucide-react";
import { ReactNode } from "react";

interface BadgeListProps {
  className?: string;
  items: string[];
  title: string;
  placeholder?: string;
  helpText?: string | ReactNode;
}

const BadgeList = ({ className, items, title, placeholder, helpText }: BadgeListProps) => {
  return (
    <div className={clsx("w-full flex flex-row items-center", className)}>
      <Text className="text-slate-90 text-base flex flex-row items-center mr-1">
        {helpText ? title : `${title}: `}
      </Text>
      {helpText ? (
        <Tooltip className="mr-2" title={helpText}>
          <Info size={12} className="text-gray-50 flex items-center" />
        </Tooltip>
      ) : null}
      <Text className="text-slate-90 text-base flex flex-row items-center"></Text>
      <div>
        {items?.length ? (
          <div className="flex flex-wrap gap-2">
            {items.map((item, index) => (
              <Badge key={index} size="md">
                {item}
              </Badge>
            ))}
          </div>
        ) : (
          <Text className="placeholder-gray-40 opacity-35 text-sm">{placeholder ?? "None"}</Text>
        )}
      </div>
    </div>
  );
};

export default BadgeList;
