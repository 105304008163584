import {
  DiffState,
  DiffStateFieldTypeEnum,
  generateDiffStateFieldInputType,
} from "../../../../../models/DiffModels";
import {
  generateDiffState,
  generateEmptyDiffState,
  globalCurrentState,
  globalNewState,
} from "../helpers-diff";
import { generateDiffStateForStepTemplateReturnSchema } from "./step-templates-return-schema-diff";
import { generateDiffStateForStepTemplateAPIEndpoint } from "./step-templates-api-endpoint-diff";
import { generateDiffStateForStepTemplatePaginationConfiguration } from "./step-templates-pag-config-diff";
import { generateDiffStateForStepTemplateParameterSchema } from "./step-templates-param-schema-diff";

/**
 * Generate diff for Blueprint step template, particularly for "New version available" alert
 * Example output
 
    Template
        name
        step_type
    
    Input schema
        (dynamic, but should look something like)
        > id
        > eins
        > remote_id
        ≥ legal_name
            type: string
            description: ...
    
    Output schema
        (dynamic, but should look something like)
        > id
        > eins
        ≥ ignore_id
            type: string
            format: uuid
            max_length: 32
            description: ...
        > remote_id
        > created_at
        ...
    
    API endpoint
        id
        name
        ...
    
    Pagination configuration
        id
        name
        type
        ...
 */

export const generateDiffForBlueprintStepTemplate = (): DiffState => {
  const currentTemplate = globalCurrentState;
  const currentTemplateParamSchema =
    "parameter_schema" in currentTemplate ? currentTemplate["parameter_schema"] : {};
  const currentTemplateReturnSchema =
    "return_schema" in currentTemplate ? currentTemplate["return_schema"] : {};
  const newTemplate = globalNewState;
  const newTemplateParamSchema =
    "parameter_schema" in newTemplate ? newTemplate["parameter_schema"] : {};
  const newTemplateReturnSchema =
    "return_schema" in newTemplate ? newTemplate["return_schema"] : {};
  const diffStateForParameterSchema: DiffState = generateDiffStateForStepTemplateParameterSchema(
    currentTemplateParamSchema,
    newTemplateParamSchema
  );
  const diffStateForReturnSchema: DiffState = generateDiffStateForStepTemplateReturnSchema(
    currentTemplateReturnSchema,
    newTemplateReturnSchema
  );

  let fields: generateDiffStateFieldInputType[] = [
    {
      type: DiffStateFieldTypeEnum.HEADER,
      name: "template-header",
      displayName: "Template",
      keyPathToName: ["template"],
      childDiffStateInputFields: [
        {
          name: "name",
          displayName: "Name",
        },
        {
          name: "step_type",
          displayName: "Step type",
        },
      ],
    },
    {
      type: DiffStateFieldTypeEnum.HEADER,
      name: "parameter-schema-header",
      displayName: "Input schema",
      childDiffStateFields:
        diffStateForParameterSchema.length > 0
          ? diffStateForParameterSchema
          : generateEmptyDiffState("no-parameters-text", "No parameters"),
    },
    {
      type: DiffStateFieldTypeEnum.HEADER,
      name: "return-schema-header",
      displayName: "Output schema",
      childDiffStateFields:
        diffStateForReturnSchema.length > 0
          ? diffStateForReturnSchema
          : generateEmptyDiffState("no-return-schema-text", "No return schema parameters"),
    },
  ];

  const isAPIEndpointInCurrent =
    "endpoint" in currentTemplate && globalCurrentState["endpoint"] !== null;
  const isAPIEndpointInNew = "endpoint" in newTemplate && globalNewState["endpoint"] !== null;
  if (isAPIEndpointInCurrent || isAPIEndpointInNew) {
    const currentTemplateAPIEndpoint = isAPIEndpointInCurrent ? currentTemplate["metadata"] : {};
    const newTemplateAPIEndpoint = isAPIEndpointInNew ? newTemplate["metadata"] : {};

    const diffStateInputFieldForAPIEndpoint: generateDiffStateFieldInputType = {
      type: DiffStateFieldTypeEnum.HEADER,
      name: "api-endpoint-header",
      displayName: "API endpoint",
      childDiffStateFields: generateDiffStateForStepTemplateAPIEndpoint(
        isAPIEndpointInCurrent ? currentTemplateAPIEndpoint : {},
        isAPIEndpointInNew ? newTemplateAPIEndpoint : {}
      ),
    };
    fields.push(diffStateInputFieldForAPIEndpoint);

    const isPaginationConfigurationInCurrent =
      "pagination_configuration" in currentTemplateAPIEndpoint &&
      !!currentTemplateAPIEndpoint["pagination_configuration"] &&
      Object.keys(currentTemplateAPIEndpoint["pagination_configuration"]).length > 0;
    const isPaginationConfigurationInNew =
      "pagination_configuration" in newTemplateAPIEndpoint &&
      !!newTemplateAPIEndpoint["pagination_configuration"] &&
      Object.keys(newTemplateAPIEndpoint["pagination_configuration"]).length > 0;

    if (isPaginationConfigurationInCurrent || isPaginationConfigurationInNew) {
      const diffStateInputFieldForPaginationConfiguration: generateDiffStateFieldInputType = {
        type: DiffStateFieldTypeEnum.HEADER,
        name: "pagination-configuration-header",
        displayName: "Pagination configuration",
        childDiffStateFields: generateDiffStateForStepTemplatePaginationConfiguration(
          isAPIEndpointInCurrent && isPaginationConfigurationInCurrent
            ? currentTemplateAPIEndpoint["pagination_configuration"]
            : {},
          isAPIEndpointInNew && isPaginationConfigurationInNew
            ? newTemplateAPIEndpoint["pagination_configuration"]
            : {}
        ),
      };
      fields.push(diffStateInputFieldForPaginationConfiguration);
    }
  }

  return generateDiffState({
    fields: fields,
  });
};
