import { fetchWithAuth } from "../../../api-client/api_client";
import { APIEndpointMethod } from "../../../models/Entities";

const useAbortAutogeneratedModelRun = () => {
  const abortAutogeneratedModelRun = (
    modelID: string,
    onResponse: () => void = () => {},
    onError: () => void = () => {}
  ) => {
    fetchWithAuth({
      path: `/integrations/integration-builder/autogenerated-model-run/${modelID}/abort`,
      method: APIEndpointMethod.POST,
      onResponse,
      onError,
    });
  };

  return { abortAutogeneratedModelRun };
};

export default useAbortAutogeneratedModelRun;
