import classNames from "classnames";
import {
  JSONArraySchema,
  JSONObjectSchema,
  JSONSchema,
  JSONSchemaValue,
} from "../../../models/Blueprints";
import { getIconForTypeForJSONTreeDiagram } from "../../shared/utils/SharedComponentUtils";
import EditableJSONSchemaValue from "./EditableJSONSchemaValue";
import * as immutable from "object-path-immutable";

interface Props {
  jsonSchema: JSONSchema;
  setJSONSchema: (jsonSchema: string) => void;
}

const EditableJSONSchemaTree = ({ jsonSchema, setJSONSchema }: Props) => {
  const updateJSONSchemaForValue = (
    value: JSONSchemaValue,
    newType: string,
    currentPath: Array<string>
  ): void => {
    const newValue = {
      ...value,
      type: newType,
      items: newType === "array" ? { properties: {} } : undefined,
      properties: newType === "object" ? {} : undefined,
    };

    const newJSONSchema = immutable.set(jsonSchema, currentPath, newValue);

    setJSONSchema(JSON.stringify(newJSONSchema));
  };

  const getHTMLFromJSONSchema = (
    schema: JSONSchemaValue,
    key: string,
    currentPath: Array<string>
  ): JSX.Element[] => {
    const currentHTML: JSX.Element[] = [];
    const valueType = schema.type;

    currentHTML.push(
      <EditableJSONSchemaValue
        updateJSONSchemaValue={(newType) => updateJSONSchemaForValue(schema, newType, currentPath)}
      >
        <div
          className={classNames(
            "text-monospace var-label d-flex align-items-center text-align-center",
            !valueType && "text-danger"
          )}
          key={key + "0"}
        >
          {getIconForTypeForJSONTreeDiagram(valueType)}
          {valueType === "array" &&
            (schema as JSONArraySchema).items?.type !== "object" &&
            getIconForTypeForJSONTreeDiagram((schema as JSONArraySchema).items?.type)}
          <span>{key}</span>
        </div>
      </EditableJSONSchemaValue>
    );

    const pathForBranch =
      schema.type === "array" ? [...currentPath, "items"] : [...currentPath, "properties"];

    if (valueType === "object") {
      currentHTML.push(
        <div className="row" key={key + "1"}>
          <EditableJSONSchemaValue
            updateJSONSchemaValue={(newType) =>
              updateJSONSchemaForValue(schema, newType, pathForBranch)
            }
          >
            <div className="col text-center json-schema--vertical-line" />
          </EditableJSONSchemaValue>
          <div className={"col collapse show"} id={`collapse${key}`}>
            {Object.entries((schema as JSONObjectSchema).properties ?? {}).map(
              ([propKey, value]) => {
                return <>{getHTMLFromJSONSchema(value, propKey, [...pathForBranch, propKey])}</>;
              }
            )}
          </div>
        </div>
      );
    }

    if (valueType === "array") {
      const items = (schema as JSONArraySchema).items;

      switch (items?.type) {
        case "array":
          currentHTML.push(
            <div className="row" key={key + "1"}>
              <EditableJSONSchemaValue
                updateJSONSchemaValue={(newType) =>
                  updateJSONSchemaForValue(schema, newType, [...pathForBranch, "properties"])
                }
              >
                <div className="col text-center json-schema--vertical-line" />
              </EditableJSONSchemaValue>

              <div className={"col collapse show"} id={`collapse${key}`}>
                <>
                  {"properties" in items &&
                    getHTMLFromJSONSchema((items as JSONArraySchema).items, "array", [
                      ...pathForBranch,
                      "properties",
                    ])}
                </>
              </div>
            </div>
          );
          break;
        case "object":
          currentHTML.push(
            <div className="row" key={key + "1"}>
              <EditableJSONSchemaValue
                updateJSONSchemaValue={(newType) =>
                  updateJSONSchemaForValue(schema, newType, currentPath)
                }
              >
                <div className="col text-center json-schema--vertical-line" />
              </EditableJSONSchemaValue>
              <div className={"col collapse show"} id={`collapse${key}`}>
                {Object.entries((items as JSONObjectSchema).properties ?? {}).map(
                  ([propKey, value]) => {
                    return getHTMLFromJSONSchema(value, propKey, [
                      ...pathForBranch,
                      "properties",
                      propKey,
                    ]);
                  }
                )}
              </div>
            </div>
          );
          break;
        default:
          break;
      }
    }

    return currentHTML;
  };

  return <div className="mb-9">{getHTMLFromJSONSchema(jsonSchema, "root", [])}</div>;
};

export default EditableJSONSchemaTree;
