// import { useCallback, useEffect, useState } from "react";
import React from "react";
import { Card, Row, Col, Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { Badge } from "@merge-api/merge-javascript-shared";
import { useHistory } from "react-router-dom";
import {
  BlueprintStatus,
  BlueprintVersionPublishState,
  BlueprintOperationType,
  Blueprint,
} from "../../../models/Blueprints";
import { APIEndpoint, BlueprintMeta, CommonModelMappingStatus } from "../../../models/Entities";
import { navigateToBlueprintEditor } from "../../../router/RouterUtils";
import { firstLetterUpperCase } from "../../../utils";
import { createBlueprint } from "../../blueprint-editor/utils/BlueprintEditorAPIClient";
import { isBlueprintWriteOperation } from "../../blueprint-editor/utils/BlueprintEditorUtils";
import { ScraperDataType } from "../../scraper/types";
import ClickableContainer from "../../shared/ClickableContainer";
import Dot from "../../shared/Dot";
import { UserAvatar, AvatarSize } from "../../shared/MergeAvatars";
import { BlueprintStatusBadge, CommonModelBadge, APIEndpointBadge } from "../../shared/MergeBadges";
import MergeText, { TextType } from "../../shared/text/MergeText";
import {
  getCapitalizedTimeFromNow,
  getBadgeColorForCoveragePercent,
} from "../../shared/utils/SharedComponentUtils";

const MAX_COMMENT_LENGTH = 30;

const BlueprintTableCard = ({
  blueprint,
  integrationID,
  setSelectedBlueprintName,
  setIsShowingConfirmArchiveBlueprintModal,
  fetchBlueprintVersion,
}: {
  blueprint: BlueprintMeta;
  integrationID: string;
  setIsShowingConfirmArchiveBlueprintModal: (newValue: boolean) => void;
  setSelectedBlueprintName: (selectedName: string) => void;
  fetchBlueprintVersion: (versionID: string) => void;
}) => {
  const history = useHistory();

  const commonModelBadges = () => {
    return blueprint.operation_type === BlueprintOperationType.FETCH
      ? blueprint.mapping_statuses
          .sort((a, b) => (a.common_model > b.common_model ? 1 : -1))
          .map((commonModel: CommonModelMappingStatus) => (
            <CommonModelBadge commonModel={commonModel.common_model} />
          ))
      : blueprint.written_common_model && (
          <CommonModelBadge commonModel={blueprint.written_common_model} />
        );
  };

  return (
    <Card
      className="clickable"
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        navigateToBlueprintEditor(
          history,
          integrationID,
          blueprint.id,
          // navigateToNewTab prop
          event.metaKey || event.ctrlKey
        );
      }}
    >
      <Card.Body>
        <Row>
          <Col>
            <div className="flex flex-row space-x-3 items-center justify-start">
              <MergeText isBold size="16px">
                {blueprint.human_name && blueprint.human_name != ""
                  ? blueprint.human_name
                  : blueprint.name}
              </MergeText>
              {blueprint.blueprint_overview_test_coverage?.coverage_percent != null &&
              blueprint.blueprint_overview_test_coverage?.coverage_percent != undefined ? (
                <div className="w-max h-fit flex flex-row items-center justify-center">
                  <Badge
                    size="sm"
                    color={getBadgeColorForCoveragePercent(
                      blueprint.blueprint_overview_test_coverage?.coverage_percent
                    )}
                    className="flex flex-column justify-center w-max"
                  >
                    {Math.round(blueprint.blueprint_overview_test_coverage?.coverage_percent)}% test
                    coverage
                  </Badge>
                </div>
              ) : (
                <></>
              )}
            </div>
            <Row>
              <Col>
                <BlueprintStatusBadge
                  status={
                    blueprint.status === BlueprintStatus.Archived
                      ? blueprint.status
                      : blueprint.blueprint_overview_publish_state
                  }
                />
                <Dot />
                <MergeText isBold type={TextType.MUTED}>
                  {firstLetterUpperCase(blueprint.operation_type)}
                </MergeText>
                <Dot />
                <MergeText isBold type={TextType.MUTED}>
                  {blueprint.has_scraper
                    ? blueprint.scraper_data_type === ScraperDataType.AUTH_DATA
                      ? "Auth Scraper"
                      : "Common Model Scraper"
                    : "API"}
                </MergeText>
                <Dot />
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 0 }}
                  overlay={
                    <Tooltip id="custom-function-info-tooltip">{blueprint.user?.name}</Tooltip>
                  }
                >
                  <span className="mr-1.5">
                    <UserAvatar user={blueprint.user ?? undefined} size={AvatarSize.xxs} />
                  </span>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 0 }}
                  overlay={<Tooltip id="custom-function-info-tooltip">{blueprint.comment}</Tooltip>}
                >
                  <MergeText isBold type={TextType.MUTED}>
                    {blueprint.comment.length > MAX_COMMENT_LENGTH
                      ? blueprint.comment.substring(0, MAX_COMMENT_LENGTH) + "..."
                      : blueprint.comment}
                  </MergeText>
                </OverlayTrigger>
                <MergeText type={TextType.MUTED2} className="ml-1.5">
                  {getCapitalizedTimeFromNow(blueprint.created_at)}
                </MergeText>
              </Col>
            </Row>

            {blueprint?.endpoints && blueprint.endpoints.length > 0 && (
              <Row className="mt-3">
                <Col>
                  {blueprint.endpoints.map((api_endpoint: APIEndpoint) =>
                    api_endpoint.name != "None" ? (
                      <APIEndpointBadge endpoint={api_endpoint.name} />
                    ) : (
                      <APIEndpointBadge endpoint={api_endpoint.path} />
                    )
                  )}
                </Col>
              </Row>
            )}

            {commonModelBadges()}
          </Col>
          <Col className="col-auto d-flex align-items-center">
            {isBlueprintWriteOperation(blueprint.operation_type) && (
              <Button
                id={blueprint.id}
                className="mr-3"
                variant="white"
                size="sm"
                onClick={(event) => {
                  event.stopPropagation();
                  blueprint.meta_blueprint_id
                    ? navigateToBlueprintEditor(history, integrationID, blueprint.meta_blueprint_id)
                    : createBlueprint({
                        integrationID,
                        operationType: BlueprintOperationType.META,
                        associatedBlueprintIDForMeta: blueprint.blueprint_id,
                        onSuccess: (data: Blueprint) =>
                          navigateToBlueprintEditor(history, integrationID, data.version.id),
                      });
                }}
              >
                <i className="fe fe-file-text" /> Meta
              </Button>
            )}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="custom-function-info-tooltip">
                  {blueprint.publish_state === BlueprintVersionPublishState.Published
                    ? "Cannot archive a published blueprint!"
                    : "Archive"}
                </Tooltip>
              }
            >
              <Button
                disabled={
                  blueprint.publish_state === BlueprintVersionPublishState.Published ||
                  blueprint.status === BlueprintStatus.Archived
                }
                className="archive-button"
                variant="danger"
                size="sm"
                onClick={(event) => {
                  event.stopPropagation();
                  fetchBlueprintVersion(blueprint.blueprint_id);
                  setIsShowingConfirmArchiveBlueprintModal(true);
                  setSelectedBlueprintName(blueprint.human_name || blueprint.name);
                }}
              >
                <i className="fe fe-archive" /> Archive
              </Button>
            </OverlayTrigger>
          </Col>
          <Col className="col-auto d-flex align-items-center">
            <ClickableContainer>
              <MergeText className="fe fe-chevron-right" type={TextType.MUTED} size="16px" />
            </ClickableContainer>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default React.memo(BlueprintTableCard);
