import { Text } from "@merge-api/merge-javascript-shared";

interface HelpAccordionBlockProps {
  title: string;
  body: React.ReactNode | string;
}

const HelpAccordionBlock = ({ title, body }: HelpAccordionBlockProps) => {
  return (
    <div className="flex flex-col space-y-2">
      <Text variant="h6">{title}</Text>
      <Text variant="md">{body}</Text>
    </div>
  );
};

export default HelpAccordionBlock;
