import useScraperContext from "./context/useScraperContext";
import ScraperCheckboxField from "./ScraperCheckboxField";
import ScraperElementSelectorField from "./ScraperElementSelectorField";
import { ScraperClickStep } from "./types";

const ScraperClickFields = () => {
  const { selectedStep, updateStepParameterValue } = useScraperContext();
  const step = selectedStep as ScraperClickStep;
  const { element_selector, should_click_all } = step.parameter_values;

  return (
    <>
      <ScraperElementSelectorField
        key={"element_selector"}
        updateValue={(newValue) => updateStepParameterValue(step, "element_selector", newValue)}
        value={element_selector}
      />
      <ScraperCheckboxField
        valueKey={"should_click_all"}
        updateValue={(newValue) => updateStepParameterValue(step, "should_click_all", newValue)}
        value={should_click_all}
      />
    </>
  );
};

export default ScraperClickFields;
