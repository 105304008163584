import { Alert, Text } from "@merge-api/merge-javascript-shared";
import { Info } from "lucide-react";

interface CalloutProps {
  className?: string;
  title: string;
  description?: string;
  children?: React.ReactNode;
}
const Callout = ({ className, title, description, children }: CalloutProps) => {
  return (
    <Alert title={title} color="gray" icon={<Info size={16} />} className={className}>
      {children ? children : <Text>{description}</Text>}
    </Alert>
  );
};

export default Callout;
