import Editor from "react-simple-code-editor";
import { isValidJSON } from "../../../utils";
import FormField, { FormFieldCommonProps } from "../../blueprint-editor/right-panel/FormField";

const { highlight, languages } = require("prismjs");

interface Props extends FormFieldCommonProps {
  setValue: (value: string) => void;
  currentValue: string | undefined;
  numRows: number;
  children?: JSX.Element;
  minHeight?: string | undefined;
  className?: string;
  disabled?: boolean;
  dataTestID?: string;
}

const JSONSchemaConverterFormField = ({
  setValue,
  currentValue,
  example,
  subtitle,
  title,
  children,
  className,
  dataTestID,
  disabled = false,
}: Props) => {
  const isValueValidJSON = currentValue && isValidJSON(currentValue);
  const styleProps = className ? { className } : {};
  return (
    <FormField
      title={title}
      subtitle={subtitle}
      example={example}
      dataTestID={dataTestID}
      {...styleProps}
    >
      <>
        {children}
        <Editor
          disabled={disabled}
          highlight={(code) => highlight(code, languages.js)}
          value={currentValue ?? ""}
          onValueChange={(code) => {
            setValue(isValidJSON(code) ? JSON.stringify(JSON.parse(code), null, 2) : code);
          }}
          padding={10}
          style={{
            backgroundColor: disabled ? "rgb(249 250 251)" : "white",
            border: !currentValue || isValueValidJSON ? "1px solid #d2ddec" : "1px solid red",
            borderRadius: 8,
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
          }}
        />
      </>
    </FormField>
  );
};

export default JSONSchemaConverterFormField;
