import { useCallback, useEffect, useState } from "react";
import { MappingTestSuite } from "../../../../models/MappingTests";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast } from "../../../shared/Toasts";

interface UseFetchMappingTestSuiteProps {
  mappingTestSuiteID: string;
}

export const useFetchMappingTestSuite = ({ mappingTestSuiteID }: UseFetchMappingTestSuiteProps) => {
  const [mappingTestSuite, setMappingTestSuite] = useState<MappingTestSuite>();
  const [isLoadingFetchMappingTestSuite, setIsLoadingFetchMappingTestSuite] = useState<boolean>(
    false
  );

  const fetchMappingTestSuite = useCallback(() => {
    if (!mappingTestSuiteID) {
      return;
    }
    setIsLoadingFetchMappingTestSuite(true);
    fetchWithAuth({
      path: `/stallions/integrations/test-suite/${mappingTestSuiteID}`,
      method: "GET",
      onResponse: (data: MappingTestSuite) => {
        setMappingTestSuite(data);
        setIsLoadingFetchMappingTestSuite(false);
      },
      onError: () => {
        showErrorToast("Error querying mapping test ID");
        setIsLoadingFetchMappingTestSuite(false);
      },
    });
  }, [mappingTestSuiteID]);

  useEffect(() => {
    fetchMappingTestSuite();
  }, [fetchMappingTestSuite]);

  return {
    mappingTestSuite,
    setMappingTestSuite,
    fetchMappingTestSuite,
    isLoadingFetchMappingTestSuite,
  };
};
