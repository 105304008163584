import PublishModuleMappingTestContext, {
  PublishModuleMappingTestContextState,
} from "./PublishModuleMappingTestContext";

interface Props extends PublishModuleMappingTestContextState {
  children: JSX.Element;
}

// Wraps around App to be able to pull global state anywhere in tree via useContext
const PublishModuleMappingTestContextProvider = ({ children, ...contextProps }: Props) => (
  <PublishModuleMappingTestContext.Provider value={contextProps}>
    {children}
  </PublishModuleMappingTestContext.Provider>
);

export default PublishModuleMappingTestContextProvider;
