import { useEffect, useState } from "react";
import { LogEntry } from "../../../../../models/MappingTests";
import { fetchWithAuth } from "../../../../../api-client/api_client";
import { showErrorToast } from "../../../../shared/Toasts";

interface UseGetBlueprintExecutionsProps {
  show: boolean;
  blueprintExecutionID: string;
}

export const useGetBlueprintExecutions = ({
  show,
  blueprintExecutionID,
}: UseGetBlueprintExecutionsProps) => {
  const [apiRequests, setApiRequests] = useState<LogEntry[]>([]);
  const [isLoadingAPIRequests, setIsLoadingAPIRequests] = useState<boolean>(true);

  useEffect(() => {
    if (show) {
      fetchWithAuth({
        path: `/stallions/blueprint-executions/${blueprintExecutionID}`,
        method: "GET",
        onResponse: (response: { logs: LogEntry[] }) => {
          setApiRequests(response?.logs ?? []);
          setIsLoadingAPIRequests(false);
        },
        onError: () => {
          showErrorToast("Unable to load blueprint executions to import");
          setIsLoadingAPIRequests(false);
        },
      });
    }
  }, [show, blueprintExecutionID]);

  return { apiRequests, isLoadingAPIRequests };
};
