import { ChevronLeft, ChevronRight } from "lucide-react";
import { Row, Col, Button } from "react-bootstrap";

interface Props {
  previousPageURL?: string | null;
  nextPageURL?: string | null;
  onClickPrevious?: () => void;
  onClickNext?: () => void;
}

const DeprecatedPaginationFooter = ({
  previousPageURL,
  nextPageURL,
  onClickPrevious,
  onClickNext,
}: Props) => {
  return (
    <Row className="mb-9">
      <Col>
        <div className="d-flex justify-content-around bd-highlight">
          <div>
            <Button variant="white" onClick={onClickPrevious} disabled={!previousPageURL}>
              <ChevronLeft className="text-gray-600" />
            </Button>
          </div>

          <div>
            <Button variant="white" onClick={onClickNext} disabled={!nextPageURL}>
              <ChevronRight className="text-gray-600" />
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default DeprecatedPaginationFooter;
