import { NavLink, Route, useRouteMatch, Switch } from "react-router-dom";
import APIRequestTester from "./APIRequestTester";
import JSONSchemaConverterCard from "../json-schema/JSONSchemaConverterCard";
import {
  APIRequestFormat,
  APIResponseType,
  APIRequestFormatSubtype,
  ResponseBodyAdditionalParsing,
  FileParameterData,
} from "../../../models/Entities";
import { Container } from "react-bootstrap";
import { useState } from "react";

interface Props {
  integrationID: string;
  method: string;
  path: string;
  baseAPIURLOverride: string;

  headerSchema: string;
  bodySchema: string;
  pathParamSchema: string;
  queryParamSchema: string;
  fileSchema: string;
  requestFormat: APIRequestFormat;
  requestType: string;
  responseType: APIResponseType;
  responseBodyAdditionalParsing?: ResponseBodyAdditionalParsing;
  requestBodyBaseConfig: string;
  requestBodyParamSchema: string;
  requestBodyFormatSubtype: APIRequestFormatSubtype;
  soapRequestUseBodyTemplatingEngine: boolean;
}

const APIEndpointEditFormRightPanel = (props: Props) => {
  const { path, url } = useRouteMatch();
  const [sampleSchemaValue, setSampleSchemaValue] = useState("");
  const [JSONSchemaValue, setJSONSchemaValue] = useState("");
  const [isJSONSchemaShowing, setIsJSONSchemaShowing] = useState(false);
  const [pathTestValues, setPathTestValues] = useState<{ [key: string]: any }>({});
  const [queryParamTestValues, setQueryParamTestValues] = useState<{
    [key: string]: any;
  }>({});
  const [headerTestValues, setHeaderTestValues] = useState<{
    [key: string]: any;
  }>({});
  const [bodyTestValues, setBodyTestValues] = useState<{ [key: string]: any }>({});
  const [fileTestValues, setFileTestValues] = useState<FileParameterData[]>([]);
  const [requestBodyParamValues, setRequestBodyParamValues] = useState<{
    [key: string]: any;
  }>({});

  return (
    <div
      className="log-side-panel"
      style={{
        position: "fixed",
        width: "30%",
        height: "100%",
        zIndex: 1039,
      }}
    >
      <Container fluid>
        <ul className="nav nav-tabs mb-6">
          <li className="nav-item">
            <NavLink to={`${url}/converter`} className="nav-link">
              JSON Schema Converter
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={`${url}/tester`} className="nav-link">
              Request Tester
            </NavLink>
          </li>
        </ul>

        <Switch>
          <Route exact path={path}>
            <JSONSchemaConverterCard
              responseType={props.responseType}
              sampleSchemaValue={sampleSchemaValue}
              setSampleSchemaValue={setSampleSchemaValue}
              JSONSchemaValue={JSONSchemaValue}
              setJSONSchemaValue={setJSONSchemaValue}
              isJSONSchemaShowing={isJSONSchemaShowing}
              setIsJSONSchemaShowing={setIsJSONSchemaShowing}
            />
          </Route>
          <Route exact path={`${path}/converter`}>
            <JSONSchemaConverterCard
              responseType={props.responseType}
              sampleSchemaValue={sampleSchemaValue}
              setSampleSchemaValue={setSampleSchemaValue}
              JSONSchemaValue={JSONSchemaValue}
              setJSONSchemaValue={setJSONSchemaValue}
              isJSONSchemaShowing={isJSONSchemaShowing}
              setIsJSONSchemaShowing={setIsJSONSchemaShowing}
            />
          </Route>
          <Route exact path={`${path}/tester`}>
            <APIRequestTester
              integrationID={props.integrationID}
              method={props.method}
              path={props.path}
              baseAPIURLOverride={props.baseAPIURLOverride}
              headerSchema={props.headerSchema}
              bodySchema={props.bodySchema}
              requestFormat={props.requestFormat}
              pathParamSchema={props.pathParamSchema}
              queryParamSchema={props.queryParamSchema}
              fileSchema={props.fileSchema}
              requestType={props.requestType}
              responseType={props.responseType}
              responseBodyAdditionalParsing={props.responseBodyAdditionalParsing}
              requestBodyBaseConfig={props.requestBodyBaseConfig || ""}
              requestBodyParamSchema={props.requestBodyParamSchema || ""}
              requestBodyFormatSubtype={props.requestBodyFormatSubtype || ""}
              soapRequestUseBodyTemplatingEngine={props.soapRequestUseBodyTemplatingEngine || false}
              pathTestValues={pathTestValues}
              setPathTestValues={setPathTestValues}
              bodyTestValues={bodyTestValues}
              setBodyTestValues={setBodyTestValues}
              queryParamTestValues={queryParamTestValues}
              setQueryParamTestValues={setQueryParamTestValues}
              headerTestValues={headerTestValues}
              setHeaderTestValues={setHeaderTestValues}
              requestBodyParamValues={requestBodyParamValues}
              setRequestBodyParamValues={setRequestBodyParamValues}
              fileTestValues={fileTestValues}
              setFileTestValues={setFileTestValues}
            />
          </Route>
        </Switch>
      </Container>
    </div>
  );
};

export default APIEndpointEditFormRightPanel;
