import { Badge, Checkbox, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { memo, useState } from "react";
import { APIEndpointPreviewIntegrationBuilder } from "../../utils/Entities";

interface APIEndpointsGeneratorTableRowProps {
  setSelectedPreviewIDs: React.Dispatch<React.SetStateAction<string[]>>;
  endpointPreview: APIEndpointPreviewIntegrationBuilder;
}

const APIEndpointsGeneratorTableRow = ({
  setSelectedPreviewIDs,
  endpointPreview,
}: APIEndpointsGeneratorTableRowProps) => {
  const [isSelected, setIsSelected] = useState(false);

  const onChange = () => {
    setIsSelected((prev) => !prev);
    setSelectedPreviewIDs((selectedPreviewIDs) => {
      if (!endpointPreview.id) return selectedPreviewIDs;
      if (selectedPreviewIDs.includes(endpointPreview.id)) {
        return selectedPreviewIDs.filter((id) => id !== endpointPreview.id);
      }
      return [...selectedPreviewIDs, endpointPreview.id];
    });
  };

  return (
    <tr onClick={onChange} className="cursor-pointer">
      <td className="multi-line-text">
        <div className="flex flex-row items-center justify-start space-x-4">
          <Checkbox checked={isSelected} />
          <Text variant="h6">{endpointPreview.name}</Text>
        </div>
      </td>
      <td className="multi-line-text">
        <div className="flex flex-row items-center justify-start">
          <Text>{endpointPreview.method}</Text>
        </div>
      </td>
      <td className="multi-line-text">
        <div className="flex flex-row items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap">
          <Tooltip title={endpointPreview.path}>
            <Badge size="sm">{endpointPreview.path}</Badge>
          </Tooltip>
        </div>
      </td>
      <td className="multi-line-text">
        <div className="flex flex-row items-center justify-start">
          <Text>{endpointPreview.description}</Text>
        </div>
      </td>
    </tr>
  );
};

export default memo(APIEndpointsGeneratorTableRow);
