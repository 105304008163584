interface Props {
  choices: Array<{ name: string; id: string }>;
  currentValue: any;
  placeholder: string;
  onChange: (e: any) => void;
  className?: string;
  disabled?: boolean;
  showEmptyOption?: boolean;
  noSort?: boolean;
}

const Dropdown = ({
  choices,
  currentValue,
  onChange,
  placeholder,
  className,
  disabled,
  showEmptyOption,
  noSort,
}: Props) => (
  <select
    className={className || "custom-select"}
    onChange={onChange}
    value={currentValue ?? ""}
    disabled={disabled ?? false}
  >
    {showEmptyOption ? (
      <option value={""}>{"--- " + placeholder + " ---"}</option>
    ) : (
      <option value={""} disabled hidden={true}>
        {placeholder}
      </option>
    )}
    {(!noSort
      ? choices.sort((choice1, choice2) => (choice1.name > choice2.name ? 1 : -1))
      : choices
    ).map((choice) => (
      <option key={choice.id} value={choice.id}>
        {choice.name}
      </option>
    ))}
  </select>
);

export default Dropdown;
