import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { APICategory } from "../../../models/Entities";
import { AuthConfigUpdateProps } from "./AuthConfigShared";
import TestRunResponseBlock, { TestRunResponse } from "../../shared/TestRunResponseBlock";
import { showErrorToast } from "../../shared/Toasts";
import { FormErrorData, fetchWithAuth } from "../../../api-client/api_client";
import AuthConfigMergeLinkButton from "./AuthConfigMergeLinkButton";
import DropdownFormField from "../../blueprint-editor/right-panel/DropdownFormField";
import AuthConfigTestCleanupButton from "./AuthConfigTestCleanupButton";
import SpinnerButton from "../../shared/SpinnerButton";
import EmptyStateWrapper from "../../shared/layout/EmptyStateWrapper";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";
import React from "react";

interface Props {
  authConfigUpdateProps: AuthConfigUpdateProps;
  authConfigID: string;
}

interface SerializedLog {
  request_body?: string;
  request_headers?: { [key: string]: string };
  url: string;
  method: string;
  response_body?: string;
  response_code: number;
}

const _renderResponses = (responses: TestRunResponse[] | undefined, isLoading: boolean) => {
  if (isLoading) {
    return (
      <div className="form-group mb-5" style={{ paddingTop: 20 }}>
        <DeprecatedH4>Loading logs...</DeprecatedH4>
      </div>
    );
  }

  if (!responses) {
    return <></>;
  }
  if (responses.length == 0) {
    return (
      <div className="form-group mb-5" style={{ paddingTop: 20 }}>
        <DeprecatedH4>No Logs Available</DeprecatedH4>
      </div>
    );
  }
  return (
    <>
      {responses.map((response) => (
        <TestRunResponseBlock response={response} />
      ))}
    </>
  );
};

const _serializedLogToTestRunResponse = (log: SerializedLog): TestRunResponse => {
  return {
    status_code: log.response_code,
    body: log.request_body,
    request: {
      url: log.url,
      body: log.request_body,
      method: log.method,
      headers: log.request_headers ?? {},
    },
  };
};

const AuthMergeLinkTester = (props: Props) => {
  const MAX_FETCH_LOG_ATTEMPTS = 10;
  const [testRunResponses, setTestRunResponses] = useState<TestRunResponse[] | undefined>();
  const [linkToken, setLinkToken] = useState<string | undefined>();
  const [category, setCategory] = useState<string | undefined>(undefined);
  const [testOAuthAuthorizeURL, setTestOAuthAuthorizeURL] = useState<string | undefined>();
  const [isRefreshLoading, setIsRefreshLoading] = useState<boolean>(false);
  const [linkedAccountID, setLinkedAccountID] = useState<string | undefined>();
  const [testAuthConfigID, setTestAuthConfigID] = useState<string | undefined>();
  const [linkedAccountCompleted, setLinkedAccountCompleted] = useState<boolean>(false);
  const [areLogsLoading, setAreLogsLoading] = useState<boolean>(false);
  const [fetchLogAttempts, setFetchLogAttempts] = useState<number>(0);
  const [hasLinkingLogs, setHasLinkingLogs] = useState<boolean>(false);
  const [logsFilterTimestamp, setLogsFilterTimestamp] = useState<number | undefined>();

  const _fetchLinkingLogs = (linkedAccountID: string) => {
    setFetchLogAttempts(fetchLogAttempts + 1);

    let path = `/integrations/auth-configs/get-test-linking-logs/${linkedAccountID}?auth_type=${props.authConfigUpdateProps.authType}`;
    if (logsFilterTimestamp) {
      path = path + `&filter_by=${logsFilterTimestamp}`;
    }

    fetchWithAuth({
      path: path,
      method: "GET",
      onResponse: (data) => {
        const testRunResponses: TestRunResponse[] = data.map((serializedLog: SerializedLog) =>
          _serializedLogToTestRunResponse(serializedLog)
        );
        if (testRunResponses && testRunResponses.length > 0) {
          setTestRunResponses(testRunResponses);
          setHasLinkingLogs(true);
          setAreLogsLoading(false);
        } else if (fetchLogAttempts >= MAX_FETCH_LOG_ATTEMPTS) {
          showErrorToast("Failed to fetch logs");
          setTestRunResponses(testRunResponses);
          setLogsFilterTimestamp(undefined);
          setAreLogsLoading(false);
        }
      },
      onError: () => {
        setFetchLogAttempts(0);
        // If we ran into an error, we shouldn't continuously try again
        setAreLogsLoading(false);
        setLogsFilterTimestamp(undefined);
      },
    });
  };

  const _fetchCreateTestLinkToken = () => {
    // Clear existing link token while we wait for the request to fetch a new token
    fetchWithAuth({
      path: "/integrations/create-test-link-token",
      method: "POST",
      onResponse: (data) => {
        setLinkToken(data.link_token);
      },
      onError: (err: Response | undefined) => {
        if (err) {
          err.json().then((data: FormErrorData) => {
            let errorMessage =
              "Unable to initialize new test Linked Accounts, please check your connection and try again.";
            for (const field_name in data) {
              if (field_name === "non_field_errors" && data[field_name][0] !== "") {
                errorMessage = data[field_name][0];
                break;
              }
            }
            showErrorToast(errorMessage);
          });
        } else {
          showErrorToast(
            "Unable to initialize new test Linked Accounts, please check your connection and try again."
          );
        }
      },
    });
  };

  const _onMergeLinkExit = (testAuthConfigID: string, category: string) => {
    setTestAuthConfigID(testAuthConfigID);
    setLogsFilterTimestamp(undefined);
    setTestOAuthAuthorizeURL(undefined);

    // We can fetch the linked account from the link token
    fetchWithAuth({
      path: `/stallions/integrations/auth-configs/get-linked-account/${linkToken}?category=${category}`,
      method: "GET",
      onResponse: (data) => {
        const linkedAccountID = data.id;
        const testOAuthURLFromResp = data.oauth_authorize_url;
        const linkedAccountCompleteFromResp = data.is_complete;

        setLinkedAccountID(linkedAccountID);

        if (!linkedAccountID) {
          showErrorToast("Failed to fetch linked account from link token.");
          return;
        }

        if (testOAuthURLFromResp) {
          setTestOAuthAuthorizeURL(testOAuthURLFromResp);
        }

        setLinkedAccountCompleted(linkedAccountCompleteFromResp);
        setAreLogsLoading(true);
      },
      onError: () => {
        showErrorToast("Failed to fetch linked account from link token.");
      },
    });

    // Fetch a new test link token in case we want to launch Merge Link again
    _fetchCreateTestLinkToken();
  };

  const onAuthConfigRefresh = (linkedAccountID: string, testAuthConfigID: string) => {
    setIsRefreshLoading(true);
    setTestRunResponses(undefined);
    setFetchLogAttempts(0);
    setHasLinkingLogs(false);
    setTestOAuthAuthorizeURL(undefined);
    setLogsFilterTimestamp(Math.floor(Date.now() / 1000));

    fetchWithAuth({
      path: `/integrations/linked-accounts/credentials/refresh/${linkedAccountID}/${testAuthConfigID}`,
      method: "GET",
      onResponse: () => {
        setAreLogsLoading(true);
        setIsRefreshLoading(false);
      },
      onError: () => {
        showErrorToast("Refresh Failed.");
        setIsRefreshLoading(false);
      },
    });
  };

  useEffect(() => {
    // On initial page render, get link token to pass in to Merge Link
    _fetchCreateTestLinkToken();
  }, []);

  useEffect(() => {
    if (props.authConfigUpdateProps.integrationCategory) {
      setCategory(props.authConfigUpdateProps.integrationCategory);
    }
  }, [props.authConfigUpdateProps.integrationCategory]);

  useEffect(() => {
    if (
      linkedAccountID &&
      areLogsLoading &&
      fetchLogAttempts <= MAX_FETCH_LOG_ATTEMPTS &&
      !hasLinkingLogs
    ) {
      setTimeout(() => {
        _fetchLinkingLogs(linkedAccountID);
      }, 3500);
    }
  }, [fetchLogAttempts, hasLinkingLogs, areLogsLoading]);

  return linkToken ? (
    <>
      <Row className="mt-6">
        <Col>
          {!category && (
            <DropdownFormField
              currentValue={category}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
              placeholder=""
              title="Integration Category"
              subtitle={"Make sure it's the same as what you plan to select in Merge Link."}
              choices={Object.values(APICategory).map((id) => ({
                id,
                name: APICategory[id],
              }))}
            />
          )}
          {category && (
            <AuthConfigMergeLinkButton
              authConfigID={props.authConfigID}
              linkToken={linkToken}
              category={category}
              authConfigUpdateProps={props.authConfigUpdateProps}
              onMergeLinkExit={_onMergeLinkExit}
            />
          )}
          {linkedAccountID && testAuthConfigID && linkedAccountCompleted && (
            <SpinnerButton
              text="Refresh Auth Credentials"
              isLoading={isRefreshLoading}
              className="btn-block btn-primary mt-1.5"
              onClick={() => onAuthConfigRefresh(linkedAccountID, testAuthConfigID)}
            />
          )}
        </Col>
      </Row>
      {testAuthConfigID && (
        <AuthConfigTestCleanupButton
          testAuthConfigID={testAuthConfigID}
          testLinkedAccountID={linkedAccountID}
          integrationID={props.authConfigUpdateProps.integrationID}
          setTestRunResponses={setTestRunResponses}
          setLinkedAccountID={setLinkedAccountID}
          setTestAuthConfigID={setTestAuthConfigID}
          setAreLogsLoading={setAreLogsLoading}
          setTestOAuthAuthorizeURL={setTestOAuthAuthorizeURL}
        />
      )}
      {testOAuthAuthorizeURL && (
        <>
          <DeprecatedH4 className="mt-9">OAuth Authorize URL</DeprecatedH4>
          <code className="ml-1.5">{decodeURIComponent(testOAuthAuthorizeURL)}</code>
        </>
      )}
      <Row>
        <Col>{_renderResponses(testRunResponses, areLogsLoading)}</Col>
      </Row>
    </>
  ) : (
    <EmptyStateWrapper isSpinner />
  );
};

export default React.memo(AuthMergeLinkTester);
