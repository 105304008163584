import React from "react";
import { ChevronRight } from "lucide-react";

type Props = Pick<React.ComponentProps<"div">, "className" | "onClick"> & {
  isRotatedDown: boolean;
  isVisible?: boolean;
};

const ChevronCell = ({ isRotatedDown, isVisible = true, className, onClick }: Props) => (
  <td onClick={onClick} className="m-0 p-0">
    <div className="flex items-center justify-center h-full">
      <ChevronRight
        size={16}
        className={` text-gray-60 ${className || ""}`}
        style={{
          transform: isRotatedDown ? "rotate(90deg)" : "rotate(0deg)",
          transition: "transform 0.3s",
          visibility: isVisible ? "visible" : "hidden",
        }}
      />
    </div>
  </td>
);

export default ChevronCell;
