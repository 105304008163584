import { useHistory, useParams } from "react-router-dom";
import { navigateToMappingTestEditor } from "../../../router/RouterUtils";
import EndpointCollectionCardBase from "./EndpointCollectionCardBase";
import FetchMappingTestButtons from "./FetchMappingTestButtons";
import {
  AutogeneratedReadMappingTestRunStatus,
  MappingTestAndVersions,
} from "../../../models/MappingTests";
import { useGetLatestAutogeneratedReadMappingTest } from "./hooks/useGetLatestAutogeneratedReadMappingTest";

type RouteParams = {
  mappingTestSuiteID: string;
};

type Props = {
  integrationID: string;
  mappingTest: MappingTestAndVersions;
  readTestCollectionID: string | null | undefined;
  fetchMappingTestSuite: () => void;
};

const FetchMappingTestCard = ({
  integrationID,
  mappingTest,
  readTestCollectionID,
  fetchMappingTestSuite,
}: Props) => {
  const history = useHistory();

  const { mappingTestSuiteID } = useParams<RouteParams>();

  //hooks
  const {
    generatedReadTestRun,
    setGeneratedReadTestRun,
  } = useGetLatestAutogeneratedReadMappingTest({ mappingTestSuiteID });

  // functions
  const isMappingTestReset = (() => {
    // if the mapping test has a published version, it means its not reset
    if (mappingTest?.published_version) {
      return false;
    }
    // check if `next_version` is undefined or null

    if (!mappingTest?.next_version) {
      return true;
    }
    const blocks = mappingTest?.next_version?.mapping_test_blocks ?? [];
    // Check if `mapping_test_blocks` has one or less blocks
    if (blocks.length === 0) {
      return true;
    }
    // check if `blueprints_meta` in the first `mapping_test_block` is empty
    if (Object.keys(blocks[0]?.blueprints_meta ?? {}).length === 0) {
      return true;
    }

    return false;
  })();

  // consts derived from state
  const inProgressExecution = generatedReadTestRun
    ? [
        AutogeneratedReadMappingTestRunStatus.PENDING,
        AutogeneratedReadMappingTestRunStatus.RUNNING_FETCH_BLUEPRINTS_FOR_LINKED_ACCOUNT,
        AutogeneratedReadMappingTestRunStatus.GENERATING_MAPPING_TEST,
      ].includes(generatedReadTestRun?.status)
    : false;

  const finishedExecution = generatedReadTestRun
    ? [
        AutogeneratedReadMappingTestRunStatus.FINISHED_WITH_SUCCESS,
        AutogeneratedReadMappingTestRunStatus.FINISHED_WITH_FAILURES,
        AutogeneratedReadMappingTestRunStatus.FINISHED_WITH_EXCEPTIONS,
        AutogeneratedReadMappingTestRunStatus.ABORTED,
      ].includes(generatedReadTestRun?.status)
    : false;

  return (
    <EndpointCollectionCardBase
      doesEntityExist={true}
      onClick={() => {
        if (!inProgressExecution) {
          navigateToMappingTestEditor(history, integrationID, mappingTest.id);
        }
      }}
      title={"Fetch Test"}
      isFetchMappingTestReset={isMappingTestReset}
      actionButton={
        <>
          {readTestCollectionID && (
            <FetchMappingTestButtons
              fetchMappingTestSuite={fetchMappingTestSuite}
              mappingTestID={mappingTest.id}
              finishedExecution={finishedExecution}
              inProgressExecution={inProgressExecution}
              generatedReadTestRun={generatedReadTestRun}
              integrationID={integrationID}
              mappingTestSuiteID={mappingTestSuiteID}
              isMappingTestReset={isMappingTestReset}
              setGeneratedReadTestRun={setGeneratedReadTestRun}
            />
          )}
        </>
      }
    />
  );
};

export default FetchMappingTestCard;
