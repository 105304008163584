import { Button, Text } from "@merge-api/merge-javascript-shared";
import IntegrationTabs from "./integration-page/IntegrationTabs";
import useIntegrationData from "./integration-page/useIntegrationData";
import { navigateToIntegrationBuilderPostIntegrationInitialization } from "../../router/RouterUtils";
import { useHistory } from "react-router-dom";

type Props = { children: JSX.Element | JSX.Element[]; integrationID: string };

const IntegrationPageWrapper = ({ integrationID, children }: Props) => {
  const { integrationName, categories } = useIntegrationData(integrationID);
  const history = useHistory();

  return (
    <>
      <div className="flex flex-col mt-10">
        <div className="flex flex-row items-center justify-between">
          <Text variant="h2">{integrationName}</Text>
          <Button
            size="md"
            className="mr-4"
            onClick={() =>
              navigateToIntegrationBuilderPostIntegrationInitialization(
                history,
                integrationID,
                true
              )
            }
          >
            Edit integration
          </Button>
        </div>

        <IntegrationTabs integrationID={integrationID} categories={categories} />
        {children}
      </div>
    </>
  );
};

export default IntegrationPageWrapper;
