import { Button, Card, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import EmptyStateWrapper from "../../shared/layout/EmptyStateWrapper";

import { usePublishModuleContext } from "./context/PublishModuleContext";
import PublishModuleContextProvider from "./context/PublishModuleContextProvider";
import PublishModuleChecksCardV2 from "./IntegrationPublishChecks/PublishModuleChecksCardV2";
import PublishModulePublishModal from "./IntegrationStagedVersions/PublishModulePublishModal";
import PublishModuleStagedVersionsCardV2 from "./IntegrationStagedVersions/PublishModuleStagedVersionsCardV2";

const LoadingSpinnerCard = () => {
  return (
    <Card className="flex justify-center align-center mb-6">
      <EmptyStateWrapper isSpinner />
    </Card>
  );
};

const IntegrationPublishModuleSubtabWithValidatorV2 = () => {
  const {
    integrationID,
    publishModuleInfo,
    hasChanges,
    isPublishBlocked,
    isSaving,
    overrideTicket,
    overrideComment,
    isPublishModalOpen,
    setIsPublishModalOpen,
    onPublish,
  } = usePublishModuleContext();

  return (
    <div className="space-y-8 mb-12">
      <div>
        <Text variant="h3" className="mb-6">{`Staged components`}</Text>
        {!publishModuleInfo ? <LoadingSpinnerCard /> : <PublishModuleStagedVersionsCardV2 />}
      </div>
      {hasChanges && (
        <div className="space-y-8">
          <div className="space-y-4">
            <div className="flex flex-row justify-between items-center">
              <Text variant="h3">Checks</Text>
            </div>
            <PublishModuleChecksCardV2 />
          </div>
          <div className="flex flex-row justify-end w-full">
            {isPublishBlocked ? (
              <div>
                <Tooltip
                  title={
                    "Cannot publish. Blocking rules must be addressed or overridden and mapping tests must pass before publishing."
                  }
                >
                  <Button disabled>Publish staged versions</Button>
                </Tooltip>
              </div>
            ) : (
              <>
                <PublishModulePublishModal
                  open={isPublishModalOpen}
                  onPublish={onPublish}
                  onClose={() => setIsPublishModalOpen(false)}
                  stagedChanges={publishModuleInfo?.staged_changes}
                  integrationID={integrationID}
                  overrideComment={overrideComment}
                  overrideTicket={overrideTicket}
                />
                <Button
                  loading={isSaving}
                  disabled={isSaving}
                  onClick={() => setIsPublishModalOpen(true)}
                >
                  Publish staged versions
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const IntegrationPublishModuleWithContext = ({ integrationID }: { integrationID: string }) => {
  return (
    <PublishModuleContextProvider integrationID={integrationID}>
      <IntegrationPublishModuleSubtabWithValidatorV2 />
    </PublishModuleContextProvider>
  );
};

export default IntegrationPublishModuleWithContext;
