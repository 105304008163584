import { Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { AvatarSize, UserAvatar } from "../../../../shared/MergeAvatars";
import { User } from "../../../../../models/Entities";
import { BlueprintSearchResultBlueprint } from "../../../../../models/blueprint-search/BlueprintSearchOutputModels";
import { Check, Pencil } from "lucide-react";
import BlueprintSearchCardSubtitleText from "./BlueprintSearchCardSubtitleText";

interface Props {
  blueprintResult: BlueprintSearchResultBlueprint;
}

const BlueprintSearchCardTitle = ({ blueprintResult }: Props) => {
  // Very rarely will there be a Blueprint with no "latest_blueprint_version"
  // But if there isn't, we don't render this component
  if (!blueprintResult.latest_blueprint_version) {
    return null;
  }

  const title = blueprintResult.human_name || blueprintResult.name;
  const comment =
    blueprintResult.published_blueprint_version?.comment ??
    blueprintResult.latest_blueprint_version?.comment;
  const publishedDateString = blueprintResult.published_blueprint_version?.created_at
    ? new Date(blueprintResult.published_blueprint_version?.created_at).toLocaleDateString()
    : undefined;
  const lastEditedDateString = blueprintResult.latest_blueprint_version?.created_at
    ? new Date(blueprintResult.latest_blueprint_version?.created_at).toLocaleDateString()
    : undefined;
  const user: User =
    (blueprintResult.published_blueprint_version?.user as User) ??
    (blueprintResult.latest_blueprint_version?.user as User);

  const renderScraperSubtitle = () => {
    if (!blueprintResult.scraper_data_type) return undefined;

    return (
      <>
        <BlueprintSearchCardSubtitleText text={blueprintResult.scraper_data_type} />
        <BlueprintSearchCardSubtitleText text="•" />
      </>
    );
  };

  return (
    <div className="flex flex-col space-y-1 items-start justify-start">
      <Text variant="h5" className="text-start">
        {title}
      </Text>
      <div className="flex flex-row space-x-2 items-center justify-start">
        <BlueprintSearchCardSubtitleText text={blueprintResult.category?.toUpperCase() || "None"} />
        <BlueprintSearchCardSubtitleText text="•" />
        <BlueprintSearchCardSubtitleText text={blueprintResult.operation_type} />
        <BlueprintSearchCardSubtitleText text="•" />
        {renderScraperSubtitle()}
        <Tooltip title={user?.email} className="flex flex-row items-center justify-center">
          <UserAvatar user={user ?? undefined} size={AvatarSize.xxs} />
        </Tooltip>
        <BlueprintSearchCardSubtitleText text={comment} />
        <BlueprintSearchCardSubtitleText
          text={publishedDateString ? `Published ${publishedDateString}` : undefined}
          rightIcon={<Check size={12} />}
        />
        <BlueprintSearchCardSubtitleText
          text={`Last modified ${lastEditedDateString}`}
          rightIcon={<Pencil size={12} />}
        />
      </div>
    </div>
  );
};

export default BlueprintSearchCardTitle;
