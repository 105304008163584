import TypeaheadFormField from "./TypeaheadFormField";

const BlueprintEditorRightPanelArrayLoopForm = () => {
  return (
    <form>
      <TypeaheadFormField
        title="Loop Target Array"
        subtitle="Select an array returned by a previous step."
        valueKey="array"
        parameterType={"array"}
      />
    </form>
  );
};

export default BlueprintEditorRightPanelArrayLoopForm;
