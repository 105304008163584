import SelectiveSyncFilterBuilderContextProvider from "../context/SelectiveSyncFilterBuilderContextProvider";
import SelectiveSyncFilterSchemaFormRoot from "./selective-sync-schema-form/SelectiveSyncFilterSchemaFormRoot";
import { Route, Switch } from "react-router-dom";
import {
  getIntegrationBuilderSelectiveSyncFilterBuilder,
  getIntegrationBuilderSelectiveSyncFilterBuilderCreate,
  getIntegrationBuilderSelectiveSyncFilterBuilderForId,
} from "../../../../router/RouterUtils";
import SelectiveSyncFilterSchemaForm from "./selective-sync-schema-form/SelectiveSyncFilterSchemaForm";
import SelectiveSyncFiltersTableContainer from "./selective-sync-filter-table/SelectiveSyncFiltersTableContainer";

interface Props {
  integrationID: string;
}

const SelectiveSyncFilters = ({ integrationID }: Props) => {
  return (
    <SelectiveSyncFilterBuilderContextProvider integrationID={integrationID}>
      <>
        <Switch>
          <Route
            exact
            path={getIntegrationBuilderSelectiveSyncFilterBuilder(integrationID)}
            render={() => <SelectiveSyncFiltersTableContainer integrationID={integrationID} />}
          />
          <Route
            exact
            path={getIntegrationBuilderSelectiveSyncFilterBuilderForId(integrationID)}
            render={() => <SelectiveSyncFilterSchemaFormRoot integrationID={integrationID} />}
          />
          <Route
            exact
            path={getIntegrationBuilderSelectiveSyncFilterBuilderCreate(integrationID)}
            render={() => (
              <SelectiveSyncFilterSchemaForm
                selectiveSyncFilterSchemaData={null}
                integrationID={integrationID}
              />
            )}
          />
        </Switch>
      </>
    </SelectiveSyncFilterBuilderContextProvider>
  );
};

export default SelectiveSyncFilters;
