import { useEffect, useState } from "react";
import { APIEndpoint } from "../../../../integrations/versioned-components/types";
import TypeaheadHeader from "../../../shared/TypeaheadHeader";
import { fetchAPIEndpointsSimple } from "../shared/SelectiveSyncFilterBuilderAPIClient";
import APIEndpointChoiceRow from "./APIEndpointChoiceRow";

interface Props {
  integrationID: string;
  apiEndpointInfo: APIEndpoint | null;
  showSubtitle?: boolean;
  className?: string;
  setAPIEndpointInfo: React.Dispatch<React.SetStateAction<APIEndpoint | null>>;
  title?: string;
}

const APIEndpointsTypeahead = ({
  integrationID,
  apiEndpointInfo,
  setAPIEndpointInfo,
  showSubtitle = true,
  className = "",
  title = "Third-party endpoint",
}: Props) => {
  const [apiEndpoints, setApiEndpoints] = useState<APIEndpoint[]>([]);

  useEffect(() => {
    fetchAPIEndpointsSimple({
      integrationID,
      onSuccess: (data: APIEndpoint[]) => setApiEndpoints(data),
      onError: () => {},
    });
  }, []);

  const filteredAPIEndpoints = apiEndpoints.filter(
    (endpoint) => endpoint.id !== apiEndpointInfo?.id
  );

  return (
    <TypeaheadHeader
      className={className}
      title={title}
      subtitle={
        showSubtitle ? "The API endpoint that we will apply this selective sync filter to" : ""
      }
      value={apiEndpointInfo}
      options={filteredAPIEndpoints}
      onChange={(_: any, apiEndpoint: any) => setAPIEndpointInfo(apiEndpoint)}
      renderOption={(apiEndpoint) => <APIEndpointChoiceRow apiEndpoint={apiEndpoint} />}
      getOptionLabel={(apiEndpoint: APIEndpoint) => apiEndpoint?.name ?? ""}
      filterOptions={(options: any, { inputValue }: any) => {
        return options.filter((option: APIEndpoint) => {
          const inputValueToLowerCase = inputValue.toLowerCase();
          const endpointName = (option?.name ?? "").toLowerCase();
          const path = (option?.path ?? "").toLowerCase();
          return (
            endpointName.includes(inputValueToLowerCase) || path.startsWith(inputValueToLowerCase)
          );
        });
      }}
    />
  );
};

export default APIEndpointsTypeahead;
