import { useMemo, useState } from "react";
import queryString from "query-string";
import { useDebounce } from "use-debounce";
import { APICategory, Integration, PaginationType } from "../../../../models/Entities";
import { usePaginatedRequest } from "../../../../hooks/usePaginatedRequest";

// constants
export const ALL_CATEGORIES = "all";
export enum INTEGRATION_ORDER_BY_OPTIONS {
  NAME = "name",
  LAST_MODIFIED = "-last_modified_blueprint_at",
  LINKED_ACCOUNTS = "-number_of_linked_accounts",
}

interface UsePaginatedIntegrationsProps {
  isLeftNav?: boolean;
}

const useLoadPaginatedIntegrations = ({ isLeftNav = false }: UsePaginatedIntegrationsProps) => {
  // state
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 250);
  const [category, setCategory] = useState<APICategory | "all">(ALL_CATEGORIES);
  const [orderBy, setOrderBy] = useState<INTEGRATION_ORDER_BY_OPTIONS>(
    INTEGRATION_ORDER_BY_OPTIONS.NAME
  );

  // derived state
  const paramsPath = useMemo(() => {
    const queryParams: any = {};

    if (debouncedSearchTerm) queryParams.search_term = debouncedSearchTerm;
    if (category && category !== ALL_CATEGORIES) queryParams.category = category;
    queryParams.order_by = orderBy;
    if (isLeftNav) queryParams.is_leftnav = isLeftNav;

    return queryString.stringify(queryParams);
  }, [category, debouncedSearchTerm, orderBy]);

  // hooks
  const { results: integrations, hasNext, onNext, isLoading } = usePaginatedRequest<Integration>({
    paramsPath,
    rootPath: "/integrations/admin-meta",
    isInfiniteScroll: true,
    paginationType: PaginationType.OFFSET,
  });

  return {
    searchTerm,
    setSearchTerm,
    category,
    setCategory,
    orderBy,
    setOrderBy,
    integrations,
    hasNext,
    onNext,
    isLoading,
  };
};

export { useLoadPaginatedIntegrations };
