import React from "react";
export interface SelectiveSyncFilterBuilderContextState {
  integrationID: string;
}

const SelectiveSyncFilterBuilderContext = React.createContext<
  SelectiveSyncFilterBuilderContextState
>({
  integrationID: "",
});

export default SelectiveSyncFilterBuilderContext;
