import { Modal, Row, Col } from "react-bootstrap";
import classNames from "classnames";
import DeprecatedH4 from "../deprecated/DeprecatedH4";

type MergeModalProps = {
  show: boolean;
  title?: string;
  nav?: JSX.Element;
  onHide?: () => void;
  onShow?: () => void;
  children: React.ReactNode;
  centered?: boolean | null;
  dialogClassName?: string;
  bodyClassName?: string;
  ref?: any;
  fitContent?: boolean;
};

function MergeModal(props: MergeModalProps) {
  return (
    <Modal
      show={props.show ?? false}
      onHide={props.onHide}
      onShow={props.onShow}
      centered={!!props.centered || true}
      dialogClassName={props.dialogClassName}
      ref={props.ref}
      className={classNames("modal-shadow", props.fitContent ? "fit-content" : "")}
    >
      {props.title && (
        <Modal.Header closeButton>
          {props.nav ? (
            <Row className="w-100 align-items-end">
              <Col className="my-auto">
                <DeprecatedH4 className="mb-0">{props.title}</DeprecatedH4>
              </Col>
              <Col className="col-auto">{props.nav}</Col>
            </Row>
          ) : (
            <Modal.Title className="mb-0">{props.title}</Modal.Title>
          )}
        </Modal.Header>
      )}
      <Modal.Body className={props.bodyClassName}>{props.children}</Modal.Body>
    </Modal>
  );
}

export default MergeModal;
