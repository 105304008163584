import classNames from "classnames";
import { Button, ButtonGroup } from "react-bootstrap";
import styled, { css } from "styled-components";

export interface MultiSwitchOption {
  text: string;
  id: string;
  selectedColor: string;
  backgroundColor?: string;
  borderColor?: string;
  disable?: boolean;
  disableTooltip?: string;
}

interface Props {
  options: MultiSwitchOption[];
  selectedID: string;
  onSelectOption: (option: MultiSwitchOption) => void;
  className?: string;
  keyPrefix?: string;
}

/**
 * Describes where a button is, determining what borders/rounding of corners applies
 */
type Layout = "right" | "middle" | "left" | "alone";

/**
 * Adds a background or border color to the button, overriding the other styles on the button.
 * Resets the border radius.
 */
const StyledButton = styled(Button)<{
  $backgroundColor?: string;
  $borderColor?: string;
  $layout: Layout;
}>`
  border-radius: 0 !important;
  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor} !important;
    `};
  ${({ $borderColor }) =>
    $borderColor &&
    css`
      border: 1px solid ${$borderColor} !important;
    `};
  ${({ $layout }) => {
    const left = css`
      border-radius: 5px 0 0 5px !important;
    `;
    const right = css`
      border-radius: 0 5px 5px 0 !important;
    `;
    switch ($layout) {
      case "alone":
        return css`
          ${left};
          ${right};
        `;
      case "left":
        return css`
          ${left};
          border-right-width: 0 !important;
        `;
      case "right":
        return right;
      case "middle":
        return css`
          border-right-width: 0 !important;
        `;
    }
  }}
`;

const MultiSwitch = ({ options, selectedID, onSelectOption, className }: Props) => {
  const selectedIndex = options.findIndex((option) => option.id === selectedID);

  return (
    <ButtonGroup className={classNames("multi-switch-button-group", className)}>
      {options.map((option, i) => {
        const isFirst = i === 0;
        const isLast = i === options.length - 1;
        const isAlone = isFirst && isLast;
        const layout: Layout = isAlone ? "alone" : isFirst ? "left" : isLast ? "right" : "middle";
        return (
          <StyledButton
            type="button"
            variant="secondary"
            className={selectedIndex === i ? "multi-switch-button-selected" : "multi-switch-button"}
            $layout={layout}
            $backgroundColor={
              selectedIndex === i ? options[selectedIndex].selectedColor : option.backgroundColor
            }
            $borderColor={option.borderColor}
            key={i}
            onClick={() => {
              onSelectOption(option);
            }}
          >
            <b>{option.text}</b>
          </StyledButton>
        );
      })}
    </ButtonGroup>
  );
};

export default MultiSwitch;
