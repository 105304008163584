import TextFieldHeader from "../../../shared/TextFieldHeader";
import BinaryChoiceButtons from "../shared/BinaryChoiceButtons";
import ClientCredentialSourceButtons from "../buttons/ClientCredentialSourceButtons";
import { ClientCredentialSource } from "../../../../integrations/auth-config/AuthConfigShared";
import LineIndent from "../shared/LineIndent";
import KeyPathFieldHeader from "../../../shared/KeyPathFieldHeader";
import AccordionCardForSection from "../../../shared/AccordionCardForSection";
import { Link, Text } from "@merge-api/merge-javascript-shared";
import { getIntegrationBuilderLinkingFlowStepsPathConfigurationForId } from "../../../../../router/RouterUtils";

interface AuthorizationURLCardProps {
  oAuthAuthorizeURL: string | undefined;
  setOAuthAuthorizeURL: React.Dispatch<React.SetStateAction<string | undefined>>;
  redirectQueryParams: string[] | null;
  setRedirectQueryParams: React.Dispatch<React.SetStateAction<string[] | null>>;
  doesOAuthUsePKCE: boolean | undefined;
  setDoesOAuthUsePKCE: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  clientCredentialSourceChoice: ClientCredentialSource | undefined;
  setClientCredentialSourceChoice: React.Dispatch<
    React.SetStateAction<ClientCredentialSource | undefined>
  >;
  oAuthClientID: string | undefined;
  setOAuthClientID: React.Dispatch<React.SetStateAction<string | undefined>>;
  oAuthClientSecret: string | undefined;
  setOAuthClientSecret: React.Dispatch<React.SetStateAction<string | undefined>>;
  oAuthShouldFetchAccessToken: boolean | undefined;
  setOAuthShouldFetchAccessToken: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  oAuthAuthorizeURLParamKeysToExclude: string[] | null;
  setOAuthAuthorizeURLParamKeysToExclude: React.Dispatch<React.SetStateAction<string[] | null>>;
  hasPermissionToEdit: boolean;
  integrationId: string;
}

const AuthorizationConfigurationCard = ({
  oAuthAuthorizeURL,
  setOAuthAuthorizeURL,
  redirectQueryParams,
  setRedirectQueryParams,
  doesOAuthUsePKCE,
  setDoesOAuthUsePKCE,
  clientCredentialSourceChoice,
  setClientCredentialSourceChoice,
  oAuthClientID,
  setOAuthClientID,
  oAuthClientSecret,
  setOAuthClientSecret,
  oAuthShouldFetchAccessToken,
  setOAuthShouldFetchAccessToken,
  oAuthAuthorizeURLParamKeysToExclude,
  setOAuthAuthorizeURLParamKeysToExclude,
  hasPermissionToEdit,
  integrationId,
}: AuthorizationURLCardProps) => {
  return (
    <AccordionCardForSection
      dataTestID="accordion-auth-config-authorization-configuration"
      title="Authorization configuration"
      defaultExpanded
      hasPermissionToEdit={hasPermissionToEdit}
    >
      <TextFieldHeader
        dataTestID="field-auth-config-authorization-url"
        title="Authorization URL"
        subtitle="Enter the URL that users need to go to for authorization."
        learnMoreText="Ensure that there is no question mark,'?', at the end. You can use {DOMAIN}, {API-KEY}, and {PATH} in this URL. For example, 'https://{DOMAIN}.bamboohr.com/authorize.php?request=authorize&scope=openid+email'"
        placeholder="example.com"
        prefix="https://"
        prefixVariant="url"
        required
        value={oAuthAuthorizeURL}
        onChange={(newEvent) => {
          setOAuthAuthorizeURL(newEvent.target.value);
        }}
      />
      <ClientCredentialSourceButtons
        clientCredentialSourceChoice={clientCredentialSourceChoice}
        setClientCredentialSourceChoice={setClientCredentialSourceChoice}
        className="mt-6"
      />
      {clientCredentialSourceChoice === ClientCredentialSource.customer && (
        <LineIndent className="mt-6">
          <Text>
            For customer's client credentials to work, make sure "Required customer credential
            fields for partnership auth" are filled out on this{" "}
          </Text>
          <Link
            target="_blank"
            href={getIntegrationBuilderLinkingFlowStepsPathConfigurationForId(integrationId)}
          >
            page.
          </Link>
        </LineIndent>
      )}
      {clientCredentialSourceChoice !== ClientCredentialSource.customer && (
        <LineIndent className="mt-6">
          <TextFieldHeader
            dataTestID="field-auth-config-client-id"
            title="Client ID"
            placeholder="12345"
            value={oAuthClientID}
            onChange={(newEvent) => {
              setOAuthClientID(newEvent.target.value);
            }}
          />
          <TextFieldHeader
            className="mt-6"
            dataTestID="field-auth-config-client-secret"
            title="Client secret"
            placeholder="12345"
            type="password"
            value={oAuthClientSecret}
            onChange={(newEvent) => {
              setOAuthClientSecret(newEvent.target.value);
            }}
          />
        </LineIndent>
      )}
      <AccordionCardForSection
        dataTestID="accordion-auth-config-advanced-authorization-configuration"
        title="Advanced"
        badgeContents={4}
        variant="outline"
        className="mt-6 mb-0 clickable-element pointer-events-auto cursor-pointer"
        nested
        hasPermissionToEdit={hasPermissionToEdit}
      >
        <KeyPathFieldHeader
          dataTestID="field-auth-config-redirect-query-params"
          title="Redirect query params"
          subtitle="If an integration includes additional query parameters in the redirect URL after OAuth authorization. Type the name of the key below and press Enter."
          learnMoreText="For example, Quickbooks Online includes the 'realmId' key in the redirect URL"
          placeholder="Add key name..."
          keyPath={redirectQueryParams}
          onKeyPathChange={setRedirectQueryParams}
          hasSource={false}
          showArrow={false}
        />
        <KeyPathFieldHeader
          className="mt-6"
          dataTestID="field-auth-config-authorize-url-param-keys-to-exclude"
          title="Authorize URL parameter keys to exclude"
          subtitle="In very rare cases, an integration may need to exclude params for authorization. Type the name of the key below and press Enter."
          learnMoreText="For example, Bullhorn excludes the 'redirect_uri' key during authorization"
          placeholder="Add key name..."
          keyPath={oAuthAuthorizeURLParamKeysToExclude}
          onKeyPathChange={setOAuthAuthorizeURLParamKeysToExclude}
          hasSource={false}
          showArrow={false}
        />
        <BinaryChoiceButtons
          className="mt-6"
          dataTestID="field-auth-config-oauth-use-pkce"
          title="Does the OAuth flow use PKCE?"
          subtitle="For the vast majority of integrations this can remain false. However, for integrations that follow PKCE standard this must be set to true."
          learnMoreText="PKCE (Proof Key for Code Exchange) is an enhancement to the OAuth 2.0 authorization flow, designed primarily to secure public clients that cannot securely store secrets. An example of an integration that follows PKCE standard is Paycor, which requires this to be set to true"
          binaryChoice={doesOAuthUsePKCE}
          setBinaryChoice={setDoesOAuthUsePKCE}
        />
        <BinaryChoiceButtons
          dataTestID="field-auth-config-oauth-should-fetch-access-token"
          className="mt-6"
          title="Does the access token need to be fetched immediately after authorization?"
          subtitle="For the vast majority of integrations this can remain false, however, in some cases, the access token must be fetched again immediately upon authorization"
          binaryChoice={oAuthShouldFetchAccessToken}
          setBinaryChoice={setOAuthShouldFetchAccessToken}
        />
      </AccordionCardForSection>
    </AccordionCardForSection>
  );
};

export default AuthorizationConfigurationCard;
