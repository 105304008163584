import { APICategory } from "@merge-api/merge-javascript-shared";
import {
  TypeaheadComparatorClusterOption,
  TypeaheadComparatorEnum,
} from "@merge-api/merge-javascript-shared/dist/designSystem/molecules/Typeahead/types/types";
import {
  BlueprintExtendedOperationType,
  BlueprintStatus,
  BlueprintVersionPublishState,
} from "../../../../models/Blueprints";
import {
  BlueprintSearchCommonModelFilter,
  BlueprintSearchFilterTypeaheadEnum,
  BlueprintSearchFilterSet,
  BlueprintSearchFilterTypeEnum,
  MapBlueprintSearchFilterTypeaheadDisplayName,
} from "../../../../models/blueprint-search/BlueprintSearchInputModels";
import { BlueprintSearchResultBlueprint } from "../../../../models/blueprint-search/BlueprintSearchOutputModels";
import { DEFAULT_CATEGORY_TYPEAHEAD_OPTION, SortTypeEnum } from "./constants";
import { IntegrationCommonModelAndFields } from "../../../../models/Entities";

/**
 * Converts typeahead comparator filters to BlueprintSearchFilterSet
 * Example of a Typeahead Filter 
 * {
      value: "category",
      label: "Category",
      comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ANY],
      target: {
        targetOptions: [
          {
            value: "ats",
            label: "ATS",
          },
        ],
      },
    },
 * @param typeaheadFilters - list of filters from Typeahead when isComparatorCluster=true
 * @returns [BlueprintSearchFilterSet]
 */
export const convertTypeaheadFiltersToRequestBody = (
  typeaheadFilters: TypeaheadComparatorClusterOption[]
): BlueprintSearchFilterSet => {
  let filterSet: BlueprintSearchFilterSet = {};

  typeaheadFilters.forEach((filter) => {
    switch (filter.value) {
      case BlueprintSearchFilterTypeaheadEnum.CATEGORY:
        filterSet.category = {
          filter_type: BlueprintSearchFilterTypeEnum.CONTAINS_ANY,
          values: filter.target?.targetOptions?.map((option) => option.value as APICategory) || [],
        };
        break;
      case BlueprintSearchFilterTypeaheadEnum.OPERATION_TYPE:
        filterSet.operation_type = {
          filter_type: BlueprintSearchFilterTypeEnum.CONTAINS_ANY,
          values:
            filter.target?.targetOptions?.map(
              (option) => option.value as BlueprintExtendedOperationType
            ) || [],
        };
        break;
      case BlueprintSearchFilterTypeaheadEnum.PUBLISHED_STATUS:
        filterSet.published_status = {
          filter_type: BlueprintSearchFilterTypeEnum.EQUALS,
          value: filter.target?.targetOptions?.[0].value as BlueprintVersionPublishState,
        };
        break;
      case BlueprintSearchFilterTypeaheadEnum.ARCHIVED_STATUS:
        filterSet.archived_status = {
          filter_type: BlueprintSearchFilterTypeEnum.EQUALS,
          value: filter.target?.targetOptions?.[0].value as BlueprintStatus,
        };
        break;
      case BlueprintSearchFilterTypeaheadEnum.API_ENDPOINT_ID:
        filterSet.api_endpoint_id = {
          filter_type: BlueprintSearchFilterTypeEnum.CONTAINS_ALL,
          values: filter.target?.targetOptions?.map((option) => option.value as string) || [],
        };
        break;
      case BlueprintSearchFilterTypeaheadEnum.FUNCTIONAL_BLUEPRINT_ID:
        filterSet.functional_blueprint_id = {
          filter_type: BlueprintSearchFilterTypeEnum.CONTAINS_ALL,
          values: filter.target?.targetOptions?.map((option) => option.value as string) || [],
        };
        break;
      // We process Common Models separately, since this involves looking at 2 typeaheadFilters
      // Note: This runs the function an extra unnecessary time if both filters are included,
      // but this is a relatively light operation, so inefficiency should not noticable at all.
      case BlueprintSearchFilterTypeaheadEnum.COMMON_MODEL_FIELD:
      case BlueprintSearchFilterTypeaheadEnum.COMMON_MODEL:
        filterSet.common_model = convertCommonModelTypeaheadFiltersToRequestBody(typeaheadFilters);
        break;
      default:
        console.warn(`Unhandled filter type: ${filter.value}`);
    }
  });

  return filterSet;
};

/**
 * Constructs value & label for Typehead dropdown option for a common model field
 * @param model id of common model
 * @param field field of common model
 * @returns label to show in Typeahead dropdown for common model field
 */
export const constructLabelFromCommonModelAndField = (model: string, field: string): string => {
  return `${model}.${field}`;
};

/**
 * Parses common model id and field from Typeahead dropdown
 * @param label Typeahead dropdown label coresponding to comon model field
 * @returns array of string containing model id and field
 */
export const parseCommonModelAndFieldFromLabel = (label: string): string[] => {
  const labelArray = label.split(".");
  const field = labelArray[labelArray.length - 1];
  const model = labelArray.slice(0, -1).join(".");
  return [model, field];
};

/**
 * Converts TypeaheadComparatorClusterOption filters for common models and fields
 * into a BlueprintSearchCommonModelFilter for the API request.
 *
 * This function processes both "common_model" and "common_model_field" filters,
 * combining them into a single structure that represents the selected common models
 * and their associated fields (if any).
 *
 * @param typeaheadFilters - Array of TypeaheadComparatorClusterOption objects
 * @returns BlueprintSearchCommonModelFilter object for API request
 */
export const convertCommonModelTypeaheadFiltersToRequestBody = (
  typeaheadFilters: TypeaheadComparatorClusterOption[]
): BlueprintSearchCommonModelFilter => {
  // Construct a map of common models (and fields) from both filters
  let mapCommonModelToFields: Record<string, string[]> = {};

  const fieldTypeaheadFilter = typeaheadFilters.find(
    (filter) => filter.value === BlueprintSearchFilterTypeaheadEnum.COMMON_MODEL_FIELD
  );
  if (fieldTypeaheadFilter) {
    fieldTypeaheadFilter.target.targetOptions.forEach((selectedFieldOption) => {
      const [model, field] = parseCommonModelAndFieldFromLabel(selectedFieldOption.value);
      mapCommonModelToFields[model] =
        model in mapCommonModelToFields ? [...mapCommonModelToFields[model], field] : [field];
    });
  }

  const modelTypeaheadFilter = typeaheadFilters.find(
    (filter) => filter.value === BlueprintSearchFilterTypeaheadEnum.COMMON_MODEL
  );
  if (modelTypeaheadFilter) {
    modelTypeaheadFilter.target.targetOptions.forEach((selectedModelOption) => {
      if (!(selectedModelOption.value in mapCommonModelToFields)) {
        mapCommonModelToFields[selectedModelOption.value] = [];
      }
    });
  }

  return {
    filter_type: BlueprintSearchFilterTypeEnum.CONTAINS_ALL,
    values: Object.keys(mapCommonModelToFields).map((key) => {
      return {
        model: key,
        ...(mapCommonModelToFields[key].length > 0 ? { fields: mapCommonModelToFields[key] } : {}),
      };
    }),
  };
};

/**
 * Constructs TypeaheadComparatorClusterOption arrays for Common Models and Common Model Fields
 * @param commonModelsAndFieldsData Array of IntegrationCommonModelAndFields objects
 * @returns Array of TypeaheadComparatorClusterOption for Common Models and Common Model Fields
 */
export const constructCommonModelAndFieldTypeaheadOptions = (
  commonModelsAndFieldsData: IntegrationCommonModelAndFields[]
): TypeaheadComparatorClusterOption[] => {
  const commonModelOptions: TypeaheadComparatorClusterOption = {
    value: BlueprintSearchFilterTypeaheadEnum.COMMON_MODEL,
    label:
      MapBlueprintSearchFilterTypeaheadDisplayName[BlueprintSearchFilterTypeaheadEnum.COMMON_MODEL],
    comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ALL],
    target: {
      targetOptions: commonModelsAndFieldsData.map((commonModel) => {
        return {
          value: commonModel.id,
          label: commonModel.id,
        };
      }),
    },
  };
  // Construct flattened list of all common model fields, in displayable format
  let fieldLabelsArray: string[] = [];
  commonModelsAndFieldsData.forEach((commonModel) => {
    commonModel.model_fields.forEach((field) => {
      fieldLabelsArray.push(constructLabelFromCommonModelAndField(commonModel.id, field));
    });
  });
  const fieldOptions: TypeaheadComparatorClusterOption = {
    value: BlueprintSearchFilterTypeaheadEnum.COMMON_MODEL_FIELD,
    label:
      MapBlueprintSearchFilterTypeaheadDisplayName[
        BlueprintSearchFilterTypeaheadEnum.COMMON_MODEL_FIELD
      ],
    comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ALL],
    target: {
      targetOptions: fieldLabelsArray.map((fieldLabel) => {
        return {
          value: fieldLabel,
          label: fieldLabel,
        };
      }),
    },
  };

  return [commonModelOptions, fieldOptions];
};

/**
 * Sorts Blueprint search results given the sortType
 */
export const sortBlueprintResults = (
  blueprintResults: BlueprintSearchResultBlueprint[],
  sortType: SortTypeEnum
): BlueprintSearchResultBlueprint[] => {
  return blueprintResults.sort(
    (sortType === SortTypeEnum.MODIFIED &&
      ((a, b) =>
        a.latest_blueprint_version.created_at > b.latest_blueprint_version.created_at ? -1 : 1)) ||
      (sortType === SortTypeEnum.ALPHA &&
        ((a, b) => ((a.human_name || a.name) > (b.human_name || b.name) ? 1 : -1))) ||
      ((a, b) => ((a.human_name || a.name) > (b.human_name || b.name) ? -1 : 1))
  );
};

export const filterCategoryTypeaheadOptions = (
  categories: string[]
): TypeaheadComparatorClusterOption => {
  return {
    ...DEFAULT_CATEGORY_TYPEAHEAD_OPTION,
    target: {
      targetOptions: DEFAULT_CATEGORY_TYPEAHEAD_OPTION.target.targetOptions.filter((option) =>
        categories.includes(option.value)
      ),
    },
  };
};
