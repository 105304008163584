import { DateTimeFormatEnums, TokenExpiresInType } from "../../../utils/Entities";
import DateTimeFormatButtons from "../buttons/DateTimeFormatButtons";
import TokenExpiresInTypeButtons from "../buttons/TokenExpiresInTypeButtons";
import LineIndent from "../shared/LineIndent";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import KeyPathFieldHeader from "../../../shared/KeyPathFieldHeader";

interface TokenURLResponseRenderedComponenetsProps {
  doesResponseIncludeExpiresInParam: boolean | undefined;
  tokenExpiresInType: TokenExpiresInType | undefined;
  setTokenExpiresInType: React.Dispatch<React.SetStateAction<TokenExpiresInType | undefined>>;
  dateTimeFormat: DateTimeFormatEnums | undefined;
  setDateTimeFormat: React.Dispatch<React.SetStateAction<DateTimeFormatEnums | undefined>>;
  oAuthResponseTokenLifespanInSeconds: number | undefined;
  setOAuthResponseTokenLifespanInSeconds: React.Dispatch<React.SetStateAction<number | undefined>>;
  oAuthResponseCreatedAtKeyPath: string[] | null;
  setOAuthResponseCreatedAtKeyPath: React.Dispatch<React.SetStateAction<string[] | null>>;
}

const TokenURLResponseRenderedComponenets = ({
  doesResponseIncludeExpiresInParam,
  tokenExpiresInType,
  setTokenExpiresInType,
  dateTimeFormat,
  setDateTimeFormat,
  oAuthResponseTokenLifespanInSeconds,
  setOAuthResponseTokenLifespanInSeconds,
  oAuthResponseCreatedAtKeyPath,
  setOAuthResponseCreatedAtKeyPath,
}: TokenURLResponseRenderedComponenetsProps) => {
  if (doesResponseIncludeExpiresInParam) {
    return (
      <>
        <LineIndent className="mt-6">
          <TokenExpiresInTypeButtons
            tokenExpiresInType={tokenExpiresInType}
            setTokenExpiresInType={setTokenExpiresInType}
          />
        </LineIndent>
        {tokenExpiresInType === TokenExpiresInType.CREATED && (
          <LineIndent className="mt-6 pl-7">
            <KeyPathFieldHeader
              dataTestID="field-auth-config-refresh-created-time-key-path"
              title="Created time key path"
              subtitle="If the OAuth token response contains a 'created_at' field that needs to be used to calculate token expiration, then specify the key path to that field. Otherwise, expiration will be calculated based on the current time and the 'expires_in' value when a token is fetched. Type the name of the key below and press Enter."
              placeholder="Add key name..."
              learnMoreText="For example, OneLogin will return an existing token if there is an unexpired one, instead of a creating a new one. It will have an 'expires_in' of 36000 (10 hours), however, the 'created_at' will be some time in the past. Therefore, the expiration time is not 10 hours from now, but it is 10 hours from the 'created_at'."
              keyPath={oAuthResponseCreatedAtKeyPath}
              onKeyPathChange={setOAuthResponseCreatedAtKeyPath}
              hasSource={false}
            />
            <DateTimeFormatButtons
              dateTimeFormat={dateTimeFormat}
              setDateTimeFormat={setDateTimeFormat}
            />
          </LineIndent>
        )}
      </>
    );
  } else if (doesResponseIncludeExpiresInParam === false) {
    return (
      <LineIndent className="mt-6">
        <TextFieldHeader
          dataTestID="field-auth-config-refresh-access-token-lifespan"
          title="Access token lifespan"
          subtitle="Enter token lifespan in seconds"
          placeholder="12345"
          value={oAuthResponseTokenLifespanInSeconds}
          type="number"
          onChange={(newEvent) => {
            const value = newEvent.target.value;
            if (value === "") {
              setOAuthResponseTokenLifespanInSeconds(undefined);
            } else {
              const numericValue = parseInt(value, 10);
              if (!isNaN(numericValue)) {
                setOAuthResponseTokenLifespanInSeconds(numericValue);
              }
            }
          }}
        />
      </LineIndent>
    );
  }
  // returning null for the case where doesResponseIncludeExpiresInParam is undefined
  return null;
};

export default TokenURLResponseRenderedComponenets;
