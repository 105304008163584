import { Button, ButtonVariant, Badge, Text } from "@merge-api/merge-javascript-shared";
import { AlertTriangle, CheckCircle, RefreshCw } from "lucide-react";
import { palette } from "../../../../styles/theme/colors";
import { ChecklistStatusValue, IntegrationChecklistData } from "../../../../constants";

type Props = {
  integrationChecklist: IntegrationChecklistData | undefined;
  integrationID: string;
  refreshIntegrationChecklist: () => void;
  isRefreshingChecklist: boolean;
};

const IntegrationChecklistAccordionTitle = ({
  integrationChecklist,
  refreshIntegrationChecklist,
  isRefreshingChecklist,
}: Props) => {
  const failureCount = integrationChecklist
    ? Object.values(integrationChecklist).filter(
        (value) => value == ChecklistStatusValue.NOT_IMPLEMENTED
      ).length
    : -1;

  return (
    <div className="flex flex-row justify-between w-full">
      <div className="flex flex-row justify-start items-center space-x-4">
        {isRefreshingChecklist ? (
          <RefreshCw className="rotating" color={palette.blue} size={24} />
        ) : !integrationChecklist || failureCount > 0 ? (
          <AlertTriangle color={palette.yellow} />
        ) : (
          <CheckCircle color={palette.green} />
        )}
        <Text variant="h5" className="text-black">
          Integration checklist
        </Text>
        {!isRefreshingChecklist && failureCount > 0 && (
          <Badge color="yellow">
            {failureCount.toString() + (failureCount > 1 ? " warnings" : " warning")}
          </Badge>
        )}
      </div>
      <div className="mr-6">
        {isRefreshingChecklist ? (
          <Button
            size="sm"
            variant={ButtonVariant.TertiaryWhite}
            leftIcon={<RefreshCw className="rotating" size={12} />}
            disabled
          >
            Re-run
          </Button>
        ) : (
          <Button
            size="sm"
            variant={ButtonVariant.TertiaryWhite}
            leftIcon={<RefreshCw size={12} />}
            onClick={(e) => {
              e.stopPropagation();
              refreshIntegrationChecklist();
            }}
          >
            Re-run
          </Button>
        )}
      </div>
    </div>
  );
};

export default IntegrationChecklistAccordionTitle;
