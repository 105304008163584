import { fetchWithAuth } from "../../../api-client/api_client";
import { LinkedAccountAccessSession } from "../../../models/Entities";

interface FetchLinkedAccountAccessSessionProps {
  setLinkedAccountAccessSession: (
    linkedAccountAccessSession: LinkedAccountAccessSession | null
  ) => void;
  setIsUnauthorizedUser: (value: boolean) => void;
}

const useFetchLinkedAccountAccessSession = ({
  setLinkedAccountAccessSession,
  setIsUnauthorizedUser,
}: FetchLinkedAccountAccessSessionProps) => {
  const fetchLinkedAccountAccessSession = () => {
    fetchWithAuth({
      path: `/integrations/linked-accounts/access-session`,
      method: "GET",
      onResponse: (data: LinkedAccountAccessSession | null) => {
        setLinkedAccountAccessSession(data);
        setIsUnauthorizedUser(false);
      },
      onError: (error) => {
        if (error?.status === 403) {
          setIsUnauthorizedUser(true);
        } else {
          setIsUnauthorizedUser(false);
          setLinkedAccountAccessSession(null);
        }
      },
    });
  };

  return { fetchLinkedAccountAccessSession };
};

export default useFetchLinkedAccountAccessSession;
