import {
  TypeaheadComparatorClusterOption,
  TypeaheadComparatorEnum,
} from "@merge-api/merge-javascript-shared/dist/designSystem/molecules/Typeahead/types/types";
import { useEffect, useState } from "react";
import {
  DEFAULT_ARCHIVED_STATUS_TYPEAHEAD_OPTION,
  DEFAULT_OPERATION_TYPE_TYPEAHEAD_OPTION,
  DEFAULT_PUBLISHED_STATUS_TYPEAHEAD_OPTION,
  FUNCTIONAL_BLUEPRINT_BLUEPRINT_SEARCH_FILTER_SET,
} from "../utils/constants";
import { fetchBlueprintSearch } from "../utils/BlueprintSearchAPIClient";
import { BlueprintSearchResults } from "../../../../models/blueprint-search/BlueprintSearchOutputModels";
import { showErrorToast } from "../../../../components/shared/Toasts";
import {
  fetchIntegrationCommonModelAndFields,
  fetchSimpleAPIEndpoints,
} from "../../utils/IntegrationsAPIClient";
import {
  constructCommonModelAndFieldTypeaheadOptions,
  filterCategoryTypeaheadOptions,
} from "../utils/BlueprintSearchUtils";
import { Integration, IntegrationCommonModelAndFields } from "../../../../models/Entities";
import {
  BlueprintSearchFilterTypeaheadEnum,
  MapBlueprintSearchFilterTypeaheadDisplayName,
} from "../../../../models/blueprint-search/BlueprintSearchInputModels";

/**
 * This hook manages the state and logic for available filters in the Blueprint search.
 *
 * It uses the following state variables:
 * - availableFilters: Stores the array of TypeaheadComparatorClusterOption for available filters
 * - isLoadingAvailableFilters: Indicates whether the filters are currently being loaded
 * - isLoadingFunctionalBlueprints: Tracks the loading state of functional blueprints
 * - isLoadingAPIEndpoints: Tracks the loading state of API endpoints
 *
 * @returns {Object} An object containing:
 *   - availableFilters: Array of TypeaheadComparatorClusterOption for available filters
 *   - isLoadingAvailableFilters: Boolean indicating if filters are being loaded
 *   - initializeAvailableFilters: Function to initialize filters for a given integration
 */
const useBlueprintSearchAvailableFilters = () => {
  const [availableFilters, setAvailableFilters] = useState<TypeaheadComparatorClusterOption[]>([]);
  const [isLoadingAvailableFilters, setIsLoadingAvailableFilters] = useState<boolean>(false);
  const [isLoadingFunctionalBlueprints, setIsLoadingFunctionalBlueprints] = useState<boolean>(
    false
  );
  const [isLoadingAPIEndpoints, setIsLoadingAPIEndpoints] = useState<boolean>(false);
  const [isLoadingCommonModels, setIsLoadingCommonModels] = useState<boolean>(false);

  // Sets the `isLoadingAvailableFilters` to True once ALL API requests are finished loading
  useEffect(() => {
    setIsLoadingAvailableFilters(
      isLoadingFunctionalBlueprints || isLoadingAPIEndpoints || isLoadingCommonModels
    );
  }, [isLoadingFunctionalBlueprints, isLoadingAPIEndpoints, isLoadingCommonModels]);

  /**
   * Initializes available filters for Blueprint Search
   * Filters include
   *  - Category (filtered by the integration's categories)
   *  - Operation type
   *  - Published status
   *  - Archived status
   *  - API endpoints
   *  - Functional Blueprints
   * @param integration The integration to initialize filters for
   */
  const initializeAvailableFilters = (integration: Integration) => {
    setIsLoadingAvailableFilters(true);
    setIsLoadingFunctionalBlueprints(true);
    setIsLoadingAPIEndpoints(true);
    setIsLoadingCommonModels(true);

    let filters: TypeaheadComparatorClusterOption[] = [
      DEFAULT_OPERATION_TYPE_TYPEAHEAD_OPTION,
      filterCategoryTypeaheadOptions(integration.categories),
      DEFAULT_PUBLISHED_STATUS_TYPEAHEAD_OPTION,
      DEFAULT_ARCHIVED_STATUS_TYPEAHEAD_OPTION,
    ];
    setAvailableFilters(filters);

    // Fetch functional Blueprints
    fetchBlueprintSearch({
      integrationID: integration.id,
      requestBody: FUNCTIONAL_BLUEPRINT_BLUEPRINT_SEARCH_FILTER_SET,
      onSuccess: (response: BlueprintSearchResults) => {
        const functionalBlueprintTypeaheadOption: TypeaheadComparatorClusterOption = {
          value: BlueprintSearchFilterTypeaheadEnum.FUNCTIONAL_BLUEPRINT_ID,
          label:
            MapBlueprintSearchFilterTypeaheadDisplayName[
              BlueprintSearchFilterTypeaheadEnum.FUNCTIONAL_BLUEPRINT_ID
            ],
          comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ALL],
          target: {
            targetOptions: (response.blueprints ?? []).map((blueprint) => {
              return {
                value: blueprint.id,
                label: blueprint.human_name ? blueprint.human_name : blueprint.name,
              };
            }),
          },
        };
        setAvailableFilters((prevAvailableFilters) => {
          // Due to async, there could be previous options stored. We want to override those.
          const cleanedUpAvailableFilters = prevAvailableFilters.filter(
            (filter) => filter.value !== BlueprintSearchFilterTypeaheadEnum.FUNCTIONAL_BLUEPRINT_ID
          );
          return [...cleanedUpAvailableFilters, functionalBlueprintTypeaheadOption];
        });
      },
      onError: () => {
        showErrorToast("Unable to fetch Functional Blueprints for search");
        setAvailableFilters((prevAvailableFilters) => {
          return prevAvailableFilters.filter(
            (filter) => filter.value !== BlueprintSearchFilterTypeaheadEnum.FUNCTIONAL_BLUEPRINT_ID
          );
        });
      },
      setIsLoading: setIsLoadingFunctionalBlueprints,
    });

    // Fetch API endpoints
    fetchSimpleAPIEndpoints({
      integrationID: integration.id,
      onSuccess: (response: any) => {
        const apiEndpointTypeaheadOption: TypeaheadComparatorClusterOption = {
          value: BlueprintSearchFilterTypeaheadEnum.API_ENDPOINT_ID,
          label:
            MapBlueprintSearchFilterTypeaheadDisplayName[
              BlueprintSearchFilterTypeaheadEnum.API_ENDPOINT_ID
            ],
          comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ALL],
          target: {
            targetOptions: (Array.isArray(response) && response.length > 0 ? response : []).map(
              (simpleAPIEndpoint) => {
                return {
                  value: simpleAPIEndpoint?.id,
                  label:
                    simpleAPIEndpoint?.name ??
                    `${simpleAPIEndpoint?.method} ${simpleAPIEndpoint?.path}`,
                };
              }
            ),
          },
        };
        setAvailableFilters((prevAvailableFilters) => {
          // Due to async, there could be previous options stored. We want to override those.
          const cleanedUpAvailableFilters = prevAvailableFilters.filter(
            (filter) => filter.value !== BlueprintSearchFilterTypeaheadEnum.API_ENDPOINT_ID
          );
          return [...cleanedUpAvailableFilters, apiEndpointTypeaheadOption];
        });
      },
      onError: () => {
        showErrorToast("Unable to fetch API Endpoints for search");
        setAvailableFilters((prevAvailableFilters) => {
          return prevAvailableFilters.filter(
            (filter) => filter.value !== BlueprintSearchFilterTypeaheadEnum.API_ENDPOINT_ID
          );
        });
      },
      setIsLoading: setIsLoadingAPIEndpoints,
    });

    // TODO: Fetch common models
    fetchIntegrationCommonModelAndFields({
      integrationID: integration.id,
      onSuccess: (data: IntegrationCommonModelAndFields[]) => {
        const commonModelAndFieldTypeaheadOptions = constructCommonModelAndFieldTypeaheadOptions(
          data
        );
        setAvailableFilters((prevAvailableFilters) => {
          // Due to async, there could be previous options stored. We want to override those.
          const cleanedUpAvailableFilters = prevAvailableFilters.filter(
            (filter) =>
              filter.value !== BlueprintSearchFilterTypeaheadEnum.COMMON_MODEL &&
              filter.value !== BlueprintSearchFilterTypeaheadEnum.COMMON_MODEL_FIELD
          );
          return [...cleanedUpAvailableFilters, ...commonModelAndFieldTypeaheadOptions];
        });
      },
      onError: () => {
        showErrorToast("Unable to fetch Common Models for search");
        setAvailableFilters((prevAvailableFilters) => {
          return prevAvailableFilters.filter(
            (filter) =>
              filter.value !== BlueprintSearchFilterTypeaheadEnum.COMMON_MODEL &&
              filter.value !== BlueprintSearchFilterTypeaheadEnum.COMMON_MODEL_FIELD
          );
        });
      },
      setIsLoading: setIsLoadingCommonModels,
    });
  };

  return {
    availableFilters,
    initializeAvailableFilters,
    isLoadingAvailableFilters,
  };
};

export default useBlueprintSearchAvailableFilters;
