import { useHistory } from "react-router-dom";
import { RateLimitConfigurationIntegrationBuilder } from "../../../utils/Entities";
import {
  getIntegrationBuilderSingleRateLimitConfigurationPathForId,
  navigateToIntegrationBuilderAPIEndpointForm,
} from "../../../../../router/RouterUtils";
import TypeCell from "./cells/TypeCell";
import RateLimitValueCell from "./cells/RateLimitValueCell";
import ThresholdCell from "./cells/ThresholdCell";
import HeaderCell from "./cells/HeaderCell";
import APIEndpointCell from "./cells/APIEndpointCell";
import ToggleCell from "./cells/ToggleCell";
import { useState } from "react";
import { ButtonVariant, Dialog } from "@merge-api/merge-javascript-shared";
import RateLimitPublishDialogContent from "./cells/RateLimitPublishDialogContent";
import useCreateOrPatchRateLimitConfiguration from "../../hooks/useCreateOrPatchRateLimitConfiguration";

interface RateLimitConfigurationTableRowProps {
  rateLimitConfiguration: RateLimitConfigurationIntegrationBuilder;
  integrationID: string;
  setRateLimitConfigurations: React.Dispatch<
    React.SetStateAction<RateLimitConfigurationIntegrationBuilder[] | undefined>
  >;
}

const RateLimitConfigurationTableRow = ({
  rateLimitConfiguration,
  integrationID,
  setRateLimitConfigurations,
}: RateLimitConfigurationTableRowProps) => {
  // state
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // hooks
  const history = useHistory();
  const { patchRateLimitConfiguration, isLoadingSubmit } = useCreateOrPatchRateLimitConfiguration({
    integrationID: integrationID,
    setRateLimitConfigurations: setRateLimitConfigurations,
    isPublishing: true,
    setIsModalOpen: setIsModalOpen,
  });

  // event handlers
  const onRateLimitConfigurationClick = () => {
    if (rateLimitConfiguration.api_endpoint) {
      navigateToIntegrationBuilderAPIEndpointForm(
        history,
        integrationID,
        rateLimitConfiguration.api_endpoint,
        false,
        true
      );
    } else {
      history.push({
        pathname: getIntegrationBuilderSingleRateLimitConfigurationPathForId(
          integrationID,
          rateLimitConfiguration.id
        ),
      });
    }
  };

  const onToggleClick = () => {
    setIsModalOpen(true);
  };

  return (
    <tr className="cursor-pointer">
      <TypeCell
        onClick={onRateLimitConfigurationClick}
        rateLimitConfiguration={rateLimitConfiguration}
      />
      <RateLimitValueCell
        onClick={onRateLimitConfigurationClick}
        rateLimitConfiguration={rateLimitConfiguration}
      />
      <ThresholdCell
        onClick={onRateLimitConfigurationClick}
        rateLimitConfiguration={rateLimitConfiguration}
      />
      <HeaderCell
        onClick={onRateLimitConfigurationClick}
        rateLimitConfiguration={rateLimitConfiguration}
      />
      <APIEndpointCell
        onClick={onRateLimitConfigurationClick}
        rateLimitConfiguration={rateLimitConfiguration}
      />
      <ToggleCell onClick={onToggleClick} checked={rateLimitConfiguration?.is_active} />
      <Dialog
        title={rateLimitConfiguration.is_active ? "Unpublish rate limit" : "Publish rate limit"}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        primaryButtonVariant={
          rateLimitConfiguration.is_active ? ButtonVariant.DangerFilled : ButtonVariant.PrimaryBlue
        }
        primaryButtonText={rateLimitConfiguration.is_active ? "Unpublish" : "Publish"}
        onPrimaryButtonClick={() => patchRateLimitConfiguration(rateLimitConfiguration)}
        primaryButtonLoading={isLoadingSubmit}
        onSecondaryButtonClick={() => setIsModalOpen(false)}
        children={<RateLimitPublishDialogContent rateLimitConfiguration={rateLimitConfiguration} />}
      />
    </tr>
  );
};

export default RateLimitConfigurationTableRow;
