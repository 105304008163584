import { useContext } from "react";
import MergeTable from "../../../shared/MergeTable";
import APIEndpointTableRow from "../../api-endpoint-setup/components/APIEndpointTableRow";
import AccordionCard from "../../shared/AccordionCard";
import PaginationConfigurationContext from "../context/PaginationConfigurationContext";

const PaginationConfigurationEndpoints = () => {
  const { integrationID, endpoints } = useContext(PaginationConfigurationContext);

  const numEndpoints = endpoints ? endpoints.length : 0;

  return (
    <AccordionCard title="Endpoints" badgeContents={numEndpoints} disabled={numEndpoints == 0}>
      <div>
        <MergeTable
          hasMarginBottom={false}
          className="border border-gray-20"
          isOverviewPage={true}
          hasMorePaddingOnFirstElement
          header={
            <>
              <th scope="col">Name</th>
              <th scope="col">Method</th>
              <th scope="col">Path</th>
            </>
          }
          content={
            endpoints
              ? endpoints.map((endpoint) => (
                  <APIEndpointTableRow
                    key={endpoint.id}
                    apiEndpoint={endpoint}
                    integrationID={integrationID}
                    navigateToNewTab
                  />
                ))
              : []
          }
        />
      </div>
    </AccordionCard>
  );
};

export default PaginationConfigurationEndpoints;
