import clsx from "clsx";
import HeaderBase from "../../../shared/HeaderBase";
import { RateLimitResetTimestampFormat } from "../../../utils/Entities";
import { Chips } from "@merge-api/merge-javascript-shared";

interface ResetTimestampFormatButtonsProps {
  resetExpirationFormat: RateLimitResetTimestampFormat | undefined;
  setResetExpirationFormat: React.Dispatch<
    React.SetStateAction<RateLimitResetTimestampFormat | undefined>
  >;
  className?: string;
}

const ResetTimestampFormatButtons = ({
  resetExpirationFormat,
  setResetExpirationFormat,
  className,
}: ResetTimestampFormatButtonsProps) => {
  const handleButtonClick = (value: string | number | boolean) => {
    if (
      Object.values(RateLimitResetTimestampFormat).includes(value as RateLimitResetTimestampFormat)
    ) {
      setResetExpirationFormat(value as RateLimitResetTimestampFormat);
    }
  };

  const options = [
    {
      value: RateLimitResetTimestampFormat.TIMESTAMP,
      text: "UNIX",
      disabled: false,
      selected: resetExpirationFormat === RateLimitResetTimestampFormat.TIMESTAMP,
      onClick: handleButtonClick,
    },
    {
      value: RateLimitResetTimestampFormat.TTL,
      text: "Time-to-live",
      disabled: false,
      selected: resetExpirationFormat === RateLimitResetTimestampFormat.TTL,
      onClick: handleButtonClick,
    },
  ];
  return (
    <div className={clsx("w-full", className)}>
      <HeaderBase
        title="Reset expiration format field"
        subtitle="Specify the structure of the rate limit reset field's value. 'UNIX' indicates a UNIX timestamp. 'Time-to-live,' indicates the remaining seconds until the rate limit resets."
        required
        dataTestID="field-rate-limit-config-reset-expiration-format"
      />
      <Chips options={options} />
    </div>
  );
};

export default ResetTimestampFormatButtons;
