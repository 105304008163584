import { memo } from "react";
import { Text } from "@merge-api/merge-javascript-shared";
import {
  BlueprintRunnerExecutionResponse,
  StepLoggingViewEnum,
} from "../../../../models/Blueprints";
import { useEffect, useState } from "react";
import IntegrationEditorStepLogsTable from "../../../shared/integration-editor-base/IntegrationEditorStepLogsTable";
import LeftPanelSectionHeader from "../shared/LeftPanelSectionHeader";
import LeftPanelSelect from "../shared/LeftPanelSelect";
import StepIOLogsTable from "./step-io-logs/StepIOLogsTable";
import { MergeFlagFeature, useMergeFlag } from "../../../shared/hooks/useMergeFlag";
import useBlueprintContext from "../../context/useBlueprintContext";

interface Props {
  blueprintRunnerExecutionResponse: BlueprintRunnerExecutionResponse | null;
}

/**
 * Renders the logs and log view options for a Blueprint run
 * If no Blueprint run results, does not render
 */
const BlueprintLeftPanelConsoleResultLogs = ({ blueprintRunnerExecutionResponse }: Props) => {
  // Only show "Step I/O for canvas" option if flag is enabled
  const { stepLoggingView, setStepLoggingView } = useBlueprintContext();
  const [isStepIOInCanvasFlagEnabled, setIsStepIOInCanvasFlagEnabled] = useState<boolean>(false);
  const { enabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_STEP_IO_FOR_CANVAS,
    isEnabledForUser: true,
  });
  useEffect(() => {
    setIsStepIOInCanvasFlagEnabled(enabled ?? false);
  }, [enabled]);
  // Default to STEP_IO_IN_CANVAS if flag enabled, otherwise only care about STEP_IO
  useEffect(() => {
    setStepLoggingView(
      enabled ? StepLoggingViewEnum.STEP_IO_IN_CANVAS : StepLoggingViewEnum.STEP_IO
    );
  }, [enabled]);

  // Why render logs if there is no execution response
  if (!blueprintRunnerExecutionResponse) {
    return null;
  }
  // Extract params we want from execution response
  const { exit_data, start_time } = blueprintRunnerExecutionResponse;

  const renderExitDataLogs = () => {
    if (exit_data) {
      // Render step IO
      if (
        [StepLoggingViewEnum.STEP_IO, StepLoggingViewEnum.STEP_IO_IN_CANVAS].includes(
          stepLoggingView
        )
      ) {
        return (
          <div className="flex flex-col space-y-2">
            <Text variant="h6">Step I/O logs</Text>
            {exit_data.debugging_info?.step_io_logs ? (
              <StepIOLogsTable stepIOLogs={exit_data.debugging_info.step_io_logs} />
            ) : (
              <Text>No step I/O logs found</Text>
            )}
          </div>
        );
      }
      // Render step logs
      return (
        <div className="flex flex-col space-y-2">
          <Text variant="h6">Step logs</Text>
          {exit_data.step_logs ? (
            <IntegrationEditorStepLogsTable startTime={start_time} stepLogs={exit_data.step_logs} />
          ) : (
            <Text>No step logs found</Text>
          )}
        </div>
      );
    }

    return undefined;
  };

  return (
    <>
      <hr className="bg-gray-10 p-0 m-0" />
      <div className="flex flex-col space-y-4 py-4">
        <LeftPanelSectionHeader title="Logs" />
        <LeftPanelSelect
          title="Log view"
          placeholder="Default"
          onChange={(_, value) => setStepLoggingView(value)}
          value={stepLoggingView}
          options={Object.values(StepLoggingViewEnum).filter((view) =>
            isStepIOInCanvasFlagEnabled ? true : view !== StepLoggingViewEnum.STEP_IO_IN_CANVAS
          )}
          clearable={false}
        />
        {renderExitDataLogs()}
      </div>
    </>
  );
};

export default memo(BlueprintLeftPanelConsoleResultLogs);
