import { Text } from "@merge-api/merge-javascript-shared";
import { AdditionalInfoDisplayProps } from "./types";

const UniqueIdentifierAdditionalInfoDisplay = ({ data }: AdditionalInfoDisplayProps) => {
  const {
    unique_identifier_formats_by_blueprint_version_common_model_step_id,
    blueprint_version_id_to_name,
  } = data;

  return (
    <div>
      {Object.keys(unique_identifier_formats_by_blueprint_version_common_model_step_id).map(
        (modelKey) => {
          const blueprints =
            unique_identifier_formats_by_blueprint_version_common_model_step_id[modelKey];
          return (
            <div key={modelKey}>
              <Text variant="h6">{modelKey}</Text>
              <ul>
                {Object.entries(blueprints).map(([blueprintId, value]) => {
                  const blueprintName = blueprint_version_id_to_name[blueprintId];
                  return (
                    <li key={blueprintId}>
                      <Text>{`• ${blueprintName} ${JSON.stringify(value)}`}</Text>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        }
      )}
    </div>
  );
};

export default UniqueIdentifierAdditionalInfoDisplay;
