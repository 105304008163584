import { Col, Row, Button } from "react-bootstrap";
import styled, { css } from "styled-components";
import CenteredVerticalLineCol, { VerticalLineConfig } from "./CenteredVerticalLineCol";
import { palette } from "../../../styles/theme/colors";
import MultiSwitch from "./MultiSwitch";
import { JSONArraySchema, JSONObjectSchema, JSONSchemaValue } from "../../../models/Blueprints";
import { getIconStyling } from "../../shared/utils/SharedComponentUtils";
import { BlueprintValidationActions } from "./BlueprintValidationEditorUtils";
import BlueprintValidationEditorCardCollapsableSection from "./BlueprintValidationEditorCardCollapsableSection";
import BlueprintValidationEditorObjectFieldSet from "./BlueprintValidationEditorObjectFieldSet";
import BlueprintValidationEditorArrayFieldSubItem from "./BlueprintValidationEditorArrayFieldSubItem";
import { JSONSchemaTypeOption } from "../utils/BlueprintDataTransformUtils";

const NON_NESTABLE_KEYS = ["linked_account_params"];

const BottomBorder = css`
  border-bottom: 1px solid ${palette.border};
`;

const BorderedCenteredVerticalLineCol = styled(CenteredVerticalLineCol)<{
  showBorder: boolean;
}>`
  ${({ showBorder }) => showBorder && BottomBorder}
`;

const XSIconContainer = styled.div.attrs({
  className: "avatar-xs avatar-title rounded-circle",
})`
  margin: 19px 32px;
`;

const FieldRow = styled(Row)<{ level: number }>`
  margin: 0px;
  flex-wrap: nowrap;
  padding-left: 44px;
`;

const BorderedFieldRowContent = styled(Row)<{
  showBorder: boolean;
}>`
  padding-top: 16px;
  padding-bottom: 16px;
  ${({ showBorder }) => showBorder && BottomBorder}
`;

const FieldName = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 13px;
  font-family: var(--font-family-monospace);
`;

const StyledMultiSwitch = styled(MultiSwitch)`
  margin-right: 8px;

  button {
    font-size: 12px;
    line-height: 16px;
  }
`;

interface Props {
  fieldKey: string;
  fieldValue: JSONSchemaValue;
  removeField: (() => void) | undefined;
  isFieldRequired: boolean;
  level: number;
  toggleIsFieldRequired: () => void;
  traversalPath: Array<string>;
  showBorderAboveIcon?: boolean;
  showBorderBelowContent?: boolean;
  verticalLineConfig: VerticalLineConfig;
  validationActions: BlueprintValidationActions;
  operationType: undefined | string;
}

const BlueprintValidationEditorField = ({
  removeField,
  level,
  fieldKey,
  fieldValue,
  toggleIsFieldRequired,
  showBorderBelowContent,
  verticalLineConfig,
  isFieldRequired,
  validationActions,
  traversalPath,
  operationType,
}: Props) => {
  const isObject = fieldValue.type === "object";
  const isArray = fieldValue.type === "array";
  const isArrayOfObjects = isArray && fieldValue.items.type === "object";
  const hasNestableObject = !NON_NESTABLE_KEYS.includes(fieldKey) && (isObject || isArrayOfObjects);
  const nestableObject = isArrayOfObjects
    ? ((fieldValue as JSONArraySchema).items as JSONObjectSchema)
    : isObject
    ? (fieldValue as JSONObjectSchema)
    : undefined;
  const arraySubItemType = isArray
    ? ((fieldValue as JSONArraySchema).items.type as JSONSchemaTypeOption)
    : undefined;
  const nestableObjectTraversalPath = isArrayOfObjects
    ? [...traversalPath, "items"]
    : isObject
    ? traversalPath
    : undefined;

  const allowObjectExpand = hasNestableObject && nestableObject && nestableObjectTraversalPath;
  const allowExpand = allowObjectExpand || isArray;

  return (
    <>
      <FieldRow level={level}>
        <BorderedCenteredVerticalLineCol
          className="col-auto pl-0 pr-0"
          $verticalLineConfig={verticalLineConfig}
          showBorder={showBorderBelowContent}
        >
          <XSIconContainer>
            <i className={`fe ${getIconStyling(fieldValue.type)}`} />
          </XSIconContainer>
        </BorderedCenteredVerticalLineCol>
        <Col className="pr-0 mr-3">
          <BorderedFieldRowContent showBorder={showBorderBelowContent}>
            <Col className="d-flex pl-0">
              <FieldName>{fieldKey}</FieldName>
            </Col>
            <Col className="d-flex col-auto pr-6 flex-grow-1 justify-content-end">
              <StyledMultiSwitch
                options={[
                  {
                    text: "Optional",
                    id: "optional",
                    selectedColor: palette.empty,
                    backgroundColor: palette.white,
                    borderColor: palette.border,
                    disable: !isFieldRequired,
                  },
                  {
                    text: "Required",
                    id: "required",
                    selectedColor: palette.black,
                    backgroundColor: palette.white,
                    borderColor: palette.border,
                    disable: isFieldRequired,
                  },
                ]}
                selectedID={isFieldRequired ? "required" : "optional"}
                onSelectOption={toggleIsFieldRequired}
              />
              {removeField && (
                <Button variant="outline-danger" size="sm" onClick={() => removeField()}>
                  <div className="configuration-customfields-button-text">Delete</div>
                </Button>
              )}
            </Col>
          </BorderedFieldRowContent>
        </Col>
      </FieldRow>
      {allowExpand && (
        <BlueprintValidationEditorCardCollapsableSection fieldKey={fieldKey}>
          <>
            {isArray && (
              <BlueprintValidationEditorArrayFieldSubItem
                level={level + 1}
                subItemType={arraySubItemType}
                setSubItemType={(subItemType) =>
                  validationActions.setArrayFieldSubItemType(traversalPath, subItemType)
                }
              />
            )}
            {allowObjectExpand && (
              <BlueprintValidationEditorObjectFieldSet
                fieldKey={fieldKey}
                level={level + 1}
                objectJSONSchema={nestableObject}
                validationActions={validationActions}
                operationType={operationType}
                traversalPath={nestableObjectTraversalPath}
              />
            )}
          </>
        </BlueprintValidationEditorCardCollapsableSection>
      )}
    </>
  );
};

export default BlueprintValidationEditorField;
