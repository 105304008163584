import { useContext, useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { APIEndpointMethod } from "../../../../models/Entities";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import { useHistory } from "react-router-dom";
import IntegrationBuilderContext from "../../context/IntegrationBuilderContext";
import { WebAddressSchemaIntegrationBuilder } from "../../utils/Entities";
import { navigateToIntegrationBuilderWebAddressSchemaPostWebAddressSchemaCreation } from "../../../../router/RouterUtils";

interface CreateOrPatchSchemaProps {
  setWebAddressSchemas: React.Dispatch<
    React.SetStateAction<WebAddressSchemaIntegrationBuilder[] | undefined>
  >;
  integrationID: string;
  webAddressSchemaID?: string;
  // params for body of api call
  scheme?: string;
  subdomain?: string;
  secondLevelDomain?: string;
  topLevelDomain?: string;
  description?: string;
}

const useCreateOrPatchWebAddressSchema = (params: CreateOrPatchSchemaProps) => {
  const [isLoading, setIsLoading] = useState(false);
  // context
  const history = useHistory();
  const { isWebAddressSchemaComplete, setIsWebAddressSchemaComplete, resetDiffStates } = useContext(
    IntegrationBuilderContext
  );

  // body of api called shared between create and patch
  const webAddressSchemaBody = {
    scheme: params.scheme,
    subdomain: params.subdomain ?? null,
    second_level_domain: params.secondLevelDomain ?? undefined,
    top_level_domain: params.topLevelDomain ?? undefined,
    description: params.description ?? null,
  };

  const createWebAddressSchema = () => {
    setIsLoading(true);
    fetchWithAuth({
      path: `/integrations/${params.integrationID}/web-address-schemas`,
      method: APIEndpointMethod.POST,
      body: webAddressSchemaBody,
      onResponse: (responseData: WebAddressSchemaIntegrationBuilder) => {
        showSuccessToast("Web addresss schema successfully created!");
        // reset diff states
        resetDiffStates();
        // navigates to url for web address schema with id
        navigateToIntegrationBuilderWebAddressSchemaPostWebAddressSchemaCreation(
          history,
          params.integrationID,
          responseData.id
        );
        // add new aweb address schema to webAddressSchemas array
        params.setWebAddressSchemas((prevWebAddressSchemas) => {
          return prevWebAddressSchemas ? [...prevWebAddressSchemas, responseData] : [responseData];
        });
        setIsLoading(false);
        if (isWebAddressSchemaComplete === false) {
          setIsWebAddressSchemaComplete(true);
        }
      },
      onError: () => {
        showErrorToast("Failed to create your web address schema.");
        setIsLoading(false);
      },
    });
  };

  const patchWebAddressSchema = () => {
    setIsLoading(true);
    fetchWithAuth({
      path: `/integrations/${params.integrationID}/web-address-schemas/${params.webAddressSchemaID}`,
      method: APIEndpointMethod.PATCH,
      body: webAddressSchemaBody,
      onResponse: (responseData: WebAddressSchemaIntegrationBuilder) => {
        showSuccessToast("Web addresss schema successfully updated!");
        setIsLoading(false);
        // replaces web address schema in webAddressSchemas array with new data
        params.setWebAddressSchemas((prevWebAddressSchemas) => {
          return prevWebAddressSchemas?.map((schema) =>
            schema.id === params.webAddressSchemaID ? responseData : schema
          );
        });
      },
      onError: () => {
        showErrorToast("Failed to update your web address schema.");
        setIsLoading(false);
      },
    });
  };

  return { createWebAddressSchema, patchWebAddressSchema, isLoading };
};

export default useCreateOrPatchWebAddressSchema;
