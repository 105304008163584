import TextFieldHeader from "../../../shared/TextFieldHeader";

interface NameConfigurationProps {
  authConfigName: string | undefined;
  setAuthConfigName: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const NameConfiguration = ({ authConfigName, setAuthConfigName }: NameConfigurationProps) => {
  return (
    <div>
      <hr className="text-gray-50 h-[0.5px] my-4 -mx-5" />
      <TextFieldHeader
        dataTestID="field-auth-config-configuration-name"
        title="Configuration name"
        required
        placeholder="Human-readable name for this authentication configuration"
        value={authConfigName}
        onChange={(newEvent) => {
          setAuthConfigName(newEvent.target.value);
        }}
      />
    </div>
  );
};

export default NameConfiguration;
