import { DiffState } from "../../../../../models/DiffModels";
import { generateDiffForSimpleComponents } from "../helpers-diff";

/**
 * Generates diff state for step's template's API endpoint, if it has one
 * @param {Object} currentAPIEndpointData - The current API endpoint data.
 * @param {Object} newAPIEndpointData - The new API endpoint data.
 * @returns {DiffState} - The generated diff state for the API endpoint.
 */
export const generateDiffStateForStepTemplateAPIEndpoint = (
  currentAPIEndpointData: { [key: string]: any },
  newAPIEndpointData: { [key: string]: any }
): DiffState => {
  // Remove pagination_configuration
  let currentDataWithoutPagConfig: { [key: string]: any } = { ...currentAPIEndpointData };
  currentDataWithoutPagConfig = Object.keys(currentDataWithoutPagConfig)
    .filter((key) => key !== "pagination_configuration")
    .reduce((newObj: { [key: string]: any }, key) => {
      newObj[key] = currentDataWithoutPagConfig[key];
      return newObj;
    }, {});
  let newDataWithoutPagConfig: { [key: string]: any } = { ...newAPIEndpointData };
  newDataWithoutPagConfig = Object.keys(newDataWithoutPagConfig)
    .filter((key) => key !== "pagination_configuration")
    .reduce((newObj: { [key: string]: any }, key) => {
      newObj[key] = newDataWithoutPagConfig[key];
      return newObj;
    }, {});

  return generateDiffForSimpleComponents(currentDataWithoutPagConfig, newDataWithoutPagConfig);
};
