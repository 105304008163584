import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import nightOwl from "react-syntax-highlighter/dist/cjs/styles/hljs/night-owl";
import json from "react-syntax-highlighter/dist/cjs/languages/hljs/json";
import python from "react-syntax-highlighter/dist/cjs/languages/hljs/python";
import ruby from "react-syntax-highlighter/dist/cjs/languages/hljs/ruby";
import bash from "react-syntax-highlighter/dist/cjs/languages/hljs/bash";
import elixir from "react-syntax-highlighter/dist/cjs/languages/hljs/elixir";
import go from "react-syntax-highlighter/dist/cjs/languages/hljs/go";
import xml from "react-syntax-highlighter/dist/cjs/languages/hljs/xml";

SyntaxHighlighter.registerLanguage("json", json);
SyntaxHighlighter.registerLanguage("python", python);
SyntaxHighlighter.registerLanguage("ruby", ruby);
SyntaxHighlighter.registerLanguage("bash", bash);
SyntaxHighlighter.registerLanguage("elixir", elixir);
SyntaxHighlighter.registerLanguage("go", go);
SyntaxHighlighter.registerLanguage("xml", xml);

interface Props {
  children?: string | null;
  language?: string | null;
  showLineNumbers?: boolean | null;
  customStyle?: any;
  shouldExcludeCopyOption?: boolean | null;
  shouldWrapLongLines?: boolean | null;
}

const MergeCodeBlockTextWithSyntaxHighlight = ({
  children,
  language,
  showLineNumbers,
  customStyle,
  shouldExcludeCopyOption = false,
  shouldWrapLongLines = false,
}: Props) => {
  return (
    <SyntaxHighlighter
      className="bg-navy-90"
      language={language || "json"}
      showLineNumbers={showLineNumbers ? true : false}
      style={nightOwl}
      wrapLongLines={shouldWrapLongLines}
      customStyle={Object.assign(
        { padding: "12px 12px 0px 12px", borderRadius: "8px" },
        customStyle
      )}
      shouldExcludeCopyOption={shouldExcludeCopyOption}
    >
      {children}
    </SyntaxHighlighter>
  );
};

export default MergeCodeBlockTextWithSyntaxHighlight;
