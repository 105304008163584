import { useCallback, useState } from "react";
import { showErrorToast, showSuccessToast } from "../../shared/Toasts";
import useInterval from "../../mapping-tests/utils/useInterval";

// This is an empty object interface which we will extend by passing in whichever
// execution inputs interface we need at the callsite
interface OptionalInputs {}

/**
 * Encapsulates pattern of 1. Kicking off async request and 2. Periodically fetching result of request.
 * Used across testing framework, blueprint and scraper editors.
 */
const useFetchAsyncResult = <
  AsyncExecution extends {
    id: string;
    finished_at?: string;
    exception_message?: string;
  },
  AsyncExecutionOptionalInputs extends OptionalInputs = {}
>(
  asyncExecutionMethod: ({
    onResponse,
    onError,
  }: {
    onResponse: (results: AsyncExecution) => void;
    onError: () => void;
  }) => void,
  fetchAsyncExecutionResult: ({
    asyncExecutionID,
    onResponse,
    onError,
  }: {
    asyncExecutionID: string;
    onResponse: (results: AsyncExecution) => void;
    onError: () => void;
  }) => void,
  asyncRequestName: string,
  isAsyncExecutionFinishedPredicate?: (asyncExecution: AsyncExecution) => boolean
): [
  boolean,
  AsyncExecution | undefined,
  () => void,
  (optionalInputs?: AsyncExecutionOptionalInputs | undefined) => void,
  () => void
] => {
  const [asyncExecutionID, setAsyncExecutionID] = useState<string>();
  const [asyncExecutionResults, setAsyncExecutionResults] = useState<AsyncExecution>();
  const [isMakingFirstAsyncRequest, setIsMakingFirstAsyncRequest] = useState(false);

  const isAsyncExecutionFinishedPredicateOrDefault =
    isAsyncExecutionFinishedPredicate !== undefined
      ? isAsyncExecutionFinishedPredicate
      : (asyncExecution: AsyncExecution) =>
          asyncExecution.finished_at || asyncExecution.exception_message;

  const fetchFuncOnResponse = (results: AsyncExecution) => {
    setAsyncExecutionResults(results);
    if (isAsyncExecutionFinishedPredicateOrDefault(results)) {
      setAsyncExecutionID(undefined);
    }
    if (results.exception_message) {
      showErrorToast(results.exception_message);
    }
  };
  const fetchFuncOnError = () => {
    setAsyncExecutionResults(undefined);
    setAsyncExecutionID(undefined);
    showErrorToast("Something went wrong");
  };
  const fetchFunc = () => {
    if (asyncExecutionID && asyncExecutionID !== "") {
      fetchAsyncExecutionResult({
        asyncExecutionID,
        onResponse: fetchFuncOnResponse,
        onError: fetchFuncOnError,
      });
    }
  };
  useInterval(fetchFunc, 3000);

  const clearAsyncExecutionResults = useCallback(() => {
    setAsyncExecutionResults(undefined);
  }, []);

  // This function will start the polling.
  const executeAsyncRequest = useCallback(
    (optionalInputs?: AsyncExecutionOptionalInputs | undefined) => {
      clearAsyncExecutionResults();
      setIsMakingFirstAsyncRequest(true);
      asyncExecutionMethod({
        onResponse: (data: AsyncExecution) => {
          setAsyncExecutionID(data.id);
          setIsMakingFirstAsyncRequest(false);
          fetchFuncOnResponse(data);
          if (!isAsyncExecutionFinishedPredicateOrDefault(data)) {
            showSuccessToast(`Successfully kicked off ${asyncRequestName}.`);
          }
        },
        onError: () => {
          setIsMakingFirstAsyncRequest(false);
          fetchFuncOnError();
          showErrorToast(`Something went wrong while kicking off ${asyncRequestName}`);
        },
        ...optionalInputs,
      });
    },
    [asyncExecutionMethod, asyncRequestName]
  );

  const stopExecutingAsyncRequests = useCallback(() => {
    setAsyncExecutionID(undefined);
    setIsMakingFirstAsyncRequest(false);
    clearAsyncExecutionResults();
  }, []);

  return [
    !!asyncExecutionID || isMakingFirstAsyncRequest,
    asyncExecutionResults,
    clearAsyncExecutionResults,
    executeAsyncRequest,
    stopExecutingAsyncRequests,
  ];
};

export default useFetchAsyncResult;
