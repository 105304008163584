import {
  IntegrationComponentNames,
  StagedComponent,
  mapDisplayIntegrationComponentName,
} from "../types";
import { ButtonVariant, Dropdown, MenuItem, Text } from "@merge-api/merge-javascript-shared";
import {
  navigateToMappingTestEditor,
  navigateToBlueprintEditor,
  navigateToScraperEditor,
  navigateToIntegrationBuilderSelectiveSyncFilterBuilderForId,
} from "../../../../router/RouterUtils";
import { useHistory } from "react-router-dom";
import { ChevronRight, MoreHorizontal } from "lucide-react";
import { useEffect, useState } from "react";
import {
  fetchBlueprintVersionForIntegration,
  fetchBlueprintVersions,
} from "../../../blueprint-editor/utils/BlueprintEditorAPIClient";
import {
  BlueprintVersion,
  BlueprintVersionPublishState,
  BlueprintWithTrigger,
} from "../../../../models/Blueprints";
import { DiffModalTypeEnum, DiffModelTypeEnum } from "../../../../models/DiffModels";
import DiffModal from "../../../shared/diff-view/DiffModal";
import UnstageModal from "./UnstageModal";

type Props = {
  modelClassName: IntegrationComponentNames;
  stagedComponent: StagedComponent;
  integrationID: string;
  onRefreshAllChecks: () => void;
};

const PublishModuleStagedComponentVersionCard = ({
  modelClassName,
  stagedComponent,
  integrationID,
  onRefreshAllChecks,
}: Props) => {
  const history = useHistory();

  // States for diff
  const [isDiffModalOpen, setIsDiffModalOpen] = useState<boolean>(false);
  const [isLoadingCurrentStateForDiff, setIsLoadingCurrentStateForDiff] = useState<boolean>(false);
  const [isLoadingNewStateForDiff, setIsLoadingNewStateForDiff] = useState<boolean>(false);
  const [blueprintVersions, setBlueprintVersions] = useState<BlueprintVersion[] | undefined>();
  const [currentStateForDiff, setCurrentStateForDiff] = useState<{ [key: string]: any }>({});
  const [newStateForDiff, setNewStateForDiff] = useState<{ [key: string]: any }>({});
  const [currentStateTitle, setCurrentStateTitle] = useState<string>();
  const [newStateTitle, setNewStateTitle] = useState<string>();

  // States for unstaging
  const [isUnstageModalOpen, setIsUnstageModalOpen] = useState<boolean>(false);

  // Open diff modal and load staged & published Blueprint version JSON's
  const onOpenBlueprintDiffModal = () => {
    setIsDiffModalOpen(true);
    setIsLoadingCurrentStateForDiff(true);
    setIsLoadingNewStateForDiff(true);
    fetchBlueprintVersions({
      blueprintID: stagedComponent.component_id,
      onSuccess: (versions: BlueprintVersion[]) => {
        setBlueprintVersions(versions);
      },
    });
  };

  // Loads published & staged Blueprint versions, for diff modal
  useEffect(() => {
    if (
      !(blueprintVersions ?? []).find(
        (version) => version.publish_state === BlueprintVersionPublishState.Published
      )
    ) {
      setIsLoadingCurrentStateForDiff(false);
    }
    (blueprintVersions ?? []).forEach((version) => {
      if (version.publish_state === BlueprintVersionPublishState.Staged) {
        fetchBlueprintVersionForIntegration({
          integrationID: integrationID,
          blueprintVersionID: version.id,
          onSuccess: (response: BlueprintWithTrigger) => {
            const { schedule, ...blueprint } = response;
            setNewStateForDiff(blueprint);
            setNewStateTitle(`Blueprint Version - "${version.comment}"`);
            setIsLoadingNewStateForDiff(false);
          },
        });
      } else if (version.publish_state === BlueprintVersionPublishState.Published) {
        fetchBlueprintVersionForIntegration({
          integrationID: integrationID,
          blueprintVersionID: version.id,
          onSuccess: (response: BlueprintWithTrigger) => {
            const { schedule, ...blueprint } = response;
            setCurrentStateForDiff(blueprint);
            setCurrentStateTitle(`Blueprint Version - "${version.comment}"`);
            setIsLoadingCurrentStateForDiff(false);
          },
        });
      }
    });
  }, [blueprintVersions]);

  return (
    <div
      className="flex justify-between items-center px-6 py-5 cursor-pointer hover:bg-slate-0"
      onClick={() => {
        switch (modelClassName) {
          case IntegrationComponentNames.MAPPING_TEST:
            navigateToMappingTestEditor(history, integrationID, stagedComponent.component_id, true);
            break;
          case IntegrationComponentNames.BLUEPRINT:
            onOpenBlueprintDiffModal();
            break;
          case IntegrationComponentNames.SCRAPER:
            navigateToScraperEditor(
              history,
              integrationID,
              stagedComponent.component_id,
              stagedComponent.component_version_id
            );
            break;
          case IntegrationComponentNames.API_ENDPOINT_FILTER:
          case IntegrationComponentNames.SELECTIVE_SYNC_FILTER_SCHEMA:
            navigateToIntegrationBuilderSelectiveSyncFilterBuilderForId(
              history,
              integrationID,
              true,
              stagedComponent.component_version_id
            );
            break;
          default:
            throw new Error("Operation not supported for this model class.");
          // End Generation  Here
        }
      }}
    >
      <div>
        <Text variant="h5" className="p-0 m-0">
          {stagedComponent.name}
        </Text>
        <Text variant="sm" className="text-slate-50">
          {stagedComponent.comment}
        </Text>
      </div>
      <div className="flex flex-row items-center space-x-6">
        <div onClick={(event) => event.stopPropagation()}>
          <Dropdown
            ButtonProps={{
              children: <MoreHorizontal size={16} />,
              variant: ButtonVariant.IconOnly,
            }}
            menuPlacement="bottom-end"
          >
            <MenuItem
              onClick={() => {
                switch (modelClassName) {
                  case IntegrationComponentNames.MAPPING_TEST:
                    navigateToMappingTestEditor(
                      history,
                      integrationID,
                      stagedComponent.component_id,
                      true
                    );
                    break;
                  case IntegrationComponentNames.BLUEPRINT:
                    navigateToBlueprintEditor(
                      history,
                      integrationID,
                      stagedComponent.component_version_id,
                      true
                    );
                    break;
                  case IntegrationComponentNames.SCRAPER:
                    navigateToScraperEditor(
                      history,
                      integrationID,
                      stagedComponent.component_id,
                      stagedComponent.component_version_id
                    );
                    break;
                  case IntegrationComponentNames.API_ENDPOINT_FILTER:
                  case IntegrationComponentNames.SELECTIVE_SYNC_FILTER_SCHEMA:
                    navigateToIntegrationBuilderSelectiveSyncFilterBuilderForId(
                      history,
                      integrationID,
                      true,
                      stagedComponent.component_version_id
                    );
                    break;
                  default:
                    throw new Error("Operation not supported for this model class.");
                }
              }}
            >
              View {mapDisplayIntegrationComponentName[modelClassName]}
            </MenuItem>
            <MenuItem onClick={() => setIsUnstageModalOpen(true)}>Unstage</MenuItem>
          </Dropdown>
        </div>
        <ChevronRight size={16} className="text-gray-50" />
      </div>
      {isDiffModalOpen && (
        <DiffModal
          currentState={currentStateForDiff}
          currentStateTitle={currentStateTitle}
          newState={newStateForDiff}
          newStateTitle={newStateTitle}
          modelType={DiffModelTypeEnum.BLUEPRINT}
          isLoadingStates={isLoadingCurrentStateForDiff || isLoadingNewStateForDiff}
          isModalOpen={isDiffModalOpen}
          setIsModalOpen={setIsDiffModalOpen}
          diffModalType={DiffModalTypeEnum.COMPARE}
        />
      )}
      {isUnstageModalOpen && (
        <UnstageModal
          open={isUnstageModalOpen}
          onClose={() => setIsUnstageModalOpen(false)}
          modelClassName={modelClassName}
          stagedComponent={stagedComponent}
          onRefreshAllChecks={onRefreshAllChecks}
          integrationID={integrationID}
        />
      )}
    </div>
  );
};

export default PublishModuleStagedComponentVersionCard;
