import { fetchReadme, updateReadme } from "../../../api-client/ReadmeAPIClient";
import { MappingTestReadme } from "../../../models/MappingTests";

export const fetchMappingTestReadme = ({
  mappingTestID,
  ...rest
}: {
  mappingTestID: string;
  onSuccess: (data: MappingTestReadme) => void;
  onError: (error: Response | undefined) => void;
}): Promise<void> => fetchReadme({ path: `/stallions/tests/${mappingTestID}/readme`, ...rest });

export const updateMappingTestReadme = ({
  mappingTestID,
  ...rest
}: {
  mappingTestID: string;
  text: string;
  onSuccess: (data: MappingTestReadme) => void;
  onError: (error: Response | undefined) => void;
}): Promise<void> => updateReadme({ path: `/stallions/tests/${mappingTestID}/readme`, ...rest });
