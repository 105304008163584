import { Alert, Text } from "@merge-api/merge-javascript-shared";
import { Info } from "lucide-react";

interface SchemaExplainProps {
  className?: string;
}
const APIEndpointExplain = ({ className }: SchemaExplainProps) => {
  return (
    <Alert
      title="What is an API endpoint?"
      color="gray"
      icon={<Info size={16} />}
      className={className}
    >
      <Text>
        An API endpoint is a specific URL within a software's API that exposes interactions with the
        system. For example, a software may expose an endpoint at
        "https://api.acme.com/api/v1/users" to allow other systems to retrieve user data.
      </Text>
    </Alert>
  );
};

export default APIEndpointExplain;
