import { useCallback, useEffect, useState } from "react";
import { showErrorToast } from "../../shared/Toasts";
import { fetchWithAuth } from "../../../api-client/api_client";
import { useHistory } from "react-router-dom";
import { INTEGRATION_BUILDER_PATH_INITIALIZATION } from "../../../router/RouterUtils";
import { IntegrationForIntegrationBuilder } from "../utils/Entities";

/**
 * Hook for pulling integration based on query param for setting context
 */
const useLoadIntegration = (integration_id: string | undefined) => {
  // state
  const [integration, setIntegration] = useState<IntegrationForIntegrationBuilder | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const history = useHistory();

  const navigateToBuilder = useCallback(() => {
    history.push(INTEGRATION_BUILDER_PATH_INITIALIZATION);
  }, [history]);

  useEffect(() => {
    if (!integration_id) {
      navigateToBuilder();
      return;
    }
    setIsLoading(true);
    fetchWithAuth({
      path: `/integrations/integration-builder/integration/${integration_id}`,
      method: "GET",
      onResponse: (data: IntegrationForIntegrationBuilder) => {
        setIntegration(data);
        setIsLoading(false);
      },
      onError: () => {
        showErrorToast(
          "Failed to find an integration with that ID - returning to integration builder home page"
        );
        setIsLoading(false);
        navigateToBuilder();
      },
    });
  }, [integration_id, history, navigateToBuilder]);

  return { integration, setIntegration, isLoading, setIsLoading };
};

export default useLoadIntegration;
