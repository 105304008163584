import * as H from "history";
export const ADMIN_ROOT_PATH = "/";
export const LOGIN_PATH = "/login";
export const INTEGRATION_DASHBOARD_PATH = "/integrations";
export const API_ENDPOINT_EDIT_PATH =
  "/integrations/:integrationID/api-endpoints/:apiEndpointID?/edit";
export const AUTH_CONFIG_EDIT_PATH =
  "/integrations/:integrationID/auth-configs/:authConfigID?/edit";
export const AUTH_CONFIG_CREATE_PATH = "/integrations/:integrationID/auth-configs/create";
export const REPORT_TEMPLATE_EDIT_PATH =
  "/integrations/:integrationID/report-templates/:reportTemplateID?/edit";
export const REPORT_TEMPLATE_CREATE_PATH = "/integrations/:integrationID/report-templates/create";
export const API_ENDPOINT_CREATE_PATH = "/integrations/:integrationID/api-endpoints/create";
export const BLUEPRINT_EDITOR_PATH =
  "/integrations/:integrationID/blueprints/versions/:blueprintVersionID/editor";
export const BLUEPRINT_EDITOR_MAIN_TAB_PATH =
  "/integrations/:integrationID/blueprints/versions/:blueprintVersionID/editor/main";
export const BLUEPRINT_EDITOR_README_TAB_PATH =
  "/integrations/:integrationID/blueprints/versions/:blueprintVersionID/editor/readme";
export const BLUEPRINT_EDITOR_VALIDATION_TAB_PATH =
  "/integrations/:integrationID/blueprints/versions/:blueprintVersionID/editor/validation";
export const BLUEPRINT_EDITOR_RETURN_SCHEMA_TAB_PATH =
  "/integrations/:integrationID/blueprints/versions/:blueprintVersionID/editor/return-schema";
export const SCRAPER_EDITOR_PATH = "/integrations/:integrationID/scrapers/:scraperID/editor";
export const SCRAPER_VERSION_EDITOR_PATH =
  "/integrations/:integrationID/scrapers/:scraperID/versions/:scraperVersionID/editor";
export const SCRAPER_HISTORY_PATH = "/integrations/:integrationID/scrapers/:scraperID/history";
export const MAPPING_TEST_SUITE_PATH = "/integrations/:integrationID/test-suites/:testSuiteID";
export const MAPPING_TEST_EDITOR_PATH = "/integrations/:integrationID/tests/:testID/editor";
export const MAPPING_TEST_EDITOR_MAIN_TAB_PATH =
  "/integrations/:integrationID/tests/:testID/editor/main";
export const MAPPING_TEST_EDITOR_README_TAB_PATH =
  "/integrations/:integrationID/tests/:testID/editor/readme";
export const INTEGRATION_OVERVIEW_PATH = "/integrations/:integrationID/:subtab?";
export const INTEGRATIONS_EXPORT_PATH = "/integrations/export";
const __INTEGRATION_CHANGELOG_TAB_NAME = "changelog";
const __INTEGRATION_CHANGELOG_V2_TAB_NAME = "v2-log-until-release";

export const __PUBLISH_REQUESTS_TAB_NAME = `publish-requests`;
export const PUBLISH_REQUEST_VERSION_HISTORY_TAB_PATH = `publish-requests`;
export const PUBLISH_REQUESTS_TAB_PATH = `/integrations/:integrationID/${PUBLISH_REQUEST_VERSION_HISTORY_TAB_PATH}/`;
export const __PUBLISH_MODULE_SUBTAB_NAME = "publish-module";
export const UID_MANAGEMENT_PATH = "/integrations/:integrationID/uid";

// INTEGRATION BUILDER ROUTE
export const INTEGRATION_BUILDER_PATH = "/integration-builder";

export const INTEGRATION_BUILDER_PATH_INITIALIZATION = "/create-integration";

// ID ROUTING INTEGRATION BUILDER

export const getIntegrationBuilderPathForId = (id: string = ":integrationID") =>
  `${INTEGRATION_BUILDER_PATH}/${id}`;

export const getIntegrationBuilderInitializationPathForId = (id: string) =>
  `${getIntegrationBuilderPathForId(id)}/initialization`;

// ai search
export const getIntegrationBuilderAISearchPathForId = (id: string) =>
  `${getIntegrationBuilderPathForId(id)}/ai-search`;

// web address schemas
export const getIntegrationBuilderWebAddressSchemaConfigurationPathForId = (id: string) =>
  `${getIntegrationBuilderPathForId(id)}/web-address-schemas-configuration`;

export const getIntegrationBuilderWebAddressSchemaForId = (id: string) =>
  `${getIntegrationBuilderPathForId(id)}/web-address-schemas`;

export const getIntegrationBuilderSingleWebAddressSchemaPathForId = (
  integrationID: string,
  webAddressSchemaID: string = ":webAddressSchemaID"
) => `${getIntegrationBuilderWebAddressSchemaForId(integrationID)}/${webAddressSchemaID}`;

export const getIntegrationBuilderCreateNewWebAddressSchemaPathForId = (id: string) =>
  `${getIntegrationBuilderWebAddressSchemaForId(id)}/create`;

// auth
export const getIntegrationBuilderAuthenticationConfigurationPathForId = (id: string) =>
  `${getIntegrationBuilderPathForId(id)}/authentication-configurations`;

export const getIntegrationBuilderSingleAuthConfigPathForId = (
  integrationID: string,
  authConfigID: string = ":authConfigID"
) => `${getIntegrationBuilderAuthenticationConfigurationPathForId(integrationID)}/${authConfigID}`;

export const getIntegrationBuilderCreateNewAuthConfigPathForId = (id: string) =>
  `${getIntegrationBuilderAuthenticationConfigurationPathForId(id)}/create`;

// linking flow steps

export const getIntegrationBuilderLinkingFlowStepsPathConfigurationForId = (id: string) =>
  `${getIntegrationBuilderPathForId(id)}/merge-link-steps-configuration`;

export const getIntegrationBuilderLinkingFlowStepsPathForId = (id: string) =>
  `${getIntegrationBuilderPathForId(id)}/merge-link-steps`;

export const getIntegrationBuilderNewLinkingFlowStepPath = (
  integrationID: string,
  authConfigID: string = ":authConfigID",
  presetPathType?: string
) => {
  let path = `${getIntegrationBuilderLinkingFlowStepsPathForId(
    integrationID
  )}/auth-config/${authConfigID}/create`;
  if (presetPathType) path += `?presetPathType=${presetPathType}`;
  return path;
};

export const getIntegrationBuilderExistingLinkingFlowStepPath = (
  integrationID: string,
  linkingFlowStepPathID: string = ":linkingFlowStepPathID"
) => `${getIntegrationBuilderLinkingFlowStepsPathForId(integrationID)}/${linkingFlowStepPathID}`;

// pagination configurations

export const getIntegrationBuilderPaginationConfigurationsForId = (id: string) =>
  `${getIntegrationBuilderPathForId(id)}/pagination-configurations`;

export const getIntegrationBuilderCreateNewPaginationConfigurationPathForId = (id: string) =>
  `${getIntegrationBuilderPaginationConfigurationsForId(id)}/create`;

export const getIntegrationBuilderSinglePaginationConfigurationPathForId = (
  id: string,
  paginationConfigurationID: string = ":paginationConfigurationID"
) => `${getIntegrationBuilderPaginationConfigurationsForId(id)}/${paginationConfigurationID}`;

// rate limits
export const getIntegrationBuilderRateLimitConfigurationPathForId = (id: string) =>
  `${getIntegrationBuilderPathForId(id)}/rate-limit-configurations`;

export const getIntegrationBuilderCreateNewRateLimitConfigurationPathForId = (id: string) =>
  `${getIntegrationBuilderRateLimitConfigurationPathForId(id)}/create`;

export const getIntegrationBuilderSingleRateLimitConfigurationPathForId = (
  integrationID: string,
  rateLimitConfigurationID: string = ":rateLimitConfigurationID"
) =>
  `${getIntegrationBuilderRateLimitConfigurationPathForId(
    integrationID
  )}/${rateLimitConfigurationID}`;

// api endpoint
export const getIntegrationBuilderAPIEndpointsConfigurationPathForId = (id: string) =>
  `${getIntegrationBuilderPathForId(id)}/api-endpoints-configuration`;

export const getIntegrationBuilderAPIEndpointsForId = (id: string) =>
  `${getIntegrationBuilderPathForId(id)}/api-endpoints`;

export const getIntegrationBuilderAPIEndpointPathForId = (id: string) =>
  `${getIntegrationBuilderPathForId(id)}/api-endpoints`;

export const getIntegrationBuilderCreateNewAPIEndpointPathForId = (id: string) =>
  `${getIntegrationBuilderAPIEndpointsForId(id)}/create`;

export const getIntegrationBuilderSingleAPIEndpointPathForId = (
  id: string,
  apiEndpointID: string = ":apiEndpointID"
) => `${getIntegrationBuilderAPIEndpointsForId(id)}/${apiEndpointID}`;

export const getIntegrationBuilderSingleAPIEndpointQueryParam = (
  shouldAutoScrollToRateLimits: boolean = false
) => (shouldAutoScrollToRateLimits ? "?scrollTo=rate-limit-configurations" : "");

export const getIntegrationBuilderCustomerFacingFieldsPathForId = (id: string) =>
  `${getIntegrationBuilderPathForId(id)}/customer-facing-fields`;

// SELECTIVE SYNC FILTER BUILDER
export const getIntegrationBuilderSelectiveSyncFilterBuilder = (id: string) =>
  `${getIntegrationBuilderPathForId(id)}/selective-sync-filters`;

export const getIntegrationBuilderSelectiveSyncFilterBuilderCreate = (id: string) =>
  `${getIntegrationBuilderSelectiveSyncFilterBuilder(id)}/create`;

export const getIntegrationBuilderSelectiveSyncFilterBuilderForId = (
  id: string,
  selectiveSyncFilterSchemaId: string = ":selectiveSyncFilterSchemaId"
) => `${getIntegrationBuilderSelectiveSyncFilterBuilder(id)}/edit/${selectiveSyncFilterSchemaId}`;

// auth
export const INTEGRATION_BUILDER_AUTHENTICATION_PATH = `${INTEGRATION_BUILDER_PATH}/authentication`;
export const INTEGRATION_BUILDER_AUTHENTICATION_CONFIGURATION_PATH = `${INTEGRATION_BUILDER_AUTHENTICATION_PATH}/configuration`;
export const INTEGRATION_BUILDER_AUTHENTICATION_LINKING_FLOW_STEPS_PATH = `${INTEGRATION_BUILDER_AUTHENTICATION_PATH}/linking-flow-steps`;

// basics
export const INTEGRATION_BUILDER_BASICS_PATH = `${INTEGRATION_BUILDER_PATH}/basics`;
export const INTEGRATION_BUILDER_BASICS_DEFAULT_PAGINATION_PATH = `${INTEGRATION_BUILDER_BASICS_PATH}/default-pagination`;
export const INTEGRATION_BUILDER_BASICS_DEFAULT_PAGINATION_TIMESTAMP_PATH = `${INTEGRATION_BUILDER_BASICS_PATH}/default-pagination-timestamp`;
export const INTEGRATION_BUILDER_BASICS_DEFAULT_RATE_LIMIT_PATH = `${INTEGRATION_BUILDER_BASICS_PATH}/default-rate-limit`;

// api endpoint setup
export const INTEGRATION_BUILDER_API_ENDPOINTS_PATH = `${INTEGRATION_BUILDER_PATH}/api-endpoint-setup`;

// HELPERS

export type History = H.History<unknown>;

export const navigateHelper = (
  history: History,
  path: string,
  navigateToNewTab: boolean = false,
  query?: string
) =>
  navigateToNewTab
    ? window.open(query ? `${path}?${query}` : path, "_blank")
    : query
    ? history.push({ pathname: path, search: query })
    : history.push(path);

// GETS

export function getBlueprintsPath(id: string) {
  return getBlueprintsOperationTypePath(id, "fetch");
}

export function getBlueprintsOperationTypePath(id: string, operationType: string) {
  return `/integrations/${id}/blueprints/${operationType.toLowerCase()}`;
}

export function getTestSuitesTablePath(integrationID: string) {
  return `/integrations/${integrationID}/test-suites`;
}

export function getIndividualTestSuitePath(integrationID: string, testSuiteID: string) {
  return `${getTestSuitesTablePath(integrationID)}/${testSuiteID}`;
}

export function getTestCollectionSuitePath(
  integrationID: string,
  testSuiteID: string,
  endpointTestCollectionID: string
) {
  return `${getIndividualTestSuitePath(
    integrationID,
    testSuiteID
  )}/endpoint-collection/${endpointTestCollectionID}`;
}

export function getEndpointCollectionPath(
  integrationID: string,
  testSuiteID: string,
  endpointCollectionID: string
) {
  return `${getIndividualTestSuitePath(
    integrationID,
    testSuiteID
  )}/collections/${endpointCollectionID}`;
}

export function getMappingTestOperationTypePath(id: string, operationType: string) {
  return `/integrations/${id}/tests/${operationType.toLowerCase()}`;
}

export function getMappingTestEditorPath(id: string, mappingTestID: string) {
  return `/integrations/${id}/tests/${mappingTestID}/editor/main`;
}

export function getMappingTestEditorReadmePath(id: string, mappingTestID: string) {
  return `/integrations/${id}/tests/${mappingTestID}/editor/readme`;
}

export function getScrapersPath(id: string) {
  return `/integrations/${id}/scrapers`;
}

export function getUIDTemplateCategoryPath(integrationID: string, category: string) {
  return `/integrations/${integrationID}/uid/${category}`;
}

export function getUIDTemplatePath(integrationID: string, templateID: string) {
  return `/integrations/${integrationID}/uid/template/${templateID}`;
}

export function getScraperEditorPath(
  integrationID: string,
  scraperID: string,
  scraperVersionID: string
) {
  return `/integrations/${integrationID}/scrapers/${scraperID}/versions/${scraperVersionID}/editor`;
}

export function getScraperHistoryPath(
  integrationID: string,
  scraperID: string,
  linkedAccountID?: string
) {
  let path = `/integrations/${integrationID}/scrapers/${scraperID}/history`;
  if (linkedAccountID) path += `?linkedAccountID=${linkedAccountID}`;
  return path;
}

export function getIntegrationPath(id: string) {
  return `/integrations/${id}/`;
}

export function getAPIEndpointsPath(id: string) {
  return `/integrations/${id}/api-endpoints/get`;
}

export function getReportTemplatesPath(id: string) {
  return `/integrations/${id}/report-templates/get`;
}

export function getAPIEndpointsMethodTypePath(id: string, methodType: string) {
  return `/integrations/${id}/api-endpoints/${methodType.toLowerCase()}`;
}

export function getIntegrationsLinkedAccountPath(integrationName: string) {
  return `/linked-accounts?integration=${integrationName}`;
}

export function getIntegrationCommonModelConfigsPath(integrationID: string, category: string) {
  return `integrations/${integrationID}/uid/${category}`;
}

export function getLinkedAccountsLogsPath(
  integrationName: string,
  endUserOrganizationName: string
) {
  return `/logs?integration=${integrationName}&end_user_organization_name=${endUserOrganizationName}`;
}

export function getBlueprintEditorPath(integrationID: string, blueprintVersionID: string) {
  return `/integrations/${integrationID}/blueprints/versions/${blueprintVersionID}/editor/main`;
}

export function getBlueprintReadmeTabPath(integrationID: string, blueprintVersionID: string) {
  return `/integrations/${integrationID}/blueprints/versions/${blueprintVersionID}/editor/readme`;
}

export function getBlueprintEditorValidationTabPath(
  integrationID: string,
  blueprintVersionID: string
) {
  return `/integrations/${integrationID}/blueprints/versions/${blueprintVersionID}/editor/validation`;
}

export function getBlueprintEditorReturnSchemaTabPath(
  integrationID: string,
  blueprintVersionID: string
) {
  return `/integrations/${integrationID}/blueprints/versions/${blueprintVersionID}/editor/return-schema`;
}

export function getIntegrationChangelogPath(integrationID: string) {
  return `/integrations/${integrationID}/${__INTEGRATION_CHANGELOG_TAB_NAME}`;
}

export function getIntegrationChangelogV2Path(integrationID: string) {
  return `/integrations/${integrationID}/${__INTEGRATION_CHANGELOG_V2_TAB_NAME}`;
}

export function getPublishRequestsPath(integrationID: string) {
  return `/integrations/${integrationID}/${__PUBLISH_REQUESTS_TAB_NAME}`;
}

export function getPublishModulePath(integrationID: string) {
  return `${getPublishRequestsPath(integrationID)}/${__PUBLISH_MODULE_SUBTAB_NAME}`;
}

// NAVIGATES

export const navigateToIntegration = (history: History, integrationID: string) =>
  navigateHelper(history, getIntegrationPath(integrationID), true);

export const navigateToAPIEndpointsSubtab = (history: History, integrationID: string) =>
  navigateHelper(history, getAPIEndpointsPath(integrationID));

export const navigateToBlueprintsSubtab = (
  history: History,
  integrationID: string,
  navigateToNewTab?: boolean
) => navigateHelper(history, getBlueprintsPath(integrationID), navigateToNewTab ?? false);

export const navigateToScrapersSubtab = (history: History, integrationID: string) =>
  navigateHelper(history, getScrapersPath(integrationID));

export const navigateToChangelogSubtab = (history: History, integrationID: string) =>
  navigateHelper(history, getIntegrationChangelogPath(integrationID));

export const navigateToUIDTemplateEditor = (
  history: History,
  integrationID: string,
  templateID: string
) => navigateHelper(history, getUIDTemplatePath(integrationID, templateID));

export const navigateToBlueprintEditor = (
  history: History,
  integrationID: string,
  blueprintVersionID: string,
  navigateToNewTab: boolean = false,
  query: string = ""
) =>
  navigateHelper(
    history,
    getBlueprintEditorPath(integrationID, blueprintVersionID),
    navigateToNewTab,
    query
  );

export const navigateToIntegrationBuilderPostIntegrationInitialization = (
  history: History,
  integrationID: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getIntegrationBuilderInitializationPathForId(integrationID),
    navigateToNewTab
  );

export const navigateToIntegrationBuilderAuthConfigPostAuthConfigCreation = (
  history: History,
  integrationID: string,
  authConfigID: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getIntegrationBuilderSingleAuthConfigPathForId(integrationID, authConfigID),
    navigateToNewTab
  );

export const navigateToIntegrationBuilderAuthConfigTable = (
  history: History,
  integrationID: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getIntegrationBuilderAuthenticationConfigurationPathForId(integrationID),
    navigateToNewTab
  );

export const navigateToIntegrationBuilderLinkingFlowStepPathTable = (
  history: History,
  integrationID: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getIntegrationBuilderLinkingFlowStepsPathForId(integrationID),
    navigateToNewTab
  );

export const navigateToIntegrationBuilderNewLinkingFlowStepPathSetup = (
  history: History,
  integrationID: string,
  authConfigID: string,
  presetPathType?: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getIntegrationBuilderNewLinkingFlowStepPath(integrationID, authConfigID, presetPathType),
    navigateToNewTab
  );

export const navigateToIntegrationBuilderExistingLinkingFlowStepPathSetup = (
  history: History,
  integrationID: string,
  linkingFlowStepPathID: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getIntegrationBuilderExistingLinkingFlowStepPath(integrationID, linkingFlowStepPathID),
    navigateToNewTab
  );

export const navigateToIntegrationBuilderCreateWebAddressSchema = (
  history: History,
  integrationID: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getIntegrationBuilderCreateNewWebAddressSchemaPathForId(integrationID),
    navigateToNewTab
  );

export const navigateToIntegrationBuilderWebAddressSchemaPostWebAddressSchemaCreation = (
  history: History,
  integrationID: string,
  webAddressSchemaID: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getIntegrationBuilderSingleWebAddressSchemaPathForId(integrationID, webAddressSchemaID),
    navigateToNewTab
  );

export const navigateToIntegrationBuilderWebAddressSchemaTable = (
  history: History,
  integrationID: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getIntegrationBuilderWebAddressSchemaForId(integrationID),
    navigateToNewTab
  );

// rate limits
export const navigateToIntegrationBuilderCreateRateLimitConfiguration = (
  history: History,
  integrationID: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getIntegrationBuilderCreateNewRateLimitConfigurationPathForId(integrationID),
    navigateToNewTab
  );

export const navigateToIntegrationBuilderRateLimitConfigurationTable = (
  history: History,
  integrationID: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getIntegrationBuilderRateLimitConfigurationPathForId(integrationID),
    navigateToNewTab
  );

export const navigateToIntegrationBuilderRateLimitConfigurationForm = (
  history: History,
  integrationID: string,
  rateLimitConfigurationID: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getIntegrationBuilderSingleRateLimitConfigurationPathForId(
      integrationID,
      rateLimitConfigurationID
    ),
    navigateToNewTab
  );

// pagination configurations

export const navigateToIntegrationBuilderCreatePaginationConfiguration = (
  history: History,
  integrationID: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getIntegrationBuilderCreateNewPaginationConfigurationPathForId(integrationID),
    navigateToNewTab
  );

export const navigateToIntegrationBuilderPaginationConfigurationForm = (
  history: History,
  integrationID: string,
  paginationConfigurationID: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getIntegrationBuilderSinglePaginationConfigurationPathForId(
      integrationID,
      paginationConfigurationID
    ),
    navigateToNewTab
  );

export const navigateToIntegrationBuilderPaginationConfigurationTable = (
  history: History,
  integrationID: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getIntegrationBuilderPaginationConfigurationsForId(integrationID),
    navigateToNewTab
  );

// api endpoints

export const navigateToIntegrationBuilderAPIEndpointForm = (
  history: History,
  integrationID: string,
  apiEndpointID: string,
  navigateToNewTab: boolean = false,
  shouldAutoScrollToRateLimits: boolean = false
) =>
  navigateHelper(
    history,
    getIntegrationBuilderSingleAPIEndpointPathForId(integrationID, apiEndpointID),
    navigateToNewTab,
    getIntegrationBuilderSingleAPIEndpointQueryParam(shouldAutoScrollToRateLimits)
  );

export const navigateToIntegrationBuilderAPIEndpointTable = (
  history: History,
  integrationID: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(history, getIntegrationBuilderAPIEndpointsForId(integrationID), navigateToNewTab);

export const navigateToIntegrationBuilderCreateAPIEndpoint = (
  history: History,
  integrationID: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getIntegrationBuilderCreateNewAPIEndpointPathForId(integrationID),
    navigateToNewTab
  );

// SELECTIVE SYNC FILTER BUILDER

export const navigateToIntegrationBuilderSelectiveSyncFilterBuilder = (
  history: History,
  integrationID: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getIntegrationBuilderSelectiveSyncFilterBuilder(integrationID),
    navigateToNewTab
  );

export const navigateToIntegrationBuilderSelectiveSyncFilterBuilderForId = (
  history: History,
  integrationID: string,
  navigateToNewTab: boolean = false,
  selectiveSyncFilterSchemaId: string
) =>
  navigateHelper(
    history,
    getIntegrationBuilderSelectiveSyncFilterBuilderForId(
      integrationID,
      selectiveSyncFilterSchemaId
    ),
    navigateToNewTab
  );

export const navigateToIntegrationBuilderSelectiveSyncFilterBuilderCreate = (
  history: History,
  integrationID: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getIntegrationBuilderSelectiveSyncFilterBuilderCreate(integrationID),
    navigateToNewTab
  );

export const navigateToIntegrationBuilderCreateAuthenticationConfiguration = (
  history: History,
  integrationID: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getIntegrationBuilderCreateNewAuthConfigPathForId(integrationID),
    navigateToNewTab
  );

export const navigateToBlueprintEditorValidationTab = (
  history: History,
  integrationID: string,
  blueprintVersionID: string
) =>
  navigateHelper(history, getBlueprintEditorValidationTabPath(integrationID, blueprintVersionID));

export const navigateToScraperEditor = (
  history: History,
  integrationID: string,
  scraperID: string,
  scraperVersionID: string,
  navigateToNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getScraperEditorPath(integrationID, scraperID, scraperVersionID),
    navigateToNewTab
  );

export const navigateToScraperHistory = (
  history: History,
  integrationID: string,
  scraperID: string,
  linkedAccountID?: string,
  navigateToNewTab: boolean = false
) => {
  navigateHelper(
    history,
    getScraperHistoryPath(integrationID, scraperID, linkedAccountID),
    navigateToNewTab
  );
};

export const navigateToMappingTestEditor = (
  history: History,
  integrationID: string,
  mappingTestID: string,
  shouldNavigateInNewTab: boolean = false
) => {
  navigateHelper(
    history,
    getMappingTestEditorPath(integrationID, mappingTestID),
    shouldNavigateInNewTab
  );
};

export const navigateToTestSuitesTable = (
  history: History,
  integrationID: string,
  navigateToNewTab?: boolean
) => navigateHelper(history, getTestSuitesTablePath(integrationID), navigateToNewTab ?? false);

export const navigateToIndividualTestSuite = (
  history: History,
  integrationID: string,
  testSuiteID: string,
  navigateToNewTab?: boolean
) =>
  navigateHelper(
    history,
    getIndividualTestSuitePath(integrationID, testSuiteID),
    navigateToNewTab ?? false
  );

export function navigateToEndpointCollection(
  history: History,
  integrationID: string,
  testSuiteID: string,
  endpointTestCollectionID: string
) {
  return navigateHelper(
    history,
    getEndpointCollectionPath(integrationID, testSuiteID, endpointTestCollectionID)
  );
}

export function navigateToPublishModule(
  history: History,
  integrationID: string,
  shouldNavigateInNewTab: boolean = false
) {
  return navigateHelper(history, getPublishModulePath(integrationID), shouldNavigateInNewTab);
}

export function getAuthConfigTablePath(integrationID: string) {
  return `/integrations/${integrationID}/auth-configs`;
}

export function getReportTemplateTablePath(integrationID: string) {
  return `/integrations/${integrationID}/report-templates`;
}

export function getIndividualAuthConfigPath(integrationID: string, authConfigID: string) {
  return `${getAuthConfigTablePath(integrationID)}/${authConfigID}`;
}

export function getIndividualAuthConfig(integrationID: string, authConfigID: string) {
  return `${getAuthConfigTablePath(integrationID)}/${authConfigID}/edit`;
}

export function getIndividualReportTemplate(integrationID: string, reportTemplateID: string) {
  return `${getReportTemplateTablePath(integrationID)}/${reportTemplateID}/edit`;
}

export const navigateToAuthConfigsTable = (history: History, integrationID: string) =>
  navigateHelper(history, getAuthConfigTablePath(integrationID));

export const navigateToReportTemplatesTable = (history: History, integrationID: string) =>
  navigateHelper(history, getReportTemplateTablePath(integrationID));

export const navigatetoIndividualAuthConfig = (
  history: History,
  integrationID: string,
  auth_config: string
) => navigateHelper(history, getIndividualAuthConfig(integrationID, auth_config));

export const navigateToIndividualReportTemplate = (
  history: History,
  integrationID: string,
  report_template: string,
  shouldNavigateInNewTab: boolean = false
) =>
  navigateHelper(
    history,
    getIndividualReportTemplate(integrationID, report_template),
    shouldNavigateInNewTab
  );
