import { Card } from "@merge-api/merge-javascript-shared";
import CardHeader from "../../shared/CardHeader";
import BasicRateLimitConfiguration from "./BasicRateLimitConfiguration";
import RateLimitAdvanced from "./RateLimitAdvanced";
import RateLimitHeaders from "./RateLimitHeaders";

interface RateLimitConfigurationSetupOptionsProps {
  inlineSetup?: boolean;
}

const RateLimitConfigurationSetupOptions = ({
  inlineSetup = false,
}: RateLimitConfigurationSetupOptionsProps) => {
  return (
    <>
      {inlineSetup ? (
        <BasicRateLimitConfiguration />
      ) : (
        <Card className="pt-4 mt-6 px-5 pb-5 bg-white mb-6 min-w-fit" variant="shadow">
          <CardHeader title="Configure rate limit" />
          <BasicRateLimitConfiguration />
        </Card>
      )}
      <RateLimitHeaders inlineSetup={inlineSetup} />
      <RateLimitAdvanced inlineSetup={inlineSetup} />
    </>
  );
};

export default RateLimitConfigurationSetupOptions;
