import IntegrationBuilderHeader from "../shared/IntegrationBuilderHeader";
import IntegrationCreation from "./components/IntegrationCreation";

interface IntegrationInitializationProps {
  integrationID: string | undefined;
}

const IntegrationInitialization = ({ integrationID }: IntegrationInitializationProps) => {
  return (
    <div>
      <IntegrationBuilderHeader title="Integration initialization" showRequired />

      {/* Integration creation  */}
      <IntegrationCreation integrationID={integrationID} />
    </div>
  );
};

export default IntegrationInitialization;
