import { Tooltip, Text } from "@merge-api/merge-javascript-shared";

interface CardSubtitleTextProps {
  text: string | undefined;
  isLight?: boolean;
  tooltipTitle?: string;
  rightIcon?: React.ReactNode;
}

const BlueprintSearchCardSubtitleText: React.FC<CardSubtitleTextProps> = ({
  text,
  isLight = false,
  tooltipTitle = "",
  rightIcon = null,
}) => {
  if (!text) return null;

  return (
    <Tooltip title={tooltipTitle}>
      <div className="flex flex-row space-x-1 text-gray-70 items-center">
        {rightIcon}
        <Text variant={isLight ? "sm" : "title-sm"} className="max-w-[200px] truncate text-start">
          {text}
        </Text>
      </div>
    </Tooltip>
  );
};

export default BlueprintSearchCardSubtitleText;
