import {
  AlertColor,
  BaseColor,
  StrongColor,
} from "@merge-api/merge-javascript-shared/dist/designSystem/types";
import { ChecklistStatusValue } from "../../../constants";
import { AlertTriangle, CircleCheck } from "lucide-react";

export interface ChecklistDetail {
  title: string;
  badgeColor: BaseColor | StrongColor;
  alertColor: AlertColor;
  icon: JSX.Element;
}

export const CHECKLIST_VALUE_DISPLAY_DETAILS: Partial<Record<
  ChecklistStatusValue,
  ChecklistDetail
>> = {
  [ChecklistStatusValue.NOT_IMPLEMENTED]: {
    title: "Missing",
    badgeColor: "yellow",
    alertColor: "yellow",
    icon: <AlertTriangle color="black" size={16} />,
  },
  [ChecklistStatusValue.NOT_SUPPORTED]: {
    title: "Unsupported",
    badgeColor: "gray",
    alertColor: "gray",
    icon: <AlertTriangle color="gray" size={16} />,
  },
  [ChecklistStatusValue.IMPLEMENTED]: {
    title: "Completed",
    badgeColor: "teal",
    alertColor: "green",
    icon: <CircleCheck color="black" size={16} />,
  },
};
