import { Button, ButtonVariant, Dialog, Text, TextField } from "@merge-api/merge-javascript-shared";
import MergeCodeBlock from "../../../shared/MergeCodeBlock";
import { useState } from "react";

interface ConverterModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  inputTitle: string;
  outputTitle: string;
  placeholder: string;
  convertFunction: (input: string) => void;
  isLoading: boolean;
  result: string | null;
}

const ConverterModal = ({
  isModalOpen,
  setIsModalOpen,
  inputTitle,
  outputTitle,
  placeholder,
  convertFunction,
  isLoading,
  result,
}: ConverterModalProps) => {
  const [inputValue, setInputValue] = useState<string>("");

  const handleConvertClick = () => {
    convertFunction(inputValue);
  };

  return (
    <Dialog
      title={`Convert ${inputTitle} to ${outputTitle}`}
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
      variant="lg"
      footerButtonsHidden
    >
      <Text as="div" className="black font-semibold mb-2">
        {inputTitle}
      </Text>
      <TextField
        placeholder={placeholder}
        multiline
        rows={6}
        maxRows={6}
        className="mb-4"
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
      />
      <Button
        className="mb-6"
        variant={ButtonVariant.SecondaryBlue}
        fullWidth
        onClick={handleConvertClick}
        disabled={isLoading}
      >
        {isLoading ? "Converting..." : "Convert"}
      </Button>
      <Text className="black font-semibold mb-2">{outputTitle}</Text>
      <div className="max-h-[232px] overflow-y-auto">
        <MergeCodeBlock
          textToCopy={result || ""}
          customStyle={{
            padding: "10px",
            borderRadius: "8px",
            minHeight: "200px",
          }}
        >
          {result || "No result"}
        </MergeCodeBlock>
      </div>
    </Dialog>
  );
};

export default ConverterModal;
