import { Accordion, Badge, Card, Text } from "@merge-api/merge-javascript-shared";
import { ChecklistStatusValue } from "../../../../constants";
import { CHECKLIST_VALUE_DISPLAY_DETAILS } from "../constants";
import { usePublishModuleContext } from "../context/PublishModuleContext";
import IntegrationChecklistIssueV2 from "./IntegrationChecklistIssueV2";

const IntegrationChecklistResultsContainerV2 = () => {
  const {
    integrationID,
    integrationChecklist,
    fetchIntegrationChecklist,
  } = usePublishModuleContext();

  const integrationChecklistIssues = integrationChecklist
    ? Object.entries(integrationChecklist).filter(([_, value]) => {
        return Object.keys(CHECKLIST_VALUE_DISPLAY_DETAILS).includes(value);
      })
    : undefined;

  return (
    <div className="-my-4">
      {" "}
      {/* This is due to the padding placed on the parent AccordionCard */}
      {integrationChecklist && integrationChecklistIssues ? (
        <div className="divide-y divide-solid divide-gray-10">
          {Object.entries(CHECKLIST_VALUE_DISPLAY_DETAILS)
            .filter(([key]) => {
              const filteredIssues = integrationChecklistIssues?.filter(([_, v]) => v === key);
              return filteredIssues && filteredIssues.length > 0;
            })
            .map(([key, value]) => {
              // Filter the checklist issues for the current key
              const filteredIssues = integrationChecklistIssues?.filter(([_, v]) => v === key);

              // Only render the section if there are filtered issues
              return (
                <Accordion
                  title={
                    <div className="flex flex-row items-center space-x-2 py-4">
                      <Text>{value.title}</Text>
                      <Badge color={value.badgeColor}>{filteredIssues.length}</Badge>
                    </div>
                  }
                  defaultExpanded={key === ChecklistStatusValue.NOT_IMPLEMENTED}
                  chevronOrientation="down"
                  key={key}
                  variant="none"
                >
                  <div className="space-y-2 mb-4">
                    {filteredIssues.map(([k, v]) => (
                      <IntegrationChecklistIssueV2
                        key={k}
                        checklistItem={k}
                        checklistValue={v}
                        integrationID={integrationID}
                        fetchIntegrationChecklist={fetchIntegrationChecklist}
                      />
                    ))}
                  </div>
                </Accordion>
              );
            })}
        </div>
      ) : (
        <Card>
          <Text>No checklist information available.</Text>
        </Card>
      )}
    </div>
  );
};

export default IntegrationChecklistResultsContainerV2;
