import styled from "styled-components";
import MergeModal from "../../shared/MergeModal";
import MergeText from "../../shared/text/MergeText";
import { Button } from "react-bootstrap";
import InputFormField from "../../blueprint-editor/right-panel/InputFormField";
import {
  IntegrationCommonModelTemplate,
  IntegrationCommonModelTemplateField,
} from "../../../models/Entities";
import Dropdown from "../../blueprint-editor/right-panel/Dropdown";

const ModalButtonContainer = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  float: left;
  width: 100%;
  justify-content: center;
`;

const DeleteProceedButton = styled(Button)`
  background: #e62837;
  border: none;
  &:hover {
    background: #121314;
    border: none;
  }
  &:focus {
    background: #121314;
    border: none;
  }
`;

const NonCommonModelInputsContainer = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  float: left;
  width: 100%;
  justify-content: center;
`;

interface DeleteModalProps {
  showDeleteModal: boolean;
  setShowDeleteModal: (showDeleteModal: boolean) => void;
  deleteTemplate: () => void;
}

interface AddNonCommonModelFieldModalProps {
  template: IntegrationCommonModelTemplate;
  showAddNonCommonModelFieldModal: boolean;
  setShowAddNonCommonModelFieldModal: (showAddNonCommonModelFieldL: boolean) => void;
  nonCommonModelFieldTitle: string;
  setNonCommonModelFieldTitle: (nonCommonModelFieldTitle: string) => void;
  nonCommonModelFieldDescr: string;
  setNonCommonModelFieldDescr: (nonCommonModelFieldDescr: string) => void;
  nonCommonModelFieldParsed: string;
  setNonCommonModelFieldParsed: (nonCommonModelFieldParsed: string) => void;
  nonCommonModelFieldNull: string;
  setNonCommonModelFieldNull: (nonCommonModelFieldNull: string) => void;
  nonCommonModelFieldTransformation: string;
  setNonCommonModelFieldTransformation: (nonCommonModelFieldTransformation: string) => void;
}

export const DeleteNonCommonModelFieldModal = ({
  showDeleteModal,
  setShowDeleteModal,
  deleteTemplate,
}: DeleteModalProps) => {
  return (
    <MergeModal
      show={showDeleteModal ?? false}
      centered
      onHide={() => setShowDeleteModal(false)}
      title={"Delete template"}
      bodyClassName="overflow-hidden"
    >
      <div>This will permanently delete this template. Do you wish to continue?</div>

      <ModalButtonContainer>
        <Button
          className="mr-1.5"
          variant="outline-secondary"
          onClick={() => setShowDeleteModal(false)}
        >
          Cancel
        </Button>
        <DeleteProceedButton onClick={() => deleteTemplate()}>Delete template</DeleteProceedButton>
      </ModalButtonContainer>
    </MergeModal>
  );
};

export const AddNonCommonModelFieldModal = ({
  template,
  showAddNonCommonModelFieldModal,
  setShowAddNonCommonModelFieldModal,
  nonCommonModelFieldTitle,
  setNonCommonModelFieldTitle,
  nonCommonModelFieldDescr,
  setNonCommonModelFieldDescr,
  nonCommonModelFieldNull,
  setNonCommonModelFieldNull,
  nonCommonModelFieldParsed,
  setNonCommonModelFieldParsed,
  nonCommonModelFieldTransformation,
  setNonCommonModelFieldTransformation,
}: AddNonCommonModelFieldModalProps) => {
  return (
    <MergeModal
      show={showAddNonCommonModelFieldModal ?? false}
      centered
      onHide={() => setShowAddNonCommonModelFieldModal(false)}
      title={"Add Non-Common Model Field"}
      bodyClassName="overflow-hidden"
    >
      <NonCommonModelInputsContainer>
        <MergeText className="ml-1" size="14px" isBold>
          Non-Common Model Field Title
        </MergeText>
        <InputFormField
          onChange={(newValue) => setNonCommonModelFieldTitle(newValue)}
          currentValue={nonCommonModelFieldTitle}
        />
        <MergeText className="ml-1" size="14px" isBold>
          Non-Common Model Field Description
        </MergeText>
        <InputFormField
          onChange={(newValue) => setNonCommonModelFieldDescr(newValue)}
          currentValue={nonCommonModelFieldDescr}
        />
        <div className="mb-9">
          <MergeText className="ml-1" size="14px" isBold>
            Allow Nulls
          </MergeText>
          <Dropdown
            placeholder={nonCommonModelFieldNull}
            onChange={(e) => {
              setNonCommonModelFieldNull(e.target.value);
            }}
            currentValue={nonCommonModelFieldNull}
            choices={[
              {
                id: "true",
                name: "Allow nulls",
              },
              {
                id: "false",
                name: "No nulls",
              },
            ]}
          />
        </div>

        <div className="mb-9">
          <MergeText className="ml-1" size="14px" isBold>
            Use Unparsed or Parsed Value
          </MergeText>
          <Dropdown
            placeholder={nonCommonModelFieldParsed}
            onChange={(e) => {
              setNonCommonModelFieldParsed(e.target.value);
            }}
            currentValue={nonCommonModelFieldParsed}
            choices={[
              {
                id: "true",
                name: "Unparsed",
              },
              {
                id: "false",
                name: "Parsed",
              },
            ]}
          />
        </div>
        <div className="mb-9">
          <MergeText className="ml-1" size="14px" isBold>
            Apply Transformation to Value
          </MergeText>
          <Dropdown
            placeholder={nonCommonModelFieldTransformation}
            onChange={(e) => {
              setNonCommonModelFieldTransformation(e.target.value);
            }}
            currentValue={nonCommonModelFieldTransformation}
            choices={[{ id: "null", name: "None" }].concat(
              template!.non_common_model_transformations.map((transformation) => ({
                id: transformation,
                name: transformation,
              }))
            )}
          />
        </div>
      </NonCommonModelInputsContainer>
      <ModalButtonContainer>
        <Button
          className="mr-1.5"
          variant="outline-secondary"
          onClick={() => {
            setShowAddNonCommonModelFieldModal(false);
            setNonCommonModelFieldTitle("");
            setNonCommonModelFieldDescr("");
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            template?.unique_id_fields.push({
              field_name: nonCommonModelFieldTitle,
              description: nonCommonModelFieldDescr,
              is_common_model_field: false,
              should_allow_nulls: nonCommonModelFieldNull === "true" ? true : false,
              use_unparsed_value: nonCommonModelFieldParsed === "true" ? true : false,
              transformation:
                nonCommonModelFieldTransformation === "null"
                  ? null
                  : nonCommonModelFieldTransformation,
            } as IntegrationCommonModelTemplateField);
            setShowAddNonCommonModelFieldModal(false);
            setNonCommonModelFieldTitle("");
            setNonCommonModelFieldDescr("");
          }}
        >
          Add Field
        </Button>
      </ModalButtonContainer>
    </MergeModal>
  );
};
