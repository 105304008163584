import {
  BlueprintComparator,
  BlueprintIfElseStep,
  BlueprintParameterValue,
  BlueprintParameterValueType,
  BlueprintParameterValueReturnValue,
  BlueprintParameterValueStatementArray,
  BlueprintLogicalOperation,
  BlueprintLogicalOperator,
} from "../../../models/Blueprints";
import { BlueprintAvailableParameter } from "../utils/BlueprintEditorUtils";
import { Button } from "react-bootstrap";
import FormField from "./FormField";
import { HeaderPretitle } from "../../shared/text/MergeText";
import useBlueprintContext from "../context/useBlueprintContext";
import DropdownFormField from "./DropdownFormField";
import TypeaheadFormField from "./TypeaheadFormField";
import ClickableContainer from "../../shared/ClickableContainer";
import { MinusCircle } from "lucide-react";
import max from "lodash/max";
import TypeaheadFormFieldTypeahead from "./TypeaheadFormFieldTypeahead";
import { showErrorToast } from "../../shared/Toasts";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";
export const COMPARATOR_CONFIG: {
  [k: string]: {
    name: string;
    id: BlueprintComparator;
    arity: number;
    description?: string;
  };
} = {
  [BlueprintComparator.GREATER_THAN_OR_EQUAL]: {
    name: ">=",
    id: BlueprintComparator.GREATER_THAN_OR_EQUAL,
    arity: 2,
  },
  [BlueprintComparator.GREATER_THAN]: {
    name: ">",
    id: BlueprintComparator.GREATER_THAN,
    arity: 2,
  },
  [BlueprintComparator.EQUAL]: {
    name: "==",
    id: BlueprintComparator.EQUAL,
    arity: 2,
  },
  [BlueprintComparator.NOT_EQUAL]: {
    name: "!=",
    id: BlueprintComparator.NOT_EQUAL,
    arity: 2,
  },
  [BlueprintComparator.LESS_THAN]: {
    name: "<",
    id: BlueprintComparator.LESS_THAN,
    arity: 2,
  },
  [BlueprintComparator.LESS_THAN_OR_EQUAL]: {
    name: "<=",
    id: BlueprintComparator.LESS_THAN_OR_EQUAL,
    arity: 2,
  },
  [BlueprintComparator.CONTAINS]: {
    name: "Contains",
    id: BlueprintComparator.CONTAINS,
    arity: 2,
  },
  [BlueprintComparator.NOT_CONTAINS]: {
    name: "Does not contain",
    id: BlueprintComparator.NOT_CONTAINS,
    arity: 2,
  },
  [BlueprintComparator.IS_NULL_OR_EMPTY]: {
    name: "Is Null or Empty",
    id: BlueprintComparator.IS_NULL_OR_EMPTY,
    arity: 1,
    description: "Returns true if the value is None, '', [], or {}.",
  },
};

export const COMPARATOR_CHOICES = Object.values(COMPARATOR_CONFIG).map((value) => ({
  name: value.name,
  id: value.id,
}));

export const LOGICAL_OPERATOR_CHOICES = [
  { name: "ANY", id: BlueprintLogicalOperator.ANY },
  { name: "ALL", id: BlueprintLogicalOperator.ALL },
  { name: "NONE", id: BlueprintLogicalOperator.NONE },
];

const BlueprintEditorRightPanelIfElseForm = () => {
  const { selectedStep, updateStepParameterValue } = useBlueprintContext();
  const step = selectedStep as BlueprintIfElseStep;
  const statementArray =
    (step.parameter_values.statements as BlueprintParameterValueStatementArray)?.statement_array ??
    [];

  const isDeprecatedIfElse = !statementArray.length;

  if (isDeprecatedIfElse) {
    const currentComparatorValue =
      step.parameter_values?.["comparator"]?.value_type === BlueprintParameterValueType.constant
        ? step.parameter_values?.["comparator"]?.constant
        : null;

    const currentComparatorConfig = currentComparatorValue
      ? COMPARATOR_CONFIG[currentComparatorValue]
      : null;

    const currentComparatorArity = currentComparatorConfig?.arity ?? 2;

    return (
      <form>
        <HeaderPretitle className="my-2">Parameters</HeaderPretitle>
        <TypeaheadFormField
          title={currentComparatorArity === 2 ? "Value 1" : "Value"}
          subtitle={
            currentComparatorArity === 2 ? "The first value to compare." : "The value to compare"
          }
          valueKey={"value1"}
          parameterType={"any"}
        />
        <DropdownFormField
          choices={COMPARATOR_CHOICES}
          currentValue={currentComparatorValue}
          placeholder={"Select an operation."}
          title={"Operation"}
          subtitle={"The operation."}
          onChange={(e) =>
            updateStepParameterValue(step, "comparator", {
              constant: e.target.value,
              value_type: BlueprintParameterValueType.constant,
            })
          }
        />
        {currentComparatorArity === 2 && (
          <TypeaheadFormField
            title={"Value 2"}
            subtitle={"The second value to compare."}
            valueKey={"value2"}
            parameterType={"any"}
          />
        )}
      </form>
    );
  } else {
    const updateStatements = (newStatementArray: Array<BlueprintLogicalOperation>) =>
      updateStepParameterValue(step, "statements", {
        value_type: BlueprintParameterValueType.statementArray,
        statement_array: newStatementArray,
      });

    const updateLogicalOperator = (newLogicalOperator: BlueprintLogicalOperator) =>
      updateStepParameterValue(step, "logical_operator", {
        value_type: BlueprintParameterValueType.constant,
        constant: newLogicalOperator,
      });

    const nextStatementIndex =
      (max(statementArray.map((statement) => Number(statement.id.slice(9)))) ?? 0) + 1;

    const initializeStatement = (statementIndex: number): BlueprintLogicalOperation => {
      return { id: "statement" + statementIndex, val1: null, val2: null, comparator: null };
    };
    const addStatement = () => {
      updateStatements([...statementArray, initializeStatement(nextStatementIndex)]);
    };

    const getIndexForStatementID = (statementID: string): number => {
      return statementArray.findIndex((statement) => statement.id === statementID);
    };

    const updateExistingStatement = (newStatement: BlueprintLogicalOperation) => {
      const index = getIndexForStatementID(newStatement.id);

      updateStatements([
        ...statementArray.slice(0, index),
        newStatement,
        ...statementArray.slice(index + 1),
      ]);
    };

    const updateStatementParameterValue = (
      statement: BlueprintLogicalOperation,
      parameterKey: string,
      parameterValue: BlueprintParameterValue | null
    ) => {
      updateExistingStatement({
        ...statement,
        [parameterKey]: parameterValue,
      });
    };

    const deleteStatement = (deletedStatement: BlueprintLogicalOperation) => {
      if (statementArray.length < 2) {
        showErrorToast("Must have at least 1 statement.");
      } else {
        const index = getIndexForStatementID(deletedStatement.id);
        updateStatements([...statementArray.slice(0, index), ...statementArray.slice(index + 1)]);
      }
    };

    const currentLogicalOperator =
      step.parameter_values?.["logical_operator"]?.value_type ===
      BlueprintParameterValueType.constant
        ? step.parameter_values?.["logical_operator"]?.constant
        : null;

    return (
      <form>
        <HeaderPretitle className="mt-3">Statements</HeaderPretitle>
        <DropdownFormField
          choices={LOGICAL_OPERATOR_CHOICES}
          currentValue={currentLogicalOperator}
          placeholder={"Select a logical operator"}
          title={"Logical Operator"}
          subtitle={"How many of the following statements must be true?"}
          onChange={(e) => {
            updateLogicalOperator(e.target.value);
          }}
        />
        {statementArray &&
          statementArray.map((statement) => {
            const comparatorValue =
              statement?.["comparator"]?.value_type === BlueprintParameterValueType.constant
                ? statement?.["comparator"]?.constant
                : null;

            const currentComparatorConfig = comparatorValue
              ? COMPARATOR_CONFIG[comparatorValue]
              : null;

            const currentComparatorArity = currentComparatorConfig?.arity ?? 2;
            const comparatorDescription = currentComparatorConfig?.description;

            return (
              <div>
                <div className="mb-1.5 d-flex align-items-center justify-content-between">
                  <DeprecatedH4 className="mb-0">{statement.id}</DeprecatedH4>
                  <div className="ml-1.5">
                    <ClickableContainer onClick={() => deleteStatement(statement)}>
                      <MinusCircle className="red" strokeWidth={1.5} />
                    </ClickableContainer>
                  </div>
                </div>
                <FormField
                  title={currentComparatorArity === 2 ? "Value 1" : "Value"}
                  subtitle={
                    currentComparatorArity === 2
                      ? "The first value to compare."
                      : "The value to compare."
                  }
                >
                  <TypeaheadFormFieldTypeahead
                    allowConstantValues
                    currentParameterValue={statement.val1}
                    parameterType={"any"}
                    onChange={(options: BlueprintAvailableParameter[]) =>
                      updateStatementParameterValue(
                        statement,
                        "val1",
                        options[0]?.customOption
                          ? {
                              constant: options[0].labelKey,
                              value_type: BlueprintParameterValueType.constant,
                            }
                          : (options[0]
                              ?.parameterValue as BlueprintParameterValueReturnValue | null)
                      )
                    }
                  />
                </FormField>
                <DropdownFormField
                  choices={COMPARATOR_CHOICES}
                  currentValue={
                    statement?.["comparator"]?.value_type === BlueprintParameterValueType.constant
                      ? statement?.["comparator"]?.constant
                      : null
                  }
                  placeholder={"Select an operation"}
                  title={"Operation"}
                  subtitle={`The operation. ${comparatorDescription ?? ""}`}
                  onChange={(e) => {
                    updateStatementParameterValue(statement, "comparator", {
                      constant: e.target.value,
                      value_type: BlueprintParameterValueType.constant,
                    });
                  }}
                />
                {currentComparatorArity === 2 && (
                  <FormField title={"Value 2"} subtitle={"The second value to compare."}>
                    <TypeaheadFormFieldTypeahead
                      allowConstantValues
                      currentParameterValue={statement.val2}
                      parameterType={"any"}
                      onChange={(options: BlueprintAvailableParameter[]) =>
                        updateStatementParameterValue(
                          statement,
                          "val2",
                          options[0]?.customOption
                            ? {
                                constant: options[0].labelKey,
                                value_type: BlueprintParameterValueType.constant,
                              }
                            : (options[0]
                                ?.parameterValue as BlueprintParameterValueReturnValue | null)
                        )
                      }
                    />
                  </FormField>
                )}
              </div>
            );
          })}
        <Button className="mt-0 mb-3 btn-block" onClick={() => addStatement()}>
          Add New Statement
        </Button>
      </form>
    );
  }
};

export default BlueprintEditorRightPanelIfElseForm;
