import { useState } from "react";
import { ButtonVariant, Dialog, Text } from "@merge-api/merge-javascript-shared";
import KeyPathFieldHeader from "../../../../../shared/KeyPathFieldHeader";
import { SubFilterKeyPath } from "../../../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";

const INTERCOM_JSON_STRING_TEMPLATE = JSON.stringify(
  {
    query: {
      operator: "AND",
      value: [
        {
          field: "{KEY}",
          operator: ">",
          value: "1306054154",
        },
      ],
    },
    pagination: {
      per_page: 5,
    },
  },
  null,
  2
);

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (keyName: SubFilterKeyPath | undefined | null) => void;
  compositeFilterMappingSubFilterKey: SubFilterKeyPath | undefined;
}

const CompositeFilterMappingKeyModal = ({
  open,
  onClose,
  onSave,
  compositeFilterMappingSubFilterKey,
}: Props) => {
  const [keyName, setKeyName] = useState<SubFilterKeyPath | undefined | null>(
    compositeFilterMappingSubFilterKey
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Set key path in composite filter"
      primaryButtonText="Save"
      primaryButtonVariant={ButtonVariant.PrimaryBlue}
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={() => {
        onSave(keyName);
        onClose();
      }}
      onSecondaryButtonClick={() => onClose()}
      variant="lg"
    >
      <div className="flex flex-col space-y-4">
        <KeyPathFieldHeader
          title="Key path in composite filter"
          subtitle="The location of the key in the request for this 3rd-party API endpoint filter. Used when the composite filter engine translates Selective Sync filter input to 3rd-party API request params. If still unsure, ask engineering about how the associated composite filter type works."
          learnMoreTextComponent={
            <div className="flex flex-col space-y-2">
              <Text variant="sm" className="text-gray-60">
                For example, our composite filter engine for Intercom will insert the value entered
                in this field as {"{"}KEY{"}"} in Intercom API request body below:
              </Text>
              <Text as="pre" variant="sm-mono" className="text-gray-60">
                {INTERCOM_JSON_STRING_TEMPLATE}
              </Text>
            </div>
          }
          keyPath={keyName}
          onKeyPathChange={(keyPath: SubFilterKeyPath | undefined | null) => setKeyName(keyPath)}
        />
      </div>
    </Dialog>
  );
};

export default CompositeFilterMappingKeyModal;
