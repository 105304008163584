import { ReactNode } from "react";

interface CardHeaderProps {
  title: string;
  button?: ReactNode;
}

const CardHeader = ({ title, button }: CardHeaderProps) => {
  return (
    <>
      <div className="text-slate-90 text-lg font-semibold mb-2 justify-between items-center flex flex-row">
        {title} {button}
      </div>
      <hr className="text-gray-50 h-[0.5px] my-4 -mx-5" />
    </>
  );
};
export default CardHeader;
