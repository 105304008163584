import { ChevronRight } from "lucide-react";
import styled from "styled-components";
import MergeText, { TextType } from "../../shared/text/MergeText";

const LinkRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
`;

const Link = styled.div`
  font-weight: 600;
  color: #2c7be5;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;

  &&:hover {
    color: #1657af;
    text-decoration: none;
  }
`;

type LinkType =
  | {
      onClick: () => void;
      title: string;
    }
  | {
      onClick?: never;
      title: string;
    };
type Props = {
  links: LinkType[];
};

const MappingTestTableViewLinkRow = ({ links }: Props) => {
  return (
    <LinkRow>
      {links.map((link) => {
        return (
          <>
            {!link?.onClick ? (
              <MergeText isBold size="15px" type={TextType.MUTED}>
                {link.title}
              </MergeText>
            ) : (
              <Link onClick={link.onClick}>{link.title}</Link>
            )}
            {link?.onClick && <ChevronRight />}
          </>
        );
      })}
    </LinkRow>
  );
};

export default MappingTestTableViewLinkRow;
