import {
  BlueprintParameterValue,
  BlueprintParameterValueType,
  BlueprintStepTemplate,
} from "../../../models/Blueprints";
import CreateOrUpdateForm from "./CreateOrUpdateForm";

interface Props {
  stepID: string;
  stepTemplate: BlueprintStepTemplate;
}

const BlueprintEditorRightPanelInitializeWrittenCommonModelForm = ({
  stepID,
  stepTemplate,
}: Props) => {
  const validator = (valueKey: string, parameterValue: BlueprintParameterValue) => {
    if (parameterValue.is_unique_identifier || valueKey === "unique_identifier") {
      // Can't set null unique identifiers when initializing a model.
      return parameterValue.value_type !== BlueprintParameterValueType.none;
    }
    return true;
  };
  return <CreateOrUpdateForm stepID={stepID} stepTemplate={stepTemplate} validator={validator} />;
};

export default BlueprintEditorRightPanelInitializeWrittenCommonModelForm;
