import { useEffect, useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast } from "../../../shared/Toasts";
import { AuthConfigIntegrationBuilder } from "../../utils/Entities";

interface loadAuthConfigProps {
  integrationID: string;
}

const useLoadAuthConfigs = ({ integrationID }: loadAuthConfigProps) => {
  // state
  const [authConfigs, setAuthConfigs] = useState<AuthConfigIntegrationBuilder[] | undefined>();
  const [isLoadingAuthConfig, setIsLoadingAuthConfig] = useState(true);

  // effects
  useEffect(() => {
    fetchWithAuth({
      path: `/integrations/${integrationID}/auth-configs`,
      method: "GET",
      onResponse: (data: AuthConfigIntegrationBuilder[]) => {
        setAuthConfigs(data);
        setIsLoadingAuthConfig(false);
      },
      onError: () => {
        showErrorToast("Failed to fetch authentication configurations for integration.");
        setAuthConfigs([]);
      },
    });
  }, [setAuthConfigs]);

  return { authConfigs, isLoadingAuthConfig, setAuthConfigs };
};

export default useLoadAuthConfigs;
