import { Route, Switch, useParams } from "react-router-dom";
import { Integration } from "../../../models/Entities";
import IndividualEndpointCollectionOverview from "./IndividualEndpointCollectionOverview";
import IndividualMappingTestSuiteOverview from "./IndividualMappingTestSuiteOverview";
import { useFetchMappingTestSuite } from "./hooks/useFetchMappingTestSuite";

type RouteParams = {
  integrationID: string;
  mappingTestSuiteID: string;
};

type Props = {
  integrationMeta: Integration;
};

const IndividualMappingTestSuiteRouter = ({ integrationMeta }: Props) => {
  const { mappingTestSuiteID } = useParams<RouteParams>();
  const { mappingTestSuite, fetchMappingTestSuite } = useFetchMappingTestSuite({
    mappingTestSuiteID,
  });

  return (
    <Switch>
      <Route
        path={
          "/integrations/:integrationID/test-suites/:mappingTestSuiteID/collections/:endpointCollectionID"
        }
        children={() => (
          <IndividualEndpointCollectionOverview
            integrationMeta={integrationMeta}
            endUserOrganizationName={mappingTestSuite?.end_user_organization_name ?? ""}
          />
        )}
      />
      <Route
        children={() => (
          <IndividualMappingTestSuiteOverview
            mappingTestSuite={mappingTestSuite}
            fetchMappingTestSuite={fetchMappingTestSuite}
          />
        )}
      />
    </Switch>
  );
};

export default IndividualMappingTestSuiteRouter;
