import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast } from "../../../../components/shared/Toasts";
import { APIEndpointMethod } from "../../../../models/Entities";

interface Props {
  endpointCollectionRunID: string;
}

const usePatchEndpointCollectionRun = ({ endpointCollectionRunID }: Props) => {
  const patchEndpointCollectionRun = (notification_status: string) => {
    fetchWithAuth({
      path: `/integrations/integration-builder/api-endpoint-collection-run/${endpointCollectionRunID}`,
      method: APIEndpointMethod.PATCH,
      body: { notification_status },
      onResponse: () => {},
      onError: () => {
        showErrorToast("Failed to update endpoint collection run.");
      },
    });
  };

  return { patchEndpointCollectionRun };
};

export default usePatchEndpointCollectionRun;
