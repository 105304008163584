import {
  Card,
  AdminAPICategory,
  iconForAPICategory,
  descriptionForAPICategory,
  displayNameForAPICategory,
  Checkbox,
} from "@merge-api/merge-javascript-shared";
import clsx from "clsx";
import { useEffect, useState } from "react";

interface IntegrationCategoryCardProps {
  category: AdminAPICategory;
  selected: boolean;
  onClick: () => void;
}

const IntegrationCategoryCard = ({ category, selected, onClick }: IntegrationCategoryCardProps) => {
  // constants
  const coloredCategoryIcon = iconForAPICategory(category, 14, true, true);
  const categoryDescription = descriptionForAPICategory(category);
  const categoryDisplayName = displayNameForAPICategory(category);

  // state to determine whether the checkbox is checked - delayed for a beautiful animation
  const [isCheckedForAnimation, setIsCheckedForAnimation] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCheckedForAnimation(selected);
    }, 10);

    return () => {
      clearTimeout(timer);
    };
  }, [selected]);

  return (
    <Card
      onClick={onClick}
      variant="none"
      type="button"
      className={clsx(
        "px-3 pt-2.5 pb-2 w-full min-w-[297px] hover:border-blue-40",
        selected ? "border border-blue-40" : "border border-gray-20"
      )}
    >
      <div className="flex flex-row items-start gap-x-2">
        <div className="h-5 w-5 flex items-center mt-0.5">
          {selected ? (
            <Checkbox variant="primary" checked={isCheckedForAnimation} />
          ) : (
            coloredCategoryIcon
          )}
        </div>
        <div className="flex flex-col items-start">
          <div className="text-black font-semibold text-base"> {categoryDisplayName}</div>
          <div className="text-gray-70 text-base"> {categoryDescription}</div>
        </div>
      </div>
    </Card>
  );
};

export default IntegrationCategoryCard;
