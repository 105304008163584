import { Alert } from "@merge-api/merge-javascript-shared";
import useBlueprintContext from "../../context/useBlueprintContext";
import { useEffect, useState } from "react";
import BlueprintCanvasTopBannerTitle from "./components/BlueprintCanvasTopBannerTitle";
import BlueprintCanvasTopBannerStepNavigator from "./components/BlueprintCanvasTopBannerStepNavigator";

interface props {
  scrollToStep: (stepID: string) => void;
  canvasRef: React.RefObject<HTMLDivElement>;
}

interface styleProps {
  width: string;
  left: string;
}

/**
 * Renders the top banner that hovers above the Blueprint canvas
 * Currently just used for managing step-tracing view
 */
const BlueprintCanvasTopBanner = ({ scrollToStep, canvasRef }: props) => {
  const {
    setIsTracing,
    stepParameterToTrace,
    setStepParameterToTrace,
    setTracedStepIDs,
    tracedStepIDs,
    selectedStep,
  } = useBlueprintContext();

  const [style, setStyle] = useState<styleProps>({ width: "", left: "" });

  const calculateOffset = () => {
    if (canvasRef.current) {
      const rect = canvasRef.current.getBoundingClientRect();
      const leftOffset = rect.left; // Distance from left of viewport
      const width = rect.width - 40; // Component's width

      setStyle({
        width: `${width}px`,
        left: `${leftOffset}px`,
      });
    }
  };

  // Listens to resize
  useEffect(() => {
    // Initial calculation
    calculateOffset();

    // Recalculate on window resize
    window.addEventListener("resize", calculateOffset);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("resize", calculateOffset);
    };
  }, []);

  // This banner should not be rendered if no tracing parameters exist
  if (!stepParameterToTrace) return null;

  // Handler when "Close" button is clicked -- should close tracing view and reset states
  const handleClose = () => {
    setIsTracing(false);
    setStepParameterToTrace(undefined);
    setTracedStepIDs([]);
  };

  // Handler when step is clicked -- should auto-scroll and auto-focus on step
  const handleClickStep = () => {
    scrollToStep(stepParameterToTrace.step_id);
  };

  return (
    <div style={style} className="fixed top-0 mt-14 ml-5 h-fit z-50">
      <Alert size="sm" color="purple" className="shadow-[0px_1.5px_4px_-1px_rgba(0,0,0,0.16)]">
        <div className="flex flex-col space-y-2 w-full">
          <BlueprintCanvasTopBannerTitle
            stepParameterToTrace={stepParameterToTrace}
            handleClickStep={handleClickStep}
            handleClose={handleClose}
          />
          <BlueprintCanvasTopBannerStepNavigator
            tracedStepIDs={tracedStepIDs}
            selectedStepID={selectedStep?.id}
            scrollToStep={scrollToStep}
          />
        </div>
      </Alert>
    </div>
  );
};

export default BlueprintCanvasTopBanner;
