import { useMemo, useState } from "react";
import {
  Integration,
  IntegrationCommonModelConfig,
  IntegrationCommonModelTemplate,
} from "../../../models/Entities";
import styled from "styled-components";
import { fetchWithAuth } from "../../../api-client/api_client";
import { showErrorToast } from "../../shared/Toasts";
import { CATEGORY_COMMON_MODEL_TYPES } from "../../../constants";
import { SmallTextMutedParagraph } from "../../shared/text/MergeText";
import { Row, Col } from "react-bootstrap";
import MergeText from "../../shared/text/MergeText";
import { getReadableCommonModelName } from "./utils/UIDUtils";
import { navigateToUIDTemplateEditor } from "../../../router/RouterUtils";
import { useHistory, useParams } from "react-router-dom";
import FullPageSpinner from "../../shared/layout/FullPageSpinner";
import { UIDTemplateCards } from "./UIDTemplateCards";
import { apiCategoryFromString } from "../../../models/Helpers";

interface Props {
  integrationMeta: Integration;
  integrationCommonModelConfigs: IntegrationCommonModelConfig[] | undefined;
}

type RouteParams = {
  category: string;
};

const TextContainer = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
`;

const UIDTemplateManagementView = ({ integrationMeta, integrationCommonModelConfigs }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  const { category } = useParams<RouteParams>();

  const apiCategory = apiCategoryFromString(category);
  const currentCategoryModels = Object.keys(CATEGORY_COMMON_MODEL_TYPES[apiCategory!]);
  currentCategoryModels.sort();

  const createTemplate = (integrationID: string, category: string, model: string) => {
    setIsLoading(true);
    fetchWithAuth({
      path: `/integrations/${integrationID}/uid/template_configs`,
      method: "POST",
      body: {
        integrationID: integrationID,
        category: category,
        model: model,
      },
      onResponse: (data: IntegrationCommonModelTemplate) => {
        navigateToUIDTemplateEditor(history, integrationID, data.id);
      },
      onError: () => {
        showErrorToast("Failed to create template.");
        setIsLoading(false);
      },
    });
  };

  let currentCategoryModelsToConfigs: {
    [model: string]: IntegrationCommonModelConfig | undefined;
  } = {};

  currentCategoryModelsToConfigs = useMemo(() => {
    let newCurrentCategoryModelsToConfigs: {
      [model: string]: IntegrationCommonModelConfig | undefined;
    } = {};
    if (integrationCommonModelConfigs) {
      currentCategoryModels.forEach((model) => {
        const config = integrationCommonModelConfigs.find((config) => {
          return (
            config.category.toLowerCase() === category.toLowerCase() &&
            config.model.toLowerCase() === model.replaceAll("_", " ").toLowerCase()
          );
        });
        newCurrentCategoryModelsToConfigs[model] = config;
      });
    }
    return newCurrentCategoryModelsToConfigs;
  }, [currentCategoryModels, integrationCommonModelConfigs]);

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <Row className="align-items-center">
        <Col className="col-1">
          <SmallTextMutedParagraph className="mb-0">Common Model</SmallTextMutedParagraph>
        </Col>
        <Col className="col-2">
          <hr />
        </Col>
        <Col className="col-2 ml-3">
          <SmallTextMutedParagraph className="mb-0">
            Unique Identifier Template
          </SmallTextMutedParagraph>
        </Col>
        <Col className="col-6">
          <hr />
        </Col>
      </Row>
      {currentCategoryModels.map((model) => (
        <Row className="my-3" key={model}>
          <Col>
            <Row className="align-items-top mt-6 mb-9">
              <Col className="col-3">
                <TextContainer>
                  <MergeText isBold size="20px">
                    {getReadableCommonModelName(model, "_")}
                  </MergeText>
                </TextContainer>
              </Col>
              <Col className="col-8 align-items: center">
                <UIDTemplateCards
                  integrationID={integrationMeta.id}
                  templates={currentCategoryModelsToConfigs[model]?.unique_id_templates!}
                  category={category}
                  model={model}
                  createTemplate={createTemplate}
                />
              </Col>
            </Row>
            <hr />
          </Col>
        </Row>
      ))}
    </>
  );
};

export default UIDTemplateManagementView;
