import { BlueprintParameterValue, BlueprintParameterValueType } from "../../../models/Blueprints";
import FormField, { FormFieldCommonProps } from "./FormField";
import TypeaheadFormFieldTypeahead from "./TypeaheadFormFieldTypeahead";

interface Props extends FormFieldCommonProps {
  onChange: (option: BlueprintParameterValue | null) => void;
  currentParameterValue: null | BlueprintParameterValue;
  procedureIndex?: number;
  shouldIncludeProcedureParameters?: boolean;
}

const NestedTypeaheadFormField = ({
  currentParameterValue,
  onChange,
  procedureIndex,
  shouldIncludeProcedureParameters,
  title,
  subtitle,
}: Props) => {
  return (
    <FormField subtitle={subtitle} title={title} valueType={"any"}>
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">
          <TypeaheadFormFieldTypeahead
            allowConstantValues
            currentParameterValue={currentParameterValue}
            procedureIndex={procedureIndex}
            shouldIncludeProcedureParameters={shouldIncludeProcedureParameters}
            onChange={(options) =>
              onChange(
                options[0]?.customOption
                  ? {
                      constant: options[0].labelKey,
                      value_type: BlueprintParameterValueType.constant,
                    }
                  : options[0]?.parameterValue
              )
            }
            parameterType={"any"}
          />
        </div>
      </div>
    </FormField>
  );
};

export default NestedTypeaheadFormField;
