import { format } from "date-fns";
import { useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import MergeCopyToClipboard from "../../shared/MergeCopyToClipboard";
import { HeaderPretitle } from "../../shared/text/MergeText";

const ConsoleSubtabContainer = styled.div`
  margin-top: 12px;
`;

const FormattedDateCopyContainer = styled.div`
  margin-top: 12px;
`;

const FormattedDateCopy = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const getFormattedDate = (dateString: string | undefined) => {
  if (!dateString) {
    return undefined;
  }

  try {
    var now = undefined;

    // Check if it's a unix timestamp
    if (typeof dateString == "string" && dateString.match("[0-9]+")?.[0] == dateString) {
      const unixTimestamp = parseInt(dateString);
      now = new Date(unixTimestamp * 1000);
    } else {
      now = new Date(dateString);
    }

    return format(
      new Date(now.getTime() + now.getTimezoneOffset() * 60000),
      "yyyy-MM-dd'T'HH:mm:ss'Z'"
    );
  } catch {
    return undefined;
  }
};

const MappingTestEditorLeftPanelUtilsSubtab = () => {
  const [timestampValue, setTimestampValue] = useState<string>();
  const formattedDate = getFormattedDate(timestampValue);

  return (
    <ConsoleSubtabContainer>
      <div>
        <HeaderPretitle>Timestamp Converter</HeaderPretitle>
        <Form.Control
          id="timestamp"
          onChange={(e) => setTimestampValue(e.target.value)}
          placeholder="Enter timestamp"
          value={timestampValue}
        />
        {formattedDate && (
          <FormattedDateCopyContainer>
            <HeaderPretitle>Formatted Date</HeaderPretitle>
            <FormattedDateCopy>
              <b>{`${formattedDate}`}</b>
              <MergeCopyToClipboard textToCopy={formattedDate} />
            </FormattedDateCopy>
          </FormattedDateCopyContainer>
        )}
      </div>
    </ConsoleSubtabContainer>
  );
};

export default MappingTestEditorLeftPanelUtilsSubtab;
