import { BlueprintParameterValue, BlueprintStepType } from "../../models/Blueprints";
import StepCardPreview from "./stepcard/step-card-preview/StepCardPreview";
import StepCardIcon from "./stepcard/StepCardIcon";
import StepCardHeader from "./stepcard/StepCardHeader";
import StepCardAdditionalInfo from "./stepcard/StepCardAdditionalInfo";
import StepCardSubheader from "./stepcard/StepCardSubheader";
import clsx from "clsx";
import { StepCardStatusBadgeProps } from "./stepcard/models/StepCardModels";

interface Props {
  cardClassName?: string;
  title: string;
  subtitle: string;
  stepIconClassName?: string;
  stepParameterValues?: Record<string, BlueprintParameterValue>;
  stepImg?: string;
  stepType?: BlueprintStepType | string;
  onClick?: () => void;
  onContextMenu?: (e: any) => void;
  id?: string;
  additionalCardInfo?: Record<string, string | [string]>;
  statusBadgeProps?: StepCardStatusBadgeProps | undefined;
}

const StepCard = ({
  cardClassName,
  title,
  subtitle,
  stepIconClassName,
  stepParameterValues,
  stepImg,
  stepType,
  onClick,
  onContextMenu,
  id,
  additionalCardInfo,
  statusBadgeProps,
}: Props) => {
  const shouldShowPreview = (
    stepParameterValues: Record<string, BlueprintParameterValue> | undefined
  ) =>
    stepParameterValues &&
    (stepParameterValues.array ||
      stepParameterValues.value ||
      stepType === BlueprintStepType.IfElse ||
      stepType === BlueprintStepType.SetVariable ||
      stepType === BlueprintStepType.DataTransform ||
      stepType === BlueprintStepType.CustomFunction);

  return (
    <div
      className={clsx(
        cardClassName,
        "flex flex-col space-y-2 p-2 border border-gray-20 w-[350px] rounded-md cursor-pointer"
      )}
      onContextMenu={onContextMenu}
      id={id}
      onClick={onClick}
    >
      <div className="flex flex-row space-x-2 items-start">
        <StepCardIcon stepImg={stepImg} stepIconClassName={stepIconClassName} />
        <div className="flex flex-col items-start w-full truncate">
          <StepCardHeader title={subtitle} statusBadgeProps={statusBadgeProps} />
          <StepCardSubheader subtitle={title} />
          {additionalCardInfo && <StepCardAdditionalInfo additionalCardInfo={additionalCardInfo} />}
        </div>
      </div>
      {stepParameterValues && shouldShowPreview(stepParameterValues) && (
        <StepCardPreview step={stepParameterValues} stepType={stepType} />
      )}
    </div>
  );
};

export default StepCard;
