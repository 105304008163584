import { JSONSchema7 } from "json-schema";
import { JSONSchema } from "../../../models/Blueprints";

export interface IntegrationComponentVersion {
  model_data: Record<string, string>;
  model_display_data: Record<string, string>;
  model_id: string;
  has_changes: boolean;
}

export enum NextComponentVersionState {
  DRAFT_WITH_CHANGES = "DRAFT_WITH_CHANGES",
  STAGED_WITH_CHANGES = "STAGED_WITH_CHANGES",
  NO_CHANGES = "NO_CHANGES",
}
export interface ParentIntegrationComponentModelAndVersions {
  model_id: string;
  model_data: Record<string, string>;
  model_display_data: Record<string, string>;
  has_published_version: boolean;
  next_component_version: undefined | IntegrationComponentVersion;
  next_component_version_state: NextComponentVersionState;
  published_component_version: undefined | IntegrationComponentVersion;
}

export interface AdminModelFormConfiguration {
  form_schema: JSONSchema7;
  app_label: string;
  model_name: string;
  list_display_fields: string[];
  relation_fields: string[];
}

export interface ComponentVersionAndParentFormConfiguration {
  name: string;
  url: string;
  component_version_configuration: AdminModelFormConfiguration;
  component_configuration: AdminModelFormConfiguration;
}

export type PydanticJsonSchemas = Record<string, JSONSchema>;

export type APIEndpoint = {
  id?: string;
  name?: string;
  method: "GET" | "OPTIONS" | "HEAD" | "POST" | "PUT" | "PATCH" | "DELETE";
  path: string;
  request_type?: "REST" | "SOAP";
};
