import { Text, Accordion } from "@merge-api/merge-javascript-shared";
import { IntegrationBuilderAutogenRun } from "../../utils/Entities";
import { formatDate, getDuration } from "../../utils/helpers";

interface AutogeneratedConfigurationPreviousRunsProps {
  autogen_runs: IntegrationBuilderAutogenRun[];
}

const AutogeneratedConfigurationPreviousRuns = ({
  autogen_runs,
}: AutogeneratedConfigurationPreviousRunsProps) => {
  const formatTime = (start_time: string, end_time: string) => {
    if (!start_time || !end_time) return "-";
    return `${formatDate(start_time)} - ${formatDate(end_time)}`;
  };

  return (
    <Accordion
      chevronSize={16}
      chevronOrientation="right"
      defaultExpanded={false}
      className="border-t-[1px] border-t-gray-10 overflow-hidden rounded-t-none"
      titleClassName="px-4 py-3 bg-gray-0"
      variant="none"
      title={<Text variant="h6">Previous attempts</Text>}
    >
      <div className="px-4 bg-gray-0">
        <div className="flex flex-row justify-start pb-3">
          <Text className="w-1/3 text-gray-70" variant="sm">
            Time
          </Text>
          <Text className="w-1/12 text-gray-70" variant="sm">
            Run time
          </Text>
          <Text className="w-7/12 text-gray-70" variant="sm">
            Exception message
          </Text>
        </div>
        <div>
          {autogen_runs.map((run, index) => (
            <div
              key={index}
              className="flex flex-row justify-start border-t-[0.5px] border-gray-20 py-3 items-center"
            >
              <Text className="w-1/3">
                {formatTime(run?.start_time ?? "", run?.end_time ?? "")}
              </Text>
              <Text className="w-1/12">
                {getDuration(run?.start_time ?? "", run?.end_time ?? "")}
              </Text>
              <Text className="w-7/12">{run.exception_message}</Text>
            </div>
          ))}
        </div>
      </div>
    </Accordion>
  );
};

export default AutogeneratedConfigurationPreviousRuns;
