import { useContext } from "react";
import { ChangelogEvent, ChangelogEventType } from "../../utils/types";
import ChevronCell from "./components/ChevronCell";
import DateCell from "./components/DateCell";
import EventCell from "./components/EventCell";
import ModelCell from "./components/ModelCell";
import UserCell from "./components/UserCell";
import { Accordion, AccordionContext, useAccordionToggle } from "react-bootstrap";
import clsx from "clsx";
import FieldsChangedContainer from "./components/FieldsChangedContainer";
import PublishInformationContainer from "./components/PublishInformationContainer";

type ChangelogTableRowProps = {
  changelogEvent: ChangelogEvent;
};

const ChangelogTableRow = ({ changelogEvent }: ChangelogTableRowProps) => {
  // destructured props from changelogEvent
  const {
    id,
    user_name,
    user_email,
    created_at,
    event_type,
    model_type,
    model_id,
    updated_fields,
    new_fields,
    original_fields,
    blueprint_name,
    asana_ticket_url,
    publish_description,
    github_deploy_pr_url,
  } = changelogEvent;
  const isEditEventType =
    event_type === ChangelogEventType.EDITED &&
    !!updated_fields &&
    !!new_fields &&
    !!original_fields;

  const isPublishEventType =
    event_type === ChangelogEventType.PUBLISHED || event_type === ChangelogEventType.UNPUBLISHED;

  const isExpandableEventType = isEditEventType || isPublishEventType;
  // state and functions for accordion display
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(id, () => {
    // no need to manually update the context here, useAccordionToggle will handle it
  });

  const isExpanded = currentEventKey === id;

  return (
    <>
      <tr
        onClick={isExpandableEventType ? toggleOnClick : undefined}
        className={clsx({
          "cursor-pointer": isExpandableEventType,
          "cursor-default": !isExpandableEventType,
        })}
      >
        <UserCell name={user_name} email={user_email} />
        <ModelCell
          modelType={model_type}
          eventType={event_type}
          modelID={model_id}
          githubDeployURL={github_deploy_pr_url}
        />
        <EventCell eventType={event_type} />
        <DateCell date={created_at} />
        <ChevronCell isRotatedDown={isExpanded} isVisible={isExpandableEventType} />
      </tr>
      <tr className="border-none">
        <td colSpan={5} className="p-0 border-none">
          {isExpandableEventType && (
            <Accordion.Collapse eventKey={id}>
              <div className="pb-6 px-5">
                {isEditEventType && (
                  <FieldsChangedContainer
                    updatedFields={updated_fields}
                    newFields={new_fields}
                    originalFields={original_fields}
                    modelType={model_type}
                  />
                )}
                {isPublishEventType && (
                  <PublishInformationContainer
                    asanaTicketURL={asana_ticket_url}
                    blueprintName={blueprint_name}
                    publishDescription={publish_description}
                  />
                )}
              </div>
            </Accordion.Collapse>
          )}
        </td>
      </tr>
    </>
  );
};

export default ChangelogTableRow;
