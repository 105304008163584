import {
  ChangelogEventType,
  ChangelogEventTypeColorMap,
  ChangelogEventTypeTextMap,
} from "../../../utils/types";
import { Badge } from "@merge-api/merge-javascript-shared";

type EventCellProps = {
  eventType: ChangelogEventType;
};
const EventCell = ({ eventType }: EventCellProps) => {
  // const derived from state
  const color = ChangelogEventTypeColorMap[eventType];
  const text = ChangelogEventTypeTextMap[eventType];

  return (
    <td>
      <Badge size="md" color={color}>
        {text}
      </Badge>
    </td>
  );
};

export default EventCell;
