import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { RateLimitConfigurationIntegrationBuilder } from "../../utils/Entities";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import { fetchWithAuth } from "../../../../api-client/api_client";
import IntegrationBuilderContext from "../../context/IntegrationBuilderContext";
import { APIEndpointMethod } from "../../../../models/Entities";
import {
  navigateToIntegrationBuilderRateLimitConfigurationForm,
  navigateToIntegrationBuilderRateLimitConfigurationTable,
} from "../../../../router/RouterUtils";

interface CreateOrPatchRateLimitConfiguration {
  setRateLimitConfigurations: React.Dispatch<
    React.SetStateAction<RateLimitConfigurationIntegrationBuilder[] | undefined>
  >;
  integrationID: string;
  // used to determine if the rate limit configuration is being published or unpublished from the table page
  isPublishing?: boolean;
  // used to determine if the modal should be closed after the rate limit configuration is published/unpublished from the table page
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  // used for submission within the API endpoint form - prevents navigation and multiple toast messages from displaying
  isSubmittingAPIEndpoint?: boolean;
}

const useCreateOrPatchRateLimitConfiguration = ({
  setRateLimitConfigurations,
  integrationID,
  isPublishing,
  setIsModalOpen,
  isSubmittingAPIEndpoint = false,
}: CreateOrPatchRateLimitConfiguration) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  // context
  const history = useHistory();
  const { setIsRateLimitConfigurationComplete, resetDiffStates } = useContext(
    IntegrationBuilderContext
  );

  const createRateLimitConfiguration = (data: RateLimitConfigurationIntegrationBuilder) => {
    setIsLoadingSubmit(true);
    fetchWithAuth({
      path: `/integrations/${integrationID}/rate-limit-configs`,
      method: APIEndpointMethod.POST,
      body: data,
      onResponse: (responseData: RateLimitConfigurationIntegrationBuilder) => {
        // if we're in the API endpoint form, we don't want to navigate away
        if (!isSubmittingAPIEndpoint) {
          showSuccessToast("Rate limit configuration successfully created!");
          // Reset diff state before navigating, so that Unsaved modal doesn't pop up
          resetDiffStates();
          // Navigates to url for rate limit configuration with id
          if (responseData.id) {
            navigateToIntegrationBuilderRateLimitConfigurationForm(
              history,
              integrationID,
              responseData.id
            );
          } else {
            navigateToIntegrationBuilderRateLimitConfigurationTable(history, integrationID);
          }
          // add new rate limit configuration to ratelimitConfigurations array
          setRateLimitConfigurations((prevRateLimitConfigs) => {
            return prevRateLimitConfigs ? [...prevRateLimitConfigs, responseData] : [responseData];
          });
        }
        setIsLoadingSubmit(false);
        setIsRateLimitConfigurationComplete(true);
      },
      onError: () => {
        showErrorToast("Failed to create your rate limit configuration.");
        setIsLoadingSubmit(false);
      },
    });
  };

  const patchRateLimitConfiguration = (data: RateLimitConfigurationIntegrationBuilder) => {
    const requestData = isPublishing
      ? {
          ...data,
          is_active: !data.is_active,
        }
      : data;
    setIsLoadingSubmit(true);
    fetchWithAuth({
      path: `/integrations/${integrationID}/rate-limit-configs/${data.id}`,
      method: APIEndpointMethod.PATCH,
      body: requestData,
      onResponse: (responseData: RateLimitConfigurationIntegrationBuilder) => {
        if (!isSubmittingAPIEndpoint) {
          const successMessage = isPublishing
            ? responseData.is_active
              ? "Successfully published rate limit configuration"
              : "Successfully unpublished rate limit configuration"
            : "Rate limit configuration successfully updated";

          showSuccessToast(successMessage);
          if (setIsModalOpen) setIsModalOpen(false);
          // Reset diff state before navigating, so that Unsaved modal doesn't pop up
          if (!isPublishing) {
            resetDiffStates();
          }
          setRateLimitConfigurations((prevRateLimitConfigs) => {
            return prevRateLimitConfigs?.map((config) => {
              if (config.id === responseData.id) {
                const updatedResponseData = {
                  ...responseData,
                  api_endpoint_name: data.api_endpoint_name,
                };
                return updatedResponseData;
              }
              return config;
            });
          });
          if (!isPublishing) {
            // Navigate
            if (responseData.id) {
              navigateToIntegrationBuilderRateLimitConfigurationForm(
                history,
                integrationID,
                responseData.id
              );
            } else {
              navigateToIntegrationBuilderRateLimitConfigurationTable(history, integrationID);
            }
          }
        }
        setIsLoadingSubmit(false);
      },
      onError: () => {
        showErrorToast(
          isPublishing === true
            ? "Failed to change the publication state of the rate limit configuration"
            : "Failed to update your rate limit configuration"
        );
        setIsLoadingSubmit(false);
      },
    });
  };

  return { createRateLimitConfiguration, patchRateLimitConfiguration, isLoadingSubmit };
};

export default useCreateOrPatchRateLimitConfiguration;
