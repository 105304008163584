import {
  BlueprintParameterValue,
  BlueprintStep,
  BlueprintStepType,
  BlueprintVariableSteps,
  StepParameterTracingTarget,
} from "../../../models/Blueprints";

/**
 * Returns a list of variables that a step can alter
 * We'll only look at BlueprintVariableSteps - aka steps that set or modify a variable
 */
const getMappedVariablesForStep = (step: BlueprintStep): Set<string> | null => {
  if (BlueprintVariableSteps.includes(step.template.step_type as BlueprintStepType)) {
    let variables: Set<string> = new Set();
    // Process SET_VARIABLE steps
    if ((step.template.step_type as BlueprintStepType) == BlueprintStepType.SetVariable) {
      if (step.parameter_values.key?.value_type === "CONSTANT") {
        variables.add(step.parameter_values.key.constant);
      }
    }
    // Process other variable transformation steps
    else {
      Object.values(step.parameter_values).forEach((parameterValue) => {
        if (parameterValue?.value_type === "VARIABLE") {
          variables.add(parameterValue.key);
        }
      });
    }
    return variables;
  }
  return null;
};

interface stepsDataForTracing {
  flat_map_of_steps: Record<string, BlueprintStep>;
  map_of_variables_to_ordered_step_ids: Record<string, string[]>;
}

/**
 * Generates flattened map of steps from Blueprint's steps {step_id: step}
 * Helper function for other computation functions like traced steps
 * variable map is ordered to ensure only previous steps are processed in addToStepIDsStack
 */
const generateFlattenedMapOfStepsAndVariables = (steps: BlueprintStep[]): stepsDataForTracing => {
  let map: Record<string, BlueprintStep> = {};
  let variableOrderedMap: Record<string, string[]> = {};
  steps.forEach((step) => {
    // Store step in map
    map[step.id] = step;
    // Store & compute if step alters any variables
    const variables = getMappedVariablesForStep(step);
    if (variables) {
      variables.forEach((variable) => {
        if (!(variable in variableOrderedMap)) {
          variableOrderedMap[variable] = [step.id];
        } else {
          variableOrderedMap[variable].push(step.id);
        }
      });
    }
    if (step.paths) {
      Object.values(step.paths).forEach((pathSteps) => {
        const childData = generateFlattenedMapOfStepsAndVariables(pathSteps);
        // Add children step map
        map = { ...map, ...childData.flat_map_of_steps };
        // Add children variable maps
        Object.entries(childData.map_of_variables_to_ordered_step_ids).forEach(
          ([variable, step_ids]) => {
            if (!(variable in variableOrderedMap)) {
              variableOrderedMap[variable] = step_ids;
            } else {
              step_ids.forEach((stepID) => variableOrderedMap[variable].push(stepID));
            }
          }
        );
      });
    }
  });
  return {
    flat_map_of_steps: map,
    map_of_variables_to_ordered_step_ids: variableOrderedMap,
  };
};

interface mappedStepInputParameterData {
  step_ids: Set<string>;
  variables: Set<string>;
}

/**
 * Helper function to process insert child mappedStepInputParameterData into parent mappedStepInputParameterData
 */
const upsertMappedStepInputParameterData = (
  data: mappedStepInputParameterData,
  childData: mappedStepInputParameterData
): mappedStepInputParameterData => {
  childData.step_ids.forEach((stepID) => data.step_ids.add(stepID));
  childData.variables.forEach((variable) => data.variables.add(variable));
  return data;
};

/**
 * Helper function to process child parameter values for getMappedStepsForParameterValue
 */
const getMappedStepsForChildParameterValue = (
  data: mappedStepInputParameterData,
  value: BlueprintParameterValue
): mappedStepInputParameterData => {
  data = upsertMappedStepInputParameterData(data, getMappedStepsForParameterValue(value));
  return data;
};

/**
 * Helper function to adding to stack from step ids, for computeTracedStepIDsForBlueprint
 */
const addToStepIDsStackFromStepIDs = (
  stackStepIDs: string[],
  stepInputData: mappedStepInputParameterData,
  visitedStepIDs?: Set<string>
): string[] => {
  stepInputData.step_ids.forEach((stepID) => {
    if (!visitedStepIDs || !visitedStepIDs.has(stepID)) {
      stackStepIDs.push(stepID);
    }
  });
  return stackStepIDs;
};

/**
 * Helper function for adding to stack from variables, for computeTracedStepIDsForBlueprint
 */
const addToStepIDsStackFromVariables = (
  stackStepIDs: string[],
  stepInputData: mappedStepInputParameterData,
  mapOfVariablesToOrderedStepIDs: Record<string, string[]>,
  currentStepID: string,
  visitedStepIDs?: Set<string>
): string[] => {
  stepInputData.variables.forEach((variable) => {
    if (variable in mapOfVariablesToOrderedStepIDs) {
      for (const stepID of mapOfVariablesToOrderedStepIDs[variable]) {
        if (!visitedStepIDs || !visitedStepIDs.has(stepID)) {
          stackStepIDs.push(stepID);
        }
        // Break if we reach currentStepID, since we don't care about steps after it that touch variable
        if (stepID == currentStepID) {
          break;
        }
      }
    }
  });
  return stackStepIDs;
};

/**
 * Extracts the mapped step ids and variables for a given parameter value
 * Example: Employee.remote_id is mapped to "get_bamboo_hr_api.items.id" --> "step_ids" = {get_bamboo_hr_api,}
 */
const getMappedStepsForParameterValue = (
  value: BlueprintParameterValue,
  key?: string,
  step?: BlueprintStep
): mappedStepInputParameterData => {
  let data: mappedStepInputParameterData = { step_ids: new Set(), variables: new Set() };
  // Process parameter values that reference another step or variable
  if (value?.hasOwnProperty("value_type")) {
    switch (value["value_type"]) {
      case "RETURN_VALUE": {
        if (value?.hasOwnProperty("step_id")) {
          data.step_ids.add(value["step_id"]);
        }
        break;
      }
      // Consider edge case of "Set Variable" step's "key" parameter
      // While the "key" parameter is of type "CONSTANT", it's actually a reference to a "VARIABLE"
      case "CONSTANT": {
        const isSetVariableKeyParameter =
          key === "key" &&
          (step?.template.step_type as BlueprintStepType) === BlueprintStepType.SetVariable;
        if (isSetVariableKeyParameter && value?.hasOwnProperty("constant")) {
          data.variables.add(value["constant"]);
        }
        break;
      }
      case "VARIABLE": {
        if (value?.hasOwnProperty("key")) {
          data.variables.add(value["key"]);
        }
        break;
      }
      case "NESTED_PARAMETER_VALUES": {
        if (value?.hasOwnProperty("nested_parameter_values")) {
          Object.values(value["nested_parameter_values"]).forEach((childParameterValue) => {
            data = getMappedStepsForChildParameterValue(data, childParameterValue);
          });
        }
        break;
      }
      case "CUSTOM_OBJECT": {
        if (value?.hasOwnProperty("object_value")) {
          Object.values(value["object_value"]).forEach((childParameterValue) => {
            data = getMappedStepsForChildParameterValue(data, childParameterValue);
          });
        }
        break;
      }
      case "CUSTOM_ARRAY": {
        if (value?.hasOwnProperty("array_values")) {
          (value["array_values"] ?? []).forEach((childParameterValue) => {
            data = getMappedStepsForChildParameterValue(data, childParameterValue);
          });
        }
        break;
      }
      case "PROCEDURE_ARRAY": {
        if (value?.hasOwnProperty("procedure_array")) {
          (value["procedure_array"] ?? []).forEach((procedure) => {
            Object.values(procedure.parameter_values).forEach((childParameterValue) => {
              if (childParameterValue) {
                data = getMappedStepsForChildParameterValue(data, childParameterValue);
              }
            });
          });
        }
        break;
      }
      case "STATEMENT_ARRAY": {
        if (value.hasOwnProperty("statement_array")) {
          (value["statement_array"] ?? []).forEach((statement) => {
            if (statement.val1) {
              data = getMappedStepsForChildParameterValue(data, statement.val1);
            }
            if (statement.val2) {
              data = getMappedStepsForChildParameterValue(data, statement.val2);
            }
          });
        }
        break;
      }
      default:
        break;
    }
  }
  return data;
};

/**
 * Iniitalizes DFS stack for "computeTracedStepIDsForBlueprint()"
 * Return stack of step IDs to visit
 */
const initializeStack = (
  stepParameterToTrace: StepParameterTracingTarget,
  flatMapOfSteps: Record<string, BlueprintStep>,
  mapOfVariablesToOrderedStepIDs: Record<string, string[]>
) => {
  let stackStepIDs: string[] = [];

  const initialParameterValue: BlueprintParameterValue =
    flatMapOfSteps[stepParameterToTrace.step_id]["parameter_values"][
      stepParameterToTrace.parameter_value_key
    ];

  if (!initialParameterValue) return [];

  // Iniitalize the stack
  const initialStepInputData = getMappedStepsForParameterValue(
    initialParameterValue,
    stepParameterToTrace.parameter_value_key,
    flatMapOfSteps[stepParameterToTrace.step_id]
  );

  stackStepIDs = addToStepIDsStackFromStepIDs(stackStepIDs, initialStepInputData);
  stackStepIDs = addToStepIDsStackFromVariables(
    stackStepIDs,
    initialStepInputData,
    mapOfVariablesToOrderedStepIDs,
    stepParameterToTrace.step_id
  );

  // Double-check that we're not re-visiting the target step
  // Could happen for "Set Variable" steps
  stackStepIDs = stackStepIDs.filter((stepID) => stepID !== stepParameterToTrace.step_id);

  return stackStepIDs;
};

/**
 * Clean up tracedStepIDs to re-order based on Blueprint step ordering
 */
const cleanUpTracedStepIDs = (steps: BlueprintStep[], tracedStepIDSet: Set<string>): string[] => {
  let orderedTracedStepIDs: string[] = [];
  steps.forEach((step) => {
    if (tracedStepIDSet.has(step.id)) {
      orderedTracedStepIDs.push(step.id);
    }
    if (step.paths) {
      Object.values(step.paths).forEach((pathSteps) => {
        const childSteps = cleanUpTracedStepIDs(pathSteps, tracedStepIDSet);
        orderedTracedStepIDs = orderedTracedStepIDs.concat(childSteps);
      });
    }
  });
  return orderedTracedStepIDs;
};

/**
 * Computes traced steps for Blueprint, given a selected step's parameter key
 * It does this by first creating a dictionary of {step_id: step}
 * Then DFS up the dictionary, starting from the selected step's parameter key
 */
export const computeTracedStepIDsForBlueprint = (
  steps: BlueprintStep[],
  stepParameterToTrace: StepParameterTracingTarget
): string[] => {
  let tracedStepIDs: string[] = [stepParameterToTrace.step_id];

  // Initialize flattened map of steps
  const precomputedData = generateFlattenedMapOfStepsAndVariables(steps);
  const flatMapOfSteps = precomputedData.flat_map_of_steps;
  const mapOfVariablesToOrderedStepIDs = precomputedData.map_of_variables_to_ordered_step_ids;

  // DFS starting at the target
  let stackStepIDs = initializeStack(
    stepParameterToTrace,
    flatMapOfSteps,
    mapOfVariablesToOrderedStepIDs
  );
  let visitedStepIDs: Set<string> = new Set(); // To prevent unnecessary duplicate visits

  // Run DFS to collect step_ids and variables
  while (stackStepIDs.length > 0) {
    const currentStepID = stackStepIDs.pop();
    if (currentStepID && !visitedStepIDs.has(currentStepID)) {
      visitedStepIDs.add(currentStepID);
      tracedStepIDs.push(currentStepID);
      const step = currentStepID in flatMapOfSteps ? flatMapOfSteps[currentStepID] : null;
      if (step) {
        // Find all other steps that map to this step's inputs!
        let stepInputData: mappedStepInputParameterData = {
          step_ids: new Set(),
          variables: new Set(),
        };
        Object.entries(step.parameter_values).forEach(([parameterKey, parameterValue]) => {
          stepInputData = upsertMappedStepInputParameterData(
            stepInputData,
            getMappedStepsForParameterValue(parameterValue, parameterKey, step)
          );
        });
        // Add newly found step ids and variables to the stacks
        stackStepIDs = addToStepIDsStackFromStepIDs(stackStepIDs, stepInputData, visitedStepIDs);
        stackStepIDs = addToStepIDsStackFromVariables(
          stackStepIDs,
          stepInputData,
          mapOfVariablesToOrderedStepIDs,
          currentStepID,
          visitedStepIDs
        );
      }
    }
  }

  // Re-order based on Blueprint step ordering, and remove duplicates
  const tracedStepIDSet: Set<string> = new Set(tracedStepIDs);
  tracedStepIDs = cleanUpTracedStepIDs(steps, tracedStepIDSet);

  return tracedStepIDs;
};
