import SelectHeader from "../../../shared/SelectHeader";
import {
  AuthConfigIntegrationBuilder,
  LinkingFlowStepIntegrationBuilder,
  LinkingFlowStepPostActionTypeEnums,
  LinkingFlowStepTypeEnums,
} from "../../../utils/Entities";
import PostmanTableHeader from "../../../authentication/components/shared/PostmanTableHeader";
import { useEffect, useState } from "react";
import { Row } from "../../../shared/postman-table/PostmanTableRow";
import {
  convertAdditionalAuthNamesToRows,
  convertAdditionalAuthRowsToNames,
} from "../../utils/helpers";
import AccordionCard from "../../../shared/AccordionCard";
import { Alert, Link, Text } from "@merge-api/merge-javascript-shared";
import { navigateToIntegrationBuilderAuthConfigPostAuthConfigCreation } from "../../../../../router/RouterUtils";
import { useHistory } from "react-router-dom";

interface Props {
  index: number;
  authConfigs: AuthConfigIntegrationBuilder[] | undefined;
  integrationID: string | undefined;
  requestedStepPathAuthConfigID: string;
  requestedStep: LinkingFlowStepIntegrationBuilder;
  onCallbackUpdateStep: (index: number, keyValuePairs: Record<string, any>) => void;
}

const AdvancedConfigurationFields = ({
  index,
  integrationID,
  authConfigs,
  requestedStepPathAuthConfigID,
  requestedStep,
  onCallbackUpdateStep,
}: Props) => {
  const history = useHistory();

  const shouldShowAdditionalAuthDisplayFields =
    requestedStep.step_type === LinkingFlowStepTypeEnums.STEP_TYPE_ADDITIONAL_AUTH;
  const shouldComputeAdditionalAuthDisplayFields =
    shouldShowAdditionalAuthDisplayFields && authConfigs && requestedStepPathAuthConfigID;

  const [additionalAuthDisplayNameRows, setAdditionalAuthDisplayNameRows] = useState<Row[]>([]);

  // Initialize state
  useEffect(() => {
    if (shouldComputeAdditionalAuthDisplayFields) {
      setAdditionalAuthDisplayNameRows(
        convertAdditionalAuthNamesToRows(authConfigs, requestedStepPathAuthConfigID, requestedStep)
      );
    }
  }, [authConfigs, requestedStepPathAuthConfigID, shouldShowAdditionalAuthDisplayFields]);

  // Update request body for additional_auth_display_names
  useEffect(() => {
    // These "if" conditions are required to prevent incorrectly setting "{}" on initial render
    if (shouldComputeAdditionalAuthDisplayFields) {
      onCallbackUpdateStep(index, {
        additional_auth_display_names: convertAdditionalAuthRowsToNames(
          additionalAuthDisplayNameRows
        ),
      });
    }
  }, [additionalAuthDisplayNameRows]);

  return (
    <AccordionCard
      dataTestID={`accordion-step-card-advanced-${index}`}
      title="Advanced"
      badgeContents={
        shouldShowAdditionalAuthDisplayFields && additionalAuthDisplayNameRows.length > 0 ? 2 : 1
      }
      chevronSize={16}
      chevronOrientation="right"
      variant="outline"
      titleVariant="h6"
      onChange={() => {}}
    >
      <div className="space-y-6">
        {shouldShowAdditionalAuthDisplayFields && additionalAuthDisplayNameRows.length > 0 && (
          <PostmanTableHeader
            dataTestID={`field-step-card-additional-auth-display-names-${index}`}
            title="Additional auth display names"
            subtitle="If provided, will override the display names for the provided additional auth fields."
            rows={additionalAuthDisplayNameRows}
            setRows={setAdditionalAuthDisplayNameRows}
            keysDisabled={true}
            userInputDisabled={true}
          />
        )}
        {shouldShowAdditionalAuthDisplayFields && additionalAuthDisplayNameRows.length === 0 && (
          <Alert showWarningIcon>
            <Text>
              No additional auth keys found for this Authentication Configuration. Add keys to the
              Authentication Configuration{" "}
              <Link
                onClick={() =>
                  integrationID
                    ? navigateToIntegrationBuilderAuthConfigPostAuthConfigCreation(
                        history,
                        integrationID,
                        requestedStepPathAuthConfigID
                      )
                    : {}
                }
              >
                here
              </Link>
              !
            </Text>
          </Alert>
        )}
        <SelectHeader
          dataTestID={`field-step-card-step-post-action-type-${index}`}
          title="Step post action type"
          subtitle="Select an action to run upon step completion"
          options={Object.values(LinkingFlowStepPostActionTypeEnums)}
          value={requestedStep.step_post_action_type}
          onChange={(_: any, selectedOption: LinkingFlowStepPostActionTypeEnums | null) =>
            onCallbackUpdateStep(index, { step_post_action_type: selectedOption })
          }
        />
      </div>
    </AccordionCard>
  );
};

export default AdvancedConfigurationFields;
