import { useHistory } from "react-router-dom";
import { WebAddressSchemaIntegrationBuilder } from "../../utils/Entities";
import { getIntegrationBuilderSingleWebAddressSchemaPathForId } from "../../../../router/RouterUtils";
import URLCell from "./URLCell";
import DescriptionCell from "./DescriptionCell";

type WebAddressSchemaTableRowProps = {
  webAddressSchema: WebAddressSchemaIntegrationBuilder;
  integrationID: string;
};

const AuthenticationConfigurationTableRow = ({
  webAddressSchema,
  integrationID,
}: WebAddressSchemaTableRowProps) => {
  // hooks
  const history = useHistory();

  // event handlers
  const onWebAddresSchemaClick = () => {
    history.push({
      pathname: getIntegrationBuilderSingleWebAddressSchemaPathForId(
        integrationID,
        webAddressSchema.id
      ),
    });
  };

  return (
    <tr onClick={onWebAddresSchemaClick} className="cursor-pointer">
      <URLCell webAddressSchema={webAddressSchema} />
      <DescriptionCell description={webAddressSchema?.description} />
    </tr>
  );
};

export default AuthenticationConfigurationTableRow;
