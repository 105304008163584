import {
  RateLimitConfigurationIntegrationBuilder,
  RateLimitType,
} from "../../../../utils/Entities";
import {
  rateLimitTimeValueTabletDisplayStrings,
  rateLimitTypeRequestDisplayStrings,
} from "../../../utils/constants";
import { Text } from "@merge-api/merge-javascript-shared";

interface RateLimitValueCellProps {
  rateLimitConfiguration: RateLimitConfigurationIntegrationBuilder;
  onClick: React.MouseEventHandler<HTMLTableCellElement> | undefined;
}

const RateLimitValueCell = ({ rateLimitConfiguration, onClick }: RateLimitValueCellProps) => {
  const requestDisplayString =
    rateLimitTypeRequestDisplayStrings[rateLimitConfiguration.rate_limit_type];
  const timeDisplayString =
    rateLimitTimeValueTabletDisplayStrings[rateLimitConfiguration.rate_limit_time_period];
  const countDisplay =
    rateLimitConfiguration.rate_limit_type !== RateLimitType.COMPLEXITY_COUNT
      ? rateLimitConfiguration?.default_rate_limit_max_count
      : rateLimitConfiguration?.default_rate_limit_max_points;

  return (
    <td onClick={onClick}>
      <div className="inline">
        <Text as="span" className="text-gray-90 font-medium">
          {countDisplay}{" "}
        </Text>
        <Text as="span" className="text-gray-60">
          {requestDisplayString}
        </Text>
        {rateLimitConfiguration?.rate_limit_type !== RateLimitType.CONNECTION_COUNT && (
          <>
            <Text as="span" className="text-gray-60">
              {" / "}
            </Text>
            <Text as="span" className="text-gray-90 font-medium">
              {rateLimitConfiguration?.rate_limit_time_value}{" "}
            </Text>
            <Text as="span" className="text-gray-60">
              {timeDisplayString}
            </Text>
          </>
        )}
      </div>
    </td>
  );
};
export default RateLimitValueCell;
