import { useEffect, useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { TestLinkTokenResponseData } from "../../utils/Entities";

const useLoadLinkToken = () => {
  const [linkToken, setLinkToken] = useState<string | undefined>();

  function fetchLinkToken() {
    fetchWithAuth({
      path: `/integrations/create-test-link-token`,
      method: "POST",
      onResponse: (data: TestLinkTokenResponseData) => {
        setLinkToken(data.link_token);
      },
      onError: () => {
        setLinkToken(undefined);
      },
    });
  }

  useEffect(() => {
    fetchLinkToken();
  }, [setLinkToken]);

  return { linkToken, fetchLinkToken };
};

export default useLoadLinkToken;
