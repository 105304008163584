import { useReducer, useCallback, Reducer } from "react";
import { Blueprint, JSONSchemaValue } from "../../models/Blueprints";
import { JSONSchemaTraversalPath } from "./validator/BlueprintValidationEditorUtils";
import * as immutable from "object-path-immutable";

type BlueprintReducerActions = {
  set: (newPresent: Blueprint) => void;
  setBlueprintParameterSchemaValue: (
    fieldTraversalPath: JSONSchemaTraversalPath,
    fieldValue: JSONSchemaValue | undefined
  ) => void;
  setBlueprintReturnSchemaValue: (
    fieldTraversalPath: JSONSchemaTraversalPath,
    fieldValue: JSONSchemaValue | undefined
  ) => void;
  setRequiredFieldsForObject: (
    objectSchemaTraversalPath: JSONSchemaTraversalPath,
    requiredFields: Array<string>
  ) => void;
  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;
};

enum BlueprintReducerActionType {
  UNDO = "UNDO",
  REDO = "REDO",
  SET_BLUEPRINT = "SET_BLUEPRINT",
  SET_BLUEPRINT_PARAMETER_SCHEMA_VALUE = "SET_BLUEPRINT_PARAMETER_SCHEMA_VALUE",
  SET_BLUEPRINT_RETURN_SCHEMA_VALUE = "SET_BLUEPRINT_RETURN_SCHEMA_VALUE",
  SET_REQUIRED_FIELDS_FOR_OBJECT = "SET_REQUIRED_FIELDS_FOR_OBJECT",
}

type State = {
  past: Array<Blueprint | undefined>;
  present: Blueprint | undefined;
  future: Array<Blueprint | undefined>;
};

interface ActionBase<T extends BlueprintReducerActionType> {
  type: T;
}

interface SetBlueprintParameterSchemaValueAction
  extends ActionBase<BlueprintReducerActionType.SET_BLUEPRINT_PARAMETER_SCHEMA_VALUE> {
  fieldValue: JSONSchemaValue | undefined;
  fieldTraversalPath: JSONSchemaTraversalPath;
}
interface SetBlueprintReturnSchemaValueAction
  extends ActionBase<BlueprintReducerActionType.SET_BLUEPRINT_RETURN_SCHEMA_VALUE> {
  fieldValue: JSONSchemaValue | undefined;
  fieldTraversalPath: JSONSchemaTraversalPath;
}

interface SetRequiredFieldsForObjectAction
  extends ActionBase<BlueprintReducerActionType.SET_REQUIRED_FIELDS_FOR_OBJECT> {
  objectSchemaTraversalPath: JSONSchemaTraversalPath;
  requiredFields: Array<string>;
}

interface UndoAction extends ActionBase<BlueprintReducerActionType.UNDO> {}

interface RedoAction extends ActionBase<BlueprintReducerActionType.REDO> {}

interface SetBlueprintAction extends ActionBase<BlueprintReducerActionType.SET_BLUEPRINT> {
  newPresent: Blueprint;
}

type Action =
  | SetBlueprintParameterSchemaValueAction
  | SetBlueprintReturnSchemaValueAction
  | UndoAction
  | RedoAction
  | SetBlueprintAction
  | SetRequiredFieldsForObjectAction;

type ActionAndDispatch<T extends Action> = T & {
  dispatch?: (props: T) => void;
};

const reducer = (state: State, action: ActionAndDispatch<Action>): State => {
  const { past, present, future } = state;

  const updateForNewPresent = (newPresent: Blueprint) => {
    if (newPresent === present) {
      return state;
    }
    if (present === undefined) {
      return { ...state, past: [], present: newPresent, future: [] };
    }
    return {
      ...state,
      past: [...past, present],
      present: newPresent,
      future: [],
    };
  };

  switch (action.type) {
    case BlueprintReducerActionType.UNDO: {
      const previous = past[past.length - 1];
      const newPast = past.slice(0, past.length - 1);

      return {
        past: newPast,
        present: previous,
        future: [present, ...future],
      };
    }

    case BlueprintReducerActionType.REDO: {
      const next = future[0];
      const newFuture = future.slice(1);

      return {
        past: [...past, present],
        present: next,
        future: newFuture,
      };
    }

    case BlueprintReducerActionType.SET_BLUEPRINT: {
      const { newPresent } = action;

      return updateForNewPresent(newPresent);
    }

    case BlueprintReducerActionType.SET_BLUEPRINT_PARAMETER_SCHEMA_VALUE: {
      const { fieldTraversalPath, fieldValue } = action;
      if (!present) {
        throw Error("Setting parameter schema requires non-null blueprint.");
      }
      let newSchema;

      if (fieldValue) {
        newSchema = immutable.set(present.parameter_schema, fieldTraversalPath, fieldValue);
      } else {
        newSchema = immutable.del(present.parameter_schema, fieldTraversalPath);
      }

      const newPresent: Blueprint = { ...present, parameter_schema: newSchema };

      return updateForNewPresent(newPresent);
    }

    case BlueprintReducerActionType.SET_BLUEPRINT_RETURN_SCHEMA_VALUE: {
      const { fieldTraversalPath, fieldValue } = action;
      if (!present) {
        throw Error("Setting return schema requires non-null blueprint.");
      }
      let newSchema;

      if (fieldValue) {
        newSchema = immutable.set(present.return_schema, fieldTraversalPath, fieldValue);
      } else {
        newSchema = immutable.del(present.return_schema, fieldTraversalPath);
      }

      const newPresent: Blueprint = { ...present, return_schema: newSchema };

      return updateForNewPresent(newPresent);
    }

    case BlueprintReducerActionType.SET_REQUIRED_FIELDS_FOR_OBJECT: {
      const { objectSchemaTraversalPath, requiredFields } = action;
      if (!present) {
        throw Error("Setting parameter schema requires non-null blueprint.");
      }
      if (immutable.get(present.parameter_schema, objectSchemaTraversalPath)?.type !== "object") {
        throw Error("objectSchemaTraversalPath must point to object in parameter_schema");
      }
      const newSchema = immutable.set(
        present.parameter_schema,
        [...objectSchemaTraversalPath, "required"],
        requiredFields
      );

      const newPresent: Blueprint = { ...present, parameter_schema: newSchema };

      return updateForNewPresent(newPresent);
    }
  }
};

const useBlueprintReducer = (
  initialPresent: Blueprint | undefined
): [Blueprint | undefined, BlueprintReducerActions] => {
  const [state, dispatch] = useReducer<Reducer<State, ActionAndDispatch<Action>>>(
    reducer,
    {
      past: [],
      future: [],
      present: initialPresent,
    },
    undefined
  );

  const canUndo = state.past.length !== 0;
  const canRedo = state.future.length !== 0;
  const undo = useCallback(() => {
    if (canUndo) {
      dispatch({ type: BlueprintReducerActionType.UNDO });
    }
  }, [canUndo]);
  const redo = useCallback(() => {
    if (canRedo) {
      dispatch({ type: BlueprintReducerActionType.REDO });
    }
  }, [canRedo]);
  const set = useCallback(
    (newPresent) => dispatch({ type: BlueprintReducerActionType.SET_BLUEPRINT, newPresent }),
    []
  );
  const setBlueprintParameterSchemaValue = useCallback(
    (fieldTraversalPath, fieldValue) =>
      dispatch({
        type: BlueprintReducerActionType.SET_BLUEPRINT_PARAMETER_SCHEMA_VALUE,
        fieldTraversalPath,
        fieldValue,
      }),
    []
  );

  const setBlueprintReturnSchemaValue = useCallback(
    (fieldTraversalPath, fieldValue) =>
      dispatch({
        type: BlueprintReducerActionType.SET_BLUEPRINT_RETURN_SCHEMA_VALUE,
        fieldTraversalPath,
        fieldValue,
      }),
    []
  );

  const setRequiredFieldsForObject = useCallback(
    (objectSchemaTraversalPath, requiredFields) =>
      dispatch({
        type: BlueprintReducerActionType.SET_REQUIRED_FIELDS_FOR_OBJECT,
        objectSchemaTraversalPath,
        requiredFields,
      }),
    []
  );

  return [
    state.present,
    {
      set,
      undo,
      redo,
      canUndo,
      canRedo,
      setBlueprintParameterSchemaValue,
      setBlueprintReturnSchemaValue,
      setRequiredFieldsForObject,
    },
  ];
};

export default useBlueprintReducer;
