import { Card, Text } from "@merge-api/merge-javascript-shared";
import { BlueprintValidatorResult, PublishModuleInfo } from "../types";
import PublishModuleValidatorContextProvider from "../../context/PublishModuleValidatorContextProvider";
import PublishModuleValidatorStagedBlueprint from "./PublishModuleValidatorStagedBlueprint";

const PublishModuleValidatorResultsContainer = ({
  blueprintValidatorInfo,
  integrationID,
  publishModuleInfo,
  isRunningValidator,
}: {
  integrationID: string;
  blueprintValidatorInfo: Record<string, BlueprintValidatorResult>;
  publishModuleInfo: PublishModuleInfo | undefined;
  isRunningValidator: boolean;
}) => {
  const stagedBlueprintChanges = publishModuleInfo?.staged_changes?.Blueprint;

  return (
    <PublishModuleValidatorContextProvider
      blueprintValidatorInfo={blueprintValidatorInfo}
      integrationID={integrationID}
      publishModuleInfo={publishModuleInfo}
    >
      <div>
        {stagedBlueprintChanges ? (
          <div>
            {stagedBlueprintChanges.map((stagedBlueprint) => (
              <PublishModuleValidatorStagedBlueprint
                stagedBlueprint={stagedBlueprint}
                stagedBlueprintValidatorInfo={
                  blueprintValidatorInfo[stagedBlueprint.component_version_id]
                }
                integrationID={integrationID}
                isRunningValidator={isRunningValidator}
              />
            ))}
          </div>
        ) : (
          <Card className="m-4 mt-0 p-4 pt-2" variant="none">
            <Text className="text-gray-50">No staged Blueprints</Text>
          </Card>
        )}
      </div>
    </PublishModuleValidatorContextProvider>
  );
};

export default PublishModuleValidatorResultsContainer;
