import MergeModal from "../../shared/MergeModal";
import { Button, Col, Row } from "react-bootstrap";

type Props = {
  show?: boolean;
  title: string;
  bodyText: string;
  confirmText: string;
  rejectText: string;
  onHide: () => void;
  onConfirm: () => void;
};

export default function MappingTestChangesModal({
  show,
  title,
  bodyText,
  confirmText,
  rejectText,
  onHide,
  onConfirm,
}: Props) {
  return (
    <>
      <MergeModal
        show={show ?? false}
        onHide={onHide}
        title={title}
        bodyClassName="overflow-hidden"
      >
        <>
          <Row>
            <Col>
              <b>{bodyText}</b>
            </Col>
          </Row>
          <Row className="mt-6">
            <Col>
              <Button
                className="btn-block"
                variant="danger"
                onClick={() => {
                  onHide();
                  onConfirm();
                }}
              >
                {confirmText}
              </Button>
            </Col>
            <Col>
              <Button className="btn-block" variant="outline-primary" onClick={() => onHide()}>
                {rejectText}
              </Button>
            </Col>
          </Row>
        </>
      </MergeModal>
    </>
  );
}
