import TypeaheadFormField from "./TypeaheadFormField";

const BlueprintEditorRightPanelAddLinkedAccountAdditionalAuthForm = () => {
  return (
    <form>
      <TypeaheadFormField
        title="Field Name"
        subtitle="Key to use as field name in additional auth fields map for linked account."
        valueKey="field_name"
        parameterType={"string"}
      />
      <TypeaheadFormField
        title="Field Value"
        subtitle="String value to store in additional auth fields map for linked account."
        valueKey="field_value"
        parameterType={"string"}
      />
    </form>
  );
};

export default BlueprintEditorRightPanelAddLinkedAccountAdditionalAuthForm;
