import React, { Suspense, useState } from "react";
import { Button } from "react-bootstrap";

import useScraperContext from "./context/useScraperContext";
import { ScraperCustomFunctionStep } from "./types";
import ScraperNestedParameterValueField from "./ScraperNestedParameterValueField";
import ScraperTextField from "./ScraperTextField";

// TODO: make custom function modal in shared location,
// and make custom function shared form component for both scrapers and blueprints.
// https://app.asana.com/0/1201675852071199/1202076934098742/f
const LazyCodeBlock = React.lazy(
  () => import("../blueprint-editor/right-panel/custom-function/CustomFunctionsModal")
);

const ScraperCustomFunctionFields = () => {
  const { selectedStep, updateStepParameterValue } = useScraperContext();
  const step = selectedStep as ScraperCustomFunctionStep;
  const args = step.parameter_values.arguments.nested_parameter_values;

  const [isShowingCustomFunctionsModal, setIsShowingCustomFunctionsModal] = useState<boolean>(
    false
  );

  return (
    <div>
      <Suspense fallback={<div />}>
        <LazyCodeBlock
          isShowingCustomFunctionsModal={isShowingCustomFunctionsModal}
          setIsShowingCustomFunctionsModal={setIsShowingCustomFunctionsModal}
          namespace={"scrapers"}
        />
      </Suspense>

      <ScraperNestedParameterValueField
        value={step.parameter_values.arguments}
        valueKey={"arguments"}
        updateValue={(newValue) => updateStepParameterValue(step, "arguments", newValue)}
      />

      <ScraperTextField
        key={step.id}
        value={step.parameter_values.code}
        valueKey={"code"}
        updateValue={(newValue) => updateStepParameterValue(step, "code", newValue)}
        description={
          <>
            Only Python will work for custom functions. <br />
            <br />
            {"def custom_function(" + Object.keys(args).join(",") + "): \n"}
          </>
        }
      />
      <Button
        variant="white"
        size="sm"
        className="btn-block mt-9"
        onClick={() => setIsShowingCustomFunctionsModal(true)}
      >
        View Custom Functions <span className="fe fe-maximize-2 ml-1.5" />
      </Button>
    </div>
  );
};

export default ScraperCustomFunctionFields;
