import { useMemo } from "react";
import { BlueprintStepTemplate } from "../../../models/Blueprints";
import TypeaheadFormField from "./TypeaheadFormField";
import { HeaderPretitle } from "../../shared/text/MergeText";

interface Props {
  stepID: string;
  stepTemplate: BlueprintStepTemplate;
}

const BlueprintEditorRightPanelCallFunctionalBPForm = ({ stepID, stepTemplate }: Props) => {
  const [parameterFields] = useMemo(() => {
    return [Object.fromEntries(Object.entries(stepTemplate.parameter_schema ?? {}))];
  }, [stepID, stepTemplate]);
  return (
    <>
      <form>
        <HeaderPretitle className="my-2">Parameters</HeaderPretitle>
        {Object.entries(parameterFields ?? {})
          .sort()
          .map(([key, parameter]) => {
            return (
              <TypeaheadFormField
                key={key}
                title={key}
                valueKey={key}
                parameterType={parameter.type}
              />
            );
          })}
      </form>
    </>
  );
};

export default BlueprintEditorRightPanelCallFunctionalBPForm;
