import { useContext, useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import { useHistory } from "react-router-dom";
import { APIEndpointMethod } from "../../../../models/Entities";
import { navigateToIntegrationBuilderAuthConfigTable } from "../../../../router/RouterUtils";
import { AuthConfigIntegrationBuilder } from "../../utils/Entities";
import IntegrationBuilderContext from "../../context/IntegrationBuilderContext";

interface DeleteWebAddressSchemaProps {
  integrationID: string;
  setAuthConfigs: React.Dispatch<React.SetStateAction<AuthConfigIntegrationBuilder[] | undefined>>;
  authConfigID?: string;
}

const useDeleteAuthenticationConfiguration = (params: DeleteWebAddressSchemaProps) => {
  // state
  const [isLoadingAuthConfigDeletion, setLoadingAuthConfigDeletion] = useState(false);
  // context
  const history = useHistory();
  const { setIsAuthenticationConfigurationComplete } = useContext(IntegrationBuilderContext);
  // effects
  const deleteAuthenticationConfiguration = () => {
    setLoadingAuthConfigDeletion(true);
    fetchWithAuth({
      path: `/integrations/${params.integrationID}/auth-configs/${params.authConfigID}`,
      method: APIEndpointMethod.DELETE,
      onResponse: () => {
        showSuccessToast("Authentication configuration successfully deleted!");
        setLoadingAuthConfigDeletion(false);
        // navigate to auth config table page
        navigateToIntegrationBuilderAuthConfigTable(history, params.integrationID);
        // deletes auth config in authConfigs array
        params.setAuthConfigs((prevAuthConfigs) => {
          const updatedConfigs = prevAuthConfigs?.filter(
            (config) => config.id !== params.authConfigID
          );
          if (updatedConfigs?.length === 0) {
            setIsAuthenticationConfigurationComplete(false);
          }
          return updatedConfigs;
        });
      },
      onError: () => {
        showErrorToast("Failed to delete your authentication configuration.");
        setLoadingAuthConfigDeletion(false);
      },
    });
  };

  return { deleteAuthenticationConfiguration, isLoadingAuthConfigDeletion };
};

export default useDeleteAuthenticationConfiguration;
