import { APICategory, Card } from "@merge-api/merge-javascript-shared";
import IntegrationCategorySelection from "./IntegrationCategorySelection";
import { useEffect, useState } from "react";
import useIntegrationBuilderContext from "../../context/useIntegrationBuilderContext";
import useCreateOrPatchIntegration from "../../hooks/useCreateOrPatchIntegration";
import { IntegrationForIntegrationBuilder } from "../../utils/Entities";
import TextFieldHeader from "../../shared/TextFieldHeader";
import EmptyStateWrapper from "../../../shared/layout/EmptyStateWrapper";
import IntegrationCreationAdvanced from "./IntegrationCreationAdvanced";
import HeaderBase from "../../shared/HeaderBase";
import { DiffModelTypeEnum } from "../../../../models/DiffModels";
import EditorLeavingGuard from "../../../shared/unsaved-changes/EditorLeavingGuard";
import { BOOLEAN_OPTIONS_DATA } from "../../api-endpoint-setup/constants";

interface IntegrationCreationProps {
  integrationID: string | undefined;
}

function does_integration_use_scrapers(
  extra_scraper_headers: string | null | undefined,
  should_use_scraper_proxy: boolean | undefined
): boolean {
  return !!extra_scraper_headers || !!should_use_scraper_proxy;
}

const IntegrationCreation = ({ integrationID }: IntegrationCreationProps) => {
  // context
  const {
    setOnSubmit,
    setCanSubmit,
    integration,
    isLoading,
    setCurrentStateForDiff,
    setNewStateForDiff,
    computeHasUnsavedChanges,
  } = useIntegrationBuilderContext();

  const [requestBodyForIntegration, setRequestBodyForIntegration] = useState<
    IntegrationForIntegrationBuilder | undefined
  >();

  // Set states for yes/no options
  const [isSoapIntegration, setIsSoapIntegration] = useState<boolean>(false);
  const [isMultiSubsidiary, setIsMultiSubsidiary] = useState<boolean>(false);
  const [isUseScrapers, setIsUseScrapers] = useState<boolean>(false);
  const [isScraperProxy, setIsScraperProxy] = useState<boolean>(false);

  // Create boolean options
  const isSoapIntegrationOptions = BOOLEAN_OPTIONS_DATA.map(({ value, text }) => ({
    value,
    text,
    selected: value === isSoapIntegration,
    onClick: () => setIsSoapIntegration(value),
  }));
  const isMultiSubsidiaryOptions = BOOLEAN_OPTIONS_DATA.map(({ value, text }) => ({
    value,
    text,
    selected: value === isMultiSubsidiary,
    onClick: () => setIsMultiSubsidiary(value),
  }));
  const isUseScrapersOptions = BOOLEAN_OPTIONS_DATA.map(({ value, text }) => ({
    value,
    text,
    selected: value === isUseScrapers,
    onClick: () => setIsUseScrapers(value),
  }));
  const isScraperProxyOptions = BOOLEAN_OPTIONS_DATA.map(({ value, text }) => ({
    value,
    text,
    selected: value === isScraperProxy,
    onClick: () => setIsScraperProxy(value),
  }));

  // Update request body
  useEffect(() => {
    setRequestBodyForIntegration((body) => {
      return { ...body, is_soap_integration: isSoapIntegration };
    });
  }, [isSoapIntegration]);
  useEffect(() => {
    setRequestBodyForIntegration((body) => {
      return { ...body, is_multi_subsidiary: isMultiSubsidiary };
    });
  }, [isMultiSubsidiary]);
  useEffect(() => {
    if (!isUseScrapers) {
      setIsScraperProxy(false);
      setRequestBodyForIntegration((body) => {
        return {
          ...body,
          should_use_scraper_proxy: false,
          extra_scraper_headers: null,
        };
      });
    }
  }, [isUseScrapers]);
  useEffect(() => {
    setRequestBodyForIntegration((body) => {
      return { ...body, should_use_scraper_proxy: isScraperProxy };
    });
  }, [isScraperProxy]);

  const {
    createIntegration,
    isLoadingCreateOrPatchIntegration,
    patchIntegration,
  } = useCreateOrPatchIntegration({ requestBodyForIntegration });

  // derived constants from state
  const INTEGRATION_CREATE_SUBMIT_TEXT = integrationID ? "Save integration" : "Create integration";

  // sets submitText, canSubmit, isLoadingSubmit, onSubmit
  useIntegrationBuilderContext({
    submitButtonText: INTEGRATION_CREATE_SUBMIT_TEXT,
    isLoadingSubmit: isLoadingCreateOrPatchIntegration,
    shouldRenderSubmitButton: true,
    shouldRenderNavigationButtons: true,
    shouldRenderDeleteButton: false,
    modelTypeForDiff: DiffModelTypeEnum.INTEGRATION_INITIALIZATION,
    shouldHideDiffModal: false,
    markForDeletion: false,
    shouldRenderStageButton: false,
  });

  // initialize request body
  // When adding/removing fields, you must update helpers-integration-initialization-diff.ts
  // Otherwise diff modal will display outdated fields
  useEffect(() => {
    let requestBody: IntegrationForIntegrationBuilder = {
      name: undefined,
      categories: undefined,
      is_soap_integration: false,
      is_multi_subsidiary: false,
      should_use_scraper_proxy: false,
      extra_scraper_headers: undefined,
      abbreviated_name: undefined,
    };
    let requestBodyWithHelperFields = {
      ...requestBody,
      does_integration_use_scrapers: false,
    };
    if (integration) {
      requestBody = {
        name: integration.name,
        categories: integration.categories,
        is_soap_integration: integration.is_soap_integration,
        is_multi_subsidiary: integration.is_multi_subsidiary,
        should_use_scraper_proxy: integration.should_use_scraper_proxy,
        extra_scraper_headers:
          integration.extra_scraper_headers && integration.extra_scraper_headers.length > 0
            ? integration.extra_scraper_headers
            : null,
        abbreviated_name: integration.abbreviated_name,
      };
      requestBodyWithHelperFields = {
        ...requestBody,
        does_integration_use_scrapers: does_integration_use_scrapers(
          integration?.extra_scraper_headers,
          integration?.should_use_scraper_proxy
        ),
      };
    }
    setRequestBodyForIntegration(requestBody);
    setIsSoapIntegration(requestBody?.is_soap_integration ?? false);
    setIsMultiSubsidiary(requestBody?.is_multi_subsidiary ?? false);
    setIsUseScrapers(
      !!requestBody?.extra_scraper_headers || !!requestBody?.should_use_scraper_proxy
    );
    setIsScraperProxy(requestBody?.should_use_scraper_proxy ?? false);
    setCurrentStateForDiff(requestBodyWithHelperFields);
    setNewStateForDiff(requestBodyWithHelperFields);
  }, [integrationID, integration]);

  // set submit button state based on current values
  // When you add/delete fields from this form, you must update the related diff helper
  // Otherwise diff modal will display outdated fields
  useEffect(() => {
    setOnSubmit(() => (integrationID ? patchIntegration(integrationID) : createIntegration()));
    setCanSubmit(
      !isLoadingCreateOrPatchIntegration &&
        !!requestBodyForIntegration?.name &&
        !!requestBodyForIntegration?.categories &&
        requestBodyForIntegration?.categories?.length !== 0
    );
    setNewStateForDiff({
      ...requestBodyForIntegration,
      does_integration_use_scrapers: does_integration_use_scrapers(
        integration?.extra_scraper_headers,
        integration?.should_use_scraper_proxy
      ),
    });
  }, [integrationID, requestBodyForIntegration]);

  // Clear out any advanced fields if accounting nor hris selected
  useEffect(() => {
    if (requestBodyForIntegration) {
      if (!requestBodyForIntegration.categories?.includes(APICategory.accounting)) {
        setRequestBodyForIntegration((body) => {
          return {
            ...body,
            is_multi_subsidiary: false,
          };
        });
      }
      if (!requestBodyForIntegration.categories?.includes(APICategory.hris)) {
        setRequestBodyForIntegration((body) => {
          return {
            ...body,
            should_use_scraper_proxy: false,
            extra_scraper_headers: null,
          };
        });
      }
    }
  }, [requestBodyForIntegration?.categories]);

  return (
    <EditorLeavingGuard computeHasUnsavedChanges={computeHasUnsavedChanges}>
      {integrationID && isLoading ? (
        <EmptyStateWrapper isSpinner />
      ) : (
        <div className="space-y-6 pb-4">
          <Card className="pt-4 px-5 pb-5 mb-6 bg-white">
            <div className="text-slate-90 text-lg font-semibold">Integration creation</div>
            <hr className="text-gray-50 h-[0.5px] my-4 -mx-5" />
            <TextFieldHeader
              dataTestID="field-integration-name"
              title="Integration name"
              placeholder="Name"
              value={requestBodyForIntegration?.name ? requestBodyForIntegration.name : ""}
              onChange={(event) =>
                setRequestBodyForIntegration((body) => {
                  return { ...body, name: event.target.value };
                })
              }
              className="mb-4"
              required
            />
            {requestBodyForIntegration?.name && requestBodyForIntegration?.name.length >= 25 && (
              <TextFieldHeader
                title="Abbreviated name"
                subtitle="The shortened name of the integration. Only needed if the integration name is so long that it looks awkward in Merge Link or the marketing page."
                value={requestBodyForIntegration?.abbreviated_name || ""}
                onChange={(event) =>
                  setRequestBodyForIntegration((body) => {
                    return {
                      ...body,
                      abbreviated_name: event.target.value.length > 0 ? event.target.value : null,
                    };
                  })
                }
                placeholder={"Default: " + requestBodyForIntegration?.name}
              />
            )}
            <HeaderBase title="Select categories" required />
            <IntegrationCategorySelection
              requestBodyForIntegration={requestBodyForIntegration}
              setRequestBodyForIntegration={setRequestBodyForIntegration}
            />
          </Card>
          <IntegrationCreationAdvanced
            requestBodyForIntegration={requestBodyForIntegration}
            setRequestBodyForIntegration={setRequestBodyForIntegration}
            isSoapIntegrationOptions={isSoapIntegrationOptions}
            isMultiSubsidiaryOptions={isMultiSubsidiaryOptions}
            isUseScrapers={isUseScrapers}
            isUseScrapersOptions={isUseScrapersOptions}
            isScraperProxyOptions={isScraperProxyOptions}
          />
        </div>
      )}
    </EditorLeavingGuard>
  );
};

export default IntegrationCreation;
