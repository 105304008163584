import { useEffect, useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { AsyncAutogeneratedReadMappingTestRun } from "../../../../models/MappingTests";

interface GetLatestAutogeneratedReadMappingTestProps {
  mappingTestSuiteID: string;
}

export const useGetLatestAutogeneratedReadMappingTest = ({
  mappingTestSuiteID,
}: GetLatestAutogeneratedReadMappingTestProps) => {
  const [
    generatedReadTestRun,
    setGeneratedReadTestRun,
  ] = useState<AsyncAutogeneratedReadMappingTestRun | null>(null);

  useEffect(() => {
    const fetchData = () => {
      fetchWithAuth({
        path: `/stallions/integrations/test-suite/${mappingTestSuiteID}/latest-autogenerated-read-test-run`,
        method: "GET",
        body: {},
        onResponse: (data) => {
          setGeneratedReadTestRun(data);
        },
        onError: () => {},
      });
    };

    fetchData();
  }, [mappingTestSuiteID]);

  return {
    generatedReadTestRun,
    setGeneratedReadTestRun,
  };
};
