import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as FullStory from "@fullstory/browser";

import "./styles/css/fonts/feather/feather.css";
import "./styles/css/dashkit/flatpickr.min.css";
import "./styles/css/dashkit/quill.core.css";
import "./styles/css/dashkit/vs2015.css";
import "./styles/css/dashkit/theme.min.css";
import "./styles/scss/main.scss";
import "./styles/css/tailwind/tailwind.css";
import "./styles/scss/normalization.scss";

if (process.env.REACT_APP_MERGE_ENV === "PRODUCTION") {
  FullStory.init({ orgId: "YVN3B" });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
