import KeyPathFieldHeader from "../../../shared/KeyPathFieldHeader";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import { Row } from "../../../shared/postman-table/PostmanTableRow";
import BasicAuthNoTokenExchange from "../non-oauth/BasicAuthNoTokenExchange";
import LineIndent from "../shared/LineIndent";

interface ScraperConfigurationProps {
  authConfigScraperSessionLifespan: number | undefined;
  setAuthConfigScraperSessionLifespan: React.Dispatch<React.SetStateAction<number | undefined>>;
  authConfigServiceAccountFieldKeys: string[] | null;
  setAuthConfigServiceAccountFieldKeys: React.Dispatch<React.SetStateAction<string[] | null>>;
  basicAuthKeyHeaderFormat: Row[];
  setBasicAuthKeyHeaderFormat: React.Dispatch<React.SetStateAction<Row[]>>;
}

const ScraperConfiguration = ({
  authConfigScraperSessionLifespan,
  setAuthConfigScraperSessionLifespan,
  authConfigServiceAccountFieldKeys,
  setAuthConfigServiceAccountFieldKeys,
  basicAuthKeyHeaderFormat,
  setBasicAuthKeyHeaderFormat,
}: ScraperConfigurationProps) => {
  return (
    <LineIndent className="mt-6">
      <TextFieldHeader
        dataTestID="field-auth-config-scraper-session-lifespan"
        className="mb-6"
        title="Scraper session lifespan"
        subtitle="Enter session lifespan in seconds"
        placeholder="123"
        value={authConfigScraperSessionLifespan}
        type="number"
        onChange={(newEvent) => {
          const value = newEvent.target.value;
          if (value === "") {
            setAuthConfigScraperSessionLifespan(undefined);
          } else {
            const numericValue = parseInt(value, 10);
            if (!isNaN(numericValue)) {
              setAuthConfigScraperSessionLifespan(numericValue);
            }
          }
        }}
      />
      <KeyPathFieldHeader
        dataTestID="field-auth-config-service-account-field-keys"
        className="mb-6"
        title="Service account field keys"
        subtitle="If an integration requires a service account, enter the keys to display to an end user during the linking flow. Type the name of the key below and press Enter."
        placeholder="Add key name..."
        keyPath={authConfigServiceAccountFieldKeys}
        onKeyPathChange={setAuthConfigServiceAccountFieldKeys}
        hasSource={false}
        showArrow={false}
      />
      <BasicAuthNoTokenExchange
        basicAuthKeyHeaderFormat={basicAuthKeyHeaderFormat}
        setBasicAuthKeyHeaderFormat={setBasicAuthKeyHeaderFormat}
      />
    </LineIndent>
  );
};

export default ScraperConfiguration;
