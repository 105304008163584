import styled from "styled-components";

const Badge = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  padding: 2px 5px;
  border-radius: 4px;
`;

export const BadgeSuccess = styled(Badge)`
  color: #1a9b87;
  background-color: #ebfcfa;
`;

export const BadgeWarning = styled(Badge)`
  color: #d9a800;
  background-color: #fff9e6;
`;

export const BadgeError = styled(Badge)`
  color: #e62837;
  background-color: #fff2f3;
`;

const BLUEPRINT_EXECUTION_EXIT_CODE_MESSAGES: { [key: number]: string } = {
  100: "Continue",
  101: "Switching Protocols",
  200: "OK",
  201: "Created",
  202: "Accepted",
  203: "Non-Authoritative Information",
  204: "No Content",
  205: "Reset Content",
  206: "Partial Content",
  300: "Multiple Choices",
  301: "Moved Permanently",
  302: "Found",
  303: "See Other",
  304: "Not Modified",
  305: "Use Proxy",
  307: "Temporary Redirect",
  400: "Bad Request",
  401: "Unauthorized",
  402: "Payment Required",
  403: "Forbidden",
  404: "Not Found",
  405: "Method Not Allowed",
  406: "Not Acceptable",
  407: "Proxy Authentication Required",
  408: "Request Timeout",
  409: "Conflict",
  410: "Gone",
  411: "Length Required",
  412: "Precondition Failed",
  413: "Payload Too Large",
  414: "URI Too Long",
  415: "Unsupported Media Type",
  416: "Range Not Satisfiable",
  417: "Expectation Failed",
  418: "MFA Code Needed", // Overridden
  419: "Missing Permissions", // Overridden
  426: "Upgrade Required",
  429: "Too Many Requests", // Overridden
  500: "Internal Server Error",
  501: "Not Implemented",
  502: "Bad Gateway",
  503: "Service Unavailable",
  504: "Gateway Timeout",
  505: "HTTP Version Not Supported",
  // 6XXs are all Merge-specific
  600: "Killed by Deploy",
  601: "Soft Timeout Error",
  602: "Dead Worker",
  603: "Manually killed",
  607: "Lost DB Connection",
  608: "DB Connection Issue",
  609: "Cache Conflict",
  610: "Concurrency Failure",
  614: "Heartbeat Error",
  615: "Cache Metadata Conflict",
  616: "Max Item Limit Reached",
  617: "Max Runtime Limit Reached",
  628: "API Request Timeout",
  629: "Rate Limited",
  630: "Rate Limit Backoff Exceeded",
};

interface BlueprintExecutionExitCodeBadgeProps {
  exitCode: string | number;
}

const BlueprintExecutionExitCodeBadge = ({ exitCode }: BlueprintExecutionExitCodeBadgeProps) => {
  if (!exitCode) {
    return null;
  }
  const exitCodeInt = typeof exitCode !== "number" ? parseInt(exitCode) : exitCode;
  const exitCodeMessage =
    exitCodeInt in BLUEPRINT_EXECUTION_EXIT_CODE_MESSAGES
      ? BLUEPRINT_EXECUTION_EXIT_CODE_MESSAGES[exitCodeInt]
      : "Unknown Exit Code";

  let BadgeComponent = BadgeSuccess;
  if (exitCodeInt >= 300) {
    BadgeComponent = BadgeError;
  }
  return (
    <BadgeComponent>
      <b>{`${exitCode} ${exitCodeMessage}`}</b>
    </BadgeComponent>
  );
};

export default BlueprintExecutionExitCodeBadge;
