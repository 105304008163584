import { Text } from "@merge-api/merge-javascript-shared";
import { X } from "lucide-react";

interface Props {
  index: number;
  onDelete: () => void;
}
/**
 * Renders the title for an individual Selective Sync filter in Blueprint Editor left console
 * Displays the filter number and provides a delete button
 * @param {number} index - The index of the filter
 * @param {Function} onDelete - Callback function to delete the filter
 */

const LeftPanelSelectiveSyncFilterTitle = ({ index, onDelete }: Props) => {
  return (
    <div className="flex flex-row justify-between items-center">
      <Text variant="title-sm" className="text-gray-60 uppercase">
        Filter {index + 1}
      </Text>
      <X size={12} className="text-gray-60 cursor-pointer hover:text-black" onClick={onDelete} />
    </div>
  );
};

export default LeftPanelSelectiveSyncFilterTitle;
