import { IntegrationValidatorRuleResult } from "../types";

// Define action types and reducer explicitly
export type BlockingRuleAction =
  | { type: typeof ADD_BLOCKING_RULE; payload: string }
  | { type: typeof REMOVE_BLOCKING_RULE; payload: string }
  | { type: typeof CLEAR_BLOCKING_RULES; payload: string };

// Action Types
export const ADD_BLOCKING_RULE = "ADD_BLOCKING_RULE";
export const REMOVE_BLOCKING_RULE = "REMOVE_BLOCKING_RULE";
export const CLEAR_BLOCKING_RULES = "CLEAR_BLOCKING_RULES";

// Helper function to generate a unique key for a rule
export const generateRuleKey = (rule: IntegrationValidatorRuleResult) =>
  `${rule.rule_id}-${rule.component_version_id}`;

// Reducer function
export const blockingRulesReducer = (state: Set<string>, action: any) => {
  switch (action.type) {
    case ADD_BLOCKING_RULE: {
      const newState = new Set(state); // Clone the state to maintain immutability
      newState.add(action.payload); // Add the new rule key
      return newState;
    }
    case REMOVE_BLOCKING_RULE: {
      const newState = new Set(state); // Clone the state
      newState.delete(action.payload); // Remove the rule key
      return newState;
    }
    case CLEAR_BLOCKING_RULES: {
      return new Set<string>(); // Reset the state
    }
    default:
      return state;
  }
};
