import { LucideIcon } from "lucide-react";
import styled from "styled-components";
import MergeText, { TextType } from "../../shared/text/MergeText";

export type IntegrationEditorTopControlPanelActionProps = {
  Icon: LucideIcon;
  hoverText?: string;
  isDisabled?: boolean;
  isHidden?: boolean;
  onClick: () => void;
  text: string;
};

export type ActionButtonProps = {
  isDisabled?: boolean;
};

const ActionButton = styled.div<ActionButtonProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
  margin-right: 12px;

  > div {
    margin-left: 8px;
  }

  cursor: ${({ isDisabled }) => (isDisabled ? "initial" : "pointer")};
  opacity: ${({ isDisabled }) => (isDisabled ? "0.5" : "1")};
`;

const IntegrationEditorTopControlPanelAction = ({
  Icon,
  isDisabled,
  onClick,
  text,
}: IntegrationEditorTopControlPanelActionProps) => {
  return (
    <ActionButton
      isDisabled={isDisabled}
      onClick={() => {
        if (!isDisabled) {
          onClick();
        }
      }}
    >
      <Icon size="12px" className="mr-1.5" />
      <MergeText isBold size="12px" type={TextType.WHITE}>
        {text}
      </MergeText>
    </ActionButton>
  );
};

export default IntegrationEditorTopControlPanelAction;
