import { useEffect, useState } from "react";
import { Integration, IntegrationCommonModelConfig } from "../../../models/Entities";
import FullPageSpinner from "../../shared/layout/FullPageSpinner";
import { showErrorToast } from "../../shared/Toasts";
import UIDTemplateManagementView from "./UIDTemplateManagementView";
import { fetchTemplateConfigs } from "../utils/IntegrationsAPIClient";
import UIDTemplatePageWrapper from "./UIDTemplatePageWrapper";

type Props = {
  integrationMeta: Integration;
};

function UIDTemplateManagementOverview({ integrationMeta }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [integrationCommonModelConfigs, setIntegrationCommonModelConfigs] = useState<
    IntegrationCommonModelConfig[] | undefined
  >(undefined);

  useEffect(() => {
    const fetchTemplates = () => {
      setIsLoading(true);
      fetchTemplateConfigs({
        integrationID: integrationMeta.id,
        onSuccess: (data: IntegrationCommonModelConfig[]) => {
          setIntegrationCommonModelConfigs(data);
          setIsLoading(false);
        },
        onError: () => {
          showErrorToast("Failed to fetch templates.");
          setIsLoading(false);
        },
      });
    };

    fetchTemplates();
  }, []);

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <UIDTemplatePageWrapper integrationMeta={integrationMeta}>
      <UIDTemplateManagementView
        integrationMeta={integrationMeta}
        integrationCommonModelConfigs={integrationCommonModelConfigs}
      />
    </UIDTemplatePageWrapper>
  );
}

export default UIDTemplateManagementOverview;
