import { format, parseISO } from "date-fns";
import { useContext, useState } from "react";
import AccordionCard from "../../../shared/AccordionCard";
import MultiSelectHeader from "../../../shared/MultiSelectHeader";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import {
  BOOLEAN_OPTIONS_DATA,
  TIMESTAMP_FILTER_PARAM_LOCATION_OPTIONS_DATA,
} from "../../constants";
import APIEndpointContext from "../../context/APIEndpointContext";

const TimestampFilterConfigurationSetupOptions = () => {
  const {
    doesAllowTimestampFiltering,
    setDoesAllowTimestampFiltering,
    timestampFilterParamKey,
    setTimestampFilterParamKey,
    timestampFilterParamLocation,
    setTimestampFilterParamLocation,
    timestampFilterFormatOverride,
    setTimestampFilterFormatOverride,
    timestampFilterParamPrefix,
    setTimestampFilterParamPrefix,
    timestampFilterParamSuffix,
    setTimestampFilterParamSuffix,
    timestampFilterBufferHours,
    setTimestampFilterBufferHours,
    timestampFilterDefaultOverride,
    setTimestampFilterDefaultOverride,
  } = useContext(APIEndpointContext);

  const allowedFilteringOptions = BOOLEAN_OPTIONS_DATA.map(({ value, text }) => ({
    value,
    text,
    selected: doesAllowTimestampFiltering == value,
    onClick: setDoesAllowTimestampFiltering,
  }));

  const timestampFilterParamLocationOptions = TIMESTAMP_FILTER_PARAM_LOCATION_OPTIONS_DATA.map(
    ({ value, text }) => ({
      value,
      text,
      selected: timestampFilterParamLocation === value,
      onClick: setTimestampFilterParamLocation,
    })
  );

  const convertLocalToUTC = (localDateString: string): string => {
    const date = new Date(localDateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = date.getUTCDate().toString().padStart(2, "0");
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:00Z`; // Construct the UTC string
  };

  const [isInvalidTimestampOverride, setIsInvalidTimestampOverride] = useState(false);

  const isInvalidTimestampBufferHours =
    timestampFilterBufferHours !== null &&
    (timestampFilterBufferHours < 1 || timestampFilterBufferHours > 24);

  return (
    <div className="w-full">
      <MultiSelectHeader
        dataTestID="field-api-endpoint-timestamp-filter-allow-filtering"
        title="Does this endpoint allow filtering by timestamp?"
        options={allowedFilteringOptions}
        className="mt-6"
      />
      {doesAllowTimestampFiltering && (
        <div className="border-l-2 pl-8 mt-6 border-gray-10 w-full">
          <MultiSelectHeader
            dataTestID="field-api-endpoint-timestamp-filter-param-location"
            title="Parameter location"
            subtitle="The location of the parameter that will be used to filter by timestamp"
            required
            options={timestampFilterParamLocationOptions}
          />
          <TextFieldHeader
            dataTestID="field-api-endpoint-timestamp-filter-param-key"
            title="Parameter key"
            subtitle="The key of the parameter that will be used to filter by timestamp"
            className="mt-6"
            placeholder="Value"
            value={timestampFilterParamKey}
            onChange={(e) => setTimestampFilterParamKey(e.target.value)}
            hasSource={false}
            required
          />
          <AccordionCard
            dataTestID="accordion-advanced-timestamp-options"
            className="mt-6"
            chevronSize={12}
            title="Advanced"
            titleClassName="px-5 py-3"
            variant="outline"
            nested
          >
            <TextFieldHeader
              dataTestID="field-api-endpoint-timestamp-filter-format-override"
              title="Format override"
              subtitle="Overrides integration-level timestamp format for this specific API endpoint"
              learnMoreText="If the integration uses ISO formatting, put ISO_FORMAT. If the integration uses timestamp in milliseconds, put MILLISECONDS. Otherwise, put in string that can be passed into the strftime python function. Example: Cake HR uses YYYY-MM-DD, so this should be %Y-%m-%d"
              placeholder="Examples: ISO_FORMAT, MILLISECONDS, %Y-%m-%d"
              value={timestampFilterFormatOverride}
              onChange={(e) => setTimestampFilterFormatOverride(e.target.value)}
              className="mt-6"
              hasSource={false}
            />
            <TextFieldHeader
              dataTestID="field-api-endpoint-timestamp-filter-param-prefix"
              title="Parameter prefix"
              subtitle="Prefix to be added to the parameter value"
              placeholder="Example: sys_updated_on>"
              value={timestampFilterParamPrefix}
              onChange={(e) => setTimestampFilterParamPrefix(e.target.value)}
              className="mt-6"
              hasSource={false}
            />
            <TextFieldHeader
              dataTestID="field-api-endpoint-timestamp-filter-param-suffix"
              title="Parameter suffix"
              subtitle="Suffix to be added to the parameter value"
              placeholder="Example: ..*"
              value={timestampFilterParamSuffix}
              onChange={(e) => setTimestampFilterParamSuffix(e.target.value)}
              className="mt-6"
              hasSource={false}
            />
            <TextFieldHeader
              dataTestID="field-api-endpoint-timestamp-filter-buffer-hours"
              title="Buffer hours"
              subtitle="Adds a set number of hours BEFORE a modified_since timestamp value to help prevent race conditions and differences between timezones. Must be a number between 1 and 24."
              type="number"
              placeholder={"Buffer hours: 1-24"}
              value={timestampFilterBufferHours ?? ""}
              error={isInvalidTimestampBufferHours}
              errorText={"Buffer hours must be between 1 and 24"}
              onChange={(e) => {
                if (!!!e.target.value) {
                  setTimestampFilterBufferHours(null);
                } else {
                  setTimestampFilterBufferHours(parseInt(e.target.value));
                }
              }}
              className="mt-6"
              hasSource={false}
            />
            <TextFieldHeader
              dataTestID="field-api-endpoint-timestamp-filter-default-override"
              title="Default override"
              subtitle="Optionally override the starting date used for modified_at timestamps. Example: 03-19-1997 06:32 AM"
              value={
                timestampFilterDefaultOverride
                  ? format(parseISO(timestampFilterDefaultOverride), "yyyy-MM-dd'T'HH:mm")
                  : ""
              }
              onChange={(e) => {
                if (!!!e.target.value) {
                  setIsInvalidTimestampOverride(true);
                  setTimestampFilterDefaultOverride("");
                } else {
                  setIsInvalidTimestampOverride(false);
                  const utcDate = convertLocalToUTC(e.target.value);
                  setTimestampFilterDefaultOverride(utcDate);
                }
              }}
              onFocus={(e) => {
                if (!!!e.target.value) {
                  setIsInvalidTimestampOverride(true);
                }
              }}
              onBlur={() => {
                setIsInvalidTimestampOverride(false);
              }}
              error={isInvalidTimestampOverride}
              className="mt-6"
              type="datetime-local"
              hasSource={false}
              textFieldClassName="w-[250px]"
            />
          </AccordionCard>
        </div>
      )}
    </div>
  );
};

export default TimestampFilterConfigurationSetupOptions;
