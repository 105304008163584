import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ChecklistStatusValue } from "../../constants";
import IntegrationBuilderWrapper from "./IntegrationBuilderWrapper";
import IntegrationBuilderContextProvider from "./context/IntegrationBuilderContextProvider";
import useLoadIntegration from "./hooks/useLoadIntegration";
import { DiffModelTypeEnum, DiffStatusEnum } from "../../models/DiffModels";
import { compareStates } from "../shared/diff-view/utils/helpers-diff";

type RouteParams = {
  integrationID: string | undefined;
};

const IntegrationBuilder = () => {
  const { integrationID } = useParams<RouteParams>();

  // if no integration id is provided we'll want to disable most routes until an integration is created
  const isDisabled = !integrationID;
  const { integration, setIntegration, isLoading, setIsLoading } = useLoadIntegration(
    integrationID
  );

  // submit context states
  const [canSubmit, setCanSubmit] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [onSubmit, setInternalOnSubmit] = useState<() => void>(() => {});
  const setOnSubmit = useCallback((newOnSubmit: () => void) => {
    setInternalOnSubmit(() => newOnSubmit);
  }, []);
  const [submitButtonText, setSubmitButtonText] = useState<string>("Save");
  const [shouldRenderSubmitButton, setShouldRenderSubmitButton] = useState(true);
  const [currentStateForDiff, setCurrentStateForDiff] = useState<{ [key: string]: any }>();
  const [newStateForDiff, setNewStateForDiff] = useState<{ [key: string]: any }>();
  const [modelTypeForDiff, setModelTypeForDiff] = useState<DiffModelTypeEnum>();
  const [shouldHideDiffModal, setShouldHideDiffModal] = useState(false);

  // navigation button context states
  const [shouldRenderNavigationButtons, setShouldRenderNavigationButtons] = useState(true);

  // delete button context states
  const [onDelete, setInternalOnDelete] = useState<() => void>(() => {});
  const setOnDelete = useCallback((newOnDelete: () => void) => {
    setInternalOnDelete(() => newOnDelete);
  }, []);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [deleteButtonText, setDeleteButtonText] = useState<string>("Delete");
  const [shouldRenderDeleteButton, setShouldRenderDeleteButton] = useState(false);

  // stage button context states
  const [onStage, onInternalOnStage] = useState<() => void>(() => {});
  const setOnStage = useCallback((newOnSTage: () => void) => {
    onInternalOnStage(() => newOnSTage);
  }, []);
  const [isLoadingStage, setIsLoadingStage] = useState(false);
  const [canStage, setCanStage] = useState(true);
  const [stageButtonText, setStageButtonText] = useState<string>("Stage");
  const [shouldRenderStageButton, setShouldRenderStageButton] = useState(false);

  // mark for deletion context states
  const [markedForDeletion, setMarkedForDeletion] = useState(false);

  // progress context states
  const [completionProgress, setCompletionProgress] = useState(0);
  const [isIntegrationInitializationComplete, setIsIntegrationInitializationComplete] = useState(
    false
  );
  const [isWebAddressSchemaComplete, setIsWebAddressSchemaComplete] = useState(false);
  const [
    isAuthenticationConfigurationComplete,
    setIsAuthenticationConfigurationComplete,
  ] = useState(false);
  const [isPaginationConfigurationComplete, setIsPaginationConfigurationComplete] = useState(false);
  const [isLinkingFlowStepPathComplete, setIsLinkingFlowStepPathComplete] = useState(false);
  const [isAPIEndpointsComplete, setIsAPIEndpointsComplete] = useState(false);
  const [isRateLimitConfigurationComplete, setIsRateLimitConfigurationComplete] = useState(false);
  const [isCustomerFacingFieldsComplete, setIsCustomerFacingFieldsComplete] = useState(false);
  const [isBaseAPIURLComplete, setIsBaseAPIURLComplete] = useState(false);
  const [isSetupStepsConfigFieldsComplete, setIsSetupStepsConfigFieldsComplete] = useState(false);
  const [isAPIEndpointsConfigFieldsComplete, setIsAPIEndpointsConfigFieldsComplete] = useState(
    false
  );
  const [aiSearchConfigurationStatus, setAISearchConfigurationStatus] = useState(
    ChecklistStatusValue.NOT_IMPLEMENTED
  );

  // permission states
  const [isGatedByPermissioning, setIsGatedByPermissioning] = useState<boolean>(false);
  const [hasPermissionToEdit, setHasPermissionToEdit] = useState<boolean>(false);

  // Computes whether there are unsaved changes
  // Called by EditorLeavingGuard that shows modal if unsaved changes
  function computeHasUnsavedChanges(): boolean {
    if (modelTypeForDiff && currentStateForDiff && newStateForDiff) {
      const diffState = compareStates(modelTypeForDiff, currentStateForDiff, newStateForDiff);
      return !diffState.every(
        (diffStateField) => diffStateField.diffStatus === DiffStatusEnum.NO_CHANGES
      );
    }
    return false;
  }

  // Needed after saving changes and attempting to navigate elsewhere
  // Needed such that "Unsaved" modal doesn't pop up
  function resetDiffStates(): void {
    setCurrentStateForDiff({});
    setNewStateForDiff({});
  }

  // Setting up an async version of resetDiffStates to ensure state updates have been applied
  // before the function resolves. This solves race condition issues with the diff modal and unsaved changes modal.
  async function asyncResetDiffStates(): Promise<void> {
    return new Promise<void>((resolve) => {
      setCurrentStateForDiff({});
      setNewStateForDiff({});
      // Use setTimeout to ensure state updates have been applied
      setTimeout(() => {
        resolve();
      }, 0);
    });
  }

  // sets state which determines progress bar completion
  useEffect(() => {
    if (integration) {
      if (
        integration.name &&
        integration.categories &&
        integration.categories.length > 0 &&
        isIntegrationInitializationComplete === false
      ) {
        setIsIntegrationInitializationComplete(true);
      }
      if (
        isAuthenticationConfigurationComplete === false &&
        integration?.integration_checklist?.has_authentication_config ===
          ChecklistStatusValue.IMPLEMENTED
      ) {
        setIsAuthenticationConfigurationComplete(true);
      }
      if (
        isWebAddressSchemaComplete === false &&
        integration?.integration_checklist?.has_web_address_schema ===
          ChecklistStatusValue.IMPLEMENTED
      ) {
        setIsWebAddressSchemaComplete(true);
      }
      if (
        isLinkingFlowStepPathComplete === false &&
        integration?.integration_checklist?.has_setup_steps === ChecklistStatusValue.IMPLEMENTED
      ) {
        setIsLinkingFlowStepPathComplete(true);
      }
      if (
        isCustomerFacingFieldsComplete === false &&
        integration?.integration_checklist?.has_integration_logo ===
          ChecklistStatusValue.IMPLEMENTED
      ) {
        setIsCustomerFacingFieldsComplete(true);
      }
      if (
        isPaginationConfigurationComplete === false &&
        integration?.integration_checklist?.has_pagination_config ===
          ChecklistStatusValue.IMPLEMENTED
      ) {
        setIsPaginationConfigurationComplete(true);
      }
      if (
        isRateLimitConfigurationComplete === false &&
        integration?.integration_checklist?.has_rate_limit_config ===
          ChecklistStatusValue.IMPLEMENTED
      ) {
        setIsRateLimitConfigurationComplete(true);
      }
      if (
        isAPIEndpointsComplete === false &&
        integration?.integration_checklist?.has_api_endpoint === ChecklistStatusValue.IMPLEMENTED
      ) {
        setIsAPIEndpointsComplete(true);
      }
      if (
        isLinkingFlowStepPathComplete === false &&
        integration?.integration_checklist?.has_setup_steps === ChecklistStatusValue.IMPLEMENTED
      ) {
        setIsLinkingFlowStepPathComplete(true);
      }
      if (
        isBaseAPIURLComplete === false &&
        integration?.integration_checklist?.has_base_api_url === ChecklistStatusValue.IMPLEMENTED
      ) {
        setIsBaseAPIURLComplete(true);
      }
      if (
        isSetupStepsConfigFieldsComplete === false &&
        integration?.integration_checklist?.has_setup_steps_config_fields ===
          ChecklistStatusValue.IMPLEMENTED
      ) {
        setIsSetupStepsConfigFieldsComplete(true);
      }
      if (
        isAPIEndpointsConfigFieldsComplete === false &&
        integration?.integration_checklist?.has_api_endpoints_config_fields ===
          ChecklistStatusValue.IMPLEMENTED
      ) {
        setIsAPIEndpointsConfigFieldsComplete(true);
      }
      if (integration?.integration_checklist?.has_api_endpoint_collection_run) {
        setAISearchConfigurationStatus(
          integration.integration_checklist.has_api_endpoint_collection_run
        );
      }
    }
  }, [integration]);

  // calculates progress bar completion
  useEffect(() => {
    let localCompletionProgress = 0;
    if (isIntegrationInitializationComplete) {
      localCompletionProgress++;
    }
    if (isWebAddressSchemaComplete && isBaseAPIURLComplete) {
      localCompletionProgress++;
    }
    if (isAuthenticationConfigurationComplete) {
      localCompletionProgress++;
    }
    if (isLinkingFlowStepPathComplete && isSetupStepsConfigFieldsComplete) {
      localCompletionProgress++;
    }
    if (isPaginationConfigurationComplete) {
      localCompletionProgress++;
    }
    if (isRateLimitConfigurationComplete) {
      localCompletionProgress++;
    }
    if (isAPIEndpointsComplete && isAPIEndpointsConfigFieldsComplete) {
      localCompletionProgress++;
    }
    if (isCustomerFacingFieldsComplete) {
      localCompletionProgress++;
    }
    setCompletionProgress(localCompletionProgress);
  }, [
    isIntegrationInitializationComplete,
    isAuthenticationConfigurationComplete,
    isWebAddressSchemaComplete,
    isLinkingFlowStepPathComplete,
    isPaginationConfigurationComplete,
    isRateLimitConfigurationComplete,
    isCustomerFacingFieldsComplete,
    isAPIEndpointsComplete,
  ]);

  // component rendering context
  const [isRightPanelOpen, setIsRightPanelOpen] = useState(false);
  const [rightPanelChildren, setRightPanelChildren] = useState<React.ReactNode>(undefined);

  return (
    <IntegrationBuilderContextProvider
      // integration context
      isLoading={isLoading}
      integration={integration}
      setIntegration={setIntegration}
      setIsLoading={setIsLoading}
      // submission button context
      canSubmit={canSubmit}
      setCanSubmit={setCanSubmit}
      onSubmit={onSubmit}
      setOnSubmit={setOnSubmit}
      isLoadingSubmit={isLoadingSubmit}
      setIsLoadingSubmit={setIsLoadingSubmit}
      submitButtonText={submitButtonText}
      setSubmitButtonText={setSubmitButtonText}
      shouldRenderSubmitButton={shouldRenderSubmitButton}
      setShouldRenderSubmitButton={setShouldRenderSubmitButton}
      currentStateForDiff={currentStateForDiff}
      setCurrentStateForDiff={setCurrentStateForDiff}
      newStateForDiff={newStateForDiff}
      setNewStateForDiff={setNewStateForDiff}
      modelTypeForDiff={modelTypeForDiff}
      setModelTypeForDiff={setModelTypeForDiff}
      shouldHideDiffModal={shouldHideDiffModal}
      setShouldHideDiffModal={setShouldHideDiffModal}
      //navigation button context
      shouldRenderNavigationButtons={shouldRenderNavigationButtons}
      setShouldRenderNavigationButtons={setShouldRenderNavigationButtons}
      // delete button context
      onDelete={onDelete}
      setOnDelete={setOnDelete}
      isLoadingDelete={isLoadingDelete}
      setIsLoadingDelete={setIsLoadingDelete}
      deleteButtonText={deleteButtonText}
      setDeleteButtonText={setDeleteButtonText}
      shouldRenderDeleteButton={shouldRenderDeleteButton}
      setShouldRenderDeleteButton={setShouldRenderDeleteButton}
      // stage button context
      onStage={onStage}
      setOnStage={setOnStage}
      stageButtonText={stageButtonText}
      setStageButtonText={setStageButtonText}
      shouldRenderStageButton={shouldRenderStageButton}
      setShouldRenderStageButton={setShouldRenderStageButton}
      isLoadingStage={isLoadingStage}
      setIsLoadingStage={setIsLoadingStage}
      canStage={canStage}
      setCanStage={setCanStage}
      // mark for deletion context
      markedForDeletion={markedForDeletion}
      setMarkedForDeletion={setMarkedForDeletion}
      // progress context
      completionProgress={completionProgress}
      setCompletionProgress={setCompletionProgress}
      isIntegrationInitializationComplete={isIntegrationInitializationComplete}
      setIsIntegrationInitializationComplete={setIsIntegrationInitializationComplete}
      aiSearchConfigurationStatus={aiSearchConfigurationStatus}
      setAISearchConfigurationStatus={setAISearchConfigurationStatus}
      isWebAddressSchemaComplete={isWebAddressSchemaComplete}
      setIsWebAddressSchemaComplete={setIsWebAddressSchemaComplete}
      isAuthenticationConfigurationComplete={isAuthenticationConfigurationComplete}
      setIsAuthenticationConfigurationComplete={setIsAuthenticationConfigurationComplete}
      isPaginationConfigurationComplete={isPaginationConfigurationComplete}
      setIsPaginationConfigurationComplete={setIsPaginationConfigurationComplete}
      isLinkingFlowStepPathComplete={isLinkingFlowStepPathComplete}
      setIsLinkingFlowStepPathComplete={setIsLinkingFlowStepPathComplete}
      isAPIEndpointsComplete={isAPIEndpointsComplete}
      setIsAPIEndpointsComplete={setIsAPIEndpointsComplete}
      isRateLimitConfigurationComplete={isRateLimitConfigurationComplete}
      setIsRateLimitConfigurationComplete={setIsRateLimitConfigurationComplete}
      isCustomerFacingFieldsComplete={isCustomerFacingFieldsComplete}
      setIsCustomerFacingFieldsComplete={setIsCustomerFacingFieldsComplete}
      isBaseAPIURLComplete={isBaseAPIURLComplete}
      setIsBaseAPIURLComplete={setIsBaseAPIURLComplete}
      isSetupStepsConfigFieldsComplete={isSetupStepsConfigFieldsComplete}
      setIsSetupStepsConfigFieldsComplete={setIsSetupStepsConfigFieldsComplete}
      isAPIEndpointsConfigFieldsComplete={isAPIEndpointsConfigFieldsComplete}
      setIsAPIEndpointsConfigFieldsComplete={setIsAPIEndpointsConfigFieldsComplete}
      // permissioning
      isGatedByPermissioning={isGatedByPermissioning}
      setIsGatedByPermissioning={setIsGatedByPermissioning}
      hasPermissionToEdit={hasPermissionToEdit}
      setHasPermissionToEdit={setHasPermissionToEdit}
      // component rendering context
      isRightPanelOpen={isRightPanelOpen}
      setIsRightPanelOpen={setIsRightPanelOpen}
      rightPanelChildren={rightPanelChildren}
      setRightPanelChildren={setRightPanelChildren}
      // unsaved changes
      computeHasUnsavedChanges={computeHasUnsavedChanges}
      resetDiffStates={resetDiffStates}
      asyncResetDiffStates={asyncResetDiffStates}
    >
      <IntegrationBuilderWrapper isDisabled={isDisabled} integrationID={integrationID} />
    </IntegrationBuilderContextProvider>
  );
};

export default IntegrationBuilder;
