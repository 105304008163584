import { Badge, Text } from "@merge-api/merge-javascript-shared";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import ClickableText from "../../../shared/ClickableText";
import { useState } from "react";
import InputPreviewModal from "./InputPreviewModal";
import { LinkingFlowStepIntegrationBuilder } from "../../../utils/Entities";

interface Props {
  index: number;
  requestedStep: LinkingFlowStepIntegrationBuilder;
  onCallbackUpdateStep: (index: number, keyValuePairs: Record<string, any>) => void;
}

const InputConfigurationFields = ({ index, requestedStep, onCallbackUpdateStep }: Props) => {
  const [isInputPreviewModalOpen, setIsInputPreviewModalOpen] = useState<boolean>(false);

  return (
    <div className="border-gray-20 rounded-lg border-[0.5px]">
      <div className="px-5 py-4 flex flex-row justify-between items-center">
        <div className="flex flex-row items-center justify-start space-x-2">
          <Text variant="h6">Input configuration</Text>
          <Badge color="blue">3</Badge>
        </div>
        <ClickableText
          text="Preview input"
          onClick={() => {
            setIsInputPreviewModalOpen(!isInputPreviewModalOpen);
          }}
        />
      </div>
      <div className="px-5 py-4 space-y-6 border-t-[0.5px] border-gray-20">
        <TextFieldHeader
          dataTestID={`field-step-card-input-prefix-${index}`}
          title="Input prefix"
          subtitle="Enter text to prepend to the input on this step"
          placeholder={"Example: https://"}
          value={requestedStep.input_prefix}
          onChange={(event) => onCallbackUpdateStep(index, { input_prefix: event.target.value })}
        />
        <TextFieldHeader
          dataTestID={`field-step-card-input-suffix-${index}`}
          title="Input suffix"
          subtitle="Enter text to append to the input on this step"
          placeholder={"Example: .merge.dev"}
          value={requestedStep.input_suffix}
          onChange={(event) => onCallbackUpdateStep(index, { input_suffix: event.target.value })}
        />
        <TextFieldHeader
          dataTestID={`field-step-card-input-placeholder-${index}`}
          title="Input placeholder"
          subtitle="Enter text to serve as the placeholder of the input on this step"
          placeholder={"Default: Enter value ..."}
          value={requestedStep.input_placeholder}
          onChange={(event) =>
            onCallbackUpdateStep(index, { input_placeholder: event.target.value })
          }
        />
      </div>
      <InputPreviewModal
        open={isInputPreviewModalOpen}
        onClose={() => setIsInputPreviewModalOpen(false)}
        inputPrefix={requestedStep.input_prefix}
        inputPlaceholder={requestedStep.input_placeholder || "Enter value ..."}
        inputSuffix={requestedStep.input_suffix}
      />
    </div>
  );
};

export default InputConfigurationFields;
