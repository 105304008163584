import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import { fetchWithAuth } from "../../../api-client/api_client";
import {
  APIEndpoint,
  APIEndpointMethod,
  APIRequestFormat,
  APIRequestType,
  APIResponseType,
  Integration,
  APIRequestFormatSubtype,
  TimestampParamLocation,
  ResponseBodyAdditionalParsing,
  BlueprintMeta,
} from "../../../models/Entities";
import DropdownFormField from "../../blueprint-editor/right-panel/DropdownFormField";
import InputFormField from "../../blueprint-editor/right-panel/InputFormField";
import HeaderBar from "../../portal/HeaderBar";

import { createOrUpdateEndpoint } from "../utils/IntegrationsAPIClient";
import {
  getURLParamJSONSchemaFromPath,
  getXMLRequestBodyParamSchema,
  nameFromApiRequestFormat,
} from "../utils/IntegrationsUtils";

import { showErrorToast, showSuccessToast } from "../../shared/Toasts";
import MergeModal from "../../shared/MergeModal";
import { Button, Col, Row, Form, Badge } from "react-bootstrap";
import APIEndpointEditFormRightPanel from "./APIEndpointEditFormRightPanel";
import SpinnerButton from "../../shared/SpinnerButton";
import { getAPIEndpointsPath, navigateToAPIEndpointsSubtab } from "../../../router/RouterUtils";
import JSONSchemaConverterFormField from "./JSONSchemaConverterFormField";
import XMLConverterFormField from "./XMLConverterFormField";
import FormField from "../../blueprint-editor/right-panel/FormField";
import ResponseBodyAdditionalParsingForm from "./ResponseBodyAdditionalParsingForm";
import { BlueprintOperationType } from "../../../models/Blueprints";
import useDocumentTitle from "../../shared/useDocumentTitle";
import { Tooltip, Text, Spinner } from "@merge-api/merge-javascript-shared";

type RouteParams = {
  apiEndpointID?: string;
  integrationID: string;
};
type Props = {};

function APIEndpointEditForm(_: Props) {
  const [integrationName, setIntegrationName] = useState<undefined | string>();
  const [baseAPIURL, setBaseAPIURL] = useState<undefined | string>();
  const [oldURLOverride, setOldURLOverride] = useState<undefined | string>();
  const [baseAPIURLOverride, setBaseAPIURLOverride] = useState<undefined | string>();
  const history = useHistory();
  const [name, setName] = useState<undefined | string>();
  const [method, setMethod] = useState<undefined | string>();
  const [timestampParamLocation, setTimestampParamLocation] = useState<undefined | string>();
  const [isPaginated, setIsPaginated] = useState<boolean | undefined>(false);
  const [isProxy, setIsProxy] = useState<boolean | undefined>(false);
  const [isLiveRequest, setIsLiveRequest] = useState<boolean | undefined>(false);
  const [path, setPath] = useState<undefined | string>();
  const [oldPath, setOldPath] = useState<undefined | string>();
  const [requestType, setRequestType] = useState<string>(APIRequestType.REST);
  const [requestBodyFormatSubtype, setRequestBodyFormatSubtype] = useState<APIRequestFormatSubtype>(
    APIRequestFormatSubtype.REST_JSON
  );
  const [endpointModificationBlueprint, setEndpointModificationBlueprint] = useState<
    undefined | string
  >();
  const [endpointModificationBlueprints, setEndpointModificationBlueprints] = useState<
    BlueprintMeta[]
  >([]);

  const [headerSchema, setHeaderSchema] = useState<undefined | string>();
  const [requestFormat, setRequestFormat] = useState<APIRequestFormat>(
    APIRequestFormat.REQUEST_FORMAT_JSON
  );
  const [bodySchema, setBodySchema] = useState<undefined | string>();
  const [pathParamSchema, setPathParamSchema] = useState<undefined | string>();
  const [queryParamSchema, setQueryParamSchema] = useState<undefined | string>();
  const [returnSchema, setReturnSchema] = useState<undefined | string>();
  const [isInvalidPaginatedReturnSchema, setIsInvalidPaginatedReturnSchema] = useState<boolean>(
    false
  );
  const [fileSchema, setFileSchema] = useState<undefined | string>();
  const [responseType, setResponseType] = useState<APIResponseType>(APIResponseType.JSON);
  const [responseBodyAdditionalParsing, setResponseBodyAdditionalParsing] = useState<
    undefined | ResponseBodyAdditionalParsing
  >();
  const [timestampKey, setTimestampKey] = useState<undefined | string>();
  const [requestBodyBaseConfig, setRequestBodyBaseConfig] = useState<undefined | string>();
  const [requestBodyParamSchema, setRequestBodyParamSchema] = useState<undefined | string>();
  const [isLoading, setIsLoading] = useState(false);
  const { apiEndpointID, integrationID } = useParams<RouteParams>();
  const isEditingExistingEndpoint = !!apiEndpointID;
  const [isShowingPathWarningModal, setIsShowingPathWarningModal] = useState(false);
  const [isShowingChangedPathWarningModal, setIsShowingChangedPathWarningModal] = useState(false);
  const [soapRequestUseBodyTemplatingEngine, setSoapRequestUseBodyTemplatingEngine] = useState(
    false
  );
  const [disableEndpointModification, setDisableEndpointModification] = useState(false);
  const [hasPermission, setHasPermission] = useState(true);
  const [isLoadingPageData, setIsLoadingPageData] = useState(true);

  // This useEffect checks that if we are setting the return schema
  // For a paginated endpoint, it must be an array schema
  useEffect(() => {
    if (!isPaginated) {
      setIsInvalidPaginatedReturnSchema(false);
      return;
    }
    try {
      const schemaJson = returnSchema ? JSON.parse(returnSchema) : {};

      setIsInvalidPaginatedReturnSchema(
        // Array schemas must have type array and an items field
        !(schemaJson?.type == "array" && schemaJson?.items)
      );
    } catch (error) {
      setIsInvalidPaginatedReturnSchema(false);
    }
  }, [isPaginated, returnSchema]);

  useDocumentTitle(`${integrationName ?? ""} - ${name ?? ""}`, [integrationName, name]);

  const convertFormatSubtypeToRequestType = (format: APIRequestFormatSubtype) => {
    switch (format) {
      case APIRequestFormatSubtype.REST_JSON:
        return APIRequestType.REST;
      case APIRequestFormatSubtype.SOAP_XML:
        return APIRequestType.SOAP;
      case APIRequestFormatSubtype.GRAPHQL_JSON:
        return APIRequestType.GRAPHQL;
    }
  };

  const isValidPath = (path: string) => {
    if (path.length > 0 && path[0] === "/" && !path.includes("?") && !path.includes("=")) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    fetchWithAuth({
      path: `/integrations/${integrationID}`,
      method: "GET",
      onResponse: (data: Integration) => {
        setIntegrationName(data.name);
        setBaseAPIURL(data.base_api_url);
        setIsLoadingPageData(false);
      },
      onError: (error?: Response) => {
        if (error?.status === 403) {
          setHasPermission(false);
          setIsLoadingPageData(false);
        }
      },
    });

    fetchWithAuth({
      path: `/integrations/${integrationID}/blueprints`,
      method: "GET",
      onResponse: (data) => {
        const res = data?.blueprints.filter(
          (blueprint: BlueprintMeta) =>
            blueprint.operation_type == BlueprintOperationType.ENDPOINT_MODIFICATION
        );
        setEndpointModificationBlueprints(res);
        if (!isEditingExistingEndpoint && res) {
          setEndpointModificationBlueprint(res[0].blueprint_id);
        }
      },
    });

    if (isEditingExistingEndpoint) {
      fetchWithAuth({
        path: `/integrations/${integrationID}/api-endpoints/${apiEndpointID}`,
        method: "GET",
        onResponse: (data: APIEndpoint) => {
          setName(data.name);
          setMethod(data.method);
          setPath(data.path);
          setOldPath(data.path);
          setBaseAPIURLOverride(data.base_api_url_override);
          setOldURLOverride(data.base_api_url_override);
          setRequestType(data.request_type);
          setIsPaginated(data.is_paginated);
          setIsProxy(data.is_proxy);
          setIsLiveRequest(data.is_live_request);
          setHeaderSchema(JSON.stringify(data.header_schema));
          setBodySchema(JSON.stringify(data.body_schema));
          setPathParamSchema(JSON.stringify(data.path_param_schema));
          setQueryParamSchema(JSON.stringify(data.query_param_schema));
          setRequestFormat(data.request_format);
          setFileSchema(JSON.stringify(data.file_schema));
          setResponseType(data.response_type);
          setResponseBodyAdditionalParsing(data.response_body_additional_parsing);
          setReturnSchema(JSON.stringify(data.return_schema));
          setTimestampKey(data.timestamp_param_key);
          setTimestampParamLocation(data.timestamp_param_location);
          setRequestBodyBaseConfig(data.request_body_base_config);
          setRequestBodyParamSchema(JSON.stringify(data.request_body_param_schema));
          setRequestBodyFormatSubtype(data.request_body_format_subtype);
          setSoapRequestUseBodyTemplatingEngine(data?.soap_request_use_body_templating_engine);
          setEndpointModificationBlueprint(
            data?.endpoint_modification_blueprint ? data?.endpoint_modification_blueprint : "none"
          );
          setDisableEndpointModification(data.disable_endpoint_modification);
          setIsLoadingPageData(false);
        },
        onError: (error?: Response) => {
          if (error?.status === 403) {
            setHasPermission(false);
            setIsLoadingPageData(false);
          }
        },
      });
    }
  }, [
    setIntegrationName,
    setBaseAPIURL,
    setBaseAPIURLOverride,
    setMethod,
    setPath,
    setRequestType,
    setIsPaginated,
    setIsProxy,
    setIsLiveRequest,
    setHeaderSchema,
    setBodySchema,
    setPathParamSchema,
    setQueryParamSchema,
    setRequestFormat,
    setFileSchema,
    setResponseType,
    setResponseBodyAdditionalParsing,
    setReturnSchema,
    setTimestampKey,
    setRequestBodyBaseConfig,
    setRequestBodyParamSchema,
    setEndpointModificationBlueprint,
    setEndpointModificationBlueprints,
    setDisableEndpointModification,
    isEditingExistingEndpoint,
    integrationID,
    apiEndpointID,
  ]);

  useEffect(() => {
    if (path) {
      const { pathParamJSONSchema, queryParamJSONSchema } = getURLParamJSONSchemaFromPath(path);
      setQueryParamSchema(queryParamJSONSchema);
      setPathParamSchema(pathParamJSONSchema);
    }
  }, [path]);

  // For XML request bodies, generate a schema for the required variables
  useEffect(() => {
    if (
      requestBodyBaseConfig &&
      requestType === APIRequestType.SOAP &&
      !soapRequestUseBodyTemplatingEngine
    ) {
      const XMLRequestBodyParamSchema = getXMLRequestBodyParamSchema(requestBodyBaseConfig);
      setRequestBodyParamSchema(XMLRequestBodyParamSchema);
    }
  }, [requestBodyBaseConfig, requestType]);

  const isFormComplete = method !== undefined && path !== undefined && requestType !== undefined;
  const callCreateOrUpdateEndpoint = (onSuccess: () => void) => {
    if (method && path && isPaginated !== undefined) {
      createOrUpdateEndpoint({
        apiEndpointID,
        integrationID,
        isPaginated,
        isProxy,
        isLiveRequest,
        name,
        method,
        path,
        baseAPIURLOverride,
        pathParamSchema,
        queryParamSchema,
        headerSchema,
        requestFormat,
        bodySchema,
        returnSchema,
        fileSchema,
        timestampKey,
        timestampParamLocation,
        requestType,
        responseType,
        responseBodyAdditionalParsing,
        requestBodyFormatSubtype,
        requestBodyBaseConfig,
        requestBodyParamSchema,
        soapRequestUseBodyTemplatingEngine,
        disableEndpointModification,
        onSuccess,
        onError: () => {
          showErrorToast("Save failed.");
          setIsShowingPathWarningModal(false);
          setIsShowingChangedPathWarningModal(false);
          setIsLoading(false);
        },
      });
    }
  };

  function SaveEndpointButton(props: {
    text: string;
    onSuccess: () => void;
    overrideWarning?: boolean;
  }) {
    const { text, onSuccess, overrideWarning } = props;
    return (
      <Tooltip
        className="w-100"
        title={
          isInvalidPaginatedReturnSchema
            ? "A paginated endpoint must have an array return schema"
            : null
        }
      >
        <SpinnerButton
          text={text}
          className="btn-block btn-primary"
          disabled={!isFormComplete || isInvalidPaginatedReturnSchema}
          isLoading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (method && path && isPaginated !== undefined) {
              if (!isEditingExistingEndpoint) {
                // Raise warning for invalid paths on new endpoints
                if (isValidPath(path) || overrideWarning) {
                  callCreateOrUpdateEndpoint(onSuccess);
                } else {
                  setIsLoading(false);
                  setIsShowingPathWarningModal(true);
                }
              } else {
                // Raise warning for editing existing paths
                if (
                  (path === oldPath && oldURLOverride === baseAPIURLOverride) ||
                  overrideWarning
                ) {
                  callCreateOrUpdateEndpoint(onSuccess);
                } else {
                  setIsLoading(false);
                  setIsShowingChangedPathWarningModal(true);
                }
              }
            }
          }}
        />
      </Tooltip>
    );
  }

  function WarningModal(props: {
    show: boolean;
    title: string;
    description: string;
    onHide: () => void;
    onSuccess: () => void;
  }) {
    const { show, title, description, onHide, onSuccess } = props;

    return (
      <MergeModal show={show} onHide={onHide} title={title} bodyClassName="overflow-hidden">
        <Row>
          <Col>
            <b>{description}</b>
          </Col>
        </Row>
        <Row className="mt-6">
          <Col>
            <Button className="btn-block" onClick={onHide}>
              Continue Editing
            </Button>
          </Col>
          <Col>
            <SaveEndpointButton text="Save endpoint" onSuccess={onSuccess} overrideWarning={true} />
          </Col>
        </Row>
      </MergeModal>
    );
  }

  return isLoadingPageData ? (
    <div className="flex items-center justify-center">
      <Spinner />
    </div>
  ) : hasPermission ? (
    <div className="ml-6">
      <Row>
        <Col>
          <HeaderBar
            pretitle={"Blueprint Dashboard"}
            title={
              (isEditingExistingEndpoint ? "Edit" : "Add New") + " Endpoint for " + integrationName
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="w-65">
            <Col className="col-12 col-lg-12 col-xl-12">
              <InputFormField
                currentValue={name}
                onChange={(name) => setName(name)}
                placeholder=""
                title={"Name"}
                subtitle={"A name for the API endpoint."}
              />
              <DropdownFormField
                currentValue={method}
                onChange={(e) => {
                  setMethod(e.target.value);
                }}
                placeholder="Select a method."
                title="Method Type*"
                subtitle={"Select the method type for the request."}
                choices={Object.values(APIEndpointMethod).map((method) => {
                  switch (method) {
                    case APIEndpointMethod.GET:
                      return { name: "GET", id: method };
                    case APIEndpointMethod.OPTIONS:
                      return { name: "OPTIONS", id: method };
                    case APIEndpointMethod.HEAD:
                      return { name: "HEAD", id: method };
                    case APIEndpointMethod.PUT:
                      return { name: "PUT", id: method };
                    case APIEndpointMethod.PATCH:
                      return { name: "PATCH", id: method };
                    case APIEndpointMethod.POST:
                      return { name: "POST", id: method };
                    case APIEndpointMethod.DELETE:
                      return { name: "DELETE", id: method };
                    default:
                      throw "Step Type Not Found";
                  }
                })}
              />
              <InputFormField
                currentValue={path}
                onChange={(path) => setPath(path)}
                placeholder=""
                title={"Path*"}
                subtitle={
                  "The URL for the API endpoint. Include any parameters with the format {argument}. Include the '/' in the beginning."
                }
                example={
                  "Ex. For https://api.zenefits.com/core/companies/1/people, because https://api.zenefits.com is the base API URL, just enter /core/companies/{company_id}/people."
                }
                prepend={baseAPIURLOverride ? baseAPIURLOverride : baseAPIURL}
              />
              <InputFormField
                currentValue={baseAPIURLOverride}
                onChange={(baseAPIURLOverride) => setBaseAPIURLOverride(baseAPIURLOverride)}
                title={"Edit base API URL override"}
                subtitle={
                  "Overrides integration level base URL with one specific to this endpoint."
                }
              />
              <InputFormField
                currentValue={timestampKey}
                onChange={(timestampKey) => setTimestampKey(timestampKey)}
                title={"Timestamp Query Parameter Key"}
                subtitle={
                  "If the endpoint allows you to filter by a created/modified timestamp using query parameters in the url, put the key for that query here."
                }
                example={
                  "Ex. Most Greenhouse endpoints can take in a last_activity_after parameter, so this should be last_activity_after. If using XML, include the parameter name in your SOAP Request Body wrapped in {} (e.g. {last_activity_after}) and set the Timestamp param location to 'BODY'."
                }
              />
              <DropdownFormField
                currentValue={timestampParamLocation}
                onChange={(e) => {
                  setTimestampParamLocation(e.target.value);
                }}
                placeholder={
                  !timestampKey
                    ? "Add a timestamp param key first"
                    : "Select a timestamp param location"
                }
                title="Timestamp Param Location"
                subtitle="Select where to place the timestamp param key, if it exists."
                disabled={!timestampKey}
                choices={Object.values(TimestampParamLocation).map((location) => {
                  switch (location) {
                    case TimestampParamLocation.QUERY_PARAM:
                      return { name: "QUERY_PARAM", id: location };
                    case TimestampParamLocation.BODY:
                      return { name: "BODY", id: location };
                    default:
                      throw "Location Not Found";
                  }
                })}
              />
              <Form.Group controlId="pagination">
                <Form.Check
                  type="checkbox"
                  label="Select if this endpoint is paginated"
                  onChange={() => {
                    setIsPaginated(!isPaginated);
                    setIsProxy(false);
                    setIsLiveRequest(false);
                  }}
                  checked={isPaginated}
                />
              </Form.Group>
              <Form.Group controlId="proxy">
                <Form.Check
                  type="checkbox"
                  label="Select if this endpoint is proxy"
                  onChange={() => {
                    setIsProxy(!isProxy);
                    setIsPaginated(false);
                    setIsLiveRequest(false);
                  }}
                  checked={isProxy}
                />
              </Form.Group>
              <Form.Group controlId="live_request">
                <Form.Check
                  type="checkbox"
                  label="Select if this endpoint is a live request"
                  onChange={() => {
                    setIsLiveRequest(!isLiveRequest);
                    setIsProxy(false);
                    setIsPaginated(false);
                  }}
                  checked={isLiveRequest}
                />
              </Form.Group>
              <DropdownFormField
                currentValue={requestBodyFormatSubtype}
                onChange={(e) => {
                  // dual write to deprecated request type as well as new format
                  setRequestType(convertFormatSubtypeToRequestType(e.target.value));
                  setRequestBodyFormatSubtype(e.target.value);
                }}
                placeholder=""
                title="Request Format Type*"
                subtitle={"Select what type of message format this endpoint accepts."}
                choices={Object.values(APIRequestFormatSubtype).map(
                  (subtype: APIRequestFormatSubtype) => {
                    return { name: subtype, id: subtype };
                  }
                )}
              />

              <DropdownFormField
                currentValue={endpointModificationBlueprint}
                onChange={(e) => {
                  setEndpointModificationBlueprint(e.target.value);
                }}
                placeholder="Please select your endpoint modification blueprint"
                title="Endpoint Modification Blueprint"
                subtitle={"The endpoint modifier blueprint to be used by this endpoint"}
                choices={[...endpointModificationBlueprints].map((blueprint) => ({
                  id: blueprint.blueprint_id,
                  name: blueprint.name,
                }))}
                disabled={disableEndpointModification}
              />
              <Form.Group controlId="disable_endpoint_modification">
                <Form.Check
                  type="checkbox"
                  label="Select this to disable endpoint modifications for this endpoint"
                  onChange={() => {
                    setDisableEndpointModification(!disableEndpointModification);
                  }}
                  checked={disableEndpointModification}
                />
              </Form.Group>

              <JSONSchemaConverterFormField
                currentValue={queryParamSchema}
                setValue={(queryParamSchema) => setQueryParamSchema(queryParamSchema)}
                numRows={5}
                title={"Query Parameter Schema"}
                subtitle={`Query string arguments within the API URL. Please only include text after the question mark.`}
                example={
                  'Ex. For https://api.fakecompany.com/authors?first_name=john&last_name=smith, enter {"properties":{"first_name":{"type":"string"},"last_name":{"type":"string"}}}'
                }
              />
              <JSONSchemaConverterFormField
                currentValue={pathParamSchema}
                setValue={(pathParamSchema) => setPathParamSchema(pathParamSchema)}
                numRows={5}
                title={"Path Parameter Schema"}
                subtitle={"Path arguments included in the API URL."}
                example={'Ex. {"id":"string"} for https://api.zenefits.com/core/companies/{id}'}
              />

              <JSONSchemaConverterFormField
                currentValue={headerSchema}
                setValue={(headerSchema) => setHeaderSchema(headerSchema)}
                numRows={5}
                title={"Header Schema"}
                subtitle={"Header arguments passed into the API request."}
              />
              {requestType === APIRequestType.REST && (
                <>
                  {requestFormat !== APIRequestFormat.REQUEST_FORMAT_OCTET_STREAM && (
                    <JSONSchemaConverterFormField
                      currentValue={bodySchema}
                      setValue={(bodySchema) => setBodySchema(bodySchema)}
                      numRows={5}
                      title={"Body Schema"}
                      subtitle={"Body arguments passed into the API request."}
                    />
                  )}
                  <DropdownFormField
                    currentValue={requestFormat}
                    onChange={(e) => setRequestFormat(e.target.value)}
                    placeholder=""
                    title="Request Format*"
                    subtitle={"Select the format of the request data to send."}
                    choices={Object.values(APIRequestFormat).map((id) => ({
                      id,
                      name: nameFromApiRequestFormat(id),
                    }))}
                  />
                  {requestFormat === APIRequestFormat.REQUEST_FORMAT_MULTIPART_FORM && (
                    <JSONSchemaConverterFormField
                      currentValue={fileSchema}
                      setValue={(fileSchema) => setFileSchema(fileSchema)}
                      numRows={5}
                      title="File Schema"
                      subtitle="Files passed into multi-part API requests"
                    />
                  )}
                </>
              )}
              {requestType === APIRequestType.SOAP && (
                <>
                  {
                    <XMLConverterFormField
                      currentValue={requestBodyBaseConfig}
                      setValue={(soapRequestBodyFormat) => {
                        setRequestBodyBaseConfig(soapRequestBodyFormat);
                      }}
                      numRows={5}
                      title={"SOAP Request Body Format"}
                      subtitle={
                        "Body String for SOAP Request. Include any parameters with the format {argument}."
                      }
                      example={
                        "Ex. For <b:LastName>like (Smith)</b:LastName> enter <b:LastName>like ({LastName})</b:LastName>"
                      }
                    />
                  }
                  <Form.Group controlId="templatingEngine">
                    <Form.Check
                      type="checkbox"
                      label="Select to use Genshi request body templating engine"
                      onChange={() => {
                        setSoapRequestUseBodyTemplatingEngine(!soapRequestUseBodyTemplatingEngine);
                      }}
                      checked={soapRequestUseBodyTemplatingEngine}
                    />
                  </Form.Group>
                  <JSONSchemaConverterFormField
                    currentValue={requestBodyParamSchema}
                    setValue={(soapRequestBodySchema) => {
                      // dual write to request body param schema in prep for deprecating SOAP specific fields on endpoint
                      setRequestBodyParamSchema(soapRequestBodySchema);
                    }}
                    numRows={5}
                    title={"SOAP Request Param Schema"}
                    subtitle={"Body arguments passed into the SOAP request body string."}
                  />
                </>
              )}
              {requestBodyFormatSubtype === APIRequestFormatSubtype.GRAPHQL_JSON && (
                <>
                  <InputFormField
                    currentValue={requestBodyBaseConfig}
                    onChange={(newRequestBodyBaseConfig) => {
                      setRequestBodyBaseConfig(newRequestBodyBaseConfig);
                    }}
                    placeholder=""
                    title={"GraphQL Request Body Base Config"}
                    subtitle={
                      "Body String for GraphQL Request. Include any variables with the format $<param_name>"
                    }
                    example={
                      "Ex. query Team ($id: String!) { team(id: $id) { id name issues { nodes { id title description assignee { id name } createdAt archivedAt } } } }"
                    }
                  />
                  <JSONSchemaConverterFormField
                    currentValue={requestBodyParamSchema}
                    setValue={(newRequestBodyParamSchema) =>
                      setRequestBodyParamSchema(newRequestBodyParamSchema)
                    }
                    numRows={5}
                    title={"GraphQL Request Body Param Schema"}
                    subtitle={"Body arguments passed into the Graphql request body string."}
                  />
                </>
              )}
              <DropdownFormField
                currentValue={responseType}
                onChange={(e) => setResponseType(e.target.value)}
                placeholder=""
                title="Response Type*"
                subtitle={"Select the type of data returned by the API."}
                choices={Object.values(APIResponseType).map((id) => ({
                  id,
                  name: id,
                }))}
              />
              <FormField
                title="Response Body Additional Parsing"
                subtitle={
                  "Optional: additional parsing to run on the response body after initial parsing, specifying path + parser. Remember to use a fully parsed example response as input to json schema creation for Return Schema."
                }
                example={"Ex. path: data,nestedData,nestedXml  parser: XML"}
              >
                <ResponseBodyAdditionalParsingForm
                  responseBodyAdditionalParsing={responseBodyAdditionalParsing ?? []}
                  setResponseBodyAdditionalParsing={setResponseBodyAdditionalParsing}
                />
              </FormField>
              {(responseType === APIResponseType.JSON || responseType === APIResponseType.XML) && (
                <JSONSchemaConverterFormField
                  currentValue={returnSchema}
                  setValue={(returnSchema) => setReturnSchema(returnSchema)}
                  numRows={5}
                  title={`Return Schema${method === "GET" ? "*" : ""}`}
                  subtitle={
                    "Params returned by the API request. Please follow the directions in the right panel. Copy the final JSON schema and paste it below."
                  }
                >
                  <>
                    {isPaginated && (
                      <small className="form-text red">
                        <Badge variant="danger" className="mr-3 font-weight-bold">
                          IMPORTANT:
                        </Badge>
                        Only copy and paste the array of the items that are to be looped through in
                        the blueprint editor. Include the brackets on both ends of the array when
                        copying the JSON from the right panel.
                      </small>
                    )}
                  </>
                </JSONSchemaConverterFormField>
              )}
              <Row>
                <Col>
                  <SaveEndpointButton
                    text="Save and continue editing endpoint"
                    onSuccess={() => {
                      showSuccessToast(
                        isEditingExistingEndpoint
                          ? "Successfully updated endpoint!"
                          : "Successfully created endpoint!"
                      );
                      setIsLoading(false);
                    }}
                  />
                </Col>
                <Col>
                  <SaveEndpointButton
                    text="Save endpoint"
                    onSuccess={() => {
                      navigateToAPIEndpointsSubtab(history, integrationID);
                      showSuccessToast(
                        isEditingExistingEndpoint
                          ? "Successfully updated endpoint!"
                          : "Successfully created endpoint!"
                      );
                      setIsLoading(false);
                    }}
                  />
                </Col>
              </Row>
              <Row className="text-center mt-3 mb-9">
                <Col>
                  <Link to={getAPIEndpointsPath(integrationID)} className="text-muted">
                    Cancel changes
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
          <APIEndpointEditFormRightPanel
            integrationID={integrationID}
            method={method || ""}
            path={path || ""}
            baseAPIURLOverride={baseAPIURLOverride || ""}
            headerSchema={headerSchema || ""}
            bodySchema={bodySchema || ""}
            pathParamSchema={pathParamSchema || ""}
            queryParamSchema={queryParamSchema || ""}
            fileSchema={fileSchema || ""}
            requestType={requestType || ""}
            requestFormat={requestFormat || APIRequestFormat.REQUEST_FORMAT_JSON}
            responseType={responseType || APIResponseType.JSON}
            responseBodyAdditionalParsing={responseBodyAdditionalParsing}
            requestBodyBaseConfig={requestBodyBaseConfig || ""}
            requestBodyParamSchema={requestBodyParamSchema || ""}
            requestBodyFormatSubtype={requestBodyFormatSubtype || ""}
            soapRequestUseBodyTemplatingEngine={soapRequestUseBodyTemplatingEngine || false}
          />
        </Col>
      </Row>
      <WarningModal
        show={isShowingPathWarningModal}
        onHide={() => setIsShowingPathWarningModal(false)}
        title={"Invalid Path"}
        description="Your path is invalid. Valid paths begin with '/' and do not include query parameters.
        Are you sure you want to save this endpoint?"
        onSuccess={() => {
          showSuccessToast(
            isEditingExistingEndpoint
              ? "Successfully updated endpoint!"
              : "Successfully created endpoint!"
          );
          setIsLoading(false);
          setIsShowingPathWarningModal(false);
        }}
      />
      <WarningModal
        show={isShowingChangedPathWarningModal}
        onHide={() => setIsShowingChangedPathWarningModal(false)}
        title={"WARNING!"}
        description="Once the blueprint is updated this will update the remote data paths for all users. 
        Please make sure you've communicated with users before proceeding. Are you sure you want to make this change?"
        onSuccess={() => {
          showSuccessToast(
            isEditingExistingEndpoint
              ? "Successfully updated endpoint!"
              : "Successfully created endpoint!"
          );
          setOldPath(path);
          setIsLoading(false);
          setIsShowingChangedPathWarningModal(false);
        }}
      />
    </div>
  ) : (
    <div className="flex items-center justify-center">
      <Text variant="h1">You do not have permission to access this page.</Text>
    </div>
  );
}

export default APIEndpointEditForm;
