import { ButtonVariant, Dialog, Text } from "@merge-api/merge-javascript-shared";
import { useState } from "react";
import PublishModalTextField from "./PublishModalTextField";

type Props = {
  open: boolean;
  onClose: () => void;
  setOverrideTicket: (overrideTicket: string) => void;
  setOverrideComment: (overrideComment: string) => void;
  setCanPublish: (isOverridden: boolean) => void;
  integrationID: string;
};

const PublishModuleOverrideChecksModal = ({
  open,
  onClose,
  setOverrideTicket,
  setCanPublish,
  setOverrideComment,
}: Props) => {
  const [ticket, setTicket] = useState<string | undefined>();
  const [comment, setComment] = useState<string | undefined>();

  return (
    <Dialog
      title="Override Checks"
      variant="md"
      open={open}
      onClose={onClose}
      primaryButtonVariant={ButtonVariant.DangerFilled}
      primaryButtonText={"Override"}
      onPrimaryButtonClick={() => {
        setOverrideComment(comment ?? "");
        setOverrideTicket(ticket ?? "");
        setCanPublish(true);
        onClose();
      }}
      primaryButtonDisabled={!ticket || !comment}
      secondaryButtonVariant={ButtonVariant.TextBlack}
      secondaryButtonText={"Cancel"}
      onSecondaryButtonClick={onClose}
    >
      <div className="">
        <div className="mb-4">
          <Text>Add some context to override</Text>
        </div>
        <PublishModalTextField
          title="Asana ticket"
          description="Link the relevant Asana ticket, so that it can be tracked in changelog."
          placeholder="https://app.asana.com/your-ticket"
          value={ticket}
          onChange={(e) => setTicket(e.target.value)}
        />
        <div className="mb-4"></div>
        <PublishModalTextField
          title="Comment"
          description="Provide a comment explaining why you’re overriding"
          placeholder="I’m overriding because ..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>
    </Dialog>
  );
};

export default PublishModuleOverrideChecksModal;
