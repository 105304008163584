import React from "react";
import { useParams } from "react-router-dom";
import EmptyStateWrapper from "../../../../shared/layout/EmptyStateWrapper";
import {
  SelectiveSyncFilterSchemaAPIResponse,
  VersionedComponentAPIActions,
  VersionedComponentAPIComponentKeys,
  VersionedComponentsAPIRequestData,
} from "../../../../versioned-components/types";
import useVersionedComponentsAPI from "../../../VersionedComponentsAPIClient";
import SelectiveSyncFilterSchemaForm from "./SelectiveSyncFilterSchemaForm";

interface Props {
  integrationID: string;
  selectiveSyncFilterSchemaId?: string | null;
}

type RouteParams = {
  selectiveSyncFilterSchemaId?: string;
};

const SelectiveSyncFilterSchemaFormRoot: React.FC<Props> = ({ integrationID }) => {
  const { selectiveSyncFilterSchemaId } = useParams<RouteParams>();

  const request_body: VersionedComponentsAPIRequestData = {
    component_key: VersionedComponentAPIComponentKeys.SelectiveSyncFilterSchema,
    component_id: selectiveSyncFilterSchemaId,
  };

  const { data, isLoading } = useVersionedComponentsAPI<SelectiveSyncFilterSchemaAPIResponse>({
    integrationID,
    versioned_components_api_action: VersionedComponentAPIActions.GET_COMPONENT_FOR_COMPONENT_CLASS,
    request_body,
    onResponse: (_: any) => {},
  });

  if (isLoading || !data) {
    return <EmptyStateWrapper isSpinner />;
  }

  return (
    <>
      <SelectiveSyncFilterSchemaForm
        selectiveSyncFilterSchemaData={data?.[selectiveSyncFilterSchemaId ?? ""]}
        integrationID={integrationID}
      />
    </>
  );
};

export default SelectiveSyncFilterSchemaFormRoot;
