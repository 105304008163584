import clsx from "clsx";
import { BlueprintCanvasViewableStepLog, StepLogIterationInfo } from "../../../models/Blueprints";
import StepCardStepLogRow from "./StepCardStepLogRow";
import StepCardStepLogButtonsHeader from "./StepCardStepLogButtonsHeader";
import { convertVariableDiffLogsToDisplayableDictionary } from "../../blueprint-editor/utils/BlueprintStepIOUtils";
import StepCardStepLogLoopInitialization from "./step-log-loop-initialization/StepCardStepLogLoopInitialization";
import useBlueprintContext from "../../blueprint-editor/context/useBlueprintContext";

interface Props {
  stepLog: BlueprintCanvasViewableStepLog;
  handleSelectStepLogFromIteration?: (
    stepID: string,
    selectedIterationInfo: StepLogIterationInfo
  ) => void;
}

/**
 * Renders step log in canvas, next to step card
 */
const StepCardStepLog = ({ stepLog, handleSelectStepLogFromIteration }: Props) => {
  const { selectedStepLog, setSelectedStepLog } = useBlueprintContext();

  const isHighlightedStepLog = selectedStepLog?.index_in_raw_logs === stepLog.index_in_raw_logs;
  const iterationInfo = stepLog.iteration_info;
  const iterationIndex = iterationInfo.index_in_loop_iterations;
  const totalIterations = iterationInfo.total_iterations;

  // Convert variable diff logs into displayable section
  const variableDiffLogsAsDict: Record<
    string,
    any
  > = convertVariableDiffLogsToDisplayableDictionary(stepLog.step_io_log.variable_diff_logs);

  // Handle iteration selection
  const handleSelectIteration = (selectedIterationIndex: number) => {
    if (handleSelectStepLogFromIteration) {
      const newIterationInfo: StepLogIterationInfo = {
        ...iterationInfo,
        index_in_loop_iterations: selectedIterationIndex,
      };
      handleSelectStepLogFromIteration(stepLog.step_io_log.step_id, newIterationInfo);
    }
  };

  // Defines that dropdown selection in header is for "Iteration"
  const itemInfo =
    iterationIndex !== undefined
      ? {
          title: "Iteration",
          itemIndex: iterationIndex,
          totalItems: totalIterations,
          handleSelectItem: handleSelectIteration,
        }
      : undefined;

  // Handles when step log card is clicked on
  const handleSelectStepLog = () => {
    setSelectedStepLog({
      step_id: stepLog.step_io_log.step_id,
      index_in_raw_logs: stepLog.index_in_raw_logs,
    });
  };

  return (
    <div className="flex flex-row items-center mr-8 ml-1">
      <hr className="m-0 border-dashed border border-gray-30 w-[24px]" />
      <div
        className={clsx(
          "flex flex-col bg-gray-0 w-[240px] rounded-md p-2 cursor-pointer",
          isHighlightedStepLog ? "border-[3px] border-blue-20" : "border border-gray-10"
        )}
        onClick={() => handleSelectStepLog()}
      >
        <StepCardStepLogButtonsHeader
          stepLog={stepLog}
          isHighlightedStepLog={isHighlightedStepLog}
          itemInfo={itemInfo}
        />
        <StepCardStepLogRow title="Input" value={JSON.stringify(stepLog.step_io_log.inputs)} />
        <StepCardStepLogRow title="Output" value={JSON.stringify(stepLog.step_io_log.outputs)} />
        {Object.keys(variableDiffLogsAsDict).length > 0 && (
          <StepCardStepLogRow title="Variab." value={JSON.stringify(variableDiffLogsAsDict)} />
        )}
      </div>
      {stepLog.loop_initialization_viewable_step_log && (
        <StepCardStepLogLoopInitialization
          stepLog={stepLog.loop_initialization_viewable_step_log}
          handleSelectStepLogFromIteration={handleSelectStepLogFromIteration}
        />
      )}
    </div>
  );
};

export default StepCardStepLog;
