import { Button, ButtonVariant, Tooltip } from "@merge-api/merge-javascript-shared";
import { BlueprintSearchResultBlueprint } from "../../../../../models/blueprint-search/BlueprintSearchOutputModels";
import { BlueprintOperationType, Blueprint } from "../../../../../models/Blueprints";
import { Plus } from "lucide-react";
import { useHistory } from "react-router-dom";
import { navigateToBlueprintEditor } from "../../../../../router/RouterUtils";
import { createBlueprint } from "../../../../blueprint-editor/utils/BlueprintEditorAPIClient";
import { showErrorToast } from "../../../../shared/Toasts";
import { blueprintWriteOperations } from "../../../../../models/Helpers";

interface Props {
  blueprintResult: BlueprintSearchResultBlueprint;
  integrationID: string;
}

const BlueprintSearchCardMetaBlueprintButton = ({ blueprintResult, integrationID }: Props) => {
  const history = useHistory();

  const canCreateMetaBlueprint = blueprintWriteOperations.includes(
    blueprintResult.operation_type as BlueprintOperationType
  );

  if (!canCreateMetaBlueprint) return null;

  if (blueprintResult.meta_blueprint_version_id) {
    return (
      <Button
        onClick={(event) => {
          event.stopPropagation();
          if (blueprintResult.meta_blueprint_version_id) {
            navigateToBlueprintEditor(
              history,
              integrationID,
              blueprintResult.meta_blueprint_version_id
            );
          }
        }}
        size="md"
        variant={ButtonVariant.TertiaryWhite}
      >
        View Meta Blueprint
      </Button>
    );
  } else {
    return (
      <Tooltip title="This Blueprint has no Meta Blueprint. Click to add one.">
        <Button
          onClick={(event) => {
            event.stopPropagation();
            createBlueprint({
              integrationID,
              operationType: BlueprintOperationType.META,
              category: blueprintResult.category || "",
              associatedBlueprintIDForMeta: blueprintResult.id,
              onSuccess: (data: Blueprint) =>
                navigateToBlueprintEditor(history, integrationID, data.version.id),
              onError: () => showErrorToast("Failed to create Meta Blueprint"),
            });
          }}
          size="md"
          variant={ButtonVariant.TertiaryWhite}
          leftIcon={<Plus size={16} />}
        >
          Meta Blueprint
        </Button>
      </Tooltip>
    );
  }
};

export default BlueprintSearchCardMetaBlueprintButton;
