import { Alert, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import {
  BlueprintSearchResultBlueprint,
  BlueprintSearchResultMatchedStep,
} from "../../../../../models/blueprint-search/BlueprintSearchOutputModels";
import { navigateToBlueprintEditor } from "../../../../../router/RouterUtils";
import { ChevronRight } from "lucide-react";
import { useHistory } from "react-router-dom";

interface Props {
  blueprintResult: BlueprintSearchResultBlueprint;
  integrationID: string;
  matchedStep: BlueprintSearchResultMatchedStep;
}

const BlueprintSearchCardMatchedStepRow = ({
  blueprintResult,
  integrationID,
  matchedStep,
}: Props) => {
  const history = useHistory();

  const relevantBlueprintVersionID =
    blueprintResult.published_blueprint_version?.id ?? blueprintResult.latest_blueprint_version.id;
  const textStepID = matchedStep.step_id;
  const textStepType = matchedStep.step_type ? `(${matchedStep.step_type})` : "";
  const textStepDetails = matchedStep.details ? ` — ${matchedStep.details}` : "";

  const renderTooltipTitle = () => {
    return (
      <div className="flex flex-col p-2 space-y-1">
        <Text variant="h6">{textStepID}</Text>
        {matchedStep.step_type && <Text>({matchedStep.step_type})</Text>}
        {matchedStep.details && <Text>{matchedStep.details}</Text>}
      </div>
    );
  };

  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
        navigateToBlueprintEditor(
          history,
          integrationID,
          relevantBlueprintVersionID,
          event.metaKey || event.ctrlKey,
          `step=${matchedStep.step_id}`
        );
      }}
    >
      <Tooltip title={renderTooltipTitle()} className="w-full" placement="left">
        <Alert color="gray" className="hover:bg-gray-20">
          <div className="flex flex-row justify-between w-full">
            <div className="flex flex-row w-10/12 truncate text-start space-x-1">
              <Text variant="h6">{textStepID}</Text>
              <Text className="truncate">{textStepType + textStepDetails}</Text>
            </div>
            <ChevronRight size={16} className="text-gray-70" />
          </div>
        </Alert>
      </Tooltip>
    </div>
  );
};

export default BlueprintSearchCardMatchedStepRow;
