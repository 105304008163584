import { useCallback, useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast } from "../../../shared/Toasts";
import { APIEndpointIntegrationBuilder } from "../../utils/Entities";

interface loadAPIEndpointsProps {
  integrationID: string;
  setAPIEndpoints: React.Dispatch<
    React.SetStateAction<APIEndpointIntegrationBuilder[] | undefined>
  >;
}

const useLoadAPIEndpoints = ({ integrationID, setAPIEndpoints }: loadAPIEndpointsProps) => {
  // state
  const [isLoadingAPIEndpoints, setIsLoadingAPIEndpoints] = useState(true);

  // function to load API endpoints
  const loadAPIEndpoints = useCallback(() => {
    setIsLoadingAPIEndpoints(true);
    fetchWithAuth({
      path: `/integrations/integration-builder/integration/${integrationID}/api-endpoints`,
      method: "GET",
      onResponse: (data: APIEndpointIntegrationBuilder[]) => {
        setAPIEndpoints(data);
        setIsLoadingAPIEndpoints(false);
      },
      onError: () => {
        showErrorToast("Failed to fetch API endpoints for integration.");
        setAPIEndpoints([]);
        setIsLoadingAPIEndpoints(false);
      },
    });
  }, [integrationID, setAPIEndpoints]);

  return { isLoadingAPIEndpoints, loadAPIEndpoints };
};

export default useLoadAPIEndpoints;
