import { Badge, Text } from "@merge-api/merge-javascript-shared";
import { APIEndpointParameter } from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_APIEndpointParameter";

type Props = {
  apiEndpointParameter?: APIEndpointParameter;
  editable?: boolean;
};

const APIEndpointParameterChoiceRow = ({ apiEndpointParameter }: Props) => {
  const apiEndpointFilterInfo = apiEndpointParameter;

  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row items-center">
        <Text variant="h6" className="mr-2">
          {apiEndpointFilterInfo?.api_endpoint_id?.name}
        </Text>
        <Badge className="mr-3">
          <Text variant="sm-mono">{apiEndpointFilterInfo?.api_endpoint_id?.path}</Text>
        </Badge>
      </div>
      <Text variant="md-mono">{apiEndpointFilterInfo?.key_path?.[0]}</Text>
    </div>
  );
};

export default APIEndpointParameterChoiceRow;
