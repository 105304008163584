import { Col, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import { palette } from "../../../styles/theme/colors";
import { useState, useEffect } from "react";
import { JSONSchemaTypeOption } from "../utils/BlueprintDataTransformUtils";
import Dropdown from "../right-panel/Dropdown";
import { Button } from "react-bootstrap";
import { validateKeyName } from "./BlueprintValidationEditorUtils";

const BottomBorder = css`
  border-bottom: 1px solid ${palette.border};
`;

const BorderedFieldRowContent = styled(Row)`
  padding-left: 88px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: end;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
`;

const FieldName = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 13px;
  font-family: var(--font-family-monospace);
`;

const FieldInputErrorText = styled.div`
  display: flex;
  font-size: 11px;
  color: red;
`;

const FieldInputHelpText = styled.div`
  display: flex-row;
  font-size: 11px;
  padding: 5px;
  padding-left: 0;
  padding-top: 10px;
`;

const HelpTextList = styled.ul`
  padding: 0;
  padding-top: 5px;
`;

// const StyledButtonDescription = styled.div`{
//   font-weight: 600;
//   color: #8492a6;
//   font-size: 12px;
//   padding: 4px 0;
// }`;

const StyledButton = styled(Button)<{ isSecondary: boolean }>`
  font-size: 12px;
  line-height: 16px;
  margin-left: 8px;
  padding: 8px 16px;
  ${({ isSecondary }) => isSecondary && BottomBorder}
`;

const EditButton = styled(Button)`
  font-weight: 600;
  padding: 7px 20px;
  margin-right: 24px;
  font-size: 12px;
`;

interface Props {
  addField: (fieldKey: string, fieldType: JSONSchemaTypeOption) => void;
  level: number;
}

const BlueprintValidationEditorField = ({ addField }: Props) => {
  const [fieldKey, setFieldKey] = useState<string>("");
  const [fieldType, setFieldType] = useState<JSONSchemaTypeOption>();
  const [isEditing, setIsEditing] = useState(false);
  const [isKeyNameValid, setIsKeyNameValid] = useState(false);

  useEffect(() => {
    setIsKeyNameValid(validateKeyName(fieldKey));
  }, [fieldKey]);

  const isDisabled = !fieldType || !fieldKey || fieldKey === "" || !isKeyNameValid;

  return (
    <BorderedFieldRowContent>
      {!isEditing ? (
        <EditButton onClick={() => setIsEditing(true)}>
          <b>Add New Field</b>
        </EditButton>
      ) : (
        <>
          <Col>
            <FieldName>New Field</FieldName>
            <FieldInputHelpText className="text-gray-800">
              <u>GUIDELINE FOR NAMING</u>
              <br />
              <HelpTextList>
                <li>Only add if truly needed.</li>
                <li>
                  If Merge ID, name as <b>XXX_merge_id</b>
                </li>
                <li>
                  If remote ID, name as <b>XXX_remote_id</b>
                </li>
                <li>
                  If date, name as <b>remote_XXX_at</b>
                </li>
                <li>
                  If field is part of another object "YYY", name as <b>YYY_XXX</b>
                </li>
              </HelpTextList>
              See full guideline{" "}
              <a
                href="https://www.notion.so/mergeapi/Naming-guidelines-for-integration_params-26cc9aaeb6064a72a36fd77080d4fe05"
                target="_blank"
              >
                here
              </a>
              .
            </FieldInputHelpText>
          </Col>
          <Col>
            <input
              className="form-control mr-6"
              onChange={(e) => setFieldKey(e.target.value)}
              placeholder="Field Key"
              value={fieldKey}
              autoComplete="no"
            />
            {!isKeyNameValid ? (
              <FieldInputErrorText>
                Use lowercase only.
                <br />
                Use underscore, not spaces.
              </FieldInputErrorText>
            ) : (
              <></>
            )}
          </Col>
          <Col>
            <Dropdown
              choices={Object.values(JSONSchemaTypeOption).map((option) => ({
                name: option,
                id: option,
              }))}
              currentValue={fieldType}
              placeholder={"Field Type"}
              onChange={(e) => setFieldType(e.target.value)}
            />
          </Col>
          <Col>
            <StyledButton
              disabled={isDisabled}
              onClick={() => {
                if (fieldType) {
                  addField(fieldKey, fieldType);
                  setIsEditing(false);
                  setFieldKey("");
                  setFieldType(undefined);
                }
              }}
            >
              <b>Create</b>
            </StyledButton>
            <StyledButton variant="white" onClick={() => setIsEditing(false)}>
              <b>Cancel</b>
            </StyledButton>
          </Col>
        </>
      )}
    </BorderedFieldRowContent>
  );
};

export default BlueprintValidationEditorField;
