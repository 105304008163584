import { Button, ButtonVariant, Spinner, Text } from "@merge-api/merge-javascript-shared";
import {
  ASYNC_TASK_EXECUTION_QUEUED_STATUSES,
  AsyncTaskExecution,
  BlueprintRunnerExecutionResponse,
} from "../../../../models/Blueprints";
import { formatRecentTimestamp } from "../../../../models/Helpers";
import { BlueprintRunnerTestRunStatusBadge } from "../../../shared/MergeBadges";
import IntegrationEditorProducedModelsModal from "../../../shared/integration-editor-base/IntegrationEditorProducedModelsModal";
import LeftPanelSectionHeader from "../shared/LeftPanelSectionHeader";

interface Props {
  isTestingBlueprintAsync: boolean;
  stopWaitingOnAsyncTaskExecution: () => void;
  asyncTaskExecutionResult?: AsyncTaskExecution;
  blueprintRunnerExecutionResponse: BlueprintRunnerExecutionResponse | null;
  isTestingBlueprint: boolean;
  isShowingModelsModal: boolean;
  setIsShowingModelsModal: (x: boolean) => void;
}

/**
 * Renders "Results" section of a Blueprint run, including common models produced from the run and run status
 */
const BlueprintLeftPanelConsoleResultSummary = ({
  isTestingBlueprintAsync,
  stopWaitingOnAsyncTaskExecution,
  asyncTaskExecutionResult,
  blueprintRunnerExecutionResponse,
  isTestingBlueprint,
  isShowingModelsModal,
  setIsShowingModelsModal,
}: Props) => {
  const timestampInfo = blueprintRunnerExecutionResponse?.end_time
    ? `Finished at ${formatRecentTimestamp(blueprintRunnerExecutionResponse?.end_time)}`
    : blueprintRunnerExecutionResponse?.start_time
    ? `Started at ${formatRecentTimestamp(blueprintRunnerExecutionResponse?.start_time)}`
    : asyncTaskExecutionResult?.created_at
    ? `Queued at ${formatRecentTimestamp(asyncTaskExecutionResult?.created_at)}`
    : null;

  // Renders when Blueprint is still running
  if (isTestingBlueprint) {
    const isLoadingStatus = isTestingBlueprintAsync && !asyncTaskExecutionResult?.status;
    return (
      <>
        <hr className="bg-gray-10 p-0 m-0" />
        <div className="flex flex-col space-y-4 py-4">
          <div className="flex flex-col space-y-2">
            <LeftPanelSectionHeader
              title="Results"
              badge={
                <BlueprintRunnerTestRunStatusBadge
                  exitCode={undefined}
                  isLoading={isLoadingStatus}
                  isRunning={true}
                  isQueued={
                    asyncTaskExecutionResult?.status
                      ? ASYNC_TASK_EXECUTION_QUEUED_STATUSES.includes(
                          asyncTaskExecutionResult?.status
                        )
                      : false
                  }
                />
              }
            />
            {timestampInfo && <Text className="text-gray-70">{timestampInfo}</Text>}
            <div className="flex flex-row space-x-2 items-center justify-start">
              <Spinner size="md" />
              <Text variant="h6" className="text-gray-70">
                Running Blueprint ...
              </Text>
            </div>
          </div>
          {isTestingBlueprintAsync && (
            <Button
              variant={ButtonVariant.DangerFilled}
              fullWidth={true}
              onClick={stopWaitingOnAsyncTaskExecution}
            >
              Stop waiting on execution
            </Button>
          )}
        </div>
      </>
    );
  }

  // We don't want to render anything if there's no Blueprint run results
  if (!blueprintRunnerExecutionResponse) {
    return null;
  }

  const { exit_data, exit_code } = blueprintRunnerExecutionResponse;

  // Render Blueprint run results
  return (
    <>
      {exit_data && (
        <IntegrationEditorProducedModelsModal
          show={isShowingModelsModal}
          onHide={() => setIsShowingModelsModal(false)}
          title={"Blueprint Run Models Produced"}
          producedModelsTabs={{
            "Final Model Changes": exit_data.final_model_changes,
            "Model Logs": exit_data.model_logs,
          }}
          modelUpdates={exit_data.final_model_updates}
          mappingTestInfo={exit_data?.additional_data?.autogenerated_mapping_test_information}
        />
      )}
      <hr className="bg-gray-10 p-0 m-0" />
      <div className="flex flex-col space-y-4 py-4">
        <div className="flex flex-col space-y-2">
          <LeftPanelSectionHeader
            title="Results"
            badge={<BlueprintRunnerTestRunStatusBadge exitCode={exit_code} />}
          />
          <Text className="text-gray-70">{timestampInfo}</Text>
        </div>
        <Button
          variant={ButtonVariant.SecondaryBlue}
          onClick={() => setIsShowingModelsModal(true)}
          fullWidth={true}
        >
          Show produced models
        </Button>
      </div>
    </>
  );
};

export default BlueprintLeftPanelConsoleResultSummary;
