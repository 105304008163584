import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Login from "./components/login/Login";
import AdminPortalRouter from "./router/AdminPortalRouter";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route component={AdminPortalRouter} />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        draggable
        pauseOnHover
        pauseOnFocusLoss
        closeOnClick
      />
    </Router>
  );
};

export default App;
