import { Badge } from "@merge-api/merge-javascript-shared";
import { isAnyConfigNotImplemented } from "../../../../../../utils";
import { IntegrationChecklistData } from "../../../../../../constants";

type MissingConfigurationsCellsProps = {
  integrationChecklist?: IntegrationChecklistData;
};

const MissingConfigurationsCells = ({ integrationChecklist }: MissingConfigurationsCellsProps) => {
  if (integrationChecklist) {
    if (!isAnyConfigNotImplemented(integrationChecklist))
      return (
        <td>
          <Badge color="teal">Configured</Badge>
        </td>
      );
  }
  return (
    <td>
      <Badge color="yellow">Missing configurations</Badge>
    </td>
  );
};

export default MissingConfigurationsCells;
