import { fetchWithAuth } from "../../../api-client/api_client";
import {
  APIEndpoint,
  CreateOrUpdateReportTemplateProps,
  IntegrationCommonModelAndFields,
  IntegrationCommonModelConfig,
  IntegrationCommonModelContentType,
  ResponseBodyAdditionalParsing,
} from "../../../models/Entities";
import { AuthConfigUpdateProps } from "../auth-config/AuthConfigShared";

// Fetch all API endpoints for an Integration, with only main fields serialized
export const fetchSimpleAPIEndpoints = ({
  integrationID,
  onError,
  onSuccess,
  setIsLoading,
}: {
  integrationID: string;
  onSuccess: (response: any) => void;
  onError: (response: Response | undefined) => void;
  setIsLoading?: (isLoading: boolean) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/${integrationID}/api-endpoints-simple`,
    method: "GET",
    onResponse: (data) => {
      onSuccess(data);
      setIsLoading && setIsLoading(false);
    },
    onError: (response) => {
      onError(response);
      setIsLoading && setIsLoading(false);
    },
  });
};

export const createOrUpdateEndpoint = (props: {
  apiEndpointID: string | undefined;
  integrationID: string;
  name: string | undefined;
  method: string;
  path: string;
  baseAPIURLOverride: string | undefined;
  isPaginated: boolean | undefined;
  isProxy: boolean | undefined;
  isLiveRequest: boolean | undefined;
  pathParamSchema: string | undefined;
  queryParamSchema: string | undefined;
  headerSchema: string | undefined;
  requestFormat: string | undefined;
  bodySchema: string | undefined;
  returnSchema: string | undefined;
  timestampKey: string | undefined;
  timestampParamLocation: string | undefined;
  requestType: string | undefined;
  responseType: string | undefined;
  responseBodyAdditionalParsing: ResponseBodyAdditionalParsing | undefined;
  fileSchema: string | undefined;
  requestBodyFormatSubtype: string | undefined;
  requestBodyBaseConfig: string | undefined;
  requestBodyParamSchema: string | undefined;
  soapRequestUseBodyTemplatingEngine: boolean | undefined;
  disableEndpointModification: boolean;
  onSuccess: (data: APIEndpoint) => void;
  onError: () => void;
}): Promise<void> =>
  fetchWithAuth({
    path: props.apiEndpointID
      ? `integrations/${props.integrationID}/api-endpoints/${props.apiEndpointID}`
      : `integrations/${props.integrationID}/api-endpoints`,
    body: {
      name: props.name,
      method: props.method,
      path: props.path,
      base_api_url_override: props.baseAPIURLOverride ?? "",
      is_paginated: props.isPaginated,
      is_proxy: props.isProxy,
      is_live_request: props.isLiveRequest,
      path_param_schema: props.pathParamSchema ? JSON.parse(props.pathParamSchema) : undefined,
      query_param_schema: props.queryParamSchema ? JSON.parse(props.queryParamSchema) : undefined,
      header_schema: props.headerSchema ? JSON.parse(props.headerSchema) : undefined,
      request_format: props.requestFormat,
      body_schema: props.bodySchema ? JSON.parse(props.bodySchema) : undefined,
      return_schema: props.returnSchema ? JSON.parse(props.returnSchema) : undefined,
      file_schema: props.fileSchema ? JSON.parse(props.fileSchema) : undefined,
      timestamp_param_key: props.timestampKey ?? undefined,
      timestamp_param_location: props.timestampParamLocation ?? undefined,
      request_type: props.requestType,
      response_type: props.responseType,
      request_body_format_subtype: props?.requestBodyFormatSubtype,
      request_body_base_config: props?.requestBodyBaseConfig,
      soap_request_use_body_templating_engine: props?.soapRequestUseBodyTemplatingEngine,
      request_body_param_schema: props.requestBodyParamSchema
        ? JSON.parse(props.requestBodyParamSchema)
        : undefined,
      response_body_additional_parsing: props?.responseBodyAdditionalParsing ?? undefined,
      disable_endpoint_modification: props.disableEndpointModification ?? false,
    },
    method: props.apiEndpointID ? "PATCH" : "POST",
    onResponse: props.onSuccess,
    onError: props.onError,
  });

export const duplicateEndpoint = (props: {
  apiEndpointID: string;
  integrationID: string;
  onSuccess: (data: APIEndpoint) => void;
  onError: () => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `integrations/${props.integrationID}/api-endpoints/duplicate/${props.apiEndpointID}`,
    method: "POST",
    onResponse: props.onSuccess,
    onError: props.onError,
  });

export const updateIntegrationConfiguration = (props: {
  baseAPIURL: string | undefined;
  isActive: boolean | undefined;
  categories: Array<string> | undefined;
  timestampFormat: string | undefined;
  APIRequestRetryDelay: string | undefined | null;
  configurationFields: { [key: string]: string }[] | undefined;
  additionalHeaderFields: string | undefined;
  integrationID: string;
  onSuccess: (response: any) => void;
  onError: (response: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/${props.integrationID}/configuration`,
    method: "PATCH",
    body: {
      base_api_url: props.baseAPIURL ?? undefined,
      active: props.isActive ?? undefined,
      categories: props.categories ?? undefined,
      timestamp_format: props.timestampFormat ?? undefined,
      api_request_retry_delay_seconds: parseFloat(props.APIRequestRetryDelay ?? "") ?? undefined,
      required_organization_config_fields: props.configurationFields ?? undefined,
      additional_header_fields: props.additionalHeaderFields ?? undefined,
      id: props.integrationID,
    },
    onResponse: (data: any) => {
      props.onSuccess(data);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const updateMarketingConfiguration = (props: {
  configurationNotes: string | undefined;
  headerText: string | undefined;
  bodyText: string | undefined;
  skipLandingPage: boolean | undefined;
  omitLogo: boolean | undefined;
  color: string | undefined;
  normalImage: string | undefined | null;
  squareImage: string | undefined;
  colorImage: string | undefined;
  whiteImage: string | undefined;
  lightGrayImage: string | undefined;
  darkGrayImage: string | undefined;
  blackImage: string | undefined;
  socialBanner: string | undefined;
  integrationID: string;
  onSuccess: (response: any) => void;
  onError: (response: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/${props.integrationID}/marketing`,
    method: "PATCH",
    body: {
      notes_for_customers: props.configurationNotes ?? undefined,
      image: props.normalImage ?? undefined,
      square_image: props.squareImage ?? undefined,
      social_banner: props.socialBanner ?? undefined,
      color_image: props.colorImage ?? undefined,
      white_image: props.whiteImage ?? undefined,
      light_grey_image: props.lightGrayImage ?? undefined,
      dark_grey_image: props.darkGrayImage ?? undefined,
      black_image: props.blackImage ?? undefined,
      header_text: props.headerText ?? undefined,
      body_text: props.bodyText ?? undefined,
      color: props.color ?? undefined,
      skip_landing_page_generation: props.skipLandingPage ?? undefined,
      omit_logo_from_landing_page: props.omitLogo ?? undefined,
      id: props.integrationID,
    },
    onResponse: (data: any) => {
      props.onSuccess(data);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const updatePaginationConfiguration = (props: {
  APIEndpoint: APIEndpoint | undefined;
  paginationType: string | undefined;
  requestQueryParameterKey: string | undefined;
  requestPathParameterKey: string | undefined;
  requestBodyParameterKey: string | undefined;
  responsePaginationValueLocation: string | undefined;
  responsePaginationValueType: string | undefined;
  keyPathForMaxItems: Array<string> | undefined;
  itemInitialValue: null | string | undefined;
  itemLimitAmount: null | string | undefined;
  itemLimitParamKey: string | undefined;
  keyPathForPaginationValueResponseBody: Array<string> | undefined;
  keyPathForPaginationValueHeader: string | undefined;
  booleanKeyPath: Array<string> | undefined;
  keyPathForArrayResponseBody: Array<string> | undefined;
  integrationID: string;
  onSuccess: (response: any) => void;
  onError: (response: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/${props.integrationID}/pagination`,
    method: "PATCH",
    body: {
      api_endpoint: props.APIEndpoint ?? undefined,
      item_initial_value: parseFloat(props.itemInitialValue ?? "") ?? undefined,
      item_limit_amount: parseFloat(props.itemLimitAmount ?? "") ?? undefined,
      item_limit_param_key: props.itemLimitParamKey ?? undefined,
      pagination_type: props.paginationType ?? undefined,
      request_body_parameter_key: props.requestBodyParameterKey ?? undefined,
      request_path_parameter_key: props.requestPathParameterKey ?? undefined,
      request_query_parameter_key: props.requestQueryParameterKey ?? undefined,
      response_body_array_key_path: props.keyPathForArrayResponseBody ?? undefined,
      response_has_next_bool_key_path: props.booleanKeyPath ?? undefined,
      response_max_items_key_path: props.keyPathForMaxItems ?? undefined,
      response_pagination_header_parameter: props.keyPathForPaginationValueHeader ?? undefined,
      response_pagination_value_key_path: props.keyPathForPaginationValueResponseBody ?? undefined,
      response_pagination_value_location: props.responsePaginationValueLocation ?? undefined,
      response_pagination_value_type: props.responsePaginationValueType ?? undefined,
      id: props.integrationID,
    },
    onResponse: (data: any) => {
      props.onSuccess(data);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const fetchTemplateConfigs = (props: {
  integrationID: string;
  onSuccess: (response: any) => void;
  onError?: (response: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/${props.integrationID}/uid/template_configs`,
    method: "GET",
    onResponse: (data: IntegrationCommonModelConfig[]) => {
      props.onSuccess(data);
    },
    onError: (error: Response | undefined) => {
      props?.onError?.(error);
    },
  });
};

export const fetchIntegrationCommonModelContentTypes = (props: {
  integrationID: string;
  onSuccess: (data: IntegrationCommonModelContentType[]) => void;
  onError: () => void;
}) => {
  fetchWithAuth({
    path: `integrations/${props.integrationID}/common-model-content-types`,
    method: "GET",
    onResponse: props.onSuccess,
    onError: props.onError,
  });
};

export const fetchIntegrationCommonModelAndFields = (props: {
  integrationID: string;
  onSuccess: (data: IntegrationCommonModelAndFields[]) => void;
  onError: () => void;
  setIsLoading: (isLoading: boolean) => void;
}) => {
  fetchWithAuth({
    path: `integrations/${props.integrationID}/common-model-and-fields`,
    method: "GET",
    onResponse: (data) => {
      props.onSuccess(data);
      props.setIsLoading(false);
    },
    onError: () => {
      props.onError();
      props.setIsLoading(false);
    },
  });
};

export const updateAuthenticationConfiguration = (props: {
  authConfigUpdates: AuthConfigUpdateProps;
  onSuccess: (response: any) => void;
  onError: (response: Response | undefined) => void;
}) => {
  const method = props.authConfigUpdates.id ? "PATCH" : "POST";
  const path = props.authConfigUpdates.id
    ? `/integrations/${props.authConfigUpdates.integrationID}/auth-configs/${props.authConfigUpdates.id}`
    : `/integrations/${props.authConfigUpdates.integrationID}/auth-configs?auth_type=${props.authConfigUpdates.authType}`;

  fetchWithAuth({
    path: path,
    method: method,
    body: {
      name: props.authConfigUpdates.name ?? undefined,
      additional_header_fields: props.authConfigUpdates.additionalHeaderFields ?? undefined,
      basic_auth_key_header_format: props.authConfigUpdates.headerFormatForBasicAuth ?? undefined,
      additional_auth_field_keys: props.authConfigUpdates.additionalAuthFields ?? undefined,
      service_account_field_keys: props.authConfigUpdates.serviceAccountFieldKeys ?? undefined,
      basic_auth_key_token_exchange_request_data_convert_to_string:
        props.authConfigUpdates.basicAuthKeyTokenExchangeRequestDataConvertToString ?? undefined,
      basic_auth_key_token_exchange_url: props.authConfigUpdates.tokenExchangeURL ?? undefined,
      basic_auth_key_token_exchange_header_format:
        props.authConfigUpdates.headerFormatForTokenExchange ?? undefined,
      basic_auth_key_token_exchange_request_data_format:
        props.authConfigUpdates.requestDataFormat ?? undefined,
      basic_auth_key_token_exchange_response_auth_key_path:
        props.authConfigUpdates.pathToAuthKey ?? undefined,
      basic_auth_key_token_exchange_access_token_lifespan_in_seconds: props.authConfigUpdates
        .lifespanOfToken
        ? parseFloat(props.authConfigUpdates.lifespanOfToken ?? "")
        : undefined,
      oauth_client_id: props.authConfigUpdates.OAuthClientID ?? undefined,
      oauth_client_secret: props.authConfigUpdates.OAuthClientSecret ?? undefined,
      oauth_authorize_url: props.authConfigUpdates.OAuthAuthorizeURL ?? undefined,
      oauth_authorize_url_param_keys_to_exclude:
        props.authConfigUpdates.parametersToExclude ?? undefined,
      oauth_grant_type: props.authConfigUpdates.OAuthGrantType ?? undefined,
      oauth_client_credential_source:
        props.authConfigUpdates.OAuthClientCredentialSource ?? undefined,
      oauth_params_scopes: props.authConfigUpdates.OAuthParamsScopes ?? undefined,
      oauth_authorize_url_additional_redirect_uri_query_param_keys:
        props.authConfigUpdates.additionalQueryParameters ?? undefined,
      oauth_token_url: props.authConfigUpdates.OAuthTokenURL ?? undefined,
      oauth_token_url_params_location:
        props.authConfigUpdates.OAuthTokenURLParamsLocation ?? undefined,
      oauth_token_url_header_format: props.authConfigUpdates.OAuthTokenURLFormat ?? undefined,
      oauth_response_token_key_path: props.authConfigUpdates.OAuthResponseTokenKeyPath ?? undefined,
      oauth_access_token_expiration_key_path:
        props.authConfigUpdates.OAuthAccessTokenExpirationKeyPath ?? undefined,
      oauth_access_token_lifespan_in_seconds: props.authConfigUpdates.OAuthAccessTokenLifespan
        ? parseFloat(props.authConfigUpdates.OAuthAccessTokenLifespan ?? "")
        : undefined,
      oauth_refresh_token_lifespan_in_seconds: props.authConfigUpdates.OAuthRefreshTokenLifespan
        ? parseFloat(props.authConfigUpdates.OAuthRefreshTokenLifespan ?? "")
        : undefined,
      oauth_token_url_header_keys_to_exclude:
        props.authConfigUpdates.OAuthTokenURLHeaderKeysToExclude ?? undefined,
      oauth_refresh_fields_to_exclude:
        props.authConfigUpdates.OAuthRefreshFieldsToExclude ?? undefined,
      oauth_request_fields_to_exclude:
        props.authConfigUpdates.OAuthRequestFieldsToExclude ?? undefined,
      oauth_should_refresh_access_token:
        props.authConfigUpdates.OAuthShouldRefreshAccessToken ?? undefined,
      oauth_should_fetch_access_token:
        props.authConfigUpdates.OAuthShouldFetchAccessToken ?? undefined,
      oauth_use_pkce: props.authConfigUpdates.OAuthUsePKCE ?? undefined,
    },
    onResponse: (data: any) => {
      props.onSuccess(data);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const createOrUpdateReportTemplate = (props: {
  reportTemplateInfo: CreateOrUpdateReportTemplateProps;
  onSuccess: (response: any) => void;
  onError: (response: Response | undefined) => void;
}) => {
  const { id, integration_id } = props.reportTemplateInfo;
  const method = id ? "PATCH" : "POST";
  const path = id
    ? `/integrations/${integration_id}/report-templates/${id}`
    : `/integrations/${integration_id}/report-templates`;

  fetchWithAuth({
    path: path,
    method: method,
    body: props.reportTemplateInfo,
    onResponse: (data: any) => {
      props.onSuccess(data);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const deleteTestAuthConfiguration = (props: {
  testAuthConfigID: string;
  integrationID: string;
  onSuccess: (response: any) => void;
  onError?: (response: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/${props.integrationID}/auth-configs/${props.testAuthConfigID}/test`,
    method: "DELETE",
    body: {},
    onResponse: (data: any) => {
      props.onSuccess(data);
    },
    onError: (error: Response | undefined) => {
      props?.onError?.(error);
    },
  });
};

export const createTestAuthConfiguration = (props: {
  authConfigID: string;
  authConfigUpdates: AuthConfigUpdateProps;
  linkToken: string;
  onSuccess: (response: any) => void;
  onError: (response: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/${props.authConfigUpdates.integrationID}/auth-configs/${props.authConfigID}/test?link_token=${props.linkToken}`,
    method: "POST",
    body: {
      name: props.authConfigUpdates.name ?? undefined,
      additional_header_fields: props.authConfigUpdates.additionalHeaderFields ?? undefined,
      basic_auth_key_header_format: props.authConfigUpdates.headerFormatForBasicAuth ?? undefined,
      additional_auth_field_keys: props.authConfigUpdates.additionalAuthFields ?? undefined,
      service_account_field_keys: props.authConfigUpdates.serviceAccountFieldKeys ?? undefined,
      basic_auth_key_token_exchange_request_data_convert_to_string:
        props.authConfigUpdates.basicAuthKeyTokenExchangeRequestDataConvertToString ?? undefined,
      basic_auth_key_token_exchange_url: props.authConfigUpdates.tokenExchangeURL ?? undefined,
      basic_auth_key_token_exchange_header_format:
        props.authConfigUpdates.headerFormatForTokenExchange ?? undefined,
      basic_auth_key_token_exchange_request_data_format:
        props.authConfigUpdates.requestDataFormat ?? undefined,
      basic_auth_key_token_exchange_response_auth_key_path:
        props.authConfigUpdates.pathToAuthKey ?? undefined,
      basic_auth_key_token_exchange_access_token_lifespan_in_seconds: props.authConfigUpdates
        .lifespanOfToken
        ? parseFloat(props.authConfigUpdates.lifespanOfToken ?? "")
        : undefined,
      oauth_client_id: props.authConfigUpdates.OAuthClientID ?? undefined,
      oauth_client_secret: props.authConfigUpdates.OAuthClientSecret ?? undefined,
      oauth_authorize_url: props.authConfigUpdates.OAuthAuthorizeURL ?? undefined,
      oauth_authorize_url_param_keys_to_exclude:
        props.authConfigUpdates.parametersToExclude ?? undefined,
      oauth_grant_type: props.authConfigUpdates.OAuthGrantType ?? undefined,
      oauth_client_credential_source:
        props.authConfigUpdates.OAuthClientCredentialSource ?? undefined,
      oauth_params_scopes: props.authConfigUpdates.OAuthParamsScopes ?? undefined,
      oauth_authorize_url_additional_redirect_uri_query_param_keys:
        props.authConfigUpdates.additionalQueryParameters ?? undefined,
      oauth_token_url: props.authConfigUpdates.OAuthTokenURL ?? undefined,
      oauth_token_url_params_location:
        props.authConfigUpdates.OAuthTokenURLParamsLocation ?? undefined,
      oauth_access_token_expiration_key_path:
        props.authConfigUpdates.OAuthAccessTokenExpirationKeyPath ?? undefined,
      oauth_token_url_header_format: props.authConfigUpdates.OAuthTokenURLFormat ?? undefined,
      oauth_response_token_key_path: props.authConfigUpdates.OAuthResponseTokenKeyPath ?? undefined,
      oauth_access_token_lifespan_in_seconds: props.authConfigUpdates.OAuthAccessTokenLifespan
        ? parseFloat(props.authConfigUpdates.OAuthAccessTokenLifespan ?? "")
        : undefined,
      oauth_refresh_token_lifespan_in_seconds: props.authConfigUpdates.OAuthRefreshTokenLifespan
        ? parseFloat(props.authConfigUpdates.OAuthRefreshTokenLifespan ?? "")
        : undefined,
      oauth_refresh_fields_to_exclude:
        props.authConfigUpdates.OAuthRefreshFieldsToExclude ?? undefined,
      oauth_request_fields_to_exclude:
        props.authConfigUpdates.OAuthRequestFieldsToExclude ?? undefined,
      oauth_should_refresh_access_token:
        props.authConfigUpdates.OAuthShouldRefreshAccessToken ?? undefined,
      oauth_should_fetch_access_token:
        props.authConfigUpdates.OAuthShouldFetchAccessToken ?? undefined,
      oauth_use_pkce: props.authConfigUpdates.OAuthUsePKCE ?? undefined,
      oauth_token_url_header_keys_to_exclude:
        props.authConfigUpdates.OAuthTokenURLHeaderKeysToExclude ?? undefined,
    },
    onResponse: (data: any) => {
      props.onSuccess(String(data.message));
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};
