import clsx from "clsx";
import { Info } from "lucide-react";
import AccordionCard from "./AccordionCard";
import useIntegrationBuilderContext from "../context/useIntegrationBuilderContext";

interface HelpAccordionCardProps {
  title: string;
  children: React.ReactNode;
  className?: string;
}

/**
 * Accordion card used to show helpful tips for a field, when there are many tips.
 * Children should use <HelpAccordionBlock> to organize helpful tips.
 */
const HelpAccordionCard = ({ title, children, className }: HelpAccordionCardProps) => {
  const { hasPermissionToEdit } = useIntegrationBuilderContext();

  if (!hasPermissionToEdit) {
    return <></>;
  }

  return (
    <AccordionCard
      title={title}
      titleIcon={<Info size={16} className="text-gray-90" />}
      titleVariant="h6"
      className={clsx(className, "mt-6 bg-[#F7F8F9]")}
      variant="none"
      chevronColor="black"
      children={children}
    />
  );
};

export default HelpAccordionCard;
