import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast } from "../../../shared/Toasts";
import { useState } from "react";
import { IntegrationChecklistData } from "../../../../constants";
import { Integration } from "../../../../models/Entities";
import { delay } from "../utils";

interface Props {
  integrationID: string;
}

const useLoadChecklist = ({ integrationID }: Props) => {
  const [isRefreshingChecklist, setIsRefreshingChecklist] = useState<boolean>(false);
  const [integrationChecklist, setIntegrationChecklist] = useState<
    IntegrationChecklistData | undefined
  >();

  const refreshIntegrationChecklist = () => {
    setIsRefreshingChecklist(true);
    Promise.all([
      fetchWithAuth({
        path: `/integrations/${integrationID}/integration-checklist/refresh`,
        method: "POST",
        onResponse: (data: IntegrationChecklistData) => {
          setIntegrationChecklist(data);
        },
      }),
    ])
      .then(() => delay(500))
      .then(() => {
        setIsRefreshingChecklist(false);
      })
      .catch((error) => {
        showErrorToast(`Error running validation check: ${error}.`);
        setIsRefreshingChecklist(false);
      });
  };

  const fetchIntegrationChecklist = () => {
    fetchWithAuth({
      path: `/integrations/${integrationID}`,
      method: "GET",
      onResponse: (data: Integration) => {
        setIntegrationChecklist(data?.integration_checklist);
      },
    });
  };

  return {
    isRefreshingChecklist,
    integrationChecklist,
    refreshIntegrationChecklist,
    fetchIntegrationChecklist,
  };
};

export default useLoadChecklist;
