import { Button, ButtonVariant, Dialog, Text, TextField } from "@merge-api/merge-javascript-shared";
import { Sparkles, Upload } from "lucide-react";
import React, { useRef } from "react";
import { getJSONSchemaFromExampleJSON } from "../../integrations/utils/IntegrationsUtils";

interface SampleJSONUploadProps {
  open: boolean;
  onClose: () => void;
  setValue: (value: string) => void;
}

const SampleJSONUpload = ({ open, onClose, setValue }: SampleJSONUploadProps) => {
  const [jsonText, setJSONText] = React.useState<string>("");
  const [isParsingError, setIsParsingError] = React.useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // opens the file input dialog
  const openFilePicker = () => {
    fileInputRef.current?.click();
  };

  // handles the file upload and sets the JSON text in the text area
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          setIsParsingError(false);
          setJSONText(e.target.result as string);
        }
      };
      reader.readAsText(e.target.files[0]);
    }
  };

  // formats the current JSON text in the text area
  const prettifyJSON = () => {
    try {
      const parsed = JSON.parse(jsonText);
      setJSONText(JSON.stringify(parsed, null, 2));
    } catch (e) {
      setIsParsingError(true);
    }
  };

  // confirms the JSON text is valid JSON, sets the value in the parent text field, and closes the dialog
  const onDone = () => {
    try {
      JSON.parse(jsonText);
      const converted = getJSONSchemaFromExampleJSON(jsonText ?? "");
      setValue(converted);
      onCloseDialog();
    } catch (e) {
      setIsParsingError(true);
    }
  };

  // closes the dialog and resets the JSON text
  const onCloseDialog = () => {
    setJSONText("");
    onClose();
  };

  return (
    <Dialog
      title="Use sample JSON"
      className="w-[500px]"
      primaryButtonVariant={ButtonVariant.PrimaryBlue}
      primaryButtonText="Upload"
      onPrimaryButtonClick={() => {}}
      footerButtonsHidden
      open={open}
      onClose={onCloseDialog}
      variant="sm"
    >
      <React.Fragment key=".0">
        <Button
          variant={ButtonVariant.SecondaryBlue}
          size="md"
          leftIcon={<Upload size={16} />}
          onClick={openFilePicker}
        >
          Upload file
        </Button>
        {/* hidden file input to trigger file upload - START */}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept=".txt,.json"
          onChange={handleFileChange}
        />
        {/* hidden file input to trigger file upload - END */}
        <div className="flex flex-row items-center justify-between">
          <Text className="mt-3" variant="h6">
            or paste sample JSON below
          </Text>
          <Button
            leftIcon={<Sparkles size={16} />}
            onClick={prettifyJSON}
            variant={ButtonVariant.TertiaryWhite}
            size="sm"
          >
            Prettify
          </Button>
        </div>
        <TextField
          className="mt-3"
          placeholder={JSON.stringify({ key: "value", key2: { key: "value" } }, null, 4)}
          rows={5}
          value={jsonText}
          onChange={(e) => {
            setIsParsingError(false);
            setJSONText(e.target.value);
          }}
          autosize={true}
          maxRows={20}
          error={isParsingError}
          errorText="Invalid JSON format. Please correct the JSON format."
        />
        <div className="flex flex-row items-center justify-end mt-6">
          <div
            className="text-gray-90 cursor-pointer font-medium text-base"
            onClick={onCloseDialog}
          >
            Cancel
          </div>
          <Button className="ml-6" onClick={onDone}>
            Done
          </Button>
        </div>
      </React.Fragment>
    </Dialog>
  );
};

export default SampleJSONUpload;
