import { APIEndpointParameter } from "../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_APIEndpointParameter";
import { SelectiveSyncFilterSchema } from "../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";

export enum VersionedComponentAPIActions {
  GET_COMPONENTS_FOR_COMPONENT_CLASS = "get-components-for-component-class",
  GET_COMPONENT_FOR_COMPONENT_CLASS = "get-component-for-component-class",
  STAGE_COMPONENT = "stage-component",
  UNSTAGE_COMPONENT = "unstage-component",
  DELETE_COMPONENT = "delete-component",
  RESTORE_COMPONENT = "restore-component",
  DELETE_DRAFT_COMPONENT = "delete-draft-component",
  CREATE_NEW_COMPONENT = "create-new-component",
  CREATE_AND_STAGE_NEW_COMPONENT = "create-new-component-and-stage",
  SAVE_DRAFT_COMPONENT = "save-draft-component",
  UNSTAGE_SAVE_DRAFT_AND_STAGE_COMPONENT = "unstage-save-draft-and-stage-component",
}

export enum VersionedComponentAPIComponentKeys {
  APIEndpointParameter = "api_endpoint_filters",
  SelectiveSyncFilterSchema = "selective_sync_filter_schemas",
}

export enum NextVersionState {
  STAGED_WITH_CHANGES = "STAGED_WITH_CHANGES",
  DRAFT_WITH_CHANGES = "DRAFT_WITH_CHANGES",
  NO_CHANGES = "NO_CHANGES",
}

export enum ComponentState {
  PUBLISHED_WITH_NEW_DRAFT = "published_with_new_draft",
  NEW_COMPONENT_WITH_DRAFT_VERSION = "new_component_with_draft_version",
  PUBLISHED_WITH_NEW_STAGED_VERSION = "published_with_new_staged_version",
  MARKED_FOR_DELETION = "marked_for_deletion",
  NEW_COMPONENT_WITH_STAGED_VERSION = "new_component_with_staged_version",
  PUBLISHED = "published",
}

export interface VersionedComponentInfo<T> {
  published_version: T | null;
  next_version: T | null;
  next_version_state: NextVersionState;
  component_state: ComponentState;
}

export interface VersionedComponentsAPIRequestData {
  component_id?: string | null;
  component_key: VersionedComponentAPIComponentKeys;
  component?: SelectiveSyncFilterSchema | APIEndpointParameter;
}

export type SelectiveSyncFilterSchemaAPIResponse = {
  [id: string]: VersionedComponentInfo<SelectiveSyncFilterSchema>;
};

export type APIEndpointParameterAPIResponse = {
  [id: string]: VersionedComponentInfo<APIEndpointParameter>;
};

export enum PAGE_VIEWS {
  HOME = "HOME",
  EDITOR = "EDITOR",
}
