import { MinusCircle, PlusCircle } from "lucide-react";
import styled from "styled-components";
import { BlueprintParameterSchemaValue } from "../../../models/Blueprints";
import {
  CommonModelMappingMissInfo,
  MappingTestBodyParameters,
} from "../../../models/MappingTests";
import BodyParameterField from "../left-panel/BodyParameterField";
import { getEmptyValueForField } from "../utils/MappingTestBuildingUtils";
import MappingTestEditorCommonModelExpectedMappingRow from "./MappingTestEditorCommonModelExpectedMappingRow";

var pluralize = require("pluralize");

type Props = {
  hasMappingMisses?: boolean;
  fieldName: string;
  value: Array<string>;
  bodyParameters: MappingTestBodyParameters;
  missedMappingInfo?: CommonModelMappingMissInfo;
  availableRelationLookupDict?: { [commonModelID: string]: Array<string> };
  setValue: (value: Array<string | Record<string, any>>) => void;
  isDebugMode: boolean;
  availableRelationNames: undefined | Array<string>;
  itemSchema: BlueprintParameterSchemaValue;
  fieldSchema: undefined | BlueprintParameterSchemaValue;
  fieldKeyPath: Array<string>;
  shouldManualInputSubObject: boolean;
  onUpdate: (args: { fieldKeyPath: Array<string>; newFieldValue: any }) => void;
};

type MappingMissProps = {
  hasMappingMisses?: boolean;
};

const FieldDropdown = styled.select<MappingMissProps>`
  background: ${({ hasMappingMisses }) => (hasMappingMisses ? "#FFE9EC" : "#eff2f4")}};
  color: ${({ hasMappingMisses }) => (hasMappingMisses ? "#F00424" : "")}}; 
  border: 0;
  border-bottom: ${({ hasMappingMisses }) =>
    hasMappingMisses ? "1.5px solid #FFA2A2" : "1.5px solid #dce4f0"}};
`;

const FieldInput = styled.input<MappingMissProps>`
  background: ${({ hasMappingMisses }) => (hasMappingMisses ? "#FFE9EC" : "#eff2f4")}};
  color: ${({ hasMappingMisses }) => (hasMappingMisses ? "#F00424" : "")}}; 
  border: 0;
  border-bottom: ${({ hasMappingMisses }) =>
    hasMappingMisses ? "1.5px solid #FFA2A2" : "1.5px solid #dce4f0"}};
`;

const AddItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #003fff;
  cursor: pointer;
  margin-left: 24px;
`;

const DeleteItemSection = styled.div<MappingMissProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 24px;
  width: 100%;
  justify-content: space-between;
`;

const MappingTestEditorCommonModelExpectedMappingFieldInputList = ({
  hasMappingMisses,
  fieldName,
  fieldSchema,
  itemSchema,
  value,
  bodyParameters,
  availableRelationLookupDict,
  fieldKeyPath,
  missedMappingInfo,
  onUpdate,
  setValue,
  isDebugMode,
  shouldManualInputSubObject,
  availableRelationNames,
}: Props) => {
  const defaultValue = fieldSchema?.items?.type == "object" ? {} : "";
  const addItem = () => setValue([...value, defaultValue]);
  const deleteItem = (index: number) =>
    setValue([...value.slice(0, index), ...value.slice(index + 1)]);
  const updateItem = (itemValue: string, index: number) =>
    setValue([...value.slice(0, index), itemValue, ...value.slice(index + 1)]);

  // Create a set of mapped values
  const mappedValues = new Set(value);

  // Calculate the difference
  const unmappedProducedValues = missedMappingInfo?.produced_value?.filter(
    (producedValue: string) => !mappedValues.has(producedValue)
  );

  const unmappedValuesString = unmappedProducedValues?.join(", ") || "";

  return (
    <>
      {(value ?? []).map((item, index) => {
        const isInProducedValues =
          missedMappingInfo?.produced_value?.includes(item) || !hasMappingMisses;
        return (
          <MappingTestEditorCommonModelExpectedMappingRow key={index} isRowShaded={true}>
            <DeleteItemSection>
              <MinusCircle color={"black"} onClick={() => deleteItem(index)} size={12} />
            </DeleteItemSection>
            {availableRelationNames && !shouldManualInputSubObject ? (
              <FieldDropdown
                hasMappingMisses={!isInProducedValues ?? false}
                onChange={(e) => updateItem(e.target.value, index)}
                value={item}
              >
                <>
                  <option key={"None"} value={getEmptyValueForField(itemSchema)}>
                    {"None"}
                  </option>
                  {availableRelationNames.map((name) => (
                    <option key={name} value={`$\{${name}}`}>
                      {name}
                    </option>
                  ))}
                </>
              </FieldDropdown>
            ) : fieldSchema?.items?.type == "object" ? (
              <div className="d-flex flex-column">
                <BodyParameterField
                  bodyParameters={bodyParameters}
                  availableRelationLookupDict={availableRelationLookupDict!}
                  key={fieldName}
                  fieldKey={fieldName}
                  fieldSchema={fieldSchema?.items}
                  index={index}
                  //@ts-ignore
                  fieldKeyPath={[...fieldKeyPath, index]}
                  root={"model"}
                  onUpdate={onUpdate}
                  isRequired={false}
                  shouldManualInputSubObject={shouldManualInputSubObject}
                  isNestedObject={true}
                />
              </div>
            ) : (
              <FieldInput
                hasMappingMisses={!isInProducedValues ?? false}
                value={item}
                onChange={(e) => updateItem(e.target.value, index)}
              />
            )}
          </MappingTestEditorCommonModelExpectedMappingRow>
        );
      })}

      <MappingTestEditorCommonModelExpectedMappingRow key={"add"} isRowShaded={true}>
        <div className="flex flex-col">
          {hasMappingMisses && isDebugMode && unmappedValuesString.length > 0 && (
            <div className="flex flex-wrap ml-6">
              The following values must be asserted in the list: {unmappedValuesString}
            </div>
          )}
          <AddItem onClick={addItem}>
            <PlusCircle color={"#003FFF"} size={12} />
            <div>{`Add ${pluralize(fieldName, 1)}`}</div>
          </AddItem>
        </div>
      </MappingTestEditorCommonModelExpectedMappingRow>
    </>
  );
};

export default MappingTestEditorCommonModelExpectedMappingFieldInputList;
