import { useCallback, useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { SelectiveSyncFilterSchema } from "../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import { APIEndpointMethod, LinkedAccount } from "../../../../models/Entities";
import { APIEndpoint } from "../../../integrations/versioned-components/types";
import { TestRunResponse } from "../../../shared/TestRunResponseBlock";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import { VersionedComponentAPIComponentKeys } from "../../../versioned-components/types";
import { AbstractCondition } from "../../selective-sync-filters/types";
import {
  convertExpandedPydanticModelToNativePydanticModel,
  getAPIEndpointParametersFromSelectiveSyncFilterSchema,
  prepareAbstractConditionsForTestRun,
} from "../../selective-sync-filters/utils";
import { APIEndpointParameterValues } from "../components/request_tester/APIRequestTester";

interface loadAPIEndpointProps {
  selectiveSyncFilterSchema: SelectiveSyncFilterSchema | undefined;
  abstractConditions: AbstractCondition[];
  linkedAccountInfo: LinkedAccount | null;
  apiEndpointInfo: APIEndpoint | null;
  parameterValues: APIEndpointParameterValues;
  setTestRunResponse: (value: React.SetStateAction<TestRunResponse | undefined>) => void;
}

const useRunTestAPIRequest = ({
  abstractConditions,
  selectiveSyncFilterSchema,
  linkedAccountInfo,
  apiEndpointInfo,
  parameterValues,
  setTestRunResponse,
}: loadAPIEndpointProps) => {
  const [
    isLoadingTestAPIRequestWithSelectiveSyncFilters,
    setIsLoadingTestAPIRequestWithSelectiveSyncFilters,
  ] = useState(false);

  // Method for making an api test request
  const runTestAPIRequestWithSelectiveSyncFilters = useCallback(() => {
    const nativeSelectiveSyncFilterSchema = convertExpandedPydanticModelToNativePydanticModel(
      selectiveSyncFilterSchema as SelectiveSyncFilterSchema,
      VersionedComponentAPIComponentKeys.SelectiveSyncFilterSchema
    );

    const apiEndpointParameters = getAPIEndpointParametersFromSelectiveSyncFilterSchema(
      selectiveSyncFilterSchema as SelectiveSyncFilterSchema
    );

    // Convert Selective Sync values belonging to list operators, from string to array
    const convertedAbstractConditions = prepareAbstractConditionsForTestRun(abstractConditions);

    const selectiveSyncFiltersPayload = {
      selective_sync_filter_schema: nativeSelectiveSyncFilterSchema,
      api_endpoint_filters: apiEndpointParameters,
      abstract_conditions: convertedAbstractConditions,
    };

    setIsLoadingTestAPIRequestWithSelectiveSyncFilters(true);
    fetchWithAuth({
      path: "/integrations/integration-builder/test-endpoint-with-selective-sync-filters",
      method: APIEndpointMethod.POST,
      body: {
        linked_account_id: linkedAccountInfo?.id,
        api_endpoint_id: apiEndpointInfo?.id,
        api_tester_serialized_selective_sync_manager_inputs: selectiveSyncFiltersPayload,
        parameter_values: parameterValues,
      },
      onResponse: (data) => {
        setTestRunResponse(data);
        setIsLoadingTestAPIRequestWithSelectiveSyncFilters(false);
        showSuccessToast(`Successfully made a request to 3rd party endpoint`);
      },
      onError: (error) => {
        setIsLoadingTestAPIRequestWithSelectiveSyncFilters(false);
        error?.json().then((value: string | Record<string, any>) => {
          let errorMessage = "";
          if (typeof value == "string") {
            errorMessage = value;
          } else {
            errorMessage = value?.non_field_errors;
          }
          showErrorToast(`Failed to run. Error message: \n ${errorMessage}`);
        });
      },
    });
  }, [apiEndpointInfo, linkedAccountInfo, abstractConditions, parameterValues]);

  return {
    isLoadingTestAPIRequestWithSelectiveSyncFilters,
    runTestAPIRequestWithSelectiveSyncFilters,
  };
};

export default useRunTestAPIRequest;
