import { Col } from "react-bootstrap";
import classNames from "classnames";
import Spinner from "../Spinner";
import DeprecatedH2 from "../../deprecated/DeprecatedH2";

interface Props {
  children?: JSX.Element;
  title?: string | JSX.Element;
  rowClassName?: string;
  colClassName?: string;
  isTable?: boolean;
  isSpinner?: boolean;
}

function EmptyStateWrapper(props: Props) {
  return (
    <div
      className={classNames(
        props.rowClassName ? props.rowClassName : "",
        props.isTable ? "" : "row"
      )}
    >
      <Col
        className={classNames(
          props.colClassName ? props.colClassName : "text-center align-middle",
          props.isTable ? "my-5" : "m-5"
        )}
      >
        {props.title && (
          <DeprecatedH2
            className={classNames("empty-state-text", {
              "mb-0": !props.children,
            })}
          >
            {props.title}
          </DeprecatedH2>
        )}
        {props.isSpinner ? <Spinner /> : props.children}
      </Col>
    </div>
  );
}

export default EmptyStateWrapper;
