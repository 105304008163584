import { useCallback, useRef } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast } from "../../../shared/Toasts";
import { APIEndpointPreviewIntegrationBuilder } from "../../utils/Entities";

const POLL_INTERVAL_MS = 5000;

interface pollAPIEndpointPreviewsProps {
  setAPIEndpointPreviews: React.Dispatch<
    React.SetStateAction<APIEndpointPreviewIntegrationBuilder[] | undefined>
  >;
}

const usePollAPIEndpointPreviews = ({ setAPIEndpointPreviews }: pollAPIEndpointPreviewsProps) => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const fetchAPIEndpointPreviews = useCallback(
    (integrationID: string) => {
      fetchWithAuth({
        path: `/integrations/integration-builder/integration/${integrationID}/api-endpoint-previews`,
        method: "GET",
        onResponse: (data: APIEndpointPreviewIntegrationBuilder[]) => {
          setAPIEndpointPreviews(data);
        },
        onError: () => {
          showErrorToast("Failed to fetch API endpoints for integration.");
          setAPIEndpointPreviews([]);
        },
      });
    },
    [setAPIEndpointPreviews]
  );

  const pollAPIEndpointPreviews = useCallback(
    (integrationID: string) => {
      const poll = () => {
        fetchAPIEndpointPreviews(integrationID);
      };

      // Initial call to poll
      poll();

      // Setup to poll every 5 seconds
      intervalRef.current = setInterval(poll, POLL_INTERVAL_MS);
    },
    [setAPIEndpointPreviews]
  );

  const stopPollingAPIEndpointPreviews = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  return { pollAPIEndpointPreviews, stopPollingAPIEndpointPreviews, fetchAPIEndpointPreviews };
};

export default usePollAPIEndpointPreviews;
