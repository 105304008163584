import {
  DiffState,
  DiffStateField,
  DiffStateFieldTypeEnum,
} from "../../../../../models/DiffModels";
import { getAllKeysInBothCurrentAndNew, getDiffStatus } from "../helpers";
import { processDiffForPropertyDetails } from "./step-templates-property-details-diff";

/**
 * Generates the diff state for the step template parameter schema.
 *
 * This function compares the current and new parameter schema data and generates
 * a diff state that highlights the differences between the two. It iterates through
 * all keys in both the current and new parameter schema data, and for each key, it
 * compares the details and generates a diff state field for each detail.
 *
 * @param {Object} currentParamSchemaData - The current parameter schema data.
 * @param {Object} newParamSchemaData - The new parameter schema data.
 * @returns {DiffState} - The generated diff state for the parameter schema.
 */
export const generateDiffStateForStepTemplateParameterSchema = (
  currentParamSchemaData: { [key: string]: any },
  newParamSchemaData: { [key: string]: any }
): DiffState => {
  let filteredCurrentParamSchema = { ...currentParamSchemaData };
  let filteredNewParamSchema = { ...newParamSchemaData };

  // Parameter schema can either start with "properties" key with all param schemas nested under it or without "properties"
  if ("properties" in filteredCurrentParamSchema)
    filteredCurrentParamSchema = filteredCurrentParamSchema["properties"];
  if ("properties" in filteredNewParamSchema)
    filteredNewParamSchema = filteredNewParamSchema["properties"];

  const allKeys = getAllKeysInBothCurrentAndNew(filteredCurrentParamSchema, filteredNewParamSchema);

  return allKeys.map((key) => {
    const currentDetails: { [key: string]: any } =
      key in filteredCurrentParamSchema ? filteredCurrentParamSchema[key] : {};
    const newDetails: { [key: string]: any } =
      key in filteredNewParamSchema ? filteredNewParamSchema[key] : {};

    const [currentValue, newValue, childDiffStateFields] = processDiffForPropertyDetails(
      currentDetails,
      newDetails
    );

    const diffStateField: DiffStateField = {
      type: DiffStateFieldTypeEnum.SECTION,
      name: key,
      displayName: key,
      currentValue: currentValue,
      newValue: newValue,
      diffStatus: getDiffStatus(currentValue, newValue, childDiffStateFields),
      childDiffStateFields: childDiffStateFields,
    };
    return diffStateField;
  });
};
