import { DateTimeFormatEnums } from "../../../utils/Entities";
import MultiSelectHeader from "../../../shared/MultiSelectHeader";

interface DateTimeFormatButtonsProps {
  dateTimeFormat: DateTimeFormatEnums | undefined;
  setDateTimeFormat: React.Dispatch<React.SetStateAction<DateTimeFormatEnums | undefined>>;
}

const DateTimeFormatButtons = ({
  dateTimeFormat,
  setDateTimeFormat,
}: DateTimeFormatButtonsProps) => {
  const handleButtonClick = (value: DateTimeFormatEnums) => {
    setDateTimeFormat(value);
  };

  const options = [
    {
      value: DateTimeFormatEnums.ISO_8601,
      text: "ISO 8601",
      disabled: false,
      selected: dateTimeFormat === DateTimeFormatEnums.ISO_8601,
      onClick: handleButtonClick,
    },
    {
      value: DateTimeFormatEnums.UNIX,
      text: "UNIX",
      disabled: false,
      selected: dateTimeFormat === DateTimeFormatEnums.UNIX,
      onClick: handleButtonClick,
    },
  ];

  return (
    <MultiSelectHeader
      dataTestID="field-auth-config-datetime-format"
      title="Datetime format"
      options={options}
      className="mt-4"
    />
  );
};

export default DateTimeFormatButtons;
