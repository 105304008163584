import { useMemo } from "react";
import {
  BlueprintMetaAddLinkedAccountParamStep,
  BlueprintParameterValueType,
} from "../../../models/Blueprints";
import { HeaderPretitle, SmallTextMutedParagraph } from "../../shared/text/MergeText";
import useBlueprintContext from "../context/useBlueprintContext";
import DropdownFormField from "./DropdownFormField";
import TypeaheadFormField from "./TypeaheadFormField";

const noneQueryParam = {
  name: "None",
  id: "",
};

const BlueprintEditorRightPanelMetaAddLinkedAccountParamForm = () => {
  const { selectedStep, blueprint, updateStepParameterValue } = useBlueprintContext();
  const step = selectedStep as BlueprintMetaAddLinkedAccountParamStep;

  Object.keys(blueprint.parameter_schema?.properties ?? {}).map((key) => ({ name: key, id: key }));
  const conditionalQueryParamChoices = useMemo(
    () => [
      noneQueryParam,
      ...Object.keys(blueprint.parameter_schema?.properties ?? {}).map((key) => ({
        name: key,
        id: key,
      })),
    ],
    [blueprint.parameter_schema?.properties]
  );
  const hasConditionalQueryParams = conditionalQueryParamChoices.length > 1;
  const conditionalQueryParamCurrentValue = step.parameter_values.conditional_key ?? noneQueryParam;

  return (
    <form>
      <HeaderPretitle className="my-2">Parameters</HeaderPretitle>
      <TypeaheadFormField
        title={"Field Location"}
        subtitle={
          "The linked_account_params input parameter that this field should be added to. Use The 'Meta: Access Parent BP Params' step."
        }
        includeUniqueIdentifierCheck={false}
        valueKey={"field_location"}
        parameterType={"field_reference"}
      />
      <TypeaheadFormField
        title={"Field Key"}
        subtitle={"The key to refer the linked account param by."}
        includeUniqueIdentifierCheck={false}
        valueKey={"field_key"}
        parameterType={"string"}
      />
      <TypeaheadFormField
        title={"Field Type"}
        subtitle={"The type of the field, i.e. string, number, boolean."}
        includeUniqueIdentifierCheck={false}
        valueKey={"field_type"}
        parameterType={"string"}
      />
      <TypeaheadFormField
        title={"Field Description"}
        subtitle={"The description of the field if available."}
        includeUniqueIdentifierCheck={false}
        valueKey={"field_description"}
        parameterType={"string"}
      />
      <HeaderPretitle className="mt-3">Conditionals</HeaderPretitle>
      {hasConditionalQueryParams ? (
        <>
          <SmallTextMutedParagraph>
            If this field should only show up when a certain query param is set to a particular
            value, include the key and value below. For example, some custom fields will only occur
            when a "template_id" is set to value corresponding to the template that requires those
            fields.
          </SmallTextMutedParagraph>
          <DropdownFormField
            choices={conditionalQueryParamChoices}
            currentValue={conditionalQueryParamCurrentValue}
            placeholder={"Select to enable Authentication"}
            title={"Conditional Query Param"}
            subtitle={"The key of the query param to condition on."}
            onChange={(e) =>
              updateStepParameterValue(
                step,
                "conditional_key",
                e.target.value
                  ? {
                      constant: e.target.value,
                      value_type: BlueprintParameterValueType.constant,
                    }
                  : { value_type: BlueprintParameterValueType.none }
              )
            }
          />
          <TypeaheadFormField
            title={"Conditional Value"}
            subtitle={
              "The value of the conditional to match against the query param in the request."
            }
            includeUniqueIdentifierCheck={false}
            valueKey={"conditional_value"}
            parameterType={"string"}
          />
        </>
      ) : (
        <SmallTextMutedParagraph>
          If this field should only show up when a certain query param is set to a particular value,
          you can include the key and value. This feature is disabled since this blueprint does not
          have any arguments. If you want to condition some fields, add an input parameter!
        </SmallTextMutedParagraph>
      )}
    </form>
  );
};

export default BlueprintEditorRightPanelMetaAddLinkedAccountParamForm;
