import { BlueprintSearchResultBlueprint } from "../../../../../models/blueprint-search/BlueprintSearchOutputModels";
import BlueprintSearchCardMatchedStepRow from "./BlueprintSearchCardMatchedStepRow";

interface Props {
  blueprintResult: BlueprintSearchResultBlueprint;
  integrationID: string;
}

const BlueprintSearchCardMatchedSteps = ({ blueprintResult, integrationID }: Props) => {
  if (blueprintResult.matched_steps?.length === 0) return null;

  return (
    <div className="flex flex-col space-y-2 w-full">
      {blueprintResult.matched_steps.map((matchedStep) => {
        return (
          <BlueprintSearchCardMatchedStepRow
            blueprintResult={blueprintResult}
            integrationID={integrationID}
            matchedStep={matchedStep}
          />
        );
      })}
    </div>
  );
};

export default BlueprintSearchCardMatchedSteps;
