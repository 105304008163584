import React from "react";
import { ButtonVariant, Dropdown, MenuItem, Text } from "@merge-api/merge-javascript-shared";
import { HTTPMethod } from "../../../../models/HTTPMethods";
import { ChevronDown } from "lucide-react";

interface HTTPMethodColumnHeaderProps {
  methodFilter: HTTPMethod | null;
  setMethodFilter: React.Dispatch<React.SetStateAction<HTTPMethod | null>>;
}

const ALL_METHODS = "All Methods";

const HTTPMethodColumnHeader = ({ methodFilter, setMethodFilter }: HTTPMethodColumnHeaderProps) => {
  const handleMethodFilter = (method: string) => {
    if (method === ALL_METHODS) {
      setMethodFilter(null);
    } else {
      setMethodFilter(method as HTTPMethod);
    }
  };

  return (
    <Dropdown
      ButtonProps={{
        children: (
          <Text className="font-semibold">{methodFilter ? methodFilter : ALL_METHODS}</Text>
        ),
        variant: ButtonVariant.TextBlack,
        rightIcon: <ChevronDown size={12} />,
      }}
    >
      <>
        {[ALL_METHODS, ...Object.values(HTTPMethod)].map((method) => {
          return (
            <MenuItem
              className="hover:bg-gray-0 text-base font-normal text-black"
              onClick={() => handleMethodFilter(method)}
            >
              {method}
            </MenuItem>
          );
        })}
      </>
    </Dropdown>
  );
};

export default HTTPMethodColumnHeader;
