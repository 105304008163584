import { Dialog, Text } from "@merge-api/merge-javascript-shared";
import styled from "styled-components";
import DOMPurify from "dompurify";

const Content = styled.p`
  line-height: 18px;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  counter-reset: list;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  color: #000;

  img {
    border: 1px solid #b1b8c3;
    border-radius: 6px;
  }

  a {
    color: #0000ee;
    text-decoration: underline;
  }

  p {
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
  }

  & > ol,
  & > ul {
    list-style: none;
    display: block;
    padding-inline-start: 0px;
    padding-left: 36px;
    margin: 16px 0;
  }

  & > ol li {
    counter-increment: list;
  }

  ul {
    list-style-type: disc;
  }

  ul ul {
    list-style-type: circle;
  }

  & > ol li::before {
    content: counter(list);
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    color: "#3a3a3a";
    background: "#f4f4f4";
    font-weight: bold;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    margin-left: -36px;
  }

  // Remove any top white-space, preventing double spacing
  & > *:first-child {
    margin-top: 0;
    padding-top: 0;
  }

  // Remove any bottom white-space, preventing double spacing
  & > *:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  unreset ol and li to default chrome user_agent_stylesheet ol ul,
  ul ol,
  ul ul,
  ol ol {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
  }

  ol ol ul,
  ol ul ul,
  ul ol ul,
  ul ul ul {
    list-style-type: square;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  ol {
    margin-bottom: 16px;
  }

  ol li {
    margin-bottom: 16px;
  }

  ol ol,
  ul ul {
    margin-left: 36px;
  }

  ol ol {
    margin-top: 16px;
  }
`;

interface Props {
  open: boolean;
  onClose: () => void;
  description: string;
}

const HtmlPreviewModal = ({ open, onClose, description }: Props) => {
  return (
    <Dialog variant="md" footerButtonsHidden open={open} onClose={onClose} title="Preview HTML">
      <div className="flex flex-column">
        <Text variant="h6" className="pb-2 text-black">
          Description
        </Text>
        <Text className="pb-4 text-gray-90">
          Detailed instructions to guide the end user on how to authenticate.
        </Text>
        <div className="rounded-lg border-[0.5px] border-gray-20 py-3 px-[10px] text-slate-90">
          <Content dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
        </div>
      </div>
    </Dialog>
  );
};

export default HtmlPreviewModal;
