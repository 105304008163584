import ClickableText from "../../../shared/ClickableText";
import SelectHeader from "../../../shared/SelectHeader";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import {
  LinkChoiceStepOptionFormFieldValue,
  LinkChoiceStepOptionFormFieldValueEnums,
  LinkChoiceStepOption,
  LinkChoiceStepOptionFormFieldDisplayNameMap,
  LinkChoiceStepOptionFormFieldDisplayNameReverseMap,
} from "../../../utils/Entities";
import { deleteFormFieldValue, updateFormFieldValue } from "../../utils/helpers";

interface Props {
  indexFormFieldValue: number;
  formFieldValue: LinkChoiceStepOptionFormFieldValue;
  indexStepOption: number;
  requestedLinkChoiceStepOption: LinkChoiceStepOption;
  onCallbackUpdateLinkChoiceStepOption: (index: number, keyValuePairs: Record<string, any>) => void;
}

const FieldOverrideFieldsCard = ({
  indexFormFieldValue,
  formFieldValue,
  indexStepOption,
  requestedLinkChoiceStepOption,
  onCallbackUpdateLinkChoiceStepOption,
}: Props) => {
  return (
    <div className="flex flex-column border-t-[0.5px] border-gray-20 px-5 pt-4 pb-3">
      <div className="pb-5">
        <SelectHeader
          dataTestID={`field-option-card-override-field-${indexStepOption}-${indexFormFieldValue}`}
          title="Override field"
          subtitle="Select one of the following fields to override"
          options={Object.values(LinkChoiceStepOptionFormFieldValueEnums)?.map(
            (formFieldValueEnum) => LinkChoiceStepOptionFormFieldDisplayNameMap[formFieldValueEnum]
          )}
          onChange={(_: any, selectedOption: string | null) => {
            if (selectedOption) {
              updateFormFieldValue(
                onCallbackUpdateLinkChoiceStepOption,
                requestedLinkChoiceStepOption,
                indexStepOption,
                indexFormFieldValue,
                { field_name: LinkChoiceStepOptionFormFieldDisplayNameReverseMap[selectedOption] }
              );
            }
          }}
          value={LinkChoiceStepOptionFormFieldDisplayNameMap[formFieldValue.field_name]}
          clearable={false}
        />
      </div>
      <div className="pb-3">
        <TextFieldHeader
          dataTestID={`field-option-card-override-value-${indexStepOption}-${indexFormFieldValue}`}
          title="Override value"
          subtitle="Override value for the selected field"
          value={formFieldValue.field_value}
          onChange={(event) => {
            updateFormFieldValue(
              onCallbackUpdateLinkChoiceStepOption,
              requestedLinkChoiceStepOption,
              indexStepOption,
              indexFormFieldValue,
              {
                field_value: event.target.value,
              }
            );
          }}
        />
      </div>
      <ClickableText
        dataTestID={`button-option-card-delete-override-${indexStepOption}-${indexFormFieldValue}`}
        text="Delete override"
        color="red"
        hasArrow={false}
        onClick={() =>
          deleteFormFieldValue(
            onCallbackUpdateLinkChoiceStepOption,
            requestedLinkChoiceStepOption,
            indexStepOption,
            indexFormFieldValue
          )
        }
      />
    </div>
  );
};

export default FieldOverrideFieldsCard;
