import {
  DiffModelTypeEnum,
  DiffState,
  DiffStateField,
  DiffStateFieldTypeEnum,
  DiffStatusEnum,
  generateConditionType,
  generateDiffStateFieldInputType,
  generateDiffStateInputType,
} from "../../../../models/DiffModels";
import {
  convertNameToDisplayName,
  convertValueToString,
  getAllKeysInBothCurrentAndNew,
  getDiffStatus,
  getSliceOfState,
  notNull,
} from "./helpers";
import { generateDiffForAPIEndpointsConfiguration } from "./helpers-api-endpoint-config-diff";
import { generateDiffForAPIEndpoint } from "./helpers-api-endpoint-diff";
import { generateDiffForAuthenticationConfiguration } from "./helpers-auth-config-diff";
import { generateDiffForBlueprintStepTemplate } from "./helpers-step-templates-diff/step-templates-diff";
import {
  generateDiffForBlueprint,
  generateDiffStateForBlueprintSteps,
} from "./helpers-blueprints-diff/blueprint-diff";
import { generateDiffForCustomerFacingFields } from "./helpers-customer-facing-fields";
import { generateDiffForIntegrationInitialization } from "./helpers-integration-initialization-diff";
import { generateDiffForMergeLinkStepsConfiguration } from "./helpers-merge-link-steps-config-diff";
import { generateDiffForMergeLinkSteps } from "./helpers-merge-link-steps-diff";
import { generateDiffForPaginationConfiguration } from "./helpers-pag-config-diff";
import { generateDiffForRateLimitConfiguration } from "./helpers-rate-limit-config-diff";
import { generateDiffForSelectiveSyncFilterSchema } from "./helpers-selective-sync-filter-schema";
import { generateDiffForScraper } from "../helpers-scraper-diff";

// Global variables
export let globalCurrentState: { [key: string]: any } = {};
export let globalNewState: { [key: string]: any } = {};

/* ------- MAIN FUNCTION ------- */
export const compareStates = (
  diffModelType: DiffModelTypeEnum,
  currentRawState: { [key: string]: any },
  newRawState: { [key: string]: any }
): DiffState => {
  globalCurrentState = currentRawState;
  globalNewState = newRawState;

  switch (diffModelType) {
    case DiffModelTypeEnum.INTEGRATION_INITIALIZATION:
      return generateDiffForIntegrationInitialization();
    case DiffModelTypeEnum.AUTHENTICATION_CONFIGURATION:
      return generateDiffForAuthenticationConfiguration();
    case DiffModelTypeEnum.MERGE_LINK_STEPS_CONFIGURATION:
      return generateDiffForMergeLinkStepsConfiguration();
    case DiffModelTypeEnum.MERGE_LINK_STEPS:
      return generateDiffForMergeLinkSteps({
        hasMergeLinkSteps: true,
        hasLinkChoiceStepOptions: true,
      });
    case DiffModelTypeEnum.MERGE_LINK_STEPS_CHANGELOG:
      return generateDiffForMergeLinkSteps({
        hasMergeLinkSteps: true,
        hasLinkChoiceStepOptions: false,
      });
    case DiffModelTypeEnum.LINK_CHOICE_STEP_OPTION_CHANGELOG:
      return generateDiffForMergeLinkSteps({
        hasMergeLinkSteps: false,
        hasLinkChoiceStepOptions: true,
      });
    case DiffModelTypeEnum.PAGINATION_CONFIGURATION:
      return generateDiffForPaginationConfiguration({ isForAPIEndpoint: false });
    case DiffModelTypeEnum.RATE_LIMIT_CONFIGURATION:
      return generateDiffForRateLimitConfiguration({ isForAPIEndpoint: false });
    case DiffModelTypeEnum.API_ENDPOINT_CONFIGURATION:
      return generateDiffForAPIEndpointsConfiguration();
    case DiffModelTypeEnum.API_ENDPOINT:
      return generateDiffForAPIEndpoint({ isChangelog: false });
    case DiffModelTypeEnum.API_ENDPOINT_CHANGELOG:
      return generateDiffForAPIEndpoint({ isChangelog: true });
    case DiffModelTypeEnum.CUSTOMER_FACING_FIELDS:
      return generateDiffForCustomerFacingFields();
    case DiffModelTypeEnum.BLUEPRINT:
      return generateDiffForBlueprint();
    case DiffModelTypeEnum.BLUEPRINT_STEP_TEMPLATE:
      return generateDiffForBlueprintStepTemplate();
    case DiffModelTypeEnum.DEFAULT:
      return generateDiffForSimpleComponents(currentRawState, newRawState);
    case DiffModelTypeEnum.SELECTIVE_SYNC_FILTER_SCHEMA:
      return generateDiffForSelectiveSyncFilterSchema();
    case DiffModelTypeEnum.SCRAPER:
      return generateDiffForScraper();
    default:
      return generateDiffForSimpleComponents(currentRawState, newRawState);
  }
};

/* ------- TO GENERATE DIFF STATE FIELD ------- */

export const generateDiffStateField = (
  inputField: generateDiffStateFieldInputType,
  overrideCurrentState?: { [key: string]: string },
  overrideNewState?: { [key: string]: string }
): DiffStateField | null => {
  /* BEGIN - Set up currentState and newState to process inputField */
  // Initialize currentState and newState based on inputs
  let currentState = overrideCurrentState ?? globalCurrentState;
  let newState = overrideNewState ?? globalNewState;

  // Only generate if true
  if (!shouldGenerateDiffStateField(inputField.generateCondition, currentState, newState)) {
    return null;
  }

  if (inputField.keyPathToName && inputField.keyPathToName.length > 0) {
    currentState = getSliceOfState(currentState, inputField.keyPathToName) ?? currentState;
    newState = getSliceOfState(newState, inputField.keyPathToName) ?? newState;
  }
  /* END - Set up currentState and newState to process inputField */

  /* Don't even process if isRenderNoValueAsEmpty and neither state has this  field.
   * This is especially helpful for Selective Sync's Operators.
   */
  if (inputField.isRenderNoValueAsEmpty) {
    if (
      !currentState.hasOwnProperty(inputField.name) &&
      !newState.hasOwnProperty(inputField.name)
    ) {
      return null;
    }
  }

  // Create child fields
  const childFields: DiffState = inputField.childDiffStateFields
    ? inputField.childDiffStateFields
    : inputField.generateChildDiffStateInput
    ? generateDiffState({
        ...inputField.generateChildDiffStateInput,
        overrideCurrentState: currentState,
        overrideNewState: newState,
      })
    : inputField.isChildBlueprintSteps
    ? generateDiffStateForBlueprintSteps({})
    : (inputField.childDiffStateInputFields ?? [])
        .map((inputChildField) => {
          inputChildField = {
            ...inputChildField,
            isRenderCurrentAsEmpty: inputField.isRenderCurrentAsEmpty ?? false,
            isRenderNewAsEmpty: inputField.isRenderNewAsEmpty ?? false,
          };
          return overrideCurrentState
            ? generateDiffStateField(inputChildField, currentState, newState)
            : generateDiffStateField(inputChildField);
        })
        .filter(notNull);

  // Create field
  const diffStateField: DiffStateField = {
    type: inputField.type ? inputField.type : DiffStateFieldTypeEnum.FIELD,
    name: inputField.name,
    displayName: inputField.isRenderNumberOfChildrenInDisplayName
      ? `${inputField.displayName} (${childFields.length})`
      : inputField.displayName,
    displayNameCurrent: inputField.displayNameCurrent ?? "",
    displayNameNew: inputField.displayNameNew ?? "",
    currentValue: inputField.isRenderAsEmpty
      ? ""
      : currentState.hasOwnProperty(inputField.name)
      ? convertValueToString(currentState[inputField.name], inputField.isRenderAsSecret)
      : "",
    newValue: inputField.isRenderAsEmpty
      ? ""
      : newState.hasOwnProperty(inputField.name)
      ? convertValueToString(newState[inputField.name], inputField.isRenderAsSecret)
      : "",
    diffStatus: inputField.isRenderNewAsEmpty
      ? DiffStatusEnum.DELETED
      : inputField.isRenderCurrentAsEmpty
      ? DiffStatusEnum.ADDED
      : getDiffStatus(
          convertValueToString(currentState[inputField.name]),
          convertValueToString(newState[inputField.name]),
          childFields
        ),
    isRenderChildrenAsNested: inputField.isRenderChildrenAsNested ?? false,
    isRenderAsPreviewable: inputField.isRenderAsPreviewable ?? false,
    isRenderCurrentAsEmpty: inputField.isRenderCurrentAsEmpty ?? false,
    isRenderNewAsEmpty: inputField.isRenderNewAsEmpty ?? false,
    isRenderAsSecret: inputField.isRenderAsSecret ?? false,
    isRenderAsExpanded: inputField.isRenderAsExpanded ?? false,
    childDiffStateFields: childFields,
    childDiffStateNestedFields: inputField.childDiffStateNestedFields ?? [],
  };

  return diffStateField;
};

const shouldGenerateDiffStateField = (
  generateCondition: generateConditionType | undefined,
  overrideCurrentState?: { [key: string]: any },
  overrideNewState?: { [key: string]: any }
): boolean => {
  if (generateCondition) {
    let currentStateForCondition = overrideCurrentState ?? globalCurrentState;
    let newStateForCondition = overrideNewState ?? globalNewState;
    if (generateCondition.keyPathToName && generateCondition.keyPathToName.length > 0) {
      currentStateForCondition =
        getSliceOfState(currentStateForCondition, generateCondition.keyPathToName) ??
        currentStateForCondition;
      newStateForCondition =
        getSliceOfState(newStateForCondition, generateCondition.keyPathToName) ?? {};
    }

    const currentValueForCondition = currentStateForCondition.hasOwnProperty(generateCondition.name)
      ? currentStateForCondition[generateCondition.name]
      : undefined;
    const newValueForCondition = newStateForCondition.hasOwnProperty(generateCondition.name)
      ? newStateForCondition[generateCondition.name]
      : undefined;

    if (
      !(
        generateCondition.valuesToMatch.includes(currentValueForCondition) ||
        generateCondition.valuesToMatch.includes(newValueForCondition)
      )
    ) {
      return false;
    }
  }
  return true;
};

export const generateDiffState = ({
  fields,
  isStateAnArray,
  arrayItemHeaderName,
  arrayItemIdentifier,
  arrayItemNameToDisplayAsHeaderValue,
  isIgnoreArrayOrder,
  keyPathToSliceOfState,
  overrideCurrentState,
  overrideNewState,
}: generateDiffStateInputType): DiffState => {
  // Gets slice of global states
  // Helpful for things like states for Merge Link steps, which contains both Merge Link steps and Option
  let currentState = overrideCurrentState ?? globalCurrentState;
  let newState = overrideNewState ?? globalNewState;
  let sliceOfCurrentState: any = undefined;
  let sliceOfNewState: any = undefined;
  if (keyPathToSliceOfState) {
    sliceOfCurrentState = getSliceOfState(currentState, keyPathToSliceOfState);
    sliceOfNewState = getSliceOfState(newState, keyPathToSliceOfState);
  }

  // If we're processing an array
  if (isStateAnArray) {
    // Validate states are arrays
    if (!(sliceOfCurrentState && Array.isArray(sliceOfCurrentState))) {
      sliceOfCurrentState = [];
    }
    if (!(sliceOfNewState && Array.isArray(sliceOfNewState))) {
      sliceOfNewState = [];
    }
    return generateDiffStateForArray(
      arrayItemHeaderName,
      arrayItemIdentifier,
      arrayItemNameToDisplayAsHeaderValue,
      isIgnoreArrayOrder,
      fields,
      sliceOfCurrentState,
      sliceOfNewState
    );
  }
  // If not array, we're just processing any other field
  else {
    // If processing based on a slice of state
    if (sliceOfCurrentState && sliceOfNewState) {
      return fields
        .map((field) => generateDiffStateField(field, sliceOfCurrentState, sliceOfNewState))
        .filter(notNull);
    }
    // Else we're processing based on entire state
    else {
      return fields.map((field) => generateDiffStateField(field)).filter(notNull);
    }
  }
};

const generateDiffStateForArray = (
  arrayItemHeaderName: string = "",
  arrayItemIdentifier: string = "id",
  arrayItemNameToDisplayAsHeaderValue: string = "",
  isIgnoreArrayOrder: boolean = false,
  fields: generateDiffStateFieldInputType[],
  currentArray: { [key: string]: any }[],
  newArray: { [key: string]: any }[]
): DiffState => {
  /* Should always match by an identifier */
  // match by some id
  // if current has id but not new, then a DiffStateField should be created such that new value renders empty
  // double pointer, iterate thru both arrays
  // if deleted (aka current identiifer doesn't exist in new identifier)
  // create diffstatefield
  // iterate pointer_current
  // else if added (aka new doesn't exist in current)
  // create diffstatefield
  // iterate pointer_new
  // else if updated (aka new exists in current)
  // create diffstatefield via index
  // iterate both pointer_new and pointer_current

  const currentIdentifiers: string[] = currentArray
    .filter(
      (item) =>
        arrayItemIdentifier in item &&
        item[arrayItemIdentifier] !== null &&
        item[arrayItemIdentifier] !== undefined
    )
    .map((item) => item[arrayItemIdentifier]);
  let newIdentifiers: string[] = newArray
    .filter(
      (item) =>
        arrayItemIdentifier in item &&
        item[arrayItemIdentifier] !== null &&
        item[arrayItemIdentifier] !== undefined
    )
    .map((item) => item[arrayItemIdentifier]);

  // Sort based on matching identifiers instead of order
  // We essentially want to re-sort newIdentifiers to match order of currentIdentifiers, to make it easy for diff
  if (isIgnoreArrayOrder) {
    // First, map item's identifier to item
    let newArrayMap: Record<string, any> = {};
    newIdentifiers.forEach((id) => {
      const newItem = newArray.find((item) => item[arrayItemIdentifier] === id);
      if (newItem) {
        newArrayMap = { ...newArrayMap, [id]: newItem };
      }
    });
    let tempNewIdentifiers: string[] = [];
    let tempNewArray: any[] = [];
    const newIdentifiersSet = new Set(newIdentifiers);
    const newArraySet = new Set(newArray);
    for (const currentId of currentIdentifiers) {
      if (newIdentifiersSet.has(currentId)) {
        tempNewIdentifiers.push(currentId);
        tempNewArray.push(newArrayMap[currentId]);
        newIdentifiersSet.delete(currentId);
        newArraySet.delete(newArrayMap[currentId]);
      }
    }
    newIdentifiers = [...tempNewIdentifiers, ...newIdentifiersSet];
    newArray = [...tempNewArray, ...newArraySet];
  }

  let indexCurrent = 0;
  let indexNew = 0;
  let diffStateFields: DiffState = [];

  while (indexCurrent < currentIdentifiers.length || indexNew < newIdentifiers.length) {
    // If deleted
    if (
      indexCurrent < currentIdentifiers.length &&
      !newIdentifiers.includes(currentIdentifiers[indexCurrent])
    ) {
      const item = currentArray[indexCurrent];
      const inputField: generateDiffStateFieldInputType = {
        type: DiffStateFieldTypeEnum.SECTION,
        name: arrayItemHeaderName,
        displayName: arrayItemHeaderName + " " + (indexCurrent + 1).toString(),
        isRenderNewAsEmpty: true,
        childDiffStateFields: fields
          .map((field) => generateDiffStateField({ ...field, isRenderNewAsEmpty: true }, item, {}))
          .filter(notNull),
      };
      const overrideCurrentStateToGetHeaderValue = overrideStateForArrayItemHeaderValue(
        item,
        arrayItemHeaderName,
        arrayItemNameToDisplayAsHeaderValue
      );
      const field = generateDiffStateField(inputField, overrideCurrentStateToGetHeaderValue, {});
      if (field) {
        diffStateFields.push(field);
      }
      indexCurrent += 1;
    }
    // If added
    else if (
      indexNew < newIdentifiers.length &&
      !currentIdentifiers.includes(newIdentifiers[indexNew])
    ) {
      const item = newArray[indexNew];
      const inputField: generateDiffStateFieldInputType = {
        type: DiffStateFieldTypeEnum.SECTION,
        name: arrayItemHeaderName,
        displayName: arrayItemHeaderName + " " + (indexNew + 1).toString(),
        isRenderCurrentAsEmpty: true,
        childDiffStateFields: fields
          .map((field) =>
            generateDiffStateField({ ...field, isRenderCurrentAsEmpty: true }, {}, item)
          )
          .filter(notNull),
      };
      const overrideNewStateToGetHeaderValue = overrideStateForArrayItemHeaderValue(
        item,
        arrayItemHeaderName,
        arrayItemNameToDisplayAsHeaderValue
      );
      const field = generateDiffStateField(inputField, {}, overrideNewStateToGetHeaderValue);
      if (field) {
        diffStateFields.push(field);
      }
      indexNew += 1;
    }
    // If updated
    else if (currentIdentifiers.includes(newIdentifiers[indexNew])) {
      const currentItem = currentArray[indexCurrent] ?? {};
      const newItem = newArray[indexNew] ?? {};
      const inputField: generateDiffStateFieldInputType = {
        type: DiffStateFieldTypeEnum.SECTION,
        name: arrayItemHeaderName,
        displayName: "",
        displayNameCurrent: arrayItemHeaderName + " " + (indexCurrent + 1).toString(),
        displayNameNew: arrayItemHeaderName + " " + (indexNew + 1).toString(),
        childDiffStateFields: fields
          .map((field) => generateDiffStateField(field, currentItem, newItem))
          .filter(notNull),
      };
      const overrideCurrentStateToGetHeaderValue = overrideStateForArrayItemHeaderValue(
        currentItem,
        arrayItemHeaderName,
        arrayItemNameToDisplayAsHeaderValue
      );
      const overrideNewStateToGetHeaderValue = overrideStateForArrayItemHeaderValue(
        newItem,
        arrayItemHeaderName,
        arrayItemNameToDisplayAsHeaderValue
      );
      const field = generateDiffStateField(
        inputField,
        overrideCurrentStateToGetHeaderValue,
        overrideNewStateToGetHeaderValue
      );
      if (field) {
        diffStateFields.push(field);
      }

      indexNew += 1;
      indexCurrent += 1;
    }
    // Should never happen, but this is here to prevent infinite while loop
    else {
      indexNew = newIdentifiers.length;
      indexCurrent = currentIdentifiers.length;
    }
  }

  return diffStateFields;
};

const overrideStateForArrayItemHeaderValue = (
  item: { [key: string]: any },
  keyName: string,
  keyInItemToValue: string
): { [key: string]: any } => {
  return keyInItemToValue !== "" ? { [keyName]: item[keyInItemToValue] } : {};
};

// DEFAULT FALLBACK FOR GENERATING DIFF STATAE
export const generateDiffForSimpleComponents = (
  currentRawState: { [key: string]: any },
  newRawState: { [key: string]: any }
): DiffState => {
  const allKeys = getAllKeysInBothCurrentAndNew(currentRawState, newRawState);

  return allKeys.map((key) => {
    const currentValue = key in currentRawState ? convertValueToString(currentRawState[key]) : "";
    const newValue = key in newRawState ? convertValueToString(newRawState[key]) : "";
    const diffStateField: DiffStateField = {
      type: DiffStateFieldTypeEnum.FIELD,
      name: key,
      displayName: convertNameToDisplayName(key),
      currentValue: currentValue,
      newValue: newValue,
      diffStatus: getDiffStatus(currentValue, newValue),
    };
    return diffStateField;
  });
};

/**
 * Generates an empty DiffState
 * Use when there are no child DiffStates detected for a section (ie.: no parameters for step's input schema)
 * @param {string} name - The name of the DiffState
 * @param {string} displayName - The display name of the DiffState
 * @param {DiffStatusEnum} [diffStatus=DiffStatusEnum.NO_CHANGES] - The diff status of the DiffState
 * @param {boolean} [isRenderNewAsEmpty=false] - Flag to render new value as empty
 * @param {boolean} [isRenderCurrentAsEmpty=false] - Flag to render current value as empty
 */
export const generateEmptyDiffState = (
  name: string,
  displayName: string,
  diffStatus: DiffStatusEnum = DiffStatusEnum.NO_CHANGES,
  isRenderNewAsEmpty: boolean = false,
  isRenderCurrentAsEmpty: boolean = false
): DiffState => {
  return [
    {
      type: DiffStateFieldTypeEnum.EMPTY,
      name: name,
      displayName: displayName,
      currentValue: "",
      newValue: "",
      diffStatus: diffStatus,
      isRenderNewAsEmpty: isRenderNewAsEmpty,
      isRenderCurrentAsEmpty: isRenderCurrentAsEmpty,
    },
  ];
};
