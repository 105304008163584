import { Badge } from "@merge-api/merge-javascript-shared";
import { WebConnectorSession } from "../../../../models/Blueprints";
import {
  WebConnectorSessionStatusColorMap,
  WebConnectorSessionStatusTextMap,
} from "../../../shared/utils/SharedComponentUtils";

interface WebConnectorLiveRunStatusBadgeProps {
  webConnectorSessionResponse: WebConnectorSession;
}

const WebConnectorLiveRunStatusBadge = ({
  webConnectorSessionResponse,
}: WebConnectorLiveRunStatusBadgeProps) => {
  const { status } = webConnectorSessionResponse;

  return (
    <>
      {status && (
        <Badge size="lg" color={WebConnectorSessionStatusColorMap[status]}>
          {WebConnectorSessionStatusTextMap[status]}
        </Badge>
      )}
    </>
  );
};

export default WebConnectorLiveRunStatusBadge;
