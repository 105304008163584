import clsx from "clsx";
import { ParamsLocation } from "../../../../integrations/auth-config/AuthConfigShared";
import MultiSelectHeader from "../../../shared/MultiSelectHeader";

interface QueryBodyParamButtonsProps {
  requestParamType: ParamsLocation | undefined;
  setRequestParamType: React.Dispatch<React.SetStateAction<ParamsLocation | undefined>>;
  className?: string;
}

const QueryBodyParamButtons = ({
  requestParamType,
  setRequestParamType,
  className,
}: QueryBodyParamButtonsProps) => {
  const handleButtonClick = (value: ParamsLocation) => {
    setRequestParamType(value);
  };

  const options = [
    {
      value: ParamsLocation.body_params,
      text: "Body",
      disabled: false,
      selected: requestParamType === ParamsLocation.body_params,
      onClick: handleButtonClick,
    },
    {
      value: ParamsLocation.query_params,
      text: "Query",
      disabled: false,
      selected: requestParamType === ParamsLocation.query_params,
      onClick: handleButtonClick,
    },
  ];
  return (
    <MultiSelectHeader
      dataTestID="field-auth-config-token-params-location"
      title="Params location"
      subtitle="The location that the integration will put the fields in"
      required
      options={options}
      className={clsx("w-full", className)}
    />
  );
};

export default QueryBodyParamButtons;
