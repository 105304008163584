import { ValidatedStagedComponent } from "../types";
import PublishModuleStagedComponentVersionCardV2 from "./PublishModuleStagedComponentVersionCardV2";

type Props = {
  stagedComponents: ValidatedStagedComponent[];
  integrationID: string;
};

const PublishModuleStagedComponentCardV2 = ({ stagedComponents, integrationID }: Props) => {
  return (
    <div className="space-y-4">
      {stagedComponents.map((stagedComponent) => (
        <PublishModuleStagedComponentVersionCardV2
          stagedComponent={stagedComponent}
          integrationID={integrationID}
        />
      ))}
    </div>
  );
};

export default PublishModuleStagedComponentCardV2;
