import {
  DiffState,
  DiffStateField,
  DiffStateFieldTypeEnum,
  DiffStatusEnum,
} from "../../../../../models/DiffModels";
import { getDiffStatus } from "../helpers";

/**
 * The functions in helpers-blueprint-step-params-diff.ts process diff states for a step's parameter_values
 * 
 * This is a 2-step process
 * 1) Normalize parameter_values
 * 2) Generate diff state from parameter_values
 * 
 * EXAMPLE
 * 
 * INPUT PARAMETER VALUES
    {
          "code": {
            "constant": "x1 + x2",
            "value_type": "CONSTANT"
          },
          "arguments": {
            "nested_parameter_values": {
              "x1": {
                "step_id": "get_company_id",
                "return_schema_path": [
                  "properties",
                  "return_value"
                ],
                "request_return_value_path": [
                  "return_value"
                ],
                "value_type": "RETURN_VALUE"
              },
            },
            "value_type": "NESTED_PARAMETER_VALUES"
          }
        } 
 * NORMALIZED PARAMETER VALUES
    {
      "code": "x1+x2",
      "arguments": {
        "x1": "get_company_id.return_value",
      }
    }
 * OUTPUT DIFF STATE
    [
        {
            "type": "FIELD",
            "name": "arguments",
            "displayName": "arguments",
            "currentValue": "",
            "newValue": "",
            "isRenderCurrentAsEmpty": true,
            "isRenderNewAsEmpty": false,
            "isRenderAsExpanded": true,
            "diffStatus": "ADDED",
            "childDiffStateFields": [
                {
                    "type": "FIELD",
                    "name": "x1",
                    "displayName": "x1",
                    "currentValue": "",
                    "newValue": "get_company_id.return_value",
                    "isRenderCurrentAsEmpty": true,
                    "isRenderNewAsEmpty": false,
                    "diffStatus": "ADDED"
                }
            ]
        },
        {
            "type": "FIELD",
            "name": "code",
            "displayName": "code",
            "currentValue": "",
            "newValue": "x1 + x2",
            "isRenderCurrentAsEmpty": true,
            "isRenderNewAsEmpty": false,
            "diffStatus": "ADDED"
        }
    ]
*/

// Generates diff state given current & new step's parameter_values
export const generateDiffStateForStepParams = (
  currentStep: { [key: string]: any },
  newStep: { [key: string]: any },
  overrideDiffStatus?: DiffStatusEnum | undefined // Usd o
): DiffState => {
  // Normalize parameter_values
  let currentStepParams: { [key: string]: any } = {};
  let newStepParams: { [key: string]: any } = {};

  if ("parameter_values" in currentStep) {
    currentStepParams = normalizeStepParams(currentStep["parameter_values"]);
  }
  if ("parameter_values" in newStep) {
    newStepParams = normalizeStepParams(newStep["parameter_values"]);
  }

  // Generates diff from normalized parameter_values
  return generateDiffStateForParams(currentStepParams, newStepParams, overrideDiffStatus);
};

// Generates diff from normalized parameter_values
// Sort alphabetically, then detect deleted/added/updated/no-changes
const generateDiffStateForParams = (
  currentParams: { [key: string]: any },
  newParams: { [key: string]: any },
  overrideDiffStatus?: DiffStatusEnum | undefined
): DiffState => {
  // Ensure currentParams and newParams are objects
  currentParams = currentParams || {};
  newParams = newParams || {};

  // Sort IDs in alphabetical order
  const currentIDs: string[] = Object.keys(currentParams).sort();
  const newIDs: string[] = Object.keys(newParams).sort();

  let indexCurrent = 0;
  let indexNew = 0;
  let diffStateFields: DiffState = [];

  while (indexCurrent < currentIDs.length || indexNew < newIDs.length) {
    if (indexCurrent < currentIDs.length && indexNew < newIDs.length) {
      const currentID = currentIDs[indexCurrent];
      const newID = newIDs[indexNew];
      if (currentID === newID) {
        const paramKey = newIDs[indexNew];
        diffStateFields = addExistingFieldForParam(
          diffStateFields,
          paramKey,
          currentParams[paramKey],
          newParams[paramKey],
          overrideDiffStatus
        );
        indexNew += 1;
        indexCurrent += 1;
      } else if (currentID < newID) {
        // If currentID is alphabetically before newID, then process currentID as DELETED
        const paramKey = currentIDs[indexCurrent];
        diffStateFields = addDeletedFieldForParam(
          diffStateFields,
          paramKey,
          currentParams[paramKey],
          overrideDiffStatus
        );
        indexCurrent += 1;
      } else {
        // If newID is alphabetically before currentID, then process newID as ADDED
        const paramKey = newIDs[indexNew];
        diffStateFields = addAddedFieldForParam(
          diffStateFields,
          paramKey,
          newParams[paramKey],
          overrideDiffStatus
        );
        indexNew += 1;
      }
    } else if (indexCurrent < currentIDs.length && indexNew === newIDs.length) {
      // If current and no more new, then its DELETED
      const paramKey = currentIDs[indexCurrent];
      diffStateFields = addDeletedFieldForParam(
        diffStateFields,
        paramKey,
        currentParams[paramKey],
        overrideDiffStatus
      );
      indexCurrent += 1;
    } else if (indexNew < newIDs.length && indexCurrent === currentIDs.length) {
      // If new and no more current, then its ADDED
      const paramKey = newIDs[indexNew];
      diffStateFields = addAddedFieldForParam(
        diffStateFields,
        paramKey,
        newParams[paramKey],
        overrideDiffStatus
      );
      indexNew += 1;
    }
  }

  return diffStateFields;
};

const normalizeStepParams = (params: { [key: string]: any }): { [key: string]: any } => {
  let stepParamInputFields: { [key: string]: any } = {};

  Object.entries(params).forEach(([key, value]) => {
    let normalizedValue: string | { [key: string]: any } = "";
    if (value?.hasOwnProperty("value_type")) {
      switch (value["value_type"]) {
        case "CONSTANT": {
          normalizedValue = value?.hasOwnProperty("constant")
            ? value["constant"]
            : JSON?.stringify(value);
          break;
        }
        case "RETURN_VALUE": {
          normalizedValue =
            value?.hasOwnProperty("step_id") && value?.hasOwnProperty("request_return_value_path")
              ? `${value["step_id"]}.${value["request_return_value_path"].join(".")}`
              : JSON?.stringify(value);
          break;
        }
        case "GLOBAL_VARIABLE":
        case "INPUT_PARAMETER": {
          normalizedValue = value?.hasOwnProperty("request_return_value_path")
            ? value["request_return_value_path"].join(".")
            : JSON?.stringify(value);
          break;
        }
        case "VARIABLE": {
          normalizedValue = value?.hasOwnProperty("key") ? value["key"] : JSON?.stringify(value);
          break;
        }
        case "NONE": {
          normalizedValue = "None";
          break;
        }
        case "NESTED_PARAMETER_VALUES": {
          normalizedValue = value?.hasOwnProperty("nested_parameter_values")
            ? normalizeStepParams(value["nested_parameter_values"])
            : JSON.stringify(value);
          break;
        }
        case "CUSTOM_OBJECT": {
          normalizedValue = value?.hasOwnProperty("object_value")
            ? normalizeStepParams(value["object_value"])
            : JSON.stringify(value);
          break;
        }
        case "CUSTOM_ARRAY": {
          if (value?.hasOwnProperty("array_values") && Array?.isArray(value["array_values"])) {
            let nestedParamDict: { [key: string]: any } = {};
            value["array_values"].forEach((element, index) => {
              nestedParamDict[`Item ${index + 1}`] = element;
            });
            normalizedValue = normalizeStepParams(nestedParamDict);
          } else {
            normalizedValue = JSON?.stringify(value);
          }
          break;
        }
        case "PROCEDURE_ARRAY": {
          if (
            value?.hasOwnProperty("procedure_array") &&
            Array?.isArray(value["procedure_array"])
          ) {
            let nestedParamDict: { [key: string]: any } = {};
            value["procedure_array"]?.forEach((procedure) => {
              nestedParamDict[procedure["id"]] = {
                ...normalizeStepParams(procedure["parameter_values"]),
                procedure_type: procedure["procedure_type"],
              };
            });
            normalizedValue = nestedParamDict;
          } else {
            normalizedValue = JSON?.stringify(value);
          }
          break;
        }
        case "STATEMENT_ARRAY": {
          if (value.hasOwnProperty("statement_array") && Array.isArray(value["statement_array"])) {
            let nestedParamDict: { [key: string]: any } = {};
            value["statement_array"].forEach((statement: { [key: string]: any }) => {
              const { id, ...statementParams } = statement;
              nestedParamDict[id] = normalizeStepParams(statementParams);
            });
            normalizedValue = nestedParamDict;
          } else {
            normalizedValue = JSON?.stringify(value);
          }
          break;
        }
        default: {
          normalizedValue = JSON?.stringify(value);
        }
      }
    }

    // Add check for "is_unique_identifier" fields
    if (
      value?.hasOwnProperty("is_unique_identifier") &&
      value["is_unique_identifier"] === true &&
      typeof normalizedValue !== "object"
    ) {
      normalizedValue = normalizedValue.concat(" ", "[IS UNIQUE ID]");
    }

    // Enum params have a field called "choice_mapping" (ie.: Ticket.status) that should feed into normalizedValue
    if (value?.hasOwnProperty("choice_mapping") && typeof normalizedValue !== "object") {
      normalizedValue = normalizedValue.concat(" ", JSON.stringify(value["choice_mapping"]));
    }

    stepParamInputFields[key] = normalizedValue;
  });

  return stepParamInputFields;
};

interface GeneratedDiffStateFieldForParamProps {
  paramKey: string;
  currentParamValue: { [key: string]: any } | string;
  newParamValue: { [key: string]: any } | string;
  isRenderCurrentAsEmpty?: boolean;
  isRenderNewAsEmpty?: boolean;
  overrideDiffStatus?: DiffStatusEnum | undefined;
}

const generateDiffStateFieldForParam = ({
  paramKey,
  currentParamValue,
  newParamValue,
  isRenderCurrentAsEmpty = false,
  isRenderNewAsEmpty = false,
  overrideDiffStatus,
}: GeneratedDiffStateFieldForParamProps): DiffStateField | null => {
  // Convert new & current to be the same type
  if (isRenderNewAsEmpty) {
    newParamValue = typeof currentParamValue === "string" ? "" : {};
  }
  if (isRenderCurrentAsEmpty) {
    currentParamValue = typeof newParamValue === "string" ? "" : {};
  }

  // If "string", create FIELD
  // If "object", process child fields as well
  if (typeof currentParamValue === "string" && typeof newParamValue === "string") {
    const diffStateField: DiffStateField = {
      type: DiffStateFieldTypeEnum.FIELD,
      name: paramKey,
      displayName: paramKey,
      currentValue: currentParamValue,
      newValue: newParamValue,
      isRenderCurrentAsEmpty: isRenderCurrentAsEmpty,
      isRenderNewAsEmpty: isRenderNewAsEmpty,
      diffStatus: overrideDiffStatus
        ? overrideDiffStatus
        : getDiffStatus(currentParamValue, newParamValue),
    };
    return diffStateField;
  } else if (typeof currentParamValue === "object" && typeof newParamValue === "object") {
    const childDiffStateFields = generateDiffStateForParams(
      currentParamValue,
      newParamValue,
      overrideDiffStatus
    );
    const diffStateField: DiffStateField = {
      type: DiffStateFieldTypeEnum.FIELD,
      name: paramKey,
      displayName: paramKey,
      currentValue: "",
      newValue: "",
      isRenderCurrentAsEmpty: isRenderCurrentAsEmpty,
      isRenderNewAsEmpty: isRenderNewAsEmpty,
      isRenderAsExpanded: true,
      diffStatus: overrideDiffStatus
        ? overrideDiffStatus
        : getDiffStatus("", "", childDiffStateFields),
      childDiffStateFields: childDiffStateFields,
    };
    return diffStateField;
  }
  return null;
};

/* HELPERS FOR STEP PARAMS */
const addDeletedFieldForParam = (
  diffStateFields: DiffStateField[],
  paramKey: string,
  paramValue: { [key: string]: any },
  overrideDiffStatus: DiffStatusEnum | undefined
): DiffStateField[] => {
  const diffStateField: DiffStateField | null = generateDiffStateFieldForParam({
    paramKey: paramKey,
    currentParamValue: paramValue,
    newParamValue: {},
    isRenderNewAsEmpty: true,
    overrideDiffStatus: overrideDiffStatus,
  });
  if (diffStateField) {
    diffStateFields.push(diffStateField);
  }
  return diffStateFields;
};

const addAddedFieldForParam = (
  diffStateFields: DiffStateField[],
  paramKey: string,
  paramValue: { [key: string]: any },
  overrideDiffStatus: DiffStatusEnum | undefined
): DiffStateField[] => {
  const diffStateField: DiffStateField | null = generateDiffStateFieldForParam({
    paramKey: paramKey,
    currentParamValue: {},
    newParamValue: paramValue,
    isRenderCurrentAsEmpty: true,
    overrideDiffStatus: overrideDiffStatus,
  });
  if (diffStateField) {
    diffStateFields.push(diffStateField);
  }
  return diffStateFields;
};

const addExistingFieldForParam = (
  diffStateFields: DiffStateField[],
  paramKey: string,
  currentParamValue: { [key: string]: any },
  newParamValue: { [key: string]: any },
  overrideDiffStatus: DiffStatusEnum | undefined
): DiffStateField[] => {
  const diffStateField: DiffStateField | null = generateDiffStateFieldForParam({
    paramKey: paramKey,
    currentParamValue: currentParamValue,
    newParamValue: newParamValue,
    overrideDiffStatus: overrideDiffStatus,
  });
  if (diffStateField) {
    diffStateFields.push(diffStateField);
  }
  return diffStateFields;
};
