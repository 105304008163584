import { fetchWithAuth } from "../../../api-client/api_client";
import { showErrorToast } from "../../../components/shared/Toasts";
import { APIEndpointMethod } from "../../../models/Entities";

interface Props {
  autogen_run_id: string;
}

const usePatchAutogeneratedModelRun = ({ autogen_run_id }: Props) => {
  const patchAutogeneratedModelRun = async (notification_status: string) => {
    fetchWithAuth({
      path: `/integrations/integration-builder/autogenerated-model-run/${autogen_run_id}`,
      method: APIEndpointMethod.PATCH,
      body: { notification_status },
      onResponse: () => {},
      onError: () => {
        showErrorToast("Failed to update autogenerated model run.");
      },
    });
  };

  return {
    patchAutogeneratedModelRun,
  };
};

export default usePatchAutogeneratedModelRun;
