import { BLUEPRINT_OPERATION_TYPE_LIST } from "../../../models/Helpers";
import { useParams } from "react-router-dom";
import { getBlueprintsOperationTypePath } from "../../../router/RouterUtils";
import SubLeftNavigationContainer from "../../shared/layout/SubLeftNavigationContainer";
import {
  getOperationNameFromOperationType,
  getTriggerTypeNameFromTriggerType,
} from "../../blueprint-editor/utils/BlueprintEditorUtils";
import { BlueprintOperationType, BlueprintTriggerType } from "../../../models/Blueprints";
import { firstLetterUpperCase } from "../../../utils";

type Props = { children: JSX.Element | JSX.Element[] };

type RouteParams = {
  integrationID: string;
};

function BlueprintsTablePageWrapper(props: Props) {
  const { integrationID } = useParams<RouteParams>();
  return (
    <SubLeftNavigationContainer
      subtabs={[
        {
          header: true,
          label: "Filter By",
        },
        ...BLUEPRINT_OPERATION_TYPE_LIST.map((operation_type: BlueprintOperationType) => ({
          divider: operation_type === BlueprintOperationType.VALIDATE,
          label: getOperationNameFromOperationType(operation_type),
          destination: getBlueprintsOperationTypePath(integrationID, operation_type),
        })),
        {
          label: firstLetterUpperCase(BlueprintTriggerType.WEBHOOK),
          destination: getBlueprintsOperationTypePath(
            integrationID,
            BlueprintTriggerType.WEBHOOK.toLowerCase()
          ),
        },
        {
          label: getTriggerTypeNameFromTriggerType(BlueprintTriggerType.REPORT_RECEIVED),
          destination: getBlueprintsOperationTypePath(integrationID, "report-received"),
        },
        {
          divider: true,
          label: "Published",
          destination: getBlueprintsOperationTypePath(integrationID, "published"),
        },
        {
          label: "Staged",
          destination: getBlueprintsOperationTypePath(integrationID, "staged"),
        },
        {
          label: "Draft",
          destination: getBlueprintsOperationTypePath(integrationID, "draft"),
        },
        {
          label: "Unpublished",
          destination: getBlueprintsOperationTypePath(integrationID, "unpublished"),
        },
        {
          divider: true,
          label: "Archived",
          destination: getBlueprintsOperationTypePath(integrationID, "archived"),
        },
        {
          divider: true,
          label: "All",
          destination: getBlueprintsOperationTypePath(integrationID, "all"),
        },
      ]}
    >
      {props.children}
    </SubLeftNavigationContainer>
  );
}

export default BlueprintsTablePageWrapper;
