import useScraperContext from "./context/useScraperContext";
import { ScraperIfElseStep, ScraperValueType } from "./types";
import ScraperTypeaheadFormField from "./ScraperTypeaheadFormField";
import DropdownFormField from "../blueprint-editor/right-panel/DropdownFormField";
import { COMPARATOR_CHOICES } from "../blueprint-editor/right-panel/BlueprintEditorRightPanelIfElseForm";

const ScraperIfElseFields = () => {
  const { selectedStep, updateStepParameterValue } = useScraperContext();
  const step = selectedStep as ScraperIfElseStep;
  const { value1, value2 } = step.parameter_values;
  const currentComparatorValue =
    step.parameter_values?.["comparator"]?.value_type === ScraperValueType.CONSTANT
      ? step.parameter_values?.["comparator"]?.constant
      : null;
  return (
    <>
      <ScraperTypeaheadFormField
        key={"value1"}
        valueKey={"value1"}
        updateValue={(newValue) => updateStepParameterValue(step, "value1", newValue)}
        value={value1}
      />
      <DropdownFormField
        choices={COMPARATOR_CHOICES}
        currentValue={currentComparatorValue}
        placeholder={"Select a comparator"}
        title={"Comparator"}
        subtitle={"The comparator function."}
        onChange={(e) => {
          updateStepParameterValue(step, "comparator", {
            constant: e.target.value,
            value_type: ScraperValueType.CONSTANT,
          });
        }}
      />
      <ScraperTypeaheadFormField
        key={"value2"}
        valueKey={"value2"}
        updateValue={(newValue) => updateStepParameterValue(step, "value2", newValue)}
        value={value2}
      />
    </>
  );
};

export default ScraperIfElseFields;
