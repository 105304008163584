import classNames from "classnames";
import { Plus } from "lucide-react";
import useBlueprintContext from "../context/useBlueprintContext";
import { BlueprintGhostStepType } from "../../../models/Blueprints";

interface Props {
  ghostStep: BlueprintGhostStepType;
}

const BlueprintGhostStepCard = ({ ghostStep }: Props) => {
  const { selectedStep, setSelectedStep } = useBlueprintContext();
  const cardClass = classNames(
    "card",
    "m-0",
    "step-card",
    "ghost-step",
    selectedStep?.id === ghostStep.id ? "step-card-highlighted" : "step-card-not-highlighted"
  );

  return (
    <>
      <button
        className={cardClass}
        onClick={() => {
          setSelectedStep(ghostStep);
        }}
      >
        <Plus size={"36"} strokeWidth={2} />
      </button>
    </>
  );
};

export default BlueprintGhostStepCard;
