import { isEmpty } from "lodash-es";
import {
  MappingTestBodyParameters,
  MappingTestBodyParameterSchema,
} from "../../../models/MappingTests";
import MappingTestEditorCommonModelExpectedMappingSectionHeader from "../mapping-test-block-assertions/MappingTestEditorCommonModelExpectedMappingSectionHeader";
import BodyParameterField from "./BodyParameterField";

type Props = {
  availableRelationLookupDict: { [commonModelID: string]: Array<string> };
  bodyParameters: MappingTestBodyParameters;
  setBodyParameters: (bodyParameters: MappingTestBodyParameters) => void;
  bodyParameterSchema: MappingTestBodyParameterSchema | null;
  nestedWritesMapForMappingTestModelArray: string[];
  nestedWritesMap?: Record<string, string[]>;
  commonModelName?: string;
};

const MappingTestBodyParametersSection = ({
  bodyParameterSchema,
  availableRelationLookupDict,
  bodyParameters,
  setBodyParameters,
  nestedWritesMapForMappingTestModelArray,
  commonModelName,
}: Props) => {
  const bodyParameterProperties = bodyParameterSchema?.properties;

  if (!bodyParameterProperties || !bodyParameters) {
    return null;
  }

  const { model, ...otherBodyParameters } = bodyParameterProperties;

  const modelProperties = model?.properties ?? {};
  const requiredModelProperties = model?.required ?? [];

  const updateBodyParameter = ({ fieldKey, newValue }: { fieldKey: string; newValue: unknown }) =>
    setBodyParameters({ ...bodyParameters, [fieldKey]: newValue });

  const getNewBodyParameters = ({
    fieldKeyPath,
    newFieldValue,
    currentModel,
  }: {
    fieldKeyPath: Array<string>;
    newFieldValue: any;
    currentModel: Record<string, any>;
  }): any => {
    // This function takes in a path of keys for the mapping test's input body parameters,
    // and a value to set at that path, and then calls our updateBodyParameter reducer
    // action to efficiently update it

    // Base case: fieldKeyPath is length 1
    // --- Update the field key to be the new value and return

    // Recursive case: fieldKeyPath has length > 1
    // --- We index into the object by the first key and call the function recursively with
    // --- the rest of the fieldKeyPath

    const currentKey = fieldKeyPath[0];
    const isArray = Array.isArray(currentModel);

    // Splitting up array and object recursive logic to make it more readable
    // It's identical logic, except arrays use the "fieldKey" as an array index
    // instead of as a dictionary key

    // Array case
    if (isArray) {
      const newModelValue =
        fieldKeyPath.length == 1
          ? newFieldValue
          : getNewBodyParameters({
              fieldKeyPath: fieldKeyPath.slice(1),
              newFieldValue,
              //@ts-ignore
              currentModel: currentModel.at(currentKey),
            });

      // Return all values in the array unchanged, except for the value at the target index
      return currentModel.map((element, index) =>
        //@ts-ignore
        index == currentKey ? newModelValue : element
      );
    }

    // Object case
    const newModelValue =
      fieldKeyPath.length == 1
        ? newFieldValue
        : getNewBodyParameters({
            fieldKeyPath: fieldKeyPath.slice(1),
            newFieldValue,
            currentModel: Object.assign(currentModel?.[currentKey] ?? {}),
          });

    return {
      ...(currentModel ?? {}),
      [currentKey]: newModelValue,
    };
  };

  const updateModelParameterRecursively = ({
    fieldKeyPath,
    newFieldValue,
  }: {
    fieldKeyPath: Array<string>;
    newFieldValue: any;
  }) => {
    const currentModel = bodyParameters?.model ?? {};

    const newValue = getNewBodyParameters({ fieldKeyPath, newFieldValue, currentModel });

    updateBodyParameter({
      fieldKey: "model",
      //@ts-ignore
      newValue,
    });
  };

  const updateOtherBodyParameter = ({
    fieldKeyPath,
    newFieldValue,
  }: {
    fieldKeyPath: string[];
    newFieldValue: any;
  }) => {
    const newBodyParameters = getNewBodyParameters({
      fieldKeyPath,
      newFieldValue,
      currentModel: bodyParameters,
    });
    setBodyParameters(newBodyParameters);
  };

  return (
    <div>
      <MappingTestEditorCommonModelExpectedMappingSectionHeader
        commonModelName={"Model Parameters"}
      />
      {Object.entries(modelProperties).map(([fieldKey, fieldSchema], index) => (
        <BodyParameterField
          nestedWritesMapForMappingTestModelArray={nestedWritesMapForMappingTestModelArray}
          commonModelName={commonModelName}
          bodyParameters={bodyParameters}
          availableRelationLookupDict={availableRelationLookupDict}
          key={fieldKey}
          fieldKey={fieldKey}
          fieldSchema={fieldSchema}
          index={index}
          root={"model"}
          fieldKeyPath={[fieldKey]}
          onUpdate={updateModelParameterRecursively}
          isRequired={requiredModelProperties && requiredModelProperties.includes(fieldKey)}
        />
      ))}
      {!isEmpty(otherBodyParameters) && (
        <>
          <MappingTestEditorCommonModelExpectedMappingSectionHeader
            commonModelName={"Other Body Parameters"}
          />
          {Object.entries(otherBodyParameters).map(([fieldKey, fieldSchema], index) => (
            <BodyParameterField
              nestedWritesMapForMappingTestModelArray={nestedWritesMapForMappingTestModelArray}
              commonModelName={commonModelName}
              availableRelationLookupDict={availableRelationLookupDict}
              bodyParameters={bodyParameters}
              key={fieldKey}
              fieldKey={fieldKey}
              fieldSchema={fieldSchema}
              index={index}
              fieldKeyPath={[fieldKey]}
              root={""}
              isRequired={true}
              onUpdate={updateOtherBodyParameter}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default MappingTestBodyParametersSection;
