import isEqual from "lodash/isEqual";
import { memo, useState } from "react";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { Plus } from "lucide-react";
import styled from "styled-components";
import { BlueprintMeta } from "../../../models/Entities";
import {
  MappingTestBlock,
  MappingTestBlockExecution,
  MappingTestExistingCommonModels,
  MappingTestReadTestInformation,
  MappingTestRequestMock,
} from "../../../models/MappingTests";
import { MappingTestAssertionsProps } from "../root/MappingTestV2Editor";
import MappingTestBlockContainer from "../mapping-test-block/MappingTestBlockContainer";
type Props = {
  readTestInformation: undefined | MappingTestReadTestInformation;
  existingCommonModels: MappingTestExistingCommonModels | undefined;
  addMappingTestBlock: (mappingTestID: string) => void;
  pasteMappingTestBlock: (mappingTestID: string, mappingTestBlock: MappingTestBlock) => void;
  mappingTestBlocks: Array<MappingTestBlock>;
  blueprintsMap: { [id: string]: BlueprintMeta };
  draftBlueprintsMap: { [id: string]: BlueprintMeta };
  requestsMap: { [id: string]: MappingTestRequestMock };
  mappingTestID: string;
  isRunningMappingTest: boolean;
  removeMappingTestBlock: (mappingTestID: string) => void;
  addBlueprintToMappingTestBlock: (mappingTestBlockID: string, blueprintID: string) => void;
  removeBlueprintFromMappingTestBlock: (mappingTestBlockID: string, blueprintID: string) => void;
  addRequestMockToMappingTestBlock: (
    mappingTestBlockID: string,
    blueprintID: string,
    requestMock: MappingTestRequestMock
  ) => void;
  removeRequestMockFromMappingTestBlock: (
    mappingTestBlockID: string,
    blueprintID: string,
    requestMock: MappingTestRequestMock
  ) => void;
  setOverrideLastRunAtValue: (
    mappingTestBlockID: string,
    blueprintID: string,
    overrideLastRunAtValue?: string | null
  ) => void;
  setDisableFilterByDateValue: (
    mappingTestBlockID: string,
    blueprintID: string,
    disableFilterByDateValue?: boolean
  ) => void;
  updateRelationName: (
    commonModelID: string,
    mappingTestBlockID: string,
    oldName: string,
    newName: string
  ) => void;
  setIsShowingRightPanel: React.Dispatch<React.SetStateAction<boolean>>;
  selectedBlockExecution: MappingTestBlockExecution | undefined;
  setSelectedBlockExecution: React.Dispatch<
    React.SetStateAction<MappingTestBlockExecution | undefined>
  >;
  setSelectedBlockName: React.Dispatch<React.SetStateAction<string | undefined>>;
  isExecutionFinished: boolean;
} & MappingTestAssertionsProps;

const BlockContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
  overflow-y: scroll;
`;

const MappingTestV2BlocksContainer = ({
  readTestInformation,
  existingCommonModels,
  mappingTestID,
  mappingTestBlocks,
  addMappingTestBlock,
  pasteMappingTestBlock,
  blueprintsMap,
  draftBlueprintsMap,
  requestsMap,
  addBlueprintToMappingTestBlock,
  removeMappingTestBlock,
  removeBlueprintFromMappingTestBlock,
  addRequestMockToMappingTestBlock,
  removeRequestMockFromMappingTestBlock,
  setOverrideLastRunAtValue,
  setDisableFilterByDateValue,
  setIsShowingRightPanel,
  selectedBlockExecution,
  setSelectedBlockExecution,
  setSelectedBlockName,
  isExecutionFinished,
  ...props
}: Props) => {
  const [copiedBlock, setCopiedBlock] = useState<MappingTestBlock>();
  return (
    <BlockContainer>
      {mappingTestBlocks.map((block, index) => (
        <MappingTestBlockContainer
          index={index}
          existingCommonModels={existingCommonModels}
          readTestInformation={readTestInformation}
          mappingTestBlocks={mappingTestBlocks}
          isExecutionFinished={isExecutionFinished}
          mappingTestBlock={block}
          mappingTestID={mappingTestID}
          requestsMap={requestsMap}
          blueprintsMap={blueprintsMap}
          draftBlueprintsMap={draftBlueprintsMap}
          addBlueprintToMappingTestBlock={addBlueprintToMappingTestBlock}
          removeMappingTestBlock={removeMappingTestBlock}
          removeBlueprintFromMappingTestBlock={removeBlueprintFromMappingTestBlock}
          addRequestMockToMappingTestBlock={addRequestMockToMappingTestBlock}
          removeRequestMockFromMappingTestBlock={removeRequestMockFromMappingTestBlock}
          testBlockExecution={props?.testExecutionResults?.block_executions?.[index]}
          setOverrideLastRunAtValue={setOverrideLastRunAtValue}
          setDisableFilterByDateValue={setDisableFilterByDateValue}
          setCopiedBlock={setCopiedBlock}
          setIsShowingRightPanel={setIsShowingRightPanel}
          selectedBlockExecution={selectedBlockExecution}
          setSelectedBlockExecution={setSelectedBlockExecution}
          setSelectedBlockName={setSelectedBlockName}
          {...props}
        />
      ))}
      <Button
        fullWidth
        variant={ButtonVariant.TertiaryWhite}
        leftIcon={<Plus size={16} />}
        onClick={() => addMappingTestBlock(mappingTestID)}
      >
        New Mapping Test Block
      </Button>
      <Button
        className="mt-4"
        fullWidth
        variant={ButtonVariant.TertiaryWhite}
        onClick={() => pasteMappingTestBlock(mappingTestID, copiedBlock as MappingTestBlock)}
        disabled={!copiedBlock}
        leftIcon={<Plus size={16} />}
      >
        Paste Mapping Test Block
      </Button>
    </BlockContainer>
  );
};

export default memo(MappingTestV2BlocksContainer, isEqual);
