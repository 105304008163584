import { BaseColor } from "@merge-api/merge-javascript-shared/dist/designSystem/types";
import {
  getBlueprintEditorPath,
  getIntegrationBuilderExistingLinkingFlowStepPath,
  getIntegrationBuilderLinkingFlowStepsPathForId,
  getIntegrationBuilderPathForId,
  getIntegrationBuilderSingleAPIEndpointPathForId,
  getIntegrationBuilderSingleAuthConfigPathForId,
  getIntegrationBuilderSinglePaginationConfigurationPathForId,
  getIntegrationBuilderSingleRateLimitConfigurationPathForId,
  getIntegrationBuilderSingleWebAddressSchemaPathForId,
} from "../../../../router/RouterUtils";
import { DiffModelTypeEnum } from "../../../../models/DiffModels";

export type ChangelogEvent = {
  id: string;
  created_at: Date;
  user_name?: string;
  user_email?: string;
  event_description?: string;
  event_type: ChangelogEventType;
  model_type: ChangelogModelType;
  original_fields?: Record<any, any>;
  new_fields?: Record<any, any>;
  updated_fields?: string[];
  asana_ticket_url?: string;
  publish_description?: string;
  asana_ticket_url_override?: string;
  publish_description_override?: string;
  github_deploy_pr_url?: string;
  blueprint_name?: string;
  model_id?: string;
};

export enum ChangelogEventType {
  CREATED = "CREATED",
  EDITED = "EDITED",
  DELETED = "DELETED",
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED",
  BACKEND_DEPLOY = "BACKEND_DEPLOY",
}

export enum ChangelogModelType {
  INTEGRATION = "INTEGRATION",
  WEB_ADDRESS_SCHEMA = "WEB_ADDRESS_SCHEMA",
  AUTHENTICATION_CONFIGURATION = "AUTHENTICATION_CONFIGURATION",
  MERGE_LINK_STEPS = "MERGE_LINK_STEPS",
  LINK_CHOICE_STEP_OPTION = "LINK_CHOICE_STEP_OPTION",
  PAGINATION_CONFIGURATION = "PAGINATION_CONFIGURATION",
  RATE_LIMIT_CONFIGURATION = "RATE_LIMIT_CONFIGURATION",
  API_ENDPOINT = "API_ENDPOINT",
  BLUEPRINT_VERSION = "BLUEPRINT_VERSION",
}

export const ChangelogModelTypeMap: Record<ChangelogModelType, string> = {
  [ChangelogModelType.INTEGRATION]: "Integration",
  [ChangelogModelType.WEB_ADDRESS_SCHEMA]: "Web address schema",
  [ChangelogModelType.AUTHENTICATION_CONFIGURATION]: "Authentication configuration",
  [ChangelogModelType.MERGE_LINK_STEPS]: "Merge Link steps",
  [ChangelogModelType.LINK_CHOICE_STEP_OPTION]: "Link choice step option",
  [ChangelogModelType.PAGINATION_CONFIGURATION]: "Pagination configuration",
  [ChangelogModelType.RATE_LIMIT_CONFIGURATION]: "Rate limit configuration",
  [ChangelogModelType.API_ENDPOINT]: "API endpoint",
  [ChangelogModelType.BLUEPRINT_VERSION]: "Blueprint version",
};

export const ChangelogModelTypeRouteMap: Partial<Record<
  ChangelogModelType,
  (...args: any[]) => string
>> = {
  [ChangelogModelType.INTEGRATION]: getIntegrationBuilderPathForId,
  [ChangelogModelType.WEB_ADDRESS_SCHEMA]: getIntegrationBuilderSingleWebAddressSchemaPathForId,
  [ChangelogModelType.AUTHENTICATION_CONFIGURATION]: getIntegrationBuilderSingleAuthConfigPathForId,
  [ChangelogModelType.MERGE_LINK_STEPS]: getIntegrationBuilderExistingLinkingFlowStepPath,
  [ChangelogModelType.PAGINATION_CONFIGURATION]: getIntegrationBuilderSinglePaginationConfigurationPathForId,
  [ChangelogModelType.RATE_LIMIT_CONFIGURATION]: getIntegrationBuilderSingleRateLimitConfigurationPathForId,
  [ChangelogModelType.API_ENDPOINT]: getIntegrationBuilderSingleAPIEndpointPathForId,
  [ChangelogModelType.LINK_CHOICE_STEP_OPTION]: getIntegrationBuilderLinkingFlowStepsPathForId, // since link choice step options are across all link steps, we will just link to the table
  [ChangelogModelType.BLUEPRINT_VERSION]: getBlueprintEditorPath,
};

export const ChangelogEventTypeColorMap: Record<ChangelogEventType, BaseColor> = {
  [ChangelogEventType.CREATED]: "teal",
  [ChangelogEventType.EDITED]: "yellow",
  [ChangelogEventType.DELETED]: "red",
  [ChangelogEventType.PUBLISHED]: "blue",
  [ChangelogEventType.UNPUBLISHED]: "blue",
  [ChangelogEventType.BACKEND_DEPLOY]: "gray",
};

export const ChangelogEventTypeTextMap: Record<ChangelogEventType, string> = {
  [ChangelogEventType.CREATED]: "Created",
  [ChangelogEventType.EDITED]: "Edited",
  [ChangelogEventType.DELETED]: "Deleted",
  [ChangelogEventType.PUBLISHED]: "Published",
  [ChangelogEventType.UNPUBLISHED]: "Unpublished",
  [ChangelogEventType.BACKEND_DEPLOY]: "Deploy",
};

// maps model to diff modal type
export const ModelTypeMappingToDiffModal: Partial<Record<ChangelogModelType, DiffModelTypeEnum>> = {
  [ChangelogModelType.INTEGRATION]: DiffModelTypeEnum.DEFAULT, // we use default because it's generic and encompassing of all fields potentially changed
  [ChangelogModelType.WEB_ADDRESS_SCHEMA]: DiffModelTypeEnum.WEB_ADDRESS_SCHEMA,
  [ChangelogModelType.AUTHENTICATION_CONFIGURATION]: DiffModelTypeEnum.AUTHENTICATION_CONFIGURATION,
  [ChangelogModelType.MERGE_LINK_STEPS]: DiffModelTypeEnum.MERGE_LINK_STEPS_CHANGELOG,
  [ChangelogModelType.LINK_CHOICE_STEP_OPTION]: DiffModelTypeEnum.LINK_CHOICE_STEP_OPTION_CHANGELOG,
  [ChangelogModelType.PAGINATION_CONFIGURATION]: DiffModelTypeEnum.PAGINATION_CONFIGURATION,
  [ChangelogModelType.RATE_LIMIT_CONFIGURATION]: DiffModelTypeEnum.RATE_LIMIT_CONFIGURATION,
  [ChangelogModelType.API_ENDPOINT]: DiffModelTypeEnum.API_ENDPOINT_CHANGELOG,
  [ChangelogModelType.BLUEPRINT_VERSION]: DiffModelTypeEnum.BLUEPRINT,
};
