import { useState, useEffect } from "react";
import { fetchWithAuth } from "../../../api-client/api_client";
import { IntegrationChecklistData } from "../../../constants";
import { Integration } from "../../../models/Entities";
import { isCategoryStatusActiveOrBeta } from "../dashboard/components/IntegrationsDashboardTableRow/utils";

const useIntegrationData = (integrationID: string) => {
  const [integrationName, setIntegrationName] = useState<string | undefined>();
  const [integrationChecklist, setIntegrationChecklist] = useState<
    IntegrationChecklistData | undefined
  >();
  const [categories, setCategories] = useState<string[]>();
  const [doesIntegrationHaveInactiveStatus, setDoesIntegrationHaveInactiveStatus] = useState<
    boolean
  >(false);

  useEffect(() => {
    fetchWithAuth({
      path: `/integrations/${integrationID}`,
      method: "GET",
      onResponse: (data: Integration) => {
        setIntegrationName(data.name);
        setIntegrationChecklist(data?.integration_checklist);
        setCategories(data.categories);
        if (data.categories_status_info && data.categories_status_info.length > 0) {
          const isActiveOrInBeta = data.categories_status_info.some(isCategoryStatusActiveOrBeta);
          setDoesIntegrationHaveInactiveStatus(!isActiveOrInBeta);
        } else {
          setDoesIntegrationHaveInactiveStatus(true);
        }
      },
    });
  }, [integrationID]);

  return { integrationName, integrationChecklist, categories, doesIntegrationHaveInactiveStatus };
};

export default useIntegrationData;
