import {
  RateLimitConfigurationIntegrationBuilder,
  RateLimitResetTimestampFormat,
  RateLimitType,
} from "../../utils/Entities";
import {
  RATE_LIMIT_TYPE_SELECT_OPTIONS,
  rateLimitTimeValueTabletDisplayStrings,
  rateLimitTypeRequestDisplayStrings,
} from "./constants";

// number validation for TextField inputs used in rate limit configuration sections
export const isValidNumber = (value: number, maxLimit: number) => {
  return !isNaN(value) && value >= 0 && value <= maxLimit;
};

// utility function to round the number returned for rate limit thresholds and aggressive rate limit thresholds
export function roundPercentValue(value: number, maxDecimalPlaces: number = 2): number {
  const multipliedValue = value * 100;
  const valueString = multipliedValue.toString();
  const decimalPointIndex = valueString.indexOf(".");

  // If there's no decimal point, return the value as is
  if (decimalPointIndex === -1) {
    return multipliedValue;
  }

  const decimalPlaces = valueString.length - decimalPointIndex - 1;

  // If the number of decimal places is within the allowed range, return the multiplied value, otherwise round to the nearest whole number
  if (decimalPlaces <= maxDecimalPlaces) {
    return multipliedValue;
  }
  return Math.round(multipliedValue);
}

// validation logic for rate limit configuration
export function validateRateLimitConfigurationInput(
  input: string[] | string | number | undefined | null
): boolean {
  if (typeof input === "string") {
    return input.trim().length > 0;
  }

  if (typeof input === "number") {
    return input >= 0;
  }

  if (Array.isArray(input)) {
    return input.length > 0;
  }

  // If input is undefined or null, return false
  return false;
}

// validation logic for rate limit configuration headers section

export function validateHeadersSection(
  shouldSetLimitMaxCountViaHeaders: boolean,
  shouldUpdateRateLimitCurrentCountViaHeaders: boolean,
  shouldUpdateRateLimitCountLeftViaHeaders: boolean,
  shouldUpdateResetTimestampKeyPathViaHeaders: boolean,
  responseHeaderMaxCountKeyPath?: string[] | null,
  responseHeaderRateLimitCurrentCountKeyPath?: string[] | null,
  responseHeaderRateLimitCountLeftKeyPath?: string[] | null,
  responseHeaderResetTimestampKeyPath?: string[] | null,
  resetExpirationFormat?: RateLimitResetTimestampFormat
): boolean {
  // If all booleans are false, the inputs are valid by default
  if (
    shouldSetLimitMaxCountViaHeaders === false &&
    shouldUpdateRateLimitCurrentCountViaHeaders === false &&
    shouldUpdateRateLimitCountLeftViaHeaders === false &&
    shouldUpdateResetTimestampKeyPathViaHeaders === false
  ) {
    return true;
  }

  // Validate shouldSetLimitMaxCountViaHeaders and its associated path
  if (
    shouldSetLimitMaxCountViaHeaders &&
    !validateRateLimitConfigurationInput(responseHeaderMaxCountKeyPath)
  ) {
    return false;
  }

  // Validate shouldUpdateRateLimitCurrentCountViaHeaders and its associated path
  if (
    shouldUpdateRateLimitCurrentCountViaHeaders &&
    !validateRateLimitConfigurationInput(responseHeaderRateLimitCurrentCountKeyPath)
  ) {
    return false;
  }

  // Validate shouldUpdateRateLimitCountLeftViaHeaders and its associated path
  if (
    shouldUpdateRateLimitCountLeftViaHeaders &&
    !validateRateLimitConfigurationInput(responseHeaderRateLimitCountLeftKeyPath)
  ) {
    return false;
  }

  // Validate shouldUpdateResetTimestampKeyPathViaHeaders and its associated path
  if (
    shouldUpdateResetTimestampKeyPathViaHeaders &&
    (!validateRateLimitConfigurationInput(responseHeaderResetTimestampKeyPath) ||
      !validateRateLimitConfigurationInput(resetExpirationFormat))
  ) {
    return false;
  }

  return true;
}

export const constructRateLimitDisplayTitle = (
  rateLimitConfiguration: RateLimitConfigurationIntegrationBuilder | null
): string => {
  if (
    rateLimitConfiguration &&
    (rateLimitConfiguration.rate_limit_type === RateLimitType.CONNECTION_COUNT ||
      rateLimitConfiguration.rate_limit_time_value) &&
    (rateLimitConfiguration.default_rate_limit_max_count ||
      rateLimitConfiguration.default_rate_limit_max_points)
  ) {
    const requestDisplayString =
      rateLimitTypeRequestDisplayStrings[rateLimitConfiguration.rate_limit_type];
    const timeDisplayString =
      rateLimitTimeValueTabletDisplayStrings[rateLimitConfiguration.rate_limit_time_period];
    const countDisplay =
      rateLimitConfiguration.rate_limit_type !== RateLimitType.COMPLEXITY_COUNT
        ? rateLimitConfiguration.default_rate_limit_max_count
        : rateLimitConfiguration.default_rate_limit_max_points;
    const rateLimitTitle =
      rateLimitConfiguration.rate_limit_type === RateLimitType.CONNECTION_COUNT
        ? countDisplay?.toString() + " " + requestDisplayString
        : countDisplay?.toString() +
          " " +
          requestDisplayString +
          " / " +
          rateLimitConfiguration.rate_limit_time_value +
          " " +
          timeDisplayString;
    return rateLimitTitle;
  }
  return "";
};

export const constructRateLimitDisplayType = (
  rateLimitConfiguration: RateLimitConfigurationIntegrationBuilder | null
): RateLimitType | string => {
  if (rateLimitConfiguration) {
    return (
      RATE_LIMIT_TYPE_SELECT_OPTIONS.find(
        (option) => option.value === rateLimitConfiguration.rate_limit_type
      )?.title || ""
    );
  } else {
    return "";
  }
};

export const constructRateLimitDisplayPercent = (
  rateLimitConfiguration: RateLimitConfigurationIntegrationBuilder | null
): string => {
  if (rateLimitConfiguration) {
    return rateLimitConfiguration.default_rate_limit_threshold
      ? (rateLimitConfiguration.default_rate_limit_threshold * 100).toString() + "%"
      : "";
  } else {
    return "";
  }
};

export function transformRateLimitConfigurationDataForDiffModal(
  data: RateLimitConfigurationIntegrationBuilder
): RateLimitConfigurationIntegrationBuilder & {
  rate_limit_summary: string;
  rate_limit_type: string;
  constructed_default_rate_limit_threshold: string;
} {
  return {
    ...data,
    rate_limit_summary: constructRateLimitDisplayTitle(data),
    rate_limit_type: constructRateLimitDisplayType(data) as RateLimitType,
    constructed_default_rate_limit_threshold: constructRateLimitDisplayPercent(data),
  };
}
