import AccordionCard from "../../shared/AccordionCard";
import TextFieldHeader from "../../shared/TextFieldHeader";
import { IntegrationForIntegrationBuilder } from "../../utils/Entities";

interface Props {
  requestBodyForIntegration: IntegrationForIntegrationBuilder | undefined;
  setRequestBodyForIntegration: React.Dispatch<
    React.SetStateAction<IntegrationForIntegrationBuilder | undefined>
  >;
}

const DocsConfigurationSection = ({
  requestBodyForIntegration,
  setRequestBodyForIntegration,
}: Props) => {
  return (
    <AccordionCard title="Docs" badgeContents={1}>
      {requestBodyForIntegration && (
        <div className="space-y-6">
          <TextFieldHeader
            title="Webhook setup guide URL"
            subtitle="Setup guide URL for third party webhook creation."
            value={requestBodyForIntegration.webhook_setup_guide_url || ""}
            onChange={(event) =>
              setRequestBodyForIntegration((body) => {
                return {
                  ...body,
                  webhook_setup_guide_url:
                    event.target.value.length > 0 ? event.target.value : null,
                };
              })
            }
            placeholder="Example: https://help.merge.dev/en/articles/8400669-setting-up-receiving-webhooks-in-jazzhr"
          />
        </div>
      )}
    </AccordionCard>
  );
};

export default DocsConfigurationSection;
