import { SetVariableValueType } from "../../models/Blueprints";
import { VARIABLE_TYPES } from "../blueprint-editor/right-panel/BlueprintEditorRightPanelSetVariableForm";
import DropdownFormField from "../blueprint-editor/right-panel/DropdownFormField";
import useScraperContext from "./context/useScraperContext";
import ScraperInputField from "./ScraperInputField";
import ScraperTypeaheadFormField from "./ScraperTypeaheadFormField";
import { ScraperSetVariableStep } from "./types";

const ScraperSetVariableFields = () => {
  const { selectedStep, updateStepParameterValue } = useScraperContext();
  const step = selectedStep as ScraperSetVariableStep;
  const { key, value_type, value } = step.parameter_values;
  const currentValueType = value_type.constant;

  return (
    <div>
      <ScraperInputField
        valueKey={"key"}
        updateValue={(newValue) => updateStepParameterValue(step, "key", newValue)}
        value={key}
      />
      <DropdownFormField
        choices={VARIABLE_TYPES}
        currentValue={currentValueType}
        placeholder={"Select a value type"}
        title={"Value Type"}
        subtitle={
          "The type of value to set to. If you don't need to set a default value, select 'Other Value'."
        }
        onChange={(e) =>
          updateStepParameterValue(step, "value_type", {
            ...value_type,
            constant: e.target.value,
          })
        }
      />
      {currentValueType === SetVariableValueType.OTHER_VALUE && (
        <ScraperTypeaheadFormField
          valueKey={"value"}
          updateValue={(newValue) => updateStepParameterValue(step, "value", newValue)}
          value={value}
        />
      )}
    </div>
  );
};

export default ScraperSetVariableFields;
