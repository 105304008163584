import { getIntegrationBuilderLinkingFlowStepsPathForId } from "../../../../../router/RouterUtils";
import IntegrationBuilderHeader from "../../../shared/IntegrationBuilderHeader";
import { LinkingFlowStepPathIntegrationBuilder } from "../../../utils/Entities";

interface LinkingFlowStepPathSetupHeaderProps {
  selectedLinkingFlowStepPath: LinkingFlowStepPathIntegrationBuilder | undefined;
  integrationID: string;
  authConfigName: string | undefined;
}

const LinkingFlowStepPathSetupHeader = ({
  selectedLinkingFlowStepPath,
  integrationID,
  authConfigName,
}: LinkingFlowStepPathSetupHeaderProps) => {
  return (
    <div>
      <IntegrationBuilderHeader
        title={!selectedLinkingFlowStepPath ? "Add Merge Link steps" : "Edit Merge Link steps"}
        integrationId={integrationID}
        authConfigName={authConfigName}
        authConfigId={selectedLinkingFlowStepPath?.auth_configuration_id}
        navButtonLink={getIntegrationBuilderLinkingFlowStepsPathForId(integrationID)}
        navButtonText="Merge Link steps"
        showRequired
      />
    </div>
  );
};

export default LinkingFlowStepPathSetupHeader;
