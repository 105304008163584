import classNames from "classnames";
import MergeModal from "../../shared/MergeModal";
import { useState } from "react";
import { Accordion, Form, Table } from "react-bootstrap";
import { CheckSquare, XSquare } from "lucide-react";
import { PlusMinusToggle } from "../../shared/MergeToggles";
import { BlueprintRunnerAutogeneratedMappingTestInformation } from "../../../models/Blueprints";
import { navigateToMappingTestEditor } from "../../../router/RouterUtils";
import { useHistory } from "react-router-dom";
import useBlueprintContext from "../../blueprint-editor/context/useBlueprintContext";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { MappingTestExecutionResult } from "../../../models/MappingTests";

type ModelFieldInfo = {
  [commonModelID: string]:
    | Array<{ [fieldName: string]: any }>
    | { [uniqueID: string]: { [fieldName: string]: any } };
};

interface Props {
  show: boolean;
  onHide: () => void;
  title: string;
  producedModelsTabs: {
    [tabName: string]: ModelFieldInfo;
  };
  mappingTestInfo?: undefined | BlueprintRunnerAutogeneratedMappingTestInformation;
  modelUpdates?: ModelFieldInfo;
}

function IntegrationEditorProducedModelsModal({
  show,
  onHide,
  title,
  producedModelsTabs,
  mappingTestInfo,
  modelUpdates,
}: Props) {
  const { blueprint } = useBlueprintContext();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [showRemoteDataInModal, setShowRemoteDataInModal] = useState<boolean>(false);
  const [showDeletedDataInModal, setShowDeletedDataInModal] = useState<boolean>(false);
  const history = useHistory();

  const ignoreColumns = [
    "linked_account_id",
    "deprecated_linked_account",
    "ignore_id",
    "custom_fields",
  ];

  function filterColumns(key: string) {
    if (ignoreColumns.includes(key)) return false;
    else if (key === "remote_data" && !showRemoteDataInModal) return false;
    else if (key === "remote_was_deleted" && !showDeletedDataInModal) return false;
    return true;
  }

  const doesGeneratedMappingTestExistsWithFailedRun =
    mappingTestInfo?.result === MappingTestExecutionResult.FAILURE;

  const doesGeneratedMappingTestExists =
    mappingTestInfo?.was_successful || doesGeneratedMappingTestExistsWithFailedRun;

  const navigateToMappingTest = doesGeneratedMappingTestExists
    ? () =>
        navigateToMappingTestEditor(
          history,
          blueprint.integration.id,
          mappingTestInfo.mapping_test_id,
          true
        )
    : undefined;

  return (
    <MergeModal
      title={title}
      dialogClassName="modal-xl"
      show={show}
      onHide={onHide}
      nav={
        <ul className="nav nav-tabs nav-tabs-sm card-header-tabs">
          {Object.keys(producedModelsTabs).map((tabName, index) => (
            <li key={tabName} className="nav-item">
              <span
                className={classNames("nav-link", index === activeTab ? "active" : "")}
                onClick={() => setActiveTab(index)}
              >
                {tabName}
              </span>
            </li>
          ))}
        </ul>
      }
    >
      <Form.Group className="mb-0" controlId="show-remote-data">
        <Form.Check
          type="checkbox"
          label="Show remote data"
          defaultChecked={showRemoteDataInModal}
          onChange={() => setShowRemoteDataInModal(!showRemoteDataInModal)}
        />
      </Form.Group>
      <Form.Group controlId="show-whether-deleted-data">
        <Form.Check
          type="checkbox"
          label="Show whether deleted data"
          defaultChecked={showRemoteDataInModal}
          onChange={() => setShowDeletedDataInModal(!showDeletedDataInModal)}
        />
      </Form.Group>
      <hr />
      {Object.entries(Object.values(producedModelsTabs)[activeTab]).map(
        ([commonModelID, commonModelObjects]) => {
          return (
            <>
              <Accordion>
                <PlusMinusToggle eventKey="0">
                  {commonModelID} {`(${(Object.keys(commonModelObjects) ?? []).length})`}
                </PlusMinusToggle>
                <Accordion.Collapse eventKey="0" className="mt-3">
                  <Table responsive>
                    <Table size="sm" className="low-padding-table">
                      <thead className="table-borderless">
                        {Object.keys(Object.values(commonModelObjects ?? {})?.[0] ?? [])
                          .filter(function (key) {
                            return filterColumns(key);
                          })
                          .sort(function (a, b) {
                            const keyA = a,
                              keyB = b;
                            if (keyA === "created") return -1;
                            if (keyB === "created") return -1;
                            return 0;
                          })
                          .map((key) => (
                            <>
                              <th scope="col">{key}</th>
                            </>
                          ))}
                      </thead>
                      <tbody>
                        {Object.entries(commonModelObjects).map(
                          ([commonModelUniqueIdentifier, commonModelRow]) => {
                            return (
                              <tr>
                                {Object.entries(commonModelRow)
                                  .filter(function (entry) {
                                    return filterColumns(entry[0]);
                                  })
                                  .sort(function (a, b) {
                                    const keyA = a[0],
                                      keyB = b[0];

                                    if (keyA === "created") return -1;
                                    if (keyB === "created") return -1;
                                    return 0;
                                  })
                                  .map(([key, value]) => {
                                    const updatedFields = modelUpdates?.[commonModelID];
                                    let isUpdated = false;
                                    if (
                                      updatedFields &&
                                      !Array.isArray(updatedFields) &&
                                      updatedFields[commonModelUniqueIdentifier]
                                    ) {
                                      isUpdated = key in updatedFields[commonModelUniqueIdentifier];
                                    }
                                    // 0 value decimals in Python are written to string as scientific notation (e.g. 0E-16 or 0E-8).
                                    // This can be confusing in the model updates panel, so we catch these cases with regex here and overwrite the value to "0.0".
                                    if (/0E-?\d+/.test(value)) {
                                      value = "0.0";
                                    }
                                    return (
                                      <td className={isUpdated ? "yellow" : undefined}>
                                        {key === "created" ? (
                                          value === "true" ? (
                                            <CheckSquare size={18} className="green" />
                                          ) : (
                                            <XSquare size={18} className="text-muted" />
                                          )
                                        ) : (
                                          JSON.stringify(value)
                                        )}
                                      </td>
                                    );
                                  })}
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </Table>
                </Accordion.Collapse>
              </Accordion>
              <hr />
            </>
          );
        }
      )}
      {mappingTestInfo &&
        (navigateToMappingTest ? (
          <div className="flex flex-col">
            {doesGeneratedMappingTestExistsWithFailedRun && (
              <div className="mb-3">
                The mapping test and associated models were successfully generated, however the run
                failed. In most cases, this is due to slightly incorrect assertions or mismatched
                request mocks and can be easily fixed in order to take advantage of the
                autogenerated test. Please still post in #mapping-test-discussions with your
                payload, linked account, and blueprint so we can work to improve this.{" "}
              </div>
            )}
            <Button variant={ButtonVariant.PrimaryBlue} onClick={navigateToMappingTest}>
              View autogenerated mapping test
            </Button>
          </div>
        ) : (
          <div>
            Mapping test generation failed with exceptions. Please post in #mapping-test-discussions
            with the payload, linked account, and blueprint you were using.{" "}
          </div>
        ))}
    </MergeModal>
  );
}

export default IntegrationEditorProducedModelsModal;
