import { useContext, useEffect, useMemo, useState } from "react";
import {
  PaginationValueRequestLocation,
  PointerForNextRequestResponseLocation,
} from "../../../../models/PaginationConfigurationModels";
import AccordionCard from "../../shared/AccordionCard";
import KeyPathFieldHeader from "../../shared/KeyPathFieldHeader";
import MultiSelectHeader from "../../shared/MultiSelectHeader";
import {
  KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER,
  KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER,
  POINTER_FOR_NEXT_REQUEST_RESPONSE_LOCATION_OPTIONS,
} from "../constants";
import PaginationConfigurationContext from "../context/PaginationConfigurationContext";
import EndOfPaginationSetupOptions from "./EndOfPaginationSetupOptions";

interface ResponseInformationSetupOptionsProps {
  cardVariant?: "outline" | "shadow" | "none";
}

const ResponseInformationSetupOptions = ({ cardVariant }: ResponseInformationSetupOptionsProps) => {
  const {
    paginationValueRequestLocation,
    paginatedResultsArrayKeyPath,
    setPaginatedResultsArrayKeyPath,
    pointerForNextRequestResponseLocation,
    setPointerForNextRequestResponseLocation,
    pointerForNextRequestResponseBodyKeyPath,
    setPointerForNextRequestResponseBodyKeyPath,
  } = useContext(PaginationConfigurationContext);

  /* --- START - MULTISELECTBUTTONGROUP LOGIC FOR pointerForNextRequestResponseLocation --- */

  // MultiSelectButtonGroup Options for pointerForNextRequestResponseLocation
  const [optionsForNextLocationPointer, setOptionsForNextLocationPointer] = useState<any[]>([]);

  // Update optionsForNextLocationPointer based on changes
  useEffect(() => {
    setOptionsForNextLocationPointer(
      POINTER_FOR_NEXT_REQUEST_RESPONSE_LOCATION_OPTIONS.map(({ value, text }) => {
        return {
          value,
          text,
          selected: pointerForNextRequestResponseLocation === value,
          onClick: () => {
            if (value === pointerForNextRequestResponseLocation) {
              setPointerForNextRequestResponseLocation(null);
            } else {
              setPointerForNextRequestResponseLocation(value);
            }
          },
        };
      })
    );
  }, [pointerForNextRequestResponseLocation]);

  // Function to clear value of pointerForNextRequestResponseLocation
  const clearOptionsForNextLocationPointer = () => {
    setPointerForNextRequestResponseLocation(null);
  };

  // pointerForNextRequestResponseLocation is only required for PATH or URL
  const isNextPaginationLocationRequired = useMemo(() => {
    return (
      paginationValueRequestLocation === PaginationValueRequestLocation.PATH ||
      paginationValueRequestLocation === PaginationValueRequestLocation.URL
    );
  }, [paginationValueRequestLocation]);

  // Determines if need to show "Clear selection" button
  const isShowingClearButtonForNextPaginationLocation = useMemo(() => {
    return !isNextPaginationLocationRequired && pointerForNextRequestResponseLocation !== null;
  }, [isNextPaginationLocationRequired, pointerForNextRequestResponseLocation]);

  /* --- END - MULTISELECTBUTTONGROUP LOGIC FOR pointerForNextRequestResponseLocation --- */

  return (
    <AccordionCard title="Response information" defaultExpanded={true} variant={cardVariant}>
      <KeyPathFieldHeader
        dataTestID="field-pagination-config-paginated-results-array-key-path"
        title="Key path to array of results"
        subtitle='The key path to the paginated results array in the response body. For example, if the key path is "results" > "data", you would type "results", press Enter, and type "page".'
        placeholder={
          paginatedResultsArrayKeyPath?.length
            ? KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER
            : KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER
        }
        keyPath={paginatedResultsArrayKeyPath}
        onKeyPathChange={setPaginatedResultsArrayKeyPath}
        hasSource={false}
      />
      <MultiSelectHeader
        className="mt-6"
        dataTestID="field-pagination-config-pointer-for-next-request-response-location"
        title="Location of next pagination value in response"
        subtitle="The location of the next pagination value."
        learnMoreText='If "Header" is selected, we follow Link standards and find the next offset value within "rel=next".  For example, Greenhouse API’s paginated responses contain a "Link" header, and then a "next" value inside of it that points to the next page and looks like this: Link: <https://api.github.com/user/repos?page=3&per_page=100>; rel="next",  <https://api.github.com/user/repos?page=50&per_page=100>; rel="last" See Greenhouse documentation.'
        options={optionsForNextLocationPointer}
        required={isNextPaginationLocationRequired}
        clearOptions={
          isShowingClearButtonForNextPaginationLocation ? clearOptionsForNextLocationPointer : null
        }
      />
      {pointerForNextRequestResponseLocation ===
        PointerForNextRequestResponseLocation.RESPONSE_BODY && (
        <div className="border-l-2 pl-8 mt-6 border-gray-10">
          <KeyPathFieldHeader
            dataTestID="field-pagination-config-pointer-for-next-request-response-body-key-path"
            title="Key path to next page"
            subtitle='The key path in the response body to the pointer to include in the next request. For example, if the key path is results > page > next_page_cursor, you would type "results", press Enter, type "page", press Enter, and type "next_page_cursor".'
            placeholder={
              pointerForNextRequestResponseBodyKeyPath?.length
                ? KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER
                : KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER
            }
            keyPath={pointerForNextRequestResponseBodyKeyPath}
            onKeyPathChange={setPointerForNextRequestResponseBodyKeyPath}
            hasSource={false}
            required
          />
        </div>
      )}
      <EndOfPaginationSetupOptions />
    </AccordionCard>
  );
};

export default ResponseInformationSetupOptions;
