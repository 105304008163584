import { useCallback, useRef } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast } from "../../../../components/shared/Toasts";
import { EndpointCollectionRun, GetEndpointCollectionRunResponse } from "../../utils/Entities";

const POLL_INTERVAL_MS = 5000;

interface Props {
  integrationID: string;
  setEndpointCollectionRun: React.Dispatch<React.SetStateAction<EndpointCollectionRun | undefined>>;
  setHasLoadedEndpointCollectionRun: React.Dispatch<React.SetStateAction<boolean>>;
}

const useLoadEndpointCollectionRun = ({
  integrationID,
  setEndpointCollectionRun,
  setHasLoadedEndpointCollectionRun,
}: Props) => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const fetchEndpointCollectionRun = useCallback(() => {
    fetchWithAuth({
      path: `/integrations/integration-builder/integration/${integrationID}/latest-api-endpoint-collection-run`,
      method: "GET",
      onResponse: (data: GetEndpointCollectionRunResponse | undefined) => {
        setHasLoadedEndpointCollectionRun(true);
        setEndpointCollectionRun(data?.collection_run);
      },
      onError: () => {
        setHasLoadedEndpointCollectionRun(true);
        showErrorToast("Failed to fetch Endpoint Collection Run for integration.");
      },
    });
  }, [integrationID, setEndpointCollectionRun]);

  const pollEndpointCollectionRun = useCallback(() => {
    // If we are already polling, do nothing
    if (intervalRef.current) {
      return;
    }

    const poll = () => fetchEndpointCollectionRun();
    poll();
    // Setup to poll every 5 seconds
    intervalRef.current = setInterval(poll, POLL_INTERVAL_MS);
  }, [fetchEndpointCollectionRun]);

  const stopPollingEndpointCollectionRun = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, [intervalRef]);

  return {
    fetchEndpointCollectionRun,
    pollEndpointCollectionRun,
    stopPollingEndpointCollectionRun,
  };
};

export default useLoadEndpointCollectionRun;
