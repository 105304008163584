import { useCallback, useRef } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast } from "../../../shared/Toasts";
import { APIEndpointIntegrationBuilder } from "../../utils/Entities";

const POLL_INTERVAL_MS = 5000;

interface loadAPIEndpointsProps {
  setAPIEndpoints: React.Dispatch<
    React.SetStateAction<APIEndpointIntegrationBuilder[] | undefined>
  >;
  setIsLoadingAPIEndpoints: React.Dispatch<React.SetStateAction<boolean>>;
}

const usePollAPIEndpoints = ({
  setAPIEndpoints,
  setIsLoadingAPIEndpoints,
}: loadAPIEndpointsProps) => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const pollAPIEndpoints = useCallback(
    (integrationID: string) => {
      const poll = () => {
        fetchWithAuth({
          path: `/integrations/integration-builder/integration/${integrationID}/api-endpoints`,
          method: "GET",
          onResponse: (data: APIEndpointIntegrationBuilder[]) => {
            setAPIEndpoints(data);
            setIsLoadingAPIEndpoints(false);
          },
          onError: () => {
            showErrorToast("Failed to fetch API endpoints for integration.");
            setAPIEndpoints([]);
            setIsLoadingAPIEndpoints(false);
          },
        });
      };

      // Initial call to poll
      setIsLoadingAPIEndpoints(true);
      poll();

      // Setup to poll every 5 seconds
      intervalRef.current = setInterval(poll, POLL_INTERVAL_MS);
    },
    [setAPIEndpoints]
  );

  const stopPollingAPIEndpoints = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  return { pollAPIEndpoints, stopPollingAPIEndpoints };
};

export default usePollAPIEndpoints;
