import AccordionCard from "../../shared/AccordionCard";
import { IntegrationForIntegrationBuilder, IntegrationLogos } from "../../utils/Entities";
import ImageUploadHeader from "../../shared/ImageUploadHeader";
import { useEffect, useState } from "react";

interface Props {
  integration: IntegrationForIntegrationBuilder | undefined;
  setRequestBodyForLogos: React.Dispatch<React.SetStateAction<IntegrationLogos>>;
}

const LogosSection = ({ integration, setRequestBodyForLogos }: Props) => {
  const [requestedImageFile, setRequestedImageFile] = useState<File>();
  const [requestedSquareImageFile, setRequestedSquareImageFile] = useState<File>();
  const [requestedColorImageFile, setRequestedColorImageFile] = useState<File>();
  const [requestedWhiteImageFile, setRequestedWhiteImageFile] = useState<File>();
  const [requestedLightGreyImageFile, setRequestedLightGreyImageFile] = useState<File>();
  const [requestedDarkGreyImageFile, setRequestedDarkGreyImageFile] = useState<File>();
  const [requestedBlackImageFile, setRequestedBlackImageFile] = useState<File>();
  const [requestedSocialBannerFile, setRequestedSocialBannerFile] = useState<File>();

  // Update request body
  useEffect(() => {
    if (requestedImageFile) {
      setRequestBodyForLogos((requestBodyForLogos) => {
        return { ...requestBodyForLogos, image: requestedImageFile };
      });
    }
  }, [requestedImageFile]);

  useEffect(() => {
    if (requestedSquareImageFile) {
      setRequestBodyForLogos((requestBodyForLogos) => {
        return { ...requestBodyForLogos, square_image: requestedSquareImageFile };
      });
    }
  }, [requestedSquareImageFile]);

  useEffect(() => {
    if (requestedColorImageFile) {
      setRequestBodyForLogos((requestBodyForLogos) => {
        return { ...requestBodyForLogos, color_image: requestedColorImageFile };
      });
    }
  }, [requestedColorImageFile]);

  useEffect(() => {
    if (requestedWhiteImageFile) {
      setRequestBodyForLogos((requestBodyForLogos) => {
        return { ...requestBodyForLogos, white_image: requestedWhiteImageFile };
      });
    }
  }, [requestedWhiteImageFile]);

  useEffect(() => {
    if (requestedLightGreyImageFile) {
      setRequestBodyForLogos((requestBodyForLogos) => {
        return { ...requestBodyForLogos, light_grey_image: requestedLightGreyImageFile };
      });
    }
  }, [requestedLightGreyImageFile]);

  useEffect(() => {
    if (requestedDarkGreyImageFile) {
      setRequestBodyForLogos((requestBodyForLogos) => {
        return { ...requestBodyForLogos, dark_grey_image: requestedDarkGreyImageFile };
      });
    }
  }, [requestedDarkGreyImageFile]);

  useEffect(() => {
    if (requestedBlackImageFile) {
      setRequestBodyForLogos((requestBodyForLogos) => {
        return { ...requestBodyForLogos, black_image: requestedBlackImageFile };
      });
    }
  }, [requestedBlackImageFile]);

  useEffect(() => {
    if (requestedSocialBannerFile) {
      setRequestBodyForLogos((requestBodyForLogos) => {
        return { ...requestBodyForLogos, social_banner: requestedSocialBannerFile };
      });
    }
  }, [requestedSocialBannerFile]);

  return (
    <AccordionCard title="Logos" badgeContents={8}>
      <div className="space-y-6">
        <ImageUploadHeader
          title="Rectangular image"
          subtitle="Company logo in rectangular shape. Upload an image with a clear background."
          imageUrl={integration?.image}
          setRequestedImageFile={setRequestedImageFile}
        />
        <ImageUploadHeader
          title="Square image"
          subtitle="Company logo in square shape. Upload an image with a white background."
          imageUrl={integration?.square_image}
          setRequestedImageFile={setRequestedSquareImageFile}
        />
        <ImageUploadHeader
          title="Color image"
          subtitle="Colored logo image. Upload an SVG image with colors."
          imageUrl={integration?.color_image}
          setRequestedImageFile={setRequestedColorImageFile}
        />
        <ImageUploadHeader
          title="White image"
          subtitle="White logo image. Upload an SVG image with a white color scheme."
          imageUrl={integration?.white_image}
          setRequestedImageFile={setRequestedWhiteImageFile}
        />
        <ImageUploadHeader
          title="Light grey image"
          subtitle="Light grey logo image. Upload an SVG image with a light grey color scheme."
          imageUrl={integration?.light_grey_image}
          setRequestedImageFile={setRequestedLightGreyImageFile}
        />
        <ImageUploadHeader
          title="Dark grey image"
          subtitle="Dark grey logo image. Upload an SVG image with a dark grey color scheme."
          imageUrl={integration?.dark_grey_image}
          setRequestedImageFile={setRequestedDarkGreyImageFile}
        />
        <ImageUploadHeader
          title="Black image"
          subtitle="Black logo image. Upload an SVG image with a black color scheme."
          imageUrl={integration?.black_image}
          setRequestedImageFile={setRequestedBlackImageFile}
        />
        <ImageUploadHeader
          title="Social banner"
          subtitle="Banner for social. Generated lazily when the landing page requests integration data from the backend."
          imageUrl={integration?.social_banner}
          setRequestedImageFile={setRequestedSocialBannerFile}
        />
      </div>
    </AccordionCard>
  );
};

export default LogosSection;
