import { TextField, Text, Select } from "@merge-api/merge-javascript-shared";
import HeaderBase from "../../shared/HeaderBase";
import RateLimitConfigurationContext from "../context/RateLimitConfigurationContext";
import { useContext, useEffect, useState } from "react";
import {
  RATE_LIMIT_TIME_PERIOD_OPTIONS,
  rateLimitTypeRequestDisplayStrings,
} from "../utils/constants";
import { StringOptionsData } from "../../utils/types";
import { RateLimitTimePeriod, RateLimitType } from "../../utils/Entities";

const RateLimitValue = () => {
  const {
    rateLimitType,
    rateLimitTimePeriod,
    setRateLimitTimePeriod,
    rateLimitTimeValue,
    setRateLimitTimeValue,
    rateLimitMaxCount,
    setRateLimitMaxCount,
    rateLimitMaxPoints,
    setRateLimitMaxPoints,
  } = useContext(RateLimitConfigurationContext);

  // functions for rendering rate limit time period select options and values
  const selectedRateLimitTimePeriod = RATE_LIMIT_TIME_PERIOD_OPTIONS.find(
    (option) => option.value === rateLimitTimePeriod
  );

  const renderRateLimitTimePeriodOption = (option: StringOptionsData) => {
    return <Text variant="md">{option.text}</Text>;
  };
  const renderRateLimitTimePeriodValue = (option: StringOptionsData) => {
    return <Text variant="md">{option.text}</Text>;
  };

  // consts derived from state
  const displayString = rateLimitTypeRequestDisplayStrings[rateLimitType];
  const isEndOfMonth = rateLimitTimePeriod === RateLimitTimePeriod.END_OF_MONTH;
  const isTimeBased = rateLimitType !== RateLimitType.CONNECTION_COUNT;

  // temp state and handler for parsing floats for max points
  const [tempMaxPointsInputValue, setTempMaxPointsInputValue] = useState(rateLimitMaxPoints ?? "");
  const handleInputChange = (newEvent: React.ChangeEvent<HTMLInputElement>) => {
    const value = newEvent.target.value;
    if (value === "" || /^-?\d*\.?\d*$/.test(value)) {
      setTempMaxPointsInputValue(value);
      // convert to number when valid, otherwise set to undefined
      let numericValue: number | undefined = undefined;
      if (value) {
        const parsed = parseFloat(value);
        if (!isNaN(parsed)) {
          numericValue = parsed;
        }
      }
      setRateLimitMaxPoints(numericValue);
    }
  };

  // hooks
  // set states based on selectedRateLimitTimePeriod. if end of month is selected we want the rate limit time value to be
  useEffect(() => {
    if (rateLimitTimePeriod === RateLimitTimePeriod.END_OF_MONTH) {
      setRateLimitTimeValue(1);
    }
  }, [rateLimitTimePeriod]);

  return (
    <div className="mt-6">
      <HeaderBase
        required
        title="Rate limit"
        subtitle="Limit value is the actual rate limit count set on the third party. This is only used to show what the max is and this value is used in the runner to calculate the limit count from the rate limit threshold. Time value is the amount of time, with a default of 1. Unit of time is the unit of time used for the rate limit."
        learnMoreText="For example, if the third-party documentation states “100 requests per 12 hours” then the limit value is 100, the time value is 12, and the unit of time is hours."
      />
      <div className="flex flex-row items-center space-x-3 mt-2">
        {rateLimitType === RateLimitType.COMPLEXITY_COUNT ? (
          <div data-testid="input-rate-limit-value-point-value">
            <TextField
              className="w-[118px]"
              placeholder="Point value"
              value={tempMaxPointsInputValue}
              onChange={handleInputChange}
            />
          </div>
        ) : (
          <div data-testid="input-rate-limit-value-limit-value">
            <TextField
              className="w-[118px]"
              placeholder="Limit value"
              type="number"
              value={rateLimitMaxCount}
              onChange={(newEvent) => {
                const value = newEvent.target.value;
                if (value === "") {
                  setRateLimitMaxCount(undefined);
                } else {
                  const numericValue = parseInt(value, 10);
                  if (!isNaN(numericValue)) {
                    setRateLimitMaxCount(numericValue);
                  }
                }
              }}
            />
          </div>
        )}
        <Text as="span" className="whitespace-nowrap">
          {isTimeBased ? `${displayString} per` : displayString}
        </Text>
        {isTimeBased && (
          <>
            <div data-testid="input-rate-limit-value-time-value">
              <TextField
                className="w-[118px]"
                placeholder="Time value"
                type="number"
                disabled={isEndOfMonth}
                value={rateLimitTimeValue}
                onChange={(newEvent) => {
                  const value = newEvent.target.value;
                  if (value === "") {
                    setRateLimitTimeValue(undefined);
                  } else {
                    const numericValue = parseInt(value, 10);
                    if (!isNaN(numericValue)) {
                      setRateLimitTimeValue(numericValue);
                    }
                  }
                }}
              />
            </div>
            <div data-testid="input-rate-limit-value-time-unit" className="w-[200px]">
              <Select
                value={selectedRateLimitTimePeriod}
                clearable={false}
                renderValue={renderRateLimitTimePeriodValue}
                renderOption={renderRateLimitTimePeriodOption}
                options={RATE_LIMIT_TIME_PERIOD_OPTIONS}
                placeholder="Select unit of time..."
                onChange={(_: any, selectedOption: StringOptionsData | null) => {
                  if (selectedOption) {
                    setRateLimitTimePeriod(selectedOption.value as RateLimitTimePeriod);
                  }
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default RateLimitValue;
