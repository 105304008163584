import CardHeader from "../../../shared/CardHeader";
import { Card } from "@merge-api/merge-javascript-shared";
import SelectHeader from "../../../shared/SelectHeader";
import {
  LinkingFlowStepPathIntegrationBuilder,
  LinkingFlowStepPathTypeEnums,
} from "../../../utils/Entities";
import { convertQueryValueToEnumKey } from "../../utils/helpers";
import { useEffect } from "react";
import { AuthType } from "../../../../../models/Entities";
import AdvancedStepPathFields from "./AdvancedStepPathFields";

interface ConfigureStepPathSectionProps {
  setRequestedStepPath: React.Dispatch<
    React.SetStateAction<LinkingFlowStepPathIntegrationBuilder | undefined>
  >;
  requestedStepPath: LinkingFlowStepPathIntegrationBuilder;
  authConfigType: AuthType | undefined;
}

const selectablePathTypeOptions = [
  LinkingFlowStepPathTypeEnums.PATH_TYPE_MANUAL_LINKING,
  LinkingFlowStepPathTypeEnums.PATH_TYPE_PARTNERSHIP,
];

const scraperPathTypeOptions = [
  LinkingFlowStepPathTypeEnums.PATH_TYPE_SCRAPER,
  LinkingFlowStepPathTypeEnums.PATH_TYPE_SCRAPER_MFA,
  LinkingFlowStepPathTypeEnums.PATH_TYPE_SCRAPER_SECURITY_QUESTION,
];

const ConfigureStepPathSection = ({
  setRequestedStepPath,
  requestedStepPath,
  authConfigType,
}: ConfigureStepPathSectionProps) => {
  const presetPathType = convertQueryValueToEnumKey("presetPathType", LinkingFlowStepPathTypeEnums);

  useEffect(() => {
    if (presetPathType) {
      setRequestedStepPath({
        ...requestedStepPath,
        path_type: presetPathType,
      });
    }
  }, [presetPathType]);

  return (
    <Card className="pt-4 px-5 pb-5 bg-white mb-6 min-w-fit space-y-6">
      <CardHeader title="Configure step path" />
      <SelectHeader
        dataTestID="field-step-path-path-type"
        title="Path type"
        options={
          presetPathType || scraperPathTypeOptions.includes(requestedStepPath.path_type)
            ? Object.values(LinkingFlowStepPathTypeEnums)
            : selectablePathTypeOptions
        }
        onChange={(_: any, selectedOption: LinkingFlowStepPathTypeEnums | null) => {
          setRequestedStepPath({
            ...requestedStepPath,
            path_type: selectedOption || LinkingFlowStepPathTypeEnums.PATH_TYPE_MANUAL_LINKING,
          });
        }}
        value={presetPathType ? presetPathType : requestedStepPath.path_type}
        disabled={
          presetPathType || scraperPathTypeOptions.includes(requestedStepPath.path_type)
            ? true
            : false
        }
        clearable={false}
      />
      <AdvancedStepPathFields
        authConfigType={authConfigType}
        requestedStepPath={requestedStepPath}
        setRequestedStepPath={setRequestedStepPath}
      />
    </Card>
  );
};

export default ConfigureStepPathSection;
