import { Button, Card, Text } from "@merge-api/merge-javascript-shared";
import { ChecklistStatusValue } from "../../../../constants";
import { WandSparkles } from "lucide-react";
import { useHistory } from "react-router-dom";
import { getIntegrationBuilderAISearchPathForId } from "../../../../router/RouterUtils";

interface APIEndpointGeneratorPreviewProps {
  integrationID: string;
  aiSearchConfigurationStatus: ChecklistStatusValue;
}

const APIEndpointGeneratorPreview = ({
  integrationID,
  aiSearchConfigurationStatus,
}: APIEndpointGeneratorPreviewProps) => {
  const history = useHistory();

  const getButtonText = () => {
    switch (aiSearchConfigurationStatus) {
      case ChecklistStatusValue.PENDING:
        return "Setting up AI features";
      case ChecklistStatusValue.NOT_IMPLEMENTED:
      case ChecklistStatusValue.FAILURE:
        return "Set up AI features";
      default:
        return "Generate";
    }
  };

  const onClick = () => {
    switch (aiSearchConfigurationStatus) {
      case ChecklistStatusValue.PENDING:
        return;
      case ChecklistStatusValue.NOT_IMPLEMENTED:
      case ChecklistStatusValue.FAILURE:
        history.push({
          pathname: getIntegrationBuilderAISearchPathForId(integrationID),
        });
        return;
      default:
        return;
    }
  };

  return (
    <Card
      variant="shadow"
      className="px-5 py-4 text-white flex flex-row items-center justify-between border-[1px] border-gray-10"
    >
      <div className="flex items-center flex-row">
        <WandSparkles size={16} className="mr-3 text-black" />
        <Text variant="h5" className="flex items-center text-black">
          Generate endpoints with AI
        </Text>
      </div>
      <Button
        size="sm"
        leftIcon={
          aiSearchConfigurationStatus !== ChecklistStatusValue.PENDING ? (
            <WandSparkles size={12} />
          ) : undefined
        }
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
        loading={aiSearchConfigurationStatus === ChecklistStatusValue.PENDING}
        disabled={aiSearchConfigurationStatus === ChecklistStatusValue.PENDING}
      >
        {getButtonText()}
      </Button>
    </Card>
  );
};

export default APIEndpointGeneratorPreview;
