import { Dialog, Card, Text, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { ArrowRight } from "lucide-react";
import { useEffect, useState } from "react";
import {
  APIEndpointParameterMapping,
  DatetimeFormat,
  ListTransformationInformation,
  UserFacingFilterDetails,
  ValueTransformation,
} from "../../../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import { ConditionListOperator, ConditionOperator, DatetimeFormatEnum } from "../../../../types";
import CommonModelDisplay from "../../../shared/CommonModelDisplay";
import TransformValuesModalForm from "./TransformValuesModalForm";
import useIntegrationBuilderContext from "../../../../../context/useIntegrationBuilderContext";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;

  operator: ConditionOperator;
  valueTransformation: ValueTransformation | null;
  apiEndpointParameterMapping: APIEndpointParameterMapping;
  userFacingDetails: UserFacingFilterDetails | null;
  updateValueTransformation: (newValueTransformation: ValueTransformation | null) => void;
  isDisabled?: boolean;
}

const EMPTY_LIST_TRANSFORMATION_INFORMATION: ListTransformationInformation = {
  should_insert_as_repeated_query_parameters: false,
  is_non_native_or: false,
  list_delimiter: null,
};

const EMPTY_VALUE_TRANSFORMATION: ValueTransformation = {
  prefix: null,
  suffix: null,
  deprecated_default_value: null,
  format_string: null,
  datetime_format: null,
  list_transformation_information: null,
  composite_filter_mapping_information: null,
};

const TransformValuesModal = ({
  isModalOpen,
  operator,
  valueTransformation,
  apiEndpointParameterMapping,
  userFacingDetails,
  setIsModalOpen,
  updateValueTransformation,
  isDisabled,
}: Props) => {
  const { integration } = useIntegrationBuilderContext();
  const [onSubmit, setOnSubmit] = useState<(() => void) | null>(null);
  const isListOperator = Object.values(ConditionListOperator).includes(
    operator as ConditionListOperator
  );

  const valueTransformationUnderConstruction: ValueTransformation =
    valueTransformation ?? EMPTY_VALUE_TRANSFORMATION;

  const [formatString, setFormatString] = useState<string>(
    valueTransformationUnderConstruction?.format_string ?? ""
  );
  const [prefix, setPrefix] = useState<string>(valueTransformationUnderConstruction?.prefix ?? "");
  const [suffix, setSuffix] = useState<string>(valueTransformationUnderConstruction?.suffix ?? "");
  const [listTransformationInformation, setListTransformationInformation] = useState<
    ListTransformationInformation
  >(
    valueTransformationUnderConstruction?.list_transformation_information ??
      EMPTY_LIST_TRANSFORMATION_INFORMATION
  );
  const [datetimeFormat, setDatetimeFormat] = useState<DatetimeFormatEnum | null>(
    valueTransformationUnderConstruction?.datetime_format as DatetimeFormatEnum
  );

  const apiEndpointParameter =
    apiEndpointParameterMapping?.api_endpoint_filter_id?.next_version ??
    apiEndpointParameterMapping?.api_endpoint_filter_id?.published_version;

  const onSaveTransformValues = () => {
    const newValueTransformation = {
      ...valueTransformationUnderConstruction,
      prefix,
      suffix,
      format_string: formatString,
      datetime_format: datetimeFormat as DatetimeFormat,
      list_transformation_information: listTransformationInformation,
    };

    updateValueTransformation(newValueTransformation);
    setIsModalOpen(false);
  };

  useEffect(() => {
    setOnSubmit(() => onSaveTransformValues);
  }, [prefix, suffix, formatString, datetimeFormat, listTransformationInformation]);

  return (
    <Dialog
      onSecondaryButtonClick={() => setIsModalOpen(false)}
      variant="lg"
      title={`Transform selective sync filter value to ${
        integration ? integration.name : "3rd-party"
      } API endpoint value`}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      primaryButtonText={`Save`}
      primaryButtonVariant={ButtonVariant.PrimaryBlue}
      onPrimaryButtonClick={() => (onSubmit ? onSubmit() : () => {})}
      primaryButtonDisabled={!!isDisabled}
    >
      <div className="max-h-[700px] overflow-auto px-2">
        <Text variant="md">
          {`Define how we should transform the end-user-defined Selective Sync filter values to
          ${integration ? integration.name : "3rd-party"} API Endpoint Filter values`}
        </Text>
        <div className="flex flex-row justify-between items-center pb-2">
          <Card variant="outline" className="p-3 mt-2 mr-3 w-100">
            <CommonModelDisplay
              isBold
              alignIconLeft={true}
              commonModelID={userFacingDetails?.filter_type_id?.common_model_class_id ?? ""}
            />
            <Text variant="sm-mono">{userFacingDetails?.filter_type_id?.filter_name}</Text>
          </Card>
          <ArrowRight size={50} className="text-gray-60" />
          <Card variant="outline" className="p-3 mt-2 ml-3 w-100">
            <div className="flex flex-col">
              <Text variant="h6">{apiEndpointParameter?.api_endpoint_id?.name}</Text>
              <Text variant="sm-mono">{apiEndpointParameter?.key_path?.[0]}</Text>
            </div>
          </Card>
        </div>
        <hr />
        <TransformValuesModalForm
          formatString={formatString}
          setFormatString={setFormatString}
          prefix={prefix}
          setPrefix={setPrefix}
          suffix={suffix}
          setSuffix={setSuffix}
          listTransformationInformation={listTransformationInformation}
          setListTransformationInformation={setListTransformationInformation}
          datetimeFormat={datetimeFormat}
          setDatetimeFormat={setDatetimeFormat}
          isListOperator={isListOperator}
        />
      </div>
    </Dialog>
  );
};

export default TransformValuesModal;
