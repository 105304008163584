import TypeaheadFormField from "./TypeaheadFormField";

const BlueprintEditorRightPanelCreateOAuth1SignagtureForm = () => {
  return (
    <form>
      <TypeaheadFormField
        title="Account Id"
        subtitle="OAuth account id."
        valueKey="account_id"
        parameterType={"string"}
      />
      <TypeaheadFormField
        title="Consumer Key"
        subtitle="OAuth consumer key."
        valueKey="consumer_key"
        parameterType={"string"}
      />
      <TypeaheadFormField
        title="Consumer Secret"
        subtitle="OAuth consumer secret."
        valueKey="consumer_secret"
        parameterType={"string"}
      />
      <TypeaheadFormField
        title="Token Id"
        subtitle="OAuth token id."
        valueKey="token_id"
        parameterType={"string"}
      />
      <TypeaheadFormField
        title="Token Secret"
        subtitle="OAuth token secret."
        valueKey="token_secret"
        parameterType={"string"}
      />
    </form>
  );
};

export default BlueprintEditorRightPanelCreateOAuth1SignagtureForm;
