import { useCallback, useState } from "react";
import { UserFacingFilterType } from "../../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import { fetchMappedFilterTypesForIntegration } from "../../shared/SelectiveSyncFilterBuilderAPIClient";

type AvailableFilterTypes = {
  [id: string]: UserFacingFilterType;
};

interface loadAPIEndpointProps {
  integrationID: string;
  setIntegrationFilterTypes: (value: React.SetStateAction<AvailableFilterTypes>) => void;
}

const useLoadFilterTypes = ({ integrationID, setIntegrationFilterTypes }: loadAPIEndpointProps) => {
  // State
  const [isLoadingFilterTypes, setIsLoadingFilterTypes] = useState(true);

  // Function to load filter types
  const loadFilterTypes = useCallback(() => {
    setIsLoadingFilterTypes(true);
    fetchMappedFilterTypesForIntegration({
      integrationID,
      onSuccess: (data: { [id: string]: UserFacingFilterType }) => {
        setIsLoadingFilterTypes(false);
        setIntegrationFilterTypes(data);
      },
      onError: (_: Response | undefined) => {
        setIsLoadingFilterTypes(false);
      },
    });
  }, [integrationID, setIntegrationFilterTypes]);

  return { isLoadingFilterTypes, loadFilterTypes };
};

export default useLoadFilterTypes;
