import { RateLimitConfigurationIntegrationBuilder } from "../../../../utils/Entities";
import { Text } from "@merge-api/merge-javascript-shared";
import { roundPercentValue } from "../../../utils/helpers";

interface ThresholdCellProps {
  rateLimitConfiguration: RateLimitConfigurationIntegrationBuilder;
  onClick: React.MouseEventHandler<HTMLTableCellElement> | undefined;
}

const ThresholdCell = ({ rateLimitConfiguration, onClick }: ThresholdCellProps) => {
  const thresholdDisplay =
    rateLimitConfiguration?.default_rate_limit_threshold !== undefined
      ? `${roundPercentValue(rateLimitConfiguration.default_rate_limit_threshold)} %`
      : "N/A";

  return (
    <td onClick={onClick}>
      <Text as="div" className="text-gray-90 text-base font-medium">
        {thresholdDisplay}
      </Text>
    </td>
  );
};
export default ThresholdCell;
