import classNames from "classnames";
import { Table } from "react-bootstrap";
import {
  BlueprintRunnerStepLogEntryType,
  BlueprintRunnerStepLogs,
} from "../../../models/Blueprints";
import { getDifferenceInDatesInMilliseconds } from "../../../utils";

interface Props {
  startTime: string;
  stepLogs: BlueprintRunnerStepLogs;
}

const EventTypeRectangle = (props: { event_type: BlueprintRunnerStepLogEntryType }) => {
  const colorClass =
    props.event_type === BlueprintRunnerStepLogEntryType.Error
      ? "console-error"
      : props.event_type === BlueprintRunnerStepLogEntryType.Warning
      ? "console-warning"
      : "console-info";
  const rectClasses = classNames("event-type-rectangle", colorClass);
  return <div className={rectClasses}></div>;
};

const IntegrationEditorStepLogsTable = ({ startTime, stepLogs }: Props) => (
  <Table size="sm" className="low-padding-table" style={{ height: "100%" }}>
    <thead className="table-borderless">
      <tr>
        <th>Time</th>
        <th>Message</th>
      </tr>
    </thead>
    <tbody>
      {stepLogs.map((log) => (
        <tr id={log.time}>
          <td className="console-table-message align-middle" style={{ height: "100%" }}>
            <EventTypeRectangle event_type={log.entry_type} />
            {(getDifferenceInDatesInMilliseconds(startTime, log.time) / 1000).toFixed(2) + "s"}
          </td>
          <td className="text-break align-middle p-0">
            {(log.step_id ? log.step_id + ": " : "") + log.message}
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export default IntegrationEditorStepLogsTable;
