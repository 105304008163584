import { Card, Text } from "@merge-api/merge-javascript-shared";
import StepIOLogModalSectionRow from "./StepIOLogModalSectionRow";

interface Props {
  title: string;
  data: { [key: string]: any } | null;
}

const StepIOLogModalSection = ({ title, data }: Props) => {
  const dataArray = data ? Object.entries(data) : [];

  return (
    <Card className="bg-gray-0" variant="none">
      <div className="flex flex-col p-3">
        <Text variant="h6">{title}</Text>
        {dataArray.length === 0 ? (
          <Text variant="md-mono" className="pt-2 text-gray-50 border-t border-gray-10">
            No data
          </Text>
        ) : (
          dataArray.map(([dataKey, value]) => (
            <StepIOLogModalSectionRow dataKey={dataKey} dataValue={value} />
          ))
        )}
      </div>
    </Card>
  );
};

export default StepIOLogModalSection;
