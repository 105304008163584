import { ChevronLeft, ChevronRight } from "lucide-react";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";

interface Props {
  hasPrevious: boolean;
  hasNext: boolean;
  onPreviousClick?: () => void;
  onNextClick?: () => void;
}

const PaginationFooter = ({ hasPrevious, hasNext, onPreviousClick, onNextClick }: Props) => {
  return (
    <div className="flex justify-around mb-9">
      <div>
        <Button
          size="sm"
          variant={ButtonVariant.IconOnly}
          onClick={onPreviousClick}
          disabled={!hasPrevious}
        >
          <ChevronLeft size={16} />
        </Button>
      </div>

      <div>
        <Button
          size="sm"
          variant={ButtonVariant.IconOnly}
          onClick={onNextClick}
          disabled={!hasNext}
        >
          <ChevronRight size={16} />
        </Button>
      </div>
    </div>
  );
};

export default PaginationFooter;
