import { useHistory } from "react-router-dom";
import {
  navigateToIntegrationBuilderExistingLinkingFlowStepPathSetup,
  navigateToIntegrationBuilderNewLinkingFlowStepPathSetup,
} from "../../../../../router/RouterUtils";
import {
  LinkingFlowStepPathIntegrationBuilder,
  LinkingFlowStepPathTableRowsIntegrationBuilder,
  LinkingFlowStepPathTypeEnums,
} from "../../../utils/Entities";
import TextCell from "./TextCell";
import ToggleCell from "./ToggleCell";
import ButtonCell from "./ButtonCell";
import ChevronCell from "./ChevronCell";
import { formattedPathTypeName } from "../../utils/helpers";
import { useState } from "react";
import { ButtonVariant, Dialog, Text } from "@merge-api/merge-javascript-shared";
import useCreateOrPatchLinkingFlowStepPath from "../../hooks/useCreateOrPatchLinkingFlowStepPath";
import { Button } from "@merge-api/merge-javascript-shared";
import { getIntegrationBuilderSingleAuthConfigPathForId } from "../../../../../router/RouterUtils";

type LinkingFlowStepPathsTableRowProps = {
  linkingFlowStepPath: LinkingFlowStepPathIntegrationBuilder | undefined;
  integrationID: string;
  tableRow: LinkingFlowStepPathTableRowsIntegrationBuilder;
  reloadLinkingFlowStepPathsAndAuthConfigs: () => void;
};

const LinkingFlowStepPathsTableRow = ({
  integrationID,
  linkingFlowStepPath,
  tableRow,
  reloadLinkingFlowStepPathsAndAuthConfigs,
}: LinkingFlowStepPathsTableRowProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const history = useHistory();

  // Initiate Patch actions
  const { patchLinkingFlowStepPath, isLoadingStepPathUpdate } = useCreateOrPatchLinkingFlowStepPath(
    {
      setIsModalOpen: setIsModalOpen,
      integrationID: integrationID,
      linkingFlowStepPathID: tableRow.step_path_id,
      requestedLinkingFlowStepPath: linkingFlowStepPath
        ? {
            ...linkingFlowStepPath,
            is_active: !linkingFlowStepPath.is_active,
          }
        : undefined,
      reloadLinkingFlowStepPathsAndAuthConfigs: reloadLinkingFlowStepPathsAndAuthConfigs,
    }
  );

  // Define button actions
  const onRowClick = () => {
    if (tableRow.step_path_id) {
      navigateToIntegrationBuilderExistingLinkingFlowStepPathSetup(
        history,
        integrationID,
        tableRow.step_path_id
      );
    }
  };

  const onButtonClick = () => {
    if (tableRow) {
      navigateToIntegrationBuilderNewLinkingFlowStepPathSetup(
        history,
        integrationID,
        tableRow.auth_config_id,
        tableRow.path_type
      );
    }
  };

  const onToggleClick = () => {
    setIsModalOpen(true);
  };

  const navigateToAuthConfig = (authConfigId: string, event: any) => {
    event.stopPropagation();
    history.push({
      pathname: getIntegrationBuilderSingleAuthConfigPathForId(integrationID, authConfigId),
    });
  };

  return linkingFlowStepPath ? (
    <tr className="cursor-pointer h-[74px]">
      <td className="py-5" onClick={onRowClick}>
        <Button
          size="md"
          variant={ButtonVariant.TextBlue}
          onClick={(event) => navigateToAuthConfig(tableRow.auth_config_id, event)}
        >
          {tableRow.auth_config_name}
        </Button>
      </td>
      <TextCell
        text={tableRow.path_type ? formattedPathTypeName(tableRow.path_type) : ""}
        isBold={true}
        onClick={onRowClick}
      />
      <ToggleCell onClick={onToggleClick} checked={linkingFlowStepPath.is_active} />
      <td></td>
      <ChevronCell onClick={onRowClick} />
      <Dialog
        title={tableRow.is_active ? "Unpublish linking flow steps" : "Publish linking flow steps"}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        primaryButtonVariant={
          tableRow.is_active ? ButtonVariant.DangerFilled : ButtonVariant.PrimaryBlue
        }
        primaryButtonText={tableRow.is_active ? "Unpublish" : "Publish"}
        onPrimaryButtonClick={() => patchLinkingFlowStepPath()}
        primaryButtonLoading={isLoadingStepPathUpdate}
        onSecondaryButtonClick={() => setIsModalOpen(false)}
        children={
          linkingFlowStepPath.is_active ? (
            <div className="space-y-2">
              <div>
                <Text>
                  Unpublishing will remove these linking flow steps for all end users of this
                  integration.
                </Text>
              </div>
              <div>
                <Text>Are you sure you want to unpublish?</Text>
              </div>
            </div>
          ) : (
            <div className="space-y-2">
              <div>
                <Text>
                  Publishing will make these linking flow steps appear for all end users of this
                  integration.
                </Text>
              </div>
              <div>
                <Text>Are you sure you want to publish?</Text>
              </div>
            </div>
          )
        }
      />
    </tr>
  ) : (
    <tr className="h-[74px]">
      <TextCell text={tableRow.auth_config_name || ""} onClick={onRowClick} />
      <ButtonCell
        onClick={onButtonClick}
        buttonText={
          tableRow.path_type === LinkingFlowStepPathTypeEnums.PATH_TYPE_SCRAPER
            ? "Scraper steps"
            : tableRow.path_type === LinkingFlowStepPathTypeEnums.PATH_TYPE_SCRAPER_MFA
            ? "Scraper MFA steps"
            : tableRow.path_type ===
              LinkingFlowStepPathTypeEnums.PATH_TYPE_SCRAPER_SECURITY_QUESTION
            ? "Scraper security question steps"
            : "Linking flow steps"
        }
      />
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );
};

export default LinkingFlowStepPathsTableRow;
