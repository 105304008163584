/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import DeletionConfirmationModal from "../../shared/DeletionConfirmationModal";
import { Table, Card } from "react-bootstrap";
import EmptyStateWrapper from "../../shared/layout/EmptyStateWrapper";
import { ChevronToggle } from "../../shared/MergeToggles";
import { MappingTestAndVersions } from "../../../models/MappingTests";
import MappingTestCaseCreateButton from "./MappingTestCaseCreateButton";
import { fetchWithAuth } from "../../../api-client/api_client";
import { showErrorToast, showSuccessToast } from "../../shared/Toasts";
import {
  navigateToTestSuitesTable,
  navigateToIndividualTestSuite,
  navigateToMappingTestEditor,
} from "../../../router/RouterUtils";
import { Integration } from "../../../models/Entities";
import styled from "styled-components";
import MergeText from "../../shared/text/MergeText";
import MappingTestTableViewLinkRow from "./MappingTestTableViewLinkRow";
import ComponentStatusBadge from "../../shared/ComponentStatusBadge";

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

type Props = {
  testCases: MappingTestAndVersions[];
  integrationMeta: Integration;
  mappingTestSuiteID: string;
  endpointCollectionID: string;
  endUserOrganizationName: string;
  title: string;
};
const IndividualEndpointCollectionTestCaseTable = ({
  testCases,
  integrationMeta,
  mappingTestSuiteID,
  endpointCollectionID,
  endUserOrganizationName,
  title,
}: Props) => {
  const [
    isShowingConfirmDeleteMappingTestModal,
    setIsShowingConfirmDeleteMappingTestModal,
  ] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [selectedMappingTestIDForDeletion, setSelectedMappingTestIDForDeletion] = useState<
    string
  >();
  const [deletedTestCaseIds, setDeletedTestCaseIds] = useState<Array<string>>([]);

  //@ts-ignore
  const { params } = useLocation();

  const handleNavigateToMappingTestCase = (
    testCaseId: string,
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    navigateToMappingTestEditor(
      history,
      integrationMeta.id,
      testCaseId,
      event.metaKey || event.ctrlKey
    );
  };

  const filteredExpectedResult = params?.split("=")[1];

  const filteredTestCases = useMemo(
    () =>
      testCases
        .filter(
          (testCase) =>
            !filteredExpectedResult || filteredExpectedResult === testCase.expected_result
        )
        .filter((testCase) => !deletedTestCaseIds.includes(testCase.id)),
    [testCases, filteredExpectedResult, deletedTestCaseIds]
  );

  const deleteMappingTestCase = (mappingTestID: string | undefined) => {
    setIsLoading(true);
    fetchWithAuth({
      path: `/stallions/tests/${mappingTestID}`,
      method: "DELETE",
      onResponse: () => {
        showSuccessToast(`Successfully deleted test!`);
        setIsShowingConfirmDeleteMappingTestModal(false);
        setIsLoading(false);
        setDeletedTestCaseIds([...deletedTestCaseIds, mappingTestID!]);
      },
      onError: () => {
        showErrorToast(`Failed to delete test.`);
        setIsLoading(false);
      },
    });
  };

  return (
    <>
      <DeletionConfirmationModal
        selectedObjectType="Test"
        associatedObjectsName={null}
        show={isShowingConfirmDeleteMappingTestModal}
        onHide={() => setIsShowingConfirmDeleteMappingTestModal(false)}
        isLoading={isLoading}
        onConfirm={() => deleteMappingTestCase(selectedMappingTestIDForDeletion)}
      />

      <div>
        <MappingTestTableViewLinkRow
          links={[
            {
              onClick: () => navigateToTestSuitesTable(history, integrationMeta.id),
              title: "Mapping Test Suites",
            },
            {
              onClick: () =>
                navigateToIndividualTestSuite(history, integrationMeta.id, mappingTestSuiteID),
              title: endUserOrganizationName,
            },
            {
              title: title,
            },
          ]}
        />
        <HeaderRow>
          <MergeText isBold size="24px">
            Test Cases
          </MergeText>
          <MappingTestCaseCreateButton
            integrationID={integrationMeta.id}
            endpointCollectionID={endpointCollectionID}
            categories={integrationMeta.categories}
          />
        </HeaderRow>
        <Card>
          <Table responsive hover>
            <thead className="table-borderless">
              <tr>
                <th scope="col">Test Case name</th>
                <th scope="col">Type</th>
                <th scope="col">Status</th>
                <th scope="col" className="text-right" />
                <th scope="col" className="text-right" />
              </tr>
            </thead>
            <tbody>
              {filteredTestCases ? (
                filteredTestCases.length > 0 ? (
                  <>
                    {filteredTestCases.map((testCase: MappingTestAndVersions) => (
                      <>
                        <tr key={testCase.id} className="table-link">
                          <td
                            className="w-25"
                            onClick={(event) => handleNavigateToMappingTestCase(testCase.id, event)}
                          >
                            {testCase.name}
                          </td>
                          <td
                            className="w-35"
                            onClick={(event) => handleNavigateToMappingTestCase(testCase.id, event)}
                          >
                            {testCase.expected_result}
                          </td>
                          <td
                            className="w-10"
                            onClick={(event) => handleNavigateToMappingTestCase(testCase.id, event)}
                          >
                            {
                              <ComponentStatusBadge
                                hasPublishedVersion={!!testCase.published_version}
                                nextComponentVersionState={testCase.next_version_state}
                              />
                            }
                          </td>
                          <td
                            className="text-right"
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          >
                            <div className="d-flex justify-content-end">
                              <i
                                className="fe fe-trash-2"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setIsShowingConfirmDeleteMappingTestModal(true);
                                  setSelectedMappingTestIDForDeletion(testCase.id);
                                }}
                              />
                            </div>
                          </td>
                          <td className="w-5">
                            <div className="pb-1.5">
                              <ChevronToggle eventKey="0">{""}</ChevronToggle>
                            </div>
                          </td>
                        </tr>
                      </>
                    ))}
                  </>
                ) : (
                  <tr className="unmapped-common-models">
                    <td colSpan={8} className="p-0">
                      <EmptyStateWrapper isTable title="No Tests" />
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan={7} className="p-0">
                    <EmptyStateWrapper isTable isSpinner />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card>
      </div>
    </>
  );
};

export default IndividualEndpointCollectionTestCaseTable;
