import { useCallback, useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { APIEndpointMethod } from "../../../../models/Entities";
import { showErrorToast } from "../../../shared/Toasts";
import { APIEndpointIntegrationBuilder } from "../../utils/Entities";

interface loadAPIEndpointProps {
  integrationID: string;
  setAPIEndpoint: React.Dispatch<
    React.SetStateAction<APIEndpointIntegrationBuilder | undefined | null>
  >;
  apiEndpointID: string | null;
}

const useLoadAPIEndpoint = ({
  integrationID,
  setAPIEndpoint,
  apiEndpointID,
}: loadAPIEndpointProps) => {
  // state
  const [isLoadingAPIEndpoint, setIsLoadingAPIEndpoint] = useState(true);

  // function to load API endpoints
  const loadAPIEndpoint = useCallback(() => {
    if (!apiEndpointID) {
      setIsLoadingAPIEndpoint(false);
      return;
    }

    setIsLoadingAPIEndpoint(true);
    fetchWithAuth({
      path: `/integrations/integration-builder/api-endpoint/${apiEndpointID}`,
      method: APIEndpointMethod.GET,
      onResponse: (data: APIEndpointIntegrationBuilder) => {
        setAPIEndpoint(data);
        setIsLoadingAPIEndpoint(false);
      },
      onError: () => {
        showErrorToast("Failed to fetch API endpoints for integration.");
        setAPIEndpoint(undefined);
        setIsLoadingAPIEndpoint(false);
      },
    });
  }, [integrationID, setAPIEndpoint, apiEndpointID]);

  return { isLoadingAPIEndpoint, loadAPIEndpoint };
};

export default useLoadAPIEndpoint;
