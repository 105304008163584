import { Chips, Text } from "@merge-api/merge-javascript-shared";

interface LiveRunChipsProps {
  isQBDLiveRunTab: boolean;
  setIsQBDLiveRunTab: (isQBDLiveRunTab: boolean) => void;
}

const LiveRunChips = ({ isQBDLiveRunTab, setIsQBDLiveRunTab }: LiveRunChipsProps) => {
  const options = [
    {
      value: false,
      text: "Test",
      selected: !isQBDLiveRunTab,
      onClick: () => setIsQBDLiveRunTab(false),
      disabled: false,
    },
    {
      value: true,
      text: "Live",
      selected: isQBDLiveRunTab,
      onClick: () => setIsQBDLiveRunTab(true),
      disabled: false,
    },
  ];

  return (
    <>
      <Text as="div" variant="h6">
        Run type
      </Text>
      <Chips size="sm" options={options} />
    </>
  );
};

export default LiveRunChips;
