import { memo, useContext, useEffect, useState } from "react";
import MultiSelectHeader from "../../../shared/MultiSelectHeader";
import { BOOLEAN_OPTIONS_DATA } from "../../../utils/constants";
import PaginationConfigurationContext from "../../context/PaginationConfigurationContext";
import {
  KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER,
  KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER,
  SORT_FIELD_FILTER_REQUEST_LOCATION_OPTIONS,
} from "../../constants";
import { SortFieldFilterRequestLocation } from "../../../../../models/PaginationConfigurationModels";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import KeyPathFieldHeader from "../../../shared/KeyPathFieldHeader";
import JSONSchemaConverterFormField from "../../../../integrations/api-endpoints/JSONSchemaConverterFormField";

interface SortInformationSetupOptionsProps {
  className?: string;
}

const SortInformationSetupOptions = ({ className }: SortInformationSetupOptionsProps) => {
  const {
    isSortAssistedPagination,
    setIsSortAssistedPagination,
    sortFieldFilterRequestLocation,
    setSortFieldFilterRequestLocation,
    sortFieldFilterRequestKeyPath,
    setSortFieldFilterRequestKeyPath,
    sortFieldFilterRequestFormat,
    setSortFieldFilterRequestFormat,
    sortFieldFilterResponseKeyPath,
    setSortFieldFilterResponseKeyPath,
  } = useContext(PaginationConfigurationContext);

  const shouldDisplayKeyField =
    sortFieldFilterRequestLocation === SortFieldFilterRequestLocation.QUERY_PARAM ||
    sortFieldFilterRequestLocation === SortFieldFilterRequestLocation.HEADER_PARAM ||
    sortFieldFilterRequestLocation === SortFieldFilterRequestLocation.PATH_PARAM;

  const shouldDisplayKeyPathField =
    sortFieldFilterRequestLocation === SortFieldFilterRequestLocation.BODY_PARAM;

  const isSortAssistedPaginationOptions = BOOLEAN_OPTIONS_DATA.map(({ value, text }) => ({
    value,
    text,
    selected: value === isSortAssistedPagination,
    onClick: () => setIsSortAssistedPagination(value),
  }));

  const [isLocationModified, setIsLocationModified] = useState<boolean>(false);
  const sortFieldFilterRequestLocationOptions = SORT_FIELD_FILTER_REQUEST_LOCATION_OPTIONS.map(
    ({ value, text }) => ({
      value,
      text,
      selected: value === sortFieldFilterRequestLocation,
      onClick: () => {
        setIsLocationModified(true);
        setSortFieldFilterRequestLocation(value);
      },
    })
  );

  const [sortFieldRequestFormatText, setSortFieldRequestFormatText] = useState<string>(
    JSON.stringify(sortFieldFilterRequestFormat || {})
  );
  useEffect(() => {
    if (sortFieldRequestFormatText) {
      try {
        const parsedValue = JSON.parse(sortFieldRequestFormatText);
        setSortFieldFilterRequestFormat(parsedValue);
      } catch {
        setSortFieldFilterRequestFormat({});
      }
    } else {
      setSortFieldFilterRequestFormat({});
    }
  }, [sortFieldRequestFormatText]);

  // clear the key path when the location changes. Make sure it's being modified
  // and that it's not the first time the component is being rendered
  useEffect(() => {
    if (isLocationModified) {
      setSortFieldFilterRequestKeyPath([]);
    }
  }, [sortFieldFilterRequestLocation]);

  return (
    <div className={className}>
      <MultiSelectHeader
        dataTestID="field-pagination-config-is-sort-assisted-pagination"
        title="Should pagination be assisted by sorting?"
        subtitle="This is only useful if 1) 3rd-party API pagination supports a way to sort results and 2) 3rd-party API pagination cursor expires unreliably, and in case it does expire, the next Blueprint execution uses the last successful sorted result as a “bookmark” instead of starting over."
        options={isSortAssistedPaginationOptions}
      />
      {isSortAssistedPagination && (
        <div className="border-l-2 pl-8 mt-6 border-gray-10">
          <MultiSelectHeader
            dataTestID="field-pagination-config-sort-field-filter-request-location"
            title="Location of field in request used for sorting"
            subtitle="Where should we input the location of the field to be used for sorting?"
            options={sortFieldFilterRequestLocationOptions}
            required
          />
          {/** TODO: https://app.asana.com/0/1205644398660644/1206923763851312/f Modify the key path field header component to have a max depth instead of using text field conditionally */}
          {shouldDisplayKeyField && (
            <TextFieldHeader
              className="mt-5"
              dataTestID="field-pagination-config-sort-field-filter-request-key"
              title="Key to field used for sorting"
              subtitle="The key in the request query/path/header where we can insert values to be used for sorting."
              hasSource={false}
              placeholder="Key"
              value={
                sortFieldFilterRequestKeyPath && sortFieldFilterRequestKeyPath.length > 0
                  ? sortFieldFilterRequestKeyPath[0]
                  : ""
              }
              onChange={(e) => setSortFieldFilterRequestKeyPath([e.target.value])}
              required
            />
          )}
          {shouldDisplayKeyPathField && (
            <KeyPathFieldHeader
              className="mt-5"
              dataTestID="field-pagination-config-sort-field-filter-request-key-path"
              title="Key path to field used for sorting"
              subtitle="The key path in the request body where we can insert values to be used for sorting."
              hasSource={false}
              placeholder={
                sortFieldFilterRequestKeyPath?.length
                  ? KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER
                  : KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER
              }
              keyPath={sortFieldFilterRequestKeyPath}
              onKeyPathChange={setSortFieldFilterRequestKeyPath}
              required
            />
          )}
          <JSONSchemaConverterFormField
            dataTestID="field-pagination-config-sort-field-filter-request-format"
            currentValue={sortFieldRequestFormatText}
            setValue={(schema) => setSortFieldRequestFormatText(schema)}
            title="Format of value in request used for sorting"
            subtitle="This lets us know if there’s any special formatting before inserting the sorting value into the request. By default, we insert the sorting value into the key/key path you defined above. Otherwise, we insert the JSON format you specify here into the key/key path. Use the SORT_FIELD_FILTER_VALUE placeholder string to indicate where the value should be inserted."
            numRows={5}
            className="mt-5"
          />
          <KeyPathFieldHeader
            dataTestID="field-pagination-config-sort-field-filter-response-key-path"
            className="mt-5"
            title="Key path to field in response used for sorting"
            subtitle="Where in the response body is the last sorted result identifier located? So that the value can be inserted into the sort field request location. For example, the identifier of the last item could live in the sorted results array under the key path “results[ ]” > “id”. You would enter “results,id” in this field accordingly."
            hasSource={false}
            placeholder={
              sortFieldFilterResponseKeyPath?.length
                ? KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER
                : KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER
            }
            keyPath={sortFieldFilterResponseKeyPath}
            onKeyPathChange={setSortFieldFilterResponseKeyPath}
            required
          />
        </div>
      )}
    </div>
  );
};

export default memo(SortInformationSetupOptions);
