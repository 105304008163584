import { useState } from "react";
import {
  APIEndpointParameterMapping,
  SubFilterKeyPath,
  ValueTransformation,
} from "../../../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import { Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import { AlertOctagon } from "lucide-react";
import CompositeFilterMappingKeyModal from "./CompositeFilterMappingKeyModal";

interface Props {
  apiEndpointParameterMapping: APIEndpointParameterMapping;
  updateValueTransformation: (newValueTransformation: ValueTransformation | null) => void;
  isDisabled?: boolean;
}

const CompositeFilterMapping = ({
  apiEndpointParameterMapping,
  updateValueTransformation,
  isDisabled,
}: Props) => {
  const [isCompositeFilterKeyModalOpen, setIsCompositeFilterKeyModalOpen] = useState<boolean>(
    false
  );
  const compositeFilterMappingSubFilterKey: SubFilterKeyPath | undefined | null =
    apiEndpointParameterMapping.value_transformation?.composite_filter_mapping_information
      ?.sub_filter_key_path;

  const handleSaveKeyForCompositeFilterMapping = (keyName: string[] | undefined | null) => {
    let newValueTransformation: ValueTransformation | null = null;
    if (apiEndpointParameterMapping.value_transformation) {
      newValueTransformation = {
        ...apiEndpointParameterMapping.value_transformation,
        composite_filter_mapping_information: keyName
          ? {
              sub_filter_key_path: keyName,
            }
          : null,
      };
    } else {
      newValueTransformation = {
        prefix: null,
        suffix: null,
        deprecated_default_value: null,
        format_string: null,
        datetime_format: null,
        list_transformation_information: null,
        composite_filter_mapping_information: keyName
          ? {
              sub_filter_key_path: keyName,
            }
          : null,
      };
    }
    updateValueTransformation(newValueTransformation);
  };

  return (
    <div className="flex flex-row items-center space-x-2">
      {/** ICON TO SHOW NESTING */}
      <div className="flex flex-row items-start h-full">
        <div className="w-4 h-4 rounded-bl border-l-[1px] border-b-[1px] border-gray-30"></div>
      </div>
      {/** INFORMATION ON COMPOSITE FILTER SUB-KEY PATH */}
      <Text variant="title-sm">Key path in composite filter</Text>
      {compositeFilterMappingSubFilterKey && compositeFilterMappingSubFilterKey?.length > 0 ? (
        <div className="flex flex-row items-center space-x-2">
          <Text variant="md-mono">{compositeFilterMappingSubFilterKey.join(",")}</Text>
          <Button
            onClick={() => setIsCompositeFilterKeyModalOpen(true)}
            size="sm"
            variant={ButtonVariant.TextBlue}
            disabled={isDisabled}
          >
            Edit
          </Button>
        </div>
      ) : (
        <div className="flex flex-row items-center space-x-2">
          <AlertOctagon size={12} className="text-red-50" />
          <Button
            onClick={() => setIsCompositeFilterKeyModalOpen(true)}
            size="sm"
            variant={ButtonVariant.TextBlue}
            disabled={isDisabled}
          >
            Configure
          </Button>
        </div>
      )}
      {/** MODAL TO CONFIGURE */}
      <CompositeFilterMappingKeyModal
        open={isCompositeFilterKeyModalOpen}
        onClose={() => setIsCompositeFilterKeyModalOpen(false)}
        onSave={(keyName: string[] | undefined | null) =>
          handleSaveKeyForCompositeFilterMapping(keyName)
        }
        compositeFilterMappingSubFilterKey={compositeFilterMappingSubFilterKey}
      />
    </div>
  );
};

export default CompositeFilterMapping;
