import ReadmePanel from "../../shared/readme/ReadmePanel";
import {
  fetchMappingTestReadme,
  updateMappingTestReadme,
} from "../utils/MappingTestEditorAPIClient";

interface Props {
  mappingTestID: string;
}

const MappingTestEditorReadmePanel = ({ mappingTestID }: Props) => {
  return mappingTestID ? (
    <ReadmePanel
      fetchReadme={(props) => fetchMappingTestReadme({ mappingTestID, ...props })}
      updateReadme={(props) => updateMappingTestReadme({ mappingTestID, ...props })}
    />
  ) : (
    <></>
  );
};

export default MappingTestEditorReadmePanel;
