import { Card, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { JSONObjectSchema, SchemaTypes, BlueprintOperationType } from "../../../models/Blueprints";
import BlueprintValidationEditorObjectFieldSet from "./BlueprintValidationEditorObjectFieldSet";
import { BlueprintValidationActions } from "./BlueprintValidationEditorUtils";
import CenteredVerticalLineCol from "./CenteredVerticalLineCol";
import { palette } from "../../../styles/theme/colors";
import { List } from "lucide-react";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";

const CardBody = styled(Card.Body)`
  padding: 0px;
`;

const IconContainer = styled.div.attrs({
  className: "avatar-sm avatar-title rounded-circle",
})`
  margin: 24px 25px;
  color: ${palette.black};
`;

const Title = styled(DeprecatedH4)`
  font-size: 20px;
  line-height: 28px;
  color: ${palette.black};
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
`;

const CardHeaderRow = styled(Row).attrs({ className: "flex-nowrap ml-0 mr-0" })`
  border-bottom: 1px solid ${palette.border};
`;

const MaybeCenteredVerticalLineCol = styled(CenteredVerticalLineCol)<{ $showLine: boolean }>`
  background-size: 1px 50%;
  background-position: center 44px;
  padding-left: 0;
  padding-right: 0;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
`;

type Props = {
  schema: JSONObjectSchema;
  schemaType: SchemaTypes;
  validationActions: BlueprintValidationActions;
  operationType: undefined | string;
};

const MinWidthCol = styled(Col)`
  min-width: 300px;
`;

const BlueprintValidationEditorCard = ({
  schema,
  schemaType,
  validationActions,
  operationType,
}: Props) => {
  const isFunctionalBP = operationType === BlueprintOperationType.FUNCTIONAL;
  const objTitle = !isFunctionalBP
    ? "Model"
    : schemaType === SchemaTypes.parameter_schema
    ? "Parameters"
    : "Return Values";
  return (
    <MinWidthCol>
      <Card>
        <CardBody>
          <CardHeaderRow>
            <MaybeCenteredVerticalLineCol>
              <IconContainer>
                <List size={"13px"} />
              </IconContainer>
            </MaybeCenteredVerticalLineCol>
            <Col className="my-auto pr-9">
              <Row>
                <Col className="d-flex pl-0 my-auto">
                  <Title className="mb-0">{objTitle}</Title>
                </Col>
              </Row>
            </Col>
          </CardHeaderRow>
          <BlueprintValidationEditorObjectFieldSet
            fieldKey={"model"}
            level={1}
            objectJSONSchema={
              schema.properties.model
                ? (schema.properties.model as JSONObjectSchema)
                : (schema as JSONObjectSchema)
            }
            validationActions={validationActions}
            traversalPath={
              isFunctionalBP || !schema.properties.model ? [] : ["properties", "model"]
            }
            operationType={operationType}
          />
        </CardBody>
      </Card>
    </MinWidthCol>
  );
};

export default BlueprintValidationEditorCard;
