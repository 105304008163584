import { useState } from "react";
import { Button } from "react-bootstrap";
import {
  BlueprintDataTransformStep,
  BlueprintParameterValue,
  BlueprintParameterValueProcedureArray,
  BlueprintParameterValueType,
  BlueprintProcedure,
  BlueprintProcedureType,
} from "../../../models/Blueprints";
import max from "lodash/max";
import useBlueprintContext from "../context/useBlueprintContext";
import {
  generateReturnSchemaFromProcedureArray,
  initializeProcedureForProcedureType,
} from "../utils/BlueprintDataTransformUtils";
import { getSchemaForParameterValue } from "../utils/BlueprintEditorUtils";
import DropdownFormField from "./DropdownFormField";
import JSONSchemaTreeDiagram from "../../shared/JSONSchemaTreeDiagram";
import ClickableContainer from "../../shared/ClickableContainer";
import { MinusCircle } from "lucide-react";
import NestedTypeaheadFormField from "./NestedTypeaheadFormField";
import { HeaderPretitle } from "../../shared/text/MergeText";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";

const BlueprintEditorRightPanelDataTransformForm = () => {
  const { blueprint, selectedStep, updateStepParameterValue } = useBlueprintContext();
  const step = selectedStep as BlueprintDataTransformStep;
  const [isAddingNewStepType, setIsAddingNewStepType] = useState(false);
  const [currentSelectedProcedureType, setCurrentSelectedProcedureType] = useState<
    BlueprintProcedureType
  >(BlueprintProcedureType.ADD_KEY_VALUE_PAIR_TO_OBJECT);

  const procedureArray =
    (step.parameter_values.procedures as BlueprintParameterValueProcedureArray)?.procedure_array ??
    [];

  const updateProcedures = (newProcedureArray: Array<BlueprintProcedure>) =>
    updateStepParameterValue(step, "procedures", {
      value_type: BlueprintParameterValueType.procedureArray,
      procedure_array: newProcedureArray,
    });

  const nextProcedureIndex =
    (max(procedureArray.map((procedure) => Number(procedure.id.slice(9)))) ?? 0) + 1;

  const addProcedure = (procedureType: BlueprintProcedureType) => {
    updateProcedures([
      ...procedureArray,
      initializeProcedureForProcedureType(procedureType, nextProcedureIndex),
    ]);
  };

  const getIndexForProcedureID = (procedureID: string): number => {
    return procedureArray.findIndex((procedure) => procedure.id === procedureID);
  };

  const updateExistingProcedure = (newProcedure: BlueprintProcedure) => {
    const index = getIndexForProcedureID(newProcedure.id);

    updateProcedures([
      ...procedureArray.slice(0, index),
      newProcedure,
      ...procedureArray.slice(index + 1),
    ]);
  };

  const updateProcedureParameterValue = (
    procedure: BlueprintProcedure,
    parameterKey: string,
    parameterValue: BlueprintParameterValue | null
  ) => {
    updateExistingProcedure({
      ...procedure,
      parameter_values: {
        ...procedure.parameter_values,
        [parameterKey]: parameterValue,
      },
      parameter_schema: {
        type: "object",
        properties: {
          ...procedure.parameter_schema.properties,
          [parameterKey]: getSchemaForParameterValue(blueprint, parameterValue, procedureArray),
        },
      },
    });
  };

  const deleteProcedure = (deletedProcedure: BlueprintProcedure) => {
    const index = getIndexForProcedureID(deletedProcedure.id);

    updateProcedures([...procedureArray.slice(0, index), ...procedureArray.slice(index + 1)]);
  };

  return (
    <form>
      {procedureArray.map((procedure) => (
        <div key={procedure.id}>
          <div className="mb-1.5 d-flex align-items-center justify-content-between">
            <DeprecatedH4 className="mb-0">
              {procedure.procedure_type in BlueprintProcedureType
                ? ""
                : "[DEPRECATED PROCEDURE] - "}
              {procedure.id + " - " + procedure.procedure_type}
            </DeprecatedH4>
            <div className="ml-1.5">
              <ClickableContainer onClick={() => deleteProcedure(procedure)}>
                <MinusCircle className="red" strokeWidth={1.5} />
              </ClickableContainer>
            </div>
          </div>
          <HeaderPretitle className="my-3">Parameters</HeaderPretitle>
          {Object.keys(procedure.parameter_schema?.properties ?? {}).map((key) => {
            return (
              <NestedTypeaheadFormField
                key={key}
                onChange={(newValue: BlueprintParameterValue | null) => {
                  updateProcedureParameterValue(procedure, key, newValue);
                }}
                currentParameterValue={procedure.parameter_values[key]}
                procedureIndex={getIndexForProcedureID(procedure.id)}
                title={key}
                subtitle={"Set the value for the argument."}
              />
            );
          })}
          <hr />
        </div>
      ))}
      {isAddingNewStepType ? (
        <>
          <DropdownFormField
            choices={Object.values(BlueprintProcedureType).map((procedureType) => ({
              id: procedureType,
              name: procedureType,
            }))}
            currentValue={currentSelectedProcedureType}
            placeholder={"Select a procedure"}
            title={"Procedure"}
            onChange={(e) => setCurrentSelectedProcedureType(e.target.value)}
          />
          <Button
            className="mt-0 mb-3 btn-block"
            onClick={() => {
              addProcedure(currentSelectedProcedureType);
              setIsAddingNewStepType(false);
            }}
          >
            Confirm
          </Button>
        </>
      ) : (
        <Button className="mt-0 mb-3 btn-block" onClick={() => setIsAddingNewStepType(true)}>
          Add New Procedure
        </Button>
      )}
      {procedureArray.length > 0 && (
        <>
          <hr />
          <HeaderPretitle>Response</HeaderPretitle>
          <JSONSchemaTreeDiagram
            stepID={step.id}
            jsonSchema={generateReturnSchemaFromProcedureArray(procedureArray)}
          />
        </>
      )}
    </form>
  );
};

export default BlueprintEditorRightPanelDataTransformForm;
