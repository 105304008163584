import { Alert, Text } from "@merge-api/merge-javascript-shared";
import { Info } from "lucide-react";

interface PaginationConfigurationExplainProps {
  className?: string;
}
const PaginationConfigurationExplain = ({ className }: PaginationConfigurationExplainProps) => {
  return (
    <Alert
      title="What is a pagination configuration?"
      color="gray"
      icon={<Info size={16} />}
      className={className}
    >
      <Text>
        A pagination configuration in API endpoints is a method used to divide large sets of data
        returned by API endpoints into smaller, manageable chunks to optimize performance and user
        experience. It involves parameters like page size and page number, allowing users to
        navigate through data efficiently by requesting specific subsets of information at a time.
      </Text>
    </Alert>
  );
};

export default PaginationConfigurationExplain;
