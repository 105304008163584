import { useState } from "react";
import { Route, Switch } from "react-router-dom";
import {
  getIntegrationBuilderCreateNewPaginationConfigurationPathForId,
  getIntegrationBuilderPaginationConfigurationsForId,
  getIntegrationBuilderSinglePaginationConfigurationPathForId,
} from "../../../router/RouterUtils";
import { PaginationConfigurationIntegrationBuilder } from "../utils/Entities";
import BasePaginationConfigurationSetup from "./components/PaginationConfigurationSetup";
import PaginationConfigurationsTable from "./components/PaginationConfigurationsTable";

interface PaginationConfigurationProps {
  integrationID: string;
}

const PaginationConfigurations = ({ integrationID }: PaginationConfigurationProps) => {
  const [paginationConfigurations, setPaginationConfigurations] = useState<
    PaginationConfigurationIntegrationBuilder[] | undefined
  >([]);
  const [isLoadingPaginationConfigurations, setIsLoadingPaginationConfigurations] = useState(true);

  return (
    <div>
      <Switch>
        <Route
          exact
          path={getIntegrationBuilderPaginationConfigurationsForId(integrationID)}
          render={() => (
            <PaginationConfigurationsTable
              integrationID={integrationID}
              paginationConfigurations={paginationConfigurations}
              setPaginationConfigurations={setPaginationConfigurations}
              isLoadingPaginationConfigurations={isLoadingPaginationConfigurations}
              setIsLoadingPaginationConfigurations={setIsLoadingPaginationConfigurations}
            />
          )}
        />
        <Route
          exact
          path={getIntegrationBuilderCreateNewPaginationConfigurationPathForId(integrationID)}
          render={() => (
            <BasePaginationConfigurationSetup
              integrationID={integrationID}
              paginationConfigurations={paginationConfigurations}
              setPaginationConfigurations={setPaginationConfigurations}
              isLoadingPaginationConfigurations={isLoadingPaginationConfigurations}
              setIsLoadingPaginationConfigurations={setIsLoadingPaginationConfigurations}
            />
          )}
        />
        <Route
          exact
          path={getIntegrationBuilderSinglePaginationConfigurationPathForId(integrationID)}
          render={() => (
            <BasePaginationConfigurationSetup
              integrationID={integrationID}
              paginationConfigurations={paginationConfigurations}
              setPaginationConfigurations={setPaginationConfigurations}
              isLoadingPaginationConfigurations={isLoadingPaginationConfigurations}
              setIsLoadingPaginationConfigurations={setIsLoadingPaginationConfigurations}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default PaginationConfigurations;
