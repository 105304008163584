import useScraperContext from "./context/useScraperContext";
import { useMemo, useRef } from "react";

import { useWindowSize } from "../shared/hooks/useWindowSize";
import { getScraperCanvasConfiguration } from "./utils/ScraperUtils";
import ScraperStepCard from "./ScraperStepCard";
import ScraperGhostStepCard from "./ScraperGhostStepCard";
import {
  StepPlacementType,
  getXIndexForStep,
  getYIndexForStep,
} from "../blueprint-editor/utils/BlueprintCanvasUtils";

import {
  canStepHaveMoreThanTwoPaths,
  getAllTerminalStepsFromStep,
  getNumberOfPathsForStep,
  getParentStepForSubstep,
  getPathKeyForStep,
  getPredecessorStepForStep,
  getStepForStepID,
  checkConditionForTraversalPath,
  getEnforceStepTraversalPathForStepID,
} from "../blueprint-editor/utils/BlueprintEditorUtils";
import { CARD_X_STEP, CARD_Y_STEP } from "../blueprint-editor/canvas/utils/constants";

const ScraperCanvas = () => {
  const { scraper, selectedStep, disableArrows } = useScraperContext();
  const { width } = useWindowSize();
  // This positions the blueprint symmetrically in the canvas view.

  const canvasRef = useRef<HTMLDivElement>(null);
  const canvasConfiguration = useMemo(() => getScraperCanvasConfiguration(scraper, selectedStep), [
    scraper,
    selectedStep,
  ]);

  const cardXStart = Math.max(
    ((width ?? 0) - 1050 - canvasConfiguration.totalX * CARD_X_STEP) / 2,
    25
  );
  const canvas = disableArrows ? (
    <div className="blueprint-canvas h-85">
      {Object.entries(canvasConfiguration.stepPlacements).map(([stepID, stepPlacement]) => {
        return (
          <div
            key={stepID}
            style={{
              position: "absolute",
              left: `${cardXStart + stepPlacement.xIndex * CARD_X_STEP}px`,
              top: `${
                stepPlacement.yIndex * CARD_Y_STEP +
                (stepPlacement.stepPlacementType === StepPlacementType.EXISTING ? 0 : 40)
              }px`,
              transition: "all 0.5s",
            }}
          >
            {stepPlacement.stepPlacementType === StepPlacementType.GHOST ? (
              <ScraperGhostStepCard ghostStep={stepPlacement.step} />
            ) : (
              <ScraperStepCard step={stepPlacement.step} />
            )}
          </div>
        );
      })}
    </div>
  ) : (
    <div className="blueprint-canvas h-85" ref={canvasRef}>
      <div>
        {Object.entries(canvasConfiguration.stepPlacements).map(([stepID, stepPlacement]) => {
          const currentStep = getStepForStepID(scraper, stepID);
          const XArrowStart = cardXStart + stepPlacement.xIndex * CARD_X_STEP + 175;
          const YArrowStart = stepPlacement.yIndex * CARD_Y_STEP + 14;
          const YArrowArcStart = YArrowStart - 25;
          const controlPointX = XArrowStart;
          const controlPointY = YArrowStart - 35;
          const YArrowArcEnd = YArrowStart - 45;

          if (currentStep === null) {
            return <></>;
          }

          const parentStep = getParentStepForSubstep(scraper, currentStep);
          const predecessorStep = getPredecessorStepForStep(scraper, currentStep);

          const parentPath = getEnforceStepTraversalPathForStepID(scraper, currentStep.id).slice(
            0,
            -1
          );

          if (
            checkConditionForTraversalPath(scraper, parentPath, (step) => {
              return Boolean(step.hasCollapsedSubsteps);
            })
          ) {
            return <></>;
          }

          if (predecessorStep === null && parentStep !== null) {
            // child
            const XOffSetFromParent =
              getXIndexForStep(scraper, currentStep) - getXIndexForStep(scraper, parentStep);

            const XArrowArcEnd = XArrowStart + (XOffSetFromParent > 0 ? -10 : 10);
            const XArrowArcStart =
              XArrowStart - XOffSetFromParent * 180 + (XOffSetFromParent > 0 ? 10 : -10);
            const controlPointXX = XArrowStart - XOffSetFromParent * 180;

            const numberOfPathsForParent = getNumberOfPathsForStep(parentStep);

            return (
              <div style={{ position: "absolute" }} key={stepID}>
                <div
                  style={{
                    position: "absolute",
                    left: `${cardXStart + stepPlacement.xIndex * CARD_X_STEP}px`,
                    top: `${stepPlacement.yIndex * CARD_Y_STEP - 35}px`,
                    transition: "all 0.5s",
                  }}
                >
                  <svg width="360" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      className="card-bottom"
                      d="M 175 50 L 180.773503 40 L 169.226497 40 L 175 50"
                      fill="#C9D0DA"
                    ></path>
                  </svg>
                </div>
                {XOffSetFromParent === 0 ? (
                  <svg
                    width={canvasRef.current?.scrollWidth}
                    height={canvasRef.current?.scrollHeight}
                  >
                    <line // vertical line
                      x1={XArrowStart}
                      y1={YArrowStart}
                      x2={XArrowStart}
                      y2={YArrowStart - 73}
                      stroke="#C9D0DA"
                      strokeWidth="2"
                    ></line>
                  </svg>
                ) : (
                  <svg
                    width={canvasRef.current?.scrollWidth}
                    height={canvasRef.current?.scrollHeight}
                  >
                    <line // small vertical line from currentStep to horizontal line
                      x1={XArrowStart}
                      y1={YArrowStart}
                      x2={XArrowStart}
                      y2={YArrowArcStart}
                      stroke="#C9D0DA"
                      strokeWidth="2"
                      style={{ zIndex: 0 }}
                    ></line>
                    <path
                      d={
                        "M" +
                        XArrowStart +
                        " " +
                        YArrowArcStart +
                        " " +
                        "Q" +
                        " " +
                        controlPointX +
                        " " +
                        controlPointY +
                        " " +
                        XArrowArcEnd +
                        " " +
                        controlPointY
                      }
                      fill="none"
                      stroke="#C9D0DA"
                      strokeWidth="2"
                    ></path>
                    <line // horizontal line
                      x1={XArrowArcEnd}
                      y1={controlPointY}
                      x2={XArrowArcStart}
                      y2={controlPointY}
                      stroke="#C9D0DA"
                      strokeWidth="2"
                      style={{ zIndex: 0 }}
                    />
                    <path
                      d={
                        "M" +
                        XArrowArcStart +
                        " " +
                        controlPointY +
                        " " +
                        "Q" +
                        controlPointXX +
                        " " +
                        controlPointY +
                        " " +
                        controlPointXX +
                        " " +
                        YArrowArcEnd
                      }
                      fill="none"
                      stroke="#C9D0DA"
                      strokeWidth="2"
                    ></path>
                    <line // small vertical line from parentStep to horizonal line
                      x1={controlPointXX}
                      y1={YArrowArcEnd}
                      x2={controlPointXX}
                      y2={YArrowStart - 73}
                      stroke="#C9D0DA"
                      strokeWidth="2"
                      style={{ zIndex: 0 }}
                    />
                  </svg>
                )}

                {numberOfPathsForParent > 2 ||
                (numberOfPathsForParent > 0 && canStepHaveMoreThanTwoPaths(parentStep)) ? (
                  <div
                    style={{
                      position: "absolute",
                      left: `${cardXStart + stepPlacement.xIndex * CARD_X_STEP + 141}px`,
                      top: `${stepPlacement.yIndex * CARD_Y_STEP - 17}px`,
                      transition: "all 0.5s",
                    }}
                  >
                    <button className="pathkey-button">
                      {getPathKeyForStep(parentStep, currentStep)}
                    </button>
                  </div>
                ) : numberOfPathsForParent > 1 ? (
                  <div
                    style={{
                      position: "absolute",
                      left:
                        XOffSetFromParent < 0
                          ? `${cardXStart + stepPlacement.xIndex * CARD_X_STEP + 283}px`
                          : `${cardXStart + stepPlacement.xIndex * CARD_X_STEP}px`,
                      top: `${stepPlacement.yIndex * CARD_Y_STEP - 32}px`,
                      transition: "all 0.5s",
                    }}
                  >
                    <button className="pathkey-button">
                      {getPathKeyForStep(parentStep, currentStep)}
                    </button>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            );
          }

          if (predecessorStep !== null && predecessorStep.paths !== undefined) {
            const terminalSteps = getAllTerminalStepsFromStep(scraper, predecessorStep);
            let XOffSet;
            let YOffSet;

            return Object.entries(terminalSteps ?? {}).map(([_, terminalStep]) => {
              XOffSet =
                getXIndexForStep(scraper, currentStep) - getXIndexForStep(scraper, terminalStep);
              YOffSet =
                getYIndexForStep(scraper, currentStep) - getYIndexForStep(scraper, terminalStep);

              const XArrowArcEnd = XArrowStart + (XOffSet > 0 ? -10 : 10);
              const XArrowArcStart = XArrowStart - XOffSet * 180 + (XOffSet > 0 ? 10 : -10);
              const controlPointXX = XArrowStart - XOffSet * 180;

              return (
                <div style={{ position: "absolute" }} key={predecessorStep.id + stepID}>
                  <div
                    style={{
                      position: "absolute",
                      left: `${cardXStart + stepPlacement.xIndex * CARD_X_STEP}px`,
                      top: `${stepPlacement.yIndex * CARD_Y_STEP - 35}px`,
                      transition: "all 0.5s",
                    }}
                  >
                    <svg width="360" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        className="card-bottom"
                        d="M 175 50 L 180.773503 40 L 169.226497 40 L 175 50"
                        fill="#C9D0DA"
                      ></path>
                    </svg>
                  </div>
                  {XOffSet === 0 ? (
                    <svg
                      width={canvasRef.current?.scrollWidth}
                      height={canvasRef.current?.scrollHeight}
                    >
                      <line // vertical line
                        x1={XArrowStart}
                        y1={YArrowStart}
                        x2={XArrowStart}
                        y2={YArrowStart - 73}
                        stroke="#C9D0DA"
                        strokeWidth="2"
                      ></line>
                    </svg>
                  ) : (
                    <svg
                      width={canvasRef.current?.scrollWidth}
                      height={canvasRef.current?.scrollHeight}
                    >
                      <line // small vertical line from currentStep to horizontal line
                        x1={XArrowStart}
                        y1={YArrowStart}
                        x2={XArrowStart}
                        y2={YArrowArcStart}
                        stroke="#C9D0DA"
                        strokeWidth="2"
                      ></line>
                      <path
                        d={
                          "M" +
                          XArrowStart +
                          " " +
                          YArrowArcStart +
                          " " +
                          "Q" +
                          controlPointX +
                          " " +
                          controlPointY +
                          " " +
                          XArrowArcEnd +
                          " " +
                          controlPointY
                        }
                        fill="none"
                        stroke="#C9D0DA"
                        strokeWidth="2"
                      ></path>
                      <line // horizontal line
                        x1={XArrowArcEnd}
                        y1={controlPointY}
                        x2={XArrowArcStart}
                        y2={controlPointY}
                        stroke="#C9D0DA"
                        strokeWidth="2"
                      />
                      <path
                        d={
                          "M" +
                          XArrowArcStart +
                          " " +
                          controlPointY +
                          " " +
                          "Q" +
                          controlPointXX +
                          " " +
                          controlPointY +
                          " " +
                          controlPointXX +
                          " " +
                          YArrowArcEnd
                        }
                        fill="none"
                        stroke="#C9D0DA"
                        strokeWidth="2"
                      ></path>
                      <line // vertical line from parentStep to horizonal line
                        x1={controlPointXX}
                        y1={YArrowArcEnd}
                        x2={controlPointXX}
                        y2={YArrowStart - YOffSet * 73 - (YOffSet - 1) * 77}
                        stroke="#C9D0DA"
                        strokeWidth="2"
                      />
                    </svg>
                  )}
                </div>
              );
            });
          }

          if (predecessorStep != null || (predecessorStep == null && parentStep == null)) {
            return (
              <div style={{ position: "absolute" }} key={stepID}>
                <div
                  style={{
                    position: "absolute",
                    left: `${cardXStart + stepPlacement.xIndex * CARD_X_STEP}px`,
                    top: `${stepPlacement.yIndex * CARD_Y_STEP - 35}px`,
                    transition: "all 0.5s",
                  }}
                >
                  <svg width="360" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      className="card-bottom"
                      d="M 175 50 L 180.773503 40 L 169.226497 40 L 175 50"
                      fill="#C9D0DA"
                    ></path>
                  </svg>
                </div>
                <svg
                  width={canvasRef.current?.scrollWidth}
                  height={canvasRef.current?.scrollHeight}
                >
                  <line // vertical line
                    x1={XArrowStart}
                    y1={YArrowStart}
                    x2={XArrowStart}
                    y2={YArrowStart - 73}
                    stroke="#C9D0DA"
                    strokeWidth="2"
                  ></line>
                </svg>
              </div>
            );
          }
          return <></>;
        })}
      </div>

      <div>
        {Object.entries(canvasConfiguration.stepPlacements).map(([stepID, stepPlacement]) => {
          return (
            <div
              key={stepID}
              style={{
                position: "absolute",
                left: `${cardXStart + stepPlacement.xIndex * CARD_X_STEP}px`,
                top: `${
                  stepPlacement.yIndex * CARD_Y_STEP +
                  (stepPlacement.stepPlacementType === StepPlacementType.EXISTING ? 0 : 40)
                }px`,
                transition: "all 0.5s",
              }}
            >
              {stepPlacement.stepPlacementType === StepPlacementType.GHOST ? (
                <ScraperGhostStepCard ghostStep={stepPlacement.step} />
              ) : (
                <ScraperStepCard step={stepPlacement.step} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
  return canvas;
};

export default ScraperCanvas;
