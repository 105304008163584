import { ButtonVariant, Dialog, Text, Link } from "@merge-api/merge-javascript-shared";
import { useCustomerLinkedAccountContext } from "../context/CustomerLinkedAccountAccessContext";
import { useHistory } from "react-router-dom";
import { navigateToIntegration } from "../../../router/RouterUtils";

const RevokeAccessSessionDialog = () => {
  const history = useHistory();
  const {
    currentAccessSession,
    showRevokeSessionModal,
    setShowRevokeSessionModal,
    revokeLinkedAccountAccess,
  } = useCustomerLinkedAccountContext();

  // Render a link to the associated integration if it exists
  const renderIntegrationName = () => {
    if (currentAccessSession?.integration_name) {
      return (
        <Link onClick={() => navigateToIntegration(history, currentAccessSession.integration_id)}>
          {currentAccessSession.integration_name}
        </Link>
      );
    } else {
      return "another integration";
    }
  };

  const handlePrimaryButtonClick = () => {
    revokeLinkedAccountAccess();
    setShowRevokeSessionModal(false);
  };

  return (
    <Dialog
      title="Existing Session"
      open={showRevokeSessionModal}
      onClose={() => setShowRevokeSessionModal(false)}
      variant="sm"
      primaryButtonVariant={ButtonVariant.DangerFilled}
      primaryButtonText="End session"
      onPrimaryButtonClick={handlePrimaryButtonClick}
    >
      <Text>
        You already have an existing session in
        {renderIntegrationName()}. You can clear the request if you’re done using the Linked Account
        or wait until the session is over
      </Text>
    </Dialog>
  );
};

export default RevokeAccessSessionDialog;
