import { useHistory } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import { navigateToMappingTestEditor } from "../../../router/RouterUtils";
import MergeModal from "../../shared/MergeModal";
import { useState } from "react";
import DropdownFormField from "../../blueprint-editor/right-panel/DropdownFormField";
import { createMappingTestCase } from "../../mapping-tests/utils/MappingTestFetchUtils";
import { showErrorToast } from "../../shared/Toasts";
import { MappingTestExpectedResult, MappingTestMeta } from "../../../models/MappingTests";
import InputFormField from "../../blueprint-editor/right-panel/InputFormField";
import { getReadableNameForExpectedResult } from "../../mapping-tests/utils/MappingTestBuildingUtils";

type Props = {
  categories: Array<string>;
  endpointCollectionID: string;
  integrationID: string;
  isShown: boolean;
  onHide: () => void;
};

const CreateMappingTestCaseModal = ({
  integrationID,
  endpointCollectionID,
  isShown,
  onHide,
}: Props) => {
  const [name, setName] = useState<string>("");
  const [expectedResult, setExpectedResult] = useState<MappingTestExpectedResult>(
    MappingTestExpectedResult.SUCCESS
  );

  const history = useHistory();

  const addMappingTest = () => {
    createMappingTestCase({
      endpointCollectionID,
      expectedResult,
      name,
      onResponse: (data: MappingTestMeta) =>
        navigateToMappingTestEditor(history, integrationID, data.id),
      onError: () => showErrorToast("Failed to create test"),
    });
  };

  const isSavingDisabled = !name;

  return (
    <MergeModal
      show={isShown}
      onHide={onHide}
      title={"Create Test"}
      bodyClassName="overflow-hidden"
    >
      <InputFormField
        title="Name"
        subtitle="The name of the test."
        placeholder="Test Name"
        onChange={(name) => setName(name)}
        currentValue={name}
      />
      <DropdownFormField
        title="Expected Result"
        subtitle="The expected result of the test."
        placeholder="Select ExpectedResult"
        onChange={(e) => setExpectedResult(e.target.value)}
        currentValue={expectedResult}
        choices={Object.values(MappingTestExpectedResult).map((expectedResult) => ({
          name: getReadableNameForExpectedResult(expectedResult),
          id: expectedResult,
        }))}
      />
      <Row className="mt-6">
        <Col>
          <Button className="btn-block" variant="outline-danger" onClick={onHide}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button className="btn-block" disabled={isSavingDisabled} onClick={addMappingTest}>
            Create Test Case
          </Button>
        </Col>
      </Row>
    </MergeModal>
  );
};

export default CreateMappingTestCaseModal;
