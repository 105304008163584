import { Badge, Text } from "@merge-api/merge-javascript-shared";
import {
  LinkedAccountTestSuiteExecution,
  MappingTestExecutionResult,
} from "../../../../../models/MappingTests";
import { navigateToIndividualTestSuite } from "../../../../../router/RouterUtils";
import { useHistory } from "react-router-dom";

type Props = {
  integrationID: string;
  testSuiteExecution: LinkedAccountTestSuiteExecution;
};

const TestSuiteExecutionContainerTitleV2 = ({ integrationID, testSuiteExecution }: Props) => {
  const history = useHistory();
  const { linked_account_mapping_test_suite } = testSuiteExecution;

  const numberOfFailures = testSuiteExecution.mapping_test_executions.reduce((count, execution) => {
    if (execution.result === MappingTestExecutionResult.FAILURE) {
      return count + 1;
    } else {
      return count;
    }
  }, 0);

  return (
    <div
      className="flex flex-row items-center justify-between w-100 cursor-pointer"
      onClick={() => {
        navigateToIndividualTestSuite(
          history,
          integrationID,
          testSuiteExecution.linked_account_mapping_test_suite.id,
          true
        );
      }}
    >
      <div className="flex flex-col">
        <div className="flex flex-row items-center h-fit w-full space-x-2">
          <Text variant="h6">
            {linked_account_mapping_test_suite?.end_user_organization_name}{" "}
            {linked_account_mapping_test_suite.linked_account}
          </Text>
          {numberOfFailures > 0 && (
            <Badge className="mr-5" color="red">{`${numberOfFailures} failures`}</Badge>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestSuiteExecutionContainerTitleV2;
