import { useEffect, useState } from "react";
import {
  IntegrationValidatorRuleOverride,
  IntegrationValidatorRuleResult,
  IntegrationValidatorRuleResultType,
  IntegrationValidatorRuleSeverityType,
  OverrideRuleRequestInput,
  ValidatedStagedComponent,
} from "../types";
import { StagedComponentContext } from "./StagedComponentContext";
import useRuleOverrideActions from "../hooks/useRuleOverrideActions";
import { usePublishModuleContext } from "./PublishModuleContext";

// Context provider component
const StagedComponentContextProvider = ({
  children,
  stagedComponent: initialStagedComponent,
  integrationID,
}: {
  children: JSX.Element;
  stagedComponent: ValidatedStagedComponent;
  integrationID: string;
}) => {
  const { addBlockingRule, removeBlockingRule } = usePublishModuleContext();
  // Manage the stagedComponent state in the provider
  const [component, setComponent] = useState<ValidatedStagedComponent>(initialStagedComponent);
  const [passedRules, setPassedRules] = useState<IntegrationValidatorRuleResult[]>([]);
  const [newBlockingRules, setNewBlockingRules] = useState<IntegrationValidatorRuleResult[]>([]);
  const [newWarningRules, setNewWarningRules] = useState<IntegrationValidatorRuleResult[]>([]);
  const [existingBlockingRules, setExistingBlockingRules] = useState<
    IntegrationValidatorRuleResult[]
  >([]);
  const [existingWarningRules, setExistingWarningRules] = useState<
    IntegrationValidatorRuleResult[]
  >([]);
  const [overriddenRules, setOverriddenRules] = useState<IntegrationValidatorRuleResult[]>([]);

  const { createRuleOverride, deleteRuleOverride } = useRuleOverrideActions();

  const overrideRule = (rule: IntegrationValidatorRuleResult, input: OverrideRuleRequestInput) => {
    rule.is_overridden = true;
    rule.override = {
      rule_id: input.rule_id,
      asana_url: input.asana_url,
      comment: input.comment,
    } as IntegrationValidatorRuleOverride;

    // Make a request to create a rule override in the backend
    createRuleOverride(input);

    // Remove rule from the blocking rules list. This is used to determine if publishing is possible
    removeBlockingRule(rule);

    // Remove the rule from the current rule list and add it to the overridden list
    setPassedRules((prev) => prev.filter((curr_rule) => curr_rule.rule_id !== rule.rule_id));
    setNewBlockingRules((prev) => prev.filter((curr_rule) => curr_rule.rule_id !== rule.rule_id));
    setNewWarningRules((prev) => prev.filter((curr_rule) => curr_rule.rule_id !== rule.rule_id));
    setExistingBlockingRules((prev) =>
      prev.filter((curr_rule) => curr_rule.rule_id !== rule.rule_id)
    );
    setExistingWarningRules((prev) =>
      prev.filter((curr_rule) => curr_rule.rule_id !== rule.rule_id)
    );

    // Add the rule to the overridden list that gets displayed in the UI
    setOverriddenRules((prev) => [...prev, rule]);
  };

  const removeOverride = (rule: IntegrationValidatorRuleResult) => {
    rule.is_overridden = false;
    rule.override = null;

    // Since we are removing an override, we must add the rule back to the blocking rules list if it is a blocking rule that is failing
    if (
      rule.severity === IntegrationValidatorRuleSeverityType.BLOCKING &&
      rule.result === IntegrationValidatorRuleResultType.FAILURE &&
      rule.is_new_failure
    ) {
      addBlockingRule(rule);
    }

    // Make a request to delete the rule override in the backend
    deleteRuleOverride({
      rule_id: rule.rule_id,
      component_id: component.component_id,
      component_version_id: component.component_version_id,
    });

    // Remove the rule from the overridden list and add it back to the current rule list
    setOverriddenRules((prev) => prev.filter((curr_rule) => curr_rule.rule_id !== rule.rule_id));

    // Add the rule to the passedRules list if it is a success result, otherwise add it to the blocking or warning rules list depending on the severity
    if (rule.result === IntegrationValidatorRuleResultType.SUCCESS) {
      setPassedRules((prev) => [...prev, rule]);
    } else {
      if (rule.severity === IntegrationValidatorRuleSeverityType.BLOCKING) {
        if (rule.is_new_failure) {
          setNewBlockingRules((prev) => [...prev, rule]);
        } else {
          setExistingBlockingRules((prev) => [...prev, rule]);
        }
      } else if (rule.severity === IntegrationValidatorRuleSeverityType.WARNING) {
        if (rule.is_new_failure) {
          setNewWarningRules((prev) => [...prev, rule]);
        } else {
          setExistingWarningRules((prev) => [...prev, rule]);
        }
      }
    }
  };

  // Update the passed, blocking, warning, and overridden rules when the component changes
  useEffect(() => {
    setPassedRules(
      component.validator_results.filter(
        (result) =>
          result.result === IntegrationValidatorRuleResultType.SUCCESS && !result.is_overridden
      )
    );
    setNewBlockingRules(
      component.validator_results.filter(
        (result) =>
          result.result === IntegrationValidatorRuleResultType.FAILURE &&
          result.severity === IntegrationValidatorRuleSeverityType.BLOCKING &&
          !result.is_overridden &&
          result.is_new_failure
      )
    );
    setNewWarningRules(
      component.validator_results.filter(
        (result) =>
          result.result === IntegrationValidatorRuleResultType.FAILURE &&
          result.severity === IntegrationValidatorRuleSeverityType.WARNING &&
          !result.is_overridden &&
          result.is_new_failure
      )
    );
    setExistingBlockingRules(
      component.validator_results.filter(
        (result) =>
          result.result === IntegrationValidatorRuleResultType.FAILURE &&
          result.severity === IntegrationValidatorRuleSeverityType.BLOCKING &&
          !result.is_overridden &&
          !result.is_new_failure
      )
    );
    setExistingWarningRules(
      component.validator_results.filter(
        (result) =>
          result.result === IntegrationValidatorRuleResultType.FAILURE &&
          result.severity === IntegrationValidatorRuleSeverityType.WARNING &&
          !result.is_overridden &&
          !result.is_new_failure
      )
    );
    setOverriddenRules(component.validator_results.filter((result) => result.is_overridden));
  }, [component]);

  return (
    <StagedComponentContext.Provider
      value={{
        component,
        setComponent,
        integrationID,
        overrideRule,
        removeOverride,
        passedRules,
        setPassedRules,
        newBlockingRules,
        setNewBlockingRules,
        newWarningRules,
        setNewWarningRules,
        existingBlockingRules,
        setExistingBlockingRules,
        existingWarningRules,
        setExistingWarningRules,
        overriddenRules,
        setOverriddenRules,
      }}
    >
      {children}
    </StagedComponentContext.Provider>
  );
};

export default StagedComponentContextProvider;
