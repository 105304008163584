import FullPageSpinner from "../../shared/layout/FullPageSpinner";
import { useHistory, useParams } from "react-router-dom";
import { TableTitleContainer, TableTitleText } from "../../shared/utils/SharedComponentUtils";
import { AuthConfigMeta } from "../../../models/AuthConfig";
import AuthConfigTable from "./AuthConfigTable";
import { Button, ButtonVariant, Alert, Text } from "@merge-api/merge-javascript-shared";
import { Lightbulb } from "lucide-react";
import { navigateToIntegrationBuilderAuthConfigTable } from "../../../router/RouterUtils";

type RouteParams = {
  integrationID: string;
  operationType: string;
};

type Props = {
  authConfigs: AuthConfigMeta[] | undefined;
  isLoading: boolean;
};

const AuthConfigOverview = ({ authConfigs, isLoading }: Props) => {
  const { integrationID } = useParams<RouteParams>();
  const history = useHistory();

  return (
    <div className="flex flex-column w-full space-y-6">
      <Alert color="blue" icon={<Lightbulb size={16} className="text-blue-40" />}>
        <div className="flex w-full flex-row items-center justify-between">
          <Text variant="h6">
            You can now edit Authentication Configurations with the Integration Builder!
          </Text>
          <Button
            size="sm"
            variant={ButtonVariant.PrimaryBlue}
            onClick={() => navigateToIntegrationBuilderAuthConfigTable(history, integrationID)}
          >
            Navigate to Integration Builder
          </Button>
        </div>
      </Alert>
      <TableTitleContainer>
        <TableTitleText>Authentication Configurations</TableTitleText>
      </TableTitleContainer>
      {isLoading || !authConfigs ? (
        <FullPageSpinner />
      ) : (
        <AuthConfigTable authConfigs={authConfigs} integrationID={integrationID} />
      )}
    </div>
  );
};

export default AuthConfigOverview;
