import { MinusCircle } from "lucide-react";
import { BlueprintParameterValue } from "../../../../models/Blueprints";
import ClickableContainer from "../../../shared/ClickableContainer";
import { BlueprintAvailableParameter } from "../../utils/BlueprintEditorUtils";
import FormField, { FormFieldCommonProps } from "../FormField";
import TypeaheadFormFieldTypeahead from "../TypeaheadFormFieldTypeahead";

interface Props extends FormFieldCommonProps {
  onChange: (options: BlueprintAvailableParameter[]) => void;
  currentParameterValue: BlueprintParameterValue;
  onDelete?: () => void;
}

const CustomFunctionTypeaheadFormField = ({
  currentParameterValue,
  onChange,
  subtitle,
  title,
  onDelete,
}: Props) => {
  return (
    <FormField subtitle={subtitle} title={title} valueType={"any"}>
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">
          <TypeaheadFormFieldTypeahead
            allowConstantValues={false}
            currentParameterValue={currentParameterValue}
            onChange={onChange}
            parameterType={"any"}
          />
        </div>
        {onDelete && (
          <div className="ml-1.5">
            <ClickableContainer onClick={onDelete}>
              <MinusCircle className="red" strokeWidth={1.5} size={16} />
            </ClickableContainer>
          </div>
        )}
      </div>
    </FormField>
  );
};

export default CustomFunctionTypeaheadFormField;
