import { JSONObjectSchema, JSONSchema, JSONSchemaValue } from "../../../models/Blueprints";
import * as immutable from "object-path-immutable";
import {
  getEmptyJSONSchemaForType,
  JSONSchemaTypeOption,
} from "../utils/BlueprintDataTransformUtils";

export type JSONSchemaTraversalPath = Array<string>;

enum JSONSchemaRuleType {
  IS_REQUIRED = "IS_REQUIRED",
  IS_TWITTER_URL = "IS_TWITTER_URL",
}

export type JSONSchemaRule = {
  ruleType: JSONSchemaRuleType;
};

export type BlueprintValidationActions = {
  setRequiredForField: (
    objectSchema: JSONObjectSchema,
    objectSchemaTraversalPath: JSONSchemaTraversalPath,
    fieldKey: string
  ) => void;
  setNotRequiredForField: (
    objectSchema: JSONObjectSchema,
    objectSchemaTraversalPath: JSONSchemaTraversalPath,
    fieldKey: string
  ) => void;
  addFieldToObject: (
    objectSchemaTraversalPath: JSONSchemaTraversalPath,
    fieldKey: string,
    fieldType: JSONSchemaTypeOption
  ) => void;
  removeFieldFromObject: (
    objectSchemaTraversalPath: JSONSchemaTraversalPath,
    fieldKey: string
  ) => void;
  setArrayFieldSubItemType: (
    arraySchemaTraversalPath: JSONSchemaTraversalPath,
    fieldType: JSONSchemaTypeOption
  ) => void;
};

// PUBLIC UTILS

export const getSchemaFieldValue = (
  schema: JSONSchema,
  fieldTraversalPath: JSONSchemaTraversalPath
): JSONSchemaValue => {
  return immutable.get(schema, fieldTraversalPath);
};

export const buildValidationActions = ({
  setRequiredFieldsForObject,
  schemaSetter,
}: {
  setRequiredFieldsForObject: (
    objectSchemaTraversalPath: JSONSchemaTraversalPath,
    requiredFields: Array<string>
  ) => void;
  schemaSetter: (
    fieldTraversalPath: JSONSchemaTraversalPath,
    fieldValue: JSONSchemaValue | undefined
  ) => void;
}): BlueprintValidationActions => {
  const setRequiredForField = (
    objectSchema: JSONObjectSchema,
    objectSchemaTraversalPath: JSONSchemaTraversalPath,
    fieldKey: string
  ): void => {
    const currRequired = objectSchema.required ?? [];
    const newRequired = !currRequired.includes(fieldKey)
      ? [...currRequired, fieldKey]
      : currRequired;

    return setRequiredFieldsForObject(objectSchemaTraversalPath, newRequired);
  };

  const setNotRequiredForField = (
    objectSchema: JSONObjectSchema,
    objectSchemaTraversalPath: JSONSchemaTraversalPath,
    fieldKey: string
  ): void => {
    const newRequired = (objectSchema.required ?? []).filter((key) => key !== fieldKey);
    return setRequiredFieldsForObject(objectSchemaTraversalPath, newRequired);
  };

  const addFieldToObject = (
    objectSchemaTraversalPath: JSONSchemaTraversalPath,
    fieldKey: string,
    fieldType: JSONSchemaTypeOption
  ): void => {
    const initializedJSONSchemaValue = getEmptyJSONSchemaForType(fieldType);
    return schemaSetter(
      [...objectSchemaTraversalPath, "properties", fieldKey],
      initializedJSONSchemaValue
    );
  };

  const setArrayFieldSubItemType = (
    arraySchemaTraversalPath: JSONSchemaTraversalPath,
    fieldType: JSONSchemaTypeOption
  ): void => {
    const initializedJSONSchemaValue = getEmptyJSONSchemaForType(fieldType);
    return schemaSetter([...arraySchemaTraversalPath, "items"], initializedJSONSchemaValue);
  };

  const removeFieldFromObject = (
    objectSchemaTraversalPath: JSONSchemaTraversalPath,
    fieldKey: string
  ): void => {
    return schemaSetter([...objectSchemaTraversalPath, "properties", fieldKey], undefined);
  };

  return {
    setRequiredForField,
    setNotRequiredForField,
    addFieldToObject,
    removeFieldFromObject,
    setArrayFieldSubItemType,
  };
};

export const validateKeyName = (fieldKey: string): boolean => {
  let regex = new RegExp("^[a-z_]*$");
  return regex.test(fieldKey);
};
