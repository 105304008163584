import { NextComponentVersionState } from "../components/integrations/versioned-components/types";
import {
  BlueprintOperationType,
  BlueprintParameterSchema,
  BlueprintParameterSchemaValue,
  BlueprintRunnerExitData,
} from "./Blueprints";
import { APICategory, BlueprintMeta, Integration } from "./Entities";
import { HTTPMethod } from "./HTTPMethods";
import Readme from "./Readme";

export type MappingTestMeta = {
  id: string;
  category: APICategory;
  operation_type: BlueprintOperationType;
  name: string;
  modified_at: string;
};

export type MappingTestRequestMock = {
  id: string | null;
  blueprint: string | null;
  is_outgoing: boolean;
  method: HTTPMethod;
  url: string;
  request_body: null | string;
  request_body_type: null | string;
  request_headers: string | null | { [header_key: string]: string };
  response_body: null | string;
  original_response_body: null | string;
  response_body_file: null | string;
  response_body_type: null | string;
  response_code: number;
  response_headers: string | null | { [header_key: string]: string };
  edited_at: null | string;
  name: null | string;
  test_version: null | string;
  download_link: string | null;
  file_size: number | null;
};

export type MappingTestBlockBlueprintsMeta = {
  request_mocks: Array<string>;
  input_payload?: any;
  override_last_run_at?: string | null;
  disable_filter_by_date?: boolean;
};

export type MappingTestBlock = {
  order: number;
  blueprints_meta: Record<string, MappingTestBlockBlueprintsMeta>;
  ordered_blueprints_meta?: Array<Record<string, MappingTestBlockBlueprintsMeta>>;
  id: string;
  mapping_test_version_id: string;
  common_model_count_assertions: { [commonModelID: string]: number };
  common_model_mappings: MappingTestExpectedMappings;
};

export type MappingTestBlueprintsMetadata = {
  [blueprintID: string]:
    | {
        input_payload: any;
      }
    | any;
};

export type MappingTestExecutionInput = {
  shouldKeepMockData: boolean;
};

export type MappingTestFieldMappings = { [key: string]: string | Array<string> };
export type MappingTestExpectedMapping = {
  id: undefined | string;
  common_model: string;
  unique_identifier: string;
  field_mappings: MappingTestFieldMappings;
};

export type MappingTestCommonModelField = BlueprintParameterSchemaValue;

export type MappingTestCommonModel = {
  id: string;
  name: string;
  category: APICategory;
  fields: { [fieldKey: string]: MappingTestCommonModelField };
};

export type MappingTestCommonModelExpectedMappings = {
  [expectedMappingName: string]: MappingTestExpectedMapping;
};

export type MappingTestCommonModelObject = {
  id: undefined | string;
  individual_mappings: MappingTestCommonModelExpectedMappings;
};

export type MappingTestExpectedMappings = {
  [commonModelID: string]: MappingTestCommonModelObject;
};

export type CommonModelMappingMissInfo = {
  name: string;
  produced_value: any;
  expected_value: any;
};

export type MappingTestBodyParameters =
  | undefined
  | { model: MappingTestFieldMappings; remote_user_id?: string };

type ErrorMap<T> = {
  [P in keyof T]?: string | ErrorMap<T[P]>;
};

export type ErrorPath = { path: string; message: string; field?: string };
export type MappingTestErrorInfo = Array<ErrorPath>;

// This type holds potential keypaths in the MappingTestVersion type
export type MappingTestVersionConstructionErrors = ErrorMap<MappingTestVersion>;

export type MappingTestErrorResponse = { errors: MappingTestVersionConstructionErrors };

export type MappingTestExistingCommonModelsForCommonModel = {
  [name: string]: { [commonModelField: string]: any };
};

export type MappingTestExistingCommonModels = {
  [commonModelID: string]: MappingTestExistingCommonModelsForCommonModel;
};

export type MappingTestVersion = {
  id?: string | undefined;
  requests: MappingTestRequestMock[];
  common_model_mappings: MappingTestExpectedMappings;
  max_loop_iterations: number | undefined;
  max_page_iterations: number | undefined;
  common_model_count_assertions: { [commonModelID: string]: number };
  existing_common_models: undefined | MappingTestExistingCommonModels;
  should_check_auth_headers: boolean;
  body_parameters: MappingTestBodyParameters;
  frozen_time: string | undefined;
  existing_common_model_name: string | undefined;
  blueprint_metadata: MappingTestBlueprintsMetadata;
  mapping_test_blocks: Array<MappingTestBlock>;
  // Note that this local_errors field is not a field on the Mapping Test Version
  // database model (and will not be sent to the server), but holds any errors
  // that may occur while building a test
  construction_errors: MappingTestErrorInfo;
};

export enum MappingTestExpectedResult {
  SUCCESS = "SUCCESS",
  SUCCESS_WITH_WARNINGS = "SUCCESS_WITH_WARNINGS",
  ERROR = "ERROR",
}

export type MappingTestAndVersions = {
  id: string;
  integration_id: string;
  category: APICategory;
  operation_type: BlueprintOperationType;
  webhook_receiver_event_type_id: string | null;
  name: string;
  linked_account_id: string;
  published_version: undefined | MappingTestVersion;
  next_version: undefined | MappingTestVersion;
  next_version_state: NextComponentVersionState;
  expected_result: MappingTestExpectedResult | undefined;
};

export type MappingTestIndividualModelLog = {
  [key: string]: unknown;
};

export type OverlappingField = [string, string, string];

export interface BlueprintExecutionPreviewInfo {
  id: string;
  linked_account: string;
  start_time: string;
  end_time: string | undefined;
  exit_code: number;
  api_requests_count: number;
  produced_models_count: { [commonModelID: string]: number };
}

export interface RecentExecutionsResponse {
  [key: string]: BlueprintExecutionPreviewInfo[];
}

export type LatestScraperExecutionData = {
  scraper: any;
  exit_data: object;
};

export enum MappingTestExecutionResult {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  EXCEPTION = "EXCEPTION",
}

export enum LinkedAccountTestSuiteExecutionStatus {
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED_WITH_FAILURES = "FINISHED_WITH_FAILURES",
  FINISHED_WITH_EXCEPTIONS = "FINISHED_WITH_EXCEPTIONS",
  FINISHED_WITH_SUCCESS = "FINISHED_WITH_SUCCESS",
}

export enum IntegrationTestSuiteExecutionStatus {
  NOT_RUN = "NOT_RUN",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED_WITH_SUCCESS = "FINISHED_WITH_SUCCESS",
  FINISHED_WITH_FAILURES = "FINISHED_WITH_FAILURES",
  ABORTED = "ABORTED",
  FINISHED_WITH_EXCEPTIONS = "FINISHED_WITH_EXCEPTIONS",
}

export enum BlockExecutionErrorType {
  MOCK_REQUEST_NOT_FOUND = "MOCK_REQUEST_NOT_FOUND",
  BLUEPRINT_EXCEPTION = "BLUEPRINT_EXCEPTION",
  UNCAUGHT_EXCEPTION = "UNCAUGHT_EXCEPTION",
  INCORRECT_MODEL_COUNTS = "INCORRECT_MODEL_COUNTS",
  ASSERTED_MODELS_NOT_FOUND = "ASSERTED_MODELS_NOT_FOUND",
  INCORRECT_FIELD_ASSERTIONS = "INCORRECT_FIELD_ASSERTIONS",
}

export const BLOCK_EXECUTION_ERROR_TYPE_TO_TEXT = {
  MOCK_REQUEST_NOT_FOUND: "Mock request not found",
  BLUEPRINT_EXCEPTION: "Blueprint failed with exception",
  UNCAUGHT_EXCEPTION: "Uncaught mapping test run exception",
  INCORRECT_MODEL_COUNTS: "Model counts incorrect",
  ASSERTED_MODELS_NOT_FOUND: "Models not found",
  INCORRECT_FIELD_ASSERTIONS: "Field assertions incorrect",
};

export type MappingTestExecutionMappingMissesForIndividualMapping = {
  is_model_missing: boolean;
  incorrect_fields: Array<CommonModelMappingMissInfo>;
};

export type MappingTestExecutionMappingMissesForCommonModel = {
  [mappingName: string]: MappingTestExecutionMappingMissesForIndividualMapping;
};

export type MappingTestExecutionMappingMisses = {
  [commonModelID: string]: MappingTestExecutionMappingMissesForCommonModel;
};

export type MappingTestExecutionIndividualCommonModelCountMetadata = {
  total_common_model_count: number;
  is_assertion_correct: boolean;
  is_missing_mapping: boolean;
};

export type MappingTestBlockExecution = {
  id: string;
  result: MappingTestExecutionResult;
  mapping_misses: undefined | MappingTestExecutionMappingMisses;
  mapping_test_execution: number;
  exception_message: string | undefined;
  mapping_test_block_id: string;
  mapping_test_id: string;
  mapping_test_version_id: string;
  is_test_run: boolean;
  blueprint_version_id: string;
  created_at: string;
  finished_at: string | undefined;
  exit_data: Array<BlueprintRunnerExitData>;
  produced_models?: {
    [commonModelID: string]: { [commonModelValue: string]: any };
  };
  common_model_count_metadata:
    | undefined
    | { [commonModelID: string]: MappingTestExecutionIndividualCommonModelCountMetadata };
  errors?: Array<MappingTestBlockExecutionError>;
};

export type BlockExecutionSuggestionDict = {
  text: string;
};

export type BlockExecutionErrorDetailsMockRequestNotFoundDict = {
  method: string;
  url: string;
  request_body: string | null;
  request_headers?: string | null;
};

export type BlockExecutionErrorDetailsAssertionFailureDict = {
  model?: string | null;
  model_instance?: string | null;
};

export type BlockExecutionErrorDetailsAssertionsDict = {
  failing_assertions: Array<BlockExecutionErrorDetailsAssertionFailureDict>;
};

// Type guard for BlockExecutionErrorDetailsMockRequestNotFoundDict
export function isMockRequestNotFoundDict(
  details: any
): details is BlockExecutionErrorDetailsMockRequestNotFoundDict {
  return details && typeof details === "object" && "method" in details && "url" in details;
}

// Type guard for BlockExecutionErrorDetailsAssertions
export function isAssertionFailureDict(
  details: any
): details is BlockExecutionErrorDetailsAssertionsDict {
  return details && typeof details === "object" && "failing_assertions" in details;
}

export type MappingTestBlockExecutionError = {
  type: BlockExecutionErrorType;
  summary: string;
  details?:
    | BlockExecutionErrorDetailsMockRequestNotFoundDict
    | BlockExecutionErrorDetailsAssertionsDict
    | null;
  suggestions?: Array<BlockExecutionSuggestionDict> | null;
};

export type MappingTestExecution = {
  blueprint_version_id: undefined | string;
  created_at: string;
  finished_at: undefined | string;
  id: string;
  is_test_run: boolean;
  result: MappingTestExecutionResult;
  mapping_misses: undefined | MappingTestExecutionMappingMisses;
  endpoint_test_collection_id?: string;
  common_model_count_metadata:
    | undefined
    | { [commonModelID: string]: MappingTestExecutionIndividualCommonModelCountMetadata };
  exception_message: undefined | string;
  produced_models?: {
    [commonModelID: string]: { [commonModelValue: string]: any };
  };
  exit_data?: Array<BlueprintRunnerExitData>;
  mapping_test_version_id: string;
  mapping_test_id: string;
  block_executions?: Array<MappingTestBlockExecution>;
};

export interface MappingTestReadme extends Readme {}

export type LinkedAccountInMappingTest = {
  id: string;
  end_user_organization_name: string;
};

export type MappingTestReadTestInformation = {
  id: string;
  available_relation_lookup_dict: { [commonModelID: string]: Array<string> };
  is_there_a_non_draft_read_test_version: boolean;
  does_read_test_have_draft_changes: boolean;
};
export interface TestsResponse {
  integration: Integration;
  blueprints: BlueprintMeta[];
  ineligible_draft_blueprint_versions: BlueprintMeta[];
  mapping_test_and_versions: MappingTestAndVersions;
  endpoint_test_collection: undefined | MappingTestSuiteEndpointCollection;
  read_test_information: undefined | MappingTestReadTestInformation;
  common_models: { [commonModelID: string]: MappingTestCommonModel };
  linked_account: LinkedAccountInMappingTest;
}

export interface MappingTestSuiteMeta {
  id: string;
  linked_account_id: string;
  category: string;
  end_user_organization_name: string;
  linked_account: string;
}

export type MappingTestBodyParameterSchema =
  | undefined
  | {
      properties: {
        model?: { required: string[]; properties: BlueprintParameterSchema<string> };
        remote_user_id?: BlueprintParameterSchemaValue;
      };
    };
export interface MappingTestSuiteEndpointCollection {
  id: string;
  blueprint_id: string | null;
  blueprint_name: string | null;
  body_parameter_schema: MappingTestBodyParameterSchema | null;
  common_model_name: string | null;
  is_read_test_collection: boolean;
  nested_writes_map: Record<string, Array<string>>;
  operation_type: string;
  test_cases: MappingTestAndVersions[];
  webhook_event: string | null;
  webhook_receiver_event_type_id: string | null;
}

export type CommonModelName = string;

export type MappingTestSuiteEndpointCollectionInfo = {
  id: string | null;
  operation_type: string | null;
  common_model_name: string | null;
  webhook_event: string | null;
  webhook_receiver_event_type_id: string | null;
  test_cases: Array<MappingTestAndVersions>;
  is_read_test_collection: boolean;
  blueprint_id: string | null;
  blueprint_name: string | null;
};

export interface MappingTestSuite extends MappingTestSuiteMeta {
  endpoint_collections: Array<MappingTestSuiteEndpointCollectionInfo>;
  read_test: MappingTestAndVersions;
}

export interface LinkedAccountTestSuiteExecution {
  mapping_test_executions: Array<MappingTestExecution>;
  integration_test_suite_execution: string;
  linked_account_mapping_test_suite: MappingTestSuite;
  created_at: string | null;
  modified_at: string | null;
  id: string;
  status: LinkedAccountTestSuiteExecutionStatus;
}

export interface IntegrationTestSuiteExecution {
  id: string;
  trigger_type: string;
  integration_version: string;
  status: IntegrationTestSuiteExecutionStatus;
  start_time: string;
  end_time: string | null;
  task_id: string | null;
  celery_worker: string | null;
  exception_message: string | null;
  linked_account_test_suite_executions: Array<LinkedAccountTestSuiteExecution>;
}

export interface LogEntry {
  id: string;
  created_at: string;
  direction: string;
  test_version: null;
  url: string;
  blueprint: null;
  method: HTTPMethod;
  is_outgoing: boolean;
  request_body: null | string;
  request_body_type: null | string;
  request_query_params: {
    [key: string]: string | number;
  };
  request_headers: {
    [key: string]: string;
  };
  response_body_file: null;
  name: null;
  response_code: number;
  edited_at: null;
  response_headers: {
    [key: string]: string;
  };
  status: String;
  response_body: string | null;
  response_body_type: string | null;
  original_response_body: null;
  download_link: string | null;
  file_size: number | null;
}

export interface CodeBlockFileDownloadInfo {
  previewMessage: string;
  downloadLink: string;
}

export enum AutogeneratedReadMappingTestRunStatus {
  PENDING = "PENDING",
  RUNNING_FETCH_BLUEPRINTS_FOR_LINKED_ACCOUNT = "RUNNING_FETCH_BLUEPRINTS_FOR_LINKED_ACCOUNT",
  GENERATING_MAPPING_TEST = "GENERATING_MAPPING_TEST",
  FINISHED_WITH_SUCCESS = "FINISHED_WITH_SUCCESS",
  FINISHED_WITH_FAILURES = "FINISHED_WITH_FAILURES",
  FINISHED_WITH_EXCEPTIONS = "FINISHED_WITH_EXCEPTIONS",
  ABORTED = "ABORTED",
  FINISHED_WITH_FAILURES_THEN_RAN_SUCCESSFULLY = "FINISHED_WITH_FAILURES_THEN_RAN_SUCCESSFULLY",
}

export const AutogeneratedReadMappingTestRunStatusDescriptions: {
  [key in AutogeneratedReadMappingTestRunStatus]: string;
} = {
  [AutogeneratedReadMappingTestRunStatus.PENDING]: "Preparing data...",
  [AutogeneratedReadMappingTestRunStatus.RUNNING_FETCH_BLUEPRINTS_FOR_LINKED_ACCOUNT]: "Running",
  [AutogeneratedReadMappingTestRunStatus.GENERATING_MAPPING_TEST]: "Running mapping test...",
  [AutogeneratedReadMappingTestRunStatus.FINISHED_WITH_SUCCESS]:
    "Mapping test successfully generated",
  [AutogeneratedReadMappingTestRunStatus.FINISHED_WITH_FAILURES]: "Mapping test generation failed",
  [AutogeneratedReadMappingTestRunStatus.FINISHED_WITH_EXCEPTIONS]:
    "Mapping test generation failed with exceptions",
  [AutogeneratedReadMappingTestRunStatus.ABORTED]: "Mapping test generation was aborted",
  [AutogeneratedReadMappingTestRunStatus.FINISHED_WITH_FAILURES_THEN_RAN_SUCCESSFULLY]:
    "Mapping test failed the generation but was updated and now is passing",
};

export interface AutogeneratedReadMappingTestBlueprintRunData {
  blueprint_id: string;
  blueprint_name: string;
  start_time: string;
  end_time?: string;
  exception_mesasage?: string;
  exit_code: number;
}

export interface AsyncAutogeneratedReadMappingTestRun {
  id: string;
  exception_message: string;
  blueprint_run_data: AutogeneratedReadMappingTestBlueprintRunData[];
  status: AutogeneratedReadMappingTestRunStatus;
  start_time: string;
  end_time: string;
  linked_account_mapping_test_suite_id: string;
  total_blueprint_versions_to_run?: number;
}
