import clsx from "clsx";

interface Props {
  text: string;
  isBold?: boolean;
  onClick: React.MouseEventHandler<HTMLTableCellElement> | undefined;
}

const TextCell = ({ text, onClick, isBold = false }: Props) => {
  return (
    <td className="py-5" onClick={onClick}>
      <div className={clsx("text-gray-90 text-base", isBold ? "font-medium" : "")}>{text}</div>
    </td>
  );
};

export default TextCell;
