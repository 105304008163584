import { Text, Spinner } from "@merge-api/merge-javascript-shared";
import {
  APIEndpointPreviewIntegrationBuilder,
  AutogenerationRunStatus,
} from "../../utils/Entities";
import { AlertTriangle, CircleCheck } from "lucide-react";
import React from "react";

interface AutogeneratedConfigurationCardTitleProps {
  status: string;
  api_endpoint_preview?: APIEndpointPreviewIntegrationBuilder;
}

const Wrapper: React.FC = ({ children }) => (
  <div className="flex flex-row items-center justify-start">{children}</div>
);

const AutogeneratedConfigurationCardTitle = ({
  status,
  api_endpoint_preview,
}: AutogeneratedConfigurationCardTitleProps) => {
  switch (status) {
    case AutogenerationRunStatus.RUNNING:
    case AutogenerationRunStatus.PENDING:
      return (
        <Wrapper>
          <Spinner size="md" className="mr-4" />
          <Text variant="h6" className="mr-1">
            {`Generating ${
              api_endpoint_preview ? api_endpoint_preview.method : "default configuration"
            }`}
          </Text>
          {api_endpoint_preview && <Text>{api_endpoint_preview.path}</Text>}
        </Wrapper>
      );
    case AutogenerationRunStatus.FAILURE:
    case AutogenerationRunStatus.ABORTED:
      return (
        <Wrapper>
          <AlertTriangle className="mr-4" color="#BE0A19" size={16} />
          <Text variant="h6" className="text-red-70 mr-1">
            {`Failed to generate ${
              api_endpoint_preview ? api_endpoint_preview.method : "default configuration"
            }`}
          </Text>
          {api_endpoint_preview && <Text className="text-red-70">{api_endpoint_preview.path}</Text>}
        </Wrapper>
      );
    case AutogenerationRunStatus.SUCCESS_MODEL_CREATED:
      return (
        <Wrapper>
          <CircleCheck className="mr-4" color="#0D8775" size={16} />
          <Text variant="h6" className="text-teal-60 mr-1">
            {`Successfully generated ${
              api_endpoint_preview ? api_endpoint_preview.method : "default configuration"
            }`}
          </Text>
          {api_endpoint_preview && (
            <Text className="text-teal-60">{api_endpoint_preview.path}</Text>
          )}
        </Wrapper>
      );
    case AutogenerationRunStatus.SUCCESS_NO_ACTION:
      return (
        <Wrapper>
          <AlertTriangle className="mr-4" color="#955400" size={16} />
          <Text variant="h6" className="text-yellow-80 mr-1">
            No default configuration found for this integration
          </Text>
        </Wrapper>
      );
    default:
      return null;
  }
};

export default AutogeneratedConfigurationCardTitle;
