import { useHistory, useParams, generatePath } from "react-router-dom";
import { useLoadPaginatedIntegrations } from "../../dashboard/hooks/useLoadPaginatedIntegrations";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../../../shared/Spinner";
import { IntegrationSummary, CategoriesTabs } from "./components";
import { IntegrationsOrderBy } from "../../dashboard/components";
import { navigateHelper } from "../../../../router/RouterUtils";

type IntegrationsSubNavProps = {
  integrationID: undefined | string;
};

function IntegrationsSubNav({ integrationID }: IntegrationsSubNavProps) {
  // hooks
  const history = useHistory();
  const params = useParams<{ subtab: string }>();
  const {
    category,
    setCategory,
    orderBy,
    setOrderBy,
    integrations,
    hasNext,
    onNext,
    isLoading,
  } = useLoadPaginatedIntegrations({ isLeftNav: true });

  // event handlers
  const navigateToIntegration = (id: string, shouldNavigateInNewTab: boolean = false) => {
    const path = generatePath("/integrations/:integrationID/:subtab", {
      integrationID: id,
      subtab: params.subtab,
    });

    navigateHelper(history, path, shouldNavigateInNewTab);
  };

  return (
    <div
      id="scrollableContainer"
      className="bg-white border-gray-10 border-0 border-r border-solid cursor-pointer h-screen overflow-auto"
    >
      <div className="border-gray-10 border-b mx-3 border-0 border-solid py-2">
        <CategoriesTabs category={category} setCategory={setCategory} />
        <div className="mt-2">
          <IntegrationsOrderBy orderBy={orderBy} setOrderBy={setOrderBy} />
        </div>
      </div>
      {integrations?.length === 0 && isLoading ? (
        <div className="flex justify-center align-middle mt-4">
          <Spinner />
        </div>
      ) : (
        <InfiniteScroll
          next={onNext}
          loader={
            <div className="flex justify-center align-middle mt-4">
              <Spinner />
            </div>
          }
          hasMore={hasNext}
          dataLength={integrations?.length ?? 0}
          className="overflow-hidden"
          scrollableTarget="scrollableContainer"
        >
          {(integrations ?? []).map((integration) => (
            <div
              className={classNames(
                integration.id === integrationID && "bg-[#F8FBFD]",
                "p-3 border-gray-10 border-0 border-b border-solid"
              )}
              key={integration.id}
              onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                navigateToIntegration(integration.id, event.metaKey || event.ctrlKey)
              }
            >
              <IntegrationSummary integration={integration} />
            </div>
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
}

export default IntegrationsSubNav;
