import { Button, ButtonVariant, Badge, Text } from "@merge-api/merge-javascript-shared";
import { CheckCircle, CircleSlash2, OctagonX, RefreshCw } from "lucide-react";
import { palette } from "../../../../styles/theme/colors";
import { IntegrationTestSuiteExecution } from "../../../../models/MappingTests";
import { countIntegrationTestSuiteMappingTestExecutionFailures } from "../publishModuleUtils";
import { useEffect, useState } from "react";

type Props = {
  integrationTestSuiteExecution: IntegrationTestSuiteExecution | null;
  hasFailedFetchingLatestExecution: boolean;
  onRunTestsForStagedIntegrationVersion: () => void;
  isRunningMappingTests: boolean;
};

const MappingTestAccordionTitle = ({
  onRunTestsForStagedIntegrationVersion,
  integrationTestSuiteExecution,
  isRunningMappingTests,
}: Props) => {
  const [failureCount, setfailureCount] = useState<number>(0);

  useEffect(() => {
    setfailureCount(
      integrationTestSuiteExecution
        ? countIntegrationTestSuiteMappingTestExecutionFailures(integrationTestSuiteExecution)
        : 0
    );
  }, [integrationTestSuiteExecution]);

  const hasMappingTestExecutions = (
    integrationTestSuiteExecution: IntegrationTestSuiteExecution | null
  ): boolean =>
    (integrationTestSuiteExecution?.linked_account_test_suite_executions ?? []).some(
      (testSuiteExecution) => (testSuiteExecution.mapping_test_executions?.length ?? 0) > 0
    );

  return (
    <div className="flex flex-row justify-between w-full">
      <div className="flex flex-row justify-start items-center space-x-4">
        {isRunningMappingTests ? (
          <RefreshCw className="rotating" color={palette.blue} size={24} />
        ) : !hasMappingTestExecutions(integrationTestSuiteExecution) ? (
          <CircleSlash2 size={24} className="text-gray-50" />
        ) : failureCount > 0 ? (
          <OctagonX color={palette.red} />
        ) : (
          <CheckCircle color={palette.green} />
        )}
        <Text variant="h5" className="text-black">
          Mapping tests
        </Text>
        {!isRunningMappingTests && failureCount > 0 && (
          <Badge color="red">
            {failureCount.toString() + (failureCount > 1 ? " failures" : " failure")}
          </Badge>
        )}
      </div>
      <div className="mr-6">
        {isRunningMappingTests ? (
          <Button
            size="sm"
            variant={ButtonVariant.TertiaryWhite}
            leftIcon={<RefreshCw className="rotating" size={12} />}
            disabled
          >
            Re-run
          </Button>
        ) : (
          <Button
            size="sm"
            variant={ButtonVariant.TertiaryWhite}
            leftIcon={<RefreshCw size={12} />}
            onClick={(e) => {
              e.stopPropagation();
              onRunTestsForStagedIntegrationVersion();
            }}
          >
            Re-run
          </Button>
        )}
      </div>
    </div>
  );
};

export default MappingTestAccordionTitle;
