import { Spinner, Text } from "@merge-api/merge-javascript-shared";

interface Props {
  loadingText: string;
}

const LoadingSpinnerWithText = ({ loadingText }: Props) => {
  return (
    <div className="flex flex-row space-x-2 items-center justify-start">
      <Spinner size="md" />
      <Text variant="h6" className="text-gray-70">
        {loadingText}
      </Text>
    </div>
  );
};

export default LoadingSpinnerWithText;
