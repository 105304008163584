import { ButtonVariant, Dialog, Text, TextField } from "@merge-api/merge-javascript-shared";
import { useEffect, useMemo, useState } from "react";
import { LinkedAccount } from "../../../models/Entities";
import LinkedAccountSearchResults from "../../integrations/linked-accounts/LinkedAccountSearchResults";
import { useCustomerLinkedAccountContext } from "../context/CustomerLinkedAccountAccessContext";
import useFetchLinkedAccount from "../hooks/useFetchLinkedAccount";
import useRequestLinkedAccountAccess from "../hooks/useRequestLinkedAccountAccess";
import { isValidURL } from "../../integration-builder/utils/helpers";

const RequestCustomerLinkedAccountDialog = () => {
  const {
    integrationID,
    showRequestModal,
    setShowRequestModal,
    fetchLinkedAccountAccessRequest,
  } = useCustomerLinkedAccountContext();

  const [customerLinkedAccountId, setCustomerLinkedAccountId] = useState<string>("");
  const [hasCheckedLinkedAccount, setHasCheckedLinkedAccount] = useState<boolean>(false);
  const [isSearchingLinkedAccount, setIsSearchingLinkedAccount] = useState<boolean>(false);
  const [linkedAccount, setLinkedAccount] = useState<LinkedAccount | null>(null);
  const [description, setDescription] = useState<string>("");
  const [asanaTicket, setAsanaTicket] = useState<string>("");
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [isInvalidURL, setIsInvalidURL] = useState<boolean>(false);
  const isPrimaryButtonDisabled = useMemo(() => {
    return (
      !customerLinkedAccountId || !description || !asanaTicket || !linkedAccount || isInvalidURL
    );
  }, [customerLinkedAccountId, description, asanaTicket, linkedAccount, isInvalidURL]);
  const { requestLinkedAccountAccess } = useRequestLinkedAccountAccess({
    setShowRequestModal,
    setIsRequesting,
    fetchLinkedAccountAccessRequest,
  });
  const { fetchLinkedAccount } = useFetchLinkedAccount({
    setLinkedAccount,
    setHasCheckedLinkedAccount,
    setIsSearchingLinkedAccount,
  });

  const clearForm = () => {
    setCustomerLinkedAccountId("");
    setHasCheckedLinkedAccount(false);
    setLinkedAccount(null);
    setDescription("");
    setAsanaTicket("");
  };

  // Fetch linked account on blur if the field is not empty
  const onLinkedAccountTextFieldBlur = () => {
    if (customerLinkedAccountId) {
      try {
        setIsSearchingLinkedAccount(true);
        fetchLinkedAccount(customerLinkedAccountId);
      } catch {
        setHasCheckedLinkedAccount(true);
        setLinkedAccount(null);
      }
    } else {
      setHasCheckedLinkedAccount(false);
    }
  };

  // When the modal is closed, clear the form
  useEffect(() => {
    if (!showRequestModal) {
      clearForm();
    }
  }, [showRequestModal]);

  return (
    <Dialog
      title="Request customer Linked Account"
      open={showRequestModal}
      onClose={() => setShowRequestModal(false)}
      variant="md"
      primaryButtonDisabled={isPrimaryButtonDisabled}
      primaryButtonVariant={ButtonVariant.PrimaryBlue}
      onPrimaryButtonClick={() => {
        requestLinkedAccountAccess(customerLinkedAccountId, description, asanaTicket);
      }}
      primaryButtonLoading={isRequesting}
    >
      <div className="space-y-4">
        <Text>
          Fill out the form to request access to a production Linked Account. Requests will be
          reviewed on Slack and a notification will be sent to you.
        </Text>
        <div className="space-y-2">
          <Text variant="h6">Customer Linked Account ID</Text>
          <TextField
            placeholder="Enter the customer Linked Account ID"
            value={customerLinkedAccountId}
            onChange={(e) => setCustomerLinkedAccountId(e.target.value)}
            onBlur={onLinkedAccountTextFieldBlur}
          />
        </div>
        <LinkedAccountSearchResults
          integrationID={integrationID}
          hasCheckedLinkedAccount={hasCheckedLinkedAccount}
          isSearchingLinkedAccount={isSearchingLinkedAccount}
          linkedAccount={linkedAccount}
        />
        <div className="space-y-2">
          <div>
            <Text variant="h6">Why do you need access?</Text>
            <Text variant="sm" className="text-gray-70">
              Provide a brief description
            </Text>
          </div>
          <TextField
            placeholder="Enter description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="space-y-2">
          <Text variant="h6">Asana ticket</Text>
          <TextField
            placeholder="Enter URL"
            value={asanaTicket}
            onChange={(e) => {
              setIsInvalidURL(!!e.target.value && !isValidURL(e.target.value));
              setAsanaTicket(e.target.value);
            }}
            error={isInvalidURL}
            errorText="Please enter a valid URL"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default RequestCustomerLinkedAccountDialog;
