import TypeaheadFormField from "./TypeaheadFormField";

const BlueprintEditorRightPanelWhileLoopForm = () => {
  return (
    <form>
      <TypeaheadFormField
        title="While Loop Condition"
        subtitle="If true, will execute substeps. Should be a variable that is updated inside the while loop."
        valueKey="condition"
        parameterType={"boolean"}
      />
      <TypeaheadFormField
        title="Max Iterations"
        subtitle="Maximum number of iterations of the while loop to run before raising an exception."
        valueKey="max_iterations"
        parameterType={"number"}
      />
    </form>
  );
};

export default BlueprintEditorRightPanelWhileLoopForm;
