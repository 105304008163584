import React, { useCallback, useContext, useEffect, useState } from "react";
import AccordionCard from "../../../shared/AccordionCard";
import APIEndpointContext from "../../context/APIEndpointContext";
import RateLimitExplainAlert from "../../../rate-limits/components/shared/RateLimitExplainAlert";
import { Button, ButtonVariant, Tooltip } from "@merge-api/merge-javascript-shared";
import APIEndpointRateLimitCard from "./APIEndpointRateLimitCard";
import RateLimitConfigurationContextProvider from "../../../rate-limits/context/RateLimitConfigurationContextProvider";
import { addRateLimitConfiguration } from "../../helpers";
import {
  APIEndpointIntegrationBuilder,
  RateLimitConfigurationIntegrationBuilder,
} from "../../../utils/Entities";
import { RateLimitFormCallback } from "../APIEndpointSetup";
import { Plus } from "lucide-react";

interface APIEndpointRateLimitConfigurationSetupOptionsProps {
  selectedAPIEndpoint?: APIEndpointIntegrationBuilder | undefined;
  registerForm: (formID: string, formFunction: RateLimitFormCallback) => void;
  unregisterForm: (formID: string) => void;
  isDefaultExpanded: boolean;
}

interface AdditionalContextProps {
  integrationID: string;
  rateLimitConfigurations: RateLimitConfigurationIntegrationBuilder[] | undefined;
  setRateLimitConfigurations: React.Dispatch<
    React.SetStateAction<RateLimitConfigurationIntegrationBuilder[] | undefined>
  >;
  setCanSubmitRateLimitConfigurations: (value: boolean) => void;
}

type APIEndpointRateLimitConfigurationSetupOptionsPropsWithContext = APIEndpointRateLimitConfigurationSetupOptionsProps &
  AdditionalContextProps;

const APIEndpointRateLimitConfigurationSetupOptionsWithContext = React.memo(
  ({
    integrationID,
    rateLimitConfigurations,
    setRateLimitConfigurations,
    setCanSubmitRateLimitConfigurations,
    selectedAPIEndpoint,
    registerForm,
    unregisterForm,
    isDefaultExpanded = false,
  }: APIEndpointRateLimitConfigurationSetupOptionsPropsWithContext) => {
    // state
    const apiEndpointID = selectedAPIEndpoint && selectedAPIEndpoint.id;
    const [expanded, setExpanded] = useState<boolean>(isDefaultExpanded);

    // consts derived from state
    const numberOfRateLimitConfigurations = rateLimitConfigurations?.length || 0;
    // states, functions and hooks for storing and updating canSubmitStatus
    const [canSubmitStatus, setCanSubmitStatus] = useState<Record<string, boolean>>({});
    const handleCanSubmitChange = useCallback((uniqueKey: string, canSubmit: boolean) => {
      setCanSubmitStatus((prev) => ({ ...prev, [uniqueKey]: canSubmit }));
    }, []);

    useEffect(() => {
      const configurations = rateLimitConfigurations || [];
      const currentKeys = configurations.map((cfg) => cfg.id || cfg.temp_id);

      setCanSubmitStatus((prev) => {
        const updatedStatus = { ...prev };
        Object.keys(prev).forEach((key) => {
          // if the key doesn't exist in the current configurations, delete it from canSubmitStatus
          if (!currentKeys.includes(key)) {
            delete updatedStatus[key];
          }
        });
        return updatedStatus;
      });
    }, [rateLimitConfigurations]);

    // compute whether all configurations can be submitted
    useEffect(() => {
      const allCanSubmit = Object.values(canSubmitStatus).every((status) => status);
      setCanSubmitRateLimitConfigurations(allCanSubmit);
    }, [canSubmitStatus, rateLimitConfigurations]);

    // update initial expanded state
    useEffect(() => {
      setExpanded(true);
    }, [isDefaultExpanded]);

    return (
      <AccordionCard
        dataTestID="accordion-api-endpoint-rate-limit-configs"
        title="Rate limit configurations"
        badgeContents={numberOfRateLimitConfigurations}
        expanded={expanded}
        setExpanded={setExpanded}
      >
        <RateLimitExplainAlert
          className="mb-6"
          integrationID={integrationID}
          isOnAPIEndpointsPage
        />
        <div className="space-y-6">
          {rateLimitConfigurations &&
            rateLimitConfigurations.length > 0 &&
            rateLimitConfigurations.map((rateLimitConfiguration, index) => (
              <RateLimitConfigurationContextProvider
                selectedRateLimitConfiguration={rateLimitConfiguration}
                integrationID={integrationID}
                key={
                  rateLimitConfiguration.id !== undefined
                    ? rateLimitConfiguration.id
                    : rateLimitConfiguration.temp_id
                }
              >
                <APIEndpointRateLimitCard
                  index={index}
                  rateLimitConfiguration={rateLimitConfiguration}
                  registerForm={registerForm}
                  unregisterForm={unregisterForm}
                  handleCanSubmitChange={handleCanSubmitChange}
                />
              </RateLimitConfigurationContextProvider>
            ))}
        </div>
        {apiEndpointID ? (
          <div data-testid="button-api-endpoint-rate-limit-configs-add">
            <Button
              leftIcon={<Plus size={16} />}
              variant={ButtonVariant.TertiaryWhite}
              className="mt-6"
              onClick={() =>
                addRateLimitConfiguration(
                  rateLimitConfigurations,
                  setRateLimitConfigurations,
                  apiEndpointID
                )
              }
            >
              Configuration
            </Button>
          </div>
        ) : (
          <Tooltip title="You must save the API endpoint before creating rate limit configurations.">
            <Button
              leftIcon={<Plus size={16} />}
              variant={ButtonVariant.TertiaryWhite}
              disabled={true}
            >
              Configuration
            </Button>
          </Tooltip>
        )}
      </AccordionCard>
    );
  }
);

// We set up these wrappers to pass the props from the context to the component
// Any changes to the context (even if they are not used in the component) will trigger a re-render
const APIEndpointRateLimitConfigurationSetupOptions = ({
  selectedAPIEndpoint,
  registerForm,
  unregisterForm,
  isDefaultExpanded,
}: APIEndpointRateLimitConfigurationSetupOptionsProps) => {
  const {
    integrationID,
    rateLimitConfigurations,
    setRateLimitConfigurations,
    setCanSubmitRateLimitConfigurations,
  } = useContext(APIEndpointContext);

  return (
    <APIEndpointRateLimitConfigurationSetupOptionsWithContext
      integrationID={integrationID}
      rateLimitConfigurations={rateLimitConfigurations}
      setRateLimitConfigurations={setRateLimitConfigurations}
      selectedAPIEndpoint={selectedAPIEndpoint}
      registerForm={registerForm}
      unregisterForm={unregisterForm}
      setCanSubmitRateLimitConfigurations={setCanSubmitRateLimitConfigurations}
      isDefaultExpanded={isDefaultExpanded}
    />
  );
};

export default APIEndpointRateLimitConfigurationSetupOptions;
