import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { AlertTriangle } from "lucide-react";
import { useEffect } from "react";
import { usePollAutogeneratedReadTest } from "./hooks/usePollAutogeneratedReadTest";
import { AsyncAutogeneratedReadMappingTestRun } from "../../../models/MappingTests";
import clsx from "clsx";

interface ViewAutogeneratedRunButtonProps {
  generatedReadTestRun: AsyncAutogeneratedReadMappingTestRun;
  mappingTestSuiteID: string;
  inProgressExecution: boolean;
  openDialog: () => void;
  hasPollingBeenTriggered: boolean;
  setGeneratedReadTestRun: React.Dispatch<
    React.SetStateAction<AsyncAutogeneratedReadMappingTestRun | null>
  >;
  setHasPollingBeenTriggered: React.Dispatch<React.SetStateAction<boolean>>;
}

const ViewAutogeneratedRunButton = ({
  generatedReadTestRun,
  mappingTestSuiteID,
  inProgressExecution,
  openDialog,
  hasPollingBeenTriggered,
  setGeneratedReadTestRun,
  setHasPollingBeenTriggered,
}: ViewAutogeneratedRunButtonProps) => {
  // hooks
  const { pollAutogeneratedReadTest } = usePollAutogeneratedReadTest({
    setGeneratedReadTestRun,
    setHasPollingBeenTriggered,
  });

  // hook trigger polling on page load, if it hasn't already been from the autogenerate read test button
  useEffect(() => {
    if (!hasPollingBeenTriggered && inProgressExecution) {
      pollAutogeneratedReadTest(mappingTestSuiteID, generatedReadTestRun?.id);
    }
  }, [hasPollingBeenTriggered]);

  return (
    <Button
      size="sm"
      variant={ButtonVariant.TertiaryWhite}
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        openDialog();
      }}
      leftIcon={<AlertTriangle size={12} />}
      className={clsx(inProgressExecution ? "text-black" : "text-red")}
    >
      {inProgressExecution ? "View execution" : "View failed execution"}
    </Button>
  );
};

export default ViewAutogeneratedRunButton;
