import {
  BlueprintSearchResultBlueprint,
  BlueprintSearchResults,
} from "../../../models/blueprint-search/BlueprintSearchOutputModels";
import BlueprintsSearchTableCard from "./blueprints-search-table-card/BlueprintsSearchTableCard";
import LoadingText from "./shared/LoadingText";
import { Text } from "@merge-api/merge-javascript-shared";

interface Props {
  blueprintSearchResults: BlueprintSearchResults;
  integrationID: string;
  isLoading: boolean;
  setIsArchiveModalShown: (isArchiveModalShown: boolean) => void;
  setSelectedBlueprint: (selectedBlueprint: BlueprintSearchResultBlueprint) => void;
}

const BlueprintsSearchTableResults = ({
  blueprintSearchResults,
  integrationID,
  isLoading,
  setIsArchiveModalShown,
  setSelectedBlueprint,
}: Props) => {
  return (
    <div className="flex flex-col space-y-6 h-full min-h-[2000px]">
      {isLoading ? (
        <div className="">
          <LoadingText text="Loading Blueprints ..." />
        </div>
      ) : blueprintSearchResults.blueprints.length === 0 ? (
        <div className="flex flex-row items-start justify-start">
          <Text variant="h6" className="text-gray-70">
            No Blueprints found.
          </Text>
        </div>
      ) : (
        (blueprintSearchResults.blueprints ?? []).map((blueprintResult) => {
          return (
            blueprintResult.latest_blueprint_version && (
              <BlueprintsSearchTableCard
                key={blueprintResult.id}
                blueprintResult={blueprintResult}
                integrationID={integrationID}
                setIsArchiveModalShown={setIsArchiveModalShown}
                setSelectedBlueprint={setSelectedBlueprint}
              />
            )
          );
        })
      )}
    </div>
  );
};

export default BlueprintsSearchTableResults;
