import clsx from "clsx";

interface Props {
  stepImg: string | undefined;
  stepIconClassName: string | undefined;
}

const StepCardIcon = ({ stepImg, stepIconClassName }: Props) => {
  return (
    <div className="self-center">
      {stepImg ? (
        <img src={stepImg} className="card-image items-center" alt="" />
      ) : (
        <div className="card-image items-center justify-content-center trigger-card-image">
          <span className={clsx("fe text-white", stepIconClassName)} style={{ fontSize: "24px" }} />
        </div>
      )}
    </div>
  );
};

export default StepCardIcon;
