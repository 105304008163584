import { AdminAPICategory, Text } from "@merge-api/merge-javascript-shared";
import SimpleCategoryCell from "../../../shared/SimpleCategoryCell";
// import useIntegrationBuilderContext from "src/components/integration-builder/context/useIntegrationBuilderContext";

interface Props {
  commonModelID: string;
  alignIconLeft?: boolean;
  isBold?: boolean;
}

const CommonModelDisplay = ({ commonModelID, alignIconLeft = false, isBold = false }: Props) => {
  const category = commonModelID?.split(".")[0];
  const commonModelName = commonModelID?.split(".")[1];
  // const { markedForDeletion } = useIntegrationBuilderContext();

  return (
    <div className={`flex flex-row`}>
      {alignIconLeft && <SimpleCategoryCell category={category as AdminAPICategory} />}
      <Text
        className={alignIconLeft ? "ml-2 text-gray-70" : "mr-2 text-gray-70"}
        variant={isBold ? "h6" : "md"}
      >
        {commonModelName}
      </Text>
      {!alignIconLeft && <SimpleCategoryCell category={category as AdminAPICategory} />}
    </div>
  );
};

export default CommonModelDisplay;
