import { ChevronRight } from "lucide-react";
import { Integration } from "../../../../../models/Entities";
import { IntegrationAvatar, AvatarSize } from "../../../../shared/MergeAvatars";
import { Text, Tooltip } from "@merge-api/merge-javascript-shared";
import CategoryStatusBadge from "../../../dashboard/components/IntegrationsDashboardTableRow/components/CategoryStatusBadge";

type IntegrationSummaryProps = {
  integration: Integration;
};

const IntegrationSummary = ({ integration }: IntegrationSummaryProps) => {
  return (
    <div className="flex flex-grow flex-row items-center justify-between">
      <div className="flex flex-row items-center">
        {/* Avatar */}
        <div className="mr-3">
          <IntegrationAvatar integration={integration} size={AvatarSize.normal} />
        </div>

        {/* Name / Categories */}
        <div className="pl-0 flex flex-col">
          <Text variant="h6" className="truncate w-40">
            <Tooltip title={integration?.name}>
              <div>{integration.name}</div>
            </Tooltip>
          </Text>
          <div className="mt-[1px]">
            {integration?.categories_status_info?.map((category_status) => (
              <CategoryStatusBadge
                key={category_status.category}
                category_status={category_status}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Right Arrow */}
      <div className="flex items-center">
        <ChevronRight size="12" color={"black"} />
      </div>
    </div>
  );
};

export default IntegrationSummary;
