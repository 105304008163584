import { AuthType } from "../../../../../models/Entities";
import MultiSelectHeader from "../../../shared/MultiSelectHeader";

interface OAuthTypeButtonsProps {
  actualAuthType: AuthType | undefined;
  setActualAuthType: React.Dispatch<React.SetStateAction<AuthType | undefined>>;
}

const OAuthTypeButtons = ({ actualAuthType, setActualAuthType }: OAuthTypeButtonsProps) => {
  const handleButtonClick = (value: AuthType) => {
    setActualAuthType(value);
  };

  const options = [
    {
      value: AuthType.OAUTH2,
      text: "OAUTH2",
      disabled: false,
      selected: actualAuthType === AuthType.OAUTH2,
      onClick: handleButtonClick,
    },
    {
      value: AuthType.OAUTH1,
      text: "OAUTH1",
      disabled: false,
      selected: actualAuthType === AuthType.OAUTH1,
      onClick: handleButtonClick,
    },
  ];

  return (
    <MultiSelectHeader
      dataTestID="field-auth-config-oauth-type"
      title="OAuth type"
      options={options}
      required
    />
  );
};

export default OAuthTypeButtons;
