import {
  APIEndpointPaginationConfigurationTypes,
  APIEndpointResponseTypes,
  APIEndpointTimestampFilterParamLocations,
  APIEndpointTypes,
  APIProtocols,
  APIRequestContentTypes,
} from "../../../models/APIEndpointModels";
import { APIRequestType } from "../../../models/Entities";
import { SelectOptionsData, StringOptionsData } from "../utils/types";

export const API_ENDPOINT_TYPE_DISPLAY_NAMES_WITH_PAGINATION = ["Pagination", "Live"];

export const API_ENDPOINT_TYPE_TO_DISPLAY_NAME = {
  [APIEndpointTypes.SINGLE_REQUEST]: "Single Request",
  [APIEndpointTypes.PAGINATION]: "Pagination",
  [APIEndpointTypes.LIVE]: "Live",
  [APIEndpointTypes.PROXY]: "Proxy",
  [APIEndpointTypes.WEB_CONNECTOR]: "Web Connector",
};

export const API_ENDPOINT_TYPE_DISPLAY_NAME_TO_TYPE = Object.fromEntries(
  Object.entries(API_ENDPOINT_TYPE_TO_DISPLAY_NAME).map(([key, value]) => [value, key])
);

export const API_PROTOCOL_OPTIONS_DATA: StringOptionsData[] = [
  { value: APIProtocols.REST, text: "REST (JSON)" },
  { value: APIProtocols.SOAP, text: "SOAP (XML)" },
  { value: APIProtocols.GRAPHQL, text: "GraphQL" },
];

export const API_ENDPOINT_TYPE_SELECT_OPTIONS: SelectOptionsData[] = [
  {
    value: APIEndpointTypes.SINGLE_REQUEST,
    title: API_ENDPOINT_TYPE_TO_DISPLAY_NAME[APIEndpointTypes.SINGLE_REQUEST],
    subtitle:
      "Indicates that this endpoint is not paginated (i.e. an endpoint that gets an individual employee data)",
  },
  {
    value: APIEndpointTypes.PAGINATION,
    title: API_ENDPOINT_TYPE_TO_DISPLAY_NAME[APIEndpointTypes.PAGINATION],
    subtitle:
      "Indicates that this endpoint is paginated. Will display endpoint as paginated in Blueprints (i.e. a paginated endpoint that gets all employees)",
  },
  {
    value: APIEndpointTypes.LIVE,
    title: API_ENDPOINT_TYPE_TO_DISPLAY_NAME[APIEndpointTypes.LIVE],
    subtitle:
      'Indicates that the endpoint is a live request. Can only be used in Live Request Blueprints, which are used to power Merge\'s File Picker unified "/live-fetch" endpoints',
  },
  {
    value: APIEndpointTypes.PROXY,
    title: API_ENDPOINT_TYPE_TO_DISPLAY_NAME[APIEndpointTypes.PROXY],
    subtitle:
      'Indicates that the endpoint is a proxy request. Can only be used in Proxy Blueprints, which are used to power Merge\'s unified "/download" endpoints',
  },
  {
    value: APIEndpointTypes.WEB_CONNECTOR,
    title: API_ENDPOINT_TYPE_TO_DISPLAY_NAME[APIEndpointTypes.WEB_CONNECTOR],
    subtitle:
      "Indicates that the endpoint is a web connector request. Can only be used in Web Connector Blueprints, which are used to power Merge's QuickBooks Desktop integration",
  },
];

export const API_ENDPOINT_CONTENT_TYPE_OPTIONS_DATA: StringOptionsData[] = [
  { value: APIRequestContentTypes.JSON, text: "application/json" },
  { value: APIRequestContentTypes.FORM_DATA, text: "multipart/form-data" },
  { value: APIRequestContentTypes.OCTET_STREAM, text: "application/octet-stream" },
];

export const API_ENDPOINT_REQUEST_TYPE_OPTIONS_DATA: StringOptionsData[] = [
  { value: APIRequestType.REST, text: "REST" },
  { value: APIRequestType.SOAP, text: "SOAP" },
  { value: APIRequestType.GRAPHQL, text: "GraphQL" },
];

export const API_ENDPOINT_RESPONSE_TYPE_OPTIONS_DATA: StringOptionsData[] = [
  { value: APIEndpointResponseTypes.JSON, text: "JSON" },
  { value: APIEndpointResponseTypes.XML, text: "XML" },
  { value: APIEndpointResponseTypes.BYTES, text: "Bytes" },
  { value: APIEndpointResponseTypes.IMAGE, text: "Image" },
  { value: APIEndpointResponseTypes.NUMBER, text: "Number" },
];

export const API_ENDPOINT_PAGINATION_CONFIGURATION_OPTIONS_DATA = [
  { value: APIEndpointPaginationConfigurationTypes.DEFAULT, text: "Use default" },
  {
    value: APIEndpointPaginationConfigurationTypes.SELECTED,
    text: "Select existing configuration",
  },
  { value: APIEndpointPaginationConfigurationTypes.NEW, text: "Add new configuration" },
];

export const BOOLEAN_OPTIONS_DATA = [
  { value: true, text: "Yes" },
  { value: false, text: "No" },
];

export const TIMESTAMP_FILTER_PARAM_LOCATION_OPTIONS_DATA: StringOptionsData[] = [
  { value: APIEndpointTimestampFilterParamLocations.BODY, text: "Body param" },
  { value: APIEndpointTimestampFilterParamLocations.QUERY_PARAM, text: "Query param" },
  {
    value: APIEndpointTimestampFilterParamLocations.URL_ENCODED_QUERY_PARAM,
    text: "URL encoded query param",
  },
];
