import { fetchWithAuth } from "../../../../api-client/api_client";
import { APIEndpointMethod, LinkedAccount } from "../../../../models/Entities";

interface Props {
  setIsLoadingTestLinkedAccounts: (isLoading: boolean) => void;
  setTestLinkedAccounts: (linkedAccounts: LinkedAccount[]) => void;
  setSelectedTestLinkedAccount: (linkedAccountID: string | undefined) => void;
}

const useFetchTestLinkedAccounts = ({
  setIsLoadingTestLinkedAccounts,
  setTestLinkedAccounts,
  setSelectedTestLinkedAccount,
}: Props) => {
  const fetchTestLinkedAccounts = (integrationID: string, category: string | null) => {
    setIsLoadingTestLinkedAccounts(true);
    const categoryFilter = category ? `?category=${category}` : "";
    return fetchWithAuth({
      path: `/integrations/${integrationID}/testable-linked-accounts${categoryFilter}`,
      method: APIEndpointMethod.GET,
      onResponse: (data: LinkedAccount[]) => {
        setIsLoadingTestLinkedAccounts(false);

        // Sort the data so that items with `organization.is_merge_org` being false come first
        const sortedData = data.sort((a, b) => {
          return Number(a.organization.is_merge_org) - Number(b.organization.is_merge_org);
        });

        setTestLinkedAccounts(sortedData);

        if (sortedData.length > 0) {
          setSelectedTestLinkedAccount(sortedData[0].id);
        }
      },
      onError: () => {
        setIsLoadingTestLinkedAccounts(false);
      },
    });
  };
  return { fetchTestLinkedAccounts };
};

export default useFetchTestLinkedAccounts;
