import { Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import MergeModal from "./MergeModal";
import SpinnerButton from "./SpinnerButton";
import { SmallTextMutedParagraph } from "./text/MergeText";

interface Props {
  selectedObjectType: string;
  associatedObjectsName?: string | null;
  show: boolean;
  onHide: () => void;
  isLoading: boolean;
  onConfirm: () => void;
  objectName?: string | null;
}

function DeletionConfirmationModal({
  selectedObjectType,
  associatedObjectsName = "step templates",
  show,
  onHide,
  isLoading,
  onConfirm,
  objectName = null,
}: Props) {
  const [isDeletionConfirmationChecked, setDeletionConfirmationChecked] = useState(false);

  useEffect(() => {
    setTimeout(function () {
      setDeletionConfirmationChecked(false);
    }, 3000);
  }, [show]);

  return (
    <MergeModal
      title={"Confirm Delete " + selectedObjectType}
      centered
      bodyClassName="modal-extra-narrow overflow-hidden"
      dialogClassName="modal-extra-narrow"
      show={show}
      onHide={onHide}
    >
      {objectName && <b>{objectName}</b>}
      <p>Are you sure you want to delete this {selectedObjectType}?</p>
      {associatedObjectsName && (
        <SmallTextMutedParagraph className="mt-3">
          Deleting will <span className="red font-weight-bold">permanently delete</span> any
          associated {associatedObjectsName} currently in use.
        </SmallTextMutedParagraph>
      )}
      <Form>
        <Form.Group controlId="confirmation" className="mt-6">
          <Form.Check
            type="checkbox"
            checked={isDeletionConfirmationChecked}
            onChange={() => setDeletionConfirmationChecked(!isDeletionConfirmationChecked)}
            label={<b>I understand that this is an irreversible action.</b>}
          />
        </Form.Group>
        <SpinnerButton
          disabled={!isDeletionConfirmationChecked}
          isLoading={isLoading}
          text="Confirm delete"
          className="btn-danger btn-block mt-1.5"
          onClick={onConfirm}
        />
        <Button variant="outline-dark" className="btn-block mt-1.5 border-0" onClick={onHide}>
          Cancel
        </Button>
      </Form>
    </MergeModal>
  );
}

export default DeletionConfirmationModal;
