import ScraperEditorControlRow from "./ScraperEditorControlRow";
import ScraperCanvas from "./ScraperCanvas";

const ScraperEditorCenterPanel = () => {
  return (
    <div className="flex-grow-1 d-flex justify-content-center">
      <div className="w-100">
        <ScraperEditorControlRow />
        <ScraperCanvas />
      </div>
    </div>
  );
};

export default ScraperEditorCenterPanel;
