import { AdminAPICategory, Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import { useEffect, useState } from "react";
import { SelectiveSyncFilterSchema } from "../../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import { APIEndpointParameterValues } from "../../../../api-endpoint-setup/components/request_tester/APIRequestTester";
import APIRequestTesterParamInputs from "../../../../api-endpoint-setup/components/request_tester/APIRequestTesterParamInputs";
import APIEndpointContextProvider from "../../../../api-endpoint-setup/context/APIEndpointContextProvider";
import LinkedAccountsTypeahead from "../../../../shared/LinkedAccountTypeAhead";
import { APIEndpointIntegrationBuilder } from "../../../../utils/Entities";
import { APIEndpoint } from "../../../../../integrations/versioned-components/types";
import TestRunResponseBlock, { TestRunResponse } from "../../../../../shared/TestRunResponseBlock";
import { LinkedAccount } from "../../../../../../models/Entities";
import { AbstractCondition } from "../../../types";
import APIEndpointsTypeahead from "../../api-endpoint-parameter/APIEndpointsTypeahead";
import APIRequestTesterAppliedConditionsContainer from "./APIRequestTesterAppliedConditionsContainer";
import useLoadAPIEndpoint from "../../../../api-endpoint-setup/hooks/useLoadAPIEndpoint";
import useRunTestAPIRequestWithSelectiveSyncFilters from "../../../../api-endpoint-setup/hooks/useRunTestAPIRequestWithSelectiveSyncFilters";
import useRunTestAPIRequest from "../../../../api-endpoint-setup/hooks/useRunTestAPIRequest";
import LoadingSpinnerWithText from "../../../../../shared/loading-spinner-with-text/LoadingSpinnerWithText";
import useIntegrationBuilderContext from "../../../../context/useIntegrationBuilderContext";
import { X } from "lucide-react";

interface Props {
  selectiveSyncFilterSchema: SelectiveSyncFilterSchema | undefined;
  integrationID: string;
}

const getCategoryFromCommonModelID = (commonModelID: string) => {
  return commonModelID.split(".")[0];
};

const APIRequestTesterWithSelectiveSyncFilters = ({
  selectiveSyncFilterSchema,
  integrationID,
}: Props) => {
  const { setIsRightPanelOpen } = useIntegrationBuilderContext();

  // Form data: endpoint, conditions, etc. to test
  const [apiEndpointInfo, setAPIEndpointInfo] = useState<APIEndpoint | null>(null);
  const [linkedAccountInfo, setLinkedAccountInfo] = useState<LinkedAccount | null>(null);
  const [abstractConditions, setAbstractConditions] = useState<Array<AbstractCondition>>([]);
  const [parameterValues, setParameterValues] = useState<APIEndpointParameterValues>({});

  const [testRunResponse, setTestRunResponse] = useState<TestRunResponse | undefined>();
  const [testRunResponseWithoutFilters, setTestRunResponseWithoutFilters] = useState<
    TestRunResponse | undefined
  >();

  const [apiEndpoint, setAPIEndpoint] = useState<APIEndpointIntegrationBuilder | null | undefined>(
    null
  );

  const category = getCategoryFromCommonModelID(
    selectiveSyncFilterSchema?.user_facing_filter_details?.filter_type_id?.common_model_class_id ??
      ""
  );

  const filterType = selectiveSyncFilterSchema?.user_facing_filter_details?.filter_type_id;

  // Used to update the conditions in the api tester
  const updateOrRemoveAbstractConditionAtIndex = (
    index: number,
    updatedCondition: AbstractCondition,
    removeCondition: boolean = false
  ) => {
    // Create a copy of the existing conditions array and update it at specified index
    const newConditions = [...abstractConditions];
    if (removeCondition) newConditions.splice(index, 1);
    else newConditions[index] = updatedCondition;
    setAbstractConditions(newConditions);
  };

  const { loadAPIEndpoint, isLoadingAPIEndpoint } = useLoadAPIEndpoint({
    integrationID,
    setAPIEndpoint,
    apiEndpointID: apiEndpointInfo?.id ?? null,
  });

  const {
    runTestAPIRequestWithSelectiveSyncFilters,
    isLoadingTestAPIRequestWithSelectiveSyncFilters,
  } = useRunTestAPIRequestWithSelectiveSyncFilters({
    abstractConditions,
    selectiveSyncFilterSchema,
    linkedAccountInfo,
    apiEndpointInfo,
    parameterValues,
    setTestRunResponse,
  });

  const { runTestAPIRequest, isLoadingTestAPIRequest } = useRunTestAPIRequest({
    linkedAccountID: linkedAccountInfo?.id ?? "",
    apiEndpoint,
    parameterValues,
    setTestRunResponse: setTestRunResponseWithoutFilters,
  });

  useEffect(() => {
    loadAPIEndpoint();
  }, [integrationID, apiEndpointInfo]);

  return (
    <div className="flex-col w-[516px] h-full overflow-y-auto bg-white border-l-[0.5px] border-l-gray-20">
      <div className="flex flex-row w-full space-x-2 items-center border-b-[0.5px] border-b-gray-20 px-5 py-4">
        <Button
          className="border-white"
          variant={ButtonVariant.IconShadowHidden}
          onClick={() => setIsRightPanelOpen(false)}
        >
          <X className="text-gray-50" size={20} />
        </Button>
        <Text variant="h4">API tester</Text>
      </div>
      <div className="p-4 space-y-4">
        <LinkedAccountsTypeahead
          integrationID={integrationID}
          category={category as AdminAPICategory}
          setLinkedAccountInfo={setLinkedAccountInfo}
          linkedAccountInfo={linkedAccountInfo}
        />
        <APIEndpointsTypeahead
          integrationID={integrationID}
          apiEndpointInfo={apiEndpointInfo}
          setAPIEndpointInfo={setAPIEndpointInfo}
          showSubtitle={false}
        />
        <APIRequestTesterAppliedConditionsContainer
          integrationID={integrationID}
          conditions={abstractConditions}
          setConditions={setAbstractConditions}
          filterType={filterType ?? null}
          updateOrRemoveAbstractConditionAtIndex={updateOrRemoveAbstractConditionAtIndex}
        />
        <APIEndpointContextProvider
          integrationID={integrationID}
          selectedAPIEndpoint={apiEndpoint ?? undefined}
        >
          <APIRequestTesterParamInputs setParameterValues={setParameterValues} />
        </APIEndpointContextProvider>
        <Button
          className="w-full"
          loading={
            isLoadingAPIEndpoint ||
            isLoadingTestAPIRequestWithSelectiveSyncFilters ||
            isLoadingTestAPIRequest
          }
          disabled={!(apiEndpointInfo?.id && linkedAccountInfo?.id)}
          onClick={() => {
            runTestAPIRequestWithSelectiveSyncFilters();
            runTestAPIRequest();
          }}
        >
          Test
        </Button>
        {isLoadingTestAPIRequestWithSelectiveSyncFilters && (
          <LoadingSpinnerWithText loadingText="Running API request with selective sync filter ..." />
        )}
        {isLoadingTestAPIRequest && (
          <LoadingSpinnerWithText loadingText="Running API request without selective sync filter ..." />
        )}
        {testRunResponse && testRunResponseWithoutFilters && (
          <TestRunResponseBlock
            response={testRunResponse}
            responseWithoutSelectiveSyncFilters={testRunResponseWithoutFilters}
          />
        )}
      </div>
    </div>
  );
};

export default APIRequestTesterWithSelectiveSyncFilters;
