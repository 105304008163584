import { Button, ButtonVariant, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import Callout from "../../../shared/Callout";
import ConfigureOptionCard from "./ConfigureOptionCard";
import { AuthConfigIntegrationBuilder, LinkChoiceStepOption } from "../../../utils/Entities";
import { useCallback, useState } from "react";
import { Info, Plus } from "lucide-react";
import {
  addLinkChoiceStepOption,
  deleteLinkChoiceStepOption,
  updateLinkChoiceStepOption,
} from "../../utils/helpers";

interface Props {
  indexStep: number;
  authConfigs: AuthConfigIntegrationBuilder[] | undefined;
  requestedLinkChoiceStepOptions: LinkChoiceStepOption[];
  setRequestedLinkChoiceStepOptions: React.Dispatch<React.SetStateAction<LinkChoiceStepOption[]>>;
}

const LinkChoiceStepOptionConfigurationFields = ({
  indexStep,
  authConfigs,
  requestedLinkChoiceStepOptions,
  setRequestedLinkChoiceStepOptions,
}: Props) => {
  const [isCalloutExpanded, setIsCalloutExpanded] = useState<boolean>(
    requestedLinkChoiceStepOptions?.length == 0
  );

  // Apply callback on update & delete functions, to prevent unnecessary re-rendering
  const onCallbackDeleteLinkChoiceStepOption = useCallback(
    (index: number) => {
      deleteLinkChoiceStepOption(index, setRequestedLinkChoiceStepOptions);
    },
    [setRequestedLinkChoiceStepOptions]
  );

  const onCallbackUpdateLinkChoiceStepOption = useCallback(
    (index: number, keyValuePairs: Record<string, any>) => {
      updateLinkChoiceStepOption(index, setRequestedLinkChoiceStepOptions, keyValuePairs);
    },
    [setRequestedLinkChoiceStepOptions]
  );

  return (
    <div className="border-t-[0.5px] border-gray-20 px-5 pt-4 mt-5 space-y-6 flex flex-column">
      <div className="flex flex-row items-center justify-start space-x-1">
        <Text variant="h6">Options</Text>
        {!isCalloutExpanded && (
          <Tooltip
            title={
              <Text>
                Options are presented to the end user as they make their way through the linking
                flow. Selection of an option by the end user will apply the override fields. <br />
                <br />
                <b>Warning:</b> Only one Choice step can be configured per-integration. If you would
                like to configure different Choice steps across multiple auth types, reach out to
                Merge support.
              </Text>
            }
          >
            <Info size={16} className="cursor-pointer" onClick={() => setIsCalloutExpanded(true)} />
          </Tooltip>
        )}
      </div>
      {isCalloutExpanded && (
        <Callout
          title="What is an option?"
          children={
            <Text>
              Options are presented to the end user as they make their way through the linking flow.
              Selection of an option by the end user will apply the override fields. <br />
              <b>Warning:</b> Only one Choice step can be configured per-integration. If you would
              like to configure different Choice steps across multiple auth types, reach out to
              Merge support.
            </Text>
          }
        />
      )}
      {(requestedLinkChoiceStepOptions || []).map((requestedLinkChoiceStepOption, index) => {
        return (
          <ConfigureOptionCard
            key={index}
            index={index}
            authConfigs={authConfigs}
            requestedLinkChoiceStepOption={requestedLinkChoiceStepOption}
            onCallbackDeleteLinkChoiceStepOption={onCallbackDeleteLinkChoiceStepOption}
            onCallbackUpdateLinkChoiceStepOption={onCallbackUpdateLinkChoiceStepOption}
          />
        );
      })}
      <Button
        leftIcon={<Plus size={16} />}
        variant={ButtonVariant.TertiaryWhite}
        size="sm"
        onClick={() => addLinkChoiceStepOption(setRequestedLinkChoiceStepOptions)}
      >
        <div data-testid={`button-step-card-add-option-${indexStep}`}>Option</div>
      </Button>
    </div>
  );
};

export default LinkChoiceStepOptionConfigurationFields;
