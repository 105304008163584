import BasicPaginationConfigSetupOptions from "./BasicPaginationConfigSetupOptions";
import PaginationConfigurationEndpoints from "./PaginationConfigurationsEndpoints";
import RequestInformationSetupOptions from "./RequestInformationSetupOptions";
import ResponseInformationSetupOptions from "./ResponseInformationSetupOptions";
import AdvancedSetupOptions from "./advanced-configuration-setup/AdvancedSetupOptions";

interface PaginationConfigurationSetupOptionsProps {
  isNewPaginationConfiguration?: boolean;
  inlineSetup?: boolean;
  isFirstPaginationConfiguration?: boolean;
}

const PaginationConfigurationSetupOptions = ({
  isNewPaginationConfiguration,
  inlineSetup = false,
  isFirstPaginationConfiguration = false,
}: PaginationConfigurationSetupOptionsProps) => {
  return (
    <>
      <BasicPaginationConfigSetupOptions
        cardVariant={inlineSetup ? "outline" : "shadow"}
        isFirstPaginationConfiguration={isFirstPaginationConfiguration}
      />
      <RequestInformationSetupOptions cardVariant={inlineSetup ? "outline" : "shadow"} />
      <ResponseInformationSetupOptions cardVariant={inlineSetup ? "outline" : "shadow"} />
      <AdvancedSetupOptions cardVariant={inlineSetup ? "outline" : "shadow"} />
      {!isNewPaginationConfiguration && <PaginationConfigurationEndpoints />}
    </>
  );
};

export default PaginationConfigurationSetupOptions;
