import classNames from "classnames";
import React, { ReactNode } from "react";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { HeaderPretitle } from "../text/MergeText";

export interface Subtab {
  /**
   * If provided, a react element icon to show to the left of the label
   */
  icon?: React.ReactElement;
  label: string;
  destination?: string | { pathname: string; params: string };
  divider?: boolean;
  disabled?: boolean;
  disabled_tip_message?: string;
  header?: boolean;
}

interface Props {
  subtabs?: Array<Subtab>;
  children: ReactNode;
}

const SubLeftNavigationBar = ({ subtabs }: { subtabs?: Array<Subtab> }) => (
  <ul className="navbar-nav sub-navbar-nav">
    {subtabs &&
      subtabs.map((subtab) => {
        return (
          <>
            {subtab.divider && (
              <li key={subtab.label + "-"}>
                <hr key={subtab.label} className="my-3" />
              </li>
            )}
            <li key={subtab.label} className="nav-item">
              {subtab.destination && (
                <NavLink
                  className={classNames("nav-link sub-nav-link", subtab.disabled && "disabled")}
                  to={subtab.destination}
                  //@ts-ignore
                  isActive={(match, location: { params: string }) => {
                    if (!match) {
                      return false;
                    }
                    return (
                      typeof subtab.destination == "string" ||
                      location.params === subtab.destination?.params ||
                      (!location.params && subtab.destination?.params === "")
                    );
                  }}
                >
                  {subtab.icon}
                  {subtab.label}
                </NavLink>
              )}
              {subtab.header && (
                <div className="nav-link sub-nav-link">
                  <HeaderPretitle className="mb-1.5">{subtab.label}</HeaderPretitle>
                </div>
              )}
            </li>
          </>
        );
      })}
  </ul>
);

const SubLeftNavigationContainer = ({ subtabs, children }: Props) => (
  <Row className="justify-content-center">
    <Col className="col-12 col-lg-10 col-xl-8">
      <Row>
        <Col className="col-2">
          <SubLeftNavigationBar subtabs={subtabs} />
        </Col>
        <Col className="col-10">{children}</Col>
      </Row>
    </Col>
  </Row>
);

export default SubLeftNavigationContainer;
