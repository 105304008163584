import { Text } from "@merge-api/merge-javascript-shared";
import { SelectOptionsData } from "../utils/types";

interface SelectOptionProps {
  optionData: SelectOptionsData;
  isCurrentValue?: boolean;
}
const SelectOption = ({ optionData, isCurrentValue = false }: SelectOptionProps) => {
  return (
    <div className="flex flex-col">
      <Text variant="md">{optionData.title}</Text>
      {!isCurrentValue && <Text variant="sm">{optionData.subtitle}</Text>}
    </div>
  );
};

export default SelectOption;
