import { Tab, Tabs, TabsList } from "@merge-api/merge-javascript-shared";
import { LucideIcon } from "lucide-react";

export type IntegrationEditorNavbarSubtab = {
  key: string;
  navbarText: string;
  Icon: LucideIcon;
  content: React.ReactNode | (() => React.ReactNode);
};

type Props = {
  tabs: IntegrationEditorNavbarSubtab[];
  selectedTabKey: string;
  setSelectedTabKey: (tabKey: string) => void;
  className?: string;
};

const IntegrationEditorNavbar = ({ tabs, selectedTabKey, setSelectedTabKey, className }: Props) => {
  return (
    <Tabs
      className={className}
      value={selectedTabKey}
      variant="button"
      onChange={(_, value) => setSelectedTabKey(value as string)}
    >
      <TabsList>
        {tabs.map((tab) => {
          const { Icon, navbarText, key } = tab;
          return (
            <Tab key={key} value={key}>
              <div className="flex items-center">
                <Icon size="12px" className="mr-2" />
                {navbarText}
              </div>
            </Tab>
          );
        })}
      </TabsList>
    </Tabs>
  );
};

export default IntegrationEditorNavbar;
