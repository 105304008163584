import max from "lodash/max";
import { Button } from "react-bootstrap";

import { ScraperNestedParameterValues, ScraperValue, ScraperValueType } from "./types";
import ScraperTypeaheadFormField from "./ScraperTypeaheadFormField";
import FormField from "../blueprint-editor/right-panel/FormField";

interface Props {
  updateValue: (newValue: ScraperNestedParameterValues) => void;
  value: ScraperNestedParameterValues;
  valueKey: string;
}
const ScraperNestedParameterValueField = ({ updateValue, value, valueKey }: Props) => {
  const nestedValues = value.nested_parameter_values;

  const maxArgVal = max([
    ...Object.keys(nestedValues).map((nestedValue) => Number(nestedValue.slice(1))),
    0,
  ]) as number;

  const updateParameterValueFromNestedValues = (newNestedValues: {
    [key: string]: ScraperValue;
  }) => {
    updateValue({
      nested_parameter_values: newNestedValues,
      value_type: ScraperValueType.NESTED_PARAMETER_VALUES,
    });
  };

  const addNestedValue = () =>
    updateParameterValueFromNestedValues({
      ...nestedValues,
      ["x" + (maxArgVal + 1)]: {
        value_type: ScraperValueType.CONSTANT,
        constant: "",
      },
    });

  const updateExistingNestedValue = (key: string, newValue: ScraperValue | null) => {
    if (newValue) {
      updateParameterValueFromNestedValues({ ...nestedValues, [key]: newValue });
    }
  };

  const deleteArg = (key: string) => {
    const { [key]: _, ...newNestedValues } = nestedValues;
    updateParameterValueFromNestedValues(newNestedValues);
  };

  return (
    <FormField title={valueKey}>
      <div className="mt-6">
        {Object.entries(nestedValues).map(([key, value]) => (
          <ScraperTypeaheadFormField
            key={key}
            valueKey={key}
            updateValue={(newValue) => updateExistingNestedValue(key, newValue)}
            onInputChange={(input) => {
              updateParameterValueFromNestedValues({
                ...nestedValues,
                [key]: {
                  value_type: ScraperValueType.CONSTANT,
                  constant: input,
                },
              });
            }}
            value={value}
            onDelete={() => deleteArg(key)}
          />
        ))}
        <Button className="mt-6 mb-9 btn-block" onClick={addNestedValue}>
          Add New Parameter
        </Button>
      </div>
      <hr />
    </FormField>
  );
};

export default ScraperNestedParameterValueField;
