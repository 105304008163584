import { Badge, Text } from "@merge-api/merge-javascript-shared";
import {
  LinkedAccountTestSuiteExecution,
  MappingTestExecutionResult,
} from "../../../../../models/MappingTests";
import StatusIcon from "../StatusIcon";

type Props = {
  testSuiteExecution: LinkedAccountTestSuiteExecution;
};

const TestSuiteExecutionContainerTitle = ({ testSuiteExecution }: Props) => {
  const { linked_account_mapping_test_suite } = testSuiteExecution;

  const numberOfFailures = testSuiteExecution.mapping_test_executions.reduce((count, execution) => {
    if (execution.result === MappingTestExecutionResult.FAILURE) {
      return count + 1;
    } else {
      return count;
    }
  }, 0);

  return (
    <div className="flex flex-row items-center justify-between w-100">
      <div className="flex flex-col">
        <Text variant="pre-title" className="text-gray-50">
          Test Suite
        </Text>
        <div className="flex flex-row items-center h-fit w-full space-x-2">
          <div className="w-fit h-fit">
            <StatusIcon status={testSuiteExecution.status} />
          </div>
          <Text variant="h5">
            {linked_account_mapping_test_suite?.end_user_organization_name}{" "}
            {linked_account_mapping_test_suite.linked_account}
          </Text>
        </div>
      </div>
      <div>
        {numberOfFailures > 0 && (
          <Badge className="mr-5" color="red">{`${numberOfFailures} failures`}</Badge>
        )}
      </div>
    </div>
  );
};

export default TestSuiteExecutionContainerTitle;
