import styled from "styled-components";
import { getShortenedText } from "../../shared/utils/SharedComponentUtils";

const StepNoteStyledDiv = styled.div`
  font-size: 12px;
  padding-left: 20px;
  color: #b0b7c3;
`;

interface Props {
  stepNoteText: string | null;
}

const StepNotePreview = ({ stepNoteText }: Props) => (
  <StepNoteStyledDiv>{getShortenedText(stepNoteText, 75)[0]}</StepNoteStyledDiv>
);

export default StepNotePreview;
