import { getIntegrationBuilderAuthenticationConfigurationPathForId } from "../../../router/RouterUtils";
import IntegrationBuilderHeader from "../shared/IntegrationBuilderHeader";

interface AuthenticationConfigurationHeaderProps {
  isNewAuthConfig: boolean;
  integrationID: string;
}

const AuthenticationConfigurationHeader = ({
  isNewAuthConfig,
  integrationID,
}: AuthenticationConfigurationHeaderProps) => {
  return (
    <IntegrationBuilderHeader
      title={isNewAuthConfig ? "Add configuration" : "Edit configuration"}
      navButtonLink={getIntegrationBuilderAuthenticationConfigurationPathForId(integrationID)}
      navButtonText="Authentication configurations"
      showRequired
    />
  );
};

export default AuthenticationConfigurationHeader;
