import { fetchWithAuth } from "../../../api-client/api_client";
import { LinkedAccountAccessRequest } from "../../../models/Entities";

interface FetchLinkedAccountAccessRequestProps {
  setLinkedAccountAccessRequest: (
    linkedAccountAccessRequest: LinkedAccountAccessRequest | null
  ) => void;
  setIsUnauthorizedUser: (value: boolean) => void;
}

const useFetchLinkedAccountAccessRequest = ({
  setLinkedAccountAccessRequest,
  setIsUnauthorizedUser,
}: FetchLinkedAccountAccessRequestProps) => {
  const fetchLinkedAccountAccessRequest = () => {
    fetchWithAuth({
      path: `/integrations/linked-accounts/access-request`,
      method: "GET",
      onResponse: (data: LinkedAccountAccessRequest | null) => {
        setLinkedAccountAccessRequest(data);
        setIsUnauthorizedUser(false);
      },
      onError: (error) => {
        if (error?.status === 403) {
          setIsUnauthorizedUser(true);
        } else {
          setIsUnauthorizedUser(false);
          setLinkedAccountAccessRequest(null);
        }
      },
    });
  };

  return { fetchLinkedAccountAccessRequest };
};

export default useFetchLinkedAccountAccessRequest;
