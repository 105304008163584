import MergeModal from "../../shared/MergeModal";
import { Button, Col, Row } from "react-bootstrap";

type Props = {
  show?: boolean;
  onHide: () => void;
};

export default function AsyncTestRunUnsavedChangesModal({ show, onHide }: Props) {
  return (
    <>
      <MergeModal
        show={show ?? false}
        onHide={onHide}
        title={"Unsaved Changes"}
        bodyClassName="overflow-hidden"
      >
        <>
          <Row>
            <Col>
              <b>
                You have unsaved changes. You must save your changes in order to initiate an
                asynchronous test run.
              </b>
            </Col>
          </Row>
          <Row className="mt-6">
            <Col>
              <Button variant="primary" onClick={onHide}>
                Understood
              </Button>
            </Col>
          </Row>
        </>
      </MergeModal>
    </>
  );
}
