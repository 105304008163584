import { Badge, Text } from "@merge-api/merge-javascript-shared";
import { camelCaseToSentenceCase } from "../../../utils";

interface Props {
  additionalCardInfo: Record<string, string | string[]>;
}

const StepCardAdditionalInfo = ({ additionalCardInfo }: Props) => {
  return (
    <>
      {Object.entries(additionalCardInfo).map(([key, value]) => {
        if (value) {
          if (Array.isArray(value) && value.length) {
            return (
              <Text key={key} variant="sm" className="text-gray-60 space-y-1 space-x-1">
                {camelCaseToSentenceCase(key)}:{" "}
                {value.map((val, index) => (
                  <Badge key={index} size="sm">
                    {val}
                  </Badge>
                ))}
              </Text>
            );
          } else if (typeof value === "string") {
            return (
              <Text key={key} variant="sm" className="text-gray-60 space-y-1 space-x-1">
                {camelCaseToSentenceCase(key)}: {value}
              </Text>
            );
          }
        }
        return null;
      })}
    </>
  );
};

export default StepCardAdditionalInfo;
