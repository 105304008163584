import { RateLimitConfigurationIntegrationBuilder } from "../../../../utils/Entities";
import { Text } from "@merge-api/merge-javascript-shared";

interface RateLimitPublishDialogContentProps {
  rateLimitConfiguration: RateLimitConfigurationIntegrationBuilder;
  isInAPIEndpointForm?: boolean;
}

const RateLimitPublishDialogContent = ({
  rateLimitConfiguration,
  isInAPIEndpointForm = false,
}: RateLimitPublishDialogContentProps) => {
  return (
    <>
      {rateLimitConfiguration.is_active ? (
        <div className="space-y-2">
          <div>
            {isInAPIEndpointForm ? (
              <Text>
                Only active rate limits affect Integrations. Unpublishing will deactivate this rate
                limit once the API endpoint is saved.
              </Text>
            ) : (
              <Text>
                Only active rate limits affect Integrations. Unpublishing will deactivate this rate
                limit.
              </Text>
            )}
          </div>
          <div>
            <Text>Are you sure you want to unpublish?</Text>
          </div>
        </div>
      ) : (
        <div className="space-y-2">
          <div>
            {isInAPIEndpointForm ? (
              <Text>
                Only active rate limits affect Integrations. Publishing will activate this rate
                limit once the API endpoint is saved.
              </Text>
            ) : (
              <Text>
                Only active rate limits affect Integrations. Publishing will activate this rate
                limit.
              </Text>
            )}
          </div>
          <div>
            <Text>Are you sure you want to publish?</Text>
          </div>
        </div>
      )}
    </>
  );
};
export default RateLimitPublishDialogContent;
