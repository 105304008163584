import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { APIEndpointMethod } from "../../../../models/Entities";
import { navigateToIntegrationBuilderRateLimitConfigurationTable } from "../../../../router/RouterUtils";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import IntegrationBuilderContext from "../../context/IntegrationBuilderContext";
import { RateLimitConfigurationIntegrationBuilder } from "../../utils/Entities";

interface DeleteRateLimitConfigurationsProps {
  integrationID: string;
  setRateLimitConfigurations: React.Dispatch<
    React.SetStateAction<RateLimitConfigurationIntegrationBuilder[] | undefined>
  >;
  // used for submission within the API endpoint form - prevents navigation and multiple toast messages from displaying
  isSubmittingAPIEndpoint?: boolean;
}

const useDeleteRateLimitConfiguration = ({
  integrationID,
  setRateLimitConfigurations,
  isSubmittingAPIEndpoint = false,
}: DeleteRateLimitConfigurationsProps) => {
  // state
  const [
    isDeletingRateLimitConfigurationLoading,
    setIsDeletingRateLimitConfigurationLoading,
  ] = useState(false);

  // context
  const history = useHistory();
  const { setIsRateLimitConfigurationComplete } = useContext(IntegrationBuilderContext);

  // methods
  const deleteRateLimitConfiguration = (rateLimitConfigurationID?: string | null) => {
    if (!rateLimitConfigurationID) {
      return;
    }
    setIsDeletingRateLimitConfigurationLoading(true);
    fetchWithAuth({
      path: `/integrations/${integrationID}/rate-limit-configs/${rateLimitConfigurationID}`,
      method: APIEndpointMethod.DELETE,
      onResponse: () => {
        // in the API endpoint form we don't want to show this toast or update any rate limit configurations, as context handles that
        if (!isSubmittingAPIEndpoint) {
          showSuccessToast("Rate limit configuration successfully deleted!");
          // navigate to the rate limit configurations table page
          navigateToIntegrationBuilderRateLimitConfigurationTable(history, integrationID);
          // replaces rate limit configuration in rateLimitConfigurations array with new data
          setRateLimitConfigurations((prevRateLimitConfiguration) => {
            const updatedRateLimitConfigurations = prevRateLimitConfiguration?.filter(
              (configuration) => configuration.id !== rateLimitConfigurationID
            );
            if (updatedRateLimitConfigurations?.length === 0) {
              setIsRateLimitConfigurationComplete(false);
            }
            return updatedRateLimitConfigurations;
          });
        }
        setIsDeletingRateLimitConfigurationLoading(false);
      },
      onError: () => {
        showErrorToast("Failed to delete your rate limit configuration.");
        setRateLimitConfigurations([]);
      },
    });
  };

  return {
    deleteRateLimitConfiguration,
    isDeletingRateLimitConfigurationLoading,
  };
};

export default useDeleteRateLimitConfiguration;
