import React from "react";
import {
  APIEndpointIntegrationBuilder,
  PaginationConfigurationIntegrationBuilder,
} from "../../utils/Entities";

export interface PaginationConfigurationContextState {
  integrationID: string;
  endpoints: APIEndpointIntegrationBuilder[] | null;

  // Basic pagination configuration information
  name: string;
  setName: (name: string) => void;
  description: string;
  setDescription: (description: string) => void;
  paginationType: string;
  setPaginationType: (paginationType: string) => void;
  isDefaultForIntegration: boolean;
  setIsDefaultForIntegration: (isDefaultForIntegration: boolean) => void;

  // Pagination configuration request information
  paginationValueRequestLocation: string;
  setPaginationValueRequestLocation: (paginationValueRequestLocation: string) => void;
  paginationValueRequestLocationKeyPath: string[] | null;
  setPaginationValueRequestLocationKeyPath: React.Dispatch<React.SetStateAction<string[] | null>>;
  initialPaginationValue: number | null;
  setInitialPaginationValue: (initialPaginationValue: number | null) => void;
  // PaginationPageSizeInformation
  pageSize: number | null;
  setPageSize: (pageSize: number | null) => void;
  pageSizeRequestLocation: string | null;
  setPageSizeRequestLocation: (pageSizeRequestLocation: string | null) => void;
  pageSizeKeyPath: string[] | null;
  setPageSizeKeyPath: React.Dispatch<React.SetStateAction<string[] | null>>;

  // Pagination configuration response information
  paginatedResultsArrayKeyPath: string[] | null;
  setPaginatedResultsArrayKeyPath: React.Dispatch<React.SetStateAction<string[] | null>>;
  // PointerForNextRequestInformation
  pointerForNextRequestResponseLocation: string | null;
  setPointerForNextRequestResponseLocation: (
    pointerForNextRequestResponseLocation: string | null
  ) => void;
  pointerForNextRequestResponseBodyKeyPath: string[] | null;
  setPointerForNextRequestResponseBodyKeyPath: React.Dispatch<
    React.SetStateAction<string[] | null>
  >;
  // EndOfPaginationInformation
  endOfPaginationType: string;
  setEndOfPaginationType: (endOfPaginationType: string) => void;
  // --- EndOfPaginationMaxItemsInformation
  maxItemsValueLocation: string | null;
  setMaxItemsValueLocation: (maxItemsValueLocation: string | null) => void;
  maxItemsValueKeyPath: string[] | null;
  setMaxItemsValueKeyPath: React.Dispatch<React.SetStateAction<string[] | null>>;
  // --- EndOfPaginationBreakValueInformation
  breakValueType: string;
  setBreakValueType: (breakValueType: string) => void;
  breakValueResponseBodyKeyPath: string[] | null;
  setBreakValueResponseBodyKeyPath: React.Dispatch<React.SetStateAction<string[] | null>>;
  specificValueToBreakOn: any | null;
  setSpecificValueToBreakOn: (specificValueToBreakOn: any | null) => void;
  overrideInfiniteLoopConditionKeyPath: string[] | null;
  setOverrideInfiniteLoopConditionKeyPath: React.Dispatch<React.SetStateAction<string[] | null>>;

  // Pagination configuration session information
  isSessionPagination: boolean;
  setIsSessionPagination: (isSessionPagination: boolean) => void;
  sessionIDParamName: string | null;
  setSessionIDParamName: (value: string | null) => void;
  readMoreRequestHeaderOverrides: Object | null;
  setReadMoreRequestHeaderOverrides: (value: Object | null) => void;
  readMorePaginatedRequestBodyBaseConfig: string | null;
  setReadMorePaginatedRequestBodyBaseConfig: (value: string | null) => void;
  readMoreResponseBodyArrayKeyPath: string[] | null;
  setReadMoreResponseBodyArrayKeyPath: React.Dispatch<React.SetStateAction<string[] | null>>;
  responseSessionIDKeyPath: string[] | null;
  setResponseSessionIDKeyPath: React.Dispatch<React.SetStateAction<string[] | null>>;
  readMoreResponseMaxItemsKeyPath: string[] | null;
  setReadMoreResponseMaxItemsKeyPath: React.Dispatch<React.SetStateAction<string[] | null>>;

  // Pagination configuration sort information
  isSortAssistedPagination: boolean;
  setIsSortAssistedPagination: (isSortAssistedPagination: boolean) => void;
  sortFieldFilterRequestLocation: string | null;
  setSortFieldFilterRequestLocation: (value: string | null) => void;
  sortFieldFilterRequestKeyPath: string[] | null;
  setSortFieldFilterRequestKeyPath: React.Dispatch<React.SetStateAction<string[] | null>>;
  sortFieldFilterRequestFormat: Object | null;
  setSortFieldFilterRequestFormat: (value: Object | null) => void;
  sortFieldFilterResponseKeyPath: string[] | null;
  setSortFieldFilterResponseKeyPath: React.Dispatch<React.SetStateAction<string[] | null>>;

  // Form builder for pagination configuration data
  formPaginationConfigurationData: () => PaginationConfigurationIntegrationBuilder | null;

  // Can submit pagination configuration
  canSubmitPaginationConfiguration: boolean;
}

const PaginationConfigurationContext = React.createContext<PaginationConfigurationContextState>({
  integrationID: "",
  endpoints: [],

  // Basic pagination configuration information
  name: "",
  setName: () => {},
  description: "",
  setDescription: () => {},
  paginationType: "",
  setPaginationType: () => {},
  isDefaultForIntegration: false,
  setIsDefaultForIntegration: () => {},

  // Pagination configuration request information
  paginationValueRequestLocation: "",
  setPaginationValueRequestLocation: () => {},
  paginationValueRequestLocationKeyPath: null,
  setPaginationValueRequestLocationKeyPath: () => {},
  initialPaginationValue: null,
  setInitialPaginationValue: () => {},
  // PaginationPageSizeInformation
  pageSize: null,
  setPageSize: () => {},
  pageSizeRequestLocation: null,
  setPageSizeRequestLocation: () => {},
  pageSizeKeyPath: null,
  setPageSizeKeyPath: () => {},

  // Pagination configuration response information
  paginatedResultsArrayKeyPath: null,
  setPaginatedResultsArrayKeyPath: () => {},
  // PointerForNextRequestInformation
  pointerForNextRequestResponseLocation: null,
  setPointerForNextRequestResponseLocation: () => {},
  pointerForNextRequestResponseBodyKeyPath: null,
  setPointerForNextRequestResponseBodyKeyPath: () => {},
  // EndOfPaginationInformation
  endOfPaginationType: "",
  setEndOfPaginationType: () => {},
  // --- EndOfPaginationMaxItemsInformation
  maxItemsValueLocation: null,
  setMaxItemsValueLocation: () => {},
  maxItemsValueKeyPath: null,
  setMaxItemsValueKeyPath: () => {},
  // --- EndOfPaginationBreakValueInformation
  breakValueType: "",
  setBreakValueType: () => {},
  breakValueResponseBodyKeyPath: [],
  setBreakValueResponseBodyKeyPath: () => {},
  specificValueToBreakOn: null,
  setSpecificValueToBreakOn: () => {},
  overrideInfiniteLoopConditionKeyPath: null,
  setOverrideInfiniteLoopConditionKeyPath: () => {},

  // Pagination configuration session information
  isSessionPagination: false,
  setIsSessionPagination: () => {},
  sessionIDParamName: null,
  setSessionIDParamName: () => {},
  readMoreRequestHeaderOverrides: null,
  setReadMoreRequestHeaderOverrides: () => {},
  readMorePaginatedRequestBodyBaseConfig: null,
  setReadMorePaginatedRequestBodyBaseConfig: () => {},
  readMoreResponseBodyArrayKeyPath: null,
  setReadMoreResponseBodyArrayKeyPath: () => {},
  responseSessionIDKeyPath: null,
  setResponseSessionIDKeyPath: () => {},
  readMoreResponseMaxItemsKeyPath: null,
  setReadMoreResponseMaxItemsKeyPath: () => {},

  // Pagination configuration sort information
  isSortAssistedPagination: false,
  setIsSortAssistedPagination: () => {},
  sortFieldFilterRequestLocation: null,
  setSortFieldFilterRequestLocation: () => {},
  sortFieldFilterRequestKeyPath: null,
  setSortFieldFilterRequestKeyPath: () => {},
  sortFieldFilterRequestFormat: null,
  setSortFieldFilterRequestFormat: () => {},
  sortFieldFilterResponseKeyPath: null,
  setSortFieldFilterResponseKeyPath: () => {},

  // Form builder for pagination configuration data
  formPaginationConfigurationData: () => null,

  // Can submit pagination configuration
  canSubmitPaginationConfiguration: false,
});

export default PaginationConfigurationContext;
