import { useMemo, useState } from "react";
import {
  BlueprintGenericStepTemplate,
  BlueprintStepTemplate,
  BlueprintStepType,
  StepCoverageLevel,
  StepLoggingViewEnum,
} from "../../../models/Blueprints";
import { STEP_TYPES_TO_BE_DEPRECATED } from "../../../constants";
import { Badge, Text } from "@merge-api/merge-javascript-shared";

import BlueprintEditorRightPanelIfElseForm from "./BlueprintEditorRightPanelIfElseForm";
import BlueprintEditorRightPanelArrayLoopForm from "./BlueprintEditorRightPanelArrayLoopForm";
import BlueprintEditorRightPanelWhileLoopForm from "./BlueprintEditorRightPanelWhileLoopForm";
import BlueprintEditorRightPanelDateRangeLoopForm from "./BlueprintEditorRightPanelDateRangeLoopForm";
import BlueprintEditorRightPanelCreateOrUpdateForm from "./BlueprintEditorRightPanelCreateOrUpdateForm";
import BlueprintEditorRightPanelUpdateByModelIDForm from "./BlueprintEditorRightPanelUpdateByModelIDForm";
import BlueprintEditorRightPanelInitializeWrittenCommonModelForm from "./BlueprintEditorRightPanelInitializeWrittenCommonModelForm";
import BlueprintEditorRightPanelSetRemoteDeletedForm from "./BlueprintEditorRightPanelSetRemoteDeletedForm";
import BlueprintEditorRightPanelGetOrCreateByRemoteIDForm from "./BlueprintEditorRightPanelGetOrCreateByRemoteIDForm";
import BlueprintEditorRightPanelManyToManyOverrideForm from "./BlueprintEditorRightPanelManyToManyOverrideForm";
import BlueprintEditorRightPanelAPIRequestForm from "./BlueprintEditorRightPanelAPIRequestForm";
import BlueprintEditorRightPanelSwitchForm from "./BlueprintEditorRightPanelSwitchForm";
import BlueprintEditorRightPanelCommonModelLoopForm from "./BlueprintEditorRightPanelCommonModelLoopForm";
import BlueprintEditorRightPanelTraverseTreeForm from "./BlueprintEditorRightPanelTraverseTreeForm";
import BlueprintEditorRightPanelCustomFunctionForm from "./custom-function/BlueprintEditorRightPanelCustomFunctionForm";
import useBlueprintContext from "../context/useBlueprintContext";
import BlueprintEditorRightPanelAPIRequestLoopForm from "./BlueprintEditorRightPanelAPIRequestLoopForm";
import { Form } from "react-bootstrap";
import isEqual from "lodash/isEqual";
import BlueprintEditorRightPanelDataTransformForm from "./BlueprintEditorRightPanelDataTransformForm";
import BlueprintEditorRightPanelAddToArrayForm from "./BlueprintEditorRightPanelAddToArrayForm";
import BlueprintEditorRightPanelAddToDictionaryForm from "./BlueprintEditorRightPanelAddToDictionaryForm";
import BlueprintEditorRightPanelSetVariableForm from "./BlueprintEditorRightPanelSetVariableForm";
import BlueprintEditorRightPanelUpdateLinkedAccountForm from "./BlueprintEditorRightPanelUpdateLinkedAccountForm";
import BlueprintEditorRightPanelParseFromRemoteDataForm from "./BlueprintEditorRightPanelParseFromRemoteDataForm";
import BlueprintEditorRightPanelAssertForm from "./BlueprintEditorRightPanelAssertForm";
import BlueprintEditorRightPanelAddParamToEndpointForm from "./BlueprintEditorRightPanelAddParamToEndpointForm";
import {
  getBlueprintStepForStepID,
  calculateCoverageLevelForStep,
} from "../utils/BlueprintEditorUtils";
import BlueprintEditorRightPanelFileToUrlForm from "./BlueprintEditorRightPanelFileToUrlForm";
import BlueprintEditorRightPanelRunBlueprintForm from "./BlueprintEditorRightPanelRunBlueprintForm";
import BlueprintEditorRightPanelRaiseExceptionForm from "./BlueprintEditorRightPanelRaiseExceptionForm";
import BlueprintEditorRightPanelEndBlueprintForm from "./BlueprintEditorRightPanelEndBlueprintForm";
import BlueprintEditorRightPanelGetFirstInListForm from "./BlueprintEditorRightPanelGetFirstInListForm";
import BlueprintEditorRightPanelFileUrlToFileForm from "./BlueprintEditorRightPanelFileUrlToFileForm";
import BlueprintEditorRightPanelConcurrentRequestDataForm from "./BlueprintEditorRightPanelConcurrentRequestDataForm";
import BlueprintEditorRightPanelAddAvailableCustomFieldForm from "./BlueprintEditorRightPanelAddAvailableCustomFieldForm";
import BlueprintEditorRightPanelFetchCustomFieldMappingForm from "./BlueprintEditorRightPanelFetchCustomFieldMappingForm";
import BlueprintEditorRightPanelMetaAddEnumChoicesToFieldForm from "./BlueprintEditorRightPanelMetaAddEnumChoicesToFieldForm";
import BlueprintEditorRightPanelMetaAddLinkedAccountParamForm from "./BlueprintEditorRightPanelMetaAddLinkedAccountParamForm";
import BlueprintEditorRightPanelMetaAccessParentBPParamNamesForm from "./BlueprintEditorRightPanelMetaAccessParentBPParamNamesForm";
import BlueprintEditorRightPanelGetLinkedAccountFields from "./BlueprintEditorRightPanelGetLinkedAccountFields";
import BlueprintEditorRightPanelAddValidationProblemForm from "./BlueprintEditorRightPanelAddValidationProblemForm";
import BlueprintEditorRightPanelAddLinkedAccountAdditionalAuthForm from "./BlueprintEditorRightPanelAddLinkedAccountAdditionalAuthForm";
import BlueprintEditorRightPanelGetModifiedSinceForm from "./BlueprintEditorRightPanelGetModifiedSinceForm";
import BlueprintEditorRightPanelCreateOAuth1SignatureForm from "./BlueprintEditorRightPanelCreateOAuth1SignatureForm";
import BlueprintEditorRightPanelBatchAPIResponseForm from "./BlueprintEditorRightPanelBatchAPIResponseForm";
import IntegrationEditorNavbar from "../../shared/integration-editor-base/IntegrationEditorNavbar";
import { Code, File } from "lucide-react";
import styled from "styled-components";
import BlueprintEditorRightPanelGetByIdForm from "./BlueprintEditorRightPanelGetByIdForm";
import BlueprintEditorRightPanelGetRelationByIdForm from "./BlueprintEditorRightPanelGetRelationByIdForm";
import BlueprintEditorRightPanelCallFunctionalBPForm from "./BlueprintEditorRightPanelCallFunctionalBPForm";
import BlueprintEditorRightPanelReturnValuesToParentBPForm from "./BlueprintEditorRightPanelReturnValuesToParentBPForm";
import BlueprintEditorRightPanelMergeObjectsForm from "./BlueprintEditorRightPanelMergeObjectsForm";
import BlueprintEditorRightPanelWriteFileForm from "./BlueprintEditorRightPanelWriteFileForm";
import BlueprintEditorRightPanelGenericStepForm from "./BlueprintEditorRightPanelGenericStepForm";
import StepNoteEditorAndViewer from "./StepNoteEditorAndViewer";
import AlertNewStepTemplate from "./alert-new-step-template/AlertNewStepTemplate";

const StyledIntegrationEditorNavbar = styled(IntegrationEditorNavbar).attrs({ className: "px-3" })`
  margin-top: -16px;
`;

const ContentSection = styled.div`
  flex-grow: 1;
  padding: 16px 12px;
  overflow-x: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  & hr {
    margin-left: -12px;
    margin-right: -12px;
  }
`;

interface Props {
  stepID: string;
  stepTemplate: BlueprintStepTemplate | BlueprintGenericStepTemplate;
}

const StepForm = ({
  stepID,
  stepTemplate,
}: {
  stepID: string;
  stepTemplate: BlueprintStepTemplate | BlueprintGenericStepTemplate;
}) => {
  if (!Object.values(BlueprintStepType).includes(stepTemplate.step_type as BlueprintStepType)) {
    return <BlueprintEditorRightPanelGenericStepForm stepID={stepID} stepTemplate={stepTemplate} />;
  }
  const castedStepTemplate = stepTemplate as BlueprintStepTemplate;
  switch (stepTemplate.step_type) {
    case BlueprintStepType.IfElse:
      return <BlueprintEditorRightPanelIfElseForm />;
    case BlueprintStepType.ArrayLoop:
      return <BlueprintEditorRightPanelArrayLoopForm />;
    case BlueprintStepType.WhileLoop:
      return <BlueprintEditorRightPanelWhileLoopForm />;
    case BlueprintStepType.DateRangeLoop:
      return <BlueprintEditorRightPanelDateRangeLoopForm stepID={stepID} />;
    case BlueprintStepType.CreateOrUpdate:
      return (
        <BlueprintEditorRightPanelCreateOrUpdateForm
          stepID={stepID}
          stepTemplate={castedStepTemplate}
        />
      );
    case BlueprintStepType.UpdateByModelID:
      return (
        <BlueprintEditorRightPanelUpdateByModelIDForm
          stepID={stepID}
          stepTemplate={castedStepTemplate}
        />
      );
    case BlueprintStepType.InitializeWrittenCommonModel:
      return (
        <BlueprintEditorRightPanelInitializeWrittenCommonModelForm
          stepID={stepID}
          stepTemplate={castedStepTemplate}
        />
      );
    case BlueprintStepType.GetOrCreateByRemoteId:
      return <BlueprintEditorRightPanelGetOrCreateByRemoteIDForm stepID={stepID} />;
    case BlueprintStepType.ManyToManyOverride:
      return (
        <BlueprintEditorRightPanelManyToManyOverrideForm
          stepID={stepID}
          stepTemplate={castedStepTemplate}
        />
      );

    case BlueprintStepType.GetById:
      return <BlueprintEditorRightPanelGetByIdForm />;
    case BlueprintStepType.GetRelationById:
      return <BlueprintEditorRightPanelGetRelationByIdForm />;

    case BlueprintStepType.CreateQBXMLQuery:
    case BlueprintStepType.APIRequest:
    case BlueprintStepType.APIRequestProxy:
    case BlueprintStepType.APIRequestLive:
      return (
        <BlueprintEditorRightPanelAPIRequestForm
          stepID={stepID}
          stepTemplate={castedStepTemplate}
        />
      );
    case BlueprintStepType.APIRequestLoop:
      return (
        <BlueprintEditorRightPanelAPIRequestLoopForm
          stepID={stepID}
          stepTemplate={castedStepTemplate}
        />
      );
    case BlueprintStepType.CommonModelLoop:
      return (
        <BlueprintEditorRightPanelCommonModelLoopForm
          stepID={stepID}
          stepTemplate={castedStepTemplate}
        />
      );
    case BlueprintStepType.TraverseTree:
      return (
        <BlueprintEditorRightPanelTraverseTreeForm
          stepID={stepID}
          stepTemplate={castedStepTemplate}
        />
      );
    case BlueprintStepType.Switch:
      return <BlueprintEditorRightPanelSwitchForm />;
    case BlueprintStepType.CustomFunction:
      return <BlueprintEditorRightPanelCustomFunctionForm />;
    case BlueprintStepType.DataTransform:
      return <BlueprintEditorRightPanelDataTransformForm />;
    case BlueprintStepType.AddToArray:
      return <BlueprintEditorRightPanelAddToArrayForm />;
    case BlueprintStepType.AddToDictionary:
      return <BlueprintEditorRightPanelAddToDictionaryForm />;
    case BlueprintStepType.SetVariable:
      return <BlueprintEditorRightPanelSetVariableForm />;
    case BlueprintStepType.UpdateLinkedAccount:
      return <BlueprintEditorRightPanelUpdateLinkedAccountForm />;
    case BlueprintStepType.ParseFromRemoteData:
      return <BlueprintEditorRightPanelParseFromRemoteDataForm />;
    case BlueprintStepType.GetLinkedAccountFields:
      return (
        <BlueprintEditorRightPanelGetLinkedAccountFields
          stepID={stepID}
          stepTemplate={castedStepTemplate}
        />
      );
    case BlueprintStepType.Assert:
      return <BlueprintEditorRightPanelAssertForm />;
    case BlueprintStepType.AddParamToEndpoint:
      return <BlueprintEditorRightPanelAddParamToEndpointForm />;
    case BlueprintStepType.FileToUrl:
      return <BlueprintEditorRightPanelFileToUrlForm stepTemplate={castedStepTemplate} />;
    case BlueprintStepType.RunBlueprint:
      return <BlueprintEditorRightPanelRunBlueprintForm />;
    case BlueprintStepType.AddAvailableCustomField:
      return <BlueprintEditorRightPanelAddAvailableCustomFieldForm />;
    case BlueprintStepType.FetchCustomFieldMapping:
      return <BlueprintEditorRightPanelFetchCustomFieldMappingForm />;
    case BlueprintStepType.RaiseException:
      return <BlueprintEditorRightPanelRaiseExceptionForm />;
    case BlueprintStepType.EndBlueprint:
      return (
        <BlueprintEditorRightPanelEndBlueprintForm
          stepID={stepID}
          stepTemplate={castedStepTemplate}
        />
      );
    case BlueprintStepType.GetFirstInList:
      return <BlueprintEditorRightPanelGetFirstInListForm />;
    case BlueprintStepType.FileUrlToFile:
      return <BlueprintEditorRightPanelFileUrlToFileForm stepTemplate={castedStepTemplate} />;
    case BlueprintStepType.ConcurrentRequestLoop:
      return (
        <BlueprintEditorRightPanelConcurrentRequestDataForm
          stepID={stepID}
          stepTemplate={castedStepTemplate}
        />
      );
    case BlueprintStepType.SetRemoteDeleted:
      return (
        <BlueprintEditorRightPanelSetRemoteDeletedForm
          stepID={stepID}
          stepTemplate={castedStepTemplate}
        />
      );
    case BlueprintStepType.MetaAddEnumChoiceToField:
      return <BlueprintEditorRightPanelMetaAddEnumChoicesToFieldForm />;
    case BlueprintStepType.MetaAddLinkedAccountParam:
      return <BlueprintEditorRightPanelMetaAddLinkedAccountParamForm />;
    case BlueprintStepType.MetaAccessParentBPParamNames:
      return (
        <BlueprintEditorRightPanelMetaAccessParentBPParamNamesForm
          stepID={stepID}
          stepTemplate={castedStepTemplate}
        />
      );
    case BlueprintStepType.AddValidationProblem:
      return (
        <BlueprintEditorRightPanelAddValidationProblemForm stepTemplate={castedStepTemplate} />
      );
    case BlueprintStepType.CallFunctionalBP:
      return (
        <BlueprintEditorRightPanelCallFunctionalBPForm
          stepID={stepID}
          stepTemplate={castedStepTemplate}
        />
      );
    case BlueprintStepType.ReturnValuesToParentBP:
      return <BlueprintEditorRightPanelReturnValuesToParentBPForm />;
    case BlueprintStepType.AddLinkedAccountAdditionalAuth:
      return <BlueprintEditorRightPanelAddLinkedAccountAdditionalAuthForm />;
    case BlueprintStepType.GetModifiedSinceTimestampValue:
      return <BlueprintEditorRightPanelGetModifiedSinceForm />;
    case BlueprintStepType.CreateOAuth1Signature:
      return <BlueprintEditorRightPanelCreateOAuth1SignatureForm />;
    case BlueprintStepType.BatchAPIRespnse:
      return <BlueprintEditorRightPanelBatchAPIResponseForm />;
    case BlueprintStepType.MergeObjects:
      return <BlueprintEditorRightPanelMergeObjectsForm />;
    case BlueprintStepType.WriteFile:
      return <BlueprintEditorRightPanelWriteFileForm />;
    default:
      throw Error("Could not find step template.");
  }
};

const BlueprintEditorRightPanelExistingStepForm = ({
  stepID,
  stepTemplate,
}: Props): JSX.Element => {
  const {
    blueprint,
    stepTemplates,
    genericStepTemplates,
    replaceStepTemplate,
    setBlueprintStepNote,
    stepLoggingView,
    blueprintRunnerExecutionResponse,
  } = useBlueprintContext();
  const allStepTemplates = stepTemplates.concat(genericStepTemplates as BlueprintStepTemplate[]);

  const newStepTemplate = useMemo(() => {
    if (!allStepTemplates || !stepTemplate) {
      return null;
    }
    const pulledMatchingTemplate = allStepTemplates.find(
      (template) => template.id === stepTemplate.id
    );

    if (pulledMatchingTemplate && !isEqual(pulledMatchingTemplate, stepTemplate)) {
      return pulledMatchingTemplate;
    }
    return null;
  }, [allStepTemplates, stepTemplate]);

  const step = useMemo(() => {
    return getBlueprintStepForStepID(blueprint, stepID);
  }, [stepID, blueprint]);

  const willBeDeprecated = useMemo(() => {
    return step && step.template
      ? STEP_TYPES_TO_BE_DEPRECATED.includes(step.template.step_type)
      : true;
  }, [step]);

  const { selectedStep, setRunStepConcurrently } = useBlueprintContext();

  const runsConcurrently =
    selectedStep && selectedStep.template && selectedStep.template !== "ghost"
      ? selectedStep.run_concurrently
      : false;

  const updateStepConcurrency = () => {
    if (selectedStep && selectedStep.template && selectedStep.template !== "ghost") {
      setRunStepConcurrently(selectedStep.id, !runsConcurrently);
    }
  };

  const { coverageLevel, coverageMessage } = useMemo(() => {
    return calculateCoverageLevelForStep(
      blueprintRunnerExecutionResponse?.exit_data?.coverage?.step_coverage?.[stepID],
      false
    );
  }, [blueprintRunnerExecutionResponse?.exit_data?.coverage?.step_coverage?.[stepID]]);

  const logicTabContent = () => (
    <>
      {newStepTemplate && step && (
        <div className="pb-4">
          <AlertNewStepTemplate
            step={step}
            newStepTemplate={newStepTemplate}
            currentStepTemplate={stepTemplate}
            replaceStepTemplate={replaceStepTemplate}
          />
        </div>
      )}
      {stepTemplate.can_run_concurrently && (
        <>
          <Form.Group className="mb-0">
            <Form.Check
              key={`concurrent-check-${selectedStep?.id}}`}
              id={`concurrent-check-${selectedStep?.id}}`}
              type="switch"
              label="Run Concurrently"
              defaultChecked={runsConcurrently}
              onChange={updateStepConcurrency}
            />
          </Form.Group>
          <hr />
        </>
      )}
      <StepForm stepID={step?.id ?? ""} stepTemplate={stepTemplate} />
    </>
  );

  const stepNoteText = blueprint.step_notes.find((note) => note.step_id === stepID)?.text || "";
  const setStepNoteText = (newText: string) => setBlueprintStepNote(stepID, newText);
  const notesTabContent = () => (
    <StepNoteEditorAndViewer stepNoteText={stepNoteText} setStepNoteText={setStepNoteText} />
  );
  const tabs = [
    { key: "logic", navbarText: "Logic", Icon: Code, content: logicTabContent },
    {
      key: "notes",
      navbarText: stepNoteText ? "Notes" : "Add Notes",
      Icon: File,
      content: notesTabContent,
    },
  ];
  const [selectedTabKey, setSelectedTabKey] = useState(tabs[0].key);

  return (
    <>
      {stepLoggingView === StepLoggingViewEnum.STEP_COVERAGE ? (
        <div className="text-center">
          <Badge
            color={
              coverageLevel == StepCoverageLevel.MISSING
                ? "red"
                : coverageLevel == StepCoverageLevel.INCOMPLETE
                ? "yellow"
                : "teal"
            }
            size="lg"
            className="text-center w-fit mb-8"
          >
            {coverageMessage}
          </Badge>
        </div>
      ) : (
        <></>
      )}
      {willBeDeprecated ? (
        <>
          <div className="text-center bg-red-0 p-2 px-4 mb-3">
            <Text variant="h6" className="text-red-50">
              This step will be deprecated.
              <br />
              Please use AddToDictionary, AddToArray, and/or custom function steps.
            </Text>
          </div>
        </>
      ) : (
        <></>
      )}
      <StyledIntegrationEditorNavbar
        tabs={tabs}
        selectedTabKey={selectedTabKey}
        setSelectedTabKey={setSelectedTabKey}
      />
      <ContentSection>{tabs.find((tab) => tab.key === selectedTabKey)?.content()}</ContentSection>
    </>
  );
};

export default BlueprintEditorRightPanelExistingStepForm;
