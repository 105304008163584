import classNames from "classnames";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { BLUEPRINT_OPERATION_TYPES_WITH_SCHEDULES } from "../../../constants";
import { Blueprint, BlueprintTrigger } from "../../../models/Blueprints";
import MergeModal from "../../shared/MergeModal";
import SpinnerButton from "../../shared/SpinnerButton";
import { showErrorToast, showSuccessToast } from "../../shared/Toasts";
import useBlueprintContext from "../context/useBlueprintContext";
import { saveBlueprintAsDraft, saveBlueprintTrigger } from "../utils/BlueprintEditorAPIClient";
import { showErrorMessages } from "../utils/BlueprintEditorUtils";

type VersionControlSaveModalProps = {
  show?: boolean;
  onHide: () => void;
  setIsPolling?: (isPolling: boolean) => void;
  setLastSavedAt?: (lastSavedAt: Date) => void;
};

export const VersionControlSaveModal = ({
  show,
  onHide,
  setIsPolling,
  setLastSavedAt,
}: VersionControlSaveModalProps) => {
  return (
    <MergeModal
      title="Confirm Save Draft"
      show={show ?? false}
      onHide={onHide}
      bodyClassName="overflow-hidden"
    >
      <VersionControlSaveForm
        onHide={onHide}
        setIsPolling={setIsPolling}
        setLastSavedAt={setLastSavedAt}
      />
    </MergeModal>
  );
};

type VersionControlSaveFormProps = {
  onHide: () => void;
  setIsPolling?: (isPolling: boolean) => void;
  setLastSavedAt?: (lastSavedAt: Date) => void;
};

export const VersionControlSaveForm = ({
  onHide,
  setIsPolling,
  setLastSavedAt,
}: VersionControlSaveFormProps) => {
  const { register, handleSubmit, setError, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const {
    blueprint,
    blueprintTrigger,
    setOriginalBlueprint,
    setBlueprint,
    setBlueprintTrigger,
  } = useBlueprintContext();

  const onSubmit = (data: any) => {
    setIsLoading(true);
    // Track time of save if it exists
    setLastSavedAt && setLastSavedAt(new Date());

    saveBlueprintAsDraft({
      blueprint,
      history,
      onSuccess: (blueprint: Blueprint) => {
        setBlueprint(blueprint);
        setOriginalBlueprint(blueprint);
        showSuccessToast("Saved blueprint as a draft!");
        setIsLoading(false);
        setIsPolling && setIsPolling(true);
        onHide();
      },
      onError: (err: Response | undefined) => {
        setIsLoading(false);
        showErrorMessages(err, "Failed to save blueprint as a draft.", setError);
      },
      comment: data.comment,
    });

    if (
      blueprintTrigger &&
      BLUEPRINT_OPERATION_TYPES_WITH_SCHEDULES.includes(blueprint.operation_type)
    ) {
      saveBlueprintTrigger({
        blueprintTrigger,
        blueprintID: blueprint.id,
        onSuccess: (trigger: BlueprintTrigger) => {
          setBlueprintTrigger(trigger);
        },
        onError: (_) => {
          showErrorToast("Failed to save blueprint trigger.");
        },
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Form.Group controlId="comment">
        <Form.Label>Leave a comment:</Form.Label>
        <Form.Control
          name="comment"
          type="text"
          className={classNames({ "is-invalid": errors.comment })}
          ref={register({ required: true })}
        />
        <Form.Control.Feedback type="invalid">
          Please enter a comment for this blueprint version.
        </Form.Control.Feedback>
      </Form.Group>

      <SpinnerButton
        isLoading={isLoading}
        text="Confirm save"
        className="btn btn-primary btn-block"
      />
    </Form>
  );
};

export default VersionControlSaveModal;
