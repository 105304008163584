import { Text } from "@merge-api/merge-javascript-shared";
import { SelectiveSyncFilterSchema } from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import { formatRecentTimestamp } from "../../../../../models/Helpers";
import { navigateToIntegrationBuilderSelectiveSyncFilterBuilderForId } from "../../../../../router/RouterUtils";
import { VersionedComponentInfo } from "../../../../versioned-components/types";
import VersionedComponentPublishStateDescriptionBadge from "../../../shared/VersionedComponentPublishStateDescriptionBadge";
import CommonModelDisplay from "../shared/CommonModelDisplay";
import { useHistory } from "react-router-dom";
import { ConditionOperator, CONDITION_OPERATORS } from "../../types";

interface Props {
  id: string;
  integrationID: string;
  selectiveSyncFilterSchema: VersionedComponentInfo<SelectiveSyncFilterSchema>;
}

const SelectiveSyncFiltersTableRow = ({ id, integrationID, selectiveSyncFilterSchema }: Props) => {
  const { next_version, component_state, published_version } = selectiveSyncFilterSchema;
  const { user_facing_filter_details, filter_mapping_details } =
    next_version ?? published_version ?? {};

  const filterType = user_facing_filter_details?.remote_key_name;
  const operators = Object.keys(filter_mapping_details ?? {})
    .map((operator) => CONDITION_OPERATORS[operator as ConditionOperator])
    .join(", ");

  const history = useHistory();

  const modifiedAt = (next_version ?? published_version)?.modified_at;
  const commonModelID = user_facing_filter_details?.filter_type_id?.common_model_class_id;

  const navigateToSelectiveSyncFilterView = () => {
    navigateToIntegrationBuilderSelectiveSyncFilterBuilderForId(history, integrationID, false, id);
  };

  return (
    <tr key={id} onClick={() => navigateToSelectiveSyncFilterView()} className="cursor-pointer">
      <td>
        <CommonModelDisplay commonModelID={commonModelID ?? ""} />
      </td>
      <td>
        <Text>{filterType}</Text>
      </td>
      <td>
        <Text>{operators}</Text>
      </td>
      {/*S2TODO: Populate the last user and show time diff instead of date - https://app.asana.com/0/1205644398660644/1207534868357520 */}
      <td>
        <Text>{formatRecentTimestamp(modifiedAt)}</Text>
      </td>
      <td>
        <VersionedComponentPublishStateDescriptionBadge publishState={component_state} />
      </td>
    </tr>
  );
};

export default SelectiveSyncFiltersTableRow;
