import { Alert, Text } from "@merge-api/merge-javascript-shared";
import { ChevronRight, OctagonX } from "lucide-react";
import {
  MappingTestAndVersions,
  MappingTestExecution,
} from "../../../../../../../models/MappingTests";
import usePublishModuleMappingTestContext from "../../../../../context/usePublishModuleMappingTestContext";

const MappingTestExecutionFailureCard = ({
  mappingTest,
  testExecution,
}: {
  mappingTest: MappingTestAndVersions;
  testExecution: MappingTestExecution;
}) => {
  const {
    setMappingTest,
    setMappingTestExecution,
    setIsShowingMappingTestExecutionModal,
  } = usePublishModuleMappingTestContext();

  return (
    <Alert
      className="m-4"
      color="red"
      size="sm"
      icon={<OctagonX className="text-red-50" size={16} />}
      title={
        <div
          className="cursor-pointer flex flex-row justify-between items-center py-2 px-3"
          onClick={() => {
            setMappingTestExecution(testExecution);
            setMappingTest(mappingTest);
            setIsShowingMappingTestExecutionModal(true);
          }}
        >
          <div className="flex flex-row items-center text-left space-x-2">
            <Text variant="md">{mappingTest.name}</Text>
          </div>
          <div>
            <ChevronRight size={12} className="text-gray-50" />
          </div>
        </div>
      }
    />
  );
};

export default MappingTestExecutionFailureCard;
