import { ArrowUpRight } from "lucide-react";
import clsx from "clsx";

type color = "red" | "blue";

interface Props {
  text: string;
  color?: color;
  hasArrow?: boolean;
  onClick?: () => void;
  className?: string;
  dataTestID?: string;
}

const ClickableText = ({
  text,
  color = "blue",
  hasArrow = true,
  onClick,
  className,
  dataTestID,
}: Props) => {
  return (
    <div
      data-testid={dataTestID}
      onClick={onClick}
      className={clsx(
        "flex flex-row items-center cursor-pointer whitespace-nowrap",
        color === "blue" && "text-blue-40 hover:text-blue-60 ",
        color === "red" && "text-red-50 hover:text-red-70 ",
        className
      )}
    >
      <div className="text-sm font-medium">{text}</div>
      {hasArrow && <ArrowUpRight className="ml-1" size={12} />}
    </div>
  );
};

export default ClickableText;
