import { Accordion, Card, Text } from "@merge-api/merge-javascript-shared";
import EndpointCollectionContainer from "./EndpointCollection/EndpointCollectionContainer";
import TestSuiteExecutionContainerTitle from "./TestSuiteExecutionContainerTitle";
import {
  LinkedAccountTestSuiteExecution,
  MappingTestExecution,
  MappingTestExecutionResult,
  MappingTestSuiteEndpointCollectionInfo,
} from "../../../../../models/MappingTests";

const TestSuiteExecutionContainer = ({
  testSuiteExecution,
}: {
  testSuiteExecution: LinkedAccountTestSuiteExecution;
}) => {
  const { linked_account_mapping_test_suite } = testSuiteExecution;

  const getExecutionsForEndpointCollection = (
    endpointCollection: MappingTestSuiteEndpointCollectionInfo,
    testExecutions: Array<MappingTestExecution>
  ): Array<MappingTestExecution> => {
    const endpointCollectionTestIDs = new Set(
      endpointCollection?.test_cases.map((test) => test?.id)
    );

    return testExecutions.filter((execution: MappingTestExecution) =>
      endpointCollectionTestIDs.has(execution.mapping_test_id)
    );
  };

  function createMappingTestExecutionsDictionary(
    testSuiteExecution: LinkedAccountTestSuiteExecution
  ): { [key: string]: Array<MappingTestExecution> } {
    const dictionary: { [key: string]: Array<MappingTestExecution> } = {};

    testSuiteExecution.mapping_test_executions.forEach((execution) => {
      const key = execution?.endpoint_test_collection_id;
      if (key) {
        if (!dictionary[key]) {
          dictionary[key] = [];
        }
        // Append the execution to the array of executions for this key
        dictionary[key].push(execution);
      }
    });

    return dictionary;
  }

  const groupEndppointIDsByResult = (executionsDictionary: {
    [key: string]: Array<MappingTestExecution>;
  }): {
    endpoint_test_collection_ids_with_failures: { [key: string]: number };
    endpoint_test_collection_ids_with_all_success: string[];
  } => {
    const endpoint_test_collection_ids_with_failures: { [key: string]: number } = {};
    const endpoint_test_collection_ids_with_all_success: string[] = [];

    for (const [key, executions] of Object.entries(executionsDictionary)) {
      const failureCount = executions.filter(
        (execution) => execution.result === MappingTestExecutionResult.FAILURE
      ).length;
      if (failureCount > 0) {
        endpoint_test_collection_ids_with_failures[key] = failureCount;
      }

      if (
        executions.every((execution) => execution.result === MappingTestExecutionResult.SUCCESS)
      ) {
        endpoint_test_collection_ids_with_all_success.push(key);
      }
    }

    return {
      endpoint_test_collection_ids_with_failures,
      endpoint_test_collection_ids_with_all_success,
    };
  };

  const endpointIDsByResult = groupEndppointIDsByResult(
    createMappingTestExecutionsDictionary(testSuiteExecution)
  );

  // Helper functions for sorting endpoint collections by associated test execution results
  // This is important because in the UI we will group endpoint collections by ones that have failures,
  // ones that have all successes, and ones that have no executions
  const endpointCollectionsWithFailures = (): MappingTestSuiteEndpointCollectionInfo[] =>
    linked_account_mapping_test_suite?.endpoint_collections.filter((collection) =>
      Object.keys(endpointIDsByResult.endpoint_test_collection_ids_with_failures).includes(
        collection?.id ?? ""
      )
    );

  const endpointCollectionsWithSuccesses = (): MappingTestSuiteEndpointCollectionInfo[] =>
    linked_account_mapping_test_suite?.endpoint_collections.filter((collection) =>
      endpointIDsByResult.endpoint_test_collection_ids_with_all_success.includes(
        collection?.id ?? ""
      )
    );

  const endpointCollectionsWithNoExecutions = (): MappingTestSuiteEndpointCollectionInfo[] =>
    linked_account_mapping_test_suite?.endpoint_collections
      .filter(
        (endpointCollection) => endpointCollection?.operation_type && endpointCollection?.test_cases
      )
      .filter(
        (collection) =>
          !Object.keys(endpointIDsByResult.endpoint_test_collection_ids_with_failures).includes(
            collection?.id ?? ""
          ) &&
          !endpointIDsByResult.endpoint_test_collection_ids_with_all_success.includes(
            collection?.id ?? ""
          )
      );

  const testSuiteHasMappingTestExecutions =
    (testSuiteExecution?.mapping_test_executions ?? []).length > 0;

  return (
    <Accordion
      chevronSize={12}
      chevronOrientation="right"
      variant="outline"
      onChange={function noRefCheck() {}}
      title={<TestSuiteExecutionContainerTitle testSuiteExecution={testSuiteExecution} />}
      titleClassName="px-4 py-3"
    >
      {testSuiteHasMappingTestExecutions ? (
        <div>
          {/* ENDPOINTS WITH FAILURES*/}
          <div className="mb-4 mx-4 mt-2">
            {endpointCollectionsWithFailures().map((endpointCollection) => (
              <EndpointCollectionContainer
                endpointCollection={endpointCollection}
                mappingTestExecutions={getExecutionsForEndpointCollection(
                  endpointCollection,
                  testSuiteExecution?.mapping_test_executions ?? []
                )}
                testExecutionStatus={testSuiteExecution.status}
              />
            ))}
          </div>
          {/* ENDPOINTS WITH SUCCESSES*/}
          {endpointCollectionsWithSuccesses().length > 0 && (
            <Card className="mb-4 mx-4" variant="outline">
              {endpointCollectionsWithSuccesses().map((endpointCollection, index) => (
                <EndpointCollectionContainer
                  endpointCollection={endpointCollection}
                  mappingTestExecutions={getExecutionsForEndpointCollection(
                    endpointCollection,
                    testSuiteExecution?.mapping_test_executions ?? []
                  )}
                  testExecutionStatus={testSuiteExecution.status}
                  isGrouped={true}
                  isLastElement={
                    endpointCollectionsWithSuccesses().length > 0
                      ? index == endpointCollectionsWithSuccesses().length - 1
                      : undefined
                  }
                />
              ))}
            </Card>
          )}
          {/* ENDPOINTS WITH NO EXECUTIONS */}
          {endpointCollectionsWithNoExecutions().length > 0 && (
            <Card className="mb-4 mx-4" variant="outline">
              {endpointCollectionsWithNoExecutions().map((endpointCollection, index) => (
                <EndpointCollectionContainer
                  endpointCollection={endpointCollection}
                  mappingTestExecutions={getExecutionsForEndpointCollection(
                    endpointCollection,
                    testSuiteExecution?.mapping_test_executions ?? []
                  )}
                  testExecutionStatus={testSuiteExecution.status}
                  isGrouped={true}
                  isLastElement={
                    endpointCollectionsWithNoExecutions().length > 0
                      ? index == endpointCollectionsWithNoExecutions().length - 1
                      : undefined
                  }
                />
              ))}
            </Card>
          )}
        </div>
      ) : (
        <Card className="mb-4 mx-4" variant="none">
          <Text className="text-gray-50">
            No mapping test executions found. Check that tests are staged or published
          </Text>
        </Card>
      )}
    </Accordion>
  );
};

export default TestSuiteExecutionContainer;
