import { Card } from "@merge-api/merge-javascript-shared";
import { useStagedComponentContext } from "../context/StagedComponentContext";
import StagedComponentContextProvider from "../context/StagedComponentContextProvider";
import { ValidatedStagedComponent } from "../types";
import StagedComponentRulesAccordion from "./StagedComponentRulesAccordion";
import StagedComponentVersionCardTitle from "./StagedComponentVersionCardTitle";

type Props = {
  stagedComponent: ValidatedStagedComponent;
  integrationID: string;
};

const PublishModuleStagedComponentVersionCardV2 = () => {
  const {
    newBlockingRules,
    newWarningRules,
    existingBlockingRules,
    existingWarningRules,
    passedRules,
    overriddenRules,
  } = useStagedComponentContext();

  return (
    <Card className="px-5 pt-5 divide-y divide-solid divide-gray-10">
      <StagedComponentVersionCardTitle className="pb-4" />
      <StagedComponentRulesAccordion
        ruleResults={[...newBlockingRules, ...newWarningRules]}
        title={"New alerts"}
        learnMoreText="Alerts since this component was last published. These alerts require action."
        isCollapsible={false}
        className="py-4"
      />
      <StagedComponentRulesAccordion
        ruleResults={[...existingBlockingRules, ...existingWarningRules]}
        title={"Existing alerts"}
        learnMoreText="Alerts from previous publishes. You can publish a blueprint without overriding these alerts."
        className="py-4"
      />
      <StagedComponentRulesAccordion ruleResults={passedRules} title={"Passed"} className="py-4" />
      <StagedComponentRulesAccordion
        ruleResults={overriddenRules}
        title={"Overridden"}
        className="py-4"
      />
    </Card>
  );
};

const PublishModuleStagedComponentVersionCardV2WithContext = ({
  stagedComponent,
  integrationID,
}: Props) => {
  return (
    <StagedComponentContextProvider stagedComponent={stagedComponent} integrationID={integrationID}>
      <PublishModuleStagedComponentVersionCardV2 />
    </StagedComponentContextProvider>
  );
};

export default PublishModuleStagedComponentVersionCardV2WithContext;
