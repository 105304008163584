import { Badge, Link, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { X } from "lucide-react";
import { StepParameterTracingTarget } from "../../../../../models/Blueprints";

interface Props {
  stepParameterToTrace: StepParameterTracingTarget;
  handleClickStep: () => void;
  handleClose: () => void;
}

const BlueprintCanvasTopBannerTitle = ({
  stepParameterToTrace,
  handleClickStep,
  handleClose,
}: Props) => {
  return (
    <div className="flex flex-row w-full items-center justify-between">
      <div className="flex flex-row items-center space-x-2 truncate">
        <Text variant="h6">
          Tracing {stepParameterToTrace.parameter_value_key} from{" "}
          <Link onClick={handleClickStep} variant="h6">
            {stepParameterToTrace.step_id}
          </Link>
        </Text>
        <Tooltip
          className="flex flex-row items-center cursor-pointer"
          title="Step tracing is in beta. Currently supported for a limited set of steps. Please reach out to Automation team for any requests!"
        >
          <Badge color="slate">Beta</Badge>
        </Tooltip>
      </div>
      <X size={16} className="text-black cursor-pointer" onClick={handleClose} />
    </div>
  );
};

export default BlueprintCanvasTopBannerTitle;
