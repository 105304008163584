import {
  TypeaheadComparatorClusterOption,
  TypeaheadComparatorEnum,
} from "@merge-api/merge-javascript-shared/dist/designSystem/molecules/Typeahead/types/types";
import {
  BlueprintExtendedOperationType,
  BlueprintStatus,
  BlueprintVersionPublishState,
} from "../../../../models/Blueprints";
import { APICategory } from "../../../../models/Entities";
import {
  BlueprintSearchFilterTypeaheadEnum,
  BlueprintSearchFilterSet,
  BlueprintSearchFilterTypeEnum,
  MapBlueprintSearchFilterTypeaheadDisplayName,
} from "../../../../models/blueprint-search/BlueprintSearchInputModels";

export enum SortTypeEnum {
  ALPHA = "alpha",
  REVERSE_ALPHA = "reverse-alpha",
  MODIFIED = "modified",
}

export const MapDisplaySortType = {
  [SortTypeEnum.ALPHA]: "A -> Z",
  [SortTypeEnum.REVERSE_ALPHA]: "Z -> A",
  [SortTypeEnum.MODIFIED]: "Last modified",
};

export const DEFAULT_TYPEAHEAD_COMPARATOR_CLUSTER_OPTIONS: TypeaheadComparatorClusterOption[] = [
  {
    value: BlueprintSearchFilterTypeaheadEnum.OPERATION_TYPE,
    label:
      MapBlueprintSearchFilterTypeaheadDisplayName[
        BlueprintSearchFilterTypeaheadEnum.OPERATION_TYPE
      ],
    comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ANY],
    target: {
      targetOptions: [
        {
          value: BlueprintExtendedOperationType.FETCH,
          label: BlueprintExtendedOperationType.FETCH,
        },
      ],
    },
  },
];

export const DEFAULT_OPERATION_TYPE_TYPEAHEAD_OPTION: TypeaheadComparatorClusterOption = {
  value: BlueprintSearchFilterTypeaheadEnum.OPERATION_TYPE,
  label:
    MapBlueprintSearchFilterTypeaheadDisplayName[BlueprintSearchFilterTypeaheadEnum.OPERATION_TYPE],
  comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ANY],
  target: {
    targetOptions: Object.values(BlueprintExtendedOperationType).map((operationType) => ({
      value: operationType,
      label: operationType,
    })),
  },
};

export const DEFAULT_CATEGORY_TYPEAHEAD_OPTION: TypeaheadComparatorClusterOption = {
  value: BlueprintSearchFilterTypeaheadEnum.CATEGORY,
  label: MapBlueprintSearchFilterTypeaheadDisplayName[BlueprintSearchFilterTypeaheadEnum.CATEGORY],
  comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ANY],
  target: {
    targetOptions: Object.values(APICategory).map((category) => ({
      value: category,
      label: category.toUpperCase(),
    })),
  },
};

export const DEFAULT_PUBLISHED_STATUS_TYPEAHEAD_OPTION: TypeaheadComparatorClusterOption = {
  value: BlueprintSearchFilterTypeaheadEnum.PUBLISHED_STATUS,
  label:
    MapBlueprintSearchFilterTypeaheadDisplayName[
      BlueprintSearchFilterTypeaheadEnum.PUBLISHED_STATUS
    ],
  comparatorOptions: [TypeaheadComparatorEnum.EQUALS],
  target: {
    targetOptions: Object.values(BlueprintVersionPublishState).map((status) => ({
      value: status,
      label: status,
    })),
  },
};

export const DEFAULT_ARCHIVED_STATUS_TYPEAHEAD_OPTION: TypeaheadComparatorClusterOption = {
  value: BlueprintSearchFilterTypeaheadEnum.ARCHIVED_STATUS,
  label:
    MapBlueprintSearchFilterTypeaheadDisplayName[
      BlueprintSearchFilterTypeaheadEnum.ARCHIVED_STATUS
    ],
  comparatorOptions: [TypeaheadComparatorEnum.EQUALS],
  target: {
    targetOptions: Object.values(BlueprintStatus).map((status) => ({
      value: status,
      label: status,
    })),
  },
};

export const FUNCTIONAL_BLUEPRINT_BLUEPRINT_SEARCH_FILTER_SET: BlueprintSearchFilterSet = {
  operation_type: {
    filter_type: BlueprintSearchFilterTypeEnum.CONTAINS_ANY,
    values: [BlueprintExtendedOperationType.FUNCTIONAL],
  },
};
