import { useMemo } from "react";
import {
  BlueprintParameterValue,
  BlueprintParameterValueType,
  BlueprintStep,
  BlueprintStepType,
} from "../../../models/Blueprints";
import { HeaderPretitle } from "../../shared/text/MergeText";
import useBlueprintContext from "../context/useBlueprintContext";
import { getCurrentStepParameterValue } from "../utils/BlueprintEditorUtils";
import DropdownFormField from "./DropdownFormField";

const MODIFIED_SINCE_STEP_CONSTANT = "step_template_id";
const MODIFIED_SINCE_STEP_METADATA_PARAMETER_NAME = "request_meta";

const BlueprintEditorRightPanelGetModifiedSinceForm = () => {
  const { stepTemplates, updateStepParameterValues, selectedStep } = useBlueprintContext();
  const step = selectedStep as BlueprintStep;

  const selectedTemplate = useMemo(() => {
    const parameterValue = getCurrentStepParameterValue(step, MODIFIED_SINCE_STEP_CONSTANT);
    const nullOrConstantValue = parameterValue && "constant" in parameterValue && parameterValue;
    const stepTemplateId = nullOrConstantValue && nullOrConstantValue.constant;
    return (
      stepTemplates.find((stepTemplate) => stepTemplateId === stepTemplate.id) ?? stepTemplateId
    );
  }, [step, stepTemplates]);

  return (
    <>
      <HeaderPretitle className="my-2">Parameters</HeaderPretitle>
      <DropdownFormField
        key={`${step.id}-template-selection`}
        currentValue={selectedTemplate == undefined ? undefined : selectedTemplate.id}
        onChange={(e) => {
          const template = stepTemplates.find((stepTemplate) => e.target.value === stepTemplate.id);
          if (template) {
            const parameterValues: { [key: string]: BlueprintParameterValue } = {
              [MODIFIED_SINCE_STEP_METADATA_PARAMETER_NAME]: {
                constant: template.metadata,
                value_type: BlueprintParameterValueType.constant,
              },
              [MODIFIED_SINCE_STEP_CONSTANT]: {
                constant: template.id,
                value_type: BlueprintParameterValueType.constant,
              },
            };
            updateStepParameterValues(step, parameterValues);
          } else {
            const parameterValues: { [key: string]: BlueprintParameterValue | null } = {
              [MODIFIED_SINCE_STEP_METADATA_PARAMETER_NAME]: null,
              [MODIFIED_SINCE_STEP_CONSTANT]: null,
            };
            updateStepParameterValues(step, parameterValues);
          }
        }}
        title="Step Template"
        placeholder="Select a step template"
        choices={stepTemplates.filter((stepTemplate) =>
          [BlueprintStepType.APIRequestLoop, BlueprintStepType.APIRequest].includes(
            stepTemplate.step_type
          )
        )}
      />
    </>
  );
};

export default BlueprintEditorRightPanelGetModifiedSinceForm;
