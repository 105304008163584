import { MinusCircle } from "lucide-react";
import { Button, Col, Form } from "react-bootstrap";
import {
  ResponseBodyAdditionalParsing,
  ResponseBodyAdditionalParsingParser,
  ResponseBodyAdditionalParsingRule,
} from "../../../models/Entities";
import ClickableContainer from "../../shared/ClickableContainer";
import { HeaderPretitle } from "../../shared/text/MergeText";

interface Props {
  responseBodyAdditionalParsing: ResponseBodyAdditionalParsing;
  setResponseBodyAdditionalParsing: (data: ResponseBodyAdditionalParsing) => void;
  showTitle?: boolean;
}

const makeNewParsingRule = (): ResponseBodyAdditionalParsingRule => ({
  path: "",
  parser: ResponseBodyAdditionalParsingParser.JSON,
});

const ResponseBodyAdditionalParsingForm = ({
  responseBodyAdditionalParsing,
  setResponseBodyAdditionalParsing,
  showTitle = true,
}: Props) => {
  const addNewParsingRule = () => {
    setResponseBodyAdditionalParsing([...responseBodyAdditionalParsing, makeNewParsingRule()]);
  };
  const removeParsingRule = (index: number) => {
    setResponseBodyAdditionalParsing(responseBodyAdditionalParsing.filter((_, i) => i !== index));
  };

  const handleParsingRuleChange = (
    index: number,
    newParsingRule: ResponseBodyAdditionalParsingRule
  ) => {
    setResponseBodyAdditionalParsing(
      responseBodyAdditionalParsing.map((parsingRule, i) =>
        index === i ? newParsingRule : parsingRule
      )
    );
  };
  return (
    <>
      {showTitle && (
        <Form.Row>
          <Col>
            <HeaderPretitle className="mt-3 mb-3">Path + Parser values</HeaderPretitle>
          </Col>
        </Form.Row>
      )}
      {responseBodyAdditionalParsing.map((parsingRule, index) => (
        <Form.Row
          key={`parsing-rule-${index}-of-${responseBodyAdditionalParsing.length}`}
          className="align-items-center mb-3"
        >
          <Col md={6} xs={7}>
            <Form.Control
              type="text"
              placeholder="Enter comma-separated path, or empty for root"
              value={parsingRule.path}
              onChange={(e) =>
                handleParsingRuleChange(index, {
                  ...parsingRule,
                  path: e.target.value,
                })
              }
            />
          </Col>
          <Col md={2} xs={4}>
            <Form.Control
              as="select"
              value={parsingRule.parser}
              className="custom-select"
              onChange={(e) =>
                handleParsingRuleChange(index, {
                  ...parsingRule,
                  parser: e.target.value as ResponseBodyAdditionalParsingParser,
                })
              }
            >
              <option>{ResponseBodyAdditionalParsingParser.JSON}</option>
              <option>{ResponseBodyAdditionalParsingParser.XML}</option>
            </Form.Control>
          </Col>
          <Col xs={1}>
            <ClickableContainer onClick={() => removeParsingRule(index)}>
              <MinusCircle className="red" strokeWidth={1.5} size={20} />
            </ClickableContainer>
          </Col>
        </Form.Row>
      ))}
      <Form.Row>
        <Col md={3} xs={6}>
          <div data-testid="button-api-endpoint-response-body-additional-parsing">
            <Button className="mt-0 mb-3 btn-block" onClick={addNewParsingRule}>
              + Additional Parsing
            </Button>
          </div>
        </Col>
      </Form.Row>
    </>
  );
};

export default ResponseBodyAdditionalParsingForm;
