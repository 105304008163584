import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { IntegrationCommonModelTemplate } from "../../../models/Entities";
import { Col, Card } from "react-bootstrap";
import ClickableContainer from "../../shared/ClickableContainer";
import { ChevronRight } from "lucide-react";
import MergeText, { SmallTextMutedParagraph } from "../../shared/text/MergeText";

import { navigateToUIDTemplateEditor } from "../../../router/RouterUtils";

const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OverflowSmallTextMutedParagraph = styled(SmallTextMutedParagraph)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 10px;
`;

type UIDTemplateCardsProps = {
  integrationID: string;
  templates: IntegrationCommonModelTemplate[];
  category: string;
  model: string;
  createTemplate: (integrationID: string, category: string, model: string) => void;
};

export const UIDTemplateCards = ({
  integrationID,
  templates,
  category,
  model,
  createTemplate,
}: UIDTemplateCardsProps) => {
  return (
    <>
      <Col>
        {templates &&
          templates
            .sort((a, b) => {
              const templateNameA = a.template_name.toUpperCase();
              const templateNameB = b.template_name.toUpperCase();
              if (templateNameA < templateNameB) {
                return 1;
              }
              if (templateNameA > templateNameB) {
                return -1;
              }
              return 0;
            })
            .map((template) => (
              <UIDTemplateCard
                key={template.id}
                integrationID={integrationID}
                template={template}
              />
            ))}
        <ClickableContainer onClick={() => createTemplate(integrationID, category, model)}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              paddingTop: "2px",
            }}
          >
            <MergeText color="#075FF7">+ Add Template</MergeText>
          </div>
        </ClickableContainer>
      </Col>
    </>
  );
};

type UIDTemplateCardProps = {
  integrationID: string;
  template: IntegrationCommonModelTemplate;
};

const UIDTemplateCard = ({ integrationID, template }: UIDTemplateCardProps) => {
  const templateFields = useMemo(() => {
    return template.unique_id_fields
      .map((template) => template.field_name)
      .sort()
      .join(" - ");
  }, [template.unique_id_fields]);
  const history = useHistory();

  return (
    <>
      <ClickableContainer
        onClick={() => {
          navigateToUIDTemplateEditor(history, integrationID, template.id);
        }}
      >
        <Card
          style={{
            marginBottom: 20,
            boxShadow: "0px 4px 20px -4px #00000014",
          }}
        >
          <Card.Body>
            <CardRow>
              <div style={{ display: "flex", width: "90%", overflow: "hidden" }}>
                <MergeText isBold size="16px">
                  {template.template_name}
                </MergeText>
                <OverflowSmallTextMutedParagraph className="mb-0">
                  {templateFields}
                </OverflowSmallTextMutedParagraph>
              </div>
              <div>
                <ChevronRight strokeWidth={1} />
              </div>
            </CardRow>
          </Card.Body>
        </Card>
      </ClickableContainer>
    </>
  );
};
