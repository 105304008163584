import { Accordion, Text } from "@merge-api/merge-javascript-shared";
import { Activity } from "lucide-react";
import { MappingTestExecution } from "../../../../../../../../../models/MappingTests";
import MappingTestV2RightPanelLogCard from "../../../../../../../../mapping-tests/right-panel/MappingTestV2RightPanelLogCard";

interface Props {
  selectedBlockExecution: MappingTestExecution;
}

const BlockLogsAccordion = ({ selectedBlockExecution }: Props) => {
  return (
    <Accordion
      title={
        <div className="flex flex-row items-center space-x-2 border-b-1 border-gray-20">
          <Activity size={16} className="text-black" />
          <Text variant="h6" className="text-black">
            Logs
          </Text>
        </div>
      }
      titleClassName="p-4"
      chevronColor="darkGray"
      chevronOrientation="right"
      variant="outline"
      className="w-full"
    >
      <div className="px-4">
        <hr className="text-gray-20 h-[0.5px] my-0 -mx-4" />
        {selectedBlockExecution.exit_data &&
          selectedBlockExecution.exit_data.map((exitData, index) => {
            return (
              <div>
                <MappingTestV2RightPanelLogCard
                  index={index}
                  exitData={exitData}
                  selectedBlockExecution={selectedBlockExecution}
                />
              </div>
            );
          })}
      </div>
    </Accordion>
  );
};

export default BlockLogsAccordion;
