import Editor from "react-simple-code-editor";
import { isValidXML } from "../../../utils";
import FormField, { FormFieldCommonProps } from "../../blueprint-editor/right-panel/FormField";
import beautify from "xml-beautifier";
import { Button } from "react-bootstrap";
const { highlight, languages } = require("prismjs");

interface Props extends FormFieldCommonProps {
  setValue: (value: string) => void;
  currentValue: string | undefined;
  numRows: number;
  children?: JSX.Element;
}

const XMLConverterFormField = ({
  setValue,
  currentValue,
  example,
  subtitle,
  title,
  children,
}: Props) => {
  const isValueValidXML = currentValue && isValidXML(currentValue);
  return (
    <FormField title={title} subtitle={subtitle} example={example}>
      <>
        {children}
        <Button
          className="mb-3"
          onClick={() =>
            setValue(
              isValidXML(currentValue || "") ? beautify(currentValue || "") : currentValue ?? ""
            )
          }
          disabled={!isValueValidXML}
          variant="outline-primary"
          size="sm"
        >
          Beautify
        </Button>
        <Editor
          highlight={(code) => highlight(code, languages.js)}
          value={currentValue ?? ""}
          onValueChange={(code) => {
            setValue(code);
          }}
          padding={10}
          style={{
            backgroundColor: "white",
            border: isValueValidXML ? "1px solid #d2ddec" : "1px solid red",
            borderRadius: 8,
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
          }}
        />
      </>
    </FormField>
  );
};

export default XMLConverterFormField;
