import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

import useScraperContext from "./context/useScraperContext";
import ScraperEditorExistingForm from "./ScraperEditorExistingForm";
import ScraperEditorNewStepForm from "./ScraperEditorNewStepForm";
import { ScraperStep } from "./types";
import { getStepConfigForStep } from "./utils/ScraperUtils";
import { EditableText } from "../shared/EditableText";
import { showErrorToast } from "../shared/Toasts";
import DeprecatedH2 from "../deprecated/DeprecatedH2";

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  width: 350px;
  height: 100%;
  box-shadow: -1px 0px 0px #edf2f9;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScraperEditorRightPanel = () => {
  const { renameStep, selectedStep } = useScraperContext();

  const config = selectedStep ? getStepConfigForStep(selectedStep) : { description: undefined };

  const onSaveStepID = (newName: string, onError?: () => void) => {
    if (newName && selectedStep) {
      renameStep(selectedStep.id, newName, onError);
    } else {
      showErrorToast("Step name cannot be empty.");
    }
  };
  return (
    <RightPanel>
      <Row className="mt-6">
        <Col>
          <div className="text-center">
            <DeprecatedH2 className="mb-3">
              {selectedStep && (
                <EditableText
                  originalText={selectedStep.id}
                  onSave={onSaveStepID}
                  textClassName="text-muted"
                  hasEditShortcut={true}
                />
              )}
            </DeprecatedH2>
            {config?.description}
          </div>
          <hr />
          {selectedStep && "template" in selectedStep && selectedStep.template === "ghost" ? (
            <ScraperEditorNewStepForm step={selectedStep} />
          ) : selectedStep ? (
            <ScraperEditorExistingForm step={selectedStep as ScraperStep} />
          ) : undefined}
        </Col>
      </Row>
    </RightPanel>
  );
};

export default ScraperEditorRightPanel;
