import { Row } from "react-bootstrap";
import { ScraperVersion } from "../../scraper/types";
import { getReturnSchemaForScraper } from "../../scraper/utils/ScraperUtils";
import JSONSchemaTreeDiagram from "../../shared/JSONSchemaTreeDiagram";

type Props = {
  scraper: ScraperVersion;
};

const BlueprintEditorRightPanelScraperForm = ({ scraper }: Props): JSX.Element => {
  return (
    <div className="p-3 flex-grow-1">
      <Row className="align-items-center">
        <JSONSchemaTreeDiagram jsonSchema={getReturnSchemaForScraper(scraper)} stepID="scraper" />
      </Row>
    </div>
  );
};

export default BlueprintEditorRightPanelScraperForm;
