import { useState } from "react";
import { useHistory } from "react-router-dom";
import { BlueprintOperationType } from "../../../models/Blueprints";
import DropdownFormField from "../../blueprint-editor/right-panel/DropdownFormField";
import { ScraperDataType } from "../../scraper/types";
import { createScraper } from "../../scraper/utils/ScraperAPIClient";
import MergeModal from "../../shared/MergeModal";
import SpinnerButton from "../../shared/SpinnerButton";

type Props = {
  integrationID: string;
};

const getNameForScraperDataType = (dataType: ScraperDataType) => {
  switch (dataType) {
    case ScraperDataType.AUTH_DATA:
      return "Linking Flow Authentication";
    case ScraperDataType.COMMON_MODEL_DATA:
      return "Common Model Creation/Modification/Deletion";
  }
};

const ScrapersTableCreateButton = ({ integrationID }: Props) => {
  const history = useHistory();
  const [isShowingConfirmCreateScraperModal, setIsShowingConfirmCreateScraperModal] = useState<
    boolean
  >(false);
  const [isLoading, setIsLoading] = useState(false);
  const [scraperDataType, setScraperDataType] = useState(ScraperDataType.COMMON_MODEL_DATA);

  const [operationType, setOperationType] = useState(BlueprintOperationType.FETCH);

  return (
    <>
      <MergeModal
        title="Confirm Scraper Creation"
        centered
        bodyClassName="modal-narrow overflow-hidden"
        dialogClassName="modal-narrow"
        show={isShowingConfirmCreateScraperModal ?? false}
        onHide={() => setIsShowingConfirmCreateScraperModal(false)}
      >
        <p>
          Creating a scraper will also create an associated scraping blueprint. If you mark this
          scraper for authentication, it will be called after the end user inputs login credentials
          in the linking flow to pull authentication credentials.
        </p>
        <DropdownFormField
          choices={Object.keys(BlueprintOperationType).map((operationType) => ({
            name: operationType,
            id: operationType,
          }))}
          currentValue={operationType}
          placeholder={"Set operation Type"}
          title="Operation Type"
          subtitle="Operation type of the associated blueprint."
          onChange={(e) => setOperationType(e.target.value)}
        />
        <DropdownFormField
          choices={(Object.keys(ScraperDataType) as Array<ScraperDataType>).map((dataType) => ({
            name: getNameForScraperDataType(dataType),
            id: dataType,
          }))}
          currentValue={scraperDataType}
          placeholder={"Set scraper function"}
          title="Scraper data type"
          subtitle="Mark whether this scraper is used for creating/modifying/deleting common models or for pulling authentication credentials in the linking flow."
          onChange={(e) => setScraperDataType(e.target.value)}
        />
        <SpinnerButton
          isLoading={isLoading}
          text="Create scraper and associated blueprint"
          className="btn btn-primary btn-block mt-6"
          onClick={() => {
            setIsLoading(true);
            createScraper({
              name: "Default Name",
              scraperDataType,
              operationType,
              integrationID,
              onSuccess: (data) => {
                history.push(
                  `/integrations/${integrationID}/scrapers/${data.id}/versions/${data.version_id}/editor`
                );
              },
            });
          }}
        />
      </MergeModal>
      <button
        onClick={() => setIsShowingConfirmCreateScraperModal(true)}
        className="btn btn-sm btn-white btn-sm mr-3"
      >
        <>
          <div className="d-flex justify-content-center">
            <i className="fe fe-plus mr-1.5" />
            Create scraper
          </div>
        </>
      </button>
    </>
  );
};

export default ScrapersTableCreateButton;
