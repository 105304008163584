import { Dialog } from "@merge-api/merge-javascript-shared";
import { BPRStepIOLog } from "../../../../models/Blueprints";
import StepIOLogModalSection from "./StepIOLogModalSection";
import { convertVariableDiffLogsToDisplayableDictionary } from "../../utils/BlueprintStepIOUtils";

interface Props {
  stepIOLog: BPRStepIOLog;
  open: boolean;
  setOpen: (open: boolean) => void;
}

/**
 * Modal component to display detailed information about a specific step I/O log.
 * It shows input, output, and variable changes (if any) for the selected step.
 *
 * @param {BPRStepIOLog} stepIOLog - The step I/O log to be displayed in the modal
 * @param {boolean} open - Whether the modal is open or closed
 * @param {function} setOpen - Function to set the open state of the modal
 */
const StepIOLogModal = ({ stepIOLog, open, setOpen }: Props) => {
  // Convert variable diff logs into displayable section
  let variableDiffLogsAsDict: Record<string, any> = convertVariableDiffLogsToDisplayableDictionary(
    stepIOLog.variable_diff_logs
  );

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      footerButtonsHidden={true}
      title={"Step: " + stepIOLog.step_id}
      variant="xl"
    >
      <div className="flex flex-col h-[700px] overflow-y-auto space-y-4">
        <StepIOLogModalSection title="Input" data={stepIOLog.inputs} />
        <StepIOLogModalSection title="Output" data={stepIOLog.outputs} />
        {Object.keys(variableDiffLogsAsDict).length > 0 && (
          <StepIOLogModalSection title="Variable changes" data={variableDiffLogsAsDict} />
        )}
      </div>
    </Dialog>
  );
};

export default StepIOLogModal;
