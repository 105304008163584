import { useHistory } from "react-router-dom";
import { Blueprint, BlueprintVersion } from "../../../models/Blueprints";
import { User } from "../../../models/Entities";
import useBlueprintContext from "../context/useBlueprintContext";
import { UserAvatar, AvatarSize } from "../../shared/MergeAvatars";
import { getCapitalizedTimeFromNow } from "../../shared/utils/SharedComponentUtils";
import { navigateToBlueprintEditor } from "../../../router/RouterUtils";
import { Table } from "react-bootstrap";
import Spinner from "../../shared/Spinner";
import EmptyStateWrapper from "../../shared/layout/EmptyStateWrapper";
import { Button, ButtonVariant, Dialog, Text } from "@merge-api/merge-javascript-shared";
import { viewBlueprintDiff } from "../utils/BlueprintEditorUtils";
import { DiffModalTypeEnum, DiffModelTypeEnum } from "../../../models/DiffModels";
import { useState } from "react";
import PublishStateBadge from "../../../components/shared/PublishStateBadge";
import DiffModal from "../../../components/shared/diff-view/DiffModal";

interface Props {
  isVisible: boolean;
  onHide: () => void;
}

const BlueprintVersionHistoryModal = ({ onHide, isVisible }: Props) => {
  const history = useHistory();
  const { blueprint, blueprintVersions } = useBlueprintContext();
  const [blueprintToCompare, setBlueprintToCompare] = useState<Blueprint>();
  const [isShowingDiffModal, setIsShowingDiffModal] = useState(false);
  const [isLoadingBlueprintToCompare, setIsLoadingBlueprintToCompare] = useState(false);
  const integrationID = blueprint.integration.id;

  function viewBlueprintVersion(blueprint_version_id: string) {
    navigateToBlueprintEditor(history, integrationID, blueprint_version_id);
    onHide();
  }

  return (
    <>
      <Dialog
        title="Version History"
        open={isVisible}
        onClose={onHide}
        variant="xl"
        footerButtonsHidden
      >
        <div className="overflow-y-auto -mx-6 -my-5 max-h-[720px]">
          {blueprintVersions ? (
            <Table size="sm">
              <thead className="table-borderless">
                <tr>
                  <th>When</th>
                  <th>User</th>
                  <th>Status</th>
                  <th>Comment</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>{getTableRows(blueprint.owner, blueprintVersions)}</tbody>
            </Table>
          ) : (
            <EmptyStateWrapper isSpinner />
          )}
        </div>
      </Dialog>
      {isShowingDiffModal && (
        <DiffModal
          isModalOpen={isShowingDiffModal}
          setIsModalOpen={setIsShowingDiffModal}
          diffModalType={DiffModalTypeEnum.COMPARE}
          modelType={DiffModelTypeEnum.BLUEPRINT}
          isLoadingStates={isLoadingBlueprintToCompare}
          newState={blueprint}
          newStateTitle={`Blueprint version - ${blueprint.version.comment}`}
          currentState={blueprintToCompare ?? {}}
          currentStateTitle={`Blueprint version - ${blueprintToCompare?.version.comment}`}
        />
      )}
    </>
  );

  function getTableRows(_: User, blueprintVersions: BlueprintVersion[]) {
    if (blueprintVersions) {
      return blueprintVersions.map((blueprintVersion) => (
        <tr key={blueprintVersion.id}>
          <td>
            <Text>{getCapitalizedTimeFromNow(blueprintVersion.created_at)}</Text>
          </td>

          <td>
            <UserAvatar
              user={blueprintVersion.user}
              size={AvatarSize.xs}
              className="d-inline-block mr-1.5"
            />
            <Text>{blueprintVersion?.user?.name}</Text>
          </td>

          <td>
            <PublishStateBadge publishState={blueprintVersion.publish_state} />
          </td>

          <td className="w-1/2 text-wrap">
            <Text>{blueprintVersion.comment}</Text>
          </td>
          <td>
            {blueprint && blueprint.version.id === blueprintVersion.id ? (
              <Button size="sm" disabled>
                Viewing
              </Button>
            ) : (
              <div className="flex flex-row space-x-2 items-center">
                <Button size="sm" onClick={() => viewBlueprintVersion(blueprintVersion.id)}>
                  View
                </Button>
                <Button
                  size="sm"
                  variant={ButtonVariant.SecondaryBlue}
                  onClick={() =>
                    viewBlueprintDiff(
                      onHide,
                      setBlueprintToCompare,
                      setIsShowingDiffModal,
                      setIsLoadingBlueprintToCompare,
                      blueprint,
                      blueprintVersion.id
                    )
                  }
                >
                  Compare
                </Button>
              </div>
            )}
          </td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td />
          <td />
          <td className="text-center">
            <Spinner />
          </td>
          <td />
          <td />
        </tr>
      );
    }
  }
};

export default BlueprintVersionHistoryModal;
